import React from 'react'
import styled from 'styled-components'

const StyledScrollViewHeader = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	position: relative;
`

const ScrollViewHeader = ({ children }) => {
	return <StyledScrollViewHeader>{children}</StyledScrollViewHeader>
}

ScrollViewHeader.defaultProps = {}

ScrollViewHeader.propTypes = {}

export default ScrollViewHeader
