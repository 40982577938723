import React, { PureComponent } from 'react'
import { func, object, bool } from 'prop-types'

import createImage from '../../../../assets/images/onboarding/meetings-add-meeting.png'
import smartCalendar from '../../../../assets/images/onboarding/meetings-smart-meetings-calendar.png'
import smartImage from '../../../../assets/images/onboarding/meetings-smart-meetings.png'

import Button from '../../../shared/button/button'
import LeftRightLayout, { Header, Entry } from '../sub-modules/left-right-layout'
import CenterLeftRightLayout, { Column } from '../sub-modules/centered-left-right-layout'

class FirstStepBody extends PureComponent {
	static propTypes = {
		createStandardMeetingButton: object,
		showSmartMeetingInfo: func,
		isPremiumAccount: bool,
		createSmartMeetingButton: object
	}

	renderSmartMeetingBottomComponent = () => {
		const { isPremiumAccount, showSmartMeetingInfo, createSmartMeetingButton } = this.props

		if (isPremiumAccount) {
			return createSmartMeetingButton
		}

		return (
			<Button
				minWidth='100px'
				onClick={showSmartMeetingInfo}
				mode='primary'
				color='white'
				tid='onboarding.modals.meetings.intro.smart.btn'
			/>
		)
	}

	render = () => {
		const { createStandardMeetingButton } = this.props

		return (
			<CenterLeftRightLayout>
				<Column
					image={createImage}
					title='onboarding.modals.meetings.intro.create.title'
					subtitle='onboarding.modals.meetings.intro.create.subtitle'
					text='onboarding.modals.meetings.intro.create.info'
					bottomComponent={createStandardMeetingButton}
				/>

				<Column
					image={smartCalendar}
					title='onboarding.modals.meetings.intro.smart.title'
					subtitle='onboarding.modals.meetings.intro.smart.subtitle'
					text='onboarding.modals.meetings.intro.smart.info_1'
					bottomComponent={this.renderSmartMeetingBottomComponent()}
				/>
			</CenterLeftRightLayout>
		)
	}
}

class SecondStepBody extends PureComponent {
	static propTypes = {
		billingButton: object
	}

	render = () => {
		const { billingButton } = this.props

		return (
			<LeftRightLayout image={smartImage}>
				<Header tid='onboarding.modals.meetings.intro.smart.more.header' />

				<Entry
					titleTid='onboarding.modals.meetings.intro.smart.more.subtitle.1'
					subTextTid='onboarding.modals.meetings.intro.smart.more.subinfo.1'
				/>

				<Entry subTextTid='onboarding.modals.meetings.intro.smart.more.subinfo.2' bottomComponent={billingButton} />
			</LeftRightLayout>
		)
	}
}

export { FirstStepBody, SecondStepBody }
