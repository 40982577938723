import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func, object } from 'prop-types'

import DocumentsSortList from '../../dumb-components/collections/documents-sort-list'
import Text from '../../dumb-components/shared/text/text'
import documentsHelper from '../../components/helpers/documents.helper'

import { fetchCollectionDocuments, reorderDocument, setToBeMergedLocal } from '../../actions/collections.actions'

class DocumentDndSortContainer extends Component {
	state = {
		isFetchingDocuments: true
	}

	static propTypes = {
		collectionId: string.isRequired,
		onFilterIncludedDocuments: func,
		onGetControlsData: func,
		headerComponent: object
	}

	componentDidMount = () => {
		const { collectionId, fetchCollectionDocuments, onFilterIncludedDocuments } = this.props

		fetchCollectionDocuments(collectionId, (fetchedDocuments) => {
			this.setState({ isFetchingDocuments: false })
		})
	}

	handleGetControlsData = (document) => {
		const { onGetControlsData } = this.props
		const documentId = document.get('id')
		const toBeMerged = document.get('includedInAction') !== false
		return onGetControlsData && onGetControlsData({ document, documentId, toBeMerged, handleToggle: this.handleToggle })
	}

	handleToggle = (documentId, value) => {
		const { setToBeMergedLocal } = this.props
		setToBeMergedLocal(documentId, value)
	}

	handleDragEnd = ({ destination, source }) => {
		const { collectionId, reorderDocument, documents } = this.props

		if (!destination) {
			return
		}

		if (destination.index !== source.index) {
			const toIndex = destination.index
			const fromIndex = source.index
			const document = documents.get(fromIndex)
			const reorderedDocuments = documents.remove(fromIndex).insert(toIndex, document)
			const documentIds = reorderedDocuments.map(d => d.get('id'))

			reorderDocument(collectionId, documentIds)
		}
	}

	renderNameAddon = (doc) => {
		const { selectedCollection } = this.props
		const collectionDocument = selectedCollection.get('documents').find((d) => d.get('id') === doc.get('id'))
		const isSignedVersion = collectionDocument?.get('sharedVersion') === 'SIGNED'

		if (!isSignedVersion) {
			return null
		}

		return <Text tid='collections.document_list_item.common.signed_copy' nowrap hasItalic />
	}

	render = () => {
		const { documents, headerComponent } = this.props
		const { isFetchingDocuments } = this.state

		return (
			<DocumentsSortList
				documents={documents}
				isLoading={isFetchingDocuments}
				headerComponent={headerComponent}
				onGetControlsData={this.handleGetControlsData}
				onDragEnd={this.handleDragEnd}
				renderNameAddon={this.renderNameAddon}
			/>
		)
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		documents: store.collections.get('selectedCollectionDocuments'),
		selectedCollection: store.collections.get('collections').find((c) => c.get('id') === ownProps.collectionId)
	}
}

const mapActionsToProps = {
	fetchCollectionDocuments,
	reorderDocument,
	setToBeMergedLocal
}

export default connect(mapStoreToProps, mapActionsToProps)(DocumentDndSortContainer)
