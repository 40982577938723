import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, routeHoc, renderComponent } from '../../interfaces/router'
import { fetchCompanyByUrlAlias, resetCompanyReducer } from '../../actions/company.actions'
import CompanyLandingPage from '../../components/company/company-landing-page'
import CompanyProfile from './company-profile/company-profile.container'
import SharesRoutesContainer from '../shares/shares-routes.container'
import Meetings from '../meetings/meetings.container'
import MeetingContainer from '../meetings/meeting/meeting.container'
import ProtocolViewContainer from '../meetings/protocol-view/protocol-view.container'
import Documents from '../documents/documents.container'
import Tasks from '../tasks/tasks.container'
import UserManagementUsers from '../../components/company/user-management/user-management--users'
import UserManagementRoles from '../../components/company/user-management/user-management--roles'
import UserManagementAccess from './user-management/user-management-access.container'
import SubscriptionsContainer from '../subscriptions/subscriptions.container'
import Invoices from '../../components/company/invoices/invoices'
import InvestmentPortfolio from '../../components/user/investment-portfolio'
import AdvertisingAdministrationOverviewContainer from '../advertising/administration/AdvertisingAdministrationOverviewContainer'
import DealflowAdministrationOverviewContainer from '../dealflow/administration/DealflowAdministrationOverviewContainer'

class CompanyRoutesContainer extends Component {
	componentDidMount() {
		const {
			company,
			match: {
				params: { company: companyUrlAlias }
			}
		} = this.props

		if (!company || company.urlAlias !== companyUrlAlias) {
			this.props.resetCompanyReducer()
			this.props.fetchCompanyByUrlAlias(companyUrlAlias)
		}
	}

	componentDidUpdate(prevProps) {
		const {
			match: {
				params: { company: companyUrlAlias }
			}
		} = this.props

		if (prevProps.match.params.company !== companyUrlAlias) {
			this.props.fetchCompanyByUrlAlias(companyUrlAlias)
		}
	}

	render() {
		const { company } = this.props

		if (!company) {
			return null
		}

		return (
			<Switch>
				<Route exact path='/:company/company-profile' component={CompanyProfile} />
				<Route exact path='/:company/' component={CompanyLandingPage} />
				<Route path='/:company/shares' component={SharesRoutesContainer} />
				<Route
					path='/:company/investments'
					render={(props) => renderComponent(InvestmentPortfolio, { ...props, isCompany: true })}
				/>
				<Route exact path='/:company/meetings/:id?' component={Meetings} />
				<Route path='/:company/meetings/:id/:type(meeting|protocol)' component={MeetingContainer} />
				<Route path='/:company/protocols/:id' component={ProtocolViewContainer} />
				<Route exact path='/:company/documents/:id?' component={Documents} />
				<Route path='/:company/tasks/:id?' component={Tasks} />
				<Route exact path='/:company/user-management' component={UserManagementUsers} />
				<Route exact path='/:company/user-management/roles' component={UserManagementRoles} />
				<Route exact path='/:company/user-management/access' component={UserManagementAccess} />
				<Route exact path='/:company/subscriptions' component={SubscriptionsContainer} />
				<Route exact path='/:company/invoices' component={Invoices} />
				<Route exact path='/:company/advertising' component={AdvertisingAdministrationOverviewContainer} />
				<Route exact path='/:company/dealflow' component={DealflowAdministrationOverviewContainer} />

				
			</Switch>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		company: store.company.company
	}
}

const mapActionsToProps = {
	resetCompanyReducer,
	fetchCompanyByUrlAlias
}

export default connect(mapStoreToProps, mapActionsToProps)(routeHoc(CompanyRoutesContainer))
