// Example locale file for English, give this to your locale team to translate

const getTranslations = (t) => {
	return {
		// Set Filter
		selectAll: t('ag-grid.locale.selectAll'),
		selectAllSearchResults: t('ag-grid.locale.selectAllSearchResults'),
		searchOoo: t('ag-grid.locale.searchOoo'),
		blanks: t('ag-grid.locale.blanks'),
		noMatches: t('ag-grid.locale.noMatches'),

		// Number Filter & Text Filter
		filterOoo: t('ag-grid.locale.filter_search'),
		equals: t('ag-grid.locale.equals'),
		notEqual: t('ag-grid.locale.not_equal'),
		empty: t('ag-grid.locale.empty'),
		blank: t('ag-grid.locale.blank'),
		notBlank: t('ag-grid.locale.not_blank'),

		// Number Filter
		lessThan: 'Less than',
		greaterThan: 'Greater than',
		lessThanOrEqual: 'Less than or equal',
		greaterThanOrEqual: 'Greater than or equal',
		inRange: 'In range',
		inRangeStart: 'from',
		inRangeEnd: 'to',

		// Text Filter
		contains: t('ag-grid.locale.contains'),
		notContains: t('ag-grid.locale.not_contains'),
		startsWith: t('ag-grid.locale.starts_with'),
		endsWith: t('ag-grid.locale.ends_with'),

		// Date Filter
		dateFormatOoo: 'yyyy-mm-dd',

		// Filter Conditions
		andCondition: t('ag-grid.locale.and_condition'),
		orCondition: t('ag-grid.locale.or_condition'),

		// Filter Buttons
		applyFilter: 'Apply',
		resetFilter: 'Reset',
		clearFilter: 'Clear',
		cancelFilter: 'Cancel',

		// Filter Titles
		textFilter: 'Text Filter',
		numberFilter: 'Number Filter',
		dateFilter: 'Date Filter',
		setFilter: 'Set Filter',

		// Side Bar
		columns: 'Columns',
		filters: 'Filters',

		// columns tool panel
		pivotMode: 'Pivot Mode',
		groups: 'Row Groups',
		rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
		values: 'Values',
		valueColumnsEmptyMessage: 'Drag here to aggregate',
		pivots: 'Column Labels',
		pivotColumnsEmptyMessage: 'Drag here to set column labels',

		// Header of the Default Group Column
		group: 'Group',

		// Row Drag
		rowDragRow: 'row',
		rowDragRows: 'rows',

		// Other
		loadingOoo: 'Loading...',
		loadingError: 'ERR',
		noRowsToShow: 'No Rows To Show',
		enabled: 'Enabled',

		// Menu
		pinColumn: 'Pin Column',
		pinLeft: 'Pin Left',
		pinRight: 'Pin Right',
		noPin: 'No Pin',
		valueAggregation: 'Value Aggregation',
		noAggregation: 'None',
		autosizeThiscolumn: 'Autosize This Column',
		autosizeAllColumns: 'Autosize All Columns',
		groupBy: 'Group by',
		ungroupBy: 'Un-Group by',
		addToValues: 'Add ${variable} to values',
		removeFromValues: 'Remove ${variable} from values',
		addToLabels: 'Add ${variable} to labels',
		removeFromLabels: 'Remove ${variable} from labels',
		resetColumns: 'Reset Columns',
		expandAll: 'Expand All',
		collapseAll: 'Close All',
		copy: t('ag-grid.locale.copy'),
		ctrlC: 'Ctrl+C',
		copyWithHeaders: 'Copy With Headers',
		copyWithGroupHeaders: 'Copy with Group Headers',
		paste: t('ag-grid.locale.paste'),
		ctrlV: 'Ctrl+V',
		export: 'Export',
		csvExport: 'CSV Export',
		excelExport: 'Excel Export',

		// Enterprise Menu Aggregation and Status Bar
		sum: 'Sum',
		first: 'First',
		last: 'Last',
		min: 'Min',
		max: 'Max',
		none: 'None',
		count: 'Count',
		avg: 'Average',
		filteredRows: 'Filtered',
		selectedRows: 'Selected',
		totalRows: 'Total Rows',
		totalAndFilteredRows: 'Rows',
		more: 'More',
		to: t('ag-grid.locale.to'),
		of: t('ag-grid.locale.of'),
		page: t('ag-grid.locale.page'),
		nextPage: 'Next Page',
		lastPage: 'Last Page',
		firstPage: 'First Page',
		previousPage: 'Previous Page',

		// Pivoting
		pivotColumnGroupTotals: 'Total',

		// Enterprise Menu (Charts)
		pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
		pivotChart: 'Pivot Chart',
		chartRange: 'Chart Range',

		columnChart: 'Column',
		groupedColumn: 'Grouped',
		stackedColumn: 'Stacked',
		normalizedColumn: '100% Stacked',

		barChart: 'Bar',
		groupedBar: 'Grouped',
		stackedBar: 'Stacked',
		normalizedBar: '100% Stacked',

		pieChart: 'Pie',
		pie: 'Pie',
		doughnut: 'Doughnut',

		line: 'Line',

		xyChart: 'X Y (Scatter)',
		scatter: 'Scatter',
		bubble: 'Bubble',

		areaChart: 'Area',
		area: 'Area',
		stackedArea: 'Stacked',
		normalizedArea: '100% Stacked',

		histogramChart: 'Histogram',
		histogramFrequency: 'Frequency',

		combinationChart: 'Combination',
		columnLineCombo: 'Column & Line',
		AreaColumnCombo: 'Area & Column',

		// Charts
		pivotChartTitle: 'Pivot Chart',
		rangeChartTitle: 'Range Chart',
		settings: 'Settings',
		data: 'Data',
		format: 'Format',
		categories: 'Categories',
		defaultCategory: '(None)',
		series: 'Series',
		xyValues: 'X Y Values',
		paired: 'Paired Mode',
		axis: 'Axis',
		navigator: 'Navigator',
		color: 'Color',
		thickness: 'Thickness',
		xType: 'X Type',
		automatic: 'Automatic',
		category: 'Category',
		number: 'Number',
		time: 'Time',
		autoRotate: 'Auto Rotate',
		xRotation: 'X Rotation',
		yRotation: 'Y Rotation',
		ticks: 'Ticks',
		width: 'Width',
		height: 'Height',
		length: 'Length',
		padding: 'Padding',
		spacing: 'Spacing',
		chart: 'Chart',
		title: 'Title',
		titlePlaceholder: 'Chart title - double click to edit',
		background: 'Background',
		font: 'Font',
		top: 'Top',
		right: 'Right',
		bottom: 'Bottom',
		left: 'Left',
		labels: 'Labels',
		size: 'Size',
		minSize: 'Minimum Size',
		maxSize: 'Maximum Size',
		legend: 'Legend',
		position: 'Position',
		markerSize: 'Marker Size',
		markerStroke: 'Marker Stroke',
		markerPadding: 'Marker Padding',
		itemSpacing: 'Item Spacing',
		itemPaddingX: 'Item Padding X',
		itemPaddingY: 'Item Padding Y',
		layoutHorizontalSpacing: 'Horizontal Spacing',
		layoutVerticalSpacing: 'Vertical Spacing',
		strokeWidth: 'Stroke Width',
		lineDash: 'Line Dash',
		offset: 'Offset',
		offsets: 'Offsets',
		tooltips: 'Tooltips',
		callout: 'Callout',
		markers: 'Markers',
		shadow: 'Shadow',
		blur: 'Blur',
		xOffset: 'X Offset',
		yOffset: 'Y Offset',
		lineWidth: 'Line Width',
		normal: 'Normal',
		bold: 'Bold',
		italic: 'Italic',
		boldItalic: 'Bold Italic',
		predefined: 'Predefined',
		fillOpacity: 'Fill Opacity',
		strokeOpacity: 'Line Opacity',
		histogramBinCount: 'Bin count',
		columnGroup: 'Column',
		barGroup: 'Bar',
		pieGroup: 'Pie',
		lineGroup: 'Line',
		scatterGroup: 'X Y (Scatter)',
		areaGroup: 'Area',
		histogramGroup: 'Histogram',
		combinationGroup: 'Combination',
		groupedColumnTooltip: 'Grouped',
		stackedColumnTooltip: 'Stacked',
		normalizedColumnTooltip: '100% Stacked',
		groupedBarTooltip: 'Grouped',
		stackedBarTooltip: 'Stacked',
		normalizedBarTooltip: '100% Stacked',
		pieTooltip: 'Pie',
		doughnutTooltip: 'Doughnut',
		lineTooltip: 'Line',
		groupedAreaTooltip: 'Area',
		stackedAreaTooltip: 'Stacked',
		normalizedAreaTooltip: '100% Stacked',
		scatterTooltip: 'Scatter',
		bubbleTooltip: 'Bubble',
		histogramTooltip: 'Histogram',
		columnLineComboTooltip: 'Column & Line',
		areaColumnComboTooltip: 'Area & Column',
		customComboTooltip: 'Custom Combination',
		noDataToChart: 'No data available to be charted.',
		pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
		chartSettingsToolbarTooltip: 'Menu',
		chartLinkToolbarTooltip: 'Linked to Grid',
		chartUnlinkToolbarTooltip: 'Unlinked from Grid',
		chartDownloadToolbarTooltip: 'Download Chart',
		seriesChartType: 'Series Chart Type',
		seriesType: 'Series Type',
		secondaryAxis: 'Secondary Axis',

		// ARIA
		ariaChecked: 'checked',
		ariaColumn: 'Column',
		ariaColumnGroup: 'Column Group',
		ariaColumnList: 'Column List',
		ariaColumnSelectAll: 'Toggle Select All Columns',
		ariaDateFilterInput: 'Date Filter Input',
		ariaDefaultListName: 'List',
		ariaFilterColumnsInput: 'Filter Columns Input',
		ariaFilterFromValue: 'Filter from value',
		ariaFilterInput: 'Filter Input',
		ariaFilterList: 'Filter List',
		ariaFilterToValue: 'Filter to value',
		ariaFilterValue: 'Filter Value',
		ariaFilteringOperator: 'Filtering Operator',
		ariaHidden: 'hidden',
		ariaIndeterminate: 'indeterminate',
		ariaInputEditor: 'Input Editor',
		ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
		ariaRowDeselect: 'Press SPACE to deselect this row',
		ariaRowSelectAll: 'Press Space to toggle all rows selection',
		ariaRowToggleSelection: 'Press Space to toggle row selection',
		ariaRowSelect: 'Press SPACE to select this row',
		ariaSearch: 'Search',
		ariaSortableColumn: 'Press ENTER to sort',
		ariaToggleVisibility: 'Press SPACE to toggle visibility',
		ariaUnchecked: 'unchecked',
		ariaVisible: 'visible',
		ariaSearchFilterValues: 'Search filter values',

		// ARIA Labels for Drop Zones

		ariaRowGroupDropZonePanelLabel: 'Row Groups',
		ariaValuesDropZonePanelLabel: 'Values',
		ariaPivotDropZonePanelLabel: 'Column Labels',
		ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
		ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
		ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
		// used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
		ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
		ariaDropZoneColumnComponentSortAscending: 'ascending',
		ariaDropZoneColumnComponentSortDescending: 'descending',

		// ARIA Labels for Dialogs
		ariaLabelColumnMenu: 'Column Menu',
		ariaLabelCellEditor: 'Cell Editor',
		ariaLabelDialog: 'Dialog',
		ariaLabelSelectField: 'Select Field',
		ariaLabelTooltip: 'Tooltip',
		ariaLabelContextMenu: 'Context Menu',
		ariaLabelSubMenu: 'SubMenu',
		ariaLabelAggregationFunction: 'Aggregation Function',

		// Number Format (Status Bar, Pagination Panel)
		thousandSeparator: ',',
		decimalSeparator: '.'
	}
}

export default getTranslations
