import React, { Component } from 'react'
import { connect } from 'react-redux'
import { oneOf, func } from 'prop-types'
import { Map, List } from 'immutable'
import Cookies from 'universal-cookie'

import SignInBankId from '../../dumb-components/credentials/middle-components/sign-in-bankid'
import SignInEmail from '../../dumb-components/credentials/middle-components/sign-in-email'

import { authBankId, resetCredentials } from '../../actions/credentials.actions'

import { validatePersonNumber, validateEmail } from '../../modules/validation.module'
import { showErrorTooltip, hideErrorTooltip } from '../../dumb-components/shared/tooltip/tooltip.helpers'

import { SSN_FIELD, EMAIL_FIELD, PASSWORD_FIELD, REMEMBER_DETAILS_FIELD, CODE_FIELD } from '../../constants/credentials'

const AUTH_MODE = 'SIGNIN'

class SignInContainer extends Component {
	state = {
		formData: Map(),
		hasError: Map(),
		actionBtnLoading: false
	}

	static propTypes = {
		authMode: oneOf([0, 1]), // 0 = EMAIL / 1 = BANKID
		onForgotPassword: func,
		onChange: func
	}

	componentDidMount = () => {
		this.initState(true)
		document.title = 'INVONO One™ | Login med BankID'
	}

	componentDidUpdate = (prevProps) => {
		const { authMode } = this.props

		if (prevProps.authMode !== authMode) {
			this.initState()
		}
	}

	initState = (init) => {
		const { authMode, onChangeAuthMode } = this.props
		let rememberDetailsBankId = new Cookies().get('rememberDetailsBankId')
		let rememberDetailsEmail = new Cookies().get('rememberDetailsEmail')

		if (init && rememberDetailsEmail && authMode !== 0) {
			onChangeAuthMode(0)
			return
		}

		rememberDetailsBankId = rememberDetailsBankId || Map()
		rememberDetailsEmail = rememberDetailsEmail || Map()

		this.setState({
			hasError: Map(),
			formData: authMode === 1 ? Map(rememberDetailsBankId) : Map(rememberDetailsEmail),
			actionBtnLoading: false
		})
	}

	onChange = (fieldName, value) => {
		const { onChange } = this.props
		let { formData } = this.state

		if (formData.get('useRememberDetails') && fieldName !== PASSWORD_FIELD && fieldName !== CODE_FIELD) {
			if (formData.get('userId')) {
				new Cookies().remove('rememberDetailsBankId')
			} else {
				new Cookies().remove('rememberDetailsEmail')
			}

			formData = formData.set(SSN_FIELD, '')
			formData = formData.set(REMEMBER_DETAILS_FIELD, false)
			formData = formData.remove('useRememberDetails')
			formData = formData.remove('userId')
		}

		if (fieldName === CODE_FIELD) {
			value = value.trim()
		}

		formData = formData.set(fieldName, value)

		this.onHideTooltip(fieldName)

		this.setState({ formData })

		onChange && onChange(fieldName, value)
	}

	onSignInBankId = () => {
		const { formData } = this.state
		const { authBankId, sessionId } = this.props
		if (formData.get('userId') || this.validateSignInBankId()) {
			this.setState({ actionBtnLoading: true })

			authBankId(AUTH_MODE, sessionId, 'SIGNIN-BANKID', formData, () => {
				this.setState({ actionBtnLoading: false })
			})
		}
	}

	onSignInEmail = () => {
		const { formData } = this.state
		const { authBankId, sessionId } = this.props

		if (this.validateSignInEmail()) {
			this.setState({ actionBtnLoading: true })

			authBankId(AUTH_MODE, sessionId, 'SIGNIN-BASIC', formData, () => {
				this.setState({ actionBtnLoading: false })
			})
		}
	}

	validateSignInBankId = () => {
		const { formData } = this.state
		let hasError = Map()
		let isValid = true

		// Validate SSN
		if (!validatePersonNumber(formData.get(SSN_FIELD))) {
			hasError = hasError.set(SSN_FIELD, List(['credentials.validation.ssn']))
			showErrorTooltip(this, SSN_FIELD)
			isValid = false
		}

		this.setState({ hasError })

		return isValid
	}

	validateSignInEmail = () => {
		const { formData } = this.state
		let hasError = Map()
		let isValid = true

		// Validate Email
		if (!validateEmail(formData.get(EMAIL_FIELD))) {
			hasError = hasError.set(EMAIL_FIELD, List(['credentials.validation.email']))
			showErrorTooltip(this, EMAIL_FIELD)
			isValid = false
		}

		// Check that password was entered
		if (formData.get(PASSWORD_FIELD, '').length === 0) {
			hasError = hasError.set(PASSWORD_FIELD, List(['credentials.validation.password_empty']))
			showErrorTooltip(this, PASSWORD_FIELD)
			isValid = false
		}

		this.setState({ hasError })

		return isValid
	}

	validateCode = () => {
		const { formData } = this.state
		let hasError = Map()
		let isValid = true
		const codeValue = formData.get(CODE_FIELD, '')
		const hasNumbersOnly = /^\d+$/.test(codeValue)

		// Validate Code
		if (!hasNumbersOnly) {
			hasError = hasError.set(CODE_FIELD, List(['credentials.validation.code']))
			showErrorTooltip(this, CODE_FIELD)
			isValid = false
		}

		this.setState({ hasError })

		return isValid
	}

	onHideTooltip = (ref) => {
		let { hasError } = this.state
		const fieldName = ref.target ? ref.target.getAttribute('name') : ref
		hasError = hasError.remove(fieldName)
		this.setState({ hasError })
		hideErrorTooltip(this, fieldName)
	}

	onSetTooltipRefs = (refName, ref) => {
		if (ref && refName) {
			this[refName] = ref
		}
	}

	onClickVerify = () => {
		const { formData } = this.state
		const { authBankId, sessionId, authMode } = this.props

		const strategy = authMode === 0 ? 'SIGNIN-BASIC' : 'SIGNIN-BANKID'
		if (this.validateCode()) {
			this.setState({ actionBtnLoading: true })

			// Action to send verification code somewhere here.
			authBankId(AUTH_MODE, sessionId, strategy, formData, () => {
				this.setState({ actionBtnLoading: false })
			})
		}
	}

	onCancelEmailVerify = () => {
		this.props.resetCredentials()
		this.initState() // Needed to check details in the cookie
	}

	render = () => {
		const { formData, hasError, actionBtnLoading } = this.state
		const { authMode, onForgotPassword, step, translationId, status } = this.props

		if (authMode === 0) {
			return (
				<SignInEmail
					onClickSignIn={this.onSignInEmail}
					onClickForgotPassword={onForgotPassword}
					onSetTooltipRefs={this.onSetTooltipRefs}
					onChange={this.onChange}
					onHideTooltip={this.onHideTooltip}
					onClickCancel={this.onCancelEmailVerify}
					onClickVerify={this.onClickVerify}
					hasError={hasError}
					isVerifyMode={step > 0}
					status={status}
					translationId={translationId}
					actionBtnLoading={actionBtnLoading}
					email={formData && formData.get(EMAIL_FIELD)}
					password={formData && formData.get(PASSWORD_FIELD)}
					code={formData && formData.get(CODE_FIELD)}
					rememberDetails={formData && formData.get(REMEMBER_DETAILS_FIELD)}
				/>
			)
		}

		if (authMode === 1) {
			return (
				<SignInBankId
					onClickSignIn={this.onSignInBankId}
					onSetTooltipRefs={this.onSetTooltipRefs}
					onChange={this.onChange}
					onHideTooltip={this.onHideTooltip}
					hasError={hasError}
					actionBtnLoading={actionBtnLoading}
					translationId={translationId}
					ssn={formData && formData.get(SSN_FIELD)}
					rememberDetails={formData && formData.get(REMEMBER_DETAILS_FIELD)}
				/>
			)
		}
	}
}

function mapStoreToProps(store) {
	return {
		step: store.credentials.get('step'),
		translationId: store.credentials.get('translationId'),
		status: store.credentials.get('status'),
		sessionId: store.credentials.get('sessionId')
	}
}

const mapActionsToProps = {
	authBankId,
	resetCredentials
}

export default connect(mapStoreToProps, mapActionsToProps)(SignInContainer)
