import io from 'socket.io-client'
import { notifySocketId } from './actions/notify.actions'
import { socketEventVotings } from './actions/votings.actions'
import { socketEventMeetings } from './actions/meetings.actions'
import { socketEventDocuments } from './actions/documents.actions'

export default (url) => {
	return (store) => {
		const socket = io(url)

		socket.on('socket', (msg) => {
			store.dispatch(notifySocketId(msg.id))
		})

		socket.on('events', (msg) => {
			// Meetings
			store.dispatch(socketEventMeetings(msg))

			// Voting
			store.dispatch(socketEventVotings(msg))

			// Documents
			store.dispatch(socketEventDocuments(msg))
		})

		return (next) => (action) => {
			if (action.isSocketAction) {
				socket.emit(action.type, { ...action.payload, socketId: socket.id })
				return
			}

			return next(action)
		}
	}
}
