import React, { useCallback } from 'react'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Tooltip from '../../../../mui-components/tooltip/tooltip'

export const TextEditorWithValidation = forwardRef((props, ref) => {
	const [value, setValue] = useState(props.value)
	const [error, setError] = useState(null)
	const { validate, errorTid } = props
	const inputRef = useRef(null)

	useEffect(() => {
		inputRef.current?.focus()
	}, [error])

	useImperativeHandle(ref, () => {
		return {
			getValue() {
				return value
			},

			isCancelBeforeStart() {
				return false
			},

			isCancelAfterEnd() {
				if (validate) {
					return !validate(value)
				}

				return false
			}
		}
	})

	const onChange = (event) => {
		setError(null)
		if (validate) {
			const valid = validate(event.target.value)

			if (!valid) {
				setError(errorTid)
			}
		}

		setValue(event.target.value)
	}

	return (
		<Tooltip title={error} hideTooltip={!error} open>
			<input type='text' ref={inputRef} value={value} onChange={onChange} style={{ width: '100%' }} />
		</Tooltip>
	)
})
