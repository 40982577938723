import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, object, oneOfType, bool, func, number } from 'prop-types'
import { List } from 'immutable'
import { fetchSimpleUsers } from '../../../actions/usersCache.actions'
import { fetchAndCacheInvestors } from '../../../actions/investors.actions'
import DropdownStatusButtonContainer from './dropdown-status-button.container'
import DurationContainer from './duration.container'
import AgendaItemEditorContainer from './agenda-item-editor.container'
import { hasMeetingStarted, countAgendaSubTopics } from '../../../components/helpers/meeting.helper'

import { openModal } from '../../../actions/modals.actions'
import { MEETING_START_MEETING_MODAL } from '../../../constants/modals'

class AgendaItemContainer extends Component {
	static propTypes = {
		id: string,
		num: oneOfType([string, number]),
		outcome: string,
		presenter: string,
		duration: number,
		name: string,
		itemType: string,
		active: bool,
		dragHandleProps: object,
		onChange: func,
		onClick: func.isRequired,
		renderAgendaItemEditor: func,
		parentIndex: number,
		itemIndex: number,
		onEnter: func,
		onFocus: func,
		onBlur: func,
		onTab: func,
		onShiftTab: func,
		inEditMode: bool,
		level: number,
		readOnly: bool,
		internalType: string,
		showProgressIcon: bool,
		statusDropDownReadOnly: bool,
		noDurationMenu: bool,
		usePortalForDurationMenu: bool,
		renderDropdownMenu: func
	}

	// componentDidMount = () => {
	// 	const { fetchSimpleUsers, presenter, fetchAndCacheInvestors } = this.props
	//
	// 	// Todo: determine if the presenter is a shareholder or sompany member
	// 	fetchSimpleUsers(List([presenter]))
	// 	fetchAndCacheInvestors(List([presenter]))
	// }

	// componentDidUpdate = (prevProps) => {
	// 	const { fetchSimpleUsers, presenter, fetchAndCacheInvestors } = this.props
	//
	// 	if (prevProps.presenter !== presenter) {
	// 		// Todo: determine if the presenter is a shareholder or sompany member
	// 		fetchSimpleUsers(List([presenter]))
	// 		fetchAndCacheInvestors(List([presenter]))
	// 	}
	// }

	getPresenter = (presenter) => {
		const { usersCache, investors, meeting } = this.props

		if (!meeting) {
			return
		}

		const attendee = meeting.getIn(['attendees', presenter])

		const user = usersCache && usersCache.get(presenter)

		if (user) {
			const presenterName = user.get('name')
			const presenterProfileImage = user.getIn(['image', 'filename'])
			const presenterId = user.get('id')
			return { presenterName, presenterProfileImage, presenterId }
		}

		let name
		if (attendee && attendee.get('isInvestor')) {
			const investor = investors?.get(attendee.get('investmentId'))
			name = investor?.getIn(['investorInformation', 'name'])
		} else if (attendee && attendee.get('isGuest')) {
			name = attendee.get('name')
		}

		return { presenterName: name }
	}

	onChange = (field, val) => {
		const { id, onChange, internalType, meeting } = this.props

		if (internalType === 'open' && !hasMeetingStarted(meeting) && val !== 'todo' && field === 'outcome') {
			this.openAttendeesModal(val)
			return
		}

		onChange && onChange(id, field, val)
	}

	onProgressIconClick = (val) => {
		const { id, onChange } = this.props

		onChange(id, 'progress', val)
	}

	openAttendeesModal = (value) => {
		this.props.openModal(MEETING_START_MEETING_MODAL, { agendaItemOutcomeValue: value })
	}

	renderStatus = () => {
		const { id, outcome, internalType, readOnly, statusDropDownReadOnly } = this.props

		return (
			<DropdownStatusButtonContainer
				fieldName='outcome'
				itemId={id}
				outcome={outcome}
				internalType={internalType}
				onChange={this.onChange}
				readOnly={true}
			/>
		)
	}

	renderDuration = () => {
		const { id, duration, presenter, userId, usePortalForDurationMenu, noDurationMenu } = this.props
		let { readOnly } = this.props

		if (userId && userId === presenter) {
			readOnly = false
		}

		return (
			<DurationContainer
				fieldName='duration'
				itemId={id}
				duration={duration}
				onChange={this.onChange}
				readOnly={readOnly || noDurationMenu}
				usePortal={usePortalForDurationMenu}
			/>
		)
	}

	renderEditor = () => {
		const { name, onEnter, onFocus, onBlur, onTab, onShiftTab, presenter, userId } = this.props
		let { readOnly } = this.props

		if (userId && userId === presenter) {
			readOnly = false
		}

		return (
			<AgendaItemEditorContainer
				fieldName='proposal'
				onEnter={onEnter}
				onFocus={onFocus}
				onBlur={onBlur}
				onTab={onTab}
				onShiftTab={onShiftTab}
				onChange={this.onChange}
				value={name}
				placeholderTid='meetings.write_a_proposal'
				readOnly={readOnly}
				autofocus
			/>
		)
	}

	render() {
		const { usersCache, presenter, itemComponent, renderDropdownMenu, internalType, meeting, ...componentProps } =
			this.props
		const Component = itemComponent
		let presenterName, presenterProfileImage, presenterId
		const presenterObj = this.getPresenter(presenter)

		if (presenterObj) {
			presenterName = presenterObj.presenterName
			presenterProfileImage = presenterObj.presenterProfileImage
			presenterId = presenterObj.presenterId
		}

		let numOfSubTopics = 0
		if (componentProps.itemType === 'category') {
			numOfSubTopics = countAgendaSubTopics(componentProps.id, meeting?.get('agendaItems'))
		}

		return (
			<Component
				{...componentProps}
				presenterName={presenterName}
				presenterProfileImage={presenterProfileImage}
				presenterId={presenterId}
				internalType={internalType}
				renderStatus={this.renderStatus}
				renderDuration={this.renderDuration}
				renderEditor={this.renderEditor}
				renderDropdownMenu={renderDropdownMenu}
				onChange={this.onChange}
				onProgressIconClick={this.onProgressIconClick}
				numOfSubTopics={numOfSubTopics}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		usersCache: store.usersCache.get('usersCache'),
		userId: store.user.getIn(['userObj', 'id']),
		meeting: store.meetings.get('meeting'),
		investors: store.investors.get('investorsCache')
	}
}

const mapActionsToProps = {
	fetchSimpleUsers,
	openModal,
	fetchAndCacheInvestors
}

export default connect(mapStoreToProps, mapActionsToProps)(AgendaItemContainer)
