import React, { useState } from 'react'
import investmentApi from '/shared/helpers/investment.helper'
import { AddPersonModal } from '../../../../../containers/shared/add-person-modal/add-person-modal'
import { array, bool, func } from 'prop-types'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { addErrorNotification } from '../../../../../actions/notify.actions'
import uuid from 'uuid/v4'
import SimpleDarkInfoPanelWithControls from '../../../../../dumb-components/shared/dark-info-panel/simple-dark-info-panel-with-controls'
import { OutlinedButton } from '../../../../../dumb-components/shared/button-v2'
import DropdownMenuContainer from '../../../../../containers/shared/dropdown-menu.container'
import List from '../../../../../dumb-components/shared/list/list'
import ListItem from '../../../../../dumb-components/shared/list-item/list-item'
import DropdownItem from '../../../../../dumb-components/shared/dropdown-item/dropdown-item'
import Text from '../../../../../dumb-components/shared/text/text'
import DropdownIconItem from '../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import Tooltip from '../../../../../dumb-components/shared/tooltip/tooltip'
import Icon from '../../../../../dumb-components/shared/icon/icon'

const StyledList = styled(List)`
	margin-top: ${({ theme }) => theme.spacing[4]};
`

const StyledListItem = styled(ListItem)`
	display: flex;
	align-items: center;
	height: fit-content;
	padding: 8px 16px;
`

const StyledContactInfo = styled(ListItem)`
	display: flex;
	flex: 1;
	flex-direction: column;
`

const StyledIconWrapper = styled.div``

const PRIMARY_CONTACT_TOOLTP_STATES = {
	companyIsRegistered: {
		tid: 'investors_contact_book.tooltip.company_is_active',
		delayShow: 'instant'
	},
	isAlreadyPrimaryContact: {
		tid: 'investors_contact_book.tooltip.contact_is_primary_contact',
		delayShow: 'instant'
	}
}

function InvestorsContactBook({ onChange, onPrimaryContactChange, values, isRegisteredCompany, defaultContact }) {
	const [isOpen, setIsOpen] = useState(false)
	const [contact, setContact] = useState()
	const dispatch = useDispatch()

	const getPrimaryContactTooltip = (contactId) => {
		let activeState
		if (isRegisteredCompany) {
			activeState = 'companyIsRegistered'
		} else if (contactId === defaultContact) {
			activeState = 'isAlreadyPrimaryContact'
		}

		const isActive = Boolean(activeState)

		return { activeState, isActive }
	}

	const handleContact = (person) => {
		person.id = person.id || uuid()

		try {
			investmentApi.validateContact(person)
		} catch (err) {
			dispatch(addErrorNotification({ text: err.message }))
			return
		}

		const index = values.findIndex((value) => value.id === person.id)

		if (index >= 0) {
			values[index] = person
			setContact(null)
		} else {
			values.push(person)
		}

		onChange(values)
		setIsOpen(false)
	}

	const deleteContact = (id) => {
		values = values.filter((value) => value.id !== id)
		onChange(values)
	}

	const editContact = (id) => {
		const person = values.find((value) => value.id === id)
		setContact({ ...person })
		setIsOpen(true)
	}

	return (
		<>
			<SimpleDarkInfoPanelWithControls
				tid={'investors-contact-book.container_info'}
				rightControlsComponent={<OutlinedButton icon='faPlus' onClick={() => setIsOpen(true)} />}
			/>
			<StyledList>
				{values.map(({ id, name, email, phone }) => {
					const { activeState, isActive } = getPrimaryContactTooltip(id)
					return (
						<StyledListItem key={id}>
							<StyledContactInfo>
								<Text bold={600}>{name}</Text>
								<Text tid={'investors-contact-book.container_list-item.email'} values={{ email }} />
								<Text tid={'investors-contact-book.container_list-item.phone'} values={{ phone }} />
							</StyledContactInfo>
							{!isRegisteredCompany && defaultContact === id && (
								<StyledIconWrapper>
									<Tooltip
										tid='investors_contact_book.primary_contact.contact_is_primary_contact'
										delayShow='instant'>
										<Icon icon='faStar' />
									</Tooltip>
								</StyledIconWrapper>
							)}
							<div style={{ marginLeft: 'auto' }}>
								<DropdownMenuContainer
									btnIcon='faEllipsisV'
									halignMenu='right'
									btnMode='transparent-icon'
									transparentIconButtonSize='sml'
									tooltipActiveState='btnMoreActions'>
									<DropdownIconItem
										tid='generic.edit'
										icon='faPenAlt'
										size='sml'
										onClick={() => editContact(id)}
									/>
									<Tooltip
										states={PRIMARY_CONTACT_TOOLTP_STATES}
										activeState={activeState}
										active={isActive}>
										<DropdownIconItem
											tid='investors_contact_book.set_as_primary_contact'
											icon='faStar'
											size='sml'
											disabled={isActive}
											onClick={() => onPrimaryContactChange(id)}
										/>
									</Tooltip>
									<DropdownIconItem
										tid='generic.remove'
										icon='faTrashAlt'
										size='sml'
										onClick={() => deleteContact(id)}
									/>
								</DropdownMenuContainer>
							</div>
						</StyledListItem>
					)
				})}
			</StyledList>

			<AddPersonModal
				isOpen={isOpen}
				onSave={handleContact}
				onCancel={() => setIsOpen(false)}
				person={contact}
				showCategoryField={false}
			/>
		</>
	)
}

InvestorsContactBook.propTypes = {
	onChange: func.isRequired,
	values: array.isRequired,
	onPrimaryContactChange: func.isRequired,
	isRegisteredCompany: bool,
	defaultContact: bool
}

export { InvestorsContactBook }
