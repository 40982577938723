import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, bool } from 'prop-types';
import { Map, fromJS } from 'immutable';
import { Margin } from 'styled-components-spacing';
import {
	listMeetingTemplates,
	selectMeetingTemplate,
	createMeetingTemplateLocal,
	createMeetingTemplate,
	deleteMeetingTemplate,
	saveMeetingTemplate,
	updateMeetingTemplateLocal
} from '../../../actions/meeting-templates.actions';
import { saveMeeting } from '../../../actions/meetings.actions';
import { fetchSimpleUsers } from '../../../actions/usersCache.actions';
import Button from '../../../dumb-components/shared/button/button';
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls';
import { TransparentButton } from '../../../dumb-components/shared/button-v2';
import Text from '../../../dumb-components/shared/text/text';
import MeetingTemplatesModal from '../../../dumb-components/meetings/meeting-templates/meeting-templates-modal/meeting-templates-modal';
import MeetingTemplatesListContainer from './meeting-templates-list.container';
import MeetingTemplateDetailsContainer from './meeting-template-details.container';
import DropdownMenuContainer from '../../shared/dropdown-menu.container';
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item';
import DropdownItem from '../../../dumb-components/shared/dropdown-item/dropdown-item';
import { isRequired } from '../../../modules/validation.module';
import immutableForm from '../../../components/framework/immutable-form';
import Icon from '../../../dumb-components/shared/icon/icon';
import i18nhelper from '../../../components/helpers/i18n.helper';
import DefaultMeetingTemplateItems from '/shared/meetings/default-template-items';

import {
	LIVE_MEETING_TEMPLATES_CREATE,
	LIVE_MEETING_TEMPLATES_UPDATE,
	LIVE_MEETING_TEMPLATES_DELETE
} from '../../../constants/live-update';

import { MEETING_DEFAULT_TEMPLATE_ID } from '/shared/constants';

class MeetingTemplatesModalContainer extends Component {
	static propTypes = {
		isOpen: bool,
		onSave: func,
		onClose: func,
		openCreateView: bool,
		closeModalWhenCreateViewIsCanceled: bool
	};

	static defaultProps = {
		openCreateView: false,
		closeModalWhenCreateViewIsCanceled: false
	};

	state = {
		viewMode: 'list', // or 'details'
		meetingTemplate: null,
		meetingTemplateIsNew: false
	};

	componentDidMount = () => {
		const { listMeetingTemplates, selectedGroupId } = this.props;

		listMeetingTemplates(selectedGroupId || '');
	};

	componentDidUpdate = (prevProps) => {
		const { listMeetingTemplates, fetchSimpleUsers, selectedGroupId, meetingTemplates, isOpen, openCreateView } =
			this.props;

		// Opened...
		if (isOpen && !prevProps.isOpen) {
			listMeetingTemplates(selectedGroupId || '');

			if (openCreateView === true) {
				this.createMeetingTemplate();
			}
		}

		if (prevProps.selectedGroupId !== selectedGroupId) {
			listMeetingTemplates(selectedGroupId || '');
		}

		if (meetingTemplates && prevProps.meetingTemplates !== meetingTemplates) {
			fetchSimpleUsers(meetingTemplates.map((obj) => obj.get('createdBy')));
		}

		this.checkLiveUpdateEvents();
	};

	checkLiveUpdateEvents = () => {
		const { audit, selectedGroupId, listMeetingTemplates } = this.props;
		const { viewMode, meetingTemplate } = this.state;
		const templateCreate = audit.get(LIVE_MEETING_TEMPLATES_CREATE, Map());
		const templateUpdate = audit.get(LIVE_MEETING_TEMPLATES_UPDATE, Map());
		const templateDelete = audit.get(LIVE_MEETING_TEMPLATES_DELETE, Map());

		// Meeting Template was created
		if (
			templateCreate.get('refresh') === true &&
			templateCreate.getIn(['metadata', 'groupId']) === selectedGroupId
		) {
			listMeetingTemplates(selectedGroupId);
		}

		// Meeting Template was edited
		if (
			templateUpdate.get('refresh') === true &&
			templateUpdate.getIn(['metadata', 'groupId']) === selectedGroupId
		) {
			listMeetingTemplates(selectedGroupId);
		}

		if (
			templateDelete.get('refresh') === true &&
			templateDelete.getIn(['metadata', 'groupId']) === selectedGroupId
		) {
			listMeetingTemplates(selectedGroupId);

			if (
				meetingTemplate &&
				templateDelete.get('objId') === meetingTemplate.get('id') &&
				viewMode === 'details'
			) {
				this.setState({ meetingTemplate: null, viewMode: 'list' });
			}
		}
	};

	validate = (meetingTemplate) => {
		const { validate, validateField } = this.props;

		if (validate(meetingTemplate) && validateField(meetingTemplate.get('name').trim(), 'name')) {
			return true;
		}

		return false;
	};

	onSave = () => {
		const { saveMeetingTemplate, createMeetingTemplate, createMeetingTemplateLocal, openCreateView, saveMeeting } =
			this.props;
		const { meetingTemplate } = this.state;
		let { meeting } = this.props;

		if (!this.validate(meetingTemplate)) {
			return;
		}

		if (!meetingTemplate.has('id')) {
			createMeetingTemplateLocal(meetingTemplate, (newMeetingTemplate) => {
				createMeetingTemplate(newMeetingTemplate);
				if (openCreateView) {
					const firstTemplate = meeting.get('templateId') ? false : true;
					meeting = meeting.set('templateId', newMeetingTemplate.get('id'));
					saveMeeting(meeting);
					this.onClose(firstTemplate);
				}
			});
		} else {
			saveMeetingTemplate(meetingTemplate);
		}

		this.setState({ viewMode: 'list', meetingTemplateIsNew: false });
	};

	onCancel = () => {
		const { closeModalWhenCreateViewIsCanceled } = this.props;
		const { viewMode } = this.state;

		if (viewMode === 'details' && !closeModalWhenCreateViewIsCanceled) {
			this.setState({ viewMode: 'list', meetingTemplateIsNew: false });
			return;
		}

		this.onClose();
	};

	onClose = (firstTemplate) => {
		const { onClose } = this.props;

		onClose(firstTemplate);
	};

	onChange = (meetingTemplate) => {
		this.setState({ meetingTemplate });
	};

	createMeetingTemplate = () => {
		const { selectedGroupId } = this.props;
		const meetingTemplate = fromJS({
			active: true,
			groupId: selectedGroupId || null,
			items: DefaultMeetingTemplateItems,
			includeAgendaInMail: true
		});

		this.setState({ meetingTemplate, meetingTemplateIsNew: true });
		this.selectMeetingTemplate();
	};

	selectMeetingTemplate = (id) => {
		const { meetingTemplates } = this.props;

		if (id) {
			const meetingTemplate = meetingTemplates.find((obj) => obj.get('id') === id);
			this.setState({ viewMode: 'details', meetingTemplate });
		} else {
			this.setState({ viewMode: 'details' });
		}
	};

	deleteMeetingTemplate = (id) => {
		const { deleteMeetingTemplate } = this.props;
		const { viewMode } = this.state;

		deleteMeetingTemplate(id);

		if (viewMode === 'details') {
			this.setState({ viewMode: 'list' });
		}
	};

	renderMeetingTemplatesList = () => {
		const { meetingTemplates, usersCache } = this.props;

		return (
			<MeetingTemplatesListContainer
				meetingTemplates={meetingTemplates}
				usersCache={usersCache}
				onSelectMeetingTemplate={this.selectMeetingTemplate}
				onDeleteMeetingTemplate={this.deleteMeetingTemplate}
				onChange={this.onChange}
			/>
		);
	};

	renderMeetingTemplatesDetails = () => {
		const { errors, resetErrors } = this.props;
		const { meetingTemplate } = this.state;

		return (
			<MeetingTemplateDetailsContainer
				onChange={this.onChange}
				meetingTemplate={meetingTemplate}
				errors={errors}
				resetErrors={resetErrors}
			/>
		);
	};

	renderModalButtons = () => {
		const { viewMode, meetingTemplate, meetingTemplateIsNew } = this.state;

		if (viewMode === 'details') {
			return (
				<FooterRightControls>
					<TransparentButton tid='generic.form.save' onClick={this.onSave} />
					{!meetingTemplateIsNew && meetingTemplate?.get('id') !== MEETING_DEFAULT_TEMPLATE_ID ? (
						<DropdownMenuContainer
							inline
							halignMenu='right'
							renderRaw={
								<TransparentButton tid='modal.footer.dropdown_menu.more_options' textColor='midGrey' />
							}>
							<DropdownIconItem icon='faTimes' tid='generic.form.cancel' onClick={this.onCancel} />
							<DropdownItem divider />
							<DropdownIconItem
								icon='faTrashAlt'
								tid='meetings.templates.delete_template'
								onClick={this.deleteMeetingTemplate.bind(
									this,
									meetingTemplate ? meetingTemplate.get('id') : null
								)}
							/>
						</DropdownMenuContainer>
					) : (
						<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={this.onCancel} />
					)}
				</FooterRightControls>
			);
		}

		return (
			<TransparentButton
				tid='meetings.meeting_templates.button.close'
				textColor='midGrey'
				onClick={this.onClose}
			/>
		);
	};

	renderHeaderComponent = () => {
		const { viewMode } = this.state;

		if (viewMode === 'list') {
			return (
				<Button onClick={this.createMeetingTemplate}>
					<Margin right={3}>
						<Icon icon='faPlus' size='xs' />
					</Margin>
					<Text tid='meetings.templates.create_a_new_template' />
				</Button>
			);
		}
	};

	getTitleData = () => {
		const { selectedGroupId, selectedGroupName, i18n, company } = this.props;
		return {
			titleTid: selectedGroupId
				? 'meetings.templates.title.group_templates'
				: 'meetings.templates.title.global_templates',
			titleValues: selectedGroupId
				? { groupName: i18nhelper.getTranslatedValue(selectedGroupName, i18n.language, company.region) }
				: {}
		};
	};

	render = () => {
		const { isOpen } = this.props;
		const { viewMode } = this.state;
		const contentRenderer =
			viewMode === 'list' ? this.renderMeetingTemplatesList : this.renderMeetingTemplatesDetails;
		const scrollableContent = viewMode === 'list' ? true : false;
		const { titleTid, titleValues } = this.getTitleData();

		return (
			<MeetingTemplatesModal
				isOpen={isOpen}
				title={titleTid}
				titleValues={titleValues}
				renderContent={contentRenderer}
				scrollableContent={scrollableContent}
				modalFooterComponent={this.renderModalButtons()}
				modalHeaderComponent={this.renderHeaderComponent}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	const selectedGroupId = store.groups.get('selectedGroupId');

	return {
		meetingTemplates: store.meetingTemplates.get('list'),
		meetingTemplate: store.meetingTemplates.get('meetingTemplate'),
		selectedGroupId,
		selectedGroupName: store.groups.getIn(['groups', selectedGroupId, 'name'], Map()),
		usersCache: store.usersCache.get('usersCache'),
		audit: store.audit.get('meetingTemplates'),
		meeting: store.meetings.get('meeting'),
		company: store.company.company,
		i18n: store.i18n
	};
};

const mapActionsToProps = {
	listMeetingTemplates,
	selectMeetingTemplate,
	createMeetingTemplateLocal,
	createMeetingTemplate,
	deleteMeetingTemplate,
	saveMeetingTemplate,
	updateMeetingTemplateLocal,
	fetchSimpleUsers,
	saveMeeting
};

const validators = fromJS({
	name: {
		tid: 'meeting.templates.details.name.placeholder',
		rules: [{ func: isRequired, message: 'validation.is_required' }]
	}
});

MeetingTemplatesModalContainer = immutableForm(MeetingTemplatesModalContainer, 'meetingTemplatesDetails', validators);

export default connect(mapStoreToProps, mapActionsToProps)(MeetingTemplatesModalContainer);
