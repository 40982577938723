import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'

import { closeModal } from '../../actions/modals.actions'

import ErrorMessageModal from '../../dumb-components/shares/error-message-modal/error-message-modal'

import { MODALS_SHARES_ERROR_MODAL } from '../../constants/modals'

class ShareholderTemplateErrorContainer extends Component {
	static propTypes = {
		onAccept: func,
		onDecline: func
	}

	closeModal = () => {
		const { closeModal } = this.props

		closeModal()
	}

	onAccept = () => {
		const { onAccept } = this.props

		onAccept && onAccept({ preventOpenDropdown: true })
		this.closeModal()
	}

	onDecline = () => {
		const { onDecline } = this.props

		onDecline && onDecline()
		this.closeModal()
	}

	render = () => {
		const { errorData, isOpen } = this.props

		if (!errorData || errorData.size === 0) {
			return null
		}

		return (
			<ErrorMessageModal isOpen={isOpen} onDecline={this.onDecline} onAccept={this.onAccept} errorData={errorData} />
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		isOpen: store.modals.getIn(['activeModal', 'name']) === MODALS_SHARES_ERROR_MODAL,
		errorData: store.modals.getIn(['activeModal', 'options', 'errorData'])
	}
}

const mapActionsToProps = {
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(ShareholderTemplateErrorContainer)
