import req from '../modules/request.module'
import { fromJS } from 'immutable'
import { CORE_PRODUCTS_FETCH, COMPANY_CHANGED } from './types'
import { fetchMenu } from './menu.actions'

export function fetchCoreProducts() {
	return function (dispatch) {
		return req.get('/companies/core-products').then((response) => {
			dispatch({
				type: CORE_PRODUCTS_FETCH,
				payload: fromJS(response.data)
			})
		})
	}
}

export function updateCompanyProducts(companyId, productId, active) {
	return function (dispatch) {
		return req.put(`/companies/${companyId}/core-products/${productId}`, { active }).then((response) => {
			dispatch({
				type: COMPANY_CHANGED,
				payload: response.data
			})
			dispatch(fetchMenu('main'))
		})
	}
}
