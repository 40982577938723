import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, number, string } from 'prop-types'
import { map } from 'react-immutable-proptypes'

import ShareOverviewAndSaveView from '../../../dumb-components/collections/share-modal/share-overview-and-save-view'

class ShareOverviewAndSaveContainer extends Component {
	static propTypes = {
		onGoToSelectPeople: func.isRequired,
		numberOfPeopleSharedWith: number.isRequired,
		hasError: map.isRequired,
		onChange: func.isRequired,
		expiresAt: string
	}

	getDocumentsToBeShared = () => {
		const { selectedCollectionDocuments } = this.props
		return selectedCollectionDocuments.filter((d) => d.get('includedInAction') !== false)
	}

	render = () => {
		const { onGoToSelectPeople, numberOfPeopleSharedWith, hasError, userlang, onChange, expiresAt } = this.props
		const documentsToBeShared = this.getDocumentsToBeShared()

		return (
			<ShareOverviewAndSaveView
				documentsToBeShared={documentsToBeShared}
				onGoToSelectPeople={onGoToSelectPeople}
				numberOfPeopleSharedWith={numberOfPeopleSharedWith}
				hasError={hasError}
				userlang={userlang}
				onChange={onChange}
				expiresAt={expiresAt}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		selectedCollectionDocuments: store.collections.get('selectedCollectionDocuments'),
		userlang: store.i18n.language
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(ShareOverviewAndSaveContainer)
