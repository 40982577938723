import React, { createContext, useContext } from 'react'

/*
Context definition:

value = {
	singleMode: Boolean,
	setSingleMode: (singleMode: Boolean) => void,
	selectedPeople: Immutable.Map,
	setSelectedPeople: (people: Object) => void,
	resetSelectedPeople: () => void,
	activeTab: String,
	setActiveTab: (tab: String) => void,
	isLoading: Boolean,
	setIsLoading: (isLoading: Boolean) => void,
	isOpen: Boolean,
	setIsOpen: (isOpen: Boolean) => void,
	floatingFilter: Boolean,
	setFloatingFilter: (Boolean) => void,
	processSelectedPeople: (people: Object) => Object,
	genericOnSave: (onSave: (people: Object) => void) => void,
	filters: {[key: string]: {value: []}},
	setFilters: ({[key: string]: {value: []}}) => void
	showGridInExtendedView: boolean,
	setShowGridInExtendedView: (boolean) => void,
	selectedPersonalContacts: person[],
	personalContactsHasBeenCopied: boolean,
	setPersonalContactsHasBeenCopied: (boolean) => void,
	setSelectedPersonalContacts: (people: person[]) => void,
	canCopyToMultipleCompanyAddressbooks: boolean,
	copyPeopleToCompanyContacts: (people: person[], callback()) => void,
	showColumnToggle: boolean,
	setShowColumnToggle: (boolean) => void,
	genericOnCancel: (onCancel: () => void) => void
}
*/

export const PeopleModalContext = createContext({})

export function usePeopleModalContext() {
	return useContext(PeopleModalContext)
}
