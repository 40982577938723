import React, { PureComponent } from 'react'
import { func, bool } from 'prop-types'
import styled from 'styled-components'

import Label from '../../shared/label/label'
import Text from '../../shared/text/text'
import Button from '../../shared/button/button'

const Wrapper = styled.div`
	display: flex;
`

const TextWrapper = styled.div`
	display: flex;
	flex: 1;
`

const ButtonWrapper = styled.div`
	margin-left: ${(props) => props.theme.spacing[4]};
`

export default class EsignRecreateDocument extends PureComponent {
	static propTypes = {
		onClick: func,
		isLoading: bool
	}

	render = () => {
		const { onClick, isLoading } = this.props

		return (
			<Wrapper>
				<TextWrapper>
					<Text tid='documents.esign.signees_panel.recreate.info' color='white' />
				</TextWrapper>
				<ButtonWrapper>
					<Button tid='documents.esign.signees_panel.recreate.btn' onClick={onClick && onClick} isLoading={isLoading} />
				</ButtonWrapper>
			</Wrapper>
		)
	}
}
