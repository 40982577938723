import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import { closeModal } from '../../actions/modals.actions'
import { fetchCompanyData } from '../../actions/syna.actions'
import { COMPANY_VERIFICATION_MODAL } from '../../constants/modals'
import { Margin, Padding } from 'styled-components-spacing'

import ScrollView from '../../dumb-components/shared/layout/scroll-view/scroll-view'
import Button from '../../dumb-components/shared/button/button'
import Icon from '../../dumb-components/shared/icon/icon'
import Box from '../../dumb-components/shared/layout/box/box'
import RepresentativesTable from '../../components/user/account-wizard/reps-table'
import StepsModal from '../../dumb-components/shared/modal/steps-modal'

import StepsBankID from '../../dumb-components/shared/bankid/steps-bankid'

import { signAndActivateCompany } from '../../actions/company.actions'

class CompanyVerifyModalContainer extends Component {
	state = {
		modalIsOpen: false,
		bankIdSigned: false,
		bankIdSignedBy: null,
		allowedRepresentativesToSign: [],
		showLoader: true,
		step: 0
	}

	componentDidUpdate = (prevProps) => {
		const { isOpen, fetchCompanyData, company } = this.props

		// Modal was Opened
		if (isOpen && !prevProps.isOpen) {
			fetchCompanyData(company.orgNumber, ({ allowedRepresentativesToSign }) => {
				this.setState({ allowedRepresentativesToSign, showLoader: false })
			})
		}
	}

	getModalSteps = () => {
		return [
			{
				body: this.renderModalBodyStepOne(),
				footer: {
					component: this.renderModalFooterStepOne()
				}
			},
			{
				body: this.renderModalBodyStepTwo(),
				footer: {
					component: this.renderModalFooterStepTwo()
				}
			}
		]
	}

	closeModal = () => {
		this.props.closeModal()
		this.resetState()
	}

	resetState = () => {
		this.setState({
			bankIdSigned: false,
			bankIdSignedBy: null,
			allowedRepresentativesToSign: [],
			showLoader: true,
			bankIdTransaltionId: null,
			step: 0
		})
	}

	onAuthorise = (ssn, name) => {
		const { signAndActivateCompany, company, isOpen } = this.props

		this.setState({ bankIdSignedBy: ssn, step: 1 }, () => {
			signAndActivateCompany(
				company.orgNumber,
				ssn,
				name,
				(bankidData) => {
					this.setState({ bankIdTransaltionId: bankidData.bankIdMessage })
				},
				(err) => {
					// Error occured
					if (err) {
						this.setState({
							bankIdSignedBy: null,
							bankIdTransaltionId: null,
							step: 0
						})
						return
					}
					// Signing Done
					this.closeModal()
				},
				() => {
					// Do NOT deconstruct this or it will break
					// due to this function running itself multiple
					// times
					if (!this.props.isOpen) {
						return false
					}
					// Should continue pooling
					return true
				}
			)
		})
	}

	renderModalBodyStepOne = () => {
		const { bankIdSigned, bankIdSignedBy, allowedRepresentativesToSign, showLoader, bankIdTransaltionId } = this.state

		if (showLoader) {
			return (
				<Box alignItems='center' valign='center' fullHeight>
					<Icon icon='faSpinner' size='xl' spin />
				</Box>
			)
		}

		return (
			<ScrollView fillContent noLeftMargin noRightMargin alwaysShow>
				<Padding horizontal={6}>
					<RepresentativesTable
						bankIdSigned={bankIdSigned}
						bankIdSignedBy={bankIdSignedBy}
						companyRepresentatives={allowedRepresentativesToSign}
						authorise={this.onAuthorise}
					/>
				</Padding>
			</ScrollView>
		)
	}

	renderModalFooterStepOne = () => {
		return <Button tid='generic.close' onClick={this.closeModal} />
	}

	renderModalBodyStepTwo = () => {
		return <StepsBankID tid={this.state.bankIdTransaltionId} />
	}

	renderModalFooterStepTwo = () => {
		return <Button onClick={this.closeModal} tid='generic.form.cancel' />
	}

	render = () => {
		const { isOpen } = this.props
		const { step } = this.state

		return (
			<StepsModal
				step={step}
				steps={this.getModalSteps()}
				isOpen={isOpen}
				hSize='xl'
				title='company.univerified.verify.modal.title'
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		isOpen: store.modals.getIn(['activeModal', 'name']) === COMPANY_VERIFICATION_MODAL,
		company: store.company.company
	}
}

const mapActionsToProps = {
	closeModal,
	fetchCompanyData,
	signAndActivateCompany
}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyVerifyModalContainer)
