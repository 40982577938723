import React from 'react'
import { func, bool, number } from 'prop-types'
import styled from 'styled-components'
import { Padding, Margin } from 'styled-components-spacing'
import Text from '../../shared/text/text'
import Panel from '../../shared/panel/panel'
import Button from '../../shared/button/button'
import Box from '../../shared/layout/box/box'
import CountDown from '../../shared/count-down/count-down'

const CancelAccountCancellationPanel = ({ remainingDaysOfSubscriptionPeriod, isLoading, onCancelCancellation }) => {
	return (
		<Margin bottom={3}>
			<Panel mode='light' title='subscription.account_will_be_canceled'>
				<Padding all={4}>
					<Margin bottom={5}>
						<Box alignItems='center'>
							<CountDown
								time={remainingDaysOfSubscriptionPeriod}
								suffixTid='subscriptions.cancel_account.days_remaining_of_paid_subscription'
							/>
						</Box>
					</Margin>
					<Box alignItems='center'>
						<Button
							mode='primary'
							isLoading={isLoading}
							onClick={onCancelCancellation}
							tid='subscriptions.button.cancel_cancellation'
							block
						/>
					</Box>
				</Padding>
			</Panel>
		</Margin>
	)
}

CancelAccountCancellationPanel.defaultProps = {
	isLoading: false
}

CancelAccountCancellationPanel.propTypes = {
	remainingDaysOfSubscriptionPeriod: number.isRequired,
	isLoading: bool,
	onCancelCancellation: func
}

export default CancelAccountCancellationPanel
