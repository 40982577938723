import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromJS, List } from 'immutable'

import GenericInfoPanel from '../../../dumb-components/company/comapny-dashboard/generic-info-panel/generic-info-panel'
import { countTasks } from '../../../actions/tasks.actions'

class TasksPanelContainer extends Component {
	state = {
		isLoading: true,
		data: List()
	}

	componentDidMount = () => {
		this.countTasks()
	}

	componentDidUpdate = (prevProps) => {
		const { companyId } = this.props

		if (prevProps.companyId !== companyId) {
			this.setState({ isLoading: true })
			this.countTasks()
		}
	}

	countTasks = () => {
		this.props.countTasks((response) => {
			const numOfTasksTodo = response.get('numOfTasksTodo', '-').toString()
			const numOfCompletedTasks = response.get('numOfCompletedTasks', '-').toString()

			this.setState({
				data: fromJS([
					{ title: 'company.dashboard.tasks.panel.to_do', value: numOfTasksTodo },
					{ title: 'company.dashboard.tasks.panel.completed', value: numOfCompletedTasks }
				]),
				isLoading: false
			})
		})
	}

	render = () => {
		const { isLoading, data } = this.state

		return <GenericInfoPanel title='company.dashboard.tasks.panel.title' data={data} isLoading={isLoading} />
	}
}

const mapStoreToProps = (store) => {
	return {
		companyId: store.company.company.id
	}
}

const mapActionsToProps = {
	countTasks
}

export default connect(mapStoreToProps, mapActionsToProps)(TasksPanelContainer)
