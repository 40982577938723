/**
 * See React DND documentation for DndProvider at https://react-dnd.github.io/react-dnd/docs/api/dnd-provider
 */
import React from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider as ReactDndProvider } from 'react-dnd'

const DndProvider = ({ children }) => {
	return <ReactDndProvider backend={HTML5Backend}>{children}</ReactDndProvider>
}

export default DndProvider
