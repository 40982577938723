import { List } from 'immutable'

const obj = {
	getPath: (folderId, folders) => {
		if (!folderId) {
			return
		}

		let path = List([folders.getIn([folderId, 'name'])])
		let parentId = obj.getParent(folderId, folders)

		if (parentId) {
			const parent = folders.getIn([parentId, 'name'])
			path = path.push(parent)
		}

		while (parentId) {
			parentId = obj.getParent(parentId, folders)

			if (parentId) {
				const parent = folders.getIn([parentId, 'name'])
				path = path.push(parent)
			}
		}

		return path.reverse()
	},

	getParent: (childId, folders) => {
		return folders.getIn([childId, 'parent'])
	},

	getDescendentsIds: (ids, folderId, mapParentToChildren) => {
		if (!folderId) {
			folderId = 'root'
		}

		const children = mapParentToChildren.get(folderId, List())
		children.forEach((child) => {
			ids.push(child)

			if (mapParentToChildren.get(child)) {
				obj.getDescendentsIds(ids, child, mapParentToChildren)
			}
		})
	},

	folderisEmpty: (folderId, documents, mapParentToChildren) => {
		if (!mapParentToChildren || !documents) {
			return false
		}
		let hasDocuments = false

		if (!folderId) {
			folderId = 'root'
		}

		const _searchSubFolderForDocuments = (folderId) => {
			const documentsInFolder = documents && documents.filter((doc) => doc.get('folderId') === folderId)

			hasDocuments = documentsInFolder ? documentsInFolder.size > 0 : false

			if (!hasDocuments) {
				const children = mapParentToChildren && mapParentToChildren.get(folderId)

				if (children && children.size > 0) {
					children.forEach((child) => {
						_searchSubFolderForDocuments(child)
					})
				}
			}
		}

		_searchSubFolderForDocuments(folderId)
		return !hasDocuments
	}
}

export default obj
