import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Button from '../../../mui-components/button/button'
import { useDispatch, useSelector } from 'react-redux'
import { createUserDealflow, deleteUserCriteriaDealflow, updateUserDealflow } from '../../../actions/dealflow.actions'
import { List, Map } from 'immutable'
import MenuItemIcon from '../../../mui-components/dropdown/menu/menu-item-icon'
import DealflowCriteria from '../../../dumb-components/shared/dealflow/dealflow-criteria'
import Tooltip from '../../../mui-components/tooltip/tooltip'
import { Form, FormSpy } from 'react-final-form'
import { TextField } from 'mui-rff'
import { isRequired } from '../../../modules/validation.module'
import { closeModal } from '../../../actions/modals.actions'
import MUIDialog from '../../../mui-components/dialog/mui-dialog'
import { FormattedMessage } from 'react-intl'
import { DEALFLOW_EDIT_USER_CRITERIA_MODAL } from '../../../constants/modals'
import { selectAll } from '../criteriaFormMutators'
import CRITERIA from '/shared/criteria'

export default function CriteriaModal() {
	const { t } = useTranslation()
	const [initialValues, setInitialValues] = useState()

	const isEditMode = useSelector(
		(state) => state.modals.getIn(['activeModal', 'name']) === DEALFLOW_EDIT_USER_CRITERIA_MODAL
	)
	const criteriaListEditId = useSelector((state) => state.modals.getIn(['activeModal', 'options', 'id']))
	const dealflowUserCriteriaLists = useSelector((state) => state.dealflow.userCriteriaLists)

	useEffect(() => {
		if (criteriaListEditId) {
			const dealflowCriteriaListItemToEdit = dealflowUserCriteriaLists.find(
				(item) => item.get('id') === criteriaListEditId
			)

			let _initialValues = Map()
			const [...sectionKeys] = dealflowCriteriaListItemToEdit.get('sections').keys()

			sectionKeys.forEach((section) => {
				dealflowCriteriaListItemToEdit.getIn(['sections', section, 'categories']).forEach((category) => {
					_initialValues = _initialValues.set(`${category}!${section}`, true)
				})

				_initialValues = _initialValues.set(
					`sectionSelectionType-${section}`,
					dealflowCriteriaListItemToEdit.getIn(['sections', section, 'sectionsSelectionType'])
				)
			})

			Object.keys(CRITERIA).forEach((section) => {
				if (!sectionKeys.includes(section)) {
					_initialValues = _initialValues.set(`sectionSelectionType-${section}`, 'and')
				}
			})

			_initialValues = _initialValues.set('criteriaListName', dealflowCriteriaListItemToEdit.get('name'))
			_initialValues = _initialValues.set('id', dealflowCriteriaListItemToEdit.get('id'))

			setInitialValues(_initialValues.toJS())
		} else {
			let sectionSelectionType = Map()
			Object.keys(CRITERIA).forEach((section) => {
				sectionSelectionType = sectionSelectionType.set(`sectionSelectionType-${section}`, 'and')
			})

			setInitialValues(sectionSelectionType.toJS())
		}
	}, [criteriaListEditId])

	const dispatch = useDispatch()

	const onSubmit = (values, form) => {
		let sections = Map()
		const categories = Object.keys(values).filter((key) => values[key] === true)

		categories.forEach((category) => {
			const categorySplit = category.split('!')
			if (sections.get(categorySplit[1])) {
				let categoryList = sections.getIn([categorySplit[1], 'categories'])
				categoryList = categoryList.push(categorySplit[0])
				sections = sections.setIn([categorySplit[1], 'categories'], categoryList)
			} else {
				sections = sections.set(categorySplit[1], Map({ categories: List([categorySplit[0]]) }))
			}
		})

		const [...uniqueCategories] = sections.keys()

		uniqueCategories.forEach((uniqueCategory) => {
			sections = sections.setIn(
				[uniqueCategory, 'sectionsSelectionType'],
				values[`sectionSelectionType-${uniqueCategory}`]
			)
		})

		const _userDealflow = Map({
			name: values.criteriaListName,
			active: true,
			sections: sections,
			id: values.id
		})

		if (isEditMode) {
			dispatch(
				updateUserDealflow(_userDealflow, () => {
					form.reset()
				})
			)
		} else {
			dispatch(
				createUserDealflow(_userDealflow, () => {
					form.reset()
				})
			)
		}
	}

	const validate = (values) => {
		const errors = {}

		if (!isRequired(values.criteriaListName)) {
			errors.criteriaListName = t('validation.is_required')
			errors.criteriaListNameTooltipTid = t('dealflow.criteria.errors.no_name')
		} else if (
			Object.keys(values).filter((value) => value !== 'criteriaListName').length < 1 ||
			!Object.values(values).includes(true)
		) {
			errors.noCriterionChoosenTooltipTid = t('dealflow.criteria.errors.minimum_1_item')
		}

		return errors
	}

	return (
		<Form
			onSubmit={onSubmit}
			subscription={{ submitting: true }}
			validate={validate}
			initialValues={initialValues}
			mutators={{ selectAll }}
			render={(formProps) => {
				const { handleSubmit } = formProps
				console.log('formProps: ', formProps)

				return (
					<form onSubmit={handleSubmit}>
						<MUIDialog
							isOpen={true}
							onClose={() => {
								dispatch(closeModal())
							}}
							onConfirm={handleSubmit}
							optionsButton={isEditMode}
							optionsButtonProps={{
								tid: 'dealflow.criteria_modal.dialog_actions.options_dropdown.title'
							}}
							optionsButtonMenu={({ popupState }) => [
								<MenuItemIcon
									key={'first'}
									onClick={() => {
										dispatch(closeModal())
									}}
									icon={'faTimes'}
									listItemTid={'dealflow.criteria_modal.cancel_button'}
								/>,
								<Divider sx={{ mt: '8px', mb: '8px' }} key={'second'} />,
								<MenuItemIcon
									key={'second'}
									onClick={() => {
										dispatch(
											deleteUserCriteriaDealflow(criteriaListEditId, () => {
												dispatch(closeModal())
											})
										)
									}}
									icon={'faTrashAlt'}
									listItemTid={
										'dealflow.criteria_modal.dialog_actions.more_options_dropdown.delete_criteria_list'
									}
								/>
							]}
							title={
								isEditMode
									? t('dealflow.modal.title.edit_criteria_list')
									: t('dealflow.modal.title.add_criteria_list')
							}
							maxWidth={'xl'}
							fullWidth
							renderConfirmButton={() => {
								return (
									<FormSpy subscription={{ invalid: true, errors: true }}>
										{({ invalid, errors }) => (
											<Tooltip
												title={
													Object.keys(errors).length > 0 && errors.criteriaListNameTooltipTid
														? errors.criteriaListNameTooltipTid
														: Object.keys(errors).length > 0 &&
														  errors.noCriterionChoosenTooltipTid
														? errors.noCriterionChoosenTooltipTid
														: ''
												}
												hideTooltip={!invalid}>
												<Button
													onClick={() => {
														handleSubmit()
														dispatch(closeModal())
													}}
													color='positive'
													disabled={invalid}>
													<FormattedMessage
														id={
															'dealflow.criteria_modal.dialog_actions.more_options_dropdown.save_criteria_list'
														}
													/>
												</Button>
											</Tooltip>
										)}
									</FormSpy>
								)
							}}
							closeButtonTid={'dealflow.criteria_modal.cancel_button'}>
							<Grid container spacing={2}>
								<Grid item md={12}>
									<Card variant='solitude' borderradius={'true'}>
										<CardContent>
											<TextField
												fullWidth
												name={'criteriaListName'}
												label={t(
													'dealflow.criteria_modal.criteria.input_field.criteria_list_name'
												)}
											/>
										</CardContent>
									</Card>
								</Grid>

								<DealflowCriteria data={{}} />
							</Grid>
						</MUIDialog>
					</form>
				)
			}}
		/>
	)
}
