import React, { PureComponent } from 'react'
import { bool, string, func, oneOf } from 'prop-types'
import styled, { css } from 'styled-components'
import DropdownItem from './dropdown-item'
import Text from '../text/text'
import Checkbox from '../checkbox/checkbox'

const StyledWrapper = styled.div`
	display: flex;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

const StyledIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
`

const StyledTextWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

class DropdownIconItem extends PureComponent {
	render = () => {
		const { onChange, disabled, tid, tidValues, checked } = this.props
		const dropdownItemProps = {
			noHorPadding: true,
			onClick: onChange,
			disabled
		}

		return (
			<DropdownItem {...dropdownItemProps}>
				<StyledWrapper>
					<StyledIconWrapper>
						<Checkbox checked={checked} disabled={disabled} mode='modern-big' />
					</StyledIconWrapper>
					<StyledTextWrapper>
						<Text tid={tid} values={tidValues} singleLine />
					</StyledTextWrapper>
				</StyledWrapper>
			</DropdownItem>
		)
	}
}

DropdownIconItem.defaultProps = {
	disabled: false
}

DropdownIconItem.propTypes = {
	disabled: bool,
	tid: string,
	checked: bool,
	onChange: func
}

export default DropdownIconItem
