import React, { Component } from 'react'
import { connect } from 'react-redux'
import history from '../../interfaces/history'
import { notifyReturnUrl } from '../../actions/notify.actions'
export default function (ComposedComponent) {
	class Authentication extends Component {
		componentDidMount() {
			if (!this.props.authenticated) {
				this.props.notifyReturnUrl(`${location.pathname}${location.search}`)
				this.props.history.push('/signin')
			}
		}

		componentDidUpdate() {
			if (!this.props.authenticated) {
				this.props.notifyReturnUrl(`${location.pathname}${location.search}`)
				this.props.history.push('/signin')
			}
		}

		render() {
			return <ComposedComponent {...this.props} />
		}
	}

	function mapStateToProps(state) {
		return {
			history: history,
			authenticated: state.credentials.get('authenticated')
		}
	}

	const mapDispatchToProps = {
		notifyReturnUrl
	}

	return connect(mapStateToProps, mapDispatchToProps)(Authentication)
}
