import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Grid from 'styled-components-grid'
import { func, string } from 'prop-types'
import { map, list } from 'react-immutable-proptypes'
import { Padding } from 'styled-components-spacing'
import { MEETINGS_ITEM_TYPES } from '../../../../constants/meetings'
import ScrollView from '../../../shared/layout/scroll-view/scroll-view'
import Label from '../../../shared/label/label'
import Textarea from '../../../shared/input/textarea'
import Input from '../../../shared/input/input'
import { Select, SelectCreatable } from '../../../shared/select'
import { StaticPanelImage } from '../../../shared/content-placeholders'
import Text from '../../../shared/text/text'
import ToggleSwitch from '../../../shared/toggle-switch/toggle-switch'
import InlineLabel from '../../../shared/label/inline-label'

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
`

const StyledLeftSide = styled.div`
	flex: 2;
`

const StyledRightSide = styled.div`
	flex: 1;
	border-left: 1px solid ${(props) => props.theme.colors.solitudeDark};
`

export default class MeetingTemplatesDetails extends PureComponent {
	static propTypes = {
		agendaListRenderer: func,
		onChange: func,
		template: map,
		itemId: string,
		internalTypeTid: string,
		errors: map,
		durationComponentRenderer: func
	}

	onChangeItem = (fieldName, value) => {
		const { onChangeItem } = this.props

		onChangeItem(fieldName, value, true)
	}

	render = () => {
		const {
			onChange,
			template,
			agendaListRenderer,
			templateItem,
			internalTypeTid,
			errors,
			durationComponentRenderer
		} = this.props

		return (
			<StyledWrapper>
				<StyledLeftSide>
					<ScrollView alwaysShow={true} noLeftMargin noRightMargin autoHeightMin={500}>
						<Grid>
							<Grid.Unit size={1}>
								<Padding right={6} top={5} bottom={3}>
									<Label tid='meeting.templates.details.name.placeholder'>
										<Input
											fieldName='name'
											onChange={onChange}
											value={template ? template.get('name', '') : ''}
											placeholderTid='meeting.templates.details.name.placeholder'
											hasError={errors && errors.get('name')}
										/>
									</Label>
								</Padding>
							</Grid.Unit>
							<Grid.Unit size={1}>
								<Padding right={6} top={3} bottom={3}>
									<InlineLabel tid='meeting.templates.details.label.include_agenda_in_mail'>
										<ToggleSwitch
											checked={template && template.get('includeAgendaInMail')}
											onChange={onChange}
											fieldName='includeAgendaInMail'
										/>
									</InlineLabel>
								</Padding>
							</Grid.Unit>
							<Grid.Unit size={1}>
								<Padding right={6} bottom={5} top={3}>
									<Label tid='meeting.templates.details.topics.label'>
										{agendaListRenderer && agendaListRenderer()}
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>
					</ScrollView>
				</StyledLeftSide>

				<StyledRightSide>
					{templateItem && (
						<ScrollView autoHide showOnHover noLeftMargin noRightMargin>
							<Grid>
								<Grid.Unit size={1}>
									<Padding left={6} top={5} bottom={3}>
										<Label tid='meeting.templates.details.proposal.label'>
											<Textarea
												fieldName='proposal'
												placeholderTid='meeting.template.details.proposal.placeholder'
												onChange={this.onChangeItem}
												value={templateItem ? templateItem.get('proposal', '') : ''}
											/>
										</Label>
									</Padding>
								</Grid.Unit>
								<Grid.Unit size={1}>
									<Padding left={6} bottom={3} top={3}>
										<Label tid='meeting.template.details.item_type.label'>
											{internalTypeTid ? (
												<Input disabled={true} valueTid={internalTypeTid} />
											) : (
												<Select
													fieldName='itemType'
													onChange={this.onChangeItem}
													options={MEETINGS_ITEM_TYPES}
													labelIsTid
													placeholderTid='generic.select'
													value={templateItem && templateItem.get('itemType')}
												/>
											)}
										</Label>
									</Padding>
								</Grid.Unit>
								{durationComponentRenderer && (
									<Grid.Unit size={1}>
										<Padding left={6} bottom={5} top={3}>
											<Label tid='meetings.agenda.form.duration'>{durationComponentRenderer()}</Label>
										</Padding>
									</Grid.Unit>
								)}
							</Grid>
						</ScrollView>
					)}
				</StyledRightSide>
			</StyledWrapper>
		)
	}
}
