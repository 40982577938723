import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { AgGridReact } from 'ag-grid-react'
import Cookies from 'universal-cookie'
import debounce from 'lodash/debounce'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey(
	'CompanyName=Invono AB,LicensedApplication=Invono,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-034809,SupportServicesEnd=11_January_2024_[v2]_MTcwNDkzMTIwMDAwMA==f77edecfc6c31a0a1a65c853e218f1f2'
)

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'

import NumericEditor from './editors/numeric.Editor'
import { array, bool, node, func, object, string, oneOfType, number, oneOf } from 'prop-types'
import getTranslations from './locale'
import { StyledAgGridPanelTemplate, StyledAgGridTemplate } from './ag-grid.template'
import GridModal from '../../shares/ag-grid/grid-modal'
import { useDispatch } from 'react-redux'
import { closeModal } from '../../../actions/modals.actions'
import { GRID_MODE_PANEL } from '/shared/constants'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

const USABLE_EDITORS = {
	numericEditor: NumericEditor
}

const StyledGridPanelWrapper = styled.div`
	${StyledAgGridPanelTemplate};
`

const StyledGridWrapper = styled.div`
	${StyledAgGridTemplate};
`

const AgGrid = ({
	rowData,
	children,
	editors,
	columnDefs,
	customFrameworkComponents,
	rowDragEnabled,
	onRowDragEnd,
	detailCellRendererParams,
	onGridReady,
	getRowNodeId,
	dropdownManageTid,
	gridOptions,
	stopEditingWhenCellsLoseFocus,
	isGridModalOpen,
	cellValueUpdated,
	rightHeaderComponent,
	isUploadingExcel,
	masterDetail,
	mode,
	modalLeftHeader,
	enableCharts,
	onFirstDataRendered,
	getChartToolbarItems,
	notSortable,
	ref,
	gridRef,
	floatingFilter,
	suppressColumnVirtualisation,
	rowsPerPage,
	doNotIncludeExcelExport,
	columnTypes,
	id,
	components,
	rowClassRules,
	rowSelection,
	onRowSelected,
	onRowDataUpdated,
	onRowDataChanged,
	forcedHeight,
	isRowSelectable,
	hideColumnMenu,
	suppressContextMenu,
	suppressRowClickSelection,
	editType,
	onSelectionChanged,
	getRowId,
	getRowClass,
	getRowStyle,
	noPagination,
	rowHeight,
	noEvenRowBackground,
	overflowAuto,
	getRowHeight,
	suppressMovableColumns,
	onDragStopped,
	onColumnMoved,
	groupDisplayType,
	isGroupOpenByDefault,
	groupRowsSticky,
	groupRowRenderer,
	groupRowRendererParams,
	rowClass,
	groupedGrid,
	localCloseModal,
	ordinaryTextColor,
	onFilterChanged,
	onGridColumnsChanged,
	enableCopyCellText
}) => {
	const [translation, setTranslation] = useState(null)
	const [shouldResizeColumn, setShouldResizeColumn] = useState(false) // needed to prevent the saved width from being overwritten by flex: 1
	const [calculatedHeight, setCalculatedHeight] = useState('1px')

	const editorComponents = editors
		? editors.reduce((editors, editor) => {
				editors[editor] = USABLE_EDITORS[editor]
				return editors
		  }, {})
		: {}
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const onCloseGridModal = () => {
		if (localCloseModal) {
			localCloseModal()
		} else dispatch(closeModal())
	}

	useEffect(() => {
		setTranslation(getTranslations(t))
		return () => setTranslation(getTranslations(t))
	}, [])

	useEffect(() => {
		document.addEventListener('mousedown', ({ target }) => {
			if (target.className == 'ag-header-cell-resize') {
				setShouldResizeColumn(true)
			}
		})
		return () => {
			document.removeEventListener('mousedown', ({ target }) => {
				if (target.className == 'ag-header-cell-resize') {
					setShouldResizeColumn(true)
				}
			})
		}
	}, [])

	const getDefaultColDef = useMemo(() => {
		return {
			resizable: true,
			filter: 'agMultiColumnFilter',
			floatingFilter: floatingFilter,
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter'
					}
				]
			},
			menuTabs: hideColumnMenu ? [] : ['columnsMenuTab'],
			columnsMenuParams: {
				suppressColumnFilter: true,
				suppressColumnSelectAll: true,
				suppressColumnExpandAll: true
			},
			flex: 1,
			sortable: notSortable ? false : true,
			minWidth: 150
		}
	}, [floatingFilter])

	const getContextMenuItems = useCallback(
		(params) => {
			if (params?.value?.suppressContextMenu) {
				return []
			}

			if (mode === GRID_MODE_PANEL && !doNotIncludeExcelExport) {
				return ['copy', 'paste', 'excelExport']
			}

			return ['copy', 'paste']
		},
		[mode]
	)

	const doDebounce = debounce((e) => {
		const gridWidth = gridRef.current?.api?.alignedGridsService?.gridOptionsWrapper?.eGridDiv?.clientWidth
		if (e.column.actualWidth < gridWidth - 50) {
			//needed if maxColumnWidth should not have changed on resize (edgecase)
			new Cookies().set(id, { columnWidth: e.column.actualWidth })
		}
	}, 1000)

	const saveColumnWidth = (e) => {
		if (e.column && shouldResizeColumn) {
			doDebounce(e)
		}
	}
	//	Make sure the buttoncolumns stays on the first row when sorting
	/* const postSortRows = ({ nodes }) => {
		let rowNodes = nodes
		let nextInsertPos = 0

		if (nodes.length >= 2) {
			nodes.forEach((node, i) => {
				if (node.data.type) {
					rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0])
					nextInsertPos++
				}
			})
		}
	} */

	const calculateContainerHeight = (ref) => {
		if (!forcedHeight && MutationObserver) {
			let extraheight = 65
			setTimeout(() => {
				const targetEl = ref?.querySelector('.ag-body-viewport > *')
				const agCenterColsViewport = ref?.querySelector('.ag-body-viewport .ag-center-cols-viewport')
				const widthOfCenterColsViewport = agCenterColsViewport?.offsetWidth
				const widthOfCenterColsContainer = ref?.querySelector(
					'.ag-body-viewport .ag-center-cols-container'
				)?.offsetWidth

				// 100% + 15px gör att sista raden i tabellen inte ska strula enligt https://invono.atlassian.net/browse/IPS-11664?focusedCommentId=16806
				if (agCenterColsViewport) {
					agCenterColsViewport.style.height = 'calc(100% + 15px)'
				}

				if (widthOfCenterColsContainer > widthOfCenterColsViewport) {
					extraheight += 15
				}

				const config = { attributes: true }
				const callback = (mutationList) => {
					for (const mutation of mutationList) {
						if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
							setCalculatedHeight(parseInt(mutation.target.style.height) + extraheight)
						}
					}
				}
				const mutationObserver = new MutationObserver(callback)

				if (targetEl) {
					mutationObserver.observe(targetEl, config)
					setCalculatedHeight(parseInt(targetEl.style.height) + extraheight)
				}
			}, 100)
		}
	}

	const _gridOptions = {
		...gridOptions,
		...(enableCopyCellText && { enableCellTextSelection: true, ensureDomOrder: true })
	}

	const renderAgGridReact = (requirePagination) => {
		return (
			<AgGridReact
				components={components}
				//reactUi='true'
				rowData={rowData}
				onColumnResized={saveColumnWidth}
				suppressContextMenu={suppressContextMenu}
				// postSortRows={postSortRows}
				columnDefs={columnDefs}
				defaultColDef={getDefaultColDef}
				pagination={noPagination ? false : requirePagination}
				paginationPageSize={rowsPerPage}
				getContextMenuItems={getContextMenuItems}
				onGridReady={onGridReady}
				enableCharts={enableCharts}
				onFirstDataRendered={onFirstDataRendered}
				getChartToolbarItems={getChartToolbarItems}
				localeText={translation}
				ref={ref || gridRef}
				suppressMovableColumns={suppressMovableColumns === false ? suppressMovableColumns : true}
				columnTypes={columnTypes}
				frameworkComponents={{ ...editorComponents, ...customFrameworkComponents }}
				suppressRowTransform
				rowSelection={rowSelection}
				rowDragManaged={rowDragEnabled}
				animateRows={rowDragEnabled}
				onRowDragEnd={onRowDragEnd}
				masterDetail={masterDetail}
				detailCellRendererParams={detailCellRendererParams}
				getRowNodeId={getRowNodeId}
				gridOptions={_gridOptions}
				stopEditingWhenCellsLoseFocus={stopEditingWhenCellsLoseFocus}
				tooltipShowDelay={0}
				tooltipHideDelay={20000}
				rowClassRules={rowClassRules}
				isRowSelectable={isRowSelectable}
				suppressRowClickSelection={suppressRowClickSelection}
				editType={editType}
				onSelectionChanged={onSelectionChanged}
				getRowId={getRowId}
				getRowClass={getRowClass}
				getRowStyle={getRowStyle}
				rowHeight={rowHeight}
				getRowHeight={getRowHeight}
				onRowDataChanged={onRowDataChanged}
				suppressDragLeaveHidesColumns={true}
				onDragStopped={onDragStopped}
				onColumnMoved={onColumnMoved}
				groupDisplayType={groupDisplayType}
				isGroupOpenByDefault={isGroupOpenByDefault}
				groupRowsSticky={groupRowsSticky}
				groupRowRenderer={groupRowRenderer}
				groupRowRendererParams={groupRowRendererParams}
				rowClass={rowClass}
				onRowSelected={onRowSelected}
				onFilterChanged={onFilterChanged}
				onGridColumnsChanged={onGridColumnsChanged}>
				{children && children}
			</AgGridReact>
		)
	}

	if (mode === GRID_MODE_PANEL) {
		let height
		let requirePagination = true
		if (rowData) {
			if (forcedHeight) {
				height = forcedHeight
				requirePagination = rowData.length > rowsPerPage
			} else {
				if (rowData) {
					const floatingFilterheight = floatingFilter ? 56 : 0
					requirePagination = rowData.length > rowsPerPage
					const paginationHeight = requirePagination ? 56 : 0
					height = calculatedHeight + floatingFilterheight + paginationHeight
				}
			}
			//	Make sure the buttoncolumns stays on the first row when sorting
			const postSortRows = ({ nodes }) => {
				let rowNodes = nodes
				let nextInsertPos = 0

				if (nodes.length >= 2) {
					nodes.forEach((node, i) => {
						if (node.data.type) {
							rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0])
							nextInsertPos++
						}
					})
				}
			}

			const newHeight = noPagination ? '100%' : height
			return (
				<StyledGridPanelWrapper
					noEvenRowBackground={noEvenRowBackground}
					overflowAuto={overflowAuto}
					ordinaryTextColor={ordinaryTextColor}>
					<div
						className={clsx('ag-theme-material', { 'grouped-grid': groupedGrid })}
						style={{ height: newHeight, width: '100%' }}
						ref={calculateContainerHeight}>
						{renderAgGridReact(requirePagination)}
					</div>
				</StyledGridPanelWrapper>
			)
		}
	}

	//todo this one does not save first columnwidth yet probably need prop.id
	return (
		<GridModal
			onCancel={onCloseGridModal}
			isOpen={isGridModalOpen}
			cellValueUpdated={cellValueUpdated}
			rightHeaderComponent={rightHeaderComponent}
			isUploadingExcel={isUploadingExcel}
			leftHeader={modalLeftHeader}>
			<StyledGridWrapper expanderText={dropdownManageTid}>
				<div
					className={clsx('ag-theme-material', { 'grouped-grid': groupedGrid })}
					style={{ height: '100%', width: '100%' }}>
					{renderAgGridReact()}
				</div>
			</StyledGridWrapper>
		</GridModal>
	)
}

AgGrid.propTypes = {
	rowData: array,
	children: node,
	editors: array,
	columnDefs: oneOfType([func, array]),
	customFrameworkComponents: array,
	rowDragEnabled: bool,
	onRowDragEnd: func,
	masterDetail: bool,
	detailCellRendererParams: object,
	onGridReady: func,
	getRowNodeId: func,
	dropdownManageTid: string,
	gridOptions: object,
	gridModalIsOpen: bool,
	closeGridModal: func,
	cellValueUpdated: bool,
	rightHeaderComponent: func,
	isUploadingExcel: bool,
	rowsPerPage: number,
	isGridModalOpen: bool,
	mode: oneOfType([func, string]),
	modalLeftHeader: object,
	hasFiltering: bool,
	rowClassRules: oneOfType([object, bool]),
	suppressColumnVirtualisation: bool,
	rowSelection: oneOf(['single', 'multiple']),
	onRowSelected: func,
	onRowDataUpdated: func,
	onRowDataChanged: func,
	forcedHeight: string,
	isRowSelectable: func,
	hideColumnMenu: bool,
	suppressRowClickSelection: bool,
	editType: oneOf(['fullRow', undefined]),
	onSelectionChanged: func,
	getRowId: func,
	getRowClass: func,
	getRowStyle: func,
	noPagination: bool,
	rowHeight: number,
	noEvenRowBackground: bool,
	overflowAuto: bool,
	getRowHeight: func,
	suppressMovableColumns: bool,
	onDragStopped: func,
	onColumnMoved: func,
	groupDisplayType: string,
	isGroupOpenByDefault: func,
	groupRowsSticky: bool,
	groupRowRenderer: string,
	groupRowRendererParams: object,
	suppressContextMenu: bool,
	rowClass: oneOfType([string, array]),
	groupedGrid: bool,
	onGridColumnsChanged: func,
	enableCopyCellText: bool
}

AgGrid.defaultProps = {
	rowsPerPage: 15,
	rowSelection: 'single',
	groupedGrid: false,
	mode: GRID_MODE_PANEL
}

export default AgGrid
