import React, { PureComponent } from 'react'
import { func, bool } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import styled from 'styled-components'
import Modal from '../../shared/modal/modal'
import Button from '../../shared/button/button'
import Text from '../../shared/text/text'
import { TransparentButton } from '../../shared/button-v2'

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
`

const StyledButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${(props) => props.theme.spacing[3]};

	> button {
		margin-left: 0;

		:first-child {
			margin-bottom: ${(props) => props.theme.spacing[4]};
		}
	}
`

export default class EndMeetingModal extends PureComponent {
	static propTypes = {
		onEndMeetingGoToProtocol: func,
		onEndMeetingGoToMeetingList: func,
		onCancel: func,
		agendaItems: immutablePropTypes.list,
		goToProtocolBtnIsLoading: bool,
		goToMeetingsListBtnIsLoading: bool
	}

	isButtonsDisabled = () => {
		const { goToProtocolBtnIsLoading, goToMeetingsListBtnIsLoading } = this.props

		return goToProtocolBtnIsLoading === true || goToMeetingsListBtnIsLoading === true
	}

	renderFooter = () => {
		const { onCancel } = this.props
		const isDisabled = this.isButtonsDisabled()

		return <TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={onCancel} disabled={isDisabled} />
	}

	renderNavButtons = () => {
		const {
			onEndMeetingGoToProtocol,
			onEndMeetingGoToMeetingList,
			goToProtocolBtnIsLoading,
			goToMeetingsListBtnIsLoading
		} = this.props
		const isDisabled = this.isButtonsDisabled()

		return (
			<StyledButtonWrapper>
				<Button
					mode='primary'
					tid='meetings.end_meeting_modal.button.end_meeting_go_to_protocol'
					onClick={onEndMeetingGoToProtocol}
					disabled={isDisabled}
					isLoading={goToProtocolBtnIsLoading}
				/>

				<Button
					tid='meetings.end_meeting_modal.button.end_meeting_go_to_meeting_list'
					onClick={onEndMeetingGoToMeetingList}
					disabled={isDisabled}
					isLoading={goToMeetingsListBtnIsLoading}
				/>
			</StyledButtonWrapper>
		)
	}

	renderStatusInformation = () => {
		const { agendaItems } = this.props

		if (!agendaItems || agendaItems.size === 0) {
			return null
		}

		return <Text tag='p' tid='meetings.end_meeting_modal.modal.status_info' values={{ num: agendaItems.size }} />
	}

	render = () => {
		return (
			<Modal
				title='meetings.end_meeting_modal.modal.title'
				isOpen={true}
				hSize='md'
				vSize={50}
				footerComponent={this.renderFooter()}>
				<StyledWrapper>
					{this.renderStatusInformation()}
					{this.renderNavButtons()}
				</StyledWrapper>
			</Modal>
		)
	}
}
