import { fromJS } from 'immutable'
import req from '../../modules/request.module'
import { INVESTORS_LIST } from './types'

export function listInvestorsArrayPublic(investmentsIdsArray, companyId) {
	return function (dispatch) {
		return req
			.post(`/shares/public/company/investors/array?companyId=${companyId}`, {
				investments: investmentsIdsArray.toJS()
			})
			.then((response) => {
				dispatch({ type: INVESTORS_LIST, payload: fromJS(response.data) })
			})
			.catch((err) => {
				console.log(err)
			})
	}
}
