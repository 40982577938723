import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { bool, oneOf, string } from 'prop-types'
import bankIdImg from '../../../assets/images/bankid-logo.png'
import bankIdImgColor from '../../../assets/images/bankid.svg'
import Text from '../text/text'

const StyledBankId = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: ${(props) => (props.noMarginTop ? 0 : props.theme.spacing[5])} !important;

	${(props) =>
		props.bFill &&
		css`
			width: 100%;
			height: 100%;
		`}

	>* {
		margin: ${(props) => props.theme.spacing[2]} 0;
	}
`

const StyledImage = styled.img`
	width: ${(props) => props.size};
`

export default class BankId extends PureComponent {
	static propTypes = {
		bankIdTransaltionId: string,
		size: string,
		fill: bool,
		coloredLogo: bool,
		forceShow: bool,
		mode: oneOf(['dark']),
		noMarginTop: bool
	}

	static defaultProps = {
		size: '50px',
		fill: true,
		coloredLogo: false,
		forceShow: false,
		noMarginTop: false
	}

	render = () => {
		const { bankIdTransaltionId, size, mode, fill, coloredLogo, forceShow, noMarginTop } = this.props
		let image = bankIdImg

		if (coloredLogo) {
			image = bankIdImgColor
		}

		if (!bankIdTransaltionId && !forceShow) {
			return null
		}

		return (
			<StyledBankId bFill={fill} noMarginTop={noMarginTop}>
				<StyledImage src={image} alt='bankid logo' size={size} />
				{!bankIdTransaltionId && (
					<Text color={mode === 'dark' ? 'midGrey' : 'white'} asHtml>
						&nbsp;
					</Text>
				)}
				{bankIdTransaltionId && <Text tid={bankIdTransaltionId} color={mode === 'dark' ? 'midGrey' : 'white'} align='center' />}
			</StyledBankId>
		)
	}
}
