import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { func, object } from 'prop-types'
import { Padding } from 'styled-components-spacing'
import Grid from 'styled-components-grid'
import FormatNumberInput from '../format-number-input/format-number-input'
import Input from '../input/input'
import Label from '../label/label'
import MonthSelect from '../month-select/month-select'
import YearSelect from '../year-select/year-select'

const CreditCardInput = ({ creditCard, disabled, onChange, errors, i18n }) => {
	return (
		<Grid>
			<Grid.Unit size={1}>
				<Padding all={3}>
					<Label tid='subscriptions.card_number'>
						<FormatNumberInput
							value={creditCard ? creditCard.get('number') : ''}
							placeholder={i18n.messages['subscriptions.card_number']}
							format='#### #### #### ####'
							fieldName='number'
							onChange={onChange}
							disabled={disabled}
							error={errors && errors.get('number')}
						/>
					</Label>
				</Padding>
			</Grid.Unit>

			<Grid.Unit size={1 / 3}>
				<Padding all={3}>
					<Label tid='subscriptions.expiration_date_month'>
						<MonthSelect
							placeholderTid='subscriptions.card.placeholder.month'
							value={creditCard ? creditCard.get('exp_month') : null}
							fieldName='exp_month'
							onChange={onChange}
							disabled={disabled}
							hasError={errors && errors.get('exp_month')}
						/>
					</Label>
				</Padding>
			</Grid.Unit>

			<Grid.Unit size={1 / 3}>
				<Padding all={3}>
					<Label tid='subscriptions.expiration_date_year'>
						<YearSelect
							placeholderTid='subscriptions.card.placeholder.year'
							value={creditCard ? creditCard.get('exp_year') : null}
							fieldName='exp_year'
							onChange={onChange}
							disabled={disabled}
							hasError={errors && errors.get('exp_year')}
						/>
					</Label>
				</Padding>
			</Grid.Unit>

			<Grid.Unit size={1 / 3}>
				<Padding all={3}>
					<Label tid='subscriptions.cvc'>
						<Input
							type='text'
							placeholderTid='subscriptions.cvc'
							value={creditCard ? creditCard.get('cvc') : ''}
							fieldName='cvc'
							onChange={onChange}
							disabled={disabled}
							hasError={errors && errors.get('cvc')}
						/>
					</Label>
				</Padding>
			</Grid.Unit>

			<Grid.Unit size={1}>
				<Padding all={3}>
					<Label tid='subscriptions.cardholder_name'>
						<Input
							type='text'
							placeholderTid='subscriptions.cardholder_name'
							value={creditCard ? creditCard.get('name') : ''}
							fieldName='name'
							onChange={onChange}
							disabled={disabled}
							hasError={errors && errors.get('name')}
						/>
					</Label>
				</Padding>
			</Grid.Unit>
		</Grid>
	)
}

CreditCardInput.propTypes = {
	creditCard: ImmutablePropTypes.map,
	onChange: func,
	errors: ImmutablePropTypes.map,
	i18n: object
}

export default CreditCardInput
