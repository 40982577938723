import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { bool, func, string } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import moment from '../../../../modules/moment.module'
import ProtocolTable from '../protocol-table/protocol-table'
import ProtocolTableRow from '../protocol-table/protocol-table-row'
import AttendeesTable from '../attendees-table/attendees-table'

export default class ProtocolGeneral extends PureComponent {
	static propTypes = {
		startDate: string,
		endDate: string,
		reference: string,
		name: string,
		objective: string,
		template: string,
		chairman: string,
		secretary: string,
		attendees: immutablePropTypes.map,
		location: string,
		renderRoleDisplay: func
	}

	renderAttendeesList = () => {
		const { attendees, renderRoleDisplay } = this.props

		return <AttendeesTable attendees={attendees} renderRoleDisplay={renderRoleDisplay} />
	}

	render = () => {
		const {
			startDate,
			endDate,
			reference,
			name,
			objective,
			template,
			chairman,
			secretary,
			attendees,
			location
		} = this.props
		const meetingStartTime = startDate && moment(startDate)
		const meetingEndTime = endDate && moment(endDate)
		let date = ''

		if (meetingStartTime && meetingStartTime.isValid()) {
			date += `${meetingStartTime.format('LL')} ${meetingStartTime.format('LT')}`
		}

		if (meetingEndTime && meetingEndTime.isValid()) {
			date += ` - ${meetingEndTime.format('LT')}`
		}

		return (
			<ProtocolTable>
				<ProtocolTableRow labelTid='meetings.protocol.general.name' content={name} />
				<ProtocolTableRow labelTid='meetings.protocol.general.objective' content={objective} />
				<ProtocolTableRow labelTid='meetings.protocol.general.meeting_type' content={template} />
				<ProtocolTableRow labelTid='meetings.protocol.general.chairman' content={chairman} />
				<ProtocolTableRow labelTid='meetings.protocol.general.secretary' content={secretary} />
				<ProtocolTableRow labelTid='meetings.protocol.general.attendees' content={this.renderAttendeesList()} />
				<ProtocolTableRow labelTid='meetings.protocol.general.location' content={location} />
			</ProtocolTable>
		)
	}
}
