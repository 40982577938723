import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool } from 'prop-types'
import { Map } from 'immutable'
import { saveMeeting } from '../../../actions/meetings.actions'
import PhysicalLocationPanel from '../../../dumb-components/meetings/location-panels/physical'
import GoogleMapsContainer from '../../shared/google-maps.container'

class PhysicalContainer extends Component {
	static propTypes = {
		marginBottom: bool,
		withoutPanel: bool,
		readOnly: bool // Overrides old container logic for readOnly IF SPECIFIED
	}

	static defaultProps = {
		marginBottom: false
	}

	onChange = (fieldName, value) => {
		const { saveMeeting } = this.props
		let { meeting } = this.props

		meeting = meeting.set(fieldName, value)

		saveMeeting(meeting)
	}

	getIsExpanded = () => {
		const { meeting } = this.props
		return (meeting.getIn(['location', 'label'], '') || '').trim().length > 0 ? true : false
	}

	renderGoogleMap = () => {
		const { meeting } = this.props
		const lat = meeting.getIn(['location', 'location', 'lat'])
		const lng = meeting.getIn(['location', 'location', 'lng'])

		return <GoogleMapsContainer lat={lat} lng={lng} />
	}

	render = () => {
		const { meeting, marginBottom, withoutPanel } = this.props
		let { readOnly } = this.props

		// If not readOnly prop was sent in (that's why undefined check),
		// container then uses the legacy logic. This was needed
		// as there might be different logic in standard/smart meeting
		if (readOnly === undefined) {
			readOnly = meeting.getIn(['computedValues', 'isSimpleMode']) || meeting.get('isDeleted')
		}

		return (
			<PhysicalLocationPanel
				locationLabel={meeting.getIn(['location', 'label'])}
				isExpanded={this.getIsExpanded()}
				onChange={this.onChange}
				readOnly={readOnly}
				renderGoogleMap={this.renderGoogleMap}
				marginBottom={marginBottom}
				withoutPanel={withoutPanel}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get('meeting', Map()) || Map()
	}
}

const mapActionsToProps = {
	saveMeeting
}

export default connect(mapStoreToProps, mapActionsToProps)(PhysicalContainer)
