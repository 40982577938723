import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func, oneOf } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import documentsHelper from '../../../components/helpers/documents.helper'

import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'

import { deleteAttachedDocument, deleteAttachedDocumentPublic } from '../../../actions/attachments.actions'
import { openModal } from '../../../actions/modals.actions'

import { OBJ_TYPE_MEETING_PROTOCOL } from '/shared/constants'
import { CONFIRM_DELETE_PROTOCOL } from '../../../constants/modals'

const DELETE_PUBLISHED_PROTOCOL_TOOLTIP_STATES = {
	allHaveSigned: {
		tid: 'meetings.standard.protocol.delete_original.tooltip.delete_signed_first'
	},
	protocolPublished: {
		tid: 'meetings.standard.protocol.delete_original.tooltip.delete_published_first'
	}
}

class DeleteFileItemContainer extends Component {
	static propTypes = {
		document: map.isRequired,
		onClick: func.isRequired,
		onDelete: func,
		mode: oneOf(['PUBLIC-UNSAFE']),
		objType: string.isRequired,
		objId: string.isRequired
	}

	deleteFile = () => {
		const { objType, objId, mode, document, onDelete, onClick, openModal } = this.props

		onClick && onClick()

		if (objType === OBJ_TYPE_MEETING_PROTOCOL) {
			openModal(
				CONFIRM_DELETE_PROTOCOL,
				{objType, objId, mode, document, onDelete}
			)
		} else {
			this.doDeleteFile()
		}
	}

	doDeleteFile = () => {
		const {
			document,
			deleteAttachedDocument,
			deleteAttachedDocumentPublic,
			onDelete,
			mode,
			objType,
			objId,
			companyId
		} = this.props
		const documentId = document.get('id')

		if (mode === 'PUBLIC-UNSAFE') {
			deleteAttachedDocumentPublic(documentId, objType, objId, companyId, () => {
				onDelete && onDelete(document)
			})
		} else {
			deleteAttachedDocument(documentId, objType, objId, () => {
				onDelete && onDelete(document)
			})
		}
	}

	getDropdownItemText = () => {
		const { document } = this.props
		const wasAddedByLinking = !Boolean(document.get('hideFromDocumentsSection'))

		return wasAddedByLinking ? 'delete_file_unlink' : 'delete_file'
	}

	renderDropdownItem = () => {
		const { document, protocolPublished, objType } = this.props
		const allHaveSigned = documentsHelper.getDocumentAllHaveSigned(document)
		let activeState

		if (allHaveSigned) {
			activeState = 'allHaveSigned'
		} else if (protocolPublished) {
			activeState = 'protocolPublished'
		}

		if (objType === OBJ_TYPE_MEETING_PROTOCOL) {
			return (
				<Tooltip states={DELETE_PUBLISHED_PROTOCOL_TOOLTIP_STATES} activeState={activeState} delayShow='instant'>
					<DropdownIconItem
						icon='faTrashAlt'
						tid='delete_file'
						onClick={this.deleteFile}
						disabled={allHaveSigned || protocolPublished}
					/>
				</Tooltip>
			)
		}

		return <DropdownIconItem icon='faTrashAlt' tid={this.getDropdownItemText()} onClick={this.deleteFile} />
	}

	render() {
		return this.renderDropdownItem()
	}
}

const mapStoreToProps = (store) => {
	return {
		companyId: store.company.company?.id,
		protocolPublished: store.meetings.getIn(['meeting', 'computedValues', 'protocolPublished'])
	}
}

const mapActionsToProps = {
	deleteAttachedDocument,
	deleteAttachedDocumentPublic,
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(DeleteFileItemContainer)
