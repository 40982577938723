import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func, oneOf } from 'prop-types'
import { listGroups } from '../../../actions/groups.actions'
import MoveItemModal from '../../../dumb-components/shared/move-item-modal/move-item-modal'
import ItemTreeContainer from '../../shared/item-tree.container'
import { updateMeetingLocal, transferMeeting, fetchMeeting, listMeetings } from '../../../actions/meetings.actions'
import { addInfoNotification } from '../../../actions/notify.actions'

import { closeModal } from '../../../actions/modals.actions'
import { MEETING_MOVE_MEETING_MODAL } from '../../../constants/modals'

class MoveMeetingModalContainer extends Component {
	state = {
		selectedGroup: null
	}

	static propTypes = {
		meetingMode: oneOf(['new', 'edit'])
	}

	static defaultProps = {
		meetingMode: 'edit'
	}

	componentDidMount = () => {
		const { groups, listGroups, meeting } = this.props

		if (!groups || groups.size === 0) {
			listGroups()
		}
	}

	onMoveMeeting = () => {
		const {
			selectedGroupId,
			updateMeetingLocal,
			transferMeeting,
			fetchMeeting,
			meetingMode,
			listMeetings,
			addInfoNotification
		} = this.props
		const { selectedGroup } = this.state
		let { meeting } = this.props

		const meetingsToTransfer = [meeting.get('id')]
		const moveToGroupId = (selectedGroup && selectedGroup.get('id')) || null

		if (meetingMode === 'edit') {
			transferMeeting(meetingsToTransfer, moveToGroupId, selectedGroupId, () => {
				listMeetings(selectedGroupId, () => {
					meeting = meeting.set('groupId', moveToGroupId)
					fetchMeeting(meeting.get('id'))
					addInfoNotification({ tid: 'meeting.move_meeting.notify.meeting_moved' })
				})
			})
		} else {
			meeting = meeting.set('groupId', moveToGroupId)
			updateMeetingLocal(meeting, () => {
				addInfoNotification({ tid: 'meeting.move_meeting.notify.meeting_moved' })
			})
		}

		this.closeModal()
	}

	onGroupSelected = (group) => {
		this.setState({ selectedGroup: group })
	}

	closeModal = () => {
		const { closeModal } = this.props

		this.setState({ selectedGroup: null })

		closeModal()
	}

	render = () => {
		const { isOpen, meeting, groups } = this.props
		const { selectedGroup } = this.state
		const group =
			selectedGroup || (meeting && groups && groups.find((group) => group.get('id') === meeting.get('groupId')))

		if (!isOpen) {
			return null
		}

		return (
			<MoveItemModal
				titleTid='meetings.move.meeting.modal.title'
				isOpen={true}
				onMove={this.onMoveMeeting}
				onClose={this.closeModal}
				itemTreeContainerRenderer={() => {
					return (
						<ItemTreeContainer
							items={groups}
							onItemSelected={this.onGroupSelected}
							selectedItem={group}
							rootItemTid='groups.root'
							renderHoverText={true}
							permission='ALLOW_CREATE'
							destinationBadgeTid='meetings.move_meeting_modal.desination_badge'
						/>
					)
				}}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		isOpen: store.modals.getIn(['activeModal', 'name']) === MEETING_MOVE_MEETING_MODAL,
		meeting: store.meetings.get('meeting'),
		groups: store.groups.get('groups'),
		selectedGroupId: store.groups.get('selectedGroupId'),
		i18n: store.i18n
	}
}

const mapActionsToProps = {
	updateMeetingLocal,
	transferMeeting,
	fetchMeeting,
	listGroups,
	listMeetings,
	closeModal,
	addInfoNotification
}

export default connect(mapStoreToProps, mapActionsToProps)(MoveMeetingModalContainer)
