import React, { useEffect, useState } from 'react'
import Checkbox from '../../../../mui-components/checkbox/checkbox'

function CheckboxInHeaderRenderer(params) {
	const { api, filteredOnly } = params
	const [selected, setSelected] = useState(false)
	const numOfSelectedRows = api.getSelectedRows().length
	const numOfSelectableRows = (() => {
		let counter = 0
		api.forEachNode((node) => {
			if (node.selectable) {
				counter++
			}
		})
		return counter
	})()

	useEffect(() => {
		if (api && numOfSelectableRows > 0) {
			const isAllSelected = numOfSelectedRows >= numOfSelectableRows
			setSelected(isAllSelected)
		}
	}, [numOfSelectedRows, numOfSelectableRows])

	return (
		<Checkbox
			checked={selected}
			onChange={(event) => {
				const checked = event.target.checked
				if (filteredOnly) {
					checked ? api.selectAllFiltered() : api.deselectAllFiltered()
				} else {
					checked ? api.selectAll() : api.deselectAll()
				}

				setSelected(checked)
			}}
			disableRipple
			disableTouchRipple
			sx={{ p: 0 }}
		/>
	)
}

export { CheckboxInHeaderRenderer }
