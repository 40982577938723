import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromJS, List, Map } from 'immutable'
import { func, string } from 'prop-types'
import { Padding, Margin } from 'styled-components-spacing'

import AttachmentsByIdContainer from '../../../shared/attachments-by-objid.container'
import ConfirmDeleteProtocolModalContainer from '../../protocol/modals/confirm-delete-protocol-modal.container'
import { renderMeetingAttachmentDropdown } from '../../../documents/attachments/predefined-dropdown-render-functions'

import MeetingStandardAttachments from '../../../../dumb-components/meetings/meeting-standard/meeting-standard-attachments'
import ProtocolEsigningContainer from '../../protocol-esigning/protocol-esigning.container'
import Box from '../../../../dumb-components/shared/layout/box/box'

import documentsHelper from '../../../../components/helpers/documents.helper'

import {
	OBJ_TYPE_MEETING_PROTOCOL,
	OBJ_TYPE_MEETING_ATTACHMENT,
	OBJ_TYPE_MEETING_AGENDA,
	ATTACHMENTS_DISPLAY_STATUS_SHOWN,
	ATTACHMENTS_DISPLAY_STATUS_HIDDEN,
	ATTACHMENTS_DISPLAY_STATUS_DURING_AFTER
} from '/shared/constants'
import { TAB_STANDARD_MEETING_PROTOCOL } from '../../../../constants/tabs'

import { updateDocumentLocal } from '../../../../actions/documents.actions'
import { changePublishingType } from '../../../../actions/meetings.actions'

class AttachmentsContainer extends Component {
	static propTypes = {
		onUpload: func,
		onDelete: func,
		protocolSharedAt: string
	}

	state = {
		preventChangeOfTab: false
	}

	dropdownRefs = {}

	setDropdownRef = (fileName, ref) => {
		this.dropdownRefs[fileName] = ref
	}

	onDeleteFile = (objType, document) => {
		const { onDelete, changePublishingType, meetingId, publishingType, updateDocumentLocal } = this.props

		if (objType === OBJ_TYPE_MEETING_PROTOCOL && publishingType) {
			updateDocumentLocal(null)
			changePublishingType(meetingId, null, () => {
				onDelete && onDelete(objType)
			})
		} else {
			onDelete && onDelete(objType)
		}
	}

	onUpload = (objType, { document }) => {
		const { onUpload } = this.props
		this.setState({ preventChangeOfTab: true })
		onUpload && onUpload(objType, document)
	}

	renderShareProtocolContainer = (objId) => {
		const { attachedProtocolMetadata } = this.props
		const protocolDocumentId = attachedProtocolMetadata.get('documentId')

		if (!protocolDocumentId) {
			return null
		}

		return <ProtocolEsigningContainer documentId={protocolDocumentId} nakedStyle={true} objId={objId} />
	}

	isChecked = (objType) => {
		const { hasAgenda, hasAttachments, hasProtocol } = this.props

		switch (objType) {
			case OBJ_TYPE_MEETING_AGENDA:
				return hasAgenda
			case OBJ_TYPE_MEETING_ATTACHMENT:
				return hasAttachments
			case OBJ_TYPE_MEETING_PROTOCOL:
				return hasProtocol
			default:
				return false
		}
	}

	getFileIconDotData = (document, objId) => {
		const links = document.get('links')
		const isProtocol = links.some(
			(link) => link.get('objType') === OBJ_TYPE_MEETING_PROTOCOL && link.get('objId') === objId
		)

		if (isProtocol) {
			return
		}

		const displayStatus = documentsHelper.findDisplayStatus(links, objId)
		const fileIconDotColor = displayStatus === ATTACHMENTS_DISPLAY_STATUS_SHOWN || displayStatus === ATTACHMENTS_DISPLAY_STATUS_DURING_AFTER ? 'green' : 'persianRed'
		let fileIconTooltipTid


		if (displayStatus === ATTACHMENTS_DISPLAY_STATUS_SHOWN) {
			fileIconTooltipTid = 'meetings.standard.attachments.visibility.tooltip.shown'
		} else if (displayStatus === ATTACHMENTS_DISPLAY_STATUS_HIDDEN) {
			fileIconTooltipTid = 'meetings.standard.attachments.visibility.tooltip.hidden'
		} else if (displayStatus === ATTACHMENTS_DISPLAY_STATUS_DURING_AFTER) {
			fileIconTooltipTid = 'meetings.standard.attachments.visibility.tooltip.duringAfter'
		}

		return {
			fileIconDot: true,
			fileIconDotColor,
			fileIconTooltipTid
		}
	}

	getLinkDocumentDisabledData = (objType) => {
		let tooltipTid
		let disabled = false

		if (objType === OBJ_TYPE_MEETING_AGENDA) {
			tooltipTid = 'meetings.standard.link_document.tooltip.agenda'
			disabled = true
		} else if (objType === OBJ_TYPE_MEETING_PROTOCOL) {
			tooltipTid = 'meetings.standard.link_document.tooltip.protocol'
			disabled = true
		}

		return { tooltipTid, disabled }
	}

	renderBody = (objType, renderControls) => {
		const { meetingId, meetingIsDeleted } = this.props
		const objId = `${meetingId}$${objType}`
		const isAttachment = objType === OBJ_TYPE_MEETING_ATTACHMENT
		const isProtocol = objType === OBJ_TYPE_MEETING_PROTOCOL
		let descriptionTid
		let oneFileLimitControlsTooltipTid

		if (objType === OBJ_TYPE_MEETING_AGENDA) {
			descriptionTid = 'attachments.meetings.description.add_file.agenda'
			oneFileLimitControlsTooltipTid = 'attachments.multiple.one_file_limit.controls.one_agenda.tooltip'
		} else if (isProtocol) {
			descriptionTid = 'attachments.meetings.description.add_file.protocol'
			let oneFileLimitControlsTooltipTid = 'attachments.multiple.one_file_limit.controls.one_protocol.tooltip'
		}

		const linkDocumentsDisabledData = this.getLinkDocumentDisabledData(objType)

		return (
			<Padding left={4} top={4} right={4} bottom={4}>
				<AttachmentsByIdContainer
					nakedStyle={true}
					objId={objId}
					objType={objType}
					onUpload={this.onUpload.bind(this, objType)}
					onLinkDocument={this.props.onUpload}
					onUnlinkDocument={this.props.onDelete}
					isDragDisabled={!isAttachment}
					readOnly={meetingIsDeleted}
					multiple={isAttachment}
					descriptionTid={descriptionTid}
					oneFileLimitControlsTooltipTid={oneFileLimitControlsTooltipTid}
					getFileIconDotData={this.getFileIconDotData}
					linkDocumentsDisabled={linkDocumentsDisabledData.disabled}
					linkDocumentsDisabledTooltipTid={linkDocumentsDisabledData.tooltipTid}
					renderAttachmentDropdown={(defaultProps) => {
						return renderMeetingAttachmentDropdown(defaultProps, {
							onDeleteCallback: (document) => {
								this.onDeleteFile(objType, document)
							}
						})
					}}
				/>
				<Margin top={0}>{renderControls && renderControls(objType, objId)}</Margin>
				{isProtocol && <ConfirmDeleteProtocolModalContainer />}
			</Padding>
		)
	}

	renderControls = (objType, objId) => {
		if (objType === OBJ_TYPE_MEETING_PROTOCOL) {
			return this.renderShareProtocolContainer(objId)
		}
	}

	render = () => {
		const { preventChangeOfTab } = this.state
		const {
			meetingId,
			protocolSharedAt,
			document,
			protocolTabIsActiveFromStore,
			attachedProtocolMetadata,
			hasAgenda,
			hasAttachments,
			hasProtocol
		} = this.props

		return (
			<>
				<MeetingStandardAttachments
					key={meetingId}
					onUpload={this.onUpload}
					renderBody={this.renderBody}
					hasAgenda={hasAgenda}
					hasAttachments={hasAttachments}
					hasProtocol={hasProtocol}
					attachedProtocolMetadata={attachedProtocolMetadata} // Force rerender
					document={document} // Force renderControls to rerender
					renderControls={this.renderControls}
					preventChangeOfTab={preventChangeOfTab}
					protocolSharedAt={protocolSharedAt}
					protocolTabIsActiveFromStore={protocolTabIsActiveFromStore}
				/>
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		document: store.documents.get('document', Map()) || Map(),
		groups: store.groups.get('groups'),
		attachedProtocolMetadata: store.meetings.getIn(['meeting', 'protocolData'], Map()) || Map(),
		meetingId: store.meetings.getIn(['meeting', 'id']),
		company: store.company.company,
		meetingIsDeleted: store.meetings.getIn(['meeting', 'isDeleted']),
		audit: store.audit.get('documents'),
		protocolTabIsActiveFromStore: store.notify.activeTab === TAB_STANDARD_MEETING_PROTOCOL,
		publishingType: store.meetings.getIn(['meeting', 'publishingType']),
		hasAgenda: store.meetings.getIn(['meeting', 'metadata', 'uploadedDocumentTypes', OBJ_TYPE_MEETING_AGENDA]) > 0,
		hasAttachments:
			store.meetings.getIn(['meeting', 'metadata', 'uploadedDocumentTypes', OBJ_TYPE_MEETING_ATTACHMENT]) > 0,
		hasProtocol: store.meetings.getIn(['meeting', 'metadata', 'uploadedDocumentTypes', OBJ_TYPE_MEETING_PROTOCOL]) > 0
	}
}

const mapActionsToProps = {
	changePublishingType,
	updateDocumentLocal
}

export default connect(mapStoreToProps, mapActionsToProps)(AttachmentsContainer)
