import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { fromJS, Map, List } from 'immutable'
import debounce from 'lodash/debounce'
import ScrollView from '../../../../dumb-components/shared/layout/scroll-view/scroll-view'
import {
	fetchLatestTransaction,
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	cancelTemporaryTransaction
} from '../../../../actions/transaction.actions'
import { addErrorNotification, addInfoNotification } from '../../../../actions/notify.actions'
import DatePicker from '../../../../dumb-components/shared/datepicker/datepicker'
import Panel from '../../../../dumb-components/panel'
import Field from '../../../../dumb-components/fields/field'
import Select from '../../../../dumb-components/fields/select'
import { validateTransactionDate, isRequired } from '../../../../modules/validation.module'
import immutableForm from '../../../framework/immutable-form'
import { getFullShareTypeLabel } from '../../../helpers/shares'
import { SHARES_TRANSACTION_LIMITATIONS } from '../../../../constants/shares'
import ChangeofReservationSequences from './change-of-reservation--sequences'
import TransactionToolbarControlsContainer from '../../../../containers/shares/transaction-toolbar-controls.container'

const transactionType = 'CHANGE-OF-RESERVATION'

const initialTransaction = fromJS({
	type: transactionType,
	handlerData: {}
})

const de = debounce((func, type, transaction) => {
	func(type, transaction.toJS())
}, 1000)

class ChangeOfReservationForm extends Component {
	state = {
		shareTypesOptions: null,
		reservationsOptions: null
	}

	componentDidMount = () => {
		this.props.fetchLatestTransaction()
		this.props.fetchTemporaryTransaction(transactionType)
		this.initReservationsOptions()
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.transaction !== prevProps.transaction) {
			this.initShareTypesOptions(this.props.transaction)
		}
	}

	initShareTypesOptions = (transaction) => {
		if (!transaction) {
			return
		}

		const shareTypesOptions = []
		const shareTypes = transaction.getIn(['shareData', 'types'], List())
		shareTypes.forEach((shareType) => {
			const type = shareType.get('type')
			shareTypesOptions.push({
				value: type,
				label: getFullShareTypeLabel(type)
			})
		})

		this.setState({ shareTypesOptions })
	}

	initReservationsOptions = () => {
		const { i18n } = this.props
		const reservationsOptions = JSON.parse(JSON.stringify(SHARES_TRANSACTION_LIMITATIONS)).map((obj) => {
			obj.label = i18n.messages[obj.label]
			return obj
		})

		this.setState({ reservationsOptions })
	}

	onChange = (field, val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction } = this.props
		const newTransaction = tmpTransaction.setIn(['handlerData', field], val)
		this.props.resetErrors(field)
		updateTransaction(transactionType, newTransaction)
		de(saveTemporaryTransaction, transactionType, newTransaction)
	}

	onChangeDate = (val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction, resetErrors } = this.props
		const newTransaction = tmpTransaction.set('date', val)

		resetErrors('date')
		updateTransaction(transactionType, newTransaction)
		de(saveTemporaryTransaction, transactionType, newTransaction)
	}

	onChangeShareType = (val) => {
		const { tmpTransaction, transaction, updateTransaction, saveTemporaryTransaction } = this.props

		let newTransaction = tmpTransaction.setIn(['handlerData', 'type'], val)

		const selectedShareType = newTransaction.getIn(['handlerData', 'type'])
		const transactionShareTypes = transaction.getIn(['shareData', 'types'])

		const transactionType = transactionShareTypes.find((type) => {
			return type.get('type') === selectedShareType
		})
		const defaultLimitationsOfCurrentSharetype =
			transactionType && transactionType.has('transactionLimitations')
				? transactionType.get('transactionLimitations')
				: List()

		newTransaction = newTransaction.setIn(['handlerData', 'default'], defaultLimitationsOfCurrentSharetype)
		newTransaction = newTransaction.removeIn(['handlerData', 'sequences'])

		updateTransaction(transactionType, newTransaction)
		de(saveTemporaryTransaction, transactionType, newTransaction)
	}

	onSubmit = () => {
		const { tmpTransaction, createTransaction } = this.props

		if (this.props.validate(tmpTransaction)) {
			createTransaction(tmpTransaction.toJS())
		}
	}

	cancelTransaction = () => {
		this.props.cancelTemporaryTransaction(transactionType)
	}

	addReservationsToAllSequences = () => {
		const { transaction, tmpTransaction } = this.props
		const transactionSequences = transaction.get('sequences')
		const selectedShareType = tmpTransaction.getIn(['handlerData', 'type'])
		const transactionLimitations = tmpTransaction.getIn(['handlerData', 'default'])

		let sequences = List()
		transactionSequences.forEach((seq, index) => {
			if (seq.get('type') !== selectedShareType) {
				return
			}

			sequences = sequences.push(Map({ index, transactionLimitations }))
		})

		this.onChange('sequences', sequences)
	}

	resetReservationsForAllSequences = () => {
		const { transaction, tmpTransaction } = this.props
		const transactionSequences = transaction.get('sequences')
		const selectedShareType = tmpTransaction.getIn(['handlerData', 'type'])

		let sequences = List()
		transactionSequences.forEach((seq, index) => {
			if (seq.get('type') !== selectedShareType) {
				return
			}

			sequences = sequences.push(Map({ index, transactionLimitations: seq.get('transactionLimitations', List()) }))
		})

		this.onChange('sequences', sequences)
	}

	renderButtons = () => {
		return (
			<div className='i-content__tabs i-content__tabs--fix-padding'>
				<TransactionToolbarControlsContainer onSubmit={this.onSubmit} onCancel={this.cancelTransaction} />
			</div>
		)
	}

	render = () => {
		const { transaction, tmpTransaction, i18n, errors } = this.props
		const { shareTypesOptions, reservationsOptions } = this.state

		if (!tmpTransaction) {
			return null
		}

		const selectedShareType = tmpTransaction.getIn(['handlerData', 'type'])
		const transactionLimitations = tmpTransaction.getIn(['handlerData', 'default'], List())

		return (
			<div className='i-content__container'>
				{this.renderButtons()}
				<ScrollView autoHide={true} showOnHover={true}>
					<Panel tid='change_of_reservation'>
						<Field
							name='handlerData.type'
							tid='shares.transactions.change_of_reservation.class_of_shares'
							className='col-md-7'
							errors={errors}>
							<Select
								value={selectedShareType}
								options={shareTypesOptions}
								placeholder={i18n.messages['select_placeholder']}
								simpleValue
								onSelect={(val) => {
									this.onChangeShareType(val)
								}}
							/>
						</Field>

						<Field name='date' tid='generic.date' className='col-md-5' errors={errors}>
							<DatePicker
								hasError={errors ? errors.get('date', List()).size > 0 : false}
								calendarPlacement='bottom-end'
								value={tmpTransaction.get('date')}
								onChange={this.onChangeDate}
								language={i18n.language}
							/>
						</Field>

						<div className='form-group--mar-btm-0 col-md-12'>
							<FormattedHTMLMessage id='shares.transactions.change_of_reservation.form.information' />
						</div>

						{selectedShareType && (
							<div>
								<Field
									name='handlerData.default'
									tid='shares.transactions.change_of_reservation.transaction_restrictions_changes_globally'
									errors={errors}>
									<Select
										value={transactionLimitations.join()}
										options={reservationsOptions}
										simpleValue
										multi
										placeholder={i18n.messages['multi_select_to_change_all_sequences_of_the_same_share_class']}
										onSelect={(val) => {
											this.onChange('default', val.split(','))
										}}
									/>
								</Field>

								<Field>
									<div className='form-button-group'>
										<button className='btn btn-primary' onClick={this.addReservationsToAllSequences}>
											<FormattedMessage id='shares.transactions.change_of_reservation.apply_to_all_seqs' />
										</button>
										<button className='btn btn-default' onClick={this.resetReservationsForAllSequences}>
											<FormattedMessage id='shares.transactions.change_of_reservation.reset_all_seqs' />
										</button>
									</div>
								</Field>
							</div>
						)}
					</Panel>

					{selectedShareType && (
						<ChangeofReservationSequences
							transaction={transaction}
							tmpTransaction={tmpTransaction}
							selectedShareType={selectedShareType}
							transactionLimitationsOptions={reservationsOptions}
							onChange={this.onChange}
						/>
					)}
				</ScrollView>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get('transaction'),
		tmpTransaction: state.transaction.getIn(['tmpTransaction', transactionType], initialTransaction),
		i18n: state.i18n
	}
}

const mapActionsToProps = {
	fetchLatestTransaction,
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	cancelTemporaryTransaction,
	addErrorNotification,
	addInfoNotification
}

const validators = fromJS({
	date: {
		tid: 'generic.date',
		rules: [
			{
				func: isRequired,
				message: 'validation.is_required'
			},
			{
				func: validateTransactionDate,
				message: 'validation.current_transaction_date_must_be_later_then_last_transaction'
			}
		]
	},
	'handlerData.type': {
		tid: 'shares.transactions.change_of_reservation.class_of_shares',
		rules: [{ func: isRequired, message: 'validation.is_required' }]
	}
})

ChangeOfReservationForm = immutableForm(ChangeOfReservationForm, 'changeOfReservation', validators)
export default connect(mapStateToProps, mapActionsToProps)(ChangeOfReservationForm)
