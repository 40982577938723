import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { list } from 'react-immutable-proptypes'

import SimpleDarkInfoPanel from '../../shared/dark-info-panel/simple-dark-info-panel'
import Text from '../../shared/text/text'
import Alert from '../../shared/alert/alert'

const StyledInfoAndWarningHeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 100%;
`

const StyledSimpleDarkInfoPanel = styled.div`
	margin-left: ${(props) => props.theme.spacing[3]};
	margin-bottom: ${(props) => props.theme.spacing[3]};
	margin-right: ${(props) => props.theme.spacing[3]};
	flex: 1;
`

const StyledAlert = styled.div`
	margin-left: ${(props) => props.theme.spacing[3]};
	margin-bottom: ${(props) => props.theme.spacing[3]};
	margin-right: ${(props) => props.theme.spacing[3]};
	display: flex;
`

export default class InfoAndWarningHeader extends PureComponent {
	static propTypes = {
		documents: list
	}

	render = () => {
		const { documents } = this.props

		const listContainsDocThatIsNotPDF = documents.find((doc) => {
			if (doc.getIn(['file', 'ext']) !== 'pdf') {
				return true
			}
		})

		return (
			<StyledInfoAndWarningHeaderWrapper>
				<StyledSimpleDarkInfoPanel>
					<SimpleDarkInfoPanel>
						<Text color='white' tid='meetings.publish_protocol.dark_panel.multiple_pdf_merge.info' />
					</SimpleDarkInfoPanel>
				</StyledSimpleDarkInfoPanel>

				{listContainsDocThatIsNotPDF && (
					<StyledAlert>
						<Alert mode='warning' icon='faExclamationTriangle' iconType='solid'>
							<Text color='white' tid='meetings.publish_protocol.alert.multiple_pdf_merge.info' />
						</Alert>
					</StyledAlert>
				)}
			</StyledInfoAndWarningHeaderWrapper>
		)
	}
}
