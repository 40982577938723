import React, { PureComponent } from 'react'
import { string, bool } from 'prop-types'
import styled, { css } from 'styled-components'

import Text from '../../shared/text/text'
import Icon from '../../shared/icon/icon'

const StyledWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 40px;
	transition: all 0.4s ease;
	padding-left: ${(props) => props.theme.spacing[4]};
	padding-right: ${(props) => props.theme.spacing[4]};
	max-width: 200px;

	:hover {
		background-color: #f2f2f2;
	}
`
const StyledImage = styled.img`
	border-radius: 50%;
	margin-left: ${(props) => props.theme.spacing[3]};
	width: 32px;
	height: auto;
	box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);

	/* Psst... Maciej was here. */
	${({ isVH }) =>
		isVH &&
		css`
			transform: rotate(180deg);
		`}
`

const StyledIconWrapper = styled.div`
	margin-left: ${(props) => props.theme.spacing[3]};
`

const VH_IDS_STAGE = ['02906ae0-d196-11ea-9e84-77ff53468aed']
const VH_IDS_PROD = [
	'3124d420-2fb6-11e7-b283-6103624e680e',
	'1d1b9cd0-4789-11e7-82ce-cd8832f69d9d',
	'ed1eb570-1ec7-11eb-ba3b-4fb3d32e3498'
]

export default class UserProfileMenu extends PureComponent {
	static propTypes = {
		name: string,
		image: string,
		userId: string
	}

	getIsVH = () => {
		const { userId } = this.props
		return VH_IDS_STAGE.includes(userId) || VH_IDS_PROD.includes(userId)
	}

	render = () => {
		const { name, image, userId } = this.props
		const isVH = this.getIsVH()

		return (
			<StyledWrapper>
				<Text singleLine>{name}</Text>

				{image ? (
					<StyledImage src={`/api/users/public/images/${image}-32x32?userId=${userId}`} isVH={isVH} />
				) : (
					<StyledIconWrapper>
						<Icon icon='faSmile' size='sml' />
					</StyledIconWrapper>
				)}
			</StyledWrapper>
		)
	}
}
