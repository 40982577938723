import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { string, func, bool } from 'prop-types'
import { Padding } from 'styled-components-spacing'
import Grid from 'styled-components-grid'
import Input from '../input/input'
import Label from '../label/label'
import CountrySelect from '../country-select/country-select'

const AddressInput = ({
	address,
	onChange,
	language,
	showSecondAddressLine,
	showRegion,
	showCountry,
	errors,
	disabled,
	withCountryPortal
}) => {
	return (
		<Grid>
			<Grid.Unit size={1}>
				<Padding all={3}>
					<Label
						tid={
							showSecondAddressLine
								? 'subscriptions.customer_address.address_line1'
								: 'subscriptions.customer_address.address_line'
						}>
						<Input
							placeholderTid={
								showSecondAddressLine
									? 'subscriptions.customer_address.address_line1'
									: 'subscriptions.customer_address.address_line'
							}
							value={address ? address.get('address_line1') : ''}
							fieldName='address_line1'
							onChange={onChange}
							error={errors && errors.get('address_line1')}
							disabled={disabled}
						/>
					</Label>
				</Padding>
			</Grid.Unit>

			{showSecondAddressLine && (
				<Grid.Unit size={1}>
					<Padding all={3}>
						<Label tid='subscriptions.customer_address.address_line2'>
							<Input
								placeholderTid='subscriptions.customer_address.address_line2'
								value={address ? address.get('address_line2') : ''}
								fieldName='address_line2'
								onChange={onChange}
								error={errors && errors.get('address_line2')}
							/>
						</Label>
					</Padding>
				</Grid.Unit>
			)}

			<Grid.Unit size={1 / 2}>
				<Padding all={3}>
					<Label tid='subscriptions.customer_address.postal_code'>
						<Input
							placeholderTid='subscriptions.customer_address.postal_code'
							value={address ? address.get('address_zip') : ''}
							fieldName='address_zip'
							onChange={onChange}
							error={errors && errors.get('address_zip')}
							disabled={disabled}
						/>
					</Label>
				</Padding>
			</Grid.Unit>

			<Grid.Unit size={1 / 2}>
				<Padding all={3}>
					<Label tid='subscriptions.customer_address.city'>
						<Input
							placeholderTid='subscriptions.customer_address.city'
							value={address ? address.get('address_city') : ''}
							fieldName='address_city'
							onChange={onChange}
							error={errors && errors.get('address_city')}
							disabled={disabled}
						/>
					</Label>
				</Padding>
			</Grid.Unit>

			{showRegion && (
				<Grid.Unit size={1}>
					<Padding all={3}>
						<Label tid='subscriptions.customer_address.region'>
							<Input
								placeholderTid='subscriptions.customer_address.region'
								value={address ? address.get('address_state') : ''}
								fieldName='address_state'
								onChange={onChange}
								error={errors && errors.get('address_state')}
								disabled={disabled}
							/>
						</Label>
					</Padding>
				</Grid.Unit>
			)}

			{showCountry && (
				<Grid.Unit size={1}>
					<Padding all={3}>
						<Label tid='subscriptions.customer_address.country'>
							<CountrySelect
								placeholderTid='subscriptions.customer_address.country'
								value={address ? address.get('address_country') : null}
								language={language}
								fieldName='address_country'
								onChange={onChange}
								error={errors && errors.get('address_country')}
								disabled={disabled}
								withPortal={withCountryPortal}
							/>
						</Label>
					</Padding>
				</Grid.Unit>
			)}
		</Grid>
	)
}

AddressInput.defaultProps = {
	showSecondAddressLine: true,
	showRegion: true,
	showCountry: true,
	disabled: false
}

AddressInput.propTypes = {
	address: ImmutablePropTypes.map,
	onChange: func,
	language: string,
	showSecondAddressLine: bool,
	showRegion: bool,
	showCountry: bool,
	errors: ImmutablePropTypes.map,
	disabled: bool
}

export default AddressInput
