import React, { PureComponent } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { string, bool, func, oneOf } from 'prop-types'
import styled from 'styled-components'
import { StyledInputTemplate } from './input.template'
import InputAddon from './input-addon'
import theme from '../../../../theme'
import { withTranslation } from 'react-i18next'
import FieldError from '../field-error/field-error'

const StyledInput = styled.input`
	${StyledInputTemplate};
`

let lastKeyEvent = null

class Input extends PureComponent {
	static defaultProps = {
		disabled: false,
		isLoading: false,
		type: 'text',
		value: '',
		returnRawEvent: false,
		autoFocus: false
	}

	static propTypes = {
		disabled: bool,
		name: string,
		icon: string,
		iconColor: oneOf(Object.keys(theme.colors)),
		isLoading: bool,
		onChange: func,
		onFocus: func,
		onBlur: func,
		type: oneOf(['text', 'email', 'password', 'number', 'tel']),
		placeholderTid: string,
		value: string,
		hasError: ImmutablePropTypes.list,
		returnRawEvent: bool,
		legacyMode: bool,
		inputStyle: string,
		onEnter: func,
		autoFocus: bool,
		onSetRef: func
	}

	handleKeyDown = (event) => {
		if (!lastKeyEvent && event.key === 'Enter') {
			this.props.onEnter && this.props.onEnter()
		}

		if (!lastKeyEvent) {
			lastKeyEvent = event.key
		}
	}

	handleKeyUp = (event) => {
		lastKeyEvent = null
	}

	getHasError = () => {
		const { hasError } = this.props

		if (List.isList(hasError)) {
			return hasError.size > 0
		}
		return hasError ? true : false
	}

	render() {
		const {
			disabled,
			value,
			placeholderTid,
			onChange,
			onFocus,
			onBlur,
			type,
			icon,
			iconColor,
			isLoading,
			fieldName,
			returnRawEvent,
			legacyMode,
			inputStyle,
			t,
			autoFocus,
			onSetRef
		} = this.props
		let { hasError, name } = this.props

		const onChangeLocal = (event) => {
			if (fieldName) {
				onChange(fieldName, event.target.value)
			} else {
				onChange(event.target.value)
			}
		}

		if (!name && fieldName) {
			name = fieldName
		}

		const props = {
			disabled,
			inputStyle,
			name,
			type,
			value: value || '',
			onFocus,
			onChange: onChangeLocal,
			onBlur,
			legacyMode,
			autoFocus,
			placeholder: placeholderTid ? t(placeholderTid) : undefined,
			onKeyDown: this.handleKeyDown,
			onKeyUp: this.handleKeyUp,
			ref: onSetRef
		}

		const hasErrorBoolean = this.getHasError()

		// Used by autosuggest
		if (returnRawEvent) {
			if (isLoading) {
				return (
					<FieldError hasError={hasError}>
						<InputAddon isLoading={isLoading} isTransparent={disabled}>
							<StyledInput {...this.props} hasError={hasErrorBoolean} disabled={true} />
						</InputAddon>
					</FieldError>
				)
			}

			return (
				<FieldError hasError={hasError}>
					<StyledInput {...this.props} hasError={hasErrorBoolean} />
				</FieldError>
			)
		}

		if (icon || isLoading) {
			return (
				<FieldError hasError={hasError}>
					<InputAddon isLoading={isLoading} icon={icon} iconColor={iconColor} isTransparent={disabled}>
						<StyledInput {...props} hasError={hasErrorBoolean} />
					</InputAddon>
				</FieldError>
			)
		}

		return (
			<FieldError hasError={hasError}>
				<StyledInput {...props} hasError={hasErrorBoolean} />
			</FieldError>
		)
	}
}

export default withTranslation()(Input)
