import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import { Map } from 'immutable'
import DocumentsHelper from '../../../components/helpers/documents.helper'

import { addDocumentToCollection } from '../../../actions/collections.actions'
import { openModal } from '../../../actions/modals.actions'
import { ADD_DOCUMENT_TO_COLLECTION_MODAL, ADD_EXISTING_DOCUMENT_TO_COLLECTION_MODAL } from '../../../constants/modals'

const TOOLTIP_STATES = {
	isSharedFromCompany: {
		tid: 'documents.list.dropdown.item.add_to_collection.disabled.shared_from_company',
		delayShow: 'instant'
	}
}

const VERSION_SIGNED = 'SIGNED'
const VERSION_NORMAL = 'NORMAL'

class AddDocumentToCollectionComponentContainer extends Component {
	static propTypes = {
		document: map.isRequired,
		children: func.isRequired
	}

	getTooltipData = () => {
		const { document } = this.props
		const isSharedFromCompany = Boolean(document.get('isSharedFromCompanyId'))
		let activeState

		if (isSharedFromCompany) {
			activeState = 'isSharedFromCompany'
		}

		return { activeState, isDisabled: Boolean(activeState) }
	}

	addToCollection = () => {
		const { document, documentInRoot, openModal, addDocumentToCollection } = this.props
		const isSigned = DocumentsHelper.getDocumentIsSigned(document)

		if (documentInRoot && isSigned) {
			openModal(ADD_EXISTING_DOCUMENT_TO_COLLECTION_MODAL, { documentId: document.get('id') , sharedVersion: documentInRoot.get('sharedVersion')})
			return
		}

		if (isSigned) {
			openModal(ADD_DOCUMENT_TO_COLLECTION_MODAL, {documentId: document.get('id')})
			return
		}

		addDocumentToCollection(document.get('id'), VERSION_NORMAL)
	}

	render = () => {
		const { children, collectionsIsEnabled } = this.props
		const { activeState, isDisabled } = this.getTooltipData()

		if (!collectionsIsEnabled) {
			return null
		}

		return children({
			addToCollection: this.addToCollection,
			TOOLTIP_STATES,
			activeState,
			isDisabled
		})
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		documentInRoot: store.collections
			.getIn(['collections', 0, 'documents'])
			?.find((d) => d.get('id') === ownProps.document.get('id')),
		collectionsIsEnabled: store.subscriptions.getIn(['helper', 'collectionsEnabled'])
	}
}

const mapActionsToProps = {
	addDocumentToCollection,
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(AddDocumentToCollectionComponentContainer)
