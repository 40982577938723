import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import SimpleConfirmationModal from '../../dumb-components/shared/simple-confirmation-modal/simple-confirmation-modal'

import { closeModal } from '../../actions/modals.actions'
import { SIMPLE_CONFIRMATION_MODAL } from '../../constants/modals'
import history from '../../interfaces/history'

class SimpleConfirmationModalContainer extends Component {
	componentDidUpdate = (prevProps) => {
		const { activeModal, history } = this.props

		if (activeModal && activeModal.get('name') === SIMPLE_CONFIRMATION_MODAL) {
			const redirectTo = activeModal.getIn(['options', 'redirectTo'])

			setTimeout(() => {
				this.props.closeModal(SIMPLE_CONFIRMATION_MODAL)

				if (redirectTo) {
					history.push(redirectTo)
				}
			}, 2000)
		}
	}

	render = () => {
		const { activeModal } = this.props
		const isOpen = activeModal ? activeModal.get('name') === SIMPLE_CONFIRMATION_MODAL : false
		const config = isOpen ? activeModal.getIn(['options', 'config'], List()) : List()

		if (!isOpen) {
			return null
		}

		return <SimpleConfirmationModal config={config} />
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		activeModal: store.modals.get('activeModal')
	}
}

const mapActionsToProps = {
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(SimpleConfirmationModalContainer)
