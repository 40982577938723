import React, { PureComponent } from 'react'
import { func, object, bool } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import styled from 'styled-components'
import moment from '../../../modules/moment.module'

import Text from '../../shared/text/text'
import ListItem from '../../shared/list-item/list-item'
import FileIcon from '../../shared/file-icon/file-icon'
import ModernDragHandlers, { modernHoverStyling } from '../../shared/dnd-drag-handlers/modern-dnd-drag-handlers'

const StyledListItem = styled(ListItem)`
	display: flex;
	border: 1px solid ${({ theme }) => theme.colors.border};
	align-items: center;
	cursor: default;
`

const StyledDndDragHandlers = styled(ModernDragHandlers)`
	${StyledListItem}:hover &, ${StyledListItem}:active & {
		${modernHoverStyling}
	}
`

const Icon = styled.div`
	display: flex;
	width: 36px;
	min-width: 36px;
	margin-right: 0;
`

const StyledDate = styled(Text)`
	margin: 0 ${({ theme }) => theme.spacing[4]};
	padding-right: ${({ theme }) => theme.spacing[4]};
	border-right: 1px solid ${({ theme }) => theme.colors.border};
`

const StyledNameWrapper = styled.div`
	flex: 1;
	min-width: 0;
	display: flex;
`

const ContentWrapper = styled.div`
	display: flex;
	overflow: hidden;
	text-overflow: ellipsis;
	align-items: center;
	width: 100%;
	height: 100%;
`

const StyledSignedCopyText = styled(Text)`
	margin: 0 ${({ theme }) => theme.spacing[3]};
`

export default class DocumentListItem extends PureComponent {
	static propTypes = {
		document: map,
		selectedCollection: map,
		renderDropdown: func,
		onInitDragging: func,
		onStopDragging: func
	}

	static defaultProps = {}

	render = () => {
		const { document, selectedCollection, renderDropdown, onInitDragging, onStopDragging } = this.props

		const id = document.get('id')
		const ext = document.getIn(['file', 'ext'])
		const createdAt = document.get('createdAt')
		const createdAtFormatted = createdAt && moment(createdAt).format('L')
		const name = document.get('title')

		const selectedCollectionDocument = selectedCollection.get('documents').find((d) => d.get('id') === id)
		const sharingSignedVersion = selectedCollectionDocument?.get('sharedVersion') === 'SIGNED'

		return (
			<StyledListItem hoverColor='lightestGrey' onMouseDown={onInitDragging} onMouseUp={onStopDragging}>
				<ContentWrapper>
					<StyledDndDragHandlers />

					<Icon>
						<FileIcon ext={ext} />
					</Icon>

					<StyledDate>{createdAtFormatted}</StyledDate>
					<StyledNameWrapper>
						<Text singleLine>{name}</Text>
						{sharingSignedVersion && (
							<StyledSignedCopyText tid='collections.document_list_item.common.signed_copy' hasItalic nowrap />
						)}
					</StyledNameWrapper>
				</ContentWrapper>

				{renderDropdown && renderDropdown(document)}
			</StyledListItem>
		)
	}
}
