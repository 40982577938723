import React, { useEffect, useRef, useState } from 'react'
import AgGrid from '../../../dumb-components/shared/ag-grid/ag-grid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CheckboxRenderer } from '../../../dumb-components/shared/ag-grid/renderers/checkbox.renderer'
import { useCopyContactModalContext } from './copy-contact-modal.context'
import Typography from '@mui/material/Typography'
import { DateTime } from 'luxon'
import { CheckboxInHeaderRenderer } from '../../../dumb-components/shared/ag-grid/renderers/checkbox-in-header.renderer'

const CompaniesUserMemberOfGrid = () => {
	const { t } = useTranslation()
	const gridRef = useRef()
	const { setSelectedCompanies, selectedCompanies } = useCopyContactModalContext()
	const [rowData, setRowData] = useState(null)

	const companiesUserMemberOf = useSelector((state) => state.user.get('companiesUserMemberOf'))

	useEffect(() => {
		if (companiesUserMemberOf && companiesUserMemberOf.size > 0) {
			setRowData(companiesUserMemberOf.toJS())
		}
	}, [companiesUserMemberOf])

	const columnDefs = () => {
		return [
			{
				field: 'selected',
				headerName: '',
				// headerCheckboxSelection: true,
				lockVisible: true,
				minWidth: 38,
				maxWidth: 38,
				cellClass: 'checkbox-cell',
				headerClass: 'checkbox-cell',
				cellRenderer: CheckboxRenderer,
				headerComponent: CheckboxInHeaderRenderer
			},
			{
				field: 'orgNumber',
				headerName: t('companies_user_member_of.grid.headers.organization_number'),
				minWidth: 150,
				maxWidth: 150
			},
			{
				field: 'name',
				headerName: t('companies_user_member_of.grid.headers.name'),
				minWidth: 300,
				maxWidth: 300
			},
			{
				field: 'status',
				headerName: t('companies_user_member_of.grid.headers.status'),
				cellRendererSelector: (params) => {
					if (!params.data.status) {
						return null
					}
					if (params.data?.status.length === 1) {
						return {
							component: () => {
								return (
									<Typography variant='body2' sx={{ display: 'inline-block' }}>
										{t('companies_user_member_of.grid.status.cell.contact_last_copied', {
											name: params.data.status[0].name,
											date: DateTime.fromISO(params.data.status[0].lastestCopyAt).toLocaleString()
										})}
									</Typography>
								)
							}
						}
					} else {
						return {
							component: 'agGroupCellRenderer'
						}
					}
				},
				valueGetter: (params) => {
					if (params.data.status && params.data.status.length > 1) {
						return t('companies_user_member_of.grid.status.cell.multiple_entries')
					}
				}
			},
			{
				field: 'id',
				hide: true
			}
		]
	}

	const detailCellRendererParams = {
		detailGridOptions: {
			columnDefs: [
				{ field: 'name', width: 300 },
				{
					field: 'lastestCopyAt',
					headerName: t('companies_user_member_of.grid.headers.status'),
					flex: 1,
					cellRenderer: (params) => {
						return (
							<Typography variant='body2' sx={{ display: 'inline-block' }}>
								{t('companies_user_member_of.grid.status.cell.contact_without_name_last_copied', {
									date: DateTime.fromISO(params.data.lastestCopyAt).toLocaleString()
								})}
							</Typography>
						)
					}
				}
			],
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true
			},
			suppressContextMenu: true
		},
		// get the rows for each Detail Grid
		getDetailRowData: (params) => {
			params.successCallback(params.data.status)
		}
	}

	const onSelectionChanged = ({ api }) => {
		let _companies = selectedCompanies

		api.forEachNode((node, index) => {
			const isSelected = node.isSelected()
			const { id } = node.data
			const isAlreadyASelectedCompany = selectedCompanies.includes(id)

			if (isSelected) {
				if (!isAlreadyASelectedCompany) {
					_companies = _companies.push(id)
				}
			} else if (isAlreadyASelectedCompany) {
				_companies = _companies.splice(_companies.indexOf(id), 1)
			}
		})

		setSelectedCompanies(_companies)
	}

	return (
		<AgGrid
			gridRef={gridRef}
			rowData={rowData}
			columnDefs={columnDefs()}
			mode='panel'
			rowSelection={'multiple'}
			forcedHeight='100%'
			hideColumnMenu={true}
			suppressRowClickSelection={true}
			rowsPerPage={50}
			onSelectionChanged={onSelectionChanged}
			onRowSelected={({ node }) => node.setDataValue('selected', node.selected)}
			suppressContextMenu
			masterDetail={true}
			detailCellRendererParams={detailCellRendererParams}></AgGrid>
	)
}

export default CompaniesUserMemberOfGrid
