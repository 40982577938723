import { fromJS, Map } from 'immutable'
import req from '../modules/request.module'
import { TAGS_LIST_REMOTE, TAGS_CREATE_TAG, TAGS_SET_SORT } from './types'
import { LIVE_TAG_CREATE } from '../constants/live-update'
import { setLiveRequest, resetLiveRequest } from './live-update.actions'

export function listTags() {
	return function (dispatch) {
		return req.get('/tags/tags').then((response) => {
			dispatch({ type: TAGS_LIST_REMOTE, payload: fromJS(response.data) })
		})
	}
}

export function createTag(tag) {
	return function (dispatch) {
		dispatch({ type: TAGS_CREATE_TAG, payload: fromJS(tag) })
		return req.post('tags/tags', tag).then(() => {})
	}
}

export function setSortTags(tags, section) {
	return function (dispatch) {
		dispatch({ type: TAGS_SET_SORT, payload: Map({ tags, section }) })
	}
}

export function socketEventTags(eventObj) {
	const { eventName, objId } = eventObj

	return function (dispatch) {
		switch (eventName) {
			case LIVE_TAG_CREATE: {
				dispatch(setLiveRequest(['tags', eventName], { r: true, objId }))
				dispatch(resetLiveRequest(['tags', eventName]))
				break
			}
		}
	}
}
