import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { List } from 'immutable'
import { initForm, resetForm, addError, resetError, resetErrors } from '../../actions/immutable-form.actions'
import { addErrorNotification, addInfoNotification } from '../../actions/notify.actions'

export default function immutableForm(WrappedComponent, formName, validators) {
	class ImmutableForm extends Component {
		constructor(props) {
			super(props)
			this.validators = validators
		}

		componentWillUnmount = () => {
			this.props.resetErrors()
		}

		validate = (immutableObj) => {
			const { i18n } = this.props
			let valid = true

			if (!this.validators) {
				return valid
			}

			this.validators.map((validator, fieldName) => {
				const propPath = fieldName.split('.')
				const value = immutableObj.getIn(propPath)
				const disabled = validator.get('disabled', false)

				if (!disabled) {
					validator.get('rules').forEach((rule) => {
						if (!rule.get('func')(value, immutableObj)) {
							this.props.addErrorNotification({
								text: i18n.messages[rule.get('message')]
							})
							this.props.addError(fieldName, rule.get('message'))
							valid = false
						}
					})
				}
			})

			return valid
		}

		validateField = (value, field) => {
			const { i18n } = this.props
			let valid = true

			if (!this.validators) {
				return valid
			}

			this.validators.map((validator, fieldName) => {
				if (field !== fieldName) {
					return
				}

				const propPath = fieldName.split('.')
				const disabled = validator.get('disabled', false)

				if (!disabled) {
					validator.get('rules').forEach((rule) => {
						if (!rule.get('func')(value)) {
							this.props.addErrorNotification({
								text: i18n.messages[rule.get('message')]
							})
							this.props.addError(fieldName, rule.get('message'))
							valid = false
						}
					})
				}
			})

			return valid
		}

		resetErrors = (fieldName) => {
			const { errors, resetError } = this.props

			if (errors && errors.get(fieldName, List()).size > 0) {
				resetError(fieldName)
			}
		}

		resetAllErrors = () => {
			const { errors, resetErrors } = this.props

			if (errors) {
				resetErrors()
			}
		}

		disableValidationOfField = (fieldName) => {
			let field = this.validators.get(fieldName)
			field = field.set('disabled', true)
			const validators = this.validators.set(fieldName, field)
			this.validators = validators
		}

		enableValidationOfField = (fieldName) => {
			let field = this.validators.get(fieldName)
			field = field.remove('disabled')
			const validators = this.validators.set(fieldName, field)
			this.validators = validators
		}

		render = () => {
			return (
				<WrappedComponent
					validate={this.validate}
					validateField={this.validateField}
					resetErrors={this.resetErrors}
					resetAllErrors={this.resetAllErrors}
					disableValidationOfField={this.disableValidationOfField}
					enableValidationOfField={this.enableValidationOfField}
					{...this.props}
				/>
			)
		}
	}

	function mapStateToProps(state) {
		return {
			currentForm: state.immutableForm.get('currentForm'),
			errors: state.immutableForm.get('errors'),
			i18n: state.i18n
		}
	}

	const mapActionsToProps = {
		initForm,
		resetForm,
		addError,
		resetError,
		resetErrors,
		addErrorNotification,
		addInfoNotification
	}

	return connect(mapStateToProps, mapActionsToProps)(ImmutableForm)
}
