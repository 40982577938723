import React from 'react'
import styled, { css } from 'styled-components'
import { string, oneOf } from 'prop-types'
import Text from '../../../text/text'

// Images
import NoDocumentSelected from '../../../../../assets/images/placeholders/documents/no_document_selected.png'
import NoTaskSelected from '../../../../../assets/images/placeholders/tasks/no_task_selected.png'
import NoFeedbackeeSelected from '../../../../../assets/images/placeholders/meetings/no_feedbackee_selected.png'
import NoSignatorySelected from '../../../../../assets/images/placeholders/meetings/no_signatory_selected.png'

const ContentWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: ${(props) => (props.orientation === 'profile' ? 'column' : 'row')};
	align-items: center;
	margin-top: 50px;
`

const StyledImage = styled.img`
	width: ${(props) => (props.orientation === 'profile' ? '208px' : '47px')};
	height: auto;
	opacity: 0.5;
`

const StyledTextWrapepr = styled.div`
	margin-top: ${(props) => (props.orientation === 'profile' ? props.theme.spacing[5] : '0')};
	text-align: ${(props) => (props.orientation === 'profile' ? 'center' : 'left')};
	width: ${(props) => (props.orientation === 'profile' ? '208px' : 'auto')};

	${(props) =>
		props.orientation === 'landscape' &&
		css`
			display: flex;
			flex: 1;
			padding-left: ${(props) => props.theme.spacing[4]};
		`}
`

const getImage = (image) => {
	switch (image) {
		case 'no-document-selected':
			return NoDocumentSelected
		case 'no-task-selected':
			return NoTaskSelected
		case 'no-feedbackee-selected':
			return NoFeedbackeeSelected
		case 'no-signatory-selected':
			return NoSignatorySelected
	}
}

const PanelImagePlaceholder = ({ image, tid, orientation }) => {
	const imageToDisplay = getImage(image)

	return (
		<ContentWrapper orientation={orientation}>
			<StyledImage src={imageToDisplay} orientation={orientation} />
			{tid && (
				<StyledTextWrapepr orientation={orientation}>
					<Text tid={tid} color='lightGrey' />
				</StyledTextWrapepr>
			)}
		</ContentWrapper>
	)
}

PanelImagePlaceholder.defaultProps = {
	image: 'no-document-selected',
	tid: 'image_placeholder.generic.nothing_selected',
	orientation: 'profile'
}

PanelImagePlaceholder.propTypes = {
	image: oneOf(['no-document-selected', 'no-task-selected', 'no-feedbackee-selected', 'no-signatory-selected']),
	tid: string,
	orientation: oneOf(['profile', 'landscape'])
}

export default PanelImagePlaceholder
