import { fromJS, List, Map } from 'immutable'
import Cookies from 'universal-cookie'

import {
	NOTIFICATIONS_ONBOARD_FETCH_REMOTE,
	NOTIFICATIONS_ONBOARD_SINGLE_FETCH_REMOTE,
	NOTIFICATIONS_ONBOARD_MARK_READ,
	NOTIFICATIONS_ONBOARD_MARK_DISMISS,
	NOTIFICATIONS_ONBOARD_SESSION_RESET,
	NOTIFICATIONS_LOCATION_UPDATE_LOCAL,
	CREDENTIALS_SIGNOUT
} from '../actions/types'

const sessionReadNotificationsCookie = new Cookies().get('sessionReadNotifications')

/**
 * onboardNotifications: Queued notifications
 * sessionReadNotifications: ID of notifications dismissed in session
 * userLocation: Current location of the user, if route is tracked
 */
const INITIAL_STATE = fromJS({
	onboardNotifications: List(),
	sessionReadNotifications: sessionReadNotificationsCookie ? sessionReadNotificationsCookie : Map(),
	userLocation: ''
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case NOTIFICATIONS_ONBOARD_FETCH_REMOTE: {
			state = state.set('onboardNotifications', payload)
			return state
		}

		// SocketIO
		case NOTIFICATIONS_ONBOARD_SINGLE_FETCH_REMOTE: {
			const payloadUniqueSiteContext = payload.getIn(['notificationData', 'uniqueSiteContext'])
			let shouldReplace = false
			let shouldReplaceIndex = null

			if (payloadUniqueSiteContext) {
				state.get('onboardNotifications').forEach((notification, index) => {
					if (notification.get('siteContext') === payload.get('siteContext')) {
						shouldReplace =
							notification.getIn(['notificationData', 'priority']) <= payload.getIn(['notificationData', 'priority'])

						if (shouldReplace) {
							shouldReplaceIndex = index
						}
					}
				})
			}

			if (shouldReplace) {
				state = state.setIn(['onboardNotifications', shouldReplaceIndex], payload)
			} else {
				state = state.set('onboardNotifications', state.get('onboardNotifications').push(payload))
			}

			return state
		}

		case NOTIFICATIONS_ONBOARD_MARK_READ: {
			const index = state.get('onboardNotifications').findIndex((n) => n.get('id') === payload)
			state = state.setIn(['onboardNotifications', index, 'isRead'], true)
			state = state.setIn(['sessionReadNotifications', payload], Map({ id: payload }))

			// Update cookie
			const cookie = new Cookies()
			const sessionReadNotifications = cookie.get('sessionReadNotifications') || {}
			sessionReadNotifications[payload] = { id: payload }
			cookie.set('sessionReadNotifications', sessionReadNotifications)

			return state
		}

		case NOTIFICATIONS_ONBOARD_MARK_DISMISS: {
			const index = state.get('onboardNotifications').findIndex((n) => n.get('id') === payload)
			state = state.removeIn(['onboardNotifications', index])
			return state
		}

		case NOTIFICATIONS_ONBOARD_SESSION_RESET: {
			state = state.set('sessionReadNotifications', Map())
			new Cookies().remove('sessionReadNotifications')
			return state
		}

		case NOTIFICATIONS_LOCATION_UPDATE_LOCAL: {
			state = state.set('userLocation', payload)
			return state
		}

		case CREDENTIALS_SIGNOUT: {
			return INITIAL_STATE
		}

		default:
			return state
	}
}
