import { fromJS } from 'immutable'
import {
	CAP_TABLE_RESET_REDUCER,
	INVESTORS_FETCH_MY_INVESTMENT_PERSONAL,
	ALIAS_UPDATE,
	ALIAS_DELETE,
	INVESTORS_FETCH_COMBINED_INVESTMENT
} from '../actions/types'
import investmentHelper from '../components/helpers/investment.helper'

const INITIAL_STATE = fromJS({
	selectedPersonalInvestment: null,
	aliasOfTheInvestedCompany: null,
	combinedSingleInvestmentAndAllTransactions: null
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case INVESTORS_FETCH_MY_INVESTMENT_PERSONAL: {
			const aliasOrName = investmentHelper.getInvestedInCompanyNameOrAlias(payload)
			state = state.set('aliasOfTheInvestedCompany', aliasOrName)
			return state.set('selectedPersonalInvestment', payload)
		}
		case INVESTORS_FETCH_COMBINED_INVESTMENT:
			return state.set('combinedSingleInvestmentAndAllTransactions', payload)
		case CAP_TABLE_RESET_REDUCER: {
			return INITIAL_STATE
		}
		case ALIAS_UPDATE:
			return state.set('aliasOfTheInvestedCompany', payload)
		case ALIAS_DELETE:
			return state.set('aliasOfTheInvestedCompany', null)
		default:
			return state
	}
}
