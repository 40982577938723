import React, { PureComponent } from 'react'
import { string, bool } from 'prop-types'
import styled, { css } from 'styled-components'

const StyledWrapper = styled.div`
	display: flex;

	${(props) =>
		props.loading
			? css`
					button + *:after {
						content: '';
						position: absolute;
						left: 0;
						border-left: 1px solid rgba(0, 0, 0, 0.07);
						top: 10%;
						bottom: 10%;
					}
			  `
			: css`
					> * + *:after {
						content: '';
						position: absolute;
						left: 0;
						border-left: 1px solid rgba(0, 0, 0, 0.07);
						top: 10%;
						bottom: 10%;
					}
			  `}
`

export default class FooterRightControls extends PureComponent {
	static propTypes = {
		loading: bool
	}

	static defaultProps = {}

	render = () => {
		const { children, id, loading } = this.props

		return (
			<StyledWrapper id={id} loading={loading}>
				{children}
			</StyledWrapper>
		)
	}
}
