/**
 * CollapsibleMenuItem
 * @module components/menu/main-nav--collapsible-item
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

/** Config for scroller */
const scrollerConfig = {
	smooth: true,
	duration: 300,
	containerId: 'main-nav-nano-content'
}

/** Class representing a CollapsibleMenuItem. */
class CollapsibleMenuItem extends Component {
	/**
	 * Constructor
	 */
	constructor(props) {
		super(props)

		this.state = { open: false }
	}

	componentDidMount() {
		const { url, pathName } = this.props

		const pathArr = pathName.split('/')

		if (pathName.indexOf(url) === 0) {
			this.setState({ open: true })
		}
	}

	/**
	 * Toggle the open state on click
	 */
	onToggleClick(liId, event) {
		event.preventDefault()

		this.setState({ open: !this.state.open })
		//scroller.scrollTo(liId, scrollerConfig);
	}

	/**
	 * The render function
	 */
	render() {
		const { id, name, translationId, children, icon, classes, level } = this.props

		const isLevelZero = level === 0
		const open = this.state.open || isLevelZero

		const liClasses = open ? 'text--menu--active' : ''
		const ulClasses = open ? 'menu__body__list ' : 'menu__body__list menu__body__list--hidden'
		const liId = `main-nav--${id}`

		return (
			<li key={id} id={liId} className={`${liClasses} ${classes}`}>
				{isLevelZero ? (
					<span className='text--menu'>{translationId ? <FormattedMessage id={translationId} /> : name}</span>
				) : (
					<a
						href='#'
						onClick={this.onToggleClick.bind(this, liId)}
						className='text--menu menu__body__list__link'
						aria-expanded={this.state.open}>
						{icon && <i className={'fa ' + icon}></i>}
						{translationId ? <FormattedMessage id={translationId} /> : name}
						<i className='arrow' style={{ marginRight: '3px' }}></i>
					</a>
				)}

				<ul className={ulClasses + ''} aria-hidden={!this.state.open}>
					{children}
				</ul>
			</li>
		)
	}
}

function mapStateToProps(state) {
	return { company: state.company.company }
}

/** Export */
export default connect(mapStateToProps)(CollapsibleMenuItem)
