/**
 * Loading bar
 * @module components/framework/loading-bar
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import invonoLogoImg from '../../assets/images/logo-white.svg'

/** Class representing the notify component. */
class LoadingBar extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: false,
			isSaving: false
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.loading === true && prevState.loading === false) {
			this.setState({ loading: true })
		}

		if (this.props.loading === false && prevState.loading === true) {
			setTimeout(() => {
				this.setState({ loading: false })
			}, 600)
		}

		if (this.props.isSaving === true && prevState.isSaving === false) {
			this.setState({ isSaving: true })
		}

		if (this.props.isSaving === false && prevState.isSaving === true) {
			setTimeout(() => {
				this.setState({ isSaving: false })
			}, 600)
		}
	}

	/**
	 * The render function
	 */
	render() {
		const { loading } = this.state

		return (
			<div className='i-activity-bar'>
				<img src={invonoLogoImg} alt='Invono Logo' className='i-activity-bar__logo' />
				<div className='i-activity-bar__spinner'>{loading && <span className='fa fa-spinner fa-spin'></span>}</div>
			</div>
		)
	}
}

/** Map state to props. */
function mapStateToProps(state) {
	return {
		loading: state.notify.loading,
		isSaving: state.notify.isSaving
	}
}

/** Export */
export default connect(mapStateToProps, {})(LoadingBar)
