import React, { Component } from 'react'
import { connect } from 'react-redux'
import Text from '../../dumb-components/shared/text/text'
import styled from 'styled-components'
import { updateUser, saveUser } from '../../actions/user.actions'
import { List } from 'immutable'

// This will be redone SOON ™
const StyledAlertWrapper = styled.div`
	padding-left: 64px;
	padding-right: 64px;
`

// Welcome Message Block ID. Used for dismissing
const WELCOME_MSG_ID = '139f1eba-4aee-11e9-8646-d663bd873d93'

class DashboardAlert extends Component {
	componentWillUnmount = () => {
		//this.onCloseWelcomeMessage();
	}

	onCloseWelcomeMessage = () => {
		const { updateUser, saveUser } = this.props
		let { dismissedBlocks, user } = this.props
		const alreadyDismissed = dismissedBlocks && dismissedBlocks.some((uid) => uid === WELCOME_MSG_ID)

		if (!dismissedBlocks) {
			dismissedBlocks = List()
		}

		if (!alreadyDismissed && user) {
			dismissedBlocks = dismissedBlocks.push(WELCOME_MSG_ID)
			user = user.setIn(['siteSettings', 'dismissedBlocks'], dismissedBlocks)

			updateUser(user)
			saveUser('me', user)
		}
	}

	render = () => {
		const { user } = this.props

		if (!user) {
			return null
		}

		const dismissedBlocks = user.getIn(['siteSettings', 'dismissedBlocks'])
		const alreadyDismissed = dismissedBlocks && dismissedBlocks.some((uid) => uid === WELCOME_MSG_ID)

		if (alreadyDismissed) {
			return null
		}

		return (
			<div className='col-md-12 scroll-pane'>
				<div className='alert alert-invono dashboard-alert alert--unset-margin'>
					<button type='button' className='close' onClick={this.onCloseWelcomeMessage}>
						<span className='fa fa-times fa-lg'></span>
					</button>

					<StyledAlertWrapper>
						<Text tid='dashboard.welcome_message.text_1' color='white' tag='p' marginBottom={4} />
						<Text tid='dashboard.welcome_message.text_2' color='white' tag='p' align='right' marginBottom={1} />
					</StyledAlertWrapper>
				</div>
			</div>
		)
	}
}

function mapStoreToProps(state) {
	return {
		dismissedBlocks: state.user.getIn(['userObj', 'siteSettings', 'dismissedBlocks']),
		user: state.user.get('userObj')
	}
}

const mapActionsToProps = {
	updateUser,
	saveUser
}

export default connect(mapStoreToProps, mapActionsToProps)(DashboardAlert)
