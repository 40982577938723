import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signout } from '../../actions/credentials.actions'
import { resetCompanyReducer, updateCompanyLocal } from '../../actions/company.actions'
import history from '../../interfaces/history'

class Signout extends Component {
	componentDidMount() {
		this.props.signout()
		this.props.resetCompanyReducer()
		this.props.updateCompanyLocal(null)

		setTimeout(() => {
			history.push('/signin')
		}, 100)
	}

	render() {
		return null
	}
}

const mapActionsToProps = {
	signout,
	resetCompanyReducer,
	updateCompanyLocal
}

export default connect(null, mapActionsToProps)(Signout)
