import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { map } from 'react-immutable-proptypes'

import LeftRightWithHeadersLayout from '../../shared/modal/standard-layouts/left-right-with-headers'
import SimpleDarkInfoPanel from '../../shared/dark-info-panel/simple-dark-info-panel'
import Text from '../../shared/text/text'
import SimpleDocListItem from '../../documents/shared/simple-doc-list-item'
import ListItem from '../../shared/list-item/list-item'

const StyledDarkInfoPanel = styled(SimpleDarkInfoPanel)`
	width: 100%;
`

const StyledHeaderText = styled(Text)`
	display: flex;
	margin-bottom: ${({ theme }) => theme.spacing[4]};
`

const StyledInfoListItem = styled(ListItem)`
	border-top: 1px solid ${({ theme }) => theme.colors.border};
	margin: 0;
	padding: 0 ${({ theme }) => theme.spacing[3]};
	display: flex;
	align-items: center;
	height: unset;
	min-height: 50px;

	:last-child {
		border-bottom: 1px solid ${({ theme }) => theme.colors.border};
	}

	> span:first-child {
		margin-right: ${({ theme }) => theme.spacing[5]};
	}
`

export default class MergedCollectionOverview extends PureComponent {
	static propTypes = {
		collection: map.isRequired
	}

	renderDocumentItem = (document, index) => {
		return <SimpleDocListItem file={document.get('file')} title={document.get('title')} key={index} />
	}

	renderMiddleHeader = () => {
		return (
			<StyledDarkInfoPanel>
				<Text tid='collections.manage_shared.merged_details.alert.info' color='white' />
			</StyledDarkInfoPanel>
		)
	}

	renderLeftContent = () => {
		const { collection } = this.props
		const documents = collection.get('documents')

		return (
			<>
				<StyledHeaderText tid='collections.manage_shared.merged_details.documents.header' />
				{documents.map(this.renderDocumentItem)}
			</>
		)
	}

	renderRightContent = () => {
		const { collection } = this.props
		const title = collection.get('mergedDocumentTitle')
		const folder = collection.get('mergedDocumentFolder')

		return (
			<>
				<StyledInfoListItem>
					<Text tid='generic.name' nowrap />
					<Text>{title}</Text>
				</StyledInfoListItem>
				<StyledInfoListItem>
					<Text tid='folder' nowrap />
					{folder ? <Text>{folder}</Text> : <Text tid='collections.merge_pdf.modal.overview.folders.root' />}
				</StyledInfoListItem>
			</>
		)
	}

	render = () => {
		return (
			<LeftRightWithHeadersLayout
				middleHeader={this.renderMiddleHeader()}
				leftContent={this.renderLeftContent()}
				rightContent={this.renderRightContent()}
			/>
		)
	}
}
