import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

const StyledUl = styled.ul`
	margin: ${(props) => props.theme.spacing[3]} 0;
	padding-left: 24px;
`

export default class Ul extends PureComponent {
	static propTypes = {
		className: string
	}

	render = () => {
		const { className, children } = this.props
		return <StyledUl className={className}>{children}</StyledUl>
	}
}
