import React, { Component } from 'react'
import { connect } from 'react-redux'
import { object, string, func, bool } from 'prop-types'
import DropdownMenuContainer from '../shared/dropdown-menu.container'
import DropdownIconItem from '../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import DropdownItem from '../../dumb-components/shared/dropdown-item/dropdown-item'
import { deleteTask, deleteExternalTask, updateTaskLocal, saveTask, recoverTask } from '../../actions/tasks.actions'
import { addErrorNotification, addInfoNotification } from '../../actions/notify.actions'
import { fetchMultipleMeetings } from '../../actions/meetings.actions'
import Toolbar from '../../dumb-components/shared/toolbar/toolbar'
import Tooltip from '../../dumb-components/shared/tooltip/tooltip'
import { openModal, closeModal } from '../../actions/modals.actions'
import { MEETINGS_LINK_MODAL, TASK_MOVE_TASK_MODAL } from '../../constants/modals'
import { List, Map } from 'immutable'
import MeetingLinkModalContainer from '../meetings/meeting-link/meeting-link-modal.container'
import { OBJ_TYPE_MEETING } from '/shared/constants'
import history from '../../interfaces/history'

const UNLINK_MEETING_TOOLTIP_STATES = {
	unlinkFromMeetingDisabled: {
		tid: 'tasks.unlink_task_from_meeting.tooltip.persistent_link'
	},
	hasNoPermissions: {
		tid: 'tooltip.default.has_no_permissions',
		delayShow: 'instant'
	}
}

const DELETE_TASKS_TOOLTIP_STATES = {
	default: {
		tid: 'tasks.toolbar.tooltip.trash_task'
	}
}

class ToolbarContainer extends Component {
	static propTypes = {
		scrollbarRef: object,
		blockRefs: object,
		basePath: string,
		querystr: string,
		onItemClick: func,
		isExternal: bool,
		onDeleteTask: func,
		canDeleteTask: bool
	}

	static defaultProps = {
		isExternal: false,
		canDeleteTask: false
	}

	doDeleteTask = () => {
		const { deleteTask, deleteExternalTask, task, isExternal, onDeleteTask } = this.props
		const taskId = task.get('id')

		// Close dropdown
		this.moreActionBtnRef && this.moreActionBtnRef.onToggleMenu()

		if (isExternal) {
			const objId = task.get('creatorObjId')
			deleteExternalTask(objId, taskId, () => {
				this.redirectToBasePath()
				onDeleteTask && onDeleteTask(taskId)
			})
		} else {
			deleteTask(taskId, () => {
				this.redirectToBasePath()
				onDeleteTask && onDeleteTask(taskId)
			})
		}
	}

	redirectToBasePath = () => {
		const { basePath, querystr, history } = this.props
		history.push({
			pathname: basePath,
			search: querystr
		})
	}

	onClick = (block) => {
		const { blockRefs, scrollbarRef, onItemClick } = this.props
		const panelId = blockRefs && blockRefs[block] && blockRefs[block]._panel ? blockRefs[block]._panel.props.id : null

		if (!panelId) {
			return null
		}

		const panelNode = document.getElementById(panelId)
		scrollbarRef.scrollTop(panelNode.offsetTop - 8)
		onItemClick && onItemClick(block)
	}

	openMeetingLinkModal = () => {
		const { openModal } = this.props
		openModal(MEETINGS_LINK_MODAL)
	}

	closeMeetingLinkModel = () => {
		const { closeModal } = this.props
		closeModal(MEETINGS_LINK_MODAL)
	}

	linkToMeeting = (selectedMeetingId) => {
		const { updateTaskLocal, saveTask, fetchMultipleMeetings } = this.props
		let { task } = this.props

		task = task.update('links', (links) => {
			const meetingLinkIndex = links.findIndex((obj) => obj.get('objType') === OBJ_TYPE_MEETING)

			if (meetingLinkIndex >= 0) {
				return links.setIn([meetingLinkIndex, 'objId'], selectedMeetingId)
			}

			return links.push(
				Map({
					objId: selectedMeetingId,
					objType: OBJ_TYPE_MEETING
				})
			)
		})

		updateTaskLocal(task)
		saveTask(task)

		// fetchMultipleMeetings([selectedMeetingId])

		this.closeMeetingLinkModel()
	}

	unlinkFromMeeting = () => {
		const { updateTaskLocal, saveTask, addInfoNotification } = this.props
		let { task } = this.props
		const meetingLinkIndex = task && task.get('links').findIndex((obj) => obj.get('objType') === OBJ_TYPE_MEETING)
		task = task.removeIn(['links', meetingLinkIndex])

		updateTaskLocal(task)
		saveTask(task)
		addInfoNotification({
			tid: 'tasks.unlink_task_from_meeting.notification.message'
		})

		// Close dropdown
		this.moreActionBtnRef && this.moreActionBtnRef.onToggleMenu()
	}

	onOpenMoveTaskModal = () => {
		this.props.openModal(TASK_MOVE_TASK_MODAL)
	}

	recoverTask = () => {
		const { task, recoverTask } = this.props

		// Close dropdown
		this.moreActionBtnRef && this.moreActionBtnRef.onToggleMenu()

		recoverTask(task.get('id'), this.redirectToBasePath)
	}

	renderMoreActions = () => {
		const { task, canDeleteTask } = this.props
		const hasDeletePermissions = task ? task.get('ALLOW_DELETE') || canDeleteTask : false
		const taskIsReadOnly = task ? !task.get('ALLOW_UPDATE') : false
		const meetingLink =
			task && task.has('links') && task.get('links', List()).find((obj) => obj.get('objType') === OBJ_TYPE_MEETING)
		const linkToMeetingDisabled = meetingLink ? true : false
		const unlinkFromMeetingDisabled = task && task.get('creatorObjId')
		const unlinkFromMeetingTooltipState = taskIsReadOnly
			? 'disabled'
			: unlinkFromMeetingDisabled && 'unlinkFromMeetingDisabled'
		const isDeleted = task && task.get('isDeleted')
		const trashTaskActiveState = !taskIsReadOnly ? 'default' : 'hasNoPermissions'

		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisH'
				halignMenu='right'
				btnMode='transparent-icon'
				ref={(ref) => (this.moreActionBtnRef = ref)}
				noMaxWidth={true}
				tooltipActiveState='btnMoreActions'>
				{!meetingLink && (
					<Tooltip activeState='hasNoPermissions' active={taskIsReadOnly}>
						<DropdownIconItem
							tid='tasks.link_task_to_meeting'
							icon='faLink'
							onClick={this.openMeetingLinkModal}
							disabled={taskIsReadOnly || linkToMeetingDisabled}
						/>
					</Tooltip>
				)}
				{meetingLink && (
					<Tooltip states={UNLINK_MEETING_TOOLTIP_STATES} activeState={unlinkFromMeetingTooltipState}>
						<DropdownIconItem
							tid='tasks.unlink_task_from_meeting'
							icon='faLink'
							onClick={this.unlinkFromMeeting}
							disabled={taskIsReadOnly || unlinkFromMeetingDisabled}
						/>
					</Tooltip>
				)}

				<Tooltip activeState='hasNoPermissions' active={taskIsReadOnly}>
					<DropdownIconItem
						tid='tasks.task.toolbar.item.move_task'
						icon='faCodeBranch'
						disabled={taskIsReadOnly}
						onClick={this.onOpenMoveTaskModal}
					/>
				</Tooltip>

				<DropdownItem divider />

				{!isDeleted && (
					<Tooltip activeState={trashTaskActiveState} states={DELETE_TASKS_TOOLTIP_STATES}>
						<DropdownIconItem
							tid='tasks.delete_task'
							icon='faTrashAlt'
							disabled={!hasDeletePermissions || taskIsReadOnly}
							onClick={this.doDeleteTask}
						/>
					</Tooltip>
				)}

				{isDeleted && (
					<Tooltip activeState={taskIsReadOnly && 'hasNoPermissions'}>
						<DropdownIconItem
							tid='tasks.recover_task'
							icon='faTrashUndoAlt'
							disabled={!hasDeletePermissions || taskIsReadOnly}
							onClick={this.recoverTask}
						/>
					</Tooltip>
				)}
			</DropdownMenuContainer>
		)
	}

	renderMeetingLinkModal = () => {
		const { task, activeModal } = this.props
		const isOpen = activeModal && activeModal.get('name') === MEETINGS_LINK_MODAL
		const meetingLink =
			task && task.has('links') && task.get('links', List()).find((obj) => obj.get('objType') === OBJ_TYPE_MEETING)

		return (
			<MeetingLinkModalContainer
				selectedMeeting={meetingLink ? meetingLink.get('objId') : ''}
				isOpen={isOpen}
				onSave={this.linkToMeeting}
				onCancel={this.closeMeetingLinkModel}
			/>
		)
	}

	render = () => {
		const { params } = this.props

		if (!params || !params.id) {
			return <Toolbar />
		}

		return (
			<Toolbar>
				{this.renderMoreActions()}
				{this.renderMeetingLinkModal()}
			</Toolbar>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		task: store.tasks.get('task'),
		activeModal: store.modals.get('activeModal')
	}
}

const mapActionsToProps = {
	deleteTask,
	deleteExternalTask,
	openModal,
	closeModal,
	updateTaskLocal,
	saveTask,
	addErrorNotification,
	addInfoNotification,
	fetchMultipleMeetings,
	recoverTask
}

export default connect(mapStoreToProps, mapActionsToProps)(ToolbarContainer)
