import React, { PureComponent } from 'react'
import { object, func } from 'prop-types'

import uploadImage from '../../../../assets/images/onboarding/documents-upload-file.png'
import eSignImage from '../../../../assets/images/onboarding/documents-e-signing.png'

import Button from '../../../shared/button/button'
import LeftRightLayout, { Header, Entry } from '../sub-modules/left-right-layout'
import CenterLeftRightLayout, { Column } from '../sub-modules/centered-left-right-layout'

class FirstStepBody extends PureComponent {
	static propTypes = {
		openUpload: func,
		showEsignInfo: func
	}

	render = () => {
		const { openUpload, showEsignInfo } = this.props

		return (
			<CenterLeftRightLayout>
				<Column
					image={uploadImage}
					title='onboarding.modals.documents.intro.upload.title'
					subtitle='onboarding.modals.documents.intro.upload.subtitle'
					text='onboarding.modals.documents.intro.upload.info'
					bottomComponent={
						<Button
							minWidth='100px'
							onClick={openUpload}
							color='white'
							mode='primary'
							tid='onboarding.modals.documents.intro.upload.btn'
						/>
					}
				/>
				<Column
					image={eSignImage}
					title='onboarding.modals.documents.intro.esign.title'
					subtitle='onboarding.modals.documents.intro.esign.subtitle'
					text='onboarding.modals.documents.intro.esign.info'
					bottomComponent={
						<Button
							minWidth='100px'
							onClick={showEsignInfo}
							mode='primary'
							color='white'
							tid='onboarding.modals.documents.intro.esign.btn'
						/>
					}
				/>
			</CenterLeftRightLayout>
		)
	}
}

class SecondStepBody extends PureComponent {
	static propTypes = {
		billingButton: object
	}

	render = () => {
		const { billingButton } = this.props

		return (
			<LeftRightLayout image={eSignImage}>
				<Header tid='onboarding.modals.documents.intro.esign.more.header' />

				<Entry
					titleTid='onboarding.modals.documents.intro.esign.more.subheader.1'
					subTextTid='onboarding.modals.documents.intro.esign.more.subinfo.1'
				/>

				<Entry
					titleTid='onboarding.modals.documents.intro.esign.more.subheader.2'
					subTextTid='onboarding.modals.documents.intro.esign.more.subinfo.2'
				/>

				<Entry
					titleTid='onboarding.modals.documents.intro.esign.more.subheader.3'
					subTextTid='onboarding.modals.documents.intro.esign.more.subinfo.3'
					bottomComponent={billingButton}
				/>
			</LeftRightLayout>
		)
	}
}

export { FirstStepBody, SecondStepBody }
