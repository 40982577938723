import React from 'react'
import { func, string, bool } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import styled from 'styled-components'
import DropdownMenu from '../dropdown-menu/dropdown-menu'
import DropdownItem from '../dropdown-item/dropdown-item'
import Text from '../text/text'
import Button from '../button/button'

export const StyledButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background-color: ${(props) => props.theme.colors.white};
	width: 100%;
	height: 100%;
	padding-left: 2px;
	padding-right: 2px;
	box-shadow: -1px -1px 2px 0 rgba(0, 0, 0, 0.1);
`

const renderOption = (formatOption, optionsTranslated, optionTid, onOptionClick, selected, option, index) => {
	let optionLabel

	if (optionsTranslated) {
		optionLabel = (
			<Text tag='span' bold={600}>
				{option.get('label')}
			</Text>
		)
	} else {
		optionLabel = (function () {
			return <Text tag='span' bold={600} tid={option.get('label')} />
		})()
	}

	return (
		<DropdownItem onClick={() => onOptionClick && onOptionClick(option.get('value'))} key={index}>
			{!formatOption && <Text tid={optionTid} values={{ status: optionLabel }} />}
			{formatOption && formatOption(option)}
			{!formatOption && !optionTid && <Text>{optionLabel}</Text>}
		</DropdownItem>
	)
}

const StatusButton = (props) => {
	const {
		options,
		selectedOption,
		optionTid,
		btnTid,
		isOpen,
		onToggleMenu,
		onOptionClick,
		onBtnClick,
		optionsTranslated,
		formatOption,
		disabled,
		dropdownDisabled,
		onRef,
		noDropdown
	} = props
	let selectedOptionLabel

	if (optionsTranslated) {
		selectedOptionLabel = (
			<Text tag='span' bold={600} color='white'>
				{selectedOption.get('label')}
			</Text>
		)
	} else {
		selectedOptionLabel = (function () {
			return <Text tag='span' bold={600} color='white' tid={selectedOption.get('label')} />
		})()
	}

	return (
		<StyledButtonWrapper ref={onRef}>
			{noDropdown && <Button minWidth='200px' mode='dark' onClick={onBtnClick} disabled={disabled} tid={btnTid} />}

			{!noDropdown && (
				<DropdownMenu
					isOpen={isOpen}
					btnTid={btnTid}
					btnTextColor='white'
					btnIcon='faCaretUp'
					btnIconColor='white'
					btnMode='dark'
					onToggleMenu={onToggleMenu}
					halignMenu='right'
					direction='up'
					minWidth='202px'
					buttonMinWidth='200px'
					disabled={disabled && dropdownDisabled}
					inline>
					{options &&
						options.map(
							renderOption.bind(
								null,
								formatOption,
								optionsTranslated,
								optionTid,
								onOptionClick,
								selectedOption.get('value')
							)
						)}
				</DropdownMenu>
			)}
		</StyledButtonWrapper>
	)
}

StatusButton.propTypes = {
	options: immutablePropTypes.list,
	selectedOption: immutablePropTypes.map,
	optionTid: string,
	btnTid: string,
	isOpen: bool,
	onToggleMenu: func,
	onOptionClick: func,
	onBtnClick: func,
	optionsTranslated: bool,
	formatOption: func,
	disabled: bool,
	onRef: func,
	dropdownDisabled: bool,
	noDropdown: bool
}

StatusButton.defaultProps = {
	isOpen: false,
	optionsTranslated: false,
	disabled: false,
	dropdownDisabled: true
}

export default StatusButton
