import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'

import { activateUserDemo, deactivateUserDemo, resetCompany } from '../../../actions/company.actions'
import { notifyDemoIsLoading } from '../../../actions/notify.actions'

import history from '../../../interfaces/history'
import DemoItem from '../../../dumb-components/company/nav-dropdown/demo-item'

class DemoItemContainer extends Component {
	state = {
		isActive: false,
		urlAlias: null,
		imageUrl: null
	}

	static propTypes = {
		onSelect: func
	}

	componentDidMount = () => {
		this.setDemoCompanyData()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.companies !== this.props.companies) {
			this.setDemoCompanyData()
		}
	}

	setDemoCompanyData = () => {
		const { companies, userId } = this.props

		const demoCompany = companies.find((company) => {
			return company.get('isUserDemo') && company.get('superUser') === userId
		})

		const isActive = demoCompany ? true : false
		const urlAlias = demoCompany ? demoCompany.get('urlAlias') : null

		const filename = demoCompany && demoCompany.getIn(['image', 'thumbnail', 'filename'])
		const companyId = demoCompany && demoCompany.get('id')
		const imageUrl = filename ? `/api/companies/public/images/${filename}-100?companyId=${companyId}` : null
		this.setState({ isActive, urlAlias, imageUrl })
	}

	getUserDemoIsSelected = () => {
		const { urlAlias } = this.props
		const { href } = window.location

		return href.includes(urlAlias)
	}

	toggleUserDemo = () => {
		const { activateUserDemo, deactivateUserDemo, selectedUrlAlias, resetCompany, notifyDemoIsLoading, history } = this.props
		const { isActive, urlAlias } = this.state

		notifyDemoIsLoading(true)

		// If demo company is selected, navigate to dashboard
		if (selectedUrlAlias === urlAlias) {
			history.replace('/')
			resetCompany()
		}

		if (isActive) {
			deactivateUserDemo((e) => {
				if (e) {
					notifyDemoIsLoading(false)
					return
				}

				notifyDemoIsLoading(false)
				this.setState({ isActive: !isActive })
			})
		} else {
			activateUserDemo((e) => {
				if (e) {
					notifyDemoIsLoading(false)
					return
				}

				notifyDemoIsLoading(false)
				this.setState({ isActive: !isActive })
			})
		}
	}

	onSelect = () => {
		const { onSelect } = this.props
		const { urlAlias } = this.state
		onSelect && onSelect(urlAlias)
	}

	render = () => {
		const { demoIsLoading } = this.props
		const { isActive, imageUrl } = this.state

		return (
			<DemoItem
				isActive={isActive}
				isLoading={demoIsLoading}
				imageUrl={imageUrl}
				onSelect={this.onSelect}
				onToggle={this.toggleUserDemo}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	const { company } = store.company
	return {
		history: history,
		companies: store.company.companies,
		userId: store.user.getIn(['userObj', 'id']),
		selectedUrlAlias: company && company.urlAlias,
		demoIsLoading: store.notify.demoIsLoading
	}
}

const mapActionsToProps = {
	activateUserDemo,
	deactivateUserDemo,
	resetCompany,
	notifyDemoIsLoading
}

export default connect(mapStoreToProps, mapActionsToProps)(DemoItemContainer)
