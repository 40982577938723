import { fromJS } from 'immutable'

import { MODALS_OPEN, MODALS_CLOSE, HIDE_MODAL, SHOW_MODAL } from './types'

export function openModal(modalName, options, multiple) {
	const multipleModals = multiple ? true : false

	return function (dispatch) {
		dispatch({ type: MODALS_OPEN, payload: fromJS({ modalName, options, multipleModals }) })
	}
}

export function closeModal() {
	return function (dispatch) {
		dispatch({ type: MODALS_CLOSE })
	}
}

export function hideModal() {
	return function (dispatch) {
		dispatch({ type: HIDE_MODAL })
	}
}

export function showWikiModal(id, lang, text) {
	return {
		type: SHOW_MODAL,
		payload: {
			modalType: 'WIKI_EDIT_CONTENT',
			modalProps: {
				id: id,
				lang: lang,
				text: text
			}
		}
	}
}
