import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import InvestorDocumentsContainer from '../../../containers/documents/documents-investor-view.container'
import { InvestmentsContext } from '../investment.container'
import Link from '@mui/material/Link'
import { openModal } from '../../../actions/modals.actions'
import { useDispatch, useSelector } from 'react-redux'
import { CAP_TABLE_READ_MORE_MODAL } from '../../../constants/modals'
import { ReadMoreModal } from '../modals/read-more-modal'

export default function InvestmentDocumentsContainer({ match }) {
	const { selectedInvestment, isShareholderPreview, isCompany } = useContext(InvestmentsContext)
	const companyName = selectedInvestment.getIn(['investedInCompanyInformation', 'name'])

	const isReadMoreModalOpen = useSelector(
		(state) => state.modals.getIn(['activeModal', 'name']) === CAP_TABLE_READ_MORE_MODAL
	)

	const dispatch = useDispatch()

	const openReadMoreModal = () => {
		dispatch(openModal(CAP_TABLE_READ_MORE_MODAL))
	}

	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1', gap: '16px', mb: '16px' }}>
				<Box>
					<Card extrapadding={'true'} borderradius={'true'}>
						<CardContent>
							<Typography variant='body1'>{companyName}</Typography>
							<Typography variant='body2'>
								<FormattedMessage
									id={'portfolio.investments.my_records.intro_card.paragraph'}
									values={{
										link: (
											<Link
												sx={{ pb: 0.3 }}
												onClick={openReadMoreModal}
												component={'button'}
												color={'positive.main'}>
												<FormattedMessage id={'investments.information_panel.link.read_more'} />
											</Link>
										)
									}}
								/>
							</Typography>
						</CardContent>
					</Card>
				</Box>
				<Box sx={{ flex: '1' }}>
					<InvestorDocumentsContainer
						captableMode={true}
						isCompany={isCompany}
						params={match.params}
						selectedInvestment={selectedInvestment}
						isShareholderPreview={isShareholderPreview}
					/>
				</Box>
			</Box>
			{isReadMoreModalOpen && (
				<ReadMoreModal
					title={'investments.documents.white_panel.read_more.title'}
					contentFile='shareholder-documents.txt'
				/>
			)}
		</>
	)
}
