import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool, object } from 'prop-types'
import { Map, List } from 'immutable'

import { fetchSimpleUsers } from '../../../actions/usersCache.actions'

import ProtocolFeedbackPanel from '../../../dumb-components/meetings/protocol-feedback-panel/protocol-feedback-panel'
import AttendeesList from '../../../dumb-components/meetings/attendees-list/attendees-list'
import AttendeeItemContainer from '../attendees/attendee-item.container'
import FeedbackPanelControlsContainer from './feedback-panel-controls.container'
import FeedbackeeItemDropdownContainer from './feedbackee-item-dropdown.container'
import RequestFeedbackButton from './request-feedback-button.container'
import history from '../../../interfaces/history'

class ProtocolFeedbackPanelContainer extends Component {
	static propTypes = {
		showSummary: bool,
		basePath: string,
		params: object,
		querystr: string
	}

	componentDidMount = () => {
		const { feedbackees } = this.props

		if (feedbackees) {
			this.fetchUsers()
		}
	}

	componentDidUpdate = (prevProps) => {
		const { feedbackees } = this.props

		if (!prevProps.feedbackees || (feedbackees && feedbackees.size !== prevProps.feedbackees.size)) {
			this.fetchUsers()
		}
	}

	fetchUsers = () => {
		const { fetchSimpleUsers, feedbackees } = this.props
		const userIds = feedbackees?.filter((a) => !a.isGuest && !a.isInvestor)?.map((a) => a.get('userId'))

		if (userIds) {
			fetchSimpleUsers(userIds.toList())
		}
	}

	selectFeedbackee = (id) => {
		const { basePath, querystr, history } = this.props

		history.push({
			pathname: `${basePath}/${id}`,
			search: querystr
		})
	}

	renderRightComponent = () => {
		return <FeedbackPanelControlsContainer />
	}

	renderFeedbackeeDropdown = (feedbackeeId) => {
		return <FeedbackeeItemDropdownContainer feedbackeeId={feedbackeeId} />
	}

	renderFeedbackeeItem = (props) => {
		const {
			feedbackees,
			signees,
			chairman,
			secretary,
			hasExtendedRights,
			feedbackeesWarning,
			protocolPublished
		} = this.props

		const signingInProgress =
			signees && signees.size > 0 && signees.find((s) => s.get('active') === true && s.get('status') === 'NOT-SIGNED')
				? true
				: false
		const alreadySigned =
			signees && signees.size > 0 && signees.find((s) => s.get('status') === 'SIGNED') ? true : false
		const alreadyLeftFeedback = props.status === true
		let tooltipTid

		if (alreadyLeftFeedback) {
			tooltipTid = 'meetings.protocol.feedback.list.tooltip.alread_left_feedback'
		} else if (signingInProgress) {
			tooltipTid = 'meetings.protocol.feedback.list.tooltip.signing_in_progress'
		}

		return (
			<AttendeeItemContainer
				{...props}
				chairman={chairman}
				secretary={secretary}
				readOnly={!hasExtendedRights}
				hasExtendedRights={hasExtendedRights}
				onClick={this.selectFeedbackee}
				attendee={feedbackees.get(props.userId)}
				feedbackeesWarning={feedbackeesWarning}
				// switchDisabled={signingInProgress || alreadySigned || alreadyLeftFeedback || protocolPublished}
				renderMoreActionButton={this.renderFeedbackeeDropdown}
				context='feedback'
				clickable
			/>
		)
	}

	renderFeedbackeesList = () => {
		const { feedbackees, chairman, secretary, basePath, params } = this.props
		let sortedFeedbackees =
			feedbackees &&
			feedbackees.sortBy((item) => {
				if (item.get('userId') === chairman) {
					return -3
				} else if (item.get('userId') === secretary) {
					return -2
				}
				return 0
			})
		sortedFeedbackees = sortedFeedbackees.toList()

		return (
			<AttendeesList
				attendees={sortedFeedbackees}
				renderItemComponent={this.renderFeedbackeeItem}
				basePath={basePath}
				currentUrl={`${basePath}/${params.feedbackeeId}`}
			/>
		)
	}

	renderSummaryFeedbackeesList = () => {
		return <span>summary</span>
	}

	renderBottomComponent = () => {
		const { showSummary } = this.props

		if (showSummary) {
			return null
		}

		return <RequestFeedbackButton />
	}

	render = () => {
		const { feedbackees, showSummary } = this.props
		return (
			<ProtocolFeedbackPanel
				feedbackees={feedbackees}
				showSummary={showSummary}
				headerRightComponent={this.renderRightComponent()}
				feedbackeesListComponent={this.renderFeedbackeesList()}
				summaryFeedbackeesListComponent={this.renderSummaryFeedbackeesList()}
				bottomComponent={this.renderBottomComponent()}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		feedbackees: store.meetings.getIn(['meeting', 'feedbackees'], Map()),
		signees: store.meetings.getIn(['meeting', 'signees'], Map()),
		chairman: store.meetings.getIn(['meeting', 'chairman']),
		secretary: store.meetings.getIn(['meeting', 'secretary']),
		hasExtendedRights: store.meetings.getIn(['meeting', 'computedValues', 'hasExtendedRights']),
		feedbackeesWarning: store.meetings.getIn(['meeting', 'computedValues', 'feedbackeesWarning']),
		protocolPublished: store.meetings.getIn(['meeting', 'computedValues', 'protocolPublished'])
	}
}

const mapActionsToProps = {
	fetchSimpleUsers
}

export default connect(mapStoreToProps, mapActionsToProps)(ProtocolFeedbackPanelContainer)
