/* global google */
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { bool, object } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const StyledLoadingElement = styled.div`
	height: 100%;
`

const StyledContainerElement = styled.div`
	height: 400px;
`

const StyledMapElement = styled.div`
	height: 100%;
`

const MyMapComponent = withGoogleMap(({ showMarker, marker, options, lat, lng }) => (
	<GoogleMap defaultZoom={15} options={options} center={{ lat, lng }} scaleControl={false} zoomControl={false}>
		{showMarker && <Marker position={marker.position} />}
	</GoogleMap>
))

export default class ReactGoogleMap extends PureComponent {
	static propTypes = {
		location: map,
		showMarker: bool,
		options: object
	}

	static defaultProps = {
		showMarker: true,
		options: {
			mapTypeControl: false,
			streetViewControl: true,
			scrollwheel: false
		}
		//location: Map({lat: 56.8334979, lng: 13.942328399999951}) // Kungsgatan 3 Ljungby :)
	}

	render = () => {
		const { showMarker, location, options } = this.props

		if (!location) {
			return null
		}

		const myLatlng = new google.maps.LatLng(location.get('lat'), location.get('lng'))
		const marker = new google.maps.Marker({
			position: myLatlng
		})

		return (
			<MyMapComponent
				showMarker={showMarker}
				marker={marker}
				options={options}
				lat={location.get('lat')}
				lng={location.get('lng')}
				googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places'
				loadingElement={<StyledLoadingElement />}
				containerElement={<StyledContainerElement />}
				mapElement={<StyledMapElement />}
			/>
		)
	}
}
