import React, { PureComponent } from 'react'
import { string, bool, func } from 'prop-types'
import styled from 'styled-components'
import { StyledInputTemplate } from '../input/input.template'
import ReactGeosuggest from 'react-geosuggest'
import { withTranslation } from 'react-i18next'
import { Map } from 'immutable'

const StyledReactGeosuggest = styled(ReactGeosuggest)`
	.geosuggest {
		position: relative;
		text-align: left;
	}

	.geosuggest__input {
		${StyledInputTemplate};
	}

	.geosuggest__suggests-wrapper {
		position: relative;
	}

	.geosuggest__suggests {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		max-height: 25em;
		padding: 0;
		background: #fff;
		border: 1px solid #c3c3c3;
		border-top-width: 0;
		overflow-x: hidden;
		overflow-y: auto;
		list-style: none;
		z-index: 5;
	}

	.geosuggest__suggests--hidden {
		max-height: 0;
		overflow: hidden;
		border-width: 0;
	}

	.geosuggest__item {
		display: flex;
		align-items: center;
		padding: 8px 12px;
		min-height: 36px;
		cursor: pointer;
	}

	/* Mouse Hover */
	.geosuggest__item:hover,
	.geosuggest__item:focus {
		background-color: ${(props) => props.theme.colors.solitudeLight};
	}

	/* Currently Selected */
	.geosuggest__item--active {
		background-color: ${(props) => props.theme.colors.solitudeLight};
	}

	.geosuggest__item--active:hover,
	.geosuggest__item--active:focus {
		background-color: ${(props) => props.theme.colors.solitudeLight};
	}

	.geosuggest__item__matched-text {
		font-weight: bold;
	}
`

class LocationSuggestInput extends PureComponent {
	state = {
		inputValue: null
	}

	static propTypes = {
		disabled: bool,
		placeholderTid: string,
		onChange: func,
		value: string,
		autoActivateFirstSuggest: bool
	}

	static defaultProps = {
		disabled: false,
		autoActivateFirstSuggest: true,
		value: ''
	}

	onSuggestSelect = (obj) => {
		const { fieldName, onChange } = this.props
		const locationDataToReturn = obj
			? Map({ label: obj.gmaps ? obj.gmaps.formatted_address : obj.label, location: obj.location })
			: Map()

		if (fieldName) {
			onChange(fieldName, locationDataToReturn)
			return
		}

		onChange(locationDataToReturn)
	}

	onInputChanged = (inputValue) => {
		this.setState({ inputValue })
	}

	onBlur = () => {
		const { value } = this.props
		const { inputValue } = this.state

		if (value !== inputValue) {
			this.geoSuggestRef.update(value)
		}
	}

	render = () => {
		const { t, disabled, autoActivateFirstSuggest, placeholderTid, value } = this.props
		const placeholder = placeholderTid && t(placeholderTid)

		return (
			<StyledReactGeosuggest
				ref={(r) => (this.geoSuggestRef = r)}
				onBlur={this.onBlur}
				disabled={disabled}
				onChange={this.onInputChanged}
				autoActivateFirstSuggest={autoActivateFirstSuggest}
				placeholder={placeholder}
				onSuggestSelect={this.onSuggestSelect}
				initialValue={value}
			/>
		)
	}
}

export default withTranslation()(LocationSuggestInput)
