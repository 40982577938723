import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, bool } from 'prop-types'
import { Map } from 'immutable'
import DocumentHelper from '../../components/helpers/documents.helper'
import { closeModal } from '../../actions/modals.actions'
import { DOCUMENT_ESIGN_USERS_MODAL } from '../../constants/modals'
import { PeopleModalProvider } from '../shared/people-modal/people-modal.provider'
import { PeopleModalContainer } from '../shared/people-modal/people-modal.container'
import { PeopleModalHeader, getDefaultTabs } from '../shared/people-modal/people-modal-header'
import { PeopleModalFooter } from '../shared/people-modal/people-modal-footer'
import { TeamsAndInvestorsListContainer } from '../shared/people-modal/teams-and-investors-list/teams-and-investors-list.container'
import { CompanyContactsGrid } from '../shared/people-modal/company-contacts/CompanyContactsGrid'
import { PersonalContactsGrid } from '../shared/people-modal/personal-contacts/PersonalConatctsGrid'

class DocumentESignModalContainer extends Component {
	static propTypes = {
		onChange: func,
		overrideIsOpen: bool,
		overrideCloseModal: func
	}

	onSave = (eSignees) => {
		const { onChange } = this.props

		// Remove the empty user if there is one
		eSignees = eSignees.filter((user) => {
			if (user.get('isGuest') && (!user.get('name') || !user.get('email'))) {
				return false
			}

			return true
		})

		// Create Signe Objects
		eSignees = eSignees.map((user) => {
			return DocumentHelper.createESignatoryObject(user)
		})

		onChange('eSignees', eSignees)

		this.closeModal(false)
	}

	/**
	 * @param {boolean} isCanceled - true: this function was called by onCancel, false: this function was called by onSave
	 */
	closeModal = (isCanceled = true) => {
		const { closeModal, overrideCloseModal } = this.props

		if (overrideCloseModal) {
			overrideCloseModal(isCanceled)
		} else {
			closeModal()
		}
	}

	render = () => {
		const { eSignees, isOpen, overrideIsOpen } = this.props

		return (
			<PeopleModalProvider values={eSignees} isOpen={isOpen || overrideIsOpen}>
				<PeopleModalContainer
					headerComponent={() => (
						<PeopleModalHeader title='documents.esign.esignees.modal.title' tabs={getDefaultTabs()} />
					)}
					footerComponent={() => <PeopleModalFooter onSave={this.onSave} onCancel={this.closeModal} />}>
					<TeamsAndInvestorsListContainer />
					<CompanyContactsGrid />
					<PersonalContactsGrid />
				</PeopleModalContainer>
			</PeopleModalProvider>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		isOpen: store.modals.getIn(['activeModal', 'name']) === DOCUMENT_ESIGN_USERS_MODAL,
		eSignees: store.documents.getIn(['document', 'eSignees'], Map())
	}
}

const mapActionsToProps = {
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(DocumentESignModalContainer)
