import { MEETINGS_FETCH_MEETING, MEETINGS_SAVE_ATTENDEE, MEETINGS_SAVE_ATTENDEES } from './types'
import { fromJS } from 'immutable'
import req from '../../modules/request.module'
import { socketUpdateMeetingId } from './notify.actions'
import { listInvestorsArrayPublic } from './investors.actions'
import store from '../store'
import {
	EVENT_TYPE_MEETINGS_UPDATE,
	EVENT_TYPE_MEETINGS_MEETING_STARTED,
	OBJ_TYPE_MEETING_AGENDA,
	OBJ_TYPE_MEETING_ATTACHMENT,
	OBJ_TYPE_MEETING_PROTOCOL
} from '/shared/constants'
import moment from '../../modules/moment.module'
import { flattenAgendaItems } from '../../components/helpers/meeting.helper'
import history from '../../interfaces/history'

// BankID helpers
import { collect } from '../../actions/bankid-helper.actions'
import { listMultipleAttachedDocumentsPublic } from './documents.actions'

export function fetchMeeting(meetingId, companyId, userId, callback) {
	return function (dispatch) {
		return req
			.get(`/meetings/public/${meetingId}?companyId=${companyId}&sub=${userId}`)
			.then((response) => {
				response = fromJS(response.data)
				const meeting = response.get('meeting')

				if (response.get('redirectTo')) {
					history.replace(response.get('redirectTo'))
				}

				if (meeting.has('attendees') && meeting.get('attendees').size > 0) {
					const investorsAttendees = meeting.get('attendees').filter(attendee => attendee.get('isInvestor'))

					if (investorsAttendees.size > 0) {
						const investorsIds = investorsAttendees.map(attendee => attendee.get('userId')).toList()
						dispatch(listInvestorsArrayPublic(investorsIds, companyId))
					}
				}

				dispatch({ type: MEETINGS_FETCH_MEETING, payload: meeting })
				dispatch(socketUpdateMeetingId(meetingId))
				callback?.(undefined, meeting)
			})
			.catch((err) => {
				// if (err.response.status === 406 && err.response.data.translationId === 'meetings.error.user_not_allowed_attending') {
				// 	dispatch(notifySetReturnUrl(`${location.pathname}${location.search}`))
				// 	history.push({
				// 		pathname: '/public/signin',
				// 		search: '?objType=meeting'
				// 	})
				// }
				callback && callback(err)
			})
	}
}

export function addToCalendar(meetingId, userId, companyId) {
	return function () {
		window.open(`/api/meetings/public/${meetingId}/calendar?companyId=${companyId}&sub=${userId}`, '_self')
	}
}

export function saveAgendaItems(agendaItems, meetingId, companyId, userId, callback) {
	return function (dispatch) {
		return req
			.post(`/meetings/public/${meetingId}/agenda?companyId=${companyId}&sub=${userId}`, agendaItems.toJS())
			.then((response) => {
				callback && callback()
			})
			.catch((err) => {
				callback && callback()
				console.log(err)
				// TODO: show some fancy error message...
			})
	}
}

/*** PROXY -start ***/
export function saveProxy(meetingId, data, companyId, userId, callback) {
	return function (dispatch) {
		return req
			.post(`/meetings/public/${meetingId}/proxy?companyId=${companyId}&sub=${userId}`, data.toJS())
			.then((response) => {
				dispatch({ type: MEETINGS_SAVE_ATTENDEE, payload: fromJS(response.data.attendee) })
				dispatch({ type: MEETINGS_FETCH_MEETING, payload: fromJS(response.data.meeting) })
				callback && callback()
			})
			.catch((e) => {
				callback && callback(e)
			})
	}
}

export function deleteProxy(meetingId, companyId, userId, callback) {
	return function (dispatch) {
		return req
			.delete(`/meetings/public/${meetingId}/proxy?companyId=${companyId}&sub=${userId}`)
			.then((response) => {
				dispatch({ type: MEETINGS_FETCH_MEETING, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch((e) => {
				callback && callback(e)
			})
	}
}

export function confirmProxy(meetingId, proxyInviterId, attendeeData, companyId, userId, callback) {
	return function (dispatch) {
		const attendeeDataJs = attendeeData.toJS()
		return req
			.put(`/meetings/public/${meetingId}/proxy/confirm?companyId=${companyId}&sub=${userId}`, {
				...attendeeDataJs,
				proxyInviterId
			})
			.then((response) => {
				dispatch({ type: MEETINGS_FETCH_MEETING, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch((e) => {
				callback && callback(e)
			})
	}
}
/*** PROXY -end ***/

export function saveAssistants(meetingId, data, companyId, userId, callback) {
	data = data.toList()

	return function (dispatch) {
		return req
			.post(`/meetings/public/${meetingId}/assistants?companyId=${companyId}&sub=${userId}`, data.toJS())
			.then((response) => {
				dispatch({ type: MEETINGS_SAVE_ATTENDEES, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch((e) => {
				callback && callback(e)
			})
	}
}

export function verifyAttendanceViaBankId(
	meetingId,
	companyId,
	userId,
	ssn,
	getContinueBankIdCollect,
	callback,
	errorCallback
) {
	return function (dispatch) {
		const verify = (orderRef) => {
			return function (dispatch) {
				req
					.post(`/meetings/public/${meetingId}/verify-attendance/complete?companyId=${companyId}&sub=${userId}`, {
						orderRef,
						ssn
					})
					.then((response) => {
						dispatch({ type: MEETINGS_FETCH_MEETING, payload: fromJS(response.data) })
						callback && callback({ status: 'complete' })
					})
					.catch((e) => {
						errorCallback && errorCallback(e)
						console.error(e)
					})
			}
		}

		return req
			.post(`/meetings/public/${meetingId}/verify-attendance/init?companyId=${companyId}&sub=${userId}`, { ssn })
			.then((response) => {
				const { orderRef } = response.data

				if (orderRef !== 'bypass') {
					dispatch(collect(orderRef, getContinueBankIdCollect, callback, verify, errorCallback))
				} else {
					dispatch(verify(orderRef))
				}
			})
			.catch((e) => {
				errorCallback && errorCallback(e)
				console.error(e)
			})
	}
}

export function initVerifyAttendanceWithAccessCode(meetingId, companyId, userId, callback) {
	return function (dispatch) {
		return req
			.get(`/meetings/public/${meetingId}/verify-attendance/access-code/init?companyId=${companyId}&sub=${userId}`)
			.then((response) => {
				callback?.(undefined, fromJS(response.data))
			})
			.catch((e) => {
				callback?.(e)
				console.error(e)
			})
	}
}

export function completeVerifyAttendanceWithAccessCode(accessCode, meetingId, companyId, userId, callback) {
	return function (dispatch) {
		return req
			.post(
				`/meetings/public/${meetingId}/verify-attendance/access-code/complete?companyId=${companyId}&sub=${userId}`,
				{ accessCode }
			)
			.then((response) => {
				dispatch({ type: MEETINGS_FETCH_MEETING, payload: fromJS(response.data) })
				callback?.(undefined, fromJS(response.data))
			})
			.catch((e) => {
				callback?.(e)
			})
	}
}

export function saveAttendeeStatus(meetingId, companyId, userId, status, attendanceType, callback) {
	return function (dispatch) {
		return req
			.get(
				`/meetings/public/attendee/${meetingId}?companyId=${companyId}&userId=${userId}&attend=${status}&source=PUBLIC&attendanceType=${attendanceType}`
			)
			.then((response) => {
				dispatch({ type: MEETINGS_FETCH_MEETING, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch((e) => {
				callback && callback(e)
			})
	}
}

/*export function saveAttendee(meetingId, attendee) {
	return function (dispatch) {
		dispatch({type: MEETINGS_SAVE_ATTENDEE, payload: attendee});

		return req.put(`/agendas/public/${meetingId}/attendees/${attendee.get('id')}`).catch((err) => {
			console.log(err);
			// TODO: show some fancy error message...
		});
	};
}*/

export function socketEventMeetings(eventObject) {
	const { eventName, metadata, objId, data } = eventObject

	return function (dispatch, getState) {
		switch (eventName) {
			case EVENT_TYPE_MEETINGS_UPDATE:
			case EVENT_TYPE_MEETINGS_MEETING_STARTED:
				{
					const state = getState()
					const meeting = state.meetings.getIn(['meeting'])
					const userId = state.users.get('userId')
					const companyId = state.company.company.id
					const meetingId = meeting.get('id')
					const startDate = meeting.get('startDate')
					const isStartDateChanged = data && !moment(startDate).isSame(moment(data.startDate))
					const isMeetingStarted = eventName === EVENT_TYPE_MEETINGS_MEETING_STARTED

					if (meetingId === objId && (isStartDateChanged || isMeetingStarted)) {
						dispatch(
							fetchMeeting(meetingId, metadata?.companyId, userId, () => {
								const standardMeetingObjIds = [
									meetingId,
									`${meetingId}$${OBJ_TYPE_MEETING_AGENDA}`,
									`${meetingId}$${OBJ_TYPE_MEETING_ATTACHMENT}`,
									`${meetingId}$${OBJ_TYPE_MEETING_PROTOCOL}`
								]

								if (meeting.has('agendaItems')) {
									const agendaItems = flattenAgendaItems(meeting)
									let agendaItemObjIds = agendaItems.map((item) => item.get('objId'))
									agendaItemObjIds = agendaItemObjIds ? agendaItemObjIds.toJS() : []
									const objIds = standardMeetingObjIds.concat(agendaItemObjIds)
									dispatch(listMultipleAttachedDocumentsPublic(objIds, companyId, userId))
								} else {
									dispatch(listMultipleAttachedDocumentsPublic(standardMeetingObjIds, companyId, userId))
								}
							})
						)
					}
				}
				break
		}
	}
}
