import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func } from 'prop-types'
import Modal from '../../dumb-components/shared/modal/modal'
import FooterRightControls from '../../dumb-components/shared/modal/footer-right-controls'
import TransparentButton from '../../dumb-components/shared/button-v2/src/buttons/transparent-button'
import AddCapitalInsurancePanel from '../../dumb-components/shares/add-capital-insurance/add-capital-insurance-panel'
import SynaSearchContainer from '../syna/search.container'
import { Map } from 'immutable'

class CapitalInsuranceModalContainer extends Component {
	state = {
		isLoading: false,
		selectedCompany: Map()
	}

	static propTypes = {
		isModalOpen: bool,
		closeModal: func
	}

	componentDidUpdate = (prevProps) => {
		const { isModalOpen } = this.props

		if (prevProps.isModalOpen !== isModalOpen && !isModalOpen) {
			this.setState({ selectedCompany: Map() })
		}
	}

	synaSearchRef = null

	onChangeSynaSearch = (selectedCapitalInsurance) => {
		this.setState({ selectedCompany: selectedCapitalInsurance })
	}

	renderSynaContainer = () => {
		console.log('RenderSynaContainer')

		return (
			<SynaSearchContainer onChange={this.onChangeSynaSearch} onSetRef={(r) => (this.synaSearchRef = r)} withPortal />
		)
	}

	renderModalFooter = () => {
		const { closeModal, companyId } = this.props
		const { selectedCompany, isLoading } = this.state

		return (
			<FooterRightControls>
				<TransparentButton
					tid='shareholders.capital_insurance_modal.footer.btn.save_and_close'
					onClick={() => {
						closeModal(companyId, selectedCompany)
					}}
					isLoading={isLoading}
				/>
				<TransparentButton
					tid='shareholders.capital_insurance_modal.footer.btn.cancel'
					textColor='midGrey'
					onClick={closeModal}
					disabled={isLoading}
				/>
			</FooterRightControls>
		)
	}

	renderInsuranceCompanyModal = () => {
		const { isModalOpen } = this.props
		const { selectedCompany } = this.state

		return (
			<Modal
				hSize='md'
				isOpen={isModalOpen}
				title='shareholders.capital_insurance_modal.title'
				footerComponent={this.renderModalFooter()}>
				{<AddCapitalInsurancePanel synaComponent={this.renderSynaContainer} selectedCompany={selectedCompany} />}
			</Modal>
		)
	}

	render = () => {
		return this.renderInsuranceCompanyModal()
	}
}

const mapStoreToProps = (store) => {
	return {
		companyId: store.company.company.id
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(CapitalInsuranceModalContainer)
