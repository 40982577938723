import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import history from '../../interfaces/history'

class GoToBillingComponent extends Component {
	static propTypes = {
		onDone: func,
		renderComponent: func
	}

	goToSubscription = () => {
		const { companyUrlAlias, onDone, history } = this.props

		history.push(`/${companyUrlAlias}/subscriptions/`)
		onDone && onDone()
	}

	render = () => {
		const { isSubscriptionAdmin, renderComponent } = this.props

		if (!isSubscriptionAdmin) {
			return null
		}

		return renderComponent(this.goToSubscription)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		isSubscriptionAdmin: store.subscriptions.getIn(['helper', 'isSubscriptionAdmin'], false),
		companyUrlAlias: store.company.company?.urlAlias
	}
}

export default connect(mapStoreToProps, {})(GoToBillingComponent)
