import { fromJS, List, Map } from 'immutable'

import {
	PROJECTS_CREATE_REMOTE,
	PROJECTS_FETCH_REMOTE,
	PROJECTS_UPDATE_LOCAL,
	PROJECTS_DELETE_REMOTE,
	PROJECTS_SAVE_REMOTE,
	PROJECTS_LIST,
	PROJECTS_UPDATE_SELECTED_PROJECT_ID,
	PROJECTS_GET_DEFAULT_PERMISSIONS,
	COMPANY_RESET_REDUCER,
	PROJECTS_LIST_USER_TASKS
} from '../actions/types'

const INITIAL_STATE = fromJS({
	mapParentToChildren: {},
	projects: Map(),
	currentProject: null,
	selectedProjectId: '',
	defaultPermissions: {},
	projectNamesUserTasks: {}
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action
	let parentId, parentList, project, parent, index

	switch (type) {
		case PROJECTS_LIST:
			state = state.remove('mapParentToChildren')
			state = state.remove('projects')
			state = state.set('mapParentToChildren', payload.get('mapParentToChildren'))
			state = state.set('projects', payload.get('projects'))
			return state

		case PROJECTS_UPDATE_LOCAL:
			return state.set('currentProject', payload)

		case PROJECTS_CREATE_REMOTE:
			parentId = payload.get('parent') || 'root'
			parentList = state.getIn(['mapParentToChildren', parentId]) || List()
			parentList = parentList.push(payload.get('id'))
			state = state.setIn(['mapParentToChildren', parentId], parentList)
			state = state.setIn(['projects', payload.get('id')], payload)
			return state.set('currentProject', null)

		case PROJECTS_FETCH_REMOTE:
			return state.set('currentProject', payload)

		case PROJECTS_DELETE_REMOTE:
			project = state.get('currentProject')
			parent = project.get('parent') || 'root'
			index = state.getIn(['mapParentToChildren', parent], List()).indexOf(project.get('id'))

			state = state.removeIn(['projects', project.get('id')])
			state = state.removeIn(['mapParentToChildren', project.get('id')])
			state = state.removeIn(['mapParentToChildren', parent, index])
			return state.set('currentProject', null)

		case PROJECTS_SAVE_REMOTE:
			state = state.setIn(['projects', payload.get('id')], payload)
			return state.set('currentProject', null)

		case PROJECTS_UPDATE_SELECTED_PROJECT_ID:
			return state.set('selectedProjectId', payload)

		case PROJECTS_GET_DEFAULT_PERMISSIONS: {
			const permissions = payload.get('permissions')
			const createProjectObject = payload.get('createProjectObject')
			state = state.set('defaultPermissions', permissions)

			if (!createProjectObject) {
				return state
			}

			let currentProject = state.get('currentProject')
			if (!currentProject) {
				currentProject = Map({ permissions })
				return state.set('currentProject', currentProject)
			}

			return state.setIn(['currentProject', 'permissions'], permissions)
		}

		case PROJECTS_LIST_USER_TASKS: {
			let newState = Map()

			payload.forEach((project) => {
				newState = newState.set(project.get('id'), project)
			})

			return state.set('projectNamesUserTasks', newState)
		}

		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}

		default:
			return state
	}
}
