import React, { PureComponent } from 'react'
import { string, bool, func } from 'prop-types'
import styled, { css } from 'styled-components'
import { List, Map } from 'immutable'
import { list } from 'react-immutable-proptypes'
import Moment from '../../../modules/moment.module'

import Modal from '../../shared/modal/modal'
import { StyledListHeaderTemplate } from '../../shared/list/list-header.template'
import Button from '../../shared/button/button'
import ListList from '../../shared/list/list'
import ListItem from '../../shared/list-item/list-item'
import Text from '../../shared/text/text'
import Icon from '../../shared/icon/icon'
import FooterRightControls from '../../shared/modal/footer-right-controls'
import { TransparentButton } from '../../shared/button-v2'

const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
`

const StyledListItem = styled(ListItem)`
	align-items: center;
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeLight};
	margin-bottom: 0px;
`

const EllipsisTemplate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

const TemplateTitleWidth = css`
	${EllipsisTemplate};
	min-width: 100px;
	flex: 1;
`

const TemplateDateWidth = css`
	width: 150px;
`

const TemplateTickWidth = css`
	width: 80px;
	display: flex;
	justify-content: flex-end;
`

const StyledTitle = styled.div`
	${TemplateTitleWidth};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
	display: flex;
	flex-direction: column;
`

const StyledDate = styled.div`
	${TemplateDateWidth};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
	text-align: right;
`

const StyledTick = styled.div`
	${TemplateTickWidth};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

export default class FilterByCertificateModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		onClose: func,
		onRevert: func,
		onSelect: func,
		selectedId: string,
		transactions: list,
		hideReset: bool
	}

	static defaultProps = {
		transactions: List()
	}

	renderListItem = (transaction, index) => {
		const { onSelect, selectedId } = this.props
		const summary = transaction.getIn(['descriptionData', 'summary'], transaction.get('type'))
		const title = transaction.getIn(['descriptionData', 'title'], 'Missing title')

		return (
			<StyledListItem
				key={index}
				hoverColor='lightestGrey'
				onClick={onSelect && onSelect.bind(null, transaction)}
				striped>
				<StyledTitle>
					<Text tid={title} bold={700} />
					<Text>{summary}</Text>
				</StyledTitle>

				<StyledDate>
					<Text singleLine>{Moment(transaction.get('date')).format('L')}</Text>
				</StyledDate>

				<StyledTick>
					{selectedId === transaction.get('id') && <Icon icon='faCheck' type='solid' size='sml' color='green' />}
				</StyledTick>
			</StyledListItem>
		)
	}

	renderHeader = () => {
		return (
			<StyledHeader>
				<StyledTitle>
					<Text tid='shares.sharebook.modal.histric.title' bold={400} color='lightGrey' />
				</StyledTitle>
				<StyledDate>
					<Text tid='generic.date' bold={400} color='lightGrey' />
				</StyledDate>
				<StyledTick />
			</StyledHeader>
		)
	}

	renderFooter = () => {
		const { onClose } = this.props

		return (
			<FooterRightControls>
				<TransparentButton tid='generic.select_and_close' textColor='midGrey' onClick={onClose && onClose} />
			</FooterRightControls>
		)
	}

	renderFooterLeftComponent = () => {
		const { onRevert, hideReset } = this.props

		if (hideReset) {
			return null
		}

		return <Button mode='link' tid='reset' onClick={onRevert && onRevert} />
	}

	render = () => {
		const { isOpen, transactions } = this.props

		return (
			<Modal
				isOpen={isOpen}
				hSize='lg'
				title='sharebook.modal.filter_by_certificate.title'
				footerComponent={this.renderFooter()}
				footerLeftComponent={this.renderFooterLeftComponent()}>
				<ListList header={this.renderHeader}>{transactions.map(this.renderListItem)}</ListList>
			</Modal>
		)
	}
}
