import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from '../modules/moment.module'
import Cookies from 'universal-cookie'
import AppNavBar from './menu/nav-bar'
import MainNav from './menu/main-nav'
import Notify from './framework/notify'
import RootModal from './framework/root-modal'
import ProcessingBar from './framework/processing-bar'
import IdleTimer from 'react-idle-timer'
import DropzoneFullscreenDetector from '../containers/app/dropzone-fullscreen-detector.container'
import MobileLandingPage from '../dumb-components/landing-pages/mobile-coming-soon/mobile-coming-soon'
import ScreenMessageContainer from '../containers/shared/screen-message.container'
import SimpleConfirmationModalContainer from '../containers/shared/simple-confirmation-modal.container'
import TaskMoveModalContainer from '../containers/tasks/tasks-move-task-modal.container'
import ChatVisibilityControllerContainer from '../containers/app/chat-visibility-controller.container'
import DocumentDownloadSignedModalContainer from '../containers/documents/document-download-signed-modal.container'

import OnboardingControllerContainer from '../containers/notifications/onboarding/controller.container'

import { refreshToken } from '../actions/credentials.actions'
import { fetchUserCompanies } from '../actions/company.actions'

import Routes from '../routes'
import { withRouter } from '../interfaces/router'

class App extends Component {
	state = {
		timer: null,
		tokenVaildMin: 120,
		checkTokenEveryMin: 1,
		isMobileDevice: true,
		isMobileWarningDismissed: false
	}

	componentDidMount = () => {
		const timer = setInterval(this.tick, this.state.checkTokenEveryMin * 60 * 1000)
		const isMobileDevice = this.isMobileDevice()
		this.setState({ timer, isMobileDevice })
	}

	componentDidUpdate = (prevProps) => {
		const { user, userLanguage, fetchUserCompanies } = this.props
		if (prevProps.userLanguage !== userLanguage && userLanguage) {
			new Cookies().set('userLanguage', userLanguage, { path: '/', expires: Moment().add(1, 'year').toDate() })
		}

		if (user && !prevProps.user) {
			fetchUserCompanies(user.get('id'))
		} else if (user && prevProps && prevProps.user.id !== user.id) {
			fetchUserCompanies(user.get('id'))
		}
	}

	tick = () => {
		const { idleTimer } = this.refs
		if (idleTimer) {
			const idleTime = this.state.tokenVaildMin * 60 * 1000 - idleTimer.getRemainingTime()
			if (idleTime < this.state.checkTokenEveryMin * 60 * 1000) {
				this.props.refreshToken()
			}
		}
	}

	dismissMobileWarning = () => {
		this.setState({ isMobileWarningDismissed: true })
	}

	isMobileDevice = () => {
		let check = false
			; (function (a) {
				if (
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
						a
					) ||
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
						a.substr(0, 4)
					)
				) {
					check = true
				}
			})(navigator.userAgent || navigator.vendor || window.opera)
		return check
	}

	/**
	 * The render function
	 */
	render() {
		const {
			appMenusShow,
			location: { pathname }
		} = this.props
		const { isMobileDevice, isMobileWarningDismissed } = this.state

		if (!this.props.authenticated) {
			if (isMobileDevice && !isMobileWarningDismissed) {
				return (
					<>
						<ChatVisibilityControllerContainer forceHidden />
						<MobileLandingPage onDismiss={this.dismissMobileWarning} />
					</>
				)
			}

			return <Routes />
		}

		return (
			<DropzoneFullscreenDetector>
				<ScreenMessageContainer />
				<SimpleConfirmationModalContainer />
				<TaskMoveModalContainer />
				<ChatVisibilityControllerContainer />
				<OnboardingControllerContainer />
				<DocumentDownloadSignedModalContainer />

				<div className='base-layout'>
					{appMenusShow && <MainNav pathName={pathname} />}
					<div className='base-layout__body'>
						<div className='base-layout__body__nav-bar'>
							{appMenusShow && <AppNavBar isCompanyPage={this.props.match.params.company} />}
						</div>
						<div className='base-layout__body__content'>
							<Routes />
						</div>
					</div>

					<RootModal />
					<ProcessingBar />
					<Notify />
					<IdleTimer ref='idleTimer' element={document} timeout={this.state.tokenVaildMin * 60 * 1000}></IdleTimer>
				</div>
			</DropzoneFullscreenDetector>
		)
	}
}

function mapStoreToProps(state) {
	return {
		authenticated: state.credentials.get('authenticated'),
		appMenusShow: state.notify.appMenusShow,
		messages: state.i18n.messages,
		userLanguage: state.user.getIn(['userObj', 'siteSettings', 'language']),
		user: state.user.get('userObj')
	}
}

const mapActionToProps = {
	refreshToken,
	fetchUserCompanies
}

export default connect(mapStoreToProps, mapActionToProps)(withRouter(App))

