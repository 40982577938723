import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchMeeting, saveMeeting, updateMeetingLocal, fetchAgendaItem } from '../../../actions/meetings.actions'
import SplitView from '../../../dumb-components/shared/layout/split-view/split-view'
import ScrollView from '../../../dumb-components/shared/layout/scroll-view/scroll-view'
import ScrollViewHeader from '../../../dumb-components/shared/scroll-view-header/scroll-view-header'
import AgendaListContainer from './agenda-list.container'
import MeetingToolbarContainer from '../meeting/smart-meeting/meeting-toolbar.container'
import MeetingTabsContainer from '../meeting/meeting-tabs.container'

import StartMeetingModalContainer from '../meeting/start-meeting-modal.container'

import AgendaDetailsContainer from './agenda-details.container'

import { calculateMaxWidthOfSplitView, toggleVisibilityOfTabs } from '../../../components/helpers/tabs.helper'
import SelectTemplateModal from '../../../dumb-components/meetings/modals/select-template-modal'

class MeetingAgendaContainer extends Component {
	// References to the scrollviews
	scrollViewRefs = {}

	// Reference to the tabs
	tabsRef

	// Timer
	resizeEnd

	state = {
		hiddenTabs: {}
	}

	componentDidMount() {
		window.addEventListener('resize', this.onWindowResize, false)
		this.onWindowResize()
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.onWindowResize, false)
	}

	onWindowResize = () => {
		let { hiddenTabs } = this.state
		const maxWidth = calculateMaxWidthOfSplitView(this.scrollViewRefs)
		hiddenTabs = toggleVisibilityOfTabs(this.tabsRef, hiddenTabs, { maxWidth })

		this.setState({ hiddenTabs })
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('agenda')
		return pathArray[0] + 'agenda'
	}

	onChange = (prop, val) => {
		const { saveMeeting } = this.props
		let { meeting } = this.props

		meeting = meeting.setIn(prop, val)
		saveMeeting(meeting)
	}

	renderLeftHeader = () => {
		const { location } = this.props
		const { hiddenTabs } = this.state

		return (
			<MeetingTabsContainer
				match='agenda'
				location={location}
				onSetRef={(ref) => (this.tabsRef = ref)}
				hiddenTabs={hiddenTabs}
			/>
		)
	}

	renderLeftSplitView = () => {
		const {
			meeting,
			location,
			match: { params }
		} = this.props
		const basePath = this.getComponentBasePath()
		const readOnly = meeting && meeting.getIn(['computedValues', 'isSimpleMode']) === true

		return (
			<ScrollView
				header={this.renderLeftHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs['left'] = ref)}>
				<AgendaListContainer
					basePath={basePath}
					querystr={location.search}
					agendaItemIdInUrl={params.agendaItemId}
					items={meeting && meeting.get('agendaItems')}
					onMeetingChange={this.onChange}
					readOnly={readOnly}
				/>
			</ScrollView>
		)
	}

	renderRightHeader = () => {
		const {
			match: { params },
			location
		} = this.props
		const basePath = this.getComponentBasePath()

		return (
			<ScrollViewHeader>
				<MeetingToolbarContainer location={location} params={params} basePath={basePath.split('/agenda')[0]} />
			</ScrollViewHeader>
		)
	}

	renderRightSplitView = () => {
		const { match } = this.props

		return (
			<ScrollView
				header={this.renderRightHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs['right'] = ref)}>
				{match.params.agendaItemId && (
					<AgendaDetailsContainer match={match} location={this.props.location} history={this.props.history} />
				)}
			</ScrollView>
		)
	}

	render() {
		const {
			match: { params }, templateLocked
		} = this.props		

		return (
			<>
				<SplitView leftComponent={this.renderLeftSplitView} rightComponent={this.renderRightSplitView} />

				<StartMeetingModalContainer agendaItemId={params.agendaItemId} />

				{!templateLocked && <SelectTemplateModal />}
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get('meeting'),
		templateLocked: store.meetings.getIn(['meeting', 'templateLocked'])
		// audit: store.audit.get('meetings')
	}
}

const mapActionsToProps = {
	fetchMeeting,
	saveMeeting,
	updateMeetingLocal,
	fetchAgendaItem
}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingAgendaContainer)
