import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, object, bool, func } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'

import DropdownIconItem from '../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import { findProxyByAttendeeId, isAttendeeModerator } from '../../../../../components/helpers/meeting.helper'
import { openModal } from '../../../../../actions/modals.actions'
import { MEETINGS_ADD_PROXY_MODAL, CONFIRM_DELETE_PROXY } from '../../../../../constants/modals'
import { toggleMenu } from '../../../../../components/helpers/refs.helper'
import Tooltip from '../../../../../dumb-components/shared/tooltip/tooltip'

class AddProxyContainer extends Component {
	static propTypes = {
		userId: string,
		dropdownRef: object,
		attendees: immutablePropTypes.map,
		disabled: bool,
		onClickOverride: func
	}

	isAttendeeModerator = () => {
		const { userId, moderators } = this.props
		return isAttendeeModerator(userId, moderators)
	}

	onClick = () => {
		const { openModal, userId, dropdownRef, onClickOverride } = this.props

		toggleMenu(dropdownRef)

		if (this.attendeeHasProxy()) {
			openModal(CONFIRM_DELETE_PROXY, { userId })
			return
		}

		if (onClickOverride) {
			onClickOverride(userId)
			return
		}

		openModal(MEETINGS_ADD_PROXY_MODAL, { attendeeId: userId })
	}

	attendeeHasProxy = () => {
		const { userId, proxies } = this.props
		const attendeHasProxy = findProxyByAttendeeId(proxies, userId)

		return attendeHasProxy
	}

	getTextTid = () => {
		if (this.attendeeHasProxy()) {
			return 'meetings.agm.attendees_list_item.dropdown.remove_the_proxy'
		}

		return 'meetings.agm.attendees_list_item.dropdown.add_a_proxy'
	}

	render = () => {
		const { attendees, userId, disabled } = this.props
		const icon = this.attendeeHasProxy() ? 'faUserMinus' : 'faUserPlus'
		const attendee = attendees.get(userId)

		if (!attendee) {
			return null
		}

		const isInvestor = attendee.get('isInvestor')
		const isAlsoInvestor = attendee.get('isAlsoInvestor')

		if (!isInvestor && !isAlsoInvestor) {
			return null
		}

		const isModerator = this.isAttendeeModerator()
		const isDisabled = disabled || isModerator

		return (
			<Tooltip
				active={isDisabled}
				tid='meetings.agm.tooltip.dropdown_item.add_a_proxy.disabled_if_attendee_is_moderator'
				delayShow='instant'>
				<DropdownIconItem icon={icon} tid={this.getTextTid()} onClick={this.onClick} disabled={isDisabled} />
			</Tooltip>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		proxies: store.meetings.getIn(['meeting', 'proxies']),
		attendees: store.meetings.getIn(['meeting', 'attendees']),
		meetingId: store.meetings.getIn(['meeting', 'id']),
		moderators: store.meetings.getIn(['meeting', 'moderators'], List()) || List()
	}
}

const mapActionsToProps = {
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(AddProxyContainer)
