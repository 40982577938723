import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'

import Alert from '../../../../../dumb-components/onboarding/alert'
import Badge from '../../../../../dumb-components/shared/badge/badge-new'
import DismissControls from '../dismiss-controls.container'
import Text from '../../../../../dumb-components/shared/text/text'

import { getIsDismissed } from '../helpers'
const ALERT_ID = '9f9219cc-cd53-4ccb-a79b-0f88456911ff'

class PremiumSmartMeetingsInfoAlert extends Component {
	render = () => {
		const { isDismissed } = this.props

		if (isDismissed) {
			return null
		}

		return (
			<Alert
				badgeComponent={<Badge isStatic bgColor='purpleMid' textColor='white' tid='generic.premium' maxWidth={200} />}
				controlsComponent={<DismissControls alertId={ALERT_ID} />}>
				<Text color='white'>
					Smart Meetings - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
					labore et dolore magna aliqua.
				</Text>
			</Alert>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		isDismissed: getIsDismissed(store.user.get('userObj'), ALERT_ID)
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(PremiumSmartMeetingsInfoAlert)
