import React, { useState } from 'react'

import LinkRenderer from '../renderers/link-renderer'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import {
	NUMBER_FORMAT_CURRENCY,
	NUMBER_FORMAT_PERCENTAGE,
	NUMBER_FORMAT_INTEGER,
	NUMBER_FORMAT_DEFAULT
} from '/shared/constants'
import OpenInvestmentButtonRenderer from '../../../shared/ag-grid/renderers/open-investment-button-renderer'
import Cookies from 'universal-cookie'
import TooltipRenderer from '../renderers/tooltip-renderer'
import TransposedTitleColumnTooltipRenderer from '../renderers/transposed-title-column-tooltip-renderer'

import { openModal } from '../../../../actions/modals.actions'
import { FormattedMessage } from 'react-intl'
import { MY_RECORDS_EDIT_TRANSACTION_MODAL } from '../../../../constants/modals'
import { TickCellRenderer } from '../renderers/tick.cell-renderer'
import { DatePickerRenderer } from '../renderers/datepicker-renderer'
import TickHeader from '../components/tick-header'
import { Link, Box } from '@mui/material'
import { getUniqueTransactionShareTypes } from '../../../../components/helpers/sharebook.helpers'
import { getShareTypeLabel } from '../../../../components/helpers/shares'
import { OpenAttachmentsModalButton } from '../../../../containers/investments/my-records/personal-attachments/OpenAttachmentsModalButton'
import { FinancialRenderer } from '../renderers/financials.renderer'
import { NumberAndPrice } from '../tooltips/share-types/NumberAndPrice'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Typography from '@mui/material/Typography'
import MoreActionRenderer from '../renderers/more-action-renderer'
import { fromJS } from 'immutable'
import { emissionConsts } from '../../../../constants/emissions'

export function casesColumnDefs(t) {
	const columnDefs = [
		{
			field: 'caseNumber',
			headerName: t('investments.general.cases_grid.header_name.case_number'),
			type: ['lockedFirstColumn', 'widthDefault']
		},
		{
			field: 'submitted',
			headerName: t('investments.general.cases_grid.header_name.submitted'),
			type: 'rightAligned',
			maxWidth: 150
		},
		{
			field: 'classification',
			headerName: t('investments.general.cases_grid.header_name.classification'),
			flex: 1
		},
		{
			field: 'caseLink',
			headerName: t('investments.general.company_grid.header_name.case_link'),
			sortable: false,
			cellRenderer: LinkRenderer,
			filter: false,
			floatingFilter: false,
			type: 'rightAligned'
		}
	]
	return columnDefs
}

export function companyBoardColumnDefs(t, isGuest) {
	const columnDefs = [
		{
			field: 'roles',
			headerName: t('investments.general.company_grid.header_name.function'),
			type: ['lockedFirstColumn', 'widthDefault']
		},
		{
			field: 'name',
			headerName: t('investments.general.company_grid.header_name.name')
		},
		{
			field: 'ssn',
			headerName: t('investments.general.company_grid.header_name.ssn'),
			hide: isGuest,
			type: 'rightAligned'
		},
		{
			field: 'regDateRepresentative',
			headerName: t('investments.general.company_grid.header_name.access'),
			type: 'rightAligned'
		},
		{
			field: 'numOtherEngagements',
			headerName: t('investments.general.company_grid.header_name.num_other_engagements'),
			type: 'rightAligned'
		}
	]
	return columnDefs
}

export function shareholderColumnDefs(t) {
	const columnDefs = [
		{
			field: 'name',
			headerName: t('investments.general.shareholder_grid.header_name.name'),
			type: ['lockedFirstColumn', 'widthDefault']
		},
		{
			field: 'id',
			headerName: t('investments.general.shareholder_grid.header_name.id'),
			type: 'rightAligned'
		},
		{
			field: 'balance.numOfTotalShares',
			headerName: t('investments.general.shareholder_grid.header_name.num_of_total_shares'),
			type: 'rightAligned',
			valueFormatter: (params) => localeFormatNumber(params.value, NUMBER_FORMAT_INTEGER)
		},
		{
			field: 'balance.ownershipPercentage',
			headerName: t('investments.general.shareholder_grid.header_name.ownership_percentage'),
			type: 'rightAligned',
			valueFormatter: (params) => localeFormatNumber(params.value, NUMBER_FORMAT_PERCENTAGE)
		},
		{
			field: 'balance.votingRightPercentage',
			headerName: t('investments.general.shareholder_grid.header_name.voting_right_percentage'),
			type: 'rightAligned',
			valueFormatter: (params) => localeFormatNumber(params.value, NUMBER_FORMAT_PERCENTAGE)
		},
		{
			field: 'balance.value',
			headerName: t('investments.general.shareholder_grid.header_name.value'),
			type: 'rightAligned',
			valueFormatter: (params) => localeFormatNumber(params.value, NUMBER_FORMAT_CURRENCY)
		}
	]
	return columnDefs
}

export function investorEmissionsColumnDefs(t) {
	const columnDefs = [
		{
			field: 'date',
			headerName: t('investments.emission.emission_grid.header_name.date'),
			type: ['lockedFirstColumn', 'widthFirstColumn'],
			maxWidth: 175
		},
		{
			field: 'status',
			headerName: t('investments.emission.emission_grid.header_name.status')
		},
		{
			field: 'userCoverage',
			headerName: t('investments.emission.emission_grid.header_name.coverage'),
			type: 'rightAligned',
			maxWidth: 250,
			valueFormatter: (params) => localeFormatNumber(params.value, NUMBER_FORMAT_INTEGER)
		},
		{
			field: 'emissionMin',
			headerName: t('investments.emission.emission_grid.header_name.emission_amount_min'),
			type: 'rightAligned',
			maxWidth: 250,
			valueFormatter: (params) => {
				if (params.data.newShareCountType === emissionConsts.common.newShareCountTypes.highest) {
					return '-'
				} else {
					return localeFormatNumber(params.value, NUMBER_FORMAT_INTEGER)
				}
			}
		},
		{
			field: 'emissionMax',
			headerName: t('investments.emission.emission_grid.header_name.emission_amount_max'),
			type: 'rightAligned',
			maxWidth: 250,
			valueFormatter: (params) => localeFormatNumber(params.value, NUMBER_FORMAT_INTEGER)
		},
		{
			field: 'action',
			headerName: '',
			type: 'rightAligned',
			sortable: false,
			maxWidth: 100,
			cellRendererSelector: (params) => ({
				component: () => {
					return params.value.label ? (
						<Link
							component='button'
							variant='body2'
							color='positive.main'
							onClick={() => {
								params.value.showDetails()
							}}>
							{params.value.label}
						</Link>
					) : null
				}
			})
		}
	]
	return columnDefs
}

export function myTransactionsColumnDefs(t) {
	const columnDefs = [
		{
			field: 'transactionType',
			headerName: 'Typ av transaktion',
			type: 'rightAligned',
			minWidth: 400
		},
		{
			field: 'shareTypeName',
			headerName: 'Aktieslag',
			type: 'rightAligned',
			minWidth: 400
		},
		{
			field: 'totalCompanySharesDiff',
			headerName: 'Totalt antal nya aktier',
			type: 'rightAligned',
			minWidth: 400
		},
		{
			field: 'latestPrice',
			headerName: 'Pris för aktierna',
			type: 'rightAligned',
			minWidth: 400
		},
		{
			field: 'votesPerShare',
			headerName: 'Rösträttsvärde (faktor)',
			type: 'rightAligned',
			minWidth: 400
		},
		{
			field: 'numOfTotalCompanyShares',
			headerName: 'Totalt antal aktier i bolaget',
			type: 'rightAligned',
			minWidth: 400
		},
		{
			field: 'totalEmissionValue',
			headerName: 'Totalt emissionsbelopp',
			type: 'rightAligned',
			minWidth: 400
		},
		{
			field: 'sharebookRegDate',
			headerName: 'Aktiebokens registreringsdatum',
			type: 'rightAligned',
			minWidth: 400
		},
		{
			field: 'regDateBolagsverket',
			headerName: 'Registrering hos bolagsverket',
			type: 'rightAligned',
			minWidth: 400
		},
		{
			field: 'myNumOfSharesDiff',
			headerName: 'Min del',
			type: 'rightAligned',
			minWidth: 400
		},
		{
			field: 'date',
			headerName: 'Anmälnings/teckningsdatum',
			type: 'rightAligned',
			minWidth: 400
		},
		{
			field: 'investedAmount',
			headerName: 'Investerat belopp',
			type: 'rightAligned',
			minWidth: 400
		}

		//{
		//	field: 'companyPostMoney',
		//	headerName: 'companyPostMoney',
		//	type: 'rightAligned'
		//}
	]
	return columnDefs
}

export function columnTypes() {
	const columnTypes = {
		lockedFirstColumn: {
			lockPosition: 'left',
			pinned: 'left',
			lockPinned: true,
			lockVisible: true,
			suppressMenu: true,
			suppressColumnsToolPanel: true
		},
		widthFirstColumn: {
			width: 200
		},
		widthButtonColumn: {
			width: 100
		},
		widthDefault: {
			width: 300
		},
		widthDate: {
			width: 100
		}
	}

	return columnTypes
}

export function useInvestmentsColumnDefs() {
	const minWidth = 422

	const [colDefs, setColDefs] = useState()
	const getColumnDefs = (data, isGridModalOpen, id) => {
		const cols = [
			{ field: 'table', rowGroup: true, hide: true, type: 'lockedFirstColumn' },
			{
				field: 'title',
				headerName: '',
				minWidth,
				sortable: false,
				width: new Cookies().get(id)?.columnWidth || 200,
				type: ['lockedFirstColumn', 'widthDefault']
			}
		]

		const companyNames = data
			.map((company) => {
				return company.get('name')
			})
			.sort((a, b) => a.localeCompare(b))

		companyNames.forEach((name) => {
			const investment = data.find((company) => company.get('name') === name)

			let displayColumnData
			let noDetails = false
			let isRegisteredInvonoCompany
			let showInPortfolio
			const rootOwnerPersonalInvestmentId = investment.get('rootOwnerPersonalInvestmentId')

			if (rootOwnerPersonalInvestmentId && investment.get('rootOwners').size > 0) {
				const getRightRootOwner = investment
					.get('rootOwners')
					.find(
						(rootOwner) =>
							rootOwner.get('personalInvestmentId') === investment.get('rootOwnerPersonalInvestmentId')
					)

				const rootOwnerDisplayColumnData = getRightRootOwner.getIn(['personalInvestment', 'displayColumnData'])
				displayColumnData = rootOwnerDisplayColumnData
				isRegisteredInvonoCompany = getRightRootOwner.getIn(['personalInvestment', 'isRegisteredInvonoCompany'])
				showInPortfolio = getRightRootOwner.getIn(['personalInvestment', 'showInPortfolio'])
			} else {
				displayColumnData = investment.get('displayColumnData')
				isRegisteredInvonoCompany = investment.getIn(['personalInvestment', 'isRegisteredInvonoCompany'])
				showInPortfolio = investment.getIn(['personalInvestment', 'showInPortfolio'])
			}

			const isViaCompany = investment.get('viaCompanyName')

			if (isViaCompany && !displayColumnData) {
				const companyDetails = investment.get('details')

				if (!companyDetails || (companyDetails && companyDetails.isEmpty())) {
					noDetails = true
				}
			}

			cols.push({
				field: name.replaceAll('.', ''),
				displayName: name,
				headerComponent: TickHeader,
				headerComponentParams: {
					isRegisteredInvonoCompany: isRegisteredInvonoCompany,
					showInPortfolio: showInPortfolio,
					isNotMovable: true,
					companyName: name
				},
				headerClass: 'tick-header',
				minWidth: 200,
				type: 'rightAligned',
				sortable: false,

				cellRendererSelector: (params) => {
					const renderOpenInvestmentButton = {
						component: () => {
							const value = params.value ? params.value : params.data[params.column.colId]

							return OpenInvestmentButtonRenderer(value)
						}
					}

					if (params?.data?.renderer === 'investmentButton') {
						return renderOpenInvestmentButton
					} else {
						return undefined
					}
				},
				cellClass: (params) => {
					let cellClass = []
					if (
						(displayColumnData === false && params?.data?.renderer !== 'investmentButton') ||
						(noDetails && params?.data?.renderer !== 'investmentButton')
					) {
						cellClass.push('incomplete-transaction-form')
					}
					if (params?.data?.renderer === 'investmentButton') {
						cellClass.push('open-investment-btn', 'ag-right-aligned-cell')
						return cellClass
					} else {
						cellClass.push('ag-right-aligned-cell')

						return cellClass
					}
				},
				...(!isGridModalOpen && { tooltipComponent: TooltipRenderer }),
				...(!isGridModalOpen && { tooltipField: name })
			})
		})
		return cols
	}

	const setColumnDefs = (data, isGridModalOpen, id) => {
		if (data) {
			const cols = getColumnDefs(data, isGridModalOpen, id)
			setColDefs(cols)
		} else {
			setColDefs()
		}
	}

	return [colDefs, setColumnDefs]
}

export function useMyRecordsColumnDefs(closeDatePicker, openDatePicker) {
	const minWidth = 400
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [colDefs, setColDefs] = useState()

	const getColumnDefs = (data, id, onChangeDate) => {
		let cols = [
			{ field: 'table', rowGroup: true, hide: true, type: 'lockedFirstColumn' },
			{
				field: 'title',
				headerName: '',
				width: new Cookies().get(id)?.columnWidth || 200,
				minWidth,
				type: ['lockedFirstColumn', 'widthDefault'],
				sortable: false,
				cellClass: (params) => {
					if (params?.data?.cellClass === 'cellValueIndent') {
						return 'text-indent-cell'
					}
				},
				tooltipComponent: TransposedTitleColumnTooltipRenderer,
				tooltipField: 'title'
			}
		]

		//Using vanilla javascript on this function for performance reasons.
		let transactions = data.toJS()
		transactions = transactions.map((transaction, index) => {
			if (index > 0) {
				transaction.prevTransactionDate = transactions[index - 1].date
			}
			if (index < transactions.length - 1) {
				transaction.nextTransactionDate = transactions[index + 1].date
			}
			return transaction
		})
		const updatedData = fromJS(transactions)

		updatedData.forEach((transaction) => {
			const link = transaction.get('link')
			const transactionHasMissingValue = !transaction.get('isComplete')

			const onClose = () => {
				closeDatePicker(transaction.get('id'))
			}

			const onClickDatePicker = () => {
				openDatePicker(transaction.get('id'))
			}

			cols.push({
				field: `${transaction.get('transactionType')}&${transaction.get('id')}`,
				headerName: t(`investments.my_records.grid.header_name.title.${transaction.get('transactionType')}`),
				headerComponent: TickHeader,
				headerComponentParams: { transactionLink: link, transactionHasMissingValue },
				headerClass: 'tick-header',
				minWidth: 200,
				sortable: false,
				cellRendererSelector: (params) => {
					const renderOpenInvestmentButton = {
						component: () => {
							return (
								<Link
									component='button'
									variant='body2'
									color={
										link && link.get('isDirty') && !link.get('transactionId')
											? 'error.main'
											: 'text.primary'
									}
									onClick={() => {
										dispatch(
											openModal(MY_RECORDS_EDIT_TRANSACTION_MODAL, {
												id: params.value.data.transactionId,
												link: link && link.get('transactionId')
											})
										)
									}}>
									<FormattedMessage
										id={
											link && link.get('isDirty') && !link.get('transactionId')
												? 'investments.my_records.grid.link.btn.update'
												: 'investments.my_records.grid.link.btn.edit'
										}
									/>
								</Link>
							)
						}
					}

					if (params?.data?.renderer === 'editTransactionBtn') {
						return renderOpenInvestmentButton
					} else if (params?.value === 'renderTickCell') {
						return { component: TickCellRenderer }
					} else if (params?.data?.renderer === 'renderAttachments') {
						return { component: OpenAttachmentsModalButton }
					} else if (params?.data?.renderer === 'renderDate') {
						return {
							component: DatePickerRenderer,
							params: {
								minDate: transaction.get('prevTransactionDate'),
								maxDate: transaction.get('nextTransactionDate'),
								onChange: (val) => onChangeDate(val, transaction.get('id')),
								open: transaction.get('openDatePicker') ? true : false,
								onClose,
								onClick: onClickDatePicker,
								disableOpenPicker: true
							}
						}
					} else if (params?.value === 'formValueNotFilled') {
						return {
							component: () => (
								<Typography variant={'body2'} color={'error.main'} sx={{ margin: 'revert' }}>
									{t('investments.my_records.grid.missing_values')}
								</Typography>
							)
						}
					} else {
						undefined
					}
				},
				cellClass: (params) => {
					let cellClass = []
					if (params?.data?.renderer === 'editTransactionBtn') {
						return 'ag-right-aligned-cell'
					} else if (params?.value === 'renderTickCell') {
						cellClass.push('tick-cell', 'ag-right-aligned-cell')

						return cellClass
					} else {
						cellClass.push('ag-right-aligned-cell')

						return cellClass
					}
				},
				tooltipComponent: TooltipRenderer,
				tooltipField: `${transaction.get('transactionType')}&${transaction.get('id')}`,
				lockPinned: true
			})
		})

		return cols
	}

	const setColumnDefs = (data, id, onChangeDate) => {
		if (data) {
			const cols = getColumnDefs(data, id, onChangeDate)
			setColDefs(cols)
		} else {
			setColDefs()
		}
	}

	return [colDefs, setColumnDefs]
}

export function linkTransactionColumnDefs(t, previewTransactionCallback) {
	const columnDefs = [
		{
			field: 'date',
			headerName: t('investments.general.shareholder_grid.header_name.date'),
			headerClass: 'link-transaction-date-header',
			maxWidth: 92,
			cellClass: 'link-transaction-date'
		},
		{
			field: 'description',
			headerName: t('investments.general.shareholder_grid.header_name.transaction'),
			headerClass: 'link-transaction-description-header',
			flex: 1,
			cellClass: 'link-transaction-description',
			wrapText: true,
			autoHeight: true
		},
		{
			field: 'moreAction',
			headerName: '',
			sortable: false,
			lockPosition: 'right',
			lockPinned: true,
			lockVisible: true,
			suppressMenu: true,
			suppressColumnsToolPanel: true,
			maxWidth: 42,
			resizable: false,
			cellRenderer: (params) => (
				<MoreActionRenderer previewTransactionCallback={previewTransactionCallback} props={params} />
			),
			cellClass: 'more-action-btn-icon'
		}
	]
	return columnDefs
}

export function transactionHistoryColumnDefs(t, transactions, isGridModalOpen) {
	const uniqueTransactionTypes = getUniqueTransactionShareTypes(transactions)

	const additionalColumns = []

	if (isGridModalOpen && uniqueTransactionTypes) {
		uniqueTransactionTypes.forEach((uniqueShareType) => {
			additionalColumns.push({
				field: `${uniqueShareType}&newShares`,
				headerName:
					t('investments.sharebook.transaction_history_grid.header_name.new_shares') +
					' ' +
					getShareTypeLabel(uniqueShareType),
				type: 'rightAligned',
				valueFormatter: (params) =>
					typeof params.value === 'number'
						? localeFormatNumber(params.value, NUMBER_FORMAT_INTEGER)
						: params.value,
				minWidth: 200
			})

			additionalColumns.push({
				field: `${uniqueShareType}&latestPrice`,
				headerName:
					t('investments.sharebook.transaction_history_grid.header_name.price_per_share') +
					' ' +
					getShareTypeLabel(uniqueShareType),
				type: 'rightAligned',
				valueFormatter: (params) =>
					typeof params.value === 'number'
						? localeFormatNumber(params.value, NUMBER_FORMAT_CURRENCY)
						: params.value,
				minWidth: 200
			})
		})
	}

	if (!isGridModalOpen) {
		additionalColumns.push({
			field: 'numOfTotalSharesDiff',
			headerName: t('investments.sharebook.transaction_history_grid.header_name.num_of_total_shares_diff'),
			type: 'rightAligned',
			valueFormatter: (params) =>
				typeof params.value === 'number'
					? localeFormatNumber(params.value, NUMBER_FORMAT_INTEGER)
					: params.value,
			minWidth: 150,
			tooltipComponent: NumberAndPrice,
			tooltipField: 'numOfTotalSharesDiff'
		})
	}

	const columnDefs = [
		{
			field: 'date',
			headerName: t('investments.sharebook.transaction_history_grid.header_name.date'),
			minWidth: 120
		},
		...additionalColumns,
		{
			field: 'raisedDiff',
			headerName: t('investments.sharebook.transaction_history_grid.header_name.raised_diff'),
			type: 'rightAligned',
			valueFormatter: (params) =>
				typeof params.value === 'number'
					? localeFormatNumber(params.value, NUMBER_FORMAT_CURRENCY)
					: params.value,
			minWidth: 178
		},
		{
			field: 'postMoney',
			headerName: t('investments.sharebook.transaction_history_grid.header_name.post_money'),
			type: 'rightAligned',
			valueFormatter: (params) =>
				typeof params.value === 'number'
					? localeFormatNumber(params.value, NUMBER_FORMAT_CURRENCY)
					: params.value,
			minWidth: 210
		},
		{
			field: 'dilutionPercentage',
			headerName: t('investments.sharebook.transaction_history_grid.header_name.dilution_percentage'),
			type: 'rightAligned',
			valueFormatter: (params) =>
				typeof params.value === 'number'
					? localeFormatNumber(params.value, NUMBER_FORMAT_PERCENTAGE)
					: params.value,
			minWidth: 128
		},
		{
			field: 'valueStep',
			headerName: t('investments.sharebook.transaction_history_grid.header_name.value_step'),
			type: 'rightAligned',
			valueFormatter: (params) =>
				typeof params.value === 'number'
					? localeFormatNumber(params.value, NUMBER_FORMAT_CURRENCY)
					: params.value,
			minWidth: 178
		},
		{
			field: 'description',
			headerName: t('investments.sharebook.transaction_history_grid.header_name.description'),
			minWidth: 700,
			flex: 1
		}
	]
	return columnDefs
}

export function useFinancialStatementsColumnDefs() {
	const minWidth = 420

	const [colDefs, setColDefs] = useState()
	const getColumnDefs = (data) => {
		const cols = [
			{
				field: 'table',
				rowGroup: true,
				hide: true,
				type: 'lockedFirstColumn'
			},
			{
				field: 'group',
				rowGroup: true,
				hide: true,
				type: 'lockedFirstColumn'
			},
			{
				field: 'title',
				headerName: '',
				flex: 1,
				minWidth,
				sortable: false,
				type: 'lockedFirstColumn'
			}
		]

		data.sort((a, b) => {
			return a.period_to - b.period_to
		}).forEach((nyckeltal) => {
			const title = `20${nyckeltal.period_to.slice(0, 2)}-${nyckeltal.period_to.slice(2, 4)}`

			cols.push({
				field: nyckeltal.period_to,
				headerName: title,
				sortable: false,
				valueGetter: (params) => {
					if (!params.data) {
						return
					}

					return params.data[nyckeltal.period_to] ? params.data[nyckeltal.period_to]?.value : ''
				},
				valueFormatter: (params) => {
					let unit
					switch (params.data.unit) {
						case NUMBER_FORMAT_CURRENCY:
							unit = NUMBER_FORMAT_INTEGER
							break
						case NUMBER_FORMAT_PERCENTAGE:
							unit = NUMBER_FORMAT_DEFAULT
							break
						default:
							unit = params.data.unit
					}

					return typeof params.value === 'number' ? localeFormatNumber(params.value, unit) : '-'
				},
				cellRenderer: FinancialRenderer,
				type: 'rightAligned'
			})
		})

		return cols
	}

	const setColumnDefs = (data) => {
		if (data) {
			const cols = getColumnDefs(data)
			setColDefs(cols)
		} else {
			setColDefs()
		}
	}

	return [colDefs, setColumnDefs]
}
