import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { Map } from 'immutable'

import Text from '../../../dumb-components/shared/text/text'
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import ModernFilePreview from '../../../dumb-components/shared/modern-file-preview/modern-file-preview'
import { ButtonTransparentIcon } from '../../../dumb-components/shared/button'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'

import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import ConfirmContainer from '../../shared/confirm.container'
import PublishedDocumentSharedWith from '../../../dumb-components/meetings/published-document/published-document-shared-with'
import SharedWithListContainer from '../documents-shared-with-list/documents-shared-with-list.container'

import { downloadDocument, cancelDocumentSigning } from '../../../actions/documents.actions'

const IconButton = (props) => (
	<ButtonTransparentIcon
		color='white'
		hoverColor='muted'
		borderColor='white'
		size='sm'
		autoUnsetWidthHeight={false}
		noHorizontalMargin
		{...props}
	/>
)

class SignedDocumentFilePreview extends Component {
	state = {
		confirmModalIsOpen: false,
		isLoading: false,
		isSharedWithModalOpen: false
	}

	static propTypes = {
		onDeleteSignedDocument: func
	}

	dropdownRef = null

	openSignedFile = () => {
		const { downloadDocument, documentId } = this.props
		const getSignedVersion = true
		const openInViewer = true
		downloadDocument({ documentId, getSignedVersion, openInViewer })
	}

	openSharedWithModal = () => {
		this.setState({ isSharedWithModalOpen: true })
	}

	downloadSignedFile = () => {
		const { downloadDocument, documentId } = this.props
		const getSignedVersion = true
		downloadDocument({ documentId, getSignedVersion })
	}

	doDeleteSignedDocument = () => {
		const { cancelDocumentSigning, documentId, onDeleteSignedDocument } = this.props
		this.setState({ isLoading: true })

		cancelDocumentSigning(documentId, (e, document) => {
			if (e) {
				this.setState({ isLoading: false })
				return
			}

			this.setState({ confirmModalIsOpen: false, isLoading: false })
			onDeleteSignedDocument(document)
		})
	}

	toggleDropdown = () => {
		this.dropdownRef && this.dropdownRef.onToggleMenu()
	}

	openDeleteConfirmModal = () => {
		this.toggleDropdown()
		this.setState({ confirmModalIsOpen: true })
	}

	closeDeleteConfirmModal = () => {
		this.setState({ confirmModalIsOpen: false })
	}

	closeSharedWithModal = () => {
		this.setState({ isSharedWithModalOpen: false })
	}

	renderDropdown = () => {
		const { confirmModalIsOpen, isLoading } = this.state
		const { document } = this.props
		const disabled = !document.get('ALLOW_DELETE')

		return (
			<>
				<DropdownMenuContainer
					halignMenu='right'
					ref={(ref) => (this.dropdownRef = ref)}
					disabled={disabled}
					renderRaw={
						<Tooltip activeState={disabled ? 'hasNoPermissions' : false}>
							<IconButton icon='faEllipsisV' disabled={disabled} onClick={this.toggleDropdown} noHorizontalMargin />
						</Tooltip>
					}>
					<DropdownIconItem
						icon='faTrashAlt'
						tid='documents.signatories.panel.dropdown.delete_file'
						onClick={this.openDeleteConfirmModal}
					/>
				</DropdownMenuContainer>

				<ConfirmContainer
					isOpen={confirmModalIsOpen}
					title='documents.signatories.panel.delete_file.confirm.title'
					question='documents.signatories.panel.delete_file.confirm.question'
					onConfirm={this.doDeleteSignedDocument}
					onDecline={this.closeDeleteConfirmModal}
					isLoading={isLoading}
				/>
			</>
		)
	}

	render = () => {
		const { document, fileReference } = this.props
		const { confirmModalIsOpen, isLoading, isSharedWithModalOpen } = this.state
		return (
			<>
				<PublishedDocumentSharedWith
					onOpenSharedWithModal={this.openSharedWithModal}
					tid='documents.signatories.panel.label.signed_file'
				/>
				<ModernFilePreview
					document={document}
					fileReference={fileReference}
					onOpenFile={this.openSignedFile}
					onDownloadFile={this.downloadSignedFile}
					dropdownRenderer={this.renderDropdown}
				/>
				<ConfirmContainer
					isOpen={confirmModalIsOpen}
					title='documents.signatories.panel.delete_file.confirm.title'
					question='documents.signatories.panel.delete_file.confirm.question'
					onConfirm={this.doDeleteSignedDocument}
					onDecline={this.closeDeleteConfirmModal}
					isLoading={isLoading}
				/>
				{isSharedWithModalOpen && <SharedWithListContainer isOpen={true} onCancel={this.closeSharedWithModal} />}
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		documentId: store.documents.getIn(['document', 'id']),
		document: store.documents.get('document'),
		fileReference: store.documents.getIn(['document', 'eSigningData', 'fileReference']),
		userId: store.user.getIn(['userObj', 'id']),
		companyId: store.company.company.id
	}
}

const mapActionsToProps = {
	downloadDocument,
	cancelDocumentSigning
}

export default connect(mapStoreToProps, mapActionsToProps)(SignedDocumentFilePreview)
