import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import { FormGroup, FormGroupContainer } from '../../../mui-components/form-block/FromGroup'
import StepsModal from '../../../mui-components/steps-modal/steps-modal'
import SynaSearchContainer from '../../syna/mui/search.cotainer'
import { fetchCompanyData } from '../../../actions/syna.actions'
import { CompanyAddress } from '../../investments/company-address/CompanyAddress'
import { checkDealflowAvailability } from '../../../actions/company.actions'
import Button from '../../../mui-components/button/button'
import Tooltip from '../../../mui-components/tooltip/tooltip'
import { addErrorNotification } from '../../../actions/notify.actions'
import { validateEmail } from '../../../modules/validation.module'
import {
	addCompanyToDealflow,
	checkIfIHaveInvestmentInCompany,
	checkIfIHavePersonalInvestmentsInCompany
} from '../../../actions/dealflow.actions'
import { recommendInvonoToCompanyByDealflow } from '../../../actions/user.actions'

export default function SearchCompanyModal({ closeModal, dealflowCompanies }) {
	const [selectedCompany, setSelectedCompany] = useState()
	const [value, setValue] = useState('')
	const [email, setEmail] = useState('')
	const [companyIsRegistered, setCompanyIsRegistered] = useState(null)
	const [investmentExists, setInvestmentExists] = useState(null)
	const [personalInvestmentExists, setPersonalInvestmentExists] = useState(null)
	const [availableInDealflow, setAvailableInDealflow] = useState(null)
	const hasResponded =
		investmentExists !== null &&
		personalInvestmentExists !== null &&
		companyIsRegistered !== null &&
		availableInDealflow !== null

	const selectedCompanyIsAlreadyInDealFlow =
		selectedCompany &&
		dealflowCompanies.findIndex((dealflowcompany) => {
			return dealflowcompany.orgNumber === selectedCompany.company.orgNumber
		}) !== -1

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const synaSearchRef = useRef()

	const clearState = () => {
		setValue('')
		setSelectedCompany(undefined)
		setEmail('')
		setCompanyIsRegistered(null)
		setInvestmentExists(null)
		setPersonalInvestmentExists(null)
		setAvailableInDealflow(null)
	}

	const addToDealflow = () => {
		const { orgNumber } = selectedCompany.company

		dispatch(
			addCompanyToDealflow(orgNumber, () => {
				clearState()
				closeModal()
			})
		)
	}

	//const recommend = (changeStep) => {
	//	if (!(email && email.length > 0 && validateEmail(email))) {
	//		dispatch(addErrorNotification({ tid: 'dealflow.recommend_invono.errors.invalid_email' }))
	//		return
	//	}

	//	dispatch(recommendInvonoToCompanyByDealflow(email))
	//	changeStep(0)
	//}

	//const handleEmailInput = ({ target }) => {
	//	setEmail(target.value)
	//}

	//const goBackToSearchForm = (changeStep) => {
	//	changeStep(0)
	//}

	const handleCompanyCheck = (response) => {
		const { availableInDealflow, companyExists } = response
		setCompanyIsRegistered(companyExists)
		setAvailableInDealflow(availableInDealflow)
	}

	const handleSynaSearch = (selectedOption) => {
		if (!selectedOption) {
			return
		}

		dispatch(
			fetchCompanyData(selectedOption.value, (data) => {
				if (data && data.company) {
					setSelectedCompany(data)
					setValue(selectedOption)
					setTimeout(() => synaSearchRef?.current?.focus(), 1000)
					dispatch(checkDealflowAvailability(data.company.orgNumber, handleCompanyCheck))
					dispatch(checkIfIHaveInvestmentInCompany(data.company.orgNumber, setInvestmentExists))
					dispatch(
						checkIfIHavePersonalInvestmentsInCompany(data.company.orgNumber, setPersonalInvestmentExists)
					)
				} else {
					clearState()
				}
			})
		)
	}

	let infoBoxTid
	let disabledTooltipTid
	if (!availableInDealflow) {
		infoBoxTid = 'dealflow.add_company_modal.search.paragraph.opt_out'
		disabledTooltipTid = 'dealflow.add_company_modal.search.paragraph.opt_out'
	} else if (selectedCompanyIsAlreadyInDealFlow) {
		infoBoxTid = 'dealflow.add_company_modal.search.paragraph.already_in_dealflow'
		disabledTooltipTid = 'dealflow.add_company_modal.search.paragraph.already_in_dealflow'
	} else if (investmentExists) {
		infoBoxTid = 'dealflow.add_company_modal.search.paragraph.already_added_company.shareholder_exists'
		disabledTooltipTid =
			'dealflow.add_company_modal.search.paragraph.already_added_company.shareholder_exists_tooltip'
	} else if (personalInvestmentExists) {
		infoBoxTid = 'dealflow.add_company_modal.search.paragraph.already_added_company.personal_exists'
		disabledTooltipTid = 'dealflow.add_company_modal.search.paragraph.already_added_company.personal_exists_tooltip'
	}

	const renderSearchForm = ({ changeStep }) => {
		return (
			<FormGroupContainer>
				<FormGroup>
					<SynaSearchContainer
						label={t('dealflow.add_company_modal.search.label')}
						noOptionsText={t('dealflow.add_company_modal.search.label.noOptionText')}
						initialValue={value}
						onChange={(value) => {
							handleSynaSearch(value)
						}}
						onClear={clearState}
						inputRef={synaSearchRef}
					/>

					{!selectedCompany ? (
						<Typography variant='body2'>{t('dealflow.add_company_modal.search.paragraph')}</Typography>
					) : (
						<CompanyAddress company={selectedCompany.company} />
					)}
				</FormGroup>

				{value && hasResponded && (
					<>
						{!infoBoxTid && !companyIsRegistered && (
							<FormGroup>
								<Typography variant='body2'>
									{t('dealflow.add_company_modal.search.paragraph.not_invono_company')}
								</Typography>
								{/*<div>
									<Button variant='primary' onClick={() => changeStep(1)}>
										{t('dealflow.add_company_modal.search.recommend_button')}
									</Button>
						</div>*/}
							</FormGroup>
						)}
						{infoBoxTid && (
							<FormGroup>
								<Typography variant='body2'>{t(infoBoxTid)}</Typography>
							</FormGroup>
						)}
					</>
				)}
			</FormGroupContainer>
		)
	}

	//const renderRecommendForm = () => {
	//	return (
	//		<FormGroupContainer>
	//			<FormGroup>
	//				<TextField
	//					value={email}
	//					onChange={handleEmailInput}
	//					label={t('dealflow.add_company_modal.search.recommend.email_label')}
	//				/>
	//				<Typography variant='body2'>
	//					{t('dealflow.add_company_modal.search.recommend.email_paragraph')}
	//				</Typography>
	//			</FormGroup>
	//		</FormGroupContainer>
	//	)
	//}

	const disabled = !value || investmentExists || personalInvestmentExists || !hasResponded || !availableInDealflow
	const steps = [
		{
			slots: {
				title: 'dealflow.search_modal.search_form.title',
				body: renderSearchForm,
				footerRightActions: () => {
					return (
						<>
							<Tooltip
								title={
									!value
										? 'dealflow.add_company_modal.search.add_to_my_dealflow_button.tooltip'
										: disabledTooltipTid
								}
								hideTooltip={!disabled}>
								<Button
									disabled={disabled}
									variant='positive'
									tid='dealflow.add_company_modal.search.move_to_my_dealflow_button'
									onClick={addToDealflow}>
									{t('dealflow.add_company_modal.search.add_to_my_dealflow_button')}
								</Button>
							</Tooltip>
							<Button onClick={closeModal}>{t('dealflow.add_company_modal.search.cancel_button')}</Button>
						</>
					)
				}
			}
		}
		//{
		//	slots: {
		//		title: 'dealflow.search_modal.recommend_form.title',
		//		body: renderRecommendForm,
		//		footerLeftActions: ({ changeStep }) => {
		//			return (
		//				<Button onClick={() => goBackToSearchForm(changeStep)}>
		//					{t('dealflow.add_company_modal.search.previous_button')}
		//				</Button>
		//			)
		//		},
		//		footerRightActions: ({ changeStep }) => {
		//			const buttonDisabled = !(email && email.length > 0 && validateEmail(email))
		//			return (
		//				<Tooltip
		//					title='dealflow.add_company_modal.search.send_button.tooltip'
		//					hideTooltip={!buttonDisabled}>
		//					<Button onClick={() => recommend(changeStep)} variant='positive' disabled={buttonDisabled}>
		//						{t('dealflow.add_company_modal.search.send_button')}
		//					</Button>
		//				</Tooltip>
		//			)
		//		}
		//	}
		//}
	]

	return <StepsModal modalSize='sm' isOpen={true} steps={steps} />
}
