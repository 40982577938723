import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import AgGrid from '../../../dumb-components/shared/ag-grid/ag-grid'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAdvert, fetchCompanyAdverts } from '../../../actions/adverts.actions'
import { DateTime } from 'luxon'
import { DropDown } from '../../../mui-components/dropdown/DropDown'
import IconButton from '../../../mui-components/button/icon-button'
import MenuItemIcon from '../../../mui-components/dropdown/menu/menu-item-icon'
import Tooltip from '../../../mui-components/tooltip/tooltip'

export default function AdvertsGrid({ openModal }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const rowData = useSelector((state) => state.adverts.list)

	const shared = {
		sortable: false,
		suppressMenu: true
	}

	const onDeleteAdvert = (id) => {
		dispatch(deleteAdvert(id))
	}

	useEffect(() => {
		dispatch(fetchCompanyAdverts())
	}, [])

	if (rowData.length === 0) {
		return null
	}

	return (
		<Grid item md={12}>
			<Card>
				<CardHeader title={t('advertising.administration.overview.grid.title')} />
				<CardContent>
					<AgGrid
						rowData={[...rowData]}
						suppressContextMenu={true}
						components={{
							buttonRenderer: (params) => {
								const deleteDisabled = params.data.state === 'ACTIVE'

								return (
									<DropDown
										alignMenu='right'
										button={({ params }) => <IconButton noBorder icon='faEllipsisV' {...params} />}>
										<MenuItemIcon
											onClick={() => openModal('create_advert', params.data.id)}
											icon='faEdit'
											listItemTid='advertising.administration.adverts_grid.buttons.edit'
										/>
										<MenuItemIcon
											onClick={() => openModal('create_advert', params.data.id, true)}
											icon='faEye'
											listItemTid='advertising.administration.adverts_grid.buttons.preview'
										/>
										<Tooltip
											title='advertising.administration.adverts_grid.buttons.delete.tooltip'
											hideTooltip={!deleteDisabled}
											placement='bottom'>
											<MenuItemIcon
												onClick={() => !deleteDisabled && onDeleteAdvert(params.data.id)}
												icon='faTrashAlt'
												listItemTid='advertising.administration.adverts_grid.buttons.delete'
												disabled={deleteDisabled}
											/>
										</Tooltip>
									</DropDown>
								)
							}
						}}
						columnDefs={[
							{
								field: 'createdAt',
								headerName: t('advertising.administration.adverts_grid.created'),
								valueFormatter: (params) => {
									const dt = DateTime.fromISO(params.value)
									return dt.toLocaleString()
								},
								maxWidth: 130,
								...shared
							},
							{
								field: 'name',
								headerName: t('advertising.administration.adverts_grid.campaign_name'),
								flex: 1,
								...shared
							},
							{
								field: 'dateStart',
								headerName: t('advertising.administration.adverts_grid.date_from'),
								valueFormatter: (params) => {
									const dt = DateTime.fromISO(params.value)
									return dt.toLocaleString()
								},
								maxWidth: 130,
								...shared
							},
							{
								field: 'duration',
								headerName: t('advertising.administration.adverts_grid.data_to'),
								valueFormatter: (params) => {
									const dt = DateTime.fromISO(params.data.dateStart)
									return dt.plus({ months: params.value }).toLocaleString()
								},
								maxWidth: 130,
								...shared
							},
							{
								field: 'state',
								headerName: t('advertising.administration.adverts_grid.status'),
								valueFormatter: (params) => {
									return t(`advertising.states.${params.value}`)
								},
								maxWidth: 170,
								...shared
							},
							{
								field: 'edit',
								headerName: '',
								cellRenderer: 'buttonRenderer',
								maxWidth: 85,
								...shared
							}
						]}
					/>
				</CardContent>
			</Card>
		</Grid>
	)
}
