import React, { useEffect, useState } from 'react'
import WhiteSection from '../../common/white-section'
import Box from '@mui/material/Box'
import Registrering from '../../../../assets/images/emissions/Registrering.svg'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import { emissionConsts } from '../../../../constants/emissions'
import Chip from '@mui/material/Chip'
import CheckIcon from '@mui/icons-material/Check'
import { deleteDocument, getInvitees, getSubmissions, uploadDocument } from '../../../../actions/emissions.actions'
import { FileToUpload } from '../preparations/preparations'
import { useDispatch, useSelector } from 'react-redux'
import RegistrationPopUpModal from './registration-pop-up-modal'
import { addErrorNotification } from '../../../../actions/notify.actions'
import {
	Confirmations,
	DecisionTypeAndPaymentMethodComponent,
	DocumentsToRegistrationsOffice,
	InfoBox
} from './registration-components'
import { emissionIsReadOnly } from '../../utils/emission-status-helper'
import SelectPartialClosure from './select-partial-closure-component'
import { Link } from 'react-router-dom'

const { documentTypes } = emissionConsts.common

const Registration = ({ emission }) => {
	const { t } = useTranslation()
	const [registered, setRegistered] = useState(false)
	const dispatch = useDispatch()
	const documentMap = useSelector((state) => state.emissions.documentMap)
	const [closure, setClosure] = useState(emission.closures[emission.closures.length - 1])
	const closures = emission.closures
	const [popUpModal, setPopUpModal] = useState(false)
	const preventChanges = emissionIsReadOnly(emission, Registration.name)
	const [chipLabel, setChipLabel] = useState('')
	const [closureIndex, setClosureIndex] = useState(closures.length - 1)

	useEffect(() => {
		setClosure(closures[closureIndex])
		reg()
	}, [emission])

	const reg = () => {
		const amountRegistered = closures?.filter((c) => c.registered === true).length
		if (amountRegistered === 0 || closures.length === 0) {
			setChipLabel('emissions.registration.chip-error')
			setRegistered(false)
		} else if (amountRegistered > 0 && amountRegistered < closures.length) {
			setChipLabel('emissions.registration.chip-partial')
			setRegistered(false)
		} else if (amountRegistered === closures.length) {
			setChipLabel('emissions.registration.chip-success')
			setRegistered(true)
		}
	}

	const {
		common: { paymentTypes, newShareCountTypes }
	} = emissionConsts

	useEffect(() => {
		async function fetchInvitees() {
			try {
				await dispatch(getInvitees(emission.companyId, emission.id))
			} catch (e) {
				dispatch(addErrorNotification(e.message))
			}
		}
		async function fetchSubmissions() {
			try {
				await dispatch(getSubmissions(emission.companyId, emission.id))
			} catch (e) {
				dispatch(addErrorNotification(e.message))
			}
		}

		fetchSubmissions()
		fetchInvitees()
	}, [])

	return (
		<>
			{emission.newShareCountType === newShareCountTypes.specific ||
			emission.paymentType === paymentTypes.nonMonetary ||
			emission.paymentType === paymentTypes.setOff ? (
				<>
					{closures.length === 0 ? (
						<Box position={'relative'}>
							<WhiteSection
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									height: '70rem'
								}}>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Box sx={{ display: 'flex' }}>
										<img src={Registrering} style={{ height: '300px' }} />
									</Box>
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<Typography variant={'h4'}>
											{t('emissions.registration.registration-of-new-emission.header')}
										</Typography>
										<Typography variant={'body2'}>
											{t('emissions.registration.registration-of-new-emission.desc')}
										</Typography>
									</Box>
								</Box>
								<Box position={'absolute'} bottom={'32px'} right={'48px'}>
									<Link to={location.pathname.replace(/\/[^\/]*$/, '/payment')}>
										<Button
											disabled={preventChanges}
											variant={'contained'}
											sx={{ display: 'flex', fontWeight: 'bold' }}>
											<Typography variant={'h5'}>
												{t('emissions.registration.registration-of-new-emission.button')}
											</Typography>
										</Button>
									</Link>
								</Box>
							</WhiteSection>
						</Box>
					) : (
						<WhiteSection sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '600px' }}>
									<Typography variant={'h5'}>
										{t('emissions.registration.registration-of-new-emission.header')}
									</Typography>
									<Typography variant={'body2'} sx={{ my: '16px' }}>
										{t('emissions.registration.closure-registration-of-new-emission.desc')}
									</Typography>
									<Typography variant={'body2'} sx={{ mt: '8px', mb: '64px' }}>
										{t('emissions.registration.partial-closure-registration-of-new-emission.desc2')}
									</Typography>
								</Box>
								{registered ? (
									<Chip
										icon={<CheckIcon />}
										label={t(chipLabel)}
										sx={{
											color: 'success.dark',
											bgcolor: 'success.100',
											'& .MuiChip-icon': {
												color: 'success.dark'
											}
										}}
									/>
								) : (
									<Chip
										label={t(chipLabel)}
										sx={{
											color: 'error.700',
											bgcolor: 'error.100'
										}}
									/>
								)}
							</Box>
							<Box sx={{ display: 'flex', ml: '40px', mt: '48px' }}>
								<DecisionTypeAndPaymentMethodComponent
									preventChanges={preventChanges}
									emission={emission}
								/>
							</Box>
							<DocumentsToRegistrationsOffice
								preventChanges={preventChanges}
								emission={emission}
								documentMap={documentMap}
							/>
							<InfoBox mb={'32px'} />
							<Confirmations
								preventChanges={preventChanges}
								emission={emission}
								documentMap={documentMap}
								closure={closure}
							/>
							<RegistrationPopUpModal
								emission={emission}
								closure={closure}
								open={popUpModal}
								close={() => setPopUpModal(false)}
								header={t('emissions.registration.register-new-emission.button')}
								desc={t('emissions.registration.pop-up.desc')}
								desc2={t('emissions.registration.pop-up.desc2')}
								button={t('emissions.registration.register-new-emission.button')}
							/>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									my: '56px',
									mx: '40px'
								}}>
								<Button
									disabled={preventChanges}
									onClick={() => setPopUpModal(true)}
									variant={'contained'}>
									<Typography variant={'h5'}>
										{t('emissions.registration.register-new-emission.button')}
									</Typography>
								</Button>
							</Box>
						</WhiteSection>
					)}
				</>
			) : (
				<WhiteSection sx={{ display: 'flex', flexDirection: 'column' }}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '600px' }}>
							<Typography variant={'h5'}>
								{t('emissions.registration.registration-of-new-emission.header')}
							</Typography>
							<Typography variant={'body2'} sx={{ my: '16px' }}>
								{t('emissions.registration.partial-closure-registration-of-new-emission.desc')}
							</Typography>
							<Typography variant={'body2'} sx={{ mt: '8px', mb: '64px' }}>
								{t('emissions.registration.partial-closure-registration-of-new-emission.desc2')}
							</Typography>
						</Box>
						{registered ? (
							<Chip
								icon={<CheckIcon />}
								label={t(chipLabel)}
								sx={{
									color: 'success.dark',
									bgcolor: 'success.100',
									'& .MuiChip-icon': {
										color: 'success.dark'
									}
								}}
							/>
						) : (
							<Chip
								label={t(chipLabel)}
								sx={{
									color: 'error.700',
									bgcolor: 'error.100'
								}}
							/>
						)}
					</Box>
					<Box sx={{ display: 'flex', ml: '40px' }}>
						<DecisionTypeAndPaymentMethodComponent preventChanges={preventChanges} emission={emission} />
					</Box>
					<DocumentsToRegistrationsOffice
						preventChanges={preventChanges}
						emission={emission}
						documentMap={documentMap}
					/>
					<InfoBox mb={'72px'} />
					<SelectPartialClosure
						emission={emission}
						closures={closures}
						setClosure={setClosure}
						setClosureIndex={setClosureIndex}
						closureIndex={closureIndex}
					/>
					{emission.closures.length === 0 && (
						<Box
							position={'relative'}
							sx={{
								display: 'flex',
								mt: '16px',
								minHeight: '342px',
								borderRadius: '4px',
								border: '1px solid #E0E0E0'
							}}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Box sx={{ display: 'flex', ml: '80px' }}>
									<img src={Registrering} style={{ height: '200px' }} />
								</Box>
								<Box
									sx={{
										ml: '54px',
										maxWidth: '520px',
										display: 'flex',
										flexDirection: 'column'
									}}>
									<Typography variant={'h4'}>{t('emissions.registration.closure')}</Typography>
									<Typography variant={'body2'} sx={{ mt: '16px', color: '#757575' }}>
										{t('emissions.registration.empty-state.desc')}
									</Typography>
								</Box>
							</Box>
							<Box position={'absolute'} bottom={'32px'} right={'48px'}>
								<Link to={location.pathname.replace(/\/[^\/]*$/, '/payment')}>
									<Button
										disabled={preventChanges}
										variant={'contained'}
										sx={{ display: 'flex', fontWeight: 'bold' }}>
										<Typography variant={'h5'}>
											{t('emissions.registration.registration-of-new-emission.button')}
										</Typography>
									</Button>
								</Link>
							</Box>
						</Box>
					)}
					{emission.closures.length > 0 && (
						<>
							<Box sx={{ display: 'flex', mx: '40px', my: '40px', alignItems: 'center' }}>
								<Typography variant={'h5'} sx={{ mr: '32px' }}>
									{emission.closures.length === 1
										? t('emissions.registration.closure')
										: `${t('emissions.registration.partial-closure')} ${closure.index}`}
								</Typography>
								{closure.registered ? (
									<Chip
										icon={<CheckIcon />}
										label={t('emissions.registration.chip-success')}
										sx={{
											color: 'success.dark',
											bgcolor: 'success.100',
											'& .MuiChip-icon': {
												color: 'success.dark'
											}
										}}
									/>
								) : (
									<Chip
										label={t('emissions.registration.chip-error')}
										sx={{
											color: 'error.700',
											bgcolor: 'error.100'
										}}
									/>
								)}
							</Box>
							<Box
								position={'relative'}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									minHeight: '252px',
									mx: '40px',
									mt: '32px',
									border: '1px solid #E0E0E0',
									borderRadius: '4px'
								}}>
								<Typography position={'absolute'} top={'32px'} left={'40px'} variant={'h6'}>
									{t('emissions.registration.documents-to-Bolagsverket.header')}
								</Typography>
								<Box
									sx={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										mb: '16px',
										mt: '56px'
									}}>
									{partialClosureEntries.map((t, i) => {
										const key = `${closure.index}_${t.fileKey}`
										const info = documentMap[key]

										return t.visible() ? (
											<Box
												key={t.fileKey}
												sx={{
													display: 'flex',
													alignItems: 'center',
													mx: '40px'
												}}>
												<FileToUpload
													preventChanges={preventChanges}
													mailIcon
													key={t.fileKey}
													title={t.title}
													filekey={key}
													fileName={info ? info.name : ''}
													fileId={info ? info.id : ''}
													onFileDeleted={() =>
														dispatch(deleteDocument(emission.companyId, emission.id, key))
													}
													onFilePicked={(file) =>
														dispatch(
															uploadDocument(emission.companyId, emission.id, key, file)
														)
													}
												/>
											</Box>
										) : null
									})}
								</Box>
							</Box>
							<Confirmations
								preventChanges={preventChanges}
								emission={emission}
								documentMap={documentMap}
								closure={closure}
							/>
							<RegistrationPopUpModal
								emission={emission}
								closure={closure}
								open={popUpModal}
								close={() => setPopUpModal(false)}
								header={
									emission.closures.length === 1
										? t('emissions.payment.register-closure-button')
										: t('emissions.payment.register-partial-closure-button')
								}
								desc={t('emissions.registration.closure-pop-up.desc')}
								desc2={t('emissions.registration.pop-up.desc2')}
								button={
									emission.closures.length === 1
										? t('emissions.payment.register-closure-button')
										: t('emissions.payment.register-partial-closure-button')
								}
							/>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									my: '56px',
									mx: '40px'
								}}>
								<Button
									disabled={preventChanges || closure.registered}
									onClick={() => setPopUpModal(true)}
									variant={'contained'}>
									<Typography variant={'h5'}>{`${
										emission.closures.length === 1
											? t('emissions.payment.register-closure-button')
											: `${t('generic.register')} ${t(
													'emissions.registration.partial-closure'
											  ).toLowerCase()} ${closure.index}`
									} `}</Typography>
								</Button>
							</Box>
						</>
					)}
				</WhiteSection>
			)}
		</>
	)
}

const partialClosureEntries = [
	{
		title: 'emissions.document-types.form-822',
		fileKey: documentTypes.form822,
		visible: () => true
	},
	{
		title: 'emissions.document-types.bank-statement',
		fileKey: documentTypes.bankStatement,
		visible: () => true
	},
	{
		title: 'emissions.document-types.board-minutes',
		fileKey: documentTypes.boardMinutes,
		visible: () => true
	}
]

export default Registration
