import React from 'react'
import Icon from '../../icon/icon'

function TickCellRenderer(params) {
	if (!params.value) {
		return null
	}

	return <Icon icon={'faCheck'} />
}

export { TickCellRenderer }
