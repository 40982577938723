import React, { PureComponent } from 'react'
import { string, func, bool } from 'prop-types'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import Text from '../text/text'

const Wrapper = styled.div`
	position: relative;
`

const Fade = styled.div`
	height: 50px;
	margin-top: -50px;
	position: relative;

	${(props) => {
		const itemColor = rgba(props.theme.colors[props.backgroundStart], 0)
		const backgroundColor = rgba(props.theme.colors[props.backgroundEnd], 1)

		return css`
			background: ${(props) => `linear-gradient(to bottom, ${itemColor} 0%, ${backgroundColor} 75%);`};
		`
	}}
`

const BorderThrough = styled.div`
	position: absolute;
	height: 60%;
	border-bottom: 1px dashed ${(props) => props.theme.colors.midGrey};
	width: 100%;
`

const TextWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;

	${({ expanded }) =>
		expanded &&
		css`
			margin-top: ${(props) => props.theme.spacing[4]};
		`}
`

const StyledText = styled(Text)`
	background: ${(props) => props.theme.colors[props.backgroundColor]};
	padding: 0 ${(props) => props.theme.spacing[3]};
	z-index: 1;
`

export default class FakeShowMore extends PureComponent {
	static propTypes = {
		textTid: string,
		onToggle: func,
		backgroundStart: string,
		backgroundEnd: string,
		expanded: bool
	}

	static defaultProps = {
		textTid: 'generic.show_more',
		backgroundStart: 'white',
		backgroundEnd: 'solitudeMid'
	}

	render = () => {
		const { children, expanded, textTid, onToggle, backgroundStart, backgroundEnd } = this.props

		return (
			<Wrapper>
				{children}

				{!expanded && <Fade backgroundStart={backgroundStart} backgroundEnd={backgroundEnd} />}

				<TextWrapper expanded={expanded}>
					<StyledText tid={textTid} onClick={onToggle} backgroundColor={backgroundEnd} />
					<BorderThrough />
				</TextWrapper>
			</Wrapper>
		)
	}
}
