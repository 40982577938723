import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { useDropzone } from 'react-dropzone-with-hooks'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import BlockIcon from '@mui/icons-material/Block'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'

const FileDropZone = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const [isUploading, setIsUploading] = useState(false)

	const { getRootProps, getInputProps, isDragActive, isDragAccept, open } = useDropzone({
		onDrop: (acceptedFiles, rejectedFiles) => {
			setIsUploading(true)
			props.onDrop(acceptedFiles, rejectedFiles, () => {
				setIsUploading(false)
			})
		},
		accept: props.accept,
		disabled: isUploading
	})

	const idleContent = {
		borderColor: theme.palette.primary['500'],
		bgColor: theme.palette.primary['50'],
		mainTextColor: theme.palette.text.primary,
		extTextColor: theme.palette.grey['500'],
		icon: isUploading ? (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<CircularProgress size={25} color={'grey'} />{' '}
				<Typography component='span' variant='body2' sx={{ ml: 1 }}>
					{t('emissions_module.marketing.uploading-file')}
				</Typography>
			</Box>
		) : (
			<UploadFileIcon
				fontSize={'large'}
				sx={{
					color: theme.palette.primary['500']
				}}
			/>
		),
		messageComponent: (
			<Typography variant='body2'>
				<Trans
					i18nKey='file-drop-zone.message.idle'
					components={{
						x: (
							<Typography
								component={'span'}
								variant={'body2'}
								onClick={open}
								sx={{
									'&:hover': {
										color: theme.palette.primary['700']
									},
									color: theme.palette.primary['500'],
									fontWeight: 'bold',
									cursor: 'pointer',
									py: 3,
									pr: 2
								}}
							/>
						)
					}}
				/>
			</Typography>
		)
	}

	const errorContent = {
		borderColor: theme.palette.error['600'],
		bgColor: theme.palette.error['50'],
		mainTextColor: theme.palette.error['700'],
		extTextColor: 'transparent',
		icon: (
			<BlockIcon
				fontSize={'large'}
				sx={{
					color: theme.palette.error['600']
				}}
			/>
		),
		messageComponent: (
			<Typography variant='body2' sx={{ color: theme.palette.error['700'] }}>
				{t('file-drop-zone.message.error')}
			</Typography>
		)
	}

	const successContent = {
		borderColor: theme.palette.primary['500'],
		bgColor: theme.palette.primary['100'],
		mainTextColor: theme.palette.text.primary,
		extTextColor: 'transparent',
		icon: (
			<UploadFileIcon
				fontSize={'large'}
				sx={{
					color: theme.palette.primary['500']
				}}
			/>
		),
		messageComponent: <Typography variant='body2'>{t('file-drop-zone.message.success')}</Typography>
	}

	const content = isDragActive ? (isDragAccept ? successContent : errorContent) : idleContent

	return (
		<Box
			sx={{
				display: 'flex',
				p: 3,
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				bgcolor: content.bgColor,
				borderRadius: 1,
				border: `1px dashed ${content.borderColor}`
			}}
			{...getRootProps()}>
			<input {...getInputProps()} />
			{content.icon}
			<Box sx={{ mt: 2 }}>{content.messageComponent}</Box>
			<Box>
				<Typography
					variant='caption'
					sx={{
						color: content.extTextColor
					}}>
					{props.fileExtMessage}
				</Typography>
			</Box>
		</Box>
	)
}

export default FileDropZone
