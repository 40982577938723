import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { fromJS, Map } from 'immutable'
import { getFullShareTypeLabel } from '../../../helpers/shares'
import Moment from '../../../../modules/moment.module'
import TextBox from '../../../framework/textbox'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import { NUMBER_FORMAT_CURRENCY, NUMBER_FORMAT_INTEGER } from '/shared/constants'

class ReductionOfShareCapitalView extends Component {
	componentDidMount = () => { }

	calcQuotaValue = (currentQuota, newShareCapital, newNumOfShares) => {
		const { tmpTransaction, transaction } = this.props

		if (!tmpTransaction) {
			return
		}

		const type = tmpTransaction.getIn(['handlerData', 'type'])
		const numOfTotalShares = transaction.getIn(['shareData', 'numOfTotalShares'])

		if (type === 'shares') {
			return newShareCapital / Math.floor(newNumOfShares)
		}

		return newShareCapital / numOfTotalShares
	}

	calcNumOfShares = (quotaValue, newShareCapital) => {
		const { tmpTransaction, transaction } = this.props

		if (!tmpTransaction) {
			return
		}

		const type = tmpTransaction.getIn(['handlerData', 'type'])
		const numOfTotalShares = transaction.getIn(['shareData', 'numOfTotalShares'])

		if (type === 'quota') {
			return numOfTotalShares
		}

		return newShareCapital / quotaValue
	}

	calcNumOfSharesToRenove = (currentNumOfShares, newNumOfShares) => {
		const { tmpTransaction } = this.props
		const type = tmpTransaction.getIn(['handlerData', 'type'])

		if (type === 'quota') {
			return
		}

		let numOfRemovedShares = 0
		tmpTransaction.getIn(['handlerData', 'investments'], Map()).map((investment) => {
			investment &&
				investment.forEach((obj) => {
					numOfRemovedShares += parseInt(obj.get('diff') || 0)
				})
		})
		const numOfSharesToRemove = currentNumOfShares - newNumOfShares - numOfRemovedShares
		return !isNaN(numOfSharesToRemove) ? numOfSharesToRemove : 0
	}

	render = () => {
		const { transaction, tmpTransaction } = this.props
		if (!transaction) {
			return null
		}

		const currentDate = transaction.get('date')
		const currentShareCapital = transaction.getIn(['shareData', 'shareCapital'])
		const currentQuota = transaction.getIn(['shareData', 'quotaValue'])
		const currentNumOfShares = transaction.getIn(['shareData', 'numOfTotalShares'], 0)

		let newDate
		let newShareCapital
		let newQuota
		let newNumOfShares
		let remainingNumOfSharesToRemove
		let transactionBegan = false

		if (tmpTransaction) {
			const decreaseShareCapitalBy = tmpTransaction.getIn(['handlerData', 'decreaseShareCapitalBy'], 0)
			newDate = tmpTransaction.get('date')
			newShareCapital = currentShareCapital - decreaseShareCapitalBy
			transactionBegan = currentShareCapital !== newShareCapital
			newNumOfShares = this.calcNumOfShares(currentQuota, newShareCapital)
			newQuota = this.calcQuotaValue(currentQuota, newShareCapital, newNumOfShares)
			remainingNumOfSharesToRemove = this.calcNumOfSharesToRenove(currentNumOfShares, newNumOfShares)
		}

		return (
			<div className='i-panel i-panel--white'>
				<div className='i-panel__body i-panel__body i-panel__body--pad-hor'>
					<div className='list__list-header'>
						<div className='list__list-header__text-area list__list-header__text-area--x3 list__list-header__text-area--pad-lft list__list-header__text-area--pad-right'>
							&nbsp;
						</div>
						<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
							<span>{Moment(currentDate).format('L')}</span>
						</div>
						<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
							{newDate && Moment(newDate).format('L')}
						</div>
					</div>
					<div className='list list--table list--striped'>
						<div className='list__item'>
							<span className='list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left'>
								<FormattedMessage id='share_capital' />
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{currentShareCapital && localeFormatNumber(currentShareCapital, NUMBER_FORMAT_CURRENCY)}
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{transactionBegan && newShareCapital && localeFormatNumber(newShareCapital, NUMBER_FORMAT_CURRENCY)}
							</span>
						</div>
						<div className='list__item'>
							<span className='list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left'>
								<FormattedMessage id='shares.transactions.reduction_of_share_capital.number_of_shares' />
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{currentNumOfShares && localeFormatNumber(currentNumOfShares, NUMBER_FORMAT_INTEGER)}
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{transactionBegan &&
									newNumOfShares &&
									localeFormatNumber(Math.floor(newNumOfShares), NUMBER_FORMAT_INTEGER)}
							</span>
						</div>
						<div className='list__item'>
							<span className='list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left'>
								<FormattedMessage id='quota_value' />
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{currentQuota && localeFormatNumber(currentQuota, NUMBER_FORMAT_CURRENCY)}
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{transactionBegan && newQuota && localeFormatNumber(newQuota, NUMBER_FORMAT_CURRENCY)}
							</span>
						</div>
						{tmpTransaction && tmpTransaction.getIn(['handlerData', 'type']) === 'shares' && (
							<div className='list__item'>
								<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-lft list__item__text-area--pad-right'></span>
								<span className='list__item__text-area list__item__text-area--x5 list__item__text-area--pad-right list__item__text-area--right'>
									<span className='h-box'>
										<strong>
											<FormattedMessage id='shares.transactions.reduction_of_share_capital.reduce_shares_by' />
											:&nbsp;
										</strong>
										{localeFormatNumber(Math.ceil(remainingNumOfSharesToRemove), NUMBER_FORMAT_INTEGER)}
									</span>
								</span>
							</div>
						)}
					</div>
					<FormattedHTMLMessage id='shares.transactions.reduction_of_share_capital.information' />
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get('transaction'),
		tmpTransaction: state.transaction.getIn(['tmpTransaction', 'REDUCTION-OF-SHARE-CAPITAL']),
		i18n: state.i18n
	}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ReductionOfShareCapitalView)
