import { fromJS, Map } from 'immutable'

import {
	USER_CACHE_ADD_USERS_TO_CACHE,
	USER_CACHE_REMOVE_USER,
	USER_CACHE_UPDATE_REQUEST_CACHE,
	USER_CACHE_RESET_REDUCER
} from '../actions/types'

const INITIAL_STATE = fromJS({
	usersCache: {},
	requestCache: {}
})

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case USER_CACHE_ADD_USERS_TO_CACHE: {
			const users = action.payload
			users.forEach((user) => {
				state = state.setIn(['usersCache', user.get('id')], user)
				state = state.removeIn(['requestCache', user.get('id')])
			})
			return state
		}

		case USER_CACHE_REMOVE_USER: {
			const userId = action.payload
			const users = state.get('usersCache', Map()).filter((user, id) => {
				return id !== userId
			})

			return state.set('usersCache', users)
		}

		case USER_CACHE_UPDATE_REQUEST_CACHE: {
			const userIds = action.payload
			userIds.forEach((id) => {
				state = state.setIn(['requestCache', id], id)
			})
			return state
		}
		case USER_CACHE_RESET_REDUCER: {
			return INITIAL_STATE
		}

		default:
			return state
	}
}
