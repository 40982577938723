/**
 * See React DnD documentation for useDrop: https://react-dnd.github.io/react-dnd/docs/api/use-drop
 */
import React from 'react'
import { useDrop } from 'react-dnd'
import { string, symbol, array, func, object, oneOfType, bool } from 'prop-types'

const Droppable = ({ accept, options, onHover, onDrop, disabled, droppableId, children }) => {
	const [collected, drop] = useDrop(() => ({
		accept,
		options,
		drop(item, monitor) {
			onDrop && onDrop(item, droppableId, monitor)

			return {
				droppableId
			}
		},
		hover: onHover,
		canDrop() {
			return !disabled
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
			handlerId: monitor.getHandlerId()
		})
	}), [droppableId, disabled, onHover, onDrop, options, accept])

	const { isOver, canDrop: canDropItem } = collected
	const height = isOver && canDropItem ? '100px' : '50px'
	const backgroundColor = isOver && canDropItem ? '#f9f9f9' : 'transparent'
	const style = {
		display: 'flex',
		alignItems: 'flex-end',
		transition: 'height 200ms ease-in-out',
		height,
		backgroundColor
	}

	return children(collected, style, drop)
}

Droppable.propTypes = {
	accept: oneOfType([string, symbol, array, func]).isRequired,
	options: object,
	onDrop: func,
	onHover: func,
	disabled: bool,
	droppableId: string.isRequired,
	children: func.isRequired
}

Droppable.defaultProps = {
	disabled: false
}

export default Droppable
