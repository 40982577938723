import React, { PureComponent } from 'react'
import { bool, func } from 'prop-types'
import styled from 'styled-components'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import Text from '../../shared/text/text'
import Alert from '../../shared/alert/alert'
import SimpleDarkInfoPanel from '../../shared/dark-info-panel/simple-dark-info-panel'
import Tooltip from '../../shared/tooltip/tooltip'

const StyledModeratorWrapper = styled.div`
	flex-direction: row;
	display: flex;
`

const StyledModeratorToggleSwitchWrapper = styled.div`
	margin-left: auto;
`

const StyledModeratorWarningWrapper = styled.div`
	display: flex;
	margin-top: ${(props) => props.theme.spacing[3]};
`

const StyledSimpleDarkInfoPanel = styled(SimpleDarkInfoPanel)`
	display: flex;
	margin-top: ${(props) => props.theme.spacing[3]};
`

export default class ModeratorPanel extends PureComponent {
	static propTypes = {
		onToggle: func,
		isModerator: bool,
		showCannotChangeModeratorWarning: bool,
		moderatorHasChanged: bool,
		attendingByProxy: bool
	}

	render = () => {
		const { onToggle, isModerator, moderatorHasChanged, attendingByProxy } = this.props
		const moderatorHasChangedTid =
			moderatorHasChanged && isModerator
				? 'meetings.standard.manage_attendee.toggle_switch.moderator_has_changed.added'
				: 'meetings.standard.manage_attendee.toggle_switch.moderator_has_changed.removed'

		return (
			<>
				<StyledModeratorWrapper>
					<Text tid='meetings.standard.manage_attendee.toggle_switch.assign_moderator' />
					<StyledModeratorToggleSwitchWrapper>
						<Tooltip
							active={attendingByProxy}
							tid='meetings.agm.manage_attendee.moderator.tooltip.toggleswitch.disabled_if_attending_by_proxy'
							delayShow='instant'
							maxWidth='250px'
							place='left'>
							<ToggleSwitch onChange={onToggle} checked={isModerator} disabled={attendingByProxy} />
						</Tooltip>
					</StyledModeratorToggleSwitchWrapper>
				</StyledModeratorWrapper>

				{moderatorHasChanged && (
					<StyledSimpleDarkInfoPanel>
						<Text tid={moderatorHasChangedTid} color='white' />
					</StyledSimpleDarkInfoPanel>
				)}
			</>
		)
	}
}
