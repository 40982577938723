import req from '../modules/request.module'
import { fromJS, Map } from 'immutable'
import { convertToRaw, convertFromRaw } from 'draft-js'
import {
	COMMENTS_UPDATE_LOCAL,
	COMMENTS_CREATE_COMMENT,
	COMMENTS_UPDATE_REMOTE,
	COMMENTS_EDIT_LOCAL,
	COMMENTS_DELETE_LOCAL,
	COMMENTS_FETCH_MULTIPLE_REMOTE
} from './types'
import { LIVE_CONVERSATION_UPDATE } from '../constants/live-update'
import { setLiveRequest, resetLiveRequest } from './live-update.actions'

export function updateLocal(comments) {
	return { type: COMMENTS_UPDATE_LOCAL, payload: comments }
}

export function addComment(objType, objId, comment, objTitle, objUrl) {
	return function (dispatch) {
		dispatch({ type: COMMENTS_CREATE_COMMENT, payload: Map({ objId, comment }) })
		comment = comment.update('text', (text) => JSON.stringify(convertToRaw(text)))

		return req
			.post(`/comments/${objId}`, { comment: comment.toJS(), objType, objTitle, objUrl })
			.then((response) => {
				const conversationObject = convertCommentsTextFromRaw(fromJS(response.data))
				dispatch({ type: COMMENTS_UPDATE_REMOTE, payload: conversationObject })
			})
			.catch((e) => {
				console.log(e)
			})
	}
}

export function editComment(objId, comment, objTitle, objUrl) {
	return function (dispatch) {
		const commentId = comment.get('id')
		const commentText = JSON.stringify(convertToRaw(comment.get('text')))

		dispatch({ type: COMMENTS_EDIT_LOCAL, payload: Map({ objId, comment }) })

		return req
			.put(`/comments/${objId}/${commentId}`, { commentText, objTitle, objUrl })
			.then((response) => {
				const conversationObject = convertCommentsTextFromRaw(fromJS(response.data))
				dispatch({ type: COMMENTS_UPDATE_REMOTE, payload: conversationObject })
			})
			.catch((e) => {
				console.log(e)
			})
	}
}

export function deleteComment(objId, commentId) {
	return function (dispatch) {
		dispatch({ type: COMMENTS_DELETE_LOCAL, payload: Map({ objId, commentId }) })

		return req
			.delete(`/comments/${objId}/${commentId}`)
			.then((response) => {
				const conversationObject = convertCommentsTextFromRaw(fromJS(response.data))
				dispatch({ type: COMMENTS_UPDATE_REMOTE, payload: conversationObject })
			})
			.catch(() => {
				console.log('Error in deleteComment')
			})
	}
}

export function deleteConversation(objId, callback) {
	return function (dispatch) {
		return req
			.delete(`/comments/conversation/${objId}`)
			.then(() => {
				callback && callback()
			})
			.catch((e) => {
				console.log(e)
			})
	}
}

export function fetchConversation(objId) {
	return function (dispatch) {
		return req
			.get(`/comments/${objId}`)
			.then((response) => {
				const conversationObject = convertCommentsTextFromRaw(fromJS(response.data))

				// Insert dummy comment into the store if no comment was found.
				// Used to make live-fetching work.
				if (conversationObject.has('comments')) {
					dispatch({ type: COMMENTS_UPDATE_REMOTE, payload: conversationObject })
				} else {
					let dummyConversationObject = conversationObject.set('objId', objId)
					dispatch({ type: COMMENTS_UPDATE_REMOTE, payload: dummyConversationObject })
				}
			})
			.catch((e) => {
				console.log(e)
			})
	}
}

export function listConversations(objIds) {
	return function (dispatch) {
		return req.post(`/comments/conversation/multiple`, { objIds }).then((response) => {
			let conversations = fromJS(response.data)

			if (conversations) {
				conversations = conversations.map((conversation) => {
					conversation = convertCommentsTextFromRaw(conversation)
					return conversation
				})

				dispatch({ type: COMMENTS_FETCH_MULTIPLE_REMOTE, payload: conversations })
			}
		})
	}
}

function convertCommentsTextFromRaw(conversationObject) {
	return conversationObject.update('comments', (comments) => {
		return (
			comments &&
			comments.map((comment) => {
				comment = comment.update('text', (text) => convertFromRaw(JSON.parse(text)))
				return comment
			})
		)
	})
}

export function socketEventComments(eventObj) {
	const { eventName, objId } = eventObj

	return function (dispatch) {
		switch (eventName) {
			case LIVE_CONVERSATION_UPDATE: {
				dispatch(setLiveRequest(['comments', eventName], { r: true, objId }))
				dispatch(resetLiveRequest(['comments', eventName]))
				break
			}
		}
	}
}
