import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import { List, Map } from 'immutable';
import { closeModal, openModal } from '../../../actions/modals.actions';
import {
	MEETING_TEMPLATES_IMPORTANT_INFORMATION_MODAL,
	MEETING_TEMPLATES_MODAL,
	PROTOCOL_PDF_OPTIONS_MODAL
} from '../../../constants/modals';
import { deleteMeetingsPerminantly } from '../../../actions/meetings.actions';
import { downloadGroupMatrix } from '../../../actions/groups.actions';

import MeetingTemplatesModalContainer from '../meeting-templates/meeting-templates-modal.container';
import IconDrawer from '../../../dumb-components/shared/icon-drawer/icon-drawer';
import ProtocolPdfOptionsModal from '../protocol-pdf-options/protocol-pdf-options-modal.container';
import ConfirmContainer from '../../shared/confirm.container';
import history from '../../../interfaces/history';
import TemplateInformationModal from '../../../dumb-components/meetings/modals/template-information-modal';

const TEMPLATE_TOOLTIP_STATES = {
	default: {
		tid: 'meetings.templates.tooltip.manage_meeting_templates'
	},
	noSubscription: {
		tid: 'meetings.templates.tooltip.manage_meeting_templates.no_sub',
		delayShow: 'short'
	}
};

const PROTOCOL_TOOLTIP_STATES = {
	default: {
		tid: 'meetings.protocol_pdf_options.tooltip.manage_protocol_pdf_options'
	},
	noSubscription: {
		tid: 'meetings.protocol_pdf_options.tooltip.manage_protocol_pdf_options.no_sub',
		delayShow: 'short'
	}
};

const DELETE_MEETINGS_TOOLTIP_STATES = {
	default: {
		tid: 'meetings.perminantly_delete_meetings.tooltip.default'
	},
	disabled: {
		tid: 'meetings.perminantly_delete_meetings.tooltip.disabled',
		delayShow: 'instant'
	}
};

const DOWNLOAD_GROUP_MATRIX_TOOLTIP_STATES = {
	default: {
		tid: 'meetings.download_group_structure.tooltip.default'
	}
};

const CONFIRM_PERMINANTLY_DELETE_MEETINGS_MODAL = 'CONFIRM_PERMINANTLY_DELETE_MEETINGS_MODAL';

class MeetingDrawerContainer extends Component {
	static propTypes = {
		basePath: string,
		params: string
	};

	getDrawerObject = () => {
		const { subHasSmartMeetings, companiesAccessRights, downloadGroupMatrix } = this.props;
		const templateActiveState = !subHasSmartMeetings && 'noSubscription';
		const protocolActiveState = !subHasSmartMeetings && 'noSubscription';
		const deleteMeetingsActiveState = 'default';

		return List([
			Map({
				icon: 'faCog'
			}),
			Map({
				icon: 'faListAlt',
				disabled: !subHasSmartMeetings,
				tooltipStates: TEMPLATE_TOOLTIP_STATES,
				tooltipActiveState: templateActiveState,
				onClick: this.onModalOpen.bind(this, MEETING_TEMPLATES_MODAL)
			}),
			Map({
				icon: 'faFilePdf',
				disabled: !subHasSmartMeetings,
				tooltipStates: PROTOCOL_TOOLTIP_STATES,
				tooltipActiveState: protocolActiveState,
				onClick: this.onModalOpen.bind(this, PROTOCOL_PDF_OPTIONS_MODAL)
			}),
			Map({
				icon: 'faFileSpreadsheet',
				tooltipStates: DOWNLOAD_GROUP_MATRIX_TOOLTIP_STATES,
				onClick: () => downloadGroupMatrix(true)
			}),
			Map({
				icon: 'faTrash',
				disabled: false,
				tooltipStates: DELETE_MEETINGS_TOOLTIP_STATES,
				tooltipActiveState: deleteMeetingsActiveState,
				onClick: this.onModalOpen.bind(this, CONFIRM_PERMINANTLY_DELETE_MEETINGS_MODAL)
			})
		]);
	};

	onModalOpen = (modalName) => {
		this.props.openModal(modalName);
	};

	onModalClose = (isMeetingTemplateModalAndFirstTemplate) => {
		if (isMeetingTemplateModalAndFirstTemplate === true) {
			this.props.openModal(MEETING_TEMPLATES_IMPORTANT_INFORMATION_MODAL);
		} else {
			this.props.closeModal();
		}
	};

	redirectIfCurrentMeetingWasDeleted = (currentMeetingWasDeleted) => {
		const { groupId, basePath, history } = this.props;

		if (!currentMeetingWasDeleted) {
			return null;
		}

		const includeGroup = groupId ? `?group=${groupId}` : '';

		history.push(`${basePath}/${includeGroup}`);
	};

	doDeleteMeetings = () => {
		const { meetingId, deleteMeetingsPerminantly } = this.props;

		this.onModalClose();
		deleteMeetingsPerminantly(meetingId, this.redirectIfCurrentMeetingWasDeleted);
	};

	renderTemplatesModal = () => {
		const { activeModal } = this.props;
		const modalIsOpen = activeModal ? activeModal.get('name') === MEETING_TEMPLATES_MODAL : false;
		const openCreateView =
			modalIsOpen && activeModal ? activeModal.getIn(['options', 'openCreateView'], false) : false;

		return (
			<MeetingTemplatesModalContainer
				isOpen={modalIsOpen}
				onClose={this.onModalClose}
				onSave={this.onModalClose}
				openCreateView={openCreateView}
				closeModalWhenCreateViewIsCanceled={
					activeModal && activeModal.getIn(['options', 'closeModalWhenCreateViewIsCanceled'], false)
				}
			/>
		);
	};

	renderManagePdfModal = () => {
		return <ProtocolPdfOptionsModal onClose={this.onModalClose} />;
	};

	renderConfirmDeleteMeetingsModal = () => {
		const { confirmDeleteMeetingsModalOpen } = this.props;

		return (
			<ConfirmContainer
				title='meetings.perminantly_delete_meetings.confirm.title'
				question='meetings.perminantly_delete_meetings.confirm.question'
				onConfirm={this.doDeleteMeetings}
				onDecline={this.onModalClose}
				isOpen={confirmDeleteMeetingsModalOpen}
			/>
		);
	};

	renderTemplateInformationModal = () => {
		const { activeModal } = this.props;
		const modalIsOpen = activeModal
			? activeModal.get('name') === MEETING_TEMPLATES_IMPORTANT_INFORMATION_MODAL
			: false;

		return (
			modalIsOpen && <TemplateInformationModal primaryTid={'meetings.smart.template_information_modal.body'} />
		);
	};

	render = () => {
		const { activeModal } = this.props;
		const drawer = this.getDrawerObject();

		return (
			<Fragment>
				<IconDrawer drawer={drawer} />

				{this.renderTemplatesModal()}
				{this.renderManagePdfModal()}
				{this.renderConfirmDeleteMeetingsModal()}
				{this.renderTemplateInformationModal()}
			</Fragment>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		activeModal: store.modals.get('activeModal'),
		subHasSmartMeetings: store.subscriptions.getIn(['helper', 'smartMeetingsEnabled']),
		confirmDeleteMeetingsModalOpen:
			store.modals.getIn(['activeModal', 'name']) === CONFIRM_PERMINANTLY_DELETE_MEETINGS_MODAL,
		meetingId: store.meetings.getIn(['meeting', 'id']),
		groupId: store.groups.get('selectedGroupId'),
		companiesAccessRights: store.company.companiesAccessRights
	};
};

const mapActionsToProps = {
	closeModal,
	openModal,
	deleteMeetingsPerminantly,
	downloadGroupMatrix
};

export default connect(mapStoreToProps, mapActionsToProps)(MeetingDrawerContainer);
