import React from 'react'
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Typography } from '@mui/material'
import { getShareTypeLabel } from '../../../../../components/helpers/shares'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import { NUMBER_FORMAT_INTEGER } from '/shared/constants'
import { useTranslation } from 'react-i18next'
import {
	bodyTableCellSx,
	footerTableCellSx,
	headerTableCellSx,
	helpTextSx
} from '../../renderers/tooltip-renderer.styles'

function MyNumberOfShares(props) {
	const {
		data: { types, bodyTid },
		column: { colId },
		value
	} = props
	const { t } = useTranslation()
	const shareTypes = types && types[colId]

	if (!shareTypes) {
		return null
	}

	return (
		<div className='custom-tooltip' component={Paper}>
			<TableContainer>
				<Table sx={{ width: '-webkit-fill-available', marginBottom: bodyTid ? '0!important' : '16px' }}>
					<TableHead>
						<TableRow>
							<TableCell component='th' sx={headerTableCellSx}>
								<Typography variant='body1'>{t('investments.grid.tooltip.table.column_header.name')}</Typography>
							</TableCell>
							<TableCell component='th' align='right' sx={headerTableCellSx}>
								<Typography variant='body1'>{t('investments.grid.tooltip.table.column_header.total')}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{shareTypes.map((shareType, index) => (
							<TableRow key={index}>
								<TableCell sx={bodyTableCellSx}>{getShareTypeLabel(shareType.type)}</TableCell>
								<TableCell align='right' sx={bodyTableCellSx}>
									{typeof shareType.shares === 'number'
										? localeFormatNumber(shareType.shares, NUMBER_FORMAT_INTEGER)
										: '-'}
								</TableCell>
							</TableRow>
						))}
						{shareTypes.length > 0 && (
							<TableRow>
								<TableCell colSpan={2} align='right' sx={footerTableCellSx}>
									{value}
								</TableCell>
							</TableRow>
						)}
						{shareTypes.length === 0 && (
							<TableRow>
								<TableCell colSpan={2} sx={bodyTableCellSx}>
									<em>{t('grid.tooltips.new_shares_and_price.no_share_types')}</em>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{bodyTid && (
				<Typography variant='body2' sx={helpTextSx} color='text.primary'>
					{t(bodyTid)}
				</Typography>
			)}
		</div>
	)
}

export { MyNumberOfShares }
