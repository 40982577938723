import React, { PureComponent } from 'react'
import { string, bool, func } from 'prop-types'
import styled, { css } from 'styled-components'

import OutlinedButtonBase from '../base/outlined-button-base'
import LoadingButtonInsert from '../base/loading-button-insert'
import Icon from '../../../icon/icon'
import Text from '../../../text/text'

const StyledOutlinedButton = styled(OutlinedButtonBase)`
	min-width: 36px;
	min-height: 36px;
	background: ${(props) => props.theme.colors[props.background]};

	${(props) =>
		props.leftMargin &&
		css`
			margin-left: ${(props) => props.theme.spacing[2]};
		`}
`

const StyledButtonLoadingInsert = styled(LoadingButtonInsert)`
	/* Has Icon and text, set margins on everything */
	${(props) =>
		props.hasIcon &&
		props.hasText &&
		css`
			padding: ${(props) => props.theme.spacing[2]} 0;

			> :first-child {
				margin-left: 12px;
				margin-right: 8px;
			}

			> :last-child {
				margin-right: 12px;
			}
		`}

	/* No icon, only text */
	${(props) =>
		!props.hasIcon &&
		props.hasText &&
		css`
			padding: ${(props) => props.theme.spacing[2]} 0;

			> :first-child {
				margin: 0 12px;
			}
		`}
`

export default class OutlinedButton extends PureComponent {
	static propTypes = {
		color: string,
		borderColor: string,
		icon: string,
		tid: string,
		onClick: func,
		leftMargin: bool,
		background: string
	}

	render = () => {
		const { icon, tid, disabled, isLoading, ...rest } = this.props

		const btnProps = {
			disabled: disabled || isLoading
		}

		const loadingButtonInsertProps = {
			hasIcon: Boolean(icon),
			hasText: Boolean(tid),
			isLoading
		}

		return (
			<StyledOutlinedButton {...rest} {...btnProps}>
				<StyledButtonLoadingInsert {...loadingButtonInsertProps}>
					{icon && <Icon icon={icon} size='sml' />}
					{tid && <Text tid={tid} />}
				</StyledButtonLoadingInsert>
			</StyledOutlinedButton>
		)
	}
}
