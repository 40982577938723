/**
 * Root Modal
 * @module components/framework/root-modal
 */
import WikiEditContentModal from '../wiki/wiki--edit-content-modal'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { hideModal } from '../../actions/modals.actions'

const MODAL_COMPONENTS = {
	WIKI_EDIT_CONTENT: WikiEditContentModal
}

/** Class representing the root modal component. */
class RootModal extends Component {
	constructor(props) {
		super(props)
	}

	/**
	 * The render function
	 */
	render() {
		const { modalType, modalProps } = this.props.modal

		if (!modalType) {
			return null
		}

		const SpecificModal = MODAL_COMPONENTS[modalType]
		return (
			<Modal
				isOpen={true}
				onRequestClose={this.props.onClose}
				contentLabel='test'
				className=' '
				style={{}}
				overlayClassName=' '
				ariaHideApp={false}>
				<SpecificModal {...modalProps} />
			</Modal>
		)
	}
}

/** Map state to props. */
function mapStateToProps(state) {
	return {
		modal: state.modal
	}
}

function mapDispatchToProps(dispatch) {
	return {
		onClose: () => dispatch(hideModal())
	}
}

/** Export */
export default connect(mapStateToProps, mapDispatchToProps)(RootModal)
