import React, { Component } from 'react'
import Moment from '../../../modules/moment.module'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Wiki from '../../wiki/wiki'
import Cookies from 'universal-cookie'
import { checkAccessToTransaction } from '../../../actions/transaction.actions'
import history from '../../../interfaces/history'


class SharesSetupStartpage extends Component {
	state = {
		hasAccess: false
	}

	constructor(props) {
		super(props)
	
		const option = new Cookies().get(
			'sharesSetupCurrentOption' + ('/' + this.props.location.pathname.split('/')[1] + '/')
		)
		if (option === 'a') {
			history.push(`${this.props.location.pathname}/a`)
		} else if (option === 'b') {
			history.push(`${this.props.location.pathname}/b`)
		}
	}

	componentDidMount() {
		const { checkAccessToTransaction } = this.props

		const option = new Cookies().get(
			'sharesSetupCurrentOption' + ('/' + this.props.location.pathname.split('/')[1] + '/')
		)
		if (option === 'a') {
			history.push(`${this.props.location.pathname}/a`)
		} else if (option === 'b') {
			history.push(`${this.props.location.pathname}/b`)
		} else {
			checkAccessToTransaction('GENESIS', () => {
				this.setState({ hasAccess: true })
			})
		}
	}

	onClick(step) {
		const companyPath = '/' + this.props.location.pathname.split('/')[1] + '/'
		new Cookies().set('sharesSetupCurrentOption' + companyPath, step, {
			path: '/',
			expires: Moment().add(1, 'year').toDate()
		})
	}

	render() {
		const { hasAccess } = this.state

		return (
			<div>
				<div className='i-panel i-panel--transparent'>
					<div className='i-panel__heading i-panel__heading--big'>
						<div className='i-panel__title-wrapper'>
							<h2 className='i-panel__title'>
								<FormattedMessage id='getting_started' />
							</h2>
							<div className='i-panel__subtitle'>
								<FormattedMessage id='getting_started_information' />
							</div>
						</div>
					</div>
					<div className='i-panel__body i-panel__body--pad-btm-0'>
						<div className='i-layout-row'>
							<div className='i-layout-column i-layout-column--left'>
								<div className='i-panel'>
									<div className='i-panel__heading'>
										<h2 className='i-panel__title'>
											<FormattedMessage id='start_share_register_from_companys_registration_date' />
										</h2>
									</div>
									<div className='i-panel__body i-panel__body--pad-hor i-panel__body--mh360 i-panel__body--rte'>
										<p>
											<FormattedMessage id='start_share_register_from_companys_registration_date_body_text1' />
										</p>
										<p>
											<FormattedHTMLMessage id='start_share_register_from_companys_registration_date_body_text2' />
										</p>
										<p>
											<FormattedMessage id='start_share_register_from_companys_registration_date_body_text3' />
										</p>
									</div>
									<div className='i-panel__footer'>
										{!hasAccess && (
											<button className='btn btn-primary btn-block' disabled>
												<FormattedMessage id='select' />
											</button>
										)}
										{hasAccess && (
											<Link
												to={`${this.props.location.pathname}/a`}
												onClick={this.onClick.bind(this, 'a')}
												className='btn btn-primary btn-block'>
												<FormattedMessage id='select' />
											</Link>
										)}
									</div>
								</div>
							</div>

							<div className='i-layout-column i-layout-column--right'>
								<div className='i-panel'>
									<div className='i-panel__heading'>
										<h2 className='i-panel__title'>
											<FormattedMessage id='start_share_register_from_a_later_date' />
										</h2>
									</div>
									<div className='i-panel__body i-panel__body--pad-hor i-panel__body--mh360 i-panel__body--rte'>
										<p>
											<FormattedMessage id='start_share_register_from_a_later_date_body_text1' />
										</p>
										<p>
											<FormattedHTMLMessage id='start_share_register_from_a_later_date_body_text2' />
										</p>
										<p>
											<FormattedMessage id='start_share_register_from_a_later_date_body_text4' />
										</p>
									</div>
									<div className='i-panel__footer'>
										{!hasAccess && (
											<button className='btn btn-primary btn-block' disabled>
												<FormattedMessage id='select' />
											</button>
										)}
										{hasAccess && (
											<Link
												to={`${this.props.location.pathname}/b`}
												onClick={this.onClick.bind(this, 'b')}
												className='btn btn-primary btn-block'>
												<FormattedMessage id='select' />
											</Link>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='i-panel'>
					<div className='i-panel__body'>
						<Wiki id='2369712c-6042-4a41-88ed-0205f4d5c403' className='text--wiki' />
					</div>
				</div>
			</div>
		)
	}
}

export default connect(null, { checkAccessToTransaction })(SharesSetupStartpage)
