import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, oneOf, string } from 'prop-types'
import { map } from 'react-immutable-proptypes'

import DocumentsHelper from '../../../components/helpers/documents.helper'
import { DOCUMENT_DOWNLOAD_SIGNED_MODAL } from '../../../constants/modals'

import { downloadDocument, downloadDocumentPublic } from '../../../actions/documents.actions'
import { openModal } from '../../../actions/modals.actions'

const TOOLTIP_STATES_OPEN = {
	default: {
		tid: 'tooltip.documents.action.open_document'
	},
	unableToOpenDocumentDueToFormat: {
		tid: 'tooltip.documents.action.unable_open_document_format',
		delayShow: 'instant'
	},
	unableToOpenDocumentDueToSize: {
		tid: 'tooltip.documents.action.unable_open_document_size',
		delayShow: 'instant'
	}
}

const TOOLTIP_STATES_DOWNLOAD = {
	default: {
		tid: 'download_file'
	}
}

class OpenOrDownloadFileComponentContainer extends Component {
	static propTypes = {
		document: map.isRequired,
		mode: oneOf(['OPEN', 'DOWNLOAD']).isRequired,
		userId: string
	}

	static defaultProps = {
		mode: 'OPEN'
	}

	getShouldOpenInViewer = () => {
		return this.props.mode === 'OPEN'
	}

	getTooltipData = () => {
		const openInViewer = this.getShouldOpenInViewer()
		return openInViewer ? this.getTooltipDataForOpenInViewer() : this.getTooltipDataForDownload()
	}

	getTooltipDataForOpenInViewer = () => {
		const { document } = this.props
		const file = document ? document.get('file') : null
		const webViewerBtnOpenDisabled = !DocumentsHelper.isViewableInBrowser(file)
		const { webViewerSupportedFormat, webViewerSupportedSize } = DocumentsHelper.getWebViewerIsSupported(file)
		const documentLoaded = document ? true : false
		let activeState = undefined

		if (webViewerBtnOpenDisabled && !webViewerSupportedFormat) {
			activeState = 'unableToOpenDocumentDueToFormat'
		} else if (webViewerBtnOpenDisabled && !webViewerSupportedSize) {
			activeState = 'unableToOpenDocumentDueToSize'
		}

		const isDisabled = webViewerBtnOpenDisabled || !documentLoaded

		return {
			TOOLTIP_STATES: TOOLTIP_STATES_OPEN,
			activeState,
			isDisabled
		}
	}

	getTooltipDataForDownload = () => {
		const { document } = this.props
		const documentLoaded = document ? true : false
		const isDisabled = !documentLoaded

		return {
			TOOLTIP_STATES: TOOLTIP_STATES_DOWNLOAD,
			isDisabled
		}
	}

	openOrDownload = () => {
		const { document, downloadDocument, openModal, userId } = this.props
		const documentId = document.get('id')
		const isSigned = DocumentsHelper.getDocumentIsSigned(document)
		const openInViewer = this.getShouldOpenInViewer()

		if (isSigned) {
			openModal(DOCUMENT_DOWNLOAD_SIGNED_MODAL, { mode: openInViewer ? 'VIEWER' : 'DOWNLOAD', document })
			return
		}

		const isSharedFromCompanyId = document.get('isSharedFromCompanyId')

		if (isSharedFromCompanyId) {
			downloadDocumentPublic({ documentId, companyId: isSharedFromCompanyId, userId, openInViewer })
		} else {
			downloadDocument({ documentId, openInViewer })
		}
	}

	render = () => {
		const { children } = this.props
		const { TOOLTIP_STATES, activeState, isDisabled } = this.getTooltipData()
		const props = {
			openOrDownload: this.openOrDownload,
			TOOLTIP_STATES,
			activeState,
			isDisabled
		}

		if (typeof children !== 'function') {
			throw new Error('this.props.children must be a function')
		}

		return this.props.children(props)
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		userId: ownProps.userId || store.user.getIn(['userObj', 'id'])
	}
}

const mapActionsToProps = {
	downloadDocument,
	downloadDocumentPublic,
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(OpenOrDownloadFileComponentContainer)
