import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, oneOf } from 'prop-types'

import { ButtonTransparentIcon } from '../../../dumb-components/shared/button'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'

import { markOnboardAsDismiss, markOnboardAsRead } from '../../../actions/notifications.actions'
import { closeModal } from '../../../actions/modals.actions'

class HeaderControlsContainer extends Component {
	static propTypes = {
		notificationId: string.isRequired,
		type: oneOf(['BUTTON', 'DROPDOWN'])
	}

	static defaultProps = {
		type: 'BUTTON'
	}

	dropdownRef = null

	dismissPermanently = () => {
		const { notificationId, markOnboardAsDismiss, closeModal } = this.props

		markOnboardAsDismiss(notificationId)

		this.dropdownRef && this.dropdownRef.onToggleMenu()
		closeModal()
	}

	dismissTemporarily = () => {
		const { notificationId, markOnboardAsRead, closeModal } = this.props

		markOnboardAsRead(notificationId)

		this.dropdownRef && this.dropdownRef.onToggleMenu()
		closeModal()
	}

	setDropdownRef = (ref) => {
		this.dropdownRef = ref
	}

	render = () => {
		const { type } = this.props

		if (type === 'BUTTON') {
			return <ButtonTransparentIcon icon='faTimes' onClick={this.dismissPermanently} />
		}

		if (type === 'DROPDOWN') {
			return (
				<DropdownMenuContainer
					btnIcon='faTimes'
					halignMenu='right'
					btnMode='transparent-icon'
					onClickOverride={this.dismissTemporarily}
					ref={this.setDropdownRef}>
					<DropdownIconItem onClick={this.dismissTemporarily} tid='onboarding.modals.controls.dismiss' icon='faMinus' />

					<DropdownIconItem
						onClick={this.dismissPermanently}
						tid='onboarding.modals.controls.dismiss_permanently'
						icon='faTimes'
					/>
				</DropdownMenuContainer>
			)
		}
	}
}

const mapStoreToProps = (store) => {
	return {
		notificationId: store.modals.getIn(['activeModal', 'options', 'notificationId'])
	}
}

const mapActionsToProps = {
	markOnboardAsDismiss,
	markOnboardAsRead,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(HeaderControlsContainer)
