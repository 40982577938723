import React from 'react'
import { func, string } from 'prop-types'
import styled from 'styled-components'
import Text from '../text/text'

const StyledLabelWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`

const StyledRightComponent = styled.div``

const StyledLabel = styled.label`
	flex: 1;
	margin-bottom: 4px;
	word-wrap: break-word;
`

const StyledMainWrapper = styled.div`
	width: 100%;
`

const Label = ({ children, tid, rightComponent, leftComponent, textColor }) => {
	return (
		<StyledMainWrapper>
			<StyledLabelWrapper>
				<StyledLabel>
					{tid ? <Text color={textColor} tid={tid} /> : leftComponent ? leftComponent() : <Text>&nbsp;</Text>}
				</StyledLabel>
				{rightComponent && <StyledRightComponent>{rightComponent && rightComponent()}</StyledRightComponent>}
			</StyledLabelWrapper>
			{children}
		</StyledMainWrapper>
	)
}

Label.defaultProps = {}

Label.propTypes = {
	rightComponent: func,
	tid: string
}

export default Label
