import React, { useState } from 'react'
import { AddPersonModal } from '../../add-person-modal/add-person-modal'
import { useDispatch } from 'react-redux'
import Button from '../../../../mui-components/button/button'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { createCompanyContact } from '../../../../actions/people.actions'

function AddContactButton(params) {
	const { t } = useTranslation()
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const dispatch = useDispatch()

	const onSave = (person) => {
		dispatch(createCompanyContact(person))
		setModalIsOpen(false)
	}

	return (
		<Box>
			<Button variant='primary' onClick={() => setModalIsOpen(true)}>
				{t('people_modal.company_contacts.button.new_contact')}
			</Button>
			<AddPersonModal isOpen={modalIsOpen} onSave={onSave} onCancel={() => setModalIsOpen(false)} />
		</Box>
	)
}

export { AddContactButton }
