import React, { PureComponent } from 'react'
import { string, bool, object, func } from 'prop-types'
import styled from 'styled-components'

import Button from '../button'
import Icon from '../../icon/icon'
import Text from '../../text/text'

const StyledIconWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-right: ${(props) => props.theme.spacing[3]};
`

export default class ButtonWithIcon extends PureComponent {
	static propTypes = {
		btnProps: object,
		icon: string,
		disabled: bool,
		onClick: func,
		tid: string,
		children: object,
		isLoading: bool,
		iconSize: string,
		iconColor: string,
		color: string,
		mode: string
	}

	static defaultProps = {
		iconSize: 'sml'
	}

	render = () => {
		const { btnProps, icon, disabled, onClick, tid, color, children, isLoading, iconSize, iconColor, mode } = this.props

		return (
			<Button disabled={disabled} onClick={onClick} isLoading={isLoading} mode={mode} {...btnProps}>
				<StyledIconWrapper>
					<Icon icon={icon} size={iconSize} color={iconColor} />
				</StyledIconWrapper>
				{children ? children : <Text tid={tid} color={color} />}
			</Button>
		)
	}
}
