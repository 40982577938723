import React from 'react'
import styled, { css } from 'styled-components'
import { rgba, lighten } from 'polished'

// Input
const INPUT_HEIGHT = '31px'
const INPUT_LARGE_HEIGHT = '45px'

// Label
const LABEL_HEIGHT = '13px'
const LABEL_WIDTH_SHORT = '70px'
const LABEL_WIDTH_MEDIUM = '100px'

// Header
const HEADER_HEIGHT = '18px'
const HEADER_WIDTH = '200px'

const genericStyling = css`
	display: flex;
	flex: 1;
`

const StyledInput = styled.div`
	@keyframes slide {
		from {
			left: 0;
		}
		to {
			left: 100%;
		}
	}

	${genericStyling}
	height: ${(props) => props.height};
	background: ${(props) => props.theme.colors.solitudeDark};
	position: relative;
	overflow: hidden;

	> span {
		position: absolute;
		content: '';
		width: ${(props) => props.height};
		height: ${(props) => props.height};
		background-color: transparent;
		background-image: -webkit-radial-gradient(
			ellipse,
			${(props) => lighten(0.05, props.theme.colors.solitudeDark)} 10%,
			${(props) => lighten(0.02, props.theme.colors.solitudeDark)} 50%,
			transparent 20%
		);
		animation: 0.2s linear infinite slide;
		opacity: 0.4;
	}
`

const StyledLabel = styled.div`
	@keyframes slide {
		from {
			left: 0;
		}
		to {
			left: 100%;
		}
	}

	height: ${LABEL_HEIGHT};
	background: ${(props) => props.theme.colors.midGrey};
	max-width: ${(props) => props.width};
	margin-bottom: ${(props) => props.theme.spacing[2]};
	position: relative;
	overflow: hidden;
	opacity: 0.5;

	> span {
		position: absolute;
		content: '';
		width: ${(props) => props.height};
		height: ${(props) => props.height};
		border-radius: 50%;
		background-color: transparent;
		background-image: -webkit-radial-gradient(
			ellipse,
			${(props) => lighten(0.05, props.theme.colors.midGrey)} 10%,
			${(props) => lighten(0.02, props.theme.colors.midGrey)} 50%,
			transparent 20%
		);
		animation: 1s linear infinite slide;
		opacity: 0.4;
	}
`

const StyledHeader = styled.div`
	height: ${HEADER_HEIGHT};
	width: ${HEADER_WIDTH};
	background: ${(props) => props.theme.colors.midGrey};
	opacity: 0.5;
`

export const StyledInputShortLabel = () => {
	return (
		<div>
			<StyledLabel width={LABEL_WIDTH_SHORT} />
			<StyledInput height={INPUT_HEIGHT}>
				<span></span>
			</StyledInput>
		</div>
	)
}

export const StyledInputMediumLabel = () => {
	return (
		<div>
			<StyledLabel width={LABEL_WIDTH_MEDIUM} />
			<StyledInput height={INPUT_HEIGHT} />
		</div>
	)
}

export const StyledInputLarge = () => {
	return (
		<div>
			<StyledInput height={INPUT_LARGE_HEIGHT} />
		</div>
	)
}

export const StyledHeaderLarge = () => {
	return <StyledHeader />
}
