import React, { createContext, useContext } from 'react'

/**
Context definition:

value = {
	position: string,
	getFeature: Function,
	saveFeature: Function,
	isFetchingFeatures: boolean,
	isInvonoAdmin: boolean,
	setFeature: Function,
	removeFeature: Function,
	deleteFeature: Function,
	featureOptions: object[]
}
*/

export const FeatureGroupContext = createContext()

export function useFeatureGroupContext() {
	return useContext(FeatureGroupContext)
}
