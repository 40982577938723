import { Map } from 'immutable'
import { createSelector } from 'reselect'

export const myInvestmentSelector = createSelector(
	(state) => state.capTableDetails.get('combinedSingleInvestmentAndAllTransactions'),
	(combinedSingleInvestmentAndAllTransactions) => {
		if (!combinedSingleInvestmentAndAllTransactions) {
			return null
		}

		let transactions = combinedSingleInvestmentAndAllTransactions.get('transactions')
			? combinedSingleInvestmentAndAllTransactions.get('transactions')
			: []

		const investmentId = combinedSingleInvestmentAndAllTransactions.get('id')

		//change the ordering of transactions from oldest to newest to help keep track of previous transaction balance
		transactions = transactions.reverse()

		let prevtransactionBalance
		transactions = transactions.map((transaction) => {
			let balance = transaction.getIn(['balances', investmentId])

			if (!balance) {
				const numOfTotalCompanyShares = transaction.getIn(['shareData', 'numOfTotalShares'])
				const shareCapital = transaction.getIn(['shareData', 'shareCapital'])
				balance = Map({
					numOfTotalCompanyShares,
					numOfTotalShares: 0,
					ownershipPercentage: 0,
					emissionPrice: 0,
					sequenceIndexs: [],
					shareCapital,
					votingRightPercentage: 0
				})
			}
			if (prevtransactionBalance) {
				transaction = transaction.set('prevtransactionBalance', prevtransactionBalance)
			}
			prevtransactionBalance = balance
			transaction = transaction.set('balance', balance)
			transaction = transaction.remove('balances')
			transaction = transaction.remove('shareData')
			return transaction
		})
		//restore transactions to the original ordering
		transactions = transactions.reverse()
		transactions = transactions.filter((transaction) => transaction.get('relatedInvestments').includes(investmentId))

		combinedSingleInvestmentAndAllTransactions = combinedSingleInvestmentAndAllTransactions.set(
			'transactions',
			transactions
		)
		return combinedSingleInvestmentAndAllTransactions
	}
)
