import React, { PureComponent } from 'react'
import { bool, func } from 'prop-types'
import styled from 'styled-components'

import Text from '../../shared/text/text'
import Modal from '../../shared/modal/modal'
import { TransparentButton } from '../../shared/button-v2'
import ColoredContentWrapper from '../../shared/colored-content-wrapper/colored-content-wrapper'

import { SUBSCRIPTION_ESIGN_BANKID_COST } from '/shared/constants'

const StyledInfoRow = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[4]};

	:last-child {
		margin-bottom: ${(props) => props.theme.spacing[0]};
	}
`

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
`

export default class InfoAboutPotAfterSubModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		onClose: func
	}

	renderFooter = () => {
		const { onClose } = this.props
		return <TransparentButton tid='generic.form.close' onClick={onClose && onClose} textColor='midGrey' />
	}

	render = () => {
		const { isOpen } = this.props
		const singleSignaturePrice = SUBSCRIPTION_ESIGN_BANKID_COST

		return (
			<Modal
				isOpen={isOpen}
				vSize={50}
				hSize='md'
				title='subscriptions.subscribe.balance_info.modal.title'
				footerComponent={this.renderFooter()}>
				<StyledColoredContentWrapper type='secondary'>
					<StyledInfoRow>
						<Text tid='subscriptions.subscribe.balance_info.modal.info_1_1' />
						&nbsp;
						<Text tid='subscriptions.subscribe.balance_info.modal.info_1_2' bold={600} />
						<Text tid='subscriptions.subscribe.balance_info.modal.info_1_3' />
					</StyledInfoRow>

					<StyledInfoRow>
						<Text tid='subscriptions.subscribe.balance_info.modal.info_2_1' />
						&nbsp;
						<Text tid='subscriptions.subscribe.balance_info.modal.info_2_2' bold={600} />
						&nbsp;
						<Text tid='subscriptions.subscribe.balance_info.modal.info_2_3' values={{ singleSignaturePrice }} />
					</StyledInfoRow>
				</StyledColoredContentWrapper>
			</Modal>
		)
	}
}
