import React from 'react'
import Box from '@mui/material/Box'
import styled from '@mui/styled-engine'

const FormGroupContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	gap: theme.spacing(2),
	flexDirection: 'column'
}))

const FormGroup = styled(Box)(({ theme, nogap }) => ({
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: theme.palette.highlight.yellow.main,
	padding: theme.spacing(4),
	gap: nogap ? 0 : theme.spacing(2),
	borderRadius: '8px'
}))

export { FormGroupContainer, FormGroup }
