import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import Text from '../text/text'
import Icon from '../../shared/icon/icon'
import { bool, string, func, oneOfType, object } from 'prop-types'
import Moment from '../../../modules/moment.module'

import { ATTENDEE_STATUS_UNSURE } from '/shared/constants'

const StyledListItem = styled.div`
	background-color: ${(props) => props.theme.colors.solitudeMid};
	border-top: 1px solid ${(props) => props.theme.colors.white};
	display: flex;
	height: 50px;

	${(props) =>
		props.sharedWithList
			? css`
					background-color: ${(props) => props.theme.colors.white};

					:first-child {
						border-top: none;
					}

					:last-child {
						border-bottom: 1px solid ${(props) => props.theme.colors.solitudeMid};
					}
			  `
			: css`
					:first-child {
						border-top: none;
					}

					:last-child {
						border-bottom: 1px solid ${(props) => props.theme.colors.white};
					}
			  `}
`

const StyledIconWrapper = styled.div`
	background: ${(props) => props.theme.colors.solitudeDark};
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	${(props) =>
		props.status === true &&
		css`
			background: ${(props) => props.theme.colors.green};
		`}

	${(props) =>
		props.status === false &&
		css`
			background: ${(props) => props.theme.colors.red};
		`}

	${(props) =>
		props.status === ATTENDEE_STATUS_UNSURE &&
		css`
			background: ${(props) => props.theme.colors.yellowMid};
		`}
`

const EllipsisTemplate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

const TemplateTickWidthCss = css`
	width: 50px;
`

const TemplateNameWidthCss = css`
	${EllipsisTemplate}
	flex: 1;
`

const TemplateDateWidthCss = css`
	width: 150px;
`

// Items
const StyledTick = styled.div`
	${TemplateTickWidthCss}
	align-items: center;
	display: flex;
	height: 50px;
	justify-content: center;
`

const StyledName = styled.div`
	${EllipsisTemplate}
	${TemplateNameWidthCss}
	display: flex;
	align-items: center;
	margin-left: ${(props) => props.theme.spacing[3]};
	margin-right: ${(props) => props.theme.spacing[3]};
`

const StyledDate = styled.div`
	${TemplateDateWidthCss}
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: ${(props) => props.theme.spacing[3]};
	margin-right: ${(props) => props.theme.spacing[3]};
`

const StyledRightComponentWrapper = styled.div`
	align-items: center;
	display: flex;
`

export default class ListPanelItem extends PureComponent {
	static propTypes = {
		status: oneOfType([string, bool]),
		name: string,
		date: string,
		nameIsTid: bool,
		renderRightComponent: func,
		role: oneOfType([string, object]),
		sharedWithList: bool,
		nameInItalic: bool
	}

	static defaultProps = {
		nameIsTid: false,
		nameInItalic: false
	}

	renderIcon = () => {
		const { status } = this.props
		let icon = 'faTimes'

		if (status === true) {
			icon = 'faCheck'
		}

		return <Icon icon={icon} color='white' size='sm' type='solid' />
	}

	render = () => {
		const { name, date, status, nameIsTid, renderRightComponent, role, sharedWithList, nameInItalic } = this.props
		return (
			<StyledListItem sharedWithList={sharedWithList}>
				<StyledTick>
					<StyledIconWrapper status={status}>{this.renderIcon()}</StyledIconWrapper>
				</StyledTick>

				<StyledName>
					{nameIsTid ? (
						<Text tag={nameInItalic ? 'em' : undefined} tid={name} singleLine />
					) : (
						<Text singleLine>{name}</Text>
					)}{' '}
					{role && (
						<Text color='muted' singleLine>
							&nbsp;({role})
						</Text>
					)}
				</StyledName>

				{renderRightComponent ? (
					<StyledRightComponentWrapper>{renderRightComponent()}</StyledRightComponentWrapper>
				) : (
					<>
						{!sharedWithList && (
							<StyledDate>
								<Text>{date ? Moment(date).format('llll') : '-'}</Text>
							</StyledDate>
						)}
					</>
				)}
			</StyledListItem>
		)
	}
}
