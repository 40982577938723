import React from 'react'
import styled from 'styled-components'

const StyledSplitView = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	height: 100%;
`

const StyledView = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	/* We subsctract 10px because that's the width of the scrollbar */
	padding: ${(props) => props.theme.spacing[3]} calc(${(props) => props.theme.spacing[3]} - 10px)
		${(props) => props.theme.spacing[3]} 0;
	padding-bottom: 0;
	position: relative;
`

const SplitView = ({ leftComponent, rightComponent }) => {
	return (
		<StyledSplitView>
			<StyledView>{leftComponent && leftComponent()}</StyledView>
			<StyledView>{rightComponent && rightComponent()}</StyledView>
		</StyledSplitView>
	)
}

export default SplitView
