import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'
import { Map, List } from 'immutable'

import AttendeeManageFunctions from '../../../dumb-components/meetings/attendee-manage-functions/attendee-manage-functions'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import { showErrorTooltip, hideErrorTooltip } from '../../../dumb-components/shared/tooltip/tooltip.helpers'

import {
	createCustomMeetingFunction,
	editCustomMeetingFunction,
	deleteCustomMeetingFunction
} from '../../../actions/custom-meeting-functions.actions'

class ManageAttendeeFunctionsContainer extends Component {
	state = {
		hasError: Map(),
		functionData: Map(),
		isLoading: false,
		isEditMode: false,
		sortedFunctions: Map()
	}

	static propTypes = {
		modalMode: bool
	}

	dropdownRefs = {}
	inputRef = null

	componentDidMount() {
		this.setSortedFunctions()
	}

	componentDidUpdate(prevProps) {
		const { customFunctions } = this.props

		if (prevProps.customFunctions !== customFunctions) {
			this.setSortedFunctions()
		}
	}

	setSortedFunctions = () => {
		const { customFunctions } = this.props

		const filteredFunctions = customFunctions.filter((f) => !f.get('isDeleted'))

		const sortedFunctions = filteredFunctions.sort((a, b) => {
			return a.getIn(['name', 'sv']).localeCompare(b.getIn(['name', 'sv']))
		})

		this.setState({ sortedFunctions })
	}

	onChange = (fieldName, value) => {
		const path = fieldName.split('.')
		let { functionData } = this.state
		this.hideTooltip(fieldName)
		functionData = functionData.setIn(path, value)
		this.setState({ functionData })
	}

	onAdd = () => {
		const { functionData } = this.state
		const { createCustomMeetingFunction } = this.props

		if (this.validate()) {
			this.setState({ isLoading: true })

			createCustomMeetingFunction(functionData, () => {
				this.setState({ isLoading: false, functionData: Map() })

				setTimeout(() => {
					this.inputRef.focus()
				}, 0)
			})
		}
	}

	onSetEditMode = (id) => {
		const { customFunctions } = this.props

		this.dropdownRefs && this.dropdownRefs[id].onToggleMenu()

		const functionToEdit = customFunctions.get(id)

		this.inputRef.focus()

		this.setState({ isEditMode: true, functionData: functionToEdit, hasError: Map() })
	}

	onCancelEdit = () => {
		this.setState({ isEditMode: false, functionData: Map(), hasError: Map() })
	}

	onEditFunction = () => {
		const { editCustomMeetingFunction } = this.props
		const { functionData } = this.state

		this.setState({ isLoading: true })

		editCustomMeetingFunction(functionData, (editedFunction) => {
			this.setState({ isLoading: false, functionData: Map(), isEditMode: false })

			setTimeout(() => {
				this.inputRef.focus()
			}, 0)
		})
	}

	onDeleteFunction = (id) => {
		const { deleteCustomMeetingFunction } = this.props
		this.dropdownRefs && this.dropdownRefs[id].onToggleMenu()
		deleteCustomMeetingFunction(id)
	}

	validate = () => {
		const { functionData } = this.state
		let hasError = Map()
		let isValid = true

		const name = functionData.getIn(['name', 'sv'])

		if (!name || !name.trim()) {
			hasError = hasError.set('name.sv', List(['meetings.manage_meeting_functions_name']))
			showErrorTooltip(this, 'name.sv')
			isValid = false
		}

		this.setState({ hasError })

		return isValid
	}

	setTooltipRefs = (refName, ref) => {
		if (ref && refName) {
			this[refName] = ref
		}
	}

	setDropdownRef = (id, ref) => {
		this.dropdownRefs[id] = ref
	}

	hideTooltip = (ref) => {
		let { hasError } = this.state
		const fieldName = ref.target ? ref.target.getAttribute('name') : ref
		hasError = hasError.remove(fieldName)
		this.setState({ hasError })
		hideErrorTooltip(this, fieldName)
	}

	renderDropdownComponent = (id) => {
		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisV'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				ref={this.setDropdownRef.bind(null, id)}
				withPortal
				inline>
				<DropdownIconItem tid='generic.edit' icon='faPenAlt' onClick={this.onSetEditMode.bind(null, id)} />
				<DropdownIconItem tid='remove' icon='faTrashAlt' onClick={this.onDeleteFunction.bind(null, id)} />
			</DropdownMenuContainer>
		)
	}

	render() {
		const { modalMode } = this.props
		const { functionData, hasError, isLoading, isEditMode, sortedFunctions } = this.state

		return (
			<AttendeeManageFunctions
				hasError={hasError}
				onChange={this.onChange}
				isLoading={isLoading}
				onSetTooltipRefs={this.setTooltipRefs}
				onHideTooltip={this.hideTooltip}
				onAdd={this.onAdd}
				functionData={functionData}
				allFunctions={sortedFunctions}
				renderDropdownComponent={this.renderDropdownComponent}
				modalMode={modalMode}
				isEditMode={isEditMode}
				onCancelEdit={this.onCancelEdit}
				onEdit={this.onEditFunction}
				onSetInputRef={(ref) => (this.inputRef = ref)}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		customFunctions: store.meetings.get('customFunctions')
	}
}

const mapActionsToProps = {
	createCustomMeetingFunction,
	editCustomMeetingFunction,
	deleteCustomMeetingFunction
}

export default connect(mapStoreToProps, mapActionsToProps)(ManageAttendeeFunctionsContainer)
