import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { Padding } from 'styled-components-spacing'
import { signProtocol, markFeedbackAsDone } from '../../../actions/meetings.actions'
import { fetchMultipleAttachedDocuments } from '../../../actions/attachments.actions'
import { MEETINGS_ITEM_TYPES, MEETING_INTERNAL_TYPES, MEETINGS_OUTCOMES } from '../../../constants/meetings'
import { flattenAgendaItems } from '../../../components/helpers/meeting.helper'
import { getUserName } from '../../../components/helpers/users'
import moment from '../../../modules/moment.module'
import Block from '../../../dumb-components/meetings/protocol-view/block/block'
import ProtocolTitle from '../../../dumb-components/meetings/protocol-view/protocol-title/protocol-title'
import ProtocolGeneralInformation from '../../../dumb-components/meetings/protocol-view/protocol-general/protocol-general'
import AgendaItem from '../../../dumb-components/meetings/protocol-view/agenda-item/agenda-item'
import DocumentsListContainer from './documents-list.container'
import TasksListContainer from './tasks-list.container'
import NotesContainer from '../../shared/notes.container'
import PersonalNotes from '../../../dumb-components/meetings/protocol-view/personal-notes/personal-notes'
import CommentsListContainer from '../../comments/comments-list.container'
import FeedbackeePanel from '../../../dumb-components/meetings/protocol-view/feedbackee-panel/feedbackee-panel'
import BankIdInformationTextContainer from '../../../dumb-components/shared/bankid/bankid'
import { validatePersonNumber } from '../../../modules/validation.module'
import { SYNA_COMPANY_ROLES, MEETING_ROLES_ADMINISTRATIVE } from '../../../constants/syna-company-roles'
import MeetingRoleDisplayContainer from '../attendees/meeting-role-display.container'

import { OBJ_TYPE_AGENDA_ITEM, OBJ_TYPE_AGENDA_ITEM_FEEDBACK, ATTENDEE_STATUS_PRESENT } from '/shared/constants'

import { fetchAndCacheInvestors } from '../../../actions/investors.actions'

import documentsHelper from '../../../components/helpers/documents.helper'
import investmentHelper from '../../../components/helpers/investment.helper'

class AgendaViewContainer extends Component {
	state = {
		protocolIsBeingSigned: false,
		agendaItems: List(),
		ssnValue: '',
		documents: Map(),
		ssnValid: true,
		bankIdTransaltionId: null,
		attendees: Map()
	}

	componentDidMount = () => {
		this.flattenAgendaItems()
		this.parseAttendees()
		this.fetchInvestors()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { meeting, usersCache, investors } = this.props
		const { agendaItems } = this.state

		if (prevProps.meeting !== meeting) {
			this.flattenAgendaItems()
		}

		if (prevState.agendaItems !== agendaItems) {
			this.fetchDocuments()
		}

		if (meeting?.get('attendees') !== prevProps.meeting?.get('attendees')) {
			this.parseAttendees()
			this.fetchInvestors()
		}

		if (usersCache !== prevProps.usersCache) {
			this.parseAttendees()
		}

		if (investors !== prevProps.investors) {
			this.parseAttendees()
		}
	}

	flattenAgendaItems = () => {
		const { meeting } = this.props

		let agendaItems = flattenAgendaItems(meeting)
		agendaItems = agendaItems.filter((obj) => !obj.get('isSuggested') && !obj.get('archived'))
		this.setState({ agendaItems })
	}

	fetchDocuments = () => {
		const { fetchMultipleAttachedDocuments } = this.props
		const { agendaItems } = this.state

		if (!agendaItems || agendaItems.size === 0) {
			return
		}

		const objIds = agendaItems.map((item) => item.get('objId'))
		fetchMultipleAttachedDocuments(objIds, (response) => {
			const documents = response.get('documents')
			const mapObjIdToDocId = response.get('mapObjIdToDocId')

			const newDocuments = mapObjIdToDocId.map((obj, objId) => {
				return obj.map((id) => {
					const doc = documents.find((doc) => doc.get('id') === id)
					const displayStatus = documentsHelper.findDisplayStatus(doc.get('links'), objId)
					let file = doc.get('file')
					file = file.set('id', doc.get('id'))
					file = file.set('displayStatus', displayStatus)

					return file
				})
			})

			this.setState({ documents: newDocuments })
		})
	}

	parseAttendees = () => {
		const { usersCache, investors, meeting } = this.props

		if (!meeting) {
			return
		}

		const secretary = meeting.get('secretary')
		const chairman = meeting.get('chairman')

		let attendees = meeting.get('attendees', Map()).filter((obj) => obj.get('status') === ATTENDEE_STATUS_PRESENT)
		attendees = attendees.map((attendee, userId) => {
			const user = usersCache && usersCache.get(userId)
			const investor =
				attendee.get('isInvestor') &&
				investors &&
				investors.find((obj) => obj.get('id') === attendee.get('investmentId'))
			let name = ''

			if (user) {
				name = user.get('name')
			} else if (investor) {
				name = investmentHelper.getInvestorName(investor)
			} else if (attendee.has('name')) {
				name = attendee.get('name')
			}

			attendee = attendee.set('name', name)

			if (attendee.has('roles')) {
				const role = attendee.getIn(['roles', 0])
				attendee = attendee.set('role', SYNA_COMPANY_ROLES[role])
			}

			if (userId === secretary) {
				attendee = attendee.set('role', MEETING_ROLES_ADMINISTRATIVE.secretary)
			}

			if (userId === chairman) {
				attendee = attendee.set('role', MEETING_ROLES_ADMINISTRATIVE.chairman)
			}

			return attendee
		})

		attendees = attendees.sortBy((item) => {
			if (item.get('userId') === chairman) {
				return -3
			} else if (item.get('userId') === secretary) {
				return -2
			}
			return 0
		})

		this.setState({ attendees })
	}

	fetchInvestors = () => {
		const { meeting, fetchAndCacheInvestors } = this.props

		if (!meeting) {
			return
		}

		const investmentIds = meeting
			.get('attendees')
			?.filter((a) => !a.get('isGuest'))
			?.map((a) => a.get('userId'))

		if (investmentIds) {
			fetchAndCacheInvestors(investmentIds)
		}
	}

	getItemType = (item) => {
		if (item.has('internalType')) {
			return MEETING_INTERNAL_TYPES.get(item.get('internalType'))
		}

		const itemType = MEETINGS_ITEM_TYPES.find((obj) => obj.get('value') === item.get('itemType'))
		return itemType && itemType.get('label')
	}

	getOutcome = (item) => {
		if (item.has('internalType')) {
			switch (item.get('outcome')) {
				case 'open':
					return 'meetings.outcomes.open'
				case 'closed':
					return 'meetings.outcomes.closed'
			}
		}

		const outcome = MEETINGS_OUTCOMES.find((obj) => obj.get('value') === item.get('outcome'))
		return outcome && outcome.get('label')
	}

	getPresenterName = (presenter) => {
		const { usersCache, investors, meeting } = this.props
		const attendee = meeting.getIn(['attendees', presenter])

		let name = usersCache && usersCache.getIn([presenter, 'name'])

		if (name) {
			return name
		}

		if (attendee && attendee.get('isInvestor')) {
			const investor = investors && investors.find((inv) => inv.get('id') === attendee.get('investmentId'))
			name = investor && investor.getIn(['investorInformation', 'name'])
		} else if (attendee && attendee.get('isGuest')) {
			name = attendee.get('name')
		}

		return name
	}

	markFeedbackAsDone = () => {
		const { markFeedbackAsDone, meeting } = this.props
		markFeedbackAsDone(meeting.get('id'))
	}

	signProtocol = () => {
		const { meeting, signProtocol, userObj } = this.props
		const { ssnValue } = this.state
		const ssn = userObj && userObj.get('ssn')

		// Valid the ssn user typed in form
		if (!ssn) {
			const valid = validatePersonNumber(ssnValue)
			this.setState({ ssnValid: valid })

			if (!valid) {
				return
			}
		}

		this.setState({ protocolIsBeingSigned: true })

		const bankIdMessageCallback = (bankIdData) => {
			this.setState({ bankIdTransaltionId: bankIdData.bankIdMessage })
		}

		signProtocol(meeting.get('id'), ssnValue, bankIdMessageCallback, () => {
			this.setState({ bankIdTransaltionId: null })
			this.setState({ protocolIsBeingSigned: false })
		})
	}

	onSsnInputChange = (field, val) => {
		this.setState(() => {
			return { ssnValue: val }
		})
	}

	renderProtocolTitle = () => {
		const { meeting } = this.props
		const startDate = meeting && meeting.get('startDate')
		const endDate = meeting && meeting.get('endDate')
		const reference = meeting && meeting.get('reference')
		const meetingStartTime = startDate && moment(startDate)
		const meetingEndTime = endDate && moment(endDate)
		let date = ''

		if (meetingStartTime && meetingStartTime.isValid()) {
			date += `${meetingStartTime.format('LL')} ${meetingStartTime.format('LT')}`
		}

		if (meetingEndTime && meetingEndTime.isValid()) {
			date += ` - ${meetingEndTime.format('LT')}`
		}

		return <ProtocolTitle textLeft={reference} textRight={date} />
	}

	renderFeedbackeePanel = () => {
		const { meeting, userObj } = this.props
		const userId = userObj && userObj.get('id')
		const profileImage = userObj && userObj.getIn(['image', 'filename'])
		const name = userObj && userObj.get('name')
		const isFeedbackee = meeting ? meeting.getIn(['feedbackees', userId, 'active']) === true : false
		const isFeedbackeeNotified = meeting ? meeting.getIn(['feedbackees', userId, 'notified']) === true : false
		const feedbackIsDone = meeting ? meeting.getIn(['feedbackees', userId, 'done']) === true : false

		if (!isFeedbackee || !isFeedbackeeNotified) {
			return null
		}

		return (
			<FeedbackeePanel
				profileImage={profileImage}
				name={name}
				feedbackIsDone={feedbackIsDone}
				onBtnClick={this.markFeedbackAsDone}
				userId={userId}
			/>
		)
	}

	renderBankIdInformationTextContainer = () => {
		const { bankIdTransaltionId } = this.state
		return <BankIdInformationTextContainer bankIdTransaltionId={bankIdTransaltionId} mode={'dark'} />
	}

	renderGenernalInformation = () => {
		const { meeting, meetingTemplate, usersCache, investors } = this.props
		const { attendees } = this.state
		const startDate = meeting && meeting.get('startDate')
		const endDate = meeting && meeting.get('endDate')
		const reference = meeting && meeting.get('reference')
		const name = meeting && meeting.get('name')
		const objective = meeting && meeting.get('objective')
		const location = meeting && meeting.getIn(['location', 'label'])
		const template = meetingTemplate && meetingTemplate.get('name')
		const chairmanAttendee = meeting && meeting.get('chairman') && meeting.getIn(['attendees', meeting.get('chairman')])
		const secretaryAttendee =
			meeting && meeting.get('secretary') && meeting.getIn(['attendees', meeting.get('secretary')])

		let chairman
		if (chairmanAttendee) {
			chairman = getUserName(chairmanAttendee, investors, usersCache)
		}

		let secretary
		if (secretaryAttendee) {
			secretary = getUserName(secretaryAttendee, investors, usersCache)
		}

		return (
			<ProtocolGeneralInformation
				startDate={startDate}
				endDate={endDate}
				reference={reference}
				name={name}
				objective={objective}
				template={template}
				chairman={chairman}
				secretary={secretary}
				attendees={attendees}
				location={location}
				renderRoleDisplay={({ attendeeId, roles }) => {
					return (
						<MeetingRoleDisplayContainer
							attendeeId={attendeeId}
							roles={roles}
							secretary={meeting.get('secretary')}
							chairman={meeting.get('chairman')}
						/>
					)
				}}
			/>
		)
	}

	renderPersonalNotesEditor = (objId) => {
		return <NotesContainer objId={objId} objType={OBJ_TYPE_AGENDA_ITEM} borderless />
	}

	renderAgendaItem = (item, index) => {
		const { usersCache, meeting, userObj, documentsDisplayed, tasksDisplayed, notesDisplayed } = this.props
		const { documents } = this.state
		const userId = userObj && userObj.get('id')
		const isSecretary = meeting && userId === meeting.get('secretary')
		const num = item.get('num')
		const title = item.get('proposal') ? item.get('proposal') : ''
		// const presenter = usersCache.getIn([item.get('presenter'), 'name']);
		const presenter = this.getPresenterName(item.get('presenter'))
		const itemType = this.getItemType(item)
		const isCategory = item.get('itemType') === 'category'
		const outcome = this.getOutcome(item)
		const minutes = item.get('minutes')
		const objId = `${meeting.get('id')}$${item.get('id')}`
		const files = documents && documents.get(objId)
		const isFeedbackee = meeting ? meeting.getIn(['feedbackees', userId, 'active']) === true : false
		const isFeedbackeeNotified = meeting ? meeting.getIn(['feedbackees', userId, 'notified']) === true : false
		const protocolPublished = meeting && meeting.getIn(['computedValues', 'protocolPublished'])
		const showFeedbakckForm = isFeedbackee && isFeedbackeeNotified && !isCategory && !protocolPublished
		const showDocuments = documentsDisplayed && files
		const showTasks = tasksDisplayed
		const showNotes = notesDisplayed

		return (
			<React.Fragment key={index}>
				<AgendaItem
					title={`${num}. ${title}`}
					presenter={presenter}
					itemType={itemType}
					outcome={outcome}
					minutes={minutes}
				/>

				{showFeedbakckForm && (
					<Block titleTid='meetings.protocol.feedback_form.title'>
						<Padding top={4}>
							<CommentsListContainer
								objId={objId}
								objType={OBJ_TYPE_AGENDA_ITEM_FEEDBACK}
								objTitle={item && item.get('proposal')}
								objUrl={window.location.href}
								panelType='inline'
								placeholderTid='meetings.protocol_view.feedback.placeholder'
								withoutPanel
							/>
						</Padding>
					</Block>
				)}

				{showDocuments && <DocumentsListContainer files={files} hideProposalColumn={true} />}

				{showTasks && <TasksListContainer objId={objId} readOnly={!isSecretary} />}

				{showNotes && <PersonalNotes renderEditor={this.renderPersonalNotesEditor.bind(this, objId)} />}
			</React.Fragment>
		)
	}

	renderAgenda = () => {
		const { agendaItems } = this.state

		return agendaItems && agendaItems.map(this.renderAgendaItem)
	}

	render = () => {
		return (
			<React.Fragment>
				{this.renderProtocolTitle()}
				{this.renderFeedbackeePanel()}
				{this.renderGenernalInformation()}
				{this.renderAgenda()}
				{this.renderFeedbackeePanel()}
			</React.Fragment>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get('meeting'),
		meetingTemplate: store.meetingTemplates.get('meetingTemplate'),
		usersCache: store.usersCache.get('usersCache'),
		userObj: store.user.get('userObj'),
		company: store.company.company,
		documentsDisplayed: store.meetings.getIn(['protocolFilters', 'documentsDisplayed']),
		tasksDisplayed: store.meetings.getIn(['protocolFilters', 'tasksDisplayed']),
		notesDisplayed: store.meetings.getIn(['protocolFilters', 'notesDisplayed']),
		investors: store.investors.get('investorsCache')
	}
}

const mapActionsToProps = {
	signProtocol,
	markFeedbackAsDone,
	fetchMultipleAttachedDocuments,
	fetchAndCacheInvestors
}

export default connect(mapStoreToProps, mapActionsToProps)(AgendaViewContainer)
