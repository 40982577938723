import { useTranslation } from 'react-i18next'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { closeEmission } from '../../../../actions/emissions.actions'
import { fetchMenu } from '../../../../actions/menu.actions'

const PaymentPopUpModal = (props) => {
	const { t } = useTranslation()
	const emission = useSelector((state) => state.emissions.current)
	const dispatch = useDispatch()

	const registerAndClose = async () => {
		await dispatch(closeEmission(emission.companyId, emission.id))
		dispatch(fetchMenu('main'))
		props.close()
	}

	return (
		<Dialog open={props.open} onClose={props.close}>
			<DialogContent sx={{ px: 6, py: 4, minWidth: '60rem', minHeight: '30rem' }}>
				<IconButton
					aria-label='close'
					onClick={props.close}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[600]
					}}>
					<CloseIcon />
				</IconButton>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<>
						<Typography sx={{ textAlign: 'center', pb: 3 }} variant='h4'>
							{t('emissions.allocation.close-emission')}
						</Typography>
						<Typography sx={{ pb: 2 }} variant='body2'>
							{props.desc}
						</Typography>
						<Box sx={{ display: 'flex', justifyContent: 'space-evenly', pt: 5 }}>
							<Button onClick={props.close} variant={'text'} sx={{ color: 'grey.600' }}>
								<Typography variant='h5'>{t('generic.no')}</Typography>
							</Button>
							<Button onClick={() => registerAndClose()} variant={'contained'}>
								<Typography variant='h5'>{t('Yes')}</Typography>
							</Button>
						</Box>
					</>
				</Box>
			</DialogContent>
		</Dialog>
	)
}

export default PaymentPopUpModal
