import React, { useCallback } from 'react'
import { string, bool, func } from 'prop-types'
import styled from 'styled-components'
import { TransparentButton } from '../../shared/button-v2'

const ExportExcelButton = ({ exportAsExcel, floatingFilter, displayFloatingFilter, noFilter }) => {
	const floatingFilterDropdownMenuItemTid = useCallback(() => {
		if (floatingFilter) {
			return 'ag_grid.panel.dropdown_menu.filter.item.hide_filter'
		} else {
			return 'ag_grid.panel.dropdown_menu.filter.item.show_filter'
		}
	}, [floatingFilter])

	return (
		<>
			{!noFilter && (
				<TransparentButton
					tid={floatingFilterDropdownMenuItemTid()}
					textColor='white'
					hasUnderline
					onClick={displayFloatingFilter}
				/>
			)}

			{exportAsExcel && (
				<TransparentButton
					tid='investments.shareholders.grid.modal.header.button.export_excel'
					textColor='white'
					hasUnderline
					onClick={exportAsExcel}
				/>
			)}
		</>
	)
}

ExportExcelButton.propTypes = {
	exportAsExcel: func,
	noFilter: bool
}

export default ExportExcelButton
