import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../dumb-components/shared/button-v2'
import { selectVoting, sealVoting } from '../../../actions/votings.actions'
import EndVoting from './end-voting.container'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'
import { getMeetingStartDatePassed } from '../../../components/helpers/meeting.helper'
import { getAtLeastOneAttendeeCanVote } from '../../../components/helpers/voting.helper'
import { startVoting } from '../../../actions/votings.actions'
import { setComponentLoading } from '../../../actions/notify.actions'

import { MEETING_VOTING_DRAFT, MEETING_VOTING_COMPLETED, MEETING_VOTING_STARTED } from '/shared/constants'

class VotingManageModalFooterContainer extends Component {
	state = {
		sealIsLoading: false
	}

	closeModal = () => {
		this.props.selectVoting(null)
	}

	sealVotingCallback = () => {
		this.closeModal()
	}

	sealVoting = () => {
		const { sealVoting, votingMeetingId, votingId } = this.props
		this.setState({ sealIsLoading: true })
		sealVoting(votingMeetingId, votingId, this.sealVotingCallback)
	}

	getCanStartVoting = () => {
		const { meetingIsStarted, attendees, topicRef, question, rollcallDone, investorsCache, votingsList } = this.props
		const votingHasStarted = votingsList.some((voteObj) => voteObj.get('status') === MEETING_VOTING_STARTED)

		// Not all steps are yet done
		if (!topicRef || !question) {
			return {
				votingBtnDisabled: true,
				tooltipTid: 'meetings.voting.manage_modal.tooltip.question_fields_not_filled'
			}
		}

		if (meetingIsStarted && votingHasStarted) {
			return {
				votingBtnDisabled: true,
				tooltipTid: 'meetings.voting.manage_modal.tooltip.ongoing_question'
			}
		}

		// Meeting not yet started
		if (!meetingIsStarted) {
			return {
				votingBtnDisabled: true,
				tooltipTid: 'meetings.voting.manage_modal.tooltip.meeting_not_started'
			}
		}

		if (!getAtLeastOneAttendeeCanVote(attendees, investorsCache)) {
			return {
				votingBtnDisabled: true,
				tooltipTid: 'meetings.voting.manage_modal.tooltip.nobody_can_vote'
			}
		}

		// Rollcall not completed
		if (attendees.size && !rollcallDone) {
			return {
				votingBtnDisabled: true,
				tooltipTid: 'meetings.voting.modal.footer.tooltip.rollcall_not_completed'
			}
		}

		return { votingBtnDisabled: false }
	}

	startVoting = () => {
		const { startVoting, voting, meetingId, setComponentLoading } = this.props
		const votingId = voting.get('id')

		setComponentLoading && setComponentLoading(START_VOTING_BUTTON, true)

		startVoting(meetingId, votingId, () => {
			setComponentLoading && setComponentLoading(START_VOTING_BUTTON, false)
		})
	}

	renderCloseAndSealButton = () => {
		const { sealIsLoading } = this.state

		return (
			<TransparentButton
				tid='meetings.voting.manage_modal.result.btn.seal_close'
				onClick={this.sealVoting}
				isLoading={sealIsLoading}
			/>
		)
	}

	renderManuallyEndVotingButton = () => {
		return (
			<EndVoting
				renderComponent={({ endVoting, sealIsLoading }) => (
					<TransparentButton
						tid='meetings.voting.manage_modal.btn.stop_voting'
						onClick={endVoting}
						isLoading={sealIsLoading}
					/>
				)}
			/>
		)
	}

	renderStartVotingButton = () => {
		const { votingStatus, startButtonIsLoading } = this.props
		const { votingBtnDisabled, tooltipTid } = this.getCanStartVoting()

		if (votingStatus !== MEETING_VOTING_DRAFT) {
			return null
		}
		return (
			<Tooltip tid={tooltipTid} delayShow='instant' active={tooltipTid ? true : false}>
				<TransparentButton
					tid='meetings.voting.manage_modal.btn.start_voting'
					isLoading={startButtonIsLoading}
					disabled={votingBtnDisabled}
					onClick={this.startVoting}
				/>
			</Tooltip>
		)
	}

	renderCloseButton = () => {
		const { votingStatus } = this.props
		const btnTid =
			votingStatus === MEETING_VOTING_DRAFT
				? 'meetings.agm.voting_modal.footer.btn.save_and_close'
				: 'generic.form.close'

		return <TransparentButton tid={btnTid} textColor='midGrey' onClick={this.closeModal} />
	}

	render = () => {
		const { votingStatus, isSealed } = this.props
		const shouldShowSealButton = votingStatus === MEETING_VOTING_COMPLETED && !isSealed
		const shouldShowEndVotingButton = votingStatus === MEETING_VOTING_STARTED

		return (
			<FooterRightControls id='votingManageFooterId'>
				{this.renderStartVotingButton()}
				{shouldShowEndVotingButton && this.renderManuallyEndVotingButton()}
				{!shouldShowSealButton && this.renderCloseButton()}
				{shouldShowSealButton && this.renderCloseAndSealButton()}
			</FooterRightControls>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		votingMeetingId: store.votings.getIn(['voting', 'meetingId']),
		votingId: store.votings.getIn(['voting', 'id']),
		votingStatus: store.votings.getIn(['voting', 'status'], MEETING_VOTING_DRAFT),
		isSealed: store.votings.getIn(['voting', 'sealed']),
		topicRef: store.votings.getIn(['voting', 'topicRef']),
		question: store.votings.getIn(['voting', 'question']),
		startButtonIsLoading: store.notify.loadingComponents?.startVotingButton,
		meetingIsStarted: store.meetings.getIn(['meeting', 'computedValues', 'meetingIsStarted']),
		attendees: store.meetings.getIn(['meeting', 'attendees'], Map()) || Map(),
		voting: store.votings.get('voting'),
		meetingId: store.meetings.getIn(['meeting', 'id']),
		rollcallDone: store.meetings.getIn(['meeting', 'metadata', 'rollcallDone']),
		investorsCache: store.investors.get('investorsCache'),
		votingsList: store.votings.get('list')
	}
}

const mapActionsToProps = {
	selectVoting,
	sealVoting,
	startVoting
}

export default connect(mapStoreToProps, mapActionsToProps)(VotingManageModalFooterContainer)
