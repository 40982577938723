import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool, func } from 'prop-types'
import { Map, List } from 'immutable'
import { addErrorNotification, addInfoNotification, clearNotifications } from '../../actions/notify.actions'
import { createRole } from '../../actions/user-management.actions'

import TeamCreateModal from '../../dumb-components/user-management/team-create-modal/team-create-modal'

class TeamCreateModalContainer extends Component {
	state = {
		team: Map(),
		hasError: Map(),
		isLoading: false
	}

	static propTypes = {
		isOpen: bool
	}

	onChange = (fieldName, value) => {
		let { team, hasError } = this.state
		team = team.set(fieldName, value)

		this.setState({ team })

		if (hasError.get(fieldName)) {
			hasError = hasError.delete(fieldName)
			this.setState({ hasError })
		}
	}

	onSave = () => {
		const { onCancel, createRole } = this.props
		const { team } = this.state

		this.setState({ isLoading: true })

		if (this.validate()) {
			createRole(team, (err) => {
				if (!err) {
					onCancel()
					this.resetState()
				} else {
					this.setState({ isLoading: false })
				}
			})
		} else {
			// Reset loading if validation did not pass
			this.setState({ isLoading: false })
		}
	}

	onCancel = () => {
		const { onCancel, clearNotifications } = this.props
		this.resetState()
		clearNotifications()
		onCancel()
	}

	validate = () => {
		const { team } = this.state
		let { hasError } = this.state
		let isValid = true

		if (team.get('name', '').trim().length === 0) {
			hasError = hasError.set('name', List([true]))
			this.addNotification('name')
			isValid = false
		}

		if (!isValid) {
			this.setState({ hasError })
		}

		return isValid
	}

	addNotification = (fieldName) => {
		const { addErrorNotification } = this.props

		addErrorNotification({
			tid: `user_management.team.add.validation.${fieldName}`
		})
	}

	resetState = () => {
		this.setState({
			team: Map(),
			hasError: Map(),
			isLoading: false
		})
	}

	render = () => {
		const { team, hasError, isLoading } = this.state
		const { isOpen } = this.props

		return (
			<TeamCreateModal
				isOpen={isOpen}
				team={team}
				hasError={hasError}
				isLoading={isLoading}
				onChange={this.onChange}
				onSave={this.onSave}
				onCancel={this.onCancel}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {
	addErrorNotification,
	addInfoNotification,
	clearNotifications,
	createRole
}

export default connect(mapStoreToProps, mapActionsToProps)(TeamCreateModalContainer)
