import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'
import { Map } from 'immutable'

import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'
import { OutlinedButton } from '../../../dumb-components/shared/button-v2'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import RequestFeedbackContainer from '../meeting/smart-meeting/dropdown-items/request-feedback.container'

class FeedbackPanelControlsContainer extends Component {
	static propTypes = {}

	static defaultProps = {}

	dropdownRef = null

	toggleDropdown = () => {
		this.dropdownRef && this.dropdownRef.onToggleMenu()
	}

	render = () => {
		const { feedbackees } = this.props
		const noFeedbackeesAdded = !feedbackees.size
		const dropdownDisabled = noFeedbackeesAdded

		return (
			<>
				<RequestFeedbackContainer
					renderComponent={({ openFeedbackeesModal }) => (
						<Tooltip tid='meetings.smart.feedback.panel.controls.add_feedbackees'>
							<OutlinedButton icon='faPlus' onClick={openFeedbackeesModal} />
						</Tooltip>
					)}
				/>

				<DropdownMenuContainer
					halignMenu='right'
					ref={(ref) => (this.dropdownRef = ref)}
					onClick={this.toggleDropdown}
					disabled={dropdownDisabled}
					withPortal
					renderRaw={
						<Tooltip
							active={dropdownDisabled}
							tid='meetings.smart.feedback.panel.controls.dropdown.tooltip.no_feedackees'
							delayShow='instant'>
							<OutlinedButton icon='faEllipsisV' disabled={dropdownDisabled} leftMargin />
						</Tooltip>
					}>
					<RequestFeedbackContainer
						renderComponent={({ openFeedbackeesModal }) => (
							<Tooltip
								active={noFeedbackeesAdded}
								tid='meetings.smart.feedback.panel.controls.dropdown.tooltip.no_feedackees'
								delayShow='instant'>
								<DropdownIconItem
									icon='faCog'
									tid='meetings.smart.feedback.panel.controls.manage_feedbackees'
									disabled={noFeedbackeesAdded}
									onClick={() => {
										openFeedbackeesModal()
										this.toggleDropdown()
									}}
								/>
							</Tooltip>
						)}
					/>
				</DropdownMenuContainer>
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		feedbackees: store.meetings.getIn(['meeting', 'feedbackees'], Map())
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(FeedbackPanelControlsContainer)
