import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Button from '../../shared/button/button'
import { bool, func, oneOf, string } from 'prop-types'
import Input from '../../shared/input/input'
import Label from '../../shared/label/label'
import Text from '../../shared/text/text'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import Tooltip from '../../shared/tooltip/tooltip'
import { map } from 'react-immutable-proptypes'
import { Map } from 'immutable'
import ErrorMessage from '../error-message'

import {
	NAME_FIELD,
	EMAIL_FIELD,
	PASSWORD_FIELD,
	PASSWORD_VERIFY_FIELD,
	TOS_FIELD,
	CODE_FIELD,
	PHONE_FIELD
} from '../../../constants/credentials'

const StyledSignInEmail = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	> * {
		margin: ${(props) => props.theme.spacing[3]} 0;
	}
`

const StyledToggleButtonDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	> * {
		margin: 0 ${(props) => props.theme.spacing[2]};
	}
`

const StyledButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: flex-end;
`

const StyledTwoButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: flex-end;

	> * {
		margin: 0 ${(props) => props.theme.spacing[4]};
	}
`

export default class SignInEmail extends PureComponent {
	static propTypes = {
		onClickForgot: func,
		onToggleRememberPass: func,
		onClickSignUp: func,
		onClickVerify: func,
		onClickCancel: func,
		onSetTooltipRefs: func,
		mode: oneOf(['signup', 'verify']),
		name: string,
		email: string,
		password: string,
		passwordVerify: string,
		phone: string,
		tos: bool,
		hasError: map,
		actionBtnLoading: bool,
		isVerifyMode: bool,
		onOpenTos: func
	}

	static defaultProps = {
		rememberDetails: false,
		tos: false,
		mode: 'signup',
		hasError: Map(),
		name: '',
		email: '',
		password: '',
		passwordVerify: '',
		phone: ''
	}

	onChangeTos = (ref, value) => {
		const { onHideTooltip, onChange } = this.props
		onHideTooltip && onHideTooltip(ref)
		onChange && onChange(ref, value)
	}

	renderNameInput = () => {
		const {
			hasError,
			onClickSignUp,
			onSetTooltipRefs,
			onChange,
			name,
			onHideTooltip,
			isVerifyMode,
			actionBtnLoading
		} = this.props

		return (
			<Label tid='credentials_name' textColor='white'>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([NAME_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, NAME_FIELD)}>
					<Input
						inputStyle='v2'
						fieldName={NAME_FIELD}
						onChange={onChange}
						value={name}
						onBlur={onHideTooltip}
						onEnter={onClickSignUp}
						disabled={isVerifyMode || actionBtnLoading}
						hasError={hasError.get(NAME_FIELD)}
					/>
				</Tooltip>
			</Label>
		)
	}

	renderEmailInput = () => {
		const {
			hasError,
			onClickSignUp,
			onSetTooltipRefs,
			onChange,
			email,
			onHideTooltip,
			isVerifyMode,
			actionBtnLoading
		} = this.props

		return (
			<Label tid='credentials.signup.label.email' textColor='white'>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([EMAIL_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, EMAIL_FIELD)}>
					<Input
						inputStyle='v2'
						fieldName={EMAIL_FIELD}
						onChange={onChange}
						value={email}
						onBlur={onHideTooltip}
						onEnter={onClickSignUp}
						disabled={isVerifyMode || actionBtnLoading}
						hasError={hasError.get(EMAIL_FIELD)}
					/>
				</Tooltip>
			</Label>
		)
	}

	renderPasswordInput = () => {
		const {
			hasError,
			onClickSignUp,
			onSetTooltipRefs,
			onChange,
			password,
			onHideTooltip,
			isVerifyMode,
			actionBtnLoading
		} = this.props

		if (isVerifyMode) {
			return null
		}

		return (
			<Label tid='credentials_password' textColor='white'>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([PASSWORD_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, PASSWORD_FIELD)}>
					<Input
						inputStyle='v2'
						fieldName={PASSWORD_FIELD}
						onChange={onChange}
						value={password}
						onBlur={onHideTooltip}
						onEnter={onClickSignUp}
						disabled={actionBtnLoading}
						hasError={hasError.get(PASSWORD_FIELD)}
						type='password'
					/>
				</Tooltip>
			</Label>
		)
	}

	renderPasswordVerifyInput = () => {
		const {
			hasError,
			onClickSignUp,
			onSetTooltipRefs,
			onChange,
			passwordVerify,
			onHideTooltip,
			isVerifyMode,
			actionBtnLoading
		} = this.props

		if (isVerifyMode) {
			return null
		}

		return (
			<Label tid='credentials_confirm_password' textColor='white'>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([PASSWORD_VERIFY_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, PASSWORD_VERIFY_FIELD)}>
					<Input
						inputStyle='v2'
						fieldName={PASSWORD_VERIFY_FIELD}
						onChange={onChange}
						value={passwordVerify}
						onBlur={onHideTooltip}
						onEnter={onClickSignUp}
						disabled={actionBtnLoading}
						hasError={hasError.get(PASSWORD_VERIFY_FIELD)}
						type='password'
					/>
				</Tooltip>
			</Label>
		)
	}

	renderTosSwitch = () => {
		const { hasError, onSetTooltipRefs, tos, isVerifyMode, actionBtnLoading, onOpenTos } = this.props

		if (isVerifyMode) {
			return null
		}

		return (
			<Tooltip
				activeState='formValidator'
				tid={hasError.getIn([TOS_FIELD, 0])}
				ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, TOS_FIELD)}>
				<StyledToggleButtonDiv>
					<ToggleSwitch fieldName={TOS_FIELD} onChange={this.onChangeTos} disabled={actionBtnLoading} checked={tos} />
					<Text>
						<Text tid='credentials_terms.of.reference_1' color='muted' />
						&nbsp;
						<Button mode='link' tid='credentials_terms.of.reference_2' onClick={onOpenTos} />
					</Text>
				</StyledToggleButtonDiv>
			</Tooltip>
		)
	}

	renderPhoneInput = () => {
		const { onClickSignUp, onChange, phone, isVerifyMode, actionBtnLoading } = this.props

		if (isVerifyMode) {
			return null
		}

		return (
			<Label tid='credentials.verify.phone.label' textColor='white'>
				<Input
					inputStyle='v2'
					fieldName={PHONE_FIELD}
					onChange={onChange}
					value={phone}
					// onBlur={onHideTooltip}
					onEnter={onClickSignUp}
					disabled={isVerifyMode || actionBtnLoading}
					// hasError={hasError.get(PHONE_FIELD)}
				/>
			</Label>
		)
	}

	renderVerifyCodeInput = () => {
		const {
			hasError,
			onClickVerify,
			onSetTooltipRefs,
			onChange,
			code,
			onHideTooltip,
			isVerifyMode,
			actionBtnLoading,
			email
		} = this.props

		if (!isVerifyMode) {
			return null
		}

		return (
			<>
				<Label tid='credentials_verify.by.email' textColor='white'>
					<Tooltip
						activeState='formValidator'
						tid={hasError.getIn([CODE_FIELD, 0])}
						ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, CODE_FIELD)}>
						<Input
							inputStyle='v2'
							fieldName={CODE_FIELD}
							onChange={onChange}
							value={code}
							onBlur={onHideTooltip}
							onEnter={onClickVerify}
							disabled={actionBtnLoading}
							hasError={hasError.get(CODE_FIELD)}
						/>
					</Tooltip>
				</Label>

				<Text color='white' tid='credentials.verify.code.code_sent_by_email' values={{ email }} />
			</>
		)
	}

	render = () => {
		const { onClickSignUp, onClickVerify, onClickCancel, isVerifyMode, actionBtnLoading, translationId } = this.props

		return (
			<StyledSignInEmail>
				{this.renderNameInput()}
				{this.renderEmailInput()}
				{this.renderPhoneInput()}
				{this.renderPasswordInput()}
				{this.renderPasswordVerifyInput()}
				{this.renderTosSwitch()}
				{this.renderVerifyCodeInput()}

				<ErrorMessage tid={translationId} />

				{isVerifyMode ? (
					<StyledTwoButtonWrapper>
						<Button
							mode='rounded'
							tid='credentials_verify'
							minWidth='150px'
							maxWidth='150px'
							height='50px'
							isLoading={actionBtnLoading}
							onClick={onClickVerify}
						/>
						<Button
							mode='rounded'
							tid='credentials_cancel'
							inverted={true}
							minWidth='150px'
							maxWidth='150px'
							height='50px'
							onClick={onClickCancel}
						/>
					</StyledTwoButtonWrapper>
				) : (
					<StyledButtonWrapper>
						<Button
							mode='rounded'
							tid='credentials_register'
							minWidth='150px'
							maxWidth='150px'
							height='50px'
							isLoading={actionBtnLoading}
							onClick={onClickSignUp}
						/>
					</StyledButtonWrapper>
				)}
			</StyledSignInEmail>
		)
	}
}
