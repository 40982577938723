import React, { PureComponent } from 'react'
import { string } from 'prop-types'

import Box from '../layout/box/box'
import BankID from './bankid'

export default class StepsBankID extends PureComponent {
	static propTypes = {
		tid: string
	}

	render = () => {
		const { tid } = this.props

		return (
			<Box valign='center' alignItems='center' fullHeight>
				<BankID bankIdTransaltionId={tid} size='170px' mode='dark' fill={false} forceShow coloredLogo noMarginTop />
			</Box>
		)
	}
}
