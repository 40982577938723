import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string } from 'prop-types'
import { Map } from 'immutable'
import { findProxyByAttendeeId, isProxyConfirmedForAttendee } from '../../../components/helpers/meeting.helper'
import ProxyPanel from '../../../dumb-components/meetings/proxy-panel/proxy-panel'

class ProxyPanelContainer extends Component {
	static propTypes = {
		attendeeId: string
	}

	state = {
		proxy: Map()
	}

	componentDidMount = () => {
		this.setProxy()
	}

	componentDidUpdate = (prevProps) => {
		const { attendees, proxies } = this.props

		if (prevProps.attendees !== attendees || prevProps.proxies !== proxies) {
			this.setProxy()
		}
	}

	setProxy = () => {
		const { attendeeId, proxies } = this.props

		if (!proxies) {
			return
		}

		const proxy = findProxyByAttendeeId(proxies, attendeeId)
		this.setState({ proxy })
	}

	render = () => {
		const { meeting, attendeeId } = this.props
		const { proxy } = this.state
		const hasProxy = proxy && isProxyConfirmedForAttendee(proxy, attendeeId)

		if (!hasProxy) {
			return null
		}

		return (
			<ProxyPanel
				name={proxy && proxy.get('name')}
				email={proxy && proxy.get('email')}
				ssn={proxy && proxy.get('ssn')}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get('meeting'),
		attendees: store.meetings.getIn(['meeting', 'attendees']),
		proxies: store.meetings.getIn(['meeting', 'proxies'])
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(ProxyPanelContainer)
