import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'

import StepsModal from '../../../../dumb-components/shared/modal/steps-modal'
import ModalInnerWrapper from '../../../../dumb-components/shared/modal/modal-inner-wrapper'
import PreviousLeftButton from '../../../../dumb-components/shared/modal/previous-left-button'
import FooterRightControls from '../../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../../dumb-components/shared/button-v2'
import HandleEmailTemplateContainer from '../../handle-email-template.container'

import CreateUserFormContainer from '../../../user-management/user-create-form.container'

import { createUser } from '../../../../actions/user-management.actions'
import { closeModal } from '../../../../actions/modals.actions'
import { INVITE_USER_BY_EMAIL_TEMPLATE_STEPS_MODAL } from '../../../../constants/modals'
import { EMAIL_TEMPLATES_INVITE_USER_TO_COMPANY } from '/shared/constants'

const INITIAL_STATE = {
	step: 0,
	user: null,
	isLoading: false
}

class InviteUserStepsModalContainer extends Component {
	state = INITIAL_STATE

	formValidateFunction = null

	componentDidUpdate = (prevProps) => {
		const { isOpen } = this.props

		if (!isOpen && prevProps.isOpen) {
			this.destroyDataOnModalClose()
		}
	}

	destroyDataOnModalClose = () => {
		this.setState(INITIAL_STATE)
		this.formValidateFunction = null
	}

	goToHandleEmail = () => {
		const isValid = this.formValidateFunction()

		if (isValid) {
			this.setState({ step: this.state.step + 1 })
		}
	}

	prevStep = () => {
		this.setState({ step: this.state.step - 1 })
	}

	setUser = (user) => {
		this.setState({ user })
	}

	inviteUser = () => {
		const { workingTemplate, companyId, createUser } = this.props
		let { user } = this.state

		this.setState({ isLoading: true })

		// Legacy code did convert userRoles to comma separated values
		// so here we go...
		let userRolesJoined = user.get('userRoles').join(',')
		user = user.set('userRoles', userRolesJoined)

		createUser(user.toJS(), companyId, workingTemplate.toJS(), () => {
			this.setState({ isLoading: false })
			this.closeModal()
		})
	}

	closeModal = () => {
		this.props.closeModal()
	}

	setValidateFunction = (validateFunction) => {
		this.formValidateFunction = validateFunction
	}

	getSteps = () => {
		const { isLoading } = this.state

		return [
			{
				body: this.renderFirstStepBody(),
				footer: {
					component: this.renderFooter()
				}
			},
			{
				body: this.renderSecondStepBody(),
				footer: {
					leftComponent: <PreviousLeftButton onClick={this.prevStep} disabled={isLoading} />,
					component: this.renderFooter()
				}
			}
		]
	}

	renderFooter = () => {
		const { step, isLoading } = this.state

		const CloseButton = () => (
			<TransparentButton tid='generic.form.close' onClick={this.closeModal} textColor='midGrey' disabled={isLoading} />
		)

		if (step === 0) {
			return (
				<FooterRightControls>
					<TransparentButton tid='next' onClick={this.goToHandleEmail} />
					<CloseButton />
				</FooterRightControls>
			)
		}

		if (step === 1) {
			return (
				<FooterRightControls>
					<TransparentButton tid='generic.send_invite' onClick={this.inviteUser} isLoading={isLoading} />
					<CloseButton />
				</FooterRightControls>
			)
		}
	}

	renderFirstStepBody = () => {
		return (
			<ModalInnerWrapper>
				<CreateUserFormContainer onSetValidateFunction={this.setValidateFunction} onUserChanged={this.setUser} />
			</ModalInnerWrapper>
		)
	}

	renderSecondStepBody = () => {
		return (
			<ModalInnerWrapper>
				<HandleEmailTemplateContainer templateName={EMAIL_TEMPLATES_INVITE_USER_TO_COMPANY} />
			</ModalInnerWrapper>
		)
	}

	render = () => {
		const { isOpen } = this.props
		const { step } = this.state

		if (!isOpen) {
			return null
		}

		return (
			<StepsModal
				isOpen={true}
				step={step}
				steps={this.getSteps()}
				title={step === 0 ? 'company.management.add_user' : 'company.management.add_user.step_two'}
				hSize='lg'
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		isOpen: store.modals.getIn(['activeModal', 'name']) === INVITE_USER_BY_EMAIL_TEMPLATE_STEPS_MODAL,
		workingTemplate: store.emailTemplates.get('workingTemplate'),
		companyId: store.company.company?.id
	}
}

const mapActionsToProps = {
	createUser,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(InviteUserStepsModalContainer)
