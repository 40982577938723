import React from 'react'
import InvestmentContainer from '../investments/investment.container'

const ShareholderPreview = ({ ...props }) => {
	return (
		<div className='i-content i-content--pad-right-half'>
			<div className='i-content__container'>
				<InvestmentContainer {...props} isShareholderPreview />
			</div>
		</div>
	)
}

export default ShareholderPreview
