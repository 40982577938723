// Authentication
export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const VERIFY_MODE = 'VERIFY_MODE';
export const REGISTER_USER_STEP_TWO = 'REGISTER_USER_STEP_TWO';

export const CREDENTIALS_SET_AUTENTICATED = 'CREDENTIALS_SET_AUTENTICATED';
export const CREDENTIALS_RESPONSE = 'CREDENTIALS_RESPONSE';
export const CREDENTIALS_CANCEL = 'CREDENTIALS_CANCEL';
export const CREDENTIALS_SIGNOUT = 'CREDENTIALS_SIGNOUT';
export const CREDENTIALS_RESET = 'CREDENTIALS_RESET';
export const CREDENTIALS_SET_TID = 'CREDENTIALS_SET_TID';
export const CREDENTIALS_RESET_TIDS = 'CREDENTIALS_RESET_TIDS';
export const CREDENTIALS_RESET_FORGOT_PASSWORD = 'CREDENTIALS_RESET_FORGOT_PASSWORD';
export const CREDENTIALS_RESET_SSN = 'CREDENTIALS_RESET_SSN';

// i18n
export const SET_LOCALE = 'SET_LOCALE';

// Menu
export const MENU_FETCH = 'MENU_FETCH';
export const MENU_ERROR = 'MENU_ERROR';

// User
export const USER_FETCH = 'USER_LOGIN';
export const USER_ERROR = 'USER_ERROR';
export const USER_SAVE = 'USER_SAVE';
export const USER_REMOVE_COMPANY = 'USER_REMOVE_COMPANY';
export const USER_UPDATE_COMPANY_SUBSCRIPTION = 'USER_UPDATE_COMPANY_SUBSCRIPTION';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_DIRTY = 'USER_DIRTY';
export const USER_EDIT_START = 'USER_EDIT_START';
export const USER_FETCH_COMPANIES_USER_MEMBER_OF = 'USER_FETCH_COMPANIES_USER_MEMBER_OF';

//usersCache
export const USER_CACHE_ADD_USERS_TO_CACHE = 'USER_CACHE_ADD_USERS_TO_CACHE';
export const USER_CACHE_REMOVE_USER = 'USER_CACHE_REMOVE_USER';
export const USER_CACHE_UPDATE_REQUEST_CACHE = 'USER_CACHE_UPDATE_REQUEST_CACHE';
export const USER_CACHE_RESET_REDUCER = 'USER_CACHE_RESET_REDUCER';

// Company
export const COMPANIES_GET = 'COMPANIES_GET';
export const COMPANIES_GET_ACCESS_RIGHTS = 'COMPANIES_GET_ACCESS_RIGHTS';
export const COMPANIES_COMPANY_UPDATE_REMOTE = 'COMPANIES_COMPANY_UPDATE_REMOTE';
export const COMPANY_CREATE = 'COMPANY_CREATE';
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const COMPANY_CHANGED = 'COMPANY_CHANGED';
export const COMPANY_RESET_REDUCER = 'COMPANY_RESET_REDUCER';
export const COMPANY_FETCH_USER_TAGS = 'COMPANY_FETCH_USER_TAGS';
export const COMPANY_DIRTY = 'COMPANY_DIRTY';
export const COMPANY_EDIT_START = 'COMPANY_EDIT_START';
export const COMPANY_UPDATE_LOCAL = 'COMPANY_UPDATE_LOCAL';
export const COMPANY_FETCH_MEMBERS = 'COMPANY_FETCH_MEMBERS';
export const USER_MANAGEMENT_CHANGED_PERMISSIONS_BUNDLES = 'USER_MANAGEMENT_CHANGED_PERMISSIONS_BUNDLES';
export const USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS_START =
	'USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS_START';
export const USER_MANAGEMENT_REQUEST_PENDING = 'USER_MANAGEMENT_REQUEST_PENDING';
export const COMPANY_FETCH_MEMBERS_AS_OPTIONS = 'COMPANY_FETCH_MEMBERS_AS_OPTIONS';

// Minutes
export const MINUTES_FETCH = 'MINUTES_FETCH';
export const MINUTES_LIST = 'MINUTES_LIST';
export const MINUTES_SAVE = 'MINUTES_SAVE';
export const MINUTES_ERROR = 'MINUTES_ERROR';
export const MINUTES_UPDATE_LIST_LOCALE = 'MINUTES_UPDATE_LIST_LOCALE';
export const MINUTES_UPDATE_LOCALE = 'MINUTES_UPDATE_LOCALE';
export const MINUTES_FETCH_READONLY = 'MINUTES_FETCH_READONLY';
export const MINUTES_DIRTY = 'MINUTES_DIRTY';
export const MINUTES_SAVE_START = 'MINUTES_SAVE_START';

// Documents
export const DOCUMENTS_LIST = 'DOCUMENTS_LIST';
export const DOCUMENTS_LIST_PAGINATED = 'DOCUMENTS_LIST_PAGINATED';
export const DOCUMENTS_CLEAR_PAGINATION = 'DOCUMENTS_CLEAR_PAGINATION';
export const DOCUMENTS_UPDATE_LOCAL = 'DOCUMENTS_UPDATE_LOCAL';
export const DOCUMENTS_CREATE_REMOTE = 'DOCUMENTS_CREATE_REMOTE';
export const DOCUMENTS_FETCH_REMOTE = 'DOCUMENTS_FETCH_REMOTE';
export const DOCUMENTS_SAVE_REMOTE = 'DOCUMENTS_SAVE_REMOTE';
export const DOCUMENTS_SAVE_REMOTE_START = 'DOCUMENTS_SAVE_REMOTE_START';
export const DOCUMENTS_DELETE_REMOTE = 'DOCUMENTS_DELETE_REMOTE';
export const DOCUMENTS_DOCUMENT_DIRTY = 'DOCUMENTS_DOCUMENT_DIRTY';
export const DOCUMENTS_UPDATE_FOLDER = 'DOCUMENTS_UPDATE_FOLDER';
export const DOCUMENTS_MIRRORED_FETCH_REMOTE = 'DOCUMENTS_MIRRORED_FETCH_REMOTE';
export const DOCUMENTS_SET_FILTERS = 'DOCUMENTS_SET_FILTERS';
export const DOCUMENTS_UNSET_FILTERS = 'DOCUMENTS_UNSET_FILTERS';
export const DOCUMENTS_CREATE_LOCAL = 'DOCUMENTS_CREATE_LOCAL';
export const DOCUMENTS_SELECT = 'DOCUMENTS_SELECT';
export const DOCUMENTS_CLEAR_LOCAL = 'DOCUMENTS_CLEAR_LOCAL';
export const DOCUMENTS_SET_VISIBLE = 'DOCUMENTS_SET_VISIBLE';
export const DOCUMENTS_SET_DEFAULT_FILTERS = 'DOCUMENTS_SET_DEFAULT_FILTERS';
export const DOCUMENTS_UPDATE_DEFAULT_FILTER = 'DOCUMENTS_UPDATE_DEFAULT_FILTER';
export const DOCUMENTS_RESET_DEFAULT_VALUES = 'DOCUMENTS_RESET_DEFAULT_VALUES';
export const DOCUMENTS_LIST_BY_COMPANIES = 'DOCUMENTS_LIST_BY_COMPANIES';
export const DOCUMENTS_FETCH_HELPER = 'DOCUMENTS_FETCH_HELPER';
export const DOCUMENTS_HARD_DELETE = 'DOCUMENTS_HARD_DELETE';
export const DOCUMENTS_HARD_DELETE_LOCAL = 'DOCUMENTS_HARD_DELETE_LOCAL';
export const DOCUMENTS_FETCH_DOCUMENTS_TO_MERGE = 'DOCUMENTS_FETCH_DOCUMENTS_TO_MERGE';
export const DOCUMENTS_PREPEND_DOCUMENT_TO_MERGE = 'DOCUMENTS_PREPEND_DOCUMENT_TO_MERGE';
export const DOCUMENTS_TO_SAVE_SAVE_REMOTE = 'DOCUMENTS_TO_SAVE_SAVE_REMOTE';
export const DOCUMENTS_CLEAR_DOCUMENTS_TO_MERGE = 'DOCUMENTS_CLEAR_DOCUMENTS_TO_MERGE';
export const DOCUMENTS_UPDATE_NUMBER_OF_SHARE_WITH = 'DOCUMENTS_UPDATE_NUMBER_OF_SHARE_WITH';
export const DOCUMENTS_ADD_DOCUMENT_TO_LIST = 'DOCUMENTS_ADD_DOCUMENT_TO_LIST';
export const DOCUMENTS_UPDATE_PAGINATED_DOCUMENTS = 'DOCUMENTS_UPDATE_PAGINATED_DOCUMENTS';
export const DOCUMENTS_UPDATE_DOCUMENT_IN_LIST = 'DOCUMENTS_UPDATE_DOCUMENT_IN_LIST';

// Attachments
export const ATTACHMENTS_FETCH = 'ATTACHMENTS_FETCH';
export const ATTACHMENTS_UPDATE = 'ATTACHMENTS_UPDATE';
export const ATTACHMENTS_DELETE = 'ATTACHMENTS_DELETE';
export const ATTACHMENTS_CREATE = 'ATTACHMENTS_CREATE';
export const ATTACHMENTS_SELECT = 'ATTACHMENTS_SELECT';
export const ATTACHMENTS_UNSELECT = 'ATTACHMENTS_UNSELECT';
export const ATTACHMENTS_GET = 'ATTACHMENTS_GET';
export const ATTACHMENTS_FETCH_MULTIPLE = 'ATTACHMENTS_FETCH_MULTIPLE';

// Documents settings
export const DOCUMENT_SETTINGS_LIST_SECTIONS = 'DOCUMENT_SETTINGS_LIST_SECTIONS';
export const DOCUMENT_SETTINGS_LIST_RESOURCES = 'DOCUMENT_SETTINGS_LIST_RESOURCES';
export const DOCUMENT_SETTINGS_GET_SECTION = 'DOCUMENT_SETTINGS_GET_SECTION';
export const DOCUMENTS_SETTINGS_ERROR = 'DOCUMENTS_SETTINGS_ERROR';

// Folders
export const FOLDERS_CREATE_REMOTE = 'FOLDERS_CREATE_REMOTE';
export const FOLDERS_FETCH_REMOTE = 'FOLDERS_FETCH_REMOTE';
export const FOLDERS_UPDATE_LOCAL = 'FOLDERS_UPDATE_LOCAL';
export const FOLDERS_DELETE_REMOTE = 'FOLDERS_DELETE_REMOTE';
export const FOLDERS_SAVE_REMOTE = 'FOLDERS_SAVE_REMOTE';
export const FOLDERS_LIST = 'FOLDERS_LIST';
export const FOLDERS_UPDATE_SELECTED_FOLDER_ID = 'FOLDERS_UPDATE_SELECTED_FOLDER_ID';
export const FOLDERS_MIRRORED_FETCH_REMOTE = 'FOLDERS_MIRRORED_FETCH_REMOTE';
export const FOLDERS_GET_DEFAULT_PERMISSIONS = 'FOLDERS_GET_DEFAULT_PERMISSIONS';

//Shared Documents
export const SHARING_DOCUMENTS_LIST = 'SHARING_DOCUMENTS_LIST';
export const SHARING_DOCUMENTS_UPDATE_LOCAL = 'SHARING_DOCUMENTS_UPDATE_LOCAL';
export const SHARING_DOCUMENTS_CREATE_LOCAL = 'SHARING_DOCUMENTS_CREATE_LOCAL';
export const SHARING_DOCUMENTS_HARD_DELETE_LOCAL = 'SHARING_DOCUMENTS_HARD_DELETE_LOCAL';
export const SHARING_DOCUMENTS_DELETE_REMOTE = 'SHARING_DOCUMENTS_DELETE_REMOTE';
export const SHARING_DOCUMENTS_SAVE_REMOTE = 'SHARING_DOCUMENTS_SAVE_REMOTE';

//Shared Folders
export const SHARING_FOLDERS_LIST = 'SHARING_FOLDERS_LIST';

// Tags
export const TAGS_LIST_REMOTE = 'TAGS_LIST_REMOTE';
export const TAGS_CREATE_TAG = 'TAGS_CREATE_TAG';
export const TAGS_SET_SORT = 'TAGS_SET_SORT';

// User management
export const USER_MANAGEMENT_FETCH_USERS = 'USER_MANAGEMENT_FETCH_USERS';
export const USER_MANAGEMENT_FETCH_ROLES = 'USER_MANAGEMENT_FETCH_ROLES';
export const USER_MANAGEMENT_FETCH_ROLES_CACHE = 'USER_MANAGEMENT_FETCH_ROLES_CACHE';
export const USER_MANAGEMENT_FETCH_RESOURCES = 'USER_MANAGEMENT_FETCH_RESOURCES';
export const USER_MANAGEMENT_ROW_STATES = 'USER_MANAGEMENT_ROW_STATES';
export const USER_MANAGEMENT_FETCH_SELECTED_USER = 'USER_MANAGEMENT_FETCH_SELECTED_USER';
export const USER_MANAGEMENT_FETCH_SELECTED_ROLE = 'USER_MANAGEMENT_FETCH_SELECTED_ROLE';
export const USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS = 'USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS';
export const USER_MANAGEMENT_CHANGED_SELECTED_ROLE_VALUE = 'USER_MANAGEMENT_CHANGED_SELECTED_ROLE_VALUE';
export const USER_MANAGEMENT_CREATE_ROLE = 'USER_MANAGEMENT_CREATE_ROLE';
export const USER_MANAGEMENT_CREATE_USER = 'USER_MANAGEMENT_CREATE_USER';
export const USER_MANAGEMENT_FETCH_USERS_IN_ROLES_CACHE = 'USER_MANAGEMENT_FETCH_USERS_IN_ROLES_CACHE';
export const USER_MANAGEMENT_RESET_CACHE = 'USER_MANAGEMENT_RESET_CACHE';

// Notify
export const NOTIFY_LOADING_START = 'NOTIFY_LOADING_START';
export const NOTIFY_LOADING_END = 'NOTIFY_LOADING_END';
export const NOTIFY_LOADING_SUCCESS = 'NOTIFY_LOADING_SUCCESS';
export const NOTIFY_LOADING_SUCCESS_END = 'NOTIFY_LOADING_SUCCESS_END';
export const NOTIFY_MENU_COLLAPSED = 'NOTIFY_MENU_COLLAPSED';
export const NOTIFY_ASIDE_EXPANDED = 'NOTIFY_ASIDE_EXPANDED';
export const NOTIFY_SOCKET_ID = 'NOTIFY_SOCKET_ID';
export const NOTIFY_ADD_MESSAGE = 'NOTIFY_ADD_MESSAGE';
export const NOTIFY_REMOVE_MESSAGE = 'NOTIFY_REMOVE_MESSAGE';
export const NOTIFY_CLEAR_MESSAGES = 'NOTIFY_CLEAR_MESSAGES';
export const NOTIFY_BANKID = 'NOTIFY_BANKID';
export const NOTIFY_RETURN_URL = 'NOTIFY_RETURN_URL';
export const NOTIFY_FAKE_LOADING_START = 'NOTIFY_FAKE_LOADING_START';
export const NOTIFY_FAKE_LOADING_END = 'NOTIFY_FAKE_LOADING_END';
export const NOTIFY_FILE_ENTERED_GLOBAL = 'NOTIFY_FILE_ENTERED_GLOBAL';
export const NOTIFY_HIDE_APP_MENUS = 'NOTIFY_HIDE_APP_MENUS';
export const NOTIFY_SHOW_APP_MENUS = 'NOTIFY_SHOW_APP_MENUS';
export const NOTIFY_DEMO_LOADING = 'NOTIFY_DEMO_LOADING';
export const NOTIFY_ATTACHMENT_UPLOADING = 'NOTIFY_ATTACHMENT_UPLOADING';
export const NOTIFY_FETCHING_ATTACHMENTS = 'NOTIFY_FETCHING_ATTACHMENTS';
export const NOTIFY_SET_ACTIVE_TAB = 'NOTIFY_SET_ACTIVE_TAB';
export const NOTIFY_COMPONENT_LOADING = 'NOTIFY_COMPONENT_LOADING';
export const NOTIFY_IS_UPLOADING_EXCEL = 'NOTIFY_IS_UPLOADING_EXCEL';

// Wiki
export const WIKI_FETCH = 'WIKI_FETCH';
export const WIKI_SAVE = 'WIKI_SAVE';
export const WIKI_ERROR = 'WIKI_ERROR';

// Shares
export const SHARES_USER_LIST = 'SHARES_USER_LIST';
export const SHARES_USER_CREATE = 'SHARES_USER_CREATE';
export const SHARES_USER_UPDATE = 'SHARES_USER_UPDATE';
export const SHARES_USER_REMOVE = 'SHARES_USER_REMOVE';
export const SHARES_SAVE = 'SHARES_SAVE';
export const SHARES_FETCH = 'SHARES_FETCH';
export const SHARES_ERROR = 'SHARES_ERROR';
export const SHARES_COMPANY_SHAREHOLDER_LIST = 'SHARES_COMPANY_SHAREHOLDER_LIST';
export const SHARES_SELECT_SHAREHOLDER = 'SHARES_SELECT_SHAREHOLDER';
export const SHARES_SAVE_SHAREHOLDER = 'SHARES_SAVE_SHAREHOLDER';
export const SHARES_UPDATE_SHAREHOLDER = 'SHARES_UPDATE_SHAREHOLDER';
export const SHARES_REMOVE_SHAREHOLDER = 'SHARES_REMOVE_SHAREHOLDER';
export const SHARES_DETERMINE_READ_ONLY = 'SHARES_DETERMINE_READ_ONLY';

// Messages
export const MESSAGES_LIST = 'MESSAGES_LIST';
export const MESSAGES_FETCH = 'MESSAGES_FETCH';
export const MESSAGES_ERROR = 'MESSAGES_ERROR';

// Subscriptions
export const SUBSCRIPTIONS_FETCH = 'SUBSCRIPTIONS_FETCH';
export const SUBSCRIPTIONS_CREATE = 'SUBSCRIPTIONS_CREATE';
export const SUBSCRIPTIONS_SAVE = 'SUBSCRIPTIONS_SAVE';
export const SUBSCRIPTIONS_DELETE = 'SUBSCRIPTIONS_DELETE';
export const SUBSCRIPTIONS_ERROR = 'SUBSCRIPTIONS_ERROR';
export const SUBSCRIPTIONS_DOWNGRADE = 'SUBSCRIPTIONS_DOWNGRADE';
export const SUBSCRIPTIONS_FETCH_PLANS = 'SUBSCRIPTIONS_FETCH_PLANS';
export const SUBSCRIPTIONS_INIT_FORM_OBJECT = 'SUBSCRIPTIONS_INIT_FORM_OBJECT';
export const SUBSCRIPTIONS_UPDATE_FORM_OBJECT = 'SUBSCRIPTIONS_UPDATE_FORM_OBJECT';
export const SUBSCRIPTIONS_FETCH_API_KEY = 'SUBSCRIPTIONS_FETCH_API_KEY';
export const SUBSCRIPTIONS_VERIFY_COUPON = 'SUBSCRIPTIONS_VERIFY_COUPON';
export const SUBSCRIPTIONS_CREATE_TOKEN = 'SUBSCRIPTIONS_CREATE_TOKEN';
export const SUBSCRIPTIONS_FETCH_PREPAID = 'SUBSCRIPTIONS_FETCH_PREPAID';
export const SUBSCRIPTIONS_SAVE_PREPAID = 'SUBSCRIPTIONS_SAVE_PREPAID';
export const SUBSCRIPTIONS_UPDATE_LOCAL_PREPAID = 'SUBSCRIPTIONS_UPDATE_LOCAL_PREPAID';
export const SUBSCRIPTIONS_FETCH_HELPER = 'SUBSCRIPTIONS_FETCH_HELPER';
export const SUBSCRIPTION_ADD_STRIPE_ERROR = 'SUBSCRIPTION_ADD_STRIPE_ERROR';

// Invoices
export const INVOICES_LIST_INVOICES = 'INVOICES_LIST_INVOICES';
export const INVOICES_FETCH_INVOICE = 'INVOICES_FETCH_INVOICE';

// Transactions
export const TRANSACTION_SAVE_TEMP = 'TRANSACTION_SAVE_TEMP';
export const TRANSACTION_SAVE_TEMP_START = 'TRANSACTION_SAVE_TEMP_START';
export const TRANSACTION_FETCH_TEMP = 'TRANSACTION_FETCH_TEMP';
export const TRANSACTION_CREATE = 'TRANSACTION_CREATE';
export const TRANSACTION_TEMP_DIRTY = 'TRANSACTION_TEMP_DIRTY';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';
export const TRANSACTION_UPDATE_TEMP = 'TRANSACTION_UPDATE_TEMP';
export const TRANSACTION_FETCH = 'TRANSACTION_FETCH';
export const TRANSACTION_INITIAL_EMISSION_LEVELING = 'TRANSACTION_INITIAL_EMISSION_LEVELING';
export const TRANSACTION_INITIAL_ADJUSTMENT_TRANSACTION = 'TRANSACTION_INITIAL_ADJUSTMENT_TRANSACTION';
export const TRANSACTION_CLEAR_TEMPORARY_TRANSACTIONS = 'TRANSACTION_CLEAR_TEMPORARY_TRANSACTIONS';
export const TRANSACTION_CLEAR_TEMPORARY_TRANSACTION = 'TRANSACTION_CLEAR_TEMPORARY_TRANSACTION';
export const TRANSACTION_CHECK_ACCESS_TO_TRANSACTION = 'TRANSACTION_CHECK_ACCESS_TO_TRANSACTION';
export const TRANSACTION_LIST = 'TRANSACTION_LIST';

// Investors
export const INVESTORS_LIST = 'INVESTORS_LIST';
export const INVESTORS_FETCH = 'INVESTORS_FETCH';
export const INVESTORS_CREATE = 'INVESTORS_CREATE';
export const INVESTORS_EDIT = 'INVESTORS_EDIT';
export const INVESTORS_DELETE = 'INVESTORS_DELETE';
export const INVESTORS_ERROR = 'INVESTORS_ERROR';
export const INVESTORS_FETCH_MY_INVESTMENTS = 'INVESTORS_FETCH_MY_INVESTMENTS';
export const INVESTORS_FETCH_MY_INVESTMENT = 'INVESTORS_FETCH_MY_INVESTMENT';
export const INVESTORS_FETCH_MY_INVESTMENT_PERSONAL = 'INVESTORS_FETCH_MY_INVESTMENT_PERSONAL';
export const INVESTORS_DIRTY = 'INVESTORS_DIRTY';
export const INVESTORS_SAVE_START = 'INVESTORS_SAVE_START';
export const INVESTORS_EDIT_LOCAL = 'INVESTORS_EDIT_LOCAL';
export const INVESTORS_SET_FILTERS = 'INVESTORS_SET_FILTERS';
export const INVESTORS_UNSET_FILTERS = 'INVESTORS_UNSET_FILTERS';
export const INVESTORS_SET_VISIBLE = 'INVESTORS_SET_VISIBLE';
export const INVESTORS_SET_SELECTEDINVESTOR = 'INVESTORS_SET_SELECTEDINVESTOR';
export const INVESTORS_BEING_FETCHED = 'INVESTORS_BEING_FETCHED';
export const INVESTORS_FETCHED_AT = 'INVESTORS_FETCHED_AT';
export const INVESTORS_UPDATE_INVESTORS_CACHE = 'INVESTORS_UPDATE_INVESTORS_CACHE';
export const INVESTORS_UPDATE_INVESTORS_REQUEST_CACHE = 'INVESTORS_UPDATE_INVESTORS_REQUEST_CACHE';
export const INVESTORS_SET_NUM_OF_COMMUNICATIVE_ACTIVE_INVESTMENTS =
	'INVESTORS_SET_NUM_OF_COMMUNICATIVE_ACTIVE_INVESTMENTS';
export const INVESTOR_LIST_CONCAT = 'INVESTOR_LIST_CONCAT';
export const INVESTOR_CREATE_LOCAL = 'INVESTOR_CREATE_LOCAL';
export const INVESTORS_FETCH_COMBINED_INVESTMENT = 'INVESTORS_FETCH_COMBINED_INVESTMENT';
export const INVESTORS_FETCH_MY_INVESTMENTS_DASHBOARD = 'INVESTORS_FETCH_MY_INVESTMENTS_DASHBOARD';
export const INVESTORS_CLEAR_MY_INVESTMENTS_DASHBOARD = 'INVESTORS_CLEAR_MY_INVESTMENTS_DASHBOARD';


// PERSONAL
export const INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS =
	'INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS';
export const INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS_MAP =
	'INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS_MAP';
export const INVESTORS_CAPITAL_INSURANCE_UPDATE_MY_INVESTMENTS_MAP =
	'INVESTORS_CAPITAL_INSURANCE_UPDATE_MY_INVESTMENTS_MAP';
export const INVESTORS_COMPANY_UPDATE_MY_INVESTMENTS_MAP = 'INVESTORS_COMPANY_UPDATE_MY_INVESTMENTS_MAP';

// Share Register
export const SHARE_REGISTER_FETCH_CERTIFICATES = 'SHARE_REGISTER_FETCH_CERTIFICATES';

// Immutable Form
export const IMMUTABLE_FORM_ADD_ERROR = 'IMMUTABLE_FORM_ADD_ERROR';
export const IMMUTABLE_FORM_RESET_ERROR = 'IMMUTABLE_FORM_RESET_ERROR';
export const IMMUTABLE_FORM_RESET_ERRORS = 'IMMUTABLE_FORM_RESET_ERRORS';
export const IMMUTABLE_FORM_INIT = 'IMMUTABLE_FORM_INIT';
export const IMMUTABLE_FORM_RESET = 'IMMUTABLE_FORM_RESET';

// Core Products
export const CORE_PRODUCTS_FETCH = 'CORE_PRODUCTS_FETCH';

// Comments
export const COMMENTS_UPDATE_LOCAL = 'COMMENTS_UPDATE_LOCAL';
export const COMMENTS_CREATE_COMMENT = 'COMMENTS_CREATE_COMMENT';
export const COMMENTS_UPDATE_REMOTE = 'COMMENTS_UPDATE_REMOTE';
export const COMMENTS_EDIT_LOCAL = 'COMMENTS_EDIT_LOCAL';
export const COMMENTS_DELETE_LOCAL = 'COMMENTS_DELETE_LOCAL';
export const COMMENTS_FETCH_MULTIPLE_REMOTE = 'COMMENTS_FETCH_MULTIPLE_REMOTE';

// Tasks
export const TASKS_LIST = 'TASKS_LIST';
export const TASKS_UPDATE_LOCAL = 'TASKS_UPDATE_LOCAL';
export const TASKS_CREATE = 'TASKS_CREATE';
export const TASKS_CREATE_LOCAL = 'TASKS_CREATE_LOCAL';
export const TASKS_FETCH = 'TASKS_FETCH';
export const TASKS_SAVE = 'TASKS_SAVE';
export const TASKS_SAVE_START = 'TASKS_SAVE_START';
export const TASKS_DELETE = 'TASKS_DELETE';
export const TASKS_TASK_DIRTY = 'TASKS_TASK_DIRTY';
export const TASKS_UPDATE_PROJECT = 'TASKS_UPDATE_PROJECT';
export const TASKS_LIST_BY_COMPANIES = 'TASKS_LIST_BY_COMPANIES';
export const TASKS_SET_FILTERS = 'TASKS_SET_FILTERS';
export const TASKS_UNSET_FILTERS = 'TASKS_UNSET_FILTERS';
export const TASK_REORDER = 'TASK_REORDER';
export const TASKS_SET_VISIBLE = 'TASKS_SET_VISIBLE';
export const TASKS_SELECT = 'TASKS_SELECT';
export const TASKS_SET_DEFAULT_FILTERS = 'TASKS_SET_DEFAULT_FILTERS';
export const TASKS_UPDATE_DEFAULT_FILTER = 'TASKS_UPDATE_DEFAULT_FILTER';
export const TASKS_RESET_DEFAULT_VALUES = 'TASKS_RESET_DEFAULT_VALUES';
export const TASKS_LIST_BY_OBJECTS = 'TASKS_LIST_BY_OBJECTS';
export const TASKS_CREATE_EXTERNAL_TASK_LOCAL = 'TASKS_CREATE_EXTERNAL_TASK_LOCAL';
export const TASKS_CREATE_EXTERNAL = 'TASKS_CREATE_EXTERNAL';
export const TASKS_UPDATE_EXTERNAL_TASKS_LOCAL = 'TASKS_UPDATE_EXTERNAL_TASKS_LOCAL';
export const TASKS_HARD_DELETE = 'TASKS_HARD_DELETE';
export const TASKS_DELETE_EXTERNAL = 'TASKS_DELETE_EXTERNAL';

// Projects
export const PROJECTS_CREATE_REMOTE = 'PROJECTS_CREATE_REMOTE';
export const PROJECTS_FETCH_REMOTE = 'PROJECTS_FETCH_REMOTE';
export const PROJECTS_DELETE_REMOTE = 'PROJECTS_DELETE_REMOTE';
export const PROJECTS_SAVE_REMOTE = 'PROJECTS_SAVE_REMOTE';
export const PROJECTS_LIST = 'PROJECTS_LIST';
export const PROJECTS_UPDATE_LOCAL = 'PROJECTS_UPDATE_LOCAL';
export const PROJECTS_UPDATE_SELECTED_PROJECT_ID = 'PROJECTS_UPDATE_SELECTED_PROJECT_ID';
export const PROJECTS_GET_DEFAULT_PERMISSIONS = 'PROJECTS_GET_DEFAULT_PERMISSIONS';

// Meetings
export const MEETINGS_LIST = 'MEETINGS_LIST';
export const MEETINGS_UPDATE_LOCAL = 'MEETINGS_UPDATE_LOCAL';
export const MEETINGS_CREATE = 'MEETINGS_CREATE';
export const MEETINGS_CREATE_LOCAL = 'MEETINGS_CREATE_LOCAL';
export const MEETINGS_FETCH = 'MEETINGS_FETCH';
export const MEETINGS_SAVE = 'MEETINGS_SAVE';
export const MEETINGS_DELETE = 'MEETINGS_DELETE';
export const MEETINGS_TASK_DIRTY = 'MEETINGS_TASK_DIRTY';
export const MEETINGS_UPDATE_PROJECT = 'MEETINGS_UPDATE_PROJECT';
export const MEETINGS_LIST_BY_COMPANIES = 'MEETINGS_LIST_BY_COMPANIES';
export const MEETINGS_SET_FILTERS = 'MEETINGS_SET_FILTERS';
export const MEETINGS_UNSET_FILTERS = 'MEETINGS_UNSET_FILTERS';
export const MEETINGS_SET_VISIBLE = 'MEETINGS_SET_VISIBLE';
export const MEETINGS_SELECT = 'MEETINGS_SELECT';
export const MEETINGS_FETCH_MEETING_TYPES = 'MEETINGS_FETCH_MEETING_TYPES';
export const MEETINGS_AGENDA_ITEM_CREATE = 'MEETINGS_AGENDA_ITEM_CREATE';
export const MEETINGS_AGENDA_ITEM_FETCH = 'MEETINGS_AGENDA_ITEM_FETCH';
export const MEETINGS_AGENDA_ITEM_SAVE = 'MEETINGS_AGENDA_ITEM_SAVE';
export const MEETINGS_AGENDA_ITEM_UPDATE = 'MEETINGS_AGENDA_ITEM_UPDATE';
export const MEETINGS_LIST_PREVIOUS_MEETINGS = 'MEETINGS_LIST_PREVIOUS_MEETINGS';
export const MEETINGS_FETCH_PREVIOUS_MEETING = 'MEETINGS_FETCH_PREVIOUS_MEETING';
export const MEETINGS_FETCH_SIGNATORY = 'MEETINGS_FETCH_SIGNATORY';
export const MEETINGS_SET_PROTOCOL_FILTERS = 'MEETINGS_SET_PROTOCOL_FILTERS';
export const MEETINGS_RESET_PROTOCOL_FILTERS = 'MEETINGS_RESET_PROTOCOL_FILTERS';
export const MEETINGS_SET_DEFAULT_FILTERS = 'MEETINGS_SET_DEFAULT_FILTERS';
export const MEETINGS_UPDATE_DEFAULT_FILTER = 'MEETINGS_UPDATE_DEFAULT_FILTER';
export const MEETINGS_RESET_DEFAULT_VALUES = 'MEETINGS_RESET_DEFAULT_VALUES';
export const MEETINGS_HARD_DELETE = 'MEETINGS_HARD_DELETE';
export const MEETINGS_UPDATE_PUBLISHING_TYPE = 'MEETINGS_UPDATE_PUBLISHING_TYPE';
export const MEETINGS_ATTENDEES_HAS_BEEN_CHANGED = 'MEETINGS_ATTENDEES_HAS_BEEN_CHANGED';

// Attendees
export const ATTENDEES_FILTER = 'ATTENDEES_FILTER';
export const ATTENDEES_RESET_FILTER = 'ATTENDEES_RESET_FILTER';

// Custom Meeting Functions
export const CUSTOM_MEETING_FUNCTIONS_LIST = 'CUSTOM_MEETING_FUNCTIONS_LIST';
export const CUSTOM_MEETING_FUNCTIONS_SAVE = 'CUSTOM_MEETING_FUNCTIONS_SAVE';

// Groups
export const GROUPS_CREATE_REMOTE = 'GROUPS_CREATE_REMOTE';
export const GROUPS_FETCH_REMOTE = 'GROUPS_FETCH_REMOTE';
export const GROUPS_DELETE_REMOTE = 'GROUPS_DELETE_REMOTE';
export const GROUPS_SAVE_REMOTE = 'GROUPS_SAVE_REMOTE';
export const GROUPS_LIST = 'GROUPS_LIST';
export const GROUPS_UPDATE_LOCAL = 'GROUPS_UPDATE_LOCAL';
export const GROUPS_UPDATE_SELECTED_GROUP_ID = 'GROUPS_UPDATE_SELECTED_PROJECT_ID';
export const GROUPS_GET_DEFAULT_PERMISSIONS = 'GROUPS_GET_DEFAULT_PERMISSIONS';
export const PROJECTS_LIST_USER_TASKS = 'PROJECTS_LIST_USER_TASKS';

// Meeting Templates
export const MEETING_TEMPLATES_LIST = 'MEETING_TEMPLATES_LIST';
export const MEETING_TEMPLATES_UPDATE_LOCAL = 'MEETING_TEMPLATES_UPDATE_LOCAL';
export const MEETING_TEMPLATES_CREATE = 'MEETING_TEMPLATES_CREATE';
export const MEETING_TEMPLATES_CREATE_LOCAL = 'MEETING_TEMPLATES_CREATE_LOCAL';
export const MEETING_TEMPLATES_FETCH = 'MEETING_TEMPLATES_FETCH';
export const MEETING_TEMPLATES_SAVE = 'MEETING_TEMPLATES_SAVE';
export const MEETING_TEMPLATES_SAVE_START = 'MEETING_TEMPLATES_SAVE_START';
export const MEETING_TEMPLATES_DELETE = 'MEETING_TEMPLATES_DELETE';
export const MEETING_TEMPLATES_DIRTY = 'MEETING_TEMPLATES_DIRTY';
export const MEETING_TEMPLATES_SELECT = 'MEETING_TEMPLATES_SELECT';
export const MEETING_TEMPLATES_LIST_BY_COMPANIES = 'MEETING_TEMPLATES_LIST_BY_COMPANIES';

// Protocol PDF Options
export const PROTOCOL_PDF_OPTIONS_LIST = 'PROTOCOL_PDF_OPTIONS_LIST';
export const PROTOCOL_PDF_OPTIONS_FETCH = 'PROTOCOL_PDF_OPTIONS_FETCH';
export const PROTOCOL_PDF_OPTIONS_SAVE = 'PROTOCOL_PDF_OPTIONS_SAVE';
export const PROTOCOL_PDF_OPTIONS_UPDATE_LOCAL = 'PROTOCOL_PDF_OPTIONS_UPDATE_LOCAL';
export const PROTOCOL_PDF_OPTIONS_CLEAR = 'PROTOCOL_PDF_OPTIONS_CLEAR';

// Voting
export const VOTING_LIST = 'VOTING_LIST';
export const VOTING_UPDATE_LOCAL = 'VOTING_UPDATE_LOCAL';
export const VOTING_CREATE = 'VOTING_CREATE';
export const VOTING_CREATE_LOCAL = 'VOTING_CREATE_LOCAL';
export const VOTING_FETCH = 'VOTING_FETCH';
export const VOTING_SAVE = 'VOTNG_SAVE';
export const VOTING_DELETE = 'VOTING_DELETE';
export const VOTING_SELECT = 'VOTING_SELECT';
export const VOTING_REMOTLY_CREATED = 'VOTING_REMOTLY_CREATED';
export const VOTING_REMONTLY_UPDATED = 'VOTING_REMONTLY_UPDATED';
export const VOTING_REMOTLY_DELETED = 'VOTING_REMOTLY_DELETED';
export const VOTING_VOTE_USER_UPDATE_LOCAL = 'VOTING_VOTE_USER_UPDATE_LOCAL';

// Audit
export const LIVE_REQUEST = 'LIVE_REQUEST';
export const LIVE_REQUEST_RESET = 'LIVE_REQUEST_RESET';

// Notes
export const NOTES_FETCH_REMOTE = 'NOTES_FETCH_REMOTE';
export const NOTES_SAVE_REMOTE = 'NOTES_SAVE_REMOTE';
export const NOTES_DIRTY = 'NOTES_DIRTY';

// Screen Message
export const SCREEN_MESSAGE_SHOW = 'SCREEN_MESSAGE_SHOW';
export const SCREEN_MESSAGE_HIDE = 'SCREEN_MESSAGE_HIDE';

// Modals
export const MODALS_OPEN = 'MODALS_OPEN';
export const MODALS_CLOSE = 'MODALS_CLOSE';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';

// Notifcations
export const NOTIFICATIONS_ONBOARD_FETCH_REMOTE = 'NOTIFICATIONS_ONBOARD_FETCH_REMOTE';
export const NOTIFICATIONS_ONBOARD_SINGLE_FETCH_REMOTE = 'NOTIFICATIONS_ONBOARD_SINGLE_FETCH_REMOTE';
export const NOTIFICATIONS_ONBOARD_MARK_READ = 'NOTIFICATIONS_ONBOARD_MARK_READ';
export const NOTIFICATIONS_ONBOARD_MARK_DISMISS = 'NOTIFICATIONS_ONBOARD_MARK_DISMISS';
export const NOTIFICATIONS_ONBOARD_SESSION_RESET = 'NOTIFICATIONS_ONBOARD_SESSION_RESET';
export const NOTIFICATIONS_LOCATION_UPDATE_LOCAL = 'NOTIFICATIONS_LOCATION_UPDATE_LOCAL';

// Email Templates
export const EMAIL_TEMPLATES_FETCH_REMOTE = 'EMAIL_TEMPLATES_FETCH_REMOTE';
export const EMAIL_TEMPLATE_UPDATE_LOCAL = 'EMAIL_TEMPLATE_UPDATE_LOCAL';
export const EMAIL_TEMPLATE_SAVE = 'EMAIL_TEMPLATE_SAVE';
export const EMAIL_TEMPLATE_RESET = 'EMAIL_TEMPLATE_RESET';

// Capital Insurance
export const CAPITAL_INSURANCE_LIST = 'CAPITAL_INSURANCE_LIST';
export const CAPITAL_INSURANCE_CREATE = 'CAPITAL_INSURANCE_CREATE';

// Depos
export const DEPOS_LIST = 'DEPOS_LIST';
export const DEPO_CREATE = 'DEPOS_CREATE';
export const DEPO_UPDATE = 'DEPOS_UPDATE';
export const DEPO_DELETE = 'DEPOS_DELETE';

// Collections
export const COLLECTIONS_FETCH = 'COLLECTIONS_FETCH';
export const COLLECTION_SELECT = 'COLLECTION_SELECT';
export const COLLECTION_CREATE = 'COLLECTION_CREATE';
export const COLLECTION_REMOVE = 'COLLECTION_REMOVE';
export const COLLECTION_ADD_DOCUMENT = 'COLLECTION_ADD_DOCUMENT';
export const COLLECTION_REMOVE_DOCUMENT = 'COLLECTION_REMOVE_DOCUMENT';
export const COLLECTION_UPDATE_LOCAL = 'COLLECTION_UPDATE_LOCAL';
export const COLLECTION_UPDATE_DOCUMENTS_LOCAL = 'COLLECTION_UPDATE_DOCUMENTS_LOCAL';
export const COLLECTION_MOVE_DOCUMENT = 'COLLECTION_MOVE_DOCUMENT';
export const COLLECTION_UPDATE_MERGED_DOCUMENT = 'COLLECTION_UPDATE_MERGED_DOCUMENT';
export const COLLECTION_DOCUMENT_SET_INCLUDE_IN_ACTION = 'COLLECTION_DOCUMENT_SET_INCLUDE_IN_ACTION';
export const COLLECTION_REMOVE_DELETED_DOCUMENT = 'COLLECTION_REMOVE_DELETED_DOCUMENT';

// Shared Collections
export const SHARED_COLLECTIONS_LIST = 'SHARED_COLLECTIONS_LIST';
export const SHARED_COLLECTION_SELECT = 'SHARED_COLLECTION_SELECT';
export const SHARED_COLLECTION_UPDATE_LOCAL = 'SHARED_COLLECTION_UPDATE_LOCAL';
export const SHARED_COLLECTION_DELETE = 'SHARED_COLLECTION_DELETE';

// Personal Documents
export const SET_PERSONAL_DOCUMENTS = 'SET_PERSONAL_DOCUMENTS';

// Personal attachments
export const LIST_PERSONAL_ATTACHMENTS = 'LIST_PERSONAL_ATTACHMENTS';
export const LIST_MANY_PERSONAL_ATTACHMENTS = 'LIST_MANY_PERSONAL_ATTACHMENTS';
export const DELETE_PERSONAL_ATTACHMENT = 'DELETE_PERSONAL_ATTACHMENT';
export const RESET_PERSONAL_ATTACHMENTS = 'RESET_PERSONAL_ATTACHMENTS';

// Stripe
export const SET_STRIPE_SETUP_INTENT_CLIENT_SECRET = 'SET_STRIPE_SETUP_INTENT_CLIENT_SECRET';
export const SET_STRIPE_PRODUCTS = 'SET_STRIPE_PRODUCTS';
export const SET_STRIPE_SUBSCRIPTION_DATA = 'SET_STRIPE_SUBSCRIPTION_DATA';

// Private subscription
export const SET_PRIVATE_CUSTOMER = 'SET_PRIVATE_CUSTOMER';

// Emissions
export const SET_ALL_EMISSIONS = 'SET_ALL_EMISSIONS';
export const SET_SINGLE_EMISSION = 'SET_SINGLE_EMISSION';
export const SET_EMISSION_SHARE_TYPES = 'SET_EMISSION_SHARE_TYPES';
export const SET_EMISSION_BASIC_SHARE_DATA = 'SET_EMISSION_BASIC_SHARE_DATA';
export const SET_EMISSION_DOCUMENT_MAP = 'SET_EMISSION_DOCUMENT_MAP';
export const SET_EMISSION_CLOSURE_REPORT_FILE_INFO = 'SET_EMISSION_CLOSURE_REPORT_FILE_INFO';
export const SET_EMISSION_MARKETING_FILES = 'SET_EMISSION_MARKETING_FILES';
export const SET_EMISSION_MARKETING_FILE_PERMISSIONS = 'SET_EMISSION_MARKETING_FILE_PERMISSIONS';
export const DELETE_EMISSION_MARKETING_FILE = 'DELETE_EMISSION_MARKETING_FILE';
export const ADD_NEW_EMISSION = 'ADD_NEW_EMISSION';
export const SET_EMISSION_INVITEES = 'SET_EMISSION_INVITEES';
export const SET_EMISSION_INVITEE_NOTES = 'SET_EMISSION_INVITEE_NOTES';
export const SET_EMISSION_SUBSCRIPTION_SLIP = 'SET_EMISSION_SUBSCRIPTION_SLIP';
export const SET_EMISSION_SUBMISSIONS = 'SET_EMISSION_SUBMISSIONS';
export const SET_EMISSION_SUBMISSION_FORM_OPTIONS = 'SET_EMISSION_SUBMISSIONS_SHIT';
export const SET_EMISSION_INVITEE_INTERESTED = 'SET_EMISSION_INVITEE_INTERESTED';
export const SET_EMISSION_NUM_OF_INVITED_DEALFLOWS = 'SET_EMISSION_NUM_OF_INVITED_DEALFLOWS';
export const SET_PREPARATION_ERROR = 'SET_PREPARATION_ERROR';

// Emissions Statistics
export const SET_EMISSION_STATISTICS = 'SET_EMISSION_STATISTICS';

export const SET_EMISSION_MARKETING_DIAGRAM_PERMISSIONS = 'SET_EMISSION_MARKETING_DIAGRAM_PERMISSIONS';
export const SET_EMISSION_MARKETING_DIAGRAM_WHEN_TO_SHARE = 'SET_EMISSION_MARKETING_DIAGRAM_WHEN_TO_SHARE';
export const SET_EMISSION_MARKETING_DEALFLOW_DISPLAY_STATUS = 'SET_EMISSION_MARKETING_DEALFLOW_DISPLAY_STATUS';

// Company Contacts
export const COMPANY_CONTACTS_LIST = 'COMPANY_CONTACTS_LIST';
export const COMPANY_CONTACTS_CREATE = 'COMPANY_CONTACTS_CREATE';
export const COMPANY_CONTACTS_EDIT = 'COMPANY_CONTACTS_EDIT';
export const COMPANY_CONTACTS_DELETE = 'COMPANY_CONTACTS_DELETE';
export const COMPANY_CONTACTS_UPDATE_CACHE = 'COMPANY_CONTACTS_UPDATE_CACHE';
export const COMPANY_CONTACTS_CREATE_MULTIPLE = 'COMPANY_CONTACTS_CREATE_MULTIPLE';

// Members and investors
export const MEMBERS_AND_INVESTORS_LIST = 'MEMBERS_AND_INVESTORS_LIST';
export const MEMBERS_AND_INVESTORS_FILTER = 'MEMBERS_AND_INVESTORS_FILTER';

// Alias
export const ALIAS_CREATE = 'ALIAS_CREATE';
export const ALIAS_UPDATE = 'ALIAS_UPDATE';
export const ALIAS_DELETE = 'ALIAS_DELETE';

//Cap Table
export const CAP_TABLE_RESET_REDUCER = 'CAP_TABLE_RESET_REDUCER';

// Dealflow
export const DEALFLOW_ADD_COMPANY = 'DEALFLOW_ADD_COMPANY';
export const DEALFLOW_LIST = 'DEALFLOW_LIST';
export const DEALFLOW_CREATE_USER_CRITERIA = 'DEALFLOW_CREATE_USER_CRITERIA';
export const DEALFLOW_DELETE_USER_CRITERIA = 'DEALFLOW_DELETE_USER_CRITERIA';
export const DEALFLOW_LIST_USER_CRITERIA_LISTS = 'DEALFLOW_LIST_USER_CRITERIA_LISTS';
export const DEALFLOW_UPDATE_STATUS_USER_CRITERIA = 'DEALFLOW_UPDATE_STATUS_USER_CRITERIA';
export const DEALFLOW_UPDATE_USER_CRITERIA = 'DEALFLOW_UPDATE_USER_CRITERIA';
export const DEALFLOW_REMOVE_COMPANY = 'DEALFLOW_REMOVE_COMPANY';
export const DEALFLOW_COMPANY_CRITERIA = 'DEALFLOW_COMPANY_CRITERIA';
export const DEALFLOW_COMPANY_CHANGE_DISPLAY_STATUS = 'DEALFLOW_COMPANY_CHANGE_DISPLAY_STATUS';
export const DEALFLOW_COMPANY_LIST_DEALFLOW_FOLLOWERS = 'DEALFLOW_COMPANY_LIST_DEALFLOW_FOLLOWERS';

// Adverts
export const ADVERTISING_LIST_ADVERTS = 'ADVERTISING_LIST_ADVERTS';
export const ADVERTISING_CREATE_ADVERT = 'ADVERTISING_CREATE_ADVERT';
export const ADVERTISING_UPDATE_ADVERT = 'ADVERTISING_UPDATE_ADVERT';
export const ADVERTISING_DELETE_ADVERT = 'ADVERTISING_DELETE_ADVERT';
