import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import { list } from 'react-immutable-proptypes'
import { List } from 'immutable'
import styled from 'styled-components'

import { ButtonTransparentIcon } from '../button'
import Tooltip from '../tooltip/tooltip'

const StyledWrapper = styled.div`
	position: absolute;
	background: ${(props) => props.theme.colors.catalinaBlue};
	height: 40px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	top: ${(props) => props.top};
	right: -${(props) => (props.drawerSize - 1) * 44}px;
	transition: all 0.4s ease;

	:hover {
		right: 0px;
	}
`

const StyledItemWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	margin-left: 2px;

	button {
		margin-right: 4px;
		margin-left: 4px;
	}

	> button:first-child {
		cursor: default;
	}

	/* When styled wrapper is hovered, change color of the first icon to something darker */
	${StyledWrapper}:hover & {
		> button:first-child > span > span[class*='fa-'] {
			color: ${(props) => props.theme.colors.midGrey};
		}
	}
`

/**
 * Component configuration is provided by providing "drawer" List
 * of Objects. Following are the configuration options for each object:
 * - icon
 * - disabled
 * - onClick
 * - tooltipTid
 * - tooltipStates
 * - tooltipActiveState
 */

export default class IconDrawer extends PureComponent {
	static propTypes = {
		drawer: list,
		top: string
	}

	static defaultProps = {
		drawer: List(),
		top: '54px'
	}

	renderItem = (item, index) => {
		return (
			<Tooltip
				states={item.get('tooltipStates')}
				activeState={item.get('tooltipActiveState')}
				tid={item.get('tooltipTid')}
				key={index}>
				<ButtonTransparentIcon
					disabled={item.get('disabled', false)}
					icon={item.get('icon')}
					onClick={item.get('onClick')}
					color='lightestGrey'
					hoverColor='java'
					noHorizontalMargin={true}
				/>
			</Tooltip>
		)
	}

	render = () => {
		const { drawer, top } = this.props

		return (
			<StyledWrapper drawerSize={drawer.size} top={top}>
				<StyledItemWrapper>{drawer.map(this.renderItem)}</StyledItemWrapper>
			</StyledWrapper>
		)
	}
}
