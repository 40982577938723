import req from '../modules/request.module';
import { addErrorNotification } from './notify.actions';

export function fetchAllAdverts(callback) {
	return async function () {
		try {
			const response = await req.get('/gateway/adverts-admin/');
			callback(undefined, response.data);
		} catch (error) {
			callback(error);
		}
	};
}

export function fetchAdvert(companyId, advertId, callback) {
	return async function () {
		try {
			const response = await req.get(`/gateway/adverts-admin/${companyId}/${advertId}`);
			callback(undefined, response.data);
		} catch (error) {
			callback(error);
		}
	};
}

export function changeActive(advertId, active) {
	return async function (dispatch) {
		try {
			req.put(`/gateway/adverts-admin/${advertId}/active`, { active });
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'advertising.administration.errors.change_active_state' }));
		}
	};
}

export function changeStartDate(advertId, dateStart) {
	return async function (dispatch) {
		try {
			req.put(`/gateway/adverts-admin/${advertId}/date`, { dateStart });
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'advertising.administration.errors.change_date' }));
		}
	};
}

export function changeDuration(advertId, duration) {
	return async function (dispatch) {
		try {
			req.put(`/gateway/adverts-admin/${advertId}/duration`, { duration });
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'advertising.administration.errors.change_duration' }));
		}
	};
}

export function changeInvoiced(advertId, invoiced) {
	return async function (dispatch) {
		try {
			req.put(`/gateway/adverts-admin/${advertId}/invoiced`, { invoiced });
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'advertising.administration.errors.change_invoiced' }));
		}
	};
}

export function changePaid(advertId, paid) {
	return async function (dispatch) {
		try {
			req.put(`/gateway/adverts-admin/${advertId}/paid`, { paid });
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'advertising.administration.errors.change_paid' }));
		}
	};
}

export function changeWarning(advertId, hasDowngradedWithActiveAdvert) {
	return async function (dispatch) {
		try {
			req.put(`/gateway/adverts-admin/${advertId}/warning`, { hasDowngradedWithActiveAdvert });
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'advertising.administration.errors.change_warning' }));
		}
	};
}
