import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import Grid from 'styled-components-grid'
import { Padding } from 'styled-components-spacing'

import Alert from '../../../../../dumb-components/onboarding/alert'
import DismissControls from '../dismiss-controls.container'
import Text from '../../../../../dumb-components/shared/text/text'

import { getIsDismissed } from '../helpers'
const ALERT_ID = 'ad3b136b-86ba-4646-b772-9e428bd99926'

class CreateAgmHowToAlert extends Component {
	visistPage = () => {
		window.open('https://invono.se/moten/digital-bolagsstamma-arsstamma/', '_blank')
	}

	render = () => {
		const { isDismissed } = this.props

		if (isDismissed) {
			return null
		}

		const Link = (
			<Text bold={600} color='white' hasUnderline onClick={this.visistPage}>
				https://invono.se
			</Text>
		)

		return (
			<Grid>
				<Grid.Unit size={1}>
					<Padding all={3}>
						<Alert controlsComponent={<DismissControls alertId={ALERT_ID} />}>
							<Text color='white' tid='onb.alert.meetings.standard.general.create.agm.how.to' values={{ link: Link }} />
						</Alert>
					</Padding>
				</Grid.Unit>
			</Grid>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		isDismissed: getIsDismissed(store.user.get('userObj'), ALERT_ID)
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(CreateAgmHowToAlert)
