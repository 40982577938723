import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { Map, List, fromJS } from 'immutable'
import DocumentDetailsInformation from '../../dumb-components/documents/document-details-information/document-details-information'
import { listMeetingTemplates } from '../../actions/meeting-templates.actions'
import {
	fetchDocument,
	updateDocumentLocal,
	saveDocument,
	downloadDocument,
	downloadDocumentPublic,
	fetchDocuments
} from '../../actions/documents.actions'
import {
	fetchSharedDocument,
	saveSharedDocument,
	updateSharedDocumentLocal
} from '../../actions/documents-sharing.actions'
import { listTags } from '../../actions/tags.actions'
import { openModal } from '../../actions/modals.actions'
import Moment from '../../modules/moment.module'
import DocumentHelper from '../../components/helpers/documents.helper'
import TagsSelectContainer from '../shared/tags-select.container'
import ObjectPermissions from '../shared/object-permissions.contrainer'
import ShareholderPermissions from '../../dumb-components/documents/shareholder-permissions/shareholder-permissions-documents'
import CommentsListContainer from '../comments/comments-list.container'
import TasksPluginContainer from '../tasks/task-plugin.container'
import FieldsLoader from '../../dumb-components/shared/fields-loader/fields-loader'
import DocumentsMeetingsDocumentDetails from '../../dumb-components/documents/documents-meeting-document-details/documents-meeting-document-details'
import SharedDocumentInfoAlert from '../../dumb-components/documents/shared-doc-info-alert/shared-document-info-alert'
import NotifyAboutSignedDocumentContainer from './request-esign/notify-about-signed-doc.container'

import ESignSignatoriesPanelContainer from './esign-signatories-panel.container'

import DocumentsHelper from '../../components/helpers/documents.helper'
import history from '../../interfaces/history'
import { routeHoc } from '../../interfaces/router'

import { DOCUMENT_TASKS_CATEGORY_ID } from '../../constants/documents'
import { DOCUMENT_DOWNLOAD_SIGNED_MODAL } from '../../constants/modals'
import {
	EVENT_TYPE_DOCUMENT_UPDATE,
	EVENT_TYPE_DOCUMENT_DELETE,
	EVENT_TYPE_DOCUMENT_TRANSFER,
	EVENT_TYPE_DOCUMENT_SIGN,
	EVENT_TYPE_DOCUMENT_SIGNING_FINALIZED,
	LOCATION_COMPANY_DOCUMENTS,
	OBJ_TYPE_DOCUMENT
} from '/shared/constants'

class Documents extends Component {
	state = {
		documentNameSuggestions: [],
		readOnly: false,
		folder: null,
		meetingTemplates: List(),
		isFilePreviewDropdownOpen: false
	}

	static defaultProps = {
		doc: Map()
	}

	componentDidMount = () => {
		const { fetchDocument, listMeetingTemplates, match: { params }, listTags, doc, documents } = this.props

		if (doc) {
			this.parseDocument()
		}

		if (!doc || doc.get('id') !== params.id) {
			fetchDocument(params.id, true)
		}

		listTags()

		listMeetingTemplates()

		const documentNameSuggestions =
			documents &&
			documents
				.map((doc) => {
					return doc.get('title')
				})
				.toArray()

		this.setState({ documentNameSuggestions })
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { fetchDocument, match: { params }, folders, documents, doc, meetingTemplates } = this.props

		this.checkLiveUpdateEvents(prevProps)

		if (prevProps.match.params.id !== params.id) {
			fetchDocument(params.id, true)
		}

		// Added checking if folders exist and containt at least one element
		// as it was crashing when an document was selected and company
		// got switched using the hamburger menu.
		if (folders && folders.size > 0 && prevProps.folders !== folders) {
			this.parseDocument()
		}

		if (doc && prevProps.doc !== doc) {
			this.parseDocument()
		}

		if (
			(prevProps.documents && prevProps.documents.size) !== (documents && documents.size) ||
			prevProps.match.params.id !== params.id
		) {
			// Array[string] with document titles
			const documentNameSuggestions =
				documents &&
				documents
					.filter((doc) => {
						// Remove titles from list that have original filename
						return doc.getIn(['file', 'originalname']) !== doc.get('title')
					})
					.map((doc) => {
						return doc.get('title')
					})
					.toSet()
					.toArray() // toSet removes duplicates
			this.setState({ documentNameSuggestions })
		}

		if (prevProps.meetingTemplates !== meetingTemplates) {
			this.parseMeetingTemplates()
		}
	}

	parseMeetingTemplates = () => {
		const { meetingTemplates, meeting } = this.props
		let parsedMeetingTemplates = List()

		if (!meetingTemplates) {
			return
		}

		parsedMeetingTemplates = meetingTemplates.filter((obj) => obj.get('active'))

		if (meeting) {
			parsedMeetingTemplates = parsedMeetingTemplates.filter(
				(obj) =>
					obj.get('groupId') === meeting.get('groupId') || obj.get('id') === '1c99f80c-dca6-4998-8290-f9ddcffa34a0'
			)
		}

		parsedMeetingTemplates = parsedMeetingTemplates.map((obj) => Map({ value: obj.get('id'), label: obj.get('name') }))
		this.setState({ meetingTemplates: parsedMeetingTemplates })
	}

	parseDocument() {
		const { doc, folders, i18n, documentIsDeleted } = this.props

		const docFolderId = doc && doc.get('folderId')
		let folder = folders && folders.get(docFolderId)

		if (!folder) {
			folder = Map({
				id: 'root',
				name: i18n.messages['documents.folders.root']
			})
		}

		const readOnly = !(doc && doc.get('ALLOW_UPDATE')) || documentIsDeleted

		this.setState({ folder, readOnly })
	}

	onToggleMenu = () => {
		this.setState((prevState) => {
			return { isFilePreviewDropdownOpen: !prevState.isFilePreviewDropdownOpen }
		})
	}

	redirectAway = (documentId, folderId) => {
		const basePath = this.getComponentBasePath()
		if (folderId) {
			this.props.history.push({
				pathname: basePath + `/`,
				search: `?folder=${folderId}`
			})
		} else {
			this.props.history.push({
				pathname: basePath + `/`
			})
		}
	}

	checkLiveUpdateEvents = (prevProps) => {
		const { audit, match: { params }, fetchDocument, location, fetchSharedDocument, doc } = this.props
		const selectedDocumentId = params.id
		const querystr = location.search

		const DOCUMENT_UPDATE = audit.get(EVENT_TYPE_DOCUMENT_UPDATE)
		const DOCUMENT_DELETE = audit.get(EVENT_TYPE_DOCUMENT_DELETE)
		const DOCUMENT_TRANSFER = audit.get(EVENT_TYPE_DOCUMENT_TRANSFER)
		const DOCUMENT_SIGN = audit.get(EVENT_TYPE_DOCUMENT_SIGN)
		const DOCUMENT_FINALIZED = audit.get(EVENT_TYPE_DOCUMENT_SIGNING_FINALIZED)

		// Document was changed, update document
		if (DOCUMENT_UPDATE && DOCUMENT_UPDATE.get('r') === true) {
			const objId = DOCUMENT_UPDATE.get('objId')
			const isSharedObject = DOCUMENT_UPDATE.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = DOCUMENT_UPDATE.getIn(['metadata', 'objectOwnerCompanyId'])

			if (selectedDocumentId === objId) {
				if (isSharedObject) {
					fetchSharedDocument(objId, objectOwnerCompanyId, true)
				} else {
					fetchDocument(selectedDocumentId, true)
				}
			}
		}

		// Document was deleted, unselect if selected and update list
		if (DOCUMENT_DELETE && DOCUMENT_DELETE.get('r') === true) {
			const basePath = this.getComponentBasePath()
			const objId = DOCUMENT_DELETE.get('objId')

			if (selectedDocumentId === objId) {
				this.props.history.push({
					pathname: basePath,
					search: querystr
				})
			}
		}

		// Document that is currently being edited was transfered.
		if (DOCUMENT_TRANSFER && DOCUMENT_TRANSFER.get('r') === true) {
			const objId = DOCUMENT_TRANSFER.get('objId')
			const isSharedObject = DOCUMENT_TRANSFER.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = DOCUMENT_TRANSFER.getIn(['metadata', 'objectOwnerCompanyId'])

			if (selectedDocumentId === objId) {
				if (isSharedObject) {
					fetchSharedDocument(objId, objectOwnerCompanyId, true)
				} else {
					fetchDocument(selectedDocumentId, true)
				}
			}
		}

		// Document was signed by someone, fetch updated document
		if (DOCUMENT_SIGN && DOCUMENT_SIGN.get('r') === true && selectedDocumentId === DOCUMENT_SIGN.get('objId')) {
			const isSharedObject = DOCUMENT_SIGN.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = DOCUMENT_SIGN.getIn(['metadata', 'objectOwnerCompanyId'])

			if (isSharedObject) {
				fetchSharedDocument(selectedDocumentId, objectOwnerCompanyId, true)
			} else {
				fetchDocument(selectedDocumentId, true)
			}
		}

		// Document was signed by someone, fetch updated document
		if (
			DOCUMENT_FINALIZED &&
			DOCUMENT_FINALIZED.get('r') === true &&
			selectedDocumentId === DOCUMENT_FINALIZED.get('objId')
		) {
			const isSharedObject = DOCUMENT_FINALIZED.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = DOCUMENT_FINALIZED.getIn(['metadata', 'objectOwnerCompanyId'])

			if (isSharedObject) {
				fetchSharedDocument(selectedDocumentId, objectOwnerCompanyId, true)
			} else {
				fetchDocument(selectedDocumentId, true)
			}
		}
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('documents')
		return pathArray[0] + 'documents'
	}

	doDebounce = debounce((doc) => {
		const { saveDocument, isSharedDocument, saveSharedDocument, isSharedFromCompanyId } = this.props

		if (isSharedDocument) {
			saveSharedDocument(doc, isSharedFromCompanyId)
		} else {
			saveDocument(doc)
		}
	}, 1000)

	updateDocumentLocal = (doc) => {
		const { updateDocumentLocal, isSharedDocument, updateSharedDocumentLocal } = this.props

		if (isSharedDocument) {
			updateSharedDocumentLocal(doc)
		} else {
			updateDocumentLocal(doc)
		}

		if (this.validateDocument(doc)) {
			this.doDebounce(doc)
		}
	}

	onChange = (field, value) => {
		// Preven't crash on changing folder.
		// Can be related to the way we bind/trigger onChange in document details container
		// as onChange is sometimes triggered upon rendering.
		const { userId } = this.props
		let { doc } = this.props

		if (!doc || !field) {
			return
		}

		// Set eSignees to be notified
		if (field === 'eSignees') {
			// All eSignees were removed
			if (!value || !value.size) {
				doc = doc.updateIn(['shareWith'], (shareWith) => {
					return shareWith.filter((user) => !user.get('addedByEsignees'))
				})
			} else {
				// Value consists of eSignees
				const shareWith = DocumentHelper.covertSigneesObjectToShareWithObject(value)

				doc = doc.mergeIn(['shareWith'], shareWith)
				doc = doc.updateIn(['shareWith'], (shareWith) => {
					return shareWith.filter((user) => {
						const userId = user.get('userId')
						const addedByEsignees = user.get('addedByEsignees')
						const isActiveSignee = value.has(userId)

						if (addedByEsignees && !isActiveSignee) {
							return false
						}

						return true
					})
				})
			}
		}

		if (field === 'validTo' && value) {
			doc = doc.set('reminderDate', Moment(value).toISOString())
			doc = doc.set('reminderSet', true)
		}

		// Set reminderSet to true if reminderDate got value
		if (field === 'reminderDate' && value) {
			doc = doc.set('reminderSet', true)
		}

		// Update document data
		doc = doc.set(field, value)

		this.updateDocumentLocal(doc)
	}

	onDownloadFile = () => {
		const { doc, downloadDocument, openModal, downloadDocumentPublic, userId } = this.props
		const isSigned = DocumentHelper.getDocumentIsSigned(doc)
		if (isSigned) {
			openModal(DOCUMENT_DOWNLOAD_SIGNED_MODAL, { mode: 'DOWNLOAD', document: doc })
			return
		}
		const isSharedFromCompanyId = doc.get('isSharedFromCompanyId')
		if (isSharedFromCompanyId) {
			const companyId = isSharedFromCompanyId
			downloadDocumentPublic({ documentId: doc.get('id'), companyId, userId })
		} else {
			downloadDocument({ documentId: doc.get('id') })
		}
	}

	onOpenFile = () => {
		const { doc, downloadDocument, downloadDocumentPublic, openModal, userId } = this.props
		const isSigned = DocumentHelper.getDocumentIsSigned(doc)
		if (isSigned) {
			openModal(DOCUMENT_DOWNLOAD_SIGNED_MODAL, { mode: 'VIEWER', document: doc })
			return
		}

		const isSharedFromCompanyId = doc.get('isSharedFromCompanyId')
		if (isSharedFromCompanyId) {
			const companyId = isSharedFromCompanyId
			downloadDocumentPublic({ documentId: doc.get('id'), openInViewer: true, companyId, userId })
		} else {
			downloadDocument({ documentId: doc.get('id'), openInViewer: true })
		}
	}

	onOpenProtocol = () => {
		const { company, doc } = this.props
		const { urlAlias } = company
		const docId = doc.get('id')

		window.open(`/${urlAlias}/protocols/${docId}`, '_blank')
	}

	validateDocument = () => {
		return true
	}

	renderShareholderPermission = () => {
		const { doc, folders, activeBlock } = this.props

		if (!doc) {
			return
		}

		// Don't render if current document doesn't belong to a folder
		if (doc && !doc.get('folderId')) {
			return
		}

		// Get ID of folder that doc belongs to
		const documentFolderId = doc.get('folderId')

		// Find current documents folder based on folders ID found in the doc object
		const currentFolder = folders.find((folder) => folder.get('id') === documentFolderId)

		// Don't render if folder is NOT mirrored
		if (currentFolder && !currentFolder.get('isMirrored')) {
			return
		}

		return (
			<ShareholderPermissions
				onChange={this.onChange}
				isMirrored={doc && doc.get('isMirrored', false)}
				selected={activeBlock === 'shareholderPermissions'}
				labelTid='documents.manage_document.shareholder.permissions.label'
			/>
		)
	}

	renderObjectPermissions = () => {
		const { doc, activeBlock } = this.props

		return (
			<ObjectPermissions
				closeTrigger={doc && doc.get('id')}
				permissions={doc && doc.get('permissions')}
				onChange={this.onChange}
				selected={activeBlock === 'permissions'}
				marginBottom={true}
			/>
		)
	}

	renderComments = () => {
		const { doc, activeBlock, documentIsDeleted } = this.props

		if (documentIsDeleted) {
			return null
		}

		return (
			<CommentsListContainer
				objType={OBJ_TYPE_DOCUMENT}
				objId={doc && doc.get('id')}
				objTitle={doc && doc.get('title')}
				objUrl={window.location.href}
				selected={activeBlock === 'comments'}
			/>
		)
	}

	renderTagsSelectContainer = () => {
		const { doc } = this.props
		const { readOnly } = this.state

		return (
			<TagsSelectContainer
				onTagsChange={(tags) => this.onChange('tags', tags)}
				value={doc && doc.get('tags')}
				readOnly={readOnly}
			/>
		)
	}

	renderMeetingDocument = () => {
		const { meetingTemplates } = this.state
		const { doc, i18n, userId } = this.props
		const templateOptions = meetingTemplates.concat(
			fromJS([{ value: 'LEGACY', label: i18n.messages['meeting.general.template_option.legacy'] }])
		)

		return (
			<DocumentsMeetingsDocumentDetails
				document={doc}
				templateOptions={templateOptions}
				onOpenProtocol={this.onOpenProtocol}
				userId={userId}
				language={i18n.language}
			/>
		)
	}

	renderExternalTasksList = () => {
		const { doc, documentIsDeleted } = this.props
		const { readOnly } = this.state

		if (!doc || documentIsDeleted) {
			return null
		}

		const folderId = doc.get('folderId')
		const objId = doc.get('id')

		return (
			<TasksPluginContainer
				objType={OBJ_TYPE_DOCUMENT}
				projectId={folderId ? folderId : DOCUMENT_TASKS_CATEGORY_ID}
				objId={objId}
				readOnly={readOnly}
				marginBottom={true}
			/>
		)
	}

	renderShareWithComponent = () => {
		return <NotifyAboutSignedDocumentContainer />
	}

	renderESignatories = () => {
		return (
			<ESignSignatoriesPanelContainer
				onChange={this.onChange}
				objType={OBJ_TYPE_DOCUMENT}
				skipFilesView={true}
				renderShareWithComponent={this.renderShareWithComponent}
			/>
		)
	}

	render = () => {
		const { doc, i18n, activeBlock, documentIsDeleted, isSharedDocument } = this.props
		const { readOnly, documentNameSuggestions, isFilePreviewDropdownOpen } = this.state

		if (!doc) {
			return <FieldsLoader />
		}

		const file = doc && doc.get('file')
		const showViewer = DocumentsHelper.isViewableInBrowser(file)

		// Render meeting document
		if (doc && doc.get('isMeeting')) {
			return this.renderMeetingDocument()
		}

		/* TODO: Replace div with styled layout component  */
		return (
			<div style={{ flex: 1 }}>
				<DocumentDetailsInformation
					isSelected={activeBlock === 'details'}
					document={doc}
					documentNameSuggestions={documentNameSuggestions}
					isLoading={!doc ? true : false}
					onChange={this.onChange}
					onDownloadFile={this.onDownloadFile}
					onOpenFile={showViewer ? this.onOpenFile : null}
					onToggleMenu={this.onToggleMenu}
					isFilePreviewDropdownOpen={isFilePreviewDropdownOpen}
					userLang={i18n.language}
					readOnly={readOnly}
					renderTagsSelectContainer={!isSharedDocument && this.renderTagsSelectContainer}
					isDeleted={documentIsDeleted}
				/>

				{!isSharedDocument && !(readOnly && !doc.get('eSigning')) && this.renderESignatories()}
				{!isSharedDocument && !readOnly && this.renderObjectPermissions()}
				{!isSharedDocument && !readOnly && this.renderShareholderPermission()}
				{!isSharedDocument && this.renderExternalTasksList()}
				{!isSharedDocument && this.renderComments()}
				{isSharedDocument && <SharedDocumentInfoAlert readOnly={readOnly} />}
			</div>
		)
	}
}

function mapStoreToProps(store) {
	return {
		history: history,
		doc: store.documents.get('document', Map()),
		documents: store.documents.get('visibleDocuments'),
		folders: store.folders.get('folders'),
		company: store.company.company,
		i18n: store.i18n,
		selectedFolderId: store.folders.get('selectedFolderId'),
		audit: store.audit.get('documents'),
		meetingTemplates: store.meetingTemplates.get('list'),
		userId: store.user.getIn(['userObj', 'id']),
		documentIsDeleted: store.documents.getIn(['document', 'isDeleted']),
		isSharedDocument: Boolean(store.documents.getIn(['document', 'isSharedFromCompanyId'])),
		isSharedFromCompanyId: store.documents.getIn(['document', 'isSharedFromCompanyId'])
	}
}

const mapActionsToProps = {
	fetchDocument,
	updateDocumentLocal,
	saveDocument,
	downloadDocument,
	downloadDocumentPublic,
	listTags,
	listMeetingTemplates,
	fetchDocuments,
	openModal,
	fetchSharedDocument,
	saveSharedDocument,
	updateSharedDocumentLocal
}

export default connect(mapStoreToProps, mapActionsToProps)(routeHoc(Documents, { routeName: LOCATION_COMPANY_DOCUMENTS }))
