import React, { Component } from 'react'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, fromJS } from 'immutable'
import { fetchCoreProducts, updateCompanyProducts } from '../../actions/core-products.actions'
import ProductsList from '../../dumb-components/subscriptions/products-list/products-list'

class CoreProductsListContainer extends Component {
	static propTypes = {
		coreProducts: ImmutablePropTypes.list,
		companyProducts: ImmutablePropTypes.list
	}

	componentDidMount = () => {
		const { fetchCoreProducts } = this.props

		fetchCoreProducts()
	}

	onChange = (productId, val) => {
		const { updateCompanyProducts, company } = this.props

		updateCompanyProducts(company.id, productId, val)
	}

	render = () => {
		const { coreProducts, company } = this.props
		const companyProducts = company ? fromJS(company.products) : null
		const products = coreProducts.map((product) => {
			product = product.set('descriptionTid', `${product.get('tid')}.description`)
			return product
		})

		if (company && company.toBeCanceledAt) {
			return null
		}

		return <ProductsList products={products} values={companyProducts} onChange={this.onChange} />
	}
}

const mapStoreToProps = (store) => {
	return {
		coreProducts: store.coreProducts.get('coreProducts', List()),
		company: store.company.company
	}
}

const mapActionsToProps = {
	fetchCoreProducts,
	updateCompanyProducts
}

export default connect(mapStoreToProps, mapActionsToProps)(CoreProductsListContainer)
