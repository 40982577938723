import React, { PureComponent } from 'react'
import { map, list } from 'react-immutable-proptypes'
import { string, func } from 'prop-types'
import styled, { css } from 'styled-components'
import { Map } from 'immutable'
import ListItem from '../../shared/list-item/list-item'
import StyledList from '../../shared/list/list'
import Text from '../../shared/text/text'
import Task from '../../tasks/task/task'
import LineSeparator from '../../shared/line-separator/line-separator'
import Badge from '../../shared/badge/badge'
import NewListItemButton from '../../shared/new-list-item-button/new-list-item-button'

const StyledListItem = styled(ListItem)`
	margin-top: ${(props) => props.theme.spacing[2]};
	margin-bottom: 0px;

	:first-child {
		margin-top: 0px;
	}
`

const StyledTaskSectionWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`

const LineSeparatorWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[5]};
`

const StyledDocumentItemTemplate = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
`

const StyledDocumentItem = styled.div`
	${StyledDocumentItemTemplate};
	border: 1px solid ${(props) => props.theme.colors.solitudeDark};
	width: 100%;
`

const StyledTransparentListItem = styled(StyledListItem)`
	background-color: transparent;
`

const StyledEmptyMessageItem = styled(StyledDocumentItem)`
	${StyledDocumentItemTemplate};
	border: none;
`

const StyledEmptyMessage = styled.div`
	flex: 1;
	padding-left: ${(props) => props.theme.spacing[4]};
	padding-right: ${(props) => props.theme.spacing[4]};
	text-align: center;
`

export default class TaskList extends PureComponent {
	static propTypes = {
		basePath: string,
		tasksMetadata: map,
		currentUrl: string,
		onTaskClick: func,
		tasks: map,
		mapObjIdToTasks: map,
		flatAgendaItems: list,
		renderTaskDropdown: func
	}

	renderBadge = (taskId) => {
		const { tasks, onBadgeClick } = this.props

		let allTasks = Map()
		tasks.forEach((item) => {
			allTasks = allTasks.merge(item)
		})

		const task = allTasks.find((obj) => obj.get('id') === taskId)
		const proposal = task.get('proposal')

		return (
			<Badge linkText={proposal} onClick={onBadgeClick.bind(null, taskId)}>
				{proposal}
			</Badge>
		)
	}

	renderTask = (task, index) => {
		const {
			basePath,
			tasksMetadata,
			currentUrl,
			onTaskClick,
			taskInEditMode,
			taskEditorComponent,
			renderTaskDropdown
		} = this.props
		const taskId = task.get('id')
		const to = `${basePath}/${taskId}`
		const isEditing = taskInEditMode === task.get('id')

		return (
			<Task
				key={index}
				isDragDisabled={true}
				taskId={taskId}
				title={task.get('title')}
				status={task.get('status')}
				dueAt={task.get('dueAt')}
				orderIndex={task.get('orderIndex')}
				profileImage={tasksMetadata.getIn([taskId, 'profileImage'])}
				assignedToUserId={tasksMetadata.getIn([taskId, 'assignedToUserId'])}
				index={index}
				active={currentUrl === to}
				onTaskClick={onTaskClick}
				withDnd={false}
				renderBadge={this.renderBadge}
				isEditing={isEditing}
				taskEditorComponent={isEditing && taskEditorComponent ? taskEditorComponent : null}
				renderDropdown={renderTaskDropdown}
			/>
		)
	}

	renderGeneralSection = (tasks) => {
		const { onClickAddNewTask } = this.props
		const newTaskButtonTid = tasks && tasks.size > 0 ? 'tasks.list.add_another_task' : 'tasks.list.add_new_task'

		return (
			<StyledTaskSectionWrapper>
				{tasks && tasks.toList().map(this.renderTask)}
				<StyledTransparentListItem>
					<NewListItemButton
						onClick={() => {
							onClickAddNewTask && onClickAddNewTask(null, null)
						}}
						tid={newTaskButtonTid}
					/>
				</StyledTransparentListItem>
			</StyledTaskSectionWrapper>
		)
	}

	renderTopicsSection = (tasks) => {
		if (!tasks || tasks.size === 0) {
			return null
		}

		return <StyledTaskSectionWrapper>{tasks.toList().map(this.renderTask)}</StyledTaskSectionWrapper>
	}

	render = () => {
		const { tasks } = this.props

		return (
			<StyledList>
				{this.renderGeneralSection(tasks.get('general'))}
				<LineSeparator bgColor='solitudeLight' tid='meetings.tasks.tasks_in_topics' margin />
				{this.renderTopicsSection(tasks.get('topics'))}
			</StyledList>
		)
	}
}
