import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { bool, func, oneOf, number } from 'prop-types'
import Text from '../shared/text/text'
import { AnimatedButtonToggle } from '../shared/animated-button-toggle'

const StyledTopComponent = styled.div`
	display: flex;
	flex-direction: column;
	> * {
		margin: ${(props) => props.theme.spacing[3]} 0;
	}
`

export default class TopComponent extends PureComponent {
	state = {
		sectionTid: null
	}

	static propTypes = {
		onChange: func,
		authMode: number,
		section: oneOf(['SIGNIN', 'SIGNUP', 'FORGOTPW'])
	}

	static defaultProps = {
		authMode: 0
	}

	componentDidMount = () => {
		this.setSectionTid()
	}

	componentDidUpdate = (prevProps) => {
		const { section } = this.props

		if (prevProps.section !== section) {
			this.setSectionTid()
		}
	}

	setSectionTid = () => {
		const { section } = this.props
		let sectionTid

		if (section === 'SIGNIN') {
			sectionTid = 'credentials.top.section.signin'
		} else if (section === 'SIGNUP') {
			sectionTid = 'credentials.top.section.signup'
		} else {
			sectionTid = 'credentials.top.section.forgotpw'
		}

		this.setState({ sectionTid })
	}

	getAnimatedBtnIsDisabled = () => {
		const { section } = this.props
		return section === 'FORGOTPW'
	}

	renderLeftTab = () => {
		const { authMode } = this.props
		const tid = 'credentials.top.authmode.email'

		if (authMode === 0) {
			return <Text bold={600} tid={tid} color='white' />
		}

		return (
			<div>
				<Text tid='credentials.top.authmode.switch_to' color='white' />
				<Text bold={600} tid={tid} color='white' />
			</div>
		)
	}

	renderRightTab = () => {
		const { authMode } = this.props
		const tid = 'credentials.top.authmode.bankId'

		if (authMode === 1) {
			return <Text bold={600} tid={tid} color='white' />
		}

		return (
			<div>
				<Text tid='credentials.top.authmode.switch_to' color='white' />
				<Text bold={600} tid={tid} color='white' />
			</div>
		)
	}

	render = () => {
		const { onChange, authMode } = this.props
		const { sectionTid } = this.state

		return (
			<StyledTopComponent>
				<Text tid={sectionTid} color='white' align='center' tag='h2' />
				<AnimatedButtonToggle
					themeName='formRounded'
					leftTabRenderer={this.renderLeftTab}
					rightTabRenderer={this.renderRightTab}
					value={authMode}
					onChange={onChange}
					disabled={this.getAnimatedBtnIsDisabled()}
				/>
			</StyledTopComponent>
		)
	}
}
