import { fromJS, List, Map } from 'immutable'

import {
	DOCUMENTS_LIST,
	DOCUMENTS_LIST_PAGINATED,
	DOCUMENTS_UPDATE_LOCAL,
	DOCUMENTS_CREATE_REMOTE,
	DOCUMENTS_FETCH_REMOTE,
	DOCUMENTS_SAVE_REMOTE_START,
	DOCUMENTS_SAVE_REMOTE,
	DOCUMENTS_DELETE_REMOTE,
	DOCUMENTS_DOCUMENT_DIRTY,
	DOCUMENTS_UPDATE_FOLDER,
	DOCUMENTS_MIRRORED_FETCH_REMOTE,
	DOCUMENTS_SET_FILTERS,
	DOCUMENTS_UNSET_FILTERS,
	DOCUMENTS_CREATE_LOCAL,
	COMPANY_RESET_REDUCER,
	DOCUMENTS_SELECT,
	DOCUMENTS_CLEAR_LOCAL,
	DOCUMENTS_SET_VISIBLE,
	DOCUMENTS_SET_DEFAULT_FILTERS,
	DOCUMENTS_UPDATE_DEFAULT_FILTER,
	DOCUMENTS_RESET_DEFAULT_VALUES,
	DOCUMENTS_LIST_BY_COMPANIES,
	DOCUMENTS_HARD_DELETE,
	DOCUMENTS_FETCH_HELPER,
	DOCUMENTS_HARD_DELETE_LOCAL,
	SHARING_DOCUMENTS_LIST,
	SHARING_DOCUMENTS_CREATE_LOCAL,
	SHARING_DOCUMENTS_UPDATE_LOCAL,
	SHARING_DOCUMENTS_HARD_DELETE_LOCAL,
	SHARING_DOCUMENTS_DELETE_REMOTE,
	SHARING_DOCUMENTS_SAVE_REMOTE,
	DOCUMENTS_FETCH_DOCUMENTS_TO_MERGE,
	DOCUMENTS_PREPEND_DOCUMENT_TO_MERGE,
	DOCUMENTS_TO_SAVE_SAVE_REMOTE,
	DOCUMENTS_CLEAR_DOCUMENTS_TO_MERGE,
	DOCUMENTS_UPDATE_NUMBER_OF_SHARE_WITH,
	DOCUMENTS_ADD_DOCUMENT_TO_LIST,
	DOCUMENTS_CLEAR_PAGINATION,
	DOCUMENTS_UPDATE_PAGINATED_DOCUMENTS,
	DOCUMENTS_UPDATE_DOCUMENT_IN_LIST
} from '../actions/types'

import { FACTORY_DEFAULT_FILTER_VALUES } from '../constants/documents'

const INITIAL_STATE = fromJS({
	allDocuments: null,
	visibleDocuments: null,
	paginatedDocuments: {
		documents: [],
		totalNumOfDocuments: 0
	},
	documentsExcludedFromFiltering: [],
	document: null,
	documentIdMapToIndex: {},
	hasAppliedFilters: false,
	isDirty: false,
	filterBy: [
		{ source: 'dateProp', values: FACTORY_DEFAULT_FILTER_VALUES.get('dateProp') },
		{ source: 'order', values: FACTORY_DEFAULT_FILTER_VALUES.get('order') },
		{ source: 'tag', values: [], isSimpleFilter: true },
		{ source: 'docStatus', values: FACTORY_DEFAULT_FILTER_VALUES.get('docStatus') },
		{ source: 'permissions', values: FACTORY_DEFAULT_FILTER_VALUES.get('permissions') },
		{ source: 'showOnlyTrashed', values: FACTORY_DEFAULT_FILTER_VALUES.get('showOnlyTrashed') }
	],
	defaultFilters: FACTORY_DEFAULT_FILTER_VALUES,
	mirroredDocuments: [],
	listByCompanies: null,
	helper: null,
	documentsToMerge: []
	/*numOfShareholdersToShareWith: 0,
	numOfAttendeesToShareWith: 0,
	numOfOtherPersonsToShareWith: 0*/
})

const createDocumentIdMapToIndex = (docs) => {
	let map = Map()
	docs &&
		docs.forEach((d, i) => {
			map = map.set(d.get('id'), i)
		})
	return map
}

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case DOCUMENTS_ADD_DOCUMENT_TO_LIST: {
			const allDocuments = state.get('allDocuments')

			if (allDocuments && allDocuments.size > 0) {
				state = state.update('allDocuments', (allDocuments) => {
					return allDocuments.push(payload)
				})
				return state.set('documentIdMapToIndex', createDocumentIdMapToIndex(state.get('allDocuments')))
			}

			state = state.set('allDocuments', List([payload]))
			return state.set('documentIdMapToIndex', createDocumentIdMapToIndex(state.get('allDocuments')))
		}
		case DOCUMENTS_LIST:
			state = state.set('allDocuments', payload)
			if (payload && payload.size !== 0) {
				state = state.set('documentIdMapToIndex', createDocumentIdMapToIndex(payload))
			}
			return state

		case DOCUMENTS_LIST_PAGINATED: {
			const { documents, totalNumOfDocuments } = payload
			let paginatedDocuments = state.getIn(['paginatedDocuments', 'documents'])
			paginatedDocuments = paginatedDocuments.concat(documents)
			state = state.setIn(['paginatedDocuments', 'documents'], paginatedDocuments)
			state = state.setIn(['paginatedDocuments', 'totalNumOfDocuments'], totalNumOfDocuments)
			return state
		}

		case DOCUMENTS_CLEAR_PAGINATION: {
			return state.update('paginatedDocuments', (paginatedDocuments) => {
				paginatedDocuments = paginatedDocuments.set('documents', List())
				paginatedDocuments = paginatedDocuments.set('totalNumOfDocuments', 0)
				return paginatedDocuments
			})
		}

		case DOCUMENTS_UPDATE_PAGINATED_DOCUMENTS: {
			const documentId = payload.get('id')
			return state.updateIn(['paginatedDocuments', 'documents'], (documents) => {
				return documents.map((doc) => {
					if (doc.get('id') === documentId) {
						doc = payload
					}

					return doc
				})
			})
		}

		case DOCUMENTS_SET_VISIBLE:
			state = state.set('hasAppliedFilters', payload.hasAppliedFilters)
			return state.set('visibleDocuments', payload.documents)

		case DOCUMENTS_UPDATE_LOCAL: {
			if (payload) {
				const index = state.getIn(['documentIdMapToIndex', payload.get('id')])
				if (index >= 0) {
					state = state.setIn(['allDocuments', index], payload)
				}
			}

			return state.set('document', payload)
		}
		case DOCUMENTS_CREATE_LOCAL: {
			let allDocuments = state.get('allDocuments')

			if (allDocuments) {
				allDocuments = allDocuments.insert(0, payload)
				state = state.set('documentIdMapToIndex', createDocumentIdMapToIndex(allDocuments))
				state = state.update('documentsExcludedFromFiltering', (docs) => docs.push(payload.get('id')))

				const updatedState = state.set('allDocuments', allDocuments)
				return updatedState.set('document', payload)
			}

			return state.set('document', payload)
		}
		case DOCUMENTS_CREATE_REMOTE: {
			let allDocuments = state.get('allDocuments')

			if (allDocuments) {
				allDocuments = allDocuments.insert(0, payload)
				const updatedState = state.set('allDocuments', allDocuments)
				return updatedState.set('document', payload)
			}

			return state.set('document', payload)
		}
		case DOCUMENTS_FETCH_REMOTE: {
			if (!payload) {
				return state.set('document', payload)
			}

			const index = state.getIn(['documentIdMapToIndex', payload.get('id')])

			if (index >= 0) {
				state = state.setIn(['allDocuments', index], payload)
			}

			return state.set('document', payload)
		}
		case DOCUMENTS_CLEAR_LOCAL:
			return state.set('document', null)
		case DOCUMENTS_SAVE_REMOTE_START:
			if (payload.get('id') === state.getIn(['document', 'id'])) {
				return state.set('isDirty', false)
			}
			return state.set('isDirty', true)
		case DOCUMENTS_SAVE_REMOTE: {
			if (state.get('isDirty')) {
				return state
			}
			const index = state.getIn(['documentIdMapToIndex', payload.get('id')])
			if (index >= 0) {
				state = state.setIn(['allDocuments', index], payload)
			}
			return state.set('document', payload)
		}
		case DOCUMENTS_DELETE_REMOTE: {
			const hideFromDocumentsSection = payload.get('hideFromDocumentsSection')
			let allDocuments = state.get('allDocuments')
			const index = state.getIn(['documentIdMapToIndex', payload.get('id')])

			if (allDocuments && index >= 0) {
				if (hideFromDocumentsSection) {
					allDocuments = allDocuments.remove(index)
				} else {
					allDocuments = allDocuments.set(index, payload)
				}

				state = state.set('allDocuments', allDocuments)
				state = state.set('documentIdMapToIndex', createDocumentIdMapToIndex(allDocuments))
			}

			state = state.update('documentsExcludedFromFiltering', (documentsExcludedFromFiltering) =>
				documentsExcludedFromFiltering.filter((docId) => docId !== payload.get('id'))
			)

			if (state.getIn(['document', 'id']) === payload.get('id')) {
				return state.set('document', null)
			}

			return state
		}

		case DOCUMENTS_HARD_DELETE_LOCAL: {
			let allDocuments = state.get('allDocuments')
			let index

			allDocuments.forEach((doc, i) => {
				if (doc.get('id') === payload) {
					index = i
				}
			})

			if (allDocuments) {
				allDocuments = allDocuments.remove(index)
				state = state.set('allDocuments', allDocuments)
				state = state.set('documentIdMapToIndex', createDocumentIdMapToIndex(allDocuments))
			}

			state = state.update('documentsExcludedFromFiltering', (documentsExcludedFromFiltering) =>
				documentsExcludedFromFiltering.filter((docId) => docId !== payload)
			)
			return state.set('document', null)
		}

		case DOCUMENTS_HARD_DELETE: {
			let found = false
			const documentsToDelete = payload.get('documentsToDelete')
			const currentSelectedDocumentId = payload.get('currentSelectedDocumentId')

			state = state.update('allDocuments', (allDocuments) => {
				return allDocuments.filter((document) => {
					if (document.get('id') === currentSelectedDocumentId) {
						found = true
					}

					return !documentsToDelete.includes(document.get('id'))
				})
			})
			state = state.set('documentsIdMapToIndex', createDocumentIdMapToIndex(state.get('allDocuments')))

			if (found) {
				return state.set('document', null)
			}

			return state
		}

		case DOCUMENTS_DOCUMENT_DIRTY:
			return state.set('isDirty', true)

		case DOCUMENTS_UPDATE_FOLDER: {
			payload.get('documentIds').forEach((docId) => {
				const documentIndex = state.getIn(['documentIdMapToIndex', docId])
				state = state.setIn(['allDocuments', documentIndex, 'folderId'], payload.get('folderId'))
			})

			state = state.setIn(['document', 'folderId'], payload.get('folderId'))
			return state
		}
		case DOCUMENTS_MIRRORED_FETCH_REMOTE:
			return state.set('mirroredDocuments', payload)

		case DOCUMENTS_SET_FILTERS: {
			const source = payload.get('source')
			const values = payload.get('values')
			const isSimpleFilter = payload.get('isSimpleFilter')
			let filterBy = state.get('filterBy')
			let sourceRemoved = false

			// Remove previous filter criteria when filitering in simple filter
			if (isSimpleFilter) {
				filterBy = filterBy.filter((obj) => !obj.get('isSimpleFilter'))
				// Remove filter criteria if value is null (null == value have been cleared in advanced filter)
			} else if (!values) {
				filterBy = filterBy.filter((obj) => obj.get('source') !== source)
				sourceRemoved = true
			}

			const index = filterBy.findIndex((obj) => obj.get('source') === source)

			if (index >= 0) {
				filterBy = filterBy.set(index, payload)
			} else if (!sourceRemoved) {
				filterBy = filterBy.push(payload)
			}

			state = state.set('documentsExcludedFromFiltering', List())
			return state.set('filterBy', filterBy)
		}

		case DOCUMENTS_UNSET_FILTERS: {
			const sources = payload
			const defaultFilters = state.get('defaultFilters')
			let filterBy = state.get('filterBy')

			filterBy = filterBy.filter((filter) => {
				let keep = false

				sources.forEach((source) => {
					if (source === filter.get('source') || filter.get('isSimpleFilter')) {
						if (defaultFilters.has(source)) {
							keep = true
						}
					}
				})

				return keep
			})

			filterBy = filterBy.map((filter) => {
				sources.forEach((source) => {
					if (source === filter.get('source')) {
						const defaultFilter = defaultFilters.get(source)
						if (defaultFilter) {
							filter = filter.set('values', defaultFilter)
						}
					}
				})

				return filter
			})

			return state.set('filterBy', filterBy)
		}

		case DOCUMENTS_SET_DEFAULT_FILTERS: {
			const filters = payload
			let defaultFilters = state.get('defaultFilters')

			filters.forEach((values, key) => {
				defaultFilters = defaultFilters.set(key, values)
			})

			return state.set('defaultFilters', defaultFilters)
		}

		case DOCUMENTS_UPDATE_DEFAULT_FILTER: {
			const source = payload.get('source')
			const values = payload.get('values')
			return state.setIn(['defaultFilters', source], values)
		}

		case DOCUMENTS_RESET_DEFAULT_VALUES: {
			let filterBy = state.get('filterBy')

			filterBy = filterBy.map((filter) => {
				const source = filter.get('source')

				if (FACTORY_DEFAULT_FILTER_VALUES.has(source)) {
					filter = filter.set('values', FACTORY_DEFAULT_FILTER_VALUES.get(source))
				}

				return filter
			})

			state = state.set('filterBy', filterBy)
			return state.set('defaultFilters', FACTORY_DEFAULT_FILTER_VALUES)
		}

		case DOCUMENTS_SELECT: {
			const index = state.getIn(['documentIdMapToIndex', payload])
			let document = state.getIn(['allDocuments', index])
			if (!document) {
				document = state.getIn(['sharedDocuments', index])
			}
			return state.set('document', document)
		}
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}

		case DOCUMENTS_LIST_BY_COMPANIES:
			return state.set('listByCompanies', payload)

		case DOCUMENTS_FETCH_HELPER:
			return state.set('helper', payload)

		case SHARING_DOCUMENTS_LIST:
			state = state.set('sharedDocuments', payload)
			state = state.set('documentIdMapToIndex', createDocumentIdMapToIndex(payload))
			return state

		case SHARING_DOCUMENTS_CREATE_LOCAL: {
			let sharedDocuments = state.get('sharedDocuments')

			if (sharedDocuments) {
				sharedDocuments = sharedDocuments.insert(0, payload)
				state = state.set('documentIdMapToIndex', createDocumentIdMapToIndex(sharedDocuments))
				state = state.update('documentsExcludedFromFiltering', (docs) => docs.push(payload.get('id')))

				const updatedState = state.set('sharedDocuments', sharedDocuments)
				return updatedState.set('document', payload)
			}

			return state.set('document', payload)
		}

		case SHARING_DOCUMENTS_UPDATE_LOCAL: {
			if (payload) {
				const index = state.getIn(['documentIdMapToIndex', payload.get('id')])
				if (index >= 0) {
					state = state.setIn(['sharedDocuments', index], payload)
				}
			}

			return state.set('document', payload)
		}

		case SHARING_DOCUMENTS_HARD_DELETE_LOCAL: {
			let sharedDocuments = state.get('sharedDocuments')
			let index

			sharedDocuments.forEach((doc, i) => {
				if (doc.get('id') === payload) {
					index = i
				}
			})

			if (sharedDocuments) {
				sharedDocuments = sharedDocuments.remove(index)
				state = state.set('sharedDocuments', sharedDocuments)
				state = state.set('documentIdMapToIndex', createDocumentIdMapToIndex(sharedDocuments))
			}

			state = state.update('documentsExcludedFromFiltering', (documentsExcludedFromFiltering) =>
				documentsExcludedFromFiltering.filter((docId) => docId !== payload)
			)
			return state.set('document', null)
		}

		case SHARING_DOCUMENTS_DELETE_REMOTE: {
			let sharedDocuments = state.get('sharedDocuments')
			const index = state.getIn(['documentIdMapToIndex', payload])

			if (sharedDocuments) {
				sharedDocuments = sharedDocuments.setIn([index, 'isDeleted'], true)
				state = state.set('sharedDocuments', sharedDocuments)
				state = state.set('documentIdMapToIndex', createDocumentIdMapToIndex(sharedDocuments))
			}

			state = state.update('documentsExcludedFromFiltering', (documentsExcludedFromFiltering) =>
				documentsExcludedFromFiltering.filter((docId) => docId !== payload)
			)
			return state.set('document', null)
		}

		case SHARING_DOCUMENTS_SAVE_REMOTE: {
			if (state.get('isDirty')) {
				return state
			}
			const index = state.getIn(['documentIdMapToIndex', payload.get('id')])
			if (index >= 0) {
				state = state.setIn(['sharedDocuments', index], payload)
			}
			return state.set('document', payload)
		}

		case DOCUMENTS_FETCH_DOCUMENTS_TO_MERGE:
			return state.set('documentsToMerge', payload)

		case DOCUMENTS_PREPEND_DOCUMENT_TO_MERGE: {
			let documentsToMerge = state.get('documentsToMerge')
			const index = documentsToMerge.findIndex((doc) => doc.get('id') === payload.get('id'))

			if (index >= 0) {
				documentsToMerge = documentsToMerge.set(index, payload)
			} else {
				documentsToMerge = documentsToMerge.unshift(payload)
			}

			return state.set('documentsToMerge', documentsToMerge)
		}

		case DOCUMENTS_TO_SAVE_SAVE_REMOTE: {
			const modifyDocId = payload.get('id')
			let documentsToMerge = state.get('documentsToMerge')
			const index = documentsToMerge.findIndex((doc) => doc.get('id') === modifyDocId)
			return state.setIn(['documentsToMerge', index], payload)
		}

		case DOCUMENTS_CLEAR_DOCUMENTS_TO_MERGE:
			return state.set('documentsToMerge', List([]))

		case DOCUMENTS_UPDATE_NUMBER_OF_SHARE_WITH: {
			const { numOfShareholdersToShareWith, numOfAttendeesToShareWith, numOfOtherPersonsToShareWith } = payload

			if (numOfShareholdersToShareWith !== undefined) {
				state = state.set('numOfShareholdersToShareWith', numOfShareholdersToShareWith)
			}

			if (numOfAttendeesToShareWith !== undefined) {
				state = state.set('numOfAttendeesToShareWith', numOfAttendeesToShareWith)
			}

			if (numOfOtherPersonsToShareWith !== undefined) {
				state = state.set('numOfOtherPersonsToShareWith', numOfOtherPersonsToShareWith)
			}

			return state
		}

		case DOCUMENTS_UPDATE_DOCUMENT_IN_LIST: {
			const index = state.getIn(['documentIdMapToIndex', payload.get('id')])

			state = state.setIn(['allDocuments', index], payload)

			if (state.getIn(['document', 'id']) === payload.get('id')) {
				state = state.set('document', payload)
			}

			return state
		}

		default:
			return state
	}
}
