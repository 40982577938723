import React, { useEffect } from 'react'
import MUIDialog from '../../../mui-components/dialog/mui-dialog'
import { closeModal } from '../../../actions/modals.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CompaniesUserMemberOfGrid from './companies-user-member-of-grid'
import { useCopyContactModalContext } from './copy-contact-modal.context'
import { copyContactsToCompanies, fetchCompaniesUserIsMemberOf } from '../../../actions/company.actions'
import { List } from 'immutable'

const CopyContactToCompanyModal = () => {
	const { selectedCompanies } = useCopyContactModalContext()
	const contactsToCopy = useSelector((store) => store.modals.getIn(['activeModal', 'options', 'contactsToCopy']))
	const callback = useSelector((state) => state.modals.getIn(['activeModal', 'options', 'callback']))
	const { t } = useTranslation()
	const dispatch = useDispatch()

	useEffect(() => {
		let contactsToCopyIds = List()
		contactsToCopy.forEach((contact) => {
			contactsToCopyIds = contactsToCopyIds.push(contact.get('id'))
		})

		dispatch(fetchCompaniesUserIsMemberOf(contactsToCopyIds))
	}, [])

	const onSave = () => {
		dispatch(
			copyContactsToCompanies(contactsToCopy, selectedCompanies, () => {
				callback?.()
				dispatch(closeModal())
			})
		)
	}

	return (
		<MUIDialog
			isOpen={true}
			title={t('copy_contact_to_company_modal.title')}
			confirmButtonTid='copy_contact_to_company_modal.footer_button.confirm.copy_to_selection'
			onConfirm={() => {
				onSave()
			}}
			disabledConfirm={selectedCompanies.size < 1}
			closeButtonTid='copy_contact_to_company_modal.footer_button.cancel'
			onClose={() => {
				dispatch(closeModal())
			}}
			tooltipStates={{
				noSelections: {
					title: 'copy_contact_to_company_modal.confirm_button.tooltip.no_company_selected',
					enterDelay: 'instant'
				}
			}}
			tooltipActiveState={'noSelections'}
			maxWidth={'xl'}
			fullWidth={true}
			paperProps={{ sx: { height: '688px' } }}>
			<CompaniesUserMemberOfGrid />
		</MUIDialog>
	)
}

export default CopyContactToCompanyModal
