import React, { Component } from 'react'
import { connect } from 'react-redux'
import { oneOf, bool, string, func } from 'prop-types'
import { Map, List } from 'immutable'
import { openModal } from '../../../actions/modals.actions'
import { cancelDocumentSigning, generateDocumentFile } from '../../../actions/documents.actions'
import { hasBalanceToBankIdSign } from '../../../components/helpers/subscription.helper'
import DocumentsHelper from '../../../components/helpers/documents.helper'

import PanelStepsInformation from '../../../dumb-components/documents/document-esign-panel/panel-steps-information'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import ConfirmContainer from '../../shared/confirm.container'
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import { ButtonWithIcon } from '../../../dumb-components/shared/button'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'
import { OutlinedButton } from '../../../dumb-components/shared/button-v2'

import NoFundsEsignModalContainer from '../no-funds-esign-modal.container'

import {
	DOCUMENT_REQUEST_ESIGN_MODAL_ADVANCED,
	INSUFFICIENT_FUNDS_DOCUMENT_ESIGN_MODAL,
	DOCUMENT_ESIGN_USERS_MODAL
} from '../../../constants/modals'

import { OBJ_TYPE_MEETING, OBJ_TYPE_DOCUMENT, MEETING_TYPE_SMART } from '/shared/constants'

const TOOLTIP_STATES = {
	incorrectFileType: {
		tid: 'tooltip.documents.toolbar.esign.incorrect_file_type',
		delayShow: 'instant'
	},
	noActiveSignees: {
		tid: 'tooltip.documents.toolbar.esign.no_signees_selected',
		delayShow: 'instant'
	},
	upgradeToStarter: {
		tid: 'documents.esign.subscription.upgrade.tooltip',
		delayShow: 'instant'
	},
	alreadySigned: {
		tid: 'documents.document.esign.toolbar.alreay_signed.tooltip',
		delayShow: 'instant'
	},
	noDocument: {
		tid: 'documents.document.esign.toolbar.no_document.tooltip',
		delayShow: 'instant'
	},
	noProtocol: {
		tid: 'documents.document.esign.toolbar.no_protocol.tooltip',
		delayShow: 'instant'
	},
	attachmentIsBeingUploaded: {
		tid: 'documents.document.esign.toolbar.attachment_is_being_uploaded',
		delayShow: 'instant'
	},
	attachmentsAreBeingFetched: {
		tid: 'documents.document.esign.toolbar.attachmentsAreBeingFetched.tooltip',
		delayShow: 'instant'
	},
	feedbackInProgress: {
		tid: 'meetings.protocol.sign.toolbar.tooltip.feedback_in_progress',
		delayShow: 'instant'
	}
}

class DocumentRequestSignButton extends Component {
	state = {
		cancelSignConfirmOpen: false,
		cancelIsLoading: false
	}

	static propTypes = {
		mode: oneOf(['toolbar', 'panel', 'dropdown']),
		env: oneOf([OBJ_TYPE_MEETING, OBJ_TYPE_DOCUMENT]),
		disabled: bool,
		willGenerateFile: bool,
		typeOfFileToGenerate: string,
		fileObjOwnerId: string,
		onGenereteFileInit: func,
		onGenereteFileComplete: func,
		renderComponent: func,
		onChangeIsDisabled: func,
		onRequestSigning: func
	}

	static defaultProps = {
		mode: 'toolbar',
		env: OBJ_TYPE_DOCUMENT
	}

	dropdownRef = null

	closeDropdownIfToolbar = () => {
		const { mode, dropdownRef } = this.props
		if (mode === 'panel') {
			return
		}

		if (mode === 'toolbar') {
			this.dropdownRef && this.dropdownRef.onToggleMenu()
		}

		if (mode === 'dropdown') {
			dropdownRef && dropdownRef.onToggleMenu()
		}
	}

	closeCancelSignConfirmModal = () => {
		this.setState({ cancelSignConfirmOpen: false })
	}

	// Check if there are enough funds to let everyone sign using BankID
	// If not, display modal with that information
	startSign = () => {
		const {
			openModal,
			generateDocumentFile,
			documentId,
			typeOfFileToGenerate,
			fileObjOwnerId,
			onGenereteFileInit,
			onGenereteFileComplete,
			willGenerateFile
		} = this.props

		this.closeDropdownIfToolbar()

		// Used in Smart meetings to create the protocol PDF. Can also be used for other dynamically genereted files
		willGenerateFile &&
			generateDocumentFile(documentId, typeOfFileToGenerate, fileObjOwnerId, onGenereteFileInit, (error, document) => {
				!error && onGenereteFileComplete && onGenereteFileComplete(document)
			})

		// Has enough money in the balance to do BankID eSigning
		if (this.getHasBalanceToBankIdSign()) {
			this.openRequestSigningModal()
		} else {
			openModal(INSUFFICIENT_FUNDS_DOCUMENT_ESIGN_MODAL)
		}
	}

	getHasBalanceToBankIdSign = () => {
		const { eSignees } = this.props
		const amountOfSignees = eSignees.size
		return hasBalanceToBankIdSign(amountOfSignees)
	}

	openRequestSigningModal = () => {
		const { willGenerateFile, typeOfFileToGenerate, fileObjOwnerId, env, onRequestSigning } = this.props

		onRequestSigning && onRequestSigning()

		this.props.openModal(DOCUMENT_REQUEST_ESIGN_MODAL_ADVANCED, {
			willGenerateFile,
			typeOfFileToGenerate,
			fileObjOwnerId
		})
	}

	cancelSign = () => {
		const { eSignees } = this.props
		const shouldOpenModal = eSignees.find((eSignee) => eSignee.get('status') === 'SIGNED') ? true : false

		this.closeDropdownIfToolbar()

		// Open confirmation modal only if someone has signed
		if (shouldOpenModal) {
			this.setState({ cancelSignConfirmOpen: true })
		} else {
			this.doCancelSign()
		}
	}

	doCancelSign = () => {
		const { cancelDocumentSigning, documentId } = this.props

		this.setState({ cancelSignConfirmOpen: false, cancelIsLoading: true })

		cancelDocumentSigning(documentId, () => {
			this.setState({ cancelIsLoading: false })
		})
	}

	onInviteSignees = () => {
		const { openModal } = this.props

		this.closeDropdownIfToolbar()

		// Has enough money in the balance to do BankID eSigning
		if (this.getHasBalanceToBankIdSign()) {
			this.openRequestSigningModal()
		} else {
			openModal(INSUFFICIENT_FUNDS_DOCUMENT_ESIGN_MODAL)
		}
	}

	openModalToAddSignees = () => {
		this.props.openModal(DOCUMENT_ESIGN_USERS_MODAL)
		this.closeDropdownIfToolbar()
	}

	getEveryoneHasSigned = () => {
		const { eSigning, eSignees } = this.props
		const fakeDocumentMap = Map({ eSigning, eSignees })
		return DocumentsHelper.getDocumentAllHaveSigned(fakeDocumentMap)
	}

	getStateData = (returnStateForAddSignees = false) => {
		const {
			eSigning,
			canUpdateDocument,
			subIncludesEsign,
			eSigningDone,
			canEsign,
			isPdf,
			willGenerateFile,
			eSignees,
			mode,
			documentId,
			env,
			disabled,
			isSharedFromCompany,
			isSharedFromCompanyName,
			attachmentIsUploading,
			attachmentsAreBeingFetched,
			isSmartMeeting,
			feedbackees,
			onChangeIsDisabled
		} = this.props
		const noSignees = eSignees.size === 0
		const everyoneHasSigned = this.getEveryoneHasSigned()
		const hasPermissions = canUpdateDocument && canEsign
		const canCancelWithoutSubscription = eSigning && canUpdateDocument && !subIncludesEsign && !eSigningDone
		const feedbackInProgress =
			feedbackees.size > 0 && feedbackees.find((f) => f.get('active') === true && f.get('done', false) === false)
				? true
				: false
		let activeState = null
		const values = {}

		if (attachmentsAreBeingFetched) {
			activeState = 'attachmentsAreBeingFetched'
		} else if (attachmentIsUploading) {
			activeState = 'attachmentIsBeingUploaded'
		} else if (isSharedFromCompany) {
			activeState = 'hasNoPermissionsSharedCompany'
			values.companyName = isSharedFromCompanyName
		} else if (isSmartMeeting && feedbackInProgress) {
			activeState = 'feedbackInProgress'
		} else if (!documentId) {
			activeState = env === OBJ_TYPE_MEETING ? 'noProtocol' : 'noDocument'
		} else if (canCancelWithoutSubscription && !returnStateForAddSignees) {
			activeState = null // Allow cancelation of ongoing eSigning without subscription if it's not yet finalized
		} else if (!subIncludesEsign) {
			activeState = 'upgradeToStarter'
		} else if (everyoneHasSigned) {
			activeState = 'alreadySigned'
		} else if (!hasPermissions) {
			activeState = 'hasNoPermissions'
		} else if (env === OBJ_TYPE_DOCUMENT && !isPdf) {
			activeState = 'incorrectFileType'
		} else if (noSignees && !returnStateForAddSignees) {
			activeState = 'noActiveSignees'
		}

		const actionBtnDisabled =
			disabled ||
			(!canCancelWithoutSubscription && (!subIncludesEsign || everyoneHasSigned || !hasPermissions || noSignees)) ||
			attachmentIsUploading ||
			attachmentsAreBeingFetched ||
			feedbackInProgress

		onChangeIsDisabled && onChangeIsDisabled(actionBtnDisabled)

		return { activeState, actionBtnDisabled, values }
	}

	getStateDataForAddSignees = () => {
		return this.getStateData(true)
	}

	renderDropdownMode = () => {
		const { eSigning } = this.props
		const everyoneHasSigned = this.getEveryoneHasSigned()
		const { activeState, actionBtnDisabled, values } = this.getStateData()

		return (
			<Tooltip states={TOOLTIP_STATES} activeState={activeState} values={values}>
				{everyoneHasSigned || !eSigning ? (
					<DropdownIconItem
						tid='tooltip.documents.toolbar.esign.request_esign'
						icon='faSignature'
						disabled={actionBtnDisabled}
						onClick={this.startSign}
					/>
				) : (
					<DropdownIconItem tid='documents.more_actions.label.cancel_esign' icon='faBan' onClick={this.cancelSign} />
				)}
			</Tooltip>
		)
	}

	renderToolbarMode = () => {
		const { eSigning, eSignees, documentId, env } = this.props

		const everyoneHasSigned = this.getEveryoneHasSigned()
		const { activeState, actionBtnDisabled, values } = this.getStateData()
		const { activeState: activeStateAddSignees, values: valuesAddSignees } = this.getStateDataForAddSignees()
		const someNeedNotification = !DocumentsHelper.allSignatoriesNotified(Map({ eSigning, eSignees }))

		return (
			<DropdownMenuContainer
				btnIcon='faPenAlt'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				noMaxWidth={true}
				disabled={!documentId}
				ref={(ref) => (this.dropdownRef = ref)}>
				{env === OBJ_TYPE_DOCUMENT && (
					<Tooltip states={TOOLTIP_STATES} activeState={activeStateAddSignees} values={valuesAddSignees}>
						<DropdownIconItem
							icon='faPlus'
							tid='documents.toolbar.esign.dropdown_item.add_signees'
							onClick={this.openModalToAddSignees}
							disabled={activeStateAddSignees}
						/>
					</Tooltip>
				)}

				{eSigning && someNeedNotification && (
					<Tooltip states={TOOLTIP_STATES} activeState={activeState} values={values}>
						<DropdownIconItem
							icon='faPaperPlane'
							tid='document.esign.signatories.panel.invite_signatories'
							onClick={this.onInviteSignees}
							disabled={actionBtnDisabled}
						/>
					</Tooltip>
				)}

				{everyoneHasSigned || !eSigning ? (
					<Tooltip states={TOOLTIP_STATES} activeState={activeState} values={values}>
						<DropdownIconItem
							tid='tooltip.documents.toolbar.esign.request_esign'
							icon='faSignature'
							onClick={this.startSign}
							disabled={actionBtnDisabled}
						/>
					</Tooltip>
				) : (
					<Tooltip states={TOOLTIP_STATES} activeState={activeState} values={values}>
						<DropdownIconItem
							tid='documents.more_actions.label.cancel_esign'
							icon='faTimes'
							onClick={this.cancelSign}
							disabled={actionBtnDisabled}
						/>
					</Tooltip>
				)}
			</DropdownMenuContainer>
		)
	}

	renderPanelMode = () => {
		const { eSigning, env } = this.props
		const { cancelIsLoading } = this.state
		const { activeState, actionBtnDisabled, values } = this.getStateData()

		if (!eSigning) {
			return (
				<PanelStepsInformation env={env}>
					<Tooltip states={TOOLTIP_STATES} activeState={activeState} values={values}>
						<ButtonWithIcon
							btnProps={{ mode: 'primary' }}
							tid='documents.esign.panel.request_esign.button'
							icon='faSignature'
							color='white'
							iconColor='white'
							disabled={actionBtnDisabled}
							onClick={this.startSign}
						/>
					</Tooltip>
				</PanelStepsInformation>
			)
		}

		return (
			<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
				<OutlinedButton
					tid='documents.more_actions.label.cancel_esign'
					icon='faTimes'
					disabled={actionBtnDisabled}
					onClick={this.cancelSign}
					isLoading={cancelIsLoading}
				/>
			</Tooltip>
		)
	}

	renderCustomComponent = () => {
		const { renderComponent } = this.props

		return renderComponent({
			onInviteSignees: this.onInviteSignees
		})
	}

	render = () => {
		const { mode, isMeeting, renderComponent, buttonIsDisabled } = this.props
		const { cancelSignConfirmOpen } = this.state

		// Don't render if Document is an legacy meeting
		if (isMeeting) {
			return null
		}

		if (renderComponent) {
			return this.renderCustomComponent()
		}

		return (
			<>
				{mode === 'toolbar' && this.renderToolbarMode()}

				{mode === 'dropdown' && this.renderDropdownMode()}

				{mode === 'panel' && !buttonIsDisabled && (
					<>
						{this.renderPanelMode()}
						<NoFundsEsignModalContainer onSignWithEmail={this.openRequestSigningModal} />
					</>
				)}

				<ConfirmContainer
					isOpen={cancelSignConfirmOpen}
					title='documents.esign.cancel.modal.title'
					question='documents.esign.cancel.modal.question'
					onConfirm={this.doCancelSign}
					onDecline={this.closeCancelSignConfirmModal}
				/>
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	const userId = store.user.getIn(['userObj', 'id'])
	const isSharedFromCompanyId = store.documents.getIn(['document', 'isSharedFromCompanyId'])

	return {
		isSharedFromCompany: Boolean(isSharedFromCompanyId),
		isSharedFromCompanyName: store.folders.getIn(['folders', isSharedFromCompanyId, 'translatedName']),
		documentId: store.documents.getIn(['document', 'id']),
		eSigning: store.documents.getIn(['document', 'eSigning'], false),
		eSignees: store.documents.getIn(['document', 'eSignees'], Map()),
		isMeeting: store.documents.getIn(['document', 'isMeeting']),
		isPdf: store.documents.getIn(['document', 'file', 'ext']) === 'pdf',
		canUpdateDocument: store.documents.getIn(['document', 'ALLOW_UPDATE']),
		canEsign: store.documents.getIn(['document', 'ALLOW_ESIGN']),
		subIncludesEsign: store.subscriptions.getIn(['helper', 'eSigningEnabled']),
		eSigningDone: store.documents.getIn(['document', 'eSigningData', 'finialisedAt']) ? true : false,
		companyId: store.company.company.id,
		isSmartMeeting: store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_SMART,
		feedbackees: store.meetings.getIn(['meeting', 'feedbackees'], Map()),
		attachmentIsUploading: store.notify.attachmentIsUploading,
		attachmentsAreBeingFetched: store.notify.attachmentsAreBeingFetched,
		buttonIsDisabled: store.documents.getIn(['document', 'eSignees'], Map()).size === 0,
		userId
	}
}

const mapActionsToProps = {
	openModal,
	cancelDocumentSigning,
	generateDocumentFile
}

export default connect(mapStoreToProps, mapActionsToProps)(DocumentRequestSignButton)
