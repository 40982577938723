import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import WhiteSection from '../../common/white-section'
import ShowPartialClosuresList from '../allocation/show-partial-closure-list'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'

const StatCard = (props) => {
	const theme = useTheme()
	const { t } = useTranslation()
	const [showClosuresListModal, setShowClosuresListModal] = useState(false)

	return (
		<WhiteSection sx={props.sx}>
			<ShowPartialClosuresList
				open={showClosuresListModal}
				close={() => setShowClosuresListModal(false)}
				companyId={props.companyId}
				emissionId={props.emissionId}
			/>
			<Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography
						sx={{
							textTransform: 'uppercase',
							color: 'secondary.500',
							pb: 1
						}}>
						{props.label}
					</Typography>
					{props.info && (
						<Tooltip title={t('emissions.allocation.info-button')} placement={'top'}>
							<InfoIcon sx={{ ml: '12px', color: 'secondary.500', fontSize: 'initial' }} />
						</Tooltip>
					)}
					{props.showPartialClosureList && props.mainValue !== (0 || '0') && (
						<Typography
							onClick={() => setShowClosuresListModal(true)}
							sx={{ color: 'primary.main', fontWeight: 'bold', cursor: 'pointer' }}>
							{t('emissions.allocation.open-partial-closure')}
						</Typography>
					)}
				</Box>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', columnGap: 2 }}>
				<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
					{props.prefix && (
						<Typography
							component={'span'}
							variant={'body2'}
							sx={{ color: theme.palette.grey['800'], alignSelf: 'flex-end', pr: 1 }}>
							{props.prefix}
						</Typography>
					)}
					{props.minValue && (
						<Typography variant={'h3'} sx={{ alignSelf: 'flex-end', lineHeight: '2.4rem', pr: 0.5 }}>
							{props.minValue} -
						</Typography>
					)}
					<Typography variant={'h3'} sx={{ alignSelf: 'flex-end', lineHeight: '2.4rem' }}>
						{props.mainValue}
					</Typography>
					<Typography
						component={'span'}
						variant={'body2'}
						sx={{ color: theme.palette.grey['800'], alignSelf: 'flex-end', ml: '0.5rem' }}>
						{props.suffix}
					</Typography>
					{props.showPartialClosureList && props.mainValue !== (0 || '0') && (
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Typography
								component={'span'}
								variant={'body2'}
								sx={{ color: 'grey.500', alignSelf: 'flex-end', ml: '2rem' }}>
								{t('emissions.allocation.partial-closure-desc2')}
								<Typography
									component={'span'}
									variant={'body2'}
									sx={{ color: 'grey.500', alignSelf: 'flex-end', ml: '0.5rem' }}>
									{props.desc}
								</Typography>
								<Typography
									component={'span'}
									variant={'body2'}
									sx={{ color: 'grey.500', alignSelf: 'flex-end', ml: '0.5rem' }}>
									{t('emissions.allocation.shares')}
								</Typography>
							</Typography>
						</Box>
					)}
				</Box>
				{props.secondaryValue && (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						{props.secondaryRangeValue && (
							<>
								<Typography sx={{ whiteSpace: 'nowrap', color: 'grey.500', mt: 1 }}>
									{props.secondaryRangeValue}
								</Typography>
								<Typography sx={{ alignSelf: 'flex-end', color: 'grey.500', mx: 1 }}>-</Typography>
							</>
						)}
						<Typography sx={{ whiteSpace: 'nowrap', color: 'grey.500', mt: 1 }}>
							{props.secondaryValue}
						</Typography>
					</Box>
				)}
			</Box>
		</WhiteSection>
	)
}

export default StatCard
