import React, { useContext, useEffect, useRef, useState } from 'react';
import localeFormatNumber from '/shared/helpers/number-formatter.helper';
import { NUMBER_FORMAT_PERCENTAGE } from '/shared/constants';
import ChartModal from '../shared/chartmodal/chartmodal';
import { exportAsPNG } from '../../../components/helpers/ag-chart.helper';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Text from '../../../dumb-components/shared/text/text';
import GridPanelRightButtons from '../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons';
import AgGridChart from '../../../dumb-components/shared/ag-grid/chart/ag-grid-chart';
import { MUItheme } from '../../../../MUItheme';
import { InvestmentsContext } from '../investment.container';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { closeModal, openModal } from '../../../actions/modals.actions';
import { CHART_MODAL } from '../../../constants/modals';

export default function ShareholderDistributionByTypeChart() {
	const chartRefPanel = useRef(null);
	const chartRefModal = useRef(null);
	const { selectedInvestment } = useContext(InvestmentsContext);
	const [chartData, setChartData] = useState();

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const investments = selectedInvestment?.get('investments');
	const chartOption = 'shareholderDistributionByTypeChart';

	const isChartModalOpen = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'options']) === chartOption;
	});

	useEffect(() => {
		if (investments) {
			setChartData(generateDataForTypeOfOwnershipDistributionChart());
		}
	}, [investments]);

	const series = [
		{
			type: 'column',
			xKey: 'bolagsform',
			yKey: 'numOfShares',
			yName: undefined,
			strokeWidth: 0,
			tooltip: { renderer: formatValue },
			shadow: {
				xOffset: 3
			},
			highlightStyle: {
				item: {
					fill: MUItheme.palette.chart.complementary,
					strokeWidth: 0
				},
				series: {
					...(isChartModalOpen ? { dimOpacity: 1 } : { dimOpacity: 0.2 })
				}
			}
		},
		{
			type: 'line',
			xKey: 'bolagsform',
			yKey: 'total',
			yName: undefined,
			tooltip: { renderer: formatValue },
			marker: {
				size: 0
			},
			highlightStyle: {
				item: {
					fill: MUItheme.palette.chart.complementary,
					strokeWidth: 0
				},
				series: {
					...(isChartModalOpen ? { dimOpacity: 1 } : { dimOpacity: 0.2 })
				}
			}
		}
	];

	const axes = [
		{
			type: 'category',
			position: 'bottom'
		},
		{
			min: 0,
			max: 100,
			type: 'number',
			position: 'left',
			keys: ['numOfShares']
		},
		{
			min: 0,
			max: 100,
			type: 'number',
			position: 'right',
			keys: ['total']
		}
	];

	const generateDataForTypeOfOwnershipDistributionChart = () => {
		let total = 0;
		let data = {
			company: { numOfShares: 0, bolagsform: t('shareholder_distribution_by_type_chart_xName.company') },
			private: { numOfShares: 0, bolagsform: t('shareholder_distribution_by_type_chart_xName.private') },
			capitalInsurance: {
				numOfShares: 0,
				bolagsform: t('shareholder_distribution_by_type_chart_xName.capitalInsurance')
			}
		};

		investments &&
			investments.forEach((investment) => {
				if (investment.get('investorTypeOfOwner')) {
					data[investment.get('investorTypeOfOwner')].numOfShares += investment.getIn([
						'balance',
						'ownershipPercentage'
					]);
				}
			});

		let sortedData = Object.values(data).sort((a, b) => b.numOfShares - a.numOfShares);

		sortedData.forEach((typeOfOwner) => {
			total += typeOfOwner.numOfShares;
			typeOfOwner.total = total;
		});

		return Object.values(sortedData);
	};

	function formatValue(params) {
		//	tooltip
		if (params.xValue && params.yValue) {
			return {
				title: params.xValue,
				content: localeFormatNumber(params.yValue, NUMBER_FORMAT_PERCENTAGE)
			};
		}
		//	legend
		else {
			return `${params.value} %`;
		}
	}

	const openChartModal = () => {
		dispatch(openModal(CHART_MODAL, chartOption));
	};

	const onCloseChartModal = () => {
		dispatch(closeModal());
	};

	const renderAgChart = () => {
		return (
			<AgGridChart
				chartRef={isChartModalOpen ? chartRefModal : chartRefPanel}
				series={series}
				axes={axes}
				data={chartData}
				labelFormatter={formatValue}
			/>
		);
	};

	if (!chartData) {
		return null;
	}

	return (
		<>
			{isChartModalOpen && (
				<ChartModal
					leftHeader={'investments.sharebook.shareholder_distribution_by_type_chart.title'}
					onCancel={onCloseChartModal}
					isOpen={isChartModalOpen}
					onExport={() => {
						exportAsPNG(
							chartRefModal,
							t('investments.sharebook.shareholder_distribution_by_type_chart.title')
						);
					}}>
					{renderAgChart()}
				</ChartModal>
			)}

			<Card>
				<CardHeader
					title={<Text tid={'investments.sharebook.shareholder_distribution_by_type_chart.title'} />}
					action={
						<GridPanelRightButtons
							chart
							noFilter={true}
							exportAsExcel={() => {
								exportAsPNG(
									chartRefPanel,
									t('investments.sharebook.shareholder_distribution_by_type_chart.title')
								);
							}}
							downloadExcelTid={
								'investments.shareholders.distribution_by_type_chart.dropdown_item.download_chart'
							}
							openGridModal={openChartModal}
						/>
					}
				/>
				<CardContent variant='panel'>{renderAgChart()}</CardContent>
			</Card>
		</>
	);
}
