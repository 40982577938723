import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, object, bool } from 'prop-types'
import { List } from 'immutable'

import { setMeetingModerator, unsetMeetingModerator } from '../../../../actions/meetings.actions'
import ModeratorPanel from '../../../../dumb-components/meetings/moderator-panel/moderator-panel'
import { isAttendingByProxy } from '../../../../components/helpers/meeting.helper'

class ModeratorControlsContainer extends Component {
	state = {
		fakeToggleAnimation: false,
		showCannotChangeModeratorWarning: false,
		moderatorHasChanged: false
	}

	static propTypes = {
		attendeeId: string
	}

	toggleAttendeeModerator = (value) => {
		const { attendeeId, meetingId, setMeetingModerator, unsetMeetingModerator, meetingHasModerator } = this.props

		if (meetingHasModerator) {
			this.setState({ moderatorHasChanged: true })
		}

		if (!value) {
			unsetMeetingModerator(meetingId, attendeeId)
		} else {
			setMeetingModerator(meetingId, attendeeId)
		}
	}

	render = () => {
		const { moderatorHasChanged } = this.state
		const { attendeeIsModerator, attendeeId, proxies } = this.props
		const isModerator = attendeeIsModerator
		const attendingByProxy = isAttendingByProxy(attendeeId, proxies)

		return (
			<ModeratorPanel
				onToggle={this.toggleAttendeeModerator}
				isModerator={isModerator}
				moderatorHasChanged={moderatorHasChanged}
				attendingByProxy={attendingByProxy}
			/>
		)
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		meetingId: store.meetings.getIn(['meeting', 'id']),
		attendeeIsModerator: (store.meetings.getIn(['meeting', 'moderators'], List()) || List()).includes(
			ownProps.attendeeId
		),
		meetingHasModerator: store.meetings.getIn(['meeting', 'computedValues', 'hasModerator']),
		proxies: store.meetings.getIn(['meeting', 'proxies'])
	}
}

const mapActionsToProps = {
	setMeetingModerator,
	unsetMeetingModerator
}

export default connect(mapStoreToProps, mapActionsToProps)(ModeratorControlsContainer)
