import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import { formatSsnPretty } from '../../../components/helpers/users'
import { emissionConsts } from '../../../constants/emissions'

const StickyContact = ({ invitee }) => {
	const { t } = useTranslation()
	const {
		invitation: { investorType }
	} = emissionConsts

	return (
		<>
			<Grid container>
				<Box sx={{ display: 'flex', mt: 4, ml: 3, mb: -1 }}>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Typography variant={'subtitle1'}>
							{t('public.subscription-slip.subscriber-info-that-we-hold')}
						</Typography>
					</Box>
				</Box>
			</Grid>
			<Grid container>
				<Box sx={{ display: 'flex', mt: 2, ml: 3, mb: -1 }}>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						{invitee.name && (
							<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
								{t('emissions.subscription-form.full-name')}:
							</Typography>
						)}
						{invitee.type === investorType.company && invitee.company && (
							<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
								{t('generic.company_name')}:
							</Typography>
						)}
						{(invitee.type === investorType.private || invitee.beneficiarySsn) && invitee.reference && (
							<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
								{t('credentials_pnr')}:
							</Typography>
						)}
						{invitee.type === investorType.company && invitee.reference && (
							<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
								{t('emissions.subscription-form.organization-number')}:
							</Typography>
						)}
						{invitee.email && (
							<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
								{t('email_address')}:
							</Typography>
						)}
						{invitee.phone && (
							<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
								{t('emissions.subscription-form.telephone-number')}:
							</Typography>
						)}
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
						{invitee.name && (
							<Typography sx={{ my: 1 }} variant={'body2'}>
								{invitee.name}
							</Typography>
						)}
						{invitee.type === investorType.company && invitee.company && (
							<Typography sx={{ my: 1 }} variant={'body2'}>
								{invitee.company}
							</Typography>
						)}
						{(invitee.type === investorType.private || invitee.type === investorType.company) &&
							invitee.reference && (
								<Typography sx={{ my: 1 }} variant={'body2'}>
									{formatSsnPretty(invitee.reference)}
								</Typography>
							)}
						{invitee.beneficiarySsn && (
							<Typography sx={{ my: 1 }} variant={'body2'}>
								{formatSsnPretty(invitee.beneficiarySsn)}
							</Typography>
						)}
						{invitee.email && (
							<Typography sx={{ my: 1 }} variant={'body2'}>
								{invitee.email}
							</Typography>
						)}
						{invitee.phone && (
							<Typography sx={{ my: 1 }} variant={'body2'}>
								{invitee.phone}
							</Typography>
						)}
					</Box>
				</Box>
			</Grid>
		</>
	)
}

export default StickyContact
