import React, { PureComponent } from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import ImmutableProps from 'react-immutable-proptypes'
import { Select } from '../../shared/select'

const StyledRoleSelectWrapper = styled.div`
	min-width: 200px;
`

class RoleSelector extends PureComponent {
	static propTypes = {
		selectedRole: ImmutableProps.map,
		onChange: func,
		roles: ImmutableProps.list
	}

	render = () => {
		const { selectedRole, onChange, roles } = this.props
		const selectedRoleValue = selectedRole && selectedRole.get('id', '')

		return (
			<StyledRoleSelectWrapper>
				<Select
					options={roles}
					value={selectedRoleValue}
					optionsLabel='name'
					optionsValue='id'
					isSearchable={false}
					onChange={onChange.bind(null, 'roleChanged')}
					placeholderTid='select_placeholder'
				/>
			</StyledRoleSelectWrapper>
		)
	}
}

export default RoleSelector
