import { DateTime } from 'luxon'
import {
	ADVERTISING_CREATE_ADVERT,
	ADVERTISING_DELETE_ADVERT,
	ADVERTISING_LIST_ADVERTS,
	ADVERTISING_UPDATE_ADVERT
} from '../actions/types'

const INITIAL_STATE = {
	list: []
}

export default function (state = INITIAL_STATE, { type, payload }) {
	switch (type) {
		case ADVERTISING_LIST_ADVERTS:
			return { ...state, list: payload }

		case ADVERTISING_CREATE_ADVERT: {
			state.list.push(payload)

			const sortedAdverts = state.list.sort((a, b) => {
				const aDate = DateTime.fromISO(a.createdAt).toJSDate()
				const bDate = DateTime.fromISO(b.createdAt).toJSDate()

				return bDate - aDate
			})

			return { ...state, list: [...sortedAdverts] }
		}

		case ADVERTISING_UPDATE_ADVERT: {
			const adverts = state.list.map((advert) => {
				if (advert.id === payload.id) {
					return payload
				}

				return advert
			})

			const sortedAdverts = adverts.sort((a, b) => {
				const aDate = DateTime.fromISO(a.createdAt).toJSDate()
				const bDate = DateTime.fromISO(b.createdAt).toJSDate()

				return bDate - aDate
			})

			return { ...state, list: sortedAdverts }
		}

		case ADVERTISING_DELETE_ADVERT: {
			const adverts = state.list.filter(({ id }) => id !== payload)
			return { ...state, list: adverts }
		}

		default:
			return state
	}
}
