import React, { useState } from 'react'
import { Map, List } from 'immutable'
import { FormattedMessage } from 'react-intl'
import { validatePassword } from '../../../../modules/validation.module'
import Panel from '../../../../dumb-components/panel'
import Field from '../../../../dumb-components/fields/field'
import Input from '../../../../dumb-components/fields/input'


function PasswordField({ user, changeUserPassword, addErrorNotification, addInfoNotification, clearNotifications }) {
	const [currentPassword, setCurrentPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [passwordVerify, setPasswordVerify] = useState('')
	const [errors, setErrors] = useState(Map())
	const hasPassword = user.get('hasPassword')


	const onChange = (path, val) => {
		user.setIn(path, val)
		debounce(user)
	}


	const onSavePassword = () => {

		let password = Map()
		const currentPasswordErrors = List()
		let newPasswordErrors = List()
		let passwordVerifyErrors = List()

		if (newPassword !== passwordVerify) {
			addErrorNotification({ tid: 'validation.verified_password' })
			newPasswordErrors = newPasswordErrors.push('validation.verified_password')
			passwordVerifyErrors = passwordVerifyErrors.push('validation.verified_password')
			setErrors({
				errors: Map({
					currentPassword: currentPasswordErrors,
					newPassword: newPasswordErrors,
					passwordVerify: passwordVerifyErrors
				})
			})
			return
		}

		if (!validatePassword(newPassword)) {
			addErrorNotification({ tid: 'validation.password' })
			newPasswordErrors = newPasswordErrors.push('validation.password')
			setErrors({
				errors: Map({
					currentPassword: currentPasswordErrors,
					newPassword: newPasswordErrors,
					passwordVerify: passwordVerifyErrors
				})
			})
			return
		}

		password = password.set('currentPassword', currentPassword)
		password = password.set('newPassword', newPassword)

		// Clear password state when request is done
		changeUserPassword(user.get('id'), password, () => {
			// Update changedPassword in user-model
			onChange(['changedPassword'], true)

			// Clear all notifications before displaying success notification
			clearNotifications()
			addInfoNotification({ tid: 'generic.password.was.updated' })

			setCurrentPassword('')
			setNewPassword('')
			setPasswordVerify('')
			setErrors(Map())
		})
	}



	if (!user) {
		return null
	}

	/**
	 * Display current password field only if user has previously changed its password or has security method basic which means it has to have an password.
	 */
	return (
		<Panel tid='generic.password'>
			{hasPassword && (
				<Field tid='current_password'>
					<Input
						name='currentPassword'
						type='password'
						value={currentPassword}
						onChange={(val) => {
							setCurrentPassword(val)
						}}
					/>
				</Field>
			)}
			<Field name='newPassword' tid='new_password' errors={errors}>
				<Input
					name='newPassword'
					type='password'
					value={newPassword}
					onChange={(val) => {
						setNewPassword(val)
					}}
				/>
			</Field>
			<Field name='passwordVerify' tid='new_password_verify' errors={errors}>
				<Input
					name='passwordVerify'
					type='password'
					value={passwordVerify}
					onChange={(val) => {
						setPasswordVerify(val)
					}}
				/>
			</Field>
			<Field>
				<button type='submit' className='btn btn-primary' disabled={false} onClick={onSavePassword}>
					<FormattedMessage id='user_profile.update_password' />
				</button>
			</Field>
		</Panel>
	)
}

export default PasswordField