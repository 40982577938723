import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { bool, func, string } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import ProtocolTable from '../protocol-table/protocol-table'
import ProtocolTableRow from '../protocol-table/protocol-table-row'
import { convertToHtml } from '../../../../components/helpers/draftjs.helper'

export default class AgendaItem extends PureComponent {
	static propTypes = {
		title: string,
		presenter: string,
		itemType: string,
		outcome: string,
		minutes: string
	}

	render = () => {
		const { title, presenter, itemType, outcome, minutes } = this.props

		return (
			<ProtocolTable headerLeftText={title}>
				{presenter && <ProtocolTableRow labelTid='meetings.protocol.agenda.presenter' content={presenter} />}
				{itemType && <ProtocolTableRow labelTid='meetings.protocol.agenda.item_type' contentTid={itemType} />}
				{outcome && <ProtocolTableRow labelTid='meetings.protocol.agenda.outcome' contentTid={outcome} />}
				{minutes && (
					<ProtocolTableRow
						labelTid='meetings.protocol.agenda.minutes'
						content={convertToHtml(minutes)}
						contentIsHtml
					/>
				)}
			</ProtocolTable>
		)
	}
}
