import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Button from '../../shared/button/button'
import { bool, func, string } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import { Map } from 'immutable'
import Input from '../../shared/input/input'
import Label from '../../shared/label/label'
import Text from '../../shared/text/text'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import Tooltip from '../../shared/tooltip/tooltip'
import ErrorMessage from '../error-message'

import { EMAIL_FIELD, PASSWORD_FIELD, REMEMBER_DETAILS_FIELD, CODE_FIELD } from '../../../constants/credentials'

const StyledSignInEmail = styled.div`
	display: flex;
	flex-direction: column;
	> * {
		margin: ${(props) => props.theme.spacing[3]} 0;
	}
	width: 100%;
	height: 100%;
`

const StyledToggleButtonDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	> * {
		margin: 0 ${(props) => props.theme.spacing[2]};
	}
`

const StyledButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: flex-end;
`

const StyledTwoButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: flex-end;
	> * {
		margin: 0 ${(props) => props.theme.spacing[4]};
	}
`

export default class SignInEmail extends PureComponent {
	static propTypes = {
		onClickForgotPassword: func,
		rememberDetails: bool,
		onClickSignIn: func,
		onClickVerify: func,
		onClickCancel: func,
		isVerifyMode: bool,
		onChange: func,
		onHideTooltip: func,
		hasError: map,
		code: string,
		email: string,
		password: string,
		actionBtnLoading: bool,
		status: string
	}

	static defaultProps = {
		rememberDetails: false,
		isVerifyMode: false,
		hasError: Map(),
		code: '',
		email: '',
		password: ''
	}

	renderEmailInput = () => {
		const {
			hasError,
			onClickSignIn,
			onSetTooltipRefs,
			isVerifyMode,
			onChange,
			email,
			onHideTooltip,
			actionBtnLoading
		} = this.props

		return (
			<Label tid='credentials.signin.label.email' textColor='white'>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([EMAIL_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(null, EMAIL_FIELD)}>
					<Input
						fieldName={EMAIL_FIELD}
						disabled={isVerifyMode || actionBtnLoading}
						onChange={onChange}
						value={email}
						onBlur={onHideTooltip}
						onEnter={onClickSignIn}
						hasError={hasError.get(EMAIL_FIELD)}
						inputStyle='v2'
					/>
				</Tooltip>
			</Label>
		)
	}

	renderPasswordInput = () => {
		const {
			onClickForgotPassword,
			onClickSignIn,
			isVerifyMode,
			hasError,
			onSetTooltipRefs,
			onChange,
			password,
			onHideTooltip,
			actionBtnLoading
		} = this.props

		const renderRightComponent = () => {
			if (isVerifyMode) {
				return null
			}

			return <Button onClick={onClickForgotPassword} mode='link' tid='credentials_forgot.password' />
		}

		return (
			<Label tid='credentials_password' textColor='white' rightComponent={renderRightComponent}>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([PASSWORD_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(null, PASSWORD_FIELD)}>
					<Input
						fieldName={PASSWORD_FIELD}
						disabled={isVerifyMode || actionBtnLoading}
						onChange={onChange}
						value={password}
						onBlur={onHideTooltip}
						onEnter={onClickSignIn}
						hasError={hasError.get(PASSWORD_FIELD)}
						inputStyle='v2'
						type='password'
					/>
				</Tooltip>
			</Label>
		)
	}

	renderVerifyCodeInput = () => {
		const {
			isVerifyMode,
			onClickVerify,
			hasError,
			onSetTooltipRefs,
			onChange,
			code,
			actionBtnLoading,
			email
		} = this.props

		if (!isVerifyMode) {
			return null
		}

		return (
			<>
				<Label tid='credentials_verification.code' textColor='white'>
					<Tooltip
						activeState='formValidator'
						tid={hasError.getIn([CODE_FIELD, 0])}
						ref={onSetTooltipRefs && onSetTooltipRefs.bind(null, CODE_FIELD)}>
						<Input
							fieldName={CODE_FIELD}
							onChange={onChange}
							value={code}
							disabled={actionBtnLoading}
							autoFocus={true}
							onEnter={onClickVerify}
							hasError={hasError.get(CODE_FIELD)}
							inputStyle='v2'
						/>
					</Tooltip>
				</Label>

				<Text color='white' tid='credentials.verify.code.code_sent_by_email_If_exist' values={{ email }} />
			</>
		)
	}

	renderRememberCredentials = () => {
		const { onChange, rememberDetails, isVerifyMode } = this.props

		if (isVerifyMode) {
			return null
		}

		return (
			<StyledToggleButtonDiv>
				<ToggleSwitch fieldName={REMEMBER_DETAILS_FIELD} checked={rememberDetails} onChange={onChange} />
				<Text tid='credentials_remember.credentials' color='muted' />
			</StyledToggleButtonDiv>
		)
	}

	render = () => {
		const {
			onClickSignIn,
			onClickVerify,
			onClickCancel,
			isVerifyMode,
			actionBtnLoading,
			translationId,
			status
		} = this.props
		const alertMode = status === 'complete' ? 'info' : undefined // Used for forgot password complete

		return (
			<StyledSignInEmail>
				{this.renderEmailInput()}
				{this.renderPasswordInput()}
				{this.renderVerifyCodeInput()}
				{this.renderRememberCredentials()}

				<ErrorMessage tid={translationId} mode={alertMode} />

				{isVerifyMode ? (
					<StyledTwoButtonWrapper>
						<Button
							mode='rounded'
							tid='credentials_verify'
							minWidth='150px'
							maxWidth='150px'
							height='50px'
							onClick={onClickVerify}
							isLoading={actionBtnLoading}
						/>
						<Button
							mode='rounded'
							tid='credentials_cancel'
							inverted={true}
							minWidth='150px'
							maxWidth='150px'
							height='50px'
							onClick={onClickCancel}
						/>
					</StyledTwoButtonWrapper>
				) : (
					<StyledButtonWrapper>
						<Button
							mode='rounded'
							tid='credentials_signin'
							minWidth='150px'
							maxWidth='150px'
							height='50px'
							onClick={onClickSignIn}
							isLoading={actionBtnLoading}
						/>
					</StyledButtonWrapper>
				)}
			</StyledSignInEmail>
		)
	}
}
