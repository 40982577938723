import React from 'react'
import Panel from '../../../../dumb-components/panel'
import Field from '../../../../dumb-components/fields/field'
import Input from '../../../../dumb-components/fields/input'


export default function InformationField({ user, onChange }) {

	if (!user) {
		return null
	}


	return (
		<Panel tid='user.profile.information'>
			<Field tid='generic.name'>
				<Input
					name='name'
					value={user.get('name')}
					onChange={(val) => {
						onChange(['name'], val)
					}}
				/>
			</Field>
			<Field tid='generic.ssn'>
				{/* This field should be disabled until further notice */}
				<Input
					name='ssn'
					value={user.get('ssn')}
					disabled
					onChange={(val) => {
						onChange(['ssn'], val)
					}}
				/>
			</Field>
			<Field tid='generic.telephone'>
				<Input
					name='phone'
					value={user.get('phone')}
					onChange={(val) => {
						onChange(['phone'], val)
					}}
				/>
			</Field>
		</Panel>
	)
}