import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { Map } from 'immutable'

import DropdownIconItem from '../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import Tooltip from '../../../../../dumb-components/shared/tooltip/tooltip'

import { openModal } from '../../../../../actions/modals.actions'
import { MEETINGS_MANAGE_MODERATORS } from '../../../../../constants/modals'

const TOOLTIP_STATES = {
	noAttendees: {
		tid: 'meetings.attendees.panel.controls.tooltip.no_attendees',
		delayShow: 'instant'
	}
}

class ManageModeratorsContainer extends Component {
	static propTypes = {
		toggleDropdown: func
	}

	openModal = () => {
		const { toggleDropdown, openModal } = this.props

		toggleDropdown && toggleDropdown()

		openModal(MEETINGS_MANAGE_MODERATORS)
	}

	getActiveState = () => {
		const { hasExtendedRights, attendees } = this.props
		const noAttendees = !attendees.size

		if (!hasExtendedRights) {
			return 'hasNoPermissions'
		}

		if (noAttendees) {
			return 'noAttendees'
		}
	}

	render = () => {
		const activeState = this.getActiveState()
		const disabled = Boolean(activeState)

		return (
			<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
				<DropdownIconItem
					icon='faUserCog'
					tid='meetings.standard.dropdown_item.manage_moderators'
					onClick={this.openModal}
					disabled={disabled}
				/>
			</Tooltip>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		hasExtendedRights: store.meetings.getIn(['meeting', 'computedValues', 'hasExtendedRights']),
		attendees: store.meetings.getIn(['meeting', 'attendees'], Map())
	}
}

const mapActionsToProps = {
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(ManageModeratorsContainer)
