import React, { PureComponent } from 'react'
import { string, object, array, oneOfType, bool } from 'prop-types'
import styled from 'styled-components'

import SimpleDarkInfoPanel from './simple-dark-info-panel'
import Text from '../text/text'

const TextWrapper = styled.div`
	flex: 1;
	margin-right: ${(props) => props.theme.spacing[3]};
	display: flex;
	align-items: center;
`

const ControlsWrapper = styled.div`
	display: flex;
	align-items: ${({ centerControls }) => (centerControls ? 'center' : 'flex-start')};
`

export default class SimpleDarkInfoPanelWithControls extends PureComponent {
	static propTypes = {
		tid: string,
		rightControlsComponent: oneOfType([object, array]),
		className: string,
		centerControls: bool,
		textValues: object
	}

	render = () => {
		const { tid, textValues, rightControlsComponent, className, centerControls } = this.props

		return (
			<SimpleDarkInfoPanel className={className}>
				<TextWrapper>
					<Text color='white' tid={tid} values={textValues} />
				</TextWrapper>
				<ControlsWrapper centerControls={centerControls}>
					{rightControlsComponent && rightControlsComponent}
				</ControlsWrapper>
			</SimpleDarkInfoPanel>
		)
	}
}
