import React, { PureComponent } from 'react'
import OnboardingAlert from '../../onboarding/alert'
import Text from '../../shared/text/text'

export default class SharedMainFolderAlert extends PureComponent {
	render = () => {
		return (
			<OnboardingAlert>
				<Text color='white' tid='documents.document_list.main_shared_folder.alert.info_1' />
				<Text>&nbsp;</Text>
				<Text color='white' tid='documents.document_list.main_shared_folder.alert.info_2' />
				<Text>&nbsp;</Text>
				<Text color='white' tid='documents.document_list.main_shared_folder.alert.info_3' />
			</OnboardingAlert>
		)
	}
}
