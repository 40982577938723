import { fromJS } from 'immutable'
import req from '../modules/request.module'
import { SHARE_REGISTER_FETCH_CERTIFICATES } from './types'
import { addErrorNotification, addInfoNotification } from './notify.actions'

export function fetchCertificates(id) {
	return function (dispatch) {
		return req
			.get(`/shares/sequences${id ? `?id=${id}` : ''}`)
			.then((response) => {
				dispatch({ type: SHARE_REGISTER_FETCH_CERTIFICATES, payload: fromJS(response.data) })
			})
			.catch((err) => {
				console.log(err)
				dispatch(
					addErrorNotification({
						tid: 'failed_to_fetch_certificates'
					})
				)
			})
	}
}
