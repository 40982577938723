// All actions must include the query of companyId to specify what company we're trying to access.

import { fromJS } from 'immutable'
import uuid from 'uuid'
import req from '../modules/request.module'
import {
	SHARING_DOCUMENTS_LIST,
	SHARING_FOLDERS_LIST,
	FOLDERS_LIST,
	DOCUMENTS_FETCH_REMOTE,
	DOCUMENTS_SAVE_REMOTE_START,
	DOCUMENTS_DOCUMENT_DIRTY,
	SHARING_DOCUMENTS_UPDATE_LOCAL,
	SHARING_DOCUMENTS_CREATE_LOCAL,
	SHARING_DOCUMENTS_HARD_DELETE_LOCAL,
	SHARING_DOCUMENTS_DELETE_REMOTE,
	SHARING_DOCUMENTS_SAVE_REMOTE
} from './types'
import moment from '../modules/moment.module'
import { addErrorNotification, addInfoNotification } from './notify.actions'

export function sharingListDocuments(companyId, silentError) {
	return function (dispatch) {
		return req
			.get(`/documents/sharing/documents?companyId=${companyId}${silentError ? '&silent=true' : ''}`)
			.then((response) => {
				const { data } = response

				if (data.silentError) {
					return
				}

				dispatch({ type: SHARING_DOCUMENTS_LIST, payload: fromJS(data.documents) })

				const folderData = {
					folders: data.folders,
					mapParentToChildren: data.sharedMapToChildren
				}
				dispatch({ type: SHARING_FOLDERS_LIST, payload: fromJS(folderData) })
				dispatch({
					type: FOLDERS_LIST,
					payload: fromJS({
						folders: response.data.sharedWithCompanyFolders,
						mapParentToChildren: response.data.sharedWithCompanyMapParentToChildren
					})
				})
			})
	}
}

export function fetchSharedDocument(docId, companyId, silentError) {
	return function (dispatch) {
		return req
			.get(`/documents/sharing/documents/${docId}?companyId=${companyId}${silentError ? '&silent=true' : ''}`)
			.then((response) => {
				if (response.data.silentError) {
					return
				}

				dispatch({ type: DOCUMENTS_FETCH_REMOTE, payload: fromJS(response.data) })
			})
	}
}

/**
 * Action for creating a document
 */
export function createSharedDocument(doc, folderId, createInCompanyId, callbacks) {
	const config = {
		onUploadProgress: (progressEvent) =>
			callbacks && callbacks.onUploadProgress ? callbacks.onUploadProgress(progressEvent) : null
	}

	const docId = uuid()
	doc.append('id', docId)

	const localDoc = {
		id: docId,
		createdAt: moment().toISOString(),
		isUploading: true,
		folderId
	}
	for (const prop in doc) {
		if (prop[0] !== 'file') {
			localDoc[prop[0]] = prop[1]
		}
	}

	return function (dispatch) {
		dispatch(createSharedDocumentLocal(fromJS(localDoc)))

		return req
			.post(
				`/documents/sharing/documents/${
					folderId ? `?folderId=${folderId}&companyId=${createInCompanyId}` : `?companyId=${createInCompanyId}`
				}`,
				doc,
				config
			)
			.then((response) => {
				let doc = fromJS(response.data)
				doc = doc.set('isUploading', false)
				dispatch({ type: SHARING_DOCUMENTS_UPDATE_LOCAL, payload: doc })
				callbacks && callbacks.onComplete ? callbacks.onComplete(doc) : callbacks(doc)
			})
			.catch((e) => {
				console.log(e)
				dispatch(sharingDocumentsError('documents.error.create_document'))

				if (e.response.data.translationId === 'documents.error.no_storage_space_left') {
					dispatch(hardDeleteSharedDocumentLocal(docId))
				}
			})
	}
}

/**
 * Action for saving a document
 */
export function saveSharedDocument(doc, sharedFromCompanyId) {
	return function (dispatch) {
		dispatch({ type: DOCUMENTS_SAVE_REMOTE_START, payload: doc })
		return req
			.put(`/documents/sharing/documents/${doc.get('id')}?companyId=${sharedFromCompanyId}`, doc.toJS(), {
				onlyLatest: true
			})
			.then((response) => {
				dispatch({ type: SHARING_DOCUMENTS_SAVE_REMOTE, payload: fromJS(response.data) })
			})
			.catch(() => {
				dispatch(sharingDocumentsError('documents.error.save_document'))
			})
	}
}

/**
 * Action for deleting a document
 * @param {String} id — document id
 */
export function deleteSharedDocument(id, deleteFromCompanyId, callback) {
	return function (dispatch) {
		return req
			.delete(`/documents/sharing/documents/${id}?companyId=${deleteFromCompanyId}`)
			.then(() => {
				dispatch({ type: SHARING_DOCUMENTS_DELETE_REMOTE, payload: id })
				dispatch(addInfoNotification({ tid: 'documents.notifications.info.document_deleted' }))
				callback && callback()
			})
			.catch(() => {
				dispatch(sharingDocumentsError('documents.error.delete_document'))
			})
	}
}

/**
 * Action for recover trashed document
 */
export function recoverSharedDocument(documentId, recoverFromCompanyId, callback) {
	return function (dispatch) {
		return req
			.put(`/documents/sharing/documents/${documentId}/recover?companyId=${recoverFromCompanyId}`)
			.then((response) => {
				dispatch({ type: SHARING_DOCUMENTS_SAVE_REMOTE, payload: fromJS(response.data) })
				dispatch(addInfoNotification({ tid: 'documents.notifications.info.document_recovered' }))
				callback && callback()
			})
			.catch(() => {
				dispatch(sharingDocumentsError('documents.error.save_document'))
			})
	}
}

export function createSharedDocumentLocal(doc) {
	return {
		type: SHARING_DOCUMENTS_CREATE_LOCAL,
		payload: doc
	}
}

export function updateSharedDocumentLocal(doc) {
	return function (dispatch) {
		dispatch({ type: DOCUMENTS_DOCUMENT_DIRTY })
		dispatch({ type: SHARING_DOCUMENTS_UPDATE_LOCAL, payload: doc })
	}
}

export function hardDeleteSharedDocumentLocal(docId) {
	return {
		type: SHARING_DOCUMENTS_HARD_DELETE_LOCAL,
		payload: docId
	}
}

export function sharingDocumentsError(error) {
	return addErrorNotification({
		tid: error
	})
}
