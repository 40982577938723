import React, { Component } from 'react'
import { connect } from 'react-redux'
import { oneOf, bool, func } from 'prop-types'
import { fromJS, List, Map } from 'immutable'
import { required } from '../../../../modules/field-validators'
import ScrollView from '../../../../dumb-components/shared/layout/scroll-view/scroll-view'
import { Padding } from 'styled-components-spacing'

//Form dep
import { Field, reduxForm, getFormValues } from 'redux-form'
import {
	renderField,
	renderReactSelectField,
	renderIdField,
	renderNameField,
	renderInvestorEmailField
} from '../../../helpers/redux-form--fields'
import FlexTable from '../../../framework/flex-table'
import { numberFormatInputFieldFormatter } from '../../../framework/flex-table/formatters'
import { FormattedMessage } from 'react-intl'
import RecommendInvonoPlatformField from '../../../helpers/recommend-invono-platform-field'
import debounce from 'lodash/debounce'
import { getShareTypeLabel } from '../../../helpers/shares'
import {
	fetchInvestor,
	editInvestor,
	deleteInvestor,
	archiveInvestor,
	editInvestorLocal,
	investorIsDirty,
	setSelectedInvestor,
	inviteInvestor,
	listInvestors
} from '../../../../actions/investors.actions'
import { openModal, closeModal } from '../../../../actions/modals.actions'
import { listCapitalInsurances } from '../../../../actions/capital-insurance.actions'
import { listDepos } from '../../../../actions/depos.actions'
import { isRequired } from '../../../../modules/validation.module'
import Panel from '../../../../dumb-components/shared/panel/panel'
import { validateEmail, validatePersonNumber, validateOrgNumber } from '../../../../modules/validation.module'
import ShareholdersToolbarControlsContainer from '../../../../containers/shares/shareholders-toolbar-controls.container'
import CountrySelect from '../../../../dumb-components/shared/country-select/country-select'
import Text from '../../../../dumb-components/shared/text/text'
import ConfirmContainer from '../../../../containers/shared/confirm.container'
import InvestorIdUnlockBtnContainer from '../../../../containers/shares/investor-id-unlock-btn.container'

import Modal from '../../../../dumb-components/shared/modal/modal'
import AttachmentsByObjIdContainer from '../../../../containers/shared/attachments-by-objid.container'
import { renderCommonAttachmentDropdown } from '../../../../containers/documents/attachments/predefined-dropdown-render-functions'
import EditorContainer from '../../../../containers/shared/editor.container'
import RemindShareholderModalContainer from '../../../../containers/notify-by-templates/modals/shares/remind-shareholder-modal.container'
import ColoredContentWrapper from '../../../../dumb-components/shared/colored-content-wrapper/colored-content-wrapper'
import { TransparentButton } from '../../../../dumb-components/shared/button-v2'
import FooterRightControls from '../../../../dumb-components/shared/modal/footer-right-controls'
import SimpleDarkInfoPanel from '../../../../dumb-components/shared/dark-info-panel/simple-dark-info-panel'
import {
	EVENT_TYPE_SHARES_INVESTMENT_UPDATE,
	EVENT_TYPE_SHARES_INVESTMENT_DELETE,
	OBJ_TYPE_INVESTMENT,
	INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE,
	INVESTOR_TYPE_OF_OWNER_COMPANY
} from '/shared/constants'
import { REMIND_SHAREHOLDER_EMAIL_TEMPLATE_MODAL } from '../../../../constants/modals'

import Tooltip from '../../../../dumb-components/shared/tooltip/tooltip'
import Alert from '../../../../dumb-components/shared/alert/alert'

import { withRouter } from '../../../../interfaces/router'
import investmentApi from '/shared/helpers/investment.helper'
import { InvestorsContactBook } from './investors-contact-book/investors-contact-book.container'
import clsx from 'clsx'
// eslint-disable-next-line
class InvestorsDetails extends Component {
	state = {
		confirmIsOpen: false,
		isLoading: false
	}

	static propTypes = {
		mode: oneOf(['MODAL']),
		isOpen: bool,
		onCloseModal: func,
		actionMode: oneOf(['CREATE', 'EDIT'])
	}

	static defaultProps = {
		mode: 'MODAL',
		actionMode: 'EDIT'
	}

	componentDidMount = () => {
		const {
			match: { params },
			actionMode,
			depos,
			listDepos,
			capitalInsurances,
			listCapitalInsurances
		} = this.props

		if (!depos || depos?.size === 0) {
			listDepos()
		}

		if (!capitalInsurances || capitalInsurances?.size === 0) {
			listCapitalInsurances()
		}
		actionMode === 'EDIT' && params?.id && this.fetchInvestor(params.id)
	}

	componentDidUpdate = (prevProps) => {
		const {
			match: { params },
			actionMode
		} = this.props

		this.checkLiveUpdateEvents()

		if (actionMode === 'EDIT' && params?.id !== prevProps.match.params?.id) {
			params.id && this.fetchInvestor(params.id)
		}
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('register-of-shareholders')
		return pathArray[0] + 'register-of-shareholders'
	}

	checkLiveUpdateEvents = () => {
		const {
			audit,
			match: { params },
			isModal,
			history
		} = this.props

		if (isModal) {
			return
		}

		const investorUpdate = audit.get(EVENT_TYPE_SHARES_INVESTMENT_UPDATE)
		const investorDelete = audit.get(EVENT_TYPE_SHARES_INVESTMENT_DELETE)
		const basePath = this.getComponentBasePath()

		if (investorUpdate && investorUpdate.get('refresh') === true && investorUpdate.get('objId') === params.id) {
			this.props.fetchInvestor(params.id)
		}

		if (investorDelete && investorDelete.get('refresh') === true && investorDelete.get('objId') === params.id) {
			history.push(basePath)
		}
	}

	fetchInvestor = (id) => {
		this.props.fetchInvestor(id)
		this.investorsDetailsScrollbar && this.investorsDetailsScrollbar.scrollToTop()
	}

	doDebounce = debounce((d) => {
		this.props.handleSubmit(this.onSubmit)()
	}, 1000)

	debounce = () => {
		this.props.investorIsDirty()
		this.doDebounce()
	}

	onSubmit = (props) => {
		const { selectedInvestor } = this.props
		if (selectedInvestor && selectedInvestor.get('id')) {
			this.props.editInvestor(selectedInvestor.get('id'), props)
		}
	}

	checkRequiredFields = () => {
		const { selectedInvestor } = this.props
		const requiredFields = ['investorInformation.id']
		let valid = true

		requiredFields.forEach((requiredField) => {
			const fieldPath = requiredField.split('.')
			const result = isRequired(selectedInvestor.getIn(fieldPath))

			if (!result) {
				valid = false
			}
		})

		return valid
	}

	onChange = (func, newValue, prevValue, field) => {
		const { editInvestorLocal } = this.props
		let { selectedInvestor } = this.props
		const fieldPath = field.split('.')

		if (fieldPath.includes('contacts') && Array.isArray(newValue) && newValue.length === 1) {
			selectedInvestor = selectedInvestor.set('defaultContact', newValue[0].id)
		}

		selectedInvestor = selectedInvestor.setIn(fieldPath, newValue)
		selectedInvestor = selectedInvestor.setIn(['metadata', 'edited'], true)
		editInvestorLocal(selectedInvestor)
		this.debounce()
		this.getDeposOptions()
	}

	onChangeCapitalInsurance = (func, newValue, prevValue, field) => {
		const { editInvestorLocal, capitalInsurances } = this.props
		let { selectedInvestor } = this.props
		const capitalInsuranceObj = capitalInsurances.find((cap) => cap.get('organisationNumber') === newValue)
		const name = capitalInsuranceObj.get('name')
		const organisationNumber = capitalInsuranceObj.get('organisationNumber')
		const address = capitalInsuranceObj.get('address')
		const city = capitalInsuranceObj.get('city')
		const zip = capitalInsuranceObj.get('zip')

		selectedInvestor = selectedInvestor.setIn(['investorInformation', 'name'], name)
		selectedInvestor = selectedInvestor.setIn(['investorInformation', 'id'], organisationNumber)
		selectedInvestor = selectedInvestor.setIn(['investorInformation', 'address'], address)
		selectedInvestor = selectedInvestor.setIn(['investorInformation', 'city'], city)
		selectedInvestor = selectedInvestor.setIn(['investorInformation', 'zip'], zip)
		selectedInvestor = selectedInvestor.setIn(['metadata', 'edited'], true)

		editInvestorLocal(selectedInvestor)
		this.debounce()
	}

	onChangeEditor = (fieldPath, newValue) => {
		const { editInvestorLocal } = this.props
		let { selectedInvestor } = this.props
		fieldPath = fieldPath.split('.')

		selectedInvestor = selectedInvestor.setIn(fieldPath, newValue)
		selectedInvestor = selectedInvestor.setIn(['metadata', 'edited'], true)
		editInvestorLocal(selectedInvestor)
		this.debounce()
	}

	onChangeCountry = (value) => {
		this.onChange(null, value, null, 'investorInformation.country')
	}

	onChangeCountryCaptialIncurance = (value) => {
		this.onChange(null, value, null, 'captialIncuranceOwnerInformation.country')
	}

	onChangeLinkedDocuments = (linkedDocuments) => {
		const { selectedInvestor } = this.props
		const updatedInvestor = selectedInvestor.set('linkedDocuments', linkedDocuments)
		this.props.editInvestor(updatedInvestor.get('id'), updatedInvestor.toJS())
	}

	onPrimaryContactChange = (contactId) => {
		this.onChange(undefined, contactId, undefined, 'defaultContact')
	}

	inviteShareholder = (index) => {
		const { selectedInvestor, openModal } = this.props

		openModal(REMIND_SHAREHOLDER_EMAIL_TEMPLATE_MODAL, { investor: selectedInvestor, index })
	}

	deleteShareholder = () => {
		if (this.props.selectedInvestor === null) {
			return false
		}

		this.setState({ confirmIsOpen: true })
	}

	doDeleteShareholder = () => {
		const { selectedInvestor, history } = this.props

		this.setState({ confirmIsOpen: false })

		this.props.deleteInvestor(selectedInvestor.get('id')).then(() => {
			history.push(location.pathname.replace('/' + selectedInvestor.get('id'), ''))
		})
	}

	closeConfirmModal = () => {
		this.setState({ confirmIsOpen: false })
	}

	closeModal = () => {
		const { closeModal, onCloseModal, editInvestorLocal, deleteInvestor, selectedInvestor } = this.props

		deleteInvestor(selectedInvestor.get('id'))

		onCloseModal && onCloseModal(null, 'DISMISSED')
		editInvestorLocal(null)
		closeModal()
	}

	SaveAndcloseModal = () => {
		const { closeModal, onCloseModal, editInvestorLocal, listInvestors, selectedInvestor } = this.props

		onCloseModal && onCloseModal(selectedInvestor, 'SAVED')
		editInvestorLocal(null)
		listInvestors()
		closeModal()
	}

	getComponentBasePath() {
		const pathNameArray = this.props.location.pathname.split('/')
		pathNameArray.pop()
		return pathNameArray.join('/')
	}

	capitalInsuranceCheckAllFields = () => {
		const { formValues } = this.props

		if (!formValues) {
			return false
		}

		const investorInformation = formValues.investorInformation

		if (
			investorInformation &&
			investorInformation.depositoryInstitute &&
			investorInformation.name &&
			investorInformation.insuranceNr
		) {
			return true
		}

		return false
	}

	isCapitalInsurance = () => {
		const { formValues } = this.props

		if (formValues && formValues.investorTypeOfOwner && formValues.investorTypeOfOwner === 'capitalInsurance') {
			return true
		}

		return false
	}

	handleOnClickNewInvestor = () => {
		const basePath = this.getComponentBasePath()
		this.props.history.push(basePath + '/new')
	}

	getEmailToSelectedInvestor = () => {
		const { selectedInvestor } = this.props

		if (!selectedInvestor) {
			return null
		}

		// Get investor email
		const investorEmail = investmentApi.getInvestorEmail(selectedInvestor.toJS())

		// Check if email is valid, if not, disabled button
		const disabled = !validateEmail(investorEmail)

		const mailToInvestorData = {
			mailto: `mailto:${investorEmail}`,
			email: investorEmail,
			disabled
		}

		return mailToInvestorData
	}

	getCapitalInsuranceOptions = () => {
		const { capitalInsurances } = this.props
		let options = []

		capitalInsurances.forEach((capitalInsurance) => {
			options.push({
				value: capitalInsurance.get('organisationNumber'),
				label: capitalInsurance.get('name') + ' | ' + capitalInsurance.get('organisationNumber')
			})
		})

		return options
	}

	getDeposOptions = () => {
		const { depos, selectedInvestor } = this.props
		let prefilledDepoId = selectedInvestor && selectedInvestor.getIn(['investorInformation', 'depositoryInstitute'])
		let prefilledDepo = depos.filter((depo) => depo.get('id') === prefilledDepoId).get(0)
		let options = []

		//	make sure that chosen but deleted depo shows
		prefilledDepo &&
			prefilledDepo.get('isDeleted') === true &&
			options.push({
				value: prefilledDepo.get('id'),
				label: prefilledDepo.get('name')
			})

		//	shows all other depos that are not deleted
		depos.forEach((depo) => {
			if (depo.get('isDeleted') === false) {
				options.push({
					value: depo.get('id'),
					label: depo.get('name')
				})
			}
		})

		return options
	}

	renderContactBook = ({ input, meta: { touched, error } }) => {
		const { selectedInvestor } = this.props

		const hasInvestorId = Boolean(selectedInvestor && selectedInvestor.get('investorId'))
		const defaultContact = selectedInvestor && selectedInvestor.get('defaultContact')

		return (
			<div className={clsx('form-group', 'col-md-12', { 'has-error': touched && error })}>
				<InvestorsContactBook
					onChange={input.onChange}
					values={input.value || []}
					onPrimaryContactChange={this.onPrimaryContactChange}
					isRegisteredCompany={hasInvestorId}
					defaultContact={defaultContact}
				/>
			</div>
		)
	}

	renderButtons = () => {
		const { selectedInvestor } = this.props

		if (!selectedInvestor) {
			return null
		}

		const isDeletable = selectedInvestor.get('isDeletable') ? true : false
		const hasInvestorId = selectedInvestor.get('investorId') ? true : false
		const selectedInvestmentId = selectedInvestor.get('id')

		return (
			<div className='i-content__tabs i-content__tabs--fix-padding'>
				<ShareholdersToolbarControlsContainer
					selectedInvestmentId={selectedInvestmentId}
					isLinked={hasInvestorId}
					onDeleteShareholder={this.deleteShareholder}
					deleteShareholderDisabled={!isDeletable}
				/>
			</div>
		)
	}

	renderShares = () => {
		const { selectedInvestor, panelType, panelMode, isModal } = this.props
		const types = selectedInvestor.getIn(['details', 'types'], List()).filter((type) => {
			return type.get('shares') > 0
		})

		const columns = fromJS([
			{
				prop: 'type',
				size: '6',
				formatter: getShareTypeLabel,
				header: { translationId: 'shares.class_of_shares' }
			},
			{
				prop: 'shares',
				size: '3',
				header: { translationId: 'shares_amount', className: 'text--align-right' },
				formatter: numberFormatInputFieldFormatter,
				readOnly: true,
				inputClassName: 'text--align-right'
			},
			{
				prop: 'latestPrice',
				size: '3',
				header: { translationId: 'value_per_share', className: 'text--align-right' },
				formatter: numberFormatInputFieldFormatter,
				readOnly: true,
				decimalScale: 4,
				inputClassName: 'text--align-right'
			}
		])

		return (
			<Panel title='shares' type={panelType} mode={panelMode} marginBottom={!isModal}>
				<Padding all={4} bottom={0}>
					<FlexTable columns={columns} data={types} />
					{!types && (
						<div>
							<p>
								<span className='text--headline'>
									<FormattedMessage id='no_transactions_for_this_shareholder' />
								</span>
								<br />
								<FormattedMessage id='no_transactions_for_this_shareholder_info' />
							</p>
							<p>
								<FormattedMessage id='click_on_transaction_menu_item_to_select_option' />
							</p>
						</div>
					)}
				</Padding>
			</Panel>
		)
	}

	renderManageIdToggle = () => {
		return <InvestorIdUnlockBtnContainer />
	}

	renderManageIdToggleCapital = () => {
		return <InvestorIdUnlockBtnContainer isCapitalIncurance={true} />
	}

	renderInvestor = () => {
		const { selectedInvestor, formValues, messages, i18n, isModal, panelType, panelMode } = this.props
		const hasInvestorId = selectedInvestor && selectedInvestor.get('investorId') ? true : false
		const id = selectedInvestor && selectedInvestor.getIn(['investorInformation', 'id'])
		const capitalInsuranceId =
			selectedInvestor && selectedInvestor.getIn(['captialIncuranceOwnerInformation', 'id'])

		const hasSwedishId = validatePersonNumber(id) || validateOrgNumber(id)
		const hasSwedishIdCapital = validatePersonNumber(capitalInsuranceId) || validateOrgNumber(capitalInsuranceId)
		const addressFromProfile =
			(selectedInvestor && investmentApi.getInvestorProperty(selectedInvestor.toJS(), 'addressFromProfile')) ||
			false
		const phoneFromProfile =
			(selectedInvestor && investmentApi.getInvestorProperty(selectedInvestor.toJS(), 'phoneFromProfile')) ||
			false
		const isActiveUser = selectedInvestor && selectedInvestor.getIn(['computed', 'isActiveUser'])
		const isActiveCompany = selectedInvestor && selectedInvestor.getIn(['computed', 'isActiveCompany'])
		const investorTypeOfOwner = selectedInvestor && selectedInvestor.get('investorTypeOfOwner')
		const isUnregistered = selectedInvestor && selectedInvestor.getIn(['computed', 'isUnregistered'])
		let idTid = 'foreign_owner_free_text'
		const validators = [required]
		const refPlaceholderTid = 'generic.ssn_placeholder'
		let namePlaceholderTid = 'generic.name_placeholder'
		let chosen

		const options = [
			{ value: 'private', label: messages['shares.type_of_owner.private'] },
			{ value: 'company', label: messages['shares.type_of_owner.company'] },
			{ value: 'capitalInsurance', label: messages['shares.capital_insurance'] }
		]

		const capitalInsuranceOptions = this.getCapitalInsuranceOptions()
		const deposOptions = this.getDeposOptions()
		const checkIsCapitalInsurance = this.isCapitalInsurance()
		const checkCapitalInsuranceFields = this.capitalInsuranceCheckAllFields()

		switch (formValues && formValues.investorTypeOfOwner) {
			case 'private':
				idTid = 'investors.personal_number_or_id'
				chosen = messages['investors.filter.investor_type_of_owner.private']
				break
			case 'company':
				idTid = 'investors.org_number_or_id'
				namePlaceholderTid = 'generic.company_name'
				chosen = chosen = messages['investors.filter.investor_type_of_owner.company']
				break
			case 'capitalInsurance':
				chosen = chosen = messages['investors.filter.investor_type_of_owner.capital_insurance']
				break
		}

		const typeOfOwner = formValues && formValues.investorTypeOfOwner

		if (checkIsCapitalInsurance) {
			return (
				<>
					<Panel
						title={isModal ? 'investments.details.panel.title.owner' : ''}
						type={panelType}
						mode={panelMode}
						marginBottom={!isModal}>
						<div style={isModal ? { margin: '0 -16px' } : { paddingTop: '16px' }}>
							<Field
								name='investorTypeOfOwner'
								translationId='type_of_owner'
								clearable={false}
								className='form-control'
								options={options}
								disabled={true}
								onChange={this.onChange}
								validate={[required]}
								component={renderReactSelectField}
							/>

							<Alert mode='info' icon='faCheck' iconType='solid' margin='15px'>
								<Text
									color='white'
									tid='shares.chosen_type_of_owner'
									values={{ typeOfOwner: chosen }}
								/>
							</Alert>

							<Field
								name='investorInformation.depositoryInstitute'
								translationId='investments.capital_insurance.depository_institution'
								clearable={false}
								className='form-control'
								options={deposOptions}
								onChange={this.onChange}
								validate={validators}
								component={renderReactSelectField}
							/>

							<Field
								name='investorInformation.id'
								translationId='investments.capital_insurance.insurance_company'
								clearable={false}
								className='form-control'
								onChange={this.onChangeCapitalInsurance}
								options={capitalInsuranceOptions}
								component={renderReactSelectField}
								validate={validators}
							/>

							<Field
								name='investorInformation.insuranceNr'
								type='text'
								translationId='investments.capital_insurance.insurance_nr'
								placeholder='investments.capital_insurance.insurance_nr'
								className='form-control'
								onChange={this.onChange}
								component={renderNameField}
								validate={validators}
							/>
						</div>
					</Panel>
					{checkCapitalInsuranceFields && (
						<Panel
							title='investments.capital_insurance.insurance_owner'
							type={panelType}
							mode={panelMode}
							marginBottom={!isModal}>
							<div style={isModal ? { margin: '0 -16px' } : { paddingTop: '16px' }}>
								<Field
									name='captialIncuranceOwnerInformation.name'
									type='text'
									translationId='generic.name'
									className='form-control'
									placeholder='generic.name_placeholder'
									onChange={this.onChange}
									disabled={hasInvestorId}
									hasInvestorId={hasInvestorId}
									component={renderNameField}
								/>

								<Field
									name='captialIncuranceOwnerInformation.id'
									type='text'
									translationId='investors.personal_number_or_id'
									rightLabelComponent={this.renderManageIdToggleCapital}
									placeholder='generic.ssn_placeholder'
									className='form-control'
									onChange={this.onChange}
									disabled={hasSwedishIdCapital}
									hasSwedishId={hasSwedishIdCapital}
									component={renderIdField}
								/>

								<Field
									name='captialIncuranceOwnerInformation.email'
									type='email'
									disabled={hasInvestorId}
									labelTid='generic.email'
									placeholder='generic.email'
									className='form-control'
									onChange={this.onChange}
									hasInvestorId={hasInvestorId}
									hideInformButton={isModal}
									notified={selectedInvestor && selectedInvestor.get('notified')}
									objId={selectedInvestor && selectedInvestor.get('id')}
									isActiveUser={isActiveUser}
									isActiveCompany={isActiveCompany}
									isUnregistered={isUnregistered}
									onNotify={this.inviteShareholder}
									investorTypeOfOwner={typeOfOwner}
									component={renderInvestorEmailField}
								/>

								<Field
									name='captialIncuranceOwnerInformation.address'
									type='text'
									translationId='generic.address'
									placeholder='generic.address'
									className='form-control'
									onChange={this.onChange}
									component={renderField}
									disabled={addressFromProfile}
								/>

								<Field
									name='captialIncuranceOwnerInformation.zip'
									type='text'
									translationId='zip'
									placeholder='zip'
									className='form-control'
									onChange={this.onChange}
									component={renderField}
									disbled={addressFromProfile}
								/>

								<Field
									name='captialIncuranceOwnerInformation.city'
									type='text'
									translationId='generic.city'
									placeholder='generic.city'
									className='form-control'
									onChange={this.onChange}
									component={renderField}
									disabled={addressFromProfile}
								/>

								<div className='form-group col-md-12'>
									<label className='control-label'>
										<Text tid='country' />
									</label>
									<CountrySelect
										onChange={this.onChangeCountryCaptialIncurance}
										value={
											selectedInvestor &&
											selectedInvestor.getIn(['captialIncuranceOwnerInformation', 'country'])
										}
										placeholderTid='country'
										language={i18n.language}
										legacyDisabledColor={true}
									/>
								</div>

								<Field
									name='captialIncuranceOwnerInformation.telephone'
									type='text'
									translationId='generic.telephone'
									placeholder='generic.telephone_placeholder'
									className='form-control'
									onChange={this.onChange}
									component={renderField}
									disabled={phoneFromProfile}
								/>
							</div>
						</Panel>
					)}
				</>
			)
		}

		return (
			<Panel
				title={!isModal ? 'investments.details.panel.title.owner' : ''}
				type={panelType}
				mode={panelMode}
				marginBottom={!isModal}>
				<div style={isModal ? { margin: '0 -16px' } : { paddingTop: '16px' }}>
					<Field
						name='investorTypeOfOwner'
						translationId='type_of_owner'
						clearable={false}
						className='form-control'
						options={options}
						disabled={typeOfOwner}
						onChange={this.onChange}
						validate={[required]}
						component={renderReactSelectField}
					/>

					{typeOfOwner && (
						<Alert mode='info' icon='faCheck' iconType='solid' margin='15px'>
							<Text color='white' tid='shares.chosen_type_of_owner' values={{ typeOfOwner: chosen }} />
						</Alert>
					)}

					<Field
						name='investorInformation.name'
						type='text'
						translationId='generic.name'
						className='form-control'
						placeholder={namePlaceholderTid}
						onChange={this.onChange}
						disabled={hasInvestorId}
						hasInvestorId={hasInvestorId}
						investorTypeOfOwner={investorTypeOfOwner}
						component={renderNameField}
					/>

					<Field
						name='investorInformation.id'
						type='text'
						translationId={idTid}
						rightLabelComponent={this.renderManageIdToggle}
						placeholder={refPlaceholderTid}
						className='form-control'
						onChange={this.onChange}
						disabled={hasSwedishId}
						hasSwedishId={hasSwedishId}
						validate={validators}
						component={renderIdField}
					/>

					<Field
						name='investorInformation.email'
						type='email'
						disabled={hasInvestorId}
						labelTid={'generic.email'}
						placeholder='generic.email'
						className='form-control'
						onChange={this.onChange}
						hideInformButton={isModal}
						hasInvestorId={hasInvestorId}
						notified={selectedInvestor && selectedInvestor.get('notified')}
						objId={selectedInvestor && selectedInvestor.get('id')}
						isActiveUser={isActiveUser}
						isActiveCompany={isActiveCompany}
						isUnregistered={isUnregistered}
						onNotify={this.inviteShareholder}
						investorTypeOfOwner={typeOfOwner}
						component={renderInvestorEmailField}
					/>

					<Field
						name='investorInformation.address'
						type='text'
						translationId='generic.address'
						placeholder='generic.address'
						className='form-control'
						onChange={this.onChange}
						disabled={addressFromProfile}
						component={renderField}
					/>

					<Field
						name='investorInformation.zip'
						type='text'
						translationId='zip'
						placeholder='zip'
						className='form-control'
						onChange={this.onChange}
						disabled={addressFromProfile}
						component={renderField}
					/>

					<Field
						name='investorInformation.city'
						type='text'
						translationId='generic.city'
						placeholder='generic.city'
						className='form-control'
						onChange={this.onChange}
						disabled={addressFromProfile}
						component={renderField}
					/>

					<div className='form-group col-md-12'>
						<label className='control-label'>
							<Text tid='country' />
						</label>
						<CountrySelect
							onChange={this.onChangeCountry}
							value={selectedInvestor && selectedInvestor.getIn(['investorInformation', 'country'])}
							placeholderTid='country'
							disabled={addressFromProfile}
							language={i18n.language}
							legacyDisabledColor={true}
						/>
					</div>

					<Field
						name='investorInformation.telephone'
						type='text'
						translationId='generic.telephone'
						placeholder='generic.telephone_placeholder'
						className='form-control'
						onChange={this.onChange}
						disabled={phoneFromProfile}
						component={renderField}
					/>

					<Field
						name='investorInformation.bankInfo'
						type='text'
						translationId='generic.bank_info'
						placeholder='generic.bank_info_placeholder'
						className='form-control'
						onChange={this.onChange}
						component={renderField}
					/>

					{formValues?.investorTypeOfOwner === INVESTOR_TYPE_OF_OWNER_COMPANY && (
						<Field
							name='investorInformation.contacts'
							onChange={this.onChange}
							component={this.renderContactBook}
						/>
					)}
				</div>
			</Panel>
		)
	}

	renderNotes = () => {
		const { selectedInvestor, panelType, panelMode, isModal } = this.props
		const investmentId = selectedInvestor && selectedInvestor.get('id')

		return (
			<Panel title='generic.notes' type={panelType} mode={panelMode} marginBottom={!isModal}>
				<Padding all={isModal ? 0 : 4} bottom={isModal ? 3 : 0}>
					<p />
					<EditorContainer
						fieldName={'investorInformation.notes'}
						objId={investmentId}
						contentState={selectedInvestor && selectedInvestor.getIn(['investorInformation', 'notes'])}
						onChange={this.onChangeEditor}
					/>
				</Padding>
			</Panel>
		)
	}

	/**
	 * The render function
	 * Renders The invitation field.
	 */
	renderInvitationField = () => {
		const { selectedInvestor } = this.props
		if (selectedInvestor.getIn(['investorInformation', 'accountStatus'])) {
			return null
		}

		return (
			<RecommendInvonoPlatformField
				key={selectedInvestor.get('id')}
				invitationMessage='not_registered_text'
				emailType='refer-user-to-join-invono-as-an-added-shareholder-to-company'
			/>
		)
	}

	renderAttachments = () => {
		const { selectedInvestor, isModal } = this.props
		const investmentId = selectedInvestor && selectedInvestor.get('id')

		return (
			<AttachmentsByObjIdContainer
				objType={OBJ_TYPE_INVESTMENT}
				objId={investmentId}
				nakedStyle={isModal}
				renderAttachmentDropdown={renderCommonAttachmentDropdown}
			/>
		)
	}

	renderRemindShareholderModalContainer = () => {
		return <RemindShareholderModalContainer />
	}

	renderContent = () => {
		const { selectedInvestor, isModal, actionMode } = this.props
		const checkIsCapitalInsurance = this.isCapitalInsurance()
		const checkCapitalInsuranceFields = this.capitalInsuranceCheckAllFields()

		if (actionMode === 'EDIT' && !selectedInvestor) {
			return null
		}

		if (isModal) {
			if (checkIsCapitalInsurance) {
				return (
					<>
						<ColoredContentWrapper
							type='secondary'
							marginBottom={4}
							flexDirection='column'
							paddingLeft={5}
							paddingRight={5}>
							{this.renderInvestor()}
						</ColoredContentWrapper>
						{checkCapitalInsuranceFields && (
							<>
								<ColoredContentWrapper
									type='secondary'
									marginBottom={4}
									paddingLeft={5}
									paddingRight={5}>
									{this.renderNotes()}
								</ColoredContentWrapper>
								<ColoredContentWrapper
									type='secondary'
									flexDirection='column'
									marginBottom={4}
									paddingTop={5}
									paddingLeft={5}
									paddingRight={5}
									paddingBottom={5}>
									{this.renderAttachments()}
								</ColoredContentWrapper>
								{this.renderRemindShareholderModalContainer()}
							</>
						)}
					</>
				)
			}

			return (
				<>
					<ColoredContentWrapper type='secondary' marginBottom={4} paddingLeft={5} paddingRight={5}>
						{this.renderInvestor()}
					</ColoredContentWrapper>
					<ColoredContentWrapper type='secondary' marginBottom={4} paddingLeft={5} paddingRight={5}>
						{this.renderNotes()}
					</ColoredContentWrapper>
					<ColoredContentWrapper
						type='secondary'
						flexDirection='column'
						marginBottom={4}
						paddingTop={5}
						paddingLeft={5}
						paddingRight={5}
						paddingBottom={5}>
						{this.renderAttachments()}
					</ColoredContentWrapper>
					{this.renderRemindShareholderModalContainer()}
				</>
			)
		}

		if (checkIsCapitalInsurance) {
			return (
				<>
					{this.renderInvestor()}
					{checkCapitalInsuranceFields && (
						<>
							{!isModal && this.renderShares()}
							{this.renderNotes()}
							{this.renderAttachments()}
							{this.renderRemindShareholderModalContainer()}
						</>
					)}
				</>
			)
		}

		return (
			<>
				{this.renderInvestor()}
				{!isModal && this.renderShares()}
				{this.renderNotes()}
				{this.renderAttachments()}
				{this.renderRemindShareholderModalContainer()}
			</>
		)
	}

	renderBody = () => {
		const { handleSubmit, isModal } = this.props
		const { confirmIsOpen } = this.state

		return (
			<>
				<form
					onSubmit={handleSubmit(this.onSubmit)}
					autoComplete='off'
					encType='multipart/form-data'
					className='i-content__container'>
					{isModal ? (
						this.renderContent()
					) : (
						<>
							{this.renderButtons()}
							<ScrollView
								scrollbarRef={(r) => (this.investorsDetailsScrollbar = r)}
								autoHide={true}
								showOnHover={true}>
								{this.renderContent()}
							</ScrollView>
							<ConfirmContainer
								title='delete_shareholder'
								message='shareholders.delete_shareholder.confirm_modal.info'
								onConfirm={this.doDeleteShareholder}
								onDecline={this.closeConfirmModal}
								isOpen={confirmIsOpen}
							/>
						</>
					)}
				</form>
			</>
		)
	}

	renderModalFooter = () => {
		const { isLoading } = this.state
		return (
			<FooterRightControls>
				<TransparentButton
					tid='generic.modal.save_and_close'
					onClick={this.SaveAndcloseModal}
					isLoading={isLoading}
				/>
				<TransparentButton
					tid='generic.form.cancel'
					textColor='midGrey'
					onClick={this.closeModal}
					disabled={isLoading}
				/>
			</FooterRightControls>
		)
	}

	render = () => {
		const { isModal, isOpen } = this.props

		if (isModal) {
			return (
				<Modal
					hSize='lg'
					isOpen={isOpen}
					title='shares.transaction.shareholders_modal.title'
					footerComponent={this.renderModalFooter()}>
					{this.renderBody()}
				</Modal>
			)
		}

		return this.renderBody()
	}
}

const formId = 'InvestorsDetails'
/** Map state to props. */
function mapStateToProps(state, ownProps) {
	return {
		user: state.user.get('userObj'),
		messages: state.i18n.messages,
		selectedInvestor: state.investors.get('selectedInvestor'),
		visibleInvestors: state.investors.get('visibleInvestors'),
		investors: state.investors.get('list'),
		hasAppliedFilters: state.investors.get('hasAppliedFilters'),
		company: state.company.company,
		formValues: getFormValues(formId)(state),
		initialValues: state.investors.get('selectedInvestor') ? state.investors.get('selectedInvestor').toJS() : {},
		i18n: state.i18n,
		audit: state.audit.get('investors'),
		isModal: ownProps.mode === 'MODAL',
		panelType: ownProps.mode === 'MODAL' ? 'inline' : undefined,
		panelMode: ownProps.mode === 'MODAL' ? 'transparent' : undefined,
		companyId: state.company.company.id,
		capitalInsurances: state.capitalInsurance.get('capitalInsurances'),
		depos: state.depos.get('depos')
	}
}

InvestorsDetails = reduxForm({
	form: formId,
	enableReinitialize: true
})(InvestorsDetails)

const mapActionsToProps = {
	fetchInvestor,
	editInvestor,
	deleteInvestor,
	archiveInvestor,
	editInvestorLocal,
	investorIsDirty,
	setSelectedInvestor,
	inviteInvestor,
	closeModal,
	openModal,
	listCapitalInsurances,
	listDepos,
	listInvestors
}

InvestorsDetails = withRouter(connect(mapStateToProps, mapActionsToProps)(InvestorsDetails))
export default InvestorsDetails
