import React from 'react'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'
import { getStore } from '../../store'
import InvestorEmailsField from '../../dumb-components/shares/investor-enails-field/investor-emails-field'
import Label from '../../dumb-components/shared/label/label'
const store = getStore()
/**
 * Renders a "standard" field.
 */
export const renderField = ({
	input,
	id,
	translationId,
	translationPlaceholders,
	icon,
	autoFocus,
	iconClass,
	readonly,
	disabled,
	type,
	placeholder,
	className,
	wrapperClassName,
	meta: { touched, error }
}) => {
	const wrapperClass = wrapperClassName || 'col-md-12'
	const autoFocusProp = autoFocus || false
	const i18n = store.getState().i18n.messages
	const translationPlaceholderObj = translationPlaceholders || {}

	return (
		<div className={`form-group ${wrapperClass} ${touched && error ? 'has-error' : ''}`}>
			{translationId && (
				<label className='control-label' htmlFor={id}>
					<FormattedMessage id={translationId} values={translationPlaceholderObj} />
				</label>
			)}
			<div className={icon === true ? 'input-group' : ' '}>
				<input
					{...input}
					id={id}
					placeholder={i18n[placeholder]}
					autoFocus={autoFocusProp}
					disabled={disabled || readonly}
					className={className}
					type={type}
				/>

				{icon === true ? (
					<span className='input-group-addon'>
						<i className={iconClass}></i>
					</span>
				) : null}
			</div>
			{touched && error && (
				<small className='help-block'>
					<FormattedMessage id={error} />
				</small>
			)}
		</div>
	)
}

/**
 * Renders a "field with checkmark"
 */
export const renderNameField = ({
	input,
	id,
	translationId,
	investorTypeOfOwner,
	autoFocus,
	hasInvestorId,
	readonly,
	disabled,
	type,
	placeholder,
	className,
	wrapperClassName,
	meta: { touched, error }
}) => {
	const wrapperClass = wrapperClassName || 'col-md-12'
	const autoFocusProp = autoFocus || false
	const i18n = store.getState().i18n.messages
	return (
		<div className={`form-group has-feedback ${wrapperClass} ${touched && error ? 'has-error' : ''}`}>
			<Label tid={translationId}>
				<input
					{...input}
					id={id}
					placeholder={i18n[placeholder]}
					autoFocus={autoFocusProp}
					disabled={disabled || readonly}
					className={className}
					type={type}
				/>
				{investorTypeOfOwner === 'company' && hasInvestorId && (
					<span className='form-control-feedback text--success' style={{ top: '24px' }}>
						<FormattedMessage id='shares.shareholders.registered' /> <i className='fa fa-check'></i>
					</span>
				)}

				{touched && error && (
					<small className='help-block'>
						<FormattedMessage id={error} />
					</small>
				)}
			</Label>
		</div>
	)
}

/**
 * Renders a "field with checkmark"
 */
export const renderIdField = ({
	input,
	id,
	translationId,
	rightLabelComponent,
	translationPlaceholders,
	autoFocus,
	readonly,
	disabled,
	hasSwedishId,
	type,
	placeholder,
	className,
	wrapperClassName,
	meta: { touched, error }
}) => {
	const wrapperClass = wrapperClassName || 'col-md-12'
	const autoFocusProp = autoFocus || false
	const i18n = store.getState().i18n.messages
	const translationPlaceholderObj = translationPlaceholders || {}

	if (rightLabelComponent) {
		return (
			<div className={`form-group has-feedback ${wrapperClass} ${touched && error ? 'has-error' : ''}`}>
				<Label tid={translationId} rightComponent={rightLabelComponent}>
					<input
						{...input}
						id={id}
						placeholder={i18n[placeholder]}
						autoFocus={autoFocusProp}
						disabled={disabled || readonly}
						className={className}
						type={type}
					/>
					{hasSwedishId && (
						<span className='form-control-feedback text--success' style={{ top: '24px' }}>
							<FormattedMessage id='shares.shareholders.swedish_id' /> <i className='fa fa-check'></i>
						</span>
					)}

					{touched && error && (
						<small className='help-block'>
							<FormattedMessage id={error} />
						</small>
					)}
				</Label>
			</div>
		)
	}

	return (
		<div className={`form-group has-feedback ${wrapperClass} ${touched && error ? 'has-error' : ''}`}>
			{translationId && (
				<label className='control-label' htmlFor={id}>
					<FormattedMessage id={translationId} values={translationPlaceholderObj} />
				</label>
			)}

			<input
				{...input}
				id={id}
				placeholder={i18n[placeholder]}
				autoFocus={autoFocusProp}
				disabled={disabled || readonly}
				className={className}
				type={type}
			/>
			{hasSwedishId && (
				<span className='form-control-feedback text--success'>
					<FormattedMessage id='shares.shareholders.swedish_id' /> <i className='fa fa-check'></i>
				</span>
			)}

			{touched && error && (
				<small className='help-block'>
					<FormattedMessage id={error} />
				</small>
			)}
		</div>
	)
}

/**
 * Renders a React Select field.
 */
export const renderReactSelectField = ({
	disabled,
	input,
	id,
	options,
	multiple,
	placeholder,
	creatable,
	clearable,
	translationId,
	readonly,
	className,
	wrapperClassName,
	isLoading,
	optionComponent,
	valueComponent,
	menuDirection,
	meta: { touched, error }
}) => {
	const props = {}
	const wrapperClass = wrapperClassName || 'col-md-12'
	const i18n = store.getState().i18n.messages

	menuDirection = menuDirection || 'down'
	className = className || ''

	if (menuDirection === 'up') {
		className += ' Select--up'
	}

	props.searchPromptText = i18n.searchPromptText
	props.loadingPlaceholder = i18n.loadingPlaceholder
	props.noResultsText = i18n.noResultsText
	props.addLabelText = 'Skapa ${label}'

	if (placeholder) {
		props.placeholder = i18n[placeholder]
	} else {
		props.placeholder = creatable ? i18n.select_or_create_placeholder : i18n.select_placeholder
	}

	if (multiple) {
		props.multi = true
	}

	if (optionComponent) {
		props.optionComponent = optionComponent
	}

	if (valueComponent) {
		props.valueComponent = valueComponent
	}

	return (
		<div className={`form-group ${wrapperClass} ${touched && error ? 'has-error' : ''}`}>
			{translationId && (
				<label className='control-label' htmlFor={id}>
					<FormattedMessage id={translationId} />
				</label>
			)}
			{creatable ? (
				<Select.Creatable
					{...input}
					{...props}
					id={id}
					promptTextCreator={(inp) => {
						return `Skapa: "${inp}"`
					}}
					disabled={readonly || disabled}
					clearable={clearable}
					ignoreAccents={false}
					className={className}
					options={options}
					simpleValue={true}
					onBlur={(event, val) => {
						input.onBlur(val)
					}}
				/>
			) : (
				<Select
					{...input}
					{...props}
					id={id}
					disabled={readonly || disabled}
					isLoading={isLoading}
					className={className}
					clearable={clearable}
					ignoreAccents={false}
					options={options}
					simpleValue={true}
					onBlur={(event, val) => {
						input.onBlur(val)
					}}
				/>
			)}
			{touched && error && (
				<small className='help-block'>
					<FormattedMessage id={error} />
				</small>
			)}
		</div>
	)
}

/**
 * Renders a investor email addresses field.
 */
export const renderInvestorEmailField = ({
	input,
	id,
	labelTid,
	notified,
	readonly,
	disabled,
	placeholder,
	className,
	wrapperClassName,
	meta,
	objId,
	hasInvestorId,
	isActiveUser,
	isActiveCompany,
	isUnregistered,
	onNotify,
	investorTypeOfOwner,
	onDelete,
	hideInformButton
}) => {
	const i18n = store.getState().i18n.messages

	return (
		<InvestorEmailsField
			fieldName={input.name}
			email={input.value || ''}
			onChange={input.onChange}
			id={id}
			labelTid={labelTid}
			notified={notified}
			readonly={readonly || disabled}
			placeholder={placeholder}
			className={className}
			wrapperClassName={wrapperClassName}
			meta={meta}
			i18n={i18n}
			hideInformButton={hideInformButton}
			objId={objId}
			hasInvestorId={hasInvestorId}
			isActiveUser={isActiveUser}
			isActiveCompany={isActiveCompany}
			isUnregistered={isUnregistered}
			onNotify={onNotify}
			investorTypeOfOwner={investorTypeOfOwner}
			onDelete={onDelete}
		/>
	)
}
