import React, { PureComponent } from 'react'
import range from 'lodash/range'
import { func, string, number, oneOfType, bool } from 'prop-types'
import moment from '../../../modules/moment.module'
import { Select } from '../select'
import { fromJS } from 'immutable'
import { list } from 'react-immutable-proptypes'

class YearSelect extends PureComponent {
	static propTypes = {
		fieldName: string,
		value: oneOfType([string, number]),
		onChange: func,
		startYear: number,
		disabled: bool,
		placeholderTid: string,
		hasError: list
	}

	static defaultProps = {
		startYear: moment().year()
	}

	getOptions = () => {
		const startYear = this.props.startYear ? this.props.startYear : moment().year()

		const years = range(startYear, startYear + 10).map((item) => {
			return {
				value: item,
				label: item
			}
		})

		return years
	}

	render = () => {
		const { value, fieldName, onChange, disabled, hasError, placeholderTid } = this.props
		const years = this.getOptions()

		return (
			<Select
				value={value}
				options={fromJS(years)}
				fieldName={fieldName}
				onChange={onChange}
				isDisabled={disabled}
				hasError={hasError}
				placeholderTid={placeholderTid}
			/>
		)
	}
}

export default YearSelect
