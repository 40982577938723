import { func } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePeopleModalContext } from '../people-modal.context'
import MuiDialog from '../../../../mui-components/dialog/mui-dialog'
import { fetchRoles } from '../../../../actions/user-management.actions'
import { TextField, Box, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PEOPLE_MODAL_FILTERS_TEAMS } from '../constants/filters'
import { filterMembersAndInvestors } from '../../../../actions/people.actions'

function RolesFilterModal({ onClose }) {
	const { filters, setFilters } = usePeopleModalContext()
	const [filtersInternally, setFiltersInternally] = useState({})
	const filterValues =
		filtersInternally[PEOPLE_MODAL_FILTERS_TEAMS] || filters[PEOPLE_MODAL_FILTERS_TEAMS] || 'SHOW_ALL'
	const roles = useSelector((state) => state.company.userManagement.roles)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		dispatch(fetchRoles())

		return () => {}
	}, [])

	const onChange = (event) => {
		const value = event.target.value

		if (value === 'SHOW_ALL') {
			delete filtersInternally[PEOPLE_MODAL_FILTERS_TEAMS]
			setFiltersInternally({ ...filtersInternally })
		} else {
			setFiltersInternally({ ...filtersInternally, [PEOPLE_MODAL_FILTERS_TEAMS]: value })
		}
	}

	const onApply = () => {
		setFilters({ ...filtersInternally })
		dispatch(filterMembersAndInvestors(filtersInternally[PEOPLE_MODAL_FILTERS_TEAMS]))
		onClose()
	}

	return (
		<MuiDialog
			title={t('people_modal.teams_filter.title')}
			isOpen={true}
			onClose={onClose}
			onConfirm={onApply}
			confirmButtonTid='people_modal.teams_filter.btn_apply'
			maxWidth='sm'
			fullWidth
			autoHeight>
			<Box sx={{ pb: '34px' }}>
				<TextField
					onChange={onChange}
					label={t('people_modal.teams_filter.select_teams')}
					variant='standard'
					fullWidth
					select
					value={filterValues}>
					<MenuItem value='SHOW_ALL'>{t('generic.show_all')}</MenuItem>
					{roles &&
						roles.map((role) => (
							<MenuItem key={role.id} value={role.name}>
								{role.name}
							</MenuItem>
						))}
				</TextField>
			</Box>
		</MuiDialog>
	)
}

RolesFilterModal.propTypes = {
	onClose: func.isRequired
}

export { RolesFilterModal }
