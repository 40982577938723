import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'
import Immutable, { Map, List, fromJS } from 'immutable'

import {
	StyledListItem,
	StyledName,
	StyledNotifySwitch
} from '../../../dumb-components/documents/request-esign/notify-about-signed-doc.template'
import ToggleSwitch from '../../../dumb-components/shared/toggle-switch/toggle-switch'
import Text from '../../../dumb-components/shared/text/text'

import { saveMeeting, publishProtocolWithoutEsign } from '../../../actions/meetings.actions'
import {
	generateDocumentFile,
	prependDocumentToMerge,
	clearDocumentsToMerge,
	storeDocumentsToMerge
} from '../../../actions/documents.actions'
import { fetchMultipleAttachedDocuments } from '../../../actions/attachments.actions'
import { openModal } from '../../../actions/modals.actions'
import { setActiveTab } from '../../../actions/notify.actions'

import { flattenAgendaItems } from '../../../components/helpers/meeting.helper'
import NotifyAboutSignedProtocolContainer from '../protocol-esigning/components/document-request-esign-modal/notify-about-signed-protocol.container'
import RequestEsignAdvancedModalContainer from '../../documents/request-esign/request-esign-advanced-modal.container'
import { MEETINGS_STATUS_PROTOCOL_PUBLISHED } from '../../../constants/meetings'
import { SIMPLE_CONFIRMATION_MODAL } from '../../../constants/modals'
import {
	MEETING_TYPE_SMART,
	MEETING_TYPE_STANDARD,
	PDFKIND_PROTOCOL,
	PROTOCOL_PUBLISHING_TYPE_WITHOUT_ESIGNING,
	PROTOCOL_PUBLISHING_TYPE_ESIGNING,
	OBJ_TYPE_MEETING_AGENDA,
	OBJ_TYPE_MEETING_ATTACHMENT,
	OBJ_TYPE_MEETING_PROTOCOL,
	OBJ_TYPE_MEETING
} from '/shared/constants'
import { TAB_STANDARD_MEETING_PROTOCOL } from '../../../constants/tabs'
import history from '../../../interfaces/history'

const TOOLTIP_STATES_SMART = {
	disabledBecauseOfFeedbackAndSignoff: {
		tid: 'meetings.protocol.toolbar.tooltip.disabled_because_of_feedback_and_signoff',
		delayShow: 'instant'
	},
	disabledBecauseOfFeedback: {
		tid: 'meetings.protocol.toolbar.tooltip.disabled_because_of_feedback',
		delayShow: 'instant'
	},
	disabledBecauseOfSignoff: {
		tid: 'meetings.protocol.toolbar.tooltip.disabled_because_of_signoff',
		delayShow: 'instant'
	},
	protocolAlreadyPublished: {
		tid: 'meetings.protocol.toolbar.tooltip.already_published',
		delayShow: 'instant'
	},
	disabledBecauseOfActiveFeedbackees: {
		tid: 'meetings.protocol.toolbar.tooltip.disabled_because_of_active_feedbackees',
		delayShow: 'instant'
	},
	processPending: {
		tid: 'meetings.general.toolbar.dropdown.publish_without_esign.tooltip.pending',
		delayShow: 'instant'
	},
	disabledBecauseOfActiveSignatories: {
		tid: 'meetings.protocol.toolbar.tooltip.disabled_because_of_active_signees',
		delayShow: 'instant'
	},
	disabled: {
		tid: 'meetings.protocol.toolbar.tooltip.unavailable',
		delayShow: 'instant'
	}
}

const TOOLTIP_STATES_STANDARD = {
	processPending: {
		tid: 'meetings.general.toolbar.dropdown.publish_without_esign.tooltip.pending',
		delayShow: 'instant'
	},
	noProtocolUploaded: {
		tid: 'meeting.protocol.publish.both.no_protocol_uploaded',
		delayShow: 'instant'
	},
	notAvailable: {
		tid: 'meeting.protocol.publish.both.not_available',
		delayShow: 'instant'
	},
	protocolPublished: {
		tid: 'meeting.protocol.publish.both.protocol_published',
		delayShow: 'instant'
	},
	notModerator: {
		tid: 'meetings.standard.toolbar.tooltip.only_mod_can_publish_protocol',
		delayShow: 'instant'
	}
}

const INITIAL_DATA_STATE = Map({
	isLoading: false,
	usersToNotify: Map()
})

class PublishProtocolComponentContainer extends Component {
	state = {
		isDisabled: false,
		activeState: null,
		modalIsOpen: false,
		data: INITIAL_DATA_STATE
	}

	static propTypes = {
		renderComponent: func,
		onChangeState: func,
		redirectTo: string
	}

	componentDidMount = () => {
		const { docUsersToNotify } = this.props

		this.recalculate()

		this.setState({ data: this.state.data.set('usersToNotify', docUsersToNotify) })
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { signatories, feedbackees, isSmartMeeting, document } = this.props
		const { modalIsOpen } = this.state
		const signatoriesUpdated = !Immutable.is(signatories, prevProps.signatories)
		const feedbackeesUpdated = !Immutable.is(feedbackees, prevProps.feedbackees)

		if (signatoriesUpdated || feedbackeesUpdated) {
			this.recalculate()
		} else if (this.props.meetingSelected !== prevProps.meetingSelected) {
			this.recalculate()
		} else if (this.props.feedbackeesTick !== prevProps.feedbackeesTick) {
			this.recalculate()
		} else if (this.props.protocolPublished !== prevProps.protocolPublished) {
			this.recalculate()
		} else if (this.props.signeesTick !== prevProps.signeesTick) {
			this.recalculate()
		} else if (this.props.publishingType !== prevProps.publishingType) {
			this.recalculate()
		} else if (this.props.protocolId !== prevProps.protocolId) {
			this.recalculate()
		}

		if (isSmartMeeting && !prevProps.document && document) {
			this.fetchAttachments()
		} else if (modalIsOpen && modalIsOpen !== prevState.modalIsOpen) {
			this.fetchAttachments()
		}
	}

	componentWillUnmount = () => {
		const { clearDocumentsToMerge } = this.props
		clearDocumentsToMerge()
	}

	recalculate = () => {
		const { isSmartMeeting, isStandardMeeting, onChangeState } = this.props
		const { isDisabled, activeState } = this.state
		let newIsDisabled
		let newActiveState
		const newState = {}

		if (isSmartMeeting) {
			newIsDisabled = this.getSmartMeetingIsDisabled()
			newActiveState = this.getSmartActiveTooltipState()
		} else if (isStandardMeeting) {
			newIsDisabled = this.getStandardMeetingIsDisabled()
			newActiveState = this.getStandardActiveTooltipState()
		}

		if (isDisabled !== newIsDisabled) {
			newState.isDisabled = newIsDisabled
		}

		if (activeState !== newActiveState) {
			newState.activeState = newActiveState
		}

		if (Object.values(newState).length) {
			this.setState({ ...newState }, onChangeState && onChangeState(this.state))
		}
	}

	fetchAttachments = () => {
		const {
			meetingId,
			agendaItems,
			fetchMultipleAttachedDocuments,
			storeDocumentsToMerge,
			isSmartMeeting,
			isStandardMeeting
		} = this.props
		let { document } = this.props
		let objIds = List([])

		if (meetingId) {
			if (isSmartMeeting) {
				const fakeMeeting = Map({
					id: meetingId,
					agendaItems
				})
				const agendaItemsFlatten = flattenAgendaItems(fakeMeeting)

				if (agendaItemsFlatten) {
					objIds = agendaItemsFlatten.map((agendaItem) => agendaItem.get('objId'))
				}
			}

			const objIdForProtocolDoc = `${meetingId}$${OBJ_TYPE_MEETING_PROTOCOL}`
			objIds = objIds.push(meetingId)
			objIds = objIds.push(`${meetingId}$${OBJ_TYPE_MEETING_AGENDA}`)
			objIds = objIds.push(`${meetingId}$${OBJ_TYPE_MEETING_ATTACHMENT}`)
			objIds = objIds.push(objIdForProtocolDoc)
			fetchMultipleAttachedDocuments(objIds, (response) => {
				let documents = response?.get('documents')

				if (!documents) {
					return
				}

				const index = documents.findIndex((doc) =>
					doc
						.get('links')
						.some(
							(link) => link.get('objType') === OBJ_TYPE_MEETING_PROTOCOL && link.get('objId') === objIdForProtocolDoc
						)
				)

				if (index >= 0) {
					document = documents.get(index)

					if (isSmartMeeting && document) {
						// document = document.set('isBeingGenerated', true)
						documents = documents.set(index, document)
					}

					if (!document.hasIn(['metadata', 'toBeMerged'])) {
						document = document.setIn(['metadata', 'toBeMerged'], true)
						documents = documents.set(index, document)
					}

					// Place the protocol in the beginning of the stack only if the protocol has not been moved
					if (!document.has('orderIndexMergeDocuments')) {
						documents = documents.remove(index).insert(0, document)
					}
				}
				storeDocumentsToMerge(documents)
			})
		}
	}

	getTooltipStates = () => {
		const { isSmartMeeting, isStandardMeeting } = this.props

		if (isSmartMeeting) {
			return TOOLTIP_STATES_SMART
		}

		if (isStandardMeeting) {
			return TOOLTIP_STATES_STANDARD
		}
	}

	getSmartMeetingIsDisabled = () => {
		const { meetingSelected, protocolPublished, feedbackeesTick, signeesTick, publishingType } = this.props
		let { feedbackees, signatories } = this.props

		if (!meetingSelected) {
			return true
		}

		if (publishingType) {
			return true
		}

		// If protocol is already published
		if (protocolPublished) {
			return true
		}

		feedbackees = feedbackees.filter((obj) => obj.get('active') === true)
		signatories =
			publishingType === PROTOCOL_PUBLISHING_TYPE_ESIGNING && signatories.filter((obj) => obj.get('active') === true)

		if ((!feedbackees || feedbackees.size === 0) && (!signatories || signatories.size === 0)) {
			return false
		}

		const feedbackeesDone = feedbackeesTick === true
		const signatoriesDone = signeesTick === true

		// Feedbackees / Signees active but request not sent
		if (
			(feedbackees && feedbackees.size > 0 && !feedbackeesDone) ||
			(signatories && signatories.size > 0 && !signatoriesDone)
		) {
			return true
		} else if (
			feedbackees &&
			feedbackees.size > 0 &&
			feedbackeesDone &&
			signatories &&
			signatories.size > 0 &&
			!signatoriesDone
		) {
			return true
		} else if (
			signatories &&
			signatories.size > 0 &&
			signatoriesDone &&
			feedbackees &&
			feedbackees.size > 0 &&
			!feedbackeesDone
		) {
			return true
		}

		return false
	}

	getSmartActiveTooltipState = () => {
		const { meetingSelected, protocolPublished, feedbackeesTick, signeesTick, publishingType } = this.props
		let { feedbackees, signatories } = this.props

		if (!meetingSelected) {
			return 'disabled'
		}

		// If protocol is already published
		if (protocolPublished) {
			return 'protocolAlreadyPublished'
		}

		if (publishingType) {
			return 'processPending'
		}

		const feedbackeesDone = feedbackees.size > 0 && feedbackeesTick === true
		const signatoriesDone = signatories.size > 0 && signeesTick === true

		const feedbackeesActive = feedbackees.filter((obj) => obj.get('active') === true).size > 0
		const signatoriesActive =
			publishingType === PROTOCOL_PUBLISHING_TYPE_ESIGNING &&
			signatories.filter((obj) => obj.get('active') === true).size > 0

		feedbackees = feedbackees.filter((obj) => obj.get('active') === true && obj.get('notified') === true)
		signatories = signatories.filter((obj) => obj.get('active') === true && obj.get('notified') === true)

		if (
			feedbackees &&
			feedbackees.size > 0 &&
			!feedbackeesDone &&
			signatories &&
			signatories.size > 0 &&
			!signatoriesDone
		) {
			return 'disabledBecauseOfFeedbackAndSignoff'
		} else if (feedbackees && feedbackees.size > 0 && !feedbackeesDone) {
			return 'disabledBecauseOfFeedback'
		} else if (signatories && signatories.size > 0 && !signatoriesDone) {
			return 'disabledBecauseOfSignoff'
		} else if (feedbackeesActive) {
			return 'disabledBecauseOfActiveFeedbackees'
		} else if (signatoriesActive) {
			return 'disabledBecauseOfActiveSignatories'
		} else {
			return 'default'
		}
	}

	getStandardMeetingIsDisabled = () => {
		return Boolean(this.getStandardActiveTooltipState())
	}

	getStandardActiveTooltipState = () => {
		const { publishingType, protocolId, protocolPublished, isModerator } = this.props

		if (protocolPublished) {
			return 'protocolPublished'
		}

		if (publishingType && publishingType !== PROTOCOL_PUBLISHING_TYPE_WITHOUT_ESIGNING) {
			return 'notAvailable'
		}

		if (publishingType && !protocolPublished) {
			return 'processPending'
		}

		if (!protocolId) {
			return 'noProtocolUploaded'
		}

		if (!isModerator) {
			return 'notModerator'
		}
	}

	postPublishProtocolActions = ({ error }) => {
		if (error) {
			return
		}

		this.openConfirmationModalForSmartMeeting()
	}

	openConfirmationModalForSmartMeeting = () => {
		const { meetingId, isSmartMeeting, openModal } = this.props

		if (!isSmartMeeting) {
			return
		}

		openModal(SIMPLE_CONFIRMATION_MODAL, {
			config: [{ tid: 'meetings.protocol_edit.simple_modal.protocol_published', fontSize: '72px' }],
			redirectTo: `${window.location.pathname.split('meetings')[0]}meetings/${meetingId}`
		})
	}

	openModal = () => {
		const { isStandardMeeting, setActiveTab, isSmartMeeting } = this.props

		if (isStandardMeeting) {
			setActiveTab(TAB_STANDARD_MEETING_PROTOCOL)
		}

		isSmartMeeting && this.generateProtocolForSmartMeeting()

		this.setState({ modalIsOpen: true })
	}

	closeModal = (isCanceled = false) => {
		const { isSmartMeeting, redirectTo, history } = this.props

		this.setState({ modalIsOpen: false, data: INITIAL_DATA_STATE })

		if (!isCanceled && isSmartMeeting && redirectTo) {
			history.push(redirectTo)
		}
	}

	generateProtocolForSmartMeeting = () => {
		const { isSmartMeeting, meetingId, protocolId, generateDocumentFile } = this.props

		if (!isSmartMeeting) {
			return
		}

		generateDocumentFile(protocolId, PDFKIND_PROTOCOL, meetingId, this.onGenerateFileInit, this.onGenereteFileComplete)
	}

	onGenerateFileInit = () => {
		const { prependDocumentToMerge } = this.props
		let { document } = this.props

		document = document.setIn(['metadata', 'isBeingGenerated'], true)
		prependDocumentToMerge(document)
	}

	onGenereteFileComplete = (err, document) => {
		const { prependDocumentToMerge } = this.props

		if (err) {
			return
		}

		document = document.removeIn(['metadata', 'isBeingGenerated'])
		document = document.setIn(['metadata', 'toBeMerged'], true)

		prependDocumentToMerge(document)
	}

	publishProtocol = () => {
		const { publishProtocolWithoutEsign, meetingId } = this.props
		const { data } = this.state

		publishProtocolWithoutEsign({
			meetingId,
			callback: this.postPublishProtocolActions
		})
		this.closeModal()
	}

	onChange = (data) => {
		this.setState({ data })
	}

	renderShareWithComponent = ({ goToUsersModal }) => {
		return <NotifyAboutSignedProtocolContainer goToUsersModal={goToUsersModal} />
	}

	render = () => {
		const { renderComponent } = this.props
		const { modalIsOpen, isDisabled, activeState, data } = this.state
		const TOOLTIP_STATES = this.getTooltipStates()

		const functionProps = {
			openModal: this.openModal,
			TOOLTIP_STATES,
			isDisabled,
			activeState
		}

		return (
			<>
				{renderComponent(functionProps)}

				{modalIsOpen && (
					<RequestEsignAdvancedModalContainer
						data={data}
						onCancel={this.closeModal}
						onSave={this.publishProtocol}
						renderShareWithComponent={this.renderShareWithComponent}
						modalTitle='meetings.protocol.publish.merge_modal.title'
						saveBtnTid='meetings.protocol.publish.merge_modal.btn.publish'
						onChange={this.onChange}
						rightColumnInfoTextTid='meetings.protocol.publish.merge_modal.right_column.info'
						objType={OBJ_TYPE_MEETING}
					/>
				)}
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		protocolId: store.meetings.getIn(['meeting', 'protocolData', 'documentId']),
		meetingId: store.meetings.getIn(['meeting', 'id']),
		isSmartMeeting: store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_SMART,
		isStandardMeeting: store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_STANDARD,
		publishingType: store.meetings.getIn(['meeting', 'publishingType']),
		meetingSelected: Boolean(store.meetings.get('meeting')),
		feedbackeesTick: store.meetings.getIn(['meeting', 'computedValues', 'feedbackeesTick']),
		protocolPublished: store.meetings.getIn(['meeting', 'computedValues', 'protocolPublished']),
		signeesTick: store.meetings.getIn(['meeting', 'computedValues', 'signeesTick']),
		feedbackees: store.meetings.getIn(['meeting', 'feedbackees'], Map()),
		signatories: store.documents.getIn(['document', 'eSignees'], Map()),
		agendaItems: store.meetings.getIn(['meeting', 'agendaItems']),
		document: store.documents.get('document', Map()),
		isModerator: store.meetings.getIn(['meeting', 'computedValues', 'isModerator']),
		docUsersToNotify: store.documents.getIn(['document', 'shareWith'], Map())
	}
}

const mapActionsToProps = {
	publishProtocolWithoutEsign,
	generateDocumentFile,
	saveMeeting,
	openModal,
	setActiveTab,
	fetchMultipleAttachedDocuments,
	prependDocumentToMerge,
	clearDocumentsToMerge,
	storeDocumentsToMerge
}

export default connect(mapStoreToProps, mapActionsToProps)(PublishProtocolComponentContainer)
