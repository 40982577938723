import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'

import Button from '../../../../../dumb-components/shared/button/button'
import Text from '../../../../../dumb-components/shared/text/text'
import history from '../../../../../interfaces/history'

class ButtonGoToBillingContainer extends Component {
	static propTypes = {
		onDone: func,
		tid: string
	}

	static defaultProps = {
		tid: 'onboarding.modals.shared.button.go_to_billing'
	}

	goToSubscription = () => {
		const { companyUrlAlias, onDone, history } = this.props

		history.push(`/${companyUrlAlias}/subscriptions/`)
		onDone && onDone()
	}

	render = () => {
		const { isSubscriptionAdmin, tid } = this.props

		if (!isSubscriptionAdmin) {
			return null
		}

		return (
			<Button mode='primary' onClick={this.goToSubscription}>
				<Text tid={tid} color='white' nowrap />
			</Button>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		isSubscriptionAdmin: store.subscriptions.getIn(['helper', 'isSubscriptionAdmin'], false),
		companyUrlAlias: store.company.company?.urlAlias
	}
}

export default connect(mapStoreToProps, {})(ButtonGoToBillingContainer)
