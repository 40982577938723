import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'

import ManageAttachmentModal from '../../../../dumb-components/meetings/manage-attachment-modal/manage-attachment-modal'
import {
	updateAttachedDocument,
	updateAttachedDocumentLocal,
	unselectAttachment
} from '../../../../actions/attachments.actions'
import { closeModal } from '../../../../actions/modals.actions'
import {updateAppSettings} from '../../../../actions/company.actions'
import { MEETINGS_ATTACHMENT_SETTINGS_MODAL } from '../../../../constants/modals'

import documentsHelper from '../../../../components/helpers/documents.helper'

class ManageAttachmentModalContainer extends Component {
	state = {
		isLoading: false,
		showWarningForDisplayStatus: false,
		defaultDisplayStatus: null,
		defaultDisplayStatusChanged: false
	}

	static propTypes = {
		objId: string.isRequired,
		doNotUnselectAttachmentOnClose: bool
	}

	componentDidMount() {
		const { defaultDisplayStatus, company } = this.state
		this.setState({ defaultDisplayStatus })
	}

	componentDidUpdate(prevProps) {
		const {isOpen, defaultDisplayStatus} = this.props

		if (!prevProps.isOpen && isOpen) {
			this.setState({defaultDisplayStatus})
		}
	}

	onChange = (fieldName, val) => {
		const { objId, updateAttachedDocumentLocal } = this.props
		let { attachment } = this.props

		if (fieldName === 'metadata.toBeMerged') {
			val = val === 'true' ? true : false
		}

		if (fieldName === 'displayStatus') {
			const linkIndex = attachment.get('links').findIndex((link) => link.get('objId') === objId)
			attachment = attachment.setIn(['links', linkIndex, 'displayStatus'], val)
		} else {
			attachment = attachment.setIn(fieldName.split('.'), val)
		}

		updateAttachedDocumentLocal(objId, attachment, false)
	}

	onSave = () => {
		const { attachment, updateAttachedDocument, objId, updateAppSettings } = this.props
		const {defaultDisplayStatus, defaultDisplayStatusChanged} = this.state
		const link = attachment.get('links').find((l) => l.get('objId') === objId)
		const objType = link.get('objType')

		this.setState({ isLoading: true })

		updateAttachedDocument(objType, objId, attachment, this.closeModal)

		if (defaultDisplayStatusChanged) {
			updateAppSettings({
				attachments: { defaultDisplayStatus }
			})
		}
	}

	closeModal = () => {
		const { unselectAttachment, doNotUnselectAttachmentOnClose, closeModal } = this.props

		closeModal()

		if (!doNotUnselectAttachmentOnClose) {
			unselectAttachment()
		}

		this.setState({ 
			isLoading: false,
			showWarningForDisplayStatus: false,
			defaultDisplayStatus: null,
			defaultDisplayStatusChanged: false
		})
	}

	setDefaultDisplayStatus = () => {
		const { attachment, objId } = this.props
		const displayStatus = documentsHelper.findDisplayStatus(attachment.get('links'), objId)

		this.setState({
			defaultDisplayStatus: displayStatus,
			showWarningForDisplayStatus: true,
			defaultDisplayStatusChanged: true
		})

		setTimeout(() => {
			this.setState({ showWarningForDisplayStatus: false })
		}, 10000)
	}

	resetDefaultDisplayStatus = () => {
		const { defaultDisplayStatus } = this.props

		this.setState({
			defaultDisplayStatus,
			showWarningForDisplayStatus: false,
			defaultDisplayStatusChanged: false
		})

		this.onChange('displayStatus', defaultDisplayStatus)
	}

	render = () => {
		const { isOpen, attachment, objId } = this.props
		const { isLoading, defaultDisplayStatus, showWarningForDisplayStatus, defaultDisplayStatusChanged } = this.state

		if (!isOpen) {
			return null
		}

		const displayStatus = documentsHelper.findDisplayStatus(attachment.get('links'), objId)

		return (
			<ManageAttachmentModal
				onClose={this.closeModal}
				onSave={this.onSave}
				onChange={this.onChange}
				displayStatus={displayStatus}
				defaultDisplayStatus={defaultDisplayStatus}
				setDefaultDisplayStatus={this.setDefaultDisplayStatus}
				resetDefaultDisplayStatus={this.resetDefaultDisplayStatus}
				showWarningForDisplayStatus={showWarningForDisplayStatus}
				defaultDisplayStatusChanged={defaultDisplayStatusChanged}
				toBeMerged={attachment.getIn(['metadata', 'toBeMerged'], 'false')}
				isPdf={attachment.getIn(['file', 'ext']) === 'pdf'}
				isLoading={isLoading}
			/>
		)
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		attachment: store.attachments.get('attachment'),
		isOpen: store.modals.getIn(['activeModal', 'name']) === MEETINGS_ATTACHMENT_SETTINGS_MODAL,
		company: store.company,
		defaultDisplayStatus: store.company.company?.appSettings?.attachments?.defaultDisplayStatus
	}
}

const mapActionsToProps = {
	updateAttachedDocument,
	updateAttachedDocumentLocal,
	unselectAttachment,
	closeModal,
	updateAppSettings
}

export default connect(mapStoreToProps, mapActionsToProps)(ManageAttachmentModalContainer)
