import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, string, bool, oneOfType } from 'prop-types'
import { Map, List } from 'immutable'

import { addMultipleAttendees } from '../../../../../actions/meetings.actions'
import {
	investorParticipatedInVoting,
	getAttendeeCanBeRemovedData
} from '../../../../../components/helpers/voting.helper'
import { getFeedbackeeOrAttendeeTooltips } from '../../../../../components/helpers/meeting.helper'
import { MEETING_TYPE_STANDARD, MEETING_TYPE_SMART, MEETING_STATUS_PROTOCOL_NEEDS_SIGNING } from '/shared/constants'
import history from '../../../../../interfaces/history'

import AddAttendeesModalContainer from '../../../attendees/add-attendees-modal.container'

const TOOLTIP_STATES = {
	default: {
		tid: 'meetings.attendees.panel.controls.tooltip.add_attendees'
	},
	signingInProgress: {
		tid: 'meetings.protocol.attendee.toolbar.add_attendee.tooltip.signing_in_progress',
		delayShow: 'instant'
	},
	protocolPublished: {
		tid: 'meetings.protocol.attendee.toolbar.add_attendee.tooltip.protocol_already_published',
		delayShow: 'instant'
	}
}

class AddAttendeeContainer extends Component {
	state = {
		isOpen: false,
		tooltipData: Map({
			activeState: 'default',
			disabled: false
		})
	}

	static propTypes = {
		renderComponent: func,
		redirectTo: oneOfType([string, bool]),
		onTooltipDataChanged: func
	}

	static defaultProps = {}

	componentDidMount = () => {
		this.setTooltipData()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { onTooltipDataChanged } = this.props
		const { tooltipData } = this.state

		if (prevState.tooltipData !== tooltipData) {
			onTooltipDataChanged && onTooltipDataChanged(tooltipData)
		}
		this.setTooltipData()
	}

	openAttendeesModal = () => {
		this.setState({ isOpen: true })
	}

	/**
	 * A callback function that is called when the modal is closed either by
	 * canceling the action or by saving the added attendees.
	 * @param {boolean} isCanceled - indicating that this function was called by onCancel
	 */
	closeModal = (isCanceled = false) => {
		const { isSmartMeeting, redirectTo, history } = this.props

		this.setState({ isOpen: false })

		if (!isCanceled && isSmartMeeting && redirectTo) {
			history.push(redirectTo)
		}
	}

	doAddAttendees = (attendees) => {
		const { addMultipleAttendees } = this.props
		addMultipleAttendees(attendees)
	}

	getAttendeeCanBeRemovedData = ({ userIds, userId, attendee }) => {
		const { votingList, meetingId, proxies, isStandardMeeting } = this.props

		return getAttendeeCanBeRemovedData({
			votingList,
			userIds,
			userId,
			meetingId,
			proxies,
			attendee,
			isStandardMeeting
		})
	}

	disableInvestorThatParticipatedInVoting = (investor) => {
		const { attendees } = this.props
		const currentUserIds = List([investor.get('id'), investor.get('investorId')])
		const attendee = attendees.find((a) => a.get('investmentId') === investor.get('id'))
		const { TOOLTIP_STATES, activeState, isDisabled } = this.getAttendeeCanBeRemovedData({
			userIds: currentUserIds,
			attendee
		})
		const tooltipTid = activeState ? TOOLTIP_STATES[activeState].tid : ''

		return Map({ isDisabled, tooltipTid })
	}

	disableMemeberThatParticipatedInVoting = (member) => {
		const currentMemberId = member.get('id')
		const { TOOLTIP_STATES, activeState, isDisabled } = this.getAttendeeCanBeRemovedData({
			userId: currentMemberId,
			attendee: member
		})
		const tooltipTid = activeState ? TOOLTIP_STATES[activeState].tid : ''

		return Map({ isDisabled, tooltipTid })
	}

	setTooltipData = () => {
		const { hasExtendedRights, isSmartMeeting, isSimpleMode, meetingStatus, protocolPublished } = this.props
		let { tooltipData } = this.state
		const previousActiveState = tooltipData.get('activeState')
		const previousDisabled = tooltipData.get('disabled')
		let activeState = 'default'
		let disabled = false

		if ((isSimpleMode && !protocolPublished) || (!hasExtendedRights && isSmartMeeting)) {
			activeState = 'hasNoPermissions'
			disabled = true
		} else if (isSmartMeeting && meetingStatus === MEETING_STATUS_PROTOCOL_NEEDS_SIGNING) {
			activeState = 'signingInProgress'
			disabled = true
		} else if (isSmartMeeting && protocolPublished) {
			activeState = 'protocolPublished'
			disabled = true
		}

		if (previousActiveState !== activeState || previousDisabled !== disabled) {
			tooltipData = tooltipData.set('activeState', activeState)
			tooltipData = tooltipData.set('disabled', disabled)

			this.setState({ tooltipData })
		}
	}

	render = () => {
		const { isStandardMeeting, attendees, renderComponent } = this.props
		const { isOpen, tooltipData } = this.state
		const activeState = tooltipData.get('activeState')
		const disabled = tooltipData.get('disabled')

		return (
			<>
				{renderComponent({
					openAttendeesModal: this.openAttendeesModal,
					TOOLTIP_STATES,
					activeState,
					disabled
				})}

				{isOpen && (
					<AddAttendeesModalContainer
						isOpen={true}
						users={attendees}
						onClose={this.closeModal}
						disableShareholderFunction={isStandardMeeting ? this.disableInvestorThatParticipatedInVoting : undefined}
						disableMemberFunction={isStandardMeeting ? this.disableMemeberThatParticipatedInVoting : undefined}
						onSelectMultipleAttendees={this.doAddAttendees}
					/>
				)}
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meetingId: store.meetings.getIn(['meeting', 'id']),
		attendees: store.meetings.getIn(['meeting', 'attendees'], Map()),
		votingList: store.votings.get('list'),
		isSimpleMode: store.meetings.getIn(['meeting', 'computedValues', 'isSimpleMode']),
		isStandardMeeting: store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_STANDARD,
		isSmartMeeting: store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_SMART,
		hasExtendedRights: store.meetings.getIn(['meeting', 'computedValues', 'hasExtendedRights']),
		proxies: store.meetings.getIn(['meeting', 'proxies']),
		meetingStatus: store.meetings.getIn(['meeting', 'status']),
		protocolPublished: store.meetings.getIn(['meeting', 'computedValues', 'protocolPublished'])
	}
}

const mapActionsToProps = {
	addMultipleAttendees
}

export default connect(mapStoreToProps, mapActionsToProps)(AddAttendeeContainer)
