import React, { PureComponent } from 'react'
import { func, string, object } from 'prop-types'
import styled from 'styled-components'
import { list } from 'react-immutable-proptypes'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import List from '../../shared/list/list'

const HeaderWrapper = styled.div`
	margin-bottom: ${({ theme }) => theme.spacing[4]};
`

export default class DocumentsDndSortList extends PureComponent {
	static propTypes = {
		documentsList: list,
		renderListItem: func,
		renderListHeader: func,
		onDragEnd: func.isRequired,
		droppableId: string.isRequired,
		headerComponent: object
	}

	static defaultProps = {}

	render = () => {
		const { documentsList, renderListItem, renderListHeader, onDragEnd, droppableId, headerComponent } = this.props

		return (
			<>
				{headerComponent && <HeaderWrapper>{headerComponent}</HeaderWrapper>}
				<List header={renderListHeader}>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId={droppableId}>
							{(provided) => (
								<div ref={provided.innerRef}>
									{documentsList && documentsList.map(renderListItem)}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</List>
			</>
		)
	}
}
