import React, { PureComponent } from 'react'
import { func } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import styled from 'styled-components'
import Moment from '../../../modules/moment.module'

import LeftRightWithHeadersLayout from '../../shared/modal/standard-layouts/left-right-with-headers'
import SimpleDarkInfoPanelWithControls from '../../shared/dark-info-panel/simple-dark-info-panel-with-controls'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import Text from '../../shared/text/text'
import SimpleDocListItem from '../../documents/shared/simple-doc-list-item'
import ListItem from '../../shared/list-item/list-item'
import Tooltip from '../../shared/tooltip/tooltip'

import { getUserName } from '../../../components/helpers/users'

const StyledDarkInfoPanel = styled(SimpleDarkInfoPanelWithControls)`
	width: 100%;
`

const StyledHeaderText = styled(Text)`
	display: flex;
	margin-bottom: ${({ theme }) => theme.spacing[4]};
`

const StyledUserListItem = styled(ListItem)`
	border-top: 1px solid ${({ theme }) => theme.colors.border};
	margin: 0;
	padding: 0 ${({ theme }) => theme.spacing[3]};
	display: flex;
	align-items: center;

	:last-child {
		border-bottom: 1px solid ${({ theme }) => theme.colors.border};
	}
`

export default class SharedCollectionOverview extends PureComponent {
	static propTypes = {
		collection: map.isRequired,
		onTurnOffShare: func.isRequired,
		investors: map,
		usersCache: map
	}

	renderDocumentItem = (document, index) => {
		return <SimpleDocListItem file={document.get('file')} title={document.get('title')} key={index} />
	}

	renderShareWithItem = (user, index) => {
		const { investors, usersCache } = this.props

		return (
			<StyledUserListItem key={index}>
				<Text>{getUserName(user, investors, usersCache)}</Text>
			</StyledUserListItem>
		)
	}

	renderMiddleHeader = () => {
		const { collection, onTurnOffShare } = this.props
		const expiresAt = collection.get('expiresAt')
		const isExpired = Moment(expiresAt).isBefore(Moment())
		const formattedExpiresAt = Moment(expiresAt).format('LLL')
		const alertTid = isExpired
			? 'collections.manage_shared.shared_details.alert.expired.info'
			: 'collections.manage_shared.shared_details.alert.info'

		return (
			<StyledDarkInfoPanel
				tid={alertTid}
				textValues={{ expiresAt: formattedExpiresAt }}
				centerControls={true}
				rightControlsComponent={
					<Tooltip
						tid='collections.manage_shared.shared_details.alert.expired.info.tooltip'
						active={isExpired}
						delayShow='instant'
						place='left'>
						<ToggleSwitch onChange={onTurnOffShare} checked={isExpired ? false : true} disabled={isExpired} />
					</Tooltip>
				}
			/>
		)
	}

	renderLeftContent = () => {
		const { collection } = this.props
		const documents = collection.get('documents')

		return (
			<>
				<StyledHeaderText tid='collections.manage_shared.shared_details.documents.header' />
				{documents.map(this.renderDocumentItem)}
			</>
		)
	}

	renderRightContent = () => {
		const { collection } = this.props
		const shareWith = collection.get('shareWith')

		return (
			<>
				<StyledHeaderText tid='collections.manage_shared.shared_details.people.header' />
				{shareWith.map(this.renderShareWithItem).toList()}
			</>
		)
	}

	render = () => {
		return (
			<LeftRightWithHeadersLayout
				middleHeader={this.renderMiddleHeader()}
				leftContent={this.renderLeftContent()}
				rightContent={this.renderRightContent()}
			/>
		)
	}
}
