import React, { Component } from 'react'
import { connect } from 'react-redux'
import AccountWizard from '../../components/user/services/account-wizard'
import CompanyAccounts from '../../dumb-components/company/company-accounts/company-accounts'
import CompanyListContainer from '../../containers/company/company-list.container'
import { fetchUserCompanies } from '../../actions/company.actions'
import { updateUser, saveUser } from '../../actions/user.actions'
import {
	EVENT_TYPE_COMPANY_MEMBER_ADDED,
	EVENT_TYPE_COMPANY_MEMBER_UPDATE,
	EVENT_TYPE_COMPANY_MEMBER_REMOVE,
	EVENT_TYPE_COMPANY_UPDATE
} from '/shared/constants'

const COMPANY_ACCOUNT_INTRO_BLOCK = '880448b8-4a4c-11e9-8646-d663bd873d93'

import ActivateDemoButton from '../company/demo/activate-demo-btn.container'

class CompanyAccountsContainer extends Component {
	state = {
		companyWizardOpen: false
	}

	componentDidUpdate() {
		this.checkLiveUpdateEvents()
	}

	checkLiveUpdateEvents = () => {
		const { auditCompany, fetchUserCompanies } = this.props
		const companyMemberAdded = auditCompany.get(EVENT_TYPE_COMPANY_MEMBER_ADDED)
		const companyMemberUpdate = auditCompany.get(EVENT_TYPE_COMPANY_MEMBER_UPDATE)
		const companyMemberRemove = auditCompany.get(EVENT_TYPE_COMPANY_MEMBER_REMOVE)
		const companyUpdate = auditCompany.get(EVENT_TYPE_COMPANY_UPDATE)

		// Company member was added, updated or deleted so we update the list
		if (
			(companyMemberAdded && companyMemberAdded.get('refresh') === true) ||
			(companyMemberUpdate && companyMemberUpdate.get('refresh') === true) ||
			(companyMemberRemove && companyMemberRemove.get('refresh') === true) ||
			(companyUpdate && companyUpdate.get('refresh') === true)
		) {
			fetchUserCompanies('me')
		}
	}

	getContinueBankIdCollect = () => {
		return this.state.companyWizardOpen
	}

	onToggleIntroBlock = (action, mode) => {
		const { saveUser, updateUser } = this.props
		let { user } = this.props

		if (action === 'close') {
			user = user.setIn(['siteSettings', 'minimalizedBlocks', COMPANY_ACCOUNT_INTRO_BLOCK], true)
		}

		if (action === 'open') {
			user = user.setIn(['siteSettings', 'minimalizedBlocks', COMPANY_ACCOUNT_INTRO_BLOCK], false)
		}

		updateUser(user)

		// If more isn't 'local', update user in database
		if (mode !== 'local') {
			// Delay 0.5s to prevent jerking animations (rerendering while animation is in progress)
			setTimeout(() => {
				saveUser('me', user)
			}, 500)
		}
	}

	onCompanyWizardOpen = () => {
		this.onToggleIntroBlock('close', 'local')
		this.setState({ companyWizardOpen: true })

		// Apparently, scrolling into view with CSS transition in progress doesn't really work good together
		setTimeout(() => {
			this.companyWizardRef.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			})
		}, 550)
	}

	onCompanyWizardClose = () => {
		this.onToggleIntroBlock('open', 'local')
		this.setState({ companyWizardOpen: false })
	}

	/* This functionality has been deactivated for now*/
	/* renderDemoButton = () => {
		return <ActivateDemoButton />
	}*/

	renderCompanyList = () => {
		return <CompanyListContainer emptyMode='accounts' />
	}

	renderAccountWizard = () => {
		return (
			<AccountWizard
				onCancel={this.onCompanyWizardClose}
				onRef={(r) => (this.companyWizardRef = r)}
				getContinueBankIdCollect={this.getContinueBankIdCollect}
			/>
		)
	}

	render = () => {
		const { isMinimized, user } = this.props
		const { companyWizardOpen } = this.state

		return (
			<CompanyAccounts
				isOpen={user && !isMinimized}
				companyWizardOpen={companyWizardOpen}
				onToggleDemo={this.toggleUserDemo}
				onToggleIntroBlock={this.onToggleIntroBlock}
				onCompanyWizardOpen={this.onCompanyWizardOpen}
				//renderDemoButton={this.renderDemoButton}
				renderCompanyList={this.renderCompanyList}
				renderAccountWizard={this.renderAccountWizard}
			/>
		)
	}
}

function mapStoreToProps(state) {
	return {
		user: state.user.get('userObj'),
		isMinimized: state.user.getIn(['userObj', 'siteSettings', 'minimalizedBlocks', COMPANY_ACCOUNT_INTRO_BLOCK]),
		auditCompany: state.audit.get('company')
	}
}

const mapActionsToProps = {
	updateUser,
	saveUser,
	fetchUserCompanies
}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyAccountsContainer)
