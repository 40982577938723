import React, { Component } from 'react'
import { connect } from 'react-redux'
import { COMPANY_ADD_NEW_COMPANY_MODAL } from '../../constants/modals'

import StepsModal from '../../dumb-components/shared/modal/steps-modal'
import CompanyAccountWizard from './account-wizard/account-wizard.container'
import InitAuthorizeCompany from './account-wizard/init-authorize-company.container'
import FooterRightControls from '../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../dumb-components/shared/button-v2'
import history from '../../interfaces/history'

import { closeModal } from '../../actions/modals.actions'
import { createUnverifiedCompany } from '../../actions/company.actions'

const INITIAL_STATE = {
	step: 0,
	authData: null
}

class AddCompanyModalContainer extends Component {
	state = INITIAL_STATE

	companyAccountWizardRef = null

	goToFirstStep = () => {
		this.resetState()
	}

	goToAutorize = (authData) => {
		this.setState({ step: 1, authData })
	}

	onSkipAuthorise = (authData) => {
		const { createUnverifiedCompany, userObj, history } = this.props

		createUnverifiedCompany(
			authData.get('companyOrgNumber'),
			userObj.get('email'),
			userObj.get('name'),
			authData.get('companyName'),
			(company, err) => {
				if (!err) {
					this.closeModal()
					history.push(`/${company.urlAlias}`)
				}
			}
		)
	}

	onVerifyToCustomer = (authData) => {
		const { createUnverifiedCompany, userObj, history } = this.props

		createUnverifiedCompany(
			authData.get('companyOrgNumber'),
			userObj.get('email'),
			userObj.get('name'),
			authData.get('companyName'),
			(company, err) => {
				if (!err) {
					this.closeModal()
					history.push(`/${company.urlAlias}`)
				}
			},
			true
		)
	}

	closeModal = () => {
		const { closeModal } = this.props

		this.resetState()

		closeModal()
	}

	onCompanyAuthorised = (responseData) => {
		const urlAlias = responseData.getIn(['company', 'urlAlias'])

		this.closeModal()

		this.props.history.push(`/${urlAlias}`)
	}

	resetState = () => {
		this.setState(INITIAL_STATE)
		this.companyAccountWizardRef._resetState()
	}

	setCompanyAccountWizardRef = (ref) => {
		this.companyAccountWizardRef = ref
	}

	getSteps = () => {
		const { authData } = this.state

		return [
			{
				body: (
					<CompanyAccountWizard
						onRef={this.setCompanyAccountWizardRef}
						onAuthorize={this.goToAutorize}
						onSkipAuthorise={this.onSkipAuthorise}
						onVerifyToCustomer={this.onVerifyToCustomer}
					/>
				),
				footer: {
					component: (
						<FooterRightControls>
							<TransparentButton tid='generic.close' textColor='midGrey' onClick={this.closeModal} />
						</FooterRightControls>
					)
				}
			},
			{
				body: <InitAuthorizeCompany authData={authData} onCompanyAuthorised={this.onCompanyAuthorised} />,
				footer: {
					component: (
						<FooterRightControls>
							<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={this.goToFirstStep} />
						</FooterRightControls>
					)
				}
			}
		]
	}

	render = () => {
		const { isOpen } = this.props
		const { step } = this.state

		return (
			<StepsModal
				isOpen={isOpen}
				steps={this.getSteps()}
				step={step}
				hSize='lg'
				title='company.add_company_wizard.modal.title'
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		isOpen: store.modals.getIn(['activeModal', 'name']) === COMPANY_ADD_NEW_COMPANY_MODAL,
		userObj: store.user.get('userObj')
	}
}

const mapActionsToProps = {
	closeModal,
	createUnverifiedCompany
}

export default connect(mapStoreToProps, mapActionsToProps)(AddCompanyModalContainer)
