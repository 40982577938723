import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import WhiteSection from '../../common/white-section'
import Typography from '@mui/material/Typography'
import ExpandMore from '@mui/icons-material/ExpandMore'
import invitationSvg from '../../../../assets/images/emissions/invitation.svg'
import DealFlowInvitationDescModal from './dealflow-description-modal'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getInvitees, getNumOfDealflows } from '../../../../actions/emissions.actions'
import { useConfirmContext } from '../../../shared/confirm/confirm.context'
import history from '../../../../interfaces/history'
import { makeInternalLink } from '../../../../components/helpers/link.helper'
import { InvitationModal } from './invitation-modal/InvitationModal'
import InvitedPeopleGrid from './invited-people-grid'
import { List } from 'immutable'
import { DateTime } from 'luxon'
import { fetchSubscription } from '../../../../actions/subscriptions.actions'
import { DropDown } from '../../../../mui-components/dropdown/DropDown'
import MenuItemIcon from '../../../../mui-components/dropdown/menu/menu-item-icon'
import { emissionIsReadOnly } from '../../utils/emission-status-helper'

const Invitation = ({ emission }) => {
	const [invitationModalOpen, setInvitationModalOpen] = useState(false)
	const [dealFlowDescOpen, setDealFlowDescOpen] = useState(false)
	const [loadingInvitees, setLoadingInvitees] = useState(true)
	const [typeOfInvitees, setTypeOfInvitees] = useState()
	const invitees = useSelector((state) => state.emissions.invitees)
	const numOfInvitedDealflows = useSelector((state) => state.emissions.invitedDealflows)
	const eSigningEnabled = useSelector((state) =>
		state.subscriptions.getIn(
			['helper', 'eSigningEnabled'],
			state.subscriptions.getIn(['helper', 'isFreeOfCharge'])
		)
	)
	const isFreeOfCharge = useSelector((state) => state.subscriptions.getIn(['helper', 'isFreeOfCharge']))
	const cardInformation = useSelector((state) =>
		(state.subscriptions.getIn(['subscription', 'stripeData', 'sources', 'data']) || List()).find((item) => {
			return item.get('object') === 'card'
		})
	)
	const urlAlias = useSelector((state) => state.company.company.urlAlias)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const confirm = useConfirmContext()
	const preventChanges = emissionIsReadOnly(emission, Invitation.name)

	const totalNumOfInvitedDealflows =
		numOfInvitedDealflows.numOfDirectDealflows + numOfInvitedDealflows.numOfDealflowsViaCriteria

	const getPreventInvitationReson = () => {
		if (!eSigningEnabled) {
			return {
				title: 'emissions.invitation_prevented_modal.titles.esigning_not_enabled',
				messages: [
					{
						tid: 'emissions.invitation_prevented_modal.messages.esigning_not_enabled'
					}
				]
			}
		}

		if (!cardInformation) {
			return {
				title: 'emissions.invitation_prevented_modal.titles.no_card',
				messages: [{ tid: 'emissions.invitation_prevented_modal.messages.no_card' }]
			}
		}

		const expMonth = cardInformation.get('exp_month')
		const expYear = cardInformation.get('exp_year')
		const expDate = DateTime.local(expYear, expMonth).endOf('month')
		const now = DateTime.now()

		if (expDate < now) {
			return {
				title: 'emissions.invitation_prevented_modal.titles.card_expired',
				messages: [{ tid: 'emissions.invitation_prevented_modal.messages.card_expired' }]
			}
		}
	}

	const showPreventInvitationModal = async () => {
		const reson = getPreventInvitationReson()

		if (typeof reson === 'undefined') {
			return
		}

		const result = await confirm.showConfirmation(
			reson.title,
			reson.messages,
			'emissions.invitation_prevented_modal.buttons.go_to_subscriptions',
			'dialog.generic.cancel'
		)

		if (result) {
			history.push(makeInternalLink(`/${urlAlias}/subscriptions`, undefined, false))
		}
	}

	const shouldShowPreventInvitationModal = !isFreeOfCharge && typeof getPreventInvitationReson() !== 'undefined'

	useEffect(() => {
		async function fetchInvitees() {
			try {
				await dispatch(getInvitees(emission.companyId, emission.id))
				await dispatch(getNumOfDealflows(emission.companyId, emission.id))
			} finally {
				setLoadingInvitees(false)
			}
		}

		fetchInvitees()
		dispatch(fetchSubscription())
	}, [])

	async function inviteViaDealFlow(typeOfInvitees) {
		if (shouldShowPreventInvitationModal) {
			await showPreventInvitationModal()
		} else {
			setTypeOfInvitees(typeOfInvitees)
			setInvitationModalOpen(true)
		}
	}

	return (
		<Box>
			<>
				<DealFlowInvitationDescModal
					open={dealFlowDescOpen}
					close={() => setDealFlowDescOpen(false)}
					onInvitationMade={() => console.log('Invite via DealFlow')}
					preventChanges={preventChanges}
				/>
			</>
			<WhiteSection sx={{ mb: 2 }}>
				<Grid container>
					<Grid item xs={7}>
						<Typography variant={'h4'} sx={{ mb: 2 }}>
							{t('emissions.invitations.header.invite-via-dealflow')}
						</Typography>
						<Typography variant={'body2'} sx={{ mb: 2 }}>
							{totalNumOfInvitedDealflows > 0
								? t('emissions.invitations.invite-via-dealflow.num_of_dealflows_invited')
								: t('emissions.invitations.invite-via-dealflow-desc-1')}
						</Typography>
						{totalNumOfInvitedDealflows === 0 && (
							<Typography
								onClick={() => setDealFlowDescOpen(true)}
								sx={{ color: 'primary.main', fontWeight: 'bold', cursor: 'pointer' }}>
								{t('emissions.invitations.read-more')}
							</Typography>
						)}
					</Grid>
					<Grid item xs={5}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'flex-end ',
								height: '100%'
							}}>
							<DropDown
								alignMenu='right'
								button={({ params }) => (
									<Button
										disabled={preventChanges}
										endIcon={<ExpandMore />}
										variant={'contained'}
										sx={{ fontWeight: 'bold' }}
										{...params}>
										<Typography variant={'h5'}>
											{t('emissions.invitations.start-invite-process')}
										</Typography>
									</Button>
								)}>
								<MenuItemIcon
									icon='faPaperPlane'
									onClick={() => inviteViaDealFlow('dealflow-via-direct-follow')}
									listItemTid='emissions.invitations.send-invitation-via-direct-follow'
									disabled={preventChanges || numOfInvitedDealflows.numOfDirectDealflows > 0}
								/>
								<MenuItemIcon
									icon='faPaperPlane'
									onClick={() => inviteViaDealFlow('dealflow-via-criteria')}
									listItemTid='emissions.invitations.send-invitation-via-criteria'
									disabled={preventChanges || numOfInvitedDealflows.numOfDealflowsViaCriteria > 0}
								/>
							</DropDown>
						</Box>
					</Grid>
				</Grid>
			</WhiteSection>
			{invitees.length === 0 && !loadingInvitees && (
				<WhiteSection>
					<Grid container>
						<Grid item xs={6}>
							<Typography variant={'h4'} sx={{ mb: 6 }}>
								{t('emissions.invitations.header.invite-share-holders-and-investors')}
							</Typography>
							<Box sx={{ pl: 3 }}>
								<img src={invitationSvg} style={{ height: '300px' }} />
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
									height: '100%'
								}}>
								<Typography sx={{ alignSelf: 'center', mt: 10 }}>
									{t('emissions.invitations.no-invites-yet')}
								</Typography>
								<Box
									sx={{
										justifySelf: 'flex-end',
										alignSelf: 'flex-end',
										'> button + button': { ml: 2 }
									}}>
									<DropDown
										alignMenu='right'
										button={({ params }) => (
											<Button
												disabled={preventChanges}
												endIcon={<ExpandMore />}
												variant={'contained'}
												sx={{ fontWeight: 'bold' }}
												{...params}>
												<Typography variant={'h5'}>
													{t('emissions.invitations.start-invite-process')}
												</Typography>
											</Button>
										)}>
										<MenuItemIcon
											icon='faPaperPlane'
											onClick={() => inviteViaDealFlow('shareholders')}
											listItemTid='emissions.invitations.invite_shareholders'
											disabled={preventChanges}
										/>
										<MenuItemIcon
											icon='faPaperPlane'
											onClick={() => inviteViaDealFlow('potential-investors')}
											listItemTid='emissions.invitations.invite_potential_investors'
											disabled={preventChanges}
										/>
									</DropDown>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</WhiteSection>
			)}

			{!loadingInvitees && invitees.length !== 0 && (
				<WhiteSection sx={{ mt: 2 }}>
					<Grid container>
						<Grid item xs={12} sx={{ height: '80rem' }}>
							<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<Typography variant={'h4'} sx={{ mb: 6 }}>
										{t('emissions.invitations.header.invited')}
									</Typography>
									<Box
										sx={{
											'> button + button': { ml: 2 }
										}}>
										<DropDown
											alignMenu='right'
											button={({ params }) => (
												<Button
													endIcon={<ExpandMore />}
													variant={'contained'}
													sx={{ fontWeight: 'bold' }}
													{...params}>
													<Typography variant={'h5'}>
														{t('emissions.invitations.continue-invite-process')}
													</Typography>
												</Button>
											)}>
											<MenuItemIcon
												icon='faPaperPlane'
												onClick={() => inviteViaDealFlow('shareholders')}
												listItemTid='emissions.invitations.invite_shareholders.again'
												disabled={preventChanges}
											/>
											<MenuItemIcon
												icon='faPaperPlane'
												onClick={() => inviteViaDealFlow('potential-investors')}
												listItemTid='emissions.invitations.invite_potential_investors.again'
												disabled={preventChanges}
											/>
										</DropDown>
									</Box>
								</Box>

								<Box sx={{ height: '100%' }}>
									<InvitedPeopleGrid invitees={invitees} preventChanges={preventChanges} />
								</Box>
							</Box>
						</Grid>
					</Grid>
				</WhiteSection>
			)}
			{invitationModalOpen && (
				<InvitationModal
					isOpen={true}
					typeOfInvitees={typeOfInvitees}
					onComplete={() => setInvitationModalOpen(false)}
					onCancel={() => setInvitationModalOpen(false)}
				/>
			)}
		</Box>
	)
}

export default Invitation
