/**
 * Subscriptions reducer
 * @module reducers/Subscriptions.reducer
 */

import { fromJS, Map } from 'immutable'

import {
	SUBSCRIPTIONS_FETCH_API_KEY,
	SUBSCRIPTIONS_FETCH,
	SUBSCRIPTIONS_CREATE,
	SUBSCRIPTIONS_SAVE,
	SUBSCRIPTIONS_ERROR,
	SUBSCRIPTIONS_DOWNGRADE,
	SUBSCRIPTIONS_FETCH_PLANS,
	SUBSCRIPTIONS_UPDATE_FORM_OBJECT,
	SUBSCRIPTIONS_VERIFY_COUPON,
	SUBSCRIPTIONS_CREATE_TOKEN,
	SUBSCRIPTIONS_FETCH_HELPER,
	SUBSCRIPTION_ADD_STRIPE_ERROR,
	COMPANY_RESET_REDUCER
} from '../actions/types'

/** The initial state object for Payments. */
const INITIAL_STATE = fromJS({
	subscription: null,
	plans: null,
	formObject: {},
	error: null,
	invoicesList: null,
	invoice: null,
	apiKey: null,
	coupon: null,
	token: null,
	helper: null,
	stripeError: null
})

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case SUBSCRIPTIONS_FETCH_API_KEY:
			return state.set('apiKey', payload)
		case SUBSCRIPTIONS_FETCH:
			return state.set('subscription', payload)
		case SUBSCRIPTIONS_CREATE:
			return state.set('subscription', payload)
		case SUBSCRIPTIONS_SAVE:
			return state.set('subscription', payload)
		case SUBSCRIPTIONS_DOWNGRADE:
			return state.set('subscription', payload)
		case SUBSCRIPTIONS_FETCH_PLANS:
			return state.set('plans', payload)
		case SUBSCRIPTIONS_UPDATE_FORM_OBJECT:
			return state.set('formObject', payload)
		case SUBSCRIPTIONS_ERROR:
			return state.set('error', payload)
		case SUBSCRIPTIONS_VERIFY_COUPON:
			return state.set('coupon', payload)
		case SUBSCRIPTIONS_CREATE_TOKEN:
			return state.set('token', payload)
		case SUBSCRIPTIONS_FETCH_HELPER:
			return state.set('helper', payload)
		case SUBSCRIPTION_ADD_STRIPE_ERROR:
			return state.set('stripeError', payload)
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}
