import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { string, func } from 'prop-types'
import DropdownMenuContainer from '../shared/dropdown-menu.container'
import DropdownIconItem from '../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import DropdownItem from '../../dumb-components/shared/dropdown-item/dropdown-item'
import Toolbar from '../../dumb-components/shared/toolbar/toolbar'
import { ButtonTransparentIcon } from '../../dumb-components/shared/button'
import Tooltip from '../../dumb-components/shared/tooltip/tooltip'

import DocumentRequestSignButton from './toolbar-items/document-request-sign-button'
import OpenOrDownloadComponent from './generic/open-or-download-file-component.container'

import MoveFileItemContainer from './generic/dropdown-items/move-file-item.container'
import DeleteFileItem from './generic/dropdown-items/delete-file-item.container'
import AddToCollectionComponent from './generic/add-document-to-collection-component.container'
import RecoverFileDropDownItem from './generic/dropdown-items/recover-file.container'
import { COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS } from '../../constants/modals'
import { openModal } from '../../actions/modals.actions'

class ToolbarContainer extends Component {
	static propTypes = {
		basePath: string,
		querystr: string,
		onDeleteDocument: func
	}

	closeMoreActionsDropdown = () => {
		this.moreActionBtnRef && this.moreActionBtnRef.onToggleMenu()
	}

	renderWebViewer = () => {
		const { document } = this.props

		return (
			<OpenOrDownloadComponent document={document} mode='OPEN'>
				{({ TOOLTIP_STATES, activeState, isDisabled, openOrDownload }) => (
					<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
						<ButtonTransparentIcon onClick={openOrDownload} icon='faExpandArrowsAlt' size='sml' disabled={isDisabled} />
					</Tooltip>
				)}
			</OpenOrDownloadComponent>
		)
	}

	renderDownload = () => {
		const { document } = this.props

		return (
			<OpenOrDownloadComponent document={document} mode='DOWNLOAD'>
				{({ TOOLTIP_STATES, activeState, isDisabled, openOrDownload }) => (
					<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
						<ButtonTransparentIcon
							onClick={openOrDownload}
							icon='faArrowAltToBottom'
							size='sml'
							disabled={isDisabled}
						/>
					</Tooltip>
				)}
			</OpenOrDownloadComponent>
		)
	}

	renderMoreActions = () => {
		const { document, basePath, querystr, openModal } = this.props

		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisH'
				halignMenu='right'
				btnMode='transparent-icon'
				noMaxWidth={true}
				ref={(ref) => (this.moreActionBtnRef = ref)}>
				<AddToCollectionComponent document={document}>
					{({ TOOLTIP_STATES, activeState, isDisabled, addToCollection }) => (
						<Tooltip states={TOOLTIP_STATES} activeState={activeState} active={isDisabled}>
							<DropdownIconItem
								tid='documents.list.dropdown.item.add_to_collection'
								icon='faReceipt'
								disabled={isDisabled}
								onClick={() => {
									this.closeMoreActionsDropdown()
									addToCollection()
								}}
							/>
						</Tooltip>
					)}
				</AddToCollectionComponent>

				<DropdownIconItem
					icon='faCopy'
					tid='personal-documents.copy-file-to-personal-documents'
					onClick={() => {
						this.closeMoreActionsDropdown()
						openModal(COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS, { document })
					}}
				/>

				<MoveFileItemContainer document={document} onClick={this.closeMoreActionsDropdown} />

				<DropdownItem divider />

				<DeleteFileItem
					document={document}
					basePath={basePath}
					querystr={querystr}
					onClick={this.closeMoreActionsDropdown}
				/>

				<RecoverFileDropDownItem
					document={document}
					basePath={basePath}
					querystr={querystr}
					onClick={this.closeMoreActionsDropdown}
				/>
			</DropdownMenuContainer>
		)
	}

	render = () => {
		const { params, document } = this.props

		if (!params.id) {
			return <Toolbar />
		}

		if (document.get('isMeeting')) {
			return <Toolbar />
		}

		const isDeleted = document.get('isDeleted')
		return (
			<Toolbar>
				<DocumentRequestSignButton mode='toolbar' disabled={isDeleted} />
				{this.renderWebViewer()}
				{this.renderDownload()}
				{this.renderMoreActions()}
			</Toolbar>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		document: store.documents.get('document', Map()) || Map()
	}
}

const mapActionsToProps = {
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(ToolbarContainer)
