import React, { PureComponent } from 'react'
import { func, string } from 'prop-types'
import styled from 'styled-components'
import { Margin } from 'styled-components-spacing'
import Grid from 'styled-components-grid'

import ScrollView from '../../shared/layout/scroll-view/scroll-view'
import Label from '../../shared/label/label'
import Text from '../../shared/text/text'
import ColoredContentWrapper from '../../shared/colored-content-wrapper/colored-content-wrapper'

import { MEETING_SUBTYPE_AGM } from '/shared/constants'

const StyledManageAttendeeWrapper = styled.div`
	display: flex;
	flex: 1;
	padding: ${(props) => props.theme.spacing[5]} ${(props) => props.theme.spacing[6]};
	flex-direction: column;
`

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	width: 100%;
	margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledTextWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[4]};
`

export default class ManageAttendeeModal extends PureComponent {
	static propTypes = {
		attendeeId: string,
		renderAttendeeFunction: func,
		meetingSubType: string,
		renderProxyPanelContainer: func,
		onManageFunctions: func,
		renderModeratorControlsContainer: func
	}

	render = () => {
		const {
			attendeeId,
			renderAttendeeFunction,
			meetingSubType,
			renderProxyPanelContainer,
			onManageFunctions,
			renderModeratorControlsContainer
		} = this.props

		return (
			<ScrollView noLeftMargin noRightMargin alwaysShow>
				<StyledManageAttendeeWrapper>
					{renderModeratorControlsContainer && (
						<StyledColoredContentWrapper type='secondary'>
							{renderModeratorControlsContainer(attendeeId)}
						</StyledColoredContentWrapper>
					)}

					<StyledColoredContentWrapper type='secondary'>
						<Label
							tid='meetings.attendees.details.label.function'
							rightComponent={() => (
								<Text tid='meetings.attendees.function.manage_function' onClick={onManageFunctions} hasUnderline />
							)}>
							{renderAttendeeFunction && renderAttendeeFunction(attendeeId)}
						</Label>
						<StyledTextWrapper>
							<Text tid='meetings.manage_attendee.modal.function.info' />
						</StyledTextWrapper>
					</StyledColoredContentWrapper>

					{meetingSubType === MEETING_SUBTYPE_AGM && (
						<Grid.Unit size={1}>{renderProxyPanelContainer && renderProxyPanelContainer(attendeeId)}</Grid.Unit>
					)}
				</StyledManageAttendeeWrapper>
			</ScrollView>
		)
	}
}
