import React from 'react'
import { object } from "prop-types"
import Typography from '@mui/material/Typography'

function CompanyAddress({ company }) {
	if (!company) {
		return null
	}

	const address = company.addresses.find(({ type }) => type === 'padr')

	return (
		<div>
			<Typography variant='body1' component='span'>
				{company.name}
			</Typography>
			<br />
			{address?.street && (
				<>
					<Typography variant='body2' component='span'>
						{address.street}
					</Typography>
					<br />
				</>
			)}
			<Typography variant='body2' component='span'>
				{address?.postalCode} {address?.city}
			</Typography>
		</div>
	)
}

CompanyAddress.propTypes = {
	company: object
}

export {CompanyAddress}
