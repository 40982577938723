import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CardActions from '@mui/material/CardActions'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '../../../../mui-components/button/button'
import { useTranslation } from 'react-i18next'
import { getCompanyLogoUrl } from '../../../../components/helpers/company.helper'
import styled from 'styled-components'
import Tooltip from '../../../../mui-components/tooltip/tooltip'

const StyledCard = styled(Card)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const StyledCardMedia = styled(CardMedia)`
	height: 75px;
	max-width: 30%;
	margin-top: 24px;
	margin-left: 24px;

	&.MuiCardMedia-img {
		object-fit: contain !important;
	}
`

const StyledCompanyName = styled(Box)`
	padding: 16px;
	width: fit-content;
`

export const AdvertCardView = ({ advert, openAdvertModal, followBtnDisabled, followBtnTooltip, onFollow }) => {
	const { t } = useTranslation()
	const logoUrl = advert.logotype && getCompanyLogoUrl(advert.company.id, advert.logotype, 100)

	return (
		<StyledCard borderradius={'true'}>
			<Box>
				<Box sx={{ display: 'flex' }}>
					{logoUrl && <StyledCardMedia variant='flex' component='img' image={logoUrl} />}
					<StyledCompanyName>
						<Typography variant='h4'>{advert.company.name}</Typography>
						<Typography variant='body2'>{advert.tagline}</Typography>
					</StyledCompanyName>
				</Box>
				<CardContent>
					<Typography variant='body2'>{advert.summary}</Typography>
				</CardContent>
			</Box>
			<CardActions>
				<Tooltip title={followBtnTooltip} hideTooltip={!followBtnDisabled}>
					<Button onClick={onFollow} variant='primary' disabled={followBtnDisabled}>
						{t('company_adverts.buttons.follow')}
					</Button>
				</Tooltip>

				<Button onClick={() => openAdvertModal(advert.id)} variant='default'>
					{t('company_adverts.buttons.read_more')}
				</Button>
			</CardActions>
		</StyledCard>
	)
}
