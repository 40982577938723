import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'

const StyledToolbarWrapper = styled.div`
	display: flex;
	flex-direction: row;
`

const ShareholdersToolbarControl = ({
	addCapitalInsuranceRenderer,
	unlinkDropdownRenderer,
	downloadDropdownRenderer,
	emailDropdownRenderer,
	moreActionsDropdownRenderer,
	printOwnerReportDropdown,
	shareholderPeekDropdownRenderer
}) => {
	return (
		<StyledToolbarWrapper>
			{shareholderPeekDropdownRenderer && shareholderPeekDropdownRenderer()}
			{addCapitalInsuranceRenderer && addCapitalInsuranceRenderer()}
			{unlinkDropdownRenderer && unlinkDropdownRenderer()}
			{downloadDropdownRenderer && downloadDropdownRenderer()}
			{printOwnerReportDropdown && printOwnerReportDropdown()}
			{emailDropdownRenderer && emailDropdownRenderer()}
			{moreActionsDropdownRenderer && moreActionsDropdownRenderer()}
		</StyledToolbarWrapper>
	)
}

ShareholdersToolbarControl.propTypes = {
	addCapitalInsuranceRenderer: func,
	unlinkDropdownRenderer: func,
	downloadDropdownRenderer: func,
	emailDropdownRenderer: func,
	moreActionsDropdownRenderer: func,
	printOwnerReportDropdown: func
}

export default ShareholdersToolbarControl
