import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func } from 'prop-types'

import Tooltip from '../../../../../dumb-components/shared/tooltip/tooltip'
import DropdownIconItem from '../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import { MEETING_TYPE_STANDARD, MEETING_SUBTYPE_AGM } from '/shared/constants'

import { sendMeetingConfirmation } from '../../../../../actions/meetings.actions'
import { isAttendingByProxy, findProxyByAttendeeId } from '../../../../../components/helpers/meeting.helper'

class SendConfirmationToAttendee extends Component {
	static propTypes = {
		onClick: func,
		userId: string
	}

	sendConfirmation = () => {
		const { userId, onClick, sendMeetingConfirmation, proxies, attendees } = this.props
		const attendingByProxy = isAttendingByProxy(userId, proxies)

		if (attendingByProxy) {
			const proxy = findProxyByAttendeeId(proxies, userId)
			const proxyId = proxy.get('userId')

			onClick && onClick(proxyId)

			sendMeetingConfirmation(proxyId)

			return
		}

		onClick && onClick(userId)

		sendMeetingConfirmation(userId)
	}

	getStateData = () => {
		const { hasExtendedRights, meetingHasModerator, isStandardMeeting, userId, isAGM, attendees, proxies } = this.props

		const attendeeIsCompanyInvestor =
			attendees &&
			attendees.find((attendee) => {
				if (attendee.get('userId') === userId && attendee.get('isCompanyInvestor')) {
					return true
				}
			})
		const attendingByProxy = isAttendingByProxy(userId, proxies)
		let isDisabled = false
		let activeState

		if (!hasExtendedRights) {
			isDisabled = true
			activeState = 'hasNoPermissions'
		}

		if (!meetingHasModerator && isStandardMeeting) {
			isDisabled = true
			activeState = 'noModeratorAdded'
		}

		if (isAGM && attendeeIsCompanyInvestor && !attendingByProxy && meetingHasModerator) {
			isDisabled = true
			activeState = 'noProxyAdded'
		}

		return { isDisabled, activeState }
	}

	render = () => {
		const { isDisabled, activeState } = this.getStateData()

		return (
			<Tooltip activeState={activeState} active={isDisabled}>
				<DropdownIconItem
					onClick={this.sendConfirmation}
					disabled={isDisabled}
					icon='faLink'
					tid='meetings.attendees.list.send_meeting_confirmation'
				/>
			</Tooltip>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		hasExtendedRights: store.meetings.getIn(['meeting', 'computedValues', 'hasExtendedRights']),
		meetingHasModerator: store.meetings.getIn(['meeting', 'computedValues', 'hasModerator'], false),
		isStandardMeeting: store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_STANDARD,
		proxies: store.meetings.getIn(['meeting', 'proxies']),
		attendees: store.meetings.getIn(['meeting', 'attendees']),
		isAGM: store.meetings.getIn(['meeting', 'meetingSubType']) === MEETING_SUBTYPE_AGM
	}
}

const mapActionsToProps = {
	sendMeetingConfirmation
}

export default connect(mapStoreToProps, mapActionsToProps)(SendConfirmationToAttendee)
