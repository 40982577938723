import React, { PureComponent } from 'react'
import { bool } from 'prop-types'
import styled from 'styled-components'

import Text from '../../shared/text/text'

const StyledWrapper = styled.div`
	display: flex;
	align-items: center;
`

export default class RollcallStatusMessage extends PureComponent {
	static propTypes = {
		rollcallDone: bool.isRequired
	}

	render = () => {
		const { rollcallDone } = this.props
		const tid = rollcallDone
			? 'meetings.toolbar.shared.rollcall.rollcall_done'
			: 'meetings.toolbar.shared.rollcall.rollcall_not_done'

		return (
			<StyledWrapper>
				<Text tid={tid} />
			</StyledWrapper>
		)
	}
}
