import React, { useEffect, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getInvitees, getSubmissions, updateSubmission } from '../../../../actions/emissions.actions'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import emissions from '../../../../services/emissions'
import SubscriptionSlipForAdminForm from './subscription-slip-for-admin-form'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import FullPageSpinner from '../../common/full-page-spinner'
import { useTheme } from '@mui/material/styles'
import { useSubscriptionModalAdminContext } from './subscription-modal-admin.context'

const SubmissionModal = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [selectedInvitee, setSelectedInvitee] = useState('')
	const [submission, setSubmission] = useState(null)
	const [loading, setLoading] = useState(true)
	const invitees = useSelector((state) => state.emissions.invitees)
	const emission = useSelector((state) => state.emissions.current)
	const submissionFormOptions = useSelector((state) => state.emissions.submissionFormOptions)
	const theme = useTheme()
	const { isSubmitting } = useSubscriptionModalAdminContext()

	useEffect(() => {
		async function f() {
			try {
				await dispatch(getInvitees(emission.companyId, emission.id))

				if (props.initialInvitee) {
					setSelectedInvitee(props.initialInvitee)
					setSubmission(await emissions.getSubmission(emission.companyId, emission.id, props.initialInvitee))
				}
			} finally {
				setLoading(false)
			}
		}

		f()
	}, [])

	const onClose = () => {
		setSubmission(null)
		setSelectedInvitee('')
		props.onClose()
		setLoading(false)
	}

	const options = invitees
		.filter((i) => !i.subscribed || i.id === props.initialInvitee)
		.map((i) => (
			<MenuItem key={i.id} value={i.id}>
				<Typography sx={{ pr: 1 }} variant='body2' component='span'>
					{i.name || i.company}
				</Typography>
				<Typography variant='body2' component='span' sx={{ color: theme.palette.text.disabled }}>
					(ref: {i.reference})
				</Typography>
			</MenuItem>
		))

	const chip = (
		<Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
			<Chip
				label={
					props.readOnly
						? t('emissions.subscription-list.digital-submission')
						: t('emissions.subscription-list.manual-submission')
				}
				sx={{
					color: 'secondary.700',
					bgcolor: 'secondary.100'
				}}
			/>
		</Box>
	)

	return (
		<Dialog open={props.open} onClose={onClose}>
			<DialogContent sx={{ px: 6, py: 4, minWidth: '60rem' }}>
				<IconButton
					aria-label='close'
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[600]
					}}>
					<CloseIcon />
				</IconButton>
				{loading ? (
					<Box>
						<Typography sx={{ textAlign: 'center', pb: 1 }} variant='h5'>
							{t('emissions.tabs.subscription-form')}
						</Typography>
						{chip}
						<Box sx={{ py: 7 }}>
							<FullPageSpinner text={t('emissions.subscription-list.loading-submission')} />
						</Box>
					</Box>
				) : (
					<Box>
						<Typography sx={{ textAlign: 'center', pb: 1 }} variant='h5'>
							{t(
								!props.initialInvitee && options.length === 0
									? 'emissions.subscription-list.add-subscription-not-possible'
									: 'emissions.tabs.subscription-form'
							)}
						</Typography>
						{options.length !== 0 ? (
							<>
								{chip}
								{!submission && !props.readOnly && (
									<Typography variant={'body2'} sx={{ mb: 4 }}>
										{t('emissions.subscription-list.choose-invitee-desc')}
									</Typography>
								)}

								{!props.readOnly && !props.initialInvitee && (
									<FormControl fullWidth sx={{ mb: 3 }}>
										<InputLabel id='invited-subscribers-label'>
											{t('emissions.subscription-list.subscribe-for')}
										</InputLabel>
										<Select
											labelId='invited-subscribers-label'
											id='invited-subscribers'
											value={selectedInvitee}
											label={t('emissions.subscription-list.subscribe-for')}
											disabled={isSubmitting}
											onChange={async (event) => {
												const selection = event.target.value
												setSubmission(
													await emissions.getSubmission(
														emission.companyId,
														emission.id,
														selection
													)
												)

												setSelectedInvitee(selection)
											}}>
											{options}
										</Select>
									</FormControl>
								)}
							</>
						) : (
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
								<Typography variant={'body2'}>
									{t('emissions.subscription-list.cant-subscribe-desc')}
								</Typography>
								<Button variant={'contained'} onClick={onClose} sx={{ minWidth: '16rem', mt: 7 }}>
									<Typography variant={'h5'}>{t('emissions.subscription-list.ok')}</Typography>
								</Button>
							</Box>
						)}

						{submission && (
							<SubscriptionSlipForAdminForm
								subscriptionSlip={{ ...submissionFormOptions, submission }}
								selectedInvitee={selectedInvitee}
								onSubmit={(values, callback) => {
									dispatch(
										updateSubmission(
											emission.companyId,
											emission.id,
											selectedInvitee,
											values,
											callback
										)
									)
								}}
								onFinalizedSubmission={() => {
									setLoading(true)
									dispatch(getSubmissions(emission.companyId, emission.id))
									dispatch(getInvitees(emission.companyId, emission.id))
									onClose()
								}}
							/>
						)}
					</Box>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default SubmissionModal
