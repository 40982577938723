import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

import Icon from '../icon/icon'
import Text from '../text/text'

const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 100%;
`

const StyledText = styled(Text)`
	margin-top: ${(props) => props.theme.spacing[4]};
`

export default class CenteredLoading extends PureComponent {
	static propTypes = {
		className: string,
		size: string,
		tid: string
	}

	static defaultProps = {
		size: 'xl'
	}

	render = () => {
		const { className, size, tid } = this.props

		return (
			<LoadingWrapper className={className}>
				<Icon icon='faSpinner' size={size} spin />
				{tid && <StyledText tid={tid} />}
			</LoadingWrapper>
		)
	}
}
