// ---------------------------------------------------------------------------------------
// -------------------------------- Multi Select Creatable -------------------------------
// ---------------------------------------------------------------------------------------
import React from 'react'
import Creatable from 'react-select-next/creatable'
import styled from 'styled-components'
import { string, bool, func, oneOf, number } from 'prop-types'
import { fromJS, List } from 'immutable'
import ImmutableProps from 'react-immutable-proptypes'
import StyledSelectTemplate from './select.template'
import { setupCustomComponents, parseValue, setupFilters } from './select.helpers'
import Text from '../../text/text'

const StyledCretableSelect = styled(Creatable)`
	${StyledSelectTemplate}
`

const MultiSelectCreatable = (props) => {
	const {
		labelIsTid,
		openMenuOnClick,
		placeholderTid,
		optionsValue,
		optionsLabel,
		isDisabled,
		formatCreateLabel,
		onChange,
		fieldName,
		value,
		isLoading,
		hideSelectedOptions,
		closeMenuOnSelect,
		noOptionsMessage,
		hideDropdownIndicator,
		filterType,
		menuPortalTarget,
		menuPlacement
	} = props
	const customComponentsConfig = { labelIsTid, placeholderTid, hideDropdownIndicator }
	let { options } = props

	// NULL !== undefined that's why default props don't help
	if (!options) {
		options = List()
	}

	const isValidNewOption = (inputValue, selectValue, selectOptions) => {
		if (
			inputValue.trim().length === 0 ||
			selectOptions.some(
				(option) =>
					option[optionsLabel].toLowerCase() === inputValue.toLowerCase() ||
					selectValue.some((value) => value[optionsLabel].toLowerCase() === inputValue.toLowerCase())
			)
		) {
			return false
		}
		return true
	}

	const _onChange = (selectedValues, action) => {
		// Return only VALUES
		selectedValues = selectedValues.map((val) => {
			return val[optionsValue]
		})

		if (fieldName) {
			onChange && onChange(fieldName, fromJS(selectedValues), fromJS(action))
		} else {
			onChange && onChange(fromJS(selectedValues), fromJS(action))
		}
	}

	const _setupFilters = (option, input) => {
		return setupFilters(filterType, option, input)
	}

	return (
		<StyledCretableSelect
			classNamePrefix='react-select'
			components={setupCustomComponents(customComponentsConfig)}
			getOptionLabel={(val) => {
				return val[optionsLabel]
			}}
			getOptionValue={(val) => {
				return val[optionsValue]
			}}
			options={options.toJS()}
			ignoreAccent={false}
			isDisabled={isDisabled || isLoading}
			isMulti={true}
			isLoading={isLoading}
			placeholder={placeholderTid}
			isValidNewOption={isValidNewOption}
			formatCreateLabel={formatCreateLabel}
			filterOption={filterType && _setupFilters}
			openMenuOnClick={openMenuOnClick}
			hideSelectedOptions={hideSelectedOptions}
			closeMenuOnSelect={closeMenuOnSelect}
			getNewOptionData={(inputValue, optionLabel) => ({
				[optionsValue]: inputValue,
				[optionsLabel]: optionLabel
			})}
			noOptionsMessage={noOptionsMessage}
			onChange={_onChange}
			value={parseValue(value, options, optionsValue)}
			maxMenuHeight={170}
			menuPlacement={menuPlacement}
			menuPortalTarget={menuPortalTarget}
			closeMenuOnScroll={
				menuPortalTarget &&
				((event) => {
					if (event.srcElement.className.includes('react-select')) {
						return false
					}

					return true
				})
			}
		/>
	)
}

MultiSelectCreatable.defaultProps = {
	labelIsTid: false,
	isDisabled: false,
	optionsLabel: 'label',
	optionsValue: 'value',
	placeholderTid: '',
	isLoading: false,
	hideSelectedOptions: true,
	closeMenuOnSelect: false,
	openMenuOnClick: true,
	hideDropdownIndicator: false,
	maxMenuHeight: 170,
	formatCreateLabel: (val) => {
		return (
			<span>
				<Text tid='generic.form.create' />: {val}
			</span>
		)
	},
	noOptionsMessage: () => {
		return <Text tid='select.default_no_options' />
	}
}

MultiSelectCreatable.propTypes = {
	fieldName: string,
	optionsLabel: string,
	optionsValue: string,
	placeholderTid: string,
	labelIsTid: bool,
	isDisabled: bool,
	onChange: func,
	formatCreateLabel: func,
	options: ImmutableProps.list,
	value: ImmutableProps.list,
	isLoading: bool,
	hideSelectedOptions: bool,
	closeMenuOnSelect: bool,
	noOptionsMessage: func,
	hideDropdownIndicator: bool,
	openMenuOnClick: bool,
	filterType: oneOf(['word-match']),
	maxMenuHeight: number,
	menuPlacement: oneOf(['auto', 'bottom', 'top'])
}

export default MultiSelectCreatable
