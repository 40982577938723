/**
 * Root reducer.
 * @module reducers/rootReducer
 */

import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import CompanyReducer from './company.reducer'
import DocumentsReducer from './documents.reducer'
import DocumentsSettingsReducer from './documents-settings.reducer'
import TransactionReducer from './transaction.reducer'

import I18nReducer from './i18n.reducer'
import MenuReducer from './menu.reducer'
import ModalReducer from './modal.reducer'
import NotifyReducer from './notify.reducer'
import UserReducer from './user.reducer'
import WikiReducer from './wiki.reducer'
import SharesReducer from './shares.reducer'
import MessagesReducer from './messages.reducer'
import SubscriptionsReducer from './subscriptions.reducer'
import PrivateSubscriptionReducer from './private-subscription.reducer'
import InvestorsReducer from './investors.reducer'
import ShareRegisterReducer from './share-register.reducer'
import ImmutableFormReducer from './immutable-form.reducer'
import InvoicesReducer from './invoices.reducer'
import FoldersReducer from './folders.reducer'
import TagsReducer from './tags.reducer'
import CoreProductsReducer from './core-products.reducer'
import UsersCacheReducer from './usersCache.reducer'
import CommentsReducer from './comments.reducer'
import TasksReducer from './tasks.reducer'
import ProjectsReducer from './projects.reducer'
import MeetingsReducer from './meetings.reducer'
import GroupsReducer from './groups.reducer'
import MeetingTemplates from './meeting-templates.reducer'
import NotesReducer from './notes.reducer'
import ScreenMessageReducer from './screen-message.reducer'
import LiveUpdateReducer from './live-update.reducer'
import ModalsReducer from './modals.reducer'
import CredentialsReducer from './credentials.reducer'
import ProtocolPdfOptionsReducer from './protocol-pdf-options.reducer'
import NotificationsReducer from './notifications.reducer'
import VotingsReducer from './votings.reducer'
import AttachmentsReducer from './attachments.reducer'
import EmailTemplatesReducer from './email-templates.reducer'
import DeposReducer from './depos.reducer'
import CapTableDetailsReducer from './cap-table-details.reducer'

import CapitalInsuranceReducer from './capital-insurance.reducer'
import CollectionsReducer from './collections.reducer'
import SharedCollectionsReducer from './shared-collections.reducer'
import PersonalDocumentsReducer from './personal-documents.reducer'
import StripeReducer from './stripe.reducer'
import EmissionsReducer from './emissions.reducer'
import People from './people.reducer'
import PersonalAttachmentsReducer from './personal-attachment.reducer'
import DealflowReducer from './dealflow.reducer'
import AdvertsReducer from './adverts.reducer'

/** Combined reducers. */
const rootReducer = combineReducers({
	adverts: AdvertsReducer,
	attachments: AttachmentsReducer,
	audit: LiveUpdateReducer,
	capTableDetails: CapTableDetailsReducer,
	capitalInsurance: CapitalInsuranceReducer,
	collections: CollectionsReducer,
	comments: CommentsReducer,
	company: CompanyReducer,
	coreProducts: CoreProductsReducer,
	credentials: CredentialsReducer,
	dealflow: DealflowReducer,
	depos: DeposReducer,
	documents: DocumentsReducer,
	documentsSettings: DocumentsSettingsReducer,
	emailTemplates: EmailTemplatesReducer,
	emissions: EmissionsReducer,
	folders: FoldersReducer,
	form: formReducer,
	groups: GroupsReducer,
	i18n: I18nReducer,
	immutableForm: ImmutableFormReducer,
	investors: InvestorsReducer,
	invoices: InvoicesReducer,
	meetings: MeetingsReducer,
	meetingTemplates: MeetingTemplates,
	menu: MenuReducer,
	messages: MessagesReducer,
	modal: ModalReducer,
	modals: ModalsReducer,
	notes: NotesReducer,
	notifications: NotificationsReducer,
	notify: NotifyReducer,
	people: People,
	personalAttachments: PersonalAttachmentsReducer,
	personalDocuments: PersonalDocumentsReducer,
	privateSubscription: PrivateSubscriptionReducer,
	projects: ProjectsReducer,
	protocolPdfOptions: ProtocolPdfOptionsReducer,
	screenMessage: ScreenMessageReducer,
	sharedCollections: SharedCollectionsReducer,
	shareRegister: ShareRegisterReducer,
	shares: SharesReducer,
	stripe: StripeReducer,
	subscriptions: SubscriptionsReducer,
	tags: TagsReducer,
	tasks: TasksReducer,
	transaction: TransactionReducer,
	user: UserReducer,
	usersCache: UsersCacheReducer,
	votings: VotingsReducer,
	wiki: WikiReducer
})

/** Export rootReducer. */
export default rootReducer
