import React, { PureComponent } from 'react'
import { func, bool } from 'prop-types'
import styled from 'styled-components'
import Text from '../../shared/text/text'
import { Margin } from 'styled-components-spacing'
import Panel from '../../shared/panel/panel'
import ComputersImage from '../../../assets/images/dashboard/dashboard-accounts-computers.png'
import Button from '../../shared/button/button'
import Icon from '../../shared/icon/icon'

const StyledIntroWrapper = styled.div`
	transition: all 0.5s;
	display: flex;
	height: 100%;
	max-height: ${(props) => (props.isOpen ? '1000px' : 0)};
	overflow: hidden;
	margin: ${(props) => (props.isOpen ? `64px ${props.theme.spacing[5]}` : `0 ${props.theme.spacing[5]}`)};
`

const StyledIntroLeftWrapper = styled.div`
	margin-right: ${(props) => props.theme.spacing[4]};
`

const StyledIntroRightWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: ${(props) => props.theme.spacing[4]};
	min-width: 380px;

	> h4 {
		margin-top: 0;
	}
`

const StyledIntroRightButtons = styled.div`
	display: flex;
	flex-direction: row;
`

const StyledIntroImage = styled.img`
	max-width: 100%;
`

const StyledCompanyWizardWrapper = styled.div`
	transition: all 0.5s;
	display: flex;
	height: 100%;
	max-height: ${(props) => (props.companyWizardOpen ? '1000px' : 0)};
	overflow: hidden;
`

class CompanyAccounts extends PureComponent {
	_onToggleIntroBlock = () => {
		const { isOpen, onToggleIntroBlock } = this.props
		const action = isOpen ? 'close' : 'open'
		onToggleIntroBlock && onToggleIntroBlock(action)
	}

	renderRightComponent = () => {
		const { isOpen } = this.props
		const icon = isOpen ? 'faCompressArrowsAlt' : 'faExpandArrowsAlt'
		const tid = isOpen ? 'generic.btn.collapse_section' : 'generic.btn.expand_section'

		return (
			<Button onClick={this._onToggleIntroBlock} minWidth='112px'>
				<Margin right={3}>
					<Icon icon={icon} size='xs' />
				</Margin>
				<Text tid={tid} />
			</Button>
		)
	}

	renderIntro = () => {
		const { isOpen, onCompanyWizardOpen, renderDemoButton } = this.props

		return (
			<StyledIntroWrapper isOpen={isOpen}>
				<StyledIntroLeftWrapper>
					<StyledIntroImage src={ComputersImage} />
				</StyledIntroLeftWrapper>
				<StyledIntroRightWrapper>
					<Text tid='dashboard.company_accounts.body.create_account' tag='h4' bold={600} marginBottom={5} />
					<Text tid='dashboard.company_accounts.body.text_1' tag='p' marginBottom={5} />
					<Text tid='dashboard.company_accounts.body.text_2' tag='p' marginBottom={5} />
					<StyledIntroRightButtons>
						<Button
							mode='primary'
							tid='dashboard.company_accounts.button.create_account'
							onClick={onCompanyWizardOpen}
						/>
						{renderDemoButton && renderDemoButton()}
					</StyledIntroRightButtons>
				</StyledIntroRightWrapper>
			</StyledIntroWrapper>
		)
	}

	render = () => {
		const { renderAccountWizard, renderCompanyList, companyWizardOpen } = this.props

		return (
			<Panel
				title='dashboard.company_accounts.panel.title'
				mode='light'
				marginBottom={true}
				rightComponent={this.renderRightComponent}>
				{this.renderIntro()}
				<Margin horizontal={4} bottom={4}>
					<StyledCompanyWizardWrapper companyWizardOpen={companyWizardOpen}>
						{renderAccountWizard && renderAccountWizard()}
					</StyledCompanyWizardWrapper>
					{renderCompanyList && renderCompanyList()}
				</Margin>
			</Panel>
		)
	}
}

CompanyAccounts.defaultProps = {
	isOpen: false,
	companyWizardOpen: false
}

CompanyAccounts.propTypes = {
	isOpen: bool,
	companyWizardOpen: bool,
	onToggleIntroBlock: func,
	onCompanyWizardOpen: func,
	renderAccountWizard: func,
	renderCompanyList: func,
	renderDemoButton: func
}

export default CompanyAccounts
