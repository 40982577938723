import React, { Component } from 'react'
import { connect } from 'react-redux'

import OnboardingModal from '../../../../../dumb-components/onboarding/modal'
import { FirstStepBody, SecondStepBody } from '../../../../../dumb-components/onboarding/modals/meetings/intro'
import FakeFooterSpacer from '../../../../../dumb-components/shared/modal/fake-footer-spacer'
import { ButtonTransparentIcon } from '../../../../../dumb-components/shared/button'
import HeaderControls from '../../header-controls.container'
import ButtonGoToBilling from '../shared/btn-go-to-billing.container'
import MeetingCreateButtonContainer from '../../../../meetings/meeting-create-button.container'
import Button from '../../../../../dumb-components/shared/button/button'

import { markOnboardAsRead } from '../../../../../actions/notifications.actions'
import { closeModal } from '../../../../../actions/modals.actions'

class DocumentsIntroContainer extends Component {
	state = {
		step: 0
	}

	markModalAsRead = () => {
		const { markOnboardAsRead, closeModal, notificationId } = this.props

		markOnboardAsRead(notificationId)
		closeModal()
	}

	goToPageTwo = () => {
		this.setState({ step: 1 })
	}

	onGoToBeginning = () => {
		this.setState({ step: 0 })
	}

	renderCreateStandardMeetingButton = ({ createStandardMeeting }) => {
		return (
			<Button
				minWidth='100px'
				onClick={() => {
					createStandardMeeting()
					this.markModalAsRead()
				}}
				color='white'
				mode='primary'
				tid='onboarding.modals.meetings.intro.create.btn'
			/>
		)
	}

	renderCreateSmartMeetingButton = ({ createSmartMeeting }) => {
		return (
			<Button
				minWidth='100px'
				onClick={() => {
					createSmartMeeting()
					this.markModalAsRead()
				}}
				color='white'
				mode='primary'
				tid='onboarding.modals.meetings.intro.create.btn'
			/>
		)
	}

	getSteps = () => {
		const { companyUrlAlias, isPremiumAccount } = this.props

		return [
			{
				body: (
					<FirstStepBody
						createStandardMeetingButton={
							<MeetingCreateButtonContainer
								basePath={`/${companyUrlAlias}/meetings`}
								renderCustomComponent={this.renderCreateStandardMeetingButton}
							/>
						}
						createSmartMeetingButton={
							<MeetingCreateButtonContainer
								basePath={`/${companyUrlAlias}/meetings`}
								renderCustomComponent={this.renderCreateSmartMeetingButton}
							/>
						}
						showSmartMeetingInfo={this.goToPageTwo}
						isPremiumAccount={isPremiumAccount}
					/>
				),
				footer: {
					component: <FakeFooterSpacer height={36} />
				}
			},
			{
				body: (
					<SecondStepBody
						billingButton={
							<ButtonGoToBilling
								onDone={this.markModalAsRead}
								tid='onboarding.modals.meetings.intro.smart.more.btn.upgrade'
							/>
						}
					/>
				),
				footer: {
					leftComponent: <ButtonTransparentIcon onClick={this.onGoToBeginning} icon='faLongArrowLeft' size='xl' />
				}
			}
		]
	}

	render = () => {
		return (
			<OnboardingModal
				title='TODO: Title'
				mode='steps'
				step={this.state.step}
				steps={this.getSteps()}
				dropdownComponent={<HeaderControls type='DROPDOWN' />}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		notificationId: store.modals.getIn(['activeModal', 'options', 'notificationId']),
		companyUrlAlias: store.company.company?.urlAlias,
		isPremiumAccount: store.subscriptions.getIn(['helper', 'premium'])
	}
}

const mapActionsToProps = {
	markOnboardAsRead,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(DocumentsIntroContainer)
