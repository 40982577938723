import { List } from 'immutable'
import {
	DEALFLOW_ADD_COMPANY,
	DEALFLOW_LIST,
	DEALFLOW_CREATE_USER_CRITERIA,
	DEALFLOW_DELETE_USER_CRITERIA,
	DEALFLOW_LIST_USER_CRITERIA_LISTS,
	DEALFLOW_UPDATE_STATUS_USER_CRITERIA,
	DEALFLOW_UPDATE_USER_CRITERIA,
	DEALFLOW_REMOVE_COMPANY,
	DEALFLOW_COMPANY_CRITERIA,
	DEALFLOW_COMPANY_LIST_DEALFLOW_FOLLOWERS,
	COMPANY_RESET_REDUCER
} from '../actions/types'

const INITIAL_STATE = {
	companies: [],
	userCriteriaLists: List(),
	companyCriteria: List(),
	companyFollowers: null
}

export default function (state = INITIAL_STATE, { type, payload }) {
	switch (type) {
		case DEALFLOW_ADD_COMPANY:
			return { ...state, companies: [...state.companies, ...[payload]] }
		case DEALFLOW_REMOVE_COMPANY: {
			const companies = state.companies.filter(({ id }) => id !== payload)
			return { ...state, companies }
		}
		case DEALFLOW_LIST:
			return { ...state, companies: payload }
		case DEALFLOW_DELETE_USER_CRITERIA:
		case DEALFLOW_LIST_USER_CRITERIA_LISTS:
		case DEALFLOW_UPDATE_STATUS_USER_CRITERIA:
		case DEALFLOW_UPDATE_USER_CRITERIA:
		case DEALFLOW_CREATE_USER_CRITERIA: {
			return { ...state, userCriteriaLists: payload }
		}
		case DEALFLOW_COMPANY_CRITERIA: {
			if (!payload) {
				return state
			}
			return { ...state, companyCriteria: payload }
		}
		case DEALFLOW_COMPANY_LIST_DEALFLOW_FOLLOWERS: {
			return { ...state, companyFollowers: payload }
		}
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}
