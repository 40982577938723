import { isImmutable } from 'immutable';

const obj = {
	getTranslatedValue: (obj, userLang, region) => {
		if (!obj || typeof obj === 'string' || !userLang || !region) {
			return obj;
		}

		//userLang = userLang.substr(0, 2); if we want translated folders again put this back in.
		userLang = 'sv';
		let value;

		if (isImmutable(obj)) {
			value = obj.get(userLang, obj.get(region.language.main));

			if (!value || value === '') {
				value = obj.get(region.language.main);
			}

			return value;
		}

		value = obj[userLang] || obj[region.language.main];

		if (!value || value === '') {
			value = obj[region.language.main];
		}

		return value;
	},
	getBrowserLanguage: () => {
		let browserLanguage = navigator.languages ? navigator.languages[0] : navigator.language;
		browserLanguage = browserLanguage.toLowerCase();

		if (browserLanguage === 'en-us' || browserLanguage === 'en') {
			browserLanguage = 'en-GB';
		}

		if (browserLanguage === 'sv' || browserLanguage === 'sv-se') {
			browserLanguage = 'sv-SE';
		}

		return browserLanguage;
	},
	/**
	 *
	 * @param {string} language
	 * @returns {string} 'en-GB' | 'sv-SE'
	 */
	normalizeLanguage: (language) => {
		if (!language) {
			throw 'No language given';
		}

		if (language === 'en-us' || language === 'en') {
			return 'en-GB';
		}

		if (language === 'sv' || language === 'sv-se') {
			return 'sv-SE';
		}
	}
};

export default obj;
