import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { func, bool, oneOf, string, oneOfType, number } from 'prop-types'
import { Link } from 'react-router-dom'
import Tooltip from '../../tooltip/tooltip'

const StyledWrapper = styled.li`
	border: 1px solid ${(props) => (props.active ? 'transparent' : '#d1d9de')};
	display: flex;
	height: 36px;
	align-items: center;
	user-select: none;
	justify-content: center;
	background-color: ${(props) => (props.active ? 'transparent' : props.theme.colors.lightestGrey)};
	list-style-type: none;

	${(props) =>
		props.isHidden && !props.investmentsTabs &&
		css`
			display: none;
		`}

	${(props) =>
		props.disabled &&
		css`
			background-color: ${props.theme.colors.disabled};
			cursor: default;
		`}

	${(props) =>
		!props.disabled &&
		css`
			:hover {
				background-color: #e8e8e8;
				border-color: #d1d9de;
			}
		`}

	${(props) =>
		props.flex &&
		css`
			flex: 1;
		`}

	${(props) =>
		!props.filler &&
		css`
			margin-right: ${(props) => props.theme.spacing[3]};

			:last-child {
				margin-right: 0;
			}
		`}

		${(props) =>
		props.investmentsTabs &&
		css`
			margin-right: 0;
		`}
`

const StyledInnerWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 100%;

	${(props) =>
		!props.noPadding &&
		css`
			margin-right: ${(props) => props.theme.spacing[4]};
			margin-left: ${(props) => (props.highlightColor ? '0' : props.theme.spacing[4])};
		`}
`

const StyledFiller = styled.div`
	flex: 1;
`

const StyledLink = styled(Link)`
	align-items: center;
	display: flex;
	height: 100%;
`

const StyledHighlight = styled.div`
	background-color: ${(props) => props.theme.colors[props.highlightColor]};
	min-width: ${(props) => props.theme.spacing[3]};
	max-width: ${(props) => props.theme.spacing[3]};
	height: 100%;
	margin-right: ${(props) => props.theme.spacing[4]};
`

export default class TabTemplate extends PureComponent {
	static propTypes = {
		active: bool,
		tabContentRenderer: func,
		noPadding: bool,
		disabled: bool,
		flex: bool,
		filler: bool,
		mode: oneOf(['router', 'state']),
		onChange: func,
		highlightColor: oneOf(['red', 'green', 'orange', 'solitudeDark']),
		tooltip: oneOfType([bool, string]),
		tooltipDelay: oneOf(['instant', 'short', 'long']),
		isHidden: bool,
		offset: string
	}

	static defaultProps = {
		flex: false,
		disabled: false,
		noPadding: false,
		active: false,
		filler: false,
		tooltipDelay: 'instant'
	}

	onChange = () => {
		const { path, onChange, disabled } = this.props
		!disabled && onChange(path)
	}

	renderInnerContent = () => {
		const { highlightColor, tabContentRenderer, noPadding } = this.props

		return (
			<StyledInnerWrapper noPadding={noPadding} highlightColor={highlightColor}>
				{highlightColor && <StyledHighlight highlightColor={highlightColor} />}
				{tabContentRenderer && tabContentRenderer()}
			</StyledInnerWrapper>
		)
	}

	renderTab = () => {
		const { onChange, path, tooltip, tooltipDelay, active, disabled, flex, filler, isHidden, offset, investmentsTabs } = this.props

		const renderContent = () => {
			return (
				<StyledWrapper
					investmentsTabs={investmentsTabs}
					active={active}
					disabled={disabled}
					flex={flex}
					filler={filler}
					onClick={onChange && this.onChange}
					data-path={path}
					isHidden={isHidden}
					offset={offset}>
					{this.renderInnerContent()}
				</StyledWrapper>
			)
		}

		if (tooltip) {
			return (
				<Tooltip tid={tooltip} delayShow={tooltipDelay}>
					{renderContent()}
				</Tooltip>
			)
		}

		return renderContent()
	}

	renderdTabWithLink = () => {
		const { path, tooltip, tooltipDelay, active, disabled, flex, filler, url, isHidden, offset, investmentsTabs } = this.props

		const renderContent = () => {
			return (
				<StyledWrapper
					investmentsTabs={investmentsTabs}
					active={active}
					disabled={disabled}
					flex={flex}
					filler={filler}
					data-path={path}
					isHidden={isHidden}
					offset={offset}>
					<StyledLink to={url}>{this.renderInnerContent()}</StyledLink>
				</StyledWrapper>
			)
		}

		if (tooltip) {
			return (
				<Tooltip tid={tooltip} delayShow={tooltipDelay}>
					{renderContent()}
				</Tooltip>
			)
		}

		return renderContent()
	}

	render = () => {
		const { disabled, tabContentRenderer, filler, activeTab } = this.props

		if (!tabContentRenderer) {
			return null
		}

		if (filler) {
			return <StyledFiller />
		}

		if (activeTab || disabled) {
			return this.renderTab()
		}

		return this.renderdTabWithLink()
	}
}
