import { fromJS } from 'immutable'
import req from '../modules/request.module'
import documentHelper from '../components/helpers/documents.helper'
import {
	SHARES_USER_LIST,
	SHARES_USER_CREATE,
	SHARES_USER_UPDATE,
	SHARES_USER_REMOVE,
	SHARES_SAVE,
	SHARES_FETCH,
	SHARES_COMPANY_SHAREHOLDER_LIST,
	SHARES_SAVE_SHAREHOLDER,
	SHARES_UPDATE_SHAREHOLDER,
	SHARES_REMOVE_SHAREHOLDER,
	SHARES_SELECT_SHAREHOLDER,
	SHARES_DETERMINE_READ_ONLY,
	SHARES_ERROR
} from './types'

/**
 * Action for listing shares for a user
 */
export function listUserShares(id) {
	return function (dispatch) {
		return req
			.get(`/shares/me`)
			.then((response) => {
				dispatch({
					type: SHARES_USER_LIST,
					payload: fromJS(response.data)
				})
			})
			.catch(() => {
				dispatch(sharesError("Could't list user shares."))
			})
	}
}

/**
 * Action for creating shares for a user
 */
export function createUserShares(investorId, investedInCompany) {
	const share = {
		investorId, // Skapa användare/företag om den inte finns, så vi alltid har det i databasen.
		investorType: 'user' // Eller 'company'
	}

	share.investedInCompanyInformation = investedInCompany

	return function (dispatch) {
		return req
			.post('/shares/', share)
			.then((response) => {
				dispatch({ type: SHARES_USER_CREATE, payload: fromJS(response.data) })
			})
			.catch(() => {
				dispatch(sharesError("Could't save share for user."))
			})
	}
}

/**
 * Action for send request for a share for a user
 */
export function requestUserShares(shareId, email, name, ssn, company, superUser) {
	const data = { email, name, ssn, company, superUser }
	return function (dispatch) {
		return req
			.post(`/shares/invite/${shareId}`, data)
			.then((response) => {
				dispatch({ type: SHARES_USER_UPDATE, payload: fromJS(response.data) })
			})
			.catch(() => {
				dispatch(sharesError("Could't save share for user."))
			})
	}
}

/**
 * Action for send a cancel request for a share for a user
 */
export function cancelRequestUserShares(shareId) {
	return function (dispatch) {
		return req
			.post(`/shares/uninvite/${shareId}`)
			.then((response) => {
				dispatch({ type: SHARES_USER_UPDATE, payload: fromJS(response.data) })
			})
			.catch(() => {
				dispatch(sharesError("Could't save share for user."))
			})
	}
}

export function createShares(share) {
	return function (dispatch) {
		//dispatch({ type: SHARES_SAVE, payload: share });
		return req
			.post(`/shares/capital`, share)
			.then((response) => {
				dispatch({ type: SHARES_SAVE, payload: fromJS(response.data) })
			})
			.catch(() => {
				dispatch(sharesError("Could't save share for company."))
			})
	}
}

export function editShares(share) {
	return function (dispatch) {
		//dispatch({ type: SHARES_SAVE, payload: share });
		return req
			.put(`/shares/capital/${share.id}`, share)
			.then((response) => {
				dispatch({ type: SHARES_SAVE, payload: fromJS(response.data) })
			})
			.catch(() => {
				dispatch(sharesError("Could't edit share for company."))
			})
	}
}

/**
 * Action for fetching a companies share capital.
 */
export function fetchShareCapital(id) {
	return function (dispatch) {
		let url = ''
		if (id) {
			url = `/shares/capital?companyId=${id}`
		} else {
			url = `/shares/capital`
		}

		return req
			.get(url)
			.then((response) => {
				dispatch({ type: SHARES_FETCH, payload: fromJS(response.data) })
			})
			.catch(() => {
				dispatch(sharesError("Could't fetch share for company."))
			})
	}
}

/**
 * Action for determine read only mode in /share-capital
 */
export function determineReadOnlyMode(resource) {
	return function (dispatch) {
		return req
			.get('/shares/readonly/' + resource)
			.then((response) => {
				dispatch({
					type: SHARES_DETERMINE_READ_ONLY,
					payload: response.data
				})
			})
			.catch(() => {
				dispatch(sharesError("Could't determine read only mode."))
			})
	}
}

/**
 * Action for listing shares for a user
 */
export function listCompanyShareholders(id) {
	return function (dispatch) {
		return req
			.get(`/shares/companies/${id}`)
			.then((response) => {
				dispatch({
					type: SHARES_COMPANY_SHAREHOLDER_LIST,
					payload: fromJS(response.data)
				})
			})
			.catch(() => {
				dispatch(sharesError("Could't list user shares."))
			})
	}
}

/**
 * Action for updating a shareholder
 */
export function updateShareholder(updatedShareholder) {
	return function (dispatch) {
		return req
			.put(`/shares/companies/${updatedShareholder.get('id')}`, updatedShareholder.toJS())
			.then((response) => {
				dispatch({ type: SHARES_UPDATE_SHAREHOLDER, payload: fromJS(response.data) })
			})
			.catch(() => {
				dispatch(sharesError("Could't list user shares."))
			})
	}
}

/**
 * Action for Hiding a share for a investor
 */
export function hideShare(shareId) {
	return function (dispatch) {
		return req
			.delete(`/shares/${shareId}`)
			.then((response) => {
				dispatch({ type: SHARES_USER_LIST, payload: fromJS(response.data) })
			})
			.catch(() => {
				dispatch(sharesError("Could't remove shareholder."))
			})
	}
}

/**
 * Action for removing a shareholder
 */
export function removeShareholder(shareholderId) {
	return function (dispatch) {
		return req
			.delete(`/shares/companies/${shareholderId}`)
			.then((response) => {
				dispatch({ type: SHARES_REMOVE_SHAREHOLDER, payload: shareholderId })
			})
			.catch(() => {
				dispatch(sharesError("Could't remove shareholder."))
			})
	}
}

/**
 * Action for creating a shareholder
 */
export function createShareholder(shareholder, basePath) {
	return function (dispatch) {
		return req
			.post(`/shares/companies/`, shareholder)
			.then((response) => {
				dispatch({ type: SHARES_SAVE_SHAREHOLDER, payload: fromJS(response.data) })
				this.props.history.push(basePath + '/' + response.data.id)
			})
			.catch((err) => {
				console.log(err)
				dispatch(sharesError("Could't create shareholder."))
			})
	}
}

export function generateExcelFromTemplate(templateId, openInViewer) {
	let w = null
	if (openInViewer) {
		w = window.open(`https://${window.location.hostname}/assets/build/misc/redirecting.html`, '_blank')
	}

	return function () {
		return req.post(`/shares/excel/template/${templateId}`).then((response) => {
			let { downloadUrl, fileSize } = response.data
			if (openInViewer) {
				const { webViewerSupportedFormat, webViewerSupportedSize } = documentHelper.getWebViewerIsSupported(
					undefined,
					'xlsx',
					fileSize
				)
				if (webViewerSupportedFormat && webViewerSupportedSize) {
					downloadUrl = encodeURIComponent(downloadUrl)
					const webViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${downloadUrl}`
					w.location = webViewerUrl
				} else {
					w.location = downloadUrl
				}
			} else {
				window.open(downloadUrl, '_self')
			}
		})
	}
}

/**
 * Action for selecting a shareholder
 */
export function selectShareholder(id) {
	return {
		type: SHARES_SELECT_SHAREHOLDER,
		payload: id
	}
}

/**
 * Error handling
 */
export function sharesError(error) {
	return {
		type: SHARES_ERROR,
		payload: error
	}
}
