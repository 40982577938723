import React, { PureComponent } from 'react'
import { func, bool, string } from 'prop-types'
import styled, { css } from 'styled-components'
import Grid from 'styled-components-grid'
import { Padding } from 'styled-components-spacing'

import LocationSuggestInput from '../../shared/location-suggest/location-suggest'
import Panel from '../../shared/panel/panel'
import Label from '../../shared/label/label'

const ExpendableContent = styled.div`
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.3s ease-in;

	${(props) =>
		props.isExpanded &&
		css`
			max-height: 420px;
		`}
`

export default class PhysicalLocationPanel extends PureComponent {
	static propTypes = {
		locationLabel: string,
		readOnly: bool,
		onChange: func,
		renderGoogleMap: func,
		marginBottom: bool,
		withoutPanel: bool,
		isExpanded: bool
	}

	static defaultProps = {
		marginBottom: false,
		withoutPanel: false
	}

	renderContent = () => {
		const { onChange, readOnly, renderGoogleMap, locationLabel, isExpanded } = this.props

		return (
			<Padding all={3}>
				<Grid>
					<Grid.Unit size={1}>
						<Padding all={3}>
							<Label tid='meetings.location.physical.placeholder.address'>
								<LocationSuggestInput
									fieldName='location'
									disabled={readOnly}
									placeholderTid='meetings.location_panel.placeholder.location'
									onChange={onChange}
									value={locationLabel ? locationLabel : ''}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>

				<ExpendableContent isExpanded={isExpanded}>
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>{renderGoogleMap()}</Padding>
						</Grid.Unit>
					</Grid>
				</ExpendableContent>
			</Padding>
		)
	}

	render = () => {
		const { marginBottom, withoutPanel } = this.props

		if (withoutPanel) {
			return this.renderContent()
		}

		return (
			<Panel title='meetings.location_panel.title' marginBottom={marginBottom}>
				{this.renderContent()}
			</Panel>
		)
	}
}
