import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import { updateTaskListLocal } from '../../../actions/tasks.actions'
import TasksDetailsContainer from '../../tasks/task-details.container'

import { LIVE_TASK_EXTERNAL_DELETE } from '../../../constants/live-update'

class MeetingsTasksDetailsWrapperContainer extends Component {
	componentDidMount = () => {
		const { updateTaskListLocal } = this.props
		updateTaskListLocal(List())
	}

	componentDidUpdate = () => {
		this.checkLiveUpdateEvents()
	}

	checkLiveUpdateEvents = () => {
		const {
			audit,
			match: {
				params: { taskId }
			},
			location: { search },
			history
		} = this.props
		const taskDeleted = audit.get(LIVE_TASK_EXTERNAL_DELETE, Map())

		if (taskDeleted.get('refresh') === true && taskDeleted.get('objId') === taskId) {
			history.push({
				pathname: this.getComponentBasePath(),
				search
			})
		}
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('tasks')
		return pathArray[0] + 'tasks'
	}

	render = () => {
		const {
			match: {
				params: { id, taskId }
			},
			location
		} = this.props
		const customParams = {}
		customParams.id = taskId

		return (
			<TasksDetailsContainer match={{ params: customParams }} location={location} actionsMode='external' objId={id} />
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		audit: store.audit.get('tasks')
	}
}

const mapActionsToProps = {
	updateTaskListLocal
}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingsTasksDetailsWrapperContainer)
