import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { func, bool, string, object, oneOfType, number } from 'prop-types'
import { Padding, Margin } from 'styled-components-spacing'
import { FormattedHTMLMessage } from 'react-intl'
import Grid from 'styled-components-grid'
import styled from 'styled-components'
import Text from '../shared/text/text'
import Alert from '../shared/alert/alert'
import Panel from '../shared/panel/panel'
import Label from '../shared/label/label'
import Button from '../shared/button/button'
import { Select } from '../shared/select'
import Input from '../shared/input/input'
import { lighten } from 'polished'
import Tooltip from '../shared/tooltip/tooltip'
import { Map } from 'immutable'
import documentHelper from '../../components/helpers/documents.helper'
import { NUMBER_FORMAT_CURRENCY, NUMBER_FORMAT_INTEGER, SUBSCRIPTION_ESIGN_BANKID_COST } from '/shared/constants'
import SimpleDarkInfoPanel from '../shared/dark-info-panel/simple-dark-info-panel'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'

const BTN_TOOLTIP_STATES = {
	canNotPurchase: {
		tid: 'subscription.tooltip.missing_card_details',
		delayShow: 'instant'
	},
	isFreeWithStoragePlan: {
		tid: 'subscription.tooltip.not_allow_storage_free',
		delayShow: 'instant'
	}
}

const StyledRows = styled.div`
	border-bottom: 1px solid ${(props) => lighten(0.2, props.theme.colors.lightGrey)};
	border-top: 1px solid ${(props) => lighten(0.2, props.theme.colors.lightGrey)};
	padding: ${(props) => props.theme.spacing[2]} 0;
	margin: ${(props) => props.theme.spacing[2]} 0;
	display: flex;
	flex: 1;
	align-items: flex-end;
	flex-direction: column;
`

const StyledRow = styled.div`
	display: flex;
	flex: 1;
	align-items: flex-end;
	width: 100%;
`

const StyledDescription = styled.div`
	flex: 1;
`

const StyledFormattedHTMLMessage = styled.div`
	color: ${(props) => props.theme.colors.white};
`

const StyledPrice = styled.div``

const PlansPanel = (props) => {
	const {
		i18n,
		accountPlans,
		storagePlans,
		selectedAccountPlanId,
		selectedStoragePlanId,
		subscribedAccountPlan,
		subscribedStoragePlan,
		isFreeWithStoragePlan,
		isLoading,
		disabled,
		warning,
		info,
		infoIcon,
		couponCode,
		partnerCode,
		coupon,
		isCouponLoading,
		onChangeState,
		canPurchase,
		onSaveSubscription,
		onSelectAccountPlan,
		onSelectStoragePlan,
		onClickPurchase,
		onClickReset,
		nextBillingDate,
		companyDueForCancellation,
		isFreeOfCharge,
		willHaveFreeAccountPlan,
		discount,
		couponDisabled,
		selectedAccountPlan
	} = props	

	const getLongDescriptionTid = () => {
		if (selectedAccountPlan) {
			return selectedAccountPlan.getIn(['metadata', 'longDescriptionTid'])
		} else if (subscribedAccountPlan) {
			return subscribedAccountPlan.getIn(['metadata', 'longDescriptionTid'])
		}

		return
	}

	let { documentsSpace, usedStorageSpace } = props

	const _getOptionLabelAccount = (option) => {
		const label = i18n.messages[option.metadata.tid]
		const description = option.metadata.descriptionTid
		const amount = option.amount / 100
		const interval = i18n.messages[`subscriptions.subscribed_product.interval.${option.interval}`]
		const storageString = option.metadata.storageString
		return (
			<span>
				<Text bold={700}>{label}</Text> {description && <Text color='muted' tid={description} />}{' '}
				{amount > 0 && (
					<Text color='muted'>
						{localeFormatNumber(amount, NUMBER_FORMAT_CURRENCY)} {interval}
					</Text>
				)}
				{amount < 1 && <Text color='muted' tid='subscription.free_plan' />}{' '}
				<Text color='muted' tid='subscriptions.incl' /> <Text color='muted'>{storageString}</Text>{' '}
				<Text color='muted' tid='subscriptions.plans.account.label.storage_space' />
			</span>
		)
	}

	const _getOptionLabelStorage = (option) => {
		const label = option.metadata.storage + ' GB'
		const amount = option.amount / 100
		const interval = i18n.messages[`subscriptions.subscribed_product.interval.${option.interval}`]

		if (amount === 0) {
			return (
				<Text color='muted' tid='subscriptions.no_extra_storage'>
					{' '}
				</Text>
			)
		}

		return (
			<span>
				<Text bold={700}>{label}</Text>{' '}
				{amount > 0 && (
					<Text color='muted'>
						{localeFormatNumber(amount, NUMBER_FORMAT_CURRENCY)} {interval}
					</Text>
				)}
				{amount < 1 && <Text color='muted' tid='subscription.free_plan' />}
			</span>
		)
	}

	const hasChangedStoragePlan = selectedStoragePlanId !== subscribedStoragePlan.get('id')
	const hasChangedAccountPlan = selectedAccountPlanId !== subscribedAccountPlan.get('id')
	const isFreePlan = subscribedAccountPlan.get('id') === 'freeAccount'
	const longDescriptionTid = getLongDescriptionTid()
	let couponIcon
	let couponIconColor

	if (isCouponLoading) {
		couponIconColor = 'midGrey'
	} else if (coupon) {
		if (coupon.get('valid')) {
			couponIcon = 'faCheck'
			couponIconColor = 'green'
		} else {
			couponIcon = 'faExclamationTriangle'
			couponIconColor = 'orange'
		}
	}

	let activeState
	if (!canPurchase) {
		activeState = 'canNotPurchase'
	} else if (isFreeWithStoragePlan) {
		activeState = 'isFreeWithStoragePlan'
	}

	const renderStorageInfo = () => {
		const formattedUsedStorageSpace = documentHelper.getFileSize(Map({ size: usedStorageSpace * 1024 * 1024 * 1024 }))
		const formattedDocumentSpace = documentHelper.getFileSize(Map({ size: documentsSpace * 1000 * 1000 * 1000 }), 1000)

		return (
			<Grid.Unit size={1}>
				<Padding all={3}>
					<StyledRow>
						<StyledDescription>
							<Text fontSize='13px' tid='subscriptions.disk_space.title' color='muted' />
							<br />
						</StyledDescription>
					</StyledRow>
					<StyledRows>
						<StyledRow>
							<StyledDescription>
								<Text fontSize='13px'>
									{localeFormatNumber(formattedUsedStorageSpace.value || 0, NUMBER_FORMAT_INTEGER)}{' '}
									{formattedUsedStorageSpace.unit} <Text fontSize='13px' tid='subscriptions.disk_space.of_total' />{' '}
									{localeFormatNumber(formattedDocumentSpace.value, NUMBER_FORMAT_INTEGER)}{' '}
									{formattedDocumentSpace.unit}
								</Text>
							</StyledDescription>
						</StyledRow>
					</StyledRows>
				</Padding>
			</Grid.Unit>
		)
	}

	return (
		<Panel mode='light' title='subscriptions.select_product' marginBottom>
			<Padding all={3}>
				<Grid>
					<Grid.Unit size={1}>
						{subscribedAccountPlan && (
							<Padding all={3}>
								<StyledRow>
									<StyledDescription>
										<Text fontSize='13px' tid='subscriptions.subscribed_product.current' color='muted' />
										<br />
									</StyledDescription>
									<StyledPrice>
										<Text fontSize='13px' tid='subscriptions.subscribed_product.inc_vat' color='muted' />
										<br />
									</StyledPrice>
								</StyledRow>
								<StyledRows>
									<StyledRow>
										<StyledDescription>
											<Text fontSize='13px' tid='subscriptions.select.change_plan' />
											<Text fontSize='13px'>
												{' '}
												- <Text fontSize='13px' tid={subscribedAccountPlan.getIn(['metadata', 'tid'])} />
											</Text>
										</StyledDescription>
										<StyledPrice>
											<Text fontSize='13px'>
												{localeFormatNumber(subscribedAccountPlan.get('amount') / 100, NUMBER_FORMAT_CURRENCY)}
											</Text>
										</StyledPrice>
									</StyledRow>
									<StyledRow>
										<StyledDescription>
											<Text fontSize='13px' tid='subscriptions.storage' />
											<Text fontSize='13px'> - {subscribedStoragePlan.getIn(['metadata', 'storage']) + ' GB'} </Text>
										</StyledDescription>
										<StyledPrice>
											<Text fontSize='13px'>
												{localeFormatNumber(subscribedStoragePlan.get('amount') / 100, NUMBER_FORMAT_CURRENCY)}
											</Text>
										</StyledPrice>
									</StyledRow>
								</StyledRows>
								{discount > 0 && !isFreeOfCharge && (
									<StyledRow>
										<StyledDescription />
										<StyledPrice>
											<Margin right={4} as='span'>
												<Text color='muted' fontSize='13px' tid='subscriptions.subscribed_product.total.discount' />
											</Margin>

											<Text fontSize='13px'>-{localeFormatNumber(discount, NUMBER_FORMAT_CURRENCY)}</Text>
										</StyledPrice>
									</StyledRow>
								)}
								<StyledRow>
									<StyledDescription />
									<StyledPrice>
										{subscribedAccountPlan.get('interval') && (
											<Margin right={4} as='span'>
												<Text
													color='muted'
													fontSize='13px'
													tid={`subscriptions.subscribed_product.total.${subscribedAccountPlan.get('interval')}`}
												/>
											</Margin>
										)}

										{subscribedAccountPlan.get('amount') > 0 && (
											<span>
												<Text fontSize='13px'>
													{!isFreeOfCharge &&
														localeFormatNumber(
															(subscribedAccountPlan.get('amount') + subscribedStoragePlan.get('amount')) / 100 -
																discount,
															NUMBER_FORMAT_CURRENCY
														)}
													{isFreeOfCharge && <Text> {localeFormatNumber(0, NUMBER_FORMAT_CURRENCY)} (100% OFF) </Text>}
												</Text>
											</span>
										)}
									</StyledPrice>
								</StyledRow>
							</Padding>
						)}
					</Grid.Unit>
					{nextBillingDate && (
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Margin bottom={4}>
									<Text
										tag='p'
										align='center'
										hasItalic
										tid='subscriptions.next_charge'
										values={{ date: nextBillingDate }}
									/>
								</Margin>
							</Padding>
						</Grid.Unit>
					)}

					{documentsSpace && renderStorageInfo()}

					<Grid.Unit size={1}>
						<Padding all={3}>
							<Label tid='subscriptions.select.change_plan'>
								<Select
									options={accountPlans}
									onChange={onSelectAccountPlan}
									optionsValue='id'
									getOptionLabel={_getOptionLabelAccount}
									value={selectedAccountPlanId}
									isDisabled={disabled}
								/>
							</Label>
						</Padding>
					</Grid.Unit>

					<Grid.Unit size={1}>
						<Padding all={3}>
							<SimpleDarkInfoPanel>
								<StyledFormattedHTMLMessage>
									<FormattedHTMLMessage id={longDescriptionTid} values={{ price: SUBSCRIPTION_ESIGN_BANKID_COST }} />
								</StyledFormattedHTMLMessage>
							</SimpleDarkInfoPanel>
						</Padding>
					</Grid.Unit>

					{!willHaveFreeAccountPlan && (
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='subscriptions.select.change_storage'>
									<Select
										options={storagePlans}
										onChange={onSelectStoragePlan}
										optionsValue='id'
										getOptionLabel={_getOptionLabelStorage}
										value={selectedStoragePlanId}
										isDisabled={disabled}
										isClearable={true}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					)}

					<Grid.Unit size={1 / 2}>
						<Padding all={3}>
							<Label tid='subscriptions.coupon'>
								<Input
									fieldName='couponCode'
									placeholderTid='subscriptions.account.placeholder.coupon'
									onChange={onChangeState}
									value={couponCode}
									icon={couponCode && couponIcon}
									disabled={disabled || couponDisabled}
									iconColor={couponIconColor}
									isLoading={isCouponLoading}
								/>
							</Label>
						</Padding>
					</Grid.Unit>

					<Grid.Unit size={1 / 2}>
						<Padding all={3}>
							<Label tid='subscriptions.partner_code'>
								<Input
									fieldName='partnerCode'
									placeholderTid='subscriptions.account.placeholder.partner'
									onBlur={onSaveSubscription}
									onChange={onChangeState}
									value={partnerCode}
									disabled={disabled}
								/>
							</Label>
						</Padding>
					</Grid.Unit>

					<Grid.Unit size={1}>
						<Padding all={3}>
							{coupon && !coupon.get('valid') && (
								<Margin bottom={4}>
									<Alert mode='warning' icon>
										<Text tid='subscriptions.coupon.coupon_no_longer_valid' color='white' />
									</Alert>
								</Margin>
							)}

							{!isFreeOfCharge && warning && (
								<Margin bottom={4}>
									<Alert mode='warning' icon>
										{typeof warning === 'string' && <Text tid={warning} color='white' />}
										{typeof warning !== 'string' && <Text {...warning} color='white' />}
									</Alert>
								</Margin>
							)}

							{!isFreeOfCharge && info && (
								<Margin bottom={4}>
									<Alert mode='info' icon={infoIcon}>
										{typeof info === 'string' && <Text tid={info} color='white' />}
										{typeof info !== 'string' && <Text {...info} color='white' />}
									</Alert>
								</Margin>
							)}

							{(!disabled || companyDueForCancellation) && (
								<Tooltip states={BTN_TOOLTIP_STATES} activeState={activeState}>
									<Button
										isLoading={isLoading}
										mode='primary'
										minWidth='160px'
										onClick={() => {
											onClickPurchase()
										}}
										tid={isFreePlan ? 'subscriptions.purchase' : 'subscriptions.purchase.change_subscription'}
										disabled={
											!canPurchase || isFreeWithStoragePlan || !(hasChangedAccountPlan || hasChangedStoragePlan)
										}
									/>
								</Tooltip>
							)}
							{!isFreeOfCharge && disabled && !companyDueForCancellation && (
								<Button
									isLoading={isLoading}
									mode='primary'
									onClick={onClickReset}
									tid='subscription.reset_plan'
									block
								/>
							)}
						</Padding>
					</Grid.Unit>
				</Grid>
			</Padding>
		</Panel>
	)
}

PlansPanel.defaultProps = {
	isLoading: false,
	infoIcon: false,
	canPurchase: false
}

PlansPanel.propTypes = {
	plans: ImmutablePropTypes.list,
	selectedPlan: string,
	onSelectPlan: func,
	onClickPurchase: func,
	onClickReset: func,
	isProductLoading: bool,
	currentPlan: ImmutablePropTypes.map,
	warning: oneOfType([string, object]),
	info: oneOfType([string, object]),
	infoIcon: bool,
	willChangePlan: bool,
	couponCode: string,
	partnerCode: string,
	onVerifyCoupon: func,
	onSavePartnerCode: func,
	coupon: ImmutablePropTypes.map,
	isCouponLoading: bool,
	isPartnerCodeLoading: bool,
	canPurchase: bool,
	companyDueForCancellation: bool,
	onSaveSubscription: func,
	onChangePartnetCode: func,
	isFreeWithStoragePlan: bool,
	isFreeOfCharge: bool,
	willHaveFreeAccountPlan: bool,
	documentsSpace: number,
	usedStorageSpace: number,
	discount: number,
	couponDisabled: bool,
	selectedAccountPlan: ImmutablePropTypes.map
}

export default PlansPanel
