import { fromJS } from 'immutable'
import { USERS_SET_USER_ID } from '../actions/types'

const INITIAL_STATE = fromJS({
	userId: null
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case USERS_SET_USER_ID:
			return state.set('userId', payload)
		default:
			return state
	}
}
