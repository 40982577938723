export function getShowDelay(delayShow) {
	switch (delayShow) {
		case 'instant':
			return 0
		case 'short':
			return 300
		default:
			return 600
	}
}

export function getTextColor(type) {
	switch (type) {
		default:
			return 'lightestGrey'
	}
}

export function isEllipsisActive(element) {
	return element.offsetWidth < element.scrollWidth
}

export function showErrorTooltip(_this, refName) {
	if (_this[refName]) {
		setTimeout(() => {
			_this[refName].show()
		}, 0)
	}
}

export function hideErrorTooltip(_this, refName) {
	if (_this[refName]) {
		setTimeout(() => {
			_this[refName].hide()
		}, 0)
	}
}
