import React, { Component } from 'react'
import { connect } from 'react-redux'

import Modal from '../../../../../dumb-components/onboarding/modal'
import HeaderControls from '../../header-controls.container'
import Intro from '../../../../../dumb-components/onboarding/modals/company-dashboard/intro'

import { markOnboardAsDismiss } from '../../../../../actions/notifications.actions'
import { closeModal } from '../../../../../actions/modals.actions'
import history from '../../../../../interfaces/history'

class CompanyDashboardIntro extends Component {
	componentDidMount = () => {
		hj && hj('trigger', 'heatmap-onboarding-company-dashboard')
	}

	dismissPermanently = () => {
		const { notificationId, markOnboardAsDismiss, closeModal } = this.props

		markOnboardAsDismiss(notificationId)

		this.dropdownRef && this.dropdownRef.onToggleMenu()

		closeModal()
	}

	getRequestedResource = (section) => {
		const { companyResources } = this.props
		const resources = companyResources && companyResources[0] && companyResources[0].children
		let foundResource = false

		if (!resources) {
			return foundResource
		}

		resources.forEach((resource) => {
			const { children } = resource

			if (children) {
				children.forEach((resource) => {
					if (resource.id === section) {
						foundResource = resource
					}
				})
			}

			if (resource.id === section) {
				foundResource = resource
			}
		})

		return foundResource
	}

	goToSection = (url) => {
		this.props.history.push(url)

		// Dismiss onboarding modal
		this.dismissPermanently()
	}

	renderHeaderControls = () => {
		return <HeaderControls />
	}

	render = () => {
		const { companyResources } = this.props
		return (
			<Modal align='right' dropdownComponent={this.renderHeaderControls()}>
				<Intro
					companyResources={companyResources} // Do not remove even if unused
					onGoToSection={this.goToSection}
					onGetResource={this.getRequestedResource}
				/>
			</Modal>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		notificationId: store.modals.getIn(['activeModal', 'options', 'notificationId']),
		companyResources: store.menu.companyResources
	}
}

const mapActionsToProps = {
	markOnboardAsDismiss,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyDashboardIntro)
