import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { isImmutable } from 'immutable'

const isJson = (str) => {
	try {
		return JSON.parse(str) ? true : false
	} catch (e) {
		return false
	}
}

export const convertToHtml = (contentState) => {
	if (!contentState) {
		return ''
	}

	try {
		contentState = isImmutable(contentState) ? contentState.toJS() : contentState
		if (typeof contentState === 'string') {
			contentState = JSON.parse(contentState)
		}

		return stateToHTML(convertFromRaw(contentState))
	} catch (e) {
		return ''
	}
}

export const convertToContentState = (str) => {
	if (!isJson(str)) {
		return null
	}

	try {
		const contentState = convertFromRaw(JSON.parse(str))
		return contentState
	} catch (e) {
		console.error(e)
	}
}

export const stripTags = (string) => {
	return string.replace(/(<([^>]+)>)/gi, '')
}
