import React from 'react';
import { string, bool, func, oneOfType, object, array } from 'prop-types';
import styled, { css } from 'styled-components';
import Modal from '../modal/modal';
import Text from '../text/text';
import Checkbox from '../checkbox/checkbox';
import Icon from '../icon/icon';
import { Padding } from 'styled-components-spacing';
import ColoredContentWrapper from '../colored-content-wrapper/colored-content-wrapper';

const StyledContainer = styled.div`
	height: 100%;
	display: flex;
	padding: 0;
	flex-direction: column;
	justify-content: center;

	${(props) =>
		props.dismissable &&
		css`
			justify-content: space-around;
		`}
`;

const StyledConfirmWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-top: ${(props) => props.theme.spacing[4]};

	${(props) =>
		props.hasColoredWrapper &&
		css`
			padding-top: ${(props) => props.theme.spacing[4]};
		`}
`;

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
`;

const StyledIconWrapper = styled.div`
	padding-bottom: ${(props) => props.theme.spacing[3]};
`;

const CheckboxWrapper = styled.div`
	display: flex;
	align-items: center;

	> :first-child {
		margin-right: ${(props) => props.theme.spacing[3]};
	}
`;

const Confirm = ({
	title,
	isOpen,
	question,
	message,
	footerComponent,
	dismissable,
	onDismiss,
	isConfirmDismissed,
	hasColoredWrapper,
	infoTid,
	oneWrapper
}) => {
	const modalProps = {
		title,
		isOpen,
		footerComponent,
		hSize: 'md',
		vSize: dismissable || hasColoredWrapper ? 50 : 25,
		noBodyMargin: true
	};

	const formatMessage = () => {
		if (typeof message === 'string' && hasColoredWrapper && oneWrapper) {
			return (
				<StyledConfirmWrapper>
					<StyledColoredContentWrapper type='secondary'>
						<StyledIconWrapper>
							<Icon icon='faExclamationTriangle' size='normal' color='yellowMid' type='solid' />
						</StyledIconWrapper>
						<Text tid={question} />
						<br />
						<Text tid={message} />
					</StyledColoredContentWrapper>
				</StyledConfirmWrapper>
			);
		}

		if (typeof message === 'string' && hasColoredWrapper) {
			return (
				<StyledConfirmWrapper>
					<StyledColoredContentWrapper type='secondary'>
						<StyledIconWrapper>
							<Icon icon='faExclamationTriangle' size='normal' color='yellowMid' type='solid' />
						</StyledIconWrapper>
						<Text tid={message} />
					</StyledColoredContentWrapper>
				</StyledConfirmWrapper>
			);
		}

		if (typeof message === 'string') {
			return <Text tag='p' tid={message} />;
		}

		if (message && message.tid && hasColoredWrapper) {
			return (
				<StyledConfirmWrapper>
					<StyledColoredContentWrapper type='secondary'>
						<StyledIconWrapper>
							<Icon icon='faExclamationTriangle' size='normal' color='yellowMid' type='solid' />
						</StyledIconWrapper>
						<Text tag='p' tid={message.tid} values={message.values} asHtml={message.asHml} />
					</StyledColoredContentWrapper>
				</StyledConfirmWrapper>
			);
		}

		if (message && message.tid) {
			return <Text tag='p' tid={message.tid} values={message.values} asHtml={message.asHml} />;
		}

		if (message && Array.isArray(message) && hasColoredWrapper) {
			return (
				<StyledConfirmWrapper>
					<StyledColoredContentWrapper type='secondary'>
						<StyledIconWrapper>
							<Icon icon='faExclamationTriangle' size='normal' color='yellowMid' type='solid' />
						</StyledIconWrapper>
						{message.map((m, index) => (
							<Text key={index} tag='p' tid={m.tid} values={m.values} asHtml={m.asHml} />
						))}
					</StyledColoredContentWrapper>
				</StyledConfirmWrapper>
			);
		}

		if (message && Array.isArray(message)) {
			return message.map((m, index) => {
				return <Text key={index} tag='p' tid={m.tid} values={m.values} asHtml={m.asHml} />;
			});
		}
	};

	const formatQuestion = () => {
		if (oneWrapper) {
			return null;
		}

		if (typeof question === 'string' && hasColoredWrapper) {
			return (
				<StyledConfirmWrapper hasColoredWrapper={hasColoredWrapper}>
					<StyledColoredContentWrapper type='secondary'>
						<StyledIconWrapper>
							<Icon icon='faExclamationTriangle' size='normal' color='yellowMid' type='solid' />
						</StyledIconWrapper>
						<Text tid={question} />
						{infoTid && (
							<Padding top={4}>
								<Text tid={infoTid} />
							</Padding>
						)}
					</StyledColoredContentWrapper>
				</StyledConfirmWrapper>
			);
		}

		if (typeof question === 'string') {
			return <Text tag='p' fontSize='16px' bold={600} tid={question} />;
		}

		if (question && question.tid) {
			return (
				<Text
					tag='p'
					fontSize='16px'
					bold={600}
					tid={question.tid}
					values={question.values}
					asHtml={question.asHtml}
				/>
			);
		}
	};

	return (
		<Modal {...modalProps} scrollableContent={true}>
			<StyledContainer dismissable={dismissable}>
				{question && formatQuestion()}
				{message && formatMessage()}
				{dismissable && (
					<CheckboxWrapper>
						<Checkbox mode='modern-big' onChange={onDismiss} checked={isConfirmDismissed} />
						<Text tid='generic.dismiss_block' />
					</CheckboxWrapper>
				)}
			</StyledContainer>
		</Modal>
	);
};

Confirm.propTypes = {
	title: string,
	question: oneOfType([string, object]),
	message: oneOfType([string, object, array]),
	isOpen: bool,
	dismissable: bool,
	onDismiss: func,
	isConfirmDismissed: bool,
	hasColoredWrapper: bool,
	infoTid: string,
	oneWrapper: bool
};

export default Confirm;
