import axios from 'axios'
import Cookies from 'universal-cookie'
import { saveAs } from 'file-saver'
import apiError from '../errors/api.error'

export default {
	async getPersonalDocuments() {
		try {
			const res = await axios.get('/api/gateway/personal-documents', {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			})

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async deletePersonalDocument(absolutePath) {
		try {
			await axios.delete(`/api/gateway/personal-documents/${absolutePath}`, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			})
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async uploadPersonalDocuments(prefix, documents) {
		const fd = new FormData()

		fd.set('prefix', prefix)

		for (const document of documents) {
			fd.append('files', document)
		}

		try {
			const res = await axios.post('/api/gateway/personal-documents/documents', fd, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`,
					'Content-Type': 'multipart/form-data'
				}
			})

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async getPersonalFolders() {
		try {
			const res = await axios.get('/api/gateway/personal-documents/folders', {
				headers: { authorization: `Bearer ${new Cookies().get('accessToken')}` }
			})

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async uploadPersonalDocumentsFolder(prefix, folderName) {
		try {
			const res = await axios.post(
				'/api/gateway/personal-documents/folders',
				{ prefix, folderName },
				{ headers: { authorization: `Bearer ${new Cookies().get('accessToken')}` } }
			)

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async downloadPersonalDocument(fileName) {
		try {
			const res = await axios.get(`/api/gateway/personal-documents/documents/${encodeURIComponent(fileName)}`, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				},
				responseType: 'blob'
			})

			saveAs(
				new Blob([res.data], { type: res.headers['content-type'] }),
				res.headers['content-disposition'].match(/filename=(.+)/)[1]
			)
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async deletePersonalDocumentsFolder(absolutePath) {
		try {
			await axios.delete(`/api/gateway/personal-documents/folders/${encodeURIComponent(absolutePath)}`, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			})
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async movePersonalDocument(from, to) {
		try {
			await axios.put(
				`/api/gateway/personal-documents/documents/${encodeURIComponent(from)}`,
				{ folderPath: to },
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			)
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async movePersonalFolder(from, to) {
		try {
			await axios.put(
				`/api/gateway/personal-documents/folders/${encodeURIComponent(from)}`,
				{ folderPath: to },
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			)
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async getCompanyFolders(companyId) {
		try {
			const res = await axios.get(`/api/gateway/personal-documents/companies/${companyId}/folders`, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			})

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async uploadPersonalDocumentToCompanyFolder(companyId, targetFolder, documentPath) {
		try {
			const res = await axios.post(
				`/api/gateway/personal-documents/companies/${companyId}/folders`,
				{
					documentPath,
					folderId: targetFolder
				},
				{
					headers: { authorization: `Bearer ${new Cookies().get('accessToken')}` }
				}
			)

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async copyDocumentToPersonalDocuments(params) {
		try {
			const res = await axios.post('/api/gateway/personal-documents/copy', params, {
				headers: { authorization: `Bearer ${new Cookies().get('accessToken')}` }
			})

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async getSignedUrl(fileName) {
		try {
			const res = await axios.get(
				`/api/gateway/personal-documents/documents/signed-urls/${encodeURIComponent(fileName)}`,
				{
					headers: { authorization: `Bearer ${new Cookies().get('accessToken')}` }
				}
			)

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	}
}
