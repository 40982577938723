import React from 'react'
import styled, { css } from 'styled-components'
import { bool, string, oneOf, oneOfType } from 'prop-types'
import { desaturate, darken } from 'polished'
import Icon from '../icon/icon'
import Text from '../text/text'

const StyledWarningTemplate = css`
	background-color: ${(props) => props.theme.colors.orange};
	color: ${(props) => props.theme.colors.white};
`

const StyledErrorTemplate = css`
	background-color: ${(props) => props.theme.colors.red};
	color: ${(props) => props.theme.colors.white};
`

const StyledInfoTemplate = css`
	background-color: ${(props) => props.theme.colors.java};
	color: ${(props) => props.theme.colors.white};
`

const StyledSuccessTemplate = css`
	background-color: ${(props) => props.theme.colors.green};
	color: ${(props) => props.theme.colors.white};
`

const StyledDarkInfoTemplate = css`
	background-color: ${(props) => props.theme.colors.limedSpruce};
	color: ${(props) => props.theme.colors.white};
`

const StyledWrapper = styled.div`
	display: flex;
	align-items: ${(props) => props.alignItems};
	padding: ${(props) => props.theme.spacing[4]};
	border-radius: ${(props) => props.theme.spacing[3]};
	margin: ${(props) => props.margin};

	${(props) => {
		if (props.mode === 'warning') {
			return StyledWarningTemplate
		} else if (props.mode === 'error') {
			return StyledErrorTemplate
		} else if (props.mode === 'success') {
			return StyledSuccessTemplate
		} else if (props.mode === 'dark') {
			return StyledDarkInfoTemplate
		}

		return StyledInfoTemplate
	}}
`

const StyledIcon = styled.div`
	max-width: 46px;
	padding-right: ${(props) => props.theme.spacing[4]};
	padding-top: ${(props) => props.theme.spacing[2]};
`

const StyledText = styled.div`
	flex: 1;
`

const Alert = ({ className, children, icon, iconType, iconColor, mode, alignItems, tid, margin }) => {
	if (icon && typeof icon !== 'string') {
		icon = mode === 'warning' ? 'faExclamationTriangle' : 'faInfoCircle'
	}

	return (
		<StyledWrapper mode={mode} className={className} alignItems={alignItems} margin={margin}>
			{icon && (
				<StyledIcon>
					<Icon icon={icon} type={iconType} size='sml' color={iconColor} />
				</StyledIcon>
			)}
			<StyledText>{children ? children : <Text tid={tid} color='white' />}</StyledText>
		</StyledWrapper>
	)
}

Alert.defaultProps = {
	icon: false,
	iconType: 'light',
	iconColor: 'white',
	alignItems: 'center'
}

Alert.propTypes = {
	mode: oneOf(['info', 'warning', 'error', 'success', 'dark']),
	icon: oneOfType([bool, string]),
	iconType: oneOf(['light', 'solid']),
	iconColor: string,
	alignItems: string
}

export default Alert
