import { Map } from 'immutable'
import moment from '../../modules/moment.module'
import mime from 'mime-types'

const obj = {
	SUPPORTED_WEB_VIEWER_FORMATS: ['docx', 'pptx', 'xlsx', 'doc', 'ppt', 'xls'],

	SUPPORTED_BROWSER_FORMATS: ['jpg', 'jpeg', 'png', 'gif', 'pdf'],

	getWebViewerIsSupported: (file, ext, size = 10000000) => {
		const SIZE_IGNORED_FORMATS = ['pdf'] // Web Viewer has no size-limitation for PDFs

		let webViewerSupportedFormat = false
		let webViewerSupportedSize = false

		if (!file && !ext && !size) {
			return { webViewerSupportedFormat, webViewerSupportedSize }
		}

		ext = file ? file.get('ext') : ext
		size = file ? file.get('size') : size

		// Check if format is supported
		webViewerSupportedFormat = obj.SUPPORTED_WEB_VIEWER_FORMATS.includes(ext)

		// Check if filesize is supported
		webViewerSupportedSize = SIZE_IGNORED_FORMATS.includes(ext) || size <= 10485760 // PDFs don't have size limitation. 10MB+

		return { webViewerSupportedFormat, webViewerSupportedSize }
	},

	isViewableInBrowser: (file, ext, size = 10000000) => {
		let viewable = false

		if (!file && !ext && !size) {
			return viewable
		}

		ext = file ? file.get('ext') : ext
		size = file ? file.get('size') : size

		if (obj.SUPPORTED_BROWSER_FORMATS.includes(ext)) {
			viewable = true
		} else {
			const { webViewerSupportedFormat, webViewerSupportedSize } = obj.getWebViewerIsSupported(undefined, ext, size)
			viewable = webViewerSupportedFormat && webViewerSupportedSize
		}

		return viewable
	},

	getDocumentIsPdf: (ext) => {
		return ext === 'pdf'
	},

	getFileSize: (file, base = 1024) => {
		const size = file.get('size')
		const tb = base * base * base * base
		const gb = base * base * base
		const mb = base * base
		const kb = base

		let unit = ''
		let value = 0
		try {
			if (size >= tb) {
				value = size / tb
				unit = 'TB'
			} else if (size >= gb) {
				value = size / gb
				unit = 'GB'
			} else if (size >= mb) {
				value = size / mb
				unit = 'MB'
			} else if (size >= kb) {
				value = size / kb
				unit = 'KB'
			} else {
				value = size
				unit = 'Byte'
			}

			return { value, unit }
		} catch (e) {}
	},

	createESignatoryObject: (userObj) => {
		let signObj = Map({
			userId: userObj.get('userId'),
			active: true,
			status: 'NOT-SIGNED',
			notifyAboutSignedDoc: true,
			notified: false
		})

		signObj = signObj.merge(userObj)

		return signObj
	},

	getStatus: (doc) => {
		if (
			doc.get('reminderSet') &&
			doc.get('validTo') &&
			moment(doc.get('validTo')).diff(moment().hour(0).minutes(0).seconds(0).milliseconds(0), 'days') <= 0
		) {
			return 'INVALID'
		} else if (
			doc.get('reminderSet') &&
			doc.get('reminderDate') &&
			moment(doc.get('reminderDate')).diff(moment().hour(0).minutes(0).seconds(0).milliseconds(0), 'days') <= 0
		) {
			return 'WARNING'
		}
	},

	getStatusColor: (status, defaultColor = 'solitudeMid') => {
		switch (status) {
			case 'INVALID':
				return 'red'
			case 'WARNING':
				return 'orange'
			default:
				return defaultColor
		}
	},

	getDocumentIsSigned: (document) => {
		if (!document) {
			return false
		}

		if (document.get('eSigning') !== true) {
			return false
		}

		const fileReference = document.getIn(['eSigningData', 'fileReference'])
		const allSigned = obj.getDocumentAllHaveSigned(document)
		return fileReference && allSigned
	},

	getDocumentAllHaveSigned: (document) => {
		if (!document) {
			return false
		}

		if (document.get('eSigning') !== true) {
			return false
		}

		if (!document.get('eSignees', Map()).size) {
			return false
		}

		const eSignees = document.get('eSignees', Map())
		const allSigned = eSignees.find((eSignee) => eSignee.get('status') !== 'SIGNED') ? false : true
		return allSigned
	},

	allSignatoriesNotified: (document) => {
		const eSignees = document ? document.get('eSignees', Map()) : Map()
		const allNotified = eSignees.find((eSignee) => eSignee.get('notified') === false) ? false : true
		return allNotified
	},

	documentPendingEsigning: (document) => {
		return document.get('eSigning') === true && !obj.getDocumentAllHaveSigned(document)
	},

	getHasMergedDocument: (doc) => {
		return doc.hasIn(['mergedDocumentData', 'fileReference'])
	},

	getCorrectMergedFileReference: (doc) => {
		const hasMergedDocument = obj.getHasMergedDocument(doc)

		if (hasMergedDocument) {
			return doc.getIn(['mergedDocumentData', 'fileReference'])
		}

		return doc.get('file')
	},

	covertSigneesObjectToShareWithObject: (eSignees) => {
		let shareWith = Map()

		if (!eSignees) {
			return shareWith
		}

		eSignees.forEach((eSignee) => {
			const userId = eSignee.get('userId')
			const isInvestor = eSignee.get('isInvestor')
			const isGuest = eSignee.get('isGuest')
			const name = eSignee.get('name')
			const email = eSignee.get('email')

			shareWith = shareWith.setIn([userId, 'userId'], userId)
			shareWith = shareWith.setIn([userId, 'addedByEsignees'], true)

			if (isInvestor) {
				shareWith = shareWith.setIn([userId, 'isInvestor'], true)
				shareWith = shareWith.setIn([userId, 'investmentId'], eSignee.get('investmentId'))
			}

			if (isGuest && email && name) {
				shareWith = shareWith.setIn([userId, 'isGuest'], true)
				shareWith = shareWith.setIn([userId, 'name'], name)
				shareWith = shareWith.setIn([userId, 'email'], email)
			}
		})

		return shareWith
	},

	/**
	 * Count the number of people in a certain group to share a document with.
	 * @param shareWith the shareWith sub-object within document
	 * @param group shareholders|attendees|individuals the group to count people in
	 * @param attendees meeting attendees
	 */
	countNumberOfPeopleToShareDocumentWith: (shareWith, group, attendees) => {
		if (!shareWith) {
			return
		}

		switch (group) {
			case 'shareholders':
				return shareWith.filter((person) => person.get('isInvestor')).size

			case 'attendees': {
				let num = 0

				shareWith.forEach((person) => {
					if (attendees?.has(person.get('userId'))) {
						num++
					}
				})

				return num
			}

			case 'individuals': {
				let num = 0

				shareWith.forEach((person) => {
					if (!person.get('isInvestor') && !attendees?.has(person.get('userId'))) {
						num++
					}
				})

				return num
			}
		}
	},

	/**
	 * Validate a file by its type and extension
	 * @param fileToUpload file object of the file that should be validated
	 * @param accepted what type of data the file should be validated against
	 */
	validateFileExtension: (fileToUpload, fileExtensions) => {
		const { type, name } = fileToUpload
		const fileParts = name.split('.')
		const extension = fileParts[fileParts.length - 1]
		const types = mime.types[extension]
		const extensions = mime.extensions[type]

		const valid =
			extensions.includes(extension) && types.includes(type) && extensions.some((ext) => fileExtensions.includes(ext))

		return valid
	},

	/**
	 * Get the displayStatus value for a given object
	 * @param links the links object to search
	 * @objId the ID of the object
	 */
	findDisplayStatus: (links, objId) => {
		if (!links || !objId) {
			return
		}

		const link = links.find((l) => l.get('objId') === objId)
		return link?.get('displayStatus')
	}
}

export default obj
