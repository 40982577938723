import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import AttendeeDetailsPanel from '../../../dumb-components/meetings/attendee-details-panel/attendee-details-panel'
import { fetchAttendee } from '../../../actions/meetings.actions'
import ListPanel from '../../../dumb-components/shared/list-panel/list-panel'
import ListPanelItem from '../../../dumb-components/shared/list-panel/list-panel-item'
import Box from '../../../dumb-components/shared/layout/box/box'
import { getUserName } from '../../../components/helpers/users'
import { LIVE_MEETINGS_ATTENDEE_REMOVE } from '../../../constants/live-update'

import AttendeeFunctionsContainer from './attendee-functions.container'
import ManageAttendeesFunctionModalContainer from './manage-attendee-functions-modal.container'

import { openModal } from '../../../actions/modals.actions'
import { MEETINGS_ATTENDEE_FUNCTIONS_MANAGE_MODAL } from '../../../constants/modals'

import { fetchAndCacheInvestors } from '../../../actions/investors.actions'
import history from '../../../interfaces/history'

class AttendeesDetailsContainer extends Component {
	state = {
		rolesOptions: null,
		attendee: null
	}

	static propTypes = {}

	componentDidMount() {
		this.fetchAttendee()
	}

	componentDidUpdate = (prevProps) => {
		const {
			meeting,
			match: {
				params: { attendeeId }
			}
		} = this.props

		if (attendeeId && attendeeId !== prevProps.match.params.attendeeId) {
			this.fetchAttendee()
		}

		if (meeting && meeting.get('attendees') !== prevProps.meeting?.get('attendees')) {
			this.fetchAttendee()
		}

		this.checkLiveUpdateEvents()
	}

	checkLiveUpdateEvents = () => {
		const {
			audit,
			match: {
				params: { id, attendeeId }
			},
			location: { search },
			history
		} = this.props
		const attendeeDelete = audit.get(LIVE_MEETINGS_ATTENDEE_REMOVE, Map())

		if (
			attendeeDelete.get('refresh') === true &&
			id === attendeeDelete.get('objId') &&
			attendeeDelete.getIn(['metadata', 'removedAttendees']).includes(attendeeId)
		) {
			const basePath = this.getComponentBasePath().split('meetings')[0] + 'meetings'
			history.push({
				pathname: basePath,
				search
			})
		}
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('attendees')
		return pathArray[0] + 'attendees'
	}

	fetchAttendee = () => {
		const {
			meeting,
			fetchAttendee,
			fetchAndCacheInvestors,
			match: {
				params: { attendeeId }
			},
			location: { search }
		} = this.props

		// Fetch attendee
		meeting &&
			fetchAttendee(attendeeId, (attendee) => {
				if (!attendee) {
					const basePath = this.getComponentBasePath().split('meetings')[0] + `meetings/${meeting.get('id')}/meeting/attendees`
					history.push({
						pathname: basePath,
						search
					})
					return
				}

				if (!attendee.get('isGuest')) {
					fetchAndCacheInvestors(List([attendee.get('userId')]))
				}
				this.setState({ attendee })
			})
	}

	renderCompanyMemberItem = (attendee, index) => {
		const respondedAt = attendee.get('date')
		const status = attendee.get('status', undefined)
		const name = this.getUserName(attendee)

		return <ListPanelItem name={name} status={status} date={respondedAt} key={index} />
	}

	getUserName = (attendee) => {
		const { investors, usersCache } = this.props
		return getUserName(attendee, investors, usersCache)
	}

	openHandleFunctionsModal = () => {
		this.props.openModal(MEETINGS_ATTENDEE_FUNCTIONS_MANAGE_MODAL)
	}

	renderCompanyMembersPanel = () => {
		const { meeting, usersCache } = this.props
		const { attendee } = this.state

		const isProxyForCompany = attendee.get('isProxyForCompany', false)
		const id = isProxyForCompany ? isProxyForCompany : attendee.get('userId')

		const attendees = meeting ? meeting.getIn(['metadata', 'companyAttendees', id], List()) : List()
		if (!usersCache || attendees.size === 0) {
			return null
		}

		let name = ''
		if (attendee) {
			name = meeting.getIn(['metadata', 'companyNames', id]) || this.getUserName(meeting.getIn(['attendees', id]))
		}

		return (
			<ListPanel
				title={name}
				items={attendees.toList()}
				columnTid='meetings.details.attendees.panel.responded'
				renderItemComponent={this.renderCompanyMemberItem}
				panelProps={{ marginBottom: true }}
			/>
		)
	}

	renderAttendeeFunctions = () => {
		const { canChangeSecretary, isSimpleMode, meeting } = this.props
		const { attendee } = this.state

		const isSecretaryPostMeeting =
			meeting && attendee
				? meeting.get('secretary') === attendee.get('userId') && meeting.getIn(['computedValues', 'meetingIsFinished'])
				: true
		const canChange = !isSecretaryPostMeeting

		return (
			<AttendeeFunctionsContainer
				attendeeId={attendee && attendee.get('userId')}
				isDisabled={(isSimpleMode && !canChangeSecretary) || !canChange}
				isSimpleModeAndCanNotChangeSecretary={isSimpleMode && !canChangeSecretary}
				isSecretaryPostMeeting={!canChange}
			/>
		)
	}

	render() {
		const { attendee } = this.state
		let name = ''
		let isActiveInvonoCompany = false
		let isProxyForCompany = null
		if (attendee) {
			name = this.getUserName(attendee)
			isActiveInvonoCompany = attendee.get('isActiveInvonoCompany', false)
			isProxyForCompany = attendee.get('isProxyForCompany', false)
		}

		return (
			<Box direction='column'>
				<AttendeeDetailsPanel
					name={name}
					attendee={attendee} // Force component rerender
					renderAttendeeFunctions={this.renderAttendeeFunctions}
					onManageFunctions={this.openHandleFunctionsModal}
				/>

				{(isActiveInvonoCompany || isProxyForCompany) && this.renderCompanyMembersPanel()}
				<ManageAttendeesFunctionModalContainer name={name} />
			</Box>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		usersCache: store.usersCache.get('usersCache'),
		meeting: store.meetings.get('meeting'),
		investors: store.investors.get('investorsCache'),
		isSimpleMode: store.meetings.getIn(['meeting', 'computedValues', 'isSimpleMode']),
		canChangeSecretary: store.meetings.getIn(['meeting', 'computedValues', 'canChangeSecretary']),
		userId: store.user.getIn(['userObj', 'id']),
		audit: store.audit.get('meetings')
	}
}

const mapActionsToProps = {
	fetchAttendee,
	openModal,
	fetchAndCacheInvestors
}

export default connect(mapStoreToProps, mapActionsToProps)(AttendeesDetailsContainer)
