import React, { PureComponent } from 'react'
import { func, bool } from 'prop-types'
import styled, { css } from 'styled-components'

import Text from '../../shared/text/text'
import Dot from '../../shared/dot/dot'

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	height: 40px;
	transition: background 0.4s ease;
	padding: 0 ${(props) => props.theme.spacing[4]};
	cursor: pointer;
	user-select: none;

	:hover {
		background: #f2f2f2;
	}
`

const StyledDot = styled(Dot)`
	margin-left: ${(props) => props.theme.spacing[4]};
	margin-top: 1px;

	/* Match color of Headway */
	${({ hasDocumentsInRoot }) =>
		!hasDocumentsInRoot &&
		css`
			background-color: #d4d4d4;
		`}
`

export default class Collections extends PureComponent {
	static propTypes = {
		onClick: func,
		hasDocumentsInRoot: bool
	}

	render = () => {
		const { hasDocumentsInRoot, onClick } = this.props
		const bgColor = hasDocumentsInRoot ? 'green' : undefined

		return (
			<Wrapper onClick={onClick}>
				<Text tid='navbar.collections' />
				<StyledDot hasDocumentsInRoot={hasDocumentsInRoot} bgColor={bgColor} />
			</Wrapper>
		)
	}
}
