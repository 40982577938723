import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, object, func, bool } from 'prop-types'
import TransparentInput from '../../../dumb-components/shared/input/transparent-input'

class MeetingEditorContainer extends Component {
	static propTypes = {
		fieldName: string,
		value: string,
		placeholderTid: string,
		onChange: func,
		onFocus: func,
		onBlur: func,
		onArrowUp: func,
		onArrowDown: func,
		onArrowRight: func,
		onArrowLeft: func,
		onEnter: func,
		onEsc: func,
		onTab: func,
		autofocus: bool,
		readOnly: bool
	}

	render = () => {
		const {
			fieldName,
			value,
			placeholderTid,
			onEnter,
			onChange,
			onFocus,
			onBlur,
			onArrowUp,
			onArrowDown,
			onArrowRight,
			onArrowLeft,
			onEsc,
			onTab,
			i18n,
			autofocus,
			readOnly
		} = this.props

		return (
			<TransparentInput
				fieldName={fieldName}
				value={value || ''}
				onChange={onChange}
				placeholderTid={placeholderTid}
				onFocus={onFocus}
				onBlur={onBlur}
				onArrowUp={onArrowUp}
				onArrowDown={onArrowDown}
				onArrowRight={onArrowRight}
				onArrowLeft={onArrowLeft}
				onEnter={onEnter}
				onEsc={onEsc}
				onTab={onTab}
				i18n={i18n}
				autofocus={autofocus}
				readOnly={readOnly}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		i18n: store.i18n
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingEditorContainer)
