import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromJS, List } from 'immutable'
import Accordion from '../../framework/accordion'
import AccordionSection from '../../framework/accordion/accordion-section'
import ScrollView from '../../../dumb-components/shared/layout/scroll-view/scroll-view'
import InformationBlock from '../../framework/information-block'
import Wiki from '../../wiki/wiki'

import EmissionView from './transactions/emission--view'
import RevertToLastTransaction from './transactions/revert-to-last-transaction'
import TransferOfSharesView from './transactions/transfer-of-shares--view'
import SplitView from './transactions/split--view'
import BonusIssueView from './transactions/bonus-issue--view'
import ChangeOfReservationView from './transactions/change-of-reservation--view'
import AdjustmentOfVotesView from './transactions/adjustment-of-votes--view'
import ConversionOfSharesView from './transactions/conversion-of-shares--view'
import ReductionOfShareCapitalView from './transactions/reduction-of-share-capital--view'
import SharesPledgeView from './transactions/shares-pledge--view'
import IssueShareCertificatesView from './transactions/issue-share-certificates--view'

import ShareTypeFormModal from './transactions/share-type--form-modal'
import { SHARES_SHARE_TYPE_MODAL } from '../../../constants/modals'
import { closeModal } from '../../../actions/modals.actions'

import { Switch, Route } from '../../../interfaces/router'
import EmissionForm from './transactions/emission--form'
import TransferOfSharesForm from './transactions/transfer-of-shares--form'
import SplitForm from './transactions/split--form'
import BonusIssueForm from './transactions/bonus-issue--form'
import ChangeOfReservationForm from './transactions/change-of-reservation--form'
import AdjustmentOfVotesForm from './transactions/adjustment-of-votes--form'
import ConversionOfSharesForm from './transactions/conversion-of-shares--form'
import ReductionOfShareCapitalForm from './transactions/reduction-of-share-capital--form'
import SharesPledgeForm from './transactions/shares-pledge--form'
import IssueShareCertificatesForm from './transactions/issue-share-certificates--form'
import history from '../../../interfaces/history'
import { fetchLatestTransaction } from '../../../actions/transaction.actions'
import { listInvestors } from '../../../actions/investors.actions'

import BreadcrumbsContainer from '../../../containers/transactions/breadcrumbs.container'
const accordionSections = fromJS([
	{
		title: 'split_reverse_split',
		resource: 'c69df621-157c-4bc1-97c0-98e253a19a8b',
		permissions: ['TRANSACTIONS--READ'],
		id: 'split',
		component: SplitView
	},
	{
		title: 'new_emissions',
		resource: 'c69df621-157c-4bc1-97c0-98e253a19a8b',
		permissions: ['TRANSACTIONS--READ'],
		id: 'emission',
		component: EmissionView
	},
	{
		title: 'bonus_issues',
		resource: 'c69df621-157c-4bc1-97c0-98e253a19a8b',
		permissions: ['TRANSACTIONS--READ'],
		id: 'bonus-issue',
		component: BonusIssueView
	},
	{
		title: 'service.shares.transfer_of_shares_transaction',
		resource: 'c69df621-157c-4bc1-97c0-98e253a19a8b',
		permissions: ['TRANSACTIONS--READ'],
		id: 'transfer-of-shares',
		component: TransferOfSharesView
	},
	{
		title: 'conversion_of_shares',
		resource: 'c69df621-157c-4bc1-97c0-98e253a19a8b',
		permissions: ['TRANSACTIONS--READ'],
		id: 'conversion-of-shares',
		component: ConversionOfSharesView
	},
	{
		title: 'adjustment_of_votes',
		resource: 'c69df621-157c-4bc1-97c0-98e253a19a8b',
		permissions: ['TRANSACTIONS--READ'],
		id: 'adjustment-of-votes',
		component: AdjustmentOfVotesView
	},
	{
		title: 'reduction_of_share_capital',
		resource: 'c69df621-157c-4bc1-97c0-98e253a19a8b',
		permissions: ['TRANSACTIONS--READ'],
		id: 'reduction-of-share-capital',
		component: ReductionOfShareCapitalView
	},
	{
		title: 'change_of_reservation',
		resource: 'c69df621-157c-4bc1-97c0-98e253a19a8b',
		permissions: ['TRANSACTIONS--READ'],
		id: 'change-of-reservation',
		component: ChangeOfReservationView
	},
	{
		title: 'shares_pledge',
		resource: 'c69df621-157c-4bc1-97c0-98e253a19a8b',
		permissions: ['TRANSACTIONS--READ'],
		id: 'shares-pledge',
		component: SharesPledgeView
	},
	{
		title: 'issue_share_certificates',
		resource: 'c69df621-157c-4bc1-97c0-98e253a19a8b',
		permissions: ['TRANSACTIONS--READ'],
		id: 'issue-share-certificates',
		component: IssueShareCertificatesView
	}
])

class Transactions extends Component {
	state = {
		accordionSections: List(),
		sectionsSorted: false,
		selectedSection: null,
		selectedSectionId: null
	}

	componentDidMount() {
		const { fetchLatestTransaction, listInvestors } = this.props
		fetchLatestTransaction()
		listInvestors(false)
		this.sortSections(accordionSections)
		this.setSelectedSection(this.props.location.pathname)
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (prevState.accordionSections.size === 0 && this.state.accordionSections.size > 0) {
			this.setSelectedSection(this.props.location.pathname)
		}
	}

	setSelectedSection(pathname) {
		const { accordionSections } = this.state
		const sections = pathname.split('transactions')
		const section = sections.length === 2 ? sections[1].substr(1) : null
		const selectedSection = accordionSections.findIndex((obj) => {
			return obj.get('id') === section
		})
		this.setState({ selectedSection, selectedSectionId: section })
	}

	toggleAccordionSection(section, scrollTo) {
		const { selectedSectionId } = this.state
		const sectionToGoTo = selectedSectionId === section ? '' : section

		history.push(this.props.location.pathname.split('transactions')[0] + `transactions/${sectionToGoTo}`)
		setTimeout(() => {
			this.scrollbars.scrollTop(scrollTo)
		}, 500)
	}

	sortSections = (sections) => {
		const { i18n } = this.props
		const { sectionsSorted } = this.state

		if (sectionsSorted) {
			return
		}

		sections = sections.sort((a, b) => {
			return i18n.messages[a.get('title')].localeCompare(i18n.messages[b.get('title')])
		})

		this.setState({ accordionSections: sections, sectionsSorted: true })
	}

	renderWiki = () => {
		return (
			<div className='i-content__container'>
				<ScrollView showOnHover={true} autoHide={true}>
					<InformationBlock>
						<Wiki id='4ea06d84-fc85-4e3b-a3aa-8a34cb660931' />
					</InformationBlock>
				</ScrollView>
			</div>
		)
	}

	renderAccordionSection = (section, index) => {
		const ViewComponent = section.component

		return (
			<AccordionSection
				title={section.title}
				resource={section.resource}
				permissions={section.permissions}
				onToggle={this.toggleAccordionSection.bind(this, section.id, index * 50)}
				id={`${section.id}-view`}
				key={index}>
				<ViewComponent />
			</AccordionSection>
		)
	}

	render() {
		const { activeModal, match } = this.props
		const { params } = match
		const { selectedSection } = this.state
		const accordionSections = this.state.accordionSections.toJS()

		if (accordionSections.length === 0) {
			return null
		}

		return (
			<div className='i-content i-content--pad-right-half'>
				<BreadcrumbsContainer sections={accordionSections} selectedSection={selectedSection} params={params} />

				<div className='i-content__body'>
					<div className='i-content__container'>
						<div className='i-content__pre-scrollbar'>
							<div className='i-content__container i-content__container--justify-center'>
								<RevertToLastTransaction />
							</div>
						</div>
						<div className='f1-box'>
							<ScrollView
								scrollbarRef={(r) => (this.scrollbars = r)}
								noLeftMargin={true}
								showOnHover={true}
								autoHide={true}>
								<Accordion selectedSection={selectedSection}>
									{accordionSections.map(this.renderAccordionSection)}
								</Accordion>
							</ScrollView>
						</div>
					</div>

					<div className='i-content__container'>
						<Switch>
							<Route exact path={`${match.path}/emission`} component={EmissionForm} />
							<Route exact path={`${match.path}/transfer-of-shares`} component={TransferOfSharesForm} />
							<Route exact path={`${match.path}/split`} component={SplitForm} />
							<Route exact path={`${match.path}/bonus-issue`} component={BonusIssueForm} />
							<Route exact path={`${match.path}/change-of-reservation`} component={ChangeOfReservationForm} />
							<Route exact path={`${match.path}/adjustment-of-votes`} component={AdjustmentOfVotesForm} />
							<Route exact path={`${match.path}/conversion-of-shares`} component={ConversionOfSharesForm} />
							<Route exact path={`${match.path}/reduction-of-share-capital`} component={ReductionOfShareCapitalForm} />
							<Route exact path={`${match.path}/shares-pledge`} component={SharesPledgeForm} />
							<Route exact path={`${match.path}/issue-share-certificates`} component={IssueShareCertificatesForm} />
							<Route exact path={`${match.path}/`} render={this.renderWiki} />
						</Switch>
					</div>
				</div>

				<ShareTypeFormModal isOpen={activeModal === SHARES_SHARE_TYPE_MODAL} />
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		i18n: state.i18n,
		activeModal: state.modals.getIn(['activeModal', 'name'])
	}
}

const mapActionsToProps = {
	fetchLatestTransaction,
	listInvestors,
	closeModal
}

export default connect(mapStateToProps, mapActionsToProps)(Transactions)
