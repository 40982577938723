import React from 'react'
import ProfileImageCrop from '../image-crop'
import Panel from '../../../../dumb-components/panel'


export default function ProfileImageField({ user, saveProfileImage, deleteProfileImage, removeUserFromCache }) {
	const userImagePreview = user.get('image') ? '/api/users/public/images/' + user.getIn(['image', 'filename']) : null
	const crop = user.getIn(['image', 'crop'], null)

	const onSubmitProfileImage = (obj) => {
		const id = user.get('id')
		saveProfileImage(id, obj)
		removeUserFromCache(id)
	}

	const onDeleteProfileImage = () => {
		const id = user.get('id')
		deleteProfileImage(id)
		removeUserFromCache(id)
	}

	return (
		<Panel tid='user.profile.profile_image' padHor={true}>
			<ProfileImageCrop
				currentImageUrl={userImagePreview}
				crop={crop && crop.toJS()}
				onChange={onSubmitProfileImage}
				onDelete={onDeleteProfileImage}
				aspectRatio={1}
				userId={user.get('id')}
				imageSuffix='-250x250'
				uploadDesc='upload.profile.image.description'
				spinnerWrapperClass='imagecrop__spinner--h-180'
			/>
		</Panel>
	)
}