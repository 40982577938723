import React, { PureComponent } from 'react'
import immutablePropTypes from 'react-immutable-proptypes'
import { string, object, oneOfType, bool, func, array, number, oneOf } from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import ListItem from '../../shared/list-item/list-item'
import Text from '../../shared/text/text'
import Icon from '../../shared/icon/icon'
import ProfileImage from '../../shared/profile-image/profile-image'

const StyledButton = styled.div`
	background-color: ${(props) => {
		if (props.mode === 'lighter') {
			return props.theme.colors.white
		} else if (props.mode === 'darker') {
			return props.theme.colors.solitudeLight
		} else {
			return 'transparent'
		}
	}};
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	height: 50px;
	border-top: 1px solid
		${(props) => {
			if (props.mode === 'lighter') {
				return rgba(props.theme.colors.solitudeDark, 0.7)
			} else if (props.mode === 'darker') {
				return props.theme.colors.solitudeDark
			} else {
				return rgba(props.theme.colors.solitudeDark, 0.7)
			}
		}};
	border-bottom: 1px solid
		${(props) => {
			if (props.mode === 'lighter') {
				return rgba(props.theme.colors.solitudeDark, 0.7)
			} else if (props.mode === 'darker') {
				return props.theme.colors.solitudeDark
			} else {
				return rgba(props.theme.colors.solitudeDark, 0.7)
			}
		}};
	${(props) =>
		props.onClick &&
		css`
			cursor: pointer;
		`}
`

const StyledDragHandler = styled.div`
	width: 16px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

const StyledUserImageWrapper = styled.div`
	width: 50px;
	height: 100%;
	opacity: 0.6;
`

const StyledStatus = styled.div`
	width: 50px;
	height: 100%;
	background-color: ${(props) =>
		props.mode === 'darker' ? props.theme.colors.solitudeDark : props.theme.colors.solitudeMid};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	opacity: 0.6;
`

const StyledTitle = styled.div`
	flex: 1;
	padding: ${(props) => props.theme.spacing[3]} ${(props) => props.theme.spacing[4]};
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;

	${(props) =>
		props.type === 'template' &&
		css`
			padding-left: 66px;
		`};
`

export default class NewListItemButton extends PureComponent {
	static propTypes = {
		mode: oneOf(['normal', 'darker', 'lighter']),
		renderInputComponent: func,
		onClick: func,
		tid: string,
		showStatusIndicator: bool,
		reverse: bool
	}

	static defaultProps = {
		showStatusIndicator: true
	}

	render = () => {
		const { onClick, tid, mode, renderInputComponent, type, showStatusIndicator, reverse } = this.props

		if (reverse) {
			return (
				<StyledButton onClick={onClick} mode={mode}>
					<StyledDragHandler />

					{type !== 'template' && (
						<StyledUserImageWrapper>
							<ProfileImage mode={mode} />
						</StyledUserImageWrapper>
					)}

					{type !== 'template' && showStatusIndicator && (
						<StyledStatus mode={mode}>
							<Icon icon='faTimes' color={mode === 'darker' ? 'lightGrey' : 'white'} type='solid' />
						</StyledStatus>
					)}

					<StyledTitle type={type}>
						{!renderInputComponent && <Text color={mode === 'darker' ? 'lightGrey' : 'muted'} tid={tid} />}
						{renderInputComponent && renderInputComponent()}
					</StyledTitle>
				</StyledButton>
			)
		}

		return (
			<StyledButton onClick={onClick} mode={mode}>
				<StyledDragHandler />

				{type !== 'template' && showStatusIndicator && (
					<StyledStatus mode={mode}>
						<Icon icon='faTimes' color={mode === 'darker' ? 'lightGrey' : 'white'} type='solid' />
					</StyledStatus>
				)}

				{type !== 'template' && (
					<StyledUserImageWrapper>
						<ProfileImage mode={mode} />
					</StyledUserImageWrapper>
				)}

				<StyledTitle type={type}>
					{!renderInputComponent && <Text color={mode === 'darker' ? 'lightGrey' : 'muted'} tid={tid} />}
					{renderInputComponent && renderInputComponent()}
				</StyledTitle>
			</StyledButton>
		)
	}
}
