import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map, Set } from 'immutable'
import { bool, func } from 'prop-types'
import uuid from 'uuid'
import { FILTER_FIELDS } from '../../../constants/meetings'
import moment from '../../../modules/moment.module'
import {
	setFilterBy,
	unsetFilterBy,
	updateDefaultFilter,
	resetDefaultFiltervalues
} from '../../../actions/meetings.actions'
import { fetchSimpleUsers } from '../../../actions/usersCache.actions'
import { listMeetingTemplates } from '../../../actions/meeting-templates.actions'
import { updateUser, saveUser } from '../../../actions/user.actions'
import { listInvestorsUnsafe } from '../../../actions/investors.actions'
import ObjectFilterContainer from '../../shared/object-filter.container'
import CompanyMembersSelectContainer from '../../company/company-members-select.container'
import { FACTORY_DEFAULT_FILTER_VALUES } from '../../../constants/meetings'

class AvancedFilterContainer extends Component {
	static propTypes = {
		isOpen: bool,
		onClose: func
	}

	state = {
		fields: null,
		attendeesInitialised: false
	}

	componentDidMount = () => {
		const { listMeetingTemplates, meetingTemplates, selectedGroupId, listInvestorsUnsafe } = this.props

		this.parseFields(FILTER_FIELDS, null)
		this.fetchUsers()

		if (!meetingTemplates) {
			listMeetingTemplates(selectedGroupId)
		}

		listInvestorsUnsafe()
	}

	componentDidUpdate = (prevProps) => {
		const { listMeetingTemplates, meetingTemplates, selectedGroupId, filterBy } = this.props

		if (this.props.meetings !== prevProps.meetings) {
			this.fetchUsers()
			this.parseFields(this.state.fields, this.props.meetings)
		}

		if (prevProps.filterBy !== filterBy) {
			this.parseFields(this.state.fields, this.props.tasks)
		}

		if (prevProps.usersCache !== this.props.usersCache || prevProps.investors !== this.props.investors) {
			this.parseAttendees()
		}

		if (prevProps.selectedGroupId !== selectedGroupId) {
			listMeetingTemplates(selectedGroupId)
		}

		if (prevProps.meetingTemplates !== meetingTemplates) {
			this.parseFields(this.state.fields, this.props.meetings)
		}

		if (!this.state.attendeesInitialised) {
			this.parseAttendees()
		}
	}

	getRowIndex = (fields, fieldName) => {
		return fields.findIndex((row) => {
			let found = false

			row.forEach((obj) => {
				if (obj.get('fieldName') === fieldName) {
					found = true
				}
			})

			return found
		})
	}

	getFieldIndex = (fields, rowIndex, fieldName) => {
		return fields.get(rowIndex).findIndex((obj) => obj.get('fieldName') === fieldName)
	}

	fetchUsers = () => {
		const { meetings, fetchSimpleUsers } = this.props
		let userIds = List()

		meetings &&
			meetings.forEach((meeting) => {
				userIds = userIds.concat(Set.fromKeys(meeting.get('attendees', Map())).toList())
			})

		fetchSimpleUsers(userIds)
	}

	parseFields = (fields, meetingsList) => {
		const { meetingTemplates, i18n } = this.props
		const templateIdRowIndex = this.getRowIndex(fields, 'templateId')
		const templateIdFieldIndex = this.getFieldIndex(fields, templateIdRowIndex, 'templateId')
		let meetingTemplatesOptions = meetingTemplates.map((obj) =>
			Map({ value: obj.get('id'), label: obj.get('name', '') })
		)

		meetingTemplatesOptions = meetingTemplatesOptions.sort((a, b) => a.get('label', '').localeCompare(b.get('label')))
		fields = fields.setIn([templateIdRowIndex, templateIdFieldIndex, 'options'], meetingTemplatesOptions)

		if (meetingsList && meetingsList.size > 0) {
			const dateRowIndex = this.getRowIndex(fields, 'date')
			const dateFieldIndex = this.getFieldIndex(fields, dateRowIndex, 'date')
			let minDate
			let maxDate

			meetingsList.forEach((meeting) => {
				const startDate = meeting.get('startDate')

				if ((!minDate && startDate) || (minDate && startDate && moment(startDate).isBefore(minDate))) {
					minDate = startDate
				}

				if ((!maxDate && startDate) || (maxDate && startDate && moment(startDate).isAfter(maxDate))) {
					maxDate = startDate
				}
			})

			fields = fields.setIn([dateRowIndex, dateFieldIndex, 'minDate'], minDate)
			fields = fields.setIn([dateRowIndex, dateFieldIndex, 'maxDate'], maxDate)
		}

		// Created By field
		const createdByRowIndex = this.getRowIndex(fields, 'createdBy')
		const createdByFieldIndex = this.getFieldIndex(fields, createdByRowIndex, 'createdBy')
		let createdByField = fields.getIn([createdByRowIndex, createdByFieldIndex])

		createdByField = createdByField.set('renderFn', this.renderCompanyMembersSelect)
		fields = fields.setIn([createdByRowIndex, createdByFieldIndex], createdByField)

		this.setState({ fields })
	}

	parseAttendees = () => {
		const { meetings, usersCache, investors, i18n } = this.props
		let { fields } = this.state
		let attendeesOptions = Map()

		meetings.forEach((meeting) => {
			meeting.get('attendees', Map()).forEach((attendee, userId) => {
				let name = ''
				if (attendee.get('isGuest')) {
					name = attendee.get('name')
				} else if (usersCache && usersCache.has(userId)) {
					name = usersCache.getIn([userId, 'name'])
				} else if (investors && attendee.get('isInvestor') === true) {
					const investor = investors.find((obj) => obj.get('id') === attendee.get('investmentId'))

					if (investor) {
						name = investor.getIn(['investorInformation', 'name'])
					}
				} else {
					return
				}

				if (name !== '') {
					attendeesOptions = attendeesOptions.set(
						userId,
						Map({
							value: userId,
							label: name || ''
						})
					)
				}
			})
		})

		attendeesOptions = attendeesOptions.toList().sort((a, b) => a.get('label', '').localeCompare(b.get('label')))
		const prependOptions = Map({
			value: 'show_all',
			label: i18n.messages['documents.filter.permissions.show_all']
		})
		attendeesOptions = attendeesOptions.insert(0, prependOptions)
		const attendeesRowIndex = this.getRowIndex(fields, 'attendees')
		const attendeesFieldIndex = this.getFieldIndex(fields, attendeesRowIndex, 'attendees')
		fields = fields.setIn([attendeesRowIndex, attendeesFieldIndex, 'options'], attendeesOptions)

		this.setState({ fields, attendeesInitialised: true })
	}

	onChange = (fieldName, val) => {
		const { setFilterBy } = this.props
		let valueIsEmpty = !val && fieldName !== 'includeArchived' && fieldName !== 'showOnlyTrashed'

		if (fieldName === 'date') {
			val = val.filter((value) => {
				return value !== null
			})

			valueIsEmpty = val.size === 0
		}

		setFilterBy(
			Map({
				source: fieldName,
				values: !valueIsEmpty ? List([val]) : null
			})
		)
	}

	onClose = () => {
		const { onClose } = this.props

		onClose && onClose()
	}

	onClearAll = () => {
		const { unsetFilterBy } = this.props
		const { fields } = this.state
		let sources = List()

		fields.forEach((row) => {
			row.forEach((field) => {
				sources = sources.push(field.get('fieldName'))
			})
		})

		unsetFilterBy(sources)
	}

	onSetDefaultValue = (fieldName, val) => {
		const { updateUser, saveUser, updateDefaultFilter, company } = this.props
		let { userObj } = this.props
		const id = `${company.id}-meetings$${fieldName}`

		userObj = userObj.setIn(['siteSettings', 'pinedFilters', id], val)
		updateUser(userObj)
		saveUser(userObj.get('id'), userObj.toJS())
		updateDefaultFilter(fieldName, List([val]))
	}

	resetDefaultValues = () => {
		const { updateUser, saveUser, resetDefaultFiltervalues, company } = this.props
		let { userObj } = this.props

		FACTORY_DEFAULT_FILTER_VALUES.forEach((val, key) => {
			const id = `${company.id}-meetings$${key}`

			if (userObj.hasIn(['siteSettings', 'pinedFilters', id])) {
				userObj = userObj.setIn(['siteSettings', 'pinedFilters', id], val.get(0))
			}
		})

		updateUser(userObj)
		saveUser(userObj.get('id'), userObj.toJS())
		resetDefaultFiltervalues()
	}

	renderCompanyMembersSelect = (fieldName, pinable, placeholder, value, defaultValue, onChange, field) => {
		return (
			<CompanyMembersSelectContainer
				fieldName={fieldName}
				onChange={onChange}
				value={value}
				placeholderTid={placeholder}
				isClearable={field.get('isClearable')}
				includeNoneOption={field.get('includeNoneOption')}
				noneOptionValue={field.get('noneOptionValue')}
				noneOptionLabelTid={field.get('noneOptionLabelTid')}
			/>
		)
	}

	render = () => {
		const { isOpen, company, filterBy, defaultFilters } = this.props
		const { fields } = this.state
		const id = `${company.id}-meetings`

		return (
			<ObjectFilterContainer
				key={uuid()}
				id={id}
				fields={fields}
				values={filterBy}
				defaultValues={defaultFilters}
				title='meetings.advanced_filter'
				isOpen={isOpen}
				onChange={this.onChange}
				onClose={this.onClose}
				onClearAll={this.onClearAll}
				onSetDefaultValue={this.onSetDefaultValue}
				onResetDefaultValues={this.resetDefaultValues}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meetings: store.meetings.get('allMeetings'),
		filterBy: store.meetings.get('filterBy'),
		defaultFilters: store.meetings.get('defaultFilters'),
		company: store.company.company,
		companyMembers: store.company.members,
		i18n: store.i18n,
		usersCache: store.usersCache.get('usersCache'),
		investors: store.investors.get('list'),
		meetingTemplates: store.meetingTemplates.get('list'),
		selectedGroupId: store.groups.get('selectedGroupId'),
		userObj: store.user.get('userObj')
	}
}

const mapActionsToProps = {
	setFilterBy,
	unsetFilterBy,
	fetchSimpleUsers,
	listMeetingTemplates,
	updateUser,
	saveUser,
	updateDefaultFilter,
	resetDefaultFiltervalues,
	listInvestorsUnsafe
}

export default connect(mapStoreToProps, mapActionsToProps)(AvancedFilterContainer)
