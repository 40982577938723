import React, { useRef, useState, useContext, useEffect } from 'react';
import localeFormatNumber from '/shared/helpers/number-formatter.helper';
import {
	NUMBER_FORMAT_PERCENTAGE,
	INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE,
	INVESTOR_TYPE_OF_OWNER_PRIVATE,
	INVESTOR_TYPE_OF_OWNER_COMPANY
} from '/shared/constants';
import AgGridChart from '../../../dumb-components/shared/ag-grid/chart/ag-grid-chart';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import GridPanelRightButtons from '../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons';
import { CHART_MODAL } from '../../../constants/modals';
import { openModal, closeModal } from '../../../actions/modals.actions';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../../dumb-components/shared/text/text';
import { useTranslation } from 'react-i18next';
import { InvestmentsContext } from '../investment.container';
import ChartModal from '../shared/chartmodal/chartmodal';
import {
	byWhatNumberShouldNavigatorShow,
	showHowManyHundreds,
	exportAsPNG,
	removeIndexFromString,
	limitNameLength
} from '../../../components/helpers/ag-chart.helper';
import { MUItheme } from '../../../../MUItheme';

export default function ShareholderChart() {
	const chartRefPanel = useRef(null);
	const chartRefModal = useRef(null);
	const { selectedInvestment } = useContext(InvestmentsContext);
	const [chartData, setChartData] = useState();

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const investments = selectedInvestment?.get('investments');
	const chartOption = 'shareholderChart';

	const isChartModalOpen = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'options']) === chartOption;
	});

	useEffect(() => {
		if (investments) {
			setChartData(generateDataForshareholderChart());
		}
	}, [investments]);

	const series = [
		{
			type: 'column',
			xKey: 'name',
			yKey: 'previousValue',
			showInLegend: false,
			yName: undefined,
			stacked: true,
			strokeWidth: 0,
			formatter: (params) => {
				//	removes the highlightcolor of the bar
				return {
					fill:
						params.yKey === 'previousValue'
							? params.highlighted
								? 'rgb(0,0,0,0)'
								: 'rgb(0,0,0,0)'
							: params.fill
				};
			}
		},
		{
			type: 'column',
			xKey: 'name',
			yKey: 'realValue',
			yName: undefined,
			stacked: true,
			tooltip: {
				renderer: (params) => {
					if (params.yKey === 'previousValue') {
						//	needed to remove the tooltip caret wich was on the parent node
						return `
						<style>
						.ag-chart-tooltip::before {
							opacity: 0;
						}
						.ag-chart-tooltip::after {
							opacity: 0;
						}
						</style>
						`;
					} else {
						const dataObject = chartData.find((dataObject) => {
							return dataObject.name === params.xValue;
						});
						const identificationString = dataObject.id ? `${dataObject.idType}: ${dataObject.id}` : '';

						return `<div class="ag-chart-tooltip-title" style="background-color: ${
							params.color
						}">${`${dataObject.investmentName} ${identificationString}`}</div> <div class="ag-chart-tooltip-content">${localeFormatNumber(
							params.yValue,
							NUMBER_FORMAT_PERCENTAGE
						)}</div>`;
					}
				}
			}
		}
	];

	const axes = [
		{
			type: 'category',
			position: 'bottom',
			label: {
				formatter: (params) => {
					if (!isChartModalOpen) {
						return removeIndexFromString(limitNameLength(params.value, 18));
					} else {
						return removeIndexFromString(params.value);
					}
				}
			}
		},
		{
			min: 0,
			max: 100,
			nice: true,
			tick: {
				count: 10
				// color: 'red',
				// size: 20,
				// width: 10
			},
			type: 'number',
			position: 'left',
			keys: 'realValue'
		}
	];

	const themeOverrides = {
		overrides: {
			column: {
				series: {
					strokeWidth: 0,
					highlightStyle: {
						item: {
							fill: MUItheme.palette.chart.complementary,
							strokeWidth: 0
						}
					}
				}
			}
		}
	};

	function formatValue(params) {
		//	legend
		return `${params.value} %`;
	}

	const generateDataForshareholderChart = () => {
		let previousValue = 0;
		let realValue = 0;
		const chartData = [];
		let name;

		const filtered =
			investments &&
			investments.sort(
				(a, b) => b.getIn(['balance', 'ownershipPercentage']) - a.getIn(['balance', 'ownershipPercentage'])
			);

		filtered &&
			filtered.map((investment, i) => {
				const investorTypeOfOwner = investment.get('investorTypeOfOwner');
				let idType;
				switch (investorTypeOfOwner) {
					case INVESTOR_TYPE_OF_OWNER_PRIVATE:
						idType = t('cap_table.ownershi_distrubution_chart.id.private');
						break;
					case INVESTOR_TYPE_OF_OWNER_COMPANY:
						idType = t('cap_table.ownershi_distrubution_chart.id.company');
						break;
					case INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE:
						{
							idType = t('cap_table.ownershi_distrubution_chart.id.capital_insurance');
						}
						break;
					default:
				}

				name = investment.get('name');
				previousValue = realValue;
				realValue += investment.getIn(['balance', 'ownershipPercentage']);
				chartData.push({
					name: `${i + 1} ${name}`,
					previousValue,
					investmentName: investment.get('name'),
					investorTypeOfOwner: investment.get('investorTypeOfOwner'),
					idType,
					id: investment.get('id'),
					realValue: realValue - previousValue
				});
			});

		//Group capital insurances by name and add #1 #2 etc infront if there's duplicates
		const insurances = chartData.filter((investment) => {
			return investment.investorTypeOfOwner === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE;
		});
		const grouping = insurances.reduce((accumulator, investment) => {
			const { investmentName } = investment;
			if (!accumulator[investmentName]) {
				accumulator[investmentName] = [];
			}
			accumulator[investmentName].push(investment);
			return accumulator;
		}, {});

		for (const group of Object.values(grouping)) {
			if (group.length > 1) {
				group.forEach((investment, i) => {
					const { name } = investment;
					const parts = name.split(' ');
					parts[1] = `#${i + 1} ${parts[1]}`;
					investment.name = parts.join(' ');
				});
			}
		}
		return chartData;
	};

	const openChartModal = () => {
		dispatch(openModal(CHART_MODAL, chartOption));
	};

	const onCloseChartModal = () => {
		dispatch(closeModal());
	};

	const renderAgChart = () => {
		return (
			<AgGridChart
				chartRef={isChartModalOpen ? chartRefModal : chartRefPanel}
				series={series}
				axes={axes}
				data={chartData}
				showNavigator={byWhatNumberShouldNavigatorShow(chartData, 100)}
				howManyToShow={showHowManyHundreds(chartData, 1)}
				singleFillColor
				themeOverrides={themeOverrides}
				labelFormatter={formatValue}
			/>
		);
	};

	if (!chartData) {
		return null;
	}

	return (
		<>
			{isChartModalOpen && (
				<ChartModal
					leftHeader={'investments.sharebook.shareholders_chart.title'}
					onCancel={onCloseChartModal}
					onExport={() => {
						exportAsPNG(chartRefModal, t('investments.sharebook.shareholders_chart.title'));
					}}
					isOpen={isChartModalOpen}>
					{renderAgChart()}
				</ChartModal>
			)}
			<Card>
				<CardHeader
					title={<Text tid={'investments.sharebook.shareholders_chart.title'} />}
					action={
						<GridPanelRightButtons
							chart
							noFilter={true}
							exportAsExcel={() => {
								exportAsPNG(chartRefPanel, t('investments.sharebook.shareholders_chart.title'));
							}}
							downloadExcelTid={
								'investments.shareholders.shareholders_chart.dropdown_item.download_chart'
							}
							openGridModal={openChartModal}
						/>
					}
				/>
				<CardContent variant='panel'>{renderAgChart()}</CardContent>
			</Card>
		</>
	);
}
