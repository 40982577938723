import React, { useContext, useEffect, useRef, useState } from 'react';
import AgGrid from '../../../dumb-components/shared/ag-grid/ag-grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { InvestmentsContext } from '../investment.container';
import { useTranslation } from 'react-i18next';
import {
	investorEmissionsColumnDefs,
	columnTypes
} from '../../../dumb-components/shared/ag-grid/column-defs/column-defs';
import Text from '../../../dumb-components/shared/text/text';
import emissions from '../../../services/emissions';
import moment from '../../../modules/moment.module';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const EmissionsListContainer = () => {
	const { investedInCompanyInformation, setEmissionTabIndex, setCurrentEmissionIds, investmentId } =
		useContext(InvestmentsContext);

	const { id: investedInCompanyId } = investedInCompanyInformation.toJS();
	const companyId = useSelector((state) => state.company.company?.id);

	const gridRef = useRef();
	const { t } = useTranslation();
	const [colDefs, setColDefs] = useState(investorEmissionsColumnDefs(t));
	const [rowData, setRowData] = useState();
	const [colTypes, setColTypes] = useState(columnTypes());

	async function getInvestorEmissions() {
		if (!investedInCompanyId) {
			return;
		}

		const investorEmissions = await emissions.getInvestorEmissions(investedInCompanyId, investmentId, companyId);

		const closedStatuses = ['cancelled', 'completed', 'registered'];

		setRowData(
			await Promise.all(
				investorEmissions.map(async (data) => {
					const emissionDateEnded = moment() > moment(data.subscriptionEndDate).add(1, 'days');
					const closedAndUserParticipated =
						(emissionDateEnded || closedStatuses.includes(data.status)) && data.userParticipation;
					const closedAndUserNotParticipated =
						(emissionDateEnded || closedStatuses.includes(data.status)) && !data.userParticipation;
					const openAndUserParticipated = !closedStatuses.includes(data.status) && data.userParticipation;
					const openAndUserNotParticipated = !closedStatuses.includes(data.status) && !data.userParticipation;

					let actionLabel = '';
					let status = '';
					if (closedAndUserParticipated) {
						status = t('investments.emission.emission_grid.status.closed-and-participated');
						actionLabel = t('investments.emission.emission_grid.status.open');
					} else if (closedAndUserNotParticipated) {
						status = t('investments.emission.emission_grid.status.closed-and-not-participated');
					} else if (openAndUserParticipated) {
						status = t('investments.emission.emission_grid.status.open-and-participated');
						actionLabel = t('investments.emission.emission_grid.status.open');
					} else if (openAndUserNotParticipated) {
						status = t('investments.emission.emission_grid.status.open-and-not-participated');
						actionLabel = t('investments.emission.emission_grid.status.apply');
					}

					const statistics = await emissions.getEmissionStatistics(investedInCompanyId, data.id);

					return {
						date: moment(data.subscriptionStartDate).format('YYYY-MM-DD'),
						status,
						userCoverage: data.userCoverage,
						emissionMin: statistics.minAmountToAchieve,
						emissionMax: statistics.maxAmountToAchieve,
						newShareCountType: data.newShareCountType,
						action: {
							label: actionLabel,
							showDetails: () => {
								setEmissionTabIndex(1);
								setCurrentEmissionIds({
									inviteeId: data.inviteeId,
									emissionId: data.id
								});
							}
						}
					};
				})
			)
		);
	}

	useEffect(() => {
		const debounce = setTimeout(getInvestorEmissions, 0);
		return () => clearTimeout(debounce);
	}, [investedInCompanyId]);

	if (!rowData || rowData.length < 1) {
		return (
			<Card borderradius={'true'}>
				<CardContent>
					<Box>
						<Typography variant='body1'>
							<FormattedMessage id={'investments.share_issue.card.information.title'} />
						</Typography>
						<Typography variant='body2'>
							<FormattedMessage id={'investments.share_issue.card.information.body'} />
						</Typography>
					</Box>
				</CardContent>
			</Card>
		);
	}

	return (
		<Card>
			<CardHeader title={<Text tid={'investments.share_issue.grid.panel.header.title'} />} />
			<CardContent variant='panel'>
				<AgGrid
					rowData={rowData}
					columnDefs={colDefs}
					gridRef={gridRef}
					columnTypes={colTypes}
					suppressContextMenu
				/>
			</CardContent>
		</Card>
	);
};

export default EmissionsListContainer;
