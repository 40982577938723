import React from 'react'
import styled from 'styled-components'

const StyledBreadcrumbsWrapper = styled.div`
	display: block;
`

const Breadcrumbs = ({ children }) => {
	return <StyledBreadcrumbsWrapper>{children}</StyledBreadcrumbsWrapper>
}

export default Breadcrumbs
