import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Select from 'react-select'
import isEqual from 'lodash/isEqual'
import { fromJS } from 'immutable'
import differenceWith from 'lodash/differenceWith'
import { createUser } from '../../../actions/user-management.actions'
import req from '../../../modules/request.module'
import { Scrollbars } from 'react-custom-scrollbars'
import { validateEmail } from '../../../modules/validation.module'
import history from '../../../interfaces/history'
import { INVITE_USER_BY_EMAIL_TEMPLATE_MODAL, INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL } from '../../../constants/modals'
import { openModal } from '../../../actions/modals.actions'
import InviteUserModalContainer from '../../../containers/notify-by-templates/modals/users/invite-user-modal.container'
import InviteUsersModalContainer from '../../../containers/notify-by-templates/modals/users/invite-users-modal.container'

class CompanyMembers extends Component {
	state = {
		initialLoadDone: false,
		roles: null,
		users: [],
		error: false,
		userWantedToProceed: false
	}

	componentDidMount() {
		this.loadRoles()
		this.setUserLimit()
	}

	setUserLimit = () => {
		const { company } = this.props.company

		// -1 Because one row is reserved for the owner
		const limit = ((company && company.usersLimit) || 3) - 1

		const userTemplate = {
			name: '',
			email: '',
			roles: [],
			inviteSent: false,
			validName: false,
			validEmail: false,
			validRoles: true
		}

		const users = []

		for (let i = 0; i < limit; i++) {
			users.push(JSON.parse(JSON.stringify(userTemplate)))
		}

		this.setState({ users })
	}

	/**
	 * Fetch roles from the permission service
	 */
	loadRoles() {
		req.get('/permissions/acl/roles?options=true').then((response) => {
			if (response.data && response.data.options) {
				this.setState({ initialLoadDone: true, roles: response.data.options })
			}
		})
	}

	editName(index, value) {
		const { users } = this.state
		const name = value.target.value
		users[index].name = name
		users[index].validName = name && name.length > 0
		this.setState({ users, userWantedToProceed: false })
	}

	editEmail(index, value) {
		const { users } = this.state
		const email = value.target.value

		users[index].email = email
		users[index].validEmail = email && email.length > 0 && validateEmail(email)
		this.setState({ users, userWantedToProceed: false })
	}

	editRoles(index, value) {
		const { users } = this.state
		const values = users[index].roles
		const newValue = Array.isArray(value) ? value : value.split(',')
		let diff

		if (newValue.length < values.length) {
			diff = differenceWith(values, newValue, isEqual)
			values.splice(values.indexOf(diff[0]), 1)
		} else if (newValue.length >= values.length) {
			diff = differenceWith(newValue, values, isEqual)

			if (diff[0]) {
				values.push(diff[0])
			} else {
				values.splice(values.indexOf(diff[0]), 1)
			}
		}

		users[index].roles = values
		users[index].validRoles = values && values.length > 0
		this.setState({ users, userWantedToProceed: false })
	}

	inviteUser(index) {
		const { openModal } = this.props
		const { users } = this.state
		const user = fromJS(users[index])

		openModal(INVITE_USER_BY_EMAIL_TEMPLATE_MODAL, { user, index })
	}

	setInviteSentToUser = (index) => {
		const { users } = this.state
		users[index].inviteSent = true
		this.setState({ users })
	}

	checkIsUserEmptyByIndex = (index) => {
		const { users } = this.state
		return users[index].name.length === 0 && users[index].email.length === 0
	}

	sendInvitesIfNeeded = () => {
		const { openModal } = this.props
		const { users } = this.state
		let gotInvitesToSend = false

		users.forEach((user, index) => {
			// If user is not empty and if an invite wasn't already sent, send invite to user.
			if (!this.checkIsUserEmptyByIndex(index) && !user.inviteSent) {
				gotInvitesToSend = true
			}
		})

		if (gotInvitesToSend) {
			openModal(INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL, { users: fromJS(users) })
		} else {
			this.pushToAccount()
		}
	}

	renderTableHeader() {
		return (
			<thead>
				<tr>
					<th className='account-company'>
						<FormattedMessage id='account.name' />
					</th>
					<th className='account-status'>
						<FormattedMessage id='email' />
					</th>
					<th className='account-status'>
						<FormattedMessage id='account.roles' />
					</th>
					<th>&nbsp;</th>
				</tr>
			</thead>
		)
	}

	renderTableBody() {
		const { user } = this.props

		return (
			<tbody>
				<tr>
					<td>{user.get('name')}</td>
					<td>{user.get('email')}</td>
					<td>
						<FormattedMessage id='wizard.company.step3.owner.role' />
					</td>
					<td>&nbsp;</td>
				</tr>
				{this.state.users.map(this.renderTableRow.bind(this))}
			</tbody>
		)
	}

	renderTableRow(user, index) {
		const { i18n } = this.props
		const { userWantedToProceed } = this.state
		const showErrors = (user.name.length > 0 || user.email.length > 0 || user.roles.length > 0) && userWantedToProceed

		return (
			<tr key={index}>
				<td>
					<span className={showErrors && !user.validName ? 'has-error' : ''}>
						<input
							type='text'
							value={user.name}
							placeholder={i18n.messages['generic.name_placeholder']}
							className='form-control '
							onChange={this.editName.bind(this, index)}
							disabled={user.inviteSent}
						/>
					</span>
				</td>
				<td>
					<span className={showErrors && !user.validEmail ? 'has-error' : ''}>
						<input
							type='email'
							value={user.email}
							placeholder={i18n.messages['wizard.company.step3.invite_email_required']}
							className='form-control'
							onChange={this.editEmail.bind(this, index)}
							disabled={user.inviteSent}
						/>
					</span>
				</td>
				<td>
					<span className={showErrors && !user.validRoles ? 'has-error' : ''}>
						<Select
							multi={true}
							value={user.roles && user.roles.length > 0 ? user.roles : null}
							options={this.state.roles}
							simpleValue={true}
							placeholder={i18n.messages['you_must_select_one_or_more_teams']}
							className={`form-control ${index > 2 ? 'Select--up' : ''}`}
							onChange={this.editRoles.bind(this, index)}
							disabled={user.inviteSent}
						/>
					</span>
				</td>
				<td className='text--align-right'>{this.renderInviteButton(user, index)}</td>
			</tr>
		)
	}

	renderInviteButton(user, index) {
		const btnDisabled = !user.validName || !user.validEmail || !user.validRoles
		const btnSuccess = user.email && user.inviteSent === true

		if (btnDisabled) {
			return (
				<button type='button' className='btn btn-default' disabled='disabled'>
					<FormattedMessage id='generic.send_invite' />
				</button>
			)
		}

		if (btnSuccess) {
			return (
				<span className='text text--no-wrap'>
					<FormattedMessage id='generic.invite_sent' /> <span className='fa fa-check text--invono' />
				</span>
			)
		}

		return (
			<button type='button' className='btn btn-default' onClick={this.inviteUser.bind(this, index)}>
				<FormattedMessage id='generic.send_invite' />
			</button>
		)
	}

	onProceed = (hasErrors) => {
		this.setState({ userWantedToProceed: true })

		// Stop if there are errors
		if (hasErrors) {
			return
		}

		this.sendAllInvitesAndProceed()
	}

	pushToAccount = () => {
		const { company, history } = this.props
		history.push('/' + company.company.urlAlias)
	}

	sendAllInvitesAndProceed = () => {
		this.sendInvitesIfNeeded()
	}

	renderProceedButtonAndErrors = () => {
		const { users, userWantedToProceed } = this.state
		let hasErrors = false
		let allInvitesSent = true

		users.map((user, index) => {
			// If user isn't empty, check if all fields are valid
			if (!this.checkIsUserEmptyByIndex(index)) {
				if (!user.validName || !user.validEmail) {
					hasErrors = true
				}

				if (!user.inviteSent) {
					allInvitesSent = false
				}
			}
		})

		if (hasErrors && userWantedToProceed) {
			return (
				<div>
					<p className='text--error pad-ver'>
						<FormattedMessage id='wizard.company.step3.incorrect_data' />
					</p>
					<button className='btn btn-account-wizard' onClick={this.onProceed.bind(null, hasErrors)} disabled>
						<FormattedMessage id='wizard.company.step3.send_invite_go_to_account' />
					</button>
				</div>
			)
		}

		if (allInvitesSent) {
			return (
				<div>
					<p className='text--error pad-ver'>&nbsp;</p>
					<button className='btn btn-account-wizard' onClick={this.onProceed.bind(null, hasErrors)}>
						<FormattedMessage id='wizard.company.step3.go_to_account' />
					</button>
				</div>
			)
		}

		return (
			<div>
				<p className='text--error pad-ver'>&nbsp;</p>
				<button className='btn btn-account-wizard' onClick={this.onProceed.bind(null, hasErrors)}>
					<FormattedMessage id='wizard.company.step3.send_invite_go_to_account' />
				</button>
			</div>
		)
	}

	render() {
		const { initialLoadDone } = this.state

		if (!initialLoadDone) {
			return null
		}

		return (
			<>
				<div className='account-wizard__content account-wizard--animated account-wizard--fade-in-right'>
					<div className='account-wizard__header'>
						<h2 className='account-wizard__title'>
							<span className='account-wizard__step'>3</span> <FormattedMessage id='account_wizard.add_members' />
						</h2>
						<p>
							<FormattedMessage id='account_wizard.add_members_info' />
						</p>
					</div>
					<div className='account-wizard__body account-wizard__body--h275' style={{ height: 'auto' }}>
						<div className='i-panel i-panel--white'>
							<div className='i-panel__body'>
								<Scrollbars autoHeight autoHeightMin={300}>
									<table className='table table-striped'>
										{this.renderTableHeader()}
										{this.renderTableBody()}
									</table>
								</Scrollbars>
							</div>
						</div>
					</div>
					<div className='account-wizard__footer'>
						{this.renderProceedButtonAndErrors()}
						{this.state.error && (
							<p className='text--error pad-ver'>
								<FormattedMessage id='account_wizard.signatories_error' />
							</p>
						)}
					</div>
				</div>
				<InviteUserModalContainer onDone={this.setInviteSentToUser} />
				<InviteUsersModalContainer onDone={this.pushToAccount} />
			</>
		)
	}
}

function mapStoreToProps(state) {
	return {
		history: history,
		user: state.user.get('userObj'),
		company: state.company,
		i18n: state.i18n
	}
}

const mapActionsToProps = {
	createUser,
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyMembers)
