import React, { PureComponent } from 'react'
import { bool, func, oneOf, string } from 'prop-types'
import styled from 'styled-components'
import { Padding } from 'styled-components-spacing'
import Panel from '../../shared/panel/panel'
import Grid from 'styled-components-grid'
import Label from '../../shared/label/label'
import { Select } from '../../shared/select'
import { List, Map } from 'immutable'
import Text from '../../shared/text/text'

const StyledTextWrapper = styled.div`
	padding-top: ${(props) => props.theme.spacing[4]};
`

const options = List([
	Map({
		value: 'true',
		label: 'documents.manage_folder.shareholder.permissions.yes'
	}),
	Map({
		value: 'false',
		label: 'documents.manage_folder.shareholder.permissions.no'
	})
])

class ShareholderPermissionsDocument extends PureComponent {
	static propTypes = {
		onChange: func,
		isMirrored: bool,
		mode: oneOf(['branded', 'light', 'transparent']),
		panelType: oneOf(['inline', 'transparent']),
		selected: bool,
		noTitle: bool,
		infoTid: string,
		labelTid: string
	}

	static defaultProps = {
		mode: null
	}

	onChangedIsMirrored = (fieldName, value) => {
		const { onChange } = this.props
		if (value === 'false') {
			onChange(fieldName, false)
		} else {
			onChange(fieldName, true)
		}
	}

	render = () => {
		const { onChange, isMirrored, mode, panelType, selected, noTitle, infoTid, labelTid } = this.props
		const isTransparentPanel = panelType === 'transparent'
		const isModeTransparent = mode === 'transparent'

		return (
			<Panel
				title={noTitle ? '' : 'documents.permissions.manage_permissions.Shareholder_permissions'}
				mode={mode}
				type={panelType}
				active={selected}
				marginBottom={isTransparentPanel ? false : true}>
				<Padding all={isModeTransparent ? 0 : 4}>
					<Grid>
						<Grid.Unit size={1}>
							<Label tid={labelTid}>
								<Select
									fieldName='isMirrored'
									onChange={this.onChangedIsMirrored}
									options={options}
									labelIsTid={true}
									value={isMirrored ? isMirrored.toString() : 'false'}
								/>
							</Label>
							{infoTid && (
								<StyledTextWrapper>
									<Text tid={infoTid} />
								</StyledTextWrapper>
							)}
						</Grid.Unit>
					</Grid>
				</Padding>
			</Panel>
		)
	}
}

export default ShareholderPermissionsDocument
