import React, { Component } from 'react'
import { connect } from 'react-redux'

import history from '../../../interfaces/history'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import MenuLabel from '../../../dumb-components/company/nav-dropdown/menu-label'
import List from '../../../dumb-components/company/nav-dropdown/list'
import CompanyItem from '../../../dumb-components/company/nav-dropdown/item'
import CompanyDemoItemContainer from './demo-item.container'
import AddCompanyItemContainer from './add-company-item.container'

import { resetCompanyReducer } from '../../../actions/company.actions'

const ITEM_HEIGHT = 60

class CompanyDropdownMenuContainer extends Component {
	state = {
		maxHeight: 0
	}

	componentDidMount = () => {
		this.calculateMaxHeight()
		window.addEventListener('resize', this.calculateMaxHeight)
	}

	componentDidUmount = () => {
		window.addEventListener('resize', this.calculateMaxHeight)
	}

	calculateMaxHeight = () => {
		const { innerHeight } = window
		const y = this.menuRef && this.menuRef.getBoundingClientRect().y
		const otherMargins = 30
		const addCompanyItem = 60

		const avaibleHeight = innerHeight - y - otherMargins - addCompanyItem

		let companyHalfs = Math.floor(avaibleHeight / (ITEM_HEIGHT / 2))

		if (companyHalfs % 2 === 0) {
			companyHalfs = companyHalfs - 1
		}

		const maxHeight = companyHalfs * (ITEM_HEIGHT / 2)

		this.setState({ maxHeight })
	}

	onSelectCompany = (urlAlias) => {
		const { resetCompanyReducer, companyUrlAlias } = this.props
		this.dropdownRef && this.dropdownRef.onToggleMenu()

		if (urlAlias !== companyUrlAlias) {
			resetCompanyReducer()
		}

		this.props.history.push(`/${urlAlias}`)
	}

	onSetDropdownRef = (ref) => {
		this.dropdownRef = ref
	}

	onSetMenuRef = (ref) => {
		this.menuRef = ref
	}

	onSetScrollbarRef = (ref) => {
		this.scrollbarRef = ref
	}

	onCloseMenu = () => {
		this.scrollbarRef && this.scrollbarRef.scrollToTop()
	}

	getOrgNumber = (orgNumber) => {
		if (orgNumber.indexOf('demo=') === 0 || orgNumber.indexOf('userdemo=') === 0) {
			return '556987-2335'
		}

		return orgNumber
	}

	renderMenuComponent = () => {
		const { companyName } = this.props
		return <MenuLabel companyName={companyName} />
	}

	renderItem = (company) => {
		const filename = company.getIn(['image', 'thumbnail', 'filename'])
		const companyId = company.get('id')
		const imageUrl = filename ? `/api/companies/public/images/${filename}-100?companyId=${companyId}` : null

		return (
			<CompanyItem
				key={company.get('id')}
				name={company.get('name')}
				imageUrl={imageUrl}
				orgNumber={this.getOrgNumber(company.get('orgNumber'))}
				onSelect={this.onSelectCompany.bind(null, company.get('urlAlias'))}
			/>
		)
	}

	renderAddCompanyItem = () => {
		return <AddCompanyItemContainer dropdownRef={this.dropdownRef} />
	}

	render = () => {
		const { userId } = this.props
		let { companies } = this.props
		const { maxHeight } = this.state

		// Remove cancelled companies
		companies = companies.filter((company) => !company.get('isCanceled'))

		// Remove own demo company
		companies = companies.filter((company) => !(company.get('isUserDemo') && company.get('superUser') === userId))

		return (
			<DropdownMenuContainer
				ref={this.onSetDropdownRef}
				onClose={this.onCloseMenu}
				noMaxWidth={true}
				alwaysRenderDropdownItems
				renderRaw={this.renderMenuComponent()}>
				<List
					companiesSize={companies.size}
					maxHeight={maxHeight}
					onSetRef={this.onSetMenuRef}
					onSetScrollbarRef={this.onSetScrollbarRef}
					renderAddCompanyItem={this.renderAddCompanyItem}>
					{companies.map(this.renderItem)}
					{/* Demo company choice is deactivated*/}
					{/*<CompanyDemoItemContainer onSelect={this.onSelectCompany} />*/}
				</List>
			</DropdownMenuContainer>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		companyName: store.company.company && store.company.company.name,
		companyUrlAlias: store.company.company?.urlAlias,
		companies: store.company.companies,
		userId: store.user.getIn(['userObj', 'id'])
	}
}

const mapActionsToProps = {
	resetCompanyReducer
}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyDropdownMenuContainer)
