import React, { forwardRef } from 'react'
import { IconButton as MuiIconButton, alpha, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import Icon from '../../dumb-components/shared/icon/icon'

const IconButton = forwardRef((props, ref) => {
	const {
		disabled,
		onClick,
		variant,
		color,
		hoverColor,
		delay,
		icon,
		iconColor,
		background,
		size,
		type,
		rotate,
		noBorder,
		...other
	} = props
	const theme = useTheme()

	const adjustedIconButtonProps = {
		disabled: disabled,
		component: disabled ? 'div' : undefined,
		onClick: disabled ? undefined : onClick,
		variant: variant
	}

	let colorOnHover
	if (hoverColor) {
		colorOnHover = hoverColor
	} else if (theme.palette[color] && typeof theme.palette[color] === 'string') {
		colorOnHover = alpha(theme.palette[color], 0.75)
	} else if (theme.palette[color] && theme.palette[color].main) {
		colorOnHover = alpha(theme.palette[color].main, 0.75)
	} else {
		colorOnHover = '#d9d9d9'
	}

	return (
		<MuiIconButton
			sx={{
				borderWidth: noBorder ? '0px' : '1px',
				borderStyle: 'solid',
				borderRadius: '0',
				width: '36px',
				height: '36px',
				color: color,
				transition: delay && '300ms color, 300ms border',

				'& span': {
					color: iconColor || 'inherit'
				},

				'&:hover': {
					color: colorOnHover,
					backgroundColor: background || 'transparent'
				},

				'&.Mui-disabled': {
					pointerEvents: 'auto',
					color: '#a5a5a5',
					border: '1px solid #a5a5a5 !important'
				},

				'&.Mui-disabled *': {
					opacity: 1
				}
			}}
			ref={ref}
			{...other}
			{...adjustedIconButtonProps}>
			<Icon icon={icon} faded={disabled} color={color} size={size} type={type} rotate={rotate} />
		</MuiIconButton>
	)
})

IconButton.propTypes = {
	color: PropTypes.string,
	...MuiIconButton.propTypes
}

IconButton.defaultProps = {
	color: 'primary',
	...MuiIconButton.defaultProps
}

export default IconButton
