import React, { PureComponent } from 'react'
import { Margin } from 'styled-components-spacing'
import Alert from '../../shared/alert/alert'
import Text from '../../shared/text/text'
import { number, func } from 'prop-types'
import { TransparentButton } from '../../shared/button-v2'

class NewEmissionAlert extends PureComponent {
	static propTypes = {
		sharesRemaining: number,
		onClick: func
	}

	render = () => {
		const { sharesRemaining, onClick, onSubmit } = this.props

		if (sharesRemaining === 0) {
			return (
				<Margin top={3} left={4} right={4}>
					<Alert mode='info' icon='faCheck' iconType='solid'>
						<Text color='white' tid='shares.transactions.emissions.warning.all_distributed' />
						<TransparentButton
							tid='shares.transactions.emissions.warning.confirm'
							onClick={onSubmit}
							hasUnderline={true}
							textColor='white'
							fontSize='13px'
							bold={400}
						/>
					</Alert>
				</Margin>
			)
		}

		const warningTid =
			sharesRemaining < 0
				? 'shares.transactions.emissions.warning.all_distributed_over'
				: 'shares.transactions.emissions.warning.all_distributed_not'

		return (
			<Margin top={3} left={4} right={4}>
				<Alert mode='warning' icon='faExclamationTriangle' iconType='solid'>
					<Text color='white' tid={warningTid} />
					&nbsp;
					<Text
						tid='shares.transactions.emissions.warning.shares_distribution.incomplete.open.edit'
						color='white'
						onClick={onClick}
						hasUnderline
					/>
				</Alert>
			</Margin>
		)
	}
}

export default NewEmissionAlert
