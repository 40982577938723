import { fromJS, List, Map } from 'immutable'
import { EMAIL_TEMPLATES_FETCH_REMOTE, EMAIL_TEMPLATE_RESET, EMAIL_TEMPLATE_UPDATE_LOCAL } from '../actions/types'

/*
 **
 * emailTemplates: {
 *   TEMPLATE-NAME: {
 *     sv: '',
 *     en: '',
 *     pl(?): ''
 *     ...
 *   }
 * }
 */

const INITIAL_STATE = fromJS({
	workingTemplate: {},
	emailTemplates: {},
	emailTemplatesDefault: {}
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case EMAIL_TEMPLATES_FETCH_REMOTE: {
			state = state.set('emailTemplatesDefault', payload.get('emailTemplatesDefault'))
			state = state.set('emailTemplates', payload.get('emailTemplates'))

			return state
		}

		case EMAIL_TEMPLATE_RESET: {
			const fakeRemovedTemplate = Map({
				name: payload.get('templateName'),
				language: payload.get('templateLanguage')
			})

			const removedTemplate = payload.getIn(['removedTemplate', 'template']) || fakeRemovedTemplate

			const templateName = removedTemplate.get('name')
			const templateLanguage = removedTemplate.get('language')
			const emailTemplates = state.get('emailTemplates')

			const removedIndex = emailTemplates.findIndex(
				(t) => t.get('name') === templateName && t.get('language') === templateLanguage
			)

			if (removedIndex !== -1) {
				state = state.deleteIn(['emailTemplates', removedIndex])
			}

			return state
		}

		case EMAIL_TEMPLATE_UPDATE_LOCAL: {
			let emailTemplates = state.get('emailTemplates')
			const templateName = payload.get('name')
			const templateLanguage = payload.get('language')

			const foundIndex = emailTemplates.findIndex(
				(t) => t.get('name') === templateName && t.get('language') === templateLanguage
			)

			// Not found
			if (foundIndex === -1) {
				emailTemplates = emailTemplates.push(payload)
			} else {
				emailTemplates = emailTemplates.set(foundIndex, payload)
			}

			state = state.set('workingTemplate', payload)
			state = state.set('emailTemplates', emailTemplates)

			return state
		}

		default:
			return state
	}
}
