import { emissionConsts } from '../../../constants/emissions'

const readOnlyWhenStarted = ['Preparations', 'SubscriptionForm', 'Marketing']
const readOnlyWhenCompleted = [
	'Preparations',
	'SubscriptionForm',
	'Marketing',
	'Invitation',
	'SubscriptionList',
	'Allocation',
	'Payment'
]

export const emissionIsReadOnly = (emission, component) => {
	const {
		common: { emissionStatus }
	} = emissionConsts

	return (
		emission.status === emissionStatus.registered ||
		emission.status === emissionStatus.cancelled ||
		(emission.status === emissionStatus.invitationsSent && readOnlyWhenStarted.includes(component)) ||
		(emission.status === emissionStatus.partiallyClosed && readOnlyWhenStarted.includes(component)) ||
		(emission.status === emissionStatus.completed && readOnlyWhenCompleted.includes(component)) ||
		(component === 'Preparations' && emission.status === emissionStatus.preparationsApproved)
	)
}

export const emissionCanBeCancelled = (emission) => {
	const {
		common: { emissionStatus }
	} = emissionConsts

	return (
		emission.status === emissionStatus.active ||
		emission.status === emissionStatus.invitationsSent ||
		emission.status === emissionStatus.preparationsApproved
	)
}

export const emissionCanBePermanentlyCancelled = (emission) => {
	const {
		common: { emissionStatus }
	} = emissionConsts

	return emission.status === emissionStatus.cancelled
}
