import React, { Component } from 'react'
import { connect } from 'react-redux'
import Tooltip from '../../dumb-components/shared/tooltip/tooltip'
import { createTaskLocal, createTask } from '../../actions/tasks.actions'
import { Map } from 'immutable'
import { ButtonTransparentIcon } from '../../dumb-components/shared/button'
import history from '../../interfaces/history'

class TasksCreateModalBtnControllerContainer extends Component {
	state = {
		taskId: undefined
	}

	createTask = () => {
		const { selectedProjectId, createTaskLocal, createTask, basePath, tasks, selectedTask } = this.props

		let orderIndex = null

		if (selectedTask) {
			const selectedTaskIndex = tasks.findIndex((task) => {
				return selectedTask.get('id') === task.get('id')
			})

			const thisOrderIndex = tasks && tasks.getIn([selectedTaskIndex, 'orderIndex'])
			orderIndex = thisOrderIndex

			if (tasks.size > selectedTaskIndex + 1) {
				const nextOrderIndex = tasks && tasks.getIn([selectedTaskIndex + 1, 'orderIndex'])
				orderIndex = (nextOrderIndex + thisOrderIndex) / 2
			} else {
				orderIndex += 100
			}
		} else {
			orderIndex = tasks && tasks.first() ? tasks.first().get('orderIndex', 0) / 2 : 100
		}

		const task = Map({
			projectId: selectedProjectId,
			orderIndex: orderIndex
		})

		createTaskLocal(orderIndex, task, (newTask) => {
			createTask(newTask, () => {
				if (selectedProjectId) {
					this.props.history.push({ pathname: `${basePath}/${newTask.get('id')}`, search: `?project=${selectedProjectId}` })
				} else {
					this.props.history.push(`${basePath}/${newTask.get('id')}`)
				}
			})
		})
	}

	render = () => {
		const { projects } = this.props
		let { selectedProjectId } = this.props

		if (!selectedProjectId) {
			selectedProjectId = 'root'
		}

		const canCreateTask = projects && projects.getIn([selectedProjectId, 'ALLOW_CREATE_TASK'])
		const btnDisabled = !canCreateTask
		const isMeetingGroup = projects && projects.getIn([selectedProjectId, 'isMeetingGroup'])

		let tooltipActiveState = null
		const tooltipStates = {
			default: {
				tid: 'tooltip.tasks.action.create_task'
			},
			hasAppliedFilters: {
				tid: 'tooltip.tasks.cant_create_task_with_filters',
				delayShow: 'instant'
			}
		}

		if (!canCreateTask) {
			if (isMeetingGroup) {
				tooltipActiveState = 'noAddTaskToMeeting'
			} else {
				tooltipActiveState = 'hasNoPermissions'
			}
		}

		return (
			<div>
				<Tooltip states={tooltipStates} activeState={tooltipActiveState}>
					<ButtonTransparentIcon onClick={this.createTask} size='sml' icon='faPlusSquare' disabled={btnDisabled} />
				</Tooltip>
			</div>
		)
	}
}

function mapStoreToProps(store) {
	return {
		history: history,
		selectedProjectId: store.projects.get('selectedProjectId'),
		projects: store.projects.get('projects'),
		tasks: store.tasks.get('visibleTasks'),
		selectedTask: store.tasks.get('task')
	}
}

const mapActionsToProps = {
	createTaskLocal,
	createTask
}

TasksCreateModalBtnControllerContainer = connect(
	mapStoreToProps,
	mapActionsToProps
)(TasksCreateModalBtnControllerContainer)
export default TasksCreateModalBtnControllerContainer
