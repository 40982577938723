import React, { Component } from 'react'
import { connect } from 'react-redux'
import { object } from 'prop-types'
import { COMPANY_ADD_NEW_COMPANY_MODAL } from '../../../constants/modals'

import AddCompanyItem from '../../../dumb-components/company/nav-dropdown/add-company-item'
import AddCompanyModalContainer from '../add-company-modal.container'

import { openModal } from '../../../actions/modals.actions'

class AddCompanyItemContainer extends Component {
	static propTypes = {
		dropdownRef: object
	}

	openModal = () => {
		const { dropdownRef, openModal } = this.props

		dropdownRef && dropdownRef.onToggleMenu()

		openModal(COMPANY_ADD_NEW_COMPANY_MODAL)
	}

	render = () => {
		return (
			<>
				<AddCompanyItem onClick={this.openModal} />

				<AddCompanyModalContainer />
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(AddCompanyItemContainer)
