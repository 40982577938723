import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func, string } from 'prop-types'
import FolderTree from '../../dumb-components/documents/folder-tree/folder-tree'
import ImmutableProps from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import i18nHelper from '../../components/helpers/i18n.helper'

class ItemTreeContainer extends Component {
	state = {
		itemTree: List(),
		region: {}
	}

	static propTypes = {
		isInvestorMode: bool,
		items: ImmutableProps.map,
		selectedItem: ImmutableProps.map,
		onItemSelected: func,
		renderHoverText: bool,
		investedInCompany: ImmutableProps.map,
		rootItemTid: string,
		onItemOpen: func,
		onItemRef: func,
		permission: string,
		destinationBadgeTid: string
	}

	static defaultProps = {
		isInvestorMode: false,
		items: Map(),
		selectedItem: Map(),
		rootItemTid: 'documents.folders.root',
		destinationBadgeTid: 'files.movemfilemodal.click_to_move_file'
	}

	componentDidMount = () => {
		const { items } = this.props
		this.props.onRef && this.props.onRef(this)

		if (items) {
			this.treeifyItems(items)
		}
	}

	componentWillUnmount = () => {
		this.props.onRef && this.props.onRef(undefined)
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { items, selectedItem } = this.props

		if (prevProps.items.size === 0 && items.size > 0 && items !== prevProps.items) {
			this.treeifyItems(items)
		}

		if (prevState.itemTree.size < this.state.itemTree.size && this.props.selectedItem) {
			this.openItemsIfNeeded(selectedItem.get('id'), true)
		}
	}

	treeifyItems = (items) => {
		const { i18n, rootItemTid, isInvestorMode, company, investedInCompany } = this.props
		let region

		if (company) {
			region = company.region
		} else if (investedInCompany && investedInCompany.get('region')) {
			region = investedInCompany.get('region').toJS()
		}

		items = items.toList()
		// Detta gjordes tidigare i backend, men då var svenska satt som förvalt språk om inte mappen/gruppen/kategorin hade en översättning av det språk
		// som användaren har inställt i webbläsaren. Iom IPS-5168 så har detta flyttats till frontend så att listan sorterar alfabetiskt baserat på användarens valda språk.
		items = items.map((item) => {
			item = item.set('translatedName', i18nHelper.getTranslatedValue(item.get('name'), i18n.language, region))
			return item
		})
		items = items.sort((a, b) =>
			a.get('translatedName') ? a.get('translatedName').localeCompare(b.get('translatedName')) : 0
		)
		let itemTree = this.treeify(items, null)
		/* Add root item */
		const rootItem = Map({
			name: i18n.messages[rootItemTid],
			parent: null,
			itemId: null,
			isOpen: true
		})
		itemTree = List([rootItem.set('children', itemTree)])

		this.setState({ itemTree, region })
	}

	treeify = (items, parent) => {
		let out = List()

		items.forEach((item) => {
			// Filter out the real root item
			if (item.get('id') === 'root') {
				return
			}

			// could just do item.get('parent') == parent,
			// This part exist so that even without a parent object your parent is root
			if (item.get('parent') === undefined) {
				item = item.set('parent', null)
			}
			if (item.get('parent') === parent) {
				const children = this.treeify(items, item.get('id'))
				if (children.size) {
					item = item.set('children', children)
				}
				out = out.push(item)
			}
		})
		return out
	}

	onItemSelected = (selectedItem) => {
		this.props.onItemSelected && this.props.onItemSelected(selectedItem)
	}

	toggleItems = (openState, itemId, branch) => {
		if (branch.get('id') === itemId) {
			branch = branch.set('isOpen', openState)
		} else if (branch.get('children', List()).size > 0) {
			const children = branch.get('children').map(this.toggleItems.bind(this, openState, itemId))
			branch = branch.set('children', children)
		}

		return branch
	}

	openItemsIfNeeded = (itemId, openState) => {
		const { items } = this.props
		let { itemTree } = this.state
		const index = itemTree.findIndex((obj) => {
			return obj.get('id') === itemId
		})

		// If index is -1 the item is not in the top level of the tree and we need to open the tree
		if (index === -1) {
			itemTree = itemTree.setIn([0, 'isOpen'], 'true')
			itemTree = itemTree.map(this.toggleItems.bind(this, false, itemId))
			let item = itemId
			while (items.get(item).get('parent')) {
				const parent = items.get(item).get('parent')
				itemTree = itemTree.map(this.toggleItems.bind(this, openState, parent))
				item = parent
			}

			this.setState({ itemTree })
		}
	}

	onItemOpenStateChanged = (itemId, openState) => {
		let { itemTree } = this.state
		itemTree = itemTree.map(this.toggleItems.bind(this, openState, itemId))
		this.setState({ itemTree })
	}

	onItemOpen = (itemId) => {
		this.onItemOpenStateChanged(itemId, true)
		this.props.onItemOpen && this.props.onItemOpen(itemId)
	}

	onItemClose = (itemId) => {
		this.onItemOpenStateChanged(itemId, false)
	}

	onBadgeClick = (folderId) => {
		this.openItemsIfNeeded(folderId, true)
	}

	render = () => {
		const { itemTree, region } = this.state
		const {
			isInvestorMode,
			selectedItem,
			renderHoverText,
			i18n,
			company,
			investedInCompany,
			onItemRef,
			permission,
			destinationBadgeTid
		} = this.props

		return (
			<FolderTree
				isInvestorMode={isInvestorMode}
				folders={itemTree}
				onSelect={this.onItemSelected}
				onOpen={this.onItemOpen}
				onClose={this.onItemClose}
				renderHoverText={renderHoverText}
				selected={selectedItem && selectedItem.get('id')}
				userLang={i18n.language}
				region={region}
				onItemRef={onItemRef}
				permission={permission}
				destinationBadgeTid={destinationBadgeTid}
			/>
		)
	}
}

function mapStoreToProps(store) {
	return {
		i18n: store.i18n,
		company: store.company.company
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(ItemTreeContainer)
