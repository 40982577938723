import React, { PureComponent } from 'react'
import { func } from 'prop-types'
import Panel from '../../shared/panel/panel'
import Icon from '../../shared/icon/icon'

export default class FeedbackPanel extends PureComponent {
	renderIcon = () => {
		return <Icon icon='faCommentAltExclamation' type='solid' size='sml' color='orange' />
	}

	render = () => {
		const { children } = this.props

		return (
			<Panel title='meetings.protocol.feedback.title' rightComponent={this.renderIcon} marginBottom>
				{children}
			</Panel>
		)
	}
}
