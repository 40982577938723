import { getStore } from '../../store'
import { Map, isImmutable, fromJS } from 'immutable'
import uuid from 'uuid'
import { INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE } from '/shared/constants'
import { createInvestor } from '../../actions/investors.actions'
import { openModal } from '../../actions/modals.actions'
import { SHARES_ADD_SHAREHOLDER_MODAL } from '../../constants/modals'

const store = getStore()

export function getShareTypeLabel(shareType) {
	const i18n = store.getState().i18n
	const [shareClass, serie] = shareType.split('$')
	let shareTypeLabel = ''

	if (shareClass !== null && shareClass !== '') {
		shareTypeLabel = (i18n.messages[`share_class.${shareClass}`] || decodeURIComponent(shareClass)) + ' '
	}

	if (serie !== null && serie !== '') {
		shareTypeLabel += i18n.messages[`share_type.${serie}`] || decodeURIComponent(serie).toUpperCase()
	}

	return shareTypeLabel
}

export function getShareTypeName(shareType) {
	const i18n = store.getState().i18n
	const [shareClass, serie] = shareType.split('$')
	let shareTypeLabel = ''

	if (shareClass !== null && shareClass !== '') {
		shareTypeLabel = (i18n.messages[`share_class.${shareClass}`] || decodeURIComponent(shareClass)) + ' '
	}

	return shareTypeLabel
}

export function getShareTypeClass(shareType) {
	const i18n = store.getState().i18n
	const [shareClass, serie] = shareType.split('$')
	let shareTypeLabel = ''

	if (serie !== null && serie !== '') {
		shareTypeLabel = i18n.messages[`share_type.${serie}`] || decodeURIComponent(serie)
	}

	return shareTypeLabel.toUpperCase()
}

export function getMergedShareType(shareTypeName, shareTypeClass) {
	const i18n = store.getState().i18n
	shareTypeClass = shareTypeClass ? encodeURIComponent(shareTypeClass).toLowerCase() : ''
	shareTypeName = typeof shareTypeName === 'object' && shareTypeName.label ? shareTypeName.label : shareTypeName

	const encodedShareTypeName = encodeURIComponent(_shareTypeName)
	const isSystemShareTypeName = i18n.messages[`share_class.${encodedShareTypeName}`]

	let _shareTypeName = shareTypeName
	if (isSystemShareTypeName) {
		_shareTypeName = encodeURIComponent(_shareTypeName).toLowerCase()
	} else {
		_shareTypeName = encodeURIComponent(_shareTypeName)
	}

	return `${_shareTypeName}$${shareTypeClass}`
}

export function getFullShareTypeLabel(type) {
	if (!type) {
		return type
	}
	const i18n = store.getState().i18n
	const [shareClass, shareType] = type.split('$')
	return (
		(i18n.messages[`share_class.${shareClass}`] || decodeURIComponent(shareClass)) +
		' ' +
		(i18n.messages[`share_type.${shareType}`] || decodeURIComponent(shareType))
	)
}

export function getTransactionLimitationsLabel(transactionLimitations) {
	const i18n = store.getState().i18n
	const labels = []

	if (!transactionLimitations) {
		return ''
	}

	transactionLimitations = transactionLimitations.forEach ? transactionLimitations : transactionLimitations.split(',')
	transactionLimitations.forEach((str) => {
		labels.push(i18n.messages[`transfer-limitation.${str}`])
	})

	return labels.join(', ')
}

/**
 * Used for restricting input on the share series
 * Returns ANY length word with UPPERCASE LETTERS only
 * Created for Select.Creatable
 * @param {String} val (Input)
 */
export function getOnlyUppercaseAndLetters(val) {
	// Selection was made using dropdown and not keyboard input
	if (!val) return

	const lastInput = val[val.length - 1]
	const value = val.toUpperCase()

	// Last input (character) was a letter, return everything
	if (lastInput.match(/[a-z]/i)) {
		return value
	}

	// Last input wasn't an letter, remove last character from the value and return it
	return value.slice(0, -1)
}

export function getShareholderStatus(shareholder) {
	if (shareholder.getIn(['details', 'numOfTotalShares']) > 0 && !shareholder.get('isDeletable')) {
		return 'CURRENT'
	}

	if (shareholder.getIn(['details', 'numOfTotalShares']) === 0 && !shareholder.get('isDeletable')) {
		return 'ARCHIVED'
	}

	if (!shareholder.get('details') && shareholder.get('isDeletable')) {
		return 'NEW'
	}
}

// Get investors that are missing ID (ssn, orgnr or other)
export function getInvestorsWithNoSsnOrOrgnr() {
	const investors = store.getState().investors.get('list')

	if (!investors) {
		return
	}

	const investorsWithNoId = investors.filter((inv) => {
		if (inv.get('investorTypeOfOwner') === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE) {
			const insuranceNumber = inv.getIn(['investorInformation', 'insuranceNr'])

			return !insuranceNumber || insuranceNumber.length === 0 || insuranceNumber === ''
		}

		const id = inv.getIn(['investorInformation', 'id'])
		return !id || id.length === 0 || id === ''
	})

	return investorsWithNoId
}

// Check if there is any investors without ID (ssn, orgnr or other)
export function investorsWithNoSsnOrOrgnrExists() {
	const result = getInvestorsWithNoSsnOrOrgnr()
	return result && result.size > 0
}

export function initNewInvestor({ modalOptions, callback }) {
	store.dispatch(createInvestor(Map({ id: uuid() })))
	store.dispatch(openModal(SHARES_ADD_SHAREHOLDER_MODAL, modalOptions))
	callback && callback()
}
