import React, { PureComponent } from 'react'
import { func, object, string, bool } from 'prop-types'
import { list, map } from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

import Modal from '../../shared/modal/modal'
import SimpleDarkInfoPanelWithControls from '../../shared/dark-info-panel/simple-dark-info-panel-with-controls'
import { OutlinedButton } from '../../shared/button-v2'
import Label from '../../shared/label/label'
import ScrollView from '../../shared/layout/scroll-view/scroll-view'
import Icon from '../../shared/icon/icon'
import TransparentInput from '../../shared/input/transparent-input'
import Text from '../../shared/text/text'
import SimpleDarkInfoPanel from '../../shared/dark-info-panel/simple-dark-info-panel'

import ItemsList from '../../shared/list/list'
import CollectionListItem from './collection-list-item'
import DocumentListItem from './document-list-item'

import { DndProvider, Droppable, Sortable } from '../../shared/drag-n-drop'

const Wrapper = styled.div`
	display: flex;
	height: 100%;
`

const LeftWrapper = styled.div`
	flex: 2;
	display: flex;
	flex-direction: column;
	border-left: 1px solid ${({ theme }) => theme.colors.border};
	border-right: 1px solid ${({ theme }) => theme.colors.border};
	margin-left: ${({ theme }) => theme.spacing[6]};
`

const RightWrapper = styled.div`
	flex: 3;
	display: flex;
	flex-direction: column;
	margin-right: ${(props) => props.theme.spacing[6]};
	border-right: 1px solid ${(props) => props.theme.colors.border};
`

const RightInnerWrapper = styled.div`
	height: 100%;
`

const AddCollectionWrapper = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.border};
	padding: ${({ theme }) => theme.spacing[3]};
`
const CollectionsScrollOutterWrapper = styled.div`
	position: relative;
	height: 100%;
`

const DraggingLeftOverlay = styled.div`
	position: absolute;
	height: 100%;
	display: ${({ show }) => (show ? 'flex' : 'none')};
	width: 100%;
	padding: ${({ theme }) => theme.spacing[4]};
	background-color: rgba(255, 255, 255, 0.9);
	align-items: center;
	flex-direction: column;
	padding-top: 15%;
	z-index: 10;
`

const StyledCollectionsItemsList = styled(ItemsList)`
	margin-bottom: ${({ theme }) => theme.spacing[4]};
`

const StyledLoadingWrapper = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	align-items: center;
	justify-content: center;
`

const StyledNameInput = styled(TransparentInput)`
	/* 24px to manually match height of the add collection component to the left*/
	padding: 24px ${({ theme }) => theme.spacing[4]};
	border: 1px solid transparent;
	transition: border-color 0.3s ease, background 0.3s ease;
	border-radius: ${({ theme }) => theme.spacing[3]};

	:hover {
		background: ${(props) => darken(0.02, props.theme.colors.white)};
	}

	:hover,
	:focus {
		border-color: ${({ theme }) => theme.colors.border};
	}
`

const StyledNameWrapper = styled.div`
	padding: ${({ theme }) => theme.spacing[3]};
	border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

const StyledRootName = styled(Text)`
	padding: 25px ${({ theme }) => theme.spacing[4]};
	display: flex;
`

const DocumentsInnerWrapper = styled(ItemsList)`
	padding: ${({ theme }) => theme.spacing[4]};
`

const AddCollectionComponent = ({ onClick, tid }) => (
	<AddCollectionWrapper>
		<SimpleDarkInfoPanelWithControls
			tid={tid}
			rightControlsComponent={<OutlinedButton icon='faPlus' onClick={onClick} />}
		/>
	</AddCollectionWrapper>
)

const LoadingComponent = () => (
	<StyledLoadingWrapper>
		<Icon icon='faSpinner' size='xl' spin />
	</StyledLoadingWrapper>
)

export default class CollectionsModal extends PureComponent {
	static propTypes = {
		collections: list,
		documents: list,
		onAddCollection: func,
		footerComponent: object,
		renderCollectionDropdown: func,
		setScrollbarRef: func,
		onSelect: func,
		selectedCollection: map,
		setItemRef: func,
		isLoadingFiles: bool,
		onChange: func,
		renderDocumentDropdown: func,
		getTranslatedMessage: func,
		onSortDocuments: func,
		onMoveDocument: func,
		onInitDragging: func,
		isDragging: bool,
		onStopDragging: func,
		collectionNameInputRef: func
	}

	static defaultProps = {
		collections: List()
	}

	getCollectionNameData = (collection, includeUnnamedInName = false) => {
		const { getTranslatedMessage } = this.props
		const isRoot = collection.get('isRoot')
		let name = collection.get('name')

		if (isRoot) {
			return {
				name: getTranslatedMessage('collections.modal.collection.item.ROOT'),
				isItalic: true,
				isRoot
			}
		}

		if (!name) {
			return {
				name: includeUnnamedInName ? getTranslatedMessage('collections.modal.collection.name.UNNAMED') : name,
				isItalic: true,
				isRoot
			}
		}

		return {
			name,
			isItalic: false,
			isRoot
		}
	}

	renderCollectionItem = (collection, index) => {
		const { onSelect, setItemRef, selectedCollection, onMoveDocument } = this.props
		const selectedCollectionId = selectedCollection.get('id')
		const collectionId = collection.get('id')
		const onHover = (item, monitor) => {
			console.log(item)
		}

		return (
			<Droppable droppableId={collectionId} accept='document' key={index} onDrop={onMoveDocument}>
				{({ canDrop, isOver }, style, dropRef) => (
					<div ref={dropRef} style={style}>
						<CollectionListItem
							collection={collection}
							renderDropdown={this.renderCollectionDropdown}
							onSelect={onSelect}
							setRef={setItemRef}
							selectedCollectionId={selectedCollectionId}
							getCollectionNameData={this.getCollectionNameData}
						/>
					</div>
				)}
			</Droppable>
		)
	}

	renderCollectionDropdown = (collection) => {
		const { renderCollectionDropdown } = this.props
		return renderCollectionDropdown && renderCollectionDropdown(collection)
	}

	renderDocumentItem = (id, { isDragging }, ref, style) => {
		const { documents, selectedCollection, renderDocumentDropdown, onInitDragging, onStopDragging } = this.props
		const document = documents.find((doc) => doc.get('id') === id)

		return (
			<div ref={ref} style={style}>
				<DocumentListItem
					document={document}
					selectedCollection={selectedCollection}
					renderDropdown={renderDocumentDropdown}
					onInitDragging={onInitDragging}
					onStopDragging={onStopDragging}
				/>
			</div>
		)
	}

	renderLeftComponent = () => {
		const { onAddCollection, setScrollbarRef, collections, isDragging } = this.props

		return (
			<>
				<AddCollectionComponent
					onClick={onAddCollection}
					tid={
						isDragging
							? 'collections.modal.collections.dnd_overlay.info'
							: 'collections.modal.manage.add_collection.info'
					}
				/>

				<CollectionsScrollOutterWrapper>
					<ScrollView scrollbarRef={setScrollbarRef} noLeftMargin noRightMargin alwaysShow fillContent>
						<StyledCollectionsItemsList>{collections.map(this.renderCollectionItem)}</StyledCollectionsItemsList>
					</ScrollView>
				</CollectionsScrollOutterWrapper>
			</>
		)
	}

	renderDocumentsList = () => {
		const { isLoadingFiles, selectedCollection, documents, onSortDocuments } = this.props
		const { isRoot } = this.getCollectionNameData(selectedCollection)

		if (isLoadingFiles) {
			return <LoadingComponent />
		}

		const hasDocuments = selectedCollection.get('documents', List()).size > 0

		if (!hasDocuments) {
			return (
				<DocumentsInnerWrapper>
					<SimpleDarkInfoPanel>
						<Text
							tid={isRoot ? 'collections.modal.collections.empty_root' : 'collections.modal.collections.empty'}
							color='white'
						/>
					</SimpleDarkInfoPanel>
				</DocumentsInnerWrapper>
			)
		}

		const items = documents.map((doc) => Map({ id: doc.get('id'), type: 'document', title: doc.get('title') }))
		return (
			<ScrollView noLeftMargin noRightMargin alwaysShow>
				<DocumentsInnerWrapper>
					<Sortable items={items.toJS()} onDragEnd={onSortDocuments} sortableId='sortable'>
						{this.renderDocumentItem}
					</Sortable>
				</DocumentsInnerWrapper>
			</ScrollView>
		)
	}

	renderRightComponent = () => {
		const { selectedCollection, onChange, getTranslatedMessage, collectionNameInputRef, onEnter } = this.props
		const { name, isItalic, isRoot } = this.getCollectionNameData(selectedCollection)

		return (
			<>
				<StyledNameWrapper>
					<StyledNameInput
						key={selectedCollection.get('id')}
						fieldName='name'
						onChange={onChange}
						value={name}
						disabled={isRoot}
						hasItalic={isItalic}
						placeholder={getTranslatedMessage('collections.modal.collection.name.UNNAMED')}
						inputRef={collectionNameInputRef}
						onEnter={onEnter}
					/>
				</StyledNameWrapper>

				{this.renderDocumentsList()}
			</>
		)
	}

	render = () => {
		const { footerComponent } = this.props

		return (
			<Modal
				title='collections.modal.manage.title'
				isOpen={true}
				hSize='xl'
				scrollableContent={false}
				noBodyMargin={true}
				footerComponent={footerComponent}>
				<Wrapper>
					<DndProvider>
						<LeftWrapper>{this.renderLeftComponent()}</LeftWrapper>
						<RightWrapper>{this.renderRightComponent()}</RightWrapper>
					</DndProvider>
				</Wrapper>
			</Modal>
		)
	}
}
