import { fromJS, List, Map } from 'immutable'
import {
	PROTOCOL_PDF_OPTIONS_LIST,
	PROTOCOL_PDF_OPTIONS_FETCH,
	PROTOCOL_PDF_OPTIONS_SAVE,
	PROTOCOL_PDF_OPTIONS_UPDATE_LOCAL,
	PROTOCOL_PDF_OPTIONS_CLEAR,
	COMPANY_RESET_REDUCER
} from './types'
import req from '../modules/request.module'
import { addErrorNotification, addInfoNotification } from './notify.actions'
import uuid from 'uuid'

/**
 * Action for fetching protocol pdf option
 */
export function fetchProtocolPdfOption(id, callback) {
	return function (dispatch) {
		return req
			.get(`/meetings/protocol-pdf/${id}`)
			.then((response) => {
				const protocolPdfOption = fromJS(response.data)
				dispatch({ type: PROTOCOL_PDF_OPTIONS_FETCH, payload: protocolPdfOption })
				callback && callback(protocolPdfOption)
			})
			.catch(() => {
				dispatch(protocolPdfOptionError('meetings.protocol_pdf_options.error.load_protocol_pdf_option'))
			})
	}
}

/**
 * Action for resetting protocolPdfOption
 */
export function clearProtocolPdfOption() {
	return {
		type: PROTOCOL_PDF_OPTIONS_CLEAR
	}
}

/**
 * Action for updating protocol pdf option locally
 */
export function updateProtocolPdfOptionLocal(protocolPdfOption) {
	return function (dispatch) {
		dispatch({ type: PROTOCOL_PDF_OPTIONS_UPDATE_LOCAL, payload: protocolPdfOption })
	}
}

/**
 * Action for saving changes of a protocol pdf option
 */
export function saveProtocolPdfOption(protocolPdfOption, callback) {
	return function (dispatch) {
		return req
			.put(`/meetings/protocol-pdf/${protocolPdfOption.get('id')}`, { protocolPdfOption: protocolPdfOption.toJS() })
			.then((response) => {
				dispatch({ type: PROTOCOL_PDF_OPTIONS_SAVE, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch(() => {
				dispatch(protocolPdfOptionError('meetings.protocol_pdf_options.error.save_protocol_pdf_option'))
			})
	}
}

/**
 * Action for fetching all protocol pdf options
 */
export function listProtocolPdfOptions() {
	return function (dispatch) {
		return req
			.get(`/meetings/protocol-pdf/`)
			.then((response) => {
				dispatch({ type: PROTOCOL_PDF_OPTIONS_LIST, payload: fromJS(response.data) })
			})
			.catch(() => {
				dispatch(protocolPdfOptionError('meetings.protocol_pdf_options.error.load_protocol_pdf_options'))
			})
	}
}

/**
 * Action for setting protocol pdf option to default
 */
export function setDefaultProtocolPdfOption(id) {
	return function (dispatch) {
		return req
			.post(`/meetings/protocol-pdf/${id}/default`)
			.then((response) => {
				dispatch({ type: PROTOCOL_PDF_OPTIONS_LIST, payload: fromJS(response.data) })
			})
			.catch(() => {
				dispatch(protocolPdfOptionError('meetings.protocol_pdf_options.error.save_protocol_pdf_option'))
			})
	}
}

/**
 * Action for listing Google Fonts
 */
export function listGoogleFonts(callback) {
	return function () {
		return req
			.get('/meetings/google-fonts')
			.then((response) => {
				callback && callback(fromJS(response.data))
			})
			.catch(() => {
				dispatch(protocolPdfOptionError('meetings.protocol_pdf_options.error.load_google_fonts'))
			})
	}
}

/**
 * Action for previewing protocol pdf option
 */
export function previewProtocolPdfOption(protocolPdfOption, companyId) {
	return function () {
		const reference = uuid()
		const win = window.open(`https://${window.location.hostname}/assets/build/misc/generating-pdf.html`, '_blank')
		req.post(`/meetings/protocol-pdf/preview`, { protocolPdfOption, reference }).then(() => {
			win.location = `/api/pdf/minutes/${companyId}-${reference}`
		})
	}
}

/**
 * Action for dispatching an meeting template error
 * @param {String} error — error message
 */
export function protocolPdfOptionError(error) {
	return addErrorNotification({
		tid: error
	})
}
