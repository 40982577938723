import React, { useRef } from 'react'
import Box from '@mui/material/Box'
import { CustomTheme } from './custom-theme'
import { ThemeProvider } from '@mui/material/styles'
import ScrollView from '../../../dumb-components/shared/layout/scroll-view/scroll-view'

const EmissionsBaseLayout = (props) => {
	const scrollbarRef = useRef()

	const style = `
	.base-layout__body__content {
		max-width: 100%;
	}`

	return (
		<ThemeProvider theme={CustomTheme}>
			<style>{style}</style>
			<ScrollView autoHide={true} showOnHover={true} noLeftMargin={true} scrollbarRef={scrollbarRef}>
				<Box sx={{ px: 8, py: 4, maxWidth: '1500px' }}>{props.children}</Box>
			</ScrollView>
		</ThemeProvider>
	)
}

export default EmissionsBaseLayout
