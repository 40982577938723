import { fromJS, List } from 'immutable'
import uuid from 'uuid'
import moment from '../modules/moment.module'
import req from '../modules/request.module'
import { generateQuery } from '../components/helpers/query-helper'
import {
	ATTACHMENTS_FETCH,
	ATTACHMENTS_CREATE,
	ATTACHMENTS_UPDATE,
	ATTACHMENTS_DELETE,
	ATTACHMENTS_SELECT,
	ATTACHMENTS_UNSELECT,
	ATTACHMENTS_GET,
	DOCUMENTS_UPDATE_LOCAL,
	ATTACHMENTS_FETCH_MULTIPLE
} from './types'
import { notifyFetchingAttachments } from './notify.actions'
import { hardDeleteDocumentLocal, documentsError } from './documents.actions'
import { setLiveRequest, resetLiveRequest } from './live-update.actions'
import {
	EVENT_TYPE_ATTACHED_DOCUMENT_UPDATE,
	EVENT_TYPE_ATTACHED_DOCUMENT_CREATE,
	EVENT_TYPE_ATTACHED_DOCUMENT_DELETE,
	EVENT_TYPE_ATTACHED_DOCUMENT_LINKED
} from '/shared/constants'

export function fetchAttachedDocuments(objId, callback, onFilterAttachments) {
	return function (dispatch) {
		return req.get(`/documents/documents/attachments/${objId}`).then((response) => {
			let attachments = fromJS(response.data)

			if (onFilterAttachments) {
				attachments = onFilterAttachments(attachments)
			}

			dispatch({ type: ATTACHMENTS_FETCH, payload: { objId, attachments } })
			callback(attachments)
		})
	}
}

export function fetchAttachedDocumentsPublic(objId, companyId, callback, onFilterAttachments) {
	return function (dispatch) {
		return req
			.get(`/documents/public/documents/${objId}?companyId=${companyId}`)
			.then((response) => {
				let attachments = fromJS(response.data)

				if (onFilterAttachments) {
					attachments = onFilterAttachments(attachments)
				}

				dispatch({ type: ATTACHMENTS_FETCH, payload: { objId, attachments } })
				callback(attachments)
			})
			.catch((err) => {
				console.log(err)
				dispatch(documentsError('documents.error.load_documents'))
			})
	}
}

export function fetchMultipleAttachedDocuments(objIds, callback) {
	return function (dispatch) {
		dispatch(notifyFetchingAttachments(true))

		return req.post('/documents/documents/attachment/multiple', objIds).then((response) => {
			const attachments = fromJS(response.data.documents)
			const mapObjIdToDocId = fromJS(response.data.mapObjIdToDocId)
			dispatch({ type: ATTACHMENTS_FETCH_MULTIPLE, payload: { mapObjIdToDocId, attachments } })
			callback?.(fromJS(response.data))
			dispatch(notifyFetchingAttachments(false))
		})
	}
}

export function fetchAttachedDocument(objType, objId, id, silent) {
	return function (dispatch) {
		return req
			.get(`/documents/documents/attachment/${objType}/${objId}/${id}?silent=${silent}`)
			.then((response) => {
				if (response.data && !response.data.silentError) {
					const attachment = fromJS(response.data)
					dispatch({ type: ATTACHMENTS_GET, payload: { objId, attachment } })
					dispatch({ type: DOCUMENTS_UPDATE_LOCAL, payload: attachment })
				}
			})
			.catch((err) => {
				console.log(err)
				dispatch(documentsError('documents.error.load_document'))
			})
	}
}

export function createAttachedDocument(objType, objId, document, callback) {
	return function (dispatch) {
		return req
			.post(`/documents/documents/attachment/${objType}/${objId}`, document)
			.then((response) => {
				const attachment = fromJS(response.data)
				dispatch({ type: ATTACHMENTS_CREATE, payload: { objId, attachment } })
				callback(attachment)
			})
			.catch((e) => {
				callback(fromJS({ hasError: true, data: e?.response?.data }))
			})
	}
}

export function createAttachedDocumentPublic(objType, objId, doc, companyId, userId, callbacks) {
	const config = {
		onUploadProgress: (progressEvent) =>
			callbacks && callbacks.onUploadProgress ? callbacks.onUploadProgress(progressEvent) : null
	}
	const query = generateQuery({ companyId, userId })

	const docId = uuid()
	doc.append('id', docId)

	const localDoc = {
		id: docId,
		createdAt: moment().toISOString()
	}
	for (const prop of doc) {
		if (prop[0] !== 'file') {
			localDoc[prop[0]] = prop[1]
		}
	}

	return function (dispatch) {
		return req
			.post(`/documents/public/documents/${objType}/${objId}${query}`, doc, config)
			.then((response) => {
				let doc = fromJS(response.data)
				dispatch({ type: ATTACHMENTS_CREATE, payload: { objId, attachment: doc } })
				callbacks && callbacks.onComplete ? callbacks.onComplete(doc) : callbacks(doc)
			})
			.catch((e) => {
				console.log(e)
				dispatch(documentsError('documents.error.create_document'))

				if (e.response.data.translationId === 'documents.error.no_storage_space_left') {
					dispatch(hardDeleteDocumentLocal(docId))
				}
			})
	}
}

export function updateAttachedDocument(objType, objId, attachment, callback) {
	const attachmentId = attachment.get('id')

	return function (dispatch) {
		return req.put(`/documents/documents/external/${objType}/${objId}/${attachmentId}`, attachment).then((response) => {
			const attachment = fromJS(response.data)
			dispatch({ type: ATTACHMENTS_UPDATE, payload: { objId, attachment } })
			callback && callback(attachment)
		})
	}
}

export function updateAttachedDocumentLocal(objId, attachment, updateAttachmentsList) {
	return function (dispatch) {
		dispatch({ type: ATTACHMENTS_UPDATE, payload: { objId, attachment, updateAttachmentsList } })
	}
}

export function reorderDocuments(documents, sourceIndex, destinationIndex, objType, objId, callback) {
	return function (dispatch) {
		let document = documents.get(sourceIndex)
		const link = documents.getIn([destinationIndex, 'links'], List()).find((link) => link.get('objId') === objId)

		if (!link) {
			return
		}

		const documentOrder = link.get('orderIndex', 0.01) || 0.01
		let prevDocOrder = null

		// down
		if (sourceIndex < destinationIndex) {
			if (destinationIndex + 1 < documents.size) {
				const prevDocLink = documents
					.getIn([destinationIndex + 1, 'links'], List())
					.find((link) => link.get('objId') === objId)
				prevDocOrder = (destinationIndex > 0 && prevDocLink.get('orderIndex', 0)) || 0
			} else {
				prevDocOrder = documentOrder + 100
			}
		} else {
			// up
			const prevDocLink = documents
				.getIn([destinationIndex - 1, 'links'], List())
				.find((link) => link.get('objId') === objId)
			prevDocOrder = (destinationIndex > 0 && prevDocLink.get('orderIndex', 0)) || 0
		}

		const orderIndex = (documentOrder + prevDocOrder) / 2
		document = document.update('links', (links) => {
			return links.map((obj) => {
				if (obj.get('objId') === objId) {
					obj = obj.set('orderIndex', orderIndex)
				}

				return obj
			})
		})

		dispatch(updateAttachedDocument(objType, objId, document))

		// Add the updated document to the documents stack
		documents = documents.map((obj) => {
			if (obj.get('id') === document.get('id')) {
				return document
			}

			return obj
		})

		// Sort documents by order index
		documents = documents.sort((a, b) => {
			const aLink = a.get('links').find((l) => l.get('objId') === objId)
			const bLink = b.get('links').find((l) => l.get('objId') === objId)

			if (aLink.get('orderIndex') === bLink.get('orderIndex')) {
				return 0
			} else {
				return aLink.get('orderIndex') - bLink.get('orderIndex')
			}
		})

		dispatch({ type: ATTACHMENTS_FETCH, payload: { objId, attachments: documents } })
		callback && callback(documents)
	}
}

export function deleteAttachedDocument(attachmentId, objType, objId, callback, softError) {
	return function (dispatch) {
		return req
			.delete(`/documents/documents/external/${objType}/${objId}/${attachmentId}`)
			.then(() => {
				dispatch({ type: ATTACHMENTS_DELETE, payload: { objId, attachmentId } })
				callback && callback()
			})
			.catch((err) => {
				console.log(err)
				!softError && dispatch(documentsError('documents.error.delete_document'))
			})
	}
}

export function deleteAttachedDocumentPublic(attachmentId, objType, objId, companyId, callback) {
	return function (dispatch) {
		return req
			.delete(`/documents/public/documents/${objType}/${objId}/${attachmentId}?companyId=${companyId}`)
			.then(() => {
				dispatch({ type: ATTACHMENTS_DELETE, payload: { objId, attachmentId } })
				callback && callback()
			})
			.catch(() => {
				dispatch(documentsError('documents.error.delete_document'))
			})
	}
}

export function selectAttachment(objId, attachmentId) {
	return function (dispatch) {
		dispatch({ type: ATTACHMENTS_SELECT, payload: { objId, attachmentId } })
	}
}

export function unselectAttachment() {
	return function (dispatch) {
		dispatch({ type: ATTACHMENTS_UNSELECT })
	}
}

export function socketEventAttachments(eventObj) {
	const { eventName, objId, metadata, data } = eventObj

	return function (dispatch) {
		switch (eventName) {
			case EVENT_TYPE_ATTACHED_DOCUMENT_CREATE:
			case EVENT_TYPE_ATTACHED_DOCUMENT_LINKED:
			case EVENT_TYPE_ATTACHED_DOCUMENT_UPDATE:
			case EVENT_TYPE_ATTACHED_DOCUMENT_DELETE: {
				dispatch(setLiveRequest(['attachments', eventName], { r: true, objId, metadata }))
				dispatch(resetLiveRequest(['attachments', eventName]))
				break
			}
		}
	}
}
