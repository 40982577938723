import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import { FileIcon, defaultStyles } from 'react-file-icon'

const mapPcFormatsToMac = {
	['numbers']: 'xls',
	['pages']: 'doc'
}

export default class CustomFileIcon extends PureComponent {
	static propTypes = {
		ext: string
	}

	static defaultProps = {
		ext: 'bin'
	}

	render = () => {
		let { ext } = this.props
		if (!ext) {
			ext = CustomFileIcon.defaultProps.ext
		}
		let defaultStyling = defaultStyles[ext]

		if (!defaultStyling) {
			for (const [macFormats, altStyleFormat] of Object.entries(mapPcFormatsToMac)) {
				if (!macFormats.includes(ext)) {
					continue
				}

				defaultStyling = defaultStyles[altStyleFormat]
			}
		}

		ext = ext.substring(0, 4)

		if (defaultStyling) {
			const { type } = defaultStyling

			if (type === 'image') {
				defaultStyling.color = 'papayawhip'
			} else if (type === 'video') {
				defaultStyling.color = 'lavender'
			}

			return <FileIcon {...defaultStyling} extension={ext} labelUppercase={true} />
		}

		// Generic
		return <FileIcon extension={ext} labelUppercase={true} />
	}
}
