import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import Text from '../../shared/text/text'

const StyledTopicNotesPanel = styled.div`
	background-color: ${(props) => props.theme.colors.solitudeLight};
`
const StyledTopicNotesPanelHeader = styled.div`
	padding: ${(props) => props.theme.spacing[3]};
	border-bottom: none;
	border-left: 1px solid ${(props) => props.theme.colors.buttonBorderGrey};
	border-right: 1px solid ${(props) => props.theme.colors.buttonBorderGrey};
	border-top: 1px solid ${(props) => props.theme.colors.buttonBorderGrey};
	display: flex;
`

const StyledTopicNotesPanelLeftComponent = styled.div`
	display: flex;
	min-width: 0;
`

const StyledTopicNotesPanelTitle = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

const StyledTopicNotesPanelBody = styled.div`
	height: auto;
`

export default class TopicNotesPanel extends PureComponent {
	static propTypes = {
		titleTid: string
	}

	render = () => {
		const { children, titleTid } = this.props

		return (
			<StyledTopicNotesPanel>
				<StyledTopicNotesPanelHeader>
					<StyledTopicNotesPanelLeftComponent>
						<StyledTopicNotesPanelTitle>
							<Text tid={titleTid} />
						</StyledTopicNotesPanelTitle>
					</StyledTopicNotesPanelLeftComponent>
				</StyledTopicNotesPanelHeader>
				<StyledTopicNotesPanelBody>{children}</StyledTopicNotesPanelBody>
			</StyledTopicNotesPanel>
		)
	}
}
