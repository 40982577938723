import React from 'react'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import styled from '@mui/styled-engine'
import {NUMBER_FORMAT_CURRENCY, NUMBER_FORMAT_INTEGER} from '/shared/constants'

const StyledNumber = styled('span')(({negative, theme}) => {
	return {
		color: negative ? theme.palette.error.main : theme.palette.text.primary
	}
})

function FinancialRenderer({data,value, valueFormatted}) {
	if (typeof value === 'undefined') {
		return null
	}

	if (typeof value !== 'number') {
		return '-'
	}

	return (
		<StyledNumber negative={value < 0}>{valueFormatted}</StyledNumber>
	)
}

export {FinancialRenderer}
