import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, bool, string, oneOf } from 'prop-types'
import { Map } from 'immutable'
import { map } from 'react-immutable-proptypes'
import documentsHelper from '../../../components/helpers/documents.helper'
import { Margin } from 'styled-components-spacing'

import StepsModal from '../../../dumb-components/shared/modal/steps-modal'
import ModalInnerWrapper from '../../../dumb-components/shared/modal/modal-inner-wrapper'
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton, OutlinedButton } from '../../../dumb-components/shared/button-v2'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'
import { ButtonTransparentIcon } from '../../../dumb-components/shared/button'

import DocumentsSortListContainer from './documents-sort-list.container'

import GenerateMergedDocument from '../../../dumb-components/documents/request-esign/generate-merged-document'
import GeneratePdfAndNotifyComboView from '../../../dumb-components/documents/request-esign/generate-pdf-and-notify-combo-view'
import { TAB_TEAMS } from '../../shared/select-user-modal/select-user-modal.constants'
import SimpleDarkInfoPanelWithControls from '../../../dumb-components/shared/dark-info-panel/simple-dark-info-panel-with-controls'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import { validateEmail } from '../../../modules/validation.module'

import {
	saveDocumentToMerge,
	mergeDocuments,
	downloadDocument,
	removeMergedDocument,
	updateIndividualsToShareWith
} from '../../../actions/documents.actions'
import { __DELETE__, OBJ_TYPE_MEETING, OBJ_TYPE_DOCUMENT } from '/shared/constants'
import { PeopleModalProvider } from '../../shared/people-modal/people-modal.provider'
import { getDefaultTabs, PeopleModalHeader } from '../../shared/people-modal/people-modal-header'
import { TeamsAndInvestorsListContainer } from '../../shared/people-modal/teams-and-investors-list/teams-and-investors-list.container'
import { CompanyContactsGrid } from '../../shared/people-modal/company-contacts/CompanyContactsGrid'
import { PersonalContactsGrid } from '../../shared/people-modal/personal-contacts/PersonalConatctsGrid'

class RequestEsignAdvancedModalContainer extends Component {
	state = {
		step: 0,
		generateMergedIsLoading: true,
		generateMergedError: false,
		notifyUsersActiveTab: TAB_TEAMS
	}

	static propTypes = {
		data: map,
		onChange: func,
		onCancel: func,
		onSave: func,
		skipFilesView: bool,
		modalTitle: string,
		saveBtnTid: string,
		rightColumnInfoTextTid: string,
		objType: oneOf([OBJ_TYPE_DOCUMENT, OBJ_TYPE_MEETING]).isRequired
	}

	static defaultProps = {
		modalTitle: 'esign.advanced_modal.title',
		saveBtnTid: 'documents.document.esign.request.modal.confirm_btn',
		rightColumnInfoTextTid: 'esign.review_esign_advanced.notify.users_alert'
	}

	componentDidMount = () => {
		const { skipFilesView } = this.props

		if (skipFilesView) {
			this.setState({ step: 1, generateMergedIsLoading: false })
		}
	}

	componentDidUpdate = (prevProps) => {
		const { skipFilesView, docUsersToNotify, onChange, data } = this.props

		if (prevProps.skipFilesView !== skipFilesView) {
			this.setState({ step: 1, generateMergedIsLoading: false })
		}

		if (prevProps.docUsersToNotify !== docUsersToNotify) {
			onChange(data.set('usersToNotify', docUsersToNotify))
		}
	}

	getToBeMergedDocuments = () => {
		const { documentsToMerge } = this.props
		return documentsToMerge
			.filter((doc) => doc.getIn(['metadata', 'toBeMerged']))
			.sort((a, b) => a.get('orderIndexMergeDocuments') - b.get('orderIndexMergeDocuments'))
	}

	getToBeMergedDocumentIds = () => {
		const toBeMergedDocuments = this.getToBeMergedDocuments()
		return toBeMergedDocuments.map((doc) => doc.get('id'))
	}

	mergeDocument = () => {
		const { mergeDocuments, primaryDocument } = this.props
		const documentIds = this.getToBeMergedDocumentIds()

		if (documentIds.size === 1) {
			this.setState({ step: 1, generateMergedIsLoading: false })
			return
		}

		this.setState({
			step: 1,
			generateMergedIsLoading: true,
			generateMergedError: false
		})

		mergeDocuments(
			documentIds,
			primaryDocument.get('id'),
			() => {
				// Success callback
				this.setState({
					generateMergedIsLoading: false,
					generateMergedError: false
				})
			},
			() => {
				// Error callback
				this.setState({
					generateMergedIsLoading: false,
					generateMergedError: true
				})
			}
		)
	}

	nextStep = () => {
		this.setState({ step: this.state.step + 1 })
	}

	goBackFromPeopleList = () => {
		const { onChange, meetingId, primaryDocument, updateIndividualsToShareWith } = this.props
		let { data } = this.props
		let usersToNotify = data.get('usersToNotify')
		const documentId = primaryDocument.get('id')
		const shareWith = primaryDocument.get('shareWith', Map())

		usersToNotify = usersToNotify.filter((user) => {
			const isGuest = user.get('isGuest')
			const name = user.get('name')
			const email = user.get('email')

			if (isGuest && (!name || !validateEmail(email))) {
				return false
			}

			return true
		})

		shareWith.forEach((user) => {
			const userId = user.get('userId')
			const userInUsersToNotify = usersToNotify.has(userId)

			if (!userInUsersToNotify) {
				usersToNotify = usersToNotify.set(userId, __DELETE__)
			}
		})

		this.setState({
			step: this.state.step - 1,
			notifyUsersActiveTab: TAB_TEAMS
		})

		updateIndividualsToShareWith({
			documentId,
			meetingId,
			individuals: usersToNotify
		})

		// Remove delete
		usersToNotify = usersToNotify.filter((user) => user !== __DELETE__)

		data = data.set('usersToNotify', usersToNotify)
		onChange(data)
	}

	toggleToBeMerged = (doc, value) => {
		const { saveDocumentToMerge } = this.props
		const docToSave = doc.setIn(['metadata', 'toBeMerged'], value)
		saveDocumentToMerge(docToSave)
	}

	getFileCanBeOpenedInBrowser = (fileReference = Map()) => {
		const ext = fileReference.get('ext')
		const size = fileReference.get('size')
		return documentsHelper.isViewableInBrowser(fileReference, ext, size)
	}

	getMergedFileData = () => {
		const { primaryDocument, documentsToMerge } = this.props
		const hasMergedDocument = primaryDocument.hasIn(['mergedDocumentData', 'fileReference'])
		const documentsToBeMerged = documentsToMerge.filter((doc) => doc.getIn(['metadata', 'toBeMerged']) === true)
		let documentId = primaryDocument.get('id')
		let openInViewer
		let fileReference

		// Document not merged, document not primary document.
		// Only one file choosen but it is not the protocol file
		if (documentsToBeMerged.size === 1) {
			const onlyDocToMerge = documentsToBeMerged.first()
			fileReference = onlyDocToMerge.get('file')
			openInViewer = this.getFileCanBeOpenedInBrowser(fileReference)
			documentId = onlyDocToMerge.get('id')
		} else if (hasMergedDocument) {
			// Document merged
			fileReference = primaryDocument.getIn(['mergedDocumentData', 'fileReference'])
			openInViewer = this.getFileCanBeOpenedInBrowser(fileReference)
		} else {
			// Document not merged eg only protcol selected
			fileReference = primaryDocument.get('file')
			openInViewer = this.getFileCanBeOpenedInBrowser(fileReference)
		}

		return { openInViewer, documentId, fileReference, hasMergedDocument }
	}

	openPreviewDocument = () => {
		const { downloadDocument, companyId } = this.props
		const { openInViewer, documentId, hasMergedDocument } = this.getMergedFileData()

		downloadDocument({ documentId, companyId, openInViewer, getMergedVersion: hasMergedDocument })
	}

	goToMergeAttachmentsStep = () => {
		const { removeMergedDocument, primaryDocument } = this.props

		removeMergedDocument(primaryDocument.get('id'))
		this.setState({ step: 0 })
	}

	modifyUsersToNotify = (usersToNotify) => {
		let { data, onChange } = this.props
		data = data.set('usersToNotify', usersToNotify)
		onChange(data)
	}

	goToUsersModal = (tabToSelect = TAB_TEAMS) => {
		this.setState({ step: 2, notifyUsersActiveTab: tabToSelect })
	}

	renderMergeAttachmentsFooter = () => {
		const { onCancel, documentsToMerge } = this.props
		const toBeMergedDocumentIds = this.getToBeMergedDocumentIds()
		const docIsBeingGenerated = documentsToMerge.some((doc) => doc.getIn(['metadata', 'isBeingGenerated']))
		const proceedButtonTid =
			toBeMergedDocumentIds.size <= 1 ? 'next' : 'esign.request_esign_advanced.merge.btn.proceed.create_pdf'
		let proceedBtnDisabled
		let proceedTooltipTid

		if (!toBeMergedDocumentIds.size) {
			proceedBtnDisabled = true
			proceedTooltipTid = 'esign.request_esign_advanced.merge.btn.tooltip.no_docs_selected'
		} else if (docIsBeingGenerated) {
			proceedBtnDisabled = true
			proceedTooltipTid = 'esign.request_esign_advanced.merge.btn.tooltip.generating_pdf'
		}

		return (
			<FooterRightControls>
				<Tooltip tid={proceedTooltipTid} delayShow='instant' active={proceedBtnDisabled}>
					<TransparentButton
						tid={proceedButtonTid}
						onClick={this.mergeDocument}
						disabled={proceedBtnDisabled}
					/>
				</Tooltip>
				<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={onCancel} />
			</FooterRightControls>
		)
	}

	getSteps = () => {
		const {
			data,
			onChange,
			onCancel,
			renderShareWithComponent,
			onSave,
			skipFilesView,
			saveBtnTid,
			modalTitle,
			rightColumnInfoTextTid
		} = this.props
		const { step } = this.state
		const { generateMergedIsLoading, generateMergedError, notifyUsersActiveTab } = this.state
		const sendSignRequestIsLoading = data.get('isLoading')
		let toBeMergedDocuments
		let mergedDocumentFileReference

		// Just performance optimisation
		if (step === 1) {
			toBeMergedDocuments = this.getToBeMergedDocuments()
			const { fileReference } = this.getMergedFileData()
			mergedDocumentFileReference = fileReference
		}

		return [
			// Document list
			{
				body: (
					<ModalInnerWrapper>
						<DocumentsSortListContainer onChange={this.toggleToBeMerged} />
					</ModalInnerWrapper>
				),
				footer: {
					component: this.renderMergeAttachmentsFooter()
				}
			},

			// PDF preview and sharing
			{
				body: (
					<GeneratePdfAndNotifyComboView>
						<GenerateMergedDocument
							isLoading={generateMergedIsLoading}
							isError={generateMergedError}
							onPreview={this.openPreviewDocument}
							onRetry={this.mergeDocument}
							affectedDocuments={toBeMergedDocuments}
							fileReference={mergedDocumentFileReference}
						/>

						<>
							<SimpleDarkInfoPanelWithControls
								tid={rightColumnInfoTextTid}
								rightControlsComponent={
									<>
										<OutlinedButton icon='faPlus' onClick={this.nextStep} />

										<DropdownMenuContainer
											halignMenu='right'
											renderRaw={<OutlinedButton icon='faEllipsisV' leftMargin />}>
											<DropdownIconItem tid='generic.edit' icon='faCog' onClick={this.nextStep} />
										</DropdownMenuContainer>
									</>
								}
							/>

							<Margin top={4}>
								{renderShareWithComponent &&
									renderShareWithComponent({ goToUsersModal: this.goToUsersModal })}
							</Margin>
						</>
					</GeneratePdfAndNotifyComboView>
				),
				footer: {
					leftComponent: !skipFilesView ? (
						<ButtonTransparentIcon
							onClick={this.goToMergeAttachmentsStep}
							icon='faLongArrowLeft'
							size='xl'
						/>
					) : null,
					component: (
						<FooterRightControls>
							<Tooltip
								active={generateMergedIsLoading}
								delayShow='instant'
								tid='esign.request_esign_advanced.merge.btn.tooltip.generating_pdf'>
								<TransparentButton
									tid={saveBtnTid}
									mode='primary'
									onClick={onSave}
									disabled={generateMergedIsLoading}
									isLoading={sendSignRequestIsLoading}
								/>
							</Tooltip>

							<TransparentButton
								tid='generic.form.cancel'
								textColor='midGrey'
								onClick={onCancel}
								disabled={sendSignRequestIsLoading}
							/>
						</FooterRightControls>
					)
				}
			},

			// People modal
			{
				header: {
					component: <PeopleModalHeader title={modalTitle} tabs={getDefaultTabs()} />
				},
				body: (
					<ModalInnerWrapper noScrollView fullHeight>
						{step === 2 && (
							<>
								<TeamsAndInvestorsListContainer />
								<CompanyContactsGrid />
								<PersonalContactsGrid />
							</>
						)}
					</ModalInnerWrapper>
				),
				footer: {
					leftComponent: (
						<ButtonTransparentIcon onClick={this.goBackFromPeopleList} icon='faLongArrowLeft' size='xl' />
					)
				}
			}
		]
	}

	render = () => {
		const { modalTitle, docUsersToNotify } = this.props

		return (
			<PeopleModalProvider values={docUsersToNotify} onChange={this.modifyUsersToNotify}>
				<StepsModal
					isOpen={true}
					steps={this.getSteps()}
					step={this.state.step}
					transformless={this.state.step === 0} // Fix for missplaced drag and drop
					hSize='xl'
					title={modalTitle}
				/>
			</PeopleModalProvider>
		)
	}
}

const mapStoreToProps = (store, ownProps) => {
	const isMeeting = ownProps.objType === OBJ_TYPE_MEETING

	return {
		documentsToMerge: store.documents.get('documentsToMerge'),
		primaryDocument: store.documents.get('document'),
		companyId: store.company.company.id,
		docUsersToNotify: store.documents.getIn(['document', 'shareWith'], Map()) || Map(),
		meetingId: isMeeting ? store.meetings.getIn(['meeting', 'id']) : null
	}
}

const mapActionsToProps = {
	saveDocumentToMerge,
	mergeDocuments,
	downloadDocument,
	removeMergedDocument,
	updateIndividualsToShareWith
}

export default connect(mapStoreToProps, mapActionsToProps)(RequestEsignAdvancedModalContainer)
