import {
	SET_STRIPE_SETUP_INTENT_CLIENT_SECRET,
	SET_STRIPE_PRODUCTS,
	SET_STRIPE_SUBSCRIPTION_DATA
} from '../actions/types'

export const subscriptionStatuses = {
	INCOMPLETE: 'incomplete',
	INCOMPLETE_EXPIRED: 'incomplete_expired',
	TRIALING: 'trialing',
	ACTIVE: 'active',
	PAST_DUE: 'past_due',
	CANCELED: 'canceled',
	UNPAID: 'unpaid'
}

const INITIAL_STATE = {
	products: [],
	setupIntentClientSecret: '',
	subscription: null
}

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_STRIPE_SETUP_INTENT_CLIENT_SECRET:
			return { ...state, setupIntentClientSecret: action.setupIntentClientSecret }
		case SET_STRIPE_PRODUCTS:
			return { ...state, products: action.products }
		case SET_STRIPE_SUBSCRIPTION_DATA:
			return { ...state, subscription: action.subscription }
		default:
			return state
	}
}
