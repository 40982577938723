import { fromJS, List, Map } from 'immutable'
import { DEPOS_LIST, DEPO_CREATE, DEPO_UPDATE, DEPO_DELETE, COMPANY_RESET_REDUCER } from '../actions/types'

const INITIAL_STATE = fromJS({
	depos: List(),
	deposIdToNameMap: Map()
})

const getId = (state, id) => {
	return state.get('depos').findIndex((depo) => depo.get('id') === id)
}

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case DEPOS_LIST: {
			state = state.set('depos', payload)

			payload?.forEach((depo) => {
				state = state.setIn(['deposIdToNameMap', depo.get('id')], depo.get('name'))
			})

			return state
		}
		case DEPO_CREATE: {
			state = state.update('depos', (depos) => {
				return depos.push(payload)
			})

			state = state.setIn(['deposIdToNameMap', payload.get('id')], payload.get('name'))
			return state
		}
		case DEPO_UPDATE:
			return state.setIn(['depos', getId(state, payload.get('id'))], payload)
		case DEPO_DELETE: {
			const isDeleted = payload.get('isDeleted')
			if (isDeleted) {
				return state.setIn(['depos', getId(state, payload.get('id'))], payload)
			} else {
				return state.removeIn(['depos', getId(state, payload.get('id'))])
			}
		}
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}
	}
	return state
}
