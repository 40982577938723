import React, { useContext, useEffect, useState } from 'react';
import { string, bool, func, node } from 'prop-types';
import MUIDialog from '../../../../mui-components/dialog/mui-dialog';
import { useTranslation } from 'react-i18next';
import { useFormState, useForm } from 'react-final-form';
import Icon from '../../../../dumb-components/shared/icon/icon';
import Text from '../../../shared/text/text';
import { FOOTER_SAVE_BUTTON_TOOLTIP_STATES } from '../../../../constants/add-transaction';
import { MenuItem, ListItemIcon, ListItemText, Divider, Box } from '@mui/material';
import { InvestmentsContext } from '../../../../containers/investments/investment.container';
import { HELPSCOUT_ADD_TRANSACTION_MODAL_INFO } from '/shared/constants';

const AddTransactionDialog = ({
	onClose,
	isEditTransactionModalOpen,
	onConfirm,
	disabledConfirm,
	onDelete,
	onUpdate,
	tooltipActiveState,
	children,
	isPreview,
	onClosePreview
}) => {
	const { selectedPersonalInvestment, selectedInvestment } = useContext(InvestmentsContext);

	const form = useForm();
	const { t } = useTranslation();

	const [formModifiedValues, setFormModifiedValues] = useState();

	const companyName = selectedInvestment
		? selectedInvestment.getIn(['investedInCompanyInformation', 'name'])
		: selectedPersonalInvestment.getIn(['investedInCompanyInformation', name]);

	useEffect(() => {
		if (isPreview) {
			setFormModifiedValues(form.getState().modified);
		}
	}, [form.getState().modified]);

	useEffect(() => {
		const isModified =
			formModifiedValues &&
			Object.keys(formModifiedValues).find((key) => {
				if (key === 'link') {
					return false;
				} else if (formModifiedValues[key] === true) {
					return true;
				}
			});

		if (
			isPreview &&
			form.getState().values?.link?.transactionId &&
			!form.getState().values.link.isDirty &&
			isModified
		) {
			form.registerField('link', (fieldState) => {
				const { change } = fieldState;

				change({
					transactionId: form.getState().values.link.transactionId,
					isDirty: true
				});
			});

			form.mutators.setIsDirty();
		}
	}, [formModifiedValues]);

	const onConfirmButtonClick = (popupState) => {
		if (typeof onUpdate === 'function' && isEditTransactionModalOpen) {
			onUpdate(form.getState().values);
			typeof onClose === 'function' && onClose();
			popupState && typeof popupState.close === 'function' && popupState.close();
		}

		if (typeof onConfirm === 'function' && form.getState().values && !isEditTransactionModalOpen) {
			onConfirm(form.getState().values, form.getState().errors);
		}
	};

	const _onDelete = (popupState) => {
		onDelete(() => {
			typeof onClose === 'function' && onClose();
			popupState && typeof popupState.close === 'function' && popupState.close();
		});
	};

	const _onClose = (popupState) => {
		typeof onClose === 'function' && onClose();
		popupState && typeof popupState.close === 'function' && popupState.close();
	};

	const test = null;

	return (
		<MUIDialog
			isOpen={true}
			flexContent
			onClose={() => {
				if (isPreview) {
					onClosePreview();
					return;
				}
				onClose();
			}}
			variant='scrollview'
			onConfirm={onConfirmButtonClick}
			optionsButton={isEditTransactionModalOpen && !isPreview}
			optionsButtonProps={
				isEditTransactionModalOpen &&
				!isPreview && {
					tid: 'investments.my_records.modal.edit_transaction.footer.options'
				}
			}
			optionsButtonMenu={({ popupState }) => [
				<MenuItem onClick={() => _onClose(popupState)} key={'first'}>
					<ListItemIcon>
						<Icon icon='faTimes' size='sml' color={'midGrey'} />
					</ListItemIcon>
					<ListItemText>{t('modal.footer.dropdown_menu.cancel_and_close')}</ListItemText>
				</MenuItem>,
				<Divider sx={{ mt: '8px', mb: '8px' }} key={'second'} />,
				<MenuItem onClick={() => _onDelete(popupState)} key={'third'}>
					<ListItemIcon>
						<Icon icon='faTrashAlt' size='sml' color={'midGrey'} />
					</ListItemIcon>
					<ListItemText>
						{t('investments.my_records.modal.edit_transaction.footer.options.delete_transaction')}
					</ListItemText>
				</MenuItem>
			]}
			paperProps={{ sx: { minHeight: '80vh', maxHeight: '80vh' } }}
			tooltipStates={FOOTER_SAVE_BUTTON_TOOLTIP_STATES}
			tooltipActiveState={tooltipActiveState}
			companyName={companyName}
			title={
				isEditTransactionModalOpen
					? t('investments.my_records.modal.edit_transaction.title')
					: t('investments.my_records.modal.add_transaction.title')
			}
			infoArticle={HELPSCOUT_ADD_TRANSACTION_MODAL_INFO}
			maxWidth={'xl'}
			fullWidth
			disabledConfirm={disabledConfirm}
			confirmButtonTid={'generic.form.save'}
			closeButtonTid={
				isPreview
					? 'investments.my_records.modal.add_transaction.preview.footer.close_preview'
					: 'modal.footer.dropdown_menu.cancel_and_close'
			}
			leftSideLinkValidation={
				<>
					{form.getState() && form.getState().values.transactionType && (
						<Box sx={{ display: 'flex', flex: 1 }}>
							{form.getState().values?.link?.transactionId ||
							form.getState().values?.link?.isDirty === true ? (
								<Text
									tid={
										form.getState().values.link.isDirty &&
										!form.getState().values.link.transactionId
											? 'investments.add_transaction_modal.footer.notification_text.no_longer_verified.company_data_changed'
											: form.getState().values.link.isDirty
											? 'investments.add_transaction_modal.footer.notification_text.no_longer_verified.user_data_changed'
											: 'investments.add_transaction_modal.footer.notification_text.verified'
									}
									color={form.getState().values.link.isDirty === true ? 'red' : 'green'}
									values={{ companyName: companyName }}
								/>
							) : (
								<Text tid={'investments.add_transaction_modal.footer.notification_text.not_verified'} />
							)}
						</Box>
					)}
				</>
			}>
			{children}
		</MUIDialog>
	);
};

AddTransactionDialog.propTypes = {
	onClose: func,
	isEditTransactionModalOpen: bool,
	onConfirm: func,
	disabledConfirm: bool,
	onDelete: func,
	onUpdate: func,
	tooltipActiveState: string,
	children: node,
	isPreview: bool,
	onClosePreview: func
};

AddTransactionDialog.defaultProps = {};

export default AddTransactionDialog;
