import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool } from 'prop-types'

import PhysicalLocationContainer from './physical.container'
import WebLocationContainer from './web.container'
import PhoneLocationContainer from './phone.container'

class LocationsContainer extends Component {
	static propTypes = {
		marginBottom: bool,
		withoutPanel: bool,
		readOnly: bool
	}

	render = () => {
		const { marginBottom, withoutPanel, readOnly } = this.props
		const commonProps = {
			marginBottom,
			withoutPanel,
			readOnly
		}

		return (
			<>
				<PhysicalLocationContainer {...commonProps} />
				<WebLocationContainer {...commonProps} />
				<PhoneLocationContainer {...commonProps} />
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(LocationsContainer)
