import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { string, object, array, bool, node, number } from 'prop-types'
import { fromJS, Map, List } from 'immutable'
import debounce from 'lodash/debounce'
import {
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	resetEmissionLevelingTransaction
} from '../../../../actions/transaction.actions'
import { addErrorNotification, addInfoNotification } from '../../../../actions/notify.actions'
import { getFullShareTypeLabel } from '../../../helpers/shares'
import formatNumber from '../../../../modules/format-number'

const transactionType = 'EMISSION-LEVELING'

const de = debounce((func, type, transaction) => {
	func(type, transaction.toJS())
}, 1000)

class EmissionLeveling extends Component {
	static propTypes = {
		shareDiff: object,
		latestTransaction: object,
		allowAdjustment: bool,
		afterValue: number,
		beforeValue: number,
		readonly: bool,
		increaseDiff: bool,
		hideEmptyShares: bool
	}

	static defaultProps = {
		shareDiff: Map(),
		latestTransaction: null,
		allowAdjustment: false,
		readonly: false,
		increaseDiff: true,
		hideEmptyShares: false
	}

	componentDidMount = () => {}

	onChange = (investmentId, shareType, val) => {
		let { shareDiff } = this.props
		let shareDiffArr = shareDiff.get(investmentId, List())
		const shareDiffIndex = shareDiffArr.findIndex((obj) => {
			return obj.get('type') === shareType
		})

		if (shareDiffIndex >= 0) {
			shareDiffArr = shareDiffArr.setIn([shareDiffIndex, 'diff'], val ? parseInt(val) : 0)
		} else {
			shareDiffArr = shareDiffArr.push(Map())
		}

		shareDiff = shareDiff.set(investmentId, shareDiffArr)
		this.props.onChange('shareDiff', shareDiff)
	}

	renderShareType = (investmentId, shareType, index) => {
		const { latestTransaction, allowAdjustment, readonly, hideEmptyShares } = this.props
		const DISALLOWED_SYMBOLS = ['-', '+', ',', '.']
		if (!latestTransaction) {
			return null
		}

		const shareTypeName = getFullShareTypeLabel(shareType.get('type'))
		const diff = shareType.get('diff')
		const storedShareType = latestTransaction.getIn(['balances', investmentId, 'types'], List()).find((obj) => {
			return obj.get('type') === shareType.get('type')
		})
		const numOfShares = storedShareType && storedShareType.get('shares')

		// Don't render if hideEmptyShares is TRUE and user owns 0 of a certain type share
		if (hideEmptyShares && numOfShares < 1) {
			return null
		}

		if (allowAdjustment) {
			return (
				<div className='list__item' key={index}>
					<div className='list__item__body'>
						<span className='list__item__text'>{shareTypeName}</span>
					</div>
					<div className='list__item__body'>
						<span className='text--align-right'>{numOfShares ? formatNumber(numOfShares) : '0'}</span>
					</div>
					<div className='list__item__text-area list__item__text-area--w120'>
						<span className='list__item__input list__item__input--pad-right text--align-right'>
							<input
								type='number'
								step={1}
								min={0}
								value={diff || ''}
								className='form-control box-w90 box-inline'
								onKeyDown={(event) => {
									if (DISALLOWED_SYMBOLS.includes(event.key)) {
										event.preventDefault()
									}
								}}
								onChange={(event) => {
									this.onChange(investmentId, shareType.get('type'), event.target.value)
								}}
								disabled={readonly}
							/>
						</span>
					</div>
				</div>
			)
		} else {
			return (
				<div className='list__item' key={index}>
					<div className='list__item__body'>
						<span className='list__item__text'>{shareTypeName}</span>
					</div>
					<div className='list__item__body'>
						<span className='text--align-right'>{numOfShares}</span>
					</div>
					<div className='list__item__text-area list__item__text-area--w120'>
						<span className='list__item__input list__item__input--pad-right text--align-right'>{diff}</span>
					</div>
				</div>
			)
		}
	}

	renderInvestor = (shareTypes, investmentId) => {
		const { investors, increaseDiff } = this.props

		if (!investors) {
			return null
		}

		const investor = investors.find((inv) => {
			return inv.get('id') === investmentId
		})

		if (!investor) {
			return null
		}

		return (
			<div className={`i-panel`} key={investmentId}>
				<div className='i-panel__heading'>
					<h2 className='i-panel__title'>
						{investor.getIn(['investorInformation', 'name'])}{' '}
						<span className='text--muted'>{investor.getIn(['investorInformation', 'id'])}</span>
					</h2>
				</div>
				<div className={`i-panel__body`}>
					<div className='form-group col-md-12'>
						<div className='list__list-header'>
							<div className='list__list-header__body list__list-header__text-area--pad-lft list__list-header__text-area--pad-right'>
								<FormattedMessage id='shares.transactions.split.class_of_shares' />
							</div>
							<div className='list__list-header__body list__list-header__text-area--pad-right'>
								<span className='text--align-right'>
									<FormattedMessage id='shares.transactions.split.total_owned' />
								</span>
							</div>
							<div className='list__list-header__text-area list__list-header__text-area--w120 list__list-header__text-area--pad-right list__list-header__text-area--right'>
								<FormattedMessage
									id={
										increaseDiff
											? 'shares.transactions.split.increase_by'
											: 'shares.transactions.split.decrease_by'
									}
								/>
							</div>
						</div>
						<div className='list list--striped list--table'>
							{shareTypes.map(this.renderShareType.bind(this, investmentId))}
						</div>
					</div>
				</div>
			</div>
		)
	}

	render = () => {
		const { shareDiff, errors, name } = this.props

		const hasError = errors && name ? errors.get(name, List()).size > 0 : false

		return (
			<div className={`${hasError ? 'has-error' : ''}`}>
				{shareDiff && shareDiff.map(this.renderInvestor).toList()}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		tmpTransaction: state.transaction.getIn(['tmpTransaction', transactionType]),
		investors: state.investors.get('list')
	}
}

const mapActionsToProps = {
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	resetEmissionLevelingTransaction,
	addErrorNotification,
	addInfoNotification
}

export default connect(mapStateToProps, mapActionsToProps)(EmissionLeveling)
