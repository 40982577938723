import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GRID_MODAL } from '../../../constants/modals'
import { GRID_MODE_PANEL, GRID_MODE_MODAL } from '/shared/constants'
import { openModal } from '../../../actions/modals.actions'
import { withTranslation } from 'react-i18next'
import { InvestmentsContext } from '../../../containers/investments/investment.container'
import AgGrid from '../../../dumb-components/shared/ag-grid/ag-grid'
import ExportExcelButton from '../../../dumb-components/investment/shareholders/export-excel-button'
import Moment from '../../../modules/moment.module'
import localeFormatNumber from '../../../modules/format-number'
import { NUMBER_FORMAT_CURRENCY, NUMBER_FORMAT_PERCENTAGE, NUMBER_FORMAT_INTEGER } from '/shared/constants'
import { getShareTypeLabel } from '../../../components/helpers/shares'
import { List, Map } from 'immutable'
import { columnTypes } from '../../../dumb-components/shared/ag-grid/column-defs/column-defs'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import GridPanelRightButtons from '../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons'
import Text from '../../../dumb-components/shared/text/text'
import { MyNumberOfShares } from '../../../dumb-components/shared/ag-grid/tooltips/share-types/MyNumberOfShares'

const SharebookMyOwnershipGridContainer = ({ t, viaInvestmentId }) => {
	const { selectedInvestment } = useContext(InvestmentsContext)

	const gridRef = useRef()
	const [colDefs, setColDefs] = useState(null)
	const [colTypes, setColTypes] = useState(columnTypes())
	const [name, setName] = useState(null)
	const [rowData, setRowData] = useState(null)
	const companyName = useSelector(
		(state) =>
			state.capTableDetails.get('aliasOfTheInvestedCompany') ||
			state.capTableDetails.getIn([
				'combinedSingleInvestmentAndAllTransactions',
				'investedInCompanyInformation',
				'name'
			])
	)

	const gridOption = 'sharebookMyOwnership'

	const isGridModalOpen = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'options']) === gridOption
	})

	useEffect(() => {
		if (!selectedInvestment) {
			return null
		}
		setName(selectedInvestment.getIn(['investorInformation', 'name']))
		setColDefs(getColumnDefs())
		setRowData(getRowData())
	}, [selectedInvestment])

	const dispatch = useDispatch()

	const getRowData = useCallback(() => {
		let transactions = formattedTransactions()
		const shareTypes = selectedInvestment.getIn(['details', 'types'], List())

		const percent = !viaInvestmentId ? selectedInvestment.getIn(['rootDetails', 'ownershipPercentage'], 100) : 100
		const quote = percent / 100

		let newRowData = List()

		let numOfTotalShares = Map({ title: t('investments.sharebook.my_ownership.grid.row_title.shares_owned') })
		let ownershipPercentage = Map({
			title: t('investments.sharebook.my_ownership.grid.row_title.percentage_of_company_owned')
		})
		let myPostMoney = Map({
			title: t('investments.sharebook.my_ownership.grid.row_title.value_of_my_shares')
		})

		let shareTypeData = shareTypes.map((shareType) => {
			return Map({
				title: t('value_of_share_N').replace('{shareType}', getShareTypeLabel(shareType.get('type'))),
				type: shareType.get('type')
			})
		})

		transactions.forEach((transaction, index) => {
			const transactionDate = Moment(transaction.get('date')).format('L')
			const dateIndexKey = transactionDate + '#' + index

			numOfTotalShares = numOfTotalShares.set(
				dateIndexKey,
				localeFormatNumber(transaction.getIn(['balance', 'numOfTotalShares']) * quote, NUMBER_FORMAT_INTEGER)
			)

			ownershipPercentage = ownershipPercentage.set(
				dateIndexKey,
				localeFormatNumber(
					transaction.getIn(['balance', 'ownershipPercentage']) * quote,
					NUMBER_FORMAT_PERCENTAGE
				)
			)

			myPostMoney = myPostMoney.set(
				dateIndexKey,
				localeFormatNumber(transaction.getIn(['balance', 'myPostMoney']) * quote, NUMBER_FORMAT_CURRENCY)
			)

			const transactionShareTypes = transaction.getIn(['balance', 'types'], List())
			numOfTotalShares = numOfTotalShares.setIn(['types', dateIndexKey], transactionShareTypes)
			numOfTotalShares = numOfTotalShares.set(
				'bodyTid',
				'investments.share_register.my_ownership_grid.row.title.tooltip.MyNumBerOfShares'
			)

			shareTypes.forEach((shareType) => {
				const shareTypeInTransaction = transactionShareTypes.find(
					(transactionShareType) => shareType.get('type') === transactionShareType.get('type')
				)
				let latestPrice = shareTypeInTransaction ? shareTypeInTransaction.get('latestPrice') : null

				shareTypeData = shareTypeData.map((shareTypeDataItem) => {
					if (shareType.get('type') === shareTypeDataItem.get('type')) {
						latestPrice = latestPrice ? localeFormatNumber(latestPrice, NUMBER_FORMAT_CURRENCY) : '-'
						shareTypeDataItem = shareTypeDataItem.set(dateIndexKey, latestPrice)
					}
					return shareTypeDataItem
				})
			})
		})

		newRowData = newRowData.push(numOfTotalShares, ownershipPercentage, myPostMoney)
		newRowData = newRowData.concat(shareTypeData)

		return newRowData.toJS()
	})

	const formattedTransactions = () => {
		let transactions = selectedInvestment.get('transactions').reverse()

		if (!transactions) {
			return null
		}

		const fieldsToCompare = [
			['balance', 'numOfTotalShares'],
			['balance', 'ownershipPercentage'],
			['balance', 'myPostMoney']
		]

		let prevTransaction = null

		transactions = transactions.filter((transaction) => {
			let isEqualToPrev = true

			fieldsToCompare.forEach((fieldPath) => {
				if ((prevTransaction && prevTransaction.getIn(fieldPath)) !== transaction.getIn(fieldPath)) {
					isEqualToPrev = false
				}
			})
			prevTransaction = transaction
			return !isEqualToPrev
		})

		return transactions
	}

	const getColumnDefs = () => {
		let transactions = formattedTransactions()
		let colDefs = [
			{
				field: 'title',
				headerName: '',
				minWidth: 200,
				type: ['lockedFirstColumn', 'widthDefault']
			}
		]

		transactions.forEach((transaction, index) => {
			const transactionDate = Moment(transaction.get('date')).format('L')
			colDefs.push({
				field: transactionDate + '#' + index,
				headerName: transactionDate,
				minWidth: 200,
				type: 'rightAligned',
				tooltipComponent: MyNumberOfShares,
				tooltipField: transactionDate + '#' + index
			})
		})

		return colDefs
	}

	const exportAsExcel = useCallback(() => {
		gridRef.current.api.exportDataAsExcel()
	})

	const openGridModal = useCallback(() => {
		dispatch(openModal(GRID_MODAL, gridOption))
	})

	const modalLeftHeader = {
		tid: 'investments.sharebook.grid_panel.my_ownership.title'
	}

	const onGridReady = (event) => {
		const allColumns = event.columnApi.getAllGridColumns()
		const lastColumn = allColumns[allColumns.length - 1]

		event.api.ensureColumnVisible(lastColumn.colId)
		event.columnApi.resetColumnState()
	}

	return (
		<Card>
			<CardHeader
				title={
					<Text
						tid={
							viaInvestmentId
								? 'my_company_ledger'
								: 'investments.sharebook.grid_panel.my_ownership.title'
						}
						values={{ companyName: companyName, name: name }}
					/>
				}
				action={
					<GridPanelRightButtons
						exportAsExcel={exportAsExcel}
						openGridModal={openGridModal}
						downloadExcelTid={'investments.sharebook.dropdown_item.download_excel_list.my_ownership'}
						noFilter={true}
					/>
				}
			/>
			<CardContent variant='panel'>
				<AgGrid
					mode={isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL}
					isGridModalOpen={isGridModalOpen}
					rowData={rowData}
					columnDefs={colDefs}
					rightHeaderComponent={() => {
						return <ExportExcelButton exportAsExcel={exportAsExcel} noFilter={true} />
					}}
					modalLeftHeader={modalLeftHeader}
					gridRef={gridRef}
					columnTypes={colTypes}
					onGridReady={onGridReady}
				/>
			</CardContent>
		</Card>
	)
}

export default withTranslation()(SharebookMyOwnershipGridContainer)
