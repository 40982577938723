import React, { Component } from 'react'
import TinyMCEInput from 'react-tinymce-input'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import ReactCrop, { makeAspectCrop } from 'react-image-crop'
import Scrollbars from 'react-custom-scrollbars'

import Modal from '../../../dumb-components/shared/modal/modal'
import Button from '../../../dumb-components/shared/button/button'
import Box from '../../../dumb-components/shared/layout/box/box'
import { closeModal } from '../../../actions/modals.actions'
import { IMAGE_CROP_MODAL } from '../../../constants/modals'

class ImageCropModal extends Component {
	state = {
		crop: {},
		imageDoneLoading: false
	}

	onImageLoaded = (img) => {
		const { noSelection, aspectRatio } = this.props
		let { crop } = this.props

		if (crop) {
			crop = crop.toJS()
			var { x, y, width, height } = crop
		}

		this.setState({ imageDoneLoading: true })
		this.props.onImageDoneLoading()

		// Don't add selectionbox if image never been cropped
		if (crop && x == 0 && y == 0 && width == 100 && height == 100) {
			return
		}

		// Add aspect ratio if crop-object was provided as a prop
		if (crop) {
			crop.aspect = aspectRatio
			this.setState({ crop })
			return
		}

		if (noSelection) {
			return
		}

		// Set default state (selection in middle of the image)
		this.setState({
			crop: makeAspectCrop(
				{
					x: 37.5,
					y: 30,
					aspect: aspectRatio,
					width: 25
				},
				img.width / img.height
			)
		})
	}

	onSelectionChange = (crop) => {
		this.setState({ crop })
	}

	closeModal = () => {
		const { closeModal } = this.props

		this.setState({
			imageDoneLoading: false,
			crop: {}
		})

		closeModal()
	}

	save = () => {
		const { image } = this.props
		const props = this.state.crop
		let fd = new FormData()

		fd.append('image', image)

		/* Go through all the JSON fields and separate all of
		 * them into individual formdata fields
		 */
		Object.keys(props).forEach((key) => {
			if (Array.isArray(props[key])) {
				for (let i = 0; i < props[key].length; i++) {
					fd.append(key, props[key][i])
				}
			} else {
				fd.append(key, props[key])
			}
		})

		// Pass props back to image-crop
		this.props.onCrop(fd)
		this.closeModal()
	}

	buildQueryStr = () => {
		const { companyId, userId } = this.props

		let queryStr = ''
		if (companyId) {
			queryStr = `?companyId=${companyId}`
		}
		if (userId) {
			queryStr = `?userId=${userId}`
		}

		return queryStr
	}

	renderModalContent = () => {
		const { crop } = this.state
		const { companyId } = this.props
		let { image } = this.props

		if (!image) {
			return null
		}

		const queryStr = this.buildQueryStr()

		return (
			<Box alignItems='center' valign='center' fullHeight>
				<ReactCrop
					src={image.preview || `${image}${queryStr}`}
					onChange={this.onSelectionChange}
					crop={crop}
					minHeight={25}
					keepSelection={true}
					onImageLoaded={this.onImageLoaded}
					imageStyle={{ maxHeight: '436px' }}
				/>
			</Box>
		)
	}

	renderFooter = () => {
		const { imageDoneLoading } = this.state

		return (
			<div>
				<Button mode='primary' tid='generic.form.save' onClick={this.save} isLoading={!imageDoneLoading} />
				<Button tid='generic.form.cancel' onClick={this.closeModal} />
			</div>
		)
	}

	render = () => {
		const { activeModal } = this.props

		return (
			<Modal
				title='image.edit'
				hSize='xl'
				scrollableContent={false}
				isOpen={activeModal === IMAGE_CROP_MODAL}
				footerComponent={this.renderFooter()}>
				{this.renderModalContent()}
			</Modal>
		)
	}
}

function mapStateToProps(state) {
	const { modals } = state

	return {
		activeModal: modals && modals.getIn(['activeModal', 'name']),
		image: modals && modals.getIn(['activeModal', 'options', 'image']),
		crop: modals && modals.getIn(['activeModal', 'options', 'crop']),
		noSelection: modals && modals.getIn(['activeModal', 'options', 'noSelection']),
		aspectRatio: modals && modals.getIn(['activeModal', 'options', 'aspectRatio']),
		companyId: state.company?.company?.id,
		onCrop: modals && modals.getIn(['activeModal', 'options', 'onCrop']),
		onImageDoneLoading: modals && modals.getIn(['activeModal', 'options', 'onImageDoneLoading']),
		userId: modals && modals.getIn(['activeModal', 'options', 'userId'])
	}
}

const mapActionsToProps = {
	closeModal
}

export default connect(mapStateToProps, mapActionsToProps)(ImageCropModal)
