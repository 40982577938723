import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clearDocument } from '../../actions/documents.actions'
import DocumentHelper from '../../components/helpers/documents.helper'
import Content from '../../dumb-components/shared/layout/content/content'
import SplitView from '../../dumb-components/shared/layout/split-view/split-view'
import ScrollView from '../../dumb-components/shared/layout/scroll-view/scroll-view'
import DocumentListContainer from './document-list.container'
import DocumentUploadContainer from './documents-upload.container'
import ScrollViewHeader from '../../dumb-components/shared/scroll-view-header/scroll-view-header'
import FoldersContainer from './folders.container'
import { Margin } from 'styled-components-spacing'
import RightViewContainer from './right-view.container'
import DocumentFilterButtonContainer from './document-filter-button.container'
import Box from '../../dumb-components/shared/layout/box/box'
import DocumentsFilterByContainer from '../documents/documents-filter-by.container'
import DocumentsFilterAndSortContainer from './documents-filter-and-sort.container'
import MoveDocumentModalContainer from './documents-move-file.container'
import CopyDocumentToPersonalDocumentsModal from './copy-document-to-personal-documents.container'
import DocumentsSettingsBar from './document-settings-bar.container'
import InitDocumentStructureOnboardingModal from '../notifications/onboarding/modals/documents/init-document-structure.container'
import DocumentsDetails from './document-details.container'
import ConfirmContainer from '../shared/confirm.container'
import AddDocumentToCollectionModalContainer from './add-document-to-collection-modal.container'
import AddExistingDocumentToCollectionWarningModalContainer from './add-existing-document-to-collection-warning-modal.container'
import {
	CONFIRM_DELETE_DOCUMENT,
	ADD_DOCUMENT_TO_COLLECTION_MODAL,
	ADD_EXISTING_DOCUMENT_TO_COLLECTION_MODAL
} from '../../constants/modals'
import { closeModal } from '../../actions/modals.actions'
import { routeHoc } from '../../interfaces/router'
import { LOCATION_COMPANY_DOCUMENTS } from '/shared/constants'
import { getQuery } from '../../interfaces/history'

class Documents extends Component {
	state = {
		totalDocumentsFiltered: 0,
		totalDocumentsFilteredShown: 0,
		filterIsOpen: false,
		prevDocument: null
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('documents')
		return pathArray[0] + 'documents'
	}

	toggleAdvancedFilter = () => {
		const { clearDocument, document, location, history } = this.props
		const { filterIsOpen, prevDocument } = this.state
		const querystr = location.search
		const basePath = this.getComponentBasePath()

		// if there are no selected document in the list and there was before filter was open, we select that document again
		if (!document && prevDocument) {
			history.push({
				pathname: `${basePath}/${prevDocument.get('id')}`,
				search: querystr
			})
			this.setState({ prevDocument: null })
		} else {
			history.push({
				pathname: basePath,
				search: querystr
			})
			this.setState({ prevDocument: document })
		}

		clearDocument()
		this.setState({ filterIsOpen: !filterIsOpen })
	}

	closeAdvancedFilter = () => {
		this.setState({ filterIsOpen: false, prevDocument: null })
	}

	renderDocumentsLeftHeader = () => {
		const { totalDocumentsFiltered, totalDocumentsFilteredShown } = this.state
		const { location } = this.props
		const querystr = location.search
		const basePath = this.getComponentBasePath()
		const query = getQuery()

		return (
			<ScrollViewHeader>
				<DocumentsFilterByContainer
					totalDocuments={totalDocumentsFiltered}
					totalDocumentsShown={totalDocumentsFilteredShown}
					isSharedFromCompanyId={query.isSharedFromCompanyId}
				/>
				<Margin left={3}>
					<Box>
						<DocumentUploadContainer
							basePath={basePath}
							querystr={querystr}
							isSharedFromCompanyId={query.isSharedFromCompanyId}
						/>
						<DocumentFilterButtonContainer onClick={this.toggleAdvancedFilter} />
					</Box>
				</Margin>
			</ScrollViewHeader>
		)
	}

	renderLeftSplitView = () => {
		const {
			location,
			match: { params }
		} = this.props
		const querystr = location.search
		const basePath = this.getComponentBasePath()
		const query = getQuery()

		return (
			<ScrollView
				header={this.renderDocumentsLeftHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}>
				<DocumentsFilterAndSortContainer
					filterDocumentsChanged={(totalDocumentsFilteredShown, totalDocumentsFiltered) => {
						this.setState({ totalDocumentsFilteredShown, totalDocumentsFiltered })
					}}
					isSharedFromCompanyId={query.isSharedFromCompanyId}>
					<DocumentListContainer
						scrollbarRef={this.scrollbarRef}
						basePath={basePath}
						params={params}
						querystr={querystr}
						filterDocumentsChanged={(totalDocumentsFilteredShown, totalDocumentsFiltered) => {
							this.setState({ totalDocumentsFilteredShown, totalDocumentsFiltered })
						}}
						onDocumentClick={this.closeAdvancedFilter}
					/>
				</DocumentsFilterAndSortContainer>
			</ScrollView>
		)
	}

	renderRightSplitView = () => {
		const { location, match } = this.props
		const { filterIsOpen } = this.state
		const basePath = this.getComponentBasePath()

		return (
			<RightViewContainer
				location={location}
				params={match.params}
				basePath={basePath}
				filterIsOpen={filterIsOpen}
				onFilterClose={this.toggleAdvancedFilter}>
				{match.params.id && <DocumentsDetails match={match} location={location} history={this.props.history} />}
			</RightViewContainer>
		)
	}

	renderHeader = () => {
		const {
			match: { params }
		} = this.props
		const basePath = this.getComponentBasePath()

		return (
			<Box>
				<FoldersContainer basePath={basePath} params={params} />

				<DocumentsSettingsBar />
			</Box>
		)
	}

	render = () => {
		const {
			isConfirmDeleteModalOpen,
			closeModal,
			modalOptions,
			isAddDocumentToCollectionModalOpen,
			isAddExistingDocumentToCollectionWarningModalOpen
		} = this.props
		return (
			<Content header={this.renderHeader} tightenHeader>
				<SplitView leftComponent={this.renderLeftSplitView} rightComponent={this.renderRightSplitView} />

				<MoveDocumentModalContainer />
				<CopyDocumentToPersonalDocumentsModal />
				<InitDocumentStructureOnboardingModal />
				<ConfirmContainer
					title='documents.delete_document_alert.title'
					message='document.delete_document_alert.message.linked_document'
					onConfirm={modalOptions}
					onDecline={closeModal}
					confirmBtnTid='document.delete_document_alert.footer.btn.confirm'
					declineBtnTid='document.delete_document_alert.footer.btn.decline'
					isOpen={isConfirmDeleteModalOpen}
				/>
				{isAddDocumentToCollectionModalOpen && <AddDocumentToCollectionModalContainer />}
				{isAddExistingDocumentToCollectionWarningModalOpen && <AddExistingDocumentToCollectionWarningModalContainer />}
			</Content>
		)
	}
}

function mapStoreToProps(store) {
	return {
		document: store.documents.get('document'),
		company: store.company.company,
		userId: store.user.getIn(['userObj', 'id']),
		isConfirmDeleteModalOpen: store.modals.getIn(['activeModal', 'name']) === CONFIRM_DELETE_DOCUMENT,
		modalOptions: store.modals.getIn(['activeModal', 'options']),
		isAddDocumentToCollectionModalOpen:
			store.modals.getIn(['activeModal', 'name']) === ADD_DOCUMENT_TO_COLLECTION_MODAL,
		isAddExistingDocumentToCollectionWarningModalOpen:
			store.modals.getIn(['activeModal', 'name']) === ADD_EXISTING_DOCUMENT_TO_COLLECTION_MODAL
	}
}

const mapActionsToProps = {
	clearDocument,
	closeModal
}

export default connect(
	mapStoreToProps,
	mapActionsToProps
)(routeHoc(Documents, { routeName: LOCATION_COMPANY_DOCUMENTS }))
