import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import { Margin } from 'styled-components-spacing'
import styled from 'styled-components'

import Label from '../../shared/label/label'
import Input from '../../shared/input/input'
import Panel from '../../shared/panel/panel'
import ColoredContentWrapper from '../../shared/colored-content-wrapper/colored-content-wrapper'

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-top: ${(props) => props.theme.spacing[5]};
`

export default class ProxyPanel extends PureComponent {
	static propTypes = {
		name: string,
		email: string,
		ssn: string
	}

	render = () => {
		const { name, email, ssn } = this.props

		return (
			<Panel title='meetings.attendees.details.proxy_panel.title' mode='transparent' noHeaderPadding>
				<StyledColoredContentWrapper type='disabledLightGrey'>
					<Label tid='meetings.attendees.details.proxy_panel.name'>
						<Input fieldName='name' value={name} disabled />
					</Label>
					<Margin top={4}>
						<Label tid='meetings.attendees.details.proxy_panel.email'>
							<Input fieldName='email' value={email} disabled />
						</Label>
					</Margin>
					<Margin top={4}>
						<Label tid='meetings.attendees.details.proxy_panel.ssn'>
							<Input fieldName='ssn' value={ssn} disabled />
						</Label>
					</Margin>
				</StyledColoredContentWrapper>
			</Panel>
		)
	}
}
