import React, { PureComponent } from 'react'
import { List } from 'immutable'
import { object, string, bool, func, number, oneOfType } from 'prop-types'
import styled from 'styled-components'
import DropdownMenu from '../../shared/dropdown-menu/dropdown-menu'
import DropdownItem from '../../shared/dropdown-item/dropdown-item'
import Text from '../../shared/text/text'
import Button from '../../shared/button/button'
import Tooltip from '../../shared/tooltip/tooltip'
import { isEllipsisActive } from '../tooltip/tooltip.helpers'

const StyledMenuWrapper = styled.div`
	display: inline-block;
	margin-right: ${(props) => props.theme.spacing[2]};

	:last-child {
		margin-right: 0;
	}
`

const StyledDropdownWrapper = styled.div`
	display: inline-block;
	margin-left: -1px;
`

class BreadcrumbsItem extends PureComponent {
	constructor(props) {
		super(props)
		this.btnRef = React.createRef()
	}

	static defaultProps = {
		isOpen: false,
		items: List(),
		isRoot: false,
		readOnly: false,
		canManageItem: false,
		canCreateItem: false,
		hideMenuOverflow: true,
		scrollbarAlwaysVisible: false,
		breadcrumbsMaxWidth: '250px'
	}

	static propTypes = {
		index: number,
		isOpen: bool,
		itemName: oneOfType([string, object]),
		items: object.isRequired,
		onBtnClick: func,
		onToggleMenu: func,
		onMenuItemClick: func,
		createItemTid: string,
		manageItemTid: string,
		onCreateItem: func,
		onManageItem: func,
		isRoot: bool,
		menuItemLeftComponent: func,
		menuItemRightComponent: func,
		menuItemSelected: func,
		readOnly: bool,
		canCreateItem: bool,
		canManageItem: bool,
		hideMenuOverflow: bool,
		scrollbarAlwaysVisible: bool,
		isStatic: bool,
		breadcrumbsMaxWidth: string,
		tooltipTidLeft: oneOfType([bool, string]),
		tooltipTidRight: oneOfType([bool, string]),
		borderless: bool,
		createTooltipTid: string,
		manageTooltipTid: string,
		onMouseEnterDrop: func,
		onMouseLeaveDrop: func,
		onMoveItem: func,
		moveItemTid: string,
		canMoveItem: bool,
		moveTooltipTid: string,
		tooltipActiveState: string,
		tooltipValues: object
	}

	getDropdownItemTooltipProps = (tid, activeState, values) => {
		return {
			tooltipTid: tid,
			tooltipActiveState: activeState,
			tooltipValues: values,
			tooltipDelayShow: 'instant',
			tooltipMaxWidth: '500px'
		}
	}

	renderSubitems = (item, index) => {
		const {
			onMenuItemClick,
			menuItemLeftComponent,
			menuItemRightComponent,
			menuItemSelected,
			hideMenuOverflow
		} = this.props

		return (
			<DropdownItem
				key={index}
				onClick={() => {
					onMenuItemClick && onMenuItemClick(item)
				}}
				leftComponent={menuItemLeftComponent && menuItemLeftComponent(item)}
				rightComponent={menuItemRightComponent && menuItemRightComponent(item)}
				selected={menuItemSelected && menuItemSelected(item)}
				overflowHidden={hideMenuOverflow}
			/>
		)
	}

	renderDropdown = () => {
		const {
			items,
			canCreateItem,
			canManageItem,
			isOpen,
			onToggleMenu,
			createItemTid,
			onCreateItem,
			isRoot,
			manageItemTid,
			index,
			onManageItem,
			scrollbarAlwaysVisible,
			onMouseEnterDrop,
			onMouseLeaveDrop,
			onMoveItem,
			moveItemTid,
			canMoveItem
		} = this.props

		// Tooltip Props
		const {
			tooltipTidRight,
			createTooltipTid,
			createTooltipActiveState,
			createTooltipValues,
			manageTooltipTid,
			manageTooltipActiveState,
			manageTooltipValues,
			moveTooltipTid,
			moveTooltipActiveState,
			moveTooltipValues
		} = this.props

		if (items.size > 0 || canCreateItem || canManageItem) {
			return (
				<StyledDropdownWrapper
					onMouseEnter={onMouseEnterDrop && onMouseEnterDrop}
					onMouseLeave={onMouseLeaveDrop && onMouseLeaveDrop}>
					<DropdownMenu
						isOpen={isOpen}
						scrollbars={true}
						scrollbarAlwaysVisible={scrollbarAlwaysVisible}
						onToggleMenu={onToggleMenu}
						tooltipTid={tooltipTidRight}>
						{items.size > 0 && items.map(this.renderSubitems)}

						{items.size > 0 && <DropdownItem divider />}

						<DropdownItem
							leftComponent={<Text tid={createItemTid} />}
							onClick={() => {
								onCreateItem && onCreateItem(index)
							}}
							disabled={!canCreateItem}
							{...this.getDropdownItemTooltipProps(createTooltipTid, createTooltipActiveState, createTooltipValues)}
						/>

						{!isRoot && (
							<>
								<DropdownItem
									leftComponent={<Text tid={manageItemTid} />}
									onClick={() => {
										onManageItem && onManageItem(index)
									}}
									disabled={!canManageItem}
									{...this.getDropdownItemTooltipProps(manageTooltipTid, manageTooltipActiveState, manageTooltipValues)}
								/>

								{onMoveItem && (
									<DropdownItem
										leftComponent={<Text tid={moveItemTid} />}
										onClick={() => {
											onMoveItem && onMoveItem()
										}}
										disabled={!canMoveItem}
										{...this.getDropdownItemTooltipProps(moveTooltipTid, moveTooltipActiveState, moveTooltipValues)}
									/>
								)}
							</>
						)}
					</DropdownMenu>
				</StyledDropdownWrapper>
			)
		}
	}

	render = () => {
		const { itemName, onBtnClick, isStatic, breadcrumbsMaxWidth, tooltipTidLeft, borderless } = this.props

		if (tooltipTidLeft) {
			return (
				<StyledMenuWrapper>
					<Tooltip tid={tooltipTidLeft}>
						<Button onClick={onBtnClick} maxWidth={breadcrumbsMaxWidth} isStatic={isStatic} borderless={borderless}>
							<Text singleLine={true} setRef={(r) => (this.btnRef = r)}>
								{itemName}
							</Text>
						</Button>
					</Tooltip>
					{this.renderDropdown()}
				</StyledMenuWrapper>
			)
		}

		const tooltipIsActive = this.btnRef && isEllipsisActive(this.btnRef)

		return (
			<StyledMenuWrapper>
				<Tooltip raw={itemName} active={tooltipIsActive} delayShow='short'>
					<Button onClick={onBtnClick} maxWidth={breadcrumbsMaxWidth} isStatic={isStatic} borderless={borderless}>
						<Text singleLine={true} setRef={(r) => (this.btnRef = r)}>
							{itemName}
						</Text>
					</Button>
				</Tooltip>
				{this.renderDropdown()}
			</StyledMenuWrapper>
		)
	}
}

export default BreadcrumbsItem
