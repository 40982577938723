import React, { Component, createRef } from 'react'
import localeFormatNumber from '../../../../modules/format-number'
import { NUMBER_FORMAT_INTEGER } from '/shared/constants'

const KEY_BACKSPACE = 8
const KEY_DELETE = 46
const KEY_F2 = 113
const KEY_ENTER = 13
const KEY_TAB = 9

export default class NumericEditor extends Component {
	constructor(props) {
		super(props)

		this.inputRef = createRef(null)

		this.cancelBeforeStart = this.props.charPress && '1234567890'.indexOf(this.props.charPress) < 0

		this.state = this.createInitialState(props)

		this.onKeyDown = this.onKeyDown.bind(this)
		this.handleChange = this.handleChange.bind(this)
	}

	componentDidMount() {
		this.setCarot()
	}

	render() {
		return (
			<input
				ref={this.inputRef}
				value={this.state.value ? localeFormatNumber(this.state.value, NUMBER_FORMAT_INTEGER) : 0}
				onKeyDown={this.onKeyDown}
				onChange={this.handleChange}
				style={{ width: '100%' }}
			/>
		)
	}

	/* Component Editor Lifecycle methods */
	// the final value to send to the grid, on completion of editing
	getValue() {
		return this.state.value
	}

	// Gets called once before editing starts, to give editor a chance to
	// cancel the editing before it even starts.
	isCancelBeforeStart() {
		return false
	}

	// Gets called once when editing is finished (eg if Enter is pressed).
	// If you return true, then the result of the edit will be ignored.
	isCancelAfterEnd() {
		if (!this.isCharNumeric(this.state.value)) {
			this.setState({ value: 0 })
		}
		return false
	}

	/* Utility methods */
	createInitialState(props) {
		let startValue
		let highlightAllOnFocus = true

		if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
			// if backspace or delete pressed, we clear the cell
			startValue = ''
		} else if (props.charPress && !this.cancelBeforeStart) {
			// if a letter was pressed, we start with the letter
			startValue = props.charPress
			highlightAllOnFocus = false
		} else {
			// otherwise we start with the current value
			startValue = props.value
			if (props.keyPress === KEY_F2) {
				highlightAllOnFocus = false
			}
		}

		return {
			value: startValue,
			highlightAllOnFocus
		}
	}

	isKeyPressAllowed = (event) => {
		const controlOrMetaKey = event.metaKey || event.ctrlKey
		const isCopy = controlOrMetaKey && event.keyCode === 67
		const isPaste = controlOrMetaKey && event.keyCode === 86
		const isHighlight = controlOrMetaKey && event.keyCode === 65
		return isCopy || isPaste || isHighlight
	}

	onKeyDown(event) {
		if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
			event.stopPropagation()
			return
		}

		if (!this.finishedEditingPressed(event) && !this.isKeyPressedNumeric(event) && !this.isKeyPressAllowed(event)) {
			if (event.preventDefault) {
				event.preventDefault()
			}
		}
	}

	isLeftOrRight(event) {
		return [37, 39].indexOf(event.keyCode) > -1
	}

	handleChange(event) {
		const valueWithoutSpace = event.target.value.replace(/\s+/g, '').trim()

		if (isNaN(valueWithoutSpace)) {
			return
		}

		this.props.updateSharesLeft && this.props.updateSharesLeft(valueWithoutSpace, this.state.value)
		this.setState({ value: valueWithoutSpace })
	}

	getCharCodeFromEvent(event) {
		event = event || window.event
		return typeof event.which === 'undefined' ? event.keyCode : event.which
	}

	isCharNumeric(charStr) {
		return !!/\d/.test(charStr)
	}

	isKeyPressedNumeric(event) {
		const charCode = this.getCharCodeFromEvent(event)
		const charStr = event.key ? event.key : String.fromCharCode(charCode)
		return this.isCharNumeric(charStr)
	}

	deleteOrBackspace(event) {
		return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1
	}

	finishedEditingPressed(event) {
		const charCode = this.getCharCodeFromEvent(event)
		return charCode === KEY_ENTER || charCode === KEY_TAB
	}

	setCarot() {
		// https://github.com/facebook/react/issues/7835#issuecomment-395504863
		setTimeout(() => {
			const currentInput = this.inputRef.current
			currentInput.focus()
			if (this.state.highlightAllOnFocus) {
				currentInput.select()

				this.setState({
					highlightAllOnFocus: false
				})
			} else {
				// when we started editing, we want the carot at the end, not the start.
				// this comes into play in two scenarios: a) when user hits F2 and b)
				// when user hits a printable character, then on IE (and only IE) the carot
				// was placed after the first character, thus 'apply' would end up as 'pplea'
				const length = currentInput.value ? currentInput.value.length : 0
				if (length > 0) {
					currentInput.setSelectionRange(length, length)
				}
			}
		})
	}
}
