import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Map } from 'immutable'
import { formatIdPretty } from '../../../helpers/users'

class SharesPledgeView extends Component {
	componentDidMount() { }

	findSelectedInvestor = () => {
		const { investors, tmpTransaction } = this.props
		let currentInvestor = Map()

		if (investors && tmpTransaction) {
			currentInvestor = investors.find((inv) => {
				return inv.get('id') === tmpTransaction.getIn(['handlerData', 'investorId'])
			})
		}

		return currentInvestor || Map()
	}

	getShareTypeName = (type) => {
		const { i18n } = this.props
		const [shareClass, shareType] = type.get('type').split('$')
		return (
			(i18n.messages[`share_class.${shareClass}`] || decodeURIComponent(shareClass)) +
			' ' +
			(i18n.messages[`share_type.${shareType}`] || decodeURIComponent(shareType))
		)
	}

	renderShareType = (sequence, index) => {
		const shares = sequence.get('shares')

		if (!shares) {
			return null
		}

		const name = this.getShareTypeName(sequence)

		return (
			<div className='list__item' key={index}>
				<div className='list__item__body'>
					<span className='list__item__text'>{name}</span>
				</div>
				<div className='list__item__text-area list__3item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
					<span className='list__item__text'>{shares}</span>
				</div>
			</div>
		)
	}

	render = () => {
		const { transaction, investors, tmpTransaction } = this.props
		let investmentId
		let investor = Map({})
		let shareTypes

		if (tmpTransaction) {
			investmentId = tmpTransaction.getIn(['handlerData', 'investorId'])
		}

		if (investmentId) {
			if (investors) {
				investor = investors.find((inv) => {
					return inv.get('id') === investmentId
				})
			}

			shareTypes = transaction.getIn(['balances', investmentId, 'types'])
		}

		if (!investor) {
			investor = Map({})
		}

		return (
			<div className='i-panel i-panel--white'>
				<div className='i-panel__body i-panel__body i-panel__body--pad-hor'>
					<div className='i-panel__heading--transaction-accordio'>
						<h2 className='i-panel__title'>
							{investmentId && investor.getIn(['investorInformation', 'name'])}{' '}
							<span className='text--muted'>{formatIdPretty(investor.getIn(['investorInformation', 'id']))}</span>
							{!investmentId && <FormattedMessage id='none_selected' />}
						</h2>
					</div>
					<div className='i-panel__body i-panel__body i-panel__body--pad-btm-0'>
						<div className='list__list-header'>
							<div className='list__list-header__body list__list-header__text-area--pad-lft list__list-header__text-area--pad-right'>
								<FormattedMessage id='shares.class_of_shares' />
							</div>
							<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
								<FormattedMessage id='shares' />
							</div>
						</div>
						<div className='list list--table list--striped'>
							{shareTypes && shareTypes.map(this.renderShareType.bind(this))}
							{!shareTypes && (
								<div className='list__item'>
									<div className='list__item__body'>&nbsp;</div>
								</div>
							)}
						</div>
						<FormattedHTMLMessage id='shares_pledge.view-information' />
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get('transaction'),
		tmpTransaction: state.transaction.getIn(['tmpTransaction', 'CHANGE-PLEDGE']),
		investors: state.investors.get('list'),
		i18n: state.i18n
	}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SharesPledgeView)
