import React, { PureComponent } from 'react'
import { func, string, bool, node } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import styled from 'styled-components'
import { Padding } from 'styled-components-spacing'
import Grid from 'styled-components-grid'
import Panel from '../../shared/panel/panel'
import Label from '../../shared/label/label'

export default class NotificationPanel extends PureComponent {
	static propTypes = {
		companyMembersContainer: node.isRequired
	}

	render = () => {
		const { companyMembersContainer } = this.props

		return (
			<Panel title='subscriptions.notification_panel.title'>
				<Padding all={3}>
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='subscriptions.notification_panel.label.member_to_recieve_emails'>
									{companyMembersContainer}
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
				</Padding>
			</Panel>
		)
	}
}
