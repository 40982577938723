// Node modules
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, string, object } from 'prop-types'
import { withRouter } from '../../interfaces/router'
import history, { getQuery } from '../../interfaces/history'
import { List, Map } from 'immutable'
import { Margin } from 'styled-components-spacing'

// Helpers
import i18nhelper from '../../components/helpers/i18n.helper'
import { mergeSharedFolders, mergeSharedMapParentToChildren } from '../../components/helpers/shared-documents.helper'

// Actions
import {
	listFolders,
	updateFolderLocal,
	updateSelectedFolderId,
	fetchDefaultPermissions
} from '../../actions/folders.actions'
import { clearDocumentsList, clearDocument } from '../../actions/documents.actions'
import { openModal } from '../../actions/modals.actions'
import { sharingListDocuments } from '../../actions/documents-sharing.actions'
import { listSharedFolders } from '../../actions/folders-sharing.actions'

// Components
import Breadcrumbs from '../../dumb-components/shared/breadcrumbs/breadcrumbs'
import BreadcrumbsItem from '../../dumb-components/shared/breadcrumbs/breadcrumbs-item'
import Text from '../../dumb-components/shared/text/text'
import FolderMoveContainer from './folder-move.container'

import { DOCUMENT_MOVE_FOLDER_MODAL } from '../../constants/modals'

// Audit
import {
	EVENT_TYPE_FOLDER_CREATE,
	EVENT_TYPE_FOLDER_UPDATE,
	EVENT_TYPE_FOLDER_DELETE,
	EVENT_TYPE_FOLDER_UNSHARED,
	SHARED_FOLDERS_ID
} from '/shared/constants'

class BreadcrumbsContainer extends Component {
	static propTypes = {
		folderId: string,
		basePath: string,
		onCreateFolder: func,
		onManageFolders: func,
		location: object.isRequired
	}

	state = {
		folderStack: List(),
		dropdownOpen: Map(),
		folders: Map(),
		mapParentToChildren: Map()
	}

	componentDidMount = () => {
		this.storeSelectedFolder()
		this.stackFolders()
		document.addEventListener('mousedown', this.handleMouseDown, false)
	}

	componentWillUnmount = () => {
		document.removeEventListener('mousedown', this.handleMouseDown, false)
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { location, folderId, sharedFolders } = this.props
		let { folders, mapParentToChildren } = this.props

		if (prevProps.location !== location) {
			this.storeSelectedFolder()
		}

		if (prevProps.folderId !== folderId) {
			this.stackFolders()
		}

		if (prevProps.folders !== folders || prevState.folders !== this.state.folders) {
			this.stackFolders()
		}

		//if we no longer have acess to the folder we're in we get pushed to root
		if (prevProps.folders !== folders || prevProps.sharedFolders !== sharedFolders) {
			folders = sharedFolders ? mergeSharedFolders(sharedFolders, folders) : folders
			mapParentToChildren = sharedFolders
				? mergeSharedMapParentToChildren(sharedFolders, mapParentToChildren)
				: mapParentToChildren
			this.setState({ folders, mapParentToChildren })
		}

		this.checkLiveUpdateEvents()
	}

	checkLiveUpdateEvents = () => {
		const { audit, folderId, basePath, listSharedFolders, listFolders, sharingListDocuments } = this.props
		const { folders } = this.state
		const query = getQuery()
		const withinSharedFolder = query && query.isSharedFromCompanyId ? true : false
		const FOLDER_CREATE = audit.get(EVENT_TYPE_FOLDER_CREATE)
		const FOLDER_UPDATE = audit.get(EVENT_TYPE_FOLDER_UPDATE)
		const FOLDER_DELETE = audit.get(EVENT_TYPE_FOLDER_DELETE)
		const FOLDER_UNSHARED = audit.get(EVENT_TYPE_FOLDER_UNSHARED)

		if (FOLDER_CREATE && FOLDER_CREATE.get('r') === true) {
			const isSharedObject = FOLDER_CREATE.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = FOLDER_CREATE.getIn(['metadata', 'objectOwnerCompanyId'])

			if (isSharedObject && withinSharedFolder) {
				listSharedFolders(objectOwnerCompanyId, true)
			} else if (!isSharedObject) {
				listFolders()
			}
		}

		if (FOLDER_UPDATE && FOLDER_UPDATE.get('r') === true) {
			const isSharedObject = FOLDER_UPDATE.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = FOLDER_UPDATE.getIn(['metadata', 'objectOwnerCompanyId'])

			if (isSharedObject && (withinSharedFolder || !folders.has(SHARED_FOLDERS_ID))) {
				sharingListDocuments(objectOwnerCompanyId, true)
			} else if (!isSharedObject) {
				listFolders()
			}
		}

		if (FOLDER_DELETE && FOLDER_DELETE.get('r') === true) {
			const objId = FOLDER_DELETE.get('objId')
			const isSharedObject = FOLDER_DELETE.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = FOLDER_DELETE.getIn(['metadata', 'objectOwnerCompanyId'])

			if (isSharedObject && withinSharedFolder) {
				listSharedFolders(objectOwnerCompanyId, true)
			}
			// Deleted folder is selected. Redirect to ROOT.
			else if (!isSharedObject && folderId === objId) {
				history.push(basePath)
			}
		}

		if (FOLDER_UNSHARED && FOLDER_UNSHARED.get('r') === true) {
			const isSharedObject = FOLDER_UNSHARED.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = FOLDER_UNSHARED.getIn(['metadata', 'objectOwnerCompanyId'])

			if (isSharedObject && withinSharedFolder) {
				sharingListDocuments(objectOwnerCompanyId, true)
			}
		}
	}

	storeSelectedFolder = () => {
		const { updateSelectedFolderId } = this.props
		const query = getQuery()
		const { folder } = query

		updateSelectedFolderId(folder)
	}

	stackFolders = () => {
		const { folderId } = this.props
		const { folders } = this.state
		let stack = List()
		let currentFolderId = folderId

		while (folders.get(currentFolderId)) {
			const parentId = folders.getIn([currentFolderId, 'parent'])
			stack = stack.unshift(currentFolderId)
			currentFolderId = parentId
		}

		this.setState({ folderStack: stack })
	}

	handleMouseDown = (e) => {
		if (!this.node.contains(e.target)) {
			this.closeAllDropdowns()
		}
	}

	toggleDropdown = (folderId) => {
		this.setState((prevState) => {
			let { dropdownOpen } = prevState
			dropdownOpen = dropdownOpen.map((obj, objId) => {
				if (folderId !== objId) {
					obj = false
				}

				return obj
			})
			dropdownOpen = dropdownOpen.set(folderId, !dropdownOpen.get(folderId))

			return { dropdownOpen }
		})
	}

	onMenuItemClick = (parentFolderId, folder) => {
		const { basePath, clearDocument } = this.props
		const folderId = folder.get('id')
		const isSharedFromCompanyId = folder.get('isSharedFromCompanyId')

		this.toggleDropdown(parentFolderId)

		clearDocument()

		history.push({
			pathname: `${basePath}/`,
			search: `?folder=${folderId}${isSharedFromCompanyId ? `&isSharedFromCompanyId=${isSharedFromCompanyId}` : ''}`
		})
	}

	closeAllDropdowns = () => {
		this.setState({ dropdownOpen: Map() })
	}

	onCreateFolder = (folderId) => {
		const { onCreateFolder, fetchDefaultPermissions } = this.props

		fetchDefaultPermissions(folderId)

		this.toggleDropdown(folderId)
		onCreateFolder && onCreateFolder(folderId === 'root' ? null : folderId)
	}

	onManageFolders = (managingFolderId) => {
		const { onManageFolders, basePath, folderId, document } = this.props
		const selectedDocumentId = document ? document.get('id') : ''

		this.toggleDropdown(managingFolderId)

		// If managing folder that is not currently selected, redirect to fetch its details.
		if (managingFolderId !== folderId) {
			history.push({
				pathname: `${basePath}/${selectedDocumentId}`,
				search: `?folder=${managingFolderId}`
			})
		}

		onManageFolders && onManageFolders(managingFolderId)
	}

	onTransferFolder = (folder) => {
		const { openModal } = this.props
		openModal(DOCUMENT_MOVE_FOLDER_MODAL, { folder })
	}

	onClickSelectedFolder = (folderId) => {
		const { basePath, clearDocument } = this.props
		const { folders } = this.state

		const isSharedFromCompanyId = folders?.getIn([folderId, 'isSharedFromCompanyId'])

		clearDocument()

		if (folderId === 'root') {
			history.push(basePath)
			return
		}

		history.push({
			pathname: basePath,
			search: `?folder=${folderId}${isSharedFromCompanyId ? `&isSharedFromCompanyId=${isSharedFromCompanyId}` : ''}`
		})
	}

	getTooltipProps = (folderId, canCreateFolder, canManageFolder, canTransferFolder) => {
		const { folders } = this.state
		const folder = folders.get(folderId)

		if (!folder) {
			return
		}

		const isSharedFromCompanyId = folder.get('isSharedFromCompanyId')
		const companyRootFolder = folders.find((folder) => folder.get('id') === isSharedFromCompanyId) || Map()
		const companyName = companyRootFolder.get('translatedName')

		if (isSharedFromCompanyId) {
			return {
				createTooltipActiveState: canCreateFolder ? undefined : 'hasNoPermissionsSharedCompany',
				createTooltipValues: canCreateFolder ? {} : { companyName },
				manageTooltipActiveState: canManageFolder ? undefined : 'hasNoPermissionsSharedCompany',
				manageTooltipValues: canManageFolder ? {} : { companyName },
				moveTooltipActiveState: canTransferFolder ? undefined : 'hasNoPermissionsSharedCompany',
				moveTooltipValues: canTransferFolder ? {} : { companyName }
			}
		}

		if (folder.get('id') === SHARED_FOLDERS_ID) {
			return {
				createTooltipTid: 'documents.breadcrumbs.root_shared_folder.tooltip.create',
				manageTooltipTid: 'documents.breadcrumbs.root_shared_folder.tooltip.manage',
				moveTooltipTid: 'documents.breadcrumbs.root_shared_folder.tooltip.move'
			}
		}

		return {
			createTooltipTid: undefined,
			createTooltipActiveState: undefined,
			createTooltipValues: {},
			manageTooltipTid: undefined,
			manageTooltipActiveState: undefined,
			manageTooltipValues: {},
			moveTooltipTid: undefined,
			moveTooltipActiveState: undefined,
			moveTooltipValues: {}
		}
	}

	renderBreadcrumbs = (folderId, index) => {
		const {
			basePath,
			i18n,
			company,
			match: { params }
		} = this.props
		const { mapParentToChildren, folders, dropdownOpen, folderStack } = this.state

		let children = List()
		const childrenIds = mapParentToChildren.get(folderId, List())
		let currentFolder

		const canManageFolder = folders && folders.getIn([folderId, 'ALLOW_UPDATE'])
		const canCreateFolder = folders && folders.getIn([folderId, 'ALLOW_CREATE'])
		const canTransferFolder = folders && folders.getIn([folderId, 'ALLOW_CREATE'])

		if (folderId === 'root') {
			currentFolder = Map({
				name: <Text tid='documents.folders.root' />,
				id: folderId
			})
		} else {
			currentFolder = folders.get(folderId)
		}

		childrenIds.forEach((childId) => {
			let child = folders.get(childId)
			if (child) {
				child = child.set('selected', folderStack.get(index + 1) === childId)
				children = children.push(child)
			}
		})

		children = children.map((obj) => {
			obj = obj.set('name', i18nhelper.getTranslatedValue(obj.get('name'), i18n.language, company.region))

			if (obj.get('description')) {
				obj = obj.set(
					'description',
					i18nhelper.getTranslatedValue(obj.get('description'), i18n.language, company.region)
				)
			}

			return obj
		})
		children = children.sort((a, b) => a.get('name').localeCompare(b.get('name')))

		return (
			<BreadcrumbsItem
				key={currentFolder && currentFolder.get('id')}
				basePath={basePath}
				params={params}
				tooltipTidRight='tooltip.documents.breadcrumbs.dropdown'
				isOpen={dropdownOpen.get(folderId)}
				itemName={
					folderId !== 'root'
						? i18nhelper.getTranslatedValue(currentFolder && currentFolder.get('name'), i18n.language, company.region)
						: currentFolder && currentFolder.get('name')
				}
				isRoot={folderId === 'root'}
				items={children}
				onBtnClick={this.onClickSelectedFolder.bind(this, currentFolder && currentFolder.get('id'), params.id)}
				onToggleMenu={this.toggleDropdown.bind(this, currentFolder && currentFolder.get('id'))}
				onMenuItemClick={this.onMenuItemClick.bind(this, currentFolder && currentFolder.get('id'))}
				onCreateItem={this.onCreateFolder.bind(this, currentFolder && currentFolder.get('id'))}
				onManageItem={this.onManageFolders.bind(this, currentFolder && currentFolder.get('id'))}
				menuItemLeftComponent={(folder) => {
					return <Text singleLine={true}>{folder.get('name')}</Text>
				}}
				menuItemSelected={(folder) => {
					return folder.get('selected')
				}}
				createItemTid='documents.folders.create_folder'
				manageItemTid='documents.folders.manage_folders'
				canCreateItem={canCreateFolder}
				canManageItem={canManageFolder}
				scrollbarAlwaysVisible={true}
				onMouseEnterDrop={this.toggleDropdown.bind(null, currentFolder && currentFolder.get('id'))}
				onMouseLeaveDrop={this.closeAllDropdowns}
				onMoveItem={this.onTransferFolder.bind(this, currentFolder)}
				moveItemTid='documents.folders.transfer_folder'
				canMoveItem={canTransferFolder}
				{...this.getTooltipProps(folderId, canCreateFolder, canManageFolder, canTransferFolder)}
			/>
		)
	}

	render = () => {
		const { folderStack } = this.state
		return (
			<div ref={(node) => (this.node = node)}>
				<Margin all={3}>
					<Breadcrumbs>
						{this.renderBreadcrumbs('root', -1)}
						{folderStack && folderStack.map(this.renderBreadcrumbs)}
					</Breadcrumbs>
				</Margin>

				<FolderMoveContainer />
			</div>
		)
	}
}

function mapStoreToProps(store) {
	return {
		document: store.documents.get('document'),
		folders: store.folders.get('folders'),
		mapParentToChildren: store.folders.get('mapParentToChildren'),
		sharedFolders: store.folders.get('sharedFolders'),
		currentFolder: store.folders.get('currentFolder'),
		folderId: store.folders.get('selectedFolderId'),
		company: store.company.company,
		i18n: store.i18n,
		audit: store.audit.get('folders')
	}
}

const mapActionsToProps = {
	listFolders,
	updateFolderLocal,
	updateSelectedFolderId,
	clearDocumentsList,
	clearDocument,
	fetchDefaultPermissions,
	openModal,
	listSharedFolders,
	sharingListDocuments
}

export default withRouter(connect(mapStoreToProps, mapActionsToProps)(BreadcrumbsContainer))
