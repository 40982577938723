import { Map, fromJS } from 'immutable';
import { func } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailCellRenderer } from '../../../../dumb-components/shared/ag-grid/renderers/email.cell-renderer';
import { TAB_EXTERNAL_USERS } from '../../select-user-modal/select-user-modal.constants';
import AgGrid from '../../../../dumb-components/shared/ag-grid/ag-grid';
import { usePeopleModalContext } from '../people-modal.context';
import { validateEmail, isRequired } from '../../../../modules/validation.module';
import { AddContactButton } from './AddContactButton';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCompanyContact, editCompanyContact, listCompanyContacts } from '../../../../actions/people.actions';
import theme from '../../../../../theme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DropDown } from '../../../../mui-components/dropdown/DropDown';
import MenuItemIcon from '../../../../mui-components/dropdown/menu/menu-item-icon';
import IconButton from '../../../../mui-components/button/icon-button';
import { UnselectableRenderer } from '../../../../dumb-components/shared/ag-grid/renderers/unselectable.renderer';
import { CheckboxRenderer } from '../../../../dumb-components/shared/ag-grid/renderers/checkbox.renderer';
import { TextOnlyTooltip } from '../../../../dumb-components/shared/ag-grid/tooltips/text-only-tooltip';
import { CheckboxInHeaderRenderer } from '../../../../dumb-components/shared/ag-grid/renderers/checkbox-in-header.renderer';
import { TextEditorWithValidation } from '../../../../dumb-components/shared/ag-grid/editors/TextEditorWithValidation';
import { ComboBox } from '../../../../dumb-components/shared/ag-grid/editors/ComboBox';

function CompanyContactsGrid({ disableFunction, filterFunction }) {
	const {
		selectedPeople,
		setSelectedPeople,
		activeTab,
		floatingFilter,
		singleMode,
		showGridInExtendedView,
		personalContactsHasBeenCopied,
		setIsFloatingFilterActive
	} = usePeopleModalContext();
	const { t } = useTranslation();
	const gridRef = useRef();
	const dispatch = useDispatch();
	const [externalPeople, setExternalPeople] = useState([]);

	const _externalPeopleList = useSelector((state) => {
		return state.people.get('companyContacts');
	});

	useEffect(() => {
		dispatch(listCompanyContacts());

		return () => {};
	}, []);

	useEffect(() => {
		if (personalContactsHasBeenCopied) {
			dispatch(listCompanyContacts());
		}
	}, [personalContactsHasBeenCopied]);

	useEffect(() => {
		const _externalPeopleArr = _externalPeopleList.toJS();
		let filteredPeople;
		if (filterFunction) {
			filteredPeople = filterFunction(_externalPeopleArr);
		} else {
			filteredPeople =
				_externalPeopleArr && _externalPeopleArr.length > 0
					? _externalPeopleArr.filter((person) => !person.isDeleted)
					: _externalPeopleArr;
		}

		filteredPeople = filteredPeople.map((person) => {
			let isSelectable = true;
			let tooltipTid;
			let restrictionIcon;
			let restrictionIconSize = 21;

			if (!validateEmail(person.email)) {
				isSelectable = false;
				tooltipTid = 'people_modal.grid.selected.tooltip.email_is_missing';
				restrictionIcon = 'faExclamationTriangle';
				restrictionIconSize = 17;
			} else if (typeof disableFunction === 'function') {
				const result = disableFunction(fromJS(person));

				if (typeof result === 'boolean') {
					isSelectable = !result;
					tooltipTid = !isSelectable && 'people_modal.grid.selected.tooltip.not_selectable';
					restrictionIcon = 'faExclamationSquare';
				} else if (typeof result === 'object') {
					isSelectable = !result.isDisabled;
					tooltipTid = result.tooltipTid ?? 'people_modal.grid.selected.tooltip.not_selectable';
					restrictionIcon = result.icon ?? 'faExclamationSquare';
				}
			}

			person.selectable = isSelectable;
			person.tooltipTidOnSelectedColumn = tooltipTid;
			person.selected = isSelectable && selectedPeople && selectedPeople.has(person.id);
			person.restrictionIcon = restrictionIcon;
			person.restrictionIconSize = restrictionIconSize;
			return person;
		});

		setExternalPeople(filteredPeople);

		return () => {};
	}, [_externalPeopleList]);

	useEffect(() => {
		setIsFloatingFilterActive(gridRef.current?.api?.isColumnFilterPresent());
	}, [gridRef.current?.api?.isColumnFilterPresent()]);

	const validateRow = (rowNode) => {
		const { name, email } = rowNode.data;

		return isRequired(name) && validateEmail(email);
	};

	const isRowSelectable = (rowNode) => {
		if (!rowNode.data) {
			return true;
		}

		if (!validateRow(rowNode)) {
			return false;
		}

		return rowNode.data.selectable;
	};

	const onCellValueChanged = (params) => {
		const { data } = params;

		dispatch(editCompanyContact(data.id, data));
	};

	const onSelectionChanged = ({ api }) => {
		let newPeople = selectedPeople;

		api.forEachNode((node) => {
			const isSelected = node.isSelected();
			const { id, name, email } = node.data;
			const isSelectedSinceBefore = selectedPeople.has(id);

			if (isSelected) {
				if (!isSelectedSinceBefore) {
					newPeople = newPeople.set(id, Map({ userId: id, name, email, isGuest: true }));
				}
			} else {
				newPeople = newPeople.remove(id);
			}
		});

		setSelectedPeople(newPeople);

		const newExternalPeople = externalPeople.map((person) => {
			const node = api.getRowNode(person.id);
			person.selected = node.selected;
			return person;
		});
		setExternalPeople(newExternalPeople);
	};

	const getColumnDefs = () => {
		const defs = [
			{
				field: 'selected',
				headerName: '',
				cellRendererSelector: ({ node, data }) => {
					if (!node.selectable) {
						return {
							component: UnselectableRenderer,
							params: {
								icon: data.restrictionIcon,
								iconSize: data.restrictionIconSize
							}
						};
					}

					return {
						component: CheckboxRenderer
					};
				},
				headerComponent: !singleMode ? CheckboxInHeaderRenderer : undefined,
				headerComponentParams: {
					filteredOnly: true
				},
				lockVisible: true,
				minWidth: 38,
				tooltipField: 'selected',
				tooltipComponent: TextOnlyTooltip,
				tooltipComponentParams: {
					tooltipTid: ({ data }) => {
						return data.tooltipTidOnSelectedColumn;
					}
				},
				cellClass: 'checkbox-cell',
				headerClass: 'checkbox-cell',
				filter: false
			},
			{
				field: 'name',
				headerName: t('people_modal.grid.headers.name'),
				flex: 1,
				minWidth: 306,
				filter: 'agTextColumnFilter',
				editable: true,
				cellEditor: ComboBox,
				cellEditorParams: {
					validate(value) {
						return isRequired(value);
					},
					errorTid: 'people_modal.grid.errors.name',
					options: () => {
						return externalPeople.map((contact) => ({ id: contact.id, label: contact.name }));
					},
					useRegularInput: true
				},
				onCellValueChanged,
				singleClickEdit: true
			},
			{
				field: 'phone',
				headerName: t('people_modal.grid.headers.phone'),
				minWidth: 150,
				filter: 'agTextColumnFilter',
				editable: true,
				cellEditor: ComboBox,
				cellEditorParams: {
					options: () => {
						return externalPeople
							.filter((contact) => !!contact.phone)
							.map((contact) => ({ id: contact.id, label: contact.phone }));
					},
					useRegularInput: true
				},
				onCellValueChanged,
				singleClickEdit: true
			},
			{
				field: 'email',
				headerName: t('people_modal.grid.headers.email'),
				minWidth: 336,
				flex: 1,
				cellRenderer: EmailCellRenderer,
				filter: 'agTextColumnFilter',
				editable: true,
				cellEditor: ComboBox,
				cellEditorParams: {
					validate(value) {
						return validateEmail(value);
					},
					errorTid: 'people_modal.grid.errors.email',
					options: () => {
						return externalPeople
							.filter((contact) => !!contact.email)
							.map((contact) => ({ id: contact.id, label: contact.email }));
					},
					useRegularInput: true
				},
				onCellValueChanged,
				singleClickEdit: true
			},
			{
				field: 'tags',
				headerName: t('people_modal.grid.headers.category'),
				minWidth: 200,
				filter: 'agTextColumnFilter',
				editable: true,
				cellEditor: ComboBox,
				cellEditorParams: {
					options: () => {
						return externalPeople
							.filter((contact) => !!contact.tags)
							.map((contact) => ({ id: contact.id, label: contact.tags }));
					},
					useRegularInput: true
				},
				onCellValueChanged,
				singleClickEdit: true
			},
			{
				field: 'source',
				headerName: t('people_modal.grid.headers.source_origin_data'),
				minWidth: 300,
				filter: 'agTextColumnFilter',
				valueGetter: (params) => {
					const { source } = params.data;

					if (!source) {
						return null;
					}

					return source.type === 'copied'
						? `${source.name}${source.phone ? ` - ${source.phone}` : ''}`
						: source.name ?? '';
				},
				cellRenderer: (params) => {
					const { source } = params.data;

					if (!source) {
						return null;
					}

					return source.type === 'copied' ? (
						params.value
					) : (
						<em>{t('people_modal.grid.source.direct_added', { name: params.value })}</em>
					);
				},
				hide: !showGridInExtendedView
			},
			{
				colId: 'actions',
				minWidth: 36,
				width: 36,
				maxWidth: 36,
				sortable: false,
				filter: false,
				type: 'rightAligned',
				cellRenderer: (params) => {
					return (
						<DropDown
							alignMenu='right'
							button={({ params }) => <IconButton noBorder icon='faEllipsisV' {...params} />}>
							<MenuItemIcon
								icon='faTrashAlt'
								listItemTid='people_modal.grid.more_action.delete_contact'
								disabled={params.data.deleted}
								onClick={() => dispatch(deleteCompanyContact(params.data.id))}
							/>
						</DropDown>
					);
				},
				lockVisible: true,
				cellClass: 'more-actions',
				headerClass: 'more-actions'
			}
		];

		return defs;
	};

	const getRowId = useCallback((params) => params.data.id, []);

	const getRowStyle = useCallback(({ data }) => {
		if (data.isDeleted) {
			return {
				'--ag-selected-row-background-color': theme.colors.red,
				'--ag-even-row-background-color': theme.colors.red,
				'--ag-odd-row-background-color': theme.colors.red,
				'--ag-background-color': theme.colors.red,
				'--ag-row-hover-color': theme.colors.red,
				'--ag-data-color': theme.colors.white
			};
		}
	}, []);

	if (activeTab !== TAB_EXTERNAL_USERS) {
		return null;
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
			<Box sx={{ mb: 1, display: 'flex' }}>
				<Box sx={{ flex: 1 }}>
					<div>
						<Typography variant='body1'>
							{t('people_modal.company_contacts.informative_text.title')}
						</Typography>
						<Typography variant='body2'>
							{t('people_modal.company_contacts.informative_text.text')}
						</Typography>
					</div>
				</Box>
				<AddContactButton />
			</Box>
			<Box sx={{ flex: 1 }}>
				<AgGrid
					gridRef={gridRef}
					rowData={externalPeople}
					columnDefs={getColumnDefs()}
					mode='panel'
					rowSelection={singleMode ? 'single' : 'multiple'}
					isRowSelectable={isRowSelectable}
					forcedHeight='100%'
					floatingFilter={floatingFilter}
					suppressRowClickSelection={true}
					getRowId={getRowId}
					onSelectionChanged={onSelectionChanged}
					getRowStyle={getRowStyle}
					hideColumnMenu={true}
					suppressContextMenu
					onFilterChanged={({ api }) => api.refreshHeader()}
					stopEditingWhenCellsLoseFocus
				/>
			</Box>
		</Box>
	);
}

CompanyContactsGrid.propTypes = {
	disableFunction: func,
	filterFunction: func
};

export { CompanyContactsGrid };
