import React, { useContext, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getQuery } from '../../../interfaces/history';
import ShareholderDistributionByTypeChart from './investment-shareholderdistribution-by-type-chart';
import ShareholdersGridContainer from '../../../containers/investments/shareholders/shareholder-grid.container';
import { InvestmentsContext } from '../investment.container';
import TransactionHistoryChart from '../sharebook/transaction-history-chart';
import ShareholderDistributionByOwnerChart from './investment-shareholderdistribution-by-owner-chart.js';
import ShareholderChart from './investment-shareholder-chart';
import { CAP_TABLE_READ_MORE_MODAL } from '../../../constants/modals';
import { openModal } from '../../../actions/modals.actions';

import DropdownMenuContainer from '../../shared/dropdown-menu.container';
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item';
import IconButton from '../../../mui-components/button/icon-button';
import { fetchOwnerReport } from '../../../actions/report-pdfs.actions';
import Link from '@mui/material/Link';
import { ReadMoreModal } from '../modals/read-more-modal';

export default function InvestmentShareholdersContainer() {
	const buttonRef = useRef();

	const dispatch = useDispatch();
	const { selectedInvestment } = useContext(InvestmentsContext);
	const name = useSelector(
		(state) =>
			state.capTableDetails.get('aliasOfTheInvestedCompany') ||
			state.capTableDetails.getIn([
				'combinedSingleInvestmentAndAllTransactions',
				'investedInCompanyInformation',
				'name'
			])
	);

	const investments = selectedInvestment?.get('investments');

	const isReadMoreModalOpen = useSelector(
		(state) => state.modals.getIn(['activeModal', 'name']) === CAP_TABLE_READ_MORE_MODAL
	);

	const createPdf = () => {
		const query = getQuery();
		const investedInCompanyId = selectedInvestment.get('investedInCompanyId');
		const investmentId = selectedInvestment.get('id');
		const isCompany = query.company && 'true';

		dispatch(fetchOwnerReport(investmentId, investedInCompanyId, undefined, undefined, isCompany));

		buttonRef && buttonRef.current.onToggleMenu();
	};

	const openReadMoreModal = () => {
		dispatch(openModal(CAP_TABLE_READ_MORE_MODAL));
	};

	return (
		<>
			<Grid container spacing={2} pb={2}>
				<Grid item lg={9} md={12}>
					<Card borderradius={'true'}>
						<CardContent>
							<Box>
								<Typography variant='body1'>
									<FormattedMessage
										id={'investments.shareholders.secondary-card.title'}
										values={{ companyName: name }}
									/>
								</Typography>
								<Typography variant='body2'>
									<FormattedMessage
										id={'investments.shareholders.secondary_card.paragraph'}
										values={{
											link: (
												<Link
													sx={{ pb: 0.3 }}
													onClick={openReadMoreModal}
													component={'button'}
													color={'positive.main'}>
													<FormattedMessage
														id={'investments.information_panel.link.read_more'}
													/>
												</Link>
											)
										}}
									/>
								</Typography>
							</Box>
						</CardContent>
					</Card>
				</Grid>

				<Grid item lg={3} md={12}>
					<Card variant='dark'>
						<CardContent variant='flex'>
							<Box>
								<Typography variant='body1'>
									<FormattedMessage id={'generic.reports'} />
								</Typography>
								<Typography variant='body2'>
									<FormattedMessage id={'investments.shareholders.dropdown_menu.download_pdf'} />
								</Typography>
							</Box>

							<DropdownMenuContainer
								halignMenu='right'
								ref={buttonRef}
								// ref={(ref) => (this.btnPrintShareholdersRef = ref)}
								noMaxWidth={true}
								renderRaw={<IconButton icon='faPrint' color='white' />}>
								<DropdownIconItem
									tid='investments.shareholders.dropdown_item.download_pdf.share_report'
									icon='faFileChartLine'
									onClick={() => createPdf()}
								/>
							</DropdownMenuContainer>
						</CardContent>
					</Card>
				</Grid>

				{investments && (
					<Grid item xs={12}>
						<ShareholdersGridContainer />
					</Grid>
				)}
				<Grid item xs={6}>
					<ShareholderChart />
				</Grid>

				<Grid item xs={6}>
					<ShareholderDistributionByOwnerChart />
				</Grid>

				<Grid item xs={6}>
					<ShareholderDistributionByTypeChart />
				</Grid>

				<Grid item xs={6}>
					<TransactionHistoryChart
						title={'investments.sharebook.shareholder.post_money_valuation_chart.title'}
						downloadExcelTid={
							'investments.shareholders.post_money_valuation_chart.dropdown_item.download_chart'
						}
					/>
				</Grid>
			</Grid>
			{isReadMoreModalOpen && (
				<ReadMoreModal
					title={'investments.shareholders.white_panel.read_more.modal.title'}
					contentFile='shareholders.txt'
				/>
			)}
		</>
	);
}
