import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchMeeting } from '../../../actions/meetings.actions'
import { Padding } from 'styled-components-spacing'
import SplitView from '../../../dumb-components/shared/layout/split-view/split-view'
import ScrollView from '../../../dumb-components/shared/layout/scroll-view/scroll-view'
import ScrollViewHeader from '../../../dumb-components/shared/scroll-view-header/scroll-view-header'
import MeetingTabsContainer from '../meeting/meeting-tabs.container'
import MeetingsGeneralContainer from '../meeting/meeting-general.container'
import MeetingToolbarContainer from '../meeting/smart-meeting/meeting-toolbar.container'
import LocationsContainer from '../location-panels/locations.container'
import Panel from '../../../dumb-components/shared/panel/panel'
import SimpleDarkInfoPanel from '../../../dumb-components/shared/dark-info-panel/simple-dark-info-panel'
import Text from '../../../dumb-components/shared/text/text'

import { calculateMaxWidthOfSplitView, toggleVisibilityOfTabs } from '../../../components/helpers/tabs.helper'

class MeetingInformationContainer extends Component {
	// References to the scrollviews
	scrollViewRefs = {}

	// Reference to the tabs
	tabsRef

	// Timer
	resizeEnd

	state = {
		hiddenTabs: []
	}

	componentDidMount = () => {
		window.addEventListener('resize', this.onWindowResize, false)
		this.onWindowResize()
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.onWindowResize, false)
	}

	onWindowResize = () => {
		let { hiddenTabs } = this.state
		const maxWidth = calculateMaxWidthOfSplitView(this.scrollViewRefs)
		hiddenTabs = toggleVisibilityOfTabs(this.tabsRef, hiddenTabs, { maxWidth })

		this.setState({ hiddenTabs })
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('info')
		return pathArray[0] + 'info'
	}

	renderLeftHeader = () => {
		const { location } = this.props
		const { hiddenTabs } = this.state

		return (
			<MeetingTabsContainer
				match='info'
				location={location}
				onSetRef={(ref) => (this.tabsRef = ref)}
				hiddenTabs={hiddenTabs}
			/>
		)
	}

	renderLeftSplitView = () => {
		const {
			location,
			match: { params }
		} = this.props

		return (
			<ScrollView
				header={this.renderLeftHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs['left'] = ref)}>
				<MeetingsGeneralContainer renderMode='full' />
			</ScrollView>
		)
	}

	renderRightHeader = () => {
		const {
			match: { params },
			location
		} = this.props
		const basePath = this.getComponentBasePath()

		return (
			<ScrollViewHeader>
				<MeetingToolbarContainer location={location} basePath={basePath.split('/info')[0]} params={params} />
			</ScrollViewHeader>
		)
	}

	renderRightSplitView = () => {
		return (
			<ScrollView
				header={this.renderRightHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs['right'] = ref)}>
				<Panel marginBottom>
					<Padding all={4}>
						<SimpleDarkInfoPanel>
							<Text tid='meetings.location.information' color='white' />
						</SimpleDarkInfoPanel>
					</Padding>
				</Panel>
				<LocationsContainer marginBottom={true} />
			</ScrollView>
		)
	}

	render = () => {
		return <SplitView leftComponent={this.renderLeftSplitView} rightComponent={this.renderRightSplitView} />
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get('meeting'),
		audit: store.audit.get('meetings')
	}
}

const mapActionsToProps = {
	fetchMeeting
}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingInformationContainer)
