export const modifyTransactionIfDirty = (selectedInvestmentTransactions, responseDataTransactions) => {
	const newResponseDataTransactions = (responseDataTransactions || []).map((transaction) => {
		const linkId = transaction?.link?.transactionId

		if (
			!linkId ||
			selectedInvestmentTransactions.find(
				(selectedInvestmentTransaction) => selectedInvestmentTransaction.get('id') === linkId
			)
		) {
			return transaction
		}

		return {
			...transaction,
			link: {
				isDirty: true
			}
		}
	})

	return newResponseDataTransactions
}
