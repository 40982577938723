import React, { Component } from 'react'
import { connect } from 'react-redux'
import Content from '../../dumb-components/shared/layout/content/content'
import SplitView from '../../dumb-components/shared/layout/split-view/split-view'
import ScrollView from '../../dumb-components/shared/layout/scroll-view/scroll-view'
import ScrollViewHeader from '../../dumb-components/shared/scroll-view-header/scroll-view-header'

import CoreProductsListContainer from './core-products-list.container'
import PlansContainer from './plans.container'
import CancelAccountContainer from './cancel-account.container'
import PaymentDetailsContainer from './payment-details.container'
import PrepaidContainer from './prepaid.container'
import Breadcrumbs from '../../dumb-components/shared/breadcrumbs/breadcrumbs'
import BreadcrumbsItem from '../../dumb-components/shared/breadcrumbs/breadcrumbs-item'
import Text from '../../dumb-components/shared/text/text'
import { Margin } from 'styled-components-spacing'
import NotificationPanelContainer from './notification-panel.container'
import StripeErrorContainer from './stripe-error.container'

import InfoAboutPotAfterSubModalContainer from './info-about-pot-after-sub-modal.container'

import { fetchSubscription, fetchPlans, fetchApiKey } from '../../actions/subscriptions.actions'
import { fetchDocumentHelper } from '../../actions/documents.actions'

class SubscriptionsContainer extends Component {
	componentDidMount = () => {
		const { fetchSubscription, fetchDocumentHelper, fetchPlans, apiKey, fetchApiKey } = this.props

		fetchSubscription()
		fetchDocumentHelper()
		fetchPlans()

		if (!apiKey) {
			fetchApiKey()
		}
	}

	renderHeader = () => {
		return (
			<Margin all={3}>
				<Breadcrumbs>
					<BreadcrumbsItem itemName={<Text tid='subscription.breadcrumbs.title' />} isStatic={true} />
				</Breadcrumbs>
			</Margin>
		)
	}

	renderLeftSplitView = () => {
		const { subscription } = this.props

		if (!subscription) {
			return null
		}

		return (
			<ScrollView autoHide={true} showOnHover={true}>
				<PlansContainer />
				<PrepaidContainer />
				{/* //! uncomment the follow lines when the BDX has been migrated */}
				{/* <CoreProductsListContainer /> */}
				<CancelAccountContainer />
			</ScrollView>
		)
	}

	renderRightSplitViewHeader = () => {
		return (
			<ScrollViewHeader>
				<StripeErrorContainer />
			</ScrollViewHeader>
		)
	}

	renderRightSplitView = () => {
		const { subscription } = this.props
		const isFreeOfCharge = subscription && subscription.get('isFreeOfCharge')
		if (isFreeOfCharge) {
			return null
		}
		return (
			<ScrollView header={this.renderRightSplitViewHeader} autoHide={true} showOnHover={true}>
				<PaymentDetailsContainer />
				<NotificationPanelContainer />
			</ScrollView>
		)
	}

	render = () => {
		return (
			<Content header={this.renderHeader} tightenHeader={true}>
				<SplitView leftComponent={this.renderLeftSplitView} rightComponent={this.renderRightSplitView} />

				<InfoAboutPotAfterSubModalContainer />
			</Content>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		subscription: store.subscriptions.get('subscription'),
		apiKey: store.subscriptions.get('apiKey')
	}
}

const mapActionsToProps = {
	fetchSubscription,
	fetchPlans,
	fetchApiKey,
	fetchDocumentHelper
}

export default connect(mapStoreToProps, mapActionsToProps)(SubscriptionsContainer)
