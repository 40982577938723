import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool, object, number } from 'prop-types'

import PublishOrEsignProtocolPanel from '../../../../dumb-components/meetings/publish-or-esign-protocol-panel/publish-or-esign-protocol-panel'

import { changePublishingType } from '../../../../actions/meetings.actions'
import { PROTOCOL_PUBLISHING_TYPE_ESIGNING, PROTOCOL_PUBLISHING_TYPE_WITHOUT_ESIGNING } from '/shared/constants'

class PublishOrEsignProtocolPanelContainer extends Component {
	static propTypes = {
		signProtocolPanelComponent: object,
		publishProtocolPanelComponent: object
	}

	/**
	 * One of:
	 * - null / undefined / empty string
	 * - PROTOCOL_PUBLISHING_TYPE_ESIGNING
	 * - PROTOCOL_PUBLISHING_TYPE_WITHOUT_ESIGNING
	 */
	changeView = (publishingType) => {
		const { meetingId, changePublishingType } = this.props
		changePublishingType(meetingId, publishingType)
	}

	render = () => {
		const {
			signProtocolPanelComponent,
			publishProtocolPanelComponent,
			publishingType,
			protocolPublished,
			eSigningEnabled,
			protocolMetadata
		} = this.props

		return (
			<>
				{publishingType === PROTOCOL_PUBLISHING_TYPE_ESIGNING && signProtocolPanelComponent}
				{publishingType === PROTOCOL_PUBLISHING_TYPE_WITHOUT_ESIGNING && publishProtocolPanelComponent}
				{!publishingType && (
					<PublishOrEsignProtocolPanel
						eSigningEnabled={eSigningEnabled}
						protocolMetadata={protocolMetadata}
						onChangeView={this.changeView}
					/>
				)}
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meetingId: store.meetings.getIn(['meeting', 'id']),
		publishingType: store.meetings.getIn(['meeting', 'publishingType'], null),
		protocolPublished: Boolean(store.meetings.getIn(['meeting', 'computedValues', 'protocolPublished'])),
		eSigningEnabled: store.subscriptions.getIn(['helper', 'eSigningEnabled'], false),
		protocolMetadata: store.meetings.getIn(['meeting', 'protocolData'])
	}
}

const mapActionsToProps = {
	changePublishingType
}

export default connect(mapStoreToProps, mapActionsToProps)(PublishOrEsignProtocolPanelContainer)
