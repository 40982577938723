import React, { Component } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { func, oneOf, string } from 'prop-types';
import { List } from 'immutable';
import moment from '/shared/helpers/moment.helper';
import { saveMeeting } from '../../../actions/meetings.actions';
import { openModal } from '../../../actions/modals.actions';
import { MEETINGS_END_MEETING_MODAL, MEETINGS_ROLLCALL_MODAL } from '../../../constants/modals';
import { StyledButtonWrapper } from '../../../dumb-components/shared/status-button/status-button';
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item';
import Button from '../../../dumb-components/shared/button/button';
import EndMeetingModalContainer from './end-meeting-modal.container';
import { MEETING_TYPE_STANDARD } from '/shared/constants';
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip';
import { useConfirmContext } from '../../shared/confirm/confirm.context';

const TOOLTIP_STATES = {
	notSecretaryEnd: {
		tid: 'smart_meeting.dropdown.tooltip.end.only_secretary',
		delayShow: 'instant'
	}
};

const EndMeetingContainer = (props) => {
	const confirm = useConfirmContext();
	const isSecretary = useSelector(
		(state) => state.meetings.getIn(['meeting', 'secretary']) === state.user.getIn(['userObj', 'id'])
	);
	const meetingIsStarted = useSelector((state) =>
		state.meetings.getIn(['meeting', 'computedValues', 'meetingIsStarted'])
	);
	const meetingIsFinished = useSelector((state) =>
		state.meetings.getIn(['meeting', 'computedValues', 'meetingIsFinished'])
	);
	const isSimpleMode = useSelector((state) => state.meetings.getIn(['meeting', 'computedValues', 'isSimpleMode']));
	const isStandardMeeting = useSelector(
		(state) => state.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_STANDARD
	);
	const rollcallDone = useSelector((state) => state.meetings.getIn(['meeting', 'metadata', 'rollcallDone']));
	const dispatch = useDispatch();

	const onClick = async () => {
		if (!rollcallDone) {
			const result = await confirm.showConfirmation(
				'meetings.confirm.rollcall_not_done.title',
				'meetings.confirm.rollcall_not_done.message'
			);

			if (result) {
				dispatch(openModal(MEETINGS_ROLLCALL_MODAL, { showEndMeetingModalAfterRollcall: true }));
				return;
			}
		}

		dispatch(openModal(MEETINGS_END_MEETING_MODAL));
	};

	const getTooltipActiveState = () => {
		if (meetingIsStarted && !isSecretary && !isStandardMeeting && !meetingIsFinished) {
			return 'notSecretaryEnd';
		}
	};

	const renderDropdown = () => {
		const btnTid = !meetingIsFinished
			? 'meetings.agenda.end_meeting_button.end_meeting'
			: 'meetings.agenda.end_meeting_button.meeting_has_ended';

		if (!meetingIsStarted) {
			return null;
		}

		const activeState = getTooltipActiveState();

		return (
			<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
				<DropdownIconItem
					icon='faStopCircle'
					onClick={onClick}
					disabled={isSimpleMode || meetingIsFinished}
					tid={btnTid}
				/>
			</Tooltip>
		);
	};

	const renderButton = () => {
		const btnTid = !meetingIsFinished
			? 'meetings.agenda.end_meeting_button.end_meeting'
			: 'meetings.agenda.end_meeting_button.meeting_has_ended';
		const btnDisabled = !isSecretary || meetingIsFinished || !meetingIsStarted;

		return <Button mode='jaded' minWidth='200px' disabled={btnDisabled} onClick={onClick} tid={btnTid} />;
	};

	return (
		<>
			{props.mode === 'dropdown' && renderDropdown()}
			{props.mode === 'button' && renderButton()}
			<EndMeetingModalContainer basePath={props.basePath} />
		</>
	);
};

EndMeetingContainer.propTypes = {
	mode: oneOf(['dropdown', 'button']),
	basePath: string.isRequired
};

export default EndMeetingContainer;
