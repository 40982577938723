import React, { PureComponent } from 'react'
import { bool, object } from 'prop-types'
import styled, { css } from 'styled-components'
import Icon from '../icon/icon'

const DragHandler = styled.div`
	min-width: 16px;
	width: 16px;
	height: 100%;
	background: ${(props) => (props.nakedStyle ? 'transparent' : props.theme.colors.solitudeMid)};
	cursor: ${(props) => (!props.isDragDisabled ? 'pointer !important' : 'default')};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	span[class*='fa-'] {
		display: none;
	}
`

export const hoverStyling = css`
	cursor: ${(props) => (!props.isDragDisabled ? 'move !important' : 'default')};

	span[class*='fa-'] {
		${(props) =>
			!props.isDragDisabled &&
			css`
				display: block;
			`}
	}
`

export default class DragHandlers extends PureComponent {
	static propTypes = {
		dragHandleProps: object,
		isDragDisabled: bool
	}

	render = () => {
		const { dragHandleProps, className } = this.props

		return (
			<DragHandler className={className} {...(dragHandleProps && dragHandleProps)}>
				<Icon icon='faArrowsAlt' type='solid' size='10' color='midGrey' />
			</DragHandler>
		)
	}
}
