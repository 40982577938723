import React, { PureComponent } from 'react'
import { string, bool, func, object, number } from 'prop-types'
import styled, { css } from 'styled-components'
import { map } from 'react-immutable-proptypes'

import Panel from '../../shared/panel/panel'
import DarkInfoPanel from '../../shared/dark-info-panel/dark-info-panel'
import Text from '../../shared/text/text'
import Alert from '../../shared/alert/alert'
import { Margin } from 'styled-components-spacing'
import AttendeeResponseStatusLegend from '../attendee-response-status-legend/attendee-response-status-legend'

const InnerWrapper = styled.div`
	margin-left: ${(props) => props.theme.spacing[props.innerMarginLeft]};
	margin-top: ${(props) => props.theme.spacing[props.innerMarginTop]};
	margin-right: ${(props) => props.theme.spacing[props.innerMarginRight]};
	margin-bottom: ${(props) => props.theme.spacing[props.innerMarginBottom]};
`

const StyledListWrapper = styled.div`
	display: flex;
	flex-direction: column;

	${(props) =>
		props.topMargin &&
		css`
			margin-top: ${(props) => props.theme.spacing[3]};
		`}
`

const AlertInnerWrapper = styled.div`
	display: flex;
	flex-direction: column;

	> :first-child {
		margin-bottom: ${(props) => props.theme.spacing[4]};
	}
`

const WhiteText = (props) => <Text color='white' {...props} />

export default class AttendeesPanel extends PureComponent {
	static propTypes = {
		noPanel: bool,
		headerRightComponent: object,
		bottomComponent: object,
		invitedAttendeesListComponent: object,
		attendees: map,
		showSummary: bool,
		customInfoTextComponent: object,
		hasMeetingPassed: bool,
		innerMarginLeft: number,
		innerMarginTop: number,
		innerMarginRight: number,
		innerMarginBottom: number,
		isStandardMeeting: bool,
		meetingHasModerator: bool,
		attendeesWarningComponent: object,
		rollcallDone: bool,
		onManageAdministrators: func.isRequired
	}

	static defaultProps = {
		innerMarginLeft: 4,
		innerMarginTop: 4,
		innerMarginRight: 4,
		innerMarginBottom: 4
	}

	getDarkInfoPanelProps = () => {
		const { headerRightComponent, bottomComponent, showSummary, meetingHasModerator, isStandardMeeting, rollcallDone } =
			this.props

		if (!meetingHasModerator && isStandardMeeting) {
			return {
				headerRightComponent,
				headerLeftComponent: this.getInfoText()
			}
		}

		return {
			headerRightComponent,
			headerLeftComponent: this.getInfoText(),
			footerComponent: bottomComponent
		}
	}

	getInnerWrapperProps = () => {
		const { innerMarginTop, innerMarginRight, innerMarginBottom, innerMarginLeft } = this.props

		return {
			innerMarginTop,
			innerMarginRight,
			innerMarginBottom,
			innerMarginLeft
		}
	}

	getInfoText = () => {
		const { showSummary, hasMeetingPassed, meetingHasModerator, rollcallDone } = this.props
		let { attendees } = this.props
		const noAttendees = !attendees.size

		attendees = attendees.filter((attendee) => {
			return !attendee.get('isProxy')
		})

		const partiallyNotified = attendees.some((a) => a.get('notified'))
		const everyoneNotified = attendees.every((a) => a.get('notified'))

		if (rollcallDone) {
			return <WhiteText tid='meeting.attendees.panel.info_text.pre_add' />
		}

		if (showSummary) {
			return <WhiteText tid='meetings.attendees.panel.info_text.summary' />
		}

		if (hasMeetingPassed) {
			return <WhiteText tid='meetings.attendees.panel.info_text.cant_invite_post_meeting' />
		}

		if (noAttendees || !meetingHasModerator) {
			return <WhiteText tid='meeting.attendees.panel.info_text.pre_add' />
		}

		if (everyoneNotified) {
			return <WhiteText tid='meeting.attendees.panel.info_text.post_invite' />
		}

		if (!partiallyNotified) {
			return <WhiteText tid='meeting.attendees.panel.info_text.pre_invite' />
		}

		if (partiallyNotified) {
			return <WhiteText tid='meeting.attendees.panel.info_text.pre_invite_partial' />
		}
	}

	renderAttendeesList = () => {
		const { showSummary, attendees, invitedAttendeesListComponent, summaryAttendeesListComponent } = this.props
		const hasAttendees = attendees.size

		return (
			<StyledListWrapper topMargin={hasAttendees}>
				{showSummary ? summaryAttendeesListComponent : invitedAttendeesListComponent}
			</StyledListWrapper>
		)
	}

	renderContent = () => {
		const {
			meetingHasModerator,
			isStandardMeeting,
			attendees,
			attendeesWarningComponent,
			invitedAttendeesListComponent,
			showSummary,
			rollcallDone,
			onManageAdministrators
		} = this.props
		const innerWrapperProps = this.getInnerWrapperProps()
		const darkInfoPanelProps = this.getDarkInfoPanelProps()
		const attendeesExist = attendees.size > 0

		return (
			<InnerWrapper {...innerWrapperProps}>
				<DarkInfoPanel {...darkInfoPanelProps} />

				{isStandardMeeting && !meetingHasModerator && attendeesExist && (
					<Margin top={3}>
						<Alert mode='warning' icon='faExclamationTriangle' iconType='solid' alignItems='flex-start'>
							<AlertInnerWrapper>
								<Text color='white' tid='meetings.attendees.alert.no_moderator' />
								<Text
									color='white'
									tid='meetings.attendees.alert.no_moderator.info'
									values={{
										value: (
											<Text
												tid='meetings.attendees.alert.no_moderator.info.value'
												color='white'
												onClick={onManageAdministrators}
												hasUnderline
											/>
										)
									}}
								/>
							</AlertInnerWrapper>
						</Alert>
					</Margin>
				)}

				{attendeesWarningComponent && meetingHasModerator && <Margin top={3}>{attendeesWarningComponent}</Margin>}

				{attendees.size > 0 && !showSummary && (
					<Margin top={3}>
						<AttendeeResponseStatusLegend />
					</Margin>
				)}

				{this.renderAttendeesList()}
			</InnerWrapper>
		)
	}

	render = () => {
		const { noPanel } = this.props

		if (noPanel) {
			return this.renderContent()
		}

		return (
			<Panel title='meetings.standard_meeting.attendees_panel.title' marginBottom>
				{this.renderContent()}
			</Panel>
		)
	}
}
