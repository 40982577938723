import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import { setInvoiceReciever } from '../../actions/subscriptions.actions'
import CompanyMembersSelectContainer from '../company/company-members-select.container'
import NotificationPanel from '../../dumb-components/subscriptions/notification-panel/notification-panel'
import { ACCOUNT_OWNER } from '/shared/constants'

class NotificationPanelContainer extends Component {
	onChange = (fieldName, val) => {
		const { setInvoiceReciever } = this.props

		setInvoiceReciever(val)
	}

	renderCompanyMembersContainer = () => {
		const { invoiceReciever, i18n } = this.props

		const prependOptions = List([
			Map({
				value: ACCOUNT_OWNER,
				label: i18n.messages['subscriptions.notification_panel.options.company_owner']
			})
		])

		return (
			<CompanyMembersSelectContainer
				fieldName='invoiceReciever'
				onChange={this.onChange}
				value={invoiceReciever}
				isClearable={false}
				prependOptions={prependOptions}
				defaultValue={ACCOUNT_OWNER}
				labelIsTid={false}
			/>
		)
	}

	render = () => {
		const { hasSources } = this.props

		if (!hasSources) {
			return null
		}

		return <NotificationPanel companyMembersContainer={this.renderCompanyMembersContainer()} />
	}
}

const mapStoreToProps = (store) => {
	return {
		invoiceReciever: store.subscriptions.getIn(['subscription', 'invoiceReciever']),
		hasSources: store.subscriptions.getIn(['subscription', 'stripeData', 'sources', 'total_count']) > 0,
		i18n: store.i18n
	}
}

const mapActionsToProps = {
	setInvoiceReciever
}

export default connect(mapStoreToProps, mapActionsToProps)(NotificationPanelContainer)
