import React, { Component } from 'react'
import { string, bool, number, oneOfType, func, object } from 'prop-types'
import NumberFormat from 'react-number-format'
import { withTranslation } from 'react-i18next'
import FieldError from '../shared/field-error/field-error'
import { svConfig } from '/shared/helpers/number-formatter.helper'
import { NUMBER_FORMAT_DEFAULT_INPUT } from '/shared/constants'

class NumericInput extends Component {
	static propTypes = {
		className: string,
		value: oneOfType([string, number]),
		type: string,
		thousandSeparator: string,
		decimalSeparator: string,
		decimalScale: number,
		disabled: bool,
		placeholder: string,
		allowSpecialSymbols: bool,
		hasError: string,
		onErrorRef: func,
		allowDecimals: bool,
		numberformatter: object
	}

	static defaultProps = {
		className: '',
		type: 'text',
		thousandSeparator: svConfig[NUMBER_FORMAT_DEFAULT_INPUT].integerSeparator,
		decimalSeparator: svConfig[NUMBER_FORMAT_DEFAULT_INPUT].decimal,
		decimalScale: null,
		disabled: false,
		allowSpecialSymbols: false,
		value: null,
		allowDecimals: false,
		numberformatter: svConfig[NUMBER_FORMAT_DEFAULT_INPUT]
	}

	onKeyDown = (e) => {
		const { allowSpecialSymbols, decimalSeparator, allowDecimals, numberformatter } = this.props
		let { decimalScale } = this.props
		const SPECIAL_SYMBOLS = ['-', '+']
		const { key } = e

		if (!decimalScale) {
			decimalScale = allowDecimals ? numberformatter.round : 0
		}

		// Disallow comma
		if (!decimalScale && key == decimalSeparator) {
			e.preventDefault()
		}

		// Disallow symbols inside SPECIAL_SYMBOLS by default
		if (!allowSpecialSymbols && SPECIAL_SYMBOLS.includes(key)) {
			e.preventDefault()
		}
	}

	onChange = (val) => {
		const { value, onChange } = this.props

		if (val !== value) {
			onChange(val)
		}
	}

	render = () => {
		const {
			className,
			value,
			type,
			thousandSeparator,
			decimalSeparator,
			disabled,
			placeholderTid,
			t,
			hasError,
			onErrorRef,
			allowDecimals,
			numberformatter
		} = this.props
		let { placeholder, decimalScale } = this.props

		if (placeholderTid) {
			placeholder = t(placeholderTid)
		}

		if (!decimalScale) {
			decimalScale = allowDecimals ? numberformatter.round : 0
		}

		return (
			<FieldError hasError={hasError} onSetRef={onErrorRef}>
				<NumberFormat
					type={type}
					placeholder={placeholder}
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					decimalScale={decimalScale}
					className={className}
					onKeyDown={this.onKeyDown}
					value={value}
					disabled={disabled}
					onValueChange={(values) => {
						this.onChange(values.floatValue)
					}}
				/>
			</FieldError>
		)
	}
}

export default withTranslation()(NumericInput)
