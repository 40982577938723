import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { getSingleEmission, getSubscriptionSlip, renameEmission } from '../../../actions/emissions.actions'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Preparations from './preparations/preparations'
import Marketing from './marketing/marketing'
import EmissionsBaseLayout from './emission-base-layout'
import EmissionNameModal from './emission-name-modal'
import { useTranslation } from 'react-i18next'
import FullPageSpinner from '../common/full-page-spinner'
import SubscriptionList from './subscription-list'
import Invitation from './invitation/invitation'
import SubscriptionForm from './subscription-form/subscription-form'
import Allocation from './allocation/allocation'
import Payment from './payment/payment'
import Cookies from 'universal-cookie'
import Registration from './registration/registration'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'

const OurTab = (props) => {
	return (
		<Tooltip tid={'emissions.has-preparation-error'} delayShow='instant' active={!!props.disabled}>
			<Tab
				{...props}
				sx={{
					textTransform: 'none',
					'&.Mui-selected': {
						fontWeight: 'bold'
					}
				}}
			/>
		</Tooltip>
	)
}

const tabs = [
	(emission) => <Preparations emission={emission} />,
	(emission) => <SubscriptionForm emission={emission} />,
	(emission) => <Marketing emission={emission} />,
	(emission) => <Invitation emission={emission} />,
	(emission) => <SubscriptionList emission={emission} />,
	(emission) => <Allocation emission={emission} />,
	(emission) => <Payment emission={emission} />,
	(emission) => <Registration emission={emission} />
]

const TabNavigation = [
	{ tab: 'preparations', value: 0 },
	{ tab: 'subscription-form', value: 1 },
	{ tab: 'marketing', value: 2 },
	{ tab: 'invitation', value: 3 },
	{ tab: 'subscriptions', value: 4 },
	{ tab: 'allocation', value: 5 },
	{ tab: 'payment', value: 6 },
	{ tab: 'registration', value: 7 }
]

const EmissionViewForAdmin = (props) => {
	const { id } = useParams()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const companyId = useSelector((state) => state.company?.company?.id)
	const emission = useSelector((state) => state.emissions.current)
	const subscriptionSlip = useSelector((state) => state.emissions.subscriptionSlip)
	const hasPreparationError = useSelector((state) => state.emissions.hasPreparationError)

	const urlPaths = location.pathname.split('/')
	const activeTabPath = urlPaths[urlPaths.length - 1]
	const activeTab = TabNavigation.find((t) => t.tab === activeTabPath)
	const activeTabValue = activeTab ? activeTab.value : 0

	const [tabIndex, setTabIndex] = useState(activeTabValue)
	const [showNameModal, setShowNameModal] = useState(false)

	useEffect(() => {
		const accessToken = new Cookies().get('accessToken')
		if (!accessToken) {
			location.replace('/')
		}

		setTabIndex(activeTabValue)
	}, [activeTabValue])

	useEffect(() => {
		if (companyId) {
			dispatch(getSingleEmission(companyId, id))
			dispatch(getSubscriptionSlip(companyId, id))
		}
	}, [companyId])

	if (!emission || !subscriptionSlip) {
		return <FullPageSpinner text={t('emissions.loading-emission')} />
	}

	return (
		<EmissionsBaseLayout>
			<EmissionNameModal
				open={showNameModal}
				initialValues={{ name: emission.name }}
				close={() => setShowNameModal(false)}
				onSubmit={async (values) => {
					await dispatch(renameEmission(companyId, id, values))
					setShowNameModal(false)
				}}
				title={t('emissions.change-name')}
				buttonText={t('emissions.change-name.short')}
			/>
			<Grid container>
				<Grid item xs={12} sx={{ mb: 3 }}>
					<Box>
						<Typography variant='overline' sx={{ fontSize: '10px' }}>
							{t('emissions.create-emission')}
						</Typography>
					</Box>
					<Box>
						<Typography variant='h3'>
							{emission.name}
							<IconButton color='primary' sx={{ ml: 3 }} onClick={() => setShowNameModal(true)}>
								<EditOutlinedIcon />
							</IconButton>
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box sx={{ position: 'relative' }}>
						<Box
							sx={{
								position: 'absolute',
								borderBottom: 2,
								borderColor: 'blue.300',
								width: '100%',
								bottom: '0',
								zIndex: 1
							}}
						/>
						<Tabs
							sx={{ position: 'relative' }}
							value={tabIndex}
							onChange={(_, newValue) => {
								setTabIndex(newValue)
								const alteredUrl = props.match.params.tab
									? props.match.url.replace('/' + props.match.params.tab, '')
									: props.match.url
								window.history.replaceState(
									null,
									'',
									alteredUrl + '/' + TabNavigation.find((t) => t.value === newValue).tab
								)
							}}>
							<OurTab label={t('emissions.tabs.preparations')} value={0} />
							<OurTab
								label={t('emissions.tabs.subscription-form')}
								value={1}
								disabled={emission.status === 'active'}
							/>
							<OurTab
								label={t('emissions.tabs.marketing')}
								value={2}
								disabled={emission.status === 'active'}
							/>
							<OurTab
								label={t('emissions.tabs.invitation')}
								value={3}
								disabled={emission.status === 'active'}
							/>
							<OurTab
								label={t('emissions.tabs.subscriptions')}
								value={4}
								disabled={emission.status === 'active'}
							/>
							<OurTab
								label={t('emissions.tabs.allocation')}
								value={5}
								disabled={emission.status === 'active'}
							/>
							<OurTab
								label={t('emissions.tabs.payment')}
								value={6}
								disabled={emission.status === 'active'}
							/>
							<OurTab
								label={t('emissions.tabs.registration')}
								value={7}
								disabled={emission.status === 'active'}
							/>
						</Tabs>
					</Box>
					<Box sx={{ mt: 4 }}>{tabs[tabIndex](emission)}</Box>
				</Grid>
			</Grid>
		</EmissionsBaseLayout>
	)
}

export default EmissionViewForAdmin
