import React, { PureComponent } from 'react'
import { object, bool } from 'prop-types'
import styled from 'styled-components'
import { map } from 'react-immutable-proptypes'
import { Map } from 'immutable'

import Label from '../../shared/label/label'
import Text from '../../shared/text/text'

import ColoredContentWrapper from '../../shared/colored-content-wrapper/colored-content-wrapper'

const ErrorMessageWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[2]};
`

const SynaComponentWrapper = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`

const NotSharedWithCompaniesMessage = styled(Text)`
	margin: ${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[5]};
	margin-bottom: 0;
	display: flex;
	justify-content: center;
`

export default class FolderShareControls extends PureComponent {
	static propTypes = {
		synaSearchComponent: object,
		companyData: map,
		showSubscriptionMessage: bool,
		subscriptionLinkComponent: object,
		sharedWithCompanies: map
	}

	static defaultProps = {
		sharedWithCompanies: Map()
	}

	renderErrorMessage = () => {
		const { companyData } = this.props

		if (!companyData) {
			return null
		}

		const name = companyData.get('name')
		const orgNumber = companyData.get('orgNumber')

		return (
			<ErrorMessageWrapper>
				<Text
					color='red'
					tid='documents.folder.create_manage.sharing.error.no_company_registered'
					values={{ name, orgNumber }}
				/>
			</ErrorMessageWrapper>
		)
	}

	renderNeedToSubscribeMessage = () => {
		const { subscriptionLinkComponent, synaSearchComponent } = this.props

		return (
			<StyledColoredContentWrapper type='disabledLightGrey'>
				<SynaComponentWrapper>
					<Label tid='documents.folder.create_manage.sharing.syna.label'>{synaSearchComponent}</Label>
				</SynaComponentWrapper>
				<Text tid='documents.folder.create_manage.sharing.alert.upgrade_to_use' />
				{subscriptionLinkComponent}
			</StyledColoredContentWrapper>
		)
	}

	render = () => {
		const { synaSearchComponent, showSubscriptionMessage, sharedWithCompanies } = this.props

		if (showSubscriptionMessage) {
			return this.renderNeedToSubscribeMessage()
		}

		return (
			<>
				<StyledColoredContentWrapper type='secondary'>
					<SynaComponentWrapper>
						<Label tid='documents.folder.create_manage.sharing.syna.label'>{synaSearchComponent}</Label>
						{this.renderErrorMessage()}
					</SynaComponentWrapper>
					<Text tid='documents.manage_folder.share_folder_with_companies.info' />
				</StyledColoredContentWrapper>
				{!sharedWithCompanies.size && (
					<NotSharedWithCompaniesMessage tid='documents.folder.create_manage.sharing.empty_list' hasItalic />
				)}
			</>
		)
	}
}
