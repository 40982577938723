import React from 'react'
import { func, bool } from 'prop-types'
import { Padding } from 'styled-components-spacing'
import Text from '../../shared/text/text'
import Panel from '../../shared/panel/panel'
import Button from '../../shared/button/button'
import Box from '../../shared/layout/box/box'

const CancelAccountPanel = ({ onCancelAccount, isLoading }) => {
	return (
		<Panel mode='light' title='subscriptions.cancel_account' marginBottom>
			<Padding all={4}>
				<Text tag='p' tid='subscriptions.when_you_cancel_company_account' />
				<Box alignItems='left'>
					<Button
						isLoading={isLoading}
						onClick={onCancelAccount}
						minWidth='160px'
						tid='subscriptions.button.cancel_account'
					/>
				</Box>
			</Padding>
		</Panel>
	)
}

CancelAccountPanel.defaultProps = {
	isLoading: false
}

CancelAccountPanel.propTypes = {
	isLoading: bool,
	onCancelAccount: func
}

export default CancelAccountPanel
