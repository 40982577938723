import companyHelper from '/shared/helpers/company.helper'
import meetingHelpers from '/shared/meetings/helpers'

export const SYNA_COMPANY_ROLES = companyHelper.companyRoles

export const MEETING_ROLES_ADMINISTRATIVE = meetingHelpers.administrativeRoles

export const SYNA_AUTHORISATION_ROLES = ['BO', 'EFT', 'EVD', 'LE', 'OF', 'PO', 'VD', 'ENSKILD_FIRMA']

export const getMeetingRolesExtendedAsOptions = (withGroup) => {
	const options = []

	if (withGroup) {
		options.push({ label: 'meeting.attendee.details.functions.group.meeting', options: [] })
	}

	for (let key in MEETING_ROLES_ADMINISTRATIVE) {
		if (MEETING_ROLES_ADMINISTRATIVE.hasOwnProperty(key)) {
			options[0].options.push({ value: key, label: MEETING_ROLES_ADMINISTRATIVE[key] })
		}
	}
	return options
}

export const getSynaCompanyRolesAsOptions = () => {
	const options = []

	for (let key in SYNA_COMPANY_ROLES) {
		if (SYNA_COMPANY_ROLES.hasOwnProperty(key)) {
			options.push({ value: key, label: SYNA_COMPANY_ROLES[key] })
		}
	}

	return options.sort((a, b) => {
		return a.label.localeCompare(b.label)
	})
}
