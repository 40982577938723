/**
 * Wiki edit
 * @module components/wiki/wiki--edit-content-modal
 */

import React, { Component } from 'react'
import TinyMCEInput from 'react-tinymce-input'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { editWiki } from '../../actions/wiki.action'
import { hideModal } from '../../actions/modals.actions'

/** Class representing wiki edit. */
class WikiEditContentModal extends Component {
	/**
	 * Constructor
	 */
	constructor(props) {
		super(props)

		this.state = { content: props.text }
	}

	/**
	 * Update component state with content from TinyMCE editor
	 */
	handleEditorChange(value) {
		this.setState({ content: value })
	}

	/**
	 * On submit, update or create wiki
	 * @param {Object} props — object with wiki content
	 */
	onSubmit(event) {
		event.preventDefault()

		this.props.onEditWiki(this.state.content, this.props.id, this.props.lang)
		this.props.onClose();
	}

	/**
	 * Render
	 */
	render() {
		const language = this.props.i18n.language
		const TinyMceConfig = {
			plugins: 'link image code fontawesome noneditable',
			toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | fontawesome | code',
			noneditable_noneditable_class: 'fa',
			extended_valid_elements: 'span[*]',
			height: 450,
			content_css: '/assets/build/styles.css',
			language: language.replace('-', '_')
		}

		return (
			<div>
				<div
					className='modal in'
					role='dialog'
					tabIndex='-1'
					aria-labelledby='demo-default-modal'
					aria-hidden='true'
					style={{ display: 'block' }}>
					<div className='modal-dialog modal-lg'>
						<div className='modal-content'>
							<form onSubmit={this.onSubmit.bind(this)} autoComplete='off'>
								<div className='modal-header'>
									<h4 className='modal-title'>
										<FormattedMessage id={'wiki.handle_wiki_content'} />
									</h4>
								</div>

								<div className='modal-body'>
									<TinyMCEInput
										value={this.state.content}
										onChange={this.handleEditorChange.bind(this)}
										tinymceConfig={TinyMceConfig}
									/>
								</div>

								<div className='modal-footer'>
									<button className='btn btn-default' onClick={this.props.onClose}>
										<FormattedMessage id={'generic.form.cancel'} />
									</button>
									<button className='btn btn-primary' type='submit'>
										<FormattedMessage id={'generic.form.save'} />
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className='modal-backdrop in'></div>
			</div>
		)
	}
}

/** Map state to props. */
function mapStateToProps(state) {
	return {
		i18n: state.i18n
	}
}

function mapDispatchToProps(dispatch) {
	return {
		onClose: () => dispatch(hideModal()),
		onEditWiki: (content, id, lang) => dispatch(editWiki(content, id, lang))
	}
}

/** Export */
export default connect(mapStateToProps, mapDispatchToProps)(WikiEditContentModal)
