import React from 'react';
import { string, bool, func, node, oneOfType, object, oneOf } from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Box } from '@mui/material';
import FooterRightControls from '../../dumb-components/shared/modal/footer-right-controls';
import { FormattedMessage } from 'react-intl';
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import Tooltip from '../../../app/mui-components/tooltip/tooltip';
import Button from '../../../app/mui-components/button/button';
import InfoSidebar from '../../dumb-components/helpscout/info-sidebar';

const MUIDialog = ({
	children,
	isOpen,
	onClose,
	onConfirm,
	title,
	confirmButton,
	confirmButtonTid,
	closeButtonTid,
	disabledConfirm,
	maxWidth,
	fullWidth,
	flexContent,
	optionsButton,
	optionsButtonProps,
	optionsButtonMenu,
	paperProps,
	tooltipStates,
	tooltipActiveState,
	leftSideLinkValidation,
	autoHeight,
	variant,
	header,
	renderConfirmButton,
	closeButton,
	height,
	infoArticle
}) => {
	const popupState = usePopupState({
		variant: 'popover',
		popupId: 'demoMenu'
	});

	const _height = () => {
		if (autoHeight) {
			return 'auto';
		} else if (height) {
			return height;
		} else {
			return undefined;
		}
	};

	return (
		<Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth} PaperProps={paperProps}>
			{!header && (
				<DialogTitle sx={{ ...(infoArticle && { display: 'flex', alignItems: 'center' }) }}>
					<>
						{title}
						{infoArticle && (
							<Box sx={{ justifyContent: 'flex-end', flex: 1, display: 'flex' }}>
								<InfoSidebar article={infoArticle} />
							</Box>
						)}
					</>
				</DialogTitle>
			)}
			{header || null}
			<DialogContent
				variant={variant}
				sx={{
					display: flexContent ? 'flex' : 'block',
					height: _height()
				}}>
				{children}
			</DialogContent>
			<DialogActions sx={leftSideLinkValidation && { justifyContent: 'space-between' }}>
				{leftSideLinkValidation && leftSideLinkValidation}
				<Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
					<FooterRightControls>
						{confirmButton && renderConfirmButton && renderConfirmButton()}

						{confirmButton && !renderConfirmButton && (
							<Tooltip
								states={tooltipStates}
								activeState={tooltipActiveState}
								hideTooltip={!disabledConfirm}>
								<Button
									onClick={() => onConfirm(popupState)}
									color='positive'
									disabled={disabledConfirm}>
									<FormattedMessage id={confirmButtonTid} />
								</Button>
							</Tooltip>
						)}

						{confirmButton && closeButton && <Divider orientation='vertical' flexItem light />}
						{optionsButton ? (
							<div>
								<Button disabled={disabledConfirm} {...bindHover(popupState)}>
									<FormattedMessage id={optionsButtonProps?.tid} />
								</Button>
								<HoverMenu
									{...bindMenu(popupState)}
									anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
									transformOrigin={{ vertical: 'top', horizontal: 'left' }}
									transitionDuration={0}>
									{typeof optionsButtonMenu === 'function' &&
										optionsButtonMenu(popupState).map((item, index) => (
											<div key={index}>{item}</div>
										))}
								</HoverMenu>
							</div>
						) : (
							<>
								{closeButton && (
									<Button onClick={onClose}>
										<FormattedMessage id={closeButtonTid} />
									</Button>
								)}
							</>
						)}
					</FooterRightControls>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

MUIDialog.propTypes = {
	isOpen: bool,
	onClose: func,
	children: node,
	confirmButton: bool,
	onConfirm: func,
	optionsButton: bool,
	optionsButtonProps: oneOfType([bool, object]),
	optionsButtonMenu: func,
	paperProps: object,
	tooltipStates: object,
	tooltipActiveState: string,
	leftSideLinkValidation: object,
	autoHeight: bool,
	header: node,
	maxWidth: oneOfType([string, oneOf(['xs', 'sm', 'md', 'lg', 'xl', false])]),
	fullWidth: bool,
	renderConfirmButton: func,
	height: string
};

MUIDialog.defaultProps = {
	confirmButtonTid: 'dialog.generic.open',
	closeButtonTid: 'dialog.generic.cancel',
	disabledConfirm: false,
	confirmButton: true,
	fullWidth: true,
	closeButton: true
};

export default MUIDialog;
