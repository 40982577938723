import React, { PureComponent } from 'react'
import { string, node, object } from 'prop-types'
import styled, { css } from 'styled-components'
import ScrollView from '../../../shared/layout/scroll-view/scroll-view'

import Text from '../../../shared/text/text'

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
`

const LeftColumn = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 600px) {
		display: none;
	}
`

const UserImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const UserImage = styled.img`
	height: 175px;
	width: auto;
`

const RightColumn = styled.div`
	display: flex;
	flex: 2;
`

const RightColumnInner = styled.div`
	display: flex;
	flex: 2;
	flex-direction: column;
	justify-content: center;
	padding-left: ${(props) => props.theme.spacing[4]};
	padding-right: ${(props) => props.theme.spacing[6]};
	padding-bottom: ${(props) => props.theme.spacing[4]};
	padding-top: ${(props) => props.theme.spacing[4]};
	justify-content: center;

	@media only screen and (max-width: 600px) {
		padding-left: ${(props) => props.theme.spacing[6]};
	}
`

const StyledHeader = styled.div`
	h1 {
		margin-top: 0;
	}
`

const InfoBlock = styled.div``

const SubText = styled.div`
	${(props) => css`
		margin-bottom: ${props.marginBottom ? props.theme.spacing[props.marginBottom] : 0};
	`}
`

/**
 * Exported sub components
 */

export const Header = ({ tid }) => {
	return (
		<StyledHeader>
			<Text tag='h1' tid={tid} />
		</StyledHeader>
	)
}

export const Entry = ({ titleTid, titleValues, subTextTid, subTextValues, bottomComponent }) => {
	return (
		<InfoBlock>
			{titleTid && <Text tag='h3' tid={titleTid} values={titleValues} />}

			<SubText marginBottom={bottomComponent ? 4 : 0}>
				<Text tag='p' tid={subTextTid} values={subTextValues} />
			</SubText>

			{bottomComponent && bottomComponent}
		</InfoBlock>
	)
}

export default class LeftRightLayoutComponent extends PureComponent {
	static propTypes = {
		image: string,
		children: node,
		titleTid: string,
		titleValues: object,
		subTextTid: string,
		subTextValues: object,
		bottomComponent: object
	}

	render = () => {
		const { image, children } = this.props

		return (
			<Wrapper>
				<LeftColumn>
					<UserImageContainer>
						<UserImage src={image} />
					</UserImageContainer>
				</LeftColumn>

				<RightColumn>
					<ScrollView noRightMargin noLeftMargin fillContent alwaysShow>
						<RightColumnInner>{children}</RightColumnInner>
					</ScrollView>
				</RightColumn>
			</Wrapper>
		)
	}
}
