import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { fromJS, Map, List } from 'immutable'
import debounce from 'lodash/debounce'
import ScrollView from '../../../../dumb-components/shared/layout/scroll-view/scroll-view'

import {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	cancelTemporaryTransaction
} from '../../../../actions/transaction.actions'
import { addErrorNotification, addInfoNotification } from '../../../../actions/notify.actions'
import DatePicker from '../../../../dumb-components/shared/datepicker/datepicker'
import Panel from '../../../../dumb-components/panel'
import Field from '../../../../dumb-components/fields/field'
import { getFullShareTypeLabel } from '../../../helpers/shares'
import { validateTransactionDate, isRequired, validateisRequiredImmutable } from '../../../../modules/validation.module'
import immutableForm from '../../../framework/immutable-form'
import NumericInput from '../../../../dumb-components/fields/numeric-input'
import TransactionToolbarControlsContainer from '../../../../containers/shares/transaction-toolbar-controls.container'

const transactionType = 'CHANGE-VOTES'

const initialTransaction = fromJS({
	type: transactionType,
	handlerData: {}
})

const de = debounce((func, type, transaction) => {
	func(type, transaction.toJS())
}, 1000)

class AdjustmentOfVotesForm extends Component {
	componentDidMount() {
		this.props.fetchTemporaryTransaction(transactionType)
	}

	onChange = (field, val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction, resetErrors } = this.props
		const newTransaction = tmpTransaction.setIn(['handlerData', field], val)
		resetErrors('handlerData.' + field)
		updateTransaction(transactionType, newTransaction)
		de(saveTemporaryTransaction, transactionType, newTransaction)
	}

	onChangeDate = (val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction, resetErrors } = this.props
		const newTransaction = tmpTransaction.set('date', val)
		resetErrors('date')
		updateTransaction(transactionType, newTransaction)
		de(saveTemporaryTransaction, transactionType, newTransaction)
	}

	onChangeShareType = (type, val) => {
		const { tmpTransaction } = this.props
		let shareTypes = tmpTransaction.getIn(['handlerData', 'types'], List())
		const index = shareTypes.findIndex((obj) => {
			return obj.get('type') === type
		})

		if (index >= 0 && !val) {
			shareTypes = shareTypes.remove(index)
		} else if (index >= 0) {
			shareTypes = shareTypes.setIn([index, 'votes'], val)
		} else {
			shareTypes = shareTypes.push(
				Map({
					type,
					votes: val
				})
			)
		}

		this.onChange('types', shareTypes)
	}

	cancelTransaction = () => {
		this.props.cancelTemporaryTransaction(transactionType)
	}

	onSubmit = () => {
		const { tmpTransaction, createTransaction } = this.props

		if (this.props.validate(tmpTransaction)) {
			createTransaction(tmpTransaction.toJS())
		}
	}

	getVotesPerShare = (type) => {
		const { tmpTransaction } = this.props
		const shareType = tmpTransaction.getIn(['handlerData', 'types'], List()).find((obj) => {
			return obj.get('type') === type
		})

		return shareType ? shareType.get('votes') : null
	}

	renderButtons = () => {
		return (
			<div className='i-content__tabs i-content__tabs--fix-padding'>
				<TransactionToolbarControlsContainer onSubmit={this.onSubmit} onCancel={this.cancelTransaction} />
			</div>
		)
	}

	renderShareType = (shareType, index) => {
		const { errors } = this.props
		const shareTypeName = getFullShareTypeLabel(shareType.get('type'))
		const newVotesPerShare = this.getVotesPerShare(shareType.get('type'))

		const hasError = errors ? errors.get('handlerData.types', List()).size > 0 : false

		return (
			<div className='list__item' key={index}>
				<div className='list__item__body'>
					<span className='list__item__text'>{shareTypeName}</span>
				</div>
				<div className='list__item__text-area list__item__text-area--x2'>
					<span className='list__item__input list__item__input--pad-right text--align-right'>
						<input
							type='text'
							value={shareType.get('votesPerShare') || ''}
							className='form-control text--align-right'
							disabled
						/>
					</span>
				</div>
				<div className={`list__item__text-area list__item__text-area--x2 ${hasError && 'has-error'} `}>
					<span className='list__item__input list__item__input--pad-right text--align-right'>
						<NumericInput
							className='form-control text--align-right'
							value={newVotesPerShare === null ? '' : newVotesPerShare}
							allowDecimals
							onChange={(val) => {
								this.onChangeShareType(shareType.get('type'), val)
							}}
						/>
					</span>
				</div>
			</div>
		)
	}

	renderShareTypes = () => {
		const { transaction } = this.props

		if (!transaction) {
			return null
		}

		return (
			<>
				<div className='list__list-header'>
					<div className='list__list-header__body list__list-header__text-area--pad-lft list__list-header__text-area--pad-right'>
						<FormattedMessage id='shares.transactions.adjustment_of_votes.class_of_shares' />
					</div>
					<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
						<FormattedMessage id='shares.transactions.adjustment_of_votes.total_votes' />
					</div>
					<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
						<FormattedMessage id='shares.transactions.adjustment_of_votes.convert_to' />
					</div>
				</div>
				<div className='list list--table '>
					{transaction.getIn(['shareData', 'types'], List()).map(this.renderShareType)}
				</div>
			</>
		)
	}

	render = () => {
		const { tmpTransaction, errors, i18n } = this.props

		if (!tmpTransaction) {
			return null
		}

		return (
			<div className='i-content__container'>
				{this.renderButtons()}
				<ScrollView autoHide={true} showOnHover={true}>
					<Panel tid='adjustment_of_votes'>
						<Field name='date' errors={errors} tid='generic.date' className='col-md-5 col-md-offset-7'>
							<DatePicker
								hasError={errors ? errors.get('date', List()).size > 0 : false}
								calendarPlacement='bottom-end'
								value={tmpTransaction.get('date')}
								onChange={this.onChangeDate}
								language={i18n.language}
							/>
						</Field>

						<Field name='handlerData.shareTypes' className='form-group--mar-btm-0 col-md-12' errors={errors}>
							{this.renderShareTypes()}
						</Field>

						<div className='form-group--mar-btm-0 col-md-12'>
							<FormattedHTMLMessage id='shares.transactions.adjustment_of_votes.form.information' />
						</div>
					</Panel>
				</ScrollView>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get('transaction'),
		tmpTransaction: state.transaction.getIn(['tmpTransaction', transactionType], initialTransaction),
		i18n: state.i18n
	}
}

const mapActionsToProps = {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	addErrorNotification,
	addInfoNotification,
	cancelTemporaryTransaction
}

const validators = fromJS({
	date: {
		tid: 'generic.date',
		rules: [
			{
				func: isRequired,
				message: 'validation.is_required'
			},
			{
				func: validateTransactionDate,
				message: 'validation.current_transaction_date_must_be_later_then_last_transaction'
			}
		]
	},
	'handlerData.types': {
		tid: 'shares.transactions.adjustment_of_votes.convert_to',
		rules: [
			{
				func: validateisRequiredImmutable,
				message: 'validation.is_required'
			}
		]
	}
})

AdjustmentOfVotesForm = immutableForm(AdjustmentOfVotesForm, 'adjustmentOfVotes', validators)

export default connect(mapStateToProps, mapActionsToProps)(AdjustmentOfVotesForm)
