import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FileDropZone from '../../common/file-drop-zone';
import GridBreak from '../../common/grid-break';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TheatersIcon from '@mui/icons-material/Theaters';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import GifBoxIcon from '@mui/icons-material/GifBox';
import MuiAutocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteMarketingFile,
	getMarketingFiles,
	saveEmission,
	setDisplayStatusInUserDealflow,
	setMarketingDiagramPermissions,
	setMarketingFilePermissions,
	updateDisplayInUserDealflow,
	updateMarketingDiagramWhenToShare,
	uploadMarketingFiles
} from '../../../../actions/emissions.actions';
import { emissionConsts } from '../../../../constants/emissions';
import WhiteSection from '../../common/white-section';
import RadioGroup from '@mui/material/RadioGroup';
import { Field, Form } from 'react-final-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import AutoSave from '../../forms/AutoSave';
import { getStore } from '../../../../store';
import { TextField } from 'mui-rff';
import { downloadDocument } from '../../../../actions/documents.actions';
import NumberFormatCustom from '../../../../dumb-components/shared/format-number-input/number-format-custom';
import { emissionIsReadOnly } from '../../utils/emission-status-helper';
import Link from '@mui/material/Link';
import { ThemeProvider } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import { MUItheme } from '../../../../../MUItheme';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';

export const marketingPermissionConsts = emissionConsts.marketing.permissions;
export const marketingDiagramConsts = emissionConsts.marketing.diagramWhenToShare;

export const getMarketingPermissionLangKey = (name) => {
	return (
		{
			[marketingPermissionConsts.investors]: 'emissions.invitations.own-investors',
			[marketingPermissionConsts.shareHolders]: 'emissions.marketing-new-emission.permissions.share-holders',
			[marketingPermissionConsts.dealFlow]: 'emissions.invitations.dealflow-investors'
		}[name] || name
	);
};

export const getMarketingDiagramWhenToShareLangKey = (name) => {
	return (
		{
			[marketingDiagramConsts.immediately]: 'emissions.marketing-new-emission.diagram.when-to-share.immediately',
			[marketingDiagramConsts.afterNumberOfDays]:
				'emissions.marketing-new-emission.diagram.when-to-share.numberOfDays',
			[marketingDiagramConsts.afterAchievedAmount]:
				'emissions.marketing-new-emission.diagram.when-to-share.achievedAmount'
		}[name] || name
	);
};

export const getMarketingDiagramWhenToShareValueLabelLangKey = (name) => {
	return (
		{
			[marketingDiagramConsts.afterNumberOfDays]:
				'emissions.marketing-new-emission.diagram.when-to-share.numberOfDays.label',
			[marketingDiagramConsts.afterAchievedAmount]:
				'emissions.marketing-new-emission.diagram.when-to-share.achievedAmount.label'
		}[name] || name
	);
};

function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) {
		return '0 Bytes';
	}

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const Marketing = ({ emission }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const files = useSelector((state) => state.emissions.marketingFiles);
	const currentEmission = useSelector((state) => state.emissions.current);
	const dispatch = useDispatch();
	const theme = useTheme();
	const preventChanges = emissionIsReadOnly(emission, Marketing.name);
	const store = getStore();

	useEffect(() => {
		async function getFiles() {
			await dispatch(getMarketingFiles(emission.companyId, emission.id));
		}

		getFiles();
	}, []);

	if (!currentEmission) {
		return null;
	}

	return (
		<Box>
			<Grid container>
				<Grid item xs={8}>
					<WhiteSection>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant='h4'>{t('emissions.marketing-new-emission')}</Typography>
								<Typography variant='body2' sx={{ pt: 2 }}>
									{t('emissions.marketing-new-emission.desc')}
								</Typography>
							</Grid>
							<GridBreak />
							<Grid item xs={12}>
								<Box sx={{ pt: 4 }}>
									{!preventChanges && (
										<FileDropZone
											onDrop={(acceptedFiles, rejectedFiles, cb) => {
												if (rejectedFiles.length) {
													cb?.();
													return;
												}

												dispatch(
													uploadMarketingFiles(
														emission.companyId,
														emission.id,
														acceptedFiles,
														cb
													)
												);
											}}
											emission={emission}
										/>
									)}
								</Box>
							</Grid>
							{files && files.length > 0 && (
								<Grid item xs={12}>
									<Typography variant='h4' sx={{ mt: 5 }}>
										{t('emissions.marketing-new-emission.uploaded-files')}
									</Typography>
									<Box
										sx={{
											mt: 1,
											minHeight: '20rem',
											display: 'flex',
											flexDirection: 'column',
											rowGap: 2
										}}>
										{files.map((u) => (
											<Box
												key={u.id}
												sx={{
													position: 'relative',
													p: 3,
													border: 1,
													borderColor: 'divider',
													borderRadius: 1
												}}>
												<IconButton
													disabled={preventChanges}
													sx={{ position: 'absolute', right: 4, top: 4 }}
													onClick={() =>
														dispatch(
															deleteMarketingFile(emission.companyId, emission.id, u.id)
														)
													}>
													<CloseIcon />
												</IconButton>
												<Box
													sx={{
														display: 'flex',
														flex: '1 1 auto',
														justifyContent: 'space-between',
														pr: 5,
														mb: 3
													}}>
													<Box sx={{ display: 'flex', alignItems: 'center' }}>
														<Box
															sx={{
																width: '3rem',
																pr: 2,
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center'
															}}>
															<Icons string={u.name} />
														</Box>
														<Link
															component={'button'}
															onClick={() => {
																viewDocument(u.id);
															}}>
															<Typography variant={'body2'} sx={{ color: '#369ff4' }}>
																{u.name}
															</Typography>
														</Link>
													</Box>
													<Typography variant={'body2'}>{formatBytes(u.size)}</Typography>
												</Box>
												<Box sx={{ display: 'flex', alignItems: 'center', pr: 5 }}>
													<Box sx={{ width: '3rem', pr: 2 }} />
													<MuiAutocomplete
														disabled={preventChanges}
														sx={{
															'& .MuiAutocomplete-tag': {
																bgcolor: 'secondary.200',
																color: 'secondary.700',
																borderRadius: 1
															}
														}}
														fullWidth
														multiple
														options={[
															marketingPermissionConsts.shareHolders,
															marketingPermissionConsts.investors,
															marketingPermissionConsts.dealFlow
														]}
														value={u.permissions}
														getOptionLabel={(option) =>
															t(getMarketingPermissionLangKey(option))
														}
														onChange={(_, value) => {
															dispatch(
																setMarketingFilePermissions(
																	emission.companyId,
																	emission.id,
																	u.id,
																	value
																)
															);
														}}
														renderInput={(params) => (
															<MuiTextField
																{...params}
																label={t(
																	'emissions.marketing-new-emission.permissions.header'
																)}
															/>
														)}
													/>
												</Box>
											</Box>
										))}
									</Box>
								</Grid>
							)}
						</Grid>
					</WhiteSection>
				</Grid>
				<Grid item xs={4}>
					<ThemeProvider theme={MUItheme}>
						<Card variant='dark' sx={{ ml: 2, mb: 2 }}>
							<CardContent variant='hbgPadding'>
								<Typography variant='h4'>
									{t('emission.marketing.dark_card.header.show_in_dealflow')}
								</Typography>
								<Typography variant='body2' sx={{ pt: 2 }}>
									{t('emission.marketing.dark_card.body')}
								</Typography>
								<Grid item xs={4} sx={{ pt: 3 }}>
									<MuiTextField
										id='display-in-dealflow-select'
										fullWidth
										select
										placeholder='displayInDealflow'
										disabled={isLoading}
										label={false}
										value={emission.displayInUserDealflow}
										InputLabelProps={{ shrink: false }}
										InputProps={{
											style: {
												...(emission.displayInUserDealflow && {
													backgroundColor: MUItheme.palette.success.mint
												})
											}
										}}
										onChange={async (event) => {
											setIsLoading(true);
											await dispatch(setDisplayStatusInUserDealflow(event.target.value));
											await dispatch(saveEmission(store.getState().emissions.current));
											setIsLoading(false);
										}}>
										<MenuItem value={true}>
											<Typography variant='body2'>
												{t('emission.marketing.dark_card.dropdown.item.yes')}
											</Typography>
										</MenuItem>
										<MenuItem value={false}>
											<Typography variant='body2'>
												{t('emission.marketing.dark_card.dropdown.item.no')}
											</Typography>
										</MenuItem>
									</MuiTextField>
								</Grid>
							</CardContent>
						</Card>
					</ThemeProvider>
					<WhiteSection sx={{ ml: 2 }}>
						<Typography variant='h4'>{t('emissions.header.show-diagram')}</Typography>
						<Typography variant='body2' sx={{ pt: 2 }}>
							{t('emissions.header.show-diagram.desc')}
						</Typography>
						<Box sx={{ mt: 4 }}>
							<AchievedAmountCard />
							<Typography
								variant='body2Italic'
								sx={{ color: theme.palette.grey['500'], fontSize: '10px', ml: '10px' }}>
								{t('emissions.marketing-sample-figures-diagram')}
							</Typography>
							<MuiAutocomplete
								disabled={preventChanges}
								sx={{
									'& .MuiAutocomplete-tag': {
										bgcolor: 'secondary.200',
										color: 'secondary.700',
										borderRadius: 1
									},
									mt: 4
								}}
								fullWidth
								multiple
								options={[
									marketingPermissionConsts.shareHolders,
									marketingPermissionConsts.investors,
									marketingPermissionConsts.dealFlow
								]}
								value={emission.marketingDiagramSettings?.permissions}
								getOptionLabel={(option) => t(getMarketingPermissionLangKey(option))}
								onChange={async (_, value) => {
									await dispatch(setMarketingDiagramPermissions(value));
									await dispatch(saveEmission(store.getState().emissions.current));
								}}
								renderInput={(params) => (
									<MuiTextField
										{...params}
										label={t('emissions.marketing-new-emission.permissions.header')}
									/>
								)}
							/>
						</Box>
						<WhenToShareSelector emission={currentEmission} preventChanges={preventChanges} />
					</WhiteSection>
				</Grid>
			</Grid>
		</Box>
	);
};

const WhenToShareSelector = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const store = getStore();
	const [amountIsEmpty, setAmountIsEmpty] = useState(false);
	const [numberOfDaysIsEmpty, setNumberOfDaysIsEmpty] = useState(false);

	const [whenToShare, setWhenToShare] = useState({
		active: '',
		amount: ''
	});

	const handleWhenToShareValueChange = async (values) => {
		setWhenToShare({
			...whenToShare,
			amount: values.value === marketingDiagramConsts.immediately ? '' : values.value
		});
		if (values.whenToShare === marketingDiagramConsts.afterAchievedAmount) {
			if (!values.value) {
				setAmountIsEmpty(true);
			} else {
				setAmountIsEmpty(false);
			}
		}
		if (values.whenToShare === marketingDiagramConsts.afterNumberOfDays) {
			if (!values.value) {
				setNumberOfDaysIsEmpty(true);
			} else {
				setNumberOfDaysIsEmpty(false);
			}
		}
	};

	const handleWhenToShareChange = async (value) => {
		setWhenToShare({
			...whenToShare,
			active: value,
			amount:
				value !== whenToShare.active || value === marketingDiagramConsts.immediately ? '' : whenToShare.amount
		});
	};

	useEffect(async () => {
		if (whenToShare.active !== '') {
			await dispatch(
				updateMarketingDiagramWhenToShare({ whenToShare: whenToShare.active, value: whenToShare.amount })
			);
			await dispatch(saveEmission(store.getState().emissions.current));
		}
	}, [whenToShare]);

	return (
		<>
			<Typography variant={'subtitle1'} sx={{ pt: 4, pb: 2 }}>
				{t('emissions.marketing-new-emission.diagram.when-to-share.heading')}
			</Typography>

			<Form onSubmit={() => {}} initialValues={props.emission.marketingDiagramSettings}>
				{({ form, handleSubmit, valid }) => {
					const currentValues = form.getState().values;

					return (
						<form onSubmit={handleSubmit}>
							<AutoSave
								save={async (values) => {
									if (valid) {
										await handleWhenToShareValueChange(values);
									}
								}}
							/>
							<RadioGroup value={currentValues.whenToShare}>
								<Field name={'type'} type={'radio'}>
									{(p) => (
										<FormControlLabel
											disabled={props.preventChanges}
											value={marketingDiagramConsts.immediately}
											control={<Radio />}
											label={t(
												getMarketingDiagramWhenToShareLangKey(
													marketingDiagramConsts.immediately
												)
											)}
											onChange={() => handleWhenToShareChange(marketingDiagramConsts.immediately)}
										/>
									)}
								</Field>
								<Field name={'type'} type={'radio'}>
									{(p) => (
										<FormControlLabel
											disabled={props.preventChanges}
											value={marketingDiagramConsts.afterAchievedAmount}
											control={<Radio />}
											label={t(
												getMarketingDiagramWhenToShareLangKey(
													marketingDiagramConsts.afterAchievedAmount
												)
											)}
											onChange={() =>
												handleWhenToShareChange(marketingDiagramConsts.afterAchievedAmount)
											}
										/>
									)}
								</Field>
								{currentValues.whenToShare === marketingDiagramConsts.afterAchievedAmount && (
									<Field name={'value'}>
										{(p) => (
											<TextField
												error={amountIsEmpty}
												helperText={
													amountIsEmpty
														? t(
																'emissions.marketing-new-emission.after-achieved-amount-error.text'
														  )
														: ''
												}
												autoComplete='off'
												name={p.input.name}
												value={currentValues.value}
												disabled={props.preventChanges}
												readOnly={props.preventChanges}
												label={t(
													getMarketingDiagramWhenToShareValueLabelLangKey(
														marketingDiagramConsts.afterAchievedAmount
													)
												)}
												InputProps={{
													inputComponent: NumberFormatCustom,
													inputProps: {
														floatFormat: true
													}
												}}
												onChange={(e) => {
													p.input.onChange(e);
												}}></TextField>
										)}
									</Field>
								)}
								<Field name={'type'} type={'radio'}>
									{(p) => (
										<FormControlLabel
											disabled={props.preventChanges}
											value={marketingDiagramConsts.afterNumberOfDays}
											control={<Radio />}
											label={t(
												getMarketingDiagramWhenToShareLangKey(
													marketingDiagramConsts.afterNumberOfDays
												)
											)}
											onChange={() =>
												handleWhenToShareChange(marketingDiagramConsts.afterNumberOfDays)
											}
										/>
									)}
								</Field>
								{currentValues.whenToShare === marketingDiagramConsts.afterNumberOfDays && (
									<Field name={'value'}>
										{(p) => (
											<TextField
												disabled={props.preventChanges}
												error={numberOfDaysIsEmpty}
												helperText={
													numberOfDaysIsEmpty
														? t(
																'emissions.marketing-new-emission.after-number-of-days-error.text'
														  )
														: ''
												}
												autoComplete='off'
												name={p.input.name}
												value={currentValues.value}
												readOnly={props.preventChanges}
												label={t(
													getMarketingDiagramWhenToShareValueLabelLangKey(
														marketingDiagramConsts.afterNumberOfDays
													)
												)}
												InputProps={{
													inputComponent: NumberFormatCustom,
													inputProps: {
														floatFormat: false
													}
												}}
												onChange={(e) => {
													p.input.onChange(e);
												}}></TextField>
										)}
									</Field>
								)}
							</RadioGroup>
						</form>
					);
				}}
			</Form>
		</>
	);
};

const AchievedAmountCard = () => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Paper sx={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
			<Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 3 }}>
				<Box>
					<Box>
						<Typography variant={'overline'} sx={{ color: theme.palette.secondary['400'] }}>
							{t('emissions.subscriptions.achieved-amount')}
						</Typography>
					</Box>
					<Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center', mr: '24px' }}>
						<ProgressCircle
							sx={{ mt: 1 }}
							size={72}
							value={45}
							backColor={'custom.progress.orange.light'}
							frontColor={'custom.progress.orange.dark'}
						/>
						<Typography
							sx={{ position: 'absolute', display: 'flex', ml: '14px', mt: '5px' }}
							variant='subtitle2'>
							{'44,77%'}
						</Typography>
					</Box>
				</Box>
				<Box sx={{ mt: 2 }}>
					<Typography variant={'h3'}>
						3 581 396{' '}
						<Typography component={'span'} variant={'body2'} sx={{ color: theme.palette.grey['800'] }}>
							SEK
						</Typography>
					</Typography>
					<Typography variant={'caption'} sx={{ color: theme.palette.grey['500'] }}>
						{t('emissions.subscriptions.generic-of')}
					</Typography>
					<Typography variant={'caption'} sx={{ color: theme.palette.grey['500'] }}>
						{' '}
						8 000 000,00 SEK
					</Typography>
				</Box>
			</Box>
		</Paper>
	);
};

export const ProgressCircle = (props) => {
	const thicknessNumerator = 250;
	return (
		<Box sx={props.sx}>
			<Box sx={{ position: 'relative' }}>
				<CircularProgress
					variant='determinate'
					sx={{
						color: props.backColor
					}}
					size={props.size}
					thickness={thicknessNumerator / props.size}
					value={100}
				/>
				<CircularProgress
					variant='determinate'
					sx={{
						color: props.frontColor,
						position: 'absolute',
						left: 0
					}}
					size={props.size}
					thickness={thicknessNumerator / props.size}
					value={props.value}
				/>
			</Box>
		</Box>
	);
};

const Icons = (props) => {
	const type = props.string.split('.').pop().toLowerCase();
	if (type === 'pdf') {
		return <PictureAsPdfIcon color={'secondary'} />;
	}
	if (type === 'mp4' || type === 'mpeg-4') {
		return <TheatersIcon color={'secondary'} />;
	}
	if (type === 'gif') {
		return <GifBoxIcon color={'secondary'} />;
	}
	if (type === 'png' || type === 'jpg' || type === 'jpeg') {
		return <InsertPhotoIcon color={'secondary'} />;
	} else {
		return <AttachFileIcon color={'secondary'} />;
	}
};

const viewDocument = (docId) => {
	downloadDocument({
		documentId: docId,
		openInViewer: true,
		getMergedVersion: false,
		getSignedVersion: false
	})();
};

export default Marketing;
