import React, { PureComponent } from 'react'
import { func, bool, string } from 'prop-types'
import styled, { css } from 'styled-components'
import Grid from 'styled-components-grid'
import { Padding } from 'styled-components-spacing'

import Input from '../../shared/input/input'
import Panel from '../../shared/panel/panel'
import Label from '../../shared/label/label'

const ExpendableContent = styled.div`
	max-height: 0px;
	overflow: hidden;
	transition: max-height 0.3s ease-in;

	${(props) =>
		props.isExpanded &&
		css`
			max-height: 420px;
		`}
`

export default class PhoneLocationPanel extends PureComponent {
	static propTypes = {
		number: string,
		code: string,
		readOnly: bool,
		onChange: func,
		marginBottom: bool,
		withoutPanel: bool,
		isExpanded: bool,
		renderEditor: func
	}

	static defaultProps = {
		marginBottom: false,
		withoutPanel: false
	}

	renderContent = () => {
		const { onChange, readOnly, number, code, isExpanded, renderEditor } = this.props

		return (
			<Padding all={3}>
				<Grid>
					<Grid.Unit size={1}>
						<Padding all={3}>
							<Label tid='meetings.location.phone.label.number'>
								<Input
									fieldName='number'
									disabled={readOnly}
									placeholderTid='meetings.location.phone.label.number'
									onChange={onChange}
									value={number ? number : ''}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>

				<ExpendableContent isExpanded={isExpanded}>
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='meetings.location.phone.label.code'>
									<Input
										fieldName='code'
										disabled={readOnly}
										placeholderTid='meetings.location.phone.label.code'
										onChange={onChange}
										value={code ? code : ''}
									/>
								</Label>
							</Padding>
						</Grid.Unit>

						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='meetings.location.phone.label.additional_info'>{renderEditor && renderEditor()}</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
				</ExpendableContent>
			</Padding>
		)
	}

	render = () => {
		const { marginBottom, withoutPanel } = this.props

		if (withoutPanel) {
			return this.renderContent()
		}

		return (
			<Panel title='meetings.location.type.phone' marginBottom={marginBottom}>
				{this.renderContent()}
			</Panel>
		)
	}
}
