import React, { useState, useEffect } from 'react'
import { alpha, useTheme } from '@mui/material'
import htmlToDraft from 'html-to-draftjs'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import styled from '@mui/styled-engine'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Text from '../text/text'
import styledComponent from 'styled-components'

const Fieldset = styled('fieldset')(({ height, theme }) => {
	return {
		position: 'relative',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: theme.shape.borderRadius,
		padding: '0 8px',
		minHeight: height || 'auto',
		transition: 'border-color .2s ease',
		'&:focus-within': {
			borderColor: '#000',
			// borderWidth: '2px',
			'> legend': {
				color: '#000'
			}
		}
	}
})

const Legend = styled('legend')(({ theme }) => {
	return {
		all: 'unset',
		padding: '0 0.5rem',
		paddingBottom: '0.25rem',
		fontSize: '1rem',
		color: theme.palette.text.primary,
		transition: 'color .2s ease',
		fontWeight: '600',
		letterSpacing: '0.02938em'
	}
})

const StyledMaxLength = styledComponent(Text)`
	position: absolute;
	right: 25px;
	top: 9px;
`

const RichTextEditor = (props) => {
	const theme = useTheme()
	const draft = htmlToDraft(props.value || '')
	const contentState = ContentState.createFromBlockArray(draft.contentBlocks)
	const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))

	useEffect(() => {
		const draft2 = htmlToDraft(props.value || '')
		const contentState2 = ContentState.createFromBlockArray(draft2.contentBlocks)
		setEditorState(EditorState.createWithContent(contentState2))
	}, [props.value])

	return (
		<>
			<Fieldset height={props.height}>
				<Legend>
					{props.label}
					{props.required ? ' *' : ''}
				</Legend>
				<div
					style={{
						minHeight: 'inherit',
						display: 'flex',
						overflow: 'auto'
					}}>
					<Editor
						readOnly={props.readOnly}
						wrapperStyle={{
							border: 'none',
							borderRadius: theme.shape.borderRadius,
							padding: theme.spacing(1),
							display: 'flex',
							flex: '1',
							flexDirection: 'column',
							width: 'fit-content'
						}}
						editorStyle={{
							padding: '0 1rem',
							flex: '1',
							height: 'inherit',
							cursor: 'text',
							...(props.editorStyle || {})
						}}
						toolbarStyle={{
							display: props.readOnly ? 'none' : 'flex',
							borderRadius: 0,
							border: 'none',
							backgroundColor: 'transparent'
						}}
						toolbar={{
							options: ['inline', 'list'],
							inline: {
								options: ['bold', 'italic', 'underline']
							},
							list: {
								options: ['unordered', 'ordered']
							}
						}}
						editorState={editorState}
						handleBeforeInput={
							props.maxLength &&
							((chars) => {
								const totalLength = editorState.getCurrentContent().getPlainText().length + chars.length
								return totalLength > props.maxLength
							})
						}
						onEditorStateChange={(newEditorState) => {
							if (props.maxLength) {
								const contentState = newEditorState.getCurrentContent()
								const oldContent = editorState.getCurrentContent()
								if (
									contentState === oldContent ||
									contentState.getPlainText().length <= props.maxLength
								) {
									setEditorState(newEditorState)
									// props.onChange(draftToHtml(convertToRaw(newEditorState.getCurrentContent())))
								}
							} else {
								setEditorState(newEditorState)
								// props.onChange(draftToHtml(convertToRaw(newEditorState.getCurrentContent())))
							}
						}}
						onBlur={(event, newEditorState) => {
							props.onChange(draftToHtml(convertToRaw(newEditorState.getCurrentContent())))
							props.onBlur()
						}}
						stripPastedStyles
					/>
					{props.maxLength && (
						<StyledMaxLength
							color={theme.palette.text.primary}
							fontSize={'1.3rem'}
							tid={'rte_max_characters'}
							values={{
								maxLength: props.maxLength - editorState.getCurrentContent().getPlainText().length
							}}
						/>
					)}
				</div>
			</Fieldset>
		</>
	)
}

export default RichTextEditor
