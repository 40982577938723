import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocumentsUpload from '../../dumb-components/documents/documents-upload/documents-upload'
import { createDocument } from '../../actions/documents.actions'
import { createSharedDocument } from '../../actions/documents-sharing.actions'
import moment from '../../modules/moment.module'
import { setGlobalRef } from '../../components/helpers/global-refs.helper'
import { REF_DOCUMENTS_UPLOAD_DROPZONE } from '../../constants/global-refs'
import history from '../../interfaces/history'

class Documents extends Component {
	state = {
		isOpenDocumensUpload: false
	}

	componentDidUpdate = (prevProps) => {
		const { fileEnteredWebsiteGlobal } = this.props

		if (fileEnteredWebsiteGlobal !== prevProps.fileEnteredWebsiteGlobal) {
			this.setState({ isOpenDocumensUpload: fileEnteredWebsiteGlobal })
		}
	}

	onClickBtnOpen = () => {
		this.dropdownRef.open()
	}

	setDropdownRef = (ref) => {
		this.dropdownRef = ref
		setGlobalRef(ref, REF_DOCUMENTS_UPLOAD_DROPZONE)
	}

	onFilesDrop = (files) => {
		const { createDocument, folderId, basePath, querystr, isSharedFromCompanyId, createSharedDocument, history } = this.props

		if (!files || files.length < 1) {
			return
		}

		const callbacks = {
			onComplete: (doc) => {
				files.length === 1 && history.push(`${basePath}/${doc.get('id')}${querystr}`) // Redirect to the new document only if there was one file uploaded
			}
		}

		files.forEach((file) => {
			const document = new FormData()
			const props = {
				file,
				documentType: 'file',
				folderId: folderId || '',
				lastModifiedDate: moment(file.lastModified).toISOString(),
				title: file.name
			}

			Object.keys(props).forEach((key) => {
				if (Array.isArray(props[key])) {
					for (let i = 0; i < props[key].length; i++) {
						document.append(key, props[key][i])
					}
				} else {
					document.append(key, props[key])
				}
			})

			if (isSharedFromCompanyId) {
				createSharedDocument(document, folderId, isSharedFromCompanyId, callbacks)
			} else {
				createDocument(document, folderId, callbacks)
			}
		})

		// Close on document upload
		this.setState({ isOpenDocumensUpload: false })
	}

	render = () => {
		const { folders, querystr, isSharedFromCompanyId, sharedByCompanyName } = this.props
		let { folderId } = this.props
		const { isOpenDocumensUpload } = this.state

		if (!folderId) {
			folderId = 'root'
		}

		const hasPermissionsToUpload = folders && folders.getIn([folderId, 'ALLOW_CREATE_DOCUMENT'])
		const isRootSharedFolder = folderId === isSharedFromCompanyId

		return (
			<DocumentsUpload
				isOpen={isOpenDocumensUpload}
				hasPermissionsToUpload={hasPermissionsToUpload}
				isSharedFolder={Boolean(isSharedFromCompanyId)}
				sharedByCompanyName={sharedByCompanyName}
				isRootSharedFolder={isRootSharedFolder}
				onDrop={this.onFilesDrop}
				onClickBtnOpen={this.onClickBtnOpen}
				dropzoneRef={this.setDropdownRef}
				multiple
			/>
		)
	}
}

function mapStoreToProps(store, ownProps) {
	return {
		history: history,
		fileEnteredWebsiteGlobal: store.notify.fileEnteredWebsiteGlobal,
		filterBy: store.documents.get('filterBy'),
		folderId: store.folders.get('selectedFolderId'),
		folders: ownProps.isSharedFromCompanyId
			? store.folders.getIn(['sharedFolders', 'folders'])
			: store.folders.get('folders'),
		sharedByCompanyName: store.folders.getIn(['folders', ownProps.isSharedFromCompanyId, 'translatedName'])
	}
}

const mapActionsToProps = {
	createDocument,
	createSharedDocument
}

export default connect(mapStoreToProps, mapActionsToProps)(Documents)
