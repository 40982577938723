import React, { PureComponent } from 'react'
import immutablePropTypes from 'react-immutable-proptypes'
import { string, oneOfType, bool, func, array, oneOf, object } from 'prop-types'
import { Padding } from 'styled-components-spacing'
import Panel from '../../shared/panel/panel'
import LinkedFiles from '../../shared/linked-files/linked-files'
import Text from '../../shared/text/text'
import v1 from 'uuid'

class Attachments extends PureComponent {
	static propTypes = {
		fieldName: string,
		multiple: bool,
		accept: oneOfType([string, array]),
		readOnly: bool,
		onDrop: func.isRequired,
		documents: immutablePropTypes.list,
		onOpenFile: func,
		admin: bool,
		panelType: oneOf(['inline']),
		marginBottom: bool,
		selected: bool,
		descriptionTid: string,
		withoutPanel: bool,
		onDragEnd: func,
		nakedStyle: bool,
		showDropzone: bool,
		isUploading: bool,
		dropdownRenderer: func,
		dropdownDeleteOnlyRenderer: func,
		preControlsRenderer: func,
		visibilityIcons: object, // {hidden: 'faEyeSlash', shown: 'faEye'}, Default: undefined
		visibilityIconTooltipTids: object,
		documentToolTipTid: string,
		onSetDropzoneRef: func,
		invisible: bool,
		objId: string
	}

	static defaultProps = {
		marginBottom: false,
		showDropzone: true,
		isUploading: false
	}

	render = () => {
		const {
			panelType,
			marginBottom,
			selected,
			descriptionTid,
			nakedStyle,
			withoutPanel,
			invisible,
			...props
		} = this.props
		const panelMode = panelType === 'inline' ? 'light' : undefined
		const extraMargin = panelType === 'inline' ? 0 : 4

		if (withoutPanel || nakedStyle || invisible) {
			return <LinkedFiles {...props} nakedStyle={nakedStyle} invisible={invisible} />
		}

		return (
			<Panel
				title='tasks.attachments'
				marginBottom={marginBottom}
				type={panelType}
				mode={panelMode}
				id={v1()}
				active={selected}>
				<Padding all={extraMargin}>
					<Text tag='p' tid={descriptionTid} />
					<LinkedFiles {...props} />
				</Padding>
			</Panel>
		)
	}
}

export default Attachments
