import { fromJS, Map, List } from 'immutable';
import req from '../modules/request.module';
import {
	TASKS_LIST,
	TASKS_SET_VISIBLE,
	TASKS_UPDATE_LOCAL,
	TASKS_CREATE,
	TASKS_CREATE_LOCAL,
	TASKS_FETCH,
	TASKS_SAVE,
	TASKS_SAVE_START,
	TASKS_DELETE,
	TASKS_TASK_DIRTY,
	TASKS_LIST_BY_COMPANIES,
	TASKS_SET_FILTERS,
	TASKS_UNSET_FILTERS,
	TASKS_SELECT,
	TASKS_SET_DEFAULT_FILTERS,
	TASKS_UPDATE_DEFAULT_FILTER,
	TASKS_RESET_DEFAULT_VALUES,
	TASKS_LIST_BY_OBJECTS,
	TASKS_CREATE_EXTERNAL_TASK_LOCAL,
	TASKS_CREATE_EXTERNAL,
	TASKS_UPDATE_EXTERNAL_TASKS_LOCAL,
	TASKS_HARD_DELETE,
	TASKS_DELETE_EXTERNAL
} from './types';
import { addErrorNotification, addInfoNotification } from './notify.actions';
import uuid from 'uuid/v1';
import {
	LIVE_TASK_CREATE,
	LIVE_TASK_UPDATE,
	LIVE_TASK_DELETE,
	LIVE_TASK_DELETE_MULTIPLE,
	LIVE_TASK_TRANSFER,
	LIVE_TASK_EXTERNAL_CREATE,
	LIVE_TASK_EXTERNAL_UPDATE,
	LIVE_TASK_EXTERNAL_DELETE,
	LIVE_TASK_EXTERNAL_DELETE_MULTIPLE
} from '../constants/live-update';
import { setLiveRequest, resetLiveRequest } from './live-update.actions';

export function setFilteredTasks(tasks, hasAppliedFilters) {
	return {
		type: TASKS_SET_VISIBLE,
		payload: { tasks, hasAppliedFilters }
	};
}

/**
 * Action for updating the task list locally
 */
export function updateTaskListLocal(tasks) {
	return {
		type: TASKS_LIST,
		payload: tasks
	};
}

/**
 * Action for fetching a task
 */
export function fetchTask(id, callback) {
	return function (dispatch) {
		return req
			.get(`/tasks/tasks/${id}`)
			.then((response) => {
				dispatch({ type: TASKS_FETCH, payload: fromJS(response.data) });
				callback && callback(fromJS(response.data));
			})
			.catch(() => {
				dispatch(tasksError('tasks.error.load_task'));
			});
	};
}

export function fetchExternalTask(objId, id, companyId, callback) {
	return function (dispatch) {
		return req
			.get(`/tasks/tasks/external/${objId}/${id}${companyId ? `?companyId=${companyId}` : ''}`)
			.then((response) => {
				dispatch({ type: TASKS_FETCH, payload: fromJS(response.data) });
				callback && callback(fromJS(response.data));
			})
			.catch(() => {
				dispatch(tasksError('tasks.error.load_task'));
			});
	};
}

export function onSelectTask(taskId) {
	return {
		type: TASKS_SELECT,
		payload: taskId
	};
}

export function updateTaskLocal(task, callback) {
	return function (dispatch) {
		if (task) {
			dispatch({ type: TASKS_TASK_DIRTY, payload: task.get('id') });
			dispatch({ type: TASKS_UPDATE_LOCAL, payload: task });
		}
		callback && callback();
	};
}

/**
 * Action for saving a task
 */
export function saveTask(task, callback) {
	return function (dispatch) {
		dispatch({ type: TASKS_SAVE_START, payload: task.get('id') });
		return req
			.put(`/tasks/tasks/${task.get('id')}`, { task: task.toJS() }, { onlyLatest: true })
			.then((response) => {
				dispatch({ type: TASKS_SAVE, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes('onlyLatest:true')) {
					console.log(e);
					dispatch(tasksError('tasks.error.save_task'));
				}
			});
	};
}

export function saveExternalTask(objId, task, callback) {
	return function (dispatch) {
		dispatch({ type: TASKS_SAVE_START, payload: task });
		return req
			.put(`/tasks/tasks/external/${objId}/${task.get('id')}`, { task: task.toJS() }, { onlyLatest: true })
			.then((response) => {
				dispatch({ type: TASKS_SAVE, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes('onlyLatest:true')) {
					console.log(e);
					dispatch(tasksError('tasks.error.save_task'));
				}
			});
	};
}

/**
 * Action for recovering a trashed task
 */
export function recoverTask(taskId, callback) {
	return function (dispatch) {
		return req
			.put(`/tasks/tasks/${taskId}/recover`)
			.then((response) => {
				dispatch({ type: TASKS_SAVE, payload: fromJS(response.data) });
				dispatch(addInfoNotification({ tid: 'tasks.notifications.info.task_recovered' }));
				callback && callback();
			})
			.catch(() => {
				dispatch(tasksError('tasks.error.recover_task'));
			});
	};
}

/**
 * Action for delete pending tasks perminantly
 */
export function deleteTasksPerminantly(currentSelectedTaskId) {
	return function (dispatch) {
		return req
			.delete(`/tasks/tasks/hard-delete`)
			.then((response) => {
				dispatch({
					type: TASKS_HARD_DELETE,
					payload: Map({ currentSelectedTaskId, tasksToDelete: fromJS(response.data) })
				});
				dispatch(addInfoNotification({ tid: 'tasks.notifications.info.task_deleted_perminantly' }));
			})
			.catch((err) => {
				console.error(err);
				dispatch(tasksError('tasks.error.perminantly_delete_tasks'));
			});
	};
}

export function createTaskLocal(insertAtIndex, task, callback) {
	return function (dispatch) {
		// create the task id local for speedup
		task = task.set('id', uuid());

		dispatch({ type: TASKS_CREATE_LOCAL, payload: Map({ task, insertAtIndex }) });
		callback && callback(task);
	};
}

export function createExternalTaskLocal(insertAtIndex, task, objId, callback) {
	return function (dispatch) {
		// create the task id local for speedup
		task = task.set('id', uuid());

		dispatch({ type: TASKS_CREATE_EXTERNAL_TASK_LOCAL, payload: Map({ task, insertAtIndex, objId }) });
		callback && callback(task);
	};
}

export function updateExternalTasksLocal(tasks) {
	return function (dispatch) {
		dispatch({
			type: TASKS_UPDATE_EXTERNAL_TASKS_LOCAL,
			payload: tasks
		});
	};
}

/**
 * Action for creating a task
 */
export function createTask(task, callback) {
	return function (dispatch) {
		return req
			.post(`/tasks/tasks/`, { task: task.toJS() })
			.then((response) => {
				task = fromJS(response.data);
				dispatch({ type: TASKS_CREATE, payload: fromJS(task) });
				callback && callback(task);
			})
			.catch(() => {
				dispatch(tasksError('tasks.error.create_task'));
			});
	};
}

export function createExternalTask(objType, objId, task, callback) {
	return function (dispatch) {
		return req
			.post(`/tasks/tasks/external/${objType}/${objId}`, { task: task.toJS() })
			.then((response) => {
				task = fromJS(response.data);
				dispatch({ type: TASKS_CREATE_EXTERNAL, payload: fromJS(task) });
				callback && callback(task);
			})
			.catch((e) => {
				console.log(e);
				dispatch(tasksError('tasks.error.create_task'));
			});
	};
}

/**
 * Action for fetching a list of tasks
 */
export function listTasks(selectedProjectId, callback) {
	return function (dispatch) {
		return req
			.get(`/tasks/tasks/${selectedProjectId ? `?project=${selectedProjectId}` : ''}`)
			.then((response) => {
				const tasks = fromJS(response.data);
				dispatch({ type: TASKS_LIST, payload: tasks });
				callback && callback(tasks);
			})
			.catch(() => {
				dispatch(tasksError('tasks.error.load_tasks'));
			});
	};
}

/**
 * Action for fetching a list of tasks
 */
export function listExternalTasks(objId, callback) {
	return function (dispatch) {
		return req
			.get(`/tasks/tasks/external/${objId}`)
			.then((response) => {
				const data = fromJS(response.data);
				dispatch({ type: TASKS_LIST, payload: data });
				callback && callback(data);
			})
			.catch(() => {
				dispatch(tasksError('tasks.error.load_tasks'));
			});
	};
}

/**
 * Action for fetching a list of tasks for multiple object ids
 */
export function listExternalTasksMultiple(objIds, callback) {
	return function (dispatch) {
		return req
			.post(`/tasks/tasks/external/multiple`, { objIds: objIds.toJS() })
			.then((response) => {
				const list = fromJS(response.data);
				dispatch({
					type: TASKS_LIST_BY_OBJECTS,
					payload: list
				});
				callback && callback(list);
			})
			.catch((e) => {
				console.log(e);
				dispatch(tasksError('tasks.error.load_tasks'));
			});
	};
}

/**
 * Action for fetching all tasks in all the companies the user is a member of
 */
export function listTasksByCompanies() {
	return function (dispatch) {
		return req
			.get(`/tasks/tasks/companies`)
			.then((response) => {
				dispatch({ type: TASKS_LIST_BY_COMPANIES, payload: fromJS(response.data) });
			})
			.catch(() => {
				dispatch(tasksError('tasks.error.load_tasks'));
			});
	};
}

/**
 * Action for clearing tasks list
 */
export function clearTasksList() {
	return function (dispatch) {
		dispatch({ type: TASKS_LIST, payload: List() });
	};
}

/**
 * Action for clearing selected task
 */
export function clearTask() {
	return function (dispatch) {
		dispatch({ type: TASKS_FETCH, payload: null });
	};
}

/**
 * Action for deleting a task
 * @param {String} id — task id
 */
export function deleteTask(id, callback) {
	return function (dispatch) {
		return req
			.delete(`/tasks/tasks/${id}`)
			.then(() => {
				dispatch({ type: TASKS_DELETE, payload: id });
				dispatch(addInfoNotification({ tid: 'tasks.notifications.info.task_deleted' }));
				callback && callback();
			})
			.catch((err) => {
				dispatch(tasksError('tasks.error.delete_task'));
			});
	};
}

export function deleteExternalTask(objId, id, callback) {
	return function (dispatch) {
		return req
			.delete(`/tasks/tasks/external/${objId}/${id}`)
			.then(() => {
				dispatch({ type: TASKS_DELETE_EXTERNAL, payload: id });
				callback && callback();
			})
			.catch((err) => {
				dispatch(tasksError('tasks.error.delete_task'));
			});
	};
}

export function deleteMultipleExternalTasks(objId) {
	return function () {
		return req.delete(`/tasks/tasks/external/${objId}/multiple`);
	};
}

export function deleteTaskLocal(id, callback) {
	return function (dispatch) {
		dispatch({ type: TASKS_DELETE, payload: id });
		callback && callback();
	};
}

export function reorderTask(tasks, sourceTaskId, destinationTaskId, objId) {
	return function (dispatch) {
		const sourceIndex = tasks.findIndex((task) => {
			return task.get('id') === sourceTaskId;
		});

		const destinationIndex = tasks.findIndex((task) => {
			return task.get('id') === destinationTaskId;
		});
		let task = tasks.get(sourceIndex);

		let taskOrder = null;
		let prevTaskOrder = null;

		// down
		if (sourceIndex < destinationIndex) {
			taskOrder = tasks.getIn([destinationIndex, 'orderIndex'], 0.01) || 0.01;
			if (destinationIndex + 1 < tasks.size) {
				prevTaskOrder = (destinationIndex > 0 && tasks.getIn([destinationIndex + 1, 'orderIndex'], 0)) || 0;
			} else {
				prevTaskOrder = taskOrder + 100;
			}
		} else {
			// up
			taskOrder = tasks.getIn([destinationIndex, 'orderIndex'], 0.01) || 0.01;
			prevTaskOrder = (destinationIndex > 0 && tasks.getIn([destinationIndex - 1, 'orderIndex'], 0)) || 0;
		}
		const insertAtIndex = (taskOrder + prevTaskOrder) / 2;
		task = task.set('orderIndex', insertAtIndex);

		dispatch({ type: TASKS_UPDATE_LOCAL, payload: task });

		if (!objId) {
			dispatch(saveTask(task));
		} else {
			dispatch(saveExternalTask(objId, task));
		}
		// return req.put(`/tasks/tasks/${task.get('id')}`, {task: task.toJS()}, {onlyLatest: true})
		// 	.then(response => {
		// 		dispatch({type: TASKS_SAVE, payload: fromJS(response.data)});
		// 	})
		// 	.catch((e) => {
		// 		console.log(e);
		// 		dispatch(tasksError('tasks.error.save_task'));
		// 	});
	};
}

// Transfer task to another project
export function transferTask(taskIds, projectId, currentProjectId, callback) {
	return function (dispatch) {
		const parts = { taskIds, projectId };
		return req.post(`/tasks/tasks/transfer`, parts).then((response) => {
			callback && callback();
			//dispatch(listTasks(currentProjectId, callback));
		});
	};
}

// fetch the latest order index in database
export function fetchLatestOrderIndex(callback) {
	return function (dispatch) {
		return req.get(`/tasks/tasks/orderindex`).then((response) => {
			callback && callback(response.data);
		});
	};
}

export function countTasks(callback) {
	return function (dispatch) {
		return req
			.get(`/tasks/tasks/count`)
			.then((response) => {
				callback(fromJS(response.data));
			})
			.catch(() => {
				dispatch(tasksError('tasks.error.load_tasks'));
			});
	};
}

export function setFilterBy(val) {
	return {
		type: TASKS_SET_FILTERS,
		payload: val
	};
}

export function unsetFilterBy(sources) {
	return {
		type: TASKS_UNSET_FILTERS,
		payload: sources
	};
}

export function updateDefaultFilter(source, values) {
	return {
		type: TASKS_UPDATE_DEFAULT_FILTER,
		payload: Map({ source, values })
	};
}

export function resetDefaultFiltervalues() {
	return {
		type: TASKS_RESET_DEFAULT_VALUES
	};
}

export function tasksSetDefaultFilterBy(defaultFilters) {
	return function (dispatch) {
		let filters = Map();
		defaultFilters &&
			defaultFilters.forEach((val, key) => {
				const source = key.split('$')[1];
				dispatch(setFilterBy(fromJS({ source, values: [val] })));
				filters = filters.set(source, List([val]));
			});
		dispatch({
			type: TASKS_SET_DEFAULT_FILTERS,
			payload: filters
		});
	};
}

/**
 * Action for dispatching an task error
 * @param {String} error — error message
 */
export function tasksError(error) {
	return addErrorNotification({
		tid: error
	});
}

export function socketEventTasks(eventObj) {
	const { eventName, objId, objIds, metadata } = eventObj;

	return function (dispatch) {
		switch (eventName) {
			case LIVE_TASK_CREATE:
			case LIVE_TASK_UPDATE:
			case LIVE_TASK_DELETE:
			case LIVE_TASK_TRANSFER:
			case LIVE_TASK_EXTERNAL_CREATE:
			case LIVE_TASK_EXTERNAL_UPDATE:
			case LIVE_TASK_EXTERNAL_DELETE: {
				dispatch(setLiveRequest(['tasks', eventName], { refresh: true, objId, metadata }));
				dispatch(resetLiveRequest(['tasks', eventName]));
				break;
			}
			case LIVE_TASK_DELETE_MULTIPLE:
			case LIVE_TASK_EXTERNAL_DELETE_MULTIPLE: {
				dispatch(setLiveRequest(['tasks', eventName], { refresh: true, objIds, metadata }));
				dispatch(resetLiveRequest(['tasks', eventName]));
				break;
			}
			default:
				dispatch({ type: 'TASKS_NULL', payload: null });
		}
	};
}
