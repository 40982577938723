export function getOrderIndex(selectedTask, tasks, alwaysSetBegining = false) {
	let orderIndex = null

	if (selectedTask && !alwaysSetBegining) {
		const selectedTaskIndex = tasks.findIndex((task) => {
			return selectedTask.get('id') === task.get('id')
		})

		const thisOrderIndex = tasks && tasks.getIn([selectedTaskIndex, 'orderIndex'])
		orderIndex = thisOrderIndex

		if (tasks.size > selectedTaskIndex + 1) {
			const nextOrderIndex = tasks && tasks.getIn([selectedTaskIndex + 1, 'orderIndex'])
			orderIndex = (nextOrderIndex + thisOrderIndex) / 2
		} else {
			orderIndex += 100
		}
	} else {
		orderIndex = tasks && tasks.first() && tasks.first().has('orderIndex') ? tasks.first().get('orderIndex') / 2 : 100
	}

	return orderIndex
}
