import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Text from '../shared/text/text'
import Button from '../shared/button/button'
import { oneOf } from 'prop-types'

const StyledBottomComponent = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;

	> * {
		margin: ${(props) => props.theme.spacing[3]} 0;
	}
`

export default class BottomComponent extends PureComponent {
	static propTypes = {
		section: oneOf(['SIGNIN', 'SIGNUP', 'FORGOTPW'])
	}

	static defaultProps = {
		section: 'SIGNIN'
	}

	render = () => {
		const { section, onClick } = this.props
		let text1Tid = 'credentials_join.us.today'
		let buttonTid = 'credentials_register.account'

		if (section === 'SIGNUP') {
			text1Tid = 'credentials_already.member'
			buttonTid = 'credentials_sign.in.to.acc'
		}

		return (
			<StyledBottomComponent>
				<Button mode='link' tid={buttonTid} minWidth='280px' height='50px' color='white' onClick={onClick} />
				<Text>Powered by Invono AB</Text>
			</StyledBottomComponent>
		)
	}
}
