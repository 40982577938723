import { getStore } from '../store'
import moment from './moment.module'
const store = getStore()
import { checkFirstFourCharInRange } from '/shared/helpers/helpers.helper'

/**
 * Validation
 * Helper containing validation methods.
 */

/**
 * Validates required values
 * @param {string} value - The value to validate.
 * @exports isRequired
 */
export const isRequired = (value) => {
	return value !== undefined && value !== null && value !== ''
}

export const validateNotZero = (value) => {
	return value && value !== 0
}

export const validateisRequiredImmutable = (value) => {
	let valid = isRequired(value)

	if (value && value.size === 0) {
		valid = false
	}

	return valid
}

export const validateNotEmptyInvestorsShares = (investors) => {
	let valid = true
	investors.map((investor) => {
		if (investor.size === 0) {
			valid = false
		}
	})

	return valid
}

/**
 * Validates emails.
 * @param {string} value - The email to validate.
 * @exports validateEmail
 */
export const validateEmail = (value) => {
	// For some strange reason value can be of type List (immutable) and therefor we have to check that the value is actually a string to prevent errors
	value = value && typeof value === 'string' ? value.trim().replace('demo=', '') : null
	return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
}

/**
 * Validates passwords.rd
 * @param {string} value - The password to validate.
 * @exports validatePassword
 */
export const validatePassword = (value) => {
	const PASSWORD_REGEX = new RegExp('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z!+-_@#$%^&*.\\d]{6,}$')
	return value && PASSWORD_REGEX.test(value)
}

/**
 * Verifies passwords.
 * @param {string} value - The password to verify.
 * * @param {string} password - The password to verify against.
 * @exports validateVerifiedPassword
 */
export const validateVerifiedPassword = (value, password) => {
	return value === password
}

export const validateInvonoCompanyId = (value) => {
	return value && /([F])([-])([0-9])([0-9])([0-9])([0-9])([-])([0-9])([0-9])([0-9])([0-9])/g.test(value)
}

export const validateInvonoUserId = (value) => {
	return value && /([A])([-])([0-9])([0-9])([0-9])([0-9])([-])([0-9])([0-9])([0-9])([0-9])/g.test(value)
}

/**
 * Validates a swedish org number.
 * @param {string} input - The org number as a string.
 * @see {@link https://gist.github.com/peppelorum/5856691}
 * @exports validateOrgNumber
 */
export const validateOrgNumber = (input) => {
	// Check valid length & form
	if (!input) {
		return false
	}

	input = input.trim().replace('-', '')

	if (input.length === 10) {
		const month = input.slice(2, 4)

		try {
			if (parseInt(month) < 20) {
				return false
			}
		} catch (e) {
			return false
		}

		if (input.indexOf('-') === -1) {
			input = input.slice(0, 6) + '-' + input.slice(6)
		}
		if (!input.match(/^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/)) {
			return false
		}

		// Clean input
		input = input.replace('-', '')

		// Declare variables
		let d = new Date(
			!!RegExp.$1 ? RegExp.$1 : RegExp.$5,
			(!!RegExp.$2 ? RegExp.$2 : RegExp.$6) - 1,
			!!RegExp.$3 ? RegExp.$3 : RegExp.$7
		)
		let sum = 0
		const numdigits = input.length
		const parity = numdigits % 2
		let i
		let digit

		// Check valid date
		if (Object.prototype.toString.call(d) !== '[object Date]' || isNaN(d.getTime())) return false

		// Check luhn algorithm
		for (i = 0; i < numdigits; i = i + 1) {
			digit = parseInt(input.charAt(i), 10)
			if (i % 2 === parity) {
				digit *= 2
			}
			if (digit > 9) {
				digit -= 9
			}
			sum += digit
		}
		return sum % 10 === 0
	}
	return false
}

/**
 * Validates a swedish person number.
 * @param {string} input - The person number as a string.
 * @see {@link https://gist.github.com/peppelorum/5856691}
 * @exports validatePersonNumber
 */
export const validatePersonNumber = (input) => {
	// Check valid length & form
	if (!input) {
		return false
	}

	input = input.trim().replace('demo=', '')

	input = input.trim().replace('-', '')

	if (input.length === 12 || input.length === 10) {
		if (input.length === 12) {
			const month = input.slice(4, 6)

			try {
				if (parseInt(month) > 12) {
					return false
				}
			} catch (e) {
				return false
			}

			if (input.indexOf('-') === -1) {
				input = input.slice(0, 8) + '-' + input.slice(8)
			}
			if (!input.match(/^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/)) {
				return false
			}

			// Clean input
			input = input.replace('-', '')

			// Remove the 19 or 20 in the beginning.
			input = input.substring(2)
		}

		if (input.length === 10 && !checkFirstFourCharInRange(input)) {
			const month = input.slice(2, 4)

			try {
				if (parseInt(month) > 12) {
					return false
				}
			} catch (e) {
				return false
			}

			if (input.indexOf('-') === -1) {
				input = input.slice(0, 6) + '-' + input.slice(6)
			}
			if (!input.match(/^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/)) {
				return false
			}

			// Clean input
			input = input.replace('-', '')
		}

		// Declare variables
		var d = new Date(
				!!RegExp.$1 ? RegExp.$1 : RegExp.$5,
				(!!RegExp.$2 ? RegExp.$2 : RegExp.$6) - 1,
				!!RegExp.$3 ? RegExp.$3 : RegExp.$7
			),
			sum = 0,
			numdigits = input.length,
			parity = numdigits % 2,
			i,
			digit

		// Check valid date
		if (Object.prototype.toString.call(d) !== '[object Date]' || isNaN(d.getTime())) return false

		// Check luhn algorithm
		for (i = 0; i < numdigits; i = i + 1) {
			digit = parseInt(input.charAt(i), 10)
			if (i % 2 === parity) {
				digit *= 2
			}
			if (digit > 9) {
				digit -= 9
			}
			sum += digit
		}
		return sum % 10 === 0
	}
	return false
}

/**
 * Validates a credit card number.
 * @param {string} input - The credit card number as a string.
 * @exports validateCreditCardNumber
 */
export const validateCreditCardNumber = (input) => {
	if (!input) {
		return true
	}

	const ccNumber = isNaN(input) ? input.replace(/\s/g, '') : input

	if (!/^[0-9]{14,16}$/.test(ccNumber)) {
		return false
	}

	// let sum = 0;
	// for (var i = 0; i < ccNumber.length; i++) {
	//     let intVal = parseInt(ccNumber.substr(i, 1));
	//     if (i % 2 == 0) {
	//         intVal *= 2;
	//         if (intVal > 9) {
	//             intVal = 1 + (intVal % 10);
	//         }
	//     }
	//     sum += intVal;
	// }
	//
	// return (sum % 10) == 0;
	return true
}

/**
 * Validates a credit card's cvc number.
 * @param {string} input - The cvc number as a string.
 * @exports validateCVCNumber
 */
export const validateCVCNumber = (input) => {
	return /^[0-9]{3,4}$/.test(input)
}

export const validatePaymentPlan = (value, immutableObj) => {
	const plan = value ? value : immutableObj.get('changeToPlan')
	return plan
}

export const validateExpDate = (value, immutableObj) => {
	if (!value) {
		return true
	}

	const date = moment(immutableObj.get('exp_year') + '-' + immutableObj.get('exp_month') + '-01')

	return date.isValid() && date.diff(moment(), 'days') >= 0
}

/**
 * Validate date as full date or just a year
 * @param {string} input - The date to validate
 * @exports validateDateAndYear
 */
export const validateDateAndYear = (input) => {
	let date = ''
	const regExp = /^\d{4}-\d{2}-\d{2}$/

	if (!input) {
		return null
	}

	if (input.length === 4) {
		date = input + '-01-01'
	} else {
		date = input
	}

	if (!date.match(regExp)) {
		return false
	}

	const dateObj = moment(date)
	return dateObj.toISOString().slice(0, 10) === date
}

/**
 * Validate a number of shares against the total number of shares
 */
export const validateNumOfShares = (input, type) => {
	let sharesLeft = false
	const transaction = store.getState().transaction.getIn(['tmpTransaction', type])

	if (!input || !transaction) {
		return true
	}

	const types = transaction.getIn(['handlerData', 'types'])
	types.forEach((type) => {
		if (!sharesLeft) {
			let numOfShares = type.get('numOfShares')

			input.forEach((investor) => {
				if (investor.get('type') === type.get('type')) {
					numOfShares -= parseInt(investor.get('numOfShares', 0))
				}
			})

			sharesLeft = numOfShares !== 0
		}
	})

	return sharesLeft === false
}

/**
 * Validate the number of shares - make sure that there is no shares left
 * This function is roughly the same as validateNumOfShares() with the difference that while that
 * function actually checks that the difference between the initial specified number of shares
 * and the number of distributed shares is not 0.
 */
export const validateNumOfSharesLeft = (input, type) => {
	let sharesLeft = false
	const transaction = store.getState().transaction.getIn(['tmpTransaction', type])

	if (!input || !transaction) {
		return true
	}

	const types = transaction.getIn(['handlerData', 'types'])
	types.forEach((type) => {
		if (!sharesLeft) {
			let numOfShares = type.get('numOfShares')

			input.forEach((investor) => {
				if (investor.get('type') === type.get('type')) {
					numOfShares -= parseInt(investor.get('numOfShares', 0))
				}
			})

			sharesLeft = numOfShares > 1
		}
	})

	return sharesLeft === false
}

/**
 * Validate number of shares - make sure that the number of distributed shares is not more than the initial value
 */
export const validateNumOfDistributedShares = (input, type) => {
	const transaction = store.getState().transaction.getIn(['tmpTransaction', type])

	if (!input || !transaction) {
		return true
	}

	const types = transaction.getIn(['handlerData', 'types'])
	let toManySharesDistributed = false

	types.forEach((type) => {
		if (!toManySharesDistributed) {
			let numOfShares = type.get('numOfShares')

			input.forEach((investor) => {
				if (investor.get('type') === type.get('type')) {
					numOfShares -= parseInt(investor.get('numOfShares', 0))
				}
			})

			toManySharesDistributed = numOfShares < 0
		}
	})

	return toManySharesDistributed === false
}

/**
 * Validate date of current transaction against last registered transaction
 */
export const validateTransactionDate = (value) => {
	const transaction = store.getState().transaction.get('transaction')
	const previousTransactionDate = moment(transaction.get('date'))

	if (!value) {
		return true
	}

	const selectedDate = moment(value)

	return moment(selectedDate).diff(moment(previousTransactionDate), 'days') >= 0
}

/**
 * Validate share types during Emission
 */
export const validateEmissionShareTypes = (values) => {
	if (!values) {
		return true
	}

	let hasError = false

	values.forEach((value) => {
		if (!value.get('numOfShares') || !parseInt(value.get('numOfShares'))) {
			hasError = true
		}

		if (!value.get('pricePerShare') || !parseFloat(value.get('pricePerShare'))) {
			hasError = true
		}
	})

	return !hasError
}

/**
 * Validate share types during Emission
 */
export const validateEmissionShareTypesPriceAgainstQuota = (values) => {
	const state = store.getState()
	const transaction = state.transaction.get('transaction')

	if (!values || !transaction) {
		return true
	}

	const quotaValue = transaction.getIn(['shareData', 'quotaValue'])

	let hasError = false

	values.forEach((value) => {
		if (Math.round(value.get('pricePerShare'), 4) < Math.round(quotaValue, 4)) {
			hasError = true
		}
	})

	return !hasError
}

export const validateInvestmentIdFrom = (value) => {
	const state = store.getState()
	const transaction = state.transaction.getIn(['tmpTransaction', state.transaction.get('currentTransactionType')])

	if (!value || !transaction) {
		return true
	}

	const investmentIdTo = transaction.getIn(['handlerData', 'investmentIdTo'])
	return !(value === investmentIdTo)
}

export const validateInvestmentIdTo = (value) => {
	const state = store.getState()
	const transaction = state.transaction.getIn(['tmpTransaction', state.transaction.get('currentTransactionType')])

	if (!value || !transaction) {
		return true
	}

	const investmentIdTo = transaction.getIn(['handlerData', 'investmentIdFrom'])
	return !(value === investmentIdTo)
}

export const validateTransferOfSharesSequences = (values) => {
	if (!values) {
		return true
	}

	let valid = true
	let hasShares = false
	values.forEach((sequence) => {
		if (!valid) {
			return
		}
		sequence = sequence.toJS()
		//Stop evaluating if there's no share transfered from the sequence
		if (!sequence.data) {
			return
		} else {
			//this flag ensures there's shares in atleast one of the sequences
			hasShares = true
		}
		const { sequenceFrom, sequenceTo } = sequence
		let data = sequence.data
		// Remove all whitespaces
		data = data.replace(/\s/g, '')

		const commaSplit = data.split(',')

		let lastSequenceTo = 0
		commaSplit.forEach((sequence) => {
			if (!valid) {
				return
			}
			const sequenceArr = sequence.split('-')
			// each sequence must have a start and an end sequencenumber
			if (sequenceArr.length !== 2) {
				valid = false
				return
			}
			let [from, to] = sequenceArr

			// each sequencenumber must be a valid positive integer
			if (!(isNormalInteger(from) && isNormalInteger(to))) {
				valid = false
				return
			}

			//convert strings to int
			try {
				from = parseInt(from)
				to = parseInt(to)
			} catch (e) {
				console.log(e)
				return
			}

			//each sequence must be from the shares you own
			if (from < sequenceFrom || to > sequenceTo) {
				valid = false
				return
			}

			//The end sequencenumber must be same or before the startsequencenumber
			if (from > to) {
				valid = false
				return
			}

			//Every sequence has to be after the previous one.
			if (from < lastSequenceTo) {
				valid = false
				return
			}

			lastSequenceTo = to
		})
	})

	return hasShares && valid
}

function isNormalInteger(str) {
	return /^\+?[1-9]\d*$/.test(str)
}

export const validateShareTypeFrom = (value) => {
	const state = store.getState()
	const transaction = state.transaction.getIn(['tmpTransaction', state.transaction.get('currentTransactionType')])

	if (!value || !transaction) {
		return true
	}

	const shareTypeTo = transaction.getIn(['handlerData', 'shareTypeTo'])
	return !(value === shareTypeTo)
}

export const validateShareTypeTo = (value) => {
	const state = store.getState()
	const transaction = state.transaction.getIn(['tmpTransaction', state.transaction.get('currentTransactionType')])

	if (!value || !transaction) {
		return true
	}

	const shareTypeFrom = transaction.getIn(['handlerData', 'shareTypeFrom'])
	return !(value === shareTypeFrom)
}

export const validateZeroSharesToConvert = (value) => {
	if (!value) {
		return true
	}

	let valid = true
	value.map((sequences) => {
		sequences.forEach((obj) => {
			if (obj.get('amount') === 0) {
				valid = false
			}
		})
	})

	return valid
}

export const validateNumOfSharesToConvertLtLimit = (value) => {
	const state = store.getState()
	const transaction = state.transaction.get('transaction')

	if (!value) {
		return true
	}

	let valid = true
	const sequences = transaction.get('sequences')

	value.map((seqs) => {
		seqs.forEach((seq) => {
			const numOfAvailShares =
				sequences.getIn([seq.get('index'), 'sequenceTo']) - sequences.getIn([seq.get('index'), 'sequenceFrom']) + 1

			if (seq.get('amount') > numOfAvailShares) {
				valid = false
			}
		})
	})

	return valid
}

export const validateSharecapitalDecrease = (value) => {
	const transaction = store.getState().transaction.get('transaction')

	if (!transaction) {
		return true
	}

	const shareCapital = transaction.getIn(['shareData', 'shareCapital'])

	return !(value > shareCapital) && shareCapital - value >= 25000
}

export const validateAnySharesLeft = (value, tmpTransaction) => {
	let reducedCount = 0
	const investments = tmpTransaction.getIn(['handlerData', 'investments'])
	investments.forEach((investment) => {
		investment.forEach((shareType) => {
			reducedCount += shareType.get('diff')
		})
	})

	const transaction = store.getState().transaction.get('transaction')
	const numOfTotalShares = transaction.getIn(['shareData', 'numOfTotalShares'])
	const sharesLeft = numOfTotalShares - reducedCount
	if (sharesLeft < 1) {
		return false
	}
	return true
}

export const validateNumOfRemainingSharesToRemove = (value, tmpTransaction) => {
	const transaction = store.getState().transaction.get('transaction')

	if (!transaction || !value) {
		return true
	}

	const quotaValue = transaction.getIn(['shareData', 'quotaValue'])
	const shareCapital = transaction.getIn(['shareData', 'shareCapital'])
	const numOfShares = transaction.getIn(['shareData', 'numOfTotalShares'], 0)
	const newShareCapital = shareCapital - tmpTransaction.getIn(['handlerData', 'decreaseShareCapitalBy'], 0)
	const newNumOfShares = Math.floor(newShareCapital / quotaValue)

	let numOfRemovedShares = 0

	value.map((investment) => {
		investment &&
			investment.forEach((obj) => {
				numOfRemovedShares += parseInt(obj.get('diff', 0))
			})
	})

	return numOfShares - newNumOfShares - numOfRemovedShares === 0
}

export const vaidateNumOfRemovedSharesPerType = (value) => {
	const transaction = store.getState().transaction.get('transaction')

	if (!transaction || !value) {
		return true
	}

	const balances = transaction.get('balances')
	let valid = true

	value.map((investment, investmentId) => {
		investment.forEach((obj) => {
			const storedType = balances.getIn([investmentId, 'types']).find((type) => {
				return type.get('type') === obj.get('type')
			})
			const numOfShares = storedType.get('shares')

			if (obj.get('diff') > numOfShares) {
				valid = false
			}
		})
	})

	return valid
}

export const validateNumOfSharesToDistributeGtZero = (value) => {
	if (!value) {
		return true
	}

	let valid = true
	value.map((shareType) => {
		if (!shareType.get('numOfShares')) {
			valid = false
		}
	})

	return valid
}

export const validateShareTypeNotNull = (values) => {
	if (!values) {
		return true
	}

	let valid = true
	values.forEach((obj) => {
		if (!obj.get('type')) {
			valid = false
		}
	})

	return valid
}

export const validateDistributedNumOfSharesNotGtLimit = (value, tmpTransaction) => {
	if (!value || !tmpTransaction) {
		return true
	}

	let numOfSharesToDistribute = 0
	const shareTypes = tmpTransaction.getIn(['handlerData', 'shareTypes'])

	if (!shareTypes) {
		return true
	}

	shareTypes.forEach((obj) => {
		if (obj.get('numOfShares') > 0) {
			numOfSharesToDistribute += obj.get('numOfShares')
		}
	})

	let remainingSharesToDistribute = numOfSharesToDistribute
	value.map((shareTypes) => {
		shareTypes.forEach((obj) => {
			if (obj.get('diff') > 0) {
				remainingSharesToDistribute -= obj.get('diff')
			}
		})
	})

	return remainingSharesToDistribute === 0
}

export const validateBeforeAndAfterNotEqual = (value, tmpTransaction) => {
	if (value === null || value === undefined) {
		return true
	}

	const afterValue = tmpTransaction.getIn(['handlerData', 'after'])
	const beforeValue = tmpTransaction.getIn(['handlerData', 'before'])

	return afterValue !== beforeValue
}

export const validateBeforeAndAfterGtZero = (value) => {
	if (value === null || value === undefined) {
		return true
	}

	return value > 0
}

export const validateAuthCode = (code = '') => {
	code = code.trim()

	const hasNumbersOnly = /^\d+$/.test(code)
	const hasCorrectLength = code.length === 4
	return hasNumbersOnly && hasCorrectLength
}
