import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromJS, List } from 'immutable'

import GenericInfoPanel from '../../../dumb-components/company/comapny-dashboard/generic-info-panel/generic-info-panel'
import { countMeetings } from '../../../actions/meetings.actions'

class MeetingsPanelContainer extends Component {
	state = {
		isLoading: true,
		data: List()
	}

	componentDidMount = () => {
		this.countMeetings()
	}

	componentDidUpdate = (prevProps) => {
		const { companyId } = this.props

		if (prevProps.companyId !== companyId) {
			this.setState({ isLoading: true })
			this.countMeetings()
		}
	}

	countMeetings = () => {
		this.props.countMeetings((response) => {
			const numOfMeetings = response.get('numOfMeetings', '-').toString()
			const numOfUpcomingMeetings = response.get('numOfUpcomingMeetings', '-').toString()

			this.setState({
				data: fromJS([
					{ title: 'company.dashboard.meetings.panel.created', value: numOfMeetings },
					{ title: 'company.dashboard.meetings.panel.upcoming', value: numOfUpcomingMeetings }
				]),
				isLoading: false
			})
		})
	}

	render = () => {
		const { isLoading, data } = this.state

		return <GenericInfoPanel title='company.dashboard.meetings.panel.title' data={data} isLoading={isLoading} />
	}
}

const mapStoreToProps = (store) => {
	return {
		companyId: store.company.company.id
	}
}

const mapActionsToProps = {
	countMeetings
}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingsPanelContainer)
