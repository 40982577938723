import React, { useContext } from 'react'
import { object } from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import history from '../../../interfaces/history'
import theme from '../../../../theme'
import { InvestmentsContext } from '../investment.container'
import Box from '@mui/material/Box'
import { Badge } from '../../../dumb-components/investment/peek/Badge'

const StyledTab = styled(Tab)((props) => ({
	'&:before': {
		background: theme.colors[props.highlightcolor]
	}
}))

function LinkTab(props) {
	const { setEmissionTabIndex } = useContext(InvestmentsContext)
	const navigate = (e) => {
		e.preventDefault()

		history.push(`${e.target.pathname}${e.target.search}`)
	}
	return (
		<StyledTab
			component='a'
			disableRipple={true}
			disabled={props.disabled}
			highlightcolor={props.highlightcolor}
			onClick={(e) => {
				// display emission list on tab click
				if (props.path === 'emission') {
					setEmissionTabIndex(0)
				}
				navigate(e)
			}}
			{...props}
		/>
	)
}

export default function InvestmentTabsContainer({ location }) {
	const { permissions, isShareholderPreview, isCompany } = useContext(InvestmentsContext)
	const isGuest = permissions && permissions.get('isGuest')
	const isDealflow = permissions?.get?.('isDealflow')
	const { t } = useTranslation()

	const getParentBasePath = () => {
		const { pathname } = location

		const pathArray = pathname.split('/')
		const lastItem = pathArray[pathArray.length - 1]
		const basePath = pathname.replace(`/${lastItem}`, '')

		return basePath
	}

	const getTabs = () => {
		return [
			{
				path: 'general',
				highlightColor: 'solitudeDark',
				tid: 'investments.tabs.general'
			},
			{
				path: 'sharebook',
				highlightColor: 'solitudeDark',
				tid: 'investments.tabs.share_book',
				disabled: isGuest || isDealflow,
				notVisible: isGuest || isDealflow
			},
			{
				path: 'shareholders',
				highlightColor: 'solitudeDark',
				tid: 'investments.tabs.shareholders',
				disabled: isGuest || isDealflow,
				notVisible: isGuest || isDealflow
			},
			{
				path: 'my-records',
				highlightColor: 'solitudeDark',
				tid: 'investments.tabs.my_records',
				disabled: isShareholderPreview || isCompany || isDealflow,
				notVisible: isShareholderPreview || isCompany || isDealflow
			},
			{
				path: 'documents',
				highlightColor: 'solitudeDark',
				tid: 'investments.tabs.documents',
				disabled: isGuest || isDealflow,
				notVisible: isGuest || isDealflow
			},
			{
				path: 'emission',
				highlightColor: 'solitudeDark',
				tid: 'investments.tabs.emission',
				disabled: isGuest || isDealflow,
				notVisible: isGuest || isDealflow
			}
			//{
			//	path: 'capital',
			//	highlightColor: 'solitudeDark',
			//	tid: 'investments.tabs.capital',
			//	disabled: isGuest || isDealflow,
			//	notVisible: isGuest || isDealflow,
			//},
			//{
			//	path: 'buy-sell',
			//	highlightColor: 'solitudeDark',
			//	tid: 'investments.tabs.buy_sell',
			//},
			//{
			//	path: 'reports',
			//	highlightColor: 'solitudeDark',
			//	tid: 'investments.tabs.reports',
			//	disabled: isGuest || isDealflow,
			//	notVisible: isGuest || isDealflow,
			//},
			//{
			//{
			//	path: 'simulation',
			//	highlightColor: 'solitudeDark',
			//	tid: 'investments.tabs.simulation',
			//	disabled: isGuest || isDealflow,
			//	notVisible: isGuest || isDealflow,
			//}
		]
	}

	const basePath = getParentBasePath()
	const tabs = getTabs()

	const getActivePath = () => {
		const { pathname } = location

		const pathArray = pathname.split('/')
		const lastItem = pathArray[pathArray.length - 1]
		const visibleTabs = tabs.filter((tab) => !tab.disabled)
		const index = visibleTabs.findIndex((tab) => tab.path === lastItem)

		if (index === -1) {
			return 0
		}

		return index
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flex: 1 }}>
			<Box sx={{ flex: 1 }}>
				<Tabs
					scrollButtons='auto'
					variant='scrollable'
					value={getActivePath()}
					selectionFollowsFocus={false}
					TabIndicatorProps={{ style: { display: 'none' } }}>
					{tabs &&
						tabs.map((tab, i) => {
							const { tid, path, disabled, notVisible, highlightColor } = tab

							if (notVisible) {
								return null
							}

							return (
								<LinkTab
									key={i}
									label={t(tid)}
									disabled={disabled}
									highlightcolor={highlightColor}
									href={`${basePath}/${path}${location.search ? location.search : ''}`}
									path={path}
								/>
							)
						})}
				</Tabs>
			</Box>
			{isShareholderPreview && (
				<Box>
					<Badge />
				</Box>
			)}
		</Box>
	)
}

InvestmentTabsContainer.propTypes = {
	location: object
}
