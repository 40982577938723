import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { func, bool, number, object, string } from 'prop-types'

import AttendeesPanel from '../../../../dumb-components/meetings/attendees-panel/attendees-panel'
import MainControlsContainer from '../../attendees/panel/main-controls.container'
import InviteAttendeesControl from './dropdown-items/invite-attendees.container'
import AttendeesListContainer from '../../attendees/attendees-list.container'
import AttendeesSummaryListContainer from './attendees-summary-list.container'

import { MEETING_TYPE_STANDARD, MEETING_TYPE_SMART } from '/shared/constants'
import { openModal } from '../../../../actions/modals.actions'
import { MEETINGS_MANAGE_MODERATORS } from '../../../../constants/modals'

class AttendeesPanelContainer extends Component {
	// + all AttendeeListContainer props
	static propTypes = {
		renderMoreActionItem: func,
		headerRightComponent: object,
		bottomComponent: object,
		showSummary: bool,
		noPanel: bool,
		isEditing: bool,
		forceDisableAttendeeWarning: bool,
		customInfoTextComponent: object,
		hideInviteAttendee: bool,
		innerMarginTop: number,
		innerMarginRight: number,
		innerMarginBottom: number,
		innerMarginLeft: number,
		attendeesRenderLimit: number,
		isStatic: bool,
		attendeesWarningComponent: object,
		scrollableTarget: string
	}

	static defaultProps = {
		clickable: true
	}

	state = {
		currentAttendeeId: null
	}

	openManageAdministratorsModal = () => {
		const { openModal } = this.props
		openModal(MEETINGS_MANAGE_MODERATORS)
	}

	renderHeaderRightComponent = () => {
		const { headerRightComponent, showSummary } = this.props

		if (headerRightComponent) {
			return headerRightComponent
		}

		if (showSummary) {
			return null
		}

		return <MainControlsContainer marginBottom={4} />
	}

	renderBottomComponent = () => {
		const { bottomComponent, showSummary } = this.props

		if (bottomComponent) {
			return bottomComponent
		}

		if (showSummary) {
			return null
		}

		return <InviteAttendeesControl type='button' renderNullIfButtonDisabled={false} />
	}

	renderAttendeesList = () => {
		const {
			readOnly,
			attendees,
			isStandardMeeting,
			permissions,
			chairman,
			secretary,
			renderMoreActionItem,
			meetingIsDeleted,
			hasMeetingPassed,
			basePath,
			querystr,
			params,
			clickable,
			forceDisableAttendeeWarning,
			hideInviteAttendee,
			attendeesRenderLimit,
			onClickShowMoreAttendees,
			isStatic,
			moderators,
			scrollableTarget
		} = this.props

		return (
			<AttendeesListContainer
				basePath={basePath}
				querystr={querystr}
				params={params}
				attendees={attendees}
				readOnly={isStandardMeeting ? false : readOnly}
				forceReadOnly={meetingIsDeleted}
				forceDisableAttendeeWarning={forceDisableAttendeeWarning}
				permissions={permissions}
				chairman={chairman}
				secretary={secretary}
				renderMoreActionItem={renderMoreActionItem}
				clickable={clickable}
				showMoreAction={!readOnly}
				forceCanSetStatus={isStandardMeeting}
				hideInviteAttendee={hideInviteAttendee}
				attendeesRenderLimit={attendeesRenderLimit}
				onClickShowMoreAttendees={onClickShowMoreAttendees}
				moderators={moderators}
				scrollableTarget={scrollableTarget}
			/>
		)
	}

	renderSummaryAttendeesList = () => {
		return <AttendeesSummaryListContainer />
	}

	render = () => {
		const {
			attendees,
			showSummary,
			noPanel,
			customInfoTextComponent,
			isStandardMeeting,
			hasMeetingPassed,
			innerMarginTop,
			innerMarginRight,
			innerMarginBottom,
			innerMarginLeft,
			meetingHasModerator,
			attendeesWarningComponent,
			rollcallDone
		} = this.props

		return (
				<AttendeesPanel
					headerRightComponent={this.renderHeaderRightComponent()}
					bottomComponent={this.renderBottomComponent()}
					invitedAttendeesListComponent={this.renderAttendeesList()}
					summaryAttendeesListComponent={this.renderSummaryAttendeesList()}
					showSummary={showSummary}
					attendees={attendees}
					noPanel={noPanel}
					isStandardMeeting={isStandardMeeting}
					hasMeetingPassed={hasMeetingPassed}
					customInfoTextComponent={customInfoTextComponent}
					innerMarginTop={innerMarginTop}
					innerMarginRight={innerMarginRight}
					innerMarginBottom={innerMarginBottom}
					innerMarginLeft={innerMarginLeft}
					meetingHasModerator={meetingHasModerator}
					attendeesWarningComponent={attendeesWarningComponent}
					rollcallDone={rollcallDone}
					onManageAdministrators={this.openManageAdministratorsModal}
				/>
		)
	}
}

const mapStoreToProps = (store) => {
	const smartMeeetingIsFinished = store.meetings.getIn(['meeting', 'computedValues', 'meetingIsFinished'])
	const standardMeetingIsFinished = store.meetings.getIn(['meeting', 'computedValues', 'hasMeetingPassed'])
	const isSmartMeeting = store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_SMART

	return {
		isStandardMeeting: store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_STANDARD,
		hasMeetingPassed: standardMeetingIsFinished || smartMeeetingIsFinished,
		readOnly: isSmartMeeting && store.meetings.getIn(['meeting', 'computedValues', 'isSimpleMode'], false),
		attendees: store.meetings.getIn(['meeting', 'attendees'], Map()),
		permissions: store.meetings.getIn(['meeting', 'permissions']),
		chairman: store.meetings.getIn(['meeting', 'chairman']),
		secretary: store.meetings.getIn(['meeting', 'secretary']),
		meetingIsDeleted: store.meetings.getIn(['meeting', 'isDeleted']),
		meetingHasModerator: store.meetings.getIn(['meeting', 'computedValues', 'hasModerator']),
		moderators: store.meetings.getIn(['meeting', 'moderators'], List()) || List(),
		rollcallDone: store.meetings.getIn(['meeting', 'metadata', 'rollcallDone'])
	}
}

const mapActionsToProps = {
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(AttendeesPanelContainer)
