import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

class ShareTypeValueRenderer extends Component {
	render = () => {
		const arr = this.props.children.split('[split-here]')
		const name = arr[0]
		const left = arr[1]

		return (
			<div className='Select-value'>
				<span className='Select-value-label'>
					{name}{' '}
					<span className='text--muted'>
						<FormattedMessage values={{ num: left }} id='N_left' />
					</span>
				</span>
			</div>
		)
	}
}

export default ShareTypeValueRenderer
