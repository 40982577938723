import React from 'react'
import { string, oneOf, number, func } from 'prop-types'
import styled from 'styled-components'
import moment from '/shared/helpers/moment.helper'
import Text from '../text/text'

const StyledWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`

const CountDown = ({ mode, dateFormat, prefixTid, suffixTid, time, onFinish, renderRaw }) => {
	const renderStatic = () => {
		return (
			<StyledWrapper>
				<Text tag='div' fontSize='72px' color='black' bold={700}>
					{time}
				</Text>
				<Text tag='div' tid={suffixTid} />
			</StyledWrapper>
		)
	}

	const renderLive = () => {
		const interval = 1000
		const [counter, setCounter] = React.useState(time)

		if (counter < interval && onFinish) {
			onFinish()
		}

		React.useEffect(() => {
			counter >= interval && setTimeout(() => setCounter(counter - interval), interval)
		}, [counter])

		const value = dateFormat ? moment(counter).format(dateFormat) : counter

		if (renderRaw) {
			return counter > 0 ? value : '00:00'
		}

		return (
			<StyledWrapper>
				<Text tag='div' color='muted' tid={prefixTid} />
				<Text tag='div' fontSize='36px' color='black' bold={700}>
					{counter > 0 ? value : '00:00'}
				</Text>
				<Text tag='div' color='muted' tid={suffixTid} />
			</StyledWrapper>
		)
	}

	if (mode === 'static') {
		return renderStatic()
	}

	return renderLive()
}

CountDown.propTypes = {
	mode: oneOf(['static', 'live']),
	dateFormat: string,
	prefixTid: string,
	suffixTid: string,
	time: number,
	onFinish: func
}

CountDown.defaultProps = {
	mode: 'static'
}

export default CountDown
