import React, { PureComponent } from 'react'
import { string, bool, func } from 'prop-types'
import styled from 'styled-components'

import Button from '../button/button'
import BankId from '../bankid/bankid'
import Modal from '../modal/modal'

const StyledWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`

export default class BankIdModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		onCancel: func,
		bankIdTransaltionId: string
	}

	static defaultProps = {}

	renderFooterComponent = () => {
		const { onCancel } = this.props

		if (!onCancel) {
			return null
		}

		return <Button onClick={onCancel} tid='generic.form.cancel' />
	}

	render = () => {
		const { isOpen, bankIdTransaltionId } = this.props

		return (
			<Modal
				isOpen={isOpen}
				hSize='sm'
				vSize={50}
				scrollableContent={false}
				footerComponent={this.renderFooterComponent()}>
				<StyledWrapper>
					<BankId mode='dark' bankIdTransaltionId={bankIdTransaltionId} fill={false} coloredLogo={true} size='170px' />
				</StyledWrapper>
			</Modal>
		)
	}
}
