import React, { Component } from 'react'
import { bool, func, string, oneOf, oneOfType, object, array } from 'prop-types'
import Confirm from '../../dumb-components/shared/confirm/confirm'
import FooterRightControls from '../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../dumb-components/shared/button-v2'

export default class ConfirmContainer extends Component {
	static propTypes = {
		title: string,
		question: oneOfType([string, object]),
		message: oneOfType([string, object, array]),
		onConfirm: oneOfType([func, object]),
		onDecline: func,
		isOpen: bool,
		isLoading: bool,
		mode: oneOf(['confirm', 'alert']),
		dismissable: bool,
		onDismiss: func,
		isConfirmDismissed: bool,
		hasColoredWrapper: bool,
		infoTid: string,
		declineBtnTid: string,
		confirmBtnTid: string,
		oneWrapper: bool,
	}

	static defaultProps = {
		isOpen: false,
		mode: 'confirm',
		confirmBtnTid: 'generic.yes',
		declineBtnTid: 'generic.no',
		hasColoredWrapper: true
	}

	onConfirm = () => {
		const { onConfirm } = this.props

		onConfirm && onConfirm()
	}

	onDecline = () => {
		const { onDecline } = this.props

		onDecline && onDecline()
	}

	renderButtons = () => {
		const { isLoading, mode, confirmBtnTid, declineBtnTid } = this.props

		if (mode === 'alert') {
			return (
				<TransparentButton
					tid='generic.form.close'
					textColor='midGrey'
					onClick={this.onConfirm}
					isLoading={isLoading}
				/>
			)
		}

		return (
			<FooterRightControls>
				<TransparentButton tid={confirmBtnTid} onClick={this.onConfirm} isLoading={isLoading} />
				<TransparentButton tid={declineBtnTid} textColor='midGrey' onClick={this.onDecline} disabled={isLoading} />
			</FooterRightControls>
		)
	}

	render = () => {
		const {
			title,
			question,
			message,
			isOpen,
			dismissable,
			onDismiss,
			isConfirmDismissed,
			questionValues,
			hasColoredWrapper,
			infoTid,
			oneWrapper
		} = this.props

		return (
			<Confirm
				title={title}
				question={question}
				questionValues={questionValues}
				message={message}
				isOpen={isOpen}
				footerComponent={this.renderButtons()}
				dismissable={dismissable}
				onDismiss={onDismiss}
				isConfirmDismissed={isConfirmDismissed}
				hasColoredWrapper={hasColoredWrapper}
				infoTid={infoTid}
				oneWrapper={oneWrapper}
			/>
		)
	}
}
