import React, { PureComponent } from 'react'
import { string, bool, object } from 'prop-types'
import styled from 'styled-components'

import Badge from '../shared/badge/badge-new'

import Text from '../shared/text/text'
import { ButtonTransparentIcon } from '../shared/button'

const StyledAlert = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${(props) => props.theme.spacing[4]};
	background-color: ${(props) => props.theme.colors.limedSpruce};
	border-color: ${(props) => props.theme.colors.limedSpruce};
	border-radius: 8px;
`

const Content = styled.div`
	align-items: flex-start;
	flex-direction: column;
	display: flex;
	flex: 1;
`

const Controls = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

export default class OnboardingAlert extends PureComponent {
	static propTypes = {
		controlsComponent: object,
		badgeComponent: object
	}

	static defaultProps = {}

	render = () => {
		const { badgeComponent, controlsComponent, children } = this.props

		return (
			<StyledAlert>
				{badgeComponent && badgeComponent}

				<Content>{children}</Content>

				{controlsComponent && <Controls>{controlsComponent}</Controls>}
			</StyledAlert>
		)
	}
}
