import React, { Component } from 'react'
import { connect } from 'react-redux'
import immutablePropTypes from 'react-immutable-proptypes'
import { func } from 'prop-types'
import { List } from 'immutable'
import MeetingTemplateDetails from '../../../dumb-components/meetings/meeting-templates/meeting-templates-details/meeting-templates-details'
import TemplateItemsListContainer from '../../meetings/meeting-templates/meeting-template-items-list.container'
import { MEETING_INTERNAL_TYPES } from '../../../constants/meetings'
import DurationSelectContainer from '../agenda/duration-select.container'
import SuggestedTopicSetting from '../../../dumb-components/meetings/meeting-templates/suggested-topics-setting/suggested-topic-setting'

class MeetingTemplatesDetailsContainer extends Component {
	state = {
		selectedTemplateItem: null
	}

	static propTypes = {
		meetingTemplate: immutablePropTypes.map,
		onChange: func,
		errors: immutablePropTypes.map
	}

	onChange = (fieldName, value) => {
		const { onChange, resetErrors } = this.props
		let { meetingTemplate } = this.props
		fieldName = !Array.isArray(fieldName) ? [fieldName] : fieldName
		meetingTemplate = meetingTemplate.setIn(fieldName, value)
		resetErrors(fieldName)
		onChange && onChange(meetingTemplate)
	}

	onChangeItem = (fieldName, value, updateItems = false) => {
		const { meetingTemplate } = this.props
		let { selectedTemplateItem } = this.state

		selectedTemplateItem = selectedTemplateItem.set(fieldName, value)

		if (updateItems) {
			const itemId = selectedTemplateItem.get('id')
			let items = meetingTemplate.get('items')

			items = items.map((item) => {
				if (item.get('id') === itemId) {
					item = selectedTemplateItem
				} else if (item.get('items', List()).size > 0) {
					const subitems = item.get('items', List()).map((subitem) => {
						if (subitem.get('id') === itemId) {
							subitem = selectedTemplateItem
						}

						return subitem
					})

					item = item.set('items', subitems)
				}

				return item
			})

			this.onChange(['items'], items)
		}

		this.setState({ selectedTemplateItem })
	}

	onChangeDuration = (fieldName, value) => {
		this.onChangeItem(fieldName, value, true)
	}

	onItemSelected = (selectedTemplateItem) => {
		this.setState({ selectedTemplateItem })
	}

	renderAgendaList = () => {
		const { meetingTemplate, i18n } = this.props
		const { selectedTemplateItem } = this.state
		const items = meetingTemplate ? meetingTemplate.get('items', List()) : List()

		return (
			<>
				<TemplateItemsListContainer
					onItemSelect={this.onItemSelected}
					onChange={this.onChange}
					onChangeItem={this.onChangeItem}
					selectedItemId={selectedTemplateItem && selectedTemplateItem.get('id')}
					meetingTemplate={meetingTemplate}
					items={items}
				/>
				<SuggestedTopicSetting
					onChange={this.onChange}
					name={i18n.messages['meetings.template.suggested_topics_setting.allow']}
					seperatorBgColor='white'
					allowSuggestedTopics={meetingTemplate && meetingTemplate.get('allowSuggestedTopics')}
				/>
			</>
		)
	}

	renderDurationComponent = () => {
		const { selectedTemplateItem } = this.state

		return <DurationSelectContainer agendaItem={selectedTemplateItem} onChange={this.onChangeDuration} />
	}

	render = () => {
		const { selectedTemplateItem } = this.state
		const { meetingTemplate, errors } = this.props
		const internalType = selectedTemplateItem && selectedTemplateItem.get('internalType')
		const internalTypeTid = internalType && MEETING_INTERNAL_TYPES.get(internalType)

		return (
			<MeetingTemplateDetails
				templateItem={selectedTemplateItem}
				template={meetingTemplate}
				onChange={this.onChange}
				onChangeItem={this.onChangeItem}
				internalTypeTid={internalTypeTid}
				agendaListRenderer={this.renderAgendaList}
				durationComponentRenderer={this.renderDurationComponent}
				errors={errors}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		i18n: store.i18n
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingTemplatesDetailsContainer)
