import React, { PureComponent } from 'react'
import { string, bool } from 'prop-types'
import styled, { css } from 'styled-components'
import Icon from '../icon/icon'

const ModernDragHandler = styled.div`
	display: flex;
	align-items: center;
	margin: 0 ${(props) => props.theme.spacing[2]};
	opacity: 0;
	transition: opacity 0.1s ease-in;
	justify-content: center;

	${({ isDragDisabled }) =>
		isDragDisabled &&
		css`
			visibility: hidden;
		`}
`

export const modernHoverStyling = css`
	opacity: 1;
`

export default class ModernDndDragHandlers extends PureComponent {
	static propTypes = {
		className: string,
		isDragDisabled: bool
	}

	render = () => {
		const { className, isDragDisabled } = this.props

		return (
			<ModernDragHandler className={className} isDragDisabled={isDragDisabled}>
				<Icon icon='faArrowsAlt' type='solid' size='10' color='midGrey' />
			</ModernDragHandler>
		)
	}
}
