import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'
import { list } from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import { maskSsn } from '../../../components/helpers/users'
import Text from '../../../dumb-components/shared/text/text'
import { Select } from '../../../dumb-components/shared/select'

class AccountWizardRepresentativesSelect extends Component {
	state = {
		options: List()
	}

	static propTypes = {
		onChange: func,
		value: string,
		allowedRepresentativesToSign: list,
		onSetRef: func
	}

	componentDidUpdate = (prevProps) => {
		const { allowedRepresentativesToSign } = this.props

		if (allowedRepresentativesToSign !== prevProps.allowedRepresentativesToSign) {
			this.setOptions()
		}
	}

	setOptions = () => {
		const { allowedRepresentativesToSign } = this.props

		let options = List()
		allowedRepresentativesToSign.forEach((rep) => {
			options = options.push(Map({ value: rep.get('ssn'), label: rep.get('name') }))
		})

		this.setState({ options })
	}

	formatOptionLabel = (option) => {
		return (
			<Text>
				{option.label} | <Text color='muted'>{maskSsn(option.value)}</Text>
			</Text>
		)
	}

	render = () => {
		const { onChange, value, onSetRef } = this.props

		return (
			<Select
				fieldName='toBeAuthorizedBy'
				options={this.state.options}
				formatOptionLabel={this.formatOptionLabel}
				onChange={onChange}
				placeholderTid='select_placeholder'
				value={value}
				onSetRef={onSetRef}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(AccountWizardRepresentativesSelect)
