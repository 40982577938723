import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map, fromJS } from 'immutable'
import immutablePropTypes from 'react-immutable-proptypes'
import { string, func, bool, object } from 'prop-types'
import { listGroups } from '../../../actions/groups.actions'
import Box from '../../../dumb-components/shared/layout/box/box.js'
import AssigneeContainer from '../../shared/assignee.container'
import NewListItemButton from '../../../dumb-components/shared/new-list-item-button/new-list-item-button'
import AddAttendeesModalContainer from './add-attendees-modal.container'

class AttendeeSelectContainer extends Component {
	static propTypes = {
		readOnly: bool,
		groupId: string,
		permissions: immutablePropTypes.map,
		onSelectAttendee: func,
		values: immutablePropTypes.map,
		showStatusIndicator: bool,
		investorsTooltip: string,
		externalsTooltip: string,
		excludeInvestors: bool,
		excludeExternals: bool,
		disableShareholderFunction: func
	}

	static defaultProps = {
		showStatusIndicator: true
	}

	state = {
		isSelectUserModalOpen: false,
		showStatusIndicator: true
	}

	componentDidMount() {
		const { groups, listGroups } = this.props

		if (!groups) {
			listGroups()
		}
	}

	onChange = (val) => {
		const { onSelectAttendee } = this.props

		// Prevent from running when backspace is pressed
		if (!val) {
			return
		}

		if (val === 'addExternalAttendee') {
			this.setState({ isSelectUserModalOpen: true })
			return
		}

		onSelectAttendee && onSelectAttendee(val)
	}

	closeUserModal = () => {
		this.setState({ isSelectUserModalOpen: false })
	}

	renderAssigneeContainer = () => {
		const { groups, groupId, permissions, values, i18n } = this.props
		const group = groupId && groups ? groups.get(groupId) : null
		const prependOptions = List([
			Map({
				value: 'addExternalAttendee',
				label: i18n.messages['meetings.attendees.add_external_attendee']
			})
		])
		const excludeOptions = values && values.map((obj) => obj.get('userId')).toList()

		return (
			<AssigneeContainer
				onChange={this.onChange}
				permissions={permissions}
				isRoot={!group}
				prependOptions={prependOptions}
				excludeOptions={excludeOptions}
				placeholderTid='select.placeholder.type_or_select'
			/>
		)
	}

	render() {
		const {
			readOnly,
			values,
			showStatusIndicator,
			investorsTooltip,
			externalsTooltip,
			excludeInvestors,
			excludeExternals,
			permissions,
			disableShareholderFunction,
			onSelectMultipleAttendees
		} = this.props
		const { isSelectUserModalOpen } = this.state

		if (readOnly) {
			return null
		}

		return (
			<Box>
				<NewListItemButton
					key={values}
					permissions={permissions}
					renderInputComponent={this.renderAssigneeContainer}
					mode='lighter'
					showStatusIndicator={showStatusIndicator}
					reverse
				/>
				<AddAttendeesModalContainer
					isOpen={isSelectUserModalOpen}
					users={values}
					onClose={this.closeUserModal}
					onSelectMultipleAttendees={onSelectMultipleAttendees}
					excludeInvestors={excludeInvestors}
					excludeExternals={excludeExternals}
					investorsTooltip={investorsTooltip}
					externalsTooltip={externalsTooltip}
					disableShareholderFunction={disableShareholderFunction}
				/>
			</Box>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		permissions: store.meetings.getIn(['meeting', 'permissions']),
		groups: store.groups.get('groups'),
		companyUsers: store.company.members,
		i18n: store.i18n
	}
}

const mapActionsToProps = {
	listGroups
}

export default connect(mapStoreToProps, mapActionsToProps)(AttendeeSelectContainer)
