import React, { useState } from 'react'
import Panel from '../../../../dumb-components/panel'
import Field from '../../../../dumb-components/fields/field'
import Select from '../../../../dumb-components/fields/select'
import Box from '../../../../dumb-components/shared/layout/box/box'
import Button from '../../../../dumb-components/shared/button/button'


export default function SettingsField({ user, messages, debounce, resetOnboardingIsLoading, resetOnboardingModals }) {
	const [selectedLanguage, setSelectedLanguage] = useState(user.getIn(['siteSettings', 'language']))
	const languageOptions = [
		{ label: 'Svenska', value: 'sv-SE' },
		{ label: 'English', value: 'en-GB' }
	]

	const onChangeDisplayLanguage = (val) => {
		if (!user.get('siteSettings')) {
			user = user.set('siteSettings', Map({ language: val }))
		} else {
			setSelectedLanguage(val)
			user = user.setIn(['siteSettings', 'language'], val)
		}
		debounce(user)
	}

	if (!user) {
		return null
	}


	return (
		<Panel tid='settings'>
			<Field tid='generic_display-language'>
				<Select
					simpleValue={true}
					options={languageOptions}
					onSelect={onChangeDisplayLanguage}
					value={selectedLanguage }
					placeholder={messages['select_placeholder']}
				/>
			</Field>
			<Field tid='user.profile.settings.onboarding.label'>
				<Box>
					<Button
						tid='user.profile.settings.onboarding.btn.reset'
						onClick={resetOnboardingModals}
						isLoading={resetOnboardingIsLoading}
					/>
				</Box>
			</Field>
		</Panel>
	)
}