// ---------------------------------------------------------------------------------------
// ----------------------------------- Select Creatable ----------------------------------
// ---------------------------------------------------------------------------------------
import React from 'react'
import Creatable from 'react-select-next/creatable'
import styled from 'styled-components'
import { string, bool, func, number, oneOfType, oneOf } from 'prop-types'
import ImmutableProps from 'react-immutable-proptypes'
import StyledSelectTemplate from './select.template'
import { setupCustomComponents, parseValue } from './select.helpers'
import Text from '../../text/text'
import { List } from 'immutable'

const StyledCretableSelect = styled(Creatable)`
	${StyledSelectTemplate}
`

const SelectCreatable = (props) => {
	const {
		labelIsTid,
		placeholderTid,
		optionsValue,
		optionsLabel,
		isDisabled,
		formatCreateLabel,
		onChange,
		fieldName,
		value,
		isLoading,
		isClearable,
		hideSelectedOptions,
		maxMenuHeight,
		menuPortalTarget,
		formatOptionLabel,
		createOptionPosition
	} = props
	const customComponentsConfig = { labelIsTid, placeholderTid, isClearable }
	let { options } = props

	// NULL !== undefined that's why default props don't help
	if (!options) {
		options = List()
	}

	const isValidNewOption = (inputValue, selectValue, selectOptions) => {
		if (
			inputValue.trim().length === 0 ||
			// OBS: Do NOT change below to exakt match operator (===)
			selectOptions.find((option) => option[optionsValue] == inputValue)
		) {
			return false
		}
		return true
	}

	const _onChange = (selectedValue, { action }) => {
		// Return only VALUES
		if (action !== 'create-option') {
			// If value exist. The field might have been cleared
			if (selectedValue) {
				selectedValue = selectedValue[optionsValue]
			}
		}

		if (fieldName) {
			return onChange && onChange(fieldName, selectedValue, action)
		}

		return onChange && onChange(selectedValue, action)
	}

	return (
		<StyledCretableSelect
			classNamePrefix='react-select'
			components={setupCustomComponents(customComponentsConfig)}
			getOptionLabel={(val) => {
				return val[optionsLabel]
			}}
			getOptionValue={(val) => {
				return val[optionsValue]
			}}
			options={options.toJS()}
			ignoreAccents={false}
			isDisabled={isDisabled || isLoading}
			isLoading={isLoading}
			isClearable={isClearable}
			placeholder={placeholderTid}
			isValidNewOption={isValidNewOption}
			formatCreateLabel={formatCreateLabel}
			formatOptionLabel={formatOptionLabel}
			getNewOptionData={(inputValue, optionLabel) => ({
				[optionsValue]: inputValue,
				[optionsLabel]: optionLabel
			})}
			hideSelectedOptions={hideSelectedOptions}
			createOptionPosition={createOptionPosition}
			onChange={_onChange}
			value={parseValue(value, options, optionsValue)}
			maxMenuHeight={170}
			menuPortalTarget={menuPortalTarget}
			closeMenuOnScroll={
				menuPortalTarget &&
				((event) => {
					if (event.srcElement.className.includes('react-select')) {
						return false
					}

					return true
				})
			}
		/>
	)
}

SelectCreatable.defaultProps = {
	labelIsTid: false,
	isDisabled: false,
	optionsLabel: 'label',
	optionsValue: 'value',
	placeholderTid: '',
	isLoading: false,
	isClearable: false,
	hideSelectedOptions: true,
	maxMenuHeight: 170,
	formatOptionLabel: func,
	createOptionPosition: 'last',
	formatCreateLabel: (val) => {
		return (
			<span>
				<Text tid='generic.form.create' />: {val}
			</span>
		)
	}
}

SelectCreatable.propTypes = {
	fieldName: string,
	optionsLabel: string,
	optionsValue: string,
	placeholderTid: string,
	labelIsTid: bool,
	isClearable: bool,
	isDisabled: bool,
	onChange: func,
	formatCreateLabel: func,
	options: ImmutableProps.list,
	value: oneOfType([string, number]),
	isLoading: bool,
	formatOptionLabel: func,
	hideSelectedOptions: bool,
	createOptionPosition: oneOf(['first', 'last']),
	maxMenuHeight: number
}

export default SelectCreatable
