import React, { PureComponent } from 'react'
import { map, list } from 'react-immutable-proptypes'
import { List } from 'immutable'
import Grid from 'styled-components-grid'
import { string, func } from 'prop-types'
import { Padding } from 'styled-components-spacing'
import Panel from '../../shared/panel/panel'
import Label from '../../shared/label/label'
import DatePicker from '../../shared/datepicker/datepicker'
import { Select } from '../../shared/select'
import TextArea from '../../shared/input/textarea'
import Moment from '../../../modules/moment.module'
import ProtocolInlinePreview from '../../meetings/protocol-inline-preview/protocol-inline-preview'
import AttachmentsByObjIdContainer from '../../../containers/shared/attachments-by-objid.container'
import { OBJ_TYPE_MEETING } from '/shared/constants'

export default class DocumentsMeetingDocumentDetails extends PureComponent {
	static propTypes = {
		document: map,
		templateOptions: list,
		userId: string,
		onOpenProtocol: func,
		onOpenFile: func,
		language: string
	}

	render = () => {
		const { document, onDownloadFile, templateOptions, userId, onOpenProtocol, onOpenFile, language } = this.props

		const objId = document && document.get('id')

		return (
			<Panel title='meetings.general.panel.title' marginBottom={true}>
				<Padding all={3}>
					<Grid>
						<Grid.Unit size={{ xs: 1, lg: 1, xl: 2 / 5 }}>
							<Padding all={3}>
								<Label tid='meetings.general.label.template'>
									<Select
										isDisabled={true}
										options={templateOptions}
										placeholderTid='meeting.general.placeholder.template'
										value={document && document.get('templateId')}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
						<Grid.Unit size={{ xs: 1, lg: 1, xl: 2 / 5 }}>
							<Padding all={3}>
								<Label tid='meetings.general.label.date'>
									<DatePicker
										disabled={true}
										value={document && document.get('startDate') && Moment(document.get('startDate'))}
										language={language}
										showTimeSelect={true}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
						<Grid.Unit size={{ xs: 1, lg: 1, xl: 1 / 5 }}>
							<Padding all={3}>
								<Label tid='meetings.general.label.end'>
									<DatePicker
										disabled={true}
										value={document && document.get('endDate') && Moment(document.get('endDate'))}
										language={language}
										showTimeSelectOnly={true}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>

					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='meetings.general.label.meeting_name'>
									<TextArea
										disabled={true}
										placeholderTid='meeting.general.placeholder.meeting_name'
										value={document && document.get('name')}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>

					{!false && (
						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<ProtocolInlinePreview
										meeting={document}
										userId={userId}
										onOpenProtocol={onOpenProtocol}
										onOpenMeeting={onOpenProtocol}
									/>
								</Padding>
							</Grid.Unit>
						</Grid>
					)}

					{false && (
						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<AttachmentsByObjIdContainer
										readOnly={true}
										nakedStyle={true}
										objType={OBJ_TYPE_MEETING}
										objId={objId}
									/>
								</Padding>
							</Grid.Unit>
						</Grid>
					)}
				</Padding>
			</Panel>
		)
	}
}
