import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map, merge } from 'immutable'
import moment from '../../modules/moment.module'
import { openModal } from '../../actions/modals.actions'
import { SUBSCRIPTION_AFTER_SUB_POT_INFO, SUBSCRIPTION_PAYMENT_REQUIRES_ACTION_MODAL } from '../../constants/modals'
import ConfirmModalContainer from '../shared/confirm.container'
import PaymentDetailsContainer from './payment-details.container'
import PaymentRequiresActionModalContainer from './payment-requires-action-modal.container'

import PlansPanel from '../../dumb-components/subscriptions/plans-panel'

import {
	PRODUCT_ACCOUNT_PLAN_FREE_ID,
	PRODUCT_STORAGE_PLAN_FREE_ID,
	SUBSCRIPTIONS_INTERVAL_MONTH,
	SUBSCRIPTIONS_INTERVAL_YEAR,
	SUBSCRIPTIONS_PLAN_TYPE_ACCOUNT,
	SUBSCRIPTIONS_PLAN_TYPE_STORAGE
} from '/shared/constants'

import {
	changePlan,
	verifyCoupon,
	clearCoupon,
	redeemCoupon,
	postPartnerCode,
	resetPlan,
	saveSubscriptions
} from '../../actions/subscriptions.actions'

class PlansContainer extends Component {
	state = {
		isLoading: false,
		selectedAccountPlanId: null,
		selectedStoragePlanId: null,
		couponCode: null,
		partnerCode: null,
		isConfirmModalOpen: false,
		isDowngradingModalOpen: false,
		isNotifyAboutSharedFoldersModalOpen: false,
		isPaymentDetailsModalOpen: false,
		isPaymentDetailsLoading: false
	}

	convertAccountPlansToSelectOptions = (plans, freePlanId) => {
		let options = List()
		let optiongroups = List()
		const freePlan = plans.get('month').find((p) => p.get('id') === freePlanId)
		options = options.push(freePlan)

		plans.forEach((items, interval) => {
			const filteredItems = items.filter((i) => i.get('id') !== freePlanId).sort(this.sortByLevel)
			const optiongroup = Map({
				label: `subscriptions.plan_otions.optiongroup.${interval}`,
				options: List(filteredItems)
			})
			optiongroups = optiongroups.push(optiongroup)
		})

		optiongroups = optiongroups.sort((a, b) => a.get('label').localeCompare(b.get('label')))
		options = merge(options, optiongroups)

		return options
	}

	isDowngrading = () => {
		const { subscription, plans } = this.props
		const { selectedAccountPlanId } = this.state
		const currentAccountPlanId = subscription.get('accountPlanId')

		// No current subscription so we are not downgrading
		if (!currentAccountPlanId) {
			return false
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false
		}

		const currentAccountPlan = plans.getIn(['mapPlanIdToPlan', currentAccountPlanId])
		const selectedAccountPlan = plans.getIn(['mapPlanIdToPlan', selectedAccountPlanId])

		// We are downgrading if level of the current account plan is greater than the selected one
		if (
			parseInt(currentAccountPlan.getIn(['metadata', 'level'])) >
			parseInt(selectedAccountPlan.getIn(['metadata', 'level']))
		) {
			return true
		}

		return false
	}

	isDowngradingAllowed = () => {
		const { subscription, usedStorageSpace, plans } = this.props
		const { selectedAccountPlanId, selectedStoragePlanId } = this.state
		const currentAccountPlanId = subscription.get('accountPlanId')

		// No current subscription so accept
		if (!currentAccountPlanId) {
			return true
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false
		}

		const currentAccountPlan = plans.getIn(['mapPlanIdToPlan', currentAccountPlanId])
		const selectedAccountPlan = plans.getIn(['mapPlanIdToPlan', selectedAccountPlanId])

		let newStorageSpace
		const currentStoragePlanId = subscription.get('storagePlanId')
		if (currentStoragePlanId) {
			const currentStoragePlan = plans.getIn(['mapPlanIdToPlan', currentStoragePlanId])
			newStorageSpace =
				parseFloat(selectedAccountPlan.getIn(['metadata', 'storage'])) +
				parseFloat(currentStoragePlan.getIn(['metadata', 'storage']))
		}

		if (selectedStoragePlanId) {
			const selectedStoragePlan = plans.getIn(['mapPlanIdToPlan', selectedStoragePlanId])
			newStorageSpace =
				parseFloat(selectedAccountPlan.getIn(['metadata', 'storage'])) +
				parseFloat(selectedStoragePlan.getIn(['metadata', 'storage']))
		}

		// We are downgrading if level of the current account plan is greater than the selected one
		// but the company have used to much storage space to allow downgrading.
		if (
			parseInt(currentAccountPlan.getIn(['metadata', 'level'])) >
				parseInt(selectedAccountPlan.getIn(['metadata', 'level'])) &&
			usedStorageSpace > newStorageSpace
		) {
			return false
		}

		return true
	}

	showMessageAboutFolderSharing = () => {
		const { subscription, plans, isSharingFolders } = this.props
		const { selectedAccountPlanId } = this.state
		const currentAccountPlanId = subscription.get('accountPlanId')

		if (!currentAccountPlanId) {
			return false
		}

		// No new account plan selected so we are not downgrading
		if (!selectedAccountPlanId) {
			return false
		}

		const currentAccountPlan = plans.getIn(['mapPlanIdToPlan', currentAccountPlanId])
		const selectedAccountPlan = plans.getIn(['mapPlanIdToPlan', selectedAccountPlanId])
		const folderSharingEnabledForCurrentAccountPlan =
			currentAccountPlan.getIn(['metadata', 'folderSharingEnabled']) === 'true'
		const folderSharingEnabledForSelectedAccountPlan =
			selectedAccountPlan.getIn(['metadata', 'folderSharingEnabled']) === 'true'

		if (folderSharingEnabledForCurrentAccountPlan && !folderSharingEnabledForSelectedAccountPlan && isSharingFolders) {
			return true
		}

		return false
	}

	closeDowngradingModal = () => {
		this.setState({ isDowngradingModalOpen: false, isLoading: false })
	}

	onSaveSubscription = () => {
		let { subscription } = this.props
		const { saveSubscriptions } = this.props
		const { couponCode, partnerCode } = this.state

		if (couponCode) {
			subscription = subscription.set('couponCode', couponCode);
		}		

		if (partnerCode) {			
			subscription = subscription.set('partnerCode', partnerCode);
		}

		if (subscription.get('partnerCode') && partnerCode === '') {
			subscription = subscription.delete('partnerCode');
		}		

		saveSubscriptions(subscription.get('companyId'), subscription);
	}

	onChangeState = (field, val) => {		
		if (field === 'couponCode' && val && val.length >= 8) {
			const { couponCode } = this.state
			val = val.substring(0, 8)
			val.length === 8 && couponCode !== val && this.verifyCoupon(val)
		}

		this.setState({ [field]: val })
	}

	onSelectAccountPlan = (selectedAccountPlanId) => {
		this.setState(() => {
			const newState = { selectedAccountPlanId }

			if (selectedAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID) {
				newState.selectedStoragePlanId = PRODUCT_STORAGE_PLAN_FREE_ID
			}

			return newState
		})
	}

	onSelectStoragePlan = (selectedStoragePlanId) => {
		this.setState({ selectedStoragePlanId })
	}

	onChangePlan = () => {
		const { subscription } = this.props
		const { selectedAccountPlanId } = this.state

		if (this.isDowngrading() && !this.isDowngradingAllowed()) {
			this.setState({ isLoading: true, isDowngradingModalOpen: true })
			return
		}

		if (selectedAccountPlanId !== PRODUCT_ACCOUNT_PLAN_FREE_ID) {
			const hasSources = subscription.getIn(['stripeData', 'sources', 'total_count']) > 0

			if (!hasSources) {
				this.setState({ isLoading: true, isPaymentDetailsModalOpen: true })
				return
			}

			this.setState({ isLoading: true, isConfirmModalOpen: true })
			return
		}

		if (selectedAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID && this.showMessageAboutFolderSharing()) {
			this.setState({ isLoading: true, isConfirmModalOpen: true })
			return
		}

		this.setState({ isLoading: true })
		this.doChangePlan()
	}

	cancelChangePlan = () => {
		this.setState({ isConfirmModalOpen: false, isLoading: false })
	}

	doChangePlan = () => {
		const { changePlan, coupon, redeemCoupon, subscription, openModal, prepaidAmount, clearCoupon } = this.props
		const { selectedAccountPlanId, selectedStoragePlanId, isPaymentDetailsModalOpen } = this.state
		const previousAccountPlanId = subscription.get('accountPlanId', PRODUCT_ACCOUNT_PLAN_FREE_ID)
		const previoutStoragePlanId = subscription.get('storagePlanId', PRODUCT_STORAGE_PLAN_FREE_ID)
		const plansToChange = []
		if (selectedAccountPlanId && selectedAccountPlanId !== previousAccountPlanId) {
			plansToChange.push(selectedAccountPlanId)
		}
		if (selectedStoragePlanId && selectedStoragePlanId !== previoutStoragePlanId) {
			plansToChange.push(selectedStoragePlanId)
		}
		const showPotInfo =
			plansToChange.includes(selectedAccountPlanId) &&
			previousAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID &&
			prepaidAmount <= 0

		const _changePlan = () => {
			changePlan(
				plansToChange,
				coupon && coupon.get('id'),
				(newSubscription) => {
					if (
						isPaymentDetailsModalOpen &&
						newSubscription.getIn(['stripeData', 'subscriptions', 'data', 0, 'status']) === 'incomplete'
					) {
						openModal(SUBSCRIPTION_PAYMENT_REQUIRES_ACTION_MODAL, { showPotInfo })
						// If user upgraded from FREE account and don't have any money in the pot, show pot info modal
					} else if (showPotInfo) {
						openModal(SUBSCRIPTION_AFTER_SUB_POT_INFO)
					}

					this.setState({
						isLoading: false,
						selectedAccountPlanId: null,
						selectedStoragePlanId: null,
						isPaymentDetailsModalOpen: false
					})
				},
				() => {
					this.setState({ isLoading: false })
				}
			)
		}

		this.setState({ isConfirmModalOpen: false })
		// if (coupon) {
		// 	redeemCoupon(coupon.get('id'), () => {
		// 		this.setState({couponCode: null})
		// 		clearCoupon()
		// 		_changePlan()
		// 	})
		// } else {
		_changePlan()
		// }
	}

	resetPlan = () => {
		const { resetPlan } = this.props

		this.setState({ isLoading: true })
		resetPlan(
			() => {
				this.setState({ isLoading: false })
			},
			() => {
				this.setState({ isLoading: false })
			}
		)
	}

	openInfoAboutPotModal = () => {}

	verifyCoupon = (couponCode) => {
		const { verifyCoupon, clearCoupon, coupon } = this.props
		if (coupon) {
			clearCoupon()
		}

		if (!couponCode) {
			return
		}

		verifyCoupon(couponCode)
	}

	closePaymentDetailsModal = () => {
		this.setState({ isPaymentDetailsModalOpen: false, isLoading: false })
	}

	setIsPaymentDetailsLoading = () => {
		this.setState({ isPaymentDetailsLoading: true })
	}

	unsetIsPaymentDetailsLoading = () => {
		this.setState({ isPaymentDetailsLoading: false })
	}

	sortByLevel = (a, b) => {
		return parseInt(a.getIn(['metadata', 'level'])) - parseInt(b.getIn(['metadata', 'level']))
	}

	render = () => {
		const {
			subscription,
			plans,
			i18n,
			companyDueForCancellation,
			coupon,
			documentsSpace,
			usedStorageSpace,
			appliedStripeCouponObj
		} = this.props
		const {
			selectedAccountPlanId,
			selectedStoragePlanId,
			couponCode,
			partnerCode,
			isLoading,
			isConfirmModalOpen,
			isDowngradingModalOpen,
			isPaymentDetailsModalOpen,
			isPaymentDetailsLoading,
			isNotifyAboutSharedFoldersModalOpen
		} = this.state
		let warning, info, infoIcon

		if (!subscription || !plans) {
			return null
		}

		const subscribedStoragePlanId = subscription.get('storagePlanId', PRODUCT_STORAGE_PLAN_FREE_ID)
		const subscribedStoragePlan = plans.getIn(['mapPlanIdToPlan', subscribedStoragePlanId])
		const willChangeToStoragePlan = subscription.get('willChangeToStoragePlan')

		const subscribedAccountPlanId = subscription.get('accountPlanId', PRODUCT_ACCOUNT_PLAN_FREE_ID)
		const subscribedAccountPlan = plans.getIn(['mapPlanIdToPlan', subscribedAccountPlanId])
		const willChangeToAccountPlan = subscription.get('willChangeToAccountPlan')

		const selectedAccountPlan = selectedAccountPlanId && plans.getIn(['mapPlanIdToPlan', selectedAccountPlanId])

		const stripeData = subscription.get('stripeData')

		const currentPeriodEnd = stripeData && stripeData.getIn(['subscriptions', 'data', 0, 'current_period_end'])
		const nextBillingDate = currentPeriodEnd && moment.unix(currentPeriodEnd).format('LL')

		const canPurchase = true

		//If you have a free account you should not be allowed to have a storage plan.
		const willHaveStoragePlan =
			(selectedStoragePlanId && selectedStoragePlanId !== PRODUCT_STORAGE_PLAN_FREE_ID) ||
			(!selectedStoragePlanId && subscribedStoragePlanId !== PRODUCT_STORAGE_PLAN_FREE_ID)
		const willHaveFreeAccountPlan =
			(selectedAccountPlanId && selectedAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID) ||
			(!selectedAccountPlanId && subscribedAccountPlanId === PRODUCT_ACCOUNT_PLAN_FREE_ID)
		const isFreeWithStoragePlan = willHaveStoragePlan && willHaveFreeAccountPlan

		if (willChangeToStoragePlan || willChangeToAccountPlan) {
			info = 'subscription.account_product.plan_will_change_at_next_period_start'
		}

		let interval = SUBSCRIPTIONS_INTERVAL_MONTH
		if (selectedAccountPlan && selectedAccountPlan.has('interval')) {
			interval = selectedAccountPlan.get('interval')
		} else if (subscribedAccountPlan && subscribedAccountPlan.has('interval')) {
			interval = subscribedAccountPlan.get('interval')
		}

		let accountPlans = plans.get(SUBSCRIPTIONS_PLAN_TYPE_ACCOUNT)
		let storagePlans = plans.getIn([SUBSCRIPTIONS_PLAN_TYPE_STORAGE, interval])

		storagePlans = storagePlans.filter((p) => p.getIn(['metadata', 'active']) === 'true').sort(this.sortByLevel)
		accountPlans = this.convertAccountPlansToSelectOptions(accountPlans, PRODUCT_ACCOUNT_PLAN_FREE_ID)

		const currentCouponCode = appliedStripeCouponObj && appliedStripeCouponObj.get('id')
		const currentPartnerCode = subscription.get('partnerCode')

		if (!canPurchase && !companyDueForCancellation) {
			warning = 'subscriptions.warning.complete_card_and_address_details'
		}

		let mapSelectedTypeToInterval = Map({
			// account: {
			// 	month: null,
			// 	year: null
			// },
			storage: {
				month: null,
				year: null
			}
		})

		// if (subscribedAccountPlan.get('interval') === 'year') {
		// 	mapSelectedTypeToInterval = mapSelectedTypeToInterval.setIn(['account', 'year'], subscribedAccountPlan)
		// 	mapSelectedTypeToInterval = mapSelectedTypeToInterval.setIn(['account', 'month'], accountPlans.find((plan) => {
		// 		return plan.getIn(['metadata', 'level']) === subscribedAccountPlan.getIn(['metadata', 'level']) && plan.getIn(['interval']) !== subscribedAccountPlan.getIn(['interval']);
		// 	}))
		// } else {
		// 	mapSelectedTypeToInterval = mapSelectedTypeToInterval.setIn(['account', 'month'], subscribedAccountPlan)
		// 	mapSelectedTypeToInterval = mapSelectedTypeToInterval.setIn(['account', 'year'], accountPlans.find((plan) => {
		// 		return plan.getIn(['metadata', 'level']) === subscribedAccountPlan.getIn(['metadata', 'level']) && plan.getIn(['interval']) !== subscribedAccountPlan.getIn(['interval']);
		// 	}))
		// }
		//
		if (subscribedStoragePlan.get('interval') === 'year') {
			mapSelectedTypeToInterval = mapSelectedTypeToInterval.setIn(['storage', 'year'], subscribedStoragePlan)
			mapSelectedTypeToInterval = mapSelectedTypeToInterval.setIn(
				['storage', 'month'],
				storagePlans.find((plan) => {
					return (
						plan.getIn(['metadata', 'level']) === subscribedStoragePlan.getIn(['metadata', 'level']) &&
						plan.getIn(['interval']) !== subscribedStoragePlan.getIn(['interval'])
					)
				})
			)
		} else {
			mapSelectedTypeToInterval = mapSelectedTypeToInterval.setIn(['storage', 'month'], subscribedStoragePlan)
			mapSelectedTypeToInterval = mapSelectedTypeToInterval.setIn(
				['storage', 'year'],
				storagePlans.find((plan) => {
					return (
						plan.getIn(['metadata', 'level']) === subscribedStoragePlan.getIn(['metadata', 'level']) &&
						plan.getIn(['interval']) !== subscribedStoragePlan.getIn(['interval'])
					)
				})
			)
		}

		let discount = 0
		if (appliedStripeCouponObj) {
			const planPrice = subscribedAccountPlan.get('amount') / 100
			const storagePrice = subscribedStoragePlan.get('amount') / 100
			const amountOff = appliedStripeCouponObj.get('amountOff')
			const percentOff = appliedStripeCouponObj.get('percent_off')

			if (amountOff) {
				discount = amountOff
			} else {
				discount = (planPrice + storagePrice) * (percentOff / 100)
			}
		}

		const isFreeOfCharge = subscription && subscription.get('isFreeOfCharge')

		const confirmPurchaseMessages = []

		if (selectedAccountPlanId !== PRODUCT_ACCOUNT_PLAN_FREE_ID) {
			confirmPurchaseMessages.push({
				tid: 'subscriptions.confirm_purchase.question'
			})
		}

		if (this.showMessageAboutFolderSharing()) {
			confirmPurchaseMessages.push({
				tid: 'subscriptions.confirm_purchase.question.sharing_folders'
			})
		}

		const _partnerCode = () => {
			if (partnerCode === '') {
				return '';
			}
			if (partnerCode) {
				return partnerCode;
			}

			if (currentPartnerCode) {
				return currentPartnerCode;
			}
		};
		

		return (
			<React.Fragment>
				<PlansPanel
					accountPlans={accountPlans}
					storagePlans={storagePlans}
					selectedAccountPlanId={selectedAccountPlanId || willChangeToAccountPlan || subscribedAccountPlanId}
					selectedStoragePlanId={
						selectedStoragePlanId ||
						willChangeToStoragePlan ||
						mapSelectedTypeToInterval.getIn(['storage', interval, 'id'])
					}
					selectedAccountPlan={selectedAccountPlan}
					subscribedAccountPlan={subscribedAccountPlan}
					subscribedStoragePlan={subscribedStoragePlan}
					storageUsed={12932}
					isLoading={isLoading}
					nextBillingDate={nextBillingDate}
					isFreeOfCharge={isFreeOfCharge}
					disabled={isFreeOfCharge || willChangeToStoragePlan || willChangeToAccountPlan || companyDueForCancellation}
					onSelectAccountPlan={this.onSelectAccountPlan}
					onSelectStoragePlan={this.onSelectStoragePlan}
					onClickPurchase={this.onChangePlan}
					onClickReset={this.resetPlan}
					warning={warning}
					info={info}
					infoIcon={infoIcon}
					coupon={coupon}
					couponCode={couponCode || currentCouponCode}
					partnerCode={_partnerCode()}
					onChangeState={this.onChangeState}
					onSaveSubscription={this.onSaveSubscription}
					canPurchase={canPurchase}
					isFreeWithStoragePlan={isFreeWithStoragePlan}
					i18n={i18n}
					companyDueForCancellation={companyDueForCancellation}
					willHaveFreeAccountPlan={willHaveFreeAccountPlan}
					documentsSpace={documentsSpace}
					usedStorageSpace={usedStorageSpace}
					discount={discount}
					couponDisabled={currentCouponCode ? true : false}
				/>

				<ConfirmModalContainer
					title='subscriptions.confirm_purchase.title'
					message={confirmPurchaseMessages}
					onConfirm={this.doChangePlan}
					onDecline={this.cancelChangePlan}
					isOpen={isConfirmModalOpen}
				/>

				<PaymentDetailsContainer
					mode='modal'
					isOpen={isPaymentDetailsModalOpen}
					onClose={this.closePaymentDetailsModal}
					onBeforeSave={this.setIsPaymentDetailsLoading}
					onAfterSave={this.doChangePlan}
					onError={this.unsetIsPaymentDetailsLoading}
					isLoading={isPaymentDetailsLoading}
				/>

				<ConfirmModalContainer
					title='subscriptions.alert_downgrading.title'
					message='subscriptions.alert_downgrading.question'
					onConfirm={this.closeDowngradingModal}
					isOpen={isDowngradingModalOpen}
					mode='alert'
				/>

				<PaymentRequiresActionModalContainer onClose={this.openInfoAboutPotModal} />
			</React.Fragment>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		subscription: store.subscriptions.get('subscription'),
		coupon: store.subscriptions.get('coupon'),
		plans: store.subscriptions.get('plans'),
		i18n: store.i18n,
		prepaidAmount: store.subscriptions.getIn(['helper', 'prepaidAmount'], 0),
		documentsSpace: store.subscriptions.getIn(['helper', 'documentsSpace']),
		usedStorageSpace: store.documents.getIn(['helper', 'usedStorageSpace']),
		isSharingFolders: store.documents.getIn(['helper', 'isSharingFolders']),
		appliedStripeCouponObj: store.subscriptions.getIn([
			'subscription',
			'stripeData',
			'subscriptions',
			'data',
			0,
			'discount',
			'coupon'
		]),
		companyDueForCancellation: store.company.company && store.company.company.toBeCanceled
	}
}

const mapActionsToProps = {
	resetPlan,
	changePlan,
	verifyCoupon,
	clearCoupon,
	redeemCoupon,
	postPartnerCode,
	saveSubscriptions,
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(PlansContainer)
