import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl'
import { fromJS, List, Map } from 'immutable'
import debounce from 'lodash/debounce'
import ScrollView from '../../../../dumb-components/shared/layout/scroll-view/scroll-view'
import { v1 } from 'uuid'
import {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	checkDivisionErrors,
	cancelTemporaryTransaction
} from '../../../../actions/transaction.actions'
import DatePicker from '../../../../dumb-components/shared/datepicker/datepicker'
import Panel from '../../../../dumb-components/panel'
import Field from '../../../../dumb-components/fields/field'
import Select from '../../../../dumb-components/fields/select'
import EmissionLeveling from './emission-leveling'
import ShareTypes from './share-types'
import {
	validateTransactionDate,
	isRequired,
	validateEmissionShareTypes,
	validateEmissionShareTypesPriceAgainstQuota,
	validateNumOfSharesLeft,
	validateBeforeAndAfterNotEqual,
	validateBeforeAndAfterGtZero,
	validateNumOfDistributedShares,
	validateisRequiredImmutable
} from '../../../../modules/validation.module'
import immutableForm from '../../../framework/immutable-form'
import TextBox from '../../../framework/textbox'
import TransactionToolbarControlsContainer from '../../../../containers/shares/transaction-toolbar-controls.container'
import ViaGuiContainer from '../../../../containers/shares/via-gui.container'
import ViaGuiWarningModal from '../../../../dumb-components/shares/excel-via-gui-export-import/via-gui-warning-modal'
import { addErrorNotification } from '../../../../actions/notify.actions'

const transactionType = 'EMISSION'

const initialTransaction = fromJS({
	type: transactionType,
	sortedShareholders: true,
	investors: {},
	handlerData: {}
})

const de = debounce((func, type, transaction) => {
	func(type, transaction.toJS())
}, 1000)

class EmissionForm extends Component {
	static propTypes = {
		intl: intlShape.isRequired
	}

	state = {
		isViaGuiWarningModalOpen: false,
		isAgGridOpen: true
	}

	componentDidMount = () => {
		this.props.fetchTemporaryTransaction(transactionType)
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.tmpTransaction !== prevProps.tmpTransaction) {
			this.setValidators(this.props.tmpTransaction)
		}
	}

	closeViaGuiWarningModal = () => {
		this.setState({ isViaGuiWarningModalOpen: false })
	}

	scrollToEmissionLeveling = () => {
		setTimeout(() => {
			const emissionLevelingWrapper = document.getElementById('emission-leveling-wrapper')

			if (!emissionLevelingWrapper) {
				return
			}

			this.scrollbar.scrollTop(emissionLevelingWrapper.offsetTop)
		}, 500)
	}

	setValidators = (tmpTransaction) => {
		const { disableValidationOfField, enableValidationOfField } = this.props
		const enableShareholderValidation = this.hasAllThreeShareValues(tmpTransaction)

		if (!tmpTransaction.getIn(['handlerData', 'emissionType'])) {
			return
		}

		if (tmpTransaction.getIn(['handlerData', 'emissionType']) === 'EMISSION-LEVELING') {
			disableValidationOfField('handlerData.types')
			disableValidationOfField('handlerData.shareholders')
			enableValidationOfField('handlerData.after')
			enableValidationOfField('handlerData.before')
		} else {
			enableValidationOfField('handlerData.types')
			if (enableShareholderValidation) {
				enableValidationOfField('handlerData.shareholders')
			} else {
				disableValidationOfField('handlerData.shareholders')
			}

			disableValidationOfField('handlerData.after')
			disableValidationOfField('handlerData.before')
		}
	}

	hasAllThreeShareValues = (tmpTransaction) => {
		const hasEmissionTypes = tmpTransaction.getIn(['handlerData', 'types'])
		const hasClassOfShares = hasEmissionTypes && hasEmissionTypes.getIn([0, 'type'])
		const hasNumOfShares = hasEmissionTypes && hasEmissionTypes.getIn([0, 'numOfShares'])
		const hasPricePerShare = hasEmissionTypes && hasEmissionTypes.getIn([0, 'pricePerShare'])

		return hasClassOfShares && hasNumOfShares && hasPricePerShare ? true : false
	}

	onChange = (field, val) => {
		const { updateTransaction, saveTemporaryTransaction, resetErrors } = this.props
		let { tmpTransaction } = this.props
		tmpTransaction = tmpTransaction.setIn(['handlerData', field], val)

		if (field === 'types') {
			const shareType = val.getIn([0, 'type'])
			tmpTransaction = tmpTransaction.updateIn(
				['handlerData', 'shareholders'],
				(shareholders) =>
					shareholders &&
					shareholders.map((shareholder) => {
						shareholder = shareholder.set('type', shareType)
						return shareholder
					})
			)
			de(saveTemporaryTransaction, transactionType, tmpTransaction)
		}
		updateTransaction(transactionType, tmpTransaction)
		resetErrors('handlerData.' + field)
	}

	onChangeDate = (val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction, resetErrors } = this.props
		const newTransaction = tmpTransaction.set('date', val)
		resetErrors('date')
		updateTransaction(transactionType, newTransaction)
		de(saveTemporaryTransaction, transactionType, newTransaction)
	}

	onChangeEmissionType = (val) => {
		const {
			tmpTransaction,
			updateTransaction,
			saveTemporaryTransaction,
			resetErrors,
			disableValidationOfField,
			enableValidationOfField
		} = this.props

		if (val === 'EMISSION-LEVELING') {
			disableValidationOfField('handlerData.types')
			disableValidationOfField('handlerData.shareholders')
			enableValidationOfField('handlerData.after')
			enableValidationOfField('handlerData.before')
		} else {
			enableValidationOfField('handlerData.types')
			enableValidationOfField('handlerData.shareholders')
			disableValidationOfField('handlerData.after')
			disableValidationOfField('handlerData.before')
		}

		let newTransaction = tmpTransaction.set('handlerData', Map())

		newTransaction = newTransaction.setIn(['handlerData', 'emissionType'], val)
		resetErrors('emissionType')
		updateTransaction(transactionType, newTransaction)
		de(saveTemporaryTransaction, transactionType, newTransaction)
	}

	addShareholder = (formValues, callback) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction, resetErrors } = this.props

		let investors = tmpTransaction.get('investors')
		investors = investors.set(
			v1(),
			fromJS({
				...formValues,
				id: null
			})
		)
		const newTransaction = tmpTransaction.set('investors', investors)

		resetErrors('investors')
		updateTransaction(transactionType, newTransaction)
		de(saveTemporaryTransaction, transactionType, newTransaction)

		callback()

		setTimeout(() => {
			const emissionInvestorsDom = document.getElementById('emissionInvestors')
			this.scrollbar.scrollTop(emissionInvestorsDom.offsetTop)
		}, 300)
	}

	validateAnySharesToSubmit = (tmpTransaction) => {
		const { addErrorNotification } = this.props
		const emissionType = tmpTransaction.getIn(['handlerData', 'emissionType'])
		if (emissionType !== 'EMISSION-LEVELING') {
			return true
		}

		const shareDiff = tmpTransaction.getIn(['handlerData', 'shareDiff'])
		if (shareDiff) {
			const valid = shareDiff.some((shareholder) => {
				return shareholder.some((shareType) => {
					return shareType.get('diff') > 0
				})
			})
			if (valid) {
				return true
			}
		}
		addErrorNotification({ tid: 'shares.transactions.emission_leveling_no_shares' })

		return false
	}

	onSubmit = (values) => {
		const { tmpTransaction, validate, createTransaction } = this.props
		if (validate(tmpTransaction) && this.validateAnySharesToSubmit(tmpTransaction)) {
			createTransaction(tmpTransaction.toJS())
		}
	}

	cancelTransaction = () => {
		this.props.cancelTemporaryTransaction(transactionType)
	}

	checkDivisionErrors = () => {
		const { tmpTransaction, checkDivisionErrors } = this.props
		checkDivisionErrors(tmpTransaction, this.scrollToEmissionLeveling)
	}

	clearSplitValues = () => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction } = this.props
		let newTransaction = tmpTransaction.removeIn(['handlerData', 'after'])
		newTransaction = newTransaction.removeIn(['handlerData', 'before'])
		newTransaction = newTransaction.removeIn(['handlerData', 'shareDiff'])
		updateTransaction(transactionType, newTransaction)
		de(saveTemporaryTransaction, transactionType, newTransaction)
	}

	calculateSharesLeft = () => {
		const { tmpTransaction } = this.props
		const shareholders = tmpTransaction.getIn(['handlerData', 'shareholders'], List())
		const shareType = tmpTransaction.getIn(['handlerData', 'types', 0])
		if (!shareType) {
			return 0
		}
		let numOfSharesLeft = shareType.get('numOfShares') || 0
		shareholders.forEach((shareholder) => {
			if (shareType.get('type') === shareholder.get('type')) {
				numOfSharesLeft -= shareholder.get('numOfShares') || 0
			}
		})

		return numOfSharesLeft
	}

	renderToolbar = () => {
		const { tmpTransaction } = this.props
		const types = tmpTransaction.getIn(['handlerData', 'types'], fromJS([]))
		const addShareholderLinkDisabled = types.size === 0
		const tmpData = tmpTransaction.getIn(['handlerData', 'types', 0])
		const hasNewShares = tmpData && tmpData.toJS().numOfShares > 0
	

		return (
			<div className='i-content__tabs i-content__tabs--fix-padding'>
				<TransactionToolbarControlsContainer
					onSubmit={this.onSubmit}
					onCancel={this.cancelTransaction}
					isEmission
					sharesRemaining={this.calculateSharesLeft()}
					hasNewShares={hasNewShares}
					showAddShareholderControl={true}
					addShareholderDisabled={addShareholderLinkDisabled}
				/>
			</div>
		)
	}

	renderEmissionInvestors = () => {
		const { transaction, tmpTransaction, errors } = this.props
		const emissionType = tmpTransaction.getIn(['handlerData', 'emissionType'])

		return (
			<>
				{emissionType === 'EMISSION-LEVELING' && (
					<EmissionLeveling
						name='handlerData.shareDiff'
						errors={errors}
						latestTransaction={transaction}
						shareDiff={tmpTransaction.getIn(['handlerData', 'shareDiff'])}
						afterValue={tmpTransaction.getIn(['handlerData', 'after'], 1)}
						beforeValue={tmpTransaction.getIn(['handlerData', 'before'], 1)}
						onChange={this.onChange}
						hideEmptyShares={true}
						allowAdjustment
					/>
				)}
			</>
		)
	}

	renderViaGuiWarningModal = () => {
		return <ViaGuiWarningModal isOpen onCancel={this.closeViaGuiWarningModal} />
	}

	render = () => {
		const { transaction, tmpTransaction, i18n, errors } = this.props
		const { isViaGuiWarningModalOpen } = this.state
		const emissionTypes = [
			{ value: 'RIGHTS_ISSUE', label: i18n.messages['rights_issue'] },
			{ value: 'DIRECTED_NEW_ISSUE', label: i18n.messages['directed_new_issue'] },
			{ value: 'EMISSION-LEVELING', label: i18n.messages['EMISSION-LEVELING'] },
			{ value: 'UNKNOWN_EMISSION_TYPE_1', label: i18n.messages['UNKNOWN_EMISSION_TYPE_1'] },
			{ value: 'UNKNOWN_EMISSION_TYPE_2', label: i18n.messages['UNKNOWN_EMISSION_TYPE_2'] }
		]
		const emissionType = tmpTransaction.getIn(['handlerData', 'emissionType'])
		const showViaGuiPanel = this.hasAllThreeShareValues(tmpTransaction)

		const sharesRemaining = this.calculateSharesLeft()

		const afterValueError = errors ? errors.get('handlerData.after', List()).size > 0 : false

		return (
			<div className='i-content__container'>
				{this.renderToolbar()}
				<ScrollView scrollbarRef={(r) => (this.scrollbar = r)} showOnHover={true} autoHide={true}>
					<Panel tid='service.shares.emission_transaction'>
						<Field
							name='handlerData.emissionType'
							errors={errors}
							tid='service.shares.emission_transaction'
							className='col-md-7'>
							<Select
								value={tmpTransaction.getIn(['handlerData', 'emissionType'])}
								options={emissionTypes}
								placeholder={i18n.messages['select_placeholder']}
								simpleValue
								onSelect={(val) => {
									this.onChangeEmissionType(val)
								}}
							/>
						</Field>

						<Field name='date' tid='generic.date' className='col-md-5' errors={errors}>
							<DatePicker
								hasError={errors ? errors.get('date', List()).size > 0 : false}
								calendarPlacement='bottom-end'
								value={tmpTransaction.get('date')}
								onChange={this.onChangeDate}
								language={i18n.language}
							/>
						</Field>

						<div className='form-group--mar-btm-0 col-md-12'>
							<FormattedHTMLMessage id='shares.transactions.emission.form.information' />
						</div>

						{emissionType === 'EMISSION-LEVELING' && (
							<div>
								<TextBox>
									<FormattedHTMLMessage id='shares.transactions.emission.EMISSION-LEVELING.information' />
								</TextBox>

								<Field>
									<div className='split__row'>
										<div className='split__input'>
											<span className={afterValueError ? 'has-error' : ''}>
												<input
													type='number'
													min={1}
													value={tmpTransaction.getIn(['handlerData', 'after'], '')}
													className='form-control box-inline box-w70 text--align-center'
													onChange={(event) => {
														this.onChange('after', parseInt(event.target.value))
													}}
												/>
											</span>
											<span className='form-control-static form-control-static--mar-hor'>
												<FormattedMessage id='shares.transaction.split.for' />
											</span>
											<span className={afterValueError ? 'has-error' : ''}>
												<input
													type='number'
													min={1}
													value={tmpTransaction.getIn(['handlerData', 'before'], '')}
													className='form-control box-inline box-w70 text--align-center'
													onChange={(event) => {
														this.onChange('before', parseInt(event.target.value))
													}}
												/>
											</span>
										</div>
										<div className='split__button-group'>
											<button className='btn btn-primary' onClick={this.checkDivisionErrors}>
												<FormattedMessage id='shares.transactions.emission.calculate' />
											</button>
											<button className='btn btn-default' onClick={this.clearSplitValues}>
												<FormattedMessage id='clear' />
											</button>
										</div>
									</div>
								</Field>

								{tmpTransaction.getIn(['handlerData', 'shareDiff']) && (
									<div id='emission-leveling-wrapper'>
										<Field>
											<div className='attendees-alert-wrapper'>
												<div className='alert alert-warning alert--icon'>
													<div className='icon-container'>
														<i className='fa fa-exclamation-triangle'></i>
													</div>
													<div className='alert-text'>
														<FormattedHTMLMessage id='shares.transactions.emission.alert' />
													</div>
												</div>
											</div>
										</Field>

										{/*<TextBox>
											<FormattedHTMLMessage id='shares.transactions.emission.emission_leveling_information' />
										</TextBox>*/}

										<Field>
											<div className='btn-group align-center'>
												<button type='button' className='btn btn-primary' onClick={this.onSubmit}>
													<FormattedHTMLMessage id='generic.accept' />
												</button>
												<button type='button' className='btn btn-default' onClick={this.cancelTransaction}>
													<FormattedHTMLMessage id='generic.form.cancel' />
												</button>
											</div>
										</Field>
									</div>
								)}
							</div>
						)}
						{emissionType && emissionType !== 'EMISSION-LEVELING' && (
							<div>
								<Field name='handlerData.types' errors={errors}>
									<ShareTypes
										latestTransaction={transaction}
										shareTypes={transaction.getIn(['shareData', 'types'])}
										value={tmpTransaction.getIn(['handlerData', 'types'])}
										onChange={(shareTypes) => {
											this.onChange('types', shareTypes)
										}}
									/>
								</Field>
								<TextBox>
									<FormattedHTMLMessage id={`shares.transactions.emission.${emissionType}.information`} />
								</TextBox>
							</div>
						)}
						{showViaGuiPanel && <ViaGuiContainer sharesRemaining={sharesRemaining} onSubmit={this.onSubmit} />}
					</Panel>
					{this.renderEmissionInvestors()}
				</ScrollView>
				{isViaGuiWarningModalOpen && this.renderViaGuiWarningModal()}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get('transaction'),
		tmpTransaction: state.transaction.getIn(['tmpTransaction', transactionType], initialTransaction),
		company: state.company.company,
		i18n: state.i18n
	}
}

const mapActionsToProps = {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	checkDivisionErrors,
	cancelTemporaryTransaction,
	addErrorNotification
}

const validateShareholders = function (value, immutableObj) {
	const transactionType = immutableObj.get('type')
	return validateNumOfSharesLeft(value, transactionType)
}

const validateNumOfDistributedSharesForThisTransaction = function (value, immutableObj) {
	const transactionType = immutableObj.get('type')
	return validateNumOfDistributedShares(value, transactionType)
}

const validators = fromJS({
	'handlerData.emissionType': {
		tid: 'service.shares.emission_transaction',
		rules: [{ func: isRequired, message: 'validation.is_required' }]
	},
	date: {
		tid: 'generic.date',
		rules: [
			{ func: isRequired, message: 'validation.is_required' },
			{
				func: validateTransactionDate,
				message: 'validation.current_transaction_date_must_be_later_then_last_transaction'
			}
		]
	},
	'handlerData.types': {
		tid: 'shares.class_of_shares',
		rules: [
			{ func: validateisRequiredImmutable, message: 'validation.is_required' },
			{ func: validateEmissionShareTypes, message: 'validation.emission_share_types' },
			{ func: validateEmissionShareTypesPriceAgainstQuota, message: 'validation.emission_share_types_price_lt_quota' }
		],
		disabled: true
	},
	'handlerData.shareholders': {
		tid: 'shareholders',
		rules: [
			{
				func: validateisRequiredImmutable,
				message: 'validation.is_required.emission.shareholders.shares_not_yet_distributed'
			},
			{ func: validateShareholders, message: 'validation.number_of_added_shares_not_equal_total_available_shares' },
			{
				func: validateNumOfDistributedSharesForThisTransaction,
				message: 'validation.number_of_distributed_shares_are_greater_than_available_shares'
			}
		],
		disabled: true
	},
	'handlerData.after': {
		rules: [
			{ func: isRequired, message: 'validation.is_required' },
			{ func: validateBeforeAndAfterNotEqual, message: 'validation.after_and_before_equal' },
			{ func: validateBeforeAndAfterNotEqual, message: 'validation.after_and_before_equal' },
			{ func: validateBeforeAndAfterGtZero, message: 'validation.value_gt_zero' }
		],
		disabled: true
	},
	'handlerData.before': {
		rules: [
			{ func: isRequired, message: 'validation.is_required' },
			{ func: validateBeforeAndAfterNotEqual, message: 'validation.after_and_before_equal' },
			{ func: validateBeforeAndAfterNotEqual, message: 'validation.after_and_before_equal' },
			{ func: validateBeforeAndAfterGtZero, message: 'validation.value_gt_zero' }
		],
		disabled: true
	}
})

EmissionForm = immutableForm(EmissionForm, 'emissionForm', validators)
EmissionForm = injectIntl(EmissionForm)
export default connect(mapStateToProps, mapActionsToProps)(EmissionForm)
