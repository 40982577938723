/**
 * React DnD Example of simple sortable list:
 * https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_hooks_js/04-sortable/cancel-on-drop-outside?from-embed=&file=/src/Card.jsx
 */
import React, { useRef, memo } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { string, func, oneOfType, number } from 'prop-types'

const SortableItem = ({ id, type, findItem, moveItem, removeItem, onDragging, onDragEnd, sortableId, children }) => {
	const item = findItem(id)
	const originalIndex = item.index
	const [{ isDragging }, drag] = useDrag(
		() => ({
			type,
			item: { id, originalIndex },
			collect(monitor) {
				const isDragging = monitor.isDragging()

				if (isDragging && onDragging) {
					onDragging(item)
				}

				return { isDragging: monitor.isDragging() }
			},
			end(item, monitor) {
				const { id: droppedId, originalIndex } = item
				const didDrop = monitor.didDrop()
				const droppableId = monitor.getDropResult()?.droppableId

				if (!didDrop) {
					moveItem(droppedId, originalIndex)
				} else if (droppableId === sortableId) {
					onDragEnd()
				}
			}
		}),
		[id, originalIndex, moveItem, removeItem, sortableId]
	)
	const [, drop] = useDrop(
		() => ({
			accept: type,
			drop() {
				return {
					droppableId: sortableId
				}
			},
			hover({ id: draggedId }) {
				if (draggedId !== id) {
					const { index: overIndex } = findItem(id)
					moveItem(draggedId, overIndex)
				}
			}
		}),
		[id, findItem, moveItem, sortableId]
	)

	const opacity = isDragging ? 0 : 1
	const style = {
		opacity,
		transition: 'opacity 450ms ease-in-out'
	}

	const ref = useRef(null)
	drag(drop(ref))

	return children(id, { isDragging }, ref, style)
}

SortableItem.propTypes = {
	id: oneOfType([string, number]).isRequired,
	type: string,
	findItem: func.isRequired,
	moveItem: func.isRequired,
	removeItem: func.isRequired,
	onDragging: func,
	onDragEnd: func,
	sortableId: string.isRequired,
	children: func.isRequired
}

export default memo(SortableItem)
