import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from '../../modules/moment.module';
import { cancelAccount, reactivateCompany, fetchCompany } from '../../actions/company.actions';
import { fetchSubscription } from '../../actions/subscriptions.actions';
import CancelAccountPanel from '../../dumb-components/subscriptions/cancel-account-panel/cancel-account-panel';
import CancelAccountCancellationPanel from '../../dumb-components/subscriptions/cancel-account-cancellation-panel/cancel-account-cancellation-panel';
import ConfirmContainer from '../shared/confirm.container';
import history from '../../interfaces/history';

class CancelAccountContainer extends Component {
	state = {
		isLoading: false,
		isOpen: false
	};

	preRequest = () => {
		this.setState({ isLoading: true });
	};

	postRequest = () => {
		this.setState({ isLoading: false });
	};

	cancelAccount = () => {
		this.setState({
			isLoading: true,
			isOpen: true
		});
	};

	doCancelAccount = () => {
		const { cancelAccount, company } = this.props;

		this.setState({
			isLoading: false,
			isOpen: false
		});
		this.preRequest();
		cancelAccount(company.id, (company) => {
			this.postRequest();

			if (company.isCanceled) {
				this.props.history.push('/dashboard');
			}
		});
	};

	decline = () => {
		this.setState({
			isLoading: false,
			isOpen: false
		});
	};

	cancelCancellation = () => {
		const { reactivateCompany, company } = this.props;

		this.preRequest();
		reactivateCompany(company.id, this.postRequest);
	};

	render = () => {
		const { company } = this.props;
		const { isLoading, isOpen } = this.state;
		const companyWillBeCanceled = company && company.toBeCanceledAt ? true : false;
		let remainingDays;

		if (companyWillBeCanceled) {
			const remainingDays = moment(company.toBeCanceledAt).diff(moment(), 'days');

			return (
				<CancelAccountCancellationPanel
					remainingDaysOfSubscriptionPeriod={remainingDays}
					isLoading={isLoading}
					onCancelCancellation={this.cancelCancellation}
				/>
			);
		}

		return (
			<>
				{companyWillBeCanceled && (
					<CancelAccountCancellationPanel
						remainingDaysOfSubscriptionPeriod={remainingDays}
						isLoading={isLoading}
						onCancelCancellation={this.cancelCancellation}
					/>
				)}
				{!companyWillBeCanceled && (
					<CancelAccountPanel isLoading={isLoading} onCancelAccount={this.cancelAccount} />
				)}

				<ConfirmContainer
					title='subscriptions.cancel_account'
					question='subscriptions.are_you_sure_you_want_to_cancel_account'
					message='subscriptions.cancel_account_delete_all'
					isOpen={isOpen}
					onConfirm={this.doCancelAccount}
					onDecline={this.decline}
					oneWrapper
				/>
			</>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		company: store.company.company,
		i18n: store.i18n
	};
};

const mapActionsToProps = {
	cancelAccount,
	reactivateCompany,
	fetchCompany,
	fetchSubscription
};

export default connect(mapStoreToProps, mapActionsToProps)(CancelAccountContainer);
