import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func, oneOfType } from 'prop-types'
import { map } from 'react-immutable-proptypes'

import ModalInnerWrapper from '../../../dumb-components/shared/modal/modal-inner-wrapper'
import ItemTreeContainer from '../../shared/item-tree.container'
import { listFolders } from '../../../actions/folders.actions'

class FoldersContainer extends Component {
	static propTypes = {
		selectedFolder: oneOfType([string, map]),
		onFolderSelected: func.isRequired
	}

	componentDidMount = () => {
		this.props.listFolders()
	}

	render = () => {
		const { folders, mergedDocument, selectedFolder, onFolderSelected } = this.props
		const documentFolder =
			mergedDocument && folders && folders.find((folder) => folder.get('id') === mergedDocument.get('folderId'))
		const folder = selectedFolder ? selectedFolder : documentFolder

		return (
			<ModalInnerWrapper>
				<ItemTreeContainer
					items={folders}
					onItemSelected={onFolderSelected}
					selectedItem={folder}
					renderHoverText={true}
					permission='ALLOW_CREATE_DOCUMENT'
				/>
			</ModalInnerWrapper>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		folders: store.folders.get('folders'),
		mergedDocument: store.collections.get('mergedDocument')
	}
}

const mapActionsToProps = {
	listFolders
}

export default connect(mapStoreToProps, mapActionsToProps)(FoldersContainer)
