import React, { PureComponent } from 'react'
import { string, bool, number, oneOf } from 'prop-types'
import styled, { css } from 'styled-components'

const EllipsisTemplate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

const ReponsivePadding = css`
	margin: ${({ tighten, theme }) => (tighten ? theme.spacing[3] : theme.spacing[4])};

	${({ tighten }) =>
		!tighten &&
		css`
			@media only screen and (max-width: 800px) {
				margin: 0 ${(props) => props.theme.spacing[3]};
			}
		`}
`

const StyledColumn = styled.div`
	${EllipsisTemplate}
	${ReponsivePadding}

	${({ minWidth }) =>
		minWidth &&
		css`
			min-width: ${minWidth}px;
			width: ${minWidth}px;
		`}

	${({ flex }) =>
		typeof flex === 'number' &&
		css`
			flex: ${flex};
		`}

	${({ hideBelow }) =>
		hideBelow &&
		css`
			@media only screen and (max-width: ${hideBelow}px) {
				display: none;
			}
		`}

	${({ center }) =>
		Boolean(center) &&
		css`
			display: flex;
			justify-content: center;
			align-items: center;
		`}
`

export default class ListTableColumn extends PureComponent {
	static propTypes = {
		className: string,
		minWidth: number,
		flex: number,
		hideBelow: number,
		center: bool,
		tighten: bool
	}

	static defaultProps = {
		minWidth: 50
	}

	render = () => {
		const { children, ...columnProps } = this.props

		return <StyledColumn {...columnProps}>{children}</StyledColumn>
	}
}
