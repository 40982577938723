import React, { PureComponent } from 'react'
import { bool, func, object } from 'prop-types'
import { list } from 'react-immutable-proptypes'

import ModalInnerWrapper from '../shared/modal/modal-inner-wrapper'
import ToggleSwitch from '../shared/toggle-switch/toggle-switch'
import DndSortList from '../documents/documents-dnd-sort/documents-dnd-sort-list'
import DndSortListItem, { ListHeader } from '../documents/documents-dnd-sort/documents-dnd-sort-list-item'
import CenteredLoading from '../shared/modal/centered-loading'

export default class DocumentsSortList extends PureComponent {
	static propTypes = {
		documents: list.isRequired,
		isLoading: bool,
		onDragEnd: func.isRequired,
		onGetControlsData: func,
		headerComponent: object,
		renderNameAddon: func
	}

	renderListHeader = () => {
		return <ListHeader />
	}

	renderListItem = (doc, index) => {
		const { onGetControlsData, renderNameAddon } = this.props

		return (
			<DndSortListItem
				getControlsData={onGetControlsData}
				doc={doc}
				key={index}
				index={index}
				draggableIdProp='id'
				renderNameAddon={renderNameAddon}
			/>
		)
	}

	render = () => {
		const { isLoading, documents, onDragEnd, headerComponent } = this.props

		if (isLoading) {
			return <CenteredLoading />
		}

		return (
			<ModalInnerWrapper>
				<DndSortList
					documentsList={documents}
					renderListItem={this.renderListItem}
					renderListHeader={this.renderListHeader}
					onDragEnd={onDragEnd}
					headerComponent={headerComponent}
					droppableId='attachments'
				/>
			</ModalInnerWrapper>
		)
	}
}
