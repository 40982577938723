import React, { Component, Profiler } from 'react'
import { connect } from 'react-redux'
import { string, func } from 'prop-types'
import { List, Map, fromJS } from 'immutable'
import { withRouter } from '../../interfaces/router'
import {
	listDocuments,
	updateDocumentListLocal,
	clearDocument,
	onSelectDocument
} from '../../actions/documents.actions'
import { listFolders } from '../../actions/folders.actions'
import { mergeSharedFolders, mergeSharedMapParentToChildren } from '../../components/helpers/shared-documents.helper'
import { sharingListDocuments } from '../../actions/documents-sharing.actions'
import { listSharedFolders } from '../../actions/folders-sharing.actions'
import Moment from '../../modules/moment.module'
import DocumentList from '../../dumb-components/documents/document-list/document-list'
import ImmutableProps from 'react-immutable-proptypes'
import folderHelper from '../../components/helpers/folder.helper'
import i18nhelper from '../../components/helpers/i18n.helper'
import Badge from '../../dumb-components/shared/badge/badge-new'
import DocumentListItemDropdown from './document-list-item-dropdown.container'
import history, { getQuery } from '../../interfaces/history'

import {
	EVENT_TYPE_DOCUMENT_CREATE,
	EVENT_TYPE_DOCUMENT_UPDATE,
	EVENT_TYPE_DOCUMENT_DELETE,
	EVENT_TYPE_DOCUMENT_TRANSFER,
	SHARED_FOLDERS_ID
} from '/shared/constants'

class DocumentListContainer extends Component {
	static propTypes = {
		basePath: string,
		querystr: string,
		documents: ImmutableProps.list,
		filterDocumentsChanged: func,
		onDocumentClick: func
	}

	static defaultProps = {
		documents: List()
	}

	state = {
		documentsMetadata: Map(),
		documentsInFolders: List(),
		documentsInSubFolders: List(),
		folders: Map(),
		mapParentToChildren: Map()
	}

	componentDidMount = () => {
		const { listDocuments, clearDocument, folderId, params } = this.props
		const query = getQuery()
		const folderInQuerystring = query.folder || ''

		// Without comparing the folderId stored in the reducer with the folderId in query string
		// listDocuments is run twice, onece with the correct folder ID and once without any ID which
		// can result in wrong list get stored in the reducer.

		if (folderId === folderInQuerystring) {
			// Can we remove this code it seem unused? --Noak
			listDocuments(folderId)
		}

		// Needed for the toolbar to dissapear if you leave the page with selected document
		// by ex. navigating to Tasks. First we need to check if document was "linked"
		// to somebody (href/url link)
		if (!params.id) {
			clearDocument()
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		const {
			listDocuments,
			folderId,
			doc,
			documents,
			params: { id },
			onSelectDocument,
			dateProp,
			sharingListDocuments,
			sharedFolders
		} = this.props
		let { mapParentToChildren, folders } = this.props
		const query = getQuery()
		const isSharedFromCompanyId = query.isSharedFromCompanyId

		this.checkLiveUpdateEvents()

		if (doc && prevProps.doc !== doc) {
			if (
				!prevProps.doc ||
				prevProps.doc.get('id') !== doc.get('id') ||
				(dateProp &&
					dateProp.getIn(['values', 0]) &&
					prevProps.doc.get(dateProp.getIn(['values', 0])) !== doc.get(dateProp.getIn(['values', 0])))
			) {
				setTimeout(() => {
					const panelNode = document.getElementById(doc.get('id'))
					const rect = panelNode && panelNode.getBoundingClientRect()
					//The 150 offset on top might need to be slightly adjusted, if things end up not refocusing sometimes try upping this value.
					const isElementInViewport =
						rect &&
						rect.top >= 150 &&
						rect.left >= 0 &&
						rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
						rect.right <= (window.innerWidth || document.documentElement.clientWidth)
					if (!isElementInViewport) {
						this.props.scrollbarRef.scrollTop(panelNode && panelNode.offsetTop)
					}
				}, 200)
			}
		}

		if (
			prevProps.documents !== documents ||
			prevProps.folders !== folders ||
			prevProps.sharedFolders !== sharedFolders
		) {
			this.parseDocuments()
			this.parseMetadata()

			if (!doc && documents) {
				onSelectDocument(id)
			}
		}

		if (this.state.folders && this.state.folders.size > 0 && prevState.folders !== this.state.folders) {
			this.parseMetadata()
		}

		if (prevProps.folderId !== folderId) {
			//If you browse to the main shared company folder you want to fetch data from that company
			if (isSharedFromCompanyId && this.state.companyOfSharedDocuments !== isSharedFromCompanyId) {
				//This might look a bit confusing but these folderIds are generated to match the companyId of the company and are here referencing the companyId
				sharingListDocuments(isSharedFromCompanyId)
				this.setState({ companyOfSharedDocuments: isSharedFromCompanyId })
			} else {
				listDocuments(folderId)
			}
		}

		if (prevProps.folders !== folders || prevProps.sharedFolders !== sharedFolders) {
			folders = sharedFolders ? mergeSharedFolders(sharedFolders, folders) : folders
			mapParentToChildren = sharedFolders
				? mergeSharedMapParentToChildren(sharedFolders, mapParentToChildren)
				: mapParentToChildren
			this.setState({ folders, mapParentToChildren })
		}
	}

	checkLiveUpdateEvents = () => {
		const { audit, folderId, listDocuments, sharingListDocuments } = this.props
		const query = getQuery()
		const withinSharedFolder = query && query.isSharedFromCompanyId ? true : false
		const DOCUMENT_CREATE = audit.get(EVENT_TYPE_DOCUMENT_CREATE)
		const DOCUMENT_UPDATE = audit.get(EVENT_TYPE_DOCUMENT_UPDATE)
		const DOCUMENT_DELETE = audit.get(EVENT_TYPE_DOCUMENT_DELETE)
		const DOCUMENT_TRANSFER = audit.get(EVENT_TYPE_DOCUMENT_TRANSFER)

		// Document was uploaded
		// TODO: check if the document was uploaded to the folder the user are in, only fetch the documents then
		if (DOCUMENT_CREATE && DOCUMENT_CREATE.get('r') === true) {
			const isSharedObject = DOCUMENT_CREATE.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = DOCUMENT_CREATE.getIn(['metadata', 'objectOwnerCompanyId'])

			if (isSharedObject && withinSharedFolder) {
				sharingListDocuments(objectOwnerCompanyId, true)
			} else if (!isSharedObject) {
				listDocuments(folderId)
			}
		}

		// Document was changed, update list to update name/folder
		if (DOCUMENT_UPDATE && DOCUMENT_UPDATE.get('r') === true) {
			const isSharedObject = DOCUMENT_UPDATE.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = DOCUMENT_UPDATE.getIn(['metadata', 'objectOwnerCompanyId'])

			if (isSharedObject && withinSharedFolder) {
				sharingListDocuments(objectOwnerCompanyId, true)
			} else if (!isSharedObject) {
				listDocuments(folderId)
			}
		}

		// Document was deleted. Update list
		if (DOCUMENT_DELETE && DOCUMENT_DELETE.get('r') === true) {
			const isSharedObject = DOCUMENT_DELETE.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = DOCUMENT_DELETE.getIn(['metadata', 'objectOwnerCompanyId'])

			if (isSharedObject && withinSharedFolder) {
				sharingListDocuments(objectOwnerCompanyId, true)
			} else if (!isSharedObject) {
				listDocuments(folderId)
			}
		}

		if (DOCUMENT_TRANSFER && DOCUMENT_TRANSFER.get('r') === true) {
			const isSharedObject = DOCUMENT_TRANSFER.getIn(['metadata', 'isSharedObject'])
			const objectOwnerCompanyId = DOCUMENT_TRANSFER.getIn(['metadata', 'objectOwnerCompanyId'])

			if (isSharedObject && withinSharedFolder) {
				sharingListDocuments(objectOwnerCompanyId, true)
			} else if (!isSharedObject) {
				listDocuments(folderId)
			}
		}
	}

	parseDocuments() {
		const { folderId, documents } = this.props
		const { mapParentToChildren } = this.state
		const folderDescendents = []
		folderHelper.getDescendentsIds(folderDescendents, folderId, mapParentToChildren)

		if (!documents) {
			return null
		}

		let documentsInFolders = documents
		let documentsInSubFolders = List()

		/**
		 * No currentFolderId means that we are in ROOT folder
		 * If a document doesn't have an folderId attached, it belongs to ROOT
		 */
		if (folderId) {
			/**
			 * We are not in ROOT as querystr exists
			 * Compare document folderId found on document with current querystr (currentFolderId)
			 */
			documentsInFolders = documents.filter((doc) => {
				return doc.get('folderId') === folderId
			})

			/**
			 * If document doesn't equal to querystr (currentFolderId), it belongs to another folder
			 */
			documentsInSubFolders = documents.filter((doc) => {
				return (
					doc.get('folderId') !== null &&
					doc.get('folderId') !== '' &&
					doc.get('folderId') !== folderId &&
					folderDescendents.indexOf(doc.get('folderId')) >= 0
				)
			})
		}

		this.setState({ documentsInFolders, documentsInSubFolders })
	}

	parseMetadata = () => {
		const { documents } = this.props
		const { folders } = this.state
		let documentsMetadata = Map()

		if (documents) {
			documents.forEach((doc) => {
				const metadata = {}
				const documentId = doc.get('id')
				const folderId = doc.get('folderId')

				if (
					doc.get('reminderSet') &&
					doc.get('validTo') &&
					Moment(doc.get('validTo')).diff(Moment().hour(0).minutes(0).seconds(0).milliseconds(0), 'days') <= 0
				) {
					metadata.status = 'INVALID'
				} else if (
					doc.get('reminderSet') &&
					doc.get('reminderDate') &&
					Moment(doc.get('reminderDate')).diff(Moment().hour(0).minutes(0).seconds(0).milliseconds(0), 'days') <= 0
				) {
					metadata.status = 'WARNING'
				}

				metadata.folderPath = folderHelper.getPath(folderId, folders)

				if (folderId && folderId !== '') {
					metadata.folderName = folders && folders.getIn([folderId, 'name'])
				}
				documentsMetadata = documentsMetadata.set(documentId, fromJS(metadata))
			})
			this.setState({ documentsMetadata })
		}
	}

	onChange = (documents) => {
		this.props.updateDocumentListLocal(documents)
		this.onChange(documents)
	}

	onBadgeClick = (documentId, folderId) => {
		const { basePath, history } = this.props
		const { folders } = this.state
		const isSharedFromCompanyId = folders.getIn([folderId, 'isSharedFromCompanyId'])
		history.push({
			pathname: basePath + `/${documentId}`,
			search: `?folder=${folderId}${isSharedFromCompanyId ? `&isSharedFromCompanyId=${isSharedFromCompanyId}` : ''}`
		})
	}

	onDocumentClick = (path, docId) => {
		const { onDocumentClick, onSelectDocument, history } = this.props
		onSelectDocument(docId)
		onDocumentClick && onDocumentClick()
		history.push(path)
	}

	renderDocumentItemDropdown = (documentId) => {
		const { basePath, querystr, documents } = this.props
		const document = documents.find((d) => d.get('id') === documentId)
		return <DocumentListItemDropdown document={document} basePath={basePath} querystr={querystr} />
	}

	renderBadge = (docId, folderId, folderPath, folderName, { hasDropdown }) => {
		const {
			i18n: { language },
			company: { region }
		} = this.props

		if (!folderName) {
			return null
		}

		return (
			<Badge
				expandedText={folderPath}
				onClick={this.onBadgeClick.bind(this, docId, folderId)}
				marginRight={!hasDropdown}>
				{i18nhelper.getTranslatedValue(folderName, language, region)}
			</Badge>
		)
	}

	render = () => {
		const { basePath, querystr, folderId, location, i18n, company, filterBy } = this.props
		const { documentsMetadata, documentsInFolders, documentsInSubFolders } = this.state

		let dateProp
		if (filterBy && filterBy.size > 0) {
			dateProp = (filterBy.find((obj) => obj.get('source') === 'dateProp') || Map()).getIn(['values', 0])
		}

		return (
			<DocumentList
				documents={documentsInFolders}
				documentsInSubFolders={documentsInSubFolders}
				documentsMetadata={documentsMetadata}
				basePath={basePath}
				folderId={folderId}
				querystr={querystr}
				currentUrl={location.pathname + location.search}
				userLang={i18n.language}
				region={company.region}
				onBadgeClick={this.onBadgeClick}
				onDocumentClick={this.onDocumentClick}
				dateProp={dateProp}
				renderBadge={this.renderBadge}
				marginBottom={4}
				isMainSharingFolder={SHARED_FOLDERS_ID === folderId}
				renderDropdown={this.renderDocumentItemDropdown}
			/>
		)
	}
}

/**
 * FOLDERID: Setting value to empty string prevents folderId from
 * changing to undefined upon first section load and therefore
 * from fetching document list twice upon load
 */
const mapStoreToProps = (store) => {
	return {
		history: history,
		documents: store.documents.get('visibleDocuments'),
		doc: store.documents.get('document'),
		filterBy: store.documents.get('filterBy'),
		folders: store.folders.get('folders'),
		folderId: store.folders.get('selectedFolderId', '') || '',
		mapParentToChildren: store.folders.get('mapParentToChildren'),
		sharedFolders: store.folders.get('sharedFolders'),
		i18n: store.i18n,
		company: store.company.company,
		hasAppliedFilters: store.documents.get('hasAppliedFilters'),
		audit: store.audit.get('documents'),
		auditFolders: store.audit.get('folders'),
		dateProp: store.documents.get('filterBy', List()).find((obj) => obj.get('source') === 'dateProp')
	}
}

const mapActionsToProps = {
	listDocuments,
	updateDocumentListLocal,
	clearDocument,
	onSelectDocument,
	sharingListDocuments,
	listFolders,
	listSharedFolders
}

export default withRouter(connect(mapStoreToProps, mapActionsToProps)(DocumentListContainer))
