import Box from '@mui/material/Box'
import { Form } from 'react-final-form'
import CustomSelect from '../../forms/CustomSelect'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'

const SelectPartialClosure = (props) => {
	const { t } = useTranslation()
	const emission = props.emission
	const closures = props.closures

	const selectClosure = () => {
		for (let i = 0; i < closures.length; i++) {
			if (closures[i].id === document.getElementsByName('closures').item(0)?.value) {
				props.setClosure(closures[i])
			}
		}
	}

	return (
		<Box
			sx={{
				display: 'flex',
				borderRadius: '4px',
				alignItems: 'center',
				background: '#E3EBF1',
				width: '100%',
				minHeight: '48px'
			}}>
			{emission.closures.length > 6 ? (
				<Box sx={{ display: 'flex', width: '100%', mx: '4px', my: '4px' }}>
					<Form
						onSubmit={() => {}}
						render={({}) => {
							return (
								<>
									<CustomSelect
										id='closures'
										label={t('emissions.allocation.partialClosures')}
										options={emission.closures.map((c) => {
											return {
												id: c.id,
												text: `${t('emissions.registration.partial-closure')} ${c.index}`
											}
										})}
									/>
									{selectClosure()}
								</>
							)
						}}
					/>
				</Box>
			) : (
				<Box sx={{ display: 'flex', my: '4px', mx: '4px' }}>
					{emission.closures.length === 0 ? (
						<Button
							sx={{
								display: 'flex',
								background: '#FFFFFF',
								color: '#424242',
								borderRadius: '4px',
								py: '9px',
								px: '24px'
							}}>
							<Typography variant={'subtitle1'}>{t('emissions.registration.closure')}</Typography>
						</Button>
					) : (
						<Grid item xs={12} sx={{ display: 'flex' }}>
							{emission.closures.map((c, i) => {
								return (
									<Button
										onClick={() => {
											props.setClosure(c)
											props.setClosureIndex(i)
										}}
										key={c.id}
										sx={{
											display: 'flex',
											backgroundColor: props.closureIndex === i ? '#FFFFFF' : 'grey',
											color: '#424242',
											borderRadius: '4px',
											py: '9px',
											px: '24px',
											mx: '4px'
										}}>
										<Typography variant={'subtitle1'}>
											{emission.closures.length === 1
												? t('emissions.registration.closure')
												: `${t('emissions.registration.partial-closure')} ${c.index}`}
										</Typography>
									</Button>
								)
							})}
						</Grid>
					)}
				</Box>
			)}
		</Box>
	)
}

export default SelectPartialClosure
