import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'

import { activateUserDemo, deactivateUserDemo } from '../../../actions/company.actions'
import { notifyDemoIsLoading } from '../../../actions/notify.actions'

import Button from '../../../dumb-components/shared/button/button'

class ActivateDemoContainer extends Component {
	state = {
		userDemoActive: false
	}

	static propTypes = {
		onToggle: func
	}

	componentDidMount = () => {
		if (this.props.companies) {
			this.checkIfDemoActive()
		}
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.companies !== this.props.companies) {
			this.checkIfDemoActive()
		}
	}

	checkIfDemoActive = () => {
		const { companies, user } = this.props

		const userDemoActive =
			companies &&
			companies.some((company) => {
				return company.get('isUserDemo') && company.get('superUser') === user.get('id')
			})

		this.setState({ userDemoActive })
	}

	toggleDemo = () => {
		const { activateUserDemo, deactivateUserDemo, notifyDemoIsLoading, onToggle } = this.props
		const { userDemoActive } = this.state

		notifyDemoIsLoading(true)

		this.setState({ userDemoActive: !userDemoActive })

		if (userDemoActive) {
			deactivateUserDemo((e) => {
				if (e) {
					notifyDemoIsLoading(false)
					return
				}

				notifyDemoIsLoading(false)
				onToggle && onToggle(false)
			})
		} else {
			activateUserDemo((e) => {
				if (e) {
					notifyDemoIsLoading(false)
					return
				}

				notifyDemoIsLoading(false)
				onToggle && onToggle(true)
			})
		}
	}

	render = () => {
		const { userDemoActive } = this.state
		const { demoIsLoading } = this.props
		const btnDemoTid = userDemoActive
			? 'dashboard.company_accounts.button.deactivate_demo'
			: 'dashboard.company_accounts.button.active_demo'

		return <Button tid={btnDemoTid} onClick={this.toggleDemo} isLoading={demoIsLoading} />
	}
}

const mapStoreToProps = (store) => {
	return {
		user: store.user.get('userObj'),
		companies: store.company.companies,
		demoIsLoading: store.notify.demoIsLoading
	}
}

const mapActionsToProps = {
	activateUserDemo,
	deactivateUserDemo,
	notifyDemoIsLoading
}

export default connect(mapStoreToProps, mapActionsToProps)(ActivateDemoContainer)
