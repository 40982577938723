import React, { Component } from 'react'
import { connect } from 'react-redux'

import ProgressView from '../../../dumb-components/meetings/voting/manage-modal/progress'
import CastVotesContainer from './cast-votes.container'

class VotingProgressContainer extends Component {
	render = () => {
		return (
			<ProgressView>
				<CastVotesContainer />
			</ProgressView>
		)
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(VotingProgressContainer)
