import { func, node } from 'prop-types'
import React from 'react'
import { useFormState } from 'react-final-form'

export default function MultiCondition({ condition, children }) {
	const { values = {} } = useFormState({ subscription: { values: true } })
	return condition(values) ? children : null
}

MultiCondition.propTypes = {
	condition: func,
	children: node
}
