import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GRID_MODE_PANEL, GRID_MODE_MODAL } from '/shared/constants';
import AgGrid from '../../../dumb-components/shared/ag-grid/ag-grid';
import { useDispatch } from 'react-redux';
import {
	changeActive,
	changeDuration,
	changeInvoiced,
	changePaid,
	changeStartDate,
	fetchAllAdverts,
	changeWarning
} from '../../../actions/adverts-invono-admin.actions';
import { DateTime } from 'luxon';
import { DatePickerRenderer } from '../../../dumb-components/shared/ag-grid/renderers/datepicker-renderer';
import ExportExcelButton from '../../../dumb-components/investment/shareholders/export-excel-button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import GridPanelRightButtons from '../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons';
import { DropDown } from '../../../mui-components/dropdown/DropDown';
import IconButton from '../../../mui-components/button/icon-button';
import MenuItemIcon from '../../../mui-components/dropdown/menu/menu-item-icon';
import { PreviewAdvert } from './modals/PreviewAdvert';

export default function CompaniesAdvertsGrid({ localCloseModal, isGridModalOpen, openModal }) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [rowData, setRowData] = useState();
	const [floatingFilter, setFloatingFilter] = useState(false);
	const [previewModalOpen, setPreviewModalOpen] = useState(false);
	const [previewData, setPreviewData] = useState({});
	const gridRef = useRef();

	const openPreviewModal = (companyId, advertId) => {
		setPreviewData({ companyId, advertId });
		setPreviewModalOpen(true);
	};

	const closePreviewModal = () => {
		setPreviewData({});
		setPreviewModalOpen(false);
	};

	const displayFloatingFilter = useCallback(() => {
		if (floatingFilter) {
			setFloatingFilter(false);
		} else {
			setFloatingFilter(true);
		}
	});

	const onCellValueChanged = ({ column: { colId }, data: { id }, newValue }) => {
		switch (colId) {
			case 'dateStart':
				updateDateStart(id, newValue);
				dispatch(changeStartDate(id, newValue));
				break;
			case 'duration':
				dispatch(changeDuration(id, newValue));
				break;
			case 'controller.invoiced':
				dispatch(changeInvoiced(id, newValue));
				break;
			case 'controller.paid':
				dispatch(changePaid(id, newValue));
				break;
			case 'controller.active':
				dispatch(changeActive(id, newValue));
				break;
		}
	};

	const renderYesOrNoEditor = () => {
		return [true, false];
	};

	const renderMonthsEditor = () => {
		return [1, 2, 3, 6, 12, 36];
	};

	const onCloseDatePicker = (advertId) => {
		const index = rowData.findIndex(({ id }) => id === advertId);
		delete rowData[index].openDatePicker;
		setRowData([...rowData]);
	};

	const onOpenDatePicker = (advertId) => {
		const index = rowData.findIndex(({ id }) => id === advertId);
		rowData[index].openDatePicker = true;
		setRowData([...rowData]);
	};

	const updateDateStart = (advertId, newValue) => {
		const index = rowData.findIndex(({ id }) => id === advertId);
		rowData[index].dateStart = newValue;
		setRowData([...rowData]);
	};

	const onDismissWarning = (advertId) => {
		const index = rowData.findIndex(({ id }) => id === advertId);
		rowData[index].hasDowngradedWithActiveAdvert = false;
		setRowData([...rowData]);
	};
	const shared = {
		all: {
			sortable: false,
			suppressMenu: true
		},
		editable: {
			editable: true,
			cellEditorPopup: true,
			singleClickEdit: true,
			cellEditor: 'agRichSelectCellEditor',
			onCellValueChanged
		},
		datepicker: {
			cellRendererSelector: (params) => {
				return {
					component: DatePickerRenderer,
					params: {
						onChange: (value) => {
							const newValue = value.toUTC().toISO();
							onCellValueChanged({
								column: { colId: 'dateStart' },
								data: { id: params.data.id },
								newValue
							});
						},
						disableOpenPicker: true,
						open: params.data.openDatePicker ? true : false,
						onClose: () => onCloseDatePicker(params.data.id),
						onClick: () => onOpenDatePicker(params.data.id)
					}
				};
			}
		},
		enterprisePlusEditable: {
			editable: (params) => {
				if (!params.data.subscriptionHelperObject.enterprise_plus) {
					return true;
				}
			},
			cellEditorPopup: true,
			singleClickEdit: true,
			cellEditorSelector: (params) => {
				if (!params.data.subscriptionHelperObject.enterprise_plus) {
					return {
						component: 'agRichSelectCellEditor',
						params: {
							values: renderYesOrNoEditor(),
							cellHeight: 48
						},
						popup: true
					};
				}
			},
			onCellValueChanged
		},
		renderMonthsOption: {
			cellEditorParams: {
				values: renderMonthsEditor(),
				cellHeight: 48
			}
		},
		renderYesOrNoOption: {
			cellEditorParams: {
				values: renderYesOrNoEditor(),
				cellHeight: 48
			}
		}
	};

	useEffect(() => {
		dispatch(
			fetchAllAdverts((error, response) => {
				if (error) {
					return;
				}

				setRowData(response);
			})
		);
	}, []);
	return (
		<Grid item md={12}>
			<Card>
				<CardHeader
					title={t('advertising.administration.overview.companies_adverts_grid.title')}
					action={
						<GridPanelRightButtons
							openGridModal={() => openModal('companies_grid')}
							displayFloatingFilter={displayFloatingFilter}
							floatingFilter={floatingFilter}
						/>
					}
				/>
				<CardContent>
					<AgGrid
						rowData={rowData}
						gridRef={gridRef}
						isGridModalOpen={isGridModalOpen}
						localCloseModal={localCloseModal}
						modalLeftHeader={{
							tid: 'advertising.administration.overview.companies_adverts_grid.title'
						}}
						floatingFilter={floatingFilter}
						stopEditingWhenCellsLoseFocus={true}
						mode={isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL}
						rightHeaderComponent={() => {
							return (
								<ExportExcelButton
									floatingFilter={floatingFilter}
									displayFloatingFilter={displayFloatingFilter}
								/>
							);
						}}
						suppressContextMenu={true}
						gridOptions={{
							rowClassRules: {
								'warning-row': function (params) {
									return params.data.hasDowngradedWithActiveAdvert;
								}
							}
						}}
						columnDefs={[
							{
								field: 'createdAt',
								headerName: t('advertising.administration.companies_adverts_grid.created'),
								valueFormatter: (params) => {
									const dt = DateTime.fromISO(params.value);
									return dt.toLocaleString();
								},
								...shared.all,
								maxWidth: 130
							},
							{
								field: 'company.name',
								headerName: t('advertising.administration.companies_adverts_grid.company'),
								...shared.all,
								flex: 2
							},
							{
								field: 'name',
								headerName: t('advertising.administration.companies_adverts_grid.campaign_name'),
								...shared.all,
								flex: 2
							},
							{
								field: 'dateStart',
								headerName: t('advertising.administration.companies_adverts_grid.date_from'),
								valueFormatter: (params) => {
									const dt = DateTime.fromISO(params.value);
									return dt.toLocaleString();
								},
								...shared.datepicker,
								suppressMenu: true,
								maxWidth: 130,
								sortable: false
							},
							{
								field: 'duration',
								headerName: t('advertising.administration.companies_adverts_grid.period'),
								valueFormatter: (params) =>
									t(
										`advertising.administration.create_advert_modal.advert_period.select.menu_item${params.value}`
									),
								filter: 'agSetColumnFilter',
								...shared.all,
								...shared.editable,
								...shared.renderMonthsOption,
								maxWidth: 130
							},
							{
								field: 'controller.invoiced',
								headerName: t('advertising.administration.companies_adverts_grid.invoiced'),
								valueFormatter: (params) => {
									if (params.data.subscriptionHelperObject.enterprise_plus) {
										return t(
											'advertising.administration.companies_adverts_grid.invoices.not_applicable'
										);
									} else {
										return params.value === true
											? t('advertising.administration.companies_adverts_grid.invoices.yes')
											: t('advertising.administration.companies_adverts_grid.invoices.no');
									}
								},
								filter: 'agSetColumnFilter',
								...shared.enterprisePlusEditable,
								...shared.all,
								maxWidth: 130
							},
							{
								field: 'controller.paid',
								headerName: t('advertising.administration.companies_adverts_grid.paid'),
								valueFormatter: (params) => {
									if (params.data.subscriptionHelperObject.enterprise_plus) {
										return t('advertising.administration.companies_adverts_grid.paid.prepaid');
									} else {
										return params.value === true
											? t('advertising.administration.companies_adverts_grid.paid.yes')
											: t('advertising.administration.companies_adverts_grid.paid.no');
									}
								},
								filter: 'agSetColumnFilter',
								...shared.enterprisePlusEditable,
								...shared.all,
								maxWidth: 130
							},
							{
								field: 'controller.active',
								headerName: t('advertising.administration.companies_adverts_grid.status'),
								valueFormatter: (params) =>
									params.value === true
										? t('advertising.administration.companies_adverts_grid.status.on')
										: t('advertising.administration.companies_adverts_grid.status.off'),
								cellRenderer: 'dropdownRenderer',
								filter: 'agSetColumnFilter',
								...shared.all,
								...shared.editable,
								...shared.renderYesOrNoOption,
								maxWidth: 100
							},
							{
								field: 'subscriptionHelperObject.account',
								headerName: t('advertising.administration.companies_adverts_grid.subscription'),
								...shared.all,
								maxWidth: 150
							},
							{
								colId: 'preview',
								headerName: '',
								maxWidth: 85,
								suppressMenu: true,
								sortable: false,
								cellRenderer: (params) => {
									return (
										<DropDown
											alignMenu='right'
											button={({ params }) => (
												<IconButton noBorder icon='faEllipsisV' {...params} />
											)}>
											<MenuItemIcon
												onClick={() => openPreviewModal(params.data.company.id, params.data.id)}
												icon='faEye'
												listItemTid='advertising.administration.adverts_grid.buttons.preview'
											/>
											{params.data.hasDowngradedWithActiveAdvert && (
												<MenuItemIcon
													onClick={() => {
														dispatch(changeWarning(params.data.id, false));
														onDismissWarning(params.data.id);
														console.log('FINA PARAMS', params);
														gridRef.current.api.redrawRows({ rowNodes: [params.node] });
													}}
													icon='faBan'
													listItemTid='advertising.administration.adverts_grid.more_action.item.btn.deactivate_warning'
												/>
											)}
										</DropDown>
									);
								}
							}
						]}
					/>
					{previewModalOpen && (
						<PreviewAdvert open={previewModalOpen} onClose={closePreviewModal} {...previewData} />
					)}
				</CardContent>
			</Card>
		</Grid>
	);
}
