/**
 * Update this example object when adding new theme properties

 example: {
	 labelProps: {
		 borderColor: 'border'
	 },
	 inputProps: {
		 borderRadius: 8,
		 activeTextColor:  'java'
	 }
 }

 *
 */

export default {
	formStandard: {
		labelProps: {
			borderColor: 'border'
		},
		inputProps: {
			borderRadius: 0,
			activeTextColor: 'darkGrey',
			backgroundColor: 'transparent',
			activeBackgroundColor: 'white'
		}
	},

	formRounded: {
		bodyProps: {
			border: '1px solid #333',
			borderRadius: '1000px',
			padding: '1px'
		},
		labelProps: {
			borderColor: 'border',
			borderRadius: '1000px'
		},
		inputProps: {
			borderRadius: 1000,
			backgroundColor: 'transparent',
			activeTextColor: 'white',
			activeBackgroundColor: 'java'
		}
	},

	panelHeader: {
		bodyProps: {
			height: '49px'
		},
		inputProps: {
			borderRadius: 0,
			activeTextColor: 'catalinaBlue',
			backgroundColor: 'solitudeLight',
			activeBackgroundColor: 'solitudeMid',
			hoverColor: 'lightestGrey'
		}
	},

	notesSwitch: {
		labelProps: {
			borderColor: 'border'
		},
		inputProps: {
			borderRadius: 0,
			backgroundColor: 'solitudeMid',
			activeTextColor: 'catalinaBlue',
			activeBackgroundColor: 'white',
			rightActiveBgColor: 'notesYellow'
		}
	}
}
