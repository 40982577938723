import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { convertToHtml } from '../../../components/helpers/draftjs.helper'
import Text from '../../../dumb-components/shared/text/text'
import GenericInfoPanel from '../../../dumb-components/company/comapny-dashboard/generic-info-panel/generic-info-panel'
import { getCompanyAnyLogoUrl } from '../../../components/helpers/company.helper'

const StyledContentContainer = styled.div`
	margin-bottom: 16px;
`

function CompanyInfoContainer({ image, companyId, businessOverview }) {
	const currentImageUrl = getCompanyAnyLogoUrl(companyId, image, 250)

	return (
		<GenericInfoPanel title='company.dashboard.company_info_panel.title'>
			<StyledContentContainer>
				{image && (
					<img
						src={currentImageUrl}
						style={{ float: 'left', maxWidth: '40%', minWidth: '20%', margin: '0 16px 0 0 ' }}
					/>
				)}
				{businessOverview ? (
					<Text asHtml>{convertToHtml(businessOverview)}</Text>
				) : (
					<Text tid='company.dashboard.company_info_panel.no_overview' />
				)}
			</StyledContentContainer>
		</GenericInfoPanel>
	)
}

function mapStateToProps(store) {
	const { image, id, businessOverview } = store.company.company
	return {
		image: image && image,
		companyId: id,
		businessOverview: businessOverview && businessOverview
	}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CompanyInfoContainer)
