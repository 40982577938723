import React, { Component } from 'react'
import { connect } from 'react-redux'
import { object, string, array, bool, func, number, oneOf } from 'prop-types'
import { saveTask, updateTaskLocal } from '../../actions/tasks.actions'
import StatusButton from '../../dumb-components/shared/status-button/status-button'
import { AVAIBLE_TASK_STATUS } from '../../constants/tasks'
import Box from '../../dumb-components/shared/layout/box/box'
import StyledStatusRectangle from '../../dumb-components/shared/color-progress-select/styled-status-rectangle'
import Text from '../../dumb-components/shared/text/text'

class StatusButtonContainer extends Component {
	static propTypes = {
		taskId: string
	}

	state = {
		isOpen: false
	}

	toggleMenu = () => {
		this.setState((prevState) => {
			return { isOpen: !prevState.isOpen }
		})
	}

	saveTask = (val) => {
		const { saveTask, updateTaskLocal } = this.props
		let { task } = this.props

		this.setState({ isOpen: false })

		task = task.set('status', val)
		updateTaskLocal(task)
		saveTask(task)
	}

	formatOptionLabel = (option) => {
		return (
			<Box>
				<StyledStatusRectangle color={option.get('statusColor')} />
				<Text tid={option.get('label')} />
			</Box>
		)
	}

	render = () => {
		const { task } = this.props
		const { isOpen } = this.state
		const canUpdate = task ? task.get('ALLOW_UPDATE') : false

		if (!task || !canUpdate) {
			return null
		}

		let selectedOption = AVAIBLE_TASK_STATUS.find((obj) => {
			return obj.get('value') === task.get('status')
		})

		if (!selectedOption) {
			selectedOption = AVAIBLE_TASK_STATUS.find((obj) => {
				return obj.get('value') === 'to_do'
			})
		}

		return (
			<StatusButton
				options={AVAIBLE_TASK_STATUS}
				selectedOption={selectedOption}
				btnTid='status_button.tasks.select_as'
				isOpen={isOpen}
				onToggleMenu={this.toggleMenu}
				formatOption={this.formatOptionLabel}
				onOptionClick={this.saveTask}
				onBtnClick={() => {
					this.saveTask(selectedOption.get('value'))
				}}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		task: store.tasks.get('task'),
		i18n: store.i18n
	}
}

const mapActionsToProps = {
	saveTask,
	updateTaskLocal
}

export default connect(mapStoreToProps, mapActionsToProps)(StatusButtonContainer)
