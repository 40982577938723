import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, object, func, bool } from 'prop-types'
import TaskCreator from '../../dumb-components/tasks/task-creator/task-creator'

class TaskCreatorContainer extends Component {
	static propTypes = {
		fieldName: string,
		value: string,
		placeholderTid: string,
		onChange: func,
		onFocus: func,
		onBlur: func,
		onArrowUp: func,
		onArrowDown: func,
		onEnter: func,
		onEsc: func,
		inline: bool,
		autofocus: bool,
		badge: object,
		noBadgeSpace: bool,
		readOnly: bool
	}

	static defaultprops = {
		inline: false,
		autofocus: false
	}

	state = {
		value: null
	}

	onChange = (field, val) => {
		const { onChange } = this.props
		this.setState({ value: val })
		onChange && onChange(field, val)
	}

	onEnter = () => {
		const { index, projectId, onEnter } = this.props

		onEnter && onEnter(index, projectId)
		this.clearValue()
	}

	clearValue = () => {
		this.setState({ value: '' })
	}

	render = () => {
		const {
			fieldName,
			value,
			placeholderTid,
			onFocus,
			onBlur,
			onArrowUp,
			onArrowDown,
			onEsc,
			i18n,
			inline,
			autofocus,
			badge,
			noBadgeSpace,
			readOnly
		} = this.props
		const stateValue = this.state.value

		return (
			<TaskCreator
				fieldName={fieldName}
				value={stateValue || value || ''}
				onChange={this.onChange}
				placeholderTid={placeholderTid}
				onFocus={onFocus}
				onBlur={onBlur}
				onArrowUp={onArrowUp}
				onArrowDown={onArrowDown}
				onEnter={this.onEnter}
				onEsc={onEsc}
				i18n={i18n}
				inline={inline}
				autofocus={autofocus}
				badge={badge}
				noBadgeSpace={noBadgeSpace}
				readOnly={readOnly}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return { i18n: store.i18n }
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(TaskCreatorContainer)
