import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAdvert } from '../../../../actions/adverts-invono-admin.actions'
import MUIDialog from '../../../../mui-components/dialog/mui-dialog'
import { Box } from '@mui/material'
import { AdvertCardView } from '../../company-adverts/views/AdvertCardView'
import { CompanyAdvertsModal } from '../../company-adverts/CompanyAdvertsModal/CompanyAdvertsModal'
import { useTranslation } from 'react-i18next'

export const PreviewAdvert = ({ companyId, advertId, open, onClose }) => {
	const [advert, setAdvert] = useState()
	const [advertModalOpen, setAdvertModalOpen] = useState(false)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		dispatch(
			fetchAdvert(companyId, advertId, (error, response) => {
				if (!error) {
					setAdvert(response)
				}
			})
		)
	}, [companyId, advertId])

	if (!advert) {
		return null
	}

	return (
		<MUIDialog
			isOpen={open}
			onClose={onClose}
			title={t('advertising.administration.create_advert_modal.preview.title')}
			closeButtonTid='logo_selector.modal.buttons.close'
			confirmButton={false}
			maxWidth='lg'>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
					backgroundColor: '#ecf0f5',
					overflow: 'auto'
				}}>
				<Box sx={{ width: '542px', padding: '32px' }}>
					<AdvertCardView
						advert={advert}
						openAdvertModal={() => setAdvertModalOpen(true)}
						onFollow={() => {}}
						followBtnDisabled={false}
					/>
					{advertModalOpen && (
						<CompanyAdvertsModal advert={advert} closeModal={() => setAdvertModalOpen(false)} />
					)}
				</Box>
			</Box>
		</MUIDialog>
	)
}
