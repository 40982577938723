import React from 'react'
import { func } from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import styled from 'styled-components'
import { Margin, Padding } from 'styled-components-spacing'
import List from '../../shared/list/list'
import ListItem from '../../shared/list-item/list-item'
import Text from '../../shared/text/text'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import Panel from '../../shared/panel/panel'

const StyledProductItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	cursor: default;
`

const StyledName = styled.div`
	flex-basis: 120px;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

const StyledDescription = styled.div`
	flex: 1;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

const StyledStatus = styled.div`
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

const renderProductItem = (values, onChange, product) => {
	const value = product.get('value')
	const disabled = value === 'DOCUMENTS' || value === 'MEETINGS' || value === 'TASKS'
	return (
		!disabled && (
			<ListItem striped key={product.get('value')}>
				<StyledProductItem>
					<StyledName>
						<Text tid={product.get('tid')} bold={600} />
					</StyledName>
					<StyledDescription>
						<Text tid={product.get('descriptionTid')} />
					</StyledDescription>
					<StyledStatus>
						<ToggleSwitch
							checked={values && values.contains(product.get('value'))}
							onChange={(checked) => {
								onChange && onChange(product.get('value'), checked)
							}}
						/>
					</StyledStatus>
				</StyledProductItem>
			</ListItem>
		)
	)
}

const ProductsList = ({ products, values, onChange }) => {
	return (
		<Panel mode='light' title='subscriptions.products.title' marginBottom>
			<Padding all={4}>
				<List>{products && products.map(renderProductItem.bind(null, values, onChange))}</List>
			</Padding>
		</Panel>
	)
}

ProductsList.propTypes = {
	products: ImmutablePropTypes.list.isRequired,
	values: ImmutablePropTypes.list,
	onChange: func
}

export default ProductsList
