import { fromJS } from 'immutable'

import { USER_CACHE_ADD_USERS_TO_CACHE, USER_CACHE_REMOVE_USER } from '../actions/types'

const INITIAL_STATE = fromJS({
	usersCache: {}
})

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case USER_CACHE_ADD_USERS_TO_CACHE: {
			const users = action.payload
			users.forEach((user) => {
				state = state.setIn(['usersCache', user.get('id')], user)
			})
			return state
		}

		default:
			return state
	}
}
