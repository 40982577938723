import React, { Component } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { string, bool, func, object, oneOfType } from 'prop-types'
import styled from 'styled-components'
import TinyMCEInline from 'react-tinymce'
import ReactTinyMCEInput from 'react-tinymce-input'
import loadScript from 'load-script'
import Text from '../text/text'

const StyledTextarea = styled.div`
	min-height: 108px;
	background-color: ${(props) => props.theme.colors.disabled};
	border: 1px solid ${(props) => (props.hasError || props.haserror ? props.theme.colors.red : '#c3c3c3')};
	padding: 8px 12px;
	cursor: default;
	color: ${(props) => props.theme.colors.midGrey};
`

const StyledTinyMCEInput = styled(ReactTinyMCEInput)`
	width: 100%;
	min-height: ${(props) => props.theme.fontSizes.default};
`

const StyledTinyMCEInline = styled(TinyMCEInline)`
	width: 100%;
	min-height: ${(props) => props.theme.fontSizes.default};
`

class TinymceInput extends Component {
	static propTypes = {
		objId: string, // Måste anges: Sätts som värde på propertyn key till StyledTinyMCEInline för att den komponenten ska omrenderas när propertyn value ändrats
		fieldName: string,
		config: oneOfType([object, string]),
		disabled: bool,
		disabledNoContentTid: string,
		onChange: func,
		language: string,
		hasError: ImmutablePropTypes.list,
		readonly: bool,
		value: string,
		bodyClassName: string
	}

	static defaultPropTypes = {
		readonly: false
	}

	state = {
		tinyMceLoaded: false
	}

	componentDidMount = () => {
		if (typeof window.tinymce === 'object') {
			this.setState({ tinyMceLoaded: true })
		} else {
			loadScript(
				'https://cloud.tinymce.com/5/tinymce.min.js?apiKey=qdu6f2rlssk88nhclkjd2spkt5n5019wumiyznid6a9hsr48',
				() => {
					this.setState({ tinyMceLoaded: true })
				}
			)
		}
	}

	getStylesheetPath = () => {
		let stylesheet = ''
		for (let i = 0; i < document.styleSheets.length; i++) {
			if (document.styleSheets[i].href && document.styleSheets[i].href.indexOf('/assets/build/styles') !== -1) {
				stylesheet = document.styleSheets[i].href
			}
		}
		return stylesheet
	}

	// Other language than english is not working at this moment.
	// License needed for other languages
	getConfig = () => {
		const MIN_HEIGHT = 300
		let config = this.props.config || {}
		const { language, disabled, placeholder, bodyClassName } = this.props
		const stylesheet = this.getStylesheetPath()
		const lang = language && language.substr(0, 2) !== 'en' ? language.replace('-', '_') : null

		const baseConfig = {
			menubar: false,
			plugins: 'link lists autoresize fullscreen paste',
			toolbar: 'bold italic underline | bullist numlist | link | fullscreen',
			autoresize_bottom_margin: 0,
			autoresize_min_height: MIN_HEIGHT,
			min_height: MIN_HEIGHT,
			paste_webkit_styles: 'none',
			branding: false,
			content_css: stylesheet,
			statusbar: false
		}

		if (bodyClassName) {
			baseConfig.body_class = bodyClassName // eslint-disable-line camelcase
		}

		if (config === 'simple') {
			return {
				...baseConfig,
				// language: lang,
				readonly: disabled
			}
		} else {
			config = {
				...baseConfig,
				...config
			}

			if (!config.readonly) {
				config.readonly = disabled
			}

			// if (!config.language) {
			// 	config.language = lang;
			// }

			return config
		}
	}

	onChangeInline = (e, editor) => {
		let val = editor.getContent({ format: 'raw' })
		const { fieldName, onChange } = this.props
		// Fix for Firefox (Chrome seems to be doing this automatically).
		if (val.indexOf(' <') !== -1) {
			val = val.replace(' <', '&nbsp;<')
		}

		if (fieldName) {
			return onChange && onChange(fieldName, val)
		}

		onChange && onChange(val)
	}

	onChangeInput = (val) => {}

	onBlurInput = (editor) => {
		const { fieldName, onChange } = this.props
		let val = editor.target.getContent({ format: 'raw' })

		// Fix for Firefox (Chrome seems to be doing this automatically).
		if (val.indexOf(' <') !== -1) {
			val = val.replace(' <', '&nbsp;<')
		}

		if (fieldName) {
			return onChange && onChange(fieldName, val)
		}

		onChange && onChange(val)
	}

	render = () => {
		const { objId, disabled, readonlyNoContentTid } = this.props
		const { tinyMceLoaded } = this.state
		let { value } = this.props
		const config = this.getConfig()

		if (typeof value !== 'string' || value === '') {
			value = '<p> </p>'
		}

		if (disabled) {
			if (value) {
				return <StyledTextarea dangerouslySetInnerHTML={{ __html: value }}></StyledTextarea>
			}

			return (
				<StyledTextarea>
					<Text tid={readonlyNoContentTid} />
				</StyledTextarea>
			)
		}

		if (!tinyMceLoaded) {
			return null
		}

		if (config.inline) {
			return <StyledTinyMCEInline key={objId} content={value} onKeyup={this.onChangeInline} config={config} />
		}

		return (
			<StyledTinyMCEInput
				key={objId}
				name={this.props.fieldName}
				value={value}
				onChange={this.onChangeInput}
				onBlur={this.onBlurInput}
				tinymceConfig={config}
			/>
		)
	}
}

export default TinymceInput
