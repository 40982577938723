import React from 'react'
import { FormSpy } from 'react-final-form'

class AutoSave extends React.Component {
	constructor(props) {
		super(props)
		this.state = { values: props.values, submitting: false }
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// What is this for?
		//if (this.props.active === prevProps.active) {
		//	return
		//}

		if (this.timeout) {
			clearTimeout(this.timeout)
		}

		this.timeout = setTimeout(this.save, 500)
	}

	save = async () => {
		if (this.promise) {
			await this.promise
		}
		const { values, save } = this.props

		if (JSON.stringify(this.state.values) !== JSON.stringify(values)) {
			this.setState({ submitting: true, values })
			this.promise = save(values)
			await this.promise
			delete this.promise
			this.setState({ submitting: false })
		}
	}

	render() {
		return null
	}
}

export default (props) => <FormSpy {...props} subscription={{ active: true, values: true }} component={AutoSave} />
