import { arrayOf, bool, func, number, oneOfType, string } from 'prop-types';
import React from 'react'
import { Field } from 'react-final-form'

function Condition({ when, is, children }) {
	const shouldRenderChildren = (value) => {
		if (Array.isArray(is)) {
			return is.includes(value)
		}

		if (typeof is === 'function') {
			return is(value)
		}

		return value === is
	}

	return (
		<Field name={when} subscription={{ value: true }}>
			{({ input: { value } }) => (shouldRenderChildren(value) ? children : null)}
		</Field>
	);
}

Condition.propTypes = {
	when: string.isRequired,
	is: oneOfType([number, string, bool, func, arrayOf(string), arrayOf(number)]).isRequired,
	// children: node
}

export { Condition };
