import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

import Breadcrumbs from '../shared/breadcrumbs/breadcrumbs'
import BreadcrumbsItem from '../shared/breadcrumbs/breadcrumbs-item'
import Text from '../shared/text/text'

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	margin: ${(props) => props.theme.spacing[3]};
	justify-content: space-between;
`

const StyledLinkCodeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-right: 3px solid #225e5e;
	padding-right: ${(props) => props.theme.spacing[3]};
	text-align: right;

	h4 {
		margin: 0;
	}
`
export default class Header extends PureComponent {
	static propTypes = {
		linkCode: string
	}

	static defaultProps = {
		linkCode: '-'
	}

	renderBreadcrumbs = () => {
		return (
			<Breadcrumbs>
				<BreadcrumbsItem itemName={<Text tid='company.profile.breadcrumbs.title' />} isStatic={true} />
			</Breadcrumbs>
		)
	}

	renderLinkCode = () => {
		const { linkCode } = this.props

		return (
			<StyledLinkCodeWrapper>
				<h4>{linkCode}</h4>
				<Text tid='invono_id_description_company' fontSize='12px' hasItalic />
			</StyledLinkCodeWrapper>
		)
	}

	render = () => {
		return (
			<Wrapper>
				{this.renderBreadcrumbs()}
				{this.renderLinkCode()}
			</Wrapper>
		)
	}
}
