import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { HELPSCOUT_ARTICLES } from '../../constants/helpscout-articles';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';

const StyledLink = styled('a')(({ theme }) => ({
	color: theme.palette.positive.main,
	...theme.typography.link,
	'&:hover': {
		textDecoration: 'underline',
		color: theme.palette.positive.hover
	}
}));

export default function InfoModal({ article }) {
	const locale = useSelector((s) => s.i18n.language);
	const { t } = useTranslation();

	//console.log('locale: ', locale);
	//console.log('window.Beacon: ', window);

	return (
		<>
			<StyledLink href={'#'} data-beacon-article-modal={HELPSCOUT_ARTICLES[article][locale]}>
				{t('investments.information_panel.link.read_more')}
			</StyledLink>
		</>
	);
}

InfoModal.defaultProps = {};

InfoModal.propTypes = {
	article: string
};
