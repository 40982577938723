const { fromJS } = require('immutable')

const filterAttendees = function (attendees, filterCriteria) {
	const filterFunctions = ['filterByResponseStatus', 'filterByAttendanceType', 'filterByStatus', 'filterByVerified']

	for (const fn of filterFunctions) {
		attendees = filterAttendees[fn](attendees, filterCriteria)
	}

	return attendees
}

filterAttendees.filterByResponseStatus = function (attendees, filterCriteria) {
	const responseStatus = filterCriteria.get('responseStatus')
	if (!responseStatus) {
		return attendees
	}

	if (responseStatus === 'noResponse') {
		return attendees.filter((a) => !a.get('responseStatus'))
	}

	return attendees.filter((a) => a.get('responseStatus') === responseStatus)
}

filterAttendees.filterByAttendanceType = function (attendees, filterCriteria) {
	const attendanceType = filterCriteria.get('attendanceType')
	if (!attendanceType) {
		return attendees
	}

	const filteredAttendees = attendees.filter((a) => a.get('attendanceType') === attendanceType)

	return filteredAttendees
}

filterAttendees.filterByStatus = function (attendees, filterCriteria) {
	const status = filterCriteria.get('status')
	if (!status) {
		return attendees
	}

	const filteredAttendees = attendees.filter((a) => {
		return a.get('status') === status
	})

	return filteredAttendees
}

filterAttendees.filterByVerified = function (attendees, filterCriteria) {
	const verifiedAttendance = filterCriteria.get('verifiedAttendance')
	if (!verifiedAttendance) {
		return attendees
	}

	const filteredAttendees = attendees.filter((a) => {
		if (verifiedAttendance === 'true') {
			return a.get('verifiedAttendance')
		}

		if (verifiedAttendance === 'false') {
			return !a.get('verifiedAttendance')
		}
	})

	return filteredAttendees
}

export default filterAttendees
