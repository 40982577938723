import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';
import { fetchCompanyInvestments } from '../../../actions/investors.actions';
import { fetchAllMyInvestments } from '../../../actions/investments.actions';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { List, Map } from 'immutable';
import { useTranslation } from 'react-i18next';

import {
	EVENT_TYPE_SHARES_INVESTMENT_LINKED,
	EVENT_TYPE_SHARES_TRANSACTION_CREATE,
	EVENT_TYPE_SHARES_TRANSACTION_DELETE,
	INVESTMENTS_CAPITAL_INSURANCE,
	INVESTMENTS_PRIVATE_PORTFOLIO,
	INVESTMENTS_COMPANY_INVESTMENTS_PORTFOLIO,
	INVESTMENTS_VIA_COMPANY,
	ADVERT_SECTIONS,
	HELPSCOUT_MY_PORTFOLIO_READ_MORE
} from '/shared/constants';
import ScrollView from '../../../dumb-components/shared/layout/scroll-view/scroll-view';
import AddInvestmentModalContainer from './add-investment-modal.container';

import slug from 'slug';
import { generateQuery } from '../../../components/helpers/query-helper';
import {
	COMPANY_ADD_NEW_COMPANY_MODAL,
	INVESTMENTS_OVERVIEW_PRIVATE_MODE_ADD_INVESTMENT_MODAL,
	MODELS_RECOMMEND_INVONO,
	CAP_TABLE_READ_MORE_MODAL
} from '../../../constants/modals';
import InvestmentsGridContainer from './investments-grid.container';
import investmentHelper from '../../../components/helpers/investment.helper';
import { openModal } from '../../../actions/modals.actions';
import { FeatureCard } from '../../advertising/feature/feature-card';
import { FeatureGroupContextProvider } from '../../advertising/feature/feature-group.provider';
import IconButton from '../../../mui-components/button/icon-button';
import { RecommendModal } from '../../recommend/RecommendModal';
import { ReadMoreModal } from '../modals/read-more-modal';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { resetCompanyReducer, companyChanged } from '../../../actions/company.actions';
import { CompanyAdverts } from '../../advertising/company-adverts/CompanyAdverts';
import AdvertCard from '../../advertising/company-adverts/AdvertCard';
import InfoModal from '../../../dumb-components/helpscout/info-modal';

export const InvestmentOverviewContext = React.createContext();

const InvestmentsOverviewContainer = ({ isCompany, location }) => {
	const [investmentsResponded, setInvestmentsResponded] = useState(false);
	const { t } = useTranslation();

	const { pathname } = location;

	const investments = useSelector((state) => {
		return state.investors.get('myInvestments');
	});

	const myInvestmentsMap = useSelector((state) => {
		return state.investors.get('myInvestmentsMap');
	});

	const audit = useSelector((state) => {
		return state.audit.get('investors');
	});

	const transactionsAudit = useSelector((state) => {
		return state.audit.get('transactions');
	});

	const isPrivateModeInvestmentModalOpen = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'name']) === INVESTMENTS_OVERVIEW_PRIVATE_MODE_ADD_INVESTMENT_MODAL;
	});
	const isReadMoreModalOpen = useSelector(
		(state) => state.modals.getIn(['activeModal', 'name']) === CAP_TABLE_READ_MORE_MODAL
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isCompany) {
			dispatch(
				fetchCompanyInvestments(() => {
					setInvestmentsResponded(true);
				})
			);
		} else {
			dispatch(
				fetchAllMyInvestments(() => {
					setInvestmentsResponded(true);
				})
			);
			dispatch(resetCompanyReducer());
			dispatch(companyChanged());
		}
	}, []);

	useEffect(() => {
		const SHARES_INVESTMENT_LINKED = audit.get(EVENT_TYPE_SHARES_INVESTMENT_LINKED);
		const SHARES_TRANSACTION_CREATE = transactionsAudit.get(EVENT_TYPE_SHARES_TRANSACTION_CREATE);
		const SHARES_TRANSACTION_DELETE = transactionsAudit.get(EVENT_TYPE_SHARES_TRANSACTION_DELETE);
		if (
			(SHARES_INVESTMENT_LINKED && SHARES_INVESTMENT_LINKED.get('refresh') === true) ||
			(SHARES_TRANSACTION_CREATE && SHARES_TRANSACTION_CREATE.get('refresh') === true) ||
			(SHARES_TRANSACTION_DELETE && SHARES_TRANSACTION_DELETE.get('refresh') === true)
		) {
			dispatch(fetchAllMyInvestments());
		}
	}, [audit, transactionsAudit]);

	const companyInvestmentsPortfolioMemo = useMemo(() => {
		let companyInvestmentsPortfolio = myInvestmentsMap?.get('companyInvestments');

		companyInvestmentsPortfolio = companyInvestmentsPortfolio?.map((portfolio) => {
			if (portfolio.get('investment')) {
				portfolio = portfolio.set(
					'investment',
					investments.find((investment) => {
						return investment.get('id') === portfolio.get('investment');
					})
				);
			}
			return portfolio;
		});
		companyInvestmentsPortfolio = companyInvestmentsPortfolio?.map((portfolio) => {
			const details = portfolio.getIn(['investment', 'details']);
			portfolio = portfolio.set('details', details);

			const name = portfolio.getIn(['investment', 'investedInCompanyInformation', 'name']);
			portfolio = portfolio.set('name', name);

			const query = generateQuery({
				investmentId: portfolio.getIn(['investment', 'id'])
			});
			const link = `${name ? slug(name) : ''}/general${query}`;
			portfolio = portfolio.set('link', link);

			return portfolio;
		});

		return companyInvestmentsPortfolio;
	}, [investments, myInvestmentsMap]);

	const privatePortfolioMemo = useMemo(() => {
		let privatePortfolio = myInvestmentsMap?.get('privateInvesments');

		privatePortfolio = privatePortfolio?.map((portfolio) => {
			if (portfolio.get('investment')) {
				portfolio = portfolio.set(
					'investment',
					investments.find((investment) => {
						return investment.get('id') === portfolio.get('investment');
					})
				);
			}
			if (portfolio.get('personalInvestment')) {
				portfolio = portfolio.set(
					'personalInvestment',
					investments.find((investment) => {
						return investment.get('id') === portfolio.get('personalInvestment');
					})
				);
			}
			return portfolio;
		});
		privatePortfolio = privatePortfolio?.map((portfolio) => {
			const showPrivatePortfolio =
				portfolio.getIn(['investment', 'details']) &&
				!portfolio.getIn(['personalInvestment', 'showInPortfolio']);
			if (!showPrivatePortfolio) {
				const transactions = portfolio.getIn(['personalInvestment', 'transactions']);
				let displayColumnData = true;

				if (transactions && transactions.size < 1) {
					displayColumnData = false;
				} else {
					const oneOrMoreCompleteTransactions =
						transactions && transactions.find((transaction) => transaction.get('isComplete'));
					if (!oneOrMoreCompleteTransactions) {
						displayColumnData = false;
					}
				}

				portfolio = portfolio.set('displayColumnData', displayColumnData);
			}

			const details = showPrivatePortfolio
				? portfolio.getIn(['investment', 'details'])
				: portfolio.getIn(['personalInvestment', 'details']);
			portfolio = portfolio.set('details', details);

			const name = investmentHelper.getInvestedInCompanyNameOrAlias(
				portfolio.get('personalInvestment'),
				portfolio.getIn(['investment', 'investedInCompanyInformation', 'name'])
			);
			portfolio = portfolio.set('name', name);
			const query = generateQuery({
				investmentId: portfolio.getIn(['investment', 'id']),
				personalInvestmentId: portfolio.getIn(['personalInvestment', 'id'])
			});
			const link = `${name ? slug(name) : ''}/general${query}`;
			portfolio = portfolio.set('link', link);

			return portfolio;
		});

		return privatePortfolio;
	}, [investments, myInvestmentsMap]);

	const myCapitalInsuranceInvestmentsMemo = useMemo(() => {
		let capitalInsurancePortfolio = myInvestmentsMap?.get('capitalInsuranceInvestments');

		capitalInsurancePortfolio = capitalInsurancePortfolio?.map((portfolio) => {
			if (portfolio.get('investment')) {
				portfolio = portfolio.set(
					'investment',
					investments.find((investment) => {
						return investment.get('id') === portfolio.get('investment');
					})
				);
			}
			if (portfolio.get('personalInvestment')) {
				portfolio = portfolio.set(
					'personalInvestment',
					investments.find((investment) => {
						return investment.get('id') === portfolio.get('personalInvestment');
					})
				);
			}
			return portfolio;
		});

		capitalInsurancePortfolio = capitalInsurancePortfolio?.map((portfolio) => {
			const showInsurancePortfolio =
				portfolio.getIn(['investment', 'details']) &&
				!portfolio.getIn(['personalInvestment', 'showInPortfolio']);

			if (!showInsurancePortfolio) {
				const transactions = portfolio.getIn(['personalInvestment', 'transactions']);
				let displayColumnData = true;

				if (transactions && transactions.size < 1) {
					displayColumnData = false;
				} else {
					const oneOrMoreCompleteTransactions =
						transactions && transactions.find((transaction) => transaction.get('isComplete'));
					if (!oneOrMoreCompleteTransactions) {
						displayColumnData = false;
					}
				}

				portfolio = portfolio.set('displayColumnData', displayColumnData);
			}

			const details = showInsurancePortfolio
				? portfolio.getIn(['investment', 'details'])
				: portfolio.getIn(['personalInvestment', 'details']);
			portfolio = portfolio.set('details', details);

			const name = investmentHelper.getInvestedInCompanyNameOrAlias(
				portfolio.get('personalInvestment'),
				portfolio.getIn(['investment', 'investedInCompanyInformation', 'name'])
			);
			const insuranceNr = portfolio.get('investment')
				? portfolio.getIn(['investment', 'investorInformation', 'insuranceNr'])
				: portfolio.getIn(['personalInvestment', 'personalInvestmentType', 'insuranceNr']);
			portfolio = portfolio.set('name', `${name} - ${insuranceNr}`);
			const query = generateQuery({
				investmentId: portfolio.getIn(['investment', 'id']),
				personalInvestmentId: portfolio.getIn(['personalInvestment', 'id'])
			});
			const link = `${name ? slug(name) : ''}/general${query}`;
			portfolio = portfolio.set('link', link);
			return portfolio;
		});

		return capitalInsurancePortfolio;
	}, [investments, myInvestmentsMap]);

	const viaCompanyInvestmentsMemo = useMemo(() => {
		let companyPortfolio = myInvestmentsMap?.get('viaCompanyInvestments');

		companyPortfolio = companyPortfolio?.map((portfolio) => {
			if (portfolio.get('investment')) {
				portfolio = portfolio.set(
					'investment',
					investments.find((investment) => {
						return investment.get('id') === portfolio.get('investment');
					})
				);
			}
			if (portfolio.get('personalInvestment')) {
				portfolio = portfolio.set(
					'personalInvestment',
					investments.find((investment) => {
						return investment.get('id') === portfolio.get('personalInvestment');
					})
				);
			}
			return portfolio;
		});

		companyPortfolio = companyPortfolio?.map((portfolio) => {
			const details = portfolio.getIn(['investment', 'details'])
				? portfolio.getIn(['investment', 'details'])
				: portfolio.getIn(['personalInvestment', 'details']);
			portfolio = portfolio.set('details', details);

			const name = investmentHelper.getInvestedInCompanyNameOrAlias(
				portfolio.get('personalInvestment'),
				portfolio.getIn(['investment', 'investedInCompanyInformation', 'name'])
			);
			portfolio = portfolio.set('name', name);

			const viaCompanyName = portfolio.get('investment')
				? portfolio.getIn(['investment', 'investorInformation', 'name'])
				: portfolio.getIn(['personalInvestment', 'personalInvestmentType', 'viaCompanyName']);
			portfolio = portfolio.set('viaCompanyName', viaCompanyName);

			let rootOwners = portfolio.getIn(['investment', 'rootOwners']);
			rootOwners = rootOwners?.map((rootOwner) => {
				const query = generateQuery({
					investmentId: rootOwner.get('id') ? portfolio.getIn(['investment', 'id']) : null,
					viaInvestmentId: rootOwner.get('id'),
					personalInvestmentId: rootOwner.get('personalInvestmentId')
				});
				rootOwner = rootOwner.set(
					'personalInvestment',
					investments.find((investment) => {
						return investment.get('id') === rootOwner.get('personalInvestmentId');
					})
				);

				const rootOwnerName = investmentHelper.getInvestedInCompanyNameOrAlias(
					rootOwner.get('personalInvestment'),
					portfolio.getIn(['investment', 'investedInCompanyInformation', 'name'])
				);

				const link = `${rootOwnerName ? slug(rootOwnerName) : ''}/general${query}`;

				return rootOwner.set('link', link);
			});

			if (!rootOwners && portfolio.get('personalInvestment')) {
				const query = generateQuery({
					personalInvestmentId: portfolio.getIn(['personalInvestment', 'id'])
				});
				rootOwners = List([
					Map({
						personalInvestment: portfolio.get('personalInvestment'),
						personalInvestmentId: portfolio.getIn(['personalInvestment', 'id']),
						link: `${name ? slug(name) : ''}/general${query}`
					})
				]);
			}

			rootOwners =
				rootOwners &&
				rootOwners.map((rootOwner) => {
					let newRootOwner = rootOwner;
					const companyShowInPortfolio = newRootOwner.getIn(['personalInvestment', 'showInPortfolio']);
					if (companyShowInPortfolio) {
						const transactions = newRootOwner.getIn(['personalInvestment', 'transactions']);
						let displayColumnData = true;
						if (transactions.size < 1) {
							displayColumnData = false;
						} else {
							const oneOrMoreCompleteTransactions =
								transactions && transactions.find((transaction) => transaction.get('isComplete'));
							if (!oneOrMoreCompleteTransactions) {
								displayColumnData = false;
							}
						}

						newRootOwner = newRootOwner.setIn(
							['personalInvestment', 'displayColumnData'],
							displayColumnData
						);

						return newRootOwner;
					} else {
						return newRootOwner;
					}
				});

			portfolio = portfolio.set('rootOwners', rootOwners);

			const investorId = portfolio.getIn(['investment', 'investorId']);

			let investor;
			if (investorId) {
				investor =
					privatePortfolioMemo?.find(
						(item) => item.getIn(['investment', 'investedInCompanyId']) === investorId
					) ||
					myCapitalInsuranceInvestmentsMemo?.find(
						(item) => item.getIn(['investment', 'investedInCompanyId']) === investorId
					);
			}

			const viaCompanyAlias =
				investor &&
				investmentHelper.getInvestedInCompanyNameOrAlias(
					investor.get('personalInvestment'),
					investor.getIn(['investment', 'investedInCompanyInformation', 'name'])
				);
			if (viaCompanyAlias) {
				portfolio = portfolio.set('viaCompanyAlias', viaCompanyAlias);
			}

			return portfolio;
		});

		companyPortfolio = companyPortfolio?.groupBy((item) => {
			const id = item.get('investment')
				? item.getIn(['investment', 'investorInformation', 'id'])
				: item.getIn(['personalInvestment', 'personalInvestmentType', 'viaCompanyOrgNumber']);

			return id;
		});

		return companyPortfolio;
	}, [investments, myInvestmentsMap]);

	const openAddPersonalInvestmentModal = () => {
		dispatch(openModal(INVESTMENTS_OVERVIEW_PRIVATE_MODE_ADD_INVESTMENT_MODAL));
	};

	const openReadMoreModal = () => {
		dispatch(openModal(CAP_TABLE_READ_MORE_MODAL));
	};

	if (!investmentsResponded) {
		return null;
	}

	const value = {
		pathname
	};

	const openAddCompanyModal = () => {
		dispatch(openModal(COMPANY_ADD_NEW_COMPANY_MODAL));
	};

	const openRecommendationModal = () => {
		dispatch(openModal(MODELS_RECOMMEND_INVONO));
	};

	const gotInvestments = investments?.size > 0;
	const gotPrivateInvestments = privatePortfolioMemo?.size > 0;
	const gotInsuranceInvestments = myCapitalInsuranceInvestmentsMemo?.size > 0;
	const gotCompanyInvestmentsPortfolio = companyInvestmentsPortfolioMemo?.size > 0;
	const gotViaCompanyInvestments = viaCompanyInvestmentsMemo?.size > 0;

	return (
		<ScrollView noLeftMargin={true} autoHide={true} showOnHover={true}>
			<Grid container spacing={2} mb={2}>
				<Grid item lg={!isCompany ? 9 : 12} md={12}>
					<Card borderradius={'true'}>
						<CardContent>
							<Typography variant='body1'>
								{!isCompany
									? t('investments.overview.white_panel.title')
									: t('investments.company.overview.white_panel.title')}
							</Typography>
							<Typography variant='body2'>
								<FormattedMessage
									id={
										!isCompany
											? 'investments.overview.panel.greeting.paragraph'
											: 'investments.company.overview.panel.greeting.paragraph'
									}
									values={{
										link: <InfoModal article={HELPSCOUT_MY_PORTFOLIO_READ_MORE} />
									}}
								/>
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				{!isCompany && (
					<Grid item lg={3} md={12}>
						<Card variant='dark'>
							<CardContent variant='flex'>
								<Box>
									<Typography variant='body1'>
										{t('investments.overview.dark_panel.title')}
									</Typography>
									<Typography variant='body2'>
										{t('investments.overview.dark_panel.paragraph')}
									</Typography>
								</Box>
								<IconButton icon='faPlus' color='white' onClick={openAddPersonalInvestmentModal} />
							</CardContent>
						</Card>
					</Grid>
				)}

				<InvestmentOverviewContext.Provider value={value}>
					{!isCompany && (
						<>
							{gotPrivateInvestments && (
								<Grid item xs={12}>
									<InvestmentsGridContainer
										rowDataMemo={privatePortfolioMemo}
										investmentType={INVESTMENTS_PRIVATE_PORTFOLIO}
										id='privateOverview'
									/>
								</Grid>
							)}

							{gotInsuranceInvestments && (
								<Grid item xs={12}>
									<InvestmentsGridContainer
										rowDataMemo={myCapitalInsuranceInvestmentsMemo}
										investmentType={INVESTMENTS_CAPITAL_INSURANCE}
										id='investmentOverview'
									/>
								</Grid>
							)}
						</>
					)}

					{isCompany && gotCompanyInvestmentsPortfolio && (
						<Grid item xs={12}>
							<InvestmentsGridContainer
								rowDataMemo={companyInvestmentsPortfolioMemo}
								investmentType={INVESTMENTS_COMPANY_INVESTMENTS_PORTFOLIO}
								id='companyInvestmentOverview'
							/>
						</Grid>
					)}

					{gotViaCompanyInvestments &&
						viaCompanyInvestmentsMemo
							.map((investment, id) => {
								return (
									<Grid item xs={12} key={id}>
										<InvestmentsGridContainer
											rowDataMemo={investment}
											investmentType={INVESTMENTS_VIA_COMPANY}
											id={id}
										/>
									</Grid>
								);
							})
							.toList()}
				</InvestmentOverviewContext.Provider>

				<CompanyAdverts forView={ADVERT_SECTIONS.MY_PORTFOLIO}>
					{(adverts, openAdvertModal) => {
						if (!adverts || adverts.length === 0) {
							return null;
						}

						return (
							<Grid item xs={12}>
								<Grid container spacing={2}>
									{adverts.map((advert, index) => (
										<Grid item xs={4} key={index}>
											<AdvertCard advert={advert} openAdvertModal={openAdvertModal} />
										</Grid>
									))}
								</Grid>
							</Grid>
						);
					}}
				</CompanyAdverts>

				<FeatureGroupContextProvider position='my_portfolio'>
					{(features) => {
						return (
							<Grid item lg={12} name='Features Grid-item'>
								<Grid container columns={4} spacing={2}>
									{features.map(({ id, size }) => {
										return (
											<Grid item lg={size / 25} md={size / 12.5} key={id}>
												<FeatureCard id={id} />
											</Grid>
										);
									})}
									<Grid item xs={1}>
										<FeatureCard />
									</Grid>
								</Grid>
							</Grid>
						);
					}}
				</FeatureGroupContextProvider>

				<Grid item lg={12}>
					<Grid container spacing={2}>
						{!gotInvestments && (
							<Grid item lg={6} md={12}>
								<Card borderradius={'true'}>
									<CardHeader
										variant='noBorder'
										title={t('investments.overview.no_investment_panel.title')}
									/>
									<CardContent>
										<Typography gutterBottom variant='body2'>
											{t('investments.overview.no_investment_panel.paragraph1')}
										</Typography>
										<ul style={{ paddingLeft: '18px' }}>
											<li>
												<Typography variant='body2'>
													{t('investments.overview.no_investment_panel.bulletpoint1')}
												</Typography>
											</li>
											<li>
												<Typography variant='body2'>
													<FormattedHTMLMessage id='investments.overview.no_investment_panel.bulletpoint2' />
												</Typography>
											</li>
											<li>
												<Typography gutterBottom variant='body2'>
													{t('investments.overview.no_investment_panel.bulletpoint3')}
												</Typography>
											</li>
										</ul>
										<Typography variant='body2'>
											<FormattedHTMLMessage id='investments.overview.no_investment_panel.paragraph2' />
										</Typography>
									</CardContent>
									<CardActions>
										<Button variant='primary' onClick={openAddCompanyModal}>
											{t('investments.overview.no_investment_panel_button.register')}
										</Button>
										<Button variant='default' onClick={openAddPersonalInvestmentModal}>
											{t('investments.overview.no_investment_panel_button.manually_add')}
										</Button>
									</CardActions>
								</Card>
							</Grid>
						)}

						<Grid item lg={6} md={12}>
							<Card variant='naked'>
								<CardHeader
									variant='noBorder'
									title={t('investments.overview.recommend_panel.title')}
								/>
								<CardContent>
									<Typography gutterBottom component='p' variant='body2'>
										{t('investments.overview.recommend_panel.paragraph')}
									</Typography>
									<ul style={{ paddingLeft: '18px', marginBottom: 0 }}>
										{[...Array(4)].map((_, i) => {
											return (
												<li key={i}>
													<Typography component='p' variant='body2'>
														{t(`investments.overview.recommend_panel.benefit${i}`)}
													</Typography>
												</li>
											);
										})}
									</ul>
								</CardContent>
								<CardActions>
									<Button variant='primary' onClick={openRecommendationModal}>
										{t('investments.overview.recommend_panel_button.recommend')}
									</Button>
								</CardActions>
								<RecommendModal />
							</Card>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{isPrivateModeInvestmentModalOpen && <AddInvestmentModalContainer />}

			{isReadMoreModalOpen && (
				<ReadMoreModal
					title={
						isCompany
							? 'company_owner.investments_overview.white_panel.read_more.title'
							: 'investments.portfolio_overview.white_panel.read_more.title'
					}
					contentFile={isCompany ? 'investment-overview-company-owner-info.txt' : 'portfolio.txt'}
				/>
			)}
		</ScrollView>
	);
};

InvestmentsOverviewContainer.propTypes = {
	isCompany: bool
};

export default InvestmentsOverviewContainer;
