import React, { Component } from 'react'
import { connect } from 'react-redux'
import { object } from 'prop-types'
import { Map } from 'immutable'

import Tooltip from '../../../../../dumb-components/shared/tooltip/tooltip'
import DropdownIconItem from '../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'

import { toggleArchiveMeetingObject } from '../../../../../components/helpers/meeting.helper'
import { updateMeetingLocal, toggleArchiveMeeting } from '../../../../../actions/meetings.actions'

import { MEETING_TYPE_STANDARD } from '/shared/constants'

class ArchiveDropdownItemContainer extends Component {
	static propTypes = {
		dropdownRef: object
	}

	getArchiveTid = () => {
		const { meeting, isMeeting, isStandardMeeting } = this.props
		const isArchived = meeting.get('archived')

		// If meeting is of typestandard meeting, always return TID with meeting instead of protocol
		if (isStandardMeeting) {
			if (isArchived) {
				return 'meeting.general.toolbar.option.undo_archive_meeting'
			}

			return 'meeting.general.toolbar.option.archive_meeting'
		}

		// Other meeting types
		if (isArchived) {
			return isMeeting
				? 'meeting.general.toolbar.option.undo_archive_meeting'
				: 'meeting.general.toolbar.option.undo_archive_protocol'
		}

		return isMeeting
			? 'meeting.general.toolbar.option.archive_meeting'
			: 'meeting.general.toolbar.option.archive_protocol'
	}

	onToggleArchive = () => {
		const { updateMeetingLocal, toggleArchiveMeeting, dropdownRef } = this.props
		let { meeting } = this.props

		dropdownRef && dropdownRef.onToggleMenu()

		meeting = toggleArchiveMeetingObject(meeting)

		updateMeetingLocal(meeting)
		toggleArchiveMeeting(meeting)
	}

	render = () => {
		const { hasExtendedRights, meetingIsDeleted } = this.props

		return (
			<Tooltip activeState='hasNoPermissions' active={!hasExtendedRights}>
				<DropdownIconItem
					onClick={this.onToggleArchive}
					disabled={!hasExtendedRights || meetingIsDeleted}
					icon='faArchive'
					tid={this.getArchiveTid()}
				/>
			</Tooltip>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get('meeting', Map()) || Map(),
		isMeeting: store.meetings.getIn(['meeting', 'computedValues', 'goToSection'], '').startsWith('meeting'),
		isStandardMeeting: store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_STANDARD,
		hasExtendedRights: store.meetings.getIn(['meeting', 'computedValues', 'hasExtendedRights'], false),
		meetingIsDeleted: store.meetings.getIn(['meeting', 'isDeleted'])
	}
}

const mapActionsToProps = {
	updateMeetingLocal,
	toggleArchiveMeeting
}

export default connect(mapStoreToProps, mapActionsToProps)(ArchiveDropdownItemContainer)
