import React from 'react'
import { string, number } from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

const StretchdelayAnim = keyframes`
	0%, 40%, 100% {
		transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1.0);
	}
`

const StyledRectTemplate = css`
	background-color: ${(props) => props.theme.colors[props.color]};
	height: 100%;
	width: ${(props) => props.barWidth};
	display: inline-block;
	animation: ${StretchdelayAnim} 1.2s infinite ease-in-out;
	margin: 0 1px;
`

const StyledRect1 = styled.div`
	${StyledRectTemplate}
`

const StyledRect2 = styled.div`
	${StyledRectTemplate}
	animation-delay: -1.1s;
`

const StyledRect3 = styled.div`
	${StyledRectTemplate}
	animation-delay: -1.0s;
`

const StyledRect4 = styled.div`
	${StyledRectTemplate}
	animation-delay: -0.9s;
`

const StyledRect5 = styled.div`
	${StyledRectTemplate}
	animation-delay: -0.8s;
`

const StyledLoader = styled.div`
	margin: 0 auto;
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	text-align: center;
	font-size: 10px;
`

const Loader = ({ width, height, barWidth, color }) => {
	return (
		<StyledLoader width={width} height={height}>
			<StyledRect1 barWidth={barWidth} color={color} />
			<StyledRect2 barWidth={barWidth} color={color} />
			<StyledRect3 barWidth={barWidth} color={color} />
			<StyledRect4 barWidth={barWidth} color={color} />
			<StyledRect5 barWidth={barWidth} color={color} />
		</StyledLoader>
	)
}

Loader.propTypes = {
	width: string,
	height: string,
	barWidth: string,
	color: string
}

Loader.defaultProps = {
	width: '70px',
	height: '50px',
	barWidth: '6px',
	color: 'midGrey'
}

export default Loader
