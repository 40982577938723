// Data fields used in credentials views
export const NAME_FIELD = 'name'
export const SSN_FIELD = 'ssn'
export const EMAIL_FIELD = 'email'
export const TOS_FIELD = 'tos'
export const PASSWORD_FIELD = 'password'
export const PASSWORD_VERIFY_FIELD = 'passwordVerify'
export const CODE_FIELD = 'code'
export const REMEMBER_DETAILS_FIELD = 'rememberDetails'
export const PHONE_FIELD = 'phone'
