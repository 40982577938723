import React, { PureComponent } from 'react'
import { string, object, func, array, bool } from 'prop-types'
import styled from 'styled-components'
import Grid from 'styled-components-grid'
import { Padding } from 'styled-components-spacing'
import Panel from '../../shared/panel/panel'
import Label from '../../shared/label/label'
import InputAutosuggest from '../../shared/input/input-autosuggest'
import Datepicker from '../../shared/datepicker/datepicker'
import ModernFilePreview from '../../shared/modern-file-preview/modern-file-preview'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import { Select } from '../../shared/select'
import Text from '../../shared/text/text'
import i18nhelper from '../../../components/helpers/i18n.helper'
import { fromJS } from 'immutable'
import v1 from 'uuid'

const StyledFilePreview = styled(ModernFilePreview)`
	background: ${({ theme }) => theme.colors.java};
	padding: ${({ theme }) => theme.spacing[4]};
	border-radius: ${({ theme }) => theme.spacing[3]};
`

class DocumentDetailsInformation extends PureComponent {
	static propTypes = {
		document: object,
		onChange: func.isRequired,
		jumpToDate: string,
		disabledFolderField: bool,
		userLang: string,
		readOnly: bool,
		isSelected: bool,
		documentNameSuggestions: array,
		onToggleMenu: func,
		isFilePreviewDropdownOpen: bool,
		isDeleted: bool
	}

	static defaultProps = {
		disabledFolderField: false,
		readOnly: false
	}

	render = () => {
		const {
			document,
			documentNameSuggestions,
			onChange,
			onOpenFile,
			isLoading,
			onDownloadFile,
			renderTagsSelectContainer,
			userLang,
			readOnly,
			isSelected,
			onToggleMenu,
			isFilePreviewDropdownOpen,
			isDeleted
		} = this.props
		const panelRightComponent = isDeleted && <Text tid='generic.is_deleted' color='red' tag='em' />

		return (
			<Panel
				title={'documents.general.panel.title'}
				marginBottom={true}
				id={v1()}
				ref={(node) => (this._panel = node)}
				active={isSelected}
				rightComponent={() => panelRightComponent}>
				<Padding all={3}>
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid={'generic.name'}>
									<InputAutosuggest
										fieldName='title'
										isLoading={isLoading}
										disabled={readOnly}
										onChange={onChange}
										suggestions={documentNameSuggestions}
										placeholderTid='documents.details.title.placeholder'
										value={(document && document.get('title', '')) || ''}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
					<Grid>
						<Grid.Unit size={{ xs: 1, lg: 1 / 3 }}>
							<Padding all={3}>
								<Label tid={'company.documents.valid_from'}>
									<Datepicker
										isLoading={isLoading}
										fieldName='validFrom'
										onChange={onChange}
										value={document && document.get('validFrom')}
										maxDate={document && document.get('validTo')}
										disabled={readOnly}
										language={userLang}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
						<Grid.Unit size={{ xs: 1, lg: 1 / 3 }}>
							<Padding all={3}>
								<Label tid={'company.documents.renewal_date'}>
									<Datepicker
										isLoading={isLoading}
										fieldName='validTo'
										onChange={onChange}
										value={document && document.get('validTo')}
										minDate={document && document.get('validFrom')}
										disabled={readOnly}
										language={userLang}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
						<Grid.Unit size={{ xs: 1, lg: 1 / 3 }}>
							<Padding all={3}>
								<Label
									tid={'documents.details.input.reminder'}
									rightComponent={() => {
										if (!readOnly) {
											return (
												<ToggleSwitch
													checked={document && document.get('reminderSet')}
													onChange={(val) => onChange && onChange('reminderSet', val)}
													removeWrapperHeight={true}
													size='sm'
												/>
											)
										}
									}}>
									<Datepicker
										isLoading={isLoading}
										muted={document && document.get('reminderSet') ? false : true}
										fieldName='reminderDate'
										onChange={onChange}
										value={document && document.get('reminderDate')}
										minDate={document && document.get('validFrom')}
										maxDate={document && document.get('validTo')}
										disabled={readOnly}
										calendarPlacement='bottom-end'
										language={userLang}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
					{renderTagsSelectContainer && (
						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid={'generic.tags'}>{renderTagsSelectContainer && renderTagsSelectContainer()}</Label>
								</Padding>
							</Grid.Unit>
						</Grid>
					)}
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='documents.document.details.label.orginal_file'>
									<StyledFilePreview document={document} onDownloadFile={onDownloadFile} onOpenFile={onOpenFile} />
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
				</Padding>
			</Panel>
		)
	}
}

export default DocumentDetailsInformation
