import { fromJS } from 'immutable'
import req from '../../modules/request.module'
import { USER_CACHE_ADD_USERS_TO_CACHE } from './types'

/**
 * Action for fetching users
 */
export function fetchSimpleUsers(userIds) {
	return function (dispatch, getState) {
		const cache = getState().usersCache.get('usersCache')
		userIds = userIds.filter((id) => {
			return id && !cache.has(id)
		})

		if (userIds.size > 0) {
			req.post(`/users/public/users`, userIds.toJS()).then((response) => {
				dispatch({ type: USER_CACHE_ADD_USERS_TO_CACHE, payload: fromJS(response.data) })
			})
		}
	}
}
