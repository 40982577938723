import React, { PureComponent } from 'react'
import { string, bool, oneOf } from 'prop-types'
import styled from 'styled-components'

import Text from '../../shared/text/text'
import { OBJ_TYPE_MEETING, OBJ_TYPE_DOCUMENT } from '/shared/constants'

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const StyledChildrenWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[4]};
`

const WhiteText = (textProps) => <Text color='white' {...textProps} />
const OptionalText = (textProps) => <Text color='lightGrey' {...textProps} />

const DOCUMENT_INFORMATION = [
	{ tid: 'esign.request_esign.meeting.information.title' },
	{ tid: 'esign.request_esign.document.information.info_1', optional: true },
	{ tid: 'esign.request_esign.document.information.info_2', optional: true },
	{ tid: 'esign.request_esign.document.information.info_3' }
]

const MEETING_INFORMATION = [
	{ tid: 'esign.request_esign.meeting.information.title' },
	{ tid: 'esign.request_esign.meeting.information.info_1', optional: true },
	{ tid: 'esign.request_esign.meeting.information.info_2', optional: true },
	{ tid: 'esign.request_esign.meeting.information.info_3', optional: true },
	{ tid: 'esign.request_esign.meeting.information.info_4' }
]

export default class DocumentSignPanelStepsInformation extends PureComponent {
	static propTypes = {
		env: oneOf([OBJ_TYPE_MEETING, OBJ_TYPE_DOCUMENT])
	}

	getStepValuesTextComponent = (number, optional) => {
		const values = {
			step: <WhiteText tid='esign.request_esign.information.step' bold={600} values={{ number }} />
		}

		if (optional) {
			values.optional = <OptionalText tid='esign.request_esign.information.optional' />
		}

		return values
	}

	renderInformation = (instructionObject) => {
		return instructionObject.map((data, index) => {
			if (index === 0) {
				return <WhiteText tid={data.tid} bold={600} marginBottom={4} key={index} />
			}

			return <WhiteText tid={data.tid} values={this.getStepValuesTextComponent(index, data.optional)} key={index} />
		})
	}

	renderDocumentInformation = () => {
		return this.renderInformation(DOCUMENT_INFORMATION)
	}

	renderMeetingInformation = () => {
		return this.renderInformation(MEETING_INFORMATION)
	}

	render = () => {
		const { children, env } = this.props

		if (!env) {
			return null
		}

		return (
			<StyledWrapper>
				{env === OBJ_TYPE_MEETING && this.renderMeetingInformation()}
				{env === OBJ_TYPE_DOCUMENT && this.renderDocumentInformation()}

				<StyledChildrenWrapper>{children}</StyledChildrenWrapper>
			</StyledWrapper>
		)
	}
}
