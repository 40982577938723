import axios from 'axios'
import Cookies from 'universal-cookie'
import apiError from '../../errors/api.error'

export default {
	async getSubscriptionSlipDisplayData(companyId, emissionId, token, currentLang) {
		try {
			const res = await axios.get(
				`/api/gateway/subscription-slip/companies/${companyId}/emission/${emissionId}?token=${token}&lang=${currentLang}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			)

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async getInsuranceOptions(companyId, emissionId, token) {
		try {
			const res = await axios.get(
				`/api/gateway/subscription-slip/companies/${companyId}/emission/${emissionId}/insurance-options?token=${token}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			)

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async getDepositoryOptions(companyId, emissionId, token) {
		try {
			const res = await axios.get(
				`/api/gateway/subscription-slip/companies/${companyId}/emission/${emissionId}/depository-options?token=${token}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			)

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async getSubmission(companyId, emissionId, token) {
		try {
			const res = await axios.get(
				`/api/gateway/subscription-slip/companies/${companyId}/emission/${emissionId}/submission?token=${token}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			)

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	},

	async updateSubmission(companyId, emissionId, token, submission, lang) {
		try {
			const res = await axios.put(
				`/api/gateway/subscription-slip/companies/${companyId}/emission/${emissionId}/submission?token=${token}&lang=${lang}`,
				submission,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			)

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	}
}
