import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromJS, Map } from 'immutable'

import DropdownMenuContainer from '../shared/dropdown-menu.container'
import DropdownItem from '../../dumb-components/shared/dropdown-item/dropdown-item'
import Text from '../../dumb-components/shared/text/text'
import LanguageSwitcherMenu from '../../dumb-components/navbar/language-switcher-menu/language-switcher-menu'
import { saveUser } from '../../actions/user.actions'
import i18nHelper from '../../components/helpers/i18n.helper'

const LANGUAGE_OPTIONS = fromJS([
	{ title: 'language_switcher.language.swedish', value: 'sv-SE' },
	{ title: 'language_switcher.langauge.english', value: 'en-GB' }
])

class LanguageSwitcherMenuContainer extends Component {
	onChangeLanguage = (value) => {
		const { saveUser } = this.props
		let { user } = this.props

		this.dropdownRef && this.dropdownRef.onToggleMenu()

		if (!user.get('siteSettings')) {
			user = user.set('siteSettings', Map({ language: value }))
		} else {
			user = user.setIn(['siteSettings', 'language'], value)
		}

		saveUser('me', user.toJS())
	}

	setDropdownRef = (ref) => {
		this.dropdownRef = ref
	}

	renderMenuLabel = () => {
		const { userLanguage } = this.props
		const browserLanguage = i18nHelper.getBrowserLanguage()

		return <LanguageSwitcherMenu language={userLanguage || browserLanguage} />
	}

	renderDropdownItem = (item, index) => {
		const { userLanguage } = this.props
		const browserLanguage = i18nHelper.getBrowserLanguage()
		const itemValue = item.get('value')

		if (userLanguage === itemValue) {
			return null
		} else if (!userLanguage && browserLanguage === itemValue) {
			return null
		}

		return (
			<DropdownItem
				key={index}
				rightComponent={<Text tid={item.get('title')}></Text>}
				onClick={this.onChangeLanguage.bind(null, item.get('value'))}
			/>
		)
	}

	render = () => {
		return (
			<DropdownMenuContainer halignMenu='right' ref={this.setDropdownRef} renderRaw={this.renderMenuLabel()}>
				{LANGUAGE_OPTIONS.map(this.renderDropdownItem)}
			</DropdownMenuContainer>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		user: store.user.get('userObj'),
		userLanguage: store.user.getIn(['userObj', 'siteSettings', 'language'])
	}
}

const mapActionsToProps = {
	saveUser
}

export default connect(mapStoreToProps, mapActionsToProps)(LanguageSwitcherMenuContainer)
