import React, { useRef } from 'react'
import { Box, useTheme } from '@mui/material'
import Icon from '../../../../dumb-components/shared/icon/icon'
import TickHeaderTooltip from './TickHeaderTooltip'

//todo StyledToolTip, translation, tic on tooltip

export default (props) => {
	const refButton = useRef(null)
	const theme = useTheme()
	let showTickOnTooltip = false

	const getTooltipTitle = () => {
		if (props.isRegisteredInvonoCompany && !props.showInPortfolio) {
			showTickOnTooltip = true
			return {
				id: 'investments.ticheader.tooltip.title.verified',
				values: { companyName: props.companyName }
			}
		} else if (link?.get('transactionId') && !link?.get('isDirty')) {
			showTickOnTooltip = true
			return 'investments.ticheader.tooltip.title.individual_transaction_verified'
		} else {
			return {
				id: 'investments.ticheader.tooltip.title.not_verified',
				values: { companyName: props.companyName }
			}
		}
	}

	const onMenuClicked = () => {
		props.showColumnMenu(refButton.current)
	}

	let menu = null
	if (props.enableMenu) {
		menu = (
			<div ref={refButton} className='customHeaderMenuButton' onClick={() => onMenuClicked()}>
				<i className={'ag-icon ag-icon-menu'}></i>
			</div>
		)
	}

	const link = props.transactionLink
	const isRegisteredInvonoCompany = props.isRegisteredInvonoCompany
	const showInPortfolio = props.showInPortfolio

	return (
		<>
			<Box sx={{ cursor: 'pointer' }}>{menu}</Box>
			<TickHeaderTooltip
				title={getTooltipTitle()}
				hideTooltip={false}
				placement='bottom'
				showTic={showTickOnTooltip}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						overflow: 'hidden',
						height: '100%',
						width: '100%',
						justifyContent: 'end',
						...(!props.isNotMovable && { cursor: 'grab' }),
						alignItems: 'center'
					}}>
					{link && link.get('transactionId') && !link.get('isDirty') && (
						<Box sx={{ pr: 1 }}>
							<Icon icon={'faCheck'} size='sml' color={theme.palette.success.main} />
						</Box>
					)}
					{isRegisteredInvonoCompany && !showInPortfolio && (
						<Box sx={{ pr: 1 }}>
							<Icon icon={'faCheck'} size='sml' color={theme.palette.success.main} />
						</Box>
					)}
					<Box
						sx={{
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
							color: theme.palette.text.primary
						}}
						className='customHeaderLabel'>
						{props.displayName}
					</Box>
				</Box>
			</TickHeaderTooltip>
		</>
	)
}
