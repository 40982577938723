import React, { Component } from 'react'
import { connect } from 'react-redux'
import immutablePropTypes from 'react-immutable-proptypes'
import { string, array, func, bool, oneOfType } from 'prop-types'
import { List, Map, fromJS } from 'immutable'
import { fetchCompanyUsers } from '../../actions/company.actions'
import { fetchRoles } from '../../actions/user-management.actions'
import { Select } from '../../dumb-components/shared/select'
import PinableSelectOption from '../../dumb-components/shared/object-filter/helper-components/pinable-select-option'

import { formatCompanyUsersAndRolesAsOptions } from '../../components/helpers/company.helper'

import {
	EVENT_TYPE_COMPANY_MEMBER_ADDED,
	EVENT_TYPE_COMPANY_MEMBER_UPDATE,
	EVENT_TYPE_COMPANY_MEMBER_REMOVE
} from '/shared/constants'

class CompanyMembersAndTeamsSelectContainer extends Component {
	static propTypes = {
		fieldName: string,
		onChange: func,
		value: string,
		readOnly: bool,
		placeholderTid: string,
		isClearable: bool,
		includeNoneOption: bool,
		noneOptionValue: string,
		prependOptions: immutablePropTypes.list,
		defaultValue: string,
		noneOptionLabelTid: string,
		excludeRoles: immutablePropTypes.list,
		formatOptionLabel: func
	}

	static defaultProps = {
		excludeRoles: List(['aed45ce5-291f-438f-a47a-f547fdecc5ee'])
	}

	state = {
		options: List()
	}

	componentDidMount = () => {
		const { fetchCompanyUsers, fetchRoles, company, companyMembers, roles } = this.props

		if ((!companyMembers || companyMembers.size === 0) && company && company.id) {
			fetchCompanyUsers(company.id)
		}

		if ((!roles || roles.length === 0) && company && company.id) {
			fetchRoles()
		}

		if ((companyMembers && companyMembers.size) || (roles && roles.size)) {
			this.parseCompanyUsersAndRoles()
		}
	}

	componentDidUpdate = (prevProps) => {
		const { roles, companyMembers } = this.props

		if (prevProps.companyMembers !== companyMembers) {
			this.parseCompanyUsersAndRoles()
		}

		if (prevProps.roles !== roles) {
			this.parseCompanyUsersAndRoles()
		}
	}

	checkLiveUpdateEvents = () => {
		const { auditCompany, fetchCompanyUsers, company } = this.props
		const companyMemberAdded = auditCompany.get(EVENT_TYPE_COMPANY_MEMBER_ADDED)
		const companyMemberUpdate = auditCompany.get(EVENT_TYPE_COMPANY_MEMBER_UPDATE)
		const companyMemberRemove = auditCompany.get(EVENT_TYPE_COMPANY_MEMBER_REMOVE)

		// Company member was added, updated or deleted so we update the list
		if (
			(companyMemberAdded && companyMemberAdded.get('refresh') === true) ||
			(companyMemberUpdate && companyMemberUpdate.get('refresh') === true) ||
			(companyMemberRemove && companyMemberRemove.get('refresh') === true)
		) {
			fetchCompanyUsers(company.id)
		}
	}

	parseCompanyUsersAndRoles = () => {
		const { companyMembers, roles, excludeRoles } = this.props
		const options = formatCompanyUsersAndRolesAsOptions({ companyMembers, roles, excludeRoles })

		this.setState({ options })
	}

	onChange = (val) => {
		const { fieldName, onChange } = this.props

		if (fieldName) {
			return onChange(fieldName, val)
		}

		return onChange(val)
	}

	renderFormatOptionLabel = (option) => {
		const { defaultValue } = this.props

		return <PinableSelectOption defaultValue={defaultValue} option={option} />
	}

	render = () => {
		const {
			placeholderTid,
			value,
			isClearable,
			includeNoneOption,
			noneOptionValue,
			prependOptions,
			defaultValue,
			noneOptionLabelTid,
			i18n,
			formatOptionLabel
		} = this.props
		let { options } = this.state

		if (prependOptions) {
			options = prependOptions.concat(options)
		}
		if (includeNoneOption) {
			options = options.unshift(
				Map({
					value: noneOptionValue,
					label: i18n.messages[noneOptionLabelTid]
				})
			)
		}

		const props = {
			placeholderTid,
			onChange: this.onChange,
			options,
			value,
			isClearable,
			formatOptionLabel
		}

		if (defaultValue) {
			props.formatOptionLabel = this.renderFormatOptionLabel
		}

		return <Select {...props} />
	}
}

const mapStoreToProps = (store) => {
	return {
		companyMembers: store.company.members,
		company: store.company.company,
		roles: store.company.userManagement.roles,
		auditCompany: store.audit.get('company'),
		i18n: store.i18n
	}
}

const mapActionsToProps = {
	fetchCompanyUsers,
	fetchRoles
}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyMembersAndTeamsSelectContainer)
