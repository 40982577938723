import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, oneOf, string, bool } from 'prop-types'
import ImmutableProps from 'react-immutable-proptypes'
import { List, Map, Set } from 'immutable'
import { fetchSimpleUsers } from '../../actions/usersCache.actions'
import { fetchUsersInRolesCache, fetchRolesCache } from '../../actions/user-management.actions'
import permissionsHelper from '../../components/helpers/permissions.helper'
import { Select } from '../../dumb-components/shared/select'
import { listInvestorsUnsafe } from '../../actions/investors.actions'
import investmentHelper from '../../components/helpers/investment.helper'

class AssigneeContainer extends Component {
	state = {
		assigneeOptions: List()
	}

	static propTypes = {
		fieldName: string,
		onChange: func,
		value: string,
		readOnly: bool,
		permissions: ImmutableProps.map,
		isRoot: bool,
		includeRoles: bool,
		prependOptions: ImmutableProps.list,
		excludeOptions: ImmutableProps.list,
		placeholderTid: string,
		usersToMerge: ImmutableProps.map
	}

	static defaultProps = {
		placeholderTid: 'component.assignee.container.placeholder'
	}

	componentDidMount = () => {
		const { permissions, value, usersCache, usersToMerge, fetchSimpleUsers, listInvestorsUnsafe } = this.props
		this.fetchUserNames(permissions)
		this.fetchUsersInRolesCache(permissions)

		if (value && !usersCache.has(value)) {
			fetchSimpleUsers(List([value]))
		}

		if (usersToMerge && usersToMerge.size > 0) {
			const companyMembers = usersToMerge.filter((user) => !user.get('isInvestor') && !user.get('isGuest'))

			companyMembers && companyMembers.size > 0 && fetchSimpleUsers(companyMembers.keySeq().toList())
		}

		listInvestorsUnsafe()

		this.parseAssigneesOptions()
	}

	componentDidUpdate = (prevProps) => {
		const { permissions, value, usersCache, usersInRoleCache, usersToMerge, fetchSimpleUsers } = this.props

		if (prevProps.permissions !== permissions) {
			this.fetchUserNames(permissions)
			this.fetchRolesName(permissions)
			this.fetchUsersInRolesCache(permissions)
			this.parseAssigneesOptions()
		}

		if (!usersCache.has(value) || prevProps.value !== value) {
			if (value) {
				this.props.fetchSimpleUsers(List([value]))
			}
		}

		if (prevProps.usersCache !== usersCache) {
			this.parseAssigneesOptions()
		}

		if (prevProps.usersInRoleCache !== usersInRoleCache) {
			this.parseAssigneesOptions()
		}

		if (usersToMerge && usersToMerge.size > 0 && prevProps.usersToMerge !== usersToMerge) {
			const companyMembers = usersToMerge.filter((user) => user.get('isCompanyMember'))

			companyMembers && companyMembers.size > 0 && fetchSimpleUsers(companyMembers.keySeq().toList())
		}
	}

	parseAssigneesOptions = () => {
		this.setState({ assigneeOptions: this.getOptions() })
	}

	fetchUserNames = (permissions) => {
		permissions && this.props.fetchSimpleUsers(permissions.getIn(['users', 'usersWithAccess'], Map()).keySeq().toList())
	}

	fetchUsersInRolesCache = (permissions) => {
		permissions &&
			this.props.fetchUsersInRolesCache(permissions.getIn(['roles', 'rolesWithAccess'], Map()).keySeq().toList())
	}

	fetchRolesName = (permissions) => {
		permissions && this.props.fetchRolesCache(permissions.getIn(['roles', 'rolesWithAccess'], Map()).keySeq().toList())
	}

	getOptions = () => {
		const {
			permissions,
			usersCache,
			usersInRoleCache,
			isRoot,
			value,
			includeRoles,
			excludeOptions,
			usersToMerge,
			investors
		} = this.props
		let values

		if (!usersCache || !usersInRoleCache || !permissions) {
			return List()
		}

		if (isRoot) {
			// This is true in tasks created in root
			values = Set.fromKeys(permissions.getIn(['users', 'usersWithAccess'], Map())).toList()
		} else {
			values = permissionsHelper.parseUsersValues(permissions, usersInRoleCache, usersCache)
		}

		// if (value && !usersCache.has(value)) {
		// 	values = values.push(value)
		// 	this.props.fetchSimpleUsers(List([value]));
		// }

		// When an task is assigned from Documents to an user that doesn't have 'permission'
		// to view content of that folder, he/she won't be shown in the assignee select list
		// as it fetches only the users that have permission to that folder. This is what
		// code below fixes.
		// START ---
		if (value && !values.includes(value)) {
			values = values.push(value)
		}
		// --- END

		// Merge values and usersToMerge
		if (usersToMerge && usersToMerge.size > 0) {
			usersToMerge.forEach((user, userId) => {
				if (!values.includes(userId)) {
					values = values.push(userId)
				}
			})
		}

		values = values.map((id) => {
			return Map({ id, isUser: true })
		})

		if (includeRoles) {
			let roleValues = permissionsHelper.parseRoleValues(permissions)

			roleValues = roleValues.map((id) => {
				return Map({ id, isRole: true })
			})

			values = values && values.size ? values.concat(roleValues) : roleValues
		}

		values = values.map((obj) => {
			const userToMerge = usersToMerge && usersToMerge.get(obj.get('id'))

			if (!userToMerge) {
				return Map({
					value: obj.get('id'),
					label: usersCache.getIn([obj.get('id'), 'name'])
				})
			}

			if (userToMerge.get('isInvestor')) {
				const investor = investors && investors.find((inv) => inv.get('id') === obj.get('id'))

				if (investor) {
					return Map({
						value: obj.get('id'),
						label: investmentHelper.getInvestorName(investor)
					})
				}
			} else if (userToMerge.get('isGuest')) {
				return Map({
					value: obj.get('id'),
					label: userToMerge.get('name')
				})
			} else {
				return Map({
					value: obj.get('id'),
					label: usersCache.getIn([obj.get('id'), 'name'])
				})
			}
		})

		if (!values) {
			return List()
		}

		// Exclude options
		if (excludeOptions) {
			values = values.filter((obj) => !excludeOptions.includes(obj.get('value')))
		}

		return values.sort((a, b) => {
			if (!a || !b || !a.get('label')) {
				return 0
			}

			return a.get('label').localeCompare(b.get('label'))
		})
	}

	render = () => {
		const {
			value,
			readOnly,
			prependOptions,
			includeNoneOption,
			noneOptionValue,
			noneOptionLabelTid,
			i18n,
			...selectProps
		} = this.props
		let { assigneeOptions } = this.state

		if (prependOptions) {
			assigneeOptions = prependOptions.concat(assigneeOptions)
		}

		if (includeNoneOption) {
			assigneeOptions = assigneeOptions.unshift(
				Map({
					value: noneOptionValue,
					label: i18n.messages[noneOptionLabelTid]
				})
			)
		}

		return <Select {...selectProps} value={value} options={assigneeOptions} isDisabled={readOnly} isClearable />
	}
}

function mapStoreToProps(store) {
	return {
		usersCache: store.usersCache.get('usersCache', Map()),
		usersInRoleCache: store.company.userManagement.usersInRoleCache,
		rolesCache: store.company.userManagement.rolesCache,
		i18n: store.i18n,
		investors: store.investors.get('list')
	}
}

const mapActionsToProps = {
	fetchSimpleUsers,
	fetchUsersInRolesCache,
	fetchRolesCache,
	listInvestorsUnsafe
}

export default connect(mapStoreToProps, mapActionsToProps)(AssigneeContainer)
