import React, { PureComponent } from 'react'
import { func, string, bool } from 'prop-types'
import countries from 'localized-countries'
import swedishCountryNames from 'localized-countries/data/sv.json'
import englishCountryNames from 'localized-countries/data/en.json'
import { Select } from '../select'
import { fromJS } from 'immutable'

class CountrySelect extends PureComponent {
	static propTypes = {
		language: string,
		fieldName: string,
		value: string,
		onChange: func,
		labelProp: string,
		valueProp: string,
		disabled: bool,
		placeholderTid: string,
		legacyDisabledColor: bool,
		withPortal: bool
	}

	static defaultProps = {
		language: 'en',
		labelProp: 'label',
		valueProp: 'code',
		disabled: false,
		placeholderTid: 'country.placeholder'
	}

	getOptions = () => {
		const { language, labelProp, valueProp } = this.props

		let countryNames = englishCountryNames

		if (language && language.substr(0, 2).toLowerCase() === 'sv') {
			countryNames = swedishCountryNames
		}

		const countryOptions = countries(countryNames)
			.array()
			.map((country) => {
				return {
					value: country[valueProp],
					label: country[labelProp]
				}
			})

		return countryOptions
	}

	render = () => {
		const {
			value,
			fieldName,
			onChange,
			error,
			disabled,
			placeholderTid,
			legacyDisabledColor,
			isClearable,
			withPortal
		} = this.props
		const countryOptions = this.getOptions()

		return (
			<Select
				value={value}
				options={fromJS(countryOptions)}
				fieldName={fieldName}
				onChange={onChange}
				error={error}
				isDisabled={disabled}
				isClearable={isClearable}
				placeholderTid={placeholderTid}
				legacyDisabledColor={legacyDisabledColor}
				menuPortalTarget={withPortal ? document.body : undefined}
			/>
		)
	}
}

export default CountrySelect
