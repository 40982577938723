/**
 *
 * @param {string} orgNumber
 * @param {array} companiesInDealflow
 * @returns {boolean}
 */
export function checkIfCompanyExistsInDealflow(orgNumber, companiesInDealflow) {
	if (!orgNumber) {
		return false
	}

	if (!companiesInDealflow || companiesInDealflow.length === 0) {
		return false
	}

	const companyExistsInDealflow = companiesInDealflow.some((company) => company.orgNumber === orgNumber)
	return companyExistsInDealflow
}

/**
 *
 * @param {string} orgNumber
 * @param {array} companiesInDealflow
 * @returns {object}
 */
export function getCompanyInDealflowByOrgNumber(orgNumber, companiesInDealflow) {
	if (!orgNumber) {
		return false
	}

	if (!companiesInDealflow || companiesInDealflow.length === 0) {
		return false
	}

	const companyInDealflow = companiesInDealflow.find((company) => company.orgNumber === orgNumber)
	return companyInDealflow
}
