import React, { Component } from 'react'
import { connect } from 'react-redux'
import { oneOf, func, bool } from 'prop-types'
import { Map, List } from 'immutable'

import SignUpBankId from '../../dumb-components/credentials/middle-components/sign-up-bankid'
import SignUpEmail from '../../dumb-components/credentials/middle-components/sign-up-email'

import { authBankId, resetCredentials, resetSsn } from '../../actions/credentials.actions'

import {
	validatePersonNumber,
	validateEmail,
	validatePassword,
	validateAuthCode
} from '../../modules/validation.module'
import { showErrorTooltip, hideErrorTooltip } from '../../dumb-components/shared/tooltip/tooltip.helpers'

import {
	NAME_FIELD,
	SSN_FIELD,
	EMAIL_FIELD,
	TOS_FIELD,
	PASSWORD_FIELD,
	PASSWORD_VERIFY_FIELD,
	CODE_FIELD,
	PHONE_FIELD
} from '../../constants/credentials'

const AUTH_MODE = 'SIGNUP'

class SignUpContainer extends Component {
	state = {
		formData: Map(),
		hasError: Map(),
		actionBtnLoading: false,
		ssnFieldDisabled: false
	}

	static propTypes = {
		authMode: oneOf([0, 1]),
		onChange: func,
		referredBySignin: bool
	}

	componentDidMount = () => {
		const { referredBySignin, ssn, resetSsn, authMode } = this.props

		if (authMode === 1 && referredBySignin === true && ssn) {
			this.onChange(SSN_FIELD, ssn)
			this.setState(() => {
				return { ssnFieldDisabled: true }
			})
			resetSsn()
		}

		document.title = 'INVONO One™ | Registrera med BankID'
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { authMode, resetCredentials, referredBySignin, ssn, resetSsn } = this.props

		if (prevProps.authMode !== authMode) {
			resetCredentials()
			this.resetState()
		}

		if (!prevState.formData.has(SSN_FIELD) && referredBySignin === true && ssn) {
			this.onChange(SSN_FIELD, ssn)
			resetSsn()
		}
	}

	resetState = (resetAll) => {
		let { formData } = this.state

		if (resetAll === true) {
			formData = Map()
		} else {
			formData = formData.set(TOS_FIELD, false)
			formData = formData.set(PASSWORD_FIELD, '')
			formData = formData.set(PASSWORD_VERIFY_FIELD, '')
		}

		this.setState({
			formData,
			hasError: Map(),
			actionBtnLoading: false,
			ssnFieldDisabled: false
		})
	}

	onChange = (fieldName, value) => {
		const { onChange } = this.props
		let { formData } = this.state

		if (fieldName === CODE_FIELD) {
			value = value.trim()
		}

		formData = formData.set(fieldName, value)
		this.onHideTooltip(fieldName)
		this.setState({ formData })

		onChange && onChange(fieldName, value)
	}

	onSignUpBankId = () => {
		const { formData } = this.state
		const { authBankId, sessionId } = this.props
		if (this.validateSignUpBankId()) {
			this.setState({ actionBtnLoading: true })

			// Action to send verification code somewhere here.
			authBankId(AUTH_MODE, sessionId, 'SIGNUP-BANKID', formData, () => {
				this.setState({ actionBtnLoading: false })
			})
		}
	}

	onSignUpEmail = () => {
		const { formData } = this.state
		const { authBankId, sessionId } = this.props
		if (this.validateSignUpEmail()) {
			this.setState({ actionBtnLoading: true })

			// Action to send verification code somewhere here.
			authBankId(AUTH_MODE, sessionId, 'SIGNUP-BASIC', formData, () => {
				this.setState({ actionBtnLoading: false })
			})
		}
	}

	validateSignUpBankId = () => {
		const { formData } = this.state
		let hasError = Map()
		let isValid = true

		// Validate SSN
		if (!validatePersonNumber(formData.get(SSN_FIELD))) {
			hasError = hasError.set(SSN_FIELD, List(['credentials.validation.ssn']))
			showErrorTooltip(this, SSN_FIELD)
			isValid = false
		}

		// Validate Email
		if (!validateEmail(formData.get(EMAIL_FIELD))) {
			hasError = hasError.set(EMAIL_FIELD, List(['credentials.validation.email']))
			showErrorTooltip(this, EMAIL_FIELD)
			isValid = false
		}

		// Validate ToS
		if (!formData.get(TOS_FIELD)) {
			hasError = hasError.set(TOS_FIELD, List(['credentials.validation.tos']))
			showErrorTooltip(this, TOS_FIELD)
			isValid = false
		}

		this.setState({ hasError })

		return isValid
	}

	validateSignUpEmail = () => {
		const { formData } = this.state
		let hasError = Map()
		let isValid = true

		// Validate Name
		if (formData.get(NAME_FIELD, '').length === 0) {
			hasError = hasError.set(NAME_FIELD, List(['credentials.validation.name']))
			showErrorTooltip(this, NAME_FIELD)
			isValid = false
		}

		// Validate Email
		if (!validateEmail(formData.get(EMAIL_FIELD))) {
			hasError = hasError.set(EMAIL_FIELD, List(['credentials.validation.email']))
			showErrorTooltip(this, EMAIL_FIELD)
			isValid = false
		}

		// Validate password Regex
		if (!validatePassword(formData.get(PASSWORD_FIELD))) {
			hasError = hasError.set(PASSWORD_FIELD, List(['credentials.validation.password']))
			showErrorTooltip(this, PASSWORD_FIELD)
			isValid = false
			// Validate Password Match
		} else if (formData.get(PASSWORD_FIELD) !== formData.get(PASSWORD_VERIFY_FIELD)) {
			hasError = hasError.set(PASSWORD_VERIFY_FIELD, List(['credentials.validation.password_not_equal']))
			showErrorTooltip(this, PASSWORD_VERIFY_FIELD)
			isValid = false
		}

		// Validate ToS
		if (!formData.get(TOS_FIELD)) {
			hasError = hasError.set(TOS_FIELD, List(['credentials.validation.tos']))
			showErrorTooltip(this, TOS_FIELD)
			isValid = false
		}

		this.setState({ hasError })

		return isValid
	}

	validateCode = () => {
		const { formData } = this.state
		let hasError = Map()
		let isValid = true
		const codeValue = formData.get(CODE_FIELD)

		// Validate Code
		if (!validateAuthCode(codeValue)) {
			hasError = hasError.set(CODE_FIELD, List(['credentials.validation.code']))
			showErrorTooltip(this, CODE_FIELD)
			isValid = false
		}

		this.setState({ hasError })

		return isValid
	}

	onHideTooltip = (ref) => {
		let { hasError } = this.state
		// If there's no target, fieldname was sent directly
		const fieldName = ref.target ? ref.target.getAttribute('name') : ref
		hasError = hasError.remove(fieldName)
		this.setState({ hasError })
		hideErrorTooltip(this, fieldName)
	}

	onSetTooltipRefs = (refName, ref) => {
		if (ref && refName) {
			this[refName] = ref
		}
	}

	onClickVerify = () => {
		const { formData } = this.state
		const { authBankId, sessionId, authMode } = this.props

		const strategy = authMode === 0 ? 'SIGNUP-BASIC' : 'SIGNUP-BANKID'
		if (this.validateCode()) {
			this.setState({ actionBtnLoading: true })

			authBankId(AUTH_MODE, sessionId, strategy, formData, (response) => {
				this.setState({ actionBtnLoading: false })

				// Conflic / User Exists
				if (response.httpStatus === 409) {
					this.resetState(true) // Reset everything
				}
			})
		}
	}

	onCancelVerify = () => {
		this.props.resetCredentials()
		this.resetState(true)
	}

	onOpenTos = () => {
		const { i18n } = this.props
		const url = i18n.language.startsWith('sv')
			? 'https://invono.se/om-oss/anvandarvillkor-och-kundavtal/anvandarvillkor-portalen/#ankarejuridik'
			: 'https://invono.se/om-oss/anvandarvillkor-och-kundavtal/terms-of-use-invono-one/'
		window.open(url, '_blank')
	}

	render = () => {
		const { formData, hasError, actionBtnLoading, ssnFieldDisabled } = this.state
		const { authMode, step, translationId, referredBySignin } = this.props

		if (authMode === 0) {
			return (
				<SignUpEmail
					onClickSignUp={this.onSignUpEmail}
					onSetTooltipRefs={this.onSetTooltipRefs}
					onChange={this.onChange}
					isVerifyMode={step > 0}
					onClickVerify={this.onClickVerify}
					name={formData && formData.get(NAME_FIELD)}
					email={formData && formData.get(EMAIL_FIELD)}
					password={formData && formData.get(PASSWORD_FIELD)}
					passwordVerify={formData && formData.get(PASSWORD_VERIFY_FIELD)}
					tos={formData && formData.get(TOS_FIELD)}
					code={formData && formData.get(CODE_FIELD)}
					phone={formData && formData.get(PHONE_FIELD)}
					onHideTooltip={this.onHideTooltip}
					translationId={translationId}
					onOpenTos={this.onOpenTos}
					actionBtnLoading={actionBtnLoading}
					onClickCancel={this.onCancelVerify}
					hasError={hasError}
				/>
			)
		}

		if (authMode === 1) {
			return (
				<SignUpBankId
					onClickSignUp={this.onSignUpBankId}
					onSetTooltipRefs={this.onSetTooltipRefs}
					onChange={this.onChange}
					onClickVerify={this.onClickVerify}
					isVerifyMode={step > 0}
					ssn={formData && formData.get(SSN_FIELD)}
					email={formData && formData.get(EMAIL_FIELD)}
					tos={formData && formData.get(TOS_FIELD)}
					code={formData && formData.get(CODE_FIELD)}
					phone={formData && formData.get(PHONE_FIELD)}
					onHideTooltip={this.onHideTooltip}
					hasError={hasError}
					translationId={translationId}
					renderBankIdInfoContainer={this.renderBankIdInfoContainer}
					onOpenTos={this.onOpenTos}
					actionBtnLoading={actionBtnLoading}
					onClickCancel={this.onCancelVerify}
					ssnFieldDisabled={ssnFieldDisabled}
					referredBySignin={referredBySignin}
				/>
			)
		}
	}
}

function mapStoreToProps(store) {
	return {
		step: store.credentials.get('step'),
		translationId: store.credentials.get('translationId'),
		sessionId: store.credentials.get('sessionId'),
		ssn: store.credentials.get('ssn'),
		i18n: store.i18n
	}
}

const mapActionsToProps = {
	authBankId,
	resetCredentials,
	resetSsn
}

export default connect(mapStoreToProps, mapActionsToProps)(SignUpContainer)
