import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool, func } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import { Map, List, fromJS } from 'immutable'
import debounce from 'lodash/debounce'
import {
	listExternalTasks,
	clearTasksList,
	createTaskLocal,
	fetchLatestOrderIndex,
	createExternalTask,
	fetchTask,
	saveExternalTask,
	updateTaskLocal,
	reorderTask,
	updateTaskListLocal,
	saveTask,
	deleteExternalTask,
	deleteTask
} from '../../actions/tasks.actions'
import { fetchSimpleUsers } from '../../actions/usersCache.actions'
import TaskPlugin from '../../dumb-components/tasks/task-plugin/task-plugin'
import TaskList from '../../dumb-components/tasks/task-list/task-list'
import TaskModalContainer from './task-create-edit-modal.container'
import TaskCreatorContainer from './task-creator.container'
import TasksFilterAndSortContainer from './tasks-filter-and-sort.container'
import { getOrderIndex } from '../../components/helpers/tasks.helper'

import {
	LIVE_TASK_EXTERNAL_CREATE,
	LIVE_TASK_EXTERNAL_UPDATE,
	LIVE_TASK_EXTERNAL_DELETE
} from '../../constants/live-update'

class TaskPluginContainer extends Component {
	static propTypes = {
		objType: string,
		objId: string,
		marginBottom: bool,
		readOnly: bool,
		legacyPanel: bool,
		onCreateTask: func,
		onDeleteTask: func
	}

	static defaultProps = {
		marginBottom: false
	}

	state = {
		isModalOpen: false,
		taskMode: null,
		taskId: null,
		taskInEditMode: null,
		tasksMetadata: Map()
	}

	componentDidMount = () => {
		const { objId, listExternalTasks, updateTaskListLocal } = this.props

		updateTaskListLocal(List())
		listExternalTasks(objId)
		this.fetchUsers()
		this.parseMetadata()
	}

	componentDidUpdate = (prevProps) => {
		const { objId, listExternalTasks, tasks, updateTaskListLocal } = this.props

		this.checkLiveUpdateEvents(prevProps)

		if (prevProps.objId !== objId) {
			updateTaskListLocal(List())
			listExternalTasks(objId)
			this.fetchUsers()
			this.parseMetadata()
		}

		if (prevProps.usersCache !== this.props.usersCache) {
			this.parseMetadata()
		}

		if (prevProps.tasks !== tasks) {
			this.fetchUsers()
			this.parseMetadata()
		}
	}

	componentWillUnmount = () => {
		const { clearTasksList } = this.props

		clearTasksList()
	}

	checkLiveUpdateEvents = () => {
		const { audit, listExternalTasks } = this.props
		const taskExtCreate = audit.get(LIVE_TASK_EXTERNAL_CREATE)
		const taskExtUpdate = audit.get(LIVE_TASK_EXTERNAL_UPDATE)
		const taskExtDelete = audit.get(LIVE_TASK_EXTERNAL_DELETE)
		const componentObjId = this.props.objId
		const componentObjType = this.props.objType

		// Task was created, update list
		if (taskExtCreate && taskExtCreate.get('refresh') === true) {
			const objIdInMetadata = taskExtCreate.getIn(['metadata', 'objId'])
			const objTypeInMetadata = taskExtCreate.getIn(['metadata', 'objType'])

			if (componentObjId === objIdInMetadata && componentObjType === objTypeInMetadata) {
				listExternalTasks(componentObjId)
			}
		}

		// Task was edited, update list
		if (taskExtUpdate && taskExtUpdate.get('refresh') === true) {
			const objIdInMetadata = taskExtUpdate.getIn(['metadata', 'objId'])

			if (componentObjId === objIdInMetadata) {
				listExternalTasks(componentObjId)
			}
		}

		// Task was deleted, update list
		if (taskExtDelete && taskExtDelete.get('refresh') === true) {
			const objIdInMetadata = taskExtDelete.getIn(['metadata', 'objId'])

			if (componentObjId === objIdInMetadata) {
				listExternalTasks(componentObjId)
			}
		}
	}

	parseMetadata() {
		const { tasks, projects, usersCache } = this.props
		let tasksMetadata = Map()
		if (tasks) {
			tasks.forEach((task) => {
				const metadata = {}
				const assignedTo = task.get('assigne')
				const taskId = task.get('id')
				const createdBy = task.get('createdBy')

				const user = usersCache.get(assignedTo)
				if (assignedTo && user) {
					metadata.profileImage = user.getIn(['image', 'filename'])
					metadata.assignedToUserId = assignedTo
				}

				const creator = usersCache.get(createdBy)
				if (creator) {
					metadata.creator = creator.get('name')
				}

				tasksMetadata = tasksMetadata.set(taskId, fromJS(metadata))
			})
			this.setState({ tasksMetadata })
		}
	}

	fetchUsers = () => {
		const { tasks, fetchSimpleUsers } = this.props
		let userIds = List()

		tasks.forEach((task) => {
			const assignedTo = task.get('assigne')
			const createdBy = task.get('createdBy')

			if (assignedTo) {
				userIds = userIds.push(assignedTo)
			}

			if (createdBy) {
				userIds = userIds.push(assignedTo)
			}
		})
		fetchSimpleUsers(userIds)
	}

	doDebounce = debounce((task) => {
		const { saveExternalTask, objId } = this.props
		saveExternalTask(objId, task)
	}, 1000)

	_createTaskLocal = (orderIndex, task) => {
		const { createTaskLocal, createExternalTask, objType, objId } = this.props
		createTaskLocal(orderIndex, task, (newTask) => {
			this.onTaskClick(newTask.get('id'))
			createExternalTask(objType, objId, newTask, () => {
				this.editTask(newTask.get('id'))
			})
		})
	}

	createTask = (insertAtIndex, title) => {
		const { projectId, fetchLatestOrderIndex, onCreateTask, task, tasks } = this.props
		let newTask = Map({ title, projectId, orderIndex: insertAtIndex, description: '' })

		this.timeout && clearTimeout(this.timeout)

		const orderIndex = getOrderIndex(task, tasks, true)

		newTask = newTask.set('orderIndex', orderIndex)
		this._createTaskLocal(orderIndex, newTask)

		onCreateTask && onCreateTask(newTask)
	}

	editTask = (taskId) => {
		this.setState({ isModalOpen: true, taskMode: 'edit', taskId })
	}

	updateTaskLocal = (task) => {
		const { updateTaskLocal } = this.props
		updateTaskLocal(task)
		this.doDebounce(task)
	}

	resetTaskInEditMode = () => {
		this.timeout = setTimeout(() => {
			this.setState({ taskInEditMode: null })
		}, 500)
	}

	onDelete = (taskId) => {
		const { deleteExternalTask, objId, onDeleteTask } = this.props

		deleteExternalTask(objId, taskId)
	}

	onChange = (field, value) => {
		let { task } = this.props

		if (!field || !task) {
			return
		}

		task = task.set(field, value)

		this.updateTaskLocal(task)
	}

	onTaskClick = (taskId) => {
		const { tasks, updateTaskLocal } = this.props
		const clickedTask = tasks && tasks.find((t) => t.get('id') === taskId)

		updateTaskLocal(clickedTask)
		this.setState({ taskInEditMode: taskId })
	}

	onCloseModal = () => {
		const { objId, listExternalTasks } = this.props

		listExternalTasks(objId)
		this.setState({ isModalOpen: false, taskMode: null, taskId: null })
	}

	onNav = (index, direction) => {
		const { tasks } = this.props
		const newIndex = index + direction
		const task = tasks.get(newIndex)

		if (task) {
			this.onTaskClick(task.get('id'))
		}
	}

	onDragEnd = (result) => {
		const { reorderTask, tasks, objId } = this.props
		const { draggableId, destination } = result

		this.onTaskClick(draggableId)
		const sourceTaskId = draggableId

		const destinationTaskId = tasks.getIn([destination.index, 'id'])
		reorderTask(tasks, sourceTaskId, destinationTaskId, objId)
	}

	showTaskCreator = (index) => {
		const { tasks } = this.props

		const thisOrder = tasks.getIn([index, 'orderIndex'])
		let nextOrder = null
		if (index + 1 >= tasks.size) {
			this.createTask(null)
		} else {
			nextOrder = tasks.getIn([index + 1, 'orderIndex'])
			this.createTask((thisOrder + nextOrder) / 2)
		}
	}

	renderTaskEditor = (projectId, title, index, badge) => {
		return (
			<TaskCreatorContainer
				fieldName='title'
				value={title}
				onEnter={this.showTaskCreator.bind(this, index)}
				onArrowUp={this.onNav.bind(this, index)}
				onArrowDown={this.onNav.bind(this, index)}
				onChange={this.onChange}
				onFocus={() => {
					this.timeout && clearTimeout(this.timeout)
				}}
				onBlur={this.resetTaskInEditMode}
				badge={badge}
				noBadgeSpace={true}
				inline
				autofocus
			/>
		)
	}

	renderList = () => {
		const { usersCache, readOnly, tasks } = this.props
		const { taskInEditMode, tasksMetadata } = this.state

		return (
			<TaskList
				tasks={tasks}
				tasksName={'plugin'}
				pluginMode
				onClickAddNewTask={this.createTask}
				onTaskEditBtnClick={this.editTask}
				onTaskClick={this.onTaskClick}
				tasksMetadata={tasksMetadata}
				taskEditorComponent={this.renderTaskEditor}
				taskInEditMode={taskInEditMode}
				readOnly={readOnly}
				showTaskEditButton={false}
				showNewTaskButton={false}
				onDragEnd={this.onDragEnd}
				showMoreAction
				onDeleteTask={this.onDelete}
			/>
		)
	}

	renderModal = () => {
		const { objId, objType, company, onDeleteTask } = this.props
		const { isModalOpen, taskMode, taskId } = this.state

		return (
			<TaskModalContainer
				isOpen={isModalOpen}
				onClose={this.onCloseModal}
				taskId={taskMode === 'edit' && taskId ? taskId : null}
				taskMode={taskMode}
				mode='external'
				objId={objId}
				objType={objType}
				company={company}
				onDeleteTask={onDeleteTask}
			/>
		)
	}

	/**
	 * Do not use <TasksFilterAndSortContainer/> here because it may result in the tasks not showing at all
	 */
	render = () => {
		const { marginBottom, legacyPanel } = this.props

		return (
			<TaskPlugin
				renderListContainer={this.renderList}
				renderModalContainer={this.renderModal}
				marginBottom={marginBottom}
				legacyPanel={legacyPanel}
				onClickAddNewTask={this.createTask}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		task: store.tasks.get('task'),
		tasks: store.tasks.get('allTasks') || List(),
		usersCache: store.usersCache.get('usersCache') || Map(),
		audit: store.audit.get('tasks'),
		company: store.company.company
	}
}

const mapActionsToProps = {
	listExternalTasks,
	clearTasksList,
	createTaskLocal,
	createExternalTask,
	fetchSimpleUsers,
	fetchTask,
	saveExternalTask,
	updateTaskLocal,
	reorderTask,
	updateTaskListLocal,
	saveTask,
	fetchLatestOrderIndex,
	deleteExternalTask,
	deleteTask
}

export default connect(mapStoreToProps, mapActionsToProps)(TaskPluginContainer)
