import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateUserLocation } from '../../actions/notifications.actions'

export default function (ComposedComponent, routeName) {
	class LocationTracker extends Component {
		componentDidMount = () => {
			const { userLocation, updateUserLocation } = this.props

			if (!userLocation && routeName === undefined) {
				return
			}

			if (routeName) {
				updateUserLocation(routeName)
			} else {
				updateUserLocation('')
			}
		}

		render = () => {
			return <ComposedComponent {...this.props} />
		}
	}

	function mapStateToProps(store) {
		return {
			userLocation: store.notifications.get('userLocation')
		}
	}

	const mapActionsToProps = {
		updateUserLocation
	}

	return connect(mapStateToProps, mapActionsToProps)(LocationTracker)
}
