import React, { PureComponent } from 'react'
import { string, func, bool, oneOf } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import styled from 'styled-components'
import Grid from 'styled-components-grid'
import { Padding } from 'styled-components-spacing'
import Panel from '../../shared/panel/panel'
import Label from '../../shared/label/label'
import DatePicker from '../../shared/datepicker/datepicker'
import { Select } from '../../shared/select'
import TextArea from '../../shared/input/textarea'
import LocationSuggest from '../../shared/location-suggest/location-suggest'
import ProtocolInlinePreview from '../protocol-inline-preview/protocol-inline-preview'
import Moment from '../../../modules/moment.module'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import Text from '../../shared/text/text'
import Input from '../../shared/input/input'
import { MEETING_DEFAULT_TEMPLATE_ID } from '/shared/constants'

const StyledOption = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
`

const StyledOptionLabel = styled.div`
	display: flex;
	flex: 1;
	margin-right: ${(props) => props.theme.spacing[2]};
`

class MeetingsGeneralInformation extends PureComponent {
	static propTypes = {
		onChange: func,
		renderMeetingPreviewDropdown: func,
		renderTagsContainer: func,
		onOpenMeeting: func,
		onOpenProtocolReview: func,
		onOpenProtocol: func,
		meeting: immutablePropTypes.map,
		userId: string,
		readOnly: bool,
		templateOptions: immutablePropTypes.list,
		renderMode: oneOf(['summary', 'full', 'nextMeeting']),
		useForNextMeeting: bool,
		templateLocked: bool,
		renderEditorContainer: func,
		group: immutablePropTypes.map,
		userLang: string,
		meetingIsDeleted: bool,
		isDeleted: bool
	}

	static defaultProps = {
		readOnly: false,
		useForNextMeeting: false,
		renderMode: 'summary'
	}

	renderTemplateOptionLabel = (option) => {
		return (
			<StyledOption>
				<StyledOptionLabel>
					<Text color={option.isDisabled ? 'muted' : undefined}>{option.label}</Text>
				</StyledOptionLabel>
			</StyledOption>
		)
	}

	renderGeneralInformation = () => {
		const {
			onChange,
			meeting,
			userId,
			renderMeetingPreviewDropdown,
			renderTagsContainer,
			readOnly,
			onOpenMeeting,
			onOpenProtocolReview,
			onOpenProtocol,
			renderMode,
			useForNextMeeting,
			templateLocked,
			renderEditorContainer,
			userLang,
			meetingIsDeleted,
			isDeleted
		} = this.props
		let { templateOptions } = this.props
		const hasExtendedRights = meeting ? meeting.getIn(['computedValues', 'hasExtendedRights']) : false

		if (renderMode !== 'summary') {
			templateOptions = templateOptions.filter((option) => {
				return option.get('value') !== 'CREATE_NEW_TEMPLATE' && option.get('value') !== 'LEGACY'
			})
		}

		const meetingTypeLabel = 'meetings.general.label.template'
		const minTime =
			meeting && meeting.get('startDate') && Moment(meeting.get('startDate')).add(1, 'minutes').toISOString()

		return (
			<Padding all={3}>
				{renderMode === 'nextMeeting' && (
					<Grid>
						<Padding all={3}>
							<Padding bottom={3}>
								<ToggleSwitch
									fieldName='useForNextMeeting'
									onChange={onChange}
									checked={useForNextMeeting}
									disabled={readOnly}
								/>
							</Padding>
							<Text tid='meetings.details.next_meeting_switch.information' />
						</Padding>
					</Grid>
				)}

				<Grid>
					<Grid.Unit size={{ xs: 1, lg: 1, xl: 1 }}>
						<Padding all={3}>
							<Label tid={meetingTypeLabel}>
								<Select
									isDisabled={readOnly || templateLocked}
									fieldName='templateId'
									onChange={onChange}
									options={templateOptions}
									placeholderTid='meeting.general.placeholder.template'
									value={meeting && meeting.get('templateId')}
									formatOptionLabel={this.renderTemplateOptionLabel}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>
				<Grid>
					<Grid.Unit size={{ xs: 1, lg: 1, xl: 1 / 3 }}>
						<Padding all={3}>
							<Label tid='meetings.general.label.reference'>
								<Input
									fieldName='reference'
									disabled={readOnly}
									onChange={onChange}
									value={meeting && meeting.get('reference')}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
					<Grid.Unit size={{ xs: 1, lg: 1, xl: 1 / 3 }}>
						<Padding all={3}>
							<Label tid='meetings.general.label.date'>
								<DatePicker
									disabled={readOnly}
									fieldName='startDate'
									onChange={onChange}
									showTimeSelect={true}
									calendarPlacement='bottom-end'
									value={meeting && meeting.get('startDate') && Moment(meeting.get('startDate'))}
									language={userLang}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
					<Grid.Unit size={{ xs: 1, lg: 1, xl: 1 / 3 }}>
						<Padding all={3}>
							<Label tid='meetings.general.label.end'>
								<DatePicker
									disabled={readOnly}
									fieldName='endDate'
									onChange={onChange}
									showTimeSelectOnly={true}
									minTime={minTime}
									value={meeting && meeting.get('endDate') && Moment(meeting.get('endDate'))}
									language={userLang}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>

				<Grid>
					<Grid.Unit size={1}>
						<Padding all={3}>
							<Label tid='meetings.general.label.meeting_name'>
								<TextArea
									disabled={readOnly}
									fieldName='name'
									onChange={onChange}
									placeholderTid='meeting.general.placeholder.meeting_name'
									value={meeting && meeting.get('name')}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>

				<Grid>
					<Grid.Unit size={1}>
						<Padding all={3}>
							<Label tid='meetings.general.label.meeting_objective'>
								<TextArea
									disabled={readOnly}
									fieldName='objective'
									onChange={onChange}
									placeholderTid='meeting.general.placeholder.objective'
									value={meeting && meeting.get('objective')}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>

				{renderMode === 'full' && renderTagsContainer && (
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='generic.tags'>{renderTagsContainer()}</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
				)}

				{renderMode === 'nextMeeting' && (
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='meetings.general.label.location'>
									<LocationSuggest
										disabled={readOnly}
										fieldName='location'
										onChange={onChange}
										placeholderTid='meetings.details.location.placeholder'
										value={meeting && meeting.getIn(['location', 'label'], '')}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
				)}

				{renderMode === 'summary' && (
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<ProtocolInlinePreview
									meeting={meeting}
									userId={userId}
									onOpenMeeting={onOpenMeeting}
									onOpenProtocol={onOpenProtocol}
									onOpenProtocolReview={onOpenProtocolReview}
									renderDropdownContainer={renderMeetingPreviewDropdown}
									meetingIsDeleted={isDeleted}
								/>
							</Padding>
						</Grid.Unit>
					</Grid>
				)}

				{hasExtendedRights && (renderMode === 'nextMeeting' || renderMode === 'full') && (
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='meetings.general.label.additional_notes'>
									{renderEditorContainer && renderEditorContainer('notes')}
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
				)}
			</Padding>
		)
	}

	render = () => {
		const { isDeleted } = this.props
		const panelRightComponent = isDeleted && <Text tid='generic.is_deleted' color='red' tag='em' />
		return (
			<Panel rightComponent={() => panelRightComponent} title='meetings.general.panel.title' marginBottom={true}>
				{this.renderGeneralInformation()}
			</Panel>
		)
	}
}

export default MeetingsGeneralInformation
