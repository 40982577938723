import styled, { css } from 'styled-components'

/**
 * !! WARNING / TODO !!
 * vertical-align should be removed after removing all of the old buttons.
 * It creates unnecessary 1-2px margin above every button.
 * I've added it FOR NOW to match the other buttons in the system.
 */

export const StyledButtonTemplate = styled.button.attrs({ type: 'button' })`
	border: 1px solid transparent;
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	padding: ${(props) => `${props.theme.spacing[2]} 12px`};
	height: 36px;
	min-width: ${(props) => props.minWidth || '36px'};
	position: relative;
	transition: all 0.25s;
	vertical-align: middle;
	display: ${(props) => (props.block ? 'block' : 'inline-block')};
	width: ${(props) => (props.block ? '100%' : 'auto')};
	border-radius: 0;
	background-image: none;
	text-align: center;
	margin-bottom: 0;
	white-space: nowrap;
	touch-action: manipulation;
	user-select: none;
	-webkit-appearance: button;
	overflow: visible;

	${(props) =>
		props.noHorizontalPadding &&
		css`
			padding: ${props.theme.spacing[2] + ' 0'};
		`}

	${(props) =>
		props.maxWidth &&
		css`
			max-width: ${(props) => props.maxWidth};
		`};

	:active {
		box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
	}

	:focus {
		outline: 0 !important;
	}

	:disabled {
		filter: alpha(opacity=65);
		-webkit-box-shadow: none;
		box-shadow: none;
		opacity: 0.35;
	}

	& + button {
		margin-left: ${(props) => props.theme.spacing[2]};
	}
`
