import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DropDownContext } from '../../../../../mui-components/dropdown/DropDown'
import MenuItemIcon from '../../../../../mui-components/dropdown/menu/menu-item-icon'
import { TAB_TEAMS_AND_SHAREHOLDES } from '../../../select-user-modal/select-user-modal.constants'
import { RolesFilterModal } from '../../filters/RolesFilterModal'
import { usePeopleModalContext } from '../../people-modal.context'

export const TeamsFilterMenuItem = () => {
	const { activeTab, filters } = usePeopleModalContext()
	const { popupState } = useContext(DropDownContext)
	const [isOpen, setIsOpen] = useState()
	const dispatch = useDispatch()
	const teamsAndShareholderTabIsActive = activeTab === TAB_TEAMS_AND_SHAREHOLDES

	const openModal = () => {
		setIsOpen(true)
	}

	const closeModal = () => {
		setIsOpen(false)
		popupState.toggle()
	}

	if (!teamsAndShareholderTabIsActive) {
		return null
	}

	const filterActive = typeof filters !== 'undefined' && Object.keys(filters).length > 0

	return (
		<>
			<MenuItemIcon
				icon='faUserFriends'
				listItemTid='ag_grid.panel.dropdown_menu.filter.item.filter_by_team'
				onClick={openModal}
				notificationBadge={filterActive}
				notificationBadgeAnimate={false}
				notificationBadgeColor={'green'}
			/>
			{isOpen && <RolesFilterModal onClose={closeModal} />}
		</>
	)
}
