import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	resetTids,
	resetForgotPassword,
	resetCredentials,
	setBankIDText,
	cancelBankId
} from '../../actions/credentials.actions'
import Bowser from 'bowser'

import CredentialsComponent from '../../dumb-components/credentials/credentials-component'
import TopComponent from '../../dumb-components/credentials/top-component'
import BottomComponent from '../../dumb-components/credentials/bottom-component'
import BankIdModal from '../../dumb-components/shared/bankid-modal/bankid-modal'

import SignInContainer from './signin.container'
import SignUpContainer from './signup.container'
import ForgotPasswordContainer from './forgot-password.container'
import history, { getQuery } from '../../interfaces/history'

const browser = Bowser.getParser(window.navigator.userAgent)

const IS_VALID_BROWSER = browser.satisfies({
	chrome: '>=71',
	firefox: '>=64',
	edge: '>=16',
	safari: '>=12'
})

class CredentialsContainer extends Component {
	state = {
		authMode: 1, // 0 = Username / 1 = BankID
		section: null,
		ignoreBrowserVersion: false,
		isCancelled: false
	}

	componentDidMount = () => {
		const query = getQuery()
		this.setSectionName()
		this.setCorrectAuthmode()
		this.checkAuthenticated(this.props.isAuthenticated)

		if (query.type === 'basic') {
			this.setState({ authMode: 0 })
		}
	}

	componentDidUpdate = (prevProps) => {
		const {
			location: { pathname },
			isAuthenticated,
			resetCredentials
		} = this.props
		const query = getQuery()

		if (isAuthenticated !== prevProps.isAuthenticated) {
			this.checkAuthenticated(isAuthenticated)
		}

		if (pathname !== prevProps.location.pathname) {
			const fromForgotPassword = prevProps.location.pathname.startsWith('/forgot-password')
			const refferedBySignin = query['ref'] && query['ref'] === 'signin'

			this.setSectionName()
			this.setCorrectAuthmode()
			this.resetCredentialTidsIfNeeded(fromForgotPassword)

			if (!fromForgotPassword && !refferedBySignin) {
				resetCredentials()
			}
		}
	}

	resetCredentialTidsIfNeeded = (fromForgotPassword) => {
		const { translationId, bankIdMessage } = this.props

		if ((translationId || bankIdMessage) && !fromForgotPassword) {
			this.props.resetTids()
		}

		// Reset forgot password credentials in store
		if (fromForgotPassword) {
			this.props.resetForgotPassword()
		}
	}

	checkAuthenticated = (isAuthenticated) => {
		if (isAuthenticated) {
			this.props.history.push('/')
		}
	}

	setSectionName = () => {
		const {
			location: { pathname }
		} = this.props
		let section

		if (pathname.startsWith('/signin')) {
			section = 'SIGNIN'
		} else if (pathname.startsWith('/signup')) {
			section = 'SIGNUP'
		} else if (pathname.startsWith('/forgot-password')) {
			section = 'FORGOTPW'
		}

		this.setState({ section })
	}

	setCorrectAuthmode = () => {
		const {
			location: { pathname }
		} = this.props
		const { authMode } = this.state

		// If pathname was changed from Login to Register, always select BankID
		if (pathname.startsWith('/signup') && authMode !== 1) {
			this.onChangeAuthMode(1)
		}

		// If user is in forgot password, always select 'Username' as authmode
		if (pathname.startsWith('/forgot-password') && authMode !== 0) {
			this.onChangeAuthMode(0)
		}
	}

	onChangeAuthMode = (val) => {
		this.setState({ authMode: val })
		this.resetCredentialTidsIfNeeded()
	}

	onChange = (fieldName, value) => {
		this.resetCredentialTidsIfNeeded()
	}

	onBottomBtnSwitchSection = () => {
		const { section } = this.state

		if (section === 'SIGNIN') {
			this.props.history.push('/signup')
		} else if (section === 'SIGNUP') {
			this.props.history.push('/signin')
		} else if (section === 'FORGOTPW') {
			this.props.history.push('/signup')
		}
	}

	onIgnoreBrowserVersion = (location) => {
		this.setState({ ignoreBrowserVersion: true })
		this.props.history.push(location)
	}

	goToForgotPassword = () => {
		this.props.history.push('/forgot-password')
		this.onChangeAuthMode(0)
	}

	onCancelForgotPassword = () => {
		this.props.history.push('/signin')
		this.onChangeAuthMode(1)
	}

	onCancelBandId = () => {
		const { setBankIDText, resetCredentials, orderRef, cancelBankId } = this.props

		if (orderRef) {
			cancelBankId(orderRef)
		} else {
			setBankIDText(null)
			resetCredentials()
		}
	}

	renderTopComponent = (section) => {
		const { authMode } = this.state

		return <TopComponent onChange={this.onChangeAuthMode} authMode={authMode} section={section} />
	}

	renderMiddleComponent = (section) => {
		const { authMode } = this.state
		const query = getQuery()

		if (section === 'SIGNIN') {
			return (
				<SignInContainer
					authMode={authMode}
					section={section}
					onChangeAuthMode={this.onChangeAuthMode}
					onChange={this.onChange}
					onForgotPassword={this.goToForgotPassword}
				/>
			)
		} else if (section === 'SIGNUP') {
			return (
				<SignUpContainer
					authMode={authMode}
					onChange={this.onChange}
					section={section}
					referredBySignin={query['ref'] && query['ref'] === 'signin'}
				/>
			)
		} else if (section === 'FORGOTPW') {
			return <ForgotPasswordContainer onCancel={this.onCancelForgotPassword} onChange={this.onChange} />
		}
	}

	renderBottomComponent = (section) => {
		return <BottomComponent section={section} onClick={this.onBottomBtnSwitchSection} />
	}

	render = () => {
		const { bankIdMessage } = this.props
		const { authMode, section, ignoreBrowserVersion } = this.state

		return (
			<>
				<CredentialsComponent
					isValidBrowser={IS_VALID_BROWSER}
					ignoreBrowserVersion={ignoreBrowserVersion}
					onIgnoreBrowserVersion={this.onIgnoreBrowserVersion}
					authMode={authMode}
					section={section}
					renderTopComponent={this.renderTopComponent}
					renderMiddleComponent={this.renderMiddleComponent}
					renderBottomComponent={this.renderBottomComponent}
				/>

				<BankIdModal
					isOpen={bankIdMessage ? true : false}
					bankIdTransaltionId={bankIdMessage}
					onCancel={this.onCancelBandId}
				/>
			</>
		)
	}
}

function mapStoreToProps(store) {
	return {
		history: history,
		isAuthenticated: store.credentials.get('authenticated'),
		translationId: store.credentials.get('translationId'),
		bankIdMessage: store.credentials.get('bankIdMessage'),
		orderRef: store.credentials.get('orderRef')
	}
}

const mapActionsToProps = {
	resetTids,
	resetForgotPassword,
	resetCredentials,
	setBankIDText,
	cancelBankId
}

export default connect(mapStoreToProps, mapActionsToProps)(CredentialsContainer)
