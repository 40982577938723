import { SET_LOCALE } from './types';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import sv from 'react-intl/locale-data/sv';
import translations from '../i18n/translations.json';
import { Settings } from 'luxon';
import { fromJS } from 'immutable';
import i18n from '../i18n';
import Moment from '../modules/moment.module';
import { HELPSCOUT_BEACON_SWEDISH, HELPSCOUT_BEACON_ENGLISH } from '/shared/constants';

export const setLanguage = (language) => {
	addLocaleData([...en, ...sv]);
	let locale = language || (navigator.languages && navigator.languages[0]) || navigator.language;

	locale = (locale ? locale : 'sv').toLowerCase();

	if (locale === 'en-us' || locale === 'en') {
		locale = 'en-GB';
	}

	if (locale === 'sv' || locale === 'sv-se') {
		locale = 'sv-SE';
	}

	const data = {
		en: {},
		sv: {}
	};

	const msg = fromJS(translations);

	msg.forEach((langs, id) => {
		langs.forEach((m, lang) => {
			data[lang][id] = m;
		});
	});

	const languageWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0];

	// Needed for react-i18next
	i18n.changeLanguage(locale);

	// Change Moment locale
	Moment.locale(locale);
	Settings.defaultLocale = locale;

	// Change Helpscout Beacon
	if (typeof Beacon !== 'undefined') {
		if (typeof Beacon('info') !== 'undefined') {
			Beacon('destroy');
		}
		Beacon('init', languageWithoutRegionCode === 'sv' ? HELPSCOUT_BEACON_SWEDISH : HELPSCOUT_BEACON_ENGLISH);
	}

	return {
		type: SET_LOCALE,
		payload: {
			messages: data[languageWithoutRegionCode] || data[language] || data.en,
			language: locale
		}
	};
};
