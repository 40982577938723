import { fromJS } from 'immutable'
import req from '../modules/request.module'
import { addInfoNotification, addErrorNotification } from './notify.actions'
import {
	DEALFLOW_ADD_COMPANY,
	DEALFLOW_LIST,
	DEALFLOW_CREATE_USER_CRITERIA,
	DEALFLOW_DELETE_USER_CRITERIA,
	DEALFLOW_LIST_USER_CRITERIA_LISTS,
	DEALFLOW_UPDATE_STATUS_USER_CRITERIA,
	DEALFLOW_UPDATE_USER_CRITERIA,
	DEALFLOW_REMOVE_COMPANY,
	DEALFLOW_COMPANY_CRITERIA,
	DEALFLOW_COMPANY_CHANGE_DISPLAY_STATUS,
	DEALFLOW_COMPANY_LIST_DEALFLOW_FOLLOWERS
} from './types'

/**
 *
 * @param {string} companyId
 */
export function addCompanyToDealflow(orgNumber, callback) {
	return async function (dispatch) {
		const response = await req.post(`/shares/dealflow/`, { orgNumber })
		dispatch({ type: DEALFLOW_ADD_COMPANY, payload: response.data })
		dispatch(addInfoNotification({ tid: 'dealflow.recommend_invono.notifications.company_added_to_dealflow' }))
		callback?.()
	}
}

export function removeCompanyFromDealflow(dealflowId, callback) {
	return async function (dispatch) {
		await req.delete(`/shares/dealflow/${dealflowId}`)
		dispatch({ type: DEALFLOW_REMOVE_COMPANY, payload: dealflowId })
		dispatch(addInfoNotification({ tid: 'dealflow.recommend_invono.notifications.company_unfollowed' }))
		callback?.()
	}
}

export function listDealflow(includeOptedOut = false) {
	return async function (dispatch) {
		const query = new URLSearchParams({ includeOptedOut }).toString()
		const response = await req.get(`/shares/dealflow/?${query}`)
		dispatch({ type: DEALFLOW_LIST, payload: response.data })
	}
}

export function createUserDealflow(dealflowUserCriteria, callback) {
	return async function (dispatch) {
		const response = await req.post(`/shares/dealflow/user/criteria/`, {
			dealflowUserCriteria: dealflowUserCriteria.toJS()
		})

		dispatch({ type: DEALFLOW_CREATE_USER_CRITERIA, payload: fromJS(response.data) })
		callback?.()
	}
}

export function fetchUserCriteriaLists() {
	return async function (dispatch) {
		const response = await req.get('/shares/dealflow/user/criteria')
		dispatch({ type: DEALFLOW_LIST_USER_CRITERIA_LISTS, payload: fromJS(response.data) })
	}
}

export function deleteUserCriteriaDealflow(id, callback) {
	return async function (dispatch) {
		try {
			const response = await req.delete(`/shares/dealflow/user/criteria/${id}`)
			dispatch({ type: DEALFLOW_DELETE_USER_CRITERIA, payload: fromJS(response.data) })
			dispatch(addInfoNotification({ tid: 'investment.notification.user_criteria_list_deleted' }))

			callback?.()
		} catch (e) {
			console.log(e)
			dispatch(addErrorNotification({ tid: 'investment.error.user_criteria_list_deleted' }))
		}
	}
}

export function changeStatus(active, id, callback) {
	return async function (dispatch) {
		const response = await req.put(`/shares/dealflow/user/criteria/status/${id}`, { active })
		dispatch({ type: DEALFLOW_UPDATE_STATUS_USER_CRITERIA, payload: fromJS(response.data) })
		callback?.()
	}
}

export function updateUserDealflow(dealflowUserCriteria, callback) {
	return async function (dispatch) {
		const response = await req.put(`/shares/dealflow/user/criteria/`, {
			dealflowUserCriteria: dealflowUserCriteria.toJS()
		})
		dispatch({ type: DEALFLOW_UPDATE_USER_CRITERIA, payload: fromJS(response.data) })
		callback?.()
	}
}

export function fetchCompanyCriteria(id) {
	return async function (dispatch) {
		const response = await req.get(`/shares/dealflow/company/criteria/${id}`)
		dispatch({ type: DEALFLOW_COMPANY_CRITERIA, payload: fromJS(response.data) })
	}
}

export function updateCompanyCriteria(id, sections, callback) {
	sections = sections.toJS()
	return async function (dispatch) {
		const response = await req.post(`/shares/dealflow/company/criteria/${id}`, { sections })
		dispatch({ type: DEALFLOW_COMPANY_CRITERIA, payload: fromJS(response.data) })
		callback?.()
	}
}

export function checkIfIHaveInvestmentInCompany(orgNumber, callback) {
	return async function (dispatch) {
		try {
			const response = await req.get(`/shares/dealflow/investment/exists/${orgNumber}`)
			callback && callback(response.data)
		} catch (error) {
			console.log(error)
			dispatch(addErrorNotification({ tid: 'investment.errors.check_investment_exists' }))
		}
	}
}

export function checkIfIHavePersonalInvestmentsInCompany(orgNumber, callback) {
	return async function (dispatch) {
		try {
			const response = await req.get(`/shares/dealflow/personal-investment/exists/${orgNumber}`)
			callback && callback(response.data)
		} catch (error) {
			console.log(error)
			dispatch(addErrorNotification({ tid: 'investment.errors.check_investment_exists' }))
		}
	}
}

export function updateAvailableInDealflow(id, availableInDealflow, callback) {
	return async function (dispatch) {
		const response = await req.put(`/companies/dealflow/status/${id}`, { availableInDealflow })
		dispatch({ type: DEALFLOW_COMPANY_CHANGE_DISPLAY_STATUS, payload: response.data })
		callback?.()
	}
}

export function listDealflowFollowers(orgNumber) {
	return async function (dispatch) {
		try {
			const response = await req.get(`/shares/dealflow/company/followers/${orgNumber}`)
			dispatch({ type: DEALFLOW_COMPANY_LIST_DEALFLOW_FOLLOWERS, payload: fromJS(response.data) })
		} catch (error) {
			console.log(error)
			dispatch(addErrorNotification({ tid: 'dealflow.errors.list_dealflow_followers' }))
		}
	}
}

export function getNumberOfDealflowFollowers(orgNumber, typeOfInvitees, companyId, callback) {
	return async function (dispatch) {
		try {
			const query = new URLSearchParams({ typeOfInvitees, companyId }).toString()
			const response = await req.get(`/shares/dealflow/company/followers/amount/${orgNumber}/?${query}`)
			callback(response.data)
		} catch (error) {
			console.log(error)
			dispatch(addErrorNotification({ tid: 'dealflow.errors.list_dealflow_followers' }))
		}
	}
}

export function changeEmissionOptin(orgNumber, optIn) {
	return async function (dispatch) {
		try {
			await req.put(`/shares/dealflow/company/${orgNumber}/emissions/opt-in`, { optIn })
			dispatch(
				addInfoNotification({
					tid: optIn
						? 'dealflow.notification.change_emission_opt_id.opt_in'
						: 'dealflow.notification.change_emission_opt_id.op_out'
				})
			)
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'dealflow.errors.change_emission_opt_in' }))
		}
	}
}
