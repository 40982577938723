import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'
import { List } from 'immutable'

import NotifyMultipleUsersModalContainer from '../../generic-modals/notify-multiple-users-modal.container'

import { remindUsers } from '../../../../actions/user-management.actions'

import { INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL } from '../../../../constants/modals'
import { EMAIL_TEMPLATES_INVITE_USER_TO_COMPANY } from '/shared/constants'

class RemindUsersModalContainer extends Component {
	notify = (usersToNotify, workingTemplate, doneCallbackFunc, errorCallbackFunc) => {
		const { remindUsers } = this.props
		let usersIdsToNotify = List()

		usersToNotify.forEach((user) => {
			// Might be missleading. User ID in this case is user email
			// Unregistered users do not have an User ID and email
			// is used instead
			const userId = user.get('userId')
			usersIdsToNotify = usersIdsToNotify.push(userId)
		})

		remindUsers(usersIdsToNotify, workingTemplate, doneCallbackFunc, errorCallbackFunc)
	}

	disableMemberFunction = (member) => {
		return member.get('accountStatus') !== 'NOT_REGISTERED'
	}

	render = () => {
		return (
			<NotifyMultipleUsersModalContainer
				modalName={INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL}
				templateName={EMAIL_TEMPLATES_INVITE_USER_TO_COMPANY}
				onSubmit={this.notify}
				disableMemberFunction={this.disableMemberFunction}
				investorsDisabled={true}
				externalsDisabled={true}
				includeUnregisteredUsers={true}
				initiallySelectAllMembers={true}
				successNotificatonTid='user_management.unregistered_user_reminded_to_register'
				successNotificatonTidMultiple='user_management.unregistered_users_reminded_to_register'
				errorNotificationTid='user_management.error.remind_unregistered_users'
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {
	remindUsers
}

export default connect(mapStoreToProps, mapActionsToProps)(RemindUsersModalContainer)
