import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool } from 'prop-types'
import styled from 'styled-components'
import { Map } from 'immutable'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import Select from 'react-select'
import SelectCreatable from '../../../../dumb-components/fields/select-creatable'
import isEqual from 'lodash/isEqual'
import NumericInput from '../../../../dumb-components/fields/numeric-input'
import uniqBy from 'lodash/uniqBy'
import {
	SHARES_TRANSACTION_LIMITATIONS,
	SHARES_DEFAULT_CLASSES,
	SHARES_DEFAULT_TYPES
} from '../../../../constants/shares'
import { getShareTypeLabel, getOnlyUppercaseAndLetters } from '../../../helpers/shares'
import { localeConfig } from '../../../../modules/format-number'
import { closeModal } from '../../../../actions/modals.actions'

import { isRequired } from '../../../../modules/validation.module'

import Text from '../../../../dumb-components/shared/text/text'
import { Margin } from 'styled-components-spacing'
import Label from '../../../../dumb-components/shared/label/label'
import Modal from '../../../../dumb-components/shared/modal/modal'
import DropdownMenuContainer from '../../../../containers/shared/dropdown-menu.container'
import DropdownItem from '../../../../dumb-components/shared/dropdown-item/dropdown-item'
import DropdownIconItem from '../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import FooterRightControls from '../../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../../dumb-components/shared/button-v2'
import ColoredContentWrapper from '../../../../dumb-components/shared/colored-content-wrapper/colored-content-wrapper'

const INITIAL_STATE = {
	shareType: null,
	selectableShareTypes: null,
	hasError: false,
	errors: Map(),
	scrollTo: null
}

const StyledShareTypeFormWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledTextWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[4]};
`

const ErrorMessageWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[2]};
`

const StyledSharesWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`

const StyledTotalSharesWrapper = styled.div`
	width: 100%;
`

const StyledSharePriceWrapper = styled.div`
	width: 100%;
	margin-left: ${(props) => props.theme.spacing[4]};
`

class ShareTypeFormModal extends Component {
	static propTypes = {
		intl: intlShape.isRequired,
		isOpen: bool
	}

	state = { ...INITIAL_STATE }

	errorRefs = []

	componentDidMount() {
		const { shareType, selectableShareTypes } = this.props

		this.setState({
			shareType: shareType && shareType.toJS(),
			selectableShareTypes: selectableShareTypes && selectableShareTypes.toJS()
		})
	}

	componentDidUpdate(prevProps, prevState) {
		const { scrollTo } = this.state
		const { shareType, selectableShareTypes } = this.props

		if (scrollTo && scrollTo !== prevState.scrollTo) {
			this.errorRefs[scrollTo].scrollIntoView({ behavior: 'smooth', block: 'center' })
			this.setState({ scrollTo: null })
		}

		if (shareType !== prevProps.shareType && shareType) {
			this.setState({ shareType: shareType.toJS() })
		}

		if (!isEqual(selectableShareTypes, prevProps.selectableShareTypes) && selectableShareTypes) {
			this.setState({ selectableShareTypes: selectableShareTypes.toJS() })
		}
	}

	setInitialState = () => {
		this.setState({ ...INITIAL_STATE })
	}

	splitSelectableShareTypes(shareClassesTypes) {
		const { i18n } = this.props
		const shareClasses = []
		const shareTypes = []

		shareClassesTypes.forEach((shareClassType) => {
			const [shareClass, shareType] = shareClassType.value.split('$')

			if (shareClass) {
				const shareClassLabel = i18n.messages[`share_class.${shareClass}`] || decodeURIComponent(shareClass)
				const shareClassLabelToUpperCase = shareClassLabel.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) =>
					match.toUpperCase()
				)
				shareClasses.push({
					value: shareClass,
					label: shareClassLabelToUpperCase
				})
			}

			if (shareType) {
				const shareTypeLabel = decodeURIComponent(shareType)
				shareTypes.push({
					value: shareType,
					label: shareTypeLabel
				})
			}
		})

		return [shareClasses, shareTypes]
	}

	collectSelectableShareTypes(shareClassesTypes) {
		const { i18n } = this.props

		const defaultShareClasses = JSON.parse(JSON.stringify(SHARES_DEFAULT_CLASSES)).map((shareClass) => {
			shareClass.label = i18n.messages[shareClass.label] || shareClass.label
			return shareClass
		})
		const defaultShareTypes = JSON.parse(JSON.stringify(SHARES_DEFAULT_TYPES)).map((shareType) => {
			shareType.label = i18n.messages[shareType.label] || shareType.label
			return shareType
		})

		let [shareClasses, shareTypes] = this.splitSelectableShareTypes(shareClassesTypes)

		shareClasses = uniqBy(shareClasses.concat(defaultShareClasses), (obj) => {
			return obj.value
		}).sort((a, b) => {
			return a.label.localeCompare(b.label)
		})
		shareTypes = uniqBy(shareTypes.concat(defaultShareTypes), (obj) => {
			return obj.value
		}).sort((a, b) => {
			return a.label.localeCompare(b.label)
		})

		return [shareClasses, shareTypes]
	}

	getTransactionLimitations() {
		const { i18n } = this.props

		return JSON.parse(JSON.stringify(SHARES_TRANSACTION_LIMITATIONS)).map((transactionLimitation) => {
			transactionLimitation.label = i18n.messages[transactionLimitation.label]
			return transactionLimitation
		})
	}

	getTypeProp(type) {
		const shareClass = type.shareClass
		const shareType = type.shareType

		if (shareClass && shareType) {
			return shareClass + '$' + shareType
		} else if (shareClass) {
			return shareClass + '$'
		} else if (shareType) {
			return '$' + shareType
		} else {
			return '-'
		}
	}

	// closeModal
	close = () => {
		this.setInitialState()
		this.props.closeModal()
	}

	onSubmit = () => {
		const { shareType } = this.state

		if (this.validate(shareType)) {
			this.props.onSubmit(shareType)
			this.close()
		}
	}

	validate = (shareType) => {
		const { selectableShareTypes, numOfSharesReadonly, editShareType } = this.props
		let errors = Map()
		let scrollTo

		if (!isRequired(shareType.shareClass)) {
			errors = errors.set('shareClass', 'shares.transactions.error.share_class_required')
			if (!scrollTo) {
				scrollTo = 'shareClass'
			}
		}

		/*if (!isRequired(shareType.pricePerShare)) {
			errors = errors.set('pricePerShare', 'shares.transactions.error.price_per_share_required')
			if (!scrollTo) {
				scrollTo = 'pricePerShare'
			}
		}*/

		if (isNaN(shareType.numOfShares) || !isRequired(shareType.numOfShares)) {
			errors = errors.set('numOfShares', 'shares.transactions.error.num_of_shares_required')
			if (!scrollTo) {
				scrollTo = 'numOfShares'
			}
		} else if (shareType.numOfShares <= 0 && !numOfSharesReadonly) {
			errors = errors.set('numOfShares', 'shares.transactions.error.num_of_shares.less_than_1')
			if (!scrollTo) {
				scrollTo = 'numOfShares'
			}
		}

		if (selectableShareTypes.find((sst) => sst.get('value') === shareType.type) ? true : false) {
			if (editShareType === shareType.type) {
			} else {
				errors = errors.set('sharetype_exists', 'shares.transactions.error.sharetype.already_exists')
				if (!scrollTo) {
					scrollTo = 'sharetype_exists'
				}
			}
		}
		const hasError = errors.size > 0

		this.setState({ errors, scrollTo })
		return !errors.size
	}

	onChangeClassOrType(propName, val) {
		const { selectableShareTypes } = this.props
		const { shareType } = this.state
		let { errors } = this.state

		shareType[propName] = val
		shareType.type = this.getTypeProp(shareType)
		this.onChange(shareType)

		errors = errors.delete(propName)

		this.setState({ errors })
	}

	onChangeTransactionLimitations(val) {
		this.handleInputChange('transactionLimitations', val.split(','))
	}

	handleInputChange(propName, val) {
		const { shareType } = this.state
		shareType[propName] = propName === 'transactionLimitations' ? val : parseFloat(val)
		this.onChange(shareType)
	}

	onChange(shareType) {
		if (shareType.type) {
			const selectableShareTypes = this.state.selectableShareTypes
			const [shareClass, serie] = shareType.type.split('$')
			let found = false

			selectableShareTypes.map((selectableShareType) => {
				const [selectableShareClass, selectableSerie] = selectableShareType.value.split('$')
				if (
					selectableShareType.value === shareType.type ||
					(shareClass !== null && shareClass !== '' && selectableShareClass === shareClass) ||
					(serie !== null && serie !== '' && selectableSerie === serie)
				) {
					found = true
				}

				if (
					shareClass !== null &&
					shareClass !== '' &&
					selectableShareClass === shareClass &&
					serie !== null &&
					serie !== ''
				) {
					selectableShareType.value = selectableShareClass + '$' + serie
				} else if (
					serie !== null &&
					serie !== '' &&
					selectableSerie === serie &&
					shareClass !== null &&
					shareClass !== ''
				) {
					selectableShareType.value = shareClass + '$' + selectableSerie
				}
			})

			if (!found) {
				selectableShareTypes.push({
					value: shareType.type,
					label: getShareTypeLabel(shareType.type),
					disabled: false
				})
			}

			this.setState({ shareType, selectableShareTypes })
		} else {
			this.setState({ shareType })
		}
	}

	deleteShareType = () => {
		this.props.onDelete()
		this.close()
	}

	formatNumerInput(num) {
		const { formatNumber } = this.props.intl

		if (isNaN(num)) {
			return num
		}

		return formatNumber(num)
	}

	renderErrorMessage = (field) => {
		const { errors } = this.state
		const error = errors.get(field)

		if (!error) {
			return null
		}

		return (
			<ErrorMessageWrapper>
				<Text color='red' tid={error} />
			</ErrorMessageWrapper>
		)
	}

	renderFooter = () => {
		const { disableDeleteButton } = this.props

		return (
			<FooterRightControls>
				<TransparentButton
					tid='shares.transactions.share_type_form_modal.footer.btn.save_and_close'
					onClick={this.onSubmit}
				/>
				{!disableDeleteButton ? (
					<DropdownMenuContainer
						inline
						halignMenu='right'
						openDirection='up'
						renderRaw={<TransparentButton tid='modal.footer.dropdown_menu.more_options' textColor='midGrey' />}>
						<DropdownIconItem icon='faTimes' tid='modal.footer.dropdown_menu.cancel_and_close' onClick={this.close} />
						<DropdownItem divider />
						<DropdownIconItem icon='faTrashAlt' tid='generic.delete' onClick={this.deleteShareType} />
					</DropdownMenuContainer>
				) : (
					<TransparentButton
						tid='shares.transactions.share_type_form_modal.footer.btn.cancel_and_close'
						textColor='midGrey'
						onClick={this.close}
					/>
				)}
			</FooterRightControls>
		)
	}

	renderContent = () => {
		const { shareType, errors } = this.state
		const { i18n, numOfSharesReadonly } = this.props

		if (!shareType) {
			return null
		}

		const { selectableShareTypes } = this.state
		const [shareClasses, shareTypes] = this.collectSelectableShareTypes(selectableShareTypes)
		const [selectedClass, selectedType] = shareType.type ? shareType.type.split('$') : []
		const transferLimitationOptions = this.getTransactionLimitations()
		const shareTypeExistsError = errors.get('sharetype_exists')
		const shareClassError = errors.get('shareClass')
		const errorType = shareTypeExistsError ? shareTypeExistsError : shareClassError
		const refError = shareTypeExistsError ? 'sharetype_exists' : 'shareClass'

		return (
			<StyledShareTypeFormWrapper>
				<StyledColoredContentWrapper type='secondary'>
					<Margin bottom={4}>
						<Label tid='transactions.conversion_of_shares.create_new_share_class.share_name'>
							<SelectCreatable
								value={selectedClass}
								options={shareClasses}
								onChange={this.onChangeClassOrType.bind(this, 'shareClass')}
								simpleValue={true}
								clearable={false}
								placeholder={i18n.messages['shares.type_or_select']}
								hasError={errorType ? errorType : null}
								onErrorRef={(r) => (this.errorRefs[refError] = r)}
								promptTextCreator={(val) => {
									return `${i18n.messages['generic.form.create']}: ${val}`
								}}
							/>
						</Label>
					</Margin>
					<Label tid='series'>
						<SelectCreatable
							value={selectedType}
							options={shareTypes}
							onChange={this.onChangeClassOrType.bind(this, 'shareType')}
							simpleValue={true}
							clearable={false}
							placeholder={i18n.messages['shares.type_or_select']}
							onInputChange={getOnlyUppercaseAndLetters}
							promptTextCreator={(val) => {
								return `${i18n.messages['generic.form.create']}: ${val}`
							}}
						/>
					</Label>
					<StyledTextWrapper>
						<Text tid='transactions.conversion_of_shares.create_new_share_class.series.info' />
					</StyledTextWrapper>
				</StyledColoredContentWrapper>

				<StyledColoredContentWrapper type='secondary'>
					<Label tid='votes_per_share'>
						<NumericInput
							type='text'
							className='form-control text--align-left'
							value={shareType.votesPerShare}
							allowDecimals
							onChange={(val) => {
								this.handleInputChange('votesPerShare', val)
							}}
						/>
					</Label>
					<StyledTextWrapper>
						<Text tid='transactions.conversion_of_shares.create_new_share_class.votes_per_share.info' />
					</StyledTextWrapper>
				</StyledColoredContentWrapper>

				{!numOfSharesReadonly && (
					<StyledColoredContentWrapper type='secondary'>
						<StyledSharesWrapper>
							<StyledTotalSharesWrapper>
								<Label tid='total_shares'>
									<NumericInput
										type='text'
										className='form-control text--align-left'
										value={shareType.numOfShares}
										hasError={errors.get('numOfShares')}
										onErrorRef={(r) => (this.errorRefs['numOfShares'] = r)}
										onChange={(val) => {
											this.handleInputChange('numOfShares', val)
										}}
									/>
								</Label>
							</StyledTotalSharesWrapper>

							<StyledSharePriceWrapper>
								<Label tid='price_per_share'>
									<NumericInput
										type='text'
										className='form-control text--align-left'
										placeholder={this.props.i18n.messages['shares.value_in_sek']}
										decimalScale={localeConfig.round}
										value={shareType.pricePerShare}
										onChange={(val) => {
											this.handleInputChange('pricePerShare', val)
										}}
									/>
								</Label>
							</StyledSharePriceWrapper>
						</StyledSharesWrapper>
						<StyledTextWrapper>
							<Text tid='transactions.conversion_of_shares.create_new_share_class.total_shares.price_per_share.info' />
						</StyledTextWrapper>
					</StyledColoredContentWrapper>
				)}

				<StyledColoredContentWrapper type='secondary'>
					<Label tid='shares.transaction_restrictions'>
						<Select
							value={shareType.transactionLimitations ? shareType.transactionLimitations.join(',') : null}
							multi
							placeholder={i18n.messages['select_placeholder']}
							clearable={false}
							options={transferLimitationOptions}
							onChange={(val) => {
								this.onChangeTransactionLimitations(val)
							}}
							simpleValue={true}
						/>
					</Label>
					<StyledTextWrapper>
						<Text tid='transactions.conversion_of_shares.create_new_share_class.transaction_limitations.info' />
					</StyledTextWrapper>
				</StyledColoredContentWrapper>
			</StyledShareTypeFormWrapper>
		)
	}

	render = () => {
		const { shareType, hasError } = this.state
		const { i18n, pricePerShareReadonly, numOfSharesReadonly, isOpen, editState } = this.props
		const titleTid = editState ? 'transactions.new_share_issue.modal.edit_class_of_share.title' : 'new_class_of_share'

		if (!shareType) {
			return null
		}

		const { selectableShareTypes } = this.state
		const [shareClasses, shareTypes] = this.collectSelectableShareTypes(selectableShareTypes)
		const [selectedClass, selectedType] = shareType.type ? shareType.type.split('$') : []
		const transferLimitationOptions = this.getTransactionLimitations()

		return (
			<Modal isOpen={isOpen} hSize='md' vSize={100} title={titleTid} footerComponent={this.renderFooter()}>
				{this.renderContent()}
			</Modal>
		)
	}
}

function mapStateToProps(state) {
	const { modals } = state

	return {
		i18n: state.i18n,
		disableDeleteButton: modals && modals.getIn(['activeModal', 'options', 'disableDeleteButton']),
		numOfSharesReadonly: modals && modals.getIn(['activeModal', 'options', 'numOfSharesReadonly']),
		pricePerShareReadonly: modals && modals.getIn(['activeModal', 'options', 'pricePerShareReadonly']),
		selectableShareTypes: modals && modals.getIn(['activeModal', 'options', 'selectableShareTypes']),
		shareType: modals && modals.getIn(['activeModal', 'options', 'shareType']),
		onSubmit: modals && modals.getIn(['activeModal', 'options', 'onSubmit']),
		onDelete: modals && modals.getIn(['activeModal', 'options', 'onDelete']),
		editShareType: modals && modals.getIn(['activeModal', 'options', 'shareType', 'type']),
		editState: modals && modals.getIn(['activeModal', 'options', 'editState'])
	}
}

ShareTypeFormModal = connect(mapStateToProps, { closeModal })(ShareTypeFormModal)
ShareTypeFormModal = injectIntl(ShareTypeFormModal)
export default ShareTypeFormModal
