import styled, { css } from 'styled-components'

export default styled.button.attrs({ type: 'button' })`
	border: none;
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	background: transparent;
	padding: 0;
	border-radius: 0;
	margin: 0;
	position: relative;
	text-align: center;
	white-space: nowrap;
	touch-action: manipulation;
	user-select: none;
	-webkit-appearance: button;
	overflow: visible;

	:focus {
		outline: 0 !important;
	}

	:disabled {
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	${(props) =>
		props.fullWidth &&
		css`
			width: 100%;
		`}
`
