import React from 'react'
import { bool, func, object, oneOf } from 'prop-types'
import Text from '../../shared/text/text'
import { list } from 'react-immutable-proptypes'
import { MultiSelectCreatable } from '../../shared/select'

const TagsSelect = ({ onChange, options, value, readOnly, menuPortalTarget, menuPlacement }) => {
	function noOptionsMessage() {
		return <Text tid='no_custom_tags_found' />
	}

	function formatCreateLabel(val) {
		return (
			<span>
				<Text tid='documents.create_new_tag' />: {val}
			</span>
		)
	}

	return (
		<MultiSelectCreatable
			options={options}
			optionsValue='id'
			optionsLabel='name'
			filterType='word-match'
			placeholderTid='tags.select.placeholder'
			onChange={onChange}
			hideDropdownIndicator={true}
			noOptionsMessage={noOptionsMessage}
			formatCreateLabel={formatCreateLabel}
			openMenuOnClick={false}
			value={value}
			isDisabled={readOnly}
			menuPortalTarget={menuPortalTarget}
			menuPlacement={menuPlacement}
		/>
	)
}

TagsSelect.defaultProps = {
	readOnly: false
}

TagsSelect.propTypes = {
	options: list,
	onChange: func,
	readOnly: bool,
	menuPortalTarget: object,
	menuPlacement: oneOf(['top', 'bottom', 'auto'])
}

export default TagsSelect
