import { getShareTypeClass, getShareTypeName } from './shares'
import { List } from 'immutable'
import {
	PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
	PERSONAL_TRANSACTION_TYPE_LEVELING,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
	PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
	PERSONAL_TRANSACTION_TYPE_SPLIT,
	PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
	PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
	PERSONAL_TRANSACTION_TYPE_SNAPSHOT,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
	PERSONAL_TRANSACTION_TYPE_GENESIS
} from '/shared/constants'

import { createLinkedPersonalTransaction } from '/shared/helpers/linkTransactionsHelper.v2'

export const checkTransactionMissingValues = (transaction) => {
	const transactionType = transaction.get('transactionType')
	const shareTypes = transaction.getIn(['handlerData', 'types'])
	const shareData = transaction.get('shareData')
	let missingValue = false

	if (!shareData) {
		missingValue = true
		return missingValue
	}

	const mandatoryShareDataItems = [
		'myPostMoney',
		'myTotalVotes',
		'numOfTotalCompanyShares',
		'postMoney',
		'totalVotes'
	]
	const missingMandatoryValue = mandatoryShareDataItems.some((item) => {
		return !(shareData.get(item) || shareData.get(item) === 0)
	})

	if (missingMandatoryValue) {
		missingValue = true
		return missingValue
	}

	switch (transactionType) {
		case PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES: {
			missingValue = checkShareTypesForMissingValues(shareTypes, ['votesPerShare'])
			break
		}
		case PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES: {
			missingValue = checkShareTypesForMissingValues(shareTypes, ['myShares'])
			break
		}
		case PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL: {
			missingValue = checkShareTypesForMissingValues(shareTypes, ['purchasePrice', 'myShares'])
			break
		}
		case PERSONAL_TRANSACTION_TYPE_SPLIT: {
			const splitQuotaFrom = transaction.getIn(['handlerData', 'splitQuotaFrom'])
			const splitQuotaTo = transaction.getIn(['handlerData', 'splitQuotaTo'])

			if (!splitQuotaFrom || !splitQuotaTo) {
				missingValue = true
				break
			}

			missingValue = checkShareTypesForMissingValues(shareTypes, ['latestPrice', 'myShares'])
			break
		}
		case PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL: {
			const transferFrom = transaction.getIn(['handlerData', 'transferFrom'])
			const transferTo = transaction.getIn(['handlerData', 'transferTo'])

			if (!transferFrom || !transferTo) {
				missingValue = true
				break
			}

			missingValue = checkShareTypesForMissingValues(shareTypes, ['latestPrice', 'votesPerShare', 'myShares'])
			break
		}
		case PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY: {
			const transferFrom = transaction.getIn(['handlerData', 'transferFrom'])
			const transferTo = transaction.getIn(['handlerData', 'transferTo'])

			if (!transferFrom || !transferTo) {
				missingValue = true
				break
			}

			missingValue = checkShareTypesForMissingValues(shareTypes, ['latestPrice', 'votesPerShare', 'myShares'])
			break
		}
		case PERSONAL_TRANSACTION_TYPE_SNAPSHOT: {
			const capitalInvested = transaction.getIn(['shareData', 'capitalInvested'])
			const numOfTotalShares = transaction.getIn(['shareData', 'numOfTotalShares'])
			missingValue =
				(capitalInvested === 0 || capitalInvested) && (numOfTotalShares === 0 || numOfTotalShares)
					? false
					: true
			break
		}
		case PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE:
		case PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES:
		case PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE:
		case PERSONAL_TRANSACTION_TYPE_LEVELING:
		case PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS:
		case PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE: {
			missingValue = checkShareTypesForMissingValues(shareTypes, [
				'totalSharesEmitted',
				'latestPrice',
				'votesPerShare',
				'myShares'
			])
			break
		}

		default:
			break
	}

	return missingValue
}

const checkShareTypesForMissingValues = (shareTypes, keys) => {
	let missingValue = false
	if (shareTypes) {
		const foundValue = shareTypes.find((shareType) => objHasKey(shareType, keys))
		if (foundValue) {
			missingValue = true
		}
	}
	return missingValue
}

const objHasKey = (obj, keys) => {
	const missingKey = keys.some((key) => !(obj.get(key) || obj.get(key) === 0))

	return missingKey
}

export const linkedTransactionPopulateInitialValues = (linkButton, form, selectedInvestmentTransactions, userId) => {
	// id is linkedTransactionId
	const { id } = linkButton
	const selectedTransactionType = form.getFieldState('transactionType').value

	const linkedTransaction = selectedInvestmentTransactions.find((transaction) => {
		return transaction.get('id') === id
	})

	if (!linkedTransaction) {
		return
	}

	const transactionValues = formatTransactionData(selectedTransactionType, linkedTransaction, userId)

	transactionValues.link = {
		transactionId: linkedTransaction && id,
		isDirty: form.getState().values?.link?.isDirty && form.getState().values?.link?.isDirty === true && false
	}

	const transactionValuesLink = transactionValues.link
	Object.keys(transactionValuesLink).forEach((key) => {
		if (transactionValuesLink[key] === undefined) {
			delete transactionValuesLink[key]
		}
	})

	form.setConfig('keepDirtyOnReinitialize', false)
	form.reset(transactionValues)
	form.mutators.retainDirtyOnInitialize()
	form.setConfig('keepDirtyOnReinitialize', true)
}

export const formatTransactionData = (selectedTransactionType, linkedTransaction, userId) => {
	const linkedPersonalTransaction = createLinkedPersonalTransaction(linkedTransaction.toJS(), userId)

	const { date, handlerData, shareData, link } = linkedPersonalTransaction
	let { transactionType } = linkedPersonalTransaction
	const { postMoney, myPostMoney, totalVotes, myTotalVotes, numOfTotalCompanyShares } = shareData

	if (selectedTransactionType === PERSONAL_TRANSACTION_TYPE_SNAPSHOT) {
		transactionType = PERSONAL_TRANSACTION_TYPE_SNAPSHOT
	}

	const transactionValues = {
		transactionType,
		date,
		numOfTotalCompanyShares,
		totalVotes,
		companyTotalValue: postMoney,
		myTotalValue: myPostMoney,
		myTotalVotes
	}

	switch (transactionType) {
		case PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL:
		case PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY: {
			const { transferFrom, transferTo, types } = handlerData
			transactionValues.transferFrom = transferFrom
			transactionValues.transferTo = transferTo
			const shareTypes = types.map((type) => {
				const { shareType, votesPerShare, latestPrice, myShares, purchasePrice } = type
				const mappedShareType = {
					shareTypeName: getShareTypeName(shareType),
					shareTypeClass: getShareTypeClass(shareType),
					latestPrice,
					votesPerShare,
					purchasePrice,
					type: shareType
				}
				if (myShares) {
					mappedShareType.myShares = myShares
				}
				return mappedShareType
			})
			transactionValues.shareTypes = shareTypes
			break
		}
		case PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER: {
			const { transferFrom, transferTo, types } = handlerData
			transactionValues.transferFrom = transferFrom
			transactionValues.transferTo = transferTo
			const shareTypes = types.map((type) => {
				const { shareType, myShares } = type
				const mappedShareType = {
					shareTypeName: getShareTypeName(shareType),
					shareTypeClass: getShareTypeClass(shareType),
					type: shareType
				}
				if (myShares) {
					mappedShareType.myShares = myShares
				}
				return mappedShareType
			})
			transactionValues.shareTypes = shareTypes
			break
		}
		case PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES: {
			const { shareTypeFrom, shareTypeTo, types } = handlerData

			transactionValues.conversionOfSharesNameFrom = getShareTypeName(shareTypeFrom)
			transactionValues.conversionOfSharesClassFrom = getShareTypeClass(shareTypeFrom)
			transactionValues.conversionOfSharesTypeFrom = shareTypeFrom

			transactionValues.conversionOfSharesNameTo = getShareTypeName(shareTypeTo)
			transactionValues.conversionOfSharesClassTo = getShareTypeClass(shareTypeTo)
			transactionValues.conversionOfSharesTypeTo = shareTypeTo

			transactionValues.shareTypes = types

			break
		}
		case PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES: {
			const { types } = handlerData
			const shareTypes = types.map((type) => {
				const { shareType, votesPerShare } = type
				return {
					shareTypeName: getShareTypeName(shareType),
					shareTypeClass: getShareTypeClass(shareType),
					votesPerShare,
					type: shareType
				}
			})
			transactionValues.shareTypes = shareTypes

			break
		}
		case PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE:
		case PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE:
		case PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS:
		case PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES:
		case PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE:
		case PERSONAL_TRANSACTION_TYPE_GENESIS:
		case PERSONAL_TRANSACTION_TYPE_LEVELING: {
			const { types } = handlerData
			const shareTypes = types
				? types.map((type) => {
						const { shareType, totalSharesEmitted, latestPrice, votesPerShare, myShares } = type
						return {
							shareTypeName: getShareTypeName(shareType),
							shareTypeClass: getShareTypeClass(shareType),
							totalSharesEmitted,
							latestPrice,
							votesPerShare,
							myShares,
							type: shareType
						}
				  })
				: List()
			transactionValues.shareTypes = shareTypes
			break
		}
		case PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL: {
			const { types } = handlerData
			const shareTypes = types
				? types.map((type) => {
						const { shareType, purchasePrice, myShares } = type
						return {
							shareTypeName: getShareTypeName(shareType),
							shareTypeClass: getShareTypeClass(shareType),
							purchasePrice,
							myShares,
							type: shareType
						}
				  })
				: List()
			transactionValues.shareTypes = shareTypes
			break
		}
		case PERSONAL_TRANSACTION_TYPE_SPLIT: {
			const { splitQuotaFrom, splitQuotaTo, types } = handlerData

			transactionValues.splitQuotaFrom = splitQuotaFrom
			transactionValues.splitQuotaTo = splitQuotaTo

			const shareTypes = types
				? types.map((type) => {
						const { shareType, purchasePrice, latestPrice, myShares } = type
						return {
							shareTypeName: getShareTypeName(shareType),
							shareTypeClass: getShareTypeClass(shareType),
							latestPrice,
							purchasePrice,
							myShares,
							type: shareType
						}
				  })
				: List()
			transactionValues.shareTypes = shareTypes
			break
		}
		case PERSONAL_TRANSACTION_TYPE_SNAPSHOT: {
			transactionValues.capitalInvested = linkedTransaction.getIn(['balance', 'capitalInvested'])
			transactionValues.numOfTotalShares = linkedTransaction.getIn(['balance', 'numOfTotalShares'])

			break
		}
		default:
			break
	}

	return transactionValues
}
