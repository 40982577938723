export function mergeSharedFolders(sharedFoldersObj, folders) {
	const foldersToMerge = sharedFoldersObj.get('folders')
	folders = folders.merge(foldersToMerge)
	return folders
}

export function mergeSharedMapParentToChildren(sharedFoldersObj, mapParentToChildren) {
	const mapToMerge = sharedFoldersObj.get('mapParentToChildren')
	mapParentToChildren = mapParentToChildren.merge(mapToMerge)
	return mapParentToChildren
}
