import React, { PureComponent } from 'react'
import immutablePropTypes from 'react-immutable-proptypes'
import { func, string, number, oneOfType } from 'prop-types'
import { fromJS } from 'immutable'
import styled, { css } from 'styled-components'
import { Padding } from 'styled-components-spacing'
import List from '../../shared/list/list'
import ListItem from '../../shared/list-item/list-item'
import Text from '../../shared/text/text'
import Button from '../../shared/button/button'
import moment from '../../../modules/moment.module'
import { StyledListHeaderTemplate } from '../../shared/list/list-header.template'
import Panel from '../../shared/panel/panel'
import ShowMoreBox from '../../../components/framework/show-more-box'
import { Select } from '../../shared/select'
import Tooltip from '../../shared/tooltip/tooltip'
import { isEllipsisActive } from '../../shared/tooltip/tooltip.helpers'
import { AVAIBLE_TASK_STATUS } from '../../../constants/tasks'
import documentsHelper from '../../../components/helpers/documents.helper'

const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
	border-left: ${(props) =>
		props.isCanceled
			? `${props.theme.spacing[3]} solid ${props.theme.colors.persianRed}`
			: `${props.theme.spacing[3]} solid transparent`};
`

const StyledListItem = styled(ListItem)`
	align-items: center;
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeLight};
	margin-bottom: 0px;
	border-left-width: ${(props) => props.theme.spacing[3]};
	border-left-style: solid;
	border-left-color: ${(props) => (props.statusColor ? props.theme.colors[props.statusColor] : 'transparent')};
`

const EllipsisTemplate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

const TemplateTitleWidth = css`
	${EllipsisTemplate};
	min-width: 100px;
	flex: 1;
`

const TemplateCreatedDateWidth = css`
	width: 130px;
`

const TemplateCompanyNameWidth = css`
	${EllipsisTemplate};
	width: 180px;
`

const TemplateOpenButtonWidthCss = css`
	width: 110px;
	display: flex;
	justify-content: flex-end;
`

const StyledFilterWrapper = styled.div`
	width: 200px;
`

// List Items
const StyledTitle = styled.div`
	${TemplateTitleWidth};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

const StyledInfo = styled.div`
	width: 100%;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

const StyledTitleWrapper = styled.div`
	${TemplateTitleWidth};
`

const StyledCreatedAt = styled.div`
	${TemplateCreatedDateWidth};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

const StyledCompanyName = styled.div`
	${TemplateCompanyNameWidth};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

const StyledButtonWrapper = styled.div`
	${TemplateOpenButtonWidthCss};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

export default class DocumentsListByCompanies extends PureComponent {
	documentElemRefs = {}

	static propTypes = {
		documents: immutablePropTypes.list,
		pagerController: func,
		openDoc: func,
		onFilter: func,
		selectedFilter: oneOfType([string, number]),
		downloadDoc: func
	}

	componentDidMount() {
		window.addEventListener('resize', this.forceRerender)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.forceRerender)
	}

	forceRerender = () => {
		this.forceUpdate()
	}

	renderHeader = () => {
		return (
			<StyledHeader>
				<StyledTitle>
					<Text tid='documents.list_by_companies.title' bold={400} color='lightGrey' />
				</StyledTitle>
				<StyledCreatedAt>
					<Text tid='documents.list_by_companies.created_at' bold={400} color='lightGrey' />
				</StyledCreatedAt>
				<StyledCompanyName>
					<Text tid='generic.company' bold={400} color='lightGrey' />
				</StyledCompanyName>
				<StyledButtonWrapper />
			</StyledHeader>
		)
	}

	renderDocument = (doc, index) => {
		const { openDoc, downloadDoc } = this.props
		const createdAt = moment(doc.get('createdAt')).format('ll')
		const companyName = doc.getIn(['companyInformation', 'name'])
		const title = doc.get('title')
		const uid = doc.get('id')
		const tooltipIsActive = this.documentElemRefs[uid] ? isEllipsisActive(this.documentElemRefs[uid]) : false
		const statusColor = documentsHelper.getStatusColor(documentsHelper.getStatus(doc), 'transparent')
		const documentIsViewableInBrowser = documentsHelper.isViewableInBrowser(doc.get('file'))

		return (
			<StyledListItem key={index} statusColor={statusColor} hoverColor='lightestGrey' striped>
				<StyledTitle>
					<Tooltip raw={title} active={tooltipIsActive} delayShow='short'>
						<StyledTitleWrapper ref={(r) => (this.documentElemRefs[uid] = r)} key={index}>
							<Text singleLine={true}>{title}</Text>
						</StyledTitleWrapper>
					</Tooltip>
				</StyledTitle>

				<StyledCreatedAt>
					<Text singleLine={true}>{createdAt}</Text>
				</StyledCreatedAt>

				<StyledCompanyName>
					<Text singleLine={true}>{companyName} </Text>
				</StyledCompanyName>

				<StyledButtonWrapper>
					{documentIsViewableInBrowser && <Button tid='generic.open' minWidth='80px' onClick={() => openDoc(doc)} />}
					{!documentIsViewableInBrowser && (
						<Button tid='documents.download' minWidth='88px' onClick={() => downloadDoc(doc)} />
					)}
				</StyledButtonWrapper>
			</StyledListItem>
		)
	}

	renderEmptyList = () => {
		return (
			<StyledListItem striped>
				<StyledInfo>
					<Text color='muted' tid='documents.list_by_companies.no_available' />
				</StyledInfo>
			</StyledListItem>
		)
	}

	renderDateFilter = () => {
		const { onFilter, selectedFilter } = this.props
		const filterValues = [
			{ label: 'documents.list_by_companies.filter.label.today', value: 1 },
			{ label: 'documents.list_by_companies.filter.label.7d', value: 7 },
			{ label: 'documents.list_by_companies.filter.label.30d', value: 30 }
		]

		return (
			<StyledFilterWrapper>
				<Select
					options={fromJS(filterValues)}
					onChange={onFilter && onFilter}
					labelIsTid={true}
					value={selectedFilter}
					isSearchable={false}
				/>
			</StyledFilterWrapper>
		)
	}

	render = () => {
		const { documents, pagerController } = this.props

		return (
			<Panel
				title='service.user_dashboard.documents'
				mode='light'
				footerComponent={pagerController}
				rightComponent={this.renderDateFilter}
				marginBottom>
				<Padding all={4}>
					<List header={this.renderHeader}>
						{documents && documents.size > 0 && (
							<ShowMoreBox closedHeight={191} btnMarginTop={32}>
								{documents.map(this.renderDocument)}
							</ShowMoreBox>
						)}
						{(!documents || documents.size === 0) && this.renderEmptyList()}
					</List>
				</Padding>
			</Panel>
		)
	}
}
