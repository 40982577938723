import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, string, array, oneOfType, bool, oneOf } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import moment from '../../modules/moment.module'
import {
	downloadDocument,
	fetchDocuments,
	deleteDocumentsPublic,
	deleteDocumentsExternal
} from '../../actions/documents.actions'
import { createAttachedDocument, createAttachedDocumentPublic } from '../../actions/attachments.actions'
import Attachments from '../../dumb-components/shared/attachments/attachments'
import DropdownMenuContainer from './dropdown-menu.container'
import DropdownIconItem from '../../dumb-components/shared/dropdown-item/dropdown-icon-item'

class AttachmentsContainer extends Component {
	static propTypes = {
		fieldName: string.isRequired,
		multiple: bool,
		accept: oneOfType([string, array]),
		readOnly: bool,
		onUpload: func.isRequired,
		files: immutablePropTypes.list,
		onOpenFile: func,
		onDownloadFile: func,
		onDeleteFile: func,
		onReorderFiles: func,
		admin: bool,
		panelType: oneOf(['inline']),
		onSetRef: func,
		marginBottom: bool,
		selected: bool,
		transparent: bool,
		descriptionTid: string,
		withoutPanel: bool,
		objType: string,
		objId: string,
		showDropzone: bool,
		mode: oneOf(['PUBLIC-UNSAFE'])
	}

	static defaultProps = {
		showDropzone: true
	}

	state = {
		documents: List(),
		isUploading: false
	}

	dropdownRefs = []

	componentDidMount = () => {
		this.loadFiles(this.props.files)
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.objId !== this.props.objId) {
			this.loadFiles(this.props.files)
		}

		if (prevProps.files !== this.props.files) {
			this.loadFiles(this.props.files)
		}
	}

	loadFiles = (documentIds) => {
		const { fetchDocuments } = this.props

		if (!documentIds || documentIds.size === 0) {
			this.setState({ documents: List() })
			return
		}

		fetchDocuments(documentIds, (docs) => {
			if (!docs || docs.size === 0) {
				return
			}

			docs = documentIds
				.filter((docId) => {
					const doc = docs.find((d) => d.get('id') === docId)
					return doc ? true : false
				})
				.map((docId) => {
					const doc = docs.find((d) => d.get('id') === docId)
					return doc
				})

			this.setState({ documents: docs })
		})
	}

	reorder = (documentId, sourceIndex, destinationIndex) => {
		const { onReorderFiles, fieldName } = this.props
		let { documents } = this.state
		const oldItem = documents.find((obj) => obj.get('id') === documentId)

		documents = documents.delete(sourceIndex).insert(destinationIndex, oldItem)
		this.setState({ documents })
		const documentIds = documents.map((obj) => obj.get('id'))

		if (onReorderFiles && fieldName) {
			onReorderFiles(fieldName, documentIds)
		} else {
			onReorderFiles(documentIds)
		}
	}

	onDragEnd = (result) => {
		const { draggableId, source, destination } = result

		if (!destination) {
			return
		}

		this.reorder(draggableId, source.index, destination.index)
	}

	upload = (filesToUpload) => {
		const { createAttachedDocument, createAttachedDocumentPublic, onUpload, fieldName, company, mode, objId, objType } =
			this.props

		this.setState({ isUploading: true })

		filesToUpload.forEach((file) => {
			const document = new FormData()
			const props = {
				file,
				lastModifiedDate: moment(file.lastModified).toISOString(),
				title: file.name,
				hideFromDocumentsSection: true
			}

			if (objId && objType) {
				props.link = JSON.stringify([{ objId, objType }])
			}

			Object.keys(props).forEach((key) => {
				if (Array.isArray(props[key])) {
					for (let i = 0; i < props[key].length; i++) {
						document.append(key, props[key][i])
					}
				} else {
					document.append(key, props[key])
				}
			})

			if (mode === 'PUBLIC-UNSAFE') {
				createAttachedDocumentPublic(objType, objId, document, company.id, null, (doc) => {
					let { documents } = this.state

					documents = documents.push(file)

					this.setState({ documents })

					if (onUpload && fieldName) {
						onUpload(fieldName, doc.get('id'))
					} else {
						onUpload(doc.get('id'))
					}

					this.setState({ isUploading: false })
				})

				return
			}

			createAttachedDocument(objType, objId, document, (doc) => {
				if (onUpload && fieldName) {
					onUpload(fieldName, doc.get('id'), objId)
				} else {
					onUpload(doc.get('id'), objId)
				}

				this.setState({ isUploading: false })
			})
		})
	}

	onOpenFile = (document) => {
		const { downloadDocument, onOpenFile } = this.props
		downloadDocument({ documentId: document.get('id'), openInViewer: true })
		onOpenFile && onOpenFile(document)
	}

	onDownloadFile = (document) => {
		const { downloadDocument, company, onDownloadFile } = this.props
		const documentId = document.get('id')

		downloadDocument({ documentId, companyId: company.id })
		onDownloadFile && onDownloadFile(document)

		this.onToggleDropdown(documentId)
	}

	deleteFile = (document) => {
		const { deleteDocumentsExternal, deleteDocumentsPublic, onDeleteFile, mode, objType, objId, company } = this.props
		const documentId = document.get('id')

		this.onToggleDropdown(documentId)

		if (mode === 'PUBLIC-UNSAFE') {
			deleteDocumentsPublic(documentId, objType, objId, company.id, () => {
				this.deleteFileFromState(document)
				onDeleteFile && onDeleteFile(document)
			})
			return
		}

		deleteDocumentsExternal(documentId, objType, objId, () => {
			this.deleteFileFromState(document)
			onDeleteFile && onDeleteFile(document)
		})
	}

	deleteFileFromState = (deletedDocument) => {
		const { documents } = this.state

		const newList = documents.filter((document) => {
			return document.get('id') !== deletedDocument.get('id')
		})

		this.setState({ documents: newList })
	}

	onToggleDropdown = (documentId) => {
		this.dropdownRefs[documentId] && this.dropdownRefs[documentId].onToggleMenu()
	}

	renderDropdownComponent = (document) => {
		return (
			<DropdownMenuContainer ref={(ref) => (this.dropdownRefs[document.get('id')] = ref)} halignMenu='right' inline>
				<DropdownIconItem
					icon='faArrowAltToBottom'
					tid='download_file'
					onClick={this.onDownloadFile.bind(null, document)}
				/>

				<DropdownIconItem icon='faTrashAlt' tid='delete_file' onClick={this.deleteFile.bind(null, document)} />
			</DropdownMenuContainer>
		)
	}

	renderDropdownDeleteOnlyComponent = (document) => {
		return (
			<DropdownMenuContainer ref={(ref) => (this.dropdownRefs[document.get('id')] = ref)} halignMenu='right' inline>
				<DropdownIconItem icon='faTrashAlt' tid='delete_file' onClick={this.deleteFile.bind(null, document)} />
			</DropdownMenuContainer>
		)
	}

	render = () => {
		const { readOnly } = this.props
		const { documents, isUploading } = this.state

		if (readOnly && documents.size === 0) {
			return null
		}

		return (
			<Attachments
				{...this.props}
				onDragEnd={this.onDragEnd}
				onDrop={this.upload}
				documents={documents}
				onOpenFile={this.onOpenFile}
				onDownloadFile={this.onDownloadFile}
				isUploading={isUploading}
				dropdownRenderer={this.renderDropdownComponent}
				dropdownDeleteOnlyRenderer={this.renderDropdownDeleteOnlyComponent}
				admin
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		company: store.company.company
	}
}

const mapActionsToProps = {
	createAttachedDocument,
	downloadDocument,
	fetchDocuments,
	deleteDocumentsExternal,
	createAttachedDocumentPublic,
	deleteDocumentsPublic
}

export default connect(mapStoreToProps, mapActionsToProps)(AttachmentsContainer)
