import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { createUser } from '../../../../actions/user-management.actions'

import NotifyUsersModalContainer from '../../generic-modals/notify-single-user-modal.container'

import { INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL } from '../../../../constants/modals'
import { EMAIL_TEMPLATES_INVITE_USER_TO_COMPANY } from '/shared/constants'

class InviteUsersModalContainer extends Component {
	propTypes = {
		onDone: func
	}

	inviteUsers = (mailTemplate, closeModalCallback, errorCallback) => {
		const { companyId, createUser, users, onDone } = this.props

		users.forEach((user) => {
			const userToCreate = {
				name: user.get('name'),
				email: user.get('email'),
				// Add "User [Default]" role to user if it does not have any roles attached
				userRoles:
					!user.get('roles') || user.get('roles').size === 0
						? ['dede9e47-dd3c-4510-97d6-973a6ad7ddac']
						: user.get('roles').toArray()
			}

			createUser(userToCreate, companyId, mailTemplate.toJS(), (error) => {
				if (error) {
					errorCallback()
					return
				}

				closeModalCallback()
				onDone?.()
			})
		})
	}

	render = () => {
		return (
			<NotifyUsersModalContainer
				templateName={EMAIL_TEMPLATES_INVITE_USER_TO_COMPANY}
				modalName={INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL}
				onSubmit={this.inviteUsers}
				successNotificatonTid='user_management.unregistered_users_notified_to_register'
				errorNotificationTid='user_management.error.notify_unregistered_users'
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		users: store.modals.getIn(['activeModal', 'options', 'users']),
		companyId: store.company.company.id
	}
}

const mapActionsToProps = {
	createUser
}

export default connect(mapStoreToProps, mapActionsToProps)(InviteUsersModalContainer)
