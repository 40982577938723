import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import { getUserName } from '../../../../components/helpers/users'

import { fetchAttachedDocument } from '../../../../actions/attachments.actions'
import { fetchAndCacheInvestors } from '../../../../actions/investors.actions'

import ListPanel from '../../../../dumb-components/shared/list-panel/list-panel'
import ListPanelItem from '../../../../dumb-components/shared/list-panel/list-panel-item'

import {
	MEETING_STATUS_PROTOCOL_NEEDS_SIGNING,
	MEETING_STATUS_PROTOCOL_PUBLISHED,
	OBJ_TYPE_MEETING
} from '/shared/constants'

// Used in meeting general view
class SigneesContainer extends Component {
	componentDidMount = () => {
		const { fetchAttachedDocument, protocolDocumentId, meetingId, meetingStatus } = this.props

		if (
			protocolDocumentId &&
			[MEETING_STATUS_PROTOCOL_PUBLISHED, MEETING_STATUS_PROTOCOL_NEEDS_SIGNING].includes(meetingStatus)
		) {
			fetchAttachedDocument(OBJ_TYPE_MEETING, meetingId, protocolDocumentId, true)
		}
	}

	componentDidUpdate = (prevProps) => {
		const {
			fetchAttachedDocument,
			protocolDocumentId,
			meetingId,
			meetingStatus,
			eSignees,
			fetchAndCacheInvestors
		} = this.props

		if (
			protocolDocumentId &&
			prevProps.protocolDocumentId !== protocolDocumentId &&
			[MEETING_STATUS_PROTOCOL_PUBLISHED, MEETING_STATUS_PROTOCOL_NEEDS_SIGNING].includes(meetingStatus)
		) {
			fetchAttachedDocument(OBJ_TYPE_MEETING, meetingId, protocolDocumentId, true)
		}

		if (eSignees && eSignees !== prevProps.eSignees) {
			const investmentIds = eSignees.filter((e) => !e.get('isGuest')).map((e) => e.get('userId'))
			fetchAndCacheInvestors(investmentIds)
		}
	}

	renderSigneesItem = (signee, index) => {
		const { investors, usersCache } = this.props

		const signedAt = signee.get('signedAt')
		const name = getUserName(signee, investors, usersCache, true)
		let status = null

		if (signee.get('status') === 'SIGNED') {
			status = true
		}

		return <ListPanelItem name={name} status={status} date={signedAt} key={index} />
	}

	render = () => {
		const { eSignees, protocolDocumentId, meetingStatus } = this.props

		if (![MEETING_STATUS_PROTOCOL_PUBLISHED, MEETING_STATUS_PROTOCOL_NEEDS_SIGNING].includes(meetingStatus)) {
			return null
		}

		if (!protocolDocumentId) {
			return null
		}

		const atLeastOneNotified = eSignees.find((signee) => signee.get('notified') === true) ? true : false

		if (eSignees.size === 0) {
			return null
		}

		if (!atLeastOneNotified) {
			return null
		}

		return (
			<ListPanel
				title='meeting.details.signees_panel.title'
				items={eSignees.toList()}
				columnTid='meetings.details.signees.panel.signed'
				renderItemComponent={this.renderSigneesItem}
				panelProps={{ marginBottom: true }}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		usersCache: store.usersCache.get('usersCache'),
		investors: store.investors.get('investorsCache'),
		meetingId: store.meetings.getIn(['settings', 'id']),
		protocolDocumentId: store.meetings.getIn(['meeting', 'protocolData', 'documentId']),
		meetingStatus: store.meetings.getIn(['meeting', 'status']),
		eSignees: store.documents.getIn(['document', 'eSignees'], Map())
	}
}

const mapActionsToProps = {
	fetchAttachedDocument,
	fetchAndCacheInvestors
}

export default connect(mapStoreToProps, mapActionsToProps)(SigneesContainer)
