import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromJS, List } from 'immutable'

import GenericInfoPanel from '../../../dumb-components/company/comapny-dashboard/generic-info-panel/generic-info-panel'
import GenericInfoListItem from '../../../dumb-components/company/comapny-dashboard/generic-info-panel/generic-info-list-item'
import RemindShareholdersModalContainer from '../../notify-by-templates/modals/shares/remind-shareholders-modal.container'

import { fetchInvestorsCount } from '../../../actions/investors.actions'
import { openModal } from '../../../actions/modals.actions'

import { getHasAccessToSection } from '../../../components/helpers/resources.helper'
import { REMIND_SHAREHOLDERS_EMAIL_TEMPLATE_MODAL } from '../../../constants/modals'
import { RESOURCE_SHAREHOLDERS } from '/shared/constants'

class ShareholdersPanelContainer extends Component {
	state = {
		isLoading: true,
		data: List()
	}

	componentDidMount = () => {
		this.countInvestors()
	}

	componentDidUpdate = (prevProps) => {
		const { companyId } = this.props

		if (prevProps.companyId !== companyId) {
			this.setState({ isLoading: true })
			this.countInvestors()
		}
	}

	countInvestors = () => {
		this.props.fetchInvestorsCount((response) => {
			const totalNumOfInvestors = response.get('totalNumOfInvestors', '-').toString()
			const registeredInvestors = response.get('registeredInvestors', '-').toString()
			const unregisteredInvestors = response.get('unregisteredInvestors', '-').toString()

			this.setState({
				data: fromJS({ totalNumOfInvestors, registeredInvestors, unregisteredInvestors }),
				isLoading: false
			})
		})
	}

	remindUnregisteredUsers = () => {
		this.props.openModal(REMIND_SHAREHOLDERS_EMAIL_TEMPLATE_MODAL)
	}

	getHasAccessToShareholders = () => {
		const { menuCompanyResources } = this.props
		return getHasAccessToSection(menuCompanyResources, RESOURCE_SHAREHOLDERS)
	}

	render = () => {
		const { isLoading, data } = this.state
		const unregisteredInvestors = data.get('unregisteredInvestors')
		const hasUnregisteredInvestors = parseInt(unregisteredInvestors) > 0
		const getHasAccessToShareholders = this.getHasAccessToShareholders()
		const showRemindLink = getHasAccessToShareholders && hasUnregisteredInvestors

		return (
			<>
				<GenericInfoPanel title='company.dashboard.shareholders.panel.title' isLoading={isLoading}>
					<GenericInfoListItem
						label='company.dashboard.shareholders.panel.total_num_of_shareholders'
						value={data.get('totalNumOfInvestors')}
					/>
					<GenericInfoListItem
						label='company.dashboard.shareholders.panel.registered_shareholders'
						value={data.get('registeredInvestors')}
					/>
					<GenericInfoListItem
						label='company.dashboard.shareholders.panel.unregistered_shareholders'
						linkText='company.dashboard.shareholders.panel.unregistered_shareholders.link_text'
						onLinkClick={this.remindUnregisteredUsers}
						showLink={showRemindLink}
						value={unregisteredInvestors}
					/>
				</GenericInfoPanel>

				<RemindShareholdersModalContainer />
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		companyId: store.company.company.id,
		menuCompanyResources: store.menu.companyResources
	}
}

const mapActionsToProps = {
	fetchInvestorsCount,
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(ShareholdersPanelContainer)
