import React, { forwardRef } from 'react'
import { TextField } from 'mui-rff'

export const ShareClassWithTooltip = forwardRef((props, ref) => (
	<TextField
		{...props}
		inputRef={ref}
		inputProps={{
			...props,
			style: { backgroundColor: 'white', textTransform: 'uppercase' }
		}}
	/>
))
