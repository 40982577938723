import { createSelector } from 'reselect'

const transactionsAffectingCompanyValue = [
	'CHANGE-VOTES',
	'CONVERSION-OF-SHARES',
	'EMISSION',
	'GENESIS',
	'BONUS-ISSUE',
	'REDUCTION-OF-SHARE-CAPITAL',
	'SPLIT'
]
const transferTransactions = ['TRANSFER', 'TRANSFER-2']

export const transactionHistorySelector = createSelector(
	(state) => state.capTableDetails.get('combinedSingleInvestmentAndAllTransactions'),
	(_, investmentId) => investmentId,
	(combinedSingleInvestmentAndAllTransactions, investmentId) => {
		const filteredTransactions = combinedSingleInvestmentAndAllTransactions
			.get('transactions')
			.filter((transaction) => {
				if (transactionsAffectingCompanyValue.includes(transaction.get('type'))) {
					return true
				} else if (transferTransactions.includes(transaction.get('type'))) {
					return (
						transaction.getIn(['handlerData', 'investmentIdFrom']) === investmentId ||
						transaction.getIn(['handlerData', 'investmentIdTo']) === investmentId
					)
				}
			})

		return filteredTransactions
	}
)
