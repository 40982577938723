import React, { PureComponent } from 'react'
import { string, func, bool, object } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import styled from 'styled-components'
import Grid from 'styled-components-grid'
import { Padding } from 'styled-components-spacing'
import Label from '../../shared/label/label'
import DatePicker from '../../shared/datepicker/datepicker'
import TextArea from '../../shared/input/textarea'
import Moment from '../../../modules/moment.module'
import Text from '../../shared/text/text'
import { Select } from '../../shared/select'

import { MEETING_SUBTYPE_OPTIONS } from '../../../constants/meetings'
import { MEETING_SUBTYPE_DEFAULT, MEETING_SUBTYPE_AGM } from '/shared/constants'

import TabPanel from '../../shared/tabs-panel/tabs-panel'
import SimpleDarkInfoPanel from '../../shared/dark-info-panel/simple-dark-info-panel'
import SelectModeratorsModalContainer from '../../../containers/meetings/meeting/standard/select-moderators-modal.container'

const StyledOption = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
`

const StyledOptionLabel = styled.div`
	display: flex;
	flex: 1;
	margin-right: ${(props) => props.theme.spacing[2]};
`

class MeetingsGeneralInformation extends PureComponent {
	static propTypes = {
		onChange: func,
		meeting: map,
		userLang: string,
		onChangePanel: func,
		readOnly: bool,
		meetingIsDeleted: bool,
		onClickTab: func,
		generalInfoAlert: object,
		renderTagsContainer: func
	}

	renderTemplateOptionLabel = (option) => {
		return (
			<StyledOption>
				<StyledOptionLabel>
					<Text color={option.isDisabled ? 'muted' : undefined} tid={option.label}></Text>
				</StyledOptionLabel>
				{option.value === MEETING_SUBTYPE_DEFAULT && (
					<Text color='muted' tid='meetings.general.template_id.default' fontSize='11px' />
				)}
				{option.value === MEETING_SUBTYPE_AGM && (
					<Text tid='generic.beta.parenthesis' color='muted' fontSize='11px' hasItalic />
				)}
			</StyledOption>
		)
	}

	renderMeetingTab = (item, isActive) => {
		const { meetingIsDeleted } = this.props

		return (
			<>
				<Text tid='meetings.standard_meeting.tabs.meeting' color={isActive ? 'catalinaBlue' : 'midGrey'} bold={600} />

				{meetingIsDeleted && (
					<>
						&nbsp;
						<Text tid='generic.is_deleted' color='red' tag='em' />
					</>
				)}
			</>
		)
	}

	renderGeneralInformation = () => {
		const { onChange, meeting, userLang, readOnly, generalInfoAlert, renderTagsContainer } = this.props
		const minTime =
			meeting && meeting.get('startDate') && Moment(meeting.get('startDate')).add(1, 'minutes').toISOString()

		return (
			<Padding all={3}>
				{generalInfoAlert && generalInfoAlert}

				<Grid>
					<Grid.Unit size={1}>
						<Padding all={3}>
							<Label tid='meetings.meeting.standard.subtype.label'>
								<Select
									fieldName='meetingSubType'
									onChange={onChange}
									options={MEETING_SUBTYPE_OPTIONS}
									value={(meeting && meeting.get('meetingSubType')) || MEETING_SUBTYPE_DEFAULT}
									formatOptionLabel={this.renderTemplateOptionLabel}
								/>
							</Label>
						</Padding>
					</Grid.Unit>

					<Grid.Unit size={{ xs: 1, lg: 2 / 3, xl: 2 / 3 }}>
						<Padding all={3}>
							<Label tid='meetings.general.label.date'>
								<DatePicker
									fieldName='startDate'
									onChange={onChange}
									showTimeSelect={true}
									calendarPlacement='bottom-end'
									value={meeting && meeting.get('startDate') && Moment(meeting.get('startDate'))}
									language={userLang}
									disabled={readOnly}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
					<Grid.Unit size={{ xs: 1, lg: 1 / 3, xl: 1 / 3 }}>
						<Padding all={3}>
							<Label tid='meetings.general.label.end'>
								<DatePicker
									fieldName='endDate'
									onChange={onChange}
									showTimeSelectOnly={true}
									minTime={minTime}
									value={meeting && meeting.get('endDate') && Moment(meeting.get('endDate'))}
									language={userLang}
									disabled={readOnly}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>

				<Grid>
					<Grid.Unit size={1}>
						<Padding all={3}>
							<Label tid='meetings.general.label.meeting_name'>
								<TextArea
									fieldName='name'
									onChange={onChange}
									placeholderTid='meeting.general.placeholder.meeting_name'
									value={meeting && meeting.get('name')}
									disabled={readOnly}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>

				<Grid>
					<Grid.Unit size={1}>
						<Padding all={3}>
							<Label tid='meetings.general.label.meeting_objective'>
								<TextArea
									fieldName='objective'
									onChange={onChange}
									placeholderTid='meeting.general.placeholder.objective'
									value={meeting && meeting.get('objective')}
									disabled={readOnly}
								/>
							</Label>
						</Padding>
					</Grid.Unit>
				</Grid>

				{renderTagsContainer && (
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='generic.tags'>{renderTagsContainer()}</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
				)}
			</Padding>
		)
	}

	renderLocationInformation = () => {
		return (
			<Padding all={4}>
				<SimpleDarkInfoPanel>
					<Text tid='meetings.location.information' color='white' />
				</SimpleDarkInfoPanel>
			</Padding>
		)
	}

	render = () => {
		const { onClickTab } = this.props

		const tabs = [
			{
				tab: { rawRenderer: this.renderMeetingTab },
				body: this.renderGeneralInformation()
			},
			{
				tab: { tid: 'meetings.standard_meeting.tabs.location' },
				body: this.renderLocationInformation()
			}
		]

		return <><TabPanel tabs={tabs} onClickTab={onClickTab} /><SelectModeratorsModalContainer /></>
	}
}

export default MeetingsGeneralInformation
