import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { string, object, array, bool, node } from 'prop-types'

export default class TextBox extends Component {
	static propTypes = {
		showMoreText: string,
		showLessText: string,
		white: bool
	}

	static defaultProps = {
		showMoreText: 'generic.show_more',
		showLessText: 'generic.show_less',
		white: false
	}

	state = {
		open: false,
		showButton: false
	}

	componentDidMount = () => {
		const numOfParagraphs = this.refs.textboxDiv ? this.refs.textboxDiv.getElementsByTagName('p').length : 0
		this.setState({ showButton: numOfParagraphs >= 2 })
	}

	toggleText = () => {
		this.setState((prevState) => {
			return { open: !prevState.open }
		})
	}

	render = () => {
		const { showMoreText, showLessText, white, children } = this.props
		const { open, showButton } = this.state

		return (
			<div className={`textbox ${white ? 'textbox--white' : ''}`}>
				<div className={`textbox__text ${open ? 'textbox__text--open' : ''}`} ref='textboxDiv'>
					{children}
				</div>
				{showButton && (
					<div className='textbox__button'>
						<div className='textbox__button-text' onClick={this.toggleText}>
							<FormattedMessage id={open ? showLessText : showMoreText} />
						</div>
					</div>
				)}
			</div>
		)
	}
}
