import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func, bool } from 'prop-types'
import { MEETINGS_OUTCOMES, MEETINGS_CLOSED_ITEM_STATUS } from '../../../constants/meetings'
import DropdownStatusButton from '../../../dumb-components/shared/dropdown-status-button/dropdown-status-button'
import Text from '../../../dumb-components/shared/text/text'
import StyledStatusRectangle from '../../../dumb-components/shared/color-progress-select/styled-status-rectangle'
import Box from '../../../dumb-components/shared/layout/box/box'

class DropdownStatusButtonContainer extends Component {
	static propTypes = {
		fieldName: string,
		itemId: string,
		outcome: string,
		internalType: string,
		onChange: func,
		readOnly: bool
	}

	state = {
		isOpen: false
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleMouseDown, false)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleMouseDown, false)
	}

	handleMouseDown = (e) => {
		if (!this.node.contains(e.target)) {
			this.setState({ isOpen: false })
		}
	}

	setRef = (ref) => {
		this.node = ref
	}

	onChange = (val) => {
		const { onChange, fieldName } = this.props

		onChange && onChange(fieldName, val)
		this.setState({ isOpen: false })
	}

	onToggleStatusMenu = () => {
		this.setState((prevState) => {
			return { isOpen: !prevState.isOpen }
		})
	}

	getStatusColor = () => {
		const { outcome } = this.props

		switch (outcome) {
			case 'open':
				return 'green'
			case 'closed':
				return 'green'
		}

		const outcomeObject = MEETINGS_OUTCOMES.find((i) => i.get('value') === outcome)
		return outcomeObject ? outcomeObject.get('statusColor') : 'solitudeDark'
	}

	getStatusIcon = () => {
		const { outcome } = this.props

		switch (outcome) {
			case 'open':
				return 'faCheck'
			case 'closed':
				return 'faCheck'
			case 'todo':
				return 'faTimes'
			case 'passed':
				return 'faCheck'
			case 'tabled':
				return null
			case 'not_accepted':
				return null
			case 'recalled':
				return null
			case 'corrected':
				return null
			default:
				return 'faTimes'
		}
	}

	formatStatusOption = (option) => {
		return (
			<Box>
				<StyledStatusRectangle color={option.get('statusColor')} />
				<Text tid={option.get('label')} />
			</Box>
		)
	}

	render() {
		const { outcome, internalType, i18n, readOnly } = this.props
		const { isOpen } = this.state
		const statusIcon = this.getStatusIcon()
		const statusColor = this.getStatusColor()
		let options = MEETINGS_OUTCOMES

		if (internalType === 'close') {
			options = MEETINGS_CLOSED_ITEM_STATUS
		}

		return (
			<DropdownStatusButton
				onToggleMenu={this.onToggleStatusMenu}
				statusColor={statusColor}
				statusIcon={statusIcon}
				options={options}
				value={outcome}
				onChange={this.onChange}
				isOpen={isOpen}
				i18n={i18n}
				onSetRef={this.setRef}
				readOnly={readOnly}
				formatOption={this.formatStatusOption}
				showValueInMenu
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		i18n: store.i18n
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(DropdownStatusButtonContainer)
