import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { getInvestorsWithNoSsnOrOrgnr } from '../../../components/helpers/shares.js'
import history from '../../../interfaces/history.js'
import InvestorsWarningModal from '../../../dumb-components/shares/investors-warning-modal/investors-warning-modal'

class InvestorsWarningModalContainer extends Component {
	static propTypes = {
		isOpen: func,
		onCloseModal: func
	}

	closeModalAndGoToShareholders = () => {
		const { urlAlias, onCloseModal } = this.props
		onCloseModal()
		history.push(`/${urlAlias}/shares/register-of-shareholders`)
	}

	render = () => {
		const { isOpen, onCloseModal } = this.props
		const investorsWithWarning = getInvestorsWithNoSsnOrOrgnr()

		if (!isOpen) {
			return null
		}

		return (
			<InvestorsWarningModal
				isOpen={isOpen}
				investorsWithWarning={investorsWithWarning}
				onCloseAndGoToShareholders={this.closeModalAndGoToShareholders}
				onClose={onCloseModal}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		urlAlias: store.company.company.urlAlias
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(InvestorsWarningModalContainer)
