import React from 'react'
import { string, bool } from 'prop-types'
import Grid from '@mui/material/Grid'
import { FormattedMessage } from 'react-intl'
import StyledIcon from '../../../dumb-components/shared/icon/icon'
import MyRecordsDarkInfoPanel from '../../../dumb-components/investment/my-records/my-records-dark-info-panel'

export default function InvestmentSimulationContainer() {
	const onCloseMessage = () => {}

	return (
		<Grid container spacing={2}>
			{/* {alreadyDismissed && ( */}
			<Grid item xs={12}></Grid>
			{/* )} */}
			<Grid item xs={12}></Grid>
		</Grid>
	)
}
