import React, { PureComponent } from 'react'
import { string, func, bool, number } from 'prop-types'
import styled from 'styled-components'
import ImmutableProps from 'react-immutable-proptypes'
import Grid from 'styled-components-grid'
import { Padding } from 'styled-components-spacing'
import { PROTOCOL_PROGGRESS_OPTIONS } from '../../../constants/meetings'
import Panel from '../../shared/panel/panel'
import Label from '../../shared/label/label'
import { Select } from '../../shared/select'
import TextArea from '../../shared/input/textarea'
import Text from '../../shared/text/text'
import ColorProgressSelect from '../../shared/color-progress-select/color-progress-select'
import DurationSelect from '../../shared/duration-select/duration-select'
import Button from '../../shared/button/button'

import { AGENDA_TOPIC_NOTES_VISIBILITY_HIDE } from '/shared/constants'

const TitleRow = styled.div`
	display: flex;
	flex: 1;
	margin-bottom: -4px; /* Some random margin is applied for whatever reason */
`

const ProposalWrapper = styled.div`
	display: flex;
	flex: 1;
	padding: ${(props) => props.theme.spacing[3]};
`

const DurationWrapper = styled.div`
	display: flex;
	padding: ${(props) => props.theme.spacing[3]};
	width: 140px;
`

/* CSS templates */
const StyledPanelTitle = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

export default class AgendaDetails extends PureComponent {
	static propTypes = {
		onChange: func,
		id: string,
		proposal: string,
		description: string,
		presenter: string,
		itemType: string,
		outcome: string,
		duration: number,
		meetingNotes: string,
		progress: string,
		presenterOptions: ImmutableProps.list,
		itemTypeOptions: ImmutableProps.list,
		outcomeOptions: ImmutableProps.list,
		durationOptions: ImmutableProps.list,
		language: string,
		fullView: bool,
		renderNotes: func,
		renderTagsContainer: func,
		itemHasSubItems: bool,
		showProgressField: bool,
		outcomeReadOnly: bool,
		renderStatusPanelContainer: func,
		renderTopicNotes: func,
		topicNotesVisibility: string,
		switchTopicNotesVisibility: func
	}

	renderTitle = () => {
		const { proposal } = this.props

		return (
			<StyledPanelTitle>
				<Text tag='h4' color='catalinaBlue' singleLine>
					{proposal}
				</Text>
			</StyledPanelTitle>
		)
	}

	render = () => {
		const {
			onChange,
			proposal,
			presenter,
			itemType,
			duration,
			presenterOptions,
			itemTypeOptions,
			renderNotes,
			itemHasSubItems,
			renderStatusPanelContainer,
			renderTopicNotes,
			topicNotesVisibility,
			switchTopicNotesVisibility
		} = this.props

		return (
			<Panel leftComponent={this.renderTitle} marginBottom>
				<Padding all={3}>
					<TitleRow>
						<ProposalWrapper>
							<Label tid='meetings.agenda.form.title'>
								<TextArea
									fieldName='proposal'
									value={proposal || ''}
									onChange={onChange}
									placeholderTid='meetings.agenda.form.title.placeholder'
								/>
							</Label>
						</ProposalWrapper>

						<DurationWrapper>
							<Label tid='meetings.agenda.form.duration'>
								<DurationSelect
									fieldName='duration'
									placeholderTid='meetings.agenda.form.duration.placeholder'
									value={duration}
									onChange={onChange}
									isDisabled={itemHasSubItems}
								/>
							</Label>
						</DurationWrapper>
					</TitleRow>

					<Grid>
						<Grid.Unit size={1 / 2}>
							<Padding all={3}>
								<Label tid='meetings.agenda.form.presenter'>
									<Select
										fieldName='presenter'
										placeholderTid='meetings.agenda.form.presenter.placeholder'
										value={presenter}
										options={presenterOptions}
										onChange={onChange}
										isDisabled={itemHasSubItems}
									/>
								</Label>
							</Padding>
						</Grid.Unit>

						<Grid.Unit size={1 / 2}>
							<Padding all={3}>
								<Label tid='meetings.agenda.form.item_type'>
									<Select
										fieldName='itemType'
										placeholderTid='meetings.agenda.form.item_type.placeholder'
										value={itemType}
										options={itemTypeOptions}
										onChange={onChange}
										isDisabled={itemHasSubItems}
										labelIsTid
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>

					{renderTopicNotes && (
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label
									tid='meetings.agenda.topic_notes.title'
									rightComponent={() => (
										<Button
											mode='link'
											tid={
												topicNotesVisibility === AGENDA_TOPIC_NOTES_VISIBILITY_HIDE
													? 'meetings.agenda.topic_notes.visibility.show_topics'
													: 'meetings.agenda.topic_notes.visibility.hide_topics'
											}
											onClick={switchTopicNotesVisibility}
										/>
									)}>
									{renderTopicNotes()}
								</Label>
							</Padding>
						</Grid.Unit>
					)}

					{renderStatusPanelContainer && (
						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>{renderStatusPanelContainer()}</Padding>
							</Grid.Unit>
						</Grid>
					)}

					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>{renderNotes()}</Padding>
						</Grid.Unit>
					</Grid>

					{/*showProgressField && (
						<Grid>
							<Grid.Unit size={1 / 2}>
								<Padding all={3}>
									<Label tid="meetings.protocol.progress">
										<Select
											fieldName="progress"
											placeholderTid="meetings.protocol.form.progress.placeholder"
											value={progress || 'todo'}
											options={PROTOCOL_PROGGRESS_OPTIONS}
											onChange={onChange}
											labelIsTid />
									</Label>
								</Padding>
							</Grid.Unit>
						</Grid>
					)*/}
				</Padding>
			</Panel>
		)
	}
}
