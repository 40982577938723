import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import { NUMBER_FORMAT_CURRENCY } from '/shared/constants'
import { emissionConsts } from '../../../constants/emissions'

export function summarize(emission) {
	return {
		emissionAmount: emissionAmount(emission),
		sharesMinMax: sharesMinMax(emission),
		shareCapitalRaise: shareCapitalRaise(emission),
		dilutionEffect: dilutionEffect(emission),
		premiumReserveAmount: premiumReserveAmount(emission),
		registeredInterest: registeredInterest(emission),
		assignedToList: assignedToList(emission),
		amountOfPartialClosures: amountOfPartialClosures(emission),
		currentAllocation: currentAllocation(emission)
	}
}

function emissionAmount(emission) {
	const sharesMin = emission.newShareCountMin
	const sharesMax = emission.newShareCountMax
	const pricePerShare = emission.pricePerShare
	const {
		common: { newShareCountTypes }
	} = emissionConsts

	if (pricePerShare === null || (sharesMin === null && sharesMax === null)) {
		return '-'
	}

	const min = sharesMin * pricePerShare
	const max = sharesMax * pricePerShare

	if (pricePerShare === 0) {
		return `${min} - ${max}`
	}

	if (sharesMax === null) {
		return format(min) + ''
	}

	if (sharesMin === null) {
		return format(max) + ''
	}

	if (emission.newShareCountType === newShareCountTypes.range) {
		return `${format(min)} - ${format(max)}`
	} else {
		return format(max) + ''
	}
}

function sharesMinMax(emission) {
	const sharesMin = emission.newShareCountMin
	const sharesMax = emission.newShareCountMax
	const {
		common: { newShareCountTypes }
	} = emissionConsts

	if (sharesMin === null && sharesMax === null) {
		return '-'
	}

	function formatInteger(num) {
		const formatted = format(num) + ''
		return formatted.slice(0, formatted.length - 3)
	}

	if (sharesMax === null) {
		return formatInteger(sharesMin)
	}

	if (sharesMin === null) {
		return formatInteger(sharesMax)
	}

	if (emission.newShareCountType === newShareCountTypes.range) {
		return `${formatInteger(sharesMin)} - ${formatInteger(sharesMax)}`
	} else {
		return formatInteger(sharesMax)
	}
}

function shareCapitalRaise(emission) {
	const sharesMin = emission.newShareCountMin
	const sharesMax = emission.newShareCountMax
	const quoteValue = emission.quoteValue

	if (quoteValue === null || (sharesMin === null && sharesMax === null)) {
		return '-'
	}

	const min = sharesMin * quoteValue
	const max = sharesMax * quoteValue

	if (quoteValue === 0) {
		return `${min} - ${max}`
	}

	if (sharesMax === null) {
		return format(min) + ''
	}

	if (sharesMin === null) {
		return format(max) + ''
	}

	if (min === max) return `${format(min)}`

	return `${format(min)} - ${format(max)}`
}

function dilutionEffect(emission) {
	const round = (number) => Math.round((number + Number.EPSILON) * 100) / 100

	const sharesMin = emission.newShareCountMin
	const sharesMax = emission.newShareCountMax
	const currentShareCount = emission.currentShareCount

	if (currentShareCount === null || (sharesMin === null && sharesMax === null)) {
		return '-'
	}

	const min = (sharesMin / (currentShareCount + sharesMin)) * 100
	const max = (sharesMax / (currentShareCount + sharesMax)) * 100

	if (sharesMax === null) {
		return round(min) + ''
	}

	if (sharesMin === null) {
		return round(max) + ''
	}

	if (min === max) return `${round(min)}`
	return `${round(min)} - ${round(max)}`
}

function premiumReserveAmount(emission) {
	const price = emission.pricePerShare
	const quote = emission.quoteValue
	const sharesMin = emission.newShareCountMin
	const sharesMax = emission.newShareCountMax

	if (price === null || quote === null || (sharesMin === null && sharesMax === null)) {
		return '-'
	}

	const min = (price - quote) * sharesMin
	const max = (price - quote) * sharesMax

	if (sharesMax === null) {
		return format(min) + ''
	}

	if (sharesMin === null) {
		return format(max) + ''
	}

	if (min === max) return `${format(min)}`

	return `${format(min)} - ${format(max)}`
}

function format(num) {
	return localeFormatNumber(num, NUMBER_FORMAT_CURRENCY, { suffix: '' })
}

function registeredInterest(submissions) {
	let total = 0
	for (let i = 0; i < submissions.length; i++) {
		total += submissions[i].submission.shareCount ?? 0
	}
	return total
}

function assignedToList(submissions) {
	let total = 0
	for (let i = 0; i < submissions.length; i++) {
		total += (submissions[i].allocation.allocation ?? 0) + (submissions[i].allocation.previouslyAllocated ?? 0)
	}

	return total
}

function currentAllocation(submissions) {
	let total = 0
	for (let i = 0; i < submissions.length; i++) {
		total += submissions[i].allocation.allocation ?? 0
	}

	return total
}

function amountOfPartialClosures(submissions) {
	let total = 0
	for (let i = 0; i < submissions.length; i++) {
		total += submissions[i].allocation.previouslyAllocated ?? 0
	}
	return total
}
