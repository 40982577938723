import React, { PureComponent } from 'react'
import { func, bool } from 'prop-types'
import { list, map } from 'react-immutable-proptypes'
import styled from 'styled-components'
import Text from '../../shared/text/text'

import TaskDetailedList from '../../tasks/task-detailed-list/task-detailed-list'

const StyledSection = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[4]};
`

export default class ProtocolTasksList extends PureComponent {
	static propTypes = {
		tasks: list,
		usersCache: map,
		onChange: func,
		readOnly: bool
	}

	render = () => {
		const { tasks, usersCache, onChange, readOnly } = this.props

		return (
			<div>
				{tasks.size > 0 && (
					<TaskDetailedList
						tasks={tasks}
						usersCache={usersCache}
						onChange={onChange}
						readOnly={readOnly}
						showProposalColumn={true}
					/>
				)}
			</div>
		)
	}
}
