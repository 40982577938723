import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, bool } from 'prop-types'
import { Map } from 'immutable'
import uuid from 'uuid'

import { openModal } from '../../actions/modals.actions'
import { createInvestor } from '../../actions/investors.actions'
import { initNewInvestor } from '../../components/helpers/shares'

import TransactionToolbarControls from '../../dumb-components/shares/transaction-toolbar-controls/transaction-toolbar-controls'
import DropdownMenuContainer from '../../containers/shared/dropdown-menu.container'
import DropdownIconItem from '../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import InvestorsDetails from '../../components/company/shares/investors/investors-details'

import { SHARES_ADD_SHAREHOLDER_MODAL } from '../../constants/modals'

class TransactionToolbarControlsContainer extends Component {
	static propTypes = {
		onSubmit: func,
		onCancel: func,
		showAddShareholderControl: bool,
		onAddShareholder: func,
		addShareholderDisabled: bool
	}

	static defaultProps = {
		showAddShareholderControl: false
	}

	onSubmit = () => {
		const { onSubmit } = this.props

		onSubmit && onSubmit()
	}

	onCancel = () => {
		const { onCancel } = this.props
		this.moreActionBtnRef && this.moreActionBtnRef.onToggleMenu()
		onCancel && onCancel()
	}

	onAddShareholder = () => {
		const { onAddShareholder } = this.props
		initNewInvestor({ callback: onAddShareholder })
	}

	renderDropdownMenu = () => {
		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisH'
				halignMenu='right'
				btnMode='transparent-icon'
				ref={(ref) => (this.moreActionBtnRef = ref)}
				tooltipActiveState='btnMoreActions'
				noMaxWidth={true}>
				<DropdownIconItem tid='shares.transactions.actions.cancel_transaction' icon='faTimes' onClick={this.onCancel} />
			</DropdownMenuContainer>
		)
	}

	render() {
		const { addShareholderDisabled, activeModal, showAddShareholderControl, modalOptions, sharesRemaining, isEmission, hasNewShares } = this.props

		return (
			<>
				<TransactionToolbarControls
					onSubmit={this.onSubmit}
					showAddShareholderControl={showAddShareholderControl}
					onAddShareholder={this.onAddShareholder}
					addShareholderDisabled={addShareholderDisabled}
					dropdownRenderer={this.renderDropdownMenu}
					sharesRemaining={sharesRemaining}
					isEmission={isEmission}
					hasNewShares={hasNewShares}
				/>
				<InvestorsDetails mode='MODAL' actionMode='CREATE' onCloseModal={modalOptions?.get('onCloseModal')} isOpen={activeModal === SHARES_ADD_SHAREHOLDER_MODAL} />
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		activeModal: store.modals.getIn(['activeModal', 'name']),
		modalOptions: store.modals.getIn(['activeModal', 'options'])
	}
}

const mapActionsToProps = {
	openModal,
	createInvestor
}

export default connect(mapStoreToProps, mapActionsToProps)(TransactionToolbarControlsContainer)
