import { css } from 'styled-components';
import { lighten, darken } from 'polished';

const linux = navigator.platform.includes('Linux');
const firefox = navigator.userAgent.includes('Firefox');

const commonGridCss = css`
	color: ${(props) => props.theme.colors.midGrey};
	font-size: ${(props) => props.theme.fontSizes.default};
	font-family: ${(props) => props.theme.fontFamilies.openSans};

	width: 100%;
	height: 100%;

	.ag-cell {
		font-family: ${(props) => props.theme.fontFamilies.openSans};
		font-size: ${(props) => props.theme.fontSizes.default};
		/*color: ${(props) => props.theme.colors.midGrey};*/
		font-weight: 400;
	}

	.ag-header-cell {
		font-weight: normal;
		font-size: 13px;
		font-family: ${(props) => props.theme.fontFamilies.openSans};
		color: ${(props) => props.theme.colors.midGrey};
		font-weight: ${(props) => props.theme.bold.h1};
	}

	.ag-header-cell-label .ag-sort-order {
		display: none;
	}

	.ag-theme-material .ag-icon-filter::before {
		color: ${(props) => lighten(0.3, props.theme.colors.midGrey)};
	}

	.ag-theme-material {
		${({ noEvenRowBackground }) => {
			if (!noEvenRowBackground) {
				return css`
					--ag-even-row-background-color: ${(props) => props.theme.colors.lightestGrey};
					--ag-data-color: ${(props) => props.theme.colors.midGrey};
					--ag-material-accent-color: ${(props) => props.theme.colors.java};
				`;
			}
		}}

		${({ ordinaryTextColor }) => {
			if (ordinaryTextColor) {
				return css`
					--ag-data-color: ${(props) => props.theme.colors.midGrey};
				`;
			}
		}}
	}

	.ag-theme-material .ag-row-even {
		background-color: var(--ag-even-row-background-color);
	}

	.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
		background-color: ${(props) => props.theme.colors.white};
	}

	/* Used in Privately Owned Grid */

	.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.open-investment-btn.ag-right-aligned-cell.ag-cell-value {
		padding: 5px 23px;
		display: flex;
		flex: 1;
		flex-direction: row-reverse;

		button {
			padding: 10px 12px;
			border-radius: 0;
			border: 1px solid transparent;
			background-color: #f9f9f9;
			border-color: #d1d9de;
			color: #6c6c6c;
			display: flex;
			flex: 1;
			justify-content: center;
			max-width: fit-content;

			:hover {
				background-color: #e8e8e8;
				border-color: #d1d9de;
				color: #6c6c6c;
				transition: all 0.25s;
			}
		}

		> button > span {
			align-self: center;
		}
	}

	.ag-cell.ag-cell-normal-height.ag-cell-inline-editing.ag-cell-value {
		padding: 0;
		height: -webkit-fill-available;
		border: 0;
		box-shadow: none;
		background-color: white;

		& input {
			height: 100%;
			border-radius: 0;
			border: 1px solid;
		}

		& input:focus-visible {
			outline: 0;
			border: 1px solid ${(props) => props.theme.colors.borderBlue};
			border-radius: 0;
		}
	}

	.ag-body-horizontal-scroll {
		margin-bottom: 8px;

		&-container {
			display: ${linux && firefox ? 'none;' : 'block;'};
		}
	}

	.ag-horizontal-left-spacer {
		height: 0;
	}

	.ag-body-viewport {
		scroll-behavior: smooth;
	}

	.ag-body-horizontal-scroll-viewport,
	.ag-body-viewport.ag-row-no-animation.ag-layout-normal {
		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background-clip: padding-box;
			background-color: rgba(0, 0, 0, 0.2);
			border: 3px solid rgba(0, 0, 0, 0.2);
			border-radius: 3px;
		}

		&::-webkit-scrollbar:horizontal {
			height: 6px;
		}
	}

	.ag-root-wrapper {
		overflow: visible;
	}

	.ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child {
		max-height: fit-content;
	}

	.custom-tooltip {
		width: fit-content;
		height: fit-content;
		background-color: ${(props) => props.theme.colors.white};
		box-shadow: 0 5px 12px 2px rgba(0, 0, 0, 0.25);
	}

	.custom-tooltip table {
		margin: ${(props) => props.theme.spacing[4]};
		white-space: nowrap;
	}

	.custom-tooltip:empty {
		display: none;
	}

	.first-column-accumulated {
		&:before {
			content: '';
			position: absolute;
			inset: 0;
			top: -1px;
			background: transparent;
			border-top: 1px solid ${(props) => lighten(0.5, props.theme.colors.darkGrey)};
			z-index: 100;
		}
	}
	.bold .ag-cell {
		font-weight: bold;
	}

	/* Used to style share types. */

	//.first-column-sharetype {
	//	//background-color: ${(props) => lighten(0.3, props.theme.colors.lightGrey)} !important;
	//}

	.ag-theme-material .ag-row-hover {
		background-color: #ecf0f1 !important;
	}

	.grouped-grid .ag-row-group {
		background-color: #d9e1e7;
	}

	.ag-theme-material .ag-row-group .ag-group-value {
		text-transform: uppercase;
	}

	.ag-theme-material .ag-row-group.ag-row-level-1 .ag-group-value {
		text-transform: unset;
	}

	.ag-virtual-list-container {
		overflow: initial;
	}

	.ag-column-select-list {
		margin: 0 12px;
	}

	.ag-virtual-list-item {
		padding-left: 0;
	}

	.ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-last-left-pinned.text-indent-cell.ag-cell-value {
		text-indent: ${(props) => props.theme.spacing[4]};
	}

	.linkButton-linked {
		background: ${(props) => lighten(0.6, props.theme.colors.green)} !important;
	}

	.linkButton-link-broken {
		background: ${(props) => lighten(0.4, props.theme.colors.textError)} !important;
	}

	.ag-header-cell.tick-header.ag-focus-managed .MuiBox-root .customHeaderMenuButton {
		visibility: hidden;
	}

	.ag-header-cell.tick-header.ag-focus-managed.ag-header-active .MuiBox-root .customHeaderMenuButton {
		visibility: visible;
	}

	.ag-header-cell.tick-header.ag-focus-managed {
		flex-direction: row;
	}

	.more-action-btn-icon {
		text-align: center;
	}

	.tick-cell .fa-check {
		vertical-align: middle;
	}

	.incomplete-transaction-form {
		visibility: hidden;
	}

	.ag-header-icon.ag-header-cell-menu-button {
		cursor: pointer;
	}

	.ag-theme-material .ag-ltr .center-aligned-cell {
		text-align: center;
	}

	.grouped-grid {
		&.ag-theme-material .ag-ltr .ag-row > .ag-cell-wrapper.ag-row-group-indent-1 {
			padding-left: 30px;
		}

		.ag-pinned-left-cols-container .ag-row .ag-cell:first-child {
			padding-left: 34px;
		}

		.ag-pinned-left-cols-container .ag-row-level-2 .ag-cell:first-child {
			padding-left: 56px;
		}

		.ag-group-expanded {
			margin-right: 8px;
		}

		&.ag-theme-material .ag-ltr .ag-row > .ag-cell-wrapper.ag-cell-expandable.ag-row-group.ag-row-group-indent-0 {
			padding-left: 8px;
		}
	}

	.ag-theme-material .ag-cell.checkbox-cell {
		padding: 0 8px;
	}
	.ag-theme-material .ag-header-cell.checkbox-cell {
		padding: 0 8px;
	}

	.ag-theme-material .ag-cell.profile-image-cell,
	.ag-theme-material .ag-header-cell.profile-image-cell,
	.ag-theme-material .ag-cell.more-actions {
		padding: 0;
	}

	.ag-theme-material .ag-cell.profile-image-cell {
		.ag-cell-wrapper {
			height: 100%;

			.ag-cell-value {
				height: 100%;
			}
		}
	}

	.warning-row {
		background-color: ${(props) => lighten(0.4, props.theme.colors.textError)} !important;
	}
`;
export const StyledAgGridPanelTemplate = css`
	${commonGridCss};

	/*Link Transaction START*/

	.more-action-btn-icon {
		line-height: normal;
		padding-top: ${(props) => props.theme.spacing[3]};
		padding-right: ${(props) => props.theme.spacing[2]};
		padding-left: 0px;
	}

	.link-transaction-date-header {
		padding-right: ${(props) => props.theme.spacing[3]};
		padding-left: 12px;
	}

	.link-transaction-date {
		line-height: normal;
		padding-top: ${(props) => props.theme.spacing[4]};
		padding-right: ${(props) => props.theme.spacing[3]};
		padding-left: 12px;
	}

	.link-transaction-description-header {
		padding-right: ${(props) => props.theme.spacing[3]};
		padding-left: ${(props) => props.theme.spacing[4]};
	}

	.link-transaction-description {
		line-height: normal;
		word-break: normal;
		padding: ${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[3]}
			${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[4]};
	}

	.ag-root.ag-unselectable.ag-layout-normal {
		${({ overflowAuto }) => {
			if (overflowAuto) {
				return css`
					overflow: auto;
				`;
			}
		}}
	}

	.ag-theme-material .ag-rich-select-list {
		height: auto;
	}

	/*Link Transaction END*/
`;

export const StyledAgGridTemplate = css`
	${commonGridCss};

	.ag-group-expanded,
	.ag-group-contracted {
		&:after {
			content: '${(props) => props.expanderText}';
			text-decoration: underline;
			font-size: ${(props) => props.theme.fontSizes.default};
			color: ${(props) => props.theme.colors.midGrey};
		}
		/*.ag-icon {
			display: none !important;
		}*/
		&:hover:after {
			color: ${(props) => props.theme.colors.midGrey};
			transition: color 0.3s;
		}
	}

	.ag-theme-material .ag-rich-select-list {
		height: 100%;
	}

	.ag-column-select-column-label,
	.ag-picker-field-display,
	.ag-picker-field-display-list {
		font-family: ${(props) => props.theme.fontFamilies.openSans};
		color: ${(props) => props.theme.colors.midGrey};
		font-weight: normal;
	}

	.ag-header-cell.text--error.ag-right-aligned-header {
		color: ${(props) => props.theme.colors.textError};
	}

	.ag-rich-select-value,
	.ag-rich-select-list,
	.ag-list.ag-select-list.ag-ltr.ag-popup-child {
		font-family: ${(props) => props.theme.fontFamilies.openSans};
		font-size: ${(props) => props.theme.fontSizes.default};
		color: ${(props) => props.theme.colors.midGrey};
		font-weight: normal;
	}

	.ag-header-cell.ag-floating-filter.ag-focus-managed[aria-colindex='4'] > div {
		visibility: hidden;
	}
`;
