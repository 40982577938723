import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'
import { remindUsers } from '../../../../actions/user-management.actions'
import { fromJS } from 'immutable'

import NotifySingleUserModalContainer from '../../generic-modals/notify-single-user-modal.container'

import { REMIND_USER_BY_EMAIL_TEMPLATE_MODAL } from '../../../../constants/modals'
import { EMAIL_TEMPLATES_INVITE_USER_TO_COMPANY } from '/shared/constants'

class RemindUserModalContainer extends Component {
	sendReminder = (workingTemplate, closeModalCallback, errorCallback) => {
		const { user, remindUsers } = this.props
		const userId = user.get('id') || user.get('email')
		const userToRemind = fromJS([userId])
		remindUsers(userToRemind, workingTemplate, closeModalCallback, errorCallback)
	}

	render = () => {
		return (
			<NotifySingleUserModalContainer
				templateName={EMAIL_TEMPLATES_INVITE_USER_TO_COMPANY}
				modalName={REMIND_USER_BY_EMAIL_TEMPLATE_MODAL}
				onSubmit={this.sendReminder}
				successNotificatonTid='user_management.unregistered_users_reminded_to_register'
				errorNotificationTid='user_management.error.remind_unregistered_users'
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		user: store.modals.getIn(['activeModal', 'options', 'user'])
	}
}

const mapActionsToProps = {
	remindUsers
}

export default connect(mapStoreToProps, mapActionsToProps)(RemindUserModalContainer)
