import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { string, func, bool, oneOfType, number } from 'prop-types'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { StyledInputTemplate } from '../input/input.template'
import InputAddon from '../input/input-addon'

const StyledNumberFormat = styled(NumberFormat)`
	${StyledInputTemplate}
`

const NumberFormatInput = ({
	placeholder,
	thousandSeparator,
	decimalSeparator,
	decimalScale,
	format,
	value,
	disabled,
	onChange,
	icon,
	isLoading,
	allowSpecialSymbols,
	fieldName,
	error,
	prefix
}) => {
	const _onChange = (val) => {
		const newValue = val.floatValue
		if (fieldName) {
			onChange(fieldName, newValue)
		} else {
			onChange(newValue)
		}
	}

	const _onKeyDown = (e) => {
		const SPECIAL_SYMBOLS = ['-', '+']
		const { key } = e

		// Disallow comma
		if (!decimalScale && key === decimalSeparator) {
			e.preventDefault()
		}

		// Disallow symbols inside SPECIAL_SYMBOLS by default
		if (!allowSpecialSymbols && SPECIAL_SYMBOLS.includes(key)) {
			e.preventDefault()
		}
	}

	const props = {
		placeholder,
		thousandSeparator,
		decimalSeparator,
		decimalScale,
		format,
		value,
		disabled,
		onValueChange: _onChange,
		onKeyDown: _onKeyDown,
		haserror: error && error.size > 0,
		prefix
	}

	if (icon || isLoading) {
		return (
			<InputAddon isLoading={isLoading} icon={icon}>
				<StyledNumberFormat {...props} />
			</InputAddon>
		)
	}

	return <StyledNumberFormat {...props} />
}

NumberFormatInput.defaultProps = {
	thousandSeparator: ' ',
	decimalSeparator: ',',
	decimalScale: 0,
	disabled: false,
	allowSpecialSymbols: false,
	value: null,
	icon: null,
	isLoading: false
}

NumberFormatInput.propTypes = {
	value: oneOfType([string, number]),
	type: string,
	thousandSeparator: string,
	decimalSeparator: string,
	decimalScale: number,
	disabled: bool,
	placeholder: string,
	allowSpecialSymbols: bool,
	icon: string,
	isLoading: bool,
	format: string,
	error: ImmutablePropTypes.list,
	prefix: string
}

export default NumberFormatInput
