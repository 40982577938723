/**
 * Auth reducer
 * @module reducers/auth.reducer
 */

import {
	CREDENTIALS_RESPONSE,
	CREDENTIALS_SET_AUTENTICATED,
	CREDENTIALS_RESET,
	CREDENTIALS_SIGNOUT,
	CREDENTIALS_SET_TID,
	CREDENTIALS_RESET_TIDS,
	CREDENTIALS_RESET_FORGOT_PASSWORD
} from '../actions/types'
import { fromJS } from 'immutable'

/** The initial state object for notify. */
const INITIAL_STATE = fromJS({
	step: 0,
	status: null,
	translationId: null,
	authenticated: false,
	sessionId: null,
	bankIdMessage: null,
	ssn: null
})

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case CREDENTIALS_RESPONSE: {
			const { step, translationId, status, sessionId, bankIdMessage, ssn, orderRef } = action.payload
			state = state.set('step', step)
			state = state.set('status', status)
			state = state.set('translationId', translationId)
			state = state.set('sessionId', sessionId)
			state = state.set('bankIdMessage', bankIdMessage)
			state = state.set('orderRef', orderRef)

			if (ssn) {
				state = state.set('ssn', ssn)
			}
			break
		}

		case CREDENTIALS_SET_TID: {
			state = state.set('bankIdMessage', action.payload)
			state = state.set('status', 'pending')
			break
		}

		case CREDENTIALS_RESET: {
			state = state.set('step', 0)
			state = state.set('status', null)
			state = state.set('translationId', null)
			state = state.set('sessionId', null)
			state = state.set('bankIdMessage', null)
			break
		}

		case CREDENTIALS_RESET_FORGOT_PASSWORD: {
			state = state.set('step', 0)
			state = state.set('sessionId', null)
			state = state.set('bankIdMessage', null)
			break
		}

		case CREDENTIALS_SET_AUTENTICATED: {
			state = state.set('authenticated', action.payload)
			break
		}

		case CREDENTIALS_SIGNOUT: {
			state = state.set('authenticated', false)
			break
		}

		case CREDENTIALS_RESET_TIDS: {
			state = state.set('translationId', null)
			state = state.set('bankIdMessage', null)
			break
		}
	}

	return state
}
