import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchMeeting } from '../../../actions/meetings.actions'
import SplitView from '../../../dumb-components/shared/layout/split-view/split-view'
import ScrollView from '../../../dumb-components/shared/layout/scroll-view/scroll-view'
import ScrollViewHeader from '../../../dumb-components/shared/scroll-view-header/scroll-view-header'
import ProtocolTabsContainer from '../protocol/protocol-tabs.container'
import LocationsContainer from '../location-panels/locations.container'

import MeetingsGeneralContainer from '../meeting/meeting-general.container'
import ProtocolToolbarContainer from '../meeting/smart-meeting/protocol-toolbar.container'

import { calculateMaxWidthOfSplitView, toggleVisibilityOfTabs } from '../../../components/helpers/tabs.helper'

class MeetingInformationContainer extends Component {
	// References to the scrollviews
	scrollViewRefs = {}

	// Reference to the tabs
	tabsRef

	// Timer
	resizeEnd

	state = {
		hiddenTabs: []
	}

	componentDidMount = () => {
		window.addEventListener('resize', this.onWindowResize, false)
		this.onWindowResize()
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.onWindowResize, false)
	}

	onWindowResize = () => {
		let { hiddenTabs } = this.state
		const maxWidth = calculateMaxWidthOfSplitView(this.scrollViewRefs)
		hiddenTabs = toggleVisibilityOfTabs(this.tabsRef, hiddenTabs, { maxWidth })

		this.setState({ hiddenTabs })
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('info')
		return pathArray[0] + 'info'
	}

	renderLeftHeader = () => {
		const { location } = this.props
		const { hiddenTabs } = this.state

		return (
			<ProtocolTabsContainer
				match='info'
				location={location}
				onSetRef={(ref) => (this.tabsRef = ref)}
				hiddenTabs={hiddenTabs}
			/>
		)
	}

	renderLeftSplitView = () => {
		const { location, params } = this.props

		return (
			<ScrollView
				header={this.renderLeftHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs['left'] = ref)}>
				<MeetingsGeneralContainer params={params} location={location} renderMode='full' />
			</ScrollView>
		)
	}

	renderRightHeader = () => {
		const { meeting, params, location } = this.props
		const basePath = this.getComponentBasePath()

		return (
			<ScrollViewHeader>
				<ProtocolToolbarContainer location={location} basePath={basePath.split('/info')[0]} params={params} />
			</ScrollViewHeader>
		)
	}

	renderRightSplitView = () => {
		return (
			<ScrollView
				header={this.renderRightHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				onSetRef={(ref) => (this.scrollViewRefs['right'] = ref)}>
				<LocationsContainer />
			</ScrollView>
		)
	}

	render = () => {
		return <SplitView leftComponent={this.renderLeftSplitView} rightComponent={this.renderRightSplitView} />
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get('meeting'),
		audit: store.audit.get('meetings')
	}
}

const mapActionsToProps = {
	fetchMeeting
}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingInformationContainer)
