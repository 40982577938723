import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { bool, func, string, oneOfType, node } from 'prop-types'
import Text from '../../../shared/text/text'

const StyledTableRow = styled.div`
	display: flex;
	min-height: 45px;
	width: 100%;
	border-bottom: 1px solid #ddd;
`

const StyledLabel = styled.div`
	min-width: 20%;
	background-color: ${(props) => props.theme.colors.silver};
	padding: ${(props) => props.theme.spacing[4]};
`

const StyledContent = styled.div`
	display: flex;
	flex: 1;
	padding: ${(props) => props.theme.spacing[4]};
	background-color: ${(props) => props.theme.colors.white};
`

export default class ProtocolTableRow extends PureComponent {
	static propTypes = {
		labelTid: string,
		labelText: string,
		content: oneOfType([string, node]),
		contentTid: string,
		contentIsHtml: bool
	}

	static defaultProps = {
		contentIsHtml: false
	}

	render = () => {
		const { labelTid, labelText, content, contentTid, contentIsHtml } = this.props

		return (
			<StyledTableRow>
				<StyledLabel>
					{labelTid && <Text bold={700} tid={labelTid} />}
					{labelText && <Text bold={700}>{labelText}</Text>}
				</StyledLabel>
				<StyledContent>
					{content && typeof content === 'string' && <Text asHtml={contentIsHtml}>{content}</Text>}
					{content && typeof content !== 'string' && content}
					{contentTid && <Text tid={contentTid} />}
				</StyledContent>
			</StyledTableRow>
		)
	}
}
