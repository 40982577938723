import React, { Component } from 'react'
import { connect } from 'react-redux'
import { oneOfType, bool, func } from 'prop-types'
import { map } from 'react-immutable-proptypes'

import { signAndCreateCompany } from '../../../actions/company.actions'

import StepsBankID from '../../../dumb-components/shared/bankid/steps-bankid'

class InitAuthorizeCompanyContainer extends Component {
	state = {
		bankIdTransaltionId: null
	}

	static propTypes = {
		authData: oneOfType([map, bool]),
		onCompanyAuthorised: func
	}

	static defaultProps = {
		authData: false
	}

	componentDidUpdate = (prevProps) => {
		const { authData } = this.props

		if (authData && authData !== prevProps.authData) {
			this.initializeAuthorization()
		}
	}

	getContinueBankIdCollect = () => {
		return this.props.authData ? true : false
	}

	callbackBankId = (bankIdData) => {
		this.setState({ bankIdTransaltionId: bankIdData.bankIdMessage })
	}

	successCallback = (responseData) => {
		const { onCompanyAuthorised } = this.props

		this.setState({ bankIdTransaltionId: null })

		onCompanyAuthorised && onCompanyAuthorised(responseData)
	}

	errorCallback = () => {
		this.setState({ bankIdTransaltionId: null })
	}

	initializeAuthorization = () => {
		const { authData, userEmail, userName, userPhone } = this.props

		const companyOrgNumber = authData.get('companyOrgNumber')
		const companyName = authData.get('companyName')
		const toBeAuthorizedBy = authData.get('toBeAuthorizedBy')

		this.props.signAndCreateCompany(
			companyOrgNumber,
			toBeAuthorizedBy,
			userEmail,
			userName,
			companyName,
			userPhone,
			this.callbackBankId,
			this.successCallback,
			this.errorCallback,
			this.getContinueBankIdCollect
		)
	}

	render = () => {
		return <StepsBankID tid={this.state.bankIdTransaltionId} />
	}
}

const mapStoreToProps = (store) => {
	return {
		userEmail: store.user.getIn(['userObj', 'email']),
		userName: store.user.getIn(['userObj', 'name']),
		userPhone: store.user.getIn(['userObj', 'phone'])
	}
}

const mapDispatchToProps = {
	signAndCreateCompany
}

export default connect(mapStoreToProps, mapDispatchToProps)(InitAuthorizeCompanyContainer)
