import React, { PureComponent } from 'react'
import immutablePropTypes from 'react-immutable-proptypes'
import { FixedSizeList as List } from 'react-window'
import { string, object, bool, func, number } from 'prop-types'
import styled from 'styled-components'
import { Map } from 'immutable'
import { findProxyByAttendeeId, isProxyConfirmedForAttendee } from '../../../components/helpers/meeting.helper'
import StyledList from '../../shared/list/list'
import ListItem from '../../shared/list-item/list-item'
import FakeShowMore from '../../shared/fake-show-more/fake-show-more'
import { InfiniteScroll, hideInfiniteScrollbars } from '../../shared/infinite-scroll'

const StyledListItem = styled(ListItem)`
	margin-top: ${(props) => props.theme.spacing[2]};
	margin-bottom: 0px;
`

const StyledTransparentListItem = styled(StyledListItem)`
	background-color: transparent;
`

const StyledInfiniteScroll = styled(InfiniteScroll)`
	${hideInfiniteScrollbars}
`

export default class AttendeesList extends PureComponent {
	static propTypes = {
		attendees: immutablePropTypes.list,
		renderItemComponent: func,
		renderAddAttendeeComponent: func,
		addPreviousAttendeesComponent: object,
		currentUrl: string,
		basePath: string,
		proxies: immutablePropTypes.map,
		clickable: bool,
		attendeesRenderLimit: number,
		onClickShowMoreAttendees: func,
		useReactWindow: bool,
		reactWindowHeight: number,
		scrollableTarget: string
	}

	static defaultProps = {
		attendees: Map()
	}

	renderAttendee = (attendee, index) => {
		const { renderItemComponent, basePath, currentUrl, proxies, clickable } = this.props

		if (attendee.get('isProxy') === true) {
			return null
		}

		const hoverColor = clickable ? 'lightestGrey' : undefined
		const active = currentUrl === `${basePath}/${attendee.get('userId')}`
		const proxy = findProxyByAttendeeId(proxies, attendee.get('userId'))
		const hasProxy = proxy && isProxyConfirmedForAttendee(proxy, attendee.get('userId'))

		return (
			<StyledListItem active={active} hoverColor={hoverColor} key={index}>
				{renderItemComponent({
					index,
					userId: attendee.get('userId'),
					isGuest: attendee.get('isGuest'),
					isInvestor: attendee.get('isInvestor'),
					isProxyForCompany: attendee.get('isProxyForCompany'),
					haveResponded: attendee.get('haveResponded'),
					status: attendee.get('done', attendee.get('status')),
					notified: attendee.get('notified'),
					notifiedAboutChanges: attendee.get('notifiedAboutChanges'),
					notifyProxyInvite: attendee.get('notifyProxyInvite'),
					roles: attendee.get('roles'),
					active: attendee.get('active'),
					hasProxy,
					proxy,
					assistantForAttendee: attendee.get('assistantForAttendee'),
					clickable,
					responseStatus: attendee.get('responseStatus')
				})}
			</StyledListItem>
		)
	}

	renderAttendeeWithReactWindow = (attendees, { index, style }) => {
		const attendee = attendees.get(index)
		return <div style={style}>{this.renderAttendee(attendee, index)}</div>
	}

	renderListOfAttendees = () => {
		const {
			attendeesRenderLimit,
			onToggleShowAllAttendees,
			useReactWindow,
			reactWindowHeight,
			onSetListRef,
			loadAttendees,
			hasMoreAttendees,
			scrollableTarget
		} = this.props
		let { attendees } = this.props

		if (!attendees) {
			return null
		}

		if (attendeesRenderLimit && attendees.size > attendeesRenderLimit) {
			attendees = attendees.slice(0, attendeesRenderLimit)

			return (
				<FakeShowMore textTid='meetings.standard.attendees.panel.view_full_list' onToggle={onToggleShowAllAttendees}>
					{attendees.map(this.renderAttendee)}
				</FakeShowMore>
			)
		}

		if (attendeesRenderLimit === 0) {
			return (
				<FakeShowMore
					textTid='meetings.standard.attendees.panel.show_less'
					onToggle={onToggleShowAllAttendees}
					expanded>
					<StyledInfiniteScroll
						dataLength={attendees.size}
						next={loadAttendees}
						hasMore={hasMoreAttendees}
						scrollableTarget={scrollableTarget}>
						{attendees.map(this.renderAttendee)}
					</StyledInfiniteScroll>
				</FakeShowMore>
			)
		}

		if (useReactWindow) {
			return (
				<List
					itemSize={54}
					itemCount={attendees.size}
					height={reactWindowHeight}
					style={{ overflow: 'visible' }}
					ref={onSetListRef}>
					{this.renderAttendeeWithReactWindow.bind(null, attendees)}
				</List>
			)
		}

		return (
			<StyledInfiniteScroll
				dataLength={attendees.size}
				next={loadAttendees}
				hasMore={hasMoreAttendees}
				scrollableTarget={scrollableTarget}>
				{attendees.map(this.renderAttendee)}
			</StyledInfiniteScroll>
		)
	}

	render = () => {
		const { renderAddAttendeeComponent, addPreviousAttendeesComponent } = this.props

		return (
			<StyledList>
				{this.renderListOfAttendees()}
				{renderAddAttendeeComponent && (
					<>
						<StyledTransparentListItem>{renderAddAttendeeComponent()}</StyledTransparentListItem>
						{addPreviousAttendeesComponent}
					</>
				)}
			</StyledList>
		)
	}
}
