import req from '../modules/request.module'
import { Map, fromJS } from 'immutable'
import { convertBodyHTMLToDraftJsInArray } from '../components/helpers/email-templates.helpers'

import { EMAIL_TEMPLATES_FETCH_REMOTE, EMAIL_TEMPLATE_RESET, EMAIL_TEMPLATE_UPDATE_LOCAL } from './types'

export function fetchEmailTemplate(templateName, callback) {
	return function (dispatch) {
		req
			.get(`/notifications/email-templates/${templateName}`)
			.then((response) => {
				const responseData = fromJS(response.data)
				const emailTemplates = responseData.get('templates')
				const emailTemplatesDefault = convertBodyHTMLToDraftJsInArray(responseData.get('defaultTemplates'))
				const returnData = Map({ emailTemplates, emailTemplatesDefault })

				dispatch({ type: EMAIL_TEMPLATES_FETCH_REMOTE, payload: returnData })
				callback && callback(returnData)
			})
			.catch((e) => {
				console.log(e)
			})
	}
}

export function saveEmailTemplate(template, callback) {
	return function (dispatch) {
		req
			.post(`/notifications/email-templates`, { template })
			.then((response) => {})
			.catch((e) => {
				console.log(e)
			})
	}
}

export function resetEmailTemplate(template, callback) {
	const templateName = template.get('name')
	const templateLanguage = template.get('language')

	return function (dispatch) {
		req
			.delete(`/notifications/email-templates/${templateName}/${templateLanguage}`)
			.then((response) => {
				const removedTemplate = fromJS(response.data)
				dispatch({ type: EMAIL_TEMPLATE_RESET, payload: fromJS({ removedTemplate, templateName, templateLanguage }) })
				callback && callback(removedTemplate)
			})
			.catch((e) => {
				console.log(e)
			})
	}
}

export function updateEmailTemplateLocal(template) {
	return function (dispatch) {
		dispatch({ type: EMAIL_TEMPLATE_UPDATE_LOCAL, payload: template })
	}
}
