import React, { Component } from 'react'
import { string, object, array, bool, func } from 'prop-types'
import FieldError from '../shared/field-error/field-error'
import { Creatable } from 'react-select'

export default class SelectCreatable extends Component {
	static propTypes = {
		clearable: bool,
		className: string,
		options: array,
		promptText: string,
		simpleValue: bool,
		value: string,
		hasError: string,
		onChange: func,
		placeholder: string,
		promptTextCreator: func,
		onErrorRef: func
	}

	static defaultProps = {
		className: '',
		clearable: false,
		simpleValue: false
	}

	render = () => {
		const {
			className,
			value,
			options,
			onChange,
			promptText,
			clearable,
			placeholder,
			noResultsText,
			promptTextCreator,
			simpleValue,
			onInputChange,
			optionComponent,
			hasError,
			onErrorRef
		} = this.props

		return (
			<FieldError hasError={hasError} onSetRef={onErrorRef}>
				<Creatable
					className={className}
					clearable={clearable}
					simpleValue={simpleValue}
					value={value}
					options={options}
					onChange={onChange}
					optionComponent={optionComponent}
					onInputChange={onInputChange}
					placeholder={placeholder}
					noResultsText={noResultsText}
					promptText={promptText}
					promptTextCreator={promptTextCreator}
				/>
			</FieldError>
		)
	}
}
