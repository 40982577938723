import React, { Component, Profiler } from 'react'
import { string, object, oneOfType, bool, func, number } from 'prop-types'
import styled, { css } from 'styled-components'
import List from '../../shared/list/list'
import LineSeparator from '../../shared/line-separator/line-separator'
import ScrollView from '../../shared/layout/scroll-view/scroll-view'
import { Margin } from 'styled-components-spacing'
import i18nhelper from '../../../components/helpers/i18n.helper'
import { DocumentListItem, DocumentListItemInvestor } from './document-list-item'
import EmptyListMessage from './empty-list-message'
import SharedMainFolderAlert from './shared-main-folder-alert'
import { InfiniteScroll, generateScrollableTarget } from '../../shared/infinite-scroll'

const StyledDocumentListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	${(props) =>
		props.marginBottom &&
		css`
			margin-bottom: ${props.theme.spacing[props.marginBottom]};
		`}
`

export default class DocumentList extends Component {
	static propTypes = {
		documents: oneOfType([string, object]),
		basePath: string,
		querystr: string,
		currentUrl: string,
		isInvestorMode: bool,
		onOpenFile: func,
		onDownloadFile: func,
		userLang: string,
		region: object,
		onBadgeClick: func,
		onDocumentClick: func,
		dateProp: string,
		renderBadge: func,
		marginBottom: number,
		isMainSharingFolder: bool,
		renderDropdown: func,
		showToggleSwitch: bool,
		isToggleSwitchChecked: func,
		onToggleSwitchChange: func,
		isToggleSwitchDisabled: func,
		onLoadDocuments: func
	}

	constructor(props) {
		super(props)

		const { scrollableTarget, renderScrollableElement } = generateScrollableTarget()
		this.scrollableTarget = scrollableTarget
		this.renderScrollableElement = renderScrollableElement
	}

	renderFolderPath(folderPath) {
		const { userLang, region } = this.props
		if (!folderPath) {
			return
		}
		return folderPath
			.map((obj) => {
				return i18nhelper.getTranslatedValue(obj, userLang, region)
			})
			.join(' / ')
	}

	renderDocumentInvestorMode = (document, index) => {
		const {
			userLang,
			region,
			onBadgeClick,
			renderBadge,
			renderDropdown,
			showToggleSwitch,
			isToggleSwitchChecked,
			onToggleSwitchChange,
			isToggleSwitchDisabled
		} = this.props

		const createdAt = document.get('createdAt')
		const id = document.get('id')
		const folderId = document.get('folderId')
		const folderName = document.get('folderName')
		const title = document.get('title')
		const validFrom = document.get('validFrom')
		const lastModified = document.get('lastModified')
		const folderPath = this.renderFolderPath(document.get('folderPath'))
		const file = document.get('file')
		const links = document.get('links')

		return (
			<DocumentListItemInvestor
				key={index}
				userLang={userLang}
				region={region}
				onBadgeClick={onBadgeClick}
				index={index}
				folderPath={folderPath}
				lastModified={lastModified}
				validFrom={validFrom}
				createdAt={createdAt}
				id={id}
				file={file}
				folderId={folderId}
				folderName={folderName}
				title={title}
				renderBadge={renderBadge}
				renderDropdown={renderDropdown}
				showToggleSwitch={showToggleSwitch}
				isToggleSwitchChecked={isToggleSwitchChecked}
				onToggleSwitchChange={onToggleSwitchChange}
				isToggleSwitchDisabled={isToggleSwitchDisabled}
				links={links}
			/>
		)
	}

	renderDocument = (document, index) => {
		const {
			basePath,
			querystr,
			currentUrl,
			userLang,
			region,
			onBadgeClick,
			onDocumentClick,
			documentsMetadata,
			renderBadge,
			renderDropdown
		} = this.props
		let { dateProp } = this.props

		const id = document.get('id')
		const isUploading = document.get('isUploading')
		const folderId = document.get('folderId')
		const title = document.get('title')
		let date = document.get(dateProp || 'createdAt')
		const to = `${basePath}/${id}${querystr}`
		const active = currentUrl === to
		const folderPath = this.renderFolderPath(documentsMetadata.getIn([id, 'folderPath']))
		const isMeeting = document.get('isMeeting')
		const status = documentsMetadata.getIn([id, 'status'])

		if (isMeeting) {
			dateProp = 'startDate'
			date = document.get('startDate')
		}

		return (
			<DocumentListItem
				id={id}
				key={index}
				active={active}
				userLang={userLang}
				region={region}
				to={to}
				date={date}
				onDocumentClick={onDocumentClick}
				onBadgeClick={onBadgeClick}
				dateProp={dateProp}
				isUploading={isUploading}
				folderPath={folderPath}
				folderId={folderId}
				status={status}
				title={title}
				folderName={documentsMetadata.getIn([id, 'folderName'])}
				renderBadge={renderBadge}
				renderDropdown={renderDropdown ? renderDropdown : undefined}
			/>
		)
	}

	renderListMessageForDocumentsSection = () => {
		const { documents, folderId, isMainSharingFolder } = this.props

		if (!documents) {
			return null
		}

		if (isMainSharingFolder) {
			return <SharedMainFolderAlert />
		}

		if (folderId && !documents.size) {
			return <EmptyListMessage />
		}
	}

	render() {
		const { documents, documentsInSubFolders, folderId, isInvestorMode, marginBottom, onLoadDocuments } = this.props

		const shouldDisplayLineSeparator = documentsInSubFolders && documentsInSubFolders.size > 0 && folderId

		if (isInvestorMode) {
			if (documents.size === 0) {
				return <EmptyListMessage />
			}

			// TODO: change hasMore
			return (
				<StyledDocumentListWrapper>
					<ScrollView autoHide={false} alwaysShowOnOverflow={true} renderView={this.renderScrollableElement}>
						<InfiniteScroll
							dataLength={documents.size}
							next={onLoadDocuments}
							hasMore={true}
							scrollableTarget={this.scrollableTarget}>
							<List>{documents.map(this.renderDocumentInvestorMode)}</List>
						</InfiniteScroll>
					</ScrollView>
				</StyledDocumentListWrapper>
			)
		}

		return (
			<StyledDocumentListWrapper marginBottom={marginBottom}>
				<List>
					{this.renderListMessageForDocumentsSection()}
					{documents && documents.map(this.renderDocument)}
				</List>
				{shouldDisplayLineSeparator && (
					<Margin vertical={5}>
						<LineSeparator tid='documents.list.sub_folder_files' bgColor='solitudeLight' />
					</Margin>
				)}
				<List>{documentsInSubFolders && documentsInSubFolders.map(this.renderDocument)}</List>
			</StyledDocumentListWrapper>
		)
	}
}
