import {
	PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
	PERSONAL_TRANSACTION_TYPE_LEVELING,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
	PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
	PERSONAL_TRANSACTION_TYPE_SPLIT,
	PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
	PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
	PERSONAL_TRANSACTION_TYPE_SNAPSHOT,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
	PERSONAL_TRANSACTION_TYPE_GENESIS
} from '/shared/constants'

export const TRANSACTION_TYPE_MENU_ITEMS = [
	{
		value: PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
		textTid: 'transaction.description.EMISSION-RIGHTS_ISSUE.extended'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
		textTid: 'transaction.description.EMISSION-DIRECTED_NEW_ISSUE.extended'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_LEVELING,
		textTid: 'transaction.description.EMISSION-EMISSION-LEVELING.extended'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
		textTid: 'transaction.description.EMISSION-UNKNOWN_EMISSION_TYPE_1.extended'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
		textTid: 'transaction.description.EMISSION-UNKNOWN_EMISSION_TYPE_2.extended'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
		textTid: 'investments.my_records.modal.add_transaction.form.select.transaction_type.bonus_issue'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
		textTid: 'investments.my_records.modal.add_transaction.form.select.transaction_type.transfer_shares_buy'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
		textTid: 'investments.my_records.modal.add_transaction.form.select.transaction_type.transfer_shares_sell'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
		textTid: 'investments.my_records.modal.add_transaction.form.select.transaction_type.transfer_shares_other'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_SPLIT,
		textTid: 'investments.my_records.modal.add_transaction.form.select.transaction_type.split'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
		textTid: 'adjustment_of_votes'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
		textTid: 'investments.my_records.modal.add_transaction.form.select.transaction_type.reduction_of_share_capital'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
		textTid: 'transaction.description.CONVERSION-OF-SHARES.title'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_SNAPSHOT,
		textTid: 'investments.my_records.modal.add_transaction.form.select.transaction_type.snapshot'
	},
	{
		value: PERSONAL_TRANSACTION_TYPE_GENESIS,
		textTid: 'investments.my_records.modal.add_transaction.form.select.transaction_type.genesis'
	}
]

export const FOOTER_SAVE_BUTTON_TOOLTIP_STATES = {
	noTransactionTypeOrDate: {
		title: 'investments.add_transaction.modal.save_button.tooltip.no_transaction_type_or_date',
		enterDelay: 'instant'
	},
	noTransactionType: {
		title: 'investments.add_transaction.modal.save_button.tooltip.no_transaction_type',
		enterDelay: 'instant'
	},
	noDate: {
		title: 'investments.add_transaction.modal.save_button.tooltip.no_date',
		enterDelay: 'instant'
	},
	wrongDateInput: {
		title: 'investments.add_transaction.modal.save_button.tooltip.wrong_date_input',
		enterDelay: 'instant'
	},
	wrongDateAndTransactionTypeInput: {
		title: 'investments.add_transaction.modal.save_button.tooltip.wrong_date_and_transaction_type_input',
		enterDelay: 'instant'
	},
	noShareTypeName: {
		title: 'investments.add_transaction.modal.save_button.tooltip.missing_share_type_name_input',
		enterDelay: 'instant'
	},
	duplicates: {
		title: 'investments.add_transaction.modal.save_button.tooltip.duplicate_share_type',
		enterDelay: 'instant'
	},
	noVotes: {
		title: 'noVotes',
		enterDelay: 'instant'
	},
	editModeAndFormNotDirty: {
		title: 'investments.add_transaction.modal.save_button.tooltip.edit_mode_form_not_dirty',
		enterDelay: 'instant'
	}
}
