import { getStore } from '../../store'
import { isSsnEqual } from './users'
const store = getStore()

export default {
	validate: (shareData, sequences) => {
		const i18n = store.getState().i18n

		if (!shareData) {
			throw new Error(i18n.messages['validation.share_data_is_missing'])
		}

		if (!sequences) {
			throw new Error(i18n.messages['validation.sequence_is_missing'])
		}

		const { numOfTotalShares, types } = shareData

		if (!numOfTotalShares) {
			throw new Error(i18n.messages['validation.number_of_shares_missing_on_step_1'])
		}

		if (numOfTotalShares < 1) {
			throw new Error(i18n.messages['validation.number_of_shares_on_step_1_is_zero_or_negative'])
		}

		if (!types || types.length < 1) {
			throw new Error(i18n.messages['validation.types_is_missing_array_of_least_one'])
		}

		let lastTo = 0
		let runningTotal = 0
		const runningTotalPerType = {}
		const existingTypes = {}

		let totalTypesShares = 0

		types.forEach((oneType, index) => {
			const { type, numOfShares } = oneType

			if (!numOfShares) {
				throw new Error(i18n.messages['validation.numofshares_is_missing'].replace('{number}', index + 1))
			}
			if (numOfShares < 1) {
				throw new Error(
					i18n.messages['validation.numofshares_less_then_1_for_type_number'].eplace('{number}', index + 1)
				)
			}
			if (!type) {
				throw new Error(i18n.messages['validation.types_must_have_unique_names'])
			}

			if (existingTypes[type]) {
				throw new Error(i18n.messages['validation.types_must_have_unique_names'])
			}

			existingTypes[type] = numOfShares
			totalTypesShares += numOfShares
		}) // loop end

		if (totalTypesShares !== numOfTotalShares) {
			throw new Error(i18n.messages['validation.numoftotalshares_donot_match_the_sum_of_the_types'])
		}

		//Check the sequences
		sequences.forEach((sequence, index) => {
			const { sequenceFrom, sequenceTo, type, investmentId } = sequence

			if (sequenceFrom === null) {
				throw new Error(i18n.messages['validation.sequencefrom_is_missing_for_sequence'].replace('{number}', index + 1))
			}

			if (sequenceTo === null) {
				throw new Error(i18n.messages['validation.sequenceto_is_missing_for_sequence'].replace('{number}', index + 1))
			}

			if (!type) {
				throw new Error(i18n.messages['validation.type_is_missing_for_sequence'].replace('{number}', index + 1))
			}

			if (!investmentId) {
				throw new Error(i18n.messages['validation.shareholder_is_missing_for_sequence'].replace('{number}', index + 1))
			}

			const sum = sequenceTo - sequenceFrom + 1

			if (sum < 1) {
				throw new Error(i18n.messages['validation.number_of_shares_is_zero'].replace('{number}', index + 1))
			}
			if (lastTo + 1 !== sequenceFrom) {
				throw new Error(
					i18n.messages['validation.sequence_overlapping'].replace('{number1}', index).replace('{number2}', index + 1)
				)
			}

			runningTotal += sum
			lastTo = sequenceTo

			if (!runningTotalPerType[type]) {
				runningTotalPerType[type] = sum
			} else {
				runningTotalPerType[type] += sum
			}
		}) // loop end

		if (runningTotal !== numOfTotalShares) {
			throw new Error(i18n.messages['validation.number_of_shares_dont_match_numoftotalshares'])
		}

		Object.keys(runningTotalPerType).forEach((type) => {
			if (runningTotalPerType[type] !== existingTypes[type]) {
				throw new Error(
					i18n.messages['validation.type_dont_match_with_specified_number_of_shares_for_that_type'].replace(
						'{type}',
						type
					)
				)
			}
		})

		return true
	},

	validateShareCapital(initialShareData) {
		const i18n = store.getState().i18n
		const shareCapital = initialShareData.get('shareCapital')
		const numOfTotalShares = initialShareData.get('numOfTotalShares')

		if (!shareCapital || shareCapital === 0) {
			throw new Error(i18n.messages['validation.share_capital_empty'])
		} else if (shareCapital < 25000) {
			throw new Error(i18n.messages['validation.share_capital_to_little'])
		}

		if (!numOfTotalShares || numOfTotalShares === 0) {
			throw new Error(i18n.messages['validation.num_of_total_shares_empty'])
		}

		return true
	},

	validateShareTypes(types, numOfTotalShares, setupType) {
		if (!types) {
			return true
		}

		const i18n = store.getState().i18n

		let numOfSharesLeft = numOfTotalShares
		const unique = []
		let hasDuplicates = false

		types.forEach((type) => {
			const typeProp = type.get('type')

			if (typeProp === '-') {
				throw new Error(i18n.messages['validation.no-type-or-series-selected'])
			}
			if (setupType === 'b' && !type.get('latestPrice')) {
				throw new Error(i18n.messages['validation.latest_price_missing'])
			}

			if (unique.indexOf(typeProp) === -1) {
				unique.push(typeProp)
			} else {
				hasDuplicates = true
			}

			const numOfShares = type.get('numOfShares')
			if (numOfShares) {
				numOfSharesLeft -= parseInt(numOfShares)
			}
		})

		if (numOfSharesLeft > 0) {
			throw new Error(i18n.messages['validation.number_of_added_shares_insufficient'])
		} else if (numOfSharesLeft < 0) {
			throw new Error(i18n.messages['validation.number_of_added_shares_to_many'])
		}

		if (hasDuplicates) {
			throw new Error(i18n.messages['validation.duplicate_share_types'])
		}

		return true
	},

	validateInvestors(investors) {
		const i18n = store.getState().i18n
		let hasError = false

		if (investors.size === 0) {
			throw new Error(i18n.messages['validation.minimum_of_one_shareholder_required'])
		}

		investors.map((investor) => {
			const investorId = investor.getIn(['investorInformation', 'id'])
			const isCapitalInsurance = investor.get('investorTypeOfOwner') === 'capitalInsurance'
			if (isCapitalInsurance) {
				return
			}

			if (!investor.getIn(['investorInformation', 'name']) || !investorId) {
				hasError = true
			}
			let myself = 0
			investors.map((compareToInvestor) => {
				if (isSsnEqual(investorId, compareToInvestor.getIn(['investorInformation', 'id']))) {
					myself++
				}
			})
			if (myself > 1) {
				throw new Error(i18n.messages['shares.validation_require_unique_id'])
			}
		})

		if (hasError) {
			throw new Error(i18n.messages['validation.shareholders_missing_information'])
		}

		return true
	},

	validateSequencesDividable(shareDiffs, dividableBy, sequences) {
		const i18n = store.getState().i18n
		let amount = 0

		shareDiffs.map((seqs, investmentId) => {
			seqs.forEach((seq) => {
				const sequence = sequences.get(seq.get('index'))
				const seqFrom = sequence.get('sequenceFrom')
				const seqTo = sequence.get('sequenceTo')
				const diff = seq.get('diff')

				amount += seqTo - seqFrom + 1 + diff
			})
		})

		if (amount % dividableBy !== 0) {
			throw new Error(i18n.messages['validation.total_number_of_shares_not_dividable'])
		}

		return true
	}
}
