import React, { useState } from 'react'
import MUIDialog from '../../mui-components/dialog/mui-dialog'
import { useTranslation } from 'react-i18next'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { useSelector } from 'react-redux'
import { RESOURCE_DOCUMENTS } from '/shared/constants'
import Typography from '@mui/material/Typography'

export default function ChooseTargetCompanyModal({ open, onCancel, onAccept }) {
	const [value, setValue] = useState('')
	const { t } = useTranslation()
	const companies = useSelector((state) => state.company.companies)

	return (
		<MUIDialog
			isOpen={open}
			title={t('personal-documents.choose-target-company')}
			confirmButtonTid='next'
			closeButtonTid='dialog.generic.cancel'
			onConfirm={() => onAccept(value)}
			onClose={onCancel}
			disabledConfirm={!value}>
			<Typography variant={'body2'} sx={{ flex: 'auto', my: 4 }}>
				{t('personal-documents.choose-target-company-description')}
			</Typography>
			<FormControl fullWidth>
				<InputLabel id='target-company'>{t('generic.company')}</InputLabel>
				<Select
					labelId='target-company'
					id='target-company-select'
					value={value}
					label={t('generic.company')}
					onChange={({ target }) => setValue(target.value)}>
					{companies
						.filter((company) => company.get('resources').includes(RESOURCE_DOCUMENTS))
						.map((company) => (
							<MenuItem key={company.get('id')} value={company.get('id')}>
								{company.get('name')}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</MUIDialog>
	)
}
