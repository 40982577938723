import React, { PureComponent } from 'react'
import { object, bool, node, func, oneOf, string } from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import theme from '../../../../theme'
import Tooltip from '../tooltip/tooltip'

const StyledLi = styled.li`
	display: flex;
	flex-direction: column;
`

const StyledDivider = styled.div`
	flex: 1;
	height: 0;
	margin: ${(props) => props.theme.spacing[3]} 0;
	overflow: hidden;
	border: 1px solid ${(props) => props.theme.colors.solitudeDark};
	cursor: default;
`

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: ${(props) => props.theme.spacing[3]} ${(props) => props.theme.spacing[4]};
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	background-color: ${(props) => (!props.backgroundColor ? 'transparent' : props.theme.colors[props.backgroundColor])};

	/* Removes horizontal padding. Used at least by DropdownIconItem */
	${(props) =>
		props.noHorPadding &&
		css`
			padding: ${(props) => props.theme.spacing[3]} 0;
		`}

	${(props) =>
		props.disabled &&
		css`
			* {
				color: ${(props) => props.theme.colors.muted};
			}
		`}

	${(props) =>
		!props.noHover &&
		css`
			:hover {
				background-color: ${(props) => rgba(props.theme.colors.solitudeMid, 0.25)};
			}
		`}

	${(props) =>
		props.selected &&
		css`
			background-color: ${(props) => props.theme.colors.solitudeMid};
			cursor: default;

			:hover {
				background-color: ${(props) => props.theme.colors.solitudeMid};
			}
		`}
`

const StyledLeftComponent = styled.div`
	white-space: nowrap;
	flex: 1;
	text-align: left;

	${(props) =>
		props.overflowHidden &&
		css`
			text-overflow: ellipsis;
			overflow: hidden;
		`};
`

const StyledRightComponent = styled.div`
	white-space: nowrap;
	text-align: right;
	padding-left: ${(props) => props.theme.spacing[3]};
`

class DropdownItem extends PureComponent {
	constructor(props) {
		super(props)
		this.leftComponentRef = React.createRef()
	}

	render = () => {
		const {
			children,
			leftComponent,
			rightComponent,
			selected,
			divider,
			tag,
			tagProps,
			onClick,
			disabled,
			overflowHidden,
			noHorPadding,
			backgroundColor,
			noHover,
			className,
			tooltipTid,
			tooltipActiveState,
			tooltipDelayShow,
			tooltipMaxWidth,
			tooltipValues
		} = this.props
		const props = {
			...tagProps,
			selected,
			disabled,
			noHorPadding,
			backgroundColor,
			noHover,
			className
		}

		const tooltipProps = {
			tid: tooltipTid,
			delayShow: tooltipDelayShow,
			maxWidth: tooltipMaxWidth,
			activeState: tooltipActiveState,
			values: tooltipValues
		}

		if (divider) {
			return (
				<StyledLi>
					<StyledDivider />
				</StyledLi>
			)
		}

		if (tag) {
			props.as = tag
		}

		if (onClick && !disabled) {
			props.onClick = onClick
		}

		return (
			<Tooltip {...tooltipProps}>
				<StyledLi>
					<StyledWrapper {...props} ref={(r) => (this.testRef = r)}>
						{leftComponent && (
							<StyledLeftComponent overflowHidden={overflowHidden}>{leftComponent}</StyledLeftComponent>
						)}
						{rightComponent && <StyledRightComponent>{rightComponent}</StyledRightComponent>}
						{!leftComponent && children}
					</StyledWrapper>
				</StyledLi>
			</Tooltip>
		)
	}
}

DropdownItem.defaultProps = {
	selected: false,
	divider: false,
	disabled: false,
	tagProps: {},
	overflowHidden: false,
	noHorPadding: false
}

DropdownItem.propTypes = {
	leftComponent: node,
	rightComponent: node,
	selected: bool,
	divider: bool,
	disabled: bool,
	tagProps: object,
	onClick: func,
	overflowHidden: bool,
	noHorPadding: bool,
	backgroundColor: oneOf(Object.keys(theme.colors)),
	noHover: bool,
	tooltipTid: string,
	tooltipDelayShow: oneOf(['instant', 'short', 'long']),
	tooltipMaxWidth: string
}

export default DropdownItem
