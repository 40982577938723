import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool, func, oneOf } from 'prop-types'
import { list } from 'react-immutable-proptypes'

import { Padding } from 'styled-components-spacing'
import Panel from '../../dumb-components/shared/panel/panel'
import Label from '../../dumb-components/shared/label/label'

import TagsContainer from './tags-select.container'

class TagsPanelContainer extends Component {
	static propTypes = {
		fieldName: string,
		onChange: func,
		marginBottom: bool,
		readOnly: bool,
		value: list,
		menuPlacement: oneOf(['top', 'bottom', 'auto'])
	}

	static defaultProps = {
		marginBottom: true,
		fieldName: 'tags'
	}

	getPanelProps = () => {
		const { marginBottom } = this.props

		return {
			title: 'generic.tags',
			marginBottom
		}
	}

	getTagsProps = () => {
		const { onChange, fieldName, readOnly, value, menuPlacement } = this.props

		return {
			onTagsChange: onChange,
			fieldName,
			readOnly,
			value,
			menuPlacement
		}
	}

	render = () => {
		return (
			<Panel {...this.getPanelProps()}>
				<Padding all={4}>
					<Label tid='generic.tags'>
						<TagsContainer {...this.getTagsProps()} />
					</Label>
				</Padding>
			</Panel>
		)
	}
}

const mapStoreToProps = () => {
	return {}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(TagsPanelContainer)
