/*
This container is used in:
- tasks
- meetings (actionsMode is set to 'external')
*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { List, Map } from 'immutable'
import { oneOf } from 'prop-types'

import {
	fetchTask,
	fetchExternalTask,
	updateTaskLocal,
	saveTask,
	saveExternalTask,
	clearTask
} from '../../actions/tasks.actions'
import TaskDetailsInformation from '../../dumb-components/tasks/task-information-details/task-information-details'
import TaskActivityInformation from '../../components/framework/activities-log'
import TagsSelectContainer from '../shared/tags-select.container'
import CommentsListContainer from '../comments/comments-list.container'
import AttachmentsByObjIdContainer from '../shared/attachments-by-objid.container'
import { renderCommonAttachmentDropdown } from '../documents/attachments/predefined-dropdown-render-functions'
import AssigneeContainer from '../shared/assignee.container'
import { AVAIBLE_TASK_STATUS } from '../../constants/tasks'
import FieldsLoader from '../../dumb-components/shared/fields-loader/fields-loader'
import EditorContainer from '../shared/editor.container'

import {
	LIVE_TASK_UPDATE,
	LIVE_TASK_DELETE,
	LIVE_TASK_TRANSFER,
	LIVE_TASK_EXTERNAL_UPDATE
} from '../../constants/live-update'

import { OBJ_TYPE_TASK } from '/shared/constants'

class TaskDetailsInformationContainer extends Component {
	static propTypes = {
		actionsMode: oneOf(['external'])
	}

	componentDidMount = () => {
		const {
			actionsMode,
			match: { params },
			task,
			tasks
		} = this.props
		if (params.id !== task.get('id') || actionsMode === 'external') {
			this.fetchTaskData()
		}
	}

	componentDidUpdate = (prevProps) => {
		const {
			actionsMode,
			match: { params },
			task,
			tasks
		} = this.props

		this.checkLiveUpdateEvents(prevProps)

		if (prevProps.match.params.id !== params.id && params.id !== task.get('id')) {
			this.fetchTaskData()
		}

		if (prevProps.match.params.id !== params.id && actionsMode === 'external') {
			this.fetchTaskData()
		}
	}

	checkLiveUpdateEvents = (prevProps) => {
		const {
			audit,
			match: { params },
			location,
			clearTask
		} = this.props
		const taskUpdate = audit.get(LIVE_TASK_UPDATE)
		const taskDelete = audit.get(LIVE_TASK_DELETE)
		const taskTransfer = audit.get(LIVE_TASK_TRANSFER)
		const taskExternalUpdate = audit.get(LIVE_TASK_EXTERNAL_UPDATE)
		const selectedTaskId = params.id
		const querystr = location.search

		// Task was edited, update task if it is the selected one
		if (taskUpdate && taskUpdate.get('refresh') === true) {
			const objId = taskUpdate.get('objId')

			if (selectedTaskId === objId) {
				this.fetchTask(selectedTaskId)
			}
		}

		// External task was updated, fetch new task if it is the selected one
		if (taskExternalUpdate && taskExternalUpdate.get('refresh') === true) {
			const objId = taskExternalUpdate.get('objId')

			if (selectedTaskId === objId) {
				this.fetchTask(selectedTaskId)
			}
		}

		// Task was transfered to another project, update task if it is the selected one
		if (taskTransfer && taskTransfer.get('refresh') === true) {
			const objId = taskTransfer.get('objId')

			if (selectedTaskId === objId) {
				this.fetchTask(selectedTaskId)
			}
		}

		// Task was deleted, unselect task if it is the selected one
		if (taskDelete && taskDelete.get('refresh') === true) {
			const basePath = this.getComponentBasePath()
			const objId = taskDelete.get('objId')

			if (selectedTaskId === objId) {
				clearTask()

				this.props.history.push({
					pathname: basePath,
					search: querystr
				})
			}
		}
	}

	fetchTask = (taskId) => {
		const { objId, actionsMode, fetchTask, fetchExternalTask, company } = this.props

		switch (actionsMode) {
			case 'external': {
				fetchExternalTask(objId, taskId, company.id)
				break
			}

			default: {
				fetchTask(taskId)
				break
			}
		}
	}

	saveTask = (task) => {
		const { objId, actionsMode, saveTask, saveExternalTask } = this.props

		switch (actionsMode) {
			case 'external': {
				saveExternalTask(objId, task)
				break
			}

			default: {
				saveTask(task)
				break
			}
		}
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('tasks')
		return pathArray[0] + 'tasks'
	}

	doDebounce = debounce((task) => {
		this.saveTask(task)
	}, 1000)

	updateTaskLocal = (task) => {
		const { updateTaskLocal } = this.props
		updateTaskLocal(task)
		this.doDebounce(task)
	}

	fetchTaskData = () => {
		const {
			match: { params }
		} = this.props
		if (params.id) {
			this.fetchTask(params.id)
		}
	}

	setRef = (component, ref) => {
		const { childrenRef } = this.props
		childrenRef && childrenRef(component, ref)
	}

	onChange = (field, value) => {
		// Preven't crash on changing folder.
		// Can be related to the way we bind/trigger onChange in document details container
		// as onChange is sometimes triggered upon rendering.
		let { task } = this.props

		if (!field || !task) {
			return
		}

		task = task.set(field, value)
		this.updateTaskLocal(task)
	}

	onTagsChange = (tags) => {
		this.onChange('tags', tags)
	}

	onUpload = (field, value) => {
		const { task } = this.props
		let attachments = task ? task.get(field) : List()
		attachments = attachments.push(value)
		this.onChange(field, attachments)
	}

	reorderAttachments = (field, docIds) => {
		this.onChange(field, docIds)
	}

	renderEditorContainer = () => {
		const { task } = this.props
		const readOnly = task ? task.get('isDeleted') || !task.get('ALLOW_UPDATE') : false

		return (
			<EditorContainer
				fieldName={'description'}
				objId={task.get('id')}
				contentState={task.get('description')}
				onChange={this.onChange}
				readOnly={readOnly}
			/>
		)
	}

	renderTagsSelectContainer = () => {
		const { task } = this.props
		const readOnly = task ? task.get('isDeleted') || !task.get('ALLOW_UPDATE') : false

		return <TagsSelectContainer onTagsChange={this.onTagsChange} value={task && task.get('tags')} readOnly={readOnly} />
	}

	renderAttachments = (canUpdate) => {
		const { task, activeBlock, taskIsDeleted } = this.props

		return (
			<AttachmentsByObjIdContainer
				objType={OBJ_TYPE_TASK}
				objId={task && task.get('id')}
				readOnly={!canUpdate || taskIsDeleted}
				renderAttachmentDropdown={renderCommonAttachmentDropdown}
			/>
		)
	}

	renderAssigneeContainer = (readOnly) => {
		const { task, projects } = this.props
		const project = projects.get(task.get('projectId'))

		return (
			<AssigneeContainer
				fieldName='assigne'
				onChange={this.onChange}
				value={task ? task.get('assigne') : null}
				readOnly={readOnly}
				permissions={
					project && project.get('permissions') ? project.get('permissions') : task.get('permissions', Map())
				}
				isRoot={!project || !project.get('permissions')}
			/>
		)
	}

	renderTasksInformationDetails = (canUpdate) => {
		const { task, company, i18n, activeBlock, taskIsDeleted } = this.props

		if (!task) {
			return <FieldsLoader />
		}

		return (
			<TaskDetailsInformation
				ref={(node) => {
					this.setRef('details', node)
				}}
				avaibleTaskStatus={AVAIBLE_TASK_STATUS}
				renderAssigneeContainer={this.renderAssigneeContainer}
				task={task}
				onChange={this.onChange}
				userLang={i18n.language}
				region={company.region}
				renderTagsSelectContainer={this.renderTagsSelectContainer}
				language={i18n.language}
				readOnly={!canUpdate || taskIsDeleted}
				selected={activeBlock === 'details'}
				renderEditorContainer={this.renderEditorContainer}
				isDeleted={taskIsDeleted}
			/>
		)
	}

	renderComments = () => {
		const { task, activeBlock } = this.props

		return (
			<CommentsListContainer
				objType={OBJ_TYPE_TASK}
				objId={task && task.get('id')}
				objTitle={task && task.get('title')}
				objUrl={window.location.href}
				onSetRef={(node) => {
					this.setRef('comments', node)
				}}
				selected={activeBlock === 'comments'}
			/>
		)
	}

	renderTaskActivityInformation = () => {
		const { task } = this.props

		return <TaskActivityInformation activities={task && task.get('activities')} marginBottom={true} />
	}

	render = () => {
		const { task, taskIsDeleted } = this.props
		const canUpdateTask = task ? task.get('ALLOW_UPDATE') : false

		return (
			<div style={{ flex: 1 }}>
				{this.renderTasksInformationDetails(canUpdateTask)}
				{this.renderAttachments(canUpdateTask)}
				{!taskIsDeleted && this.renderComments()}
				{canUpdateTask && this.renderTaskActivityInformation()}
			</div>
		)
	}
}

function mapStoreToProps(store) {
	return {
		history: history,
		task: store.tasks.get('task', Map()) || Map(),
		tasks: store.tasks.get('visibleTasks'),
		projects: store.projects.get('projects'),
		company: store.company.company,
		i18n: store.i18n,
		audit: store.audit.get('tasks'),
		taskIsDeleted: store.tasks.getIn(['task', 'isDeleted'])
	}
}

const mapActionsToProps = {
	fetchTask,
	fetchExternalTask,
	updateTaskLocal,
	saveTask,
	saveExternalTask,
	clearTask
}

export default connect(mapStoreToProps, mapActionsToProps)(TaskDetailsInformationContainer)
