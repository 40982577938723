import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

class AccordionSection extends Component {
	onSelect() {
		const { disabled, onToggle, onSelect } = this.props

		if (disabled) {
			return
		}

		onSelect()
		onToggle && onToggle()
	}

	render() {
		const { title, children, bodyClassName, selected, disabled, buttonTid, id } = this.props
		const props = {
			className: `i-accordion__section ${disabled ? 'i-accordion__section--disabled' : ''}`
		}

		if (id) {
			props.id = id
		}

		return (
			<div {...props}>
				<div className='i-accordion__heading' onClick={this.onSelect.bind(this)}>
					<div className={`i-accordion__title-wrapper`}>
						<h2 className={`i-accordion__title`} disabled={selected || disabled}>
							<FormattedMessage id={title} />
						</h2>
					</div>
					<div className='i-accordion__button-wrapper'>
						<button type='button' className='btn btn-block btn-accordion-header' disabled={selected || disabled}>
							<FormattedMessage id={buttonTid || 'select'} />
						</button>
					</div>
				</div>
				<div className={`i-accordion__body ${bodyClassName}`}>{selected && children}</div>
			</div>
		)
	}
}

export default AccordionSection
