import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { map } from 'react-immutable-proptypes'
import { bool } from 'prop-types'
import { listMeetingTemplates } from '../../../actions/meeting-templates.actions'
import { createAttendeeObject } from '../../../components/helpers/meeting.helper.pure'
import MeetingGeneralInformation from '../../../dumb-components/meetings/meetings-general-information/meetings-general-information'
import AssigneeContainer from '../../shared/assignee.container'
import AttendeesPanelContainer from '../attendees/attendees-panel.container'
import TagsSelectContainer from '../../shared/tags-select.container'
import EditorContainer from '../../shared/editor.container'

class AgendaDetailsContainer extends Component {
	static propTypes = {
		nextMeeting: map,
		useExistingMeeting: bool,
		readOnly: bool
	}

	state = {
		meetingTemplates: List()
	}

	componentDidMount = () => {
		const { listMeetingTemplates, selectedGroupId } = this.props

		listMeetingTemplates(selectedGroupId)
	}

	componentDidUpdate = (prevProps) => {
		const { selectedGroupId, meetingTemplates } = this.props

		if (prevProps.selectedGroupId !== selectedGroupId) {
			listMeetingTemplates(selectedGroupId)
		}

		if (prevProps.meetingTemplates !== meetingTemplates) {
			this.parseMeetingTemplates()
		}
	}

	parseMeetingTemplates = () => {
		const { meetingTemplates } = this.props
		let parsedMeetingTemplates = List()

		if (!meetingTemplates) {
			return
		}

		parsedMeetingTemplates = meetingTemplates.filter((obj) => obj.get('active'))
		parsedMeetingTemplates = parsedMeetingTemplates.map((obj) => Map({ value: obj.get('id'), label: obj.get('name') }))
		this.setState({ meetingTemplates: parsedMeetingTemplates })
	}

	onChange = (fieldName, value) => {
		const { onChange } = this.props
		let { nextMeeting } = this.props

		// Check if ToggleSwitch was changed and replace 'value' and 'fieldName' accordingly
		if (fieldName === 'useForNextMeeting') {
			onChange('internalData.useExistingMeeting', value)

			// Stop execution
			return
		}

		// Add chairman and secretary as attendees
		if (fieldName === 'chairman' || fieldName === 'secretary') {
			let attendees = nextMeeting.get('attendees', Map())

			if (!attendees.has(value)) {
				attendees = attendees.set(value, createAttendeeObject(value))

				nextMeeting = nextMeeting.set('attendees', attendees)
			}
		}

		nextMeeting = nextMeeting.set(fieldName, value)
		onChange('internalData.meeting', nextMeeting)
	}

	renderAssigneeField = (fieldName) => {
		const { groups, nextMeeting, meeting } = this.props
		const group = nextMeeting && groups ? groups.get(nextMeeting.get('groupId')) : null
		const readOnly = this.props.readOnly || (meeting && meeting.getIn(['computedValues', 'meetingIsFinished']))

		return (
			<AssigneeContainer
				fieldName={fieldName}
				onChange={this.onChange}
				value={nextMeeting ? nextMeeting.get(fieldName) : null}
				permissions={nextMeeting && nextMeeting.get('permissions')}
				readOnly={readOnly}
				isRoot={!group}
			/>
		)
	}

	renderTagsContainer = () => {
		const { nextMeeting, meeting } = this.props
		const readOnly = this.props.readOnly || (meeting && meeting.getIn(['computedValues', 'meetingIsFinished']))

		return (
			<TagsSelectContainer
				fieldName='tags'
				onTagsChange={this.onChange}
				readOnly={readOnly}
				value={nextMeeting && nextMeeting.get('tags')}
			/>
		)
	}

	renderMeetingGeneralInformation = () => {
		const { nextMeeting, useExistingMeeting, meeting } = this.props
		const { meetingTemplates } = this.state
		const readOnly = this.props.readOnly || (meeting && meeting.getIn(['computedValues', 'meetingIsFinished']))

		return (
			<MeetingGeneralInformation
				onChange={this.onChange}
				renderMode='nextMeeting'
				meeting={nextMeeting}
				readOnly={readOnly}
				useForNextMeeting={useExistingMeeting}
				templateOptions={meetingTemplates}
				renderAssigneeField={this.renderAssigneeField}
				renderTagsContainer={this.renderTagsContainer}
				renderEditorContainer={this.renderEditorContainer}
			/>
		)
	}

	renderAttendeesList = () => {
		const { nextMeeting, readOnly, meeting } = this.props
		const meetingIsFinished = meeting && meeting.getIn(['computedValues', 'meetingIsFinished'])

		return (
			<AttendeesPanelContainer
				panelTitle='meetings.schedule_next_meeting.attendees'
				attendees={nextMeeting && nextMeeting.get('attendees')}
				readOnly={readOnly || meetingIsFinished}
				groupId={nextMeeting && nextMeeting.get('groupId')}
				permissions={nextMeeting && nextMeeting.get('permissions')}
				chairman={nextMeeting && nextMeeting.get('chairman')}
				secretary={nextMeeting && nextMeeting.get('secretary')}
				onChange={this.onChange}
				showStatusIndicator={false}
			/>
		)
	}

	renderEditorContainer = (fieldName) => {
		const { nextMeeting, meeting } = this.props
		const readOnly = this.props.readOnly || (meeting && meeting.getIn(['computedValues', 'meetingIsFinished']))

		return (
			<EditorContainer
				fieldName={fieldName}
				objId={nextMeeting.get('id')}
				contentState={nextMeeting.get(fieldName)}
				onChange={this.onChange}
				readOnly={readOnly}
			/>
		)
	}

	render = () => {
		return (
			<div>
				{this.renderMeetingGeneralInformation()}
				{this.renderAttendeesList()}
			</div>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		groups: store.groups.get('groups'),
		meeting: store.meetings.get('meeting'),
		selectedGroupId: store.groups.get('selectedGroupId'),
		meetingTemplates: store.meetingTemplates.get('list')
	}
}

const mapActionsToProps = {
	listMeetingTemplates
}

export default connect(mapStoreToProps, mapActionsToProps)(AgendaDetailsContainer)
