import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { string, oneOf, func, bool } from 'prop-types'
import componentThemes from './animated-button-toggle-themes'
import Text from '../../text/text'

const StyledLabel = styled.label`
	display: inline-block;
	padding: 10px;
	position: relative;
	text-align: center;
	overflow: hidden;
	flex: 1;
	margin-bottom: 0;

	${(props) =>
		props.borderColor &&
		css`
			border: 1px solid ${(props) => props.theme.colors[props.borderColor]};
		`}

	${(props) =>
		props.borderRadius &&
		css`
			border-radius: ${(props) => props.borderRadius};
		`}
`

const StyledWrapper = styled.div`
	display: flex;
	flex: 1;

	${(props) =>
		props.height &&
		css`
			height: ${props.height};
		`}

	${(props) =>
		props.border &&
		css`
			border: ${(props) => props.border};
		`}

	${(props) =>
		props.borderRadius &&
		css`
			border-radius: ${(props) => props.borderRadius};
		`}

	${(props) =>
		props.padding &&
		css`
			padding: ${(props) => props.padding};
		`}
`

const ToggleStyling = css`
	display: none;
	height: 100%;

	+ label {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		flex: 1;
		z-index: 0;

		${(props) =>
			props.backgroundColor &&
			css`
				background: ${(props) => props.theme.colors[props.backgroundColor]};
			`}
	}

	+ label:hover {
		background: ${(props) => (props.hoverColor ? props.theme.colors[props.hoverColor] : 'none')};
	}

	+ label:after {
		content: '';
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -1;
	}

	:checked + label {
		cursor: default;

		> span {
			color: ${(props) => props.theme.colors[props.activeTextColor]};
		}
	}

	:checked + label:after {
		left: 0;

		${(props) =>
			props.activeBackgroundColor &&
			css`
				background: ${(props) => props.theme.colors[props.activeBackgroundColor]};
			`}
	}

	:disabled {
		+ label:after {
			background: ${(props) => props.theme.colors.darkGrey};
		}
	}
`

const ToggleLeftInput = styled.input`
	${ToggleStyling};

	+ label {
		border-right: 0;
		border-top-left-radius: ${(props) => props.borderRadius}px;
		border-bottom-left-radius: ${(props) => props.borderRadius}px;
	}

	+ label:after {
		left: 100%;
	}

	${(props) =>
		props.leftActiveBgColor &&
		!props.disabled &&
		css`
		:checked + label:after {
			background: ${(props) => props.theme.colors[props.leftActiveBgColor]};
	`}
	}
`

const ToggleRightInput = styled.input`
	${ToggleStyling};

	+ label {
		border-top-right-radius: ${(props) => props.borderRadius}px;
		border-bottom-right-radius: ${(props) => props.borderRadius}px;
	}

	+ label:after {
		left: -100%;
	}

	${(props) =>
		props.rightActiveBgColor &&
		css`
		:checked + label:after {
			background: ${(props) => props.theme.colors[props.rightActiveBgColor]};
	`}
`

export default class AnimatedButtonToggle extends PureComponent {
	static propTypes = {
		fieldName: string,
		leftTid: string,
		rightTid: string,
		value: oneOf([0, 1]),
		onChange: func,
		rightTabRenderer: func,
		leftTabRenderer: func,
		themeName: oneOf(['formStandard', 'formRounded', 'panelHeader', 'notesSwitch']),
		disabled: bool
	}

	static defaultProps = {
		value: 0
	}

	getThemeProperties = (themeName) => {
		const t = componentThemes[themeName] || componentThemes['formStandard']

		const propsObject = {
			bodyProps: t.bodyProps,
			labelProps: t.labelProps,
			inputProps: t.inputProps
		}

		return propsObject
	}

	onChangeFirst = () => {
		this.onChange(0)
	}

	onChangeSecond = () => {
		this.onChange(1)
	}

	onChange = (value) => {
		const { fieldName, onChange } = this.props

		if (fieldName) {
			onChange && onChange(fieldName, value)
			return
		}

		onChange && onChange(value)
	}

	render = () => {
		const { leftTid, rightTid, value, leftTabRenderer, rightTabRenderer, themeName, disabled } = this.props
		const { inputProps, labelProps, bodyProps } = this.getThemeProperties(themeName)

		return (
			<StyledWrapper {...bodyProps}>
				<ToggleLeftInput
					id='toggle-on'
					name='toggle'
					type='radio'
					onChange={this.onChangeFirst}
					checked={value === 0 ? true : false}
					disabled={disabled}
					{...inputProps}
				/>

				<StyledLabel htmlFor='toggle-on' {...labelProps}>
					{leftTabRenderer ? leftTabRenderer() : <Text bold={600} tid={leftTid} />}
				</StyledLabel>

				<ToggleRightInput
					id='toggle-off'
					name='toggle'
					type='radio'
					onChange={this.onChangeSecond}
					checked={value === 1 ? true : false}
					disabled={disabled}
					{...inputProps}
				/>

				<StyledLabel htmlFor='toggle-off' {...labelProps}>
					{rightTabRenderer ? rightTabRenderer() : <Text bold={600} tid={rightTid} />}
				</StyledLabel>
			</StyledWrapper>
		)
	}
}
