import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { clearMeeting, attendeesChanged } from '../../actions/meetings.actions'
import Content from '../../dumb-components/shared/layout/content/content'
import SplitView from '../../dumb-components/shared/layout/split-view/split-view'
import ScrollView from '../../dumb-components/shared/layout/scroll-view/scroll-view'
import ScrollViewHeader from '../../dumb-components/shared/scroll-view-header/scroll-view-header'
import Box from '../../dumb-components/shared/layout/box/box'
import GroupsContainer from './groups/groups.container'
import MeetingsListContainer from './meeting/meetings-list.container'
import MeetingCreateButtonContainer from './meeting-create-button.container'
import MeetingsFilterByContainer from './meeting/meetings-filter-by.container'
import MeetingsFilterAndSortContainer from './meeting/meetings-filter-and-sort.container'
import AdvancedFilterButtonContainer from './meeting/advanced-filter-button.container'
import AdvancedFilterContainer from './meeting/advanced-filter.container'
import MeetingDrawerContainer from './meeting/meeting-drawer.container'
import MeetingsToolbarContainer from './meeting/toolbar.container'

import RedirectToProtocolModalContainer from './redirect-to-protocol-modal.container'
import MoveMeetingModalContainer from './meeting/move-meeting-modal.container'
import { listCustomMeetingFunctions } from '../../actions/custom-meeting-functions.actions'

import { Switch, Route, routeHoc } from '../../interfaces/router'
import MeetingGeneralContainer from './meeting/meeting-general.container'
import history, { getQuery } from '../../interfaces/history'
import { LOCATION_COMPANY_MEETINGS, MEETING_SUBTYPE_AGM } from '/shared/constants'

import ConfirmContainer from '../shared/confirm.container'
import { closeModal } from '../../actions/modals.actions'
import { CONFIRM_DELETE_PROXY } from '../../constants/modals'
import { deleteProxy } from '../../actions/meetings.actions'

import { generateScrollableTarget } from '../../dumb-components/shared/infinite-scroll'

class MeetingsContainer extends Component {
	state = {
		filterIsOpen: false,
		totalMeetingsFiltered: 0,
		totalMeetingsFilteredShown: 0,
		prevMeeting: null,
		deleteProxyModalLoading: false
	}

	constructor(props) {
		super(props)

		const { scrollableTarget, renderScrollableElement } = generateScrollableTarget()
		this.scrollableTarget = scrollableTarget
		this.renderScrollableElement = renderScrollableElement
	}

	componentDidMount() {
		const { listCustomMeetingFunctions } = this.props
		listCustomMeetingFunctions()
	}

	componentDidUpdate(prevProps) {
		const { meeting, attendeesChanged } = this.props
		if (
			meeting &&
			prevProps.meeting &&
			prevProps.meeting !== meeting &&
			meeting.get('id') === prevProps.meeting.get('id')
		) {
			attendeesChanged(meeting.get('attendees'), prevProps.meeting.get('attendees'), meeting.get('id'))
		}
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('meetings')
		return pathArray[0] + 'meetings'
	}

	toggleAdvancedFilter = () => {
		const { clearMeeting, meeting, location, history } = this.props
		const { filterIsOpen, prevMeeting } = this.state
		const querystr = location.search
		const basePath = this.getComponentBasePath()

		// if there are no selected meeting in the list and there was before filter was open, we select that meeting again
		if (!meeting && prevMeeting) {
			history.push({
				pathname: `${basePath}/${prevMeeting.get('id')}`,
				search: querystr
			})
			this.setState({ prevMeeting: null })
		} else {
			history.push({
				pathname: basePath,
				search: querystr
			})
			this.setState({ prevMeeting: meeting })
		}

		clearMeeting()
		this.setState({ filterIsOpen: !filterIsOpen })
	}

	doDeleteProxy = () => {
		const { deleteProxy, meeting, modalOptions, closeModal } = this.props
		const userId = modalOptions.get('userId')
		const meetingId = meeting.get('id')
		this.setState({ deleteProxyModalLoading: true })
		deleteProxy(meetingId, userId, () => {
			this.setState({ deleteProxyModalLoading: false })
			closeModal()
		})
	}

	closeAdvancedFilter = () => {
		this.setState({ filterIsOpen: false, prevMeeting: null })
	}

	renderLeftHeader = () => {
		const { totalMeetingsFiltered, totalMeetingsFilteredShown } = this.state
		const basePath = this.getComponentBasePath()

		return (
			<ScrollViewHeader>
				<MeetingsFilterByContainer
					totalMeetings={totalMeetingsFiltered}
					totalMeetingsShown={totalMeetingsFilteredShown}
				/>
				<Margin left={3}>
					<Box>
						<MeetingCreateButtonContainer basePath={basePath} />
						<AdvancedFilterButtonContainer onClick={this.toggleAdvancedFilter} />
					</Box>
				</Margin>
			</ScrollViewHeader>
		)
	}

	renderLeftSplitView = () => {
		const {
			location,
			match: { params }
		} = this.props
		const basePath = this.getComponentBasePath()
		const query = getQuery()
		const { source, action } = query

		return (
			<ScrollView
				header={this.renderLeftHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRefLeft = r)}>
				<MeetingsFilterAndSortContainer
					filtersChanged={(totalMeetingsFilteredShown, totalMeetingsFiltered) => {
						this.setState({ totalMeetingsFilteredShown, totalMeetingsFiltered })
					}}>
					<MeetingsListContainer
						scrollbarRef={this.scrollbarRefLeft}
						basePath={basePath}
						querystr={location.search}
						paramsId={params.id}
						location={location}
						onMeetingClick={this.closeAdvancedFilter}
					/>
				</MeetingsFilterAndSortContainer>

				<RedirectToProtocolModalContainer basePath={basePath} source={source} action={action} />
			</ScrollView>
		)
	}

	renderRightHeader = () => {
		const {
			match: { params },
			location
		} = this.props
		const basePath = this.getComponentBasePath()

		return (
			<ScrollViewHeader>
				<MeetingsToolbarContainer location={location} basePath={basePath} paramsId={params.id} />
			</ScrollViewHeader>
		)
	}

	renderRightSplitView = () => {
		const { filterIsOpen } = this.state
		const { match } = this.props

		return (
			<ScrollView
				header={this.renderRightHeader}
				headerBottomMargin={3}
				showOnHover={true}
				scrollbarRef={(r) => (this.scrollbarRef = r)}
				contentTopMargin={filterIsOpen ? 1 : undefined}
				renderView={this.renderScrollableElement}>
				<MoveMeetingModalContainer />
				{match.params.id && !filterIsOpen && <MeetingGeneralContainer scrollableTarget={this.scrollableTarget} />}
				{filterIsOpen && <AdvancedFilterContainer isOpen={filterIsOpen} onClose={this.toggleAdvancedFilter} />}
			</ScrollView>
		)
	}

	renderHeader = () => {
		const {
			match: { params }
		} = this.props
		const basePath = this.getComponentBasePath()

		return (
			<Box>
				<GroupsContainer basePath={basePath} params={params} />

				<MeetingDrawerContainer basePath={basePath} />
			</Box>
		)
	}
	render() {
		const { activeModal, closeModal, deleteProxyModalLoading, meeting } = this.props
		const isAgm = meeting?.get('meetingSubType') === MEETING_SUBTYPE_AGM

		return (
			<>
				<Content header={this.renderHeader} tightenHeader>
					<SplitView leftComponent={this.renderLeftSplitView} rightComponent={this.renderRightSplitView} />
				</Content>
				{isAgm && (
					<ConfirmContainer
						isOpen={activeModal === CONFIRM_DELETE_PROXY}
						title='meetings.agm.confirm_modal.remove_proxy.title'
						question='meetings.agm.confirm_modal.remove_proxy.question'
						onConfirm={this.doDeleteProxy}
						onDecline={closeModal}
						isLoading={deleteProxyModalLoading}
					/>
				)}
			</>
		)
	}
}

function mapStoreToProps(store, ownProps = {}) {
	return {
		history: history,
		meeting: store.meetings.get('meeting'),
		activeModal: store.modals.getIn(['activeModal', 'name']),
		modalOptions: store.modals.getIn(['activeModal', 'options'])
	}
}

const mapActionsToProps = {
	listCustomMeetingFunctions,
	closeModal,
	deleteProxy,
	clearMeeting,
	attendeesChanged
}

export default connect(
	mapStoreToProps,
	mapActionsToProps
)(routeHoc(MeetingsContainer, { routeName: LOCATION_COMPANY_MEETINGS }))
