import React, {useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmModal from '../../../shared/confirm.container'
import { closeModal } from '../../../../actions/modals.actions'
import { deleteAttachedDocument, deleteAttachedDocumentPublic } from '../../../../actions/attachments.actions'
import {CONFIRM_DELETE_PROTOCOL} from '../../../../constants/modals'

const ConfirmDeleteProtocolModalContainer = () => {
	const isOpen = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'name']) === CONFIRM_DELETE_PROTOCOL
	})
	
	const {mode, document, objType, objId, companyId, onDelete} = useSelector((state) => {
		return {
			mode: state.modals.getIn(['activeModal', 'options', 'mode']),
			document: state.modals.getIn(['activeModal', 'options', 'document']),
			objType: state.modals.getIn(['activeModal', 'options', 'objType']),
			objId: state.modals.getIn(['activeModal', 'options', 'objId']),
			onDelete: state.modals.getIn(['activeModal', 'options', 'onDelete']),
			companyId: state.company.company.id
		}
	})
	
	const dispatch = useDispatch()
	
	const closeThisModal = useCallback(() => {
		dispatch(closeModal())
	}, [dispatch])
	
	const deleteProtocol = useCallback(() => {
		const documentId = document.get('id')
		
		if (mode === 'PUBLIC-UNSAFE') {
			dispatch(deleteAttachedDocumentPublic(documentId, objType, objId, companyId, () => {
				onDelete && onDelete(document)
			}))
		} else {
			dispatch(deleteAttachedDocument(documentId, objType, objId, () => {
				onDelete && onDelete(document)
			}))
		}
		
		closeThisModal()
	}, [mode, document, objType, objId, companyId, onDelete, dispatch, closeThisModal])

	if (!isOpen) {
		return null
	}
	
	return (
		<ConfirmModal
			isOpen={true}
			title='documents.attachment.protocol.permanently_delete.confirm.title'
			question='documents.attachment.protocol.permanently_delete.confirm.question'
			onConfirm={deleteProtocol}
			onDecline={closeThisModal}
		/>
	)
}

export default ConfirmDeleteProtocolModalContainer
