import React, { PureComponent } from 'react'
import { string, bool, oneOf } from 'prop-types'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
	display: flex;
	padding: ${(props) => props.theme.spacing[4]};
	background-color: ${(props) => props.theme.colors.limedSpruce};
	border-radius: ${(props) => props.theme.spacing[3]};

	${(props) =>
		props.flexDirection &&
		css`
			flex-direction: ${props.flexDirection};
		`}
`

export default class SimpleDarkInfoPanel extends PureComponent {
	static propTypes = {
		flexDirection: oneOf(['column', 'row'])
	}

	render = () => {
		const { className, flexDirection, children } = this.props

		return (
			<Wrapper className={className} flexDirection={flexDirection}>
				{children}
			</Wrapper>
		)
	}
}
