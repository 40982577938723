/**
 * Tab navigation
 * @module components/menu/nav-tabs
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { NavLink, withRouter, routeIsActive } from '../../interfaces/router'

class NavTabs extends Component {
	renderItem(item) {
		let classes = routeIsActive(item.url) ? 'active' : ''

		if (this.props.location) {
			const path = this.props.location.pathname
			const arr = item.url.split('/')
			if (path.indexOf(item.url) === 0 && arr[3] === 'agenda' && arr[5] === 'agenda') {
				classes = 'active'
			}
			if (path.indexOf(item.url) === 0 && arr[3] === 'agenda' && arr[5] === 'previous-meeting') {
				classes = 'active'
			}
		}

		return (
			<li className={classes} key={item.url}>
				<NavLink to={item.url} activeClassName='active'>
					<FormattedMessage id={item.translationId} />
				</NavLink>
			</li>
		)
	}

	render() {
		const { items } = this.props
		const className = this.props.className ? 'nav nav-tabs ' + this.props.className : 'nav nav-tabs'

		if (!items || items.length === 0) {
			return null
		} else {
			return <ul className={className}>{items.map(this.renderItem.bind(this))}</ul>
		}
	}
}

export default withRouter(NavTabs)
