import React from 'react'
import { connect } from 'react-redux'
import { bool } from 'prop-types'
import styled from 'styled-components'
import Text from '../../shared/text/text'
import SimpleDarkInfoPanel from '../../shared/dark-info-panel/simple-dark-info-panel'
import Icon from '../../shared/icon/icon'
import { updateUser, saveUser } from '../../../actions/user.actions'
import { List } from 'immutable'

const MY_RECORDS_DARK_PANEL_MSG_ID = '94c2d82e-1c4d-439c-847a-c6b0b9041f9e'

const StyledTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const StyledBodyWrapper = styled.div`
	padding-top: ${(props) => props.theme.spacing[3]};
`

const StyledIconWrapper = styled.div``

const MyRecordsDarkInfoPanel = ({ companyRegisteredAt, updateUser, saveUser, dismissedBlocks, user }) => {
	const onCloseMessage = () => {
		const alreadyDismissed = dismissedBlocks && dismissedBlocks.some((uid) => uid === MY_RECORDS_DARK_PANEL_MSG_ID)

		if (!dismissedBlocks) {
			dismissedBlocks = List()
		}

		if (!alreadyDismissed && user) {
			dismissedBlocks = dismissedBlocks.push(MY_RECORDS_DARK_PANEL_MSG_ID)
			user = user.setIn(['siteSettings', 'dismissedBlocks'], dismissedBlocks)

			updateUser(user)
			saveUser('me', user)
		}
	}

	const renderDarkPanel = () => {
		return (
			<SimpleDarkInfoPanel mode='dark' icon='faTimes' iconType='solid' iconColor='white' closeAlert>
				<StyledTextWrapper>
					<Text tid='portfolio.investments.my_records.dark_panel.text1' color='white' bold={600} />
					<StyledBodyWrapper>
						<Text tid='portfolio.investments.my_records.dark_panel.text2' color='white' />
					</StyledBodyWrapper>
				</StyledTextWrapper>
				<StyledIconWrapper>
					<button
						type='button'
						className='close'
						onClick={() => {
							onCloseMessage()
						}}>
						<Icon icon='faTimes' type='light' color='white' size='sm' />
					</button>
				</StyledIconWrapper>
			</SimpleDarkInfoPanel>
		)
	}

	const alreadyDismissed = dismissedBlocks && dismissedBlocks.some((uid) => uid === MY_RECORDS_DARK_PANEL_MSG_ID)

	if (!alreadyDismissed) {
		return null
	}

	return renderDarkPanel()
}

MyRecordsDarkInfoPanel.propTypes = {
	companyRegisteredAt: bool
}

const mapStoreToProps = (store) => {
	return {
		dismissedBlocks: store.user.getIn(['userObj', 'siteSettings', 'dismissedBlocks']),
		user: store.user.get('userObj')
	}
}

const mapActionsToProps = {
	updateUser,
	saveUser
}

export default connect(mapStoreToProps, mapActionsToProps)(MyRecordsDarkInfoPanel)
