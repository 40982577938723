import React, { PureComponent } from 'react'
import { func, string } from 'prop-types'
import styled from 'styled-components'
import Text from '../../shared/text/text'

const StyledTextWrapper = styled.div`
	display: flex;
	flex: 1;
	margin-bottom: ${(props) => props.theme.spacing[4]};
`

export default class extends PureComponent {
	static propTypes = {
		onOpenSharedWithModal: func,
		tid: string
	}

	render = () => {
		const { onOpenSharedWithModal, tid } = this.props

		return (
			<StyledTextWrapper>
				<Text tid={tid} color='white' />
				&nbsp;
				<Text
					tid='meetings.protocol.publish.panel.open_shared_with_list'
					color='white'
					onClick={onOpenSharedWithModal}
					hasUnderline
				/>
			</StyledTextWrapper>
		)
	}
}
