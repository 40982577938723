import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { FILTER_FIELDS } from '../../constants/tasks'
import { hasAppliedAdvancedFilters } from '../../components/helpers/filter-and-sort.helper'
import { ButtonTransparentIcon } from '../../dumb-components/shared/button'
import Tooltip from '../../dumb-components/shared/tooltip/tooltip'

const BTN_STATES = {
	default: {
		tid: 'tooltip.tasks.action.advanced_filter'
	},
	hasAppliedFilters: {
		tid: 'tooltip.tasks.action.advanced_filter.active',
		delayShow: 'instant'
	}
}

class TaskAdvancedFilterButtonContainer extends Component {
	static propTypes = {
		onClick: func
	}

	onClick = () => {
		const { onClick } = this.props

		onClick && onClick()
	}

	render = () => {
		const { hasAppliedFilters, filterBy, defaultFilters } = this.props
		const advancedFilterApplied = hasAppliedAdvancedFilters(filterBy, defaultFilters)

		return (
			<Tooltip states={BTN_STATES} activeState={advancedFilterApplied && 'hasAppliedFilters'}>
				<ButtonTransparentIcon
					onClick={this.onClick}
					size='sml'
					icon='faSlidersH'
					notificationBadge={advancedFilterApplied}
				/>
			</Tooltip>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		filterBy: store.tasks.get('filterBy'),
		hasAppliedFilters: store.tasks.get('hasAppliedFilters'),
		defaultFilters: store.tasks.get('defaultFilters')
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(TaskAdvancedFilterButtonContainer)
