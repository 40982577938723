import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { filter, sort } from '../../components/helpers/filter-and-sort.helper'
import { setFilteredInvestors } from '../../actions/investors.actions'
import { DEFAULT_SHOWBY_VALUE, DEFAULT_ORDER_VALUE } from '../../constants/shares'

class ShareholdersFilterAndSortContainer extends Component {
	componentDidMount = () => {
		this.applyFiltersAndSort()
	}

	componentDidUpdate = (prevProps) => {
		const { list, filters } = this.props
		if (prevProps.list !== list) {
			this.applyFiltersAndSort()
		}
		if (prevProps.filters !== filters) {
			this.applyFiltersAndSort()
		}
	}

	applyFiltersAndSort = () => {
		const { list, setFilteredInvestors, filters } = this.props
		let visibleInvestors = list
		const hasAppliedFilters = this.hasAppliedFilters(filters)

		if (visibleInvestors) {
			visibleInvestors = filter(visibleInvestors, filters)
			visibleInvestors = sort(visibleInvestors, filters)
			setFilteredInvestors(visibleInvestors, hasAppliedFilters)
		}
	}

	hasAppliedFilters = (filters) => {
		let hasAppliedFilters = false

		// Covers advanced filters. There are 2 filters in reducer by default
		// and they can not be removed in any way.
		if (filters.size > 2) {
			hasAppliedFilters = true
		} else {
			const order = (filters.find((obj) => obj.get('source') === 'order') || Map()).getIn(['values', 0])
			const showBy = (filters.find((obj) => obj.get('source') === 'showBy') || Map()).getIn(['values', 0])

			if (order !== DEFAULT_ORDER_VALUE || showBy !== DEFAULT_SHOWBY_VALUE) {
				hasAppliedFilters = true
			}
		}

		return hasAppliedFilters
	}

	render = () => {
		return this.props.children
	}
}

const mapStoreToProps = (store) => {
	return {
		list: store.investors.get('list'),
		filters: store.investors.get('filterBy')
	}
}

const mapActionsToProps = {
	setFilteredInvestors
}

export default connect(mapStoreToProps, mapActionsToProps)(ShareholdersFilterAndSortContainer)
