import React from 'react'
import { Select } from 'mui-rff'
import MenuItem from '@mui/material/MenuItem'

const CustomSelect = (props) => {
	const style = props.readOnly
		? {
				'& .MuiInputBase-input.Mui-disabled': {
					WebkitTextFillColor: '#BDBDBD'
				}
		  }
		: {}

	return (
		<Select disabled={props.readOnly} name={props.id} labelId={props.id} fullWidth sx={style} label={props.label}>
			{props.options.map((opt, i) => (
				<MenuItem
					key={i}
					value={opt.id}
					onClick={() => {
						props.onItemClick && props.onItemClick(opt)
					}}>
					{opt.text}
				</MenuItem>
			))}
		</Select>
	)
}

export default CustomSelect
