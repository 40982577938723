import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import SynaSearch from '../../helpers/syna-search'
import Moment from '../../../modules/moment.module'
import Checkbox from '../../../dumb-components/shared/checkbox/checkbox'
import { Margin } from 'styled-components-spacing'
import Box from '../../../dumb-components/shared/layout/box/box'
import CompanySearchContainer from '../../../containers/dashboard/company-search.container'

class CompanySearch extends Component {
	state = {
		agreementApproved: false,
		companySelected: false
	}

	goToNextStep() {
		this.props.jumpToStep(1)
	}

	onCancel() {
		this.props.jumpToStep(0)
		this.props.onCancel()
	}

	selectCompany(company) {
		this.props.onChange(company)
		this.setState({ companySelected: true })
	}

	onChange = (company) => {
		this.props.onChange(company, true)
		this.setState({ companySelected: true })
	}

	toogleApproveAgreement = () => {
		this.setState({ agreementApproved: !this.state.agreementApproved })
	}

	renderTableHeader() {
		return (
			<thead>
				<tr>
					<th className='account-company'>
						<FormattedMessage id='account.company' />
					</th>
					<th className='account-type'>
						<FormattedMessage id='account.orgnumber' />
					</th>
					<th className='account-registered'>
						<FormattedMessage id='account.registered' />
					</th>
					<th className='account-status'>
						<FormattedMessage id='account.address' />
					</th>
					<th>&nbsp;</th>
				</tr>
			</thead>
		)
	}

	renderTableBody() {
		const company = this.props.selectedCompanyDetails

		if (!company) {
			return (
				<tbody>
					<tr>
						<td colSpan='5'></td>
					</tr>
				</tbody>
			)
		}

		const companyDateOfReg = company.dateOfReg ? Moment(company.dateOfReg).format('YYYY') : false
		let companyOrgNumber = ''
		if (company.orgNumber.indexOf('demo=') === 0) {
			companyOrgNumber = 'DEMO'
		} else {
			companyOrgNumber = company.orgNumber ? company.orgNumber.substr(0, 6) + '-' + company.orgNumber.substr(6) : ''
		}

		let companyAddress = ''
		if (company.addresses?.[0]) {
			if (company.addresses[0].street) {
				companyAddress += company.addresses[0].street + ', '
			}
			if (company.addresses[0].postalCode) {
				companyAddress += company.addresses[0].postalCode + ' '
			}
			if (company.addresses[0].city) {
				companyAddress += company.addresses[0].city
			}
		}

		return (
			<tbody>
				<tr>
					<td>{company.name}</td>
					<td>{companyOrgNumber}</td>
					<td>{companyDateOfReg}</td>
					<td>{companyAddress}</td>
					<td className='text--align-right text--invono'>
						<span className='fa fa-check'></span>
					</td>
				</tr>
			</tbody>
		)
	}

	render() {
		const { agreementApproved, companySelected } = this.state
		const agreementUrl = 'https://invono.se/om-oss/anvandarvillkor-och-kundavtal/kundavtal-bolagskonto/'

		return (
			<div className='account-wizard__content account-wizard--animated account-wizard--fade-in-right'>
				<div className='account-wizard__header account-wizard__header--h90'>
					<h2 className='account-wizard__title'>
						<span className='account-wizard__step'>1</span> <FormattedMessage id='account_wizard.select_your_company' />
					</h2>
				</div>
				<div className='account-wizard__body account-wizard__body--h275'>
					<div className='i-panel'>
						<div className='i-panel__body i-panel__body--narrow'>
							<div className='form-group col-md-6 mar-btm-0'>
								<CompanySearchContainer onSelectCompany={this.selectCompany.bind(this)} onChange={this.onChange} />
							</div>
							{/*<div className='form-group col-md-6 mar-btm-0'>
								<SynaSearch onChange={this.selectCompany.bind(this)} />
							</div>*/}
						</div>
					</div>

					<div className='i-panel i-panel--white'>
						<div className='i-panel__body'>
							<FormattedMessage id='account_wizard.select_company_info' />
						</div>
					</div>

					<div className='i-panel i-panel--white'>
						<div className='i-panel__body'>
							<table className='table table-striped'>
								{this.renderTableHeader()}
								{this.renderTableBody()}
							</table>
						</div>
					</div>
				</div>
				<div className='account-wizard__footer'>
					<Margin all={4}>
						<Box alignItems='center'>
							<Checkbox mode='modern-big' onChange={this.toogleApproveAgreement} checked={agreementApproved}>
								<span>
									<FormattedMessage id='account.wizard.accept.agreement.part.1' />
									<a href={agreementUrl} target='_blank' rel='noopener noreferrer'>
										<span className='text--agreement'>
											<FormattedMessage id='account.wizard.accept.agreement.part.2' />
										</span>
									</a>
								</span>
							</Checkbox>
						</Box>
					</Margin>
					<button type='button' className='btn btn-account-wizard' onClick={this.onCancel.bind(this)}>
						<FormattedMessage id='generic.form.cancel' />
					</button>
					<button
						type='button'
						className='btn btn-account-wizard'
						onClick={this.goToNextStep.bind(this)}
						disabled={!agreementApproved || !companySelected}>
						<FormattedMessage id='next' />
					</button>
				</div>
			</div>
		)
	}
}

export default CompanySearch
