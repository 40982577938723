import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'
import { Map } from 'immutable'

import AttendeeManageFunctionsModal from '../../../dumb-components/meetings/attendee-manage-functions/attendee-manage-functions-modal'
import ManageAttendeeFunctionsContainer from './manage-attendee-functions.container'

import { MEETINGS_ATTENDEE_FUNCTIONS_MANAGE_MODAL } from '../../../constants/modals'
import { closeModal } from '../../../actions/modals.actions'

class ManageAttendeeFunctionsModalContainer extends Component {
	static propTypes = {
		name: string
	}

	closeModal = () => {
		this.props.closeModal()
	}

	render() {
		const { isOpen, name } = this.props

		if (!isOpen) {
			return null
		}

		return (
			<AttendeeManageFunctionsModal onClose={this.closeModal} name={name}>
				<ManageAttendeeFunctionsContainer />
			</AttendeeManageFunctionsModal>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		isOpen: store.modals.getIn(['activeModal', 'name']) === MEETINGS_ATTENDEE_FUNCTIONS_MANAGE_MODAL
	}
}

const mapActionsToProps = {
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(ManageAttendeeFunctionsModalContainer)
