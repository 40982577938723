import React, { Component } from 'react'
import { List, fromJS, Map } from 'immutable'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'

import ManageProxyDocuments from '../../../../dumb-components/meetings/agm/manage-proxy-documents'
import DropdownMenuContainer from '../../../shared/dropdown-menu.container'
import AttachmentsByObjIdContainer from '../../../shared/attachments-by-objid.container'
import DropdownIconItem from '../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'

import DocumentsHelper from '../../../../components/helpers/documents.helper'

import { patchMeeting, patchMeetingLocal } from '../../../../actions/meetings.actions'
import { downloadDocument, deleteDocumentsExternal } from '../../../../actions/documents.actions'

import { OBJ_TYPE_ATTENDEE } from '/shared/constants'

class ManageProxyDocumentsContainer extends Component {
	state = {
		initialLoading: true,
		isUploading: false,
		documents: List()
	}

	static propTypes = {
		attendeeId: string.isRequired
	}

	static defaultProps = {}

	dropzoneRef = null
	dropdownRefs = []

	setDropzoneRef = (ref) => {
		this.dropzoneRef = ref
	}

	setDropdownRef = (id, ref) => {
		this.dropdownRefs[id] = ref
	}

	openUploadFileExplorer = () => {
		this.dropzoneRef.open()
	}

	onDocumentsLoaded = (documents = List()) => {
		const { initialLoading } = this.state

		if (initialLoading) {
			this.setState({ documents, initialLoading: false })
		} else {
			this.setState({ documents })
		}
	}

	toggleDropdown = (documentId) => {
		this.dropdownRefs && this.dropdownRefs[documentId] && this.dropdownRefs[documentId].onToggleMenu()
	}

	// Sending in Map as documentData creates empty object
	// Sending in undefined removes the object
	patchProxyDocumentLocal = (documentId, documentData = Map()) => {
		const { patchMeetingLocal, attendeeId } = this.props
		const dataToUpdate = fromJS({
			attendees: {
				[attendeeId]: {
					proxyDocuments: {
						[documentId]: documentData
					}
				}
			}
		})

		patchMeetingLocal(dataToUpdate)
	}

	onDocumentUploaded = (uploadObj) => {
		const { documents } = this.state
		const { documentId, document } = uploadObj
		this.patchProxyDocumentLocal(documentId)
		this.setState({ isUploading: false, documents: documents.push(document) })
	}

	onBeforeUpload = () => {
		this.setState({ isUploading: true })
	}

	openDocument = (documentId) => {
		const { downloadDocument } = this.props
		this.toggleDropdown(documentId)
		downloadDocument({ documentId, openInViewer: true })
	}

	downloadDocument = (documentId) => {
		const { downloadDocument } = this.props
		this.toggleDropdown(documentId)
		downloadDocument({ documentId })
	}

	deleteDocument = (documentId) => {
		let { documents } = this.state
		const { deleteDocumentsExternal, meetingId, attendeeId } = this.props
		this.toggleDropdown(documentId)
		deleteDocumentsExternal(documentId, OBJ_TYPE_ATTENDEE, `${meetingId}$${attendeeId}`)
		this.patchProxyDocumentLocal(documentId, undefined)
		documents = documents.filter((doc) => {
			return doc.get('id') !== documentId
		})
		this.setState({ documents })
	}

	onChangeOverrideDocumentAcceptance = (ignoreProxyDocuments) => {
		const { meetingId, attendeeId, patchMeeting } = this.props
		patchMeeting(meetingId, fromJS({ attendees: { [attendeeId]: { ignoreProxyDocuments } } }))
	}

	onChangeDocumentAcceptance = (documentId, status) => {
		const { meetingId, attendeeId, patchMeeting } = this.props
		const dataToUpdate = fromJS({
			attendees: {
				[attendeeId]: {
					proxyDocuments: {
						[documentId]: { status }
					}
				}
			}
		})

		patchMeeting(meetingId, dataToUpdate)
	}

	renderMoreActionDropdown = (doc) => {
		const documentId = doc.get('id')
		const file = doc.get('file')
		const ext = file.get('ext')
		const isViewableInBrowser = DocumentsHelper.isViewableInBrowser(file, ext)

		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisV'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				tooltipActiveState='btnMoreActions'
				ref={this.setDropdownRef.bind(null, documentId)}
				noHorizontalMargin
				withPortal
				inline>
				{isViewableInBrowser && (
					<DropdownIconItem
						icon='faExpandArrowsAlt'
						tid='open_file'
						onClick={this.openDocument.bind(null, documentId)}
					/>
				)}

				<DropdownIconItem
					icon='faArrowAltToBottom'
					tid='download_file'
					onClick={this.downloadDocument.bind(null, documentId)}
				/>

				<DropdownIconItem icon='faTrashAlt' tid='delete_file' onClick={this.deleteDocument.bind(null, documentId)} />
			</DropdownMenuContainer>
		)
	}

	render = () => {
		const { meetingId, attendee, attendeeId, usersCache, investorsCache } = this.props
		const { initialLoading, isUploading, documents } = this.state

		return (
			<>
				<ManageProxyDocuments
					documents={documents}
					initialLoading={initialLoading}
					isUploading={isUploading}
					attendee={attendee}
					onChangeOverrideDocumentAcceptance={this.onChangeOverrideDocumentAcceptance}
					onChangeDocumentAcceptance={this.onChangeDocumentAcceptance}
					onUpload={this.openUploadFileExplorer}
					renderDropdown={this.renderMoreActionDropdown}
					investorsCache={investorsCache}
					usersCache={usersCache}
				/>

				<AttachmentsByObjIdContainer
					objType='attendee'
					objId={`${meetingId}$${attendeeId}`}
					setDropzoneRef={this.setDropzoneRef}
					invisible={true}
					onDocumentsLoaded={this.onDocumentsLoaded}
					onUpload={this.onDocumentUploaded}
					onBeforeUpload={this.onBeforeUpload}
				/>
			</>
		)
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		meetingId: store.meetings.getIn(['meeting', 'id']),
		attendees: store.meetings.getIn(['meeting', 'attendees']),
		proxies: store.meetings.getIn(['meeting', 'proxies']),
		attendee: store.meetings.getIn(['meeting', 'attendees', ownProps.attendeeId], Map()) || Map(),
		investorsCache: store.investors.get('investorsCache'),
		usersCache: store.usersCache.get('usersCache')
	}
}

const mapActionsToProps = {
	patchMeeting,
	patchMeetingLocal,
	downloadDocument,
	deleteDocumentsExternal
}

export default connect(mapStoreToProps, mapActionsToProps)(ManageProxyDocumentsContainer)
