import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '../../../mui-components/button/icon-button'
import { useDispatch, useSelector } from 'react-redux'
import MUIDialog from '../../../mui-components/dialog/mui-dialog'
import CriteriaListsGrid from '../criteriaListsGrid'
import { closeModal, openModal } from '../../../actions/modals.actions'
import { DEALFLOW_USER_CRITERIA_MODAL } from '../../../constants/modals'
import { fetchUserCriteriaLists } from '../../../actions/dealflow.actions'

export default function CriteriaListsModal() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const dealflowUserCriteriaLists = useSelector((state) => state.dealflow.userCriteriaLists)

	useEffect(() => {
		dispatch(fetchUserCriteriaLists())
	}, [])

	return (
		<MUIDialog
			isOpen={true}
			title={t('dealflow.criteria_modal.title')}
			closeButtonTid={'dealflow.criteria_modal.close_button'}
			onClose={() => {
				dispatch(closeModal())
			}}
			maxWidth={'xl'}
			fullWidth
			confirmButton={false}>
			<Grid container spacing={2}>
				<Grid item lg={9} md={12}>
					<Card variant={'dark'}>
						<CardContent>
							<Typography variant={'body1'}>
								{t('dealflow.criteria_modal.criteria_list.info_card.title')}
							</Typography>
							<Typography variant={'body2'}>
								{t('dealflow.criteria_modal.criteria_list.info_card.paragraph')}
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item lg={3} md={12}>
					<Card variant={'dark'}>
						<CardContent variant={'flex'}>
							<Box>
								<Typography variant={'body1'}>
									{t('dealflow.criteria_modal.create_list.darkCard.title')}
								</Typography>
								<Typography variant={'body2'}>
									{t('dealflow.criteria_modal.create_list.darkCard.paragraph')}
								</Typography>
							</Box>
							<IconButton
								icon={'faPlus'}
								color={'white'}
								onClick={() => {
									dispatch(openModal(DEALFLOW_USER_CRITERIA_MODAL, undefined, true))
								}}
							/>
						</CardContent>
					</Card>
				</Grid>
				{dealflowUserCriteriaLists.size > 0 && <CriteriaListsGrid />}
			</Grid>
		</MUIDialog>
	)
}
