import React, { useEffect, useRef } from 'react'
import { clsx } from 'clsx'
import Checkbox from '../../../../mui-components/checkbox/checkbox'

function CheckboxRenderer(params) {
	const { value, node } = params

	if (!node.selectable) {
		return null
	}

	useEffect(() => {
		if (value) {
			node.setSelected(true, false, true)
		}
	}, [])

	return (
		<Checkbox
			checked={typeof value === 'undefined' ? false : value}
			onChange={(event) => {
				const checked = event.target.checked
				node.setSelected(checked)
			}}
			disableRipple
			disableTouchRipple
			sx={{ p: 0 }}
		/>
	)
}

export { CheckboxRenderer }
