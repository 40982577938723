import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSubscription, refillPrepaid, saveSubscriptions } from '../../../actions/subscriptions.actions'

export const usePrepaid = () => {
	const [amount, setAmount] = useState('')
	const [termsAccepted, setTermsAccepted] = useState(false)
	const [autoRefill, setAutoRefill] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const subscription = useSelector((state) => state.subscriptions.get('subscription'))
	const dispatch = useDispatch()

	const saveButtonDisabled = amount === '' || isNaN(amount) || termsAccepted === false || isSubmitting

	const PREPAID_MODAL_TOOLTIP_STATES = {
		amountNotSelected: {
			title: 'subscriptions.prepaid_modal.tooltip.amount_not_selected',
			delayShow: 'instant'
		},
		termsNotAccepted: {
			title: 'subscriptions.prepaid_modal.tooltip.terms_not_accepted',
			delayShow: 'instant'
		}
	}

	const activeTooltipState = (() => {
		if (amount === '' || isNaN(amount)) {
			return 'amountNotSelected'
		} else if (!termsAccepted) {
			return 'termsNotAccepted'
		}
	})()

	const savePrepaidBalance = async (callback, errorCallback) => {
		let newSubscription = subscription.set('prepaidSelectedAmount', amount)
		newSubscription = newSubscription.set('prepaidAutoRefill', autoRefill)

		await dispatch(saveSubscriptions(newSubscription.get('companyId'), newSubscription))
		await dispatch(refillPrepaid(callback, errorCallback))
	}

	useEffect(() => {
		dispatch(fetchSubscription())
	}, [!subscription])

	useEffect(() => {
		if (subscription) {
			setAutoRefill(subscription.get('prepaidAutoRefill'))
		}
	}, [subscription?.get('prepaidAutoRefill')])

	useEffect(() => {
		if (amount === '' && subscription) {
			setAmount(subscription.get('prepaidSelectedAmount'))
		}
	}, [subscription?.get('prepaidSelectedAmount')])

	return {
		amount,
		setAmount,
		termsAccepted,
		setTermsAccepted,
		autoRefill,
		setAutoRefill,
		isSubmitting,
		setIsSubmitting,
		subscription,
		activeTooltipState,
		saveButtonDisabled,
		savePrepaidBalance,
		tooltipStates: PREPAID_MODAL_TOOLTIP_STATES
	}
}
