import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

/**
 *
 * @param {object} props
 * @param {string} props.tooltipTid
 * @param {function} props.shouldShow
 * @returns JSX.Element
 */
function TextOnlyTooltip(props) {
	const { tooltipTid, shouldShow } = props
	const { t } = useTranslation()

	const tid = typeof tooltipTid === 'function' ? tooltipTid(props) : tooltipTid

	if (!tid) {
		return null
	}

	if (typeof shouldShow === 'function' && !shouldShow(props)) {
		return null
	}

	return (
		<Box className='custom-tooltip' sx={{ padding: 2, minWidth: '200px', maxWidth: '300px' }}>
			{typeof tid === 'string' ? <Typography variant='body2'>{t(tid)}</Typography> : tid}
		</Box>
	)
}

export { TextOnlyTooltip }
