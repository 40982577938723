import React from 'react'
import styled, { css } from 'styled-components'
import { oneOf, bool, func, string, object } from 'prop-types'
import RTE from '../rte/rte'
import Button from '../button/button'
import ProfileImage from '../profile-image/profile-image'

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	min-height: ${(props) => (props.isFocused ? '120px' : '50px')};
	transition: min-height 0.25s;
`

const StyledProfileImageWrapper = styled.div`
	margin-right: ${(props) => props.theme.spacing[4]};
`

const StyledRteWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	border: 1px solid #c3c3c3;
	transition: border-color ease-in-out 0.5s, box-shadow ease-in-out 0.5s;
	transition-duration: 0.5s;

	${(props) =>
		props.isFocused === true &&
		css`
		border-color: #42A5F5};
	`}
`

const StyledRTE = styled(RTE)`
	background: ${(props) => (props.isDisabled ? props.theme.colors.disabled : props.theme.colors.white)};
	cursor: text;
	flex: 1;
	padding-left: 10px;
	padding-right: 10px;
	/*padding: 8px 12px;*/
`

const StyledControlsWrapper = styled.div`
	background: ${(props) => props.theme.colors.white};
	align-items: center;
	cursor: text;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding-right: ${(props) => props.theme.spacing[3]};
	padding-bottom: ${(props) => (props.isFocused ? props.theme.spacing[3] : 0)};
	height: ${(props) => (props.isFocused ? 'auto' : '0px')};
	overflow: hidden;
	transition: height 0.25s;
`

const CommentCreator = ({
	className,
	mode,
	editorState,
	userImage,
	onChange,
	onRef,
	onShouldFocus,
	onBlur,
	onFocus,
	isFocused,
	onCommentSave,
	onCommentCancel,
	isDisabled,
	placeholderTid,
	btnTid
}) => {
	const hasNoContent = editorState.getCurrentContent().getPlainText().length === 0

	const _onShouldFocus = (e) => {
		if (!isDisabled) {
			onShouldFocus()
		}
	}

	return (
		<StyledWrapper className={className} isFocused={isFocused}>
			{mode !== 'inline-edit' && (
				<StyledProfileImageWrapper>
					<ProfileImage height='50px' width='50px' image={userImage} />
				</StyledProfileImageWrapper>
			)}

			<StyledRteWrapper onClick={_onShouldFocus} isFocused={isFocused}>
				<StyledRTE
					editorState={editorState}
					onChange={onChange}
					onRef={onRef}
					onFocus={onFocus}
					onBlur={onBlur}
					placeholderTid={placeholderTid}
					readOnly={isDisabled ? true : false}
					isDisabled={isDisabled}
				/>

				<StyledControlsWrapper isFocused={isFocused}>
					{isFocused && mode === 'inline-edit' && <Button tid='generic.form.cancel' onMouseDown={onCommentCancel} />}
					{isFocused && (
						<Button mode='primary' tid={btnTid} onMouseDown={onCommentSave} disabled={hasNoContent || isDisabled} />
					)}
				</StyledControlsWrapper>
			</StyledRteWrapper>
		</StyledWrapper>
	)
}

CommentCreator.defaultProps = {}

CommentCreator.propTypes = {
	mode: oneOf(['inline-edit']),
	userImage: string,
	editorState: object,
	onChange: func,
	onShouldFocus: func,
	onBlur: func,
	isFocused: bool,
	onCommentSave: func,
	onCommentCancel: func,
	isDisabled: bool,
	placeholderTid: string,
	btnTid: string
}

export default CommentCreator
