/**
 * Company reducer
 * @module reducers/company.reducer
 */

import {
	COMPANIES_GET,
	COMPANIES_GET_ACCESS_RIGHTS,
	COMPANIES_COMPANY_UPDATE_REMOTE,
	COMPANY_ERROR,
	USER_MANAGEMENT_FETCH_USERS,
	USER_MANAGEMENT_FETCH_ROLES,
	USER_MANAGEMENT_FETCH_ROLES_CACHE,
	USER_MANAGEMENT_FETCH_RESOURCES,
	USER_MANAGEMENT_ROW_STATES,
	USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS_START,
	USER_MANAGEMENT_FETCH_SELECTED_USER,
	USER_MANAGEMENT_FETCH_SELECTED_ROLE,
	USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS,
	USER_MANAGEMENT_CHANGED_SELECTED_ROLE_VALUE,
	COMPANY_CHANGED,
	COMPANY_RESET_REDUCER,
	COMPANY_FETCH_USER_TAGS,
	COMPANY_DIRTY,
	COMPANY_EDIT_START,
	COMPANY_UPDATE_LOCAL,
	COMPANY_FETCH_MEMBERS,
	USER_MANAGEMENT_CHANGED_PERMISSIONS_BUNDLES,
	USER_MANAGEMENT_REQUEST_PENDING,
	USER_MANAGEMENT_FETCH_USERS_IN_ROLES_CACHE,
	USER_MANAGEMENT_RESET_CACHE,
	CREDENTIALS_SIGNOUT,
	COMPANY_FETCH_MEMBERS_AS_OPTIONS,
	DEALFLOW_COMPANY_CHANGE_DISPLAY_STATUS
} from '../actions/types'
import { List, Map, fromJS } from 'immutable'

/** The initial state object for company. */
const INITIAL_STATE = {
	error: null,
	userManagement: {
		users: [],
		roles: [],
		rolesCache: Map(),
		usersInRoleCache: Map(),
		resources: List(),
		rowStates: {},
		//selectedRole: {},
		selectedRolePermissions: Map(),
		selectedRoleValue: null,
		selectedUser: {},
		bundles: Map(),
		permissionRequestPending: false
	},
	company: null,
	companies: List(),
	companiesAccessRights: Map(),
	customTags: null,
	companyDirty: false,
	members: List(),
	membersAsOptions: List()
}

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case COMPANIES_GET: {
			return { ...state, companies: fromJS(action.payload) }
		}
		case COMPANIES_COMPANY_UPDATE_REMOTE: {
			const { payload } = action
			let { companies } = state
			const companyIndex = state.companies.findIndex((company) => company.get('id') === payload.get('id'))
			companies = state.companies.set(companyIndex, payload)
			return { ...state, companies }
		}
		case COMPANY_RESET_REDUCER:
			return { ...INITIAL_STATE, company: state.company, companies: state.companies } // We can´t remove company, but it will be changed by the urlAlias change.
		case COMPANY_ERROR:
			return { ...state, error: action.payload }
		case COMPANY_CHANGED: {
			const { companyDirty } = state
			let { company } = state

			if (!companyDirty) {
				company = action.payload
			}

			if (company && company.orgNumber && company.orgNumber.indexOf('demo=') === 0) {
				company.orgNumber = '5569872335'
			}

			return { ...state, company }
		}

		case COMPANIES_GET_ACCESS_RIGHTS: {
			return { ...state, companiesAccessRights: fromJS(action.payload) }
		}

		case COMPANY_DIRTY:
			return { ...state, companyDirty: true }

		case COMPANY_EDIT_START:
			return { ...state, companyDirty: false }

		case DEALFLOW_COMPANY_CHANGE_DISPLAY_STATUS:
		case COMPANY_UPDATE_LOCAL:
			return { ...state, company: action.payload }

		// Users list
		case USER_MANAGEMENT_FETCH_USERS:
			return {
				...state,
				userManagement: {
					...state.userManagement,
					users: action.payload
				}
			}

		// Single user
		case USER_MANAGEMENT_FETCH_SELECTED_USER:
			return {
				...state,
				userManagement: {
					...state.userManagement,
					selectedUser: action.payload
				}
			}

		// Roles list
		case USER_MANAGEMENT_FETCH_ROLES:
			return {
				...state,
				userManagement: {
					...state.userManagement,
					roles: action.payload
				}
			}

		case USER_MANAGEMENT_FETCH_ROLES_CACHE: {
			const roles = action.payload
			let rolesCache = state.userManagement.rolesCache
			roles.forEach((role) => {
				rolesCache = rolesCache.set(role.get('id'), role)
			})
			return {
				...state,
				userManagement: {
					...state.userManagement,
					rolesCache: rolesCache
				}
			}
		}

		case USER_MANAGEMENT_FETCH_USERS_IN_ROLES_CACHE: {
			const rolesWithUsers = action.payload
			let usersInRoleCache = state.userManagement.usersInRoleCache
			rolesWithUsers.forEach((users, roleId) => {
				usersInRoleCache = usersInRoleCache.set(roleId, users)
			})
			return {
				...state,
				userManagement: {
					...state.userManagement,
					usersInRoleCache: usersInRoleCache
				}
			}
		}

		case USER_MANAGEMENT_RESET_CACHE: {
			return {
				...state,
				userManagement: {
					...state.userManagement,
					usersInRoleCache: Map(),
					rolesCache: Map()
				}
			}
		}

		// Resources
		case USER_MANAGEMENT_FETCH_RESOURCES:
			return {
				...state,
				userManagement: {
					...state.userManagement,
					resources: action.payload
				}
			}

		// Row state
		case USER_MANAGEMENT_ROW_STATES:
			return {
				...state,
				userManagement: {
					...state.userManagement,
					rowStates: action.payload
				}
			}

		// Selected role details
		case USER_MANAGEMENT_FETCH_SELECTED_ROLE:
			return {
				...state,
				userManagement: {
					...state.userManagement,
					selectedRole: action.payload
				}
			}

		// Role permissions
		case USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS:
			return {
				...state,
				userManagement: {
					...state.userManagement,
					selectedRolePermissions: action.payload
				}
			}

		// Local changes
		case USER_MANAGEMENT_FETCH_SELECTED_ROLE_PERMISSIONS_START: {
			const resourceId = action.payload.get('resourceId')
			const bundleId = action.payload.get('bundleId')

			return {
				...state,
				userManagement: {
					...state.userManagement,
					selectedRolePermissions: state.userManagement.selectedRolePermissions.set(resourceId, bundleId)
				}
			}
		}

		// Role permissions settings
		case USER_MANAGEMENT_CHANGED_SELECTED_ROLE_VALUE:
			return {
				...state,
				userManagement: {
					...state.userManagement,
					selectedRoleValue: action.payload
				}
			}

		// Permissions bundles
		case USER_MANAGEMENT_CHANGED_PERMISSIONS_BUNDLES:
			return {
				...state,
				userManagement: {
					...state.userManagement,
					bundles: action.payload
				}
			}

		// Fetch custom tags
		case COMPANY_FETCH_USER_TAGS:
			return { ...state, customTags: action.payload }

		// Fetch company members
		case COMPANY_FETCH_MEMBERS:
			return { ...state, members: action.payload }

		// Set pending request in user management
		case USER_MANAGEMENT_REQUEST_PENDING:
			return {
				...state,
				userManagement: {
					...state.userManagement,
					permissionRequestPending: action.payload
				}
			}

		case COMPANY_FETCH_MEMBERS_AS_OPTIONS:
			return { ...state, membersAsOptions: action.payload }

		case CREDENTIALS_SIGNOUT:
			return INITIAL_STATE

		default:
			return state
	}
}
