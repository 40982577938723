import React from 'react'
import { string, func, bool } from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { FormattedMessage } from 'react-intl'
import { OutlinedButton } from '../../dumb-components/shared/button-v2'
import NotificationBadge from '../../dumb-components/shared/notification-badge/notification-badge'
import { useTheme } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { FormattedHTMLMessage } from 'react-intl'
import styled from 'styled-components'
import IconButton from '../button/icon-button'

const StyledTooltip = styled((props) => <Tooltip classes={{ popper: props.className }} {...props} />)`
	& .MuiTooltip-tooltip {
		background: white;
		color: #6c6c6c;
		font-size: 1.3rem;
		padding: 24px;
		border-radius: 0;
		box-shadow: 0 5px 12px 2px rgba(0, 0, 0, 0.25);
		max-width: 500px;
	}
`

const StyledTooltipContent = styled.div`
	display: flex;
	position: relative;
`
const StyledIconButtonWrapper = styled.div`
	position: absolute;
	inset: -24px -24px auto auto;
`

export default function DarkPanel({
	titleTid,
	bodyTid,
	icon,
	onClick,
	onCloseTooltip,
	isTooltipOpen,
	showNotificationBadge,
	tooltipTid
}) {
	const theme = useTheme()

	if (tooltipTid) {
		return (
			<StyledTooltip
				disableFocusListener
				open={isTooltipOpen}
				title={
					<StyledTooltipContent>
						<FormattedHTMLMessage {...tooltipTid} />
						<StyledIconButtonWrapper>
							<IconButton noBorder icon='faTimes' onClick={onCloseTooltip} />
						</StyledIconButtonWrapper>
					</StyledTooltipContent>
				}>
				<Card variant='dark'>
					<CardContent variant='flex'>
						<Box>
							<Typography variant='body1'>
								<FormattedMessage id={titleTid} />
							</Typography>
							{bodyTid && (
								<Typography variant='body2'>
									<FormattedMessage id={bodyTid} />
								</Typography>
							)}
						</Box>
						{icon && (
							<Box>
								{showNotificationBadge ? (
									<NotificationBadge
										color={theme.palette.success.main}
										x={-6}
										y={-6}
										size='md'
										active
										zIndex>
										<OutlinedButton icon={icon} onClick={onClick} />
									</NotificationBadge>
								) : (
									<OutlinedButton icon={icon} onClick={onClick} />
								)}
							</Box>
						)}
					</CardContent>
				</Card>
			</StyledTooltip>
		)
	}

	return (
		<Card variant='dark'>
			<CardContent variant='flex'>
				<Box>
					<Typography variant='body1'>
						<FormattedMessage id={titleTid} />
					</Typography>
					{bodyTid && (
						<Typography variant='body2'>
							<FormattedMessage id={bodyTid} />
						</Typography>
					)}
				</Box>
				{icon && (
					<Box>
						{showNotificationBadge ? (
							<NotificationBadge color={theme.palette.success.main} x={-6} y={-6} size='md' active zIndex>
								<OutlinedButton icon={icon} onClick={onClick} />
							</NotificationBadge>
						) : (
							<OutlinedButton icon={icon} onClick={onClick} />
						)}
					</Box>
				)}
			</CardContent>
		</Card>
	)
}

DarkPanel.propTypes = {
	titleTid: string,
	bodyTid: string,
	icon: string,
	onClick: func,
	showNotificationBadge: bool
}
