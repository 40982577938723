import React, { Component } from 'react'
import { connect } from 'react-redux'
import immutablePropTypes from 'react-immutable-proptypes'
import { string, func, bool } from 'prop-types'
import { Map, List } from 'immutable'
import { Margin } from 'styled-components-spacing'
import i18nHelper from '../../../components/helpers/i18n.helper'
import { listMeetings } from '../../../actions/meetings.actions'
import { listMeetingTemplates } from '../../../actions/meeting-templates.actions'
import { fetchSimpleUsers } from '../../../actions/usersCache.actions'
import { listGroups } from '../../../actions/groups.actions'
import FilterAndSort from '../../../components/helpers/filter-and-sort.helper'
import UniversalModal from '../../../dumb-components/shared/modal/universal-modal'
import PreviousMeetingList from '../../../dumb-components/meetings/previous-meeting/previous-meeting-list/previous-meeting-list'
import PreviousMeetingFilter from '../../../dumb-components/meetings/previous-meeting/previous-meeting-filter/previous-meeting-filter'
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../dumb-components/shared/button-v2'

class MeetingLinkModalContainer extends Component {
	static propTypes = {
		selectedMeeting: string,
		isOpen: bool,
		onSave: func,
		onCancel: func
	}

	state = {
		selectedMeeting: null,
		meetingsMetadata: Map(),
		filteredMeetings: List(),
		filterBy: List()
	}

	componentDidMount = () => {
		const { selectedMeeting } = this.props

		this.setState({ selectedMeeting })
	}

	componentDidUpdate = (prevProps, prevState) => {
		const {
			listMeetings,
			listMeetingTemplates,
			listGroups,
			meetings,
			meetingTemplates,
			usersCache,
			selectedMeeting,
			isOpen,
			groups
		} = this.props
		const { filterBy } = this.state

		if (isOpen && !prevProps.isOpen) {
			listMeetings()
			listMeetingTemplates()

			if (!groups || groups.size === 0) {
				listGroups()
			}
		}

		if (prevProps.meetings !== meetings) {
			this.filterMeetings()
			this.loadUsers()
			this.parseMetadata()
		}

		if (prevProps.meetingTemplates !== meetingTemplates || prevProps.usersCache !== usersCache) {
			this.parseMetadata()
		}

		if (prevProps.selectedMeeting !== selectedMeeting) {
			this.setState({ selectedMeeting })
		}

		if (prevState.filterBy !== filterBy) {
			this.filterMeetings()
		}
	}

	filterMeetings = () => {
		let { meetings } = this.props
		let { filterBy } = this.state
		const dateProp = this.props.filterBy.find((obj) => obj.get('source') === 'dateProp')
		const order = this.props.filterBy.find((obj) => obj.get('source') === 'order')

		if (dateProp && order) {
			filterBy = filterBy.push(dateProp)
			filterBy = filterBy.push(order)
		}

		const filterAndSort = new FilterAndSort(meetings, undefined, filterBy)
		filterAndSort.filter()
		filterAndSort.sort()
		let filteredMeetings = filterAndSort.getResult()
		filteredMeetings = filteredMeetings.filter((m) => !this.getMeetingIsDeleted(m))

		this.setState({ filteredMeetings })
	}

	loadUsers = () => {
		const { meetings, fetchSimpleUsers } = this.props
		let userIds = List()

		meetings &&
			meetings.forEach((meeting) => {
				if (meeting.get('chairman')) {
					userIds = userIds.push(meeting.get('chairman'))
				}

				if (meeting.get('secretary')) {
					userIds = userIds.push(meeting.get('secretary'))
				}
			})

		fetchSimpleUsers(userIds)
	}

	getMeetingIsDeleted = (meeting) => {
		return meeting?.get('isDeleted', false)
	}

	parseMetadata = () => {
		const { meetings, meetingTemplates, usersCache, i18n } = this.props
		let { meetingsMetadata } = this.state

		meetings &&
			meetings.forEach((meeting) => {
				if (this.getMeetingIsDeleted(meeting)) {
					return
				}

				let metadata = Map()
				const id = meeting.get('id')
				const chairman = meeting.get('chairman')
				const secretary = meeting.get('secretary')
				const templateId = meeting.get('templateId')

				if (chairman && usersCache && usersCache.has(chairman)) {
					metadata = metadata.set('chairman', usersCache.getIn([chairman, 'name']))
				}

				if (secretary && usersCache && usersCache.has(secretary)) {
					metadata = metadata.set('secretary', usersCache.getIn([secretary, 'name']))
				}

				if (templateId && meetingTemplates) {
					const meetingTemplate = meetingTemplates.find((obj) => obj.get('id') === templateId)

					if (meetingTemplate) {
						metadata = metadata.set('template', meetingTemplate.get('name'))
					} else if (templateId === 'LEGACY') {
						metadata = metadata.set('template', i18n.messages['meeting.general.template_option.legacy'])
					}
				}

				meetingsMetadata = meetingsMetadata.set(id, metadata)
			})

		this.setState({ meetingsMetadata })
	}

	onSave = () => {
		const { onSave } = this.props
		const { selectedMeeting } = this.state

		onSave(selectedMeeting)
		this.setState({ selectedMeeting: null })
	}

	onCancel = () => {
		const { onCancel } = this.props

		this.setState({ selectedMeeting: null })
		onCancel()
	}

	onSelectMeeting = (id) => {
		this.setState({ selectedMeeting: id })
	}

	onFilterChange = (val) => {
		let { filterBy } = this.state
		let filterMeetingsByDays = filterBy.find((obj) => obj.get('source') === 'filterMeetingsByDays')

		if (filterMeetingsByDays) {
			filterMeetingsByDays = filterMeetingsByDays.set('values', List([val]))
			filterBy = filterBy.map((filter) => {
				if (filter.get('source') === 'filterMeetingsByDays') {
					return filterMeetingsByDays
				}

				return filter
			})
		} else {
			filterBy = filterBy.push(Map({ source: 'filterMeetingsByDays', values: List([val]) }))
		}

		this.setState({ filterBy })
	}

	renderMeetingsList = () => {
		const { filteredMeetings, selectedMeeting, meetingsMetadata } = this.state

		return (
			<PreviousMeetingList
				meetings={filteredMeetings}
				metadata={meetingsMetadata}
				onSelectMeeting={this.onSelectMeeting}
				selectedMeetingId={selectedMeeting}
			/>
		)
	}

	renderModalButtons = () => {
		return (
			<FooterRightControls>
				<TransparentButton tid='generic.form.save' onClick={this.onSave} />
				<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={this.onCancel} />
			</FooterRightControls>
		)
	}

	renderFiltering = () => {
		const { filterBy } = this.state

		return <PreviousMeetingFilter onChange={this.onFilterChange} value={filterBy.getIn([0, 'values', 0])} />
	}

	render = () => {
		const { isOpen, groups, currentMeetingGroupId, i18n, company } = this.props

		return (
			<UniversalModal
				isOpen={isOpen}
				titleTid='meetings.link.title'
				modalFooterComponent={this.renderModalButtons()}
				modalHeaderComponent={this.renderFiltering}
				renderContent={this.renderMeetingsList}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meetings: store.meetings.get('allMeetings'),
		groups: store.groups.get('groups'),
		meetingTemplates: store.meetingTemplates.get('list'),
		usersCache: store.usersCache.get('usersCache'),
		i18n: store.i18n,
		company: store.company.company,
		filterBy: store.meetings.get('filterBy')
	}
}

const mapActionsToProps = {
	listMeetings,
	listMeetingTemplates,
	fetchSimpleUsers,
	listGroups
}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingLinkModalContainer)
