import React, { PureComponent } from 'react'
import { object, bool, oneOfType } from 'prop-types'
import styled from 'styled-components'
import ScrollView from '../../shared/layout/scroll-view/scroll-view'

const Wrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
`

const InnerWrapper = styled.div`
	margin: ${(props) => props.theme.spacing[4]};
`

const AlertsWrapper = styled.div`
	display: grid;
	grid-gap: ${(props) => props.theme.spacing[3]};

	> div :last-child {
		margin-bottom: ${(props) => props.theme.spacing[4]};
	}
`

const PanelsGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: ${(props) => props.theme.spacing[4]};
`

const StyledHalf = styled.div`
	grid-column: span 3;
	background: white;
`

const TeamMembersPanel = styled.div`
	grid-column: ${({ hasShareholdersComponent }) => (hasShareholdersComponent ? 'span 3' : 'span 6')};
`

const ShareholdersPanel = styled.div`
	grid-column: span 3;
`

const SmallPanel = styled.div`
	grid-column: span 6;

	@media screen and (min-width: 900px) {
		grid-column: span 2;
	}
`

export default class CompanyDashboardLayout extends PureComponent {
	static propTypes = {
		alertsComponent: object,
		companyInfoComponent: object,
		upgradePanelComponent: object,
		teamMembersComponent: object,
		shareholdersComponent: oneOfType([object, bool]),
		documentsComponent: object,
		meetingsComponent: object,
		tasksComponent: object
	}

	render = () => {
		return (
			<Wrapper>
				<ScrollView noLeftMargin>
					<InnerWrapper>
						<AlertsWrapper>{this.props.alertsComponent}</AlertsWrapper>
						<PanelsGrid>
							<StyledHalf>{this.props.companyInfoComponent}</StyledHalf>
							<StyledHalf>{this.props.upgradePanelComponent}</StyledHalf>
							<TeamMembersPanel hasShareholdersComponent={Boolean(this.props.shareholdersComponent)}>
								{this.props.teamMembersComponent}
							</TeamMembersPanel>
							{this.props.shareholdersComponent && (
								<ShareholdersPanel>{this.props.shareholdersComponent}</ShareholdersPanel>
							)}
							<SmallPanel>{this.props.documentsComponent}</SmallPanel>
							<SmallPanel>{this.props.meetingsComponent}</SmallPanel>
							<SmallPanel>{this.props.tasksComponent}</SmallPanel>
						</PanelsGrid>
					</InnerWrapper>
				</ScrollView>
			</Wrapper>
		)
	}
}
