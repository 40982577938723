import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'
import { List } from 'immutable'
import slug from 'slug'
import documentsHelper from '../../../components/helpers/documents.helper'

import StepsModal from '../../../dumb-components/shared/modal/steps-modal'
import FooterPrevious from '../../../dumb-components/shared/modal/previous-left-button'
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../dumb-components/shared/button-v2'
import ToggleSwitch from '../../../dumb-components/shared/toggle-switch/toggle-switch'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'
import Icon from '../../../dumb-components/shared/icon/icon'
import InfoAndWarningHeader from '../../../dumb-components/documents/document-list/info-and-warning-header'

import DocumentsDndSortContainer from '../documents-dnd-sort.container'
import MergeOverviewAndSaveContainer from './merge-overview-and-save.container'
import FoldersContainer from './folders.container'

import { closeModal, openModal } from '../../../actions/modals.actions'
import { transferDocument, addDocumentToList } from '../../../actions/documents.actions'
import {
	selectCollection,
	mergePdfsInCollection,
	saveMergedDocument,
	deleteMergedDocument,
	updateMergedDocumentLocal
} from '../../../actions/collections.actions'
import {
	saveSharedCollection,
	deleteSharedCollection,
	updateSharedCollectionLocal
} from '../../../actions/shared-collections.actions'

import folderHelper from '../../../components/helpers/folder.helper'
import history from '../../../interfaces/history'
import { __DELETE__ } from '/shared/constants'

import { COLLECTIONS_MANAGE_MODAL } from '../../../constants/modals'

const WarningComponent = ({ tid }) => (
	<Tooltip tid={tid} delayShow='instant' place='left'>
		<Icon icon='faExclamationTriangle' size='sml' color='yellowMid' type='solid' />
	</Tooltip>
)

class MergePdfModalContainer extends Component {
	state = {
		step: 0,
		mergeIsLoading: false,
		submitIsLoading: false,
		selectedFolder: null
	}

	static propTypes = {
		collection: map.isRequired // Required on mount
	}

	validateNameFunction

	setValidateNameFunction = (func) => {
		this.validateNameFunction = func
	}

	redirectoToMergedDocument = () => {
		const { companyUrlAlias, mergedDocument, history } = this.props
		const documentId = mergedDocument && mergedDocument.get('id')
		const documentFolder = mergedDocument && mergedDocument.get('folder')

		if (documentFolder) {
			history.push(`/${companyUrlAlias}/documents/${documentId}?folder=${documentFolder}`)
		} else {
			history.push(`/${companyUrlAlias}/documents/${documentId}`)
		}
	}

	submit = () => {
		const {
			mergedDocument,
			saveMergedDocument,
			saveSharedCollection,
			updateMergedDocumentLocal,
			updateSharedCollectionLocal,
			addDocumentToList
		} = this.props
		let { sharedCollection } = this.props
		const nameValid = this.validateNameFunction()

		if (!nameValid) {
			return
		}

		this.setState({ submitIsLoading: true })

		const title = mergedDocument.get('title')
		const documentId = mergedDocument.get('id')
		const slugedTitle = slug(title)

		const folderPath = this.getFolderPath()
		sharedCollection = sharedCollection.set('mergedDocumentTitle', title)
		sharedCollection = sharedCollection.set('mergedDocumentFolder', folderPath)

		const documentData = {
			title,
			file: {
				originalname: `${slugedTitle.endsWith('pdf') ? slugedTitle.substring(0, slugedTitle.length - 3) : ''}.pdf`
			},
			hideFromDocumentsSection: false
		}

		saveMergedDocument(documentId, documentData, (updatedMergedDocument) => {
			saveSharedCollection(sharedCollection, () => {
				addDocumentToList(updatedMergedDocument)
				this.redirectoToMergedDocument()
				updateMergedDocumentLocal()
				updateSharedCollectionLocal()
				this.closeModal()
			})
		})
	}

	doAbort = () => {
		this.deleteMergedDocumentAndSharedCollection()
		this.closeModal()
	}

	doGoBackToManageCollection = () => {
		const { openModal } = this.props
		openModal(COLLECTIONS_MANAGE_MODAL)
	}

	deleteMergedDocumentAndSharedCollection = () => {
		const { mergedDocument, deleteSharedCollection, sharedCollection, deleteMergedDocument } = this.props

		if (mergedDocument) {
			const mergedDocumentId = mergedDocument.get('id')
			const sharedCollectionId = sharedCollection.get('id')

			deleteMergedDocument(mergedDocumentId)
			deleteSharedCollection(sharedCollectionId)
		}
	}

	closeModal = () => {
		const { closeModal, selectCollection } = this.props
		selectCollection()
		closeModal()
	}

	doMergePdf = () => {
		const { mergePdfsInCollection, collectionDocuments, collection } = this.props
		const documentIdsToBeMerged = collectionDocuments
			.filter((d) => this.getCanBeMerged(d) && d.get('includedInAction') !== false)
			.map((d) => d.get('id'))

		this.setState({ step: 1, mergeIsLoading: true })
		mergePdfsInCollection(collection.get('id'), documentIdsToBeMerged, (result, err) => {
			if (!err) {
				this.setState({ mergeIsLoading: false })
			}
		})
	}

	goToDocumentsList = () => {
		this.deleteMergedDocumentAndSharedCollection()
		this.setState({ step: 0 })
	}

	goToMergeOverview = () => {
		this.setState({ step: 1 })
	}

	onGoToMoveFile = () => {
		this.setState({ step: 2 })
	}

	handleGetControlsData = ({ document, documentId, toBeMerged, handleToggle }) => {
		const canBeMerged = this.getCanBeMerged(document)

		if (!canBeMerged) {
			return {
				component: <WarningComponent tid='esign.advanced_modal.attachments.tooltip.incorrect_file_format' />,
				isDragDisabled: true
			}
		}

		return {
			component: <ToggleSwitch fieldName={documentId} onChange={handleToggle} checked={toBeMerged} />
		}
	}

	handleFilterIncludedDocuments = (document) => {
		return this.getCanBeMerged(document)
	}

	handleFolderChange = (selectedFolder) => {
		const { transferDocument, mergedDocument } = this.props
		this.setState({ selectedFolder })
		transferDocument([mergedDocument.get('id')], selectedFolder.get('id'))
	}

	getCanBeMerged = (document) => {
		return documentsHelper.getDocumentIsPdf(document.getIn(['file', 'ext']))
	}

	getFolderPath = () => {
		const { folders } = this.props
		const { selectedFolder } = this.state

		if (!selectedFolder) {
			return
		}

		let folderPathArray = folderHelper.getPath(selectedFolder.get('id'), folders)

		if (folderPathArray) {
			folderPathArray = folderPathArray.map((folderNameObj) => folderNameObj.get('sv'))

			return folderPathArray.join(' / ')
		}

		return selectedFolder.get('name')
	}

	getSteps = () => {
		const { collection, collectionDocuments } = this.props
		const { mergeIsLoading, selectedFolder, submitIsLoading } = this.state
		const collectionId = collection.get('id')
		const hasIncludedDocuments = collectionDocuments.some((d) => d.get('includedInAction') !== false)

		return [
			/* Documents sort view */
			{
				body: (
					<DocumentsDndSortContainer
						collectionId={collectionId}
						onGetControlsData={this.handleGetControlsData}
						onFilterIncludedDocuments={this.handleFilterIncludedDocuments}
						headerComponent={<InfoAndWarningHeader documents={collectionDocuments} />}
					/>
				),
				footer: {
					component: (
						<FooterRightControls>
							<Tooltip
								tid='collections.merge_pdf.modal.documents_list.tooltip.needs_document'
								delayShow='instant'
								active={!hasIncludedDocuments}>
								<TransparentButton tid='next' onClick={this.doMergePdf} disabled={!hasIncludedDocuments} />
							</Tooltip>
							<TransparentButton
								tid='generic.form.cancel'
								textColor='midGrey'
								onClick={this.doGoBackToManageCollection}
							/>
						</FooterRightControls>
					)
				}
			},
			/* PDF merge overview */
			{
				body: (
					<MergeOverviewAndSaveContainer
						setValidateFunction={this.setValidateNameFunction}
						onGoToMoveFile={this.onGoToMoveFile}
						folderPath={this.getFolderPath()}
					/>
				),
				footer: {
					leftComponent: (
						<FooterPrevious onClick={this.goToDocumentsList} disabled={mergeIsLoading || submitIsLoading} />
					),
					component: (
						<FooterRightControls>
							<TransparentButton
								tid='collections.merge_pdf.modal.overview.footer.submit'
								onClick={this.submit}
								isLoading={mergeIsLoading || submitIsLoading}
							/>
							<TransparentButton
								tid='generic.form.cancel'
								textColor='midGrey'
								onClick={this.doAbort}
								disabled={mergeIsLoading || submitIsLoading}
							/>
						</FooterRightControls>
					)
				}
			},
			/* Move to folder */
			{
				body: <FoldersContainer selectedFolder={selectedFolder} onFolderSelected={this.handleFolderChange} />,
				footer: {
					leftComponent: <FooterPrevious onClick={this.goToMergeOverview} />
				}
			}
		]
	}

	getModalTitle = () => {
		switch (this.state.step) {
			case 1:
				return 'collections.merge_pdf.modal.documents.title'
			default:
				return 'collections.merge_pdf.modal.documents.title'
		}
	}

	render = () => {
		const { step } = this.state

		return (
			<StepsModal
				isOpen={true}
				steps={this.getSteps()}
				step={step}
				transformless={step === 0}
				hSize='xl'
				title={this.getModalTitle()}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		collection: store.modals.getIn(['activeModal', 'options', 'collection']),
		collectionDocuments: store.collections.get('selectedCollectionDocuments'),
		mergedDocument: store.collections.get('mergedDocument'),
		companyUrlAlias: store.company.company.urlAlias,
		sharedCollection: store.sharedCollections.get('selectedCollection'),
		folders: store.folders.get('folders')
	}
}

const mapActionsToProps = {
	closeModal,
	selectCollection,
	mergePdfsInCollection,
	saveMergedDocument,
	transferDocument,
	saveSharedCollection,
	deleteSharedCollection,
	deleteMergedDocument,
	updateSharedCollectionLocal,
	updateMergedDocumentLocal,
	addDocumentToList,
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(MergePdfModalContainer)
