import { fromJS, Map } from 'immutable'
import req from '../modules/request.module'
import { LIVE_REQUEST, LIVE_REQUEST_RESET } from './types'

export function setLiveRequest(section, state) {
	return { type: LIVE_REQUEST, payload: fromJS({ section, state }) }
}

export function resetLiveRequest(section) {
	return { type: LIVE_REQUEST_RESET, payload: fromJS({ section }) }
}
