import { node } from 'prop-types'
import React, { useState } from 'react'
import { SubscriptionModalAdminContext } from './subscription-modal-admin.context'

function SubscriptionModalAdminProvider({ children }) {
	const [isSubmitting, setIsSubmitting] = useState(false)

	const value = {
		isSubmitting,
		setIsSubmitting
	}

	return <SubscriptionModalAdminContext.Provider value={value}>{children}</SubscriptionModalAdminContext.Provider>
}

SubscriptionModalAdminProvider.propTypes = {
	children: node
}

SubscriptionModalAdminProvider.defaultProps = {}

export { SubscriptionModalAdminProvider }
