import { css } from 'styled-components'
import { SPACING } from '../../../../theme'

export const StyledWrapperCss = css`
	display: flex;
	height: 60px;
	width: 100%;
	align-items: center;
	background: none;
	transition: background 0.25s;
	padding-left: ${(props) => props.theme.spacing[4]};
	padding-right: ${SPACING[4] + SPACING['SCROLL_WIDTH']}px;
	cursor: pointer;
`
