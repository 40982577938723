import React, { PureComponent } from 'react'
import { string, bool } from 'prop-types'
import styled from 'styled-components'

const StyledDot = styled.div`
	display: flex;
	align-self: center;
	background: ${(props) => props.theme.colors[props.bgColor]};
	width: ${(props) => props.size};
	min-width: ${(props) => props.size};
	min-height: ${(props) => props.size};
	height: ${(props) => props.size};
	border-radius: 50%;
`

export default class Dot extends PureComponent {
	static propTypes = {
		bgColor: string,
		size: string,
		className: string
	}

	static defaultProps = {
		bgColor: 'java',
		size: '10px'
	}

	render = () => {
		const { className, bgColor, size } = this.props

		return <StyledDot className={className} bgColor={bgColor} size={size} />
	}
}
