export function calculateMaxWidthOfSplitView(containerRefs) {
	const leftWidth = containerRefs['left'] && containerRefs['left'].getBoundingClientRect().width
	const rightWidth = containerRefs['right'] && containerRefs['right'].getBoundingClientRect().width
	const maxWidth = rightWidth + leftWidth / 2

	return maxWidth
}

export function toggleVisibilityOfTabs(tabsRef, hiddenTabs, { maxWidth, calculateMaxWidth }) {
	if (!maxWidth && calculateMaxWidth) {
		maxWidth = calculateMaxWidth()
	}

	if (!maxWidth) {
		return
	}

	const menuItems = (tabsRef.current || tabsRef)?.querySelectorAll('li')

	menuItems &&
		menuItems.forEach((menuItem) => {
			const rect = menuItem.getBoundingClientRect()

			if (!menuItem.dataset.offset) {
				menuItem.dataset.offset = rect.x + rect.width
			}
		})

	menuItems &&
		menuItems.forEach((menuItem) => {
			const path = menuItem.dataset.path
			const isHidden = hiddenTabs[path]
			if (!isHidden && menuItem.dataset.offset > maxWidth) {
				hiddenTabs[path] = { offset: menuItem.dataset.offset }
			} else if (isHidden && menuItem.dataset.offset < maxWidth) {
				delete hiddenTabs[path]
			}
		})

	return hiddenTabs
}
