import req from '../modules/request.module'

export default {
	/**
	 *
	 * @param {object} feature
	 * @returns {object}
	 */
	async createFeature(feature) {
		try {
			const response = await req.post('/wiki/feature', { feature })
			return response.data
		} catch (error) {
			throw error
		}
	},

	/**
	 *
	 * @param {uuid} id
	 * @throws {Error}
	 * @returns {object}
	 */
	async getFeature(id) {
		try {
			if (!id) {
				throw new Error('feature.errors.missing_id')
			}

			const response = await req.get(`/wiki/feature/${id}`)
			return response.data
		} catch (error) {
			throw error
		}
	},

	/**
	 *
	 * @param {object} feature
	 * @throws {Error}
	 * @returns {object}
	 */
	async editFeature(feature) {
		try {
			if (!feature.id) {
				throw new Error('feature.errors.missing_id')
			}

			const response = await req.put(`/wiki/feature/${feature.id}`, { feature })
			return response.data
		} catch (error) {
			throw error
		}
	},

	/**
	 *
	 * @param {uuid} id
	 * @throws {Error}
	 */
	async deleteFeature(id) {
		try {
			if (!id) {
				throw new Error('feature.errors.missing_id')
			}

			await req.delete(`/wiki/feature/${id}`)
		} catch (error) {
			throw error
		}
	},

	async listFeaturesByPosition(position) {
		try {
			if (!position) {
				throw new Error('feature.errors.missing_position')
			}

			const response = await req.get(`/wiki/feature/position/${position}`)
			return response.data
		} catch (error) {
			throw error
		}
	},

	async listFeatures() {
		try {
			const response = await req.get('/wiki/feature')
			return response.data
		} catch (error) {
			throw error
		}
	},

	async setFeaturePosition(id, position) {
		try {
			const response = await req.put(`/wiki/feature/${id}/position/${position}`)
			return response.data
		} catch (error) {
			throw error
		}
	},

	async unsetFeaturePosition(id, position) {
		try {
			const response = await req.delete(`/wiki/feature/${id}/position/${position}`)
			return response.data
		} catch (error) {
			throw error
		}
	}
}
