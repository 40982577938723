import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { array, func } from 'prop-types'

import Text from '../text/text'
import Icon from '../icon/icon'
import Panel from '../panel/panel'

const TabsWrapper = styled.div`
	display: flex;
	flex: 1;
	height: 49px;
	align-items: center;
`

const TabWrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	height: 100%;
	transition: background-color 0.2s ease-in;
	background: ${(props) => (!props.active ? props.theme.colors.lightestGrey : props.theme.colors.solitudeMid)};
	cursor: ${(props) => (props.active ? 'default' : 'pointer')};
	border: 1px solid transparent;
	border-bottom: none;

	:hover {
		background-color: ${(props) => (props.active ? 'none' : props.theme.colors.lightestGrey)};
	}

	/* Icon Spacing */
	${(props) =>
		props.checked &&
		css`
			span:last-child {
				margin-left: ${(props) => props.theme.spacing[3]};
			}
		`}

	/* Active tab should not have border */
	${(props) =>
		!props.active &&
		css`
			border-top-color: ${(props) => props.theme.colors.border};
			border-right-color: ${(props) => props.theme.colors.border};

			:first-child {
				border-left-color: ${(props) => props.theme.colors.border};
			}
		`}

	${(props) =>
		props.active &&
		css`
			border-right-color: ${(props) => props.theme.colors.border};

			:last-child {
				border-right-color: transparent;
			}
		`}
`

export default class TabsPanel extends PureComponent {
	static propTypes = {
		tabs: array,
		onClickTab: func
	}

	state = {
		activeTabIndex: 0
	}

	onClickTab = (activeTabIndex) => {
		const { onClickTab } = this.props

		this.setState({ activeTabIndex })
		onClickTab && onClickTab(activeTabIndex)
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { tabs } = this.props
		tabs &&
			tabs.forEach((tab, index) => {
				if (!prevProps.tabs[index].tab.initialSelect && tabs[index].tab.initialSelect) {
					this.setState({ activeTabIndex: index })
				} else if (tabs[index].tab.active === true && prevState.activeTabIndex !== index) {
					this.setState({ activeTabIndex: index })
				}
			})
	}

	renderTabContent = (item, active) => {
		const { rawRenderer, tid, checked } = item.tab

		if (rawRenderer) {
			return rawRenderer(item, active)
		}

		return (
			<>
				<Text singleLine={true} tid={tid} color={active ? 'catalinaBlue' : 'midGrey'} bold={600} />

				{checked && <Icon icon='faCheck' color='green' size='sm' />}
			</>
		)
	}

	renderTab = (item, index) => {
		const { activeTabIndex } = this.state
		const active = index === activeTabIndex
		const { checked } = item.tab

		return (
			<TabWrapper key={index} onClick={this.onClickTab.bind(null, index)} active={active} checked={checked}>
				{this.renderTabContent(item, active)}
			</TabWrapper>
		)
	}

	renderTabs = () => {
		const { tabs } = this.props

		return <TabsWrapper>{tabs.map(this.renderTab)}</TabsWrapper>
	}

	render = () => {
		const { tabs } = this.props
		const { activeTabIndex } = this.state

		return (
			<Panel leftComponent={this.renderTabs} noHeaderPadding={true} marginBottom={true}>
				{tabs[activeTabIndex].body}
			</Panel>
		)
	}
}
