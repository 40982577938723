/**
 * Wiki Class
 * @module components/framework/wiki
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { fetchWiki } from '../../actions/wiki.action'
import history from '../../interfaces/history'
import { showWikiModal } from '../../actions/modals.actions'

/** Class representing the wiki component */
class Wiki extends Component {
	/**
	 * Constructor
	 */
	constructor(props) {
		super(props)

		this.linkWasClicked = this.linkWasClicked.bind(this)
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		// Only fetch content if it's not present in the state.
		if (!this.props.wiki.wikiText['wiki-' + this.props.id]) {
			this.props.onFetchWiki(this.props.id, this.props.lang)
		}

		this.wikiDom.addEventListener('click', this.linkWasClicked)
	}

	/**
	 * componentWillUnmount
	 */
	componentWillUnmount() {
		this.wikiDom.removeEventListener('click', this.linkWasClicked)
	}

	componentDidUpdate(prevProps) {
		// Only fetch content if new props differs from props.
		if (this.props.id !== prevProps.id) {
			this.props.onFetchWiki(this.props.id, this.props.lang)
			this.scrollToTop()
		}
	}

	/**
	 * A link was clicked inside the wiki
	 * @param {Object} event — The click event.
	 */
	linkWasClicked(event) {
		const target = event.target

		if (target.tagName.toLowerCase() === 'a' && target.getAttribute('href').indexOf('http') === -1) {
			event.preventDefault()
			this.props.history.push('/' + target.getAttribute('href'))
		}
	}

	/**
	 * Scrolls the wiki (of the wiki parent scrolling container) to top.
	 */
	scrollToTop() {
		let scrollParent = this.wikiDom
		const scrollParentClass = 'scroll-pane'

		while ((scrollParent = scrollParent.parentElement) && !scrollParent.classList.contains(scrollParentClass));

		if (scrollParent !== null) {
			scrollParent.scrollTop = 0
		}
	}

	/**
	 * Render the edit button or not based on prop check.
	 * @param {String} text — the text to supply the onClick function with
	 */
	renderEditButton(text) {
		if (this.props.user) {
			const invonoAdmin = this.props.user.invonoAdmin || false

			if (!invonoAdmin) {
				return null
			} else {
				return (
					<div>
						<button onClick={() => this.props.onShowWikiModal(this.props.id, this.props.lang, text)} className='btn btn-default'>
							<FormattedMessage id='generic.form.edit' />
						</button>
					</div>
				)
			}
		}
	}

	/**
	 * Render
	 */
	render() {
		const text = this.props.wiki.wikiText['wiki-' + this.props.id]
		const className = this.props.className ? this.props.className : ''
		return (
			<div
				className={`wiki text ${className}`}
				ref={(wikiDom) => {
					this.wikiDom = wikiDom
				}}>
				<div>
					<div dangerouslySetInnerHTML={{ __html: text }} />
					{this.renderEditButton(text)}
				</div>
			</div>
		)
	}
}

/** Map state to props. */
function mapStateToProps(state) {
	return {
		history: history,
		lang: state.i18n.language,
		wiki: state.wiki,
		user: state.user.toJS().userObj
	}
}

function mapDispatchToProps(dispatch) {
	return {
		onFetchWiki: (id, lang) => dispatch(fetchWiki(id, lang)),
		onShowWikiModal: (id, lang, text) => dispatch(showWikiModal(id, lang, text)),
	}
}

/**
 * Export
 */
Wiki = connect(mapStateToProps, mapDispatchToProps)(Wiki)
export default Wiki
