import {
	SUBSCRIPTION_SLIP_LEAVE_LANDINGPAGE,
	SUBSCRIPTION_SLIP_SET_DEPOSITORY_OPTIONS,
	SUBSCRIPTION_SLIP_SET_DISPLAY_DATA,
	SUBSCRIPTION_SLIP_SET_INSURANCE_OPTIONS,
	SUBSCRIPTION_SLIP_SET_SUBMISSION,
	SUBSCRIPTION_SLIP_ERROR_LANDINGPAGE
} from './types'
import subscriptionSlip from '../services/subscriptionSlip'
import { addErrorNotification } from '../../actions/notify.actions'

export const getAllSubscriptionSlipData = (companyId, emissionId, inviteeId, currentLang) => async (dispatch) => {
	const data = await Promise.all([
		dispatch(getSubscriptionSlipDisplayData(companyId, emissionId, inviteeId, currentLang)),
		dispatch(getSubmission(companyId, emissionId, inviteeId)),
		dispatch(getInsuranceOptions(companyId, emissionId, inviteeId)),
		dispatch(getDepositoryOptions(companyId, emissionId, inviteeId))
	])

	return { displayData: data[0], submission: data[1], insuranceOptions: data[2], depositoryOptions: data[3] }
}

export const getSubscriptionSlipDisplayData = (companyId, emissionId, inviteeId, currentLang) => async (dispatch) => {
	try {
		const displayData = await subscriptionSlip.getSubscriptionSlipDisplayData(
			companyId,
			emissionId,
			inviteeId,
			currentLang
		)
		dispatch({
			type: SUBSCRIPTION_SLIP_SET_DISPLAY_DATA,
			payload: { displayData }
		})
		return displayData
	} catch (e) {
		dispatch(addErrorNotification({ text: e.message }))
		throw e
	}
}

export const getInsuranceOptions = (companyId, emissionId, inviteeId) => async (dispatch) => {
	try {
		const insuranceOptions = await subscriptionSlip.getInsuranceOptions(companyId, emissionId, inviteeId)
		dispatch({
			type: SUBSCRIPTION_SLIP_SET_INSURANCE_OPTIONS,
			payload: { insuranceOptions }
		})
		return insuranceOptions
	} catch (e) {
		dispatch(addErrorNotification({ text: e.message }))
	}
}

export const getDepositoryOptions = (companyId, emissionId, inviteeId) => async (dispatch) => {
	try {
		const depositoryOptions = await subscriptionSlip.getDepositoryOptions(companyId, emissionId, inviteeId)
		dispatch({
			type: SUBSCRIPTION_SLIP_SET_DEPOSITORY_OPTIONS,
			payload: { depositoryOptions }
		})
		return depositoryOptions
	} catch (e) {
		dispatch(addErrorNotification({ text: e.message }))
	}
}

export const getSubmission = (companyId, emissionId, inviteeId) => async (dispatch) => {
	try {
		const submission = await subscriptionSlip.getSubmission(companyId, emissionId, inviteeId)
		dispatch({
			type: SUBSCRIPTION_SLIP_SET_SUBMISSION,
			payload: { submission }
		})
		return submission
	} catch (e) {
		dispatch(addErrorNotification({ text: e.message }))
	}
}

export const leaveLandingPage = () => async (dispatch) => {
	dispatch({
		type: SUBSCRIPTION_SLIP_LEAVE_LANDINGPAGE,
		payload: { isLandingPage: true }
	})
}

export const errorPage = () => async (dispatch) => {
	dispatch({
		type: SUBSCRIPTION_SLIP_ERROR_LANDINGPAGE,
		payload: { hasError: true }
	})
}

export const updateSubmission = (companyId, emissionId, inviteeId, submission) => async (dispatch, getState) => {
	await subscriptionSlip.updateSubmission(companyId, emissionId, inviteeId, submission, getState().i18n.language)
}
