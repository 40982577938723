import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromJS, List, Map } from 'immutable'
import { map, list } from 'react-immutable-proptypes' /* eslint-disable-line */
import { object, string, bool, func, oneOf } from 'prop-types' /* eslint-disable-line */
import { listInvestors } from '../../actions/investors.actions'

import { Select } from '../../dumb-components/shared/select'

import { formatSsnPretty } from '../../components/helpers/users'

class InvestmentsSelectContainer extends Component {
	state = {
		activeInvestments: List(),
		allInvestments: List()
	}

	static propTypes = {
		visibilityMode: oneOf(['ALL_INVESTMENTS', 'ACTIVE_INVESTMENTS']),
		includeOwnCompany: bool,
		onChange: func,
		appendCreateInvestmentOption: bool,
		tmpTransaction: object
	}

	static defaultProps = {
		visibilityMode: 'ALL_INVESTMENTS',
		includeOwnCompany: false
	}

	componentDidMount = () => {
		const { investments, listInvestments } = this.props

		if (!investments || investments.size === 0) {
			listInvestments()
		}
	}

	componentDidUpdate = (prevProps) => {
		const { investments } = this.props
		if (investments !== prevProps.investments) {
			this.parseInvestments()
		}
	}

	parseInvestments = () => {
		const { investments, companyId, includeOwnCompany } = this.props

		if (!investments) {
			return
		}

		const activeInvestments = []
		const allInvestments = []

		investments.forEach((inv) => {
			const numOfTotalShares = inv.getIn(['details', 'numOfTotalShares'])
			const investorId = inv.get('investorId')

			if (numOfTotalShares && numOfTotalShares !== 0) {
				activeInvestments.push(inv)
			}

			// TO - Exclude my own company
			if (includeOwnCompany && investorId !== companyId) {
				return
			}

			allInvestments.push(inv)
		})

		this.setState({ activeInvestments: fromJS(activeInvestments), allInvestments: fromJS(allInvestments) })
	}

	getOptions = () => {
		const { visibilityMode, investmentIdFrom, investmentIdTo, i18n, appendCreateInvestmentOption } = this.props
		const { allInvestments, activeInvestments } = this.state
		let options = List()

		if (visibilityMode === 'ALL_INVESTMENTS') {
			options = allInvestments.map((inv) => {
				return Map({
					value: inv.get('id'),
					label: inv.getIn(['investorInformation', 'name'])
				})
			})
			if (investmentIdFrom) {
				options = options.filter((item) => {
					return item.get('value') !== investmentIdFrom
				})
			}
		} else if (visibilityMode === 'ACTIVE_INVESTMENTS') {
			options = activeInvestments.map((inv) => {
				return Map({
					value: inv.get('id'),
					label: inv.getIn(['investorInformation', 'name'])
				})
			})
			if (investmentIdTo) {
				options = options.filter((item) => {
					return item.get('value') !== investmentIdTo
				})
			}
		}

		if (appendCreateInvestmentOption) {
			options = options.push(
				Map({
					value: '__createNewShareholder',
					label: i18n.messages['add_new_shareholder']
				})
			)
		}

		return options
	}

	formatOptionLabel = (option) => {
		const { allInvestments } = this.state

		if (option.value === '__createNewShareholder' && option.label) {
			return (
				<div className='Select-value'>
					<span className='Select-value-label'>{option.label}</span>
				</div>
			)
		}

		const investment = allInvestments.find((i) => i.get('id') === option.value)

		const name = investment.getIn(['investorInformation', 'name'])
		let id = investment.getIn(['investorInformation', 'id'])

		if (investment.get('investorTypeOfOwner') === 'capitalInsurance') {
			id = investment.getIn(['investorInformation', 'insuranceNr'])
			return (
				<div className='Select-value'>
					<span className='Select-value-label'>
						{name} <span className='text--muted'>{id}</span>
					</span>
				</div>
			)
		} else {
			return (
				<div className='Select-value'>
					<span className='Select-value-label'>
						{name} <span className='text--muted'>{id}</span>
					</span>
				</div>
			)
		}
	}

	renderField = (inv) => {
		const name = inv && inv.investorInformation && inv.investorInformation.name
		const id = inv && inv.investorInformation && inv.investorInformation.id

		if (inv.value === '__createNewShareholder' && inv.label) {
			return (
				<div className='Select-value'>
					<span className='Select-value-label'>{inv.label}</span>
				</div>
			)
		}

		if (inv.investorTypeOfOwner === 'capitalInsurance') {
			const id = inv && inv.captialIncuranceOwnerInformation && inv.captialIncuranceOwnerInformation.name
			return (
				<div className='Select-value'>
					<span className='Select-value-label'>
						{name} <span className='text--muted'>{id}</span>
					</span>
				</div>
			)
		} else {
			return (
				<div className='Select-value'>
					<span className='Select-value-label'>
						{name} <span className='text--muted'>{id}</span>
					</span>
				</div>
			)
		}
	}

	render = () => {
		const { selectedInvestmentId, onChange } = this.props

		const options = this.getOptions()

		return (
			<Select
				value={selectedInvestmentId}
				options={options}
				onChange={onChange}
				formatOptionLabel={this.formatOptionLabel}
			/>
		)
	}
}

function mapStoreToProps(store) {
	return {
		investments: store.investors.get('list'),
		companyId: store.company.company && store.company.company.id,
		i18n: store.i18n
	}
}

const mapActionsToProps = {
	listInvestments: listInvestors
}

export default connect(mapStoreToProps, mapActionsToProps)(InvestmentsSelectContainer)
