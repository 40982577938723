import React from 'react'
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../dumb-components/shared/button-v2'
import { usePeopleModalContext } from './people-modal.context'
import { func, string } from 'prop-types'
import { TAB_MY_PERSONAL_CONTACTS } from '../select-user-modal/select-user-modal.constants'
import { useSelector } from 'react-redux'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'

export const PeopleModalFooterButtons = ({ onSave, onCancel, confirmButtonTid, cancelButtonTid }) => {
	const {
		isLoading,
		genericOnSave,
		activeTab,
		selectedPersonalContacts,
		canCopyToMultipleCompanyAddressbooks,
		copyPeopleToCompanyContacts,
		genericOnCancel,
		selectedPeople
	} = usePeopleModalContext()
	const isViewingPersonalContacts = activeTab === TAB_MY_PERSONAL_CONTACTS
	const copyToCompanyContactsButtonDisabled = selectedPersonalContacts.length < 1
	const prevAndCurrentCompanies = useSelector((state) => state.company.companies).toJS()
	const numOfCurrentCompanies = prevAndCurrentCompanies.filter((company) => company.isCanceled === false).length
	const companyName = useSelector((state) => state.company.company?.name)

	let copyButtonTid = ''
	if (canCopyToMultipleCompanyAddressbooks) {
		copyButtonTid =
			numOfCurrentCompanies === 1
				? 'people_modal.grid.more_actions.save_contact_to_my_company_contacts'
				: 'people_modal.grid.more_actions.save_contact_to_company_contacts'
	} else {
		copyButtonTid = 'people_modal.grid.more_actions.save_contact_to_my_company_contacts'
	}
	return (
		<>
			{isViewingPersonalContacts ? (
				<Tooltip
					tid='people_modal.tooltip.no_contacts_selected'
					delayShow='instant'
					active={copyToCompanyContactsButtonDisabled}>
					<TransparentButton
						tid={copyButtonTid}
						values={{ companyName }}
						onClick={() => copyPeopleToCompanyContacts(selectedPersonalContacts)}
						isLoading={isLoading}
						disabled={copyToCompanyContactsButtonDisabled}
					/>
				</Tooltip>
			) : (
				<Tooltip
					tid='people_modal.tooltip.no_people_selected'
					delayShow='instant'
					active={selectedPeople.size < 1}>
					<TransparentButton
						tid={confirmButtonTid ?? 'generic.form.save'}
						onClick={() => genericOnSave(onSave)}
						isLoading={isLoading}
						disabled={selectedPeople.size < 1}
					/>
				</Tooltip>
			)}
			<TransparentButton
				tid={cancelButtonTid ?? 'generic.form.cancel'}
				textColor='midGrey'
				onClick={() => genericOnCancel(onCancel)}
				disabled={isLoading}
			/>
		</>
	)
}

function PeopleModalFooter({ onSave, onCancel, confirmButtonTid, cancelButtonTid }) {
	return (
		<FooterRightControls>
			<PeopleModalFooterButtons
				onSave={onSave}
				onCancel={onCancel}
				confirmButtonTid={confirmButtonTid}
				cancelButtonTid={cancelButtonTid}
			/>
		</FooterRightControls>
	)
}

PeopleModalFooter.propTypes = {
	confirmButtonTid: string,
	cancelButtonTid: string,
	onSave: func.isRequired,
	onCancel: func.isRequired
}

export { PeopleModalFooter }
