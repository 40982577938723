import React, { Component } from 'react'
import styled from 'styled-components'

import Text from '../../shared/text/text'

const StyledHeadwayWrapper = styled.div`
	align-items: center;
	display: flex;
	height: 40px;
	transition: background 0.4s ease;
	padding-left: ${(props) => props.theme.spacing[4]};
	max-width: 220px;
	cursor: default;

	:hover {
		background: #f2f2f2;
	}

	#HW_badge_cont {
		cursor: default;
		width: 36px;
		height: 40px;
	}

	#HW_badge {
		cursor: default;
		top: 13px;
		left: 10px;
	}
`

export default class HeadwayApp extends Component {
	componentDidMount = () => {
		this.initHeadway()
	}

	componentDidUpdate = () => {
		this.initHeadway()
	}

	btnRef = null

	initHeadway = () => {
		if (window.Headway) {
			window.Headway.init({
				selector: '.headway-app',
				account: 'yEoMO7',
				trigger: '.headway-app',
				callbacks: {
					onShowWidget: this.onShowWidget,
					onHideWidget: this.onHideWidget,
					onShowDetails: this.onShowDetails
				}
			})
		}
	}

	onShowWidget = () => {
		document.addEventListener('mouseover', this.onMouseOver, false)
	}

	onHideWidget = () => {
		document.removeEventListener('mouseover', this.onMouseOver, false)
	}

	onShowDetails = () => {
		document.removeEventListener('mouseover', this.onMouseOver, false)
	}

	onMouseOver = (e) => {
		const eTarget = e.target
		const { iframeCont } = window.Headway

		if (!this.btnRef.contains(eTarget) && !iframeCont.contains(eTarget)) {
			window.Headway.hide()
		}
	}

	onMouseEnter = () => {
		if (window.Headway) {
			window.Headway.show()
			document.addEventListener('mouseover', this.onMouseOver, false)
		}
	}

	setBtnRef = (ref) => {
		this.btnRef = ref
	}

	render = () => {
		return (
			<StyledHeadwayWrapper className='headway-app' ref={this.setBtnRef} onMouseEnter={this.onMouseEnter}>
				<Text tid='navbar.headway.title.whats_new' />
			</StyledHeadwayWrapper>
		)
	}
}
