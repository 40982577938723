import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, bool } from 'prop-types'
import { List, Map, fromJS } from 'immutable'
import WebFont from 'webfontloader'
import {
	fetchProtocolPdfOption,
	updateProtocolPdfOptionLocal,
	saveProtocolPdfOption,
	listProtocolPdfOptions,
	setDefaultProtocolPdfOption,
	listGoogleFonts,
	clearProtocolPdfOption,
	previewProtocolPdfOption
} from '../../../actions/protocol-pdf-options.actions'
import Button from '../../../dumb-components/shared/button/button'
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../dumb-components/shared/button-v2'
import Text from '../../../dumb-components/shared/text/text'
import { Margin } from 'styled-components-spacing'
import Icon from '../../../dumb-components/shared/icon/icon'
import UniversalModal from '../../../dumb-components/shared/modal/universal-modal'
import { PROTOCOL_PDF_OPTIONS_MODAL } from '../../../constants/modals'
import ProtocolPdfOptionsList from '../../../dumb-components/meetings/protocol-pdf-options/protocol-pdf-options-list'
import ProtocolPdfOptionDetails from '../../../dumb-components/meetings/protocol-pdf-options/protocol-pdf-option-details'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import DropdownMenuItem from '../../../dumb-components/shared/dropdown-item/dropdown-item'

import {
	PDF_LOGO_POSITION_LEFT,
	PDF_LOGO_SIZE_100,
	PDF_LOGO_DISPLAY_OPTION_SHOW,
	PDF_LOGO_DISPLAY_OPTION_HIDE
} from '/shared/constants'

class ProtocolPdfOptionsModal extends Component {
	static propTypes = {
		onSave: func,
		onClose: func
	}

	state = {
		viewMode: 'list', // or 'details'
		fonts: null,
		fontsOptions: List(),
		fontWeightVariants: null,
		fontSizes: List(),
		currentProtocolPdfOption: null
	}

	dropdownMenuRefs = {}

	mapWeightNumberToReadableText = Map({
		100: 'meetings.protocol_pdf_options.font_weight.thin',
		200: 'meetings.protocol_pdf_options.font_weight.extra_light',
		300: 'meetings.protocol_pdf_options.font_weight.light',
		400: 'meetings.protocol_pdf_options.font_weight.normal',
		regular: 'meetings.protocol_pdf_options.font_weight.normal',
		500: 'meetings.protocol_pdf_options.font_weight.medium',
		600: 'meetings.protocol_pdf_options.font_weight.semi_bold',
		700: 'meetings.protocol_pdf_options.font_weight.bold',
		800: 'meetings.protocol_pdf_options.font_weight.extra_bold',
		900: 'meetings.protocol_pdf_options.font_weight.black',
		950: 'meetings.protocol_pdf_options.font_weight.extra_black'
	})

	litStyleTypeOptions = List([
		Map({
			value: 'none',
			label: 'meetings.protocol_pdf_options.list_style_type.none'
		}),
		Map({
			value: 'circle',
			label: 'meetings.protocol_pdf_options.list_style_type.circle'
		}),
		Map({
			value: 'disc',
			label: 'meetings.protocol_pdf_options.list_style_type.disc'
		}),
		Map({
			value: 'square',
			label: 'meetings.protocol_pdf_options.list_style_type.square'
		}),
		Map({
			value: 'decimal',
			label: 'meetings.protocol_pdf_options.list_style_type.decimal'
		}),
		Map({
			value: 'decimal-leading-zero',
			label: 'meetings.protocol_pdf_options.list_style_type.decimal-leading-zero'
		}),
		Map({
			value: 'lower-roamn',
			label: 'meetings.protocol_pdf_options.list_style_type.lower-roamn'
		}),
		Map({
			value: 'upper-roman',
			label: 'meetings.protocol_pdf_options.list_style_type.upper-roman'
		}),
		Map({
			value: 'lower-greek',
			label: 'meetings.protocol_pdf_options.list_style_type.lower-greek'
		}),
		Map({
			value: 'lower-latin',
			label: 'meetings.protocol_pdf_options.list_style_type.lower-latin'
		}),
		Map({
			value: 'upper-latin',
			label: 'meetings.protocol_pdf_options.list_style_type.upper-latin'
		}),
		Map({
			value: 'armenian',
			label: 'meetings.protocol_pdf_options.list_style_type.armenian'
		}),
		Map({
			value: 'georgian',
			label: 'meetings.protocol_pdf_options.list_style_type.georgian'
		})
	])

	componentDidUpdate = (prevProps, prevState) => {
		const { isOpen, listProtocolPdfOptions, protocolPdfOption } = this.props
		const { currentProtocolPdfOption } = this.state

		if (isOpen && !prevProps.isOpen) {
			listProtocolPdfOptions()
			this.loadFonts()
			this.loadFontSizes()
		}

		if (protocolPdfOption && !prevState.fontWeightVariants) {
			this.loadFontWeightVariants()
		}

		if (
			currentProtocolPdfOption &&
			currentProtocolPdfOption.get('isGoogleFont') &&
			((prevState.currentProtocolPdfOption &&
				currentProtocolPdfOption.getIn(['settings', 'fontFamily']) !==
					prevState.currentProtocolPdfOption.getIn(['settings', 'fontFamily'])) ||
				!prevState.currentProtocolPdfOption)
		) {
			WebFont.load({
				google: {
					families: [currentProtocolPdfOption.getIn(['settings', 'fontFamily'])]
				}
			})
		}
	}

	loadFonts = () => {
		const { listGoogleFonts, protocolPdfOption } = this.props
		const arialFont = Map({
			variants: List(['regular', 700]),
			isBuiltInFont: true
		})
		const timesNewRomanFont = Map({
			variants: List(['regular', 700]),
			isBuiltInFont: true
		})

		listGoogleFonts((googleFonts) => {
			let fontsOptions = List([
				Map({
					value: 'Arial',
					label: 'Arial'
				}),
				Map({
					value: 'Times New Roman',
					label: 'Times New Roman'
				})
			])
			let fonts = Map()
			fonts = fonts.set('Arial', arialFont)
			fonts = fonts.set('Times New Roman', timesNewRomanFont)

			googleFonts.forEach((font) => {
				fontsOptions = fontsOptions.push(
					Map({
						value: font.get('family'),
						label: font.get('family')
					})
				)

				fonts = fonts.set(font.get('family'), font)
			})

			this.setState({ fonts, fontsOptions })
		})
	}

	loadFontWeightVariants = () => {
		const { protocolPdfOption, i18n } = this.props
		const { fonts } = this.state
		let fontWeightVariants = Map()

		if (protocolPdfOption && fonts) {
			const fontFamily = protocolPdfOption.getIn(['settings', 'fontFamily'])
			const font = fonts.get(fontFamily)

			if (!font) {
				return
			}

			font.has('variants') &&
				font.get('variants').forEach((variant) => {
					const fontWeight = parseInt(variant)
					if (fontWeight || variant === 'regular') {
						const value = fontWeight ? fontWeight : 'regular'
						fontWeightVariants = fontWeightVariants.set(
							fontWeight || variant,
							Map({
								value: value,
								label: i18n.messages[this.mapWeightNumberToReadableText.get(value.toString())]
							})
						)
					}
				})
		}

		this.setState({ fontWeightVariants: fontWeightVariants.toList() })
	}

	loadFontSizes = () => {
		const fontSizes = List([10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72]).map((size) => {
			return Map({
				value: size,
				label: `${size}pt`
			})
		})

		this.setState({ fontSizes })
	}

	onSave = () => {
		const { saveProtocolPdfOption } = this.props
		const { currentProtocolPdfOption } = this.state

		saveProtocolPdfOption(currentProtocolPdfOption)
		this.setState({
			viewMode: 'list',
			currentProtocolPdfOption: null
		})
	}

	onCancel = () => {
		const { clearProtocolPdfOption } = this.props
		const { viewMode } = this.state

		if (viewMode === 'details') {
			clearProtocolPdfOption()
			this.setState({
				viewMode: 'list',
				currentProtocolPdfOption: null
			})
			return
		}

		this.onClose()
	}

	onClose = () => {
		const { onClose, clearProtocolPdfOption, protocolPdfOption } = this.props

		protocolPdfOption && clearProtocolPdfOption()
		onClose()
	}

	onChange = (fieldName, val) => {
		let { currentProtocolPdfOption, fonts } = this.state
		const fieldPath = `settings.${fieldName}`.split('.')

		if (fieldPath[1] === 'fontFamily') {
			const font = fonts.get(val)
			currentProtocolPdfOption = currentProtocolPdfOption.set('isGoogleFont', !font.get('isBuiltInFont'))
		}

		currentProtocolPdfOption = currentProtocolPdfOption.setIn(fieldPath, val)
		this.setState({ currentProtocolPdfOption })
	}

	selectProtocolPdfOption = (id) => {
		const { fetchProtocolPdfOption, hasLogo } = this.props

		fetchProtocolPdfOption(id, (protocolPdfOption) => {
			if (hasLogo && !protocolPdfOption.hasIn(['settings', 'logoInProtocol'])) {
				protocolPdfOption = protocolPdfOption.setIn(['settings', 'logoInProtocol'], PDF_LOGO_DISPLAY_OPTION_SHOW)
			}

			if (!protocolPdfOption.hasIn(['settings', 'logoPosition'])) {
				protocolPdfOption = protocolPdfOption.setIn(['settings', 'logoPosition'], PDF_LOGO_POSITION_LEFT)
			}

			if (!protocolPdfOption.hasIn(['settings', 'logoSize'])) {
				protocolPdfOption = protocolPdfOption.setIn(['settings', 'logoSize'], PDF_LOGO_SIZE_100)
			}

			this.setState({
				viewMode: 'details',
				currentProtocolPdfOption: protocolPdfOption
			})
		})
	}

	setDefaultOption = (id) => {
		const { setDefaultProtocolPdfOption } = this.props

		setDefaultProtocolPdfOption(id)
	}

	previewProtocolPdfOption = (id) => {
		const { fetchProtocolPdfOption, previewProtocolPdfOption, company } = this.props

		fetchProtocolPdfOption(id, (protocolPdfOption) => {
			previewProtocolPdfOption(protocolPdfOption, company.id)
		})
	}

	instantPreviewProtocolPdfOption = () => {
		const { previewProtocolPdfOption, company } = this.props
		const { currentProtocolPdfOption } = this.state

		if (!currentProtocolPdfOption) {
			return
		}

		previewProtocolPdfOption(currentProtocolPdfOption, company.id)
	}

	renderMoreActionButton = (id) => {
		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisV'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				tooltipActiveState='btnMoreActions'
				ref={(ref) => (this.dropdownMenuRefs[id] = ref)}
				inline>
				<DropdownMenuItem onClick={() => this.setDefaultOption(id)}>
					<Text tid='meetings.protocol_pdf_options.select_as_default' />
				</DropdownMenuItem>
				<DropdownMenuItem onClick={() => this.previewProtocolPdfOption(id)}>
					<Text tid='generic.preview' />
				</DropdownMenuItem>
				<DropdownMenuItem onClick={() => this.selectProtocolPdfOption(id)}>
					<Text tid='generic.edit' />
				</DropdownMenuItem>
			</DropdownMenuContainer>
		)
	}

	renderProtocolPdfOptionsList = () => {
		const { protocolPdfOptions } = this.props

		return (
			<ProtocolPdfOptionsList
				protocolPdfOptions={protocolPdfOptions}
				onOptionClick={this.selectProtocolPdfOption}
				onSelectOption={this.setDefaultOption}
				renderMoreActionButton={this.renderMoreActionButton}
			/>
		)
	}

	renderProtocolPdfOptionDetails = () => {
		const { company, hasLogo } = this.props
		const { fontsOptions, fontWeightVariants, fontSizes, currentProtocolPdfOption } = this.state

		return (
			<ProtocolPdfOptionDetails
				onChange={this.onChange}
				protocolPdfOption={currentProtocolPdfOption}
				fontsOptions={fontsOptions}
				fontWeightVariants={fontWeightVariants}
				fontSizes={fontSizes}
				litStyleTypeOptions={this.litStyleTypeOptions}
				hasLogo={hasLogo}
			/>
		)
	}

	renderModalButtons = () => {
		const { viewMode } = this.state

		if (viewMode === 'details') {
			return (
				<FooterRightControls>
					<TransparentButton tid='generic.form.save' onClick={this.onSave} />
					<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={this.onCancel} />
				</FooterRightControls>
			)
		}

		return <TransparentButton tid='generic.form.close' textColor='midGrey' onClick={this.onClose} />
	}

	renderHeaderComponent = () => {
		const { viewMode } = this.state

		if (viewMode === 'details') {
			return (
				<Button onClick={this.instantPreviewProtocolPdfOption}>
					<Margin right={3}>
						<Icon icon='faEye' size='xs' />
					</Margin>
					<Text tid='meetings.protocol_pdf_options.preview' />
				</Button>
			)
		}
	}

	render = () => {
		const { isOpen } = this.props
		const { viewMode } = this.state
		const contentRenderer =
			viewMode === 'list' ? this.renderProtocolPdfOptionsList : this.renderProtocolPdfOptionDetails

		return (
			<UniversalModal
				isOpen={isOpen}
				titleTid='meetings.protocol_pdf_options.protocol_pdf_options'
				modalHeaderComponent={this.renderHeaderComponent}
				modalFooterComponent={this.renderModalButtons()}
				renderContent={contentRenderer}
				noBodyMargin={true}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		protocolPdfOptions: store.protocolPdfOptions.get('list'),
		protocolPdfOption: store.protocolPdfOptions.get('protocolPdfOption'),
		isOpen: store.modals.getIn(['activeModal', 'name']) === PROTOCOL_PDF_OPTIONS_MODAL,
		i18n: store.i18n,
		company: store.company.company,
		hasLogo: store.company.company?.image?.main
	}
}

const mapActionsToProps = {
	fetchProtocolPdfOption,
	updateProtocolPdfOptionLocal,
	saveProtocolPdfOption,
	listProtocolPdfOptions,
	setDefaultProtocolPdfOption,
	listGoogleFonts,
	clearProtocolPdfOption,
	previewProtocolPdfOption
}

export default connect(mapStoreToProps, mapActionsToProps)(ProtocolPdfOptionsModal)
