import React, { PureComponent } from 'react'
import { bool, node, func, string, object } from 'prop-types'
import Modal from '../../../shared/modal/modal'

export default class MeetingTemplatesModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		modalFooterComponent: node,
		modalHeaderComponent: func,
		renderContent: func,
		title: string,
		scrollableContent: bool,
		titleValues: object
	}

	static defaultProps = {
		scrollableContent: true
	}

	render = () => {
		const {
			isOpen,
			modalFooterComponent,
			renderContent,
			modalHeaderComponent,
			title,
			titleValues,
			scrollableContent
		} = this.props

		return (
			<Modal
				hSize='xl'
				title={title}
				titleValues={titleValues}
				isOpen={isOpen}
				footerComponent={modalFooterComponent}
				headerRightRenderer={modalHeaderComponent}
				scrollableContent={scrollableContent}>
				{renderContent()}
			</Modal>
		)
	}
}
