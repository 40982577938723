import { List, Map, fromJS } from 'immutable'

export const formatCompanyUsersAndRolesAsOptions = ({ roles, excludeRoles, companyMembers }) => {
	let options = List()

	if (roles) {
		roles = fromJS(roles)
			.filter((role) => !excludeRoles.includes(role.get('id')))
			.map((obj) => {
				return Map({
					value: obj.get('id'),
					label: obj.get('name'),
					type: 'role'
				})
			})

		// Doesn't need to be here but futureproofing
		// As this is the first time options are saved,
		// we might as wlel have just done options = roles
		if (options.size > 0) {
			options = options.concat(roles)
		} else {
			options = roles
		}
	}

	if (companyMembers) {
		companyMembers = companyMembers.map((user) => {
			return Map({
				value: user.get('id'),
				label: user.get('name') || '',
				type: 'user'
			})
		})

		companyMembers.sort((a, b) => {
			return a.get('label', '').localeCompare(b.get('label', ''))
		})

		if (options && options.size > 0) {
			options = options.concat(companyMembers)
		} else {
			options = companyMembers
		}
	}

	return options
}

export const getCompanyAnyLogoUrl = (companyId, companyImage, height) => {
	let mainOrThumbnail

	if (companyImage?.main) {
		mainOrThumbnail = companyImage.main.filename
	} else if (companyImage?.thumbnail) {
		mainOrThumbnail = companyImage.thumbnail.filename
	}

	const logoUrl =
		mainOrThumbnail && `/api/companies/public/images/${mainOrThumbnail}-${height}?companyId=${companyId}`

	return logoUrl
}

/**
 *
 * @param {uuid} companyId
 * @param {string} companyImageFilename
 * @param {number} height
 * @returns {string}
 */
export const getCompanyLogoUrl = (companyId, companyImageFilename, height) => {
	return `/api/companies/public/images/${companyImageFilename}-${height}?companyId=${companyId}`
}
