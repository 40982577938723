import React, { PureComponent } from 'react'
import range from 'lodash/range'
import { func, string, number, oneOfType, bool } from 'prop-types'
import { list } from 'react-immutable-proptypes'
import { Select } from '../select'
import { fromJS } from 'immutable'

class MonthSelect extends PureComponent {
	static propTypes = {
		fieldName: string,
		value: oneOfType([string, number]),
		onChange: func,
		disabled: bool,
		placeholderTid: string,
		hasError: list
	}

	getOptions = () => {
		const months = range(12).map((item) => {
			return {
				value: (item < 9 ? '0' : '') + (item + 1),
				label: (item < 9 ? '0' : '') + (item + 1)
			}
		})

		return months
	}

	render = () => {
		const { value, fieldName, disabled, onChange, hasError, placeholderTid } = this.props
		const months = this.getOptions()

		return (
			<Select
				value={value ? value.toString() : null}
				options={fromJS(months)}
				fieldName={fieldName}
				onChange={onChange}
				isDisabled={disabled}
				hasError={hasError}
				placeholderTid={placeholderTid}
			/>
		)
	}
}

export default MonthSelect
