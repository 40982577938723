import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { string, func, bool, object } from 'prop-types'
import { list } from 'react-immutable-proptypes'
import { Select } from '../select'
import Text from '../text/text'
import Icon from '../icon/icon'
import StyledStatusRectangle from './styled-status-rectangle'

const StyledLabelWrapper = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
`

export default class ColorProgressSelect extends PureComponent {
	static propTypes = {
		options: list,
		value: string,
		fieldName: string,
		onChange: func,
		isDisabled: bool,
		placeholderTid: string,
		isSearchable: bool,
		menuPortalTarget: object
	}

	static defaultProps = {
		isDisabled: false,
		isSearchable: false
	}

	formatOptionLabel = (item) => {
		return (
			<StyledLabelWrapper>
				{item.statusColor && <StyledStatusRectangle color={item.statusColor} />}
				<Text tid={item.label} />
			</StyledLabelWrapper>
		)
	}

	render = () => {
		const {
			options,
			value,
			onChange,
			fieldName,
			isDisabled,
			placeholderTid,
			isSearchable,
			menuPortalTarget
		} = this.props

		return (
			<Select
				formatOptionLabel={this.formatOptionLabel}
				isDisabled={isDisabled}
				options={options}
				fieldName={fieldName}
				onChange={onChange}
				placeholderTid={placeholderTid}
				isSearchable={isSearchable}
				value={value}
				menuPortalTarget={menuPortalTarget}
			/>
		)
	}
}
