import React, { PureComponent } from 'react'
import immutablePropTypes from 'react-immutable-proptypes'
import { string, bool, func } from 'prop-types'
import styled from 'styled-components'
import { List } from 'immutable'
import StyledList from '../../../shared/list/list'
import ListItem from '../../../shared/list-item/list-item'
import LineSeparator from '../../../shared/line-separator/line-separator'
import MeetingTemplateListItem from '../meeting-templates-details/meeting-template-list-item'

const StyledListItem = styled(ListItem)`
	margin-top: ${(props) => props.theme.spacing[2]};
	margin-bottom: 0px;
	margin-left: ${(props) => `${50 * (props.level - 1)}px`};
	width: calc(100% - ${(props) => `${50 * (props.level - 1)}px`});
`

const StyledDurationPlaceholder = styled.div`
	display: inline-block;
	width: 50px;
	height: 100%;
	position: relative;
`

export default class SuggestedTopicSetting extends PureComponent {
	static propTypes = {
		onChange: func,
		name: string,
		seperatorBgColor: string,
		allowSuggestedTopics: bool
	}

	renderDurationPlaceholder = () => {
		return <StyledDurationPlaceholder />
	}

	render = () => {
		const { onChange, name, seperatorBgColor, allowSuggestedTopics } = this.props

		return (
			<>
				<LineSeparator tid='meetings.agenda.list.suggestions' bgColor={seperatorBgColor} margin />
				<StyledList>
					<StyledListItem level={1} hoverColor={'lightestGrey'}>
						<MeetingTemplateListItem
							name={name}
							isDragDisabled={true}
							level={1}
							onChange={onChange}
							fieldName='allowSuggestedTopics'
							active={allowSuggestedTopics}
							toggleSwitchTooltipTid='meetings.templates.modal.toggle_suggested_topics.tooltip'
							forceShowToggleSwitch={true}
							forceRightMargin={true}
							renderDuration={this.renderDurationPlaceholder}
						/>
					</StyledListItem>
				</StyledList>
			</>
		)
	}
}
