import { fromJS, List } from 'immutable'
import {
	COMPANY_CONTACTS_LIST,
	COMPANY_CONTACTS_CREATE,
	COMPANY_CONTACTS_EDIT,
	COMPANY_CONTACTS_DELETE,
	COMPANY_CONTACTS_CREATE_MULTIPLE,
	MEMBERS_AND_INVESTORS_LIST,
	MEMBERS_AND_INVESTORS_FILTER
} from '../actions/types'

const INITIAL_STATE = fromJS({
	companyContacts: [],
	allMembersAndInvestors: [],
	filteredMembersAndInvestors: []
})

export default function (state = INITIAL_STATE, { type, payload }) {
	switch (type) {
		case COMPANY_CONTACTS_LIST:
			return state.set('companyContacts', payload)

		case COMPANY_CONTACTS_CREATE: {
			const index = state.get('companyContacts').findIndex((person) => person.get('id') === payload.get('id'))

			if (index >= 0) {
				state = state.setIn(['companyContacts', index], payload)
			} else {
				state = state.update('companyContacts', (list) => list.insert(0, payload))
			}

			return state
		}

		case COMPANY_CONTACTS_CREATE_MULTIPLE: {
			return state.update('companyContacts', (people) => {
				payload.forEach((person) => {
					const index = people.findIndex((p) => p.get('id') === person.get('id'))

					if (index >= 0) {
						people = people.set(index, person)
					} else {
						people = people.push(person)
					}
				})

				return people
			})
		}

		case COMPANY_CONTACTS_EDIT: {
			const index = state.get('companyContacts').findIndex((person) => person.get('id') === payload.get('id'))
			return state.setIn(['companyContacts', index], payload)
		}

		case COMPANY_CONTACTS_DELETE: {
			const index = state.get('companyContacts').findIndex((person) => person.get('id') === payload)
			return state.setIn(['companyContacts', index, 'isDeleted'], true)
		}

		case MEMBERS_AND_INVESTORS_LIST: {
			state = state.set('allMembersAndInvestors', payload)
			state = state.set('filteredMembersAndInvestors', payload)
			return state
		}

		case MEMBERS_AND_INVESTORS_FILTER: {
			const { filter } = payload

			if (!filter) {
				state = state.set('filteredMembersAndInvestors', state.get('allMembersAndInvestors'))
				return state
			}

			const filteredMembersAndInvestors = state.get('allMembersAndInvestors').filter((person) => {
				if (!person.get('roles')) {
					return false
				}

				return person.get('roles').includes(filter)
			})

			return state.set('filteredMembersAndInvestors', filteredMembersAndInvestors)
		}

		default:
			return state
	}
}
