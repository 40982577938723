import React, { PureComponent } from 'react'
import { number } from 'prop-types'
import styled from 'styled-components'

const Spacer = styled.div`
	display: flex;
	min-height: ${(props) => props.height}px;
`

export default class FakeFooterSpacer extends PureComponent {
	static propTypes = {
		height: number
	}

	render = () => {
		return <Spacer height={this.props.height}>&nbsp;</Spacer>
	}
}
