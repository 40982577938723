import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { fromJS, List } from 'immutable'
import { getFullShareTypeLabel } from '../../../helpers/shares'
import Panel from '../../../../dumb-components/panel'
import TextBox from '../../../framework/textbox'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'

import { NUMBER_FORMAT_INTEGER } from '/shared/constants'

class AdjustmentOfVotesView extends Component {
	componentDidMount = () => {}

	renderShareType = (shareType, index) => {
		const { tmpTransaction } = this.props

		const shareTypeName = getFullShareTypeLabel(shareType.get('type'))

		let newVotesPerShare
		if (tmpTransaction) {
			const changedShareType = tmpTransaction.getIn(['handlerData', 'types'], List()).find((obj) => {
				return obj.get('type') === shareType.get('type')
			})

			if (changedShareType) {
				newVotesPerShare = changedShareType.get('votes')
			}
		}

		return (
			<div className='list__item' key={index}>
				<div className='list__item__body'>
					<span className='list__item__text'>{shareTypeName}</span>
				</div>
				<div className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
					{shareType.get('votesPerShare')}
				</div>
				<div className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
					{tmpTransaction && newVotesPerShare && localeFormatNumber(newVotesPerShare, NUMBER_FORMAT_INTEGER)}
				</div>
			</div>
		)
	}

	render = () => {
		const { transaction } = this.props

		if (!transaction) {
			return null
		}

		const types = transaction.getIn(['shareData', 'types'], List())

		return (
			<Panel padHor className='i-panel--white'>
				<div>
					<div className='list__list-header'>
						<div className='list__list-header__body list__list-header__text-area--pad-lft list__list-header__text-area--pad-right'>
							<FormattedMessage id='shares.transactions.adjustment_of_votes.class_of_shares' />
						</div>
						<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
							<FormattedMessage id='shares.transactions.adjustment_of_votes.before' />
						</div>
						<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
							<FormattedMessage id='shares.transactions.adjustment_of_votes.after' />
						</div>
					</div>
					<div className='list list--table list--striped'>{types.map(this.renderShareType)}</div>
				</div>
					<FormattedHTMLMessage id='shares.transactions.adjustment_of_votes.information' />
			</Panel>
		)
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get('transaction'),
		tmpTransaction: state.transaction.getIn(['tmpTransaction', 'CHANGE-VOTES']),
		i18n: state.i18n
	}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(AdjustmentOfVotesView)
