import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, oneOf } from 'prop-types'
import { Map } from 'immutable'
import { reactivateCompany, fetchCompaniesAccessRights, fetchUserCompanies } from '../../actions/company.actions'
import CompanyList from '../../dumb-components/company/company-list/company-list'
import history from '../../interfaces/history'

import {
	EVENT_TYPE_COMPANY_MEMBER_ADDED,
	EVENT_TYPE_COMPANY_MEMBER_UPDATE,
	EVENT_TYPE_COMPANY_MEMBER_REMOVE
} from '/shared/constants'

class CompanyListContainer extends Component {
	state = {
		rowIsLoading: {}
	}

	static propTypes = {
		showGetStartedButton: bool,
		emptyMode: oneOf(['accounts', 'overview'])
	}

	static defaultProps = {
		showGetStartedButton: false
	}

	componentDidMount = () => {
		const { fetchCompaniesAccessRights } = this.props
		fetchCompaniesAccessRights('me')
	}

	componentDidUpdate = (prevProps) => {
		const { companies, fetchCompaniesAccessRights } = this.props

		if (prevProps.companies !== companies) {
			fetchCompaniesAccessRights('me')
		}

		this.checkLiveUpdateEvents()
	}

	checkLiveUpdateEvents = () => {
		const { audit, fetchUserCompanies, fetchCompaniesAccessRights } = this.props
		const memberAdded = audit.get(EVENT_TYPE_COMPANY_MEMBER_ADDED, Map())
		const memberUpdated = audit.get(EVENT_TYPE_COMPANY_MEMBER_UPDATE, Map())
		const memberRemoved = audit.get(EVENT_TYPE_COMPANY_MEMBER_REMOVE, Map())

		if (
			memberAdded.get('refresh') === true ||
			memberUpdated.get('refresh') === true ||
			memberRemoved.get('refresh') === true
		) {
			fetchUserCompanies('me')
			fetchCompaniesAccessRights('me')
		}
	}

	reactivateCompany = (id, index) => {
		const { reactivateCompany } = this.props

		this.setState((prevState) => {
			const rowIsLoading = prevState.rowIsLoading
			rowIsLoading[index] = true
			return { rowIsLoading }
		})

		reactivateCompany(id, () => {
			this.setState((prevState) => {
				const rowIsLoading = prevState.rowIsLoading
				rowIsLoading[index] = false
				return { rowIsLoading }
			})
		})
	}

	openCompany = (company) => {
		this.props.history.push(`/${company.get('urlAlias')}`)
	}

	render = () => {
		const { rowIsLoading } = this.state
		const { companies, emptyMode, user, companiesAccessRights } = this.props

		return (
			<CompanyList
				companies={companies}
				companiesAccessRights={companiesAccessRights}
				rowIsLoading={rowIsLoading}
				onReactivateCompany={this.reactivateCompany}
				onOpenCompany={this.openCompany}
				emptyMode={emptyMode}
				user={user}
			/>
		)
	}
}

function mapStoreToProps(state) {
	return {
		history: history,
		user: state.user.get('userObj'),
		companies: state.company.companies,
		audit: state.audit.get('company'),
		companiesAccessRights: state.company.companiesAccessRights.map((id) => {
			return id.map((role) => {
				return role.get('name')
			})
		})
	}
}

const mapActionsToProps = {
	reactivateCompany,
	fetchCompaniesAccessRights,
	fetchUserCompanies
}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyListContainer)
