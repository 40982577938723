import React, { PureComponent } from 'react'
import { func, oneOf } from 'prop-types'
import styled from 'styled-components'

import Modal from '../shared/modal/modal'
import { TransparentButton } from '../shared/button-v2'
import ColoredContentWrapper from '../shared/colored-content-wrapper/colored-content-wrapper'
import Text from '../shared/text/text'
import Icon from '../shared/icon/icon'
import Button from '../shared/button/button'
import DropdownMenuContainer from '../../containers/shared/dropdown-menu.container'
import DropdownItem from '../shared/dropdown-item/dropdown-item'

const StyledContentWrapper = styled.div`
	padding: ${(props) => props.theme.spacing[5]} 0;
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
`

const StyledIconWrapper = styled.div`
	padding-bottom: ${(props) => props.theme.spacing[3]};
`

const VERSION_SIGNED = 'SIGNED'
const VERSION_NORMAL = 'NORMAL'

export default class AddSignedOrUnsignedDocumentModal extends PureComponent {
	static propTypes = {
		onAddDocument: func,
		onCancel: func,
		isLoadingButton: oneOf([VERSION_SIGNED, VERSION_NORMAL])
	}

	dropdownRef = null

	setDropdownRef = (ref) => {
		this.dropdownRef = ref
	}

	toggleDropdown = () => {
		this.dropdownRef?.onToggleMenu()
	}

	addSigned = () => {
		const { onAddDocument } = this.props
		this.toggleDropdown()
		onAddDocument(VERSION_SIGNED)
	}

	addNormal = () => {
		const { onAddDocument } = this.props
		this.toggleDropdown()
		onAddDocument(VERSION_NORMAL)
	}

	renderFooter = () => {
		const { isLoadingButton, onCancel } = this.props
		const disabled = Boolean(isLoadingButton)

		return (
			<>
				<DropdownMenuContainer
					renderRaw={<TransparentButton tid='collections.add_document.modal.options' isLoading={disabled} />}
					ref={this.setDropdownRef}
					disabled={disabled}
					withPortal>
					<DropdownItem onClick={this.addNormal} disabled={disabled}>
						<Text tid='collections.add_document.modal.signed.btn.original' />
					</DropdownItem>
					<DropdownItem onClick={this.addSigned} disabled={disabled}>
						<Text tid='collections.add_document.modal.signed.btn.signed' />
					</DropdownItem>
				</DropdownMenuContainer>
				<TransparentButton tid='generic.form.cancel' onClick={onCancel} textColor='midGrey' disabled={disabled} />
			</>
		)
	}

	render = () => {
		return (
			<Modal
				isOpen={true}
				vSize={50}
				hSize='md'
				footerComponent={this.renderFooter()}
				scrollableContent={false}
				title='documents.esign.signed.download.modal.title'>
				<StyledContentWrapper>
					<StyledColoredContentWrapper type='secondary'>
						<StyledIconWrapper>
							<Icon icon='faExclamationTriangle' size='normal' color='yellowMid' type='solid' />
						</StyledIconWrapper>
						<Text tid='collections.add_document.modal.signed.open.info' />
					</StyledColoredContentWrapper>
				</StyledContentWrapper>
			</Modal>
		)
	}
}
