import { fromJS } from 'immutable'
import { LIVE_REQUEST, LIVE_REQUEST_RESET } from '../actions/types'

const INITIAL_STATE = fromJS({
	projects: {},
	documents: {},
	folders: {},
	tasks: {},
	comments: {},
	tags: {},
	company: {},
	investors: {},
	transactions: {},
	meetings: {},
	groups: {},
	meetingTemplates: {},
	subscriptions: {},
	attachments: {}
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case LIVE_REQUEST: {
			const section = payload.get('section')
			const sectionState = payload.get('state')
			return state.setIn(section, sectionState)
		}
		case LIVE_REQUEST_RESET: {
			return state.removeIn(payload.get('section'))
		}
		default:
			return state
	}
}
