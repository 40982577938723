import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { fromJS, List } from 'immutable'
import debounce from 'lodash/debounce'
import ScrollView from '../../../../dumb-components/shared/layout/scroll-view/scroll-view'
import { v1 } from 'uuid'
import {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	cancelTemporaryTransaction
} from '../../../../actions/transaction.actions'
import { addErrorNotification, addInfoNotification } from '../../../../actions/notify.actions'
import DatePicker from '../../../../dumb-components/shared/datepicker/datepicker'
import Panel from '../../../../dumb-components/panel'
import Field from '../../../../dumb-components/fields/field'
import EmissionLeveling from './emission-leveling'
import {
	validateTransactionDate,
	isRequired,
	validateBeforeAndAfterNotEqual,
	validateBeforeAndAfterGtZero
} from '../../../../modules/validation.module'
import immutableForm from '../../../framework/immutable-form'
import TransactionToolbarControlsContainer from '../../../../containers/shares/transaction-toolbar-controls.container'

const transactionType = 'SPLIT'

const initialTransaction = fromJS({
	type: transactionType,
	handlerData: {}
})

const de = debounce((func, type, transaction) => {
	if (!func) {
		return
	}
	func(type, transaction.toJS())
}, 1000)

class SplitForm extends Component {
	componentDidMount() {
		this.props.fetchTemporaryTransaction(transactionType)
	}

	scrollToEmissionLeveling = () => {
		setTimeout(() => {
			const emissionLevelingWrapper = document.getElementById('emission-leveling-wrapper')

			if (!emissionLevelingWrapper) {
				return
			}

			this.scrollbar.scrollTop(emissionLevelingWrapper.offsetTop)
		}, 500)
	}

	onChange = (field, val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction, resetErrors } = this.props
		const newTransaction = tmpTransaction.setIn(['handlerData', field], parseInt(val))

		// Disallow 0 and other symbols that shouldn't be there
		//if(val && val.includes('-') || val.includes('+')) return null;
		//if(val && field && (field == 'before' || field == 'after') && (val < 1)) return null;

		resetErrors('handlerData.' + field)
		updateTransaction(transactionType, newTransaction)
		de(saveTemporaryTransaction, transactionType, newTransaction)
	}

	onChangeDate = (val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction, resetErrors } = this.props
		const newTransaction = tmpTransaction.set('date', val)
		resetErrors('date')
		updateTransaction(transactionType, newTransaction)
		de(saveTemporaryTransaction, transactionType, newTransaction)
	}

	onSubmit = () => {
		const { tmpTransaction, validate, createTransaction } = this.props

		if (validate(tmpTransaction)) {
			de(null)
			createTransaction(
				tmpTransaction.toJS(),
				() => {
					this.cancelTransaction()
				},
				this.scrollToEmissionLeveling
			)
		}
	}

	acceptAdjustment = () => {
		const { tmpTransaction, validate, createTransaction, company, investors } = this.props
		const needCompanyAsInvestment = tmpTransaction.get('needCompanyAsInvestment')
		const companyInvestmentObj = investors.find((obj) => {
			return obj.get('investorId') === company.id
		})

		let newTransaction = tmpTransaction.removeIn(['handlerData', 'shareDiff'])
		newTransaction = newTransaction.remove('needCompanyAsInvestment')
		newTransaction = newTransaction.setIn(['handlerData', 'accepted'], true)

		if (needCompanyAsInvestment) {
			const companyInvestmentId = companyInvestmentObj ? companyInvestmentObj.get('id') : v1()
			const investor = fromJS({
				investorTypeOfOwner: 'company',
				investorInformation: {
					name: company.name,
					id: company.orgNumber
				},
				id: companyInvestmentObj ? companyInvestmentObj.get('id') : null,
				investorId: company.id
			})

			newTransaction = newTransaction.setIn(['handlerData', 'companyInvestmentId'], companyInvestmentId)
			newTransaction = newTransaction.setIn(['investors', companyInvestmentId], investor)
		}

		if (validate(newTransaction)) {
			createTransaction(newTransaction.toJS())
		}
	}

	cancelTransaction = () => {
		this.props.cancelTemporaryTransaction(transactionType)
	}

	renderButtons = () => {
		const { tmpTransaction } = this.props
		const shareDiff = tmpTransaction.getIn(['handlerData', 'shareDiff'])

		return (
			<div className='i-content__tabs i-content__tabs--fix-padding'>
				{!shareDiff ? (
					<TransactionToolbarControlsContainer onSubmit={this.onSubmit} onCancel={this.cancelTransaction} />
				) : (
					<TransactionToolbarControlsContainer onSubmit={this.acceptAdjustment} onCancel={this.cancelTransaction} />
				)}

				{/*!shareDiff && (<button className="btn btn-primary" onClick={this.onSubmit}><span className="fa fa-check fa-lg"></span> <FormattedMessage id="register_transaction"/></button>)}
					{shareDiff && (<button type="button" className="btn btn-primary" onClick={this.acceptAdjustment}><FormattedHTMLMessage id="generic.accept"/></button>)}
					<DropdownButton dropdownPosition="right" dropdownAddClass="fa-ul" iconTitle="fa fa-ellipsis-h" cssClass="btn-more-action btn-default">
					<a href="#" onClick={this.cancelTransaction}><span className="fa fa-li fa-times fa-lg"></span> <FormattedMessage id="cancel_split_reverse_split"/></a>
					</DropdownButton>*/}
			</div>
		)
	}

	render = () => {
		const { transaction, tmpTransaction, errors, i18n } = this.props

		if (!tmpTransaction) {
			return null
		}

		const shareDiff = tmpTransaction.getIn(['handlerData', 'shareDiff'])
		const afterValueError = errors ? errors.get('handlerData.after', List()).size > 0 : false

		return (
			<div className='i-content__container'>
				{this.renderButtons()}
				<ScrollView scrollbarRef={(r) => (this.scrollbar = r)} autoHide={true} showOnHover={true}>
					<Panel tid='split_reverse_split'>
						<Field tid='generic.date' name='date' errors={errors} className='col-md-5 col-md-offset-7'>
							<DatePicker
								hasError={errors ? errors.get('date', List()).size > 0 : false}
								calendarPlacement='bottom-end'
								value={tmpTransaction.get('date')}
								onChange={this.onChangeDate}
								language={i18n.language}
							/>
						</Field>

						<Field name='handlerData.after' errors={errors} className='col-md-12'>
							<div className=' split__row'>
								<div className='box--v-center'>
									<span className={afterValueError ? 'has-error' : ''}>
										<input
											type='number'
											min={1}
											className='form-control box-inline box-w70 text--align-center'
											value={tmpTransaction.getIn(['handlerData', 'after']) || ''}
											onChange={(event) => {
												this.onChange('after', parseInt(event.target.value))
											}}
										/>
									</span>

									<span className='form-control-static form-control-static--mar-hor'>
										<FormattedMessage id='shares.transaction.split.for' />
									</span>

									<span className={afterValueError ? 'has-error' : ''}>
										<input
											type='number'
											min={1}
											className='form-control box-inline box-w70 text--align-center'
											value={tmpTransaction.getIn(['handlerData', 'before']) || ''}
											onChange={(event) => {
												this.onChange('before', parseInt(event.target.value))
											}}
										/>
									</span>
								</div>
							</div>
						</Field>
						<div className='form-group--mar-btm-0 col-md-12'>
							<FormattedHTMLMessage id='shares.transactions.split.form.split_information' />
						</div>
					</Panel>

					{shareDiff && (
						<div id='emission-leveling-wrapper'>
							<Panel>
								<div className='form-group col-md-12'>
									<div className='attendees-alert-wrapper'>
										<div className='alert alert-warning alert--icon'>
											<div className='icon-container'>
												<i className='fa fa-exclamation-triangle'></i>
											</div>
											<div className='alert-text'>
												<FormattedHTMLMessage id='shares.transactions.split.alert' />
											</div>
										</div>
									</div>
								</div>

								<div className='form-group col-md-12'>
									<FormattedHTMLMessage id='shares.transactions.split.eq_emission_information' />
								</div>

								<div className='form-group col-md-12'>
									<div className='btn-group align-center'>
										<button type='button' className='btn btn-primary' onClick={this.acceptAdjustment}>
											<FormattedHTMLMessage id='generic.accept' />
										</button>
										<button type='button' className='btn btn-default' onClick={this.cancelTransaction}>
											<FormattedHTMLMessage id='generic.form.cancel' />
										</button>
									</div>
								</div>
							</Panel>

							<EmissionLeveling
								latestTransaction={transaction}
								shareDiff={shareDiff}
								increaseDiff={false}
								onChange={this.onChange}
							/>
						</div>
					)}
				</ScrollView>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get('transaction'),
		tmpTransaction: state.transaction.getIn(['tmpTransaction', transactionType], initialTransaction),
		investors: state.investors.get('list'),
		company: state.company.company,
		i18n: state.i18n
	}
}

const mapActionsToProps = {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	cancelTemporaryTransaction,
	addErrorNotification,
	addInfoNotification
}

const validators = fromJS({
	date: {
		tid: 'generic.date',
		rules: [
			{ func: isRequired, message: 'validation.is_required' },
			{
				func: validateTransactionDate,
				message: 'validation.current_transaction_date_must_be_later_then_last_transaction'
			}
		]
	},
	'handlerData.after': {
		rules: [
			{ func: isRequired, message: 'validation.is_required' },
			{ func: validateBeforeAndAfterNotEqual, message: 'validation.after_and_before_equal' },
			{ func: validateBeforeAndAfterGtZero, message: 'validation.value_gt_zero' }
		]
	},
	'handlerData.before': {
		rules: [
			{ func: isRequired, message: 'validation.is_required' },
			{ func: validateBeforeAndAfterNotEqual, message: 'validation.after_and_before_equal' },
			{ func: validateBeforeAndAfterGtZero, message: 'validation.value_gt_zero' }
		]
	}
})

SplitForm = immutableForm(SplitForm, 'splitForm', validators)
export default connect(mapStateToProps, mapActionsToProps)(SplitForm)
