import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleMenu } from '../../../components/helpers/refs.helper'
import { getUserName } from '../../../components/helpers/users'

import StepsModal from '../../../dumb-components/shared/modal/steps-modal'
import PreviousLeftButton from '../../../dumb-components/shared/modal/previous-left-button'
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../dumb-components/shared/button-v2'

import ManageAttendeeContainer from './manage-attendee-modal/manage-attendee.container'
import ManageCustomFunctionsContainer from './manage-attendee-functions.container'

import { MEETINGS_ATTENDEE_SETTINGS_MODAL } from '../../../constants/modals'
import { closeModal, openModal } from '../../../actions/modals.actions'

const FooterRightCloseComponent = ({ onClick }) => (
	<FooterRightControls>
		<TransparentButton tid='generic.form.close' textColor='midGrey' onClick={onClick} />
	</FooterRightControls>
)

class ManageAttendeeModalContainer extends Component {
	// 0 - Manage Attendee || 1 - Manage custom roles
	state = {
		step: 0
	}

	componentDidUpdate(prevProps) {
		const { isOpen } = this.props

		if (!isOpen && isOpen !== prevProps.isOpen) {
			this.setState({ step: 0 })
		}
	}

	goToManageAttendee = ({ dropdownRef }) => {
		toggleMenu(dropdownRef)
		this.setState({ step: 0 })
	}

	nextStep = () => {
		this.setState({ step: this.state.step + 1 })
	}

	prevStep = () => {
		this.setState({ step: this.state.step - 1 })
	}

	closeModal = () => {
		this.props.closeModal()
	}

	getSteps = () => {
		const { attendeeId } = this.props

		return [
			{
				body: <ManageAttendeeContainer onManageFunctions={this.nextStep} attendeeId={attendeeId} />,
				footer: {
					component: <FooterRightCloseComponent onClick={this.closeModal} />
				}
			},
			{
				body: <ManageCustomFunctionsContainer />,
				footer: {
					leftComponent: <PreviousLeftButton onClick={this.prevStep} />
				}
			}
		]
	}

	getAttendeeName = () => {
		const { companyName, attendee, investors, usersCache } = this.props
		const name = companyName || getUserName(attendee, investors, usersCache)
		return name ? name : undefined
	}

	getModalTitleTid = (name) => {
		const { step } = this.state

		if (!name) {
			return
		}

		if (step === 0) {
			return 'meetings.manage.attendee.modal.name.title'
		}

		return 'meetings.attendees.function.manage_function.modal.name.title'
	}

	render() {
		const { isOpen } = this.props
		const { step } = this.state

		if (!isOpen) {
			return null
		}

		const name = this.getAttendeeName()

		return (
			<StepsModal
				isOpen={isOpen}
				steps={this.getSteps()}
				step={step}
				hSize='md'
				title={this.getModalTitleTid(name)}
				titleValues={name ? { name } : undefined}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	const attendeeId = store.modals.getIn(['activeModal', 'options', 'userId'])

	return {
		isOpen: store.modals.getIn(['activeModal', 'name']) === MEETINGS_ATTENDEE_SETTINGS_MODAL,
		usersCache: store.usersCache.get('usersCache'),
		investors: store.investors.get('list'),
		attendee: store.meetings.getIn(['meeting', 'attendees', attendeeId]),
		companyName: store.meetings.getIn(['meeting', 'metadata', 'companyNames', attendeeId]),
		attendeeId
	}
}

const mapActionsToProps = {
	openModal,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(ManageAttendeeModalContainer)
