import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string } from 'prop-types'
import { fromJS } from 'immutable'

import { Select } from '../../../dumb-components/shared/select'
import Text from '../../../dumb-components/shared/text/text'

import { changeVotingResult } from '../../../actions/votings.actions'

const VOTING_OPTIONS = fromJS([
	{ value: 'YES', label: 'TODO YES' },
	{ value: 'NO', label: 'TODO NO' },
	{ value: 'ABSTAIN', label: 'TODO ABSTAIN' },
	{ value: 'NOT_VOTED', label: 'TODO NOT_VOTED' }
])

class ChangeResultSelectContainer extends Component {
	static propTypes = {
		voterId: string.isRequired
	}

	onChange = (value) => {
		const { meetingId, votingId, voterId, changeVotingResult } = this.props

		changeVotingResult(meetingId, votingId, voterId, value)
	}

	formatOptionLabel = (option) => {
		let textColor = 'midGrey'

		switch (option.value) {
			case 'YES':
				textColor = 'green'
				break
			case 'NO':
				textColor = 'red'
				break
			case 'ABSTAIN':
				textColor = 'yellowMid'
				break
		}

		return <Text color={textColor} tid={`meetings.voting.result.${option.value}`} singleLine />
	}

	render = () => {
		const { voteResult } = this.props

		return (
			<Select
				options={VOTING_OPTIONS}
				onChange={this.onChange}
				value={voteResult}
				isSearchable={false}
				menuPortalTarget={document.body}
				formatOptionLabel={this.formatOptionLabel}
			/>
		)
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		meetingId: store.votings.getIn(['voting', 'meetingId']),
		votingId: store.votings.getIn(['voting', 'id']),
		voteResult: store.votings.getIn(['voting', 'votes', ownProps.voterId, 'result'])
	}
}

const mapActionsToProps = {
	changeVotingResult
}

export default connect(mapStoreToProps, mapActionsToProps)(ChangeResultSelectContainer)
