import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'

import { getUsersUserCanVoteFor } from '../../../components/helpers/voting.helper'
import VotingModal from '../../../public/dumb-components/meetings/voting-modal/voting-modal'

import { vote } from '../../../public/actions/votings.actions'

import { fetchAndCacheInvestors } from '../../../actions/investors.actions'

class CastVotesContainer extends Component {
	state = {
		usersToVote: List(),
		votingPendingForUsers: Map() // [userId]: {isPending: <bool>}
	}

	componentDidMount = () => {
		this.setUsersToVote()
		this.fetchInvestors()
	}

	componentDidUpdate = (prevProps) => {
		const { attendees, proxies, investors, usersCache } = this.props

		if (attendees !== prevProps.attendees) {
			this.setUsersToVote()
			this.fetchInvestors()
		}

		if (proxies !== prevProps.proxies) {
			this.setUsersToVote()
		}

		if (investors !== prevProps.investors) {
			this.setUsersToVote()
		}

		if (usersCache !== prevProps.usersCache) {
			this.setUsersToVote()
		}
	}

	fetchInvestors = () => {
		const { attendees, fetchAndCacheInvestors } = this.props
		const investmentIds = attendees && attendees.filter((a) => !a.get('isGuest')).map((a) => a.get('userId'))

		fetchAndCacheInvestors(investmentIds)
	}

	setUsersToVote = () => {
		const { attendees, proxies, userId, investors, usersCache, sharesInitialized } = this.props
		const usersToVote = getUsersUserCanVoteFor(attendees, proxies, userId, investors, usersCache, sharesInitialized)
		this.setState({ usersToVote })
	}

	setVotePendingForUser = (userId) => {
		let { votingPendingForUsers } = this.state
		votingPendingForUsers = votingPendingForUsers.setIn([userId, 'isPending'], true)
		this.setState({ votingPendingForUsers })
	}

	removeVotePendingForUser = (userId) => {
		let { votingPendingForUsers } = this.state
		votingPendingForUsers = votingPendingForUsers.remove(userId)
		this.setState({ votingPendingForUsers })
	}

	voteCallback = (userId) => {
		this.removeVotePendingForUser(userId)
	}

	handleVote = (votedForUserId, voteResult) => {
		const { voting, meetingId, userId, companyId, vote } = this.props
		const votingId = voting.get('id')
		const result = voting.getIn(['votes', votedForUserId, 'result'])

		// Do not allow voting on the same vote
		// if user already voted.
		if (voteResult === result) {
			return
		}

		this.setVotePendingForUser(votedForUserId)
		vote(
			meetingId,
			votingId,
			votedForUserId,
			voteResult,
			userId,
			companyId,
			this.voteCallback.bind(null, votedForUserId)
		)
	}

	render = () => {
		const { usersToVote, votingPendingForUsers } = this.state
		const { voting } = this.props

		return (
			<VotingModal
				voting={voting}
				paddinglessContainer={true}
				onVote={this.handleVote}
				votingUsers={usersToVote}
				votingPendingForUsers={votingPendingForUsers}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	const userId = store.user.getIn(['userObj', 'id'])

	return {
		voting: store.votings.get('voting'),
		attendees: store.meetings.getIn(['meeting', 'attendees']),
		proxies: store.meetings.getIn(['meeting', 'proxies']),
		meetingId: store.meetings.getIn(['meeting', 'id']),
		usersCache: store.usersCache.get('usersCache'),
		investors: store.investors.get('investorsCache'),
		sharesInitialized: store.company.company?.metadata?.sharesInitialized,
		userId
	}
}

const mapActionsToProps = {
	vote,
	fetchAndCacheInvestors
}

export default connect(mapStoreToProps, mapActionsToProps)(CastVotesContainer)
