import React, { Component } from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import DatePicker from 'react-datepicker'
import Moment from '../../../../modules/moment.module'
import { getLabel, SHARES_DEFAULT_CLASSES, SHARES_DEFAULT_TYPES } from '../../../../constants/shares'
import ScrollView from '../../../../dumb-components/shared/layout/scroll-view/scroll-view'
import SplitView from '../../../../dumb-components/shared/layout/split-view/split-view'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'

import { NUMBER_FORMAT_CURRENCY, NUMBER_FORMAT_INTEGER } from '/shared/constants'

class ShareOverview extends Component {
	static propTypes = {
		intl: intlShape.isRequired
	}

	constructor(props) {
		super(props)
		this.step = 4
	}

	componentDidMount() {}

	onSubmit() {
		const { setupType, transaction, onCreateTransaction, jumpToStep } = this.props

		this.props.onCreateTransaction(transaction)
	}

	goToPrevious() {
		const { onPrevious, jumpToStep } = this.props

		onPrevious(this.step - 1)
		jumpToStep(this.step - 1)
	}

	formatNumerInput(num) {
		const { formatNumber } = this.props.intl

		if (isNaN(num)) {
			return num
		}
		//return num;
		return formatNumber(num)
	}

	renderOverview() {}

	renderShareTypeTable(type, index) {
		const typeId = type.get('type')
		let classOfShare = typeId && typeId.split('$')[0]
		let classOfShareLabel = getLabel(SHARES_DEFAULT_CLASSES, classOfShare)
		let series = typeId.split('$')[1]
		let seriesLabel = getLabel(SHARES_DEFAULT_TYPES, series)
		classOfShareLabel = this.props.i18n.messages[classOfShareLabel] || decodeURIComponent(classOfShare)
		seriesLabel = this.props.i18n.messages[seriesLabel] || decodeURIComponent(series)
		return (
			<div key={index} className='list__item list__item--table list__item__input--pad-lft'>
				<div className='share-overview__class-of-share'>
					{classOfShareLabel} {seriesLabel}
				</div>
				<div className='share-overview__numOfShares'>
					{localeFormatNumber(type.get('numOfShares'), NUMBER_FORMAT_INTEGER)}
				</div>
				<div className='share-overview__votes'>{type.get('votesPerShare')}</div>
			</div>
		)
	}

	renderSequenceTable(sequence, index) {
		const { transaction } = this.props

		const investor = transaction.getIn(['investors', sequence.get('investmentId')])

		const name = investor.getIn(['investorInformation', 'name'])
		let id = investor.getIn(['investorInformation', 'id'])

		const shareholderIndex =
			transaction
				.getIn(['handlerData', 'initialSequences'])
				.findIndex((i) => i.get('investmentId') === sequence.get('investmentId')) + 1

		const isCapitalInsurance = investor.get('investorTypeOfOwner') === 'capitalInsurance'
		if (isCapitalInsurance) {
			id = investor.getIn(['investorInformation', 'insuranceNr'], '')
		}

		const type = sequence.get('type')
		let classOfShare = type && type.split('$')[0]
		let classOfShareLabel = getLabel(SHARES_DEFAULT_CLASSES, classOfShare)
		let series = type && type.split('$')[1]
		let seriesLabel = getLabel(SHARES_DEFAULT_TYPES, series)
		classOfShareLabel = this.props.i18n.messages[classOfShareLabel] || decodeURIComponent(classOfShare)
		seriesLabel = this.props.i18n.messages[seriesLabel] || decodeURIComponent(series)

		return (
			<div key={index} className='list__item list__item--table list__item__input--pad-lft'>
				<div className='share-overview__shareholder'>
					#{shareholderIndex} {name}
				</div>
				<div className='share-overview__id'>{id}</div>
				<div className='share-overview__sequence'>
					{localeFormatNumber(sequence.get('sequenceFrom'), NUMBER_FORMAT_INTEGER)} -{' '}
					{localeFormatNumber(sequence.get('sequenceTo'), NUMBER_FORMAT_INTEGER)}
				</div>
				<div className='share-overview__class-of-share'>
					{classOfShareLabel} {seriesLabel}
				</div>
			</div>
		)
	}

	renderSequences = () => {
		const { transaction } = this.props
		const sequences = transaction.getIn(['handlerData', 'initialSequences'])

		return (
			<ScrollView autoHeightMin={270}>
				<div className='list list--striped list--table share-overview__table__sequences'>
					<div className='list__list-header text--muted'>
						<div className='share-overview__shareholder'>
							<FormattedMessage id='shareholder' />
						</div>
						<div className='share-overview__id'>ID</div>
						<div className='share-overview__sequence'>
							<FormattedMessage id='sequence' />
						</div>
						<div className='share-overview__class-of-share'>
							<FormattedMessage id='shares.class_of_shares' />
						</div>
					</div>
					<div className='list__body'>{sequences.map(this.renderSequenceTable.bind(this))}</div>
				</div>
			</ScrollView>
		)
	}

	renderShareTypes = () => {
		const { transaction } = this.props
		const types = transaction.getIn(['handlerData', 'initialShareData', 'types'])

		return (
			<ScrollView autoHeightMin={270}>
				<div className='list list--striped list--table share-overview__table__share-types'>
					<div className='list__list-header text--muted'>
						<div className='share-overview__class-of-share'>
							<FormattedMessage id='shares.class_of_shares' />
						</div>
						<div className='share-overview__numOfShares'>
							<FormattedMessage id='total_number_of_shares' />
						</div>
						<div className='share-overview__votes'>
							<FormattedMessage id='votes_per_share' />
						</div>
					</div>
					<div className='list__body'>{types.map(this.renderShareTypeTable.bind(this))}</div>
				</div>
			</ScrollView>
		)
	}

	render() {
		const { transaction, setupType } = this.props

		const types = transaction.getIn(['handlerData', 'initialShareData', 'types'])
		const sequences = transaction.getIn(['handlerData', 'initialSequences'])
		const shareCapital = transaction.getIn(['handlerData', 'initialShareData', 'shareCapital'])
		const sharesIssued = transaction.getIn(['handlerData', 'initialShareData', 'numOfTotalShares'])
		const quota = transaction.getIn(['handlerData', 'initialShareData', 'quotaValue'])
		const fromDate = Moment(transaction.get('date')).format('L')

		if (!types || !sequences) {
			return null
		}

		return (
			<div className={`account-wizard__content account-wizard--animated ${this.props.className}`}>
				<div className='account-wizard__header' style={{ height: 'auto' }}>
					<h2 className='account-wizard__title'>
						<span className='account-wizard__step'>5</span> <FormattedMessage id='review_information' />
					</h2>
				</div>
				<div className='share-overview'>
					<div className='account-wizard__body' style={{ height: 'auto' }}>
						<div className='i-panel i-panel--white'>
							<div className='i-panel__body'>
								<span className='text h-box share-overview__general-info'>
									<span className='share-overview__mar-text'>
										<span className='text--bold'>
											<FormattedMessage id='share_capital' />:
										</span>{' '}
										{localeFormatNumber(shareCapital, NUMBER_FORMAT_CURRENCY)}
									</span>
									<span className='share-overview__mar-text'>
										<span className='text--bold'>
											<FormattedMessage id='total_number_of_shares' />:
										</span>{' '}
										{localeFormatNumber(sharesIssued, NUMBER_FORMAT_INTEGER)}
									</span>
									<span className='share-overview__mar-text'>
										<span className='text--bold'>
											<FormattedMessage id='quota_value' />:
										</span>{' '}
										{localeFormatNumber(quota, NUMBER_FORMAT_CURRENCY)}
									</span>
									<div className='share-overview__date-from share-overview__mar-text'>
										{setupType === 'a' && (
											<div>
												<span className='text--bold'>
													<FormattedMessage id='shares.company_formation_date' />:
												</span>{' '}
												{fromDate}
											</div>
										)}
										{setupType === 'b' && (
											<div>
												<span className='text--bold'>
													<FormattedMessage id='from_date' />:
												</span>{' '}
												{fromDate}
											</div>
										)}
									</div>
								</span>
							</div>
						</div>

						<div className='i-panel i-panel--white'>
							<div className='i-panel__body'>
								<SplitView
									leftComponent={this.renderShareTypes}
									rightComponent={this.renderSequences}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className='account-wizard__footer'>
					<button type='button' className='btn btn-account-wizard' onClick={this.goToPrevious.bind(this)}>
						<FormattedMessage id='previous' />
					</button>
					<button className='btn btn-account-wizard' onClick={this.onSubmit.bind(this)}>
						<FormattedMessage id='create_share_register' />
					</button>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return { i18n: state.i18n }
}

ShareOverview = connect(mapStateToProps)(ShareOverview)
ShareOverview = injectIntl(ShareOverview)
export default ShareOverview
