/**
 * Recommend Invono platform field
 * @module components/helpers/recommend-invono-platform-field
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { isRequired, validateEmail } from '../../modules/validation.module'
import req from '../../modules/request.module.js'

/** Class */
class RecommendInvonoPlatformField extends Component {
	constructor(props) {
		super(props)

		this.state = {
			email: '',
			valid: true,
			invitationSent: false,
			invitationSentTo: ''
		}
	}

	/**
	 * Sends the invitation request, if the email is valid.
	 */
	onSubmit() {
		const { email } = this.state
		const valid = isRequired(email) && validateEmail(email)

		if (valid) {
			req.post('/notifications/send-mail/' + this.props.emailType, { mailData: { email } }).then((response) => {
				this.setState({ invitationSent: true, invitationSentTo: email, email: '' })
			})
		}
		this.setState({ valid })
	}

	/**
	 * On change event for the text input.
	 * @param {Object} event — The onChange event.
	 */
	onEmailChange(event) {
		const email = event.target.value

		this.setState({ email })
	}

	/**
	 * The render function
	 */
	render() {
		const { email, valid, invitationSent, invitationSentTo } = this.state
		const placeholder = this.props.i18n.messages['email']
		const { invitationMessage } = this.props

		return (
			<div className='form-group col-md-12'>
				{invitationMessage && (
					<p>
						<FormattedMessage id={invitationMessage} />
					</p>
				)}
				{invitationSent && (
					<div className='alert alert-success form-group col-md-12'>
						<span className='fa fa-check'></span> <FormattedMessage id='invitation_sent_to' /> {invitationSentTo}
					</div>
				)}
				<div className={`form-group ${!valid ? 'has-error' : ''}`}>
					<input
						type='email'
						className='form-control'
						placeholder={placeholder}
						value={email}
						onChange={this.onEmailChange.bind(this)}
					/>
					{!valid && (
						<small className='help-block'>
							<FormattedMessage id='validation.email' />
						</small>
					)}
				</div>
				<div className='form-button-group'>
					<button type='button' className='btn btn-primary' onClick={this.onSubmit.bind(this)}>
						<FormattedMessage id='recommend_platform' />
					</button>
				</div>
			</div>
		)
	}
}

/** Map state to props. */
function mapStateToProps(state) {
	return {
		i18n: state.i18n
	}
}

/** Export */
RecommendInvonoPlatformField = connect(mapStateToProps, {})(RecommendInvonoPlatformField)
export default RecommendInvonoPlatformField
