import React, { Component } from 'react'
import { connect } from 'react-redux'

import DropdownMenuContainer from '../shared/dropdown-menu.container'
import DropdownIconItem from '../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import UserProfileMenu from '../../dumb-components/navbar/user-profile-menu/user-profile-menu'
import history from '../../interfaces/history'
import { PRIVATE_ADDRESS_BOOK_MODAL } from '../../constants/modals'
import { PeopleModalProvider } from '../shared/people-modal/people-modal.provider'
import { PeopleModalContainer } from '../shared/people-modal/people-modal.container'
import { PeopleModalHeader } from '../shared/people-modal/people-modal-header'
import { PersonalContactsGrid } from '../shared/people-modal/personal-contacts/PersonalConatctsGrid'
import { TAB_MY_PERSONAL_CONTACTS } from '../shared/select-user-modal/select-user-modal.constants'
import { CopyContactModalProvider } from '../shared/copy-contact/copy-contact-modal.provider'
import CopyContactToCompanyModal from '../shared/copy-contact/copy-contact-to-company-modal'
import { PeopleModalFooter } from '../shared/people-modal/people-modal-footer'

const USER_MENU_MOCKUP = [
	{
		id: 1,
		translationId: 'menu.userMenu.profile',
		url: '/profile',
		icon: 'faUser',
		index: 1
	},
	{
		id: 2,
		translationId: 'menu.user_menu.dropdown.item.private_address_book',
		modal: PRIVATE_ADDRESS_BOOK_MODAL,
		icon: 'faAddressBook',
		index: 2
	},
	{
		id: 3,
		translationId: 'menu.userMenu.logout',
		url: '/signout',
		icon: 'faPowerOff',
		index: 3
	}
]

class UserProfileMenuContainer extends Component {
	state = {
		isPrivateAddressModalOpen: false
	}

	onClickItem = (item) => {
		if (item.modal === PRIVATE_ADDRESS_BOOK_MODAL) {
			this.setState({ isPrivateAddressModalOpen: true })
		} else {
			this.props.history.push(item.url)
		}

		this.dropdownRef && this.dropdownRef.onToggleMenu()
	}

	closePrivateAddressbookModal = () => {
		this.setState({ isPrivateAddressModalOpen: false })
	}

	componentDidMount = () => {
		document.title = 'INVONO One™'
	}

	setDropdownRef = (ref) => {
		this.dropdownRef = ref
	}

	renderUserMenuItem = (item) => {
		const tid = item.translationId ? item.translationId : item.name

		return <DropdownIconItem tid={tid} icon={item.icon} key={item.id} onClick={this.onClickItem.bind(null, item)} />
	}

	render = () => {
		const { userObj } = this.props
		const { isPrivateAddressModalOpen } = this.state

		if (!userObj) {
			return null
		}

		return (
			<>
				<DropdownMenuContainer
					halignMenu='right'
					ref={this.setDropdownRef}
					renderRaw={
						<UserProfileMenu
							userId={userObj.get('id')}
							name={userObj.get('name')}
							image={userObj.getIn(['image', 'filename'])}
						/>
					}>
					{USER_MENU_MOCKUP.map(this.renderUserMenuItem)}
				</DropdownMenuContainer>
				{isPrivateAddressModalOpen && (
					<>
						<PeopleModalProvider
							isOpen={isPrivateAddressModalOpen}
							initialActiveTab={TAB_MY_PERSONAL_CONTACTS}
							canCopyToMultipleCompanyAddressbooks>
							<PeopleModalContainer
								headerComponent={() => <PeopleModalHeader title='private_addressbook.title' />}
								footerComponent={() => (
									<PeopleModalFooter
										cancelButtonTid='generic.form.close'
										onSave={this.addAttendeesFromModal}
										onCancel={this.closePrivateAddressbookModal}
									/>
								)}>
								<PersonalContactsGrid />
							</PeopleModalContainer>
						</PeopleModalProvider>

						<CopyContactModalProvider>
							<CopyContactToCompanyModal />
						</CopyContactModalProvider>
					</>
				)}
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		userObj: store.user.get('userObj')
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(UserProfileMenuContainer)
