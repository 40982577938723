import { fromJS, List, Map } from 'immutable'

import {
	IMMUTABLE_FORM_ADD_ERROR,
	IMMUTABLE_FORM_RESET_ERROR,
	IMMUTABLE_FORM_RESET_ERRORS,
	IMMUTABLE_FORM_INIT,
	IMMUTABLE_FORM_RESET,
	COMPANY_RESET_REDUCER
} from '../actions/types'

const INITIAL_STATE = fromJS({
	currentForm: null,
	errors: {}
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action
	let errors

	switch (type) {
		case IMMUTABLE_FORM_INIT:
			return state.set('currentForm', payload)
		case IMMUTABLE_FORM_RESET:
			return state.set('currentForm', null).set('validators', null).set('errors', null)
		case IMMUTABLE_FORM_ADD_ERROR:
			errors = state.getIn(['errors', payload.get('name')], List())
			errors = errors.push(payload.get('error'))
			return state.setIn(['errors', payload.get('name')], errors)
		case IMMUTABLE_FORM_RESET_ERROR:
			errors = state.get('errors', Map())
			errors = errors.delete(payload)
			return state.set('errors', errors)
		case IMMUTABLE_FORM_RESET_ERRORS:
			return state.set('errors', Map())
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}
