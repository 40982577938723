import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import { number } from 'prop-types'
import FilterBy from '../../dumb-components/shared/filter-by/filter-by'
import DropdownMenuContainer from '../shared/dropdown-menu.container'
import DropdownItem from '../../dumb-components/shared/dropdown-item/dropdown-item'
import Text from '../../dumb-components/shared/text/text'

import folderHelper from '../../components/helpers/folder.helper'

import { listTags } from '../../actions/tags.actions'
import { setFilterBy } from '../../actions/documents.actions'

import { mergeSharedFolders, mergeSharedMapParentToChildren } from '../../components/helpers/shared-documents.helper'

const FILTER_BY_ITEMS = ['tag', 'folder', 'document']

class DocumentsFilterByContainer extends Component {
	static propTypes = {
		totalDocuments: number,
		totalDocumentsShown: number
	}

	static defaultProps = {}

	state = {
		selectedFilter: 'tag',
		savedValues: Map(),
		selectedValues: List(),
		options: List(),
		folders: Map(),
		mapParentToChildren: Map()
	}

	componentDidMount = () => {
		const { isSharedFromCompanyId } = this.props
		if (isSharedFromCompanyId) {
			this.setState({ selectedFilter: 'folder' })
		}
		this.props.listTags()
	}

	componentDidUpdate = (prevProps) => {
		const { selectedFilter } = this.state
		const { tags, folderId, documents, sharedFolders, isSharedFromCompanyId } = this.props
		let { folders, mapParentToChildren } = this.props

		if (selectedFilter === 'tag' && prevProps.tags !== tags) {
			this.setOptions(selectedFilter)
		}

		if (selectedFilter === 'folder' && prevProps.folders !== folders) {
			this.setOptions(selectedFilter)
		}

		if (prevProps.documents !== documents) {
			this.setOptions(selectedFilter)
		}

		//if we no longer have acess to the folder we're in we get pushed to root
		if (prevProps.folders !== folders || prevProps.sharedFolders !== sharedFolders) {
			folders = sharedFolders ? mergeSharedFolders(sharedFolders, folders) : folders
			mapParentToChildren = sharedFolders
				? mergeSharedMapParentToChildren(sharedFolders, mapParentToChildren)
				: mapParentToChildren
			this.setState({ folders, mapParentToChildren })
		}

		if (prevProps.folderId !== folderId) {
			if (isSharedFromCompanyId && this.state.selectedFilter === 'tag') {
				this.setState({ selectedFilter: 'folder' })
			}

			this.setOptions(selectedFilter)
		}
	}

	parseTags = (tags) => {
		const { documents } = this.props
		const tagsMap = {}

		if (documents) {
			documents.forEach((doc) => {
				const tags = doc.get('tags', List())
				tags.forEach((tag) => {
					tagsMap[tag] = true
				})
			})
		}

		const parsedTags = tags
			.map((tag) => {
				return Map({
					value: tag.get('id'),
					label: tag.get('name')
				})
			})
			.filter((tag) => {
				return tagsMap[tag.get('value')]
			})
			.sortBy((f) => f.get('label').toLowerCase())
		return parsedTags
	}

	handleMultipleFolderNames = (options, folders) => {
		let haveMultipleMap = Map()
		options.forEach((option, index) => {
			const label = option.get('label')
			if (haveMultipleMap.get(label)) {
				haveMultipleMap = haveMultipleMap.update(label, (arr) => {
					return arr.push(index)
				})
			} else {
				haveMultipleMap = haveMultipleMap.set(label, List([index]))
			}
		})

		haveMultipleMap.forEach((arr) => {
			if (arr.size > 1) {
				arr.forEach((i) => {
					const option = options.get(i)
					const folder = folders.get(option.get('value'))
					const parentId = folder.get('parent')
					const parent = folders.get(parentId)
					if (parent) {
						options = options.updateIn([i, 'label'], (label) => {
							return `${label} | ${parent.get('translatedName')}`
						})
					}
				})
			}
		})
		return options
	}

	parseFolders = (folders) => {
		const { folderId } = this.props
		const { mapParentToChildren } = this.state
		// if selected folder
		const ids = []

		folderHelper.getDescendentsIds(ids, folderId, mapParentToChildren)
		let options = folders
			.toList()
			.map((folder) => {
				return Map({
					value: folder.get('id'),
					label: folder.get('translatedName')
				})
			})
			.filter((folder) => {
				return folder.get('label') && ids.includes(folder.get('value'))
			})
			.sortBy((f) => f.get('label').toLowerCase())

		options = this.handleMultipleFolderNames(options, folders)

		return options
	}

	parseDocuments = (documents) => {
		let documentsSet = Map()

		documents &&
			documents.forEach((doc) => {
				documentsSet = documentsSet.set(doc.get('title'), doc)
			})

		return documentsSet
			.toList()
			.map((doc) => {
				return Map({
					value: doc.get('id'),
					label: doc.get('title')
				})
			})
			.filter((doc) => {
				return doc.get('label')
			})
			.sortBy((f) => f.get('label').toLowerCase())
	}

	setOptions = (selectedFilter) => {
		const { tags, documents } = this.props
		const { folders } = this.state

		switch (selectedFilter) {
			case 'tag':
				this.setState({ options: this.parseTags(tags) })
				break
			case 'folder':
				this.setState({ options: this.parseFolders(folders) })
				break
			case 'document':
				this.setState({ options: this.parseDocuments(documents) })
				break
		}
	}

	// Triggers when filter type is changed
	onChangeSelectedFilter = (selectedFilter) => {
		// Previously selected filter
		const prevSelectedFilter = this.state.selectedFilter
		let { savedValues, selectedValues } = this.state

		// Save current selection to backup
		savedValues = savedValues.setIn([prevSelectedFilter], selectedValues)

		// Read values from backup if there are any
		selectedValues = savedValues.get(selectedFilter, List())

		// Close menu
		this.moreActionBtnRef && this.moreActionBtnRef.onToggleMenu()

		// Get the new options
		this.setOptions(selectedFilter)

		this.setFilterBy(selectedFilter, selectedValues)

		// Update states
		this.setState({ selectedFilter, savedValues, selectedValues })

		// Focus select input
		this.filterBySelectRef && this.filterBySelectRef.focus()
	}

	setFilterBy = (selectedFilter, selectedValues) => {
		const { documents } = this.props

		if (selectedFilter === 'document') {
			let docMap = Map()
			documents.forEach((doc) => {
				docMap = docMap.set(doc.get('id'), doc)
			})

			selectedValues = selectedValues.map((selectedValue) => {
				return docMap.getIn([selectedValue, 'title'])
			})
		}

		this.props.setFilterBy(
			Map({
				source: selectedFilter,
				values: selectedValues,
				isSimpleFilter: true
			})
		)
	}

	// Triggers when a new value is added or removed
	onChange = (selectedValues) => {
		const { selectedFilter } = this.state
		this.setState({ selectedValues })

		this.setFilterBy(selectedFilter, selectedValues)
	}

	renderDropdown = () => {
		const { selectedFilter } = this.state
		const { isSharedFromCompanyId } = this.props

		return (
			<DropdownMenuContainer
				menuIcon='faChevronDown'
				menuNoTop={true}
				buttonMinWidth='24px'
				buttonNoHorizontalPadding={true}
				ref={(ref) => (this.moreActionBtnRef = ref)}
				tooltipTid='tooltip.documents.filter_by.dropdown'>
				{FILTER_BY_ITEMS.map((item, index) => {
					// Don't render item if it is currently selected
					if (selectedFilter === item) {
						return null
					}
					if (item === 'tag' && isSharedFromCompanyId) {
						return null
					}

					return (
						<DropdownItem onClick={this.onChangeSelectedFilter.bind(null, item)} key={index}>
							<Text tid='component.filterby.menu.filter_by' />
							&nbsp;
							<Text tid={`component.filterby.item.${item}`} />
						</DropdownItem>
					)
				})}
			</DropdownMenuContainer>
		)
	}

	render = () => {
		const { selectedFilter, selectedValues, options } = this.state
		const { totalDocuments, totalDocumentsShown } = this.props

		return (
			<FilterBy
				dropdownRenderer={this.renderDropdown}
				options={options}
				onChange={this.onChange}
				selectedFilterByItem={selectedFilter}
				value={selectedValues}
				closeMenuOnSelect={true}
				counterLeftValue={totalDocumentsShown}
				counterRightValue={totalDocuments}
				selectRef={(ref) => (this.filterBySelectRef = ref)}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		tags: store.tags.getIn(['tags']),
		folders: store.folders.get('folders'),
		sharedFolders: store.folders.get('sharedFolders'),
		documents: store.documents.get('visibleDocuments'),
		mapParentToChildren: store.folders.get('mapParentToChildren'),
		folderId: store.folders.get('selectedFolderId')
	}
}

const mapActionsToProps = {
	listTags,
	setFilterBy
}

export default connect(mapStoreToProps, mapActionsToProps)(DocumentsFilterByContainer)
