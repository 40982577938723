import React, { PureComponent } from 'react'
import { string, func } from 'prop-types'
import Grid from 'styled-components-grid'
import styled from 'styled-components'
import Panel from '../../shared/panel/panel'
import { Padding } from 'styled-components-spacing'
import Label from '../../shared/label/label'
import Input from '../../shared/input/input'
import Text from '../../shared/text/text'

/* CSS templates */
const StyledPanelTitle = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

export default class AttendeeDetailsPanel extends PureComponent {
	static propTypes = {
		name: string,
		renderAttendeeFunctions: func,
		renderAttendence: func,
		onManageFunctions: func
	}

	renderTitle = () => {
		const { name } = this.props

		return (
			<StyledPanelTitle>
				<Text tag='h4' color='catalinaBlue' singleLine>
					{name}
				</Text>
			</StyledPanelTitle>
		)
	}

	render = () => {
		const { name, renderAttendeeFunctions, renderAttendence, onManageFunctions } = this.props

		return (
			<Panel leftComponent={this.renderTitle} marginBottom={true}>
				<Padding all={3}>
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='meetings.attendees.details.label.attendee'>
									<Input
										disabled={true}
										placeholderTid='meetings.attendees.details.placeholder.attendee'
										value={name}
									/>
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>

					<Grid>
						<Grid.Unit size={{ xs: 1, sm: 1 / 2 }}>
							<Padding all={3}>
								<Label
									tid='meetings.attendees.details.label.function'
									rightComponent={() => (
										<Text tid='meetings.attendees.function.manage_function' onClick={onManageFunctions} hasUnderline />
									)}>
									{renderAttendeeFunctions && renderAttendeeFunctions()}
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>
				</Padding>
			</Panel>
		)
	}
}
