import React, { PureComponent } from 'react'
import { func, string, bool } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import styled from 'styled-components'
import Modal from '../../shared/modal/modal'
import Text from '../../shared/text/text'
import { TransparentButton } from '../../shared/button-v2'
import ColoredContentWrapper from '../../shared/colored-content-wrapper/colored-content-wrapper'
import Icon from '../../shared/icon/icon'

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledIconWrapper = styled.div`
	padding-bottom: ${(props) => props.theme.spacing[3]};
`

export default class PaymentRequiresActionModal extends PureComponent {
	static propTypes = {
		onClose: func,
		isOpen: bool
	}

	renderFooter = () => {
		const { onClose } = this.props

		return <TransparentButton tid='generic.form.close' textColor='midGrey' onClick={onClose} />
	}

	render = () => {
		const { isOpen } = this.props

		return (
			<Modal
				isOpen={isOpen}
				vSize={50}
				hSize='md'
				title='subscriptions.payment.payment_requires_action'
				footerComponent={this.renderFooter()}>
				<StyledColoredContentWrapper type='secondary'>
					<StyledIconWrapper>
						<Icon icon='faExclamationTriangle' size='normal' color='yellowMid' type='solid' />
					</StyledIconWrapper>
					<Text tid='subscriptions.payment.payment_requires_action.info_1_1' marginBottom={3} />
					<Text tid='subscriptions.payment.payment_requires_action.info_1_2' marginBottom={3} />
					<Text tid='subscriptions.payment.payment_requires_action.info_1_3' marginBottom={3} />
					<Text tid='subscriptions.payment.payment_requires_action.info_1_4' />
				</StyledColoredContentWrapper>
			</Modal>
		)
	}
}
