import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Button from '../../shared/button/button'
import { bool, func, string, number } from 'prop-types'
import Input from '../../shared/input/input'
import Label from '../../shared/label/label'
import Tooltip from '../../shared/tooltip/tooltip'
import Text from '../../shared/text/text'
import ErrorMessage from '../error-message'

import { EMAIL_FIELD, CODE_FIELD, PASSWORD_FIELD, PASSWORD_VERIFY_FIELD } from '../../../constants/credentials'

const StyledForgotPassword = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	> * {
		margin: ${(props) => props.theme.spacing[3]} 0;
	}
`

const StyledTwoButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: flex-end;

	> * {
		margin: 0 ${(props) => props.theme.spacing[4]};
	}
`

export default class ForgotPassword extends PureComponent {
	static propTypes = {
		onClickActionBtn: func,
		onCancel: func,
		onChange: func,
		actionBtnTid: string,
		email: string,
		code: string,
		password: string,
		passwordVerify: string,
		step: number,
		actionBtnLoading: bool
	}

	static defaultProps = {
		actionBtnTid: 'credentials.forgotpw.btn.send',
		email: '',
		code: '',
		password: '',
		passwordVerify: ''
	}

	onInputEnterKey = () => {
		const { onClickActionBtn, actionBtnLoading } = this.props
		!actionBtnLoading && onClickActionBtn && onClickActionBtn()
	}

	renderEmailInput = () => {
		const { hasError, onSetTooltipRefs, onChange, email, onHideTooltip, step, actionBtnLoading } = this.props

		return (
			<Label tid='credentials.forgotpw.label.email' textColor='white'>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([EMAIL_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, EMAIL_FIELD)}>
					<Input
						inputStyle='v2'
						fieldName={EMAIL_FIELD}
						onChange={onChange}
						value={email}
						onBlur={onHideTooltip}
						onEnter={this.onInputEnterKey}
						disabled={step >= 1 || actionBtnLoading}
						hasError={hasError.get(EMAIL_FIELD)}
					/>
				</Tooltip>
			</Label>
		)
	}

	renderVerifyCodeInput = () => {
		const { hasError, onSetTooltipRefs, onChange, code, onHideTooltip, step, actionBtnLoading, email } = this.props
		const stepPassed = step >= 2

		return (
			<>
				<Label tid='credentials_verification.code' textColor='white'>
					<Tooltip
						activeState='formValidator'
						tid={hasError.getIn([CODE_FIELD, 0])}
						ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, CODE_FIELD)}>
						<Input
							inputStyle='v2'
							fieldName={CODE_FIELD}
							onChange={onChange}
							value={code}
							onBlur={onHideTooltip}
							onEnter={this.onInputEnterKey}
							autoFocus={true}
							disabled={stepPassed || actionBtnLoading}
							hasError={hasError.get(CODE_FIELD)}
						/>
					</Tooltip>
				</Label>

				{!stepPassed && (
					<Text color='white' tid='credentials.verify.code.code_sent_by_email_If_exist' values={{ email }} />
				)}
			</>
		)
	}

	renderPasswordInput = () => {
		const { hasError, onSetTooltipRefs, onChange, password, onHideTooltip, actionBtnLoading } = this.props

		return (
			<Label tid='credentials.forgotpw.label.pass' textColor='white'>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([PASSWORD_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, PASSWORD_FIELD)}>
					<Input
						inputStyle='v2'
						fieldName={PASSWORD_FIELD}
						onChange={onChange}
						value={password}
						onBlur={onHideTooltip}
						onEnter={this.onInputEnterKey}
						autoFocus={true}
						hasError={hasError.get(PASSWORD_FIELD)}
						disabled={actionBtnLoading}
						type='password'
					/>
				</Tooltip>
			</Label>
		)
	}

	renderPasswordVerifyInput = () => {
		const { hasError, onSetTooltipRefs, onChange, passwordVerify, onHideTooltip, actionBtnLoading } = this.props

		return (
			<Label tid='credentials.forgotpw.label.pass_verify' textColor='white'>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([PASSWORD_VERIFY_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, PASSWORD_VERIFY_FIELD)}>
					<Input
						inputStyle='v2'
						fieldName={PASSWORD_VERIFY_FIELD}
						onChange={onChange}
						value={passwordVerify}
						onBlur={onHideTooltip}
						onEnter={this.onInputEnterKey}
						disabled={actionBtnLoading}
						hasError={hasError.get(PASSWORD_VERIFY_FIELD)}
						type='password'
					/>
				</Tooltip>
			</Label>
		)
	}

	render = () => {
		const { onClickActionBtn, onCancel, actionBtnTid, step, actionBtnLoading, translationId } = this.props

		return (
			<StyledForgotPassword>
				{step >= 0 && this.renderEmailInput()}
				{step >= 1 && this.renderVerifyCodeInput()}
				{step >= 2 && this.renderPasswordInput()}
				{step >= 2 && this.renderPasswordVerifyInput()}

				<ErrorMessage tid={translationId} />

				<StyledTwoButtonWrapper>
					<Button
						mode='rounded'
						tid={actionBtnTid}
						isLoading={actionBtnLoading}
						minWidth='150px'
						maxWidth='150px'
						height='50px'
						onClick={onClickActionBtn}
					/>
					<Button
						mode='rounded'
						tid='credentials_cancel'
						inverted={true}
						minWidth='150px'
						maxWidth='150px'
						height='50px'
						onClick={onCancel}
					/>
				</StyledTwoButtonWrapper>
			</StyledForgotPassword>
		)
	}
}
