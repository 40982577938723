import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanDashboard, fetchMyInvestments } from '../../actions/investors.actions';
import { useTranslation } from 'react-i18next';
import { closeModal, openModal } from '../../actions/modals.actions';
import { CHART_MODAL } from '../../constants/modals';
import AgGridChart from '../shared/ag-grid/chart/ag-grid-chart';
import {
	byWhatNumberShouldNavigatorShow,
	exportAsPNG,
	limitNameLength
} from '../../components/helpers/ag-chart.helper';
import ChartModal from '../../containers/investments/shared/chartmodal/chartmodal';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import GridPanelRightButtons from '../shared/ag-grid/panel/grid-panel-right-buttons';
import Text from '../shared/text/text';
import localeFormatNumber from '../../modules/format-number';
import { NUMBER_FORMAT_PERCENTAGE } from '/shared/constants';
import { MUItheme } from '../../../MUItheme';

export default function MyInvestmentsChart() {
	const chartRefPanel = useRef(null);
	const chartRefModal = useRef(null);

	const [chartData, setChartData] = useState();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const chartOption = 'MyInvestmentsChart';

	const isChartModalOpen = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'options']) === chartOption;
	});

	const myInvestments = useSelector((state) => {
		return state.investors.getIn(['dashboard', 'myInvestments']);
	});

	useEffect(() => {
		dispatch(fetchMyInvestments());

		return () => {
			dispatch(cleanDashboard());
		}
	}, []);

	useEffect(() => {
		if (myInvestments) {
			parseData(myInvestments);
		}
	}, [myInvestments]);

	const parseData = (investments) => {
		const _chartData = [];
		const companyWithInvestment = [];
		investments?.forEach((investment) => {
			if (!investment.has('details') || investment.getIn(['details', 'numOfTotalShares']) === 0) {
				return;
			}
			const details = investment.get('details');
			const rootDetails = investment.get('rootDetails');
			let ownershipPercentage = details.get('ownershipPercentage');
			if (rootDetails) {
				const rootPercentage = rootDetails.get('ownershipPercentage');
				ownershipPercentage = (ownershipPercentage / 100) * rootPercentage; //missing times 100 / 100
			}
			//Check to make sure company isn't in the list twice.
			const index = companyWithInvestment.findIndex((company) => {
				return company.id === investment.get('investedInCompanyId');
			});
			if (index >= 0) {
				companyWithInvestment[index].ownershipPercentage += ownershipPercentage;
			} else {
				companyWithInvestment.push({
					id: investment.get('investedInCompanyId'),
					name: investment.getIn(['investedInCompanyInformation', 'name'], 'N/A'),
					ownershipPercentage
				});
			}
		});

		companyWithInvestment.forEach((company) => {
			_chartData.push({
				companyName: company.name,
				ownershipPercentage: company.ownershipPercentage
			});
		});
		setChartData(_chartData);
	};

	const series = [
		{
			type: 'column',
			xKey: 'companyName',
			yKey: 'ownershipPercentage',
			showInLegend: false,
			yName: undefined,
			stacked: true,
			strokeWidth: 0,
			tooltip: { renderer: formatValue },
			shadow: {
				xOffset: 3
			},
			highlightStyle: {
				item: {
					fill: MUItheme.palette.chart.complementary,
					strokeWidth: 0
				},
				series: {
					...(isChartModalOpen ? { dimOpacity: 1 } : { dimOpacity: 0.2 })
				}
			}
		}
	];

	const axes = [
		{
			type: 'category',
			position: 'bottom',
			label: {
				formatter: (params) => {
					if (!isChartModalOpen) {
						return limitNameLength(params.value, 18);
					} else {
						return params.value;
					}
				}
			}
		},
		{
			min: 0,
			max: 100,
			nice: true,
			tick: {
				count: 10
				// color: 'red',
				// size: 20,
				// width: 10
			},
			type: 'number',
			position: 'left',
			keys: ['ownershipPercentage']
		}
	];

	const themePalette = {
		palette: {
			fills: [MUItheme.palette.chart.primary, MUItheme.palette.chart.secondary],
			strokes: [MUItheme.palette.chart.primary, MUItheme.palette.chart.secondary]
		}
	};

	function formatValue(params) {
		if (params.xValue && params.yValue) {
			return {
				title: params.xValue,
				content: localeFormatNumber(params.yValue, NUMBER_FORMAT_PERCENTAGE)
			};
		}
		if (params.value === 0) {
			return '';
		} else {
			return params.value.toLocaleString().replace(/,(?=[^\s])/g, ' ');
		}
	}

	const openChartModal = () => {
		dispatch(openModal(CHART_MODAL, chartOption));
	};

	const onCloseChartModal = () => {
		dispatch(closeModal());
	};

	const renderAgChart = () => {
		return (
			<AgGridChart
				chartRef={isChartModalOpen ? chartRefModal : chartRefPanel}
				series={series}
				axes={axes}
				data={chartData}
				showNavigator={byWhatNumberShouldNavigatorShow(chartData, 100)}
				howManyToShow={0}
				themePalette={themePalette}
				singleFillColor
			/>
		);
	};	

	if (!chartData || chartData.length === 0) {
		return null;
	}

	return (
		<>
			{isChartModalOpen && (
				<ChartModal
					leftHeader={'service.user_dashboard.my_investments'}
					onCancel={onCloseChartModal}
					onExport={() => {
						exportAsPNG(chartRefModal, t('service.user_dashboard.my_investments'));
					}}
					isOpen={isChartModalOpen}>
					{renderAgChart()}
				</ChartModal>
			)}

			<Card>
				<CardHeader
					title={<Text tid={'service.user_dashboard.my_investments'} />}
					action={
						<GridPanelRightButtons
							chart
							noFilter={true}
							exportAsExcel={() => {
								exportAsPNG(chartRefPanel, t('service.user_dashboard.my_investments'));
							}}
							downloadExcelTid={'dashboard.my_investments_chart.dropdown_item.download_chart'}
							openGridModal={openChartModal}
						/>
					}
				/>
				<CardContent variant='panel'>{renderAgChart()}</CardContent>
			</Card>
		</>
	);
}
