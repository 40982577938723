import { fromJS } from 'immutable';
import req from '../modules/request.module';

export function fetchOwnerReport(investmentId, investedInCompanyId, sortByProp, sortOrder, isCompany) {
	const w = window.open(`https://${window.location.hostname}/assets/build/misc/generating-pdf.html`, '_blank');

	return function () {
		return req
			.get(
				`/shares/pdf/owner-report/investment/${investmentId}?companyId=${investedInCompanyId}&sortByProp=${sortByProp}&sortOrder=${sortOrder}&isCompany=${isCompany}`
			)
			.then((response) => {
				const url = response.data;
				w.location = url;
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function fetchOwnerReportFromApp() {
	const w = window.open(`https://${window.location.hostname}/assets/build/misc/generating-pdf.html`, '_blank');
	return function () {
		return req
			.get(`/shares/pdf/owner-report/`)
			.then((response) => {
				const url = response.data;
				w.location = url;
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
