import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'
import { validatePersonNumber, validateOrgNumber } from '../../modules/validation.module'

import { removeInvestorInfoId } from '../../actions/investors.actions'
import { Margin } from 'styled-components-spacing'
import Box from '../../dumb-components/shared/layout/box/box'
import Text from '../../dumb-components/shared/text/text'
import Button from '../../dumb-components/shared/button/button'
import ConfirmContainer from '../shared/confirm.container'

class InvestorIdUnlockBtnContainer extends Component {
	state = {
		confirmRemoveIdIsOpen: false,
		confirmRemoveIdIsLoading: false
	}

	removeId = () => {
		this.setState({ confirmRemoveIdIsOpen: true })
	}

	doRemoveId = () => {
		const { selectedInvestor, removeInvestorInfoId, isCapitalIncurance } = this.props
		const selectedInvestmentId = selectedInvestor && selectedInvestor.get('id')

		if (!selectedInvestmentId) {
			return null
		}

		this.setState({ confirmRemoveIdIsLoading: true })

		removeInvestorInfoId(selectedInvestmentId, isCapitalIncurance, () => {
			this.resetStates()
		})
	}

	cancelRemoveId = () => {
		this.resetStates()
	}

	resetStates = () => {
		this.setState({
			confirmRemoveIdIsOpen: false,
			confirmRemoveIdIsLoading: false
		})
	}

	renderConfirmComponent = () => {
		const { confirmRemoveIdIsOpen, confirmRemoveIdIsLoading } = this.state

		return (
			<ConfirmContainer
				isOpen={confirmRemoveIdIsOpen}
				isLoading={confirmRemoveIdIsLoading}
				title='shares.shareholders.confirm_modal_title'
				question='shareholders.confirm_modal.id_removal.question'
				infoTid='shareholders.confirm_modal.id_removal.info'
				onConfirm={this.doRemoveId}
				onDecline={this.cancelRemoveId}
			/>
		)
	}

	render = () => {
		const { selectedInvestor, isCapitalIncurance, willNeverLink } = this.props

		if (!selectedInvestor) {
			return null
		}

		const isLinked = selectedInvestor.get('investorId') ? true : false
		let id
		if (isCapitalIncurance) {
			id = selectedInvestor.getIn(['captialIncuranceOwnerInformation', 'id'])
		} else {
			id = selectedInvestor.getIn(['investorInformation', 'id'])
		}

		const hasSwedishId = validatePersonNumber(id) || validateOrgNumber(id)

		if ((isLinked && !willNeverLink) || !hasSwedishId) {
			return null
		}

		return (
			<>
				<Box>
					<Button mode='link' tid='shares_id_clear' checked={hasSwedishId} onClick={this.removeId} />
				</Box>

				{this.renderConfirmComponent()}
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		selectedInvestor: store.investors.get('selectedInvestor')
	}
}

const mapActionsToProps = {
	removeInvestorInfoId
}

export default connect(mapStoreToProps, mapActionsToProps)(InvestorIdUnlockBtnContainer)
