import React from 'react'
import styled from 'styled-components'
import { bool } from 'prop-types'

const StyledContent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: ${(props) => props.theme.spacing['3']};
	padding-bottom: 0;
`

const StyledContentHeader = styled.div`
	display: flex;
	height: ${(props) => (props.tightenHeader ? 'auto' : '74px')};
`

const StyledContentBody = styled.div`
	display: flex;
	flex: 1;
`

const Content = ({ children, header, tightenHeader }) => {
	return (
		<StyledContent>
			{header && <StyledContentHeader tightenHeader={tightenHeader}>{header()}</StyledContentHeader>}

			<StyledContentBody>{children}</StyledContentBody>
		</StyledContent>
	)
}

Content.defaultProps = {
	tightenHeader: false
}

Content.propTypes = {
	tightenHeader: bool
}

export default Content
