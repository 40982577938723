import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, object } from 'prop-types'

import Toolbar from '../../../../dumb-components/shared/toolbar/toolbar'
import { ButtonTransparentIcon } from '../../../../dumb-components/shared/button'
import DropdownIconItem from '../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import DropdownMenuContainer from '../../../shared/dropdown-menu.container'
import Tooltip from '../../../../dumb-components/shared/tooltip/tooltip'
import DropdownItem from '../../../../dumb-components/shared/dropdown-item/dropdown-item'

// Handle attendee components
import RollcallStatusMessage from '../../../../dumb-components/meetings/toolbar/rollcall-status-message'
import AddAttendees from '../general/dropdown-items/add-attendee.container'
import Rollcall from '../general/dropdown-items/rollcall-button.container'

// Handle feedbackee components
import RequestFeedbackContainer from './dropdown-items/request-feedback.container'

// Handle meeting state components
import PreviewProtocolContainer from './dropdown-items/preview-protocol.container'
import PublishWithEsign from '../general/dropdown-items/publish-with-esign.container'
import PublishWithoutEsign from '../../protocol/publish-protocol-component.container'

// More actions components
import MoveMeeting from '../general/dropdown-items/move-meeting.container'
import Archive from '../general/dropdown-items/archive.container'
import Delete from '../general/dropdown-items/delete-meeting.container'
import Recover from '../general/dropdown-items/recover.container'

class ProtocolToolbarContainer extends Component {
	static propTypes = {
		params: object,
		location: object,
		basePath: string
	}

	toggleAttendeeDropdown = () => {
		this.attendeeDropdownRef?.onToggleMenu()
	}

	toggleFeedbackDropdown = () => {
		this.feedbackDropdownRef?.onToggleMenu()
	}

	togglePublishDropdown = () => {
		this.publishDropdownRef?.onToggleMenu()
	}

	renderRollcallStatusMessage = () => {
		const { rollcallDone } = this.props
		return <RollcallStatusMessage rollcallDone={rollcallDone} />
	}

	renderAttendeeDropdown = () => {
		const {
			rollcallDone,
			hasExtendedRights,
			basePath,
			location: { pathname }
		} = this.props
		const redirectToAttendees = !pathname.includes('/attendees') && `${basePath}/attendees`

		return (
			<DropdownMenuContainer
				ref={(ref) => (this.attendeeDropdownRef = ref)}
				halignMenu='right'
				withPortal
				renderRaw={
					<ButtonTransparentIcon
						icon='faUserCircle'
						notificationBadge={hasExtendedRights}
						notificationBadgeAnimate={rollcallDone ? false : true}
						notificationBadgeColor={rollcallDone ? 'green' : 'persianRed'}
						onClick={this.toggleAttendeeDropdown}
					/>
				}>
				<AddAttendees
					redirectTo={redirectToAttendees}
					renderComponent={({ openAttendeesModal, TOOLTIP_STATES, activeState, disabled }) => (
						<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
							<DropdownIconItem
								icon='faUserPlus'
								tid='meetings.general.toolbar.dropdown.add_attendee'
								disabled={disabled}
								onClick={() => {
									this.toggleAttendeeDropdown()
									openAttendeesModal()
								}}
							/>
						</Tooltip>
					)}
				/>

				<Rollcall dropdownRef={this.attendeeDropdownRef} />
			</DropdownMenuContainer>
		)
	}

	renderFeedbackDropdown = () => {
		const {
			basePath,
			location: { pathname }
		} = this.props
		const redirectToFeedback = !pathname.includes('/feedback') && `${basePath}/feedback`

		return (
			<DropdownMenuContainer
				btnMode='transparent-icon'
				btnIcon='faCommentLines'
				btnIconType='solid'
				halignMenu='right'
				withPortal
				ref={(ref) => (this.feedbackDropdownRef = ref)}>
				<RequestFeedbackContainer
					redirectTo={redirectToFeedback}
					renderComponent={({ openFeedbackeesModal, tooltip: { TOOLTIP_STATES, activeState, isDisabled } }) => (
						<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
							<DropdownIconItem
								icon='faPaperPlane'
								tid='meeting.smart.protocol_toolbar.request_feedback'
								disabled={isDisabled}
								onClick={() => {
									this.toggleFeedbackDropdown()
									openFeedbackeesModal()
								}}
							/>
						</Tooltip>
					)}
				/>
			</DropdownMenuContainer>
		)
	}

	renderStateDropdown = () => {
		const {
			basePath,
			location: { pathname }
		} = this.props
		const redirectToPublish = !pathname.includes('/sign-off') && `${basePath}/sign-off`

		return (
			<DropdownMenuContainer
				btnMode='transparent-icon'
				btnIcon='faCloudUpload'
				halignMenu='right'
				withPortal
				noMaxWidth={true}
				ref={(ref) => (this.publishDropdownRef = ref)}>
				<PreviewProtocolContainer />

				<PublishWithEsign
					redirectTo={redirectToPublish}
					renderComponent={({ openSigneesModal, tooltip: { TOOLTIP_STATES, activeState, isDisabled } }) => (
						<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
							<DropdownIconItem
								icon='faArrowFromBottom'
								tid='meetings.general.toolbar.dropdown.publish_with_esign'
								disabled={isDisabled}
								onClick={() => {
									this.togglePublishDropdown()
									openSigneesModal()
								}}
							/>
						</Tooltip>
					)}
				/>

				<PublishWithoutEsign
					redirectTo={redirectToPublish}
					renderComponent={({ TOOLTIP_STATES, isDisabled, activeState, openModal }) => (
						<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
							<DropdownIconItem
								icon='faSignature'
								tid='meetings.general.toolbar.dropdown.publish_without_esign'
								disabled={isDisabled}
								onClick={() => {
									this.togglePublishDropdown()
									openModal()
								}}
							/>
						</Tooltip>
					)}
				/>
			</DropdownMenuContainer>
		)
	}

	renderMoreActionsDropdown = () => {
		const { basePath, location, meetingIsDeleted } = this.props
		const commonProps = {
			dropdownRef: this.moreActionDropdownRef
		}

		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisH'
				halignMenu='right'
				btnMode='transparent-icon'
				ref={(ref) => (this.moreActionDropdownRef = ref)}
				withPortal
				tooltipActiveState='btnMoreActions'>
				<MoveMeeting {...commonProps} />

				<Archive {...commonProps} />

				<DropdownItem divider />

				{meetingIsDeleted ? (
					<Recover {...commonProps} basePath={basePath} location={location} />
				) : (
					<Delete {...commonProps} basePath={basePath.split('/meetings')[0] + '/meetings'} location={location} />
				)}
			</DropdownMenuContainer>
		)
	}

	render = () => {
		const { meetingId } = this.props

		if (!meetingId) {
			return <Toolbar />
		}

		return (
			<Toolbar>
				{this.renderRollcallStatusMessage()}
				{this.renderAttendeeDropdown()}
				{this.renderFeedbackDropdown()}
				{this.renderStateDropdown()}
				{this.renderMoreActionsDropdown()}
			</Toolbar>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		rollcallDone: store.meetings.getIn(['meeting', 'metadata', 'rollcallDone'], false),
		meetingIsDeleted: store.meetings.getIn(['meeting', 'isDeleted']),
		hasExtendedRights: store.meetings.getIn(['meeting', 'computedValues', 'hasExtendedRights']),
		meetingId: store.meetings.getIn(['meeting', 'id'])
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(ProtocolToolbarContainer)
