import React, { PureComponent } from 'react'
import { func, string, bool } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import styled from 'styled-components'
import Input from '../../shared/input/input'
import CountrySelect from '../../shared/country-select/country-select'
import SynaSearch from '../../../components/helpers/syna-search'

const StyledContainer = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	flex-direction: row;
`

const StyledWrapper = styled.div`
	width: 33%;
`

const StyledSynaSearchContainer = styled.div`
	width: ${(props) => (props.stretch ? '100%' : '66%')};
`

export default class CountrySearch extends PureComponent {
	static propTypes = {
		country: string,
		name: string,
		orgNumber: string,
		onSelectCompany: func,
		onChange: func,
		onBlur: func,
		hideCountrySelect: bool
	}

	render = () => {
		const { country, name, orgNumber, onChange, onBlur, onSelectCompany, hideCountrySelect } = this.props

		return (
			<StyledContainer>
				{hideCountrySelect && (
					<StyledWrapper>
						<CountrySelect language='en' fieldName='country' value={country} onChange={onChange} />
					</StyledWrapper>
				)}
				{country === 'SE' && (
					<StyledSynaSearchContainer stretch={hideCountrySelect}>
						<SynaSearch onChange={onSelectCompany} />
					</StyledSynaSearchContainer>
				)}
				{country !== 'SE' && (
					<>
						<StyledWrapper>
							<Input
								fieldName='name'
								value={name}
								onChange={onChange}
								placeholderTid='company name' // can not get this to work with a tid
								onBlur={onBlur}
							/>
						</StyledWrapper>
						<StyledWrapper>
							<Input
								fieldName='orgNumber'
								value={orgNumber}
								onChange={onChange}
								placeholderTid='org. number' // can not get this to work with a tid
								onBlur={onBlur}
							/>
						</StyledWrapper>
					</>
				)}
			</StyledContainer>
		)
	}
}
