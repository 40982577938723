import React, { PureComponent } from 'react'
import { string, bool, oneOf, number, func, object, oneOfType } from 'prop-types'
import styled, { css } from 'styled-components'
import Modal from './modal'
import { SM, MD, LG, XL } from '../../../constants/modals'

const StyledMasterWrapper = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
`

const StyledStepsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	position: absolute;
	height: 100%;
	width: ${(props) => props.totalSteps * props.stepWidth}px;

	${(props) =>
		!props.transformless &&
		css`
			transform: translate3d(-${(props) => props.step * props.stepWidth}px, 0, 0);

			${(props) =>
				props.mounted &&
				css`
					transition: transform 0.6s cubic-bezier(0, 0.52, 0, 1);
				`}
		`}
`

const StyledStep = styled.div`
	width: 100%;
	height: 100%;
`

export default class StepsModal extends PureComponent {
	state = {
		stepWidth: 0,
		mounted: false
	}

	innerModalRef = null

	static propTypes = {
		// steps: array,
		isOpen: bool,
		title: string,
		step: number,
		hSize: oneOf(['sm', 'md', 'lg', 'xl']).isRequired,
		vSize: oneOf([25, 50, 75, 100]),
		onNext: func,
		onPrevious: func,
		noHeaderRightPadding: bool,
		headerRightRenderer: oneOfType([object, func]),
		/**
		 * Transformles - was implemented due to react-beautiful-dnd not supporting parents
		 * with transform CSS property. This would result in items being teleported
		 * out of the screen on drag start when using react-beautiful-dnd inside this component.
		 */
		transformless: bool,
		titleValues: object
	}

	static defaultProps = {
		steps: [],
		step: 0,
		hSize: 'md',
		vSize: 100
	}

	componentDidMount = () => {
		window.addEventListener('resize', this.handleResize, false)

		this.setState({ stepWidth: this.getModalPreferedWidth() })
		this.setStepMaxWidth()

		// Remove initial side-scrolling animation in case
		// the initial slide is somewhere in the middle of all
		// the steps.
		setTimeout(() => {
			this.setState({ mounted: true })
		}, 500)
	}

	componentDidUpdate = (prevProps) => {
		const { step, onNext, onPrevious, isOpen } = this.props

		if (isOpen && isOpen !== prevProps.isOpen) {
			this.setStepMaxWidth()
		}

		if (prevProps.step < step) {
			onNext && onNext(step)
		}

		if (prevProps.step > step) {
			onPrevious && onPrevious(step)
		}
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.handleResize, false)
	}

	handleResize = () => {
		clearTimeout(this.resizeTimer)

		this.resizeTimer = setTimeout(() => {
			this.setStepMaxWidth()
		}, 50)
	}

	setStepMaxWidth = () => {
		setTimeout(() => {
			this.doSetStepMaxWidth()
		}, 1)
	}

	doSetStepMaxWidth = () => {
		if (!this.innerModalRef) {
			return
		}

		this.innerModalRef.classList.add('forcenotransition')

		const { stepWidth } = this.state

		// - Margins, 32px each side
		const availableWidth = window.innerWidth - 64

		const modalPreferedWidth = this.getModalPreferedWidth()

		if (modalPreferedWidth > availableWidth) {
			this.setState({ stepWidth: availableWidth })
		} else if (stepWidth < modalPreferedWidth) {
			this.setState({ stepWidth: modalPreferedWidth })
		}
	}

	getModalPreferedWidth = () => {
		const { hSize } = this.props
		let modalPreferedWidth = SM

		if (hSize === 'md') {
			modalPreferedWidth = MD
		} else if (hSize === 'lg') {
			modalPreferedWidth = LG
		} else if (hSize === 'xl') {
			modalPreferedWidth = XL
		}

		return modalPreferedWidth
	}

	setInnerModalRef = (ref) => {
		this.innerModalRef = ref
	}

	renderStep = (data, index) => {
		return <StyledStep key={index}>{data.body}</StyledStep>
	}

	renderFooter = () => {
		const { steps, step } = this.props

		if (!steps[step] || !steps[step].footer || !steps[step].footer.component) {
			return null
		}

		return steps[step].footer.component
	}

	renderHeader = () => {
		const { steps, step } = this.props

		if (!steps[step]?.header?.component) {
			return null
		}

		return steps[step].header.component
	}

	renderFooterLeftComponent = () => {
		const { steps, step } = this.props

		if (!steps[step] || !steps[step].footer || !steps[step].footer.leftComponent) {
			return null
		}

		return steps[step].footer.leftComponent
	}

	render = () => {
		const { stepWidth } = this.state
		const {
			hSize,
			vSize,
			title,
			isOpen,
			steps,
			step,
			noHeaderRightPadding,
			headerRightRenderer,
			transformless,
			titleValues
		} = this.props

		if (!isOpen) {
			return null
		}

		const stepsWrapperProps = {
			totalSteps: steps.length,
			mounted: this.state.mounted,
			transformless,
			stepWidth,
			step
		}

		return (
			<Modal
				hSize={hSize}
				vSize={vSize}
				title={title}
				titleValues={titleValues}
				isOpen={true}
				noBodyMargin={true}
				scrollableContent={false}
				noHeaderRightPadding={noHeaderRightPadding}
				headerRightRenderer={headerRightRenderer}
				headerComponent={this.renderHeader()}
				footerComponent={this.renderFooter()}
				footerLeftComponent={this.renderFooterLeftComponent()}>
				<StyledMasterWrapper ref={this.setInnerModalRef}>
					<StyledStepsWrapper {...stepsWrapperProps}>{steps.map(this.renderStep)}</StyledStepsWrapper>
				</StyledMasterWrapper>
			</Modal>
		)
	}
}
