import React, { PureComponent } from 'react'
import { string, object, bool } from 'prop-types'
import styled from 'styled-components'
import Text from '../../text/text'

const StyledOption = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
`

const StyledOptionLabel = styled.div`
	display: flex;
	flex: 1;
	margin-right: ${(props) => props.theme.spacing[2]};
`

export default class PinableSelectOption extends PureComponent {
	static propTypes = {
		option: object,
		defaultValue: string,
		labelIsTid: bool
	}

	static defaultProps = {
		labelIsTid: true
	}

	render = () => {
		const { defaultValue, option, labelIsTid } = this.props

		return (
			<StyledOption>
				<StyledOptionLabel>
					{labelIsTid !== false && (
						<Text
							tid={option.label}
							color={option.isDisabled ? 'muted' : undefined}
							bold={option.type === 'role' ? 700 : undefined}
						/>
					)}
					{!labelIsTid && (
						<Text color={option.isDisabled ? 'muted' : undefined} bold={option.type === 'role' ? 700 : undefined}>
							{option.label}
						</Text>
					)}
				</StyledOptionLabel>
				{defaultValue === option.value && <Text color='muted' tid='object_filter.pinable.default' fontSize='11px' />}
			</StyledOption>
		)
	}
}
