import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, string, bool, oneOf } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import { List, Map } from 'immutable'

import moment from '../../modules/moment.module'
import { downloadDocument, downloadDocumentPublic, fetchLatestOrderIndex } from '../../actions/documents.actions'
import {
	fetchAttachedDocuments,
	reorderDocuments,
	createAttachedDocument,
	createAttachedDocumentPublic,
	fetchAttachedDocumentsPublic
} from '../../actions/attachments.actions'
import { addErrorNotification, notifyAttachmentIsUploading } from '../../actions/notify.actions'
import Attachments from '../../dumb-components/shared/attachments/attachments-v2'
import Dropzone from '../../dumb-components/shared/dropzone/dropzone'
import {
	EVENT_TYPE_ATTACHED_DOCUMENT_CREATE,
	EVENT_TYPE_ATTACHED_DOCUMENT_DELETE,
	EVENT_TYPE_ATTACHED_DOCUMENT_LINKED,
	EVENT_TYPE_ATTACHED_DOCUMENT_UPDATE,
	ATTACHMENTS_DISPLAY_STATUS_DURING_AFTER
} from '/shared/constants'
import LinkDocumentsContainer from '../documents/link-documents-modal/link-documents.container'
import ManageAttachmentModalContainer from '../meetings/meeting/standard/manage-attachment-modal.container'

class AttachmentsByObjIdContainer extends Component {
	state = {
		isUploading: false,
		isLinkDocumentsModalOpen: false
	}

	static propTypes = {
		nakedStyle: bool,
		readOnly: bool,
		isDragDisabled: bool,
		renderAttachmentDropdown: func,
		multiple: bool,
		mode: oneOf(['PUBLIC-UNSAFE']),
		panelType: oneOf(['inline']),
		objId: string.isRequired,
		objType: string.isRequired,
		userId: string,
		invisible: bool,
		setDropzoneRef: func,
		onDocumentsLoaded: func,
		onBeforeUpload: func,
		getFileIconDotData: func,
		descriptionTid: string,
		oneFileLimitControlsTooltipTid: string,
		onLinkDocument: func,
		onUnlinkDocument: func,
		hideLinkDocument: bool,
		onFilterAttachments: func,
		linkDocumentsDisabled: bool,
		linkDocumentsDisabledTooltipTid: string
	}

	static defaultProps = {
		multiple: true,
		isDragDisabled: false,
		descriptionTid: 'attachments.generic.description.add_one_or_multiple_files',
		oneFileLimitControlsTooltipTid: 'attachments.multiple.one_file_limit.controls.one_attachment.tooltip'
	}

	componentDidMount = () => {
		const { objId } = this.props
		objId && this.loadFiles(objId)
	}

	componentDidUpdate = (prevProps) => {
		const { objId } = this.props

		if (prevProps.objId !== objId) {
			this.loadFiles(objId)
		}

		this.checkLiveUpdateEvents()
	}

	checkLiveUpdateEvents = () => {
		const { audit, objId } = this.props
		const attachmentCreated = audit && audit.get(EVENT_TYPE_ATTACHED_DOCUMENT_CREATE, Map())
		const attachmentDeleted = audit && audit.get(EVENT_TYPE_ATTACHED_DOCUMENT_DELETE, Map())
		const attachmentLinked = audit && audit.get(EVENT_TYPE_ATTACHED_DOCUMENT_LINKED, Map())
		const attachmentUpdated = audit?.get(EVENT_TYPE_ATTACHED_DOCUMENT_UPDATE, Map())

		if (
			attachmentCreated &&
			attachmentCreated.get('r') === true &&
			objId === attachmentCreated.getIn(['metadata', 'objId'])
		) {
			this.loadFiles(objId)
		}

		if (
			attachmentDeleted &&
			attachmentDeleted.get('r') === true &&
			objId === attachmentDeleted.getIn(['metadata', 'objId'])
		) {
			this.loadFiles(objId)
		}

		if (attachmentLinked?.get('r') === true && attachmentLinked?.getIn(['metadata', 'objId']) === objId) {
			this.loadFiles(objId)
		}

		if (attachmentUpdated?.get('r') === true && attachmentUpdated?.getIn(['metadata', 'objId']) === objId) {
			this.loadFiles(objId)
		}
	}

	loadFiles = (objId) => {
		const {
			fetchAttachedDocuments,
			fetchAttachedDocumentsPublic,
			company,
			mode,
			onDocumentsLoaded,
			onFilterAttachments
		} = this.props

		const callback = (docs) => {
			onDocumentsLoaded && onDocumentsLoaded(docs)
		}

		if (mode === 'PUBLIC-UNSAFE') {
			fetchAttachedDocumentsPublic(objId, company.id, callback, onFilterAttachments)
			return
		}

		fetchAttachedDocuments(objId, callback, onFilterAttachments)
	}

	reorder = (fileId, sourceIndex, destinationIndex) => {
		const { reorderDocuments, objType, objId, attachments } = this.props

		reorderDocuments(attachments, sourceIndex, destinationIndex, objType, objId)
	}

	onDragEnd = (result) => {
		const { draggableId, source, destination } = result

		if (!destination) {
			return
		}

		this.reorder(draggableId, source.index, destination.index)
	}

	upload = (filesToUpload, fileRejections) => {
		const {
			createAttachedDocument,
			createAttachedDocumentPublic,
			onUpload,
			objId,
			objType,
			mode,
			company,
			fetchLatestOrderIndex,
			addErrorNotification,
			notifyAttachmentIsUploading,
			userId,
			onBeforeUpload,
			attachments
		} = this.props

		const callback = (response) => {
			if (response.get('hasError') === true) {
				this.setState(() => {
					return { isUploading: false }
				})
				return
			}

			onUpload &&
				onUpload({
					documentId: response.get('id'),
					document: response,
					filesToUpload
				})

			this.setState((prevState) => {
				return {
					isUploading: false
				}
			})

			notifyAttachmentIsUploading(false)
		}

		notifyAttachmentIsUploading(true)

		if (fileRejections && fileRejections.length > 0) {
			addErrorNotification({ tid: 'attachments.error.files_rejected' })
		}

		if (!filesToUpload || filesToUpload.length === 0) {
			return
		}

		this.setState({ isUploading: true })

		onBeforeUpload && onBeforeUpload(filesToUpload)

		let latestOrderIndex = 0
		const link =
			attachments &&
			attachments.size > 0 &&
			attachments
				.last()
				.get('links', List())
				.find((link) => link.get('objId') === objId && link.get('objType') === objType)
		if (link) {
			latestOrderIndex = link.get('orderIndex')
		}

		filesToUpload.forEach((file, index) => {
			const orderIndex = latestOrderIndex + 100 * (index + 1)
			const document = new FormData()
			const props = {
				file,
				lastModifiedDate: moment(file.lastModified).toISOString(),
				title: file.name,
				hideFromDocumentsSection: true,
				orderIndex
			}

			Object.keys(props).forEach((key) => {
				if (Array.isArray(props[key])) {
					for (let i = 0; i < props[key].length; i++) {
						document.append(key, props[key][i])
					}
				} else {
					document.append(key, props[key])
				}
			})

			if (mode === 'PUBLIC-UNSAFE') {
				createAttachedDocumentPublic(objType, objId, document, company.id, userId, callback)
				return
			}

			createAttachedDocument(objType, objId, document, callback)
		})
	}

	onOpenFile = ({ documentId }) => {
		const { downloadDocument, downloadDocumentPublic, mode, company, onOpenFile, userId } = this.props

		if (mode === 'PUBLIC-UNSAFE') {
			downloadDocumentPublic({ documentId, companyId: company.id, openInViewer: true, userId })
		} else {
			downloadDocument({ documentId, openInViewer: true })
		}
	}

	onDownloadFile = ({ documentId }) => {
		const { downloadDocument, downloadDocumentPublic, mode, company, onDownloadFile, userId } = this.props

		if (mode === 'PUBLIC-UNSAFE') {
			downloadDocumentPublic({ documentId, companyId: company.id, userId })
		} else {
			downloadDocument({ documentId })
		}
	}

	openLinkDocumentsModal = () => {
		this.setState({ isLinkDocumentsModalOpen: true })
	}

	closeLinkDocumentsModal = (fetchDocuments) => {
		const { objId } = this.props

		if (fetchDocuments) {
			this.loadFiles(objId)
		}

		this.setState({ isLinkDocumentsModalOpen: false })
	}

	renderAttachmentDropdown = (data) => {
		const { renderAttachmentDropdown, mode, objType, objId, readOnly } = this.props

		return (
			renderAttachmentDropdown &&
			renderAttachmentDropdown({
				mode,
				objType,
				objId,
				readOnly,
				...data
			})
		)
	}

	renderLinkDocumentsContainer = ({ tooltipProps, controlsDisabled }) => {
		const {
			objId,
			objType,
			multiple,
			onLinkDocument,
			onUnlinkDocument,
			linkDocumentsDisabled,
			linkDocumentsDisabledTooltipTid
		} = this.props

		return (
			<LinkDocumentsContainer
				tooltipProps={tooltipProps}
				controlsDisabled={controlsDisabled}
				linkDocumentsDisabled={linkDocumentsDisabled}
				linkDocumentsDisabledTooltipTid={linkDocumentsDisabledTooltipTid}
				onCloseModal={this.closeLinkDocumentsModal}
				objId={objId}
				objType={objType}
				multiple={multiple}
				onLinkDocument={onLinkDocument}
				onUnlinkDocument={onUnlinkDocument}
			/>
		)
	}

	render = () => {
		const {
			readOnly,
			invisible,
			nakedStyle,
			fileEnteredWindow,
			isDragDisabled,
			renderAttachmentDropdown,
			multiple,
			setDropzoneRef,
			getFileIconDotData,
			descriptionTid,
			oneFileLimitControlsTooltipTid,
			panelType,
			attachments,
			hideLinkDocument,
			objId,
			mode
		} = this.props
		const { isUploading } = this.state

		if (readOnly && (!attachments || attachments.size === 0)) {
			return null
		}

		if (invisible) {
			return (
				<Dropzone
					size='full-screen'
					multiple={multiple}
					onDrop={this.upload}
					dropzoneRef={setDropzoneRef}
					hidden={true}
				/>
			)
		}

		return (
			<>
				<Attachments
					key={objId}
					objId={objId}
					documents={attachments}
					nakedStyle={nakedStyle}
					fileEnteredWindow={fileEnteredWindow}
					readOnly={readOnly}
					isDragDisabled={isDragDisabled}
					isUploading={isUploading}
					multiple={multiple}
					onDrop={this.upload}
					onOpenFile={this.onOpenFile}
					onDragEnd={this.onDragEnd}
					onDownloadFile={this.onDownloadFile}
					onLinkFile={this.openLinkDocumentsModal}
					documentDropdownRenderer={this.renderAttachmentDropdown}
					getFileIconDotData={getFileIconDotData}
					descriptionTid={descriptionTid}
					oneFileLimitControlsTooltipTid={oneFileLimitControlsTooltipTid}
					panelType={panelType}
					renderLinkDocumentsContainer={this.renderLinkDocumentsContainer}
					hideLinkDocument={hideLinkDocument}
				/>

				{mode !== 'PUBLIC-UNSAFE' && <ManageAttachmentModalContainer objId={objId} />}
			</>
		)
	}
}

const mapStoreToProps = (store, ownProps) => {
	const userIdFromStore = store.user && store.user.getIn(['userObj', 'id'])
	return {
		company: store.company.company,
		audit: store && store.audit && store.audit.get('attachments'),
		userId: userIdFromStore || ownProps.userId,
		fileEnteredWindow: store.notify.fileEnteredWebsiteGlobal,
		attachments: store.attachments.getIn(['attachments', ownProps.objId])
	}
}

const mapActionsToProps = {
	createAttachedDocument,
	downloadDocument,
	fetchAttachedDocuments,
	createAttachedDocumentPublic,
	fetchAttachedDocumentsPublic,
	downloadDocumentPublic,
	fetchLatestOrderIndex,
	reorderDocuments,
	addErrorNotification,
	notifyAttachmentIsUploading
}

export default connect(mapStoreToProps, mapActionsToProps)(AttachmentsByObjIdContainer)
