import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import MUIDialog from '../../../../mui-components/dialog/mui-dialog'
import DealflowCriteria from '../../../../dumb-components/shared/dealflow/dealflow-criteria'
import { useDispatch, useSelector } from 'react-redux'
import { updateCompanyCriteria } from '../../../../actions/dealflow.actions'
import { fromJS, List, Map } from 'immutable'
import CRITERIA from '/shared/criteria'
import { Form, FormSpy } from 'react-final-form'
import { closeModal } from '../../../../actions/modals.actions'
import Button from '../../../../mui-components/button/button'
import { FormattedMessage } from 'react-intl'
import Tooltip from '../../../../mui-components/tooltip/tooltip'
import { resetSubmitFailed } from '../../criteriaFormMutators'
import { Typography } from '@mui/material'

export default function CriteriaAdministrationModal() {
	const [initialValues, setInitialValues] = useState()
	const [criteriaKeys, setCriteriaKeys] = useState(Object.keys(CRITERIA))
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const companyId = useSelector((state) => state.company.company.id)
	const companyCriteria = useSelector((state) => state.dealflow.companyCriteria)

	useEffect(() => {
		if (companyCriteria.size > 0) {
			let _initialValues = Map()
			const [...sectionKeys] = companyCriteria.keys()

			sectionKeys.forEach((section) => {
				companyCriteria.get(section).forEach((category) => {
					_initialValues = _initialValues.set(`${category}!${section}`, true)
				})
			})

			setInitialValues(_initialValues.toJS())
		}
	}, [])

	const onSubmit = (values) => {
		let sections = Map()
		const categories = Object.keys(values).filter((key) => values[key] === true)

		categories.forEach((category) => {
			const categorySplit = category.split('!')
			if (sections.get(categorySplit[1])) {
				let categoryList = sections.get(categorySplit[1])
				categoryList = categoryList.push(categorySplit[0])
				sections = sections.set(categorySplit[1], categoryList)
			} else {
				sections = sections.set(categorySplit[1], List([categorySplit[0]]))
			}
		})

		dispatch(
			updateCompanyCriteria(companyId, sections, () => {
				dispatch(closeModal())
			})
		)
	}

	const validate = (values) => {
		const errors = {}

		if (Object.keys(values).length < 1 || (!Object.values(values).includes(true) && !initialValues)) {
			errors.noCriterionChoosenTooltipTid = t('dealflow.criteria.administration.no_criterion_choosen')
		} else {
			const imCriteria = fromJS(CRITERIA)
			imCriteria.forEach((items, key) => {
				let counter = 0
				items.forEach((item) => {
					if (Object.keys(values).includes(item) && values[item] === true) {
						counter++
					}
				})

				if (counter > 2 && key !== 'SUB_INDUSTRIES') {
					errors[key] = t('dealflow.criteria.administration.errors.max_2_items')
				}

				if (counter > 4 && key === 'SUB_INDUSTRIES') {
					errors[key] = t('dealflow.criteria.administration.errors.max_4_items')
				}
			})
		}

		return errors
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={validate}
			subscription={{ submitting: true }}
			initialValues={initialValues}
			mutators={{ resetSubmitFailed }}
			render={(formProps) => {
				const { handleSubmit } = formProps
				return (
					<form onSubmit={handleSubmit}>
						<MUIDialog
							title={t('dealflow.criteria_modal.title')}
							isOpen={true}
							onClose={() => {
								dispatch(closeModal())
							}}
							onConfirm={handleSubmit}
							maxWidth={'xl'}
							confirmButtonTid={'save_and_close'}
							leftSideLinkValidation={
								<FormSpy subscription={{ errors: true }}>
									{({ errors }) => {
										if (Object.keys(errors).some((val) => criteriaKeys.includes(val))) {
											return (
												<Typography
													variant={'body2'}
													color={'error.main'}
													sx={{ display: 'flex', flex: 1 }}>
													{t(
														'dealflow.criteria.administration.errors.footer.max_items_exceeded'
													)}
												</Typography>
											)
										} else {
											return null
										}
									}}
								</FormSpy>
							}
							renderConfirmButton={() => {
								return (
									<FormSpy subscription={{ dirty: true, errors: true }}>
										{({ errors, dirty }) => (
											<Tooltip
												title={
													errors.noCriterionChoosenTooltipTid &&
													errors.noCriterionChoosenTooltipTid
												}
												hideTooltip={!errors.noCriterionChoosenTooltipTid}>
												<Button
													onClick={() => {
														handleSubmit()
													}}
													color='positive'
													disabled={
														errors.noCriterionChoosenTooltipTid && !initialValues
															? true
															: initialValues && !dirty
															? true
															: false
													}>
													<FormattedMessage
														id={
															'dealflow.criteria_modal.dialog_actions.more_options_dropdown.save_criteria_list'
														}
													/>
												</Button>
											</Tooltip>
										)}
									</FormSpy>
								)
							}}>
							<Grid container spacing={2}>
								<FormSpy subscription={{ errors: true }}>
									{({ errors }) => <DealflowCriteria errors={errors} data={{}} hideSelectAll />}
								</FormSpy>
							</Grid>
						</MUIDialog>
						<FormSpy
							subscription={{ submitFailed: true }}
							onChange={() => {
								const el = document.querySelector('.Mui-error')

								if (el) {
									const closestEl = el && el.closest('.MuiGrid-root.MuiGrid-item.MuiGrid-grid-md-12')
									closestEl.scrollIntoView({ behavior: 'smooth', block: 'start' })
								}
								formProps.form.mutators.resetSubmitFailed()
							}}
						/>
					</form>
				)
			}}
		/>
	)
}
