import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string } from 'prop-types'
import { List } from 'immutable'
import Moment from '../../../modules/moment.module'

import StartMeetingModal from '../../../dumb-components/meetings/start-meeting-modal/start-meeting-modal'
import StartMeetingContainer from '../agenda/start-meeting.container'

import { dismissBlock } from '../../../actions/user.actions'
const BLOCK_ID = '69519bf3-5e8c-4554-84da-dca563714f24'

class StartMeetingModalContainer extends Component {
	state = {
		isOpen: false,
		dismissPermanently: false,
		dismissSession: false
	}

	static propTypes = {
		agendaItemId: string
	}

	componentDidUpdate = (prevProps) => {
		const { isSecretary, meetingIsStarted, blockDismissed, startDatePassed, agendaItemId } = this.props
		const { dismissSession } = this.state

		// Dismissed for this session or dismissed permanently
		if (dismissSession || blockDismissed) {
			return
		}

		// Abort already here if user is not secretary
		// or meeting is already started
		if (!isSecretary || meetingIsStarted) {
			return
		}

		// Meeting start date not yet passed
		if (!startDatePassed) {
			return
		}

		// Different agenda item selected
		if (agendaItemId && prevProps.agendaItemId && agendaItemId !== prevProps.agendaItemId) {
			this.setState({ isOpen: true })
		}
	}

	onChange = (fieldName, value) => {
		this.setState({ [fieldName]: value })
	}

	close = () => {
		this.setState({ isOpen: false, dismissSession: true })
		this.dismissPermanentlyIfRequested()
	}

	dismissPermanentlyIfRequested = () => {
		const { dismissBlock } = this.props
		const { dismissPermanently } = this.state

		if (!dismissPermanently) {
			return
		}

		dismissBlock(BLOCK_ID)
	}

	renderStartMeetingButton = () => {
		return <StartMeetingContainer mode='button' btnType='primary' onClick={this.close} />
	}

	render = () => {
		const { isOpen, dismissPermanently } = this.state

		if (!isOpen) {
			return null
		}

		return (
			<StartMeetingModal
				onStart={this.onStartMeeting}
				onCancel={this.close}
				onChange={this.onChange}
				dismissPermanently={dismissPermanently}
				startMeetingComponent={this.renderStartMeetingButton()}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	const startDate = store.meetings.getIn(['meeting', 'startDate'])
	return {
		isSecretary: store.meetings.getIn(['meeting', 'computedValues', 'isSecretary']),
		meetingIsStarted: store.meetings.getIn(['meeting', 'computedValues', 'meetingIsStarted']),
		blockDismissed: store.user
			.getIn(['userObj', 'siteSettings', 'dismissedBlocks'], List())
			.some((b) => b === BLOCK_ID),
		startDatePassed: startDate && Moment(startDate) < Moment()
	}
}

const mapActionsToProps = {
	dismissBlock
}

export default connect(mapStoreToProps, mapActionsToProps)(StartMeetingModalContainer)
