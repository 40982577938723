import React, { PureComponent } from 'react'
import immutablePropTypes from 'react-immutable-proptypes'
import { func, string } from 'prop-types'
import { fromJS } from 'immutable'
import styled from 'styled-components'
import Select from '../../../shared/select/src/select'

const StyledFilter = styled.div`
	display: flex;
	width: 250px;
`

export default class PreviousMeetingFilter extends PureComponent {
	static propTypes = {
		onChange: func,
		value: string
	}

	render = () => {
		const { onChange, value } = this.props
		const options = fromJS([
			{ value: 7, label: 'meetings.previous_meeting.filter.label.7d' },
			{ value: 30, label: 'meetings.previous_meeting.filter.label.30d' },
			{ value: 90, label: 'meetings.previous_meeting.filter.label.90d' }
		])

		return (
			<StyledFilter>
				<Select
					onChange={onChange}
					options={options}
					value={value}
					placeholderTid='meetings.previous_meeting.filter.placeholder'
					isClearable
					labelIsTid
				/>
			</StyledFilter>
		)
	}
}
