import React, { PureComponent } from 'react'
import { string, bool, func, array } from 'prop-types'
import styled, { css } from 'styled-components'
import ReactAutosuggest from 'react-autosuggest'
import Input from './input'
import { StyledInputTemplate } from './input.template'
import Text from '../text/text'
import { withTranslation } from 'react-i18next'

const AutosuggestTemplate = css`
	& .react-autosuggest__container {
		position: relative;
	}

	& .react-autosuggest__input {
		${StyledInputTemplate};
	}

	& .react-autosuggest__input--focused {
		box-shadow: none;
	}

	& .react-autosuggest__input--open {
	}

	& .react-autosuggest__suggestions-container {
		display: none;
	}

	& .react-autosuggest__suggestions-container--open {
		display: block;
		position: absolute;
		top: auto;
		width: 100%;
		border: 1px solid #c3c3c3;
		background-color: #fff;
		font-weight: 300;
		z-index: 2;
		border-top: none;
	}

	& .react-autosuggest__suggestions-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	& .react-autosuggest__suggestion {
		cursor: pointer;
		padding: 8px 12px;
		line-height: 1.43;
		height: 34px;
	}

	& .react-autosuggest__suggestion--highlighted {
		background-color: ${(props) => props.theme.colors.solitudeLight};
	}
`

const StyledAutosuggestWrapper = styled.div`
	${AutosuggestTemplate};
`

/**
 * Console focus error issues: https://github.com/moroshko/react-autosuggest/issues/318
 */
class Autosuggest extends PureComponent {
	state = {
		suggestions: []
	}

	// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
	escapeRegexCharacters = (str) => {
		return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
	}

	getSuggestions = (value) => {
		const { suggestions } = this.props

		const escapedValue = this.escapeRegexCharacters(value.trim())

		if (escapedValue === '') {
			return []
		}

		return suggestions.filter((s) => {
			return s.toLowerCase().includes(value.toLowerCase()) && s.toLowerCase() !== value.toLowerCase()
		})
	}

	getSuggestionValue = (suggestion) => {
		return suggestion
	}

	renderSuggestion = (suggestion) => {
		return (
			<Text singleLine={true} maxRows={1}>
				{suggestion}
			</Text>
		)
	}

	onChange = (e, { newValue }) => {
		const { fieldName, onChange } = this.props

		if (fieldName) {
			onChange(fieldName, newValue)
			return
		}

		onChange(newValue)
	}

	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({
			suggestions: this.getSuggestions(value)
		})
	}

	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		})
	}

	renderInputComponent = (inputProps) => {
		const { isLoading, disabled, legacyMode, placeholderTid, t } = this.props
		const inputPlaceholder = placeholderTid ? t(placeholderTid) : undefined

		return (
			<Input
				{...inputProps}
				returnRawEvent={true}
				inputRef={inputProps.ref}
				ref={null}
				isLoading={isLoading}
				disabled={disabled}
				legacyMode={legacyMode}
				placeholder={inputPlaceholder}
			/>
		)
	}

	render() {
		const { suggestions } = this.state
		const { value, id, legacyMode } = this.props
		const inputProps = {
			value,
			onChange: this.onChange
		}

		return (
			<StyledAutosuggestWrapper legacyMode={legacyMode}>
				<ReactAutosuggest
					id={id}
					suggestions={suggestions}
					onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.onSuggestionsClearRequested}
					getSuggestionValue={this.getSuggestionValue}
					renderSuggestion={this.renderSuggestion}
					highlightFirstSuggestion={true}
					focusInputOnSuggestionClick={false}
					renderInputComponent={this.renderInputComponent}
					inputProps={inputProps}
				/>
			</StyledAutosuggestWrapper>
		)
	}
}

Autosuggest.defaultProps = {
	suggestions: [],
	isLoading: false,
	disabled: false,
	value: ''
}

Autosuggest.propTypes = {
	id: string,
	fieldName: string,
	suggestions: array,
	onChange: func,
	isLoading: bool,
	disabled: bool,
	value: string,
	legacyMode: bool,
	placeholderTid: string
}

export default withTranslation()(Autosuggest)
