import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { bool, func } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import { AVAIBLE_TASK_STATUS } from '../../../constants/tasks'
import moment from '../../../modules/moment.module'
import StyledList from '../../shared/list/list'
import ListItem from '../../shared/list-item/list-item'
import { StyledListHeaderTemplate } from '../../shared/list/list-header.template'
import Text from '../../shared/text/text'
import ProfileImage from '../../shared/profile-image/profile-image'
import ColorProgressSelect from '../../shared/color-progress-select/color-progress-select'

const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
`

const StyledListItem = styled(ListItem)`
	background: ${(props) => props.theme.colors.silver};
	padding: 0;
	align-items: center;
`

const CommonTemplate = css`
	display: flex;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
`

const StyledListImage = styled.div`
	${CommonTemplate}
	padding: 0;
	height: 100%;
	min-width: 50px;
	max-width: 50px;
`

const StyledListName = styled.div`
	${CommonTemplate}
	min-width: 18%;
	max-width: 18%;
`

const StyledListTitle = styled.div`
	${CommonTemplate}
	flex: 1;
	min-width: 0;
`

const StyledListProposal = styled.div`
	${CommonTemplate}
	min-width: 20%;
	max-width: 20%;
`

const StyledListDueDate = styled.div`
	${CommonTemplate}
	justify-content: flex-end;
	min-width: 15%;
	max-width: 15%;
`

const StyledListStatus = styled.div`
	${CommonTemplate}
	justify-content: flex-end;
	min-width: 15%;
	max-width: 15%;
`

export default class TaskDetailedList extends PureComponent {
	static propTypes = {
		tasks: immutablePropTypes.list,
		usersCache: immutablePropTypes.map,
		readOnly: bool,
		onChange: func,
		showProposalColumn: bool
	}

	static defaultProps = {
		showProposalColumn: false
	}

	onChange = (taskId, fieldName, val) => {
		const { onChange } = this.props
		onChange(taskId, fieldName, val)
	}

	renderHeader = () => {
		const { showProposalColumn } = this.props

		return (
			<StyledHeader>
				<StyledListImage />

				<StyledListName>
					<Text tid='tasks.assignee_name' bold={400} color='lightGrey' />
				</StyledListName>

				<StyledListTitle>
					<Text tid='tasks.task_title' bold={400} color='lightGrey' />
				</StyledListTitle>

				{showProposalColumn && (
					<StyledListProposal>
						<Text tid='meetings.protocol.tasks.proplsal_column_title' bold={400} color='lightGrey' />
					</StyledListProposal>
				)}

				<StyledListDueDate>
					<Text tid='tasks.due_date' bold={400} color='lightGrey' />
				</StyledListDueDate>

				<StyledListStatus>
					<Text tid='tasks.status' bold={400} color='lightGrey' />
				</StyledListStatus>
			</StyledHeader>
		)
	}

	renderTask = (task, index) => {
		const { usersCache, readOnly, showProposalColumn } = this.props
		const user = usersCache.get(task.get('assigne'))
		const profileImage = user ? user.getIn(['image', 'filename']) : undefined
		const userId = user ? user.get('id') : undefined
		const status = AVAIBLE_TASK_STATUS.find((obj) => obj.get('value') === task.get('status'))

		return (
			<StyledListItem key={index}>
				<StyledListImage>
					<ProfileImage
						image={profileImage ? `/api/users/public/images/${profileImage}-80x80?userId=${userId}` : null}
					/>
				</StyledListImage>

				<StyledListName>
					<Text singleLine>{user ? user.get('name') : '-'}</Text>
				</StyledListName>

				<StyledListTitle>
					<Text singleLine>{task.get('title')}</Text>
				</StyledListTitle>

				{showProposalColumn && (
					<StyledListProposal>
						<Text singleLine>{task.get('proposal', '-')}</Text>
					</StyledListProposal>
				)}

				<StyledListDueDate>
					<Text>{task.get('dueAt') ? moment(task.get('dueAt')).format('L') : '-'}</Text>
				</StyledListDueDate>

				<StyledListStatus>
					{readOnly && status && status.get('label') && <Text tid={status.get('label')} />}
					{!readOnly && (
						<ColorProgressSelect
							fieldName='status'
							placeholderTid='meetings.protocol_view.tasks.status.placeholder'
							value={status}
							options={AVAIBLE_TASK_STATUS}
							onChange={this.onChange.bind(this, task.get('id'))}
							labelIsTid
						/>
					)}
				</StyledListStatus>
			</StyledListItem>
		)
	}

	render = () => {
		const { tasks } = this.props

		return <StyledList header={this.renderHeader}>{tasks && tasks.map(this.renderTask)}</StyledList>
	}
}
