import React, { PureComponent } from 'react'
import immutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import styled from 'styled-components'
import { ButtonTransparentIcon } from '../../shared/button'
import Tooltip from '../tooltip/tooltip'

const StyledWrapper = styled.div`
	min-height: 36px;
	display: flex;
	flex: 1;
	justify-content: flex-end;
`

const StyledToolbar = styled.ul`
	margin: 0;
	padding: 0;
	display: inline-block;
	list-style: none;
`

const StyledToolbarItem = styled.li`
	margin: 0;
	padding: 0;
	display: inline-block;
	list-style: none;
`

class Toolbar extends PureComponent {
	static propTypes = {
		buttons: immutablePropTypes.list
	}

	static defaultProps = {
		buttons: List()
	}

	renderButton = (button) => {
		return (
			<ButtonTransparentIcon
				onClick={button.get('onClick')}
				icon={button.get('icon')}
				disabled={button.get('disabled')}
				size='sml'
			/>
		)
	}

	renderNavItem = (button, index) => {
		if (button.get('skip')) {
			return null
		}

		if (button.get('tooltip')) {
			const tooltip = button.get('tooltip')

			if (typeof tooltip === 'string') {
				return (
					<StyledToolbarItem key={index}>
						<Tooltip tid={tooltip}>{this.renderButton(button)}</Tooltip>
					</StyledToolbarItem>
				)
			} else {
				return (
					<StyledToolbarItem key={index}>
						<Tooltip states={tooltip.get('states').toJS()} activeState={tooltip.get('activeState')}>
							{this.renderButton(button)}
						</Tooltip>
					</StyledToolbarItem>
				)
			}
		}

		return <StyledToolbarItem key={index}>{this.renderButton(button)}</StyledToolbarItem>
	}

	renderButtons = () => {
		const { buttons } = this.props

		return <StyledToolbar>{buttons && buttons.map(this.renderNavItem)}</StyledToolbar>
	}

	render = () => {
		const { children } = this.props

		return (
			<StyledWrapper>
				{this.renderButtons()}
				{children}
			</StyledWrapper>
		)
	}
}

export default Toolbar
