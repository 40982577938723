import React from 'react'
import { node, oneOf, string, number, func, bool } from 'prop-types'
import styled, { css } from 'styled-components'
import Text from '../text/text'

const StyledBadge = styled.div`
	color: ${(props) => props.theme.colors[props.textColor]}; /* Needed because of ellipsis */
	text-align: ${(props) => props.textAlign};
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: ${(props) => props.maxWidth}px;
	width: 100%;

	> span {
		width: 100%;
	}
`

export const StyledBadgeLink = styled(StyledBadge)`
	max-width: none;
	display: none;
	text-align: right;
	direction: rtl;
	font-size: ${(props) => props.theme.fontSizes.default};
	font-weight: 600;
`

const StyledBadgeWrapper = styled.div`
	background: ${(props) => props.theme.colors[props.bgColor]};
	border-radius: ${(props) => props.height / 2}px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${(props) => props.height}px;
	min-width: ${(props) => props.minWidth}px;
	position: relative;

	${(props) =>
		!props.noTransition &&
		css`
			transition: all 0.5s ease;
		`}

	${(props) =>
		!props.isStatic &&
		css`
			position: absolute;
			right: ${(props) => props.theme.spacing[3]};
		`}

	${(props) => {
		return (
			props.linkText &&
			!props.isStatic &&
			css`
				:hover {
					width: calc(100% - (${(props) => props.theme.spacing[3]} * 2));

					> ${StyledBadge} {
						display: none;
					}

					> ${StyledBadgeLink} {
						height: 100%;
						display: flex;
						align-items: center;
					}
				}
			`
		)
	}}
`

const Badge = (props) => {
	const { isStatic, className } = props

	const wrapperProps = {
		bgColor: props.bgColor,
		height: props.height,
		minWidth: props.minWidth,
		isStatic: props.isStatic,
		linkText: props.linkText,
		noTransition: props.noTransition
	}

	const badgeProps = {
		textColor: props.textColor,
		textAlign: props.textAlign,
		maxWidth: props.maxWidth
	}

	return (
		<StyledBadgeWrapper {...wrapperProps} className={className}>
			<StyledBadge {...badgeProps}>
				{props.tid ? (
					<Text tid={props.tid} color={props.textColor} nowrap align={props.textAlign} />
				) : (
					<Text color={props.textColor} nowrap align={props.textAlign}>
						{props.children}
					</Text>
				)}
			</StyledBadge>
			{props.linkText && !isStatic && <StyledBadgeLink onClick={props.onClick}>{props.linkText}</StyledBadgeLink>}
		</StyledBadgeWrapper>
	)
}

const avaibleColors = [
	'java',
	'black',
	'white',
	'lightGrey',
	'midGrey',
	'darkGrey',
	'solitudeLight',
	'solitudeMid',
	'solitudeDark',
	'purpleLight',
	'purpleMid',
	'yellowLight',
	'yellowMid',
	'catalinaBlue',
	'persianRed',
	'limeade',
	'gamboge',
	'red',
	'green',
	'orange',
	'active',
	'dodgerBlue',
	'lightestGrey',
	'muted',
	'disabled'
]

Badge.defaultProps = {
	bgColor: 'solitudeLight',
	height: 32,
	textColor: 'midGrey',
	textAlign: 'center',
	maxWidth: 128,
	minWidth: 76,
	isStatic: false,
	noTransition: false
}

Badge.propTypes = {
	bgColor: oneOf(avaibleColors),
	children: node,
	height: number,
	maxWidth: number,
	minWidth: number,
	textColor: oneOf(avaibleColors),
	textAlign: oneOf(['right', 'left', 'center']),
	tid: string,
	linkText: string,
	onClick: func,
	isStatic: bool,
	noTransition: bool
}

export default Badge
