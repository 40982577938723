import { fromJS } from 'immutable'

import {
	DOCUMENT_SETTINGS_LIST_SECTIONS,
	DOCUMENT_SETTINGS_LIST_RESOURCES,
	DOCUMENT_SETTINGS_GET_SECTION,
	DOCUMENTS_SETTINGS_ERROR,
	COMPANY_RESET_REDUCER
} from '../actions/types'

/** The initial state object for company. */
const INITIAL_STATE = fromJS({
	resources: [],
	sections: [],
	section: {},
	error: null
})

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case DOCUMENT_SETTINGS_LIST_RESOURCES:
			return state.set('resources', action.payload)
		case DOCUMENT_SETTINGS_LIST_SECTIONS:
			return state.set('sections', action.payload)
		case DOCUMENT_SETTINGS_GET_SECTION:
			let section = action.payload
			return state.set('section', section)
		case DOCUMENTS_SETTINGS_ERROR:
			return state.set('error', action.payload)
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}
