import { Map } from 'immutable'

export const makePersonAndAppendToPeopleList = (people, rowData, forceToBeInvestor = false) => {
	const { id, isInvestor, isCompanyMember, investmentId, name, email, investorTypeOfOwner } = rowData
	if (isInvestor && (!isCompanyMember || forceToBeInvestor)) {
		people = people.set(
			id,
			Map({
				userId: id,
				name,
				email,
				investmentId,
				isInvestor,
				investorTypeOfOwner
			})
		)
	} else {
		people = people.set(id, Map({ userId: id, name, email, investmentId }))
	}

	return people
}
