/**
 * Menu reducer
 * @module reducers/menu.reducer
 */

import { MENU_FETCH, COMPANY_RESET_REDUCER } from '../actions/types'

/** The initial state object for menu. */
const INITIAL_STATE = {
	companyResources: []
}

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case MENU_FETCH:
			return { ...state, ...action.payload }
		case COMPANY_RESET_REDUCER: {
			return { ...state, companyResources: [] }
		}
		default:
			return state
	}
}
