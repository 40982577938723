import React, { Component } from 'react'
import { string, bool, number } from 'prop-types'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import { FormattedMessage } from 'react-intl'

import { openModal } from '../../../actions/modals.actions'
import { IMAGE_CROP_MODAL } from '../../../constants/modals'
import ImageCropModal from './image-crop-modal'

class ImageCrop extends Component {
	state = {
		imageIsLoading: false,
		image: ''
	}

	static propTypes = {
		imageSuffix: string.isRequired,
		isWide: bool.isRequired,
		noSelection: bool,
		aspectRatio: number.isRequired,
		imageOrginalSuffix: string,
		uploadDesc: string.isRequired,
		spinnerWrapperClass: string
	}

	static defaultProps = {
		noSelection: false,
		isWide: false,
		imageOrginalSuffix: '-org',
		spinnerWrapperClass: '',
		imageSuffix: ''
	}

	onImageDrop = (filesToUpload, err) => {
		const image = filesToUpload[0]

		if (err.length > 0) {
			return
		}

		this.openImageCropModal(image)
		this.setState({ image })
	}

	openImageCropModal = (image, crop) => {
		const { noSelection, aspectRatio, openModal, userId } = this.props

		openModal(IMAGE_CROP_MODAL, {
			image,
			crop,
			noSelection,
			aspectRatio,
			onCrop: (val) => {
				this.props.onChange(val)
				this.setState({ imageIsLoading: true })
			},
			onImageDoneLoading: () => {
				this.imageDoneLoading()
			},
			userId
		})
	}

	editImage = () => {
		const { crop, currentImageUrl, imageOrginalSuffix } = this.props
		const { imageIsLoading } = this.state
		const orginalImagePath = currentImageUrl + imageOrginalSuffix

		if (imageIsLoading) {
			return
		}

		this.setState({ imageIsLoading: true })
		this.openImageCropModal(orginalImagePath, crop)
	}

	removeImage = () => {
		this.props.onDelete()
		this.setState({ image: null })
	}

	imageDoneLoading = () => {
		this.setState({ imageIsLoading: false })
	}

	buildQueryStr = () => {
		const { companyId, userId } = this.props

		let queryStr = ''
		if (companyId) {
			queryStr = `?companyId=${companyId}`
		}
		if (userId) {
			queryStr = `?userId=${userId}`
		}

		return queryStr
	}

	render = () => {
		const { imageIsLoading } = this.state
		const { currentImageUrl, isWide, uploadDesc, spinnerWrapperClass, imageSuffix } = this.props

		const queryStr = this.buildQueryStr()

		let viewCss = 'imagecrop__view'

		if (isWide) {
			viewCss += ' ' + viewCss + '--fill'
		}

		return (
			<>
				<div className='imagecrop'>
					<div className={viewCss}>
						{currentImageUrl && (
							<img
								src={currentImageUrl + imageSuffix + queryStr}
								onLoad={this.imageDoneLoading}
								style={currentImageUrl && !imageIsLoading ? { display: 'block' } : { display: 'none' }}
							/>
						)}

						{imageIsLoading && (
							<div className={spinnerWrapperClass}>
								<span className='fa fa-spinner fa-spin fa-2x' />
							</div>
						)}

						{!currentImageUrl && !imageIsLoading && (
							<Dropzone
								className='dropzone'
								name={'files'}
								multiple={false}
								accept={'image/jpeg,image/pjpeg,image/gif,image/png'}
								activeClassName='dropzone--active'
								onDrop={(filesToUpload, err) => this.onImageDrop(filesToUpload, err)}>
								<div className='dropzone__box'>
									<div className='dropzone__box--icon'>
										<span className='fa fa-cloud-upload icon-2x' />
									</div>
									<div className='dropzone__box--description'>
										<span className='dz-text'>
											<FormattedMessage id={uploadDesc} />
										</span>
									</div>
									<div className='dropzone__box--formats'>
										<p className='text-sm text--muted'>
											<em>
												<FormattedMessage id='accepted_filetypes_images' />
											</em>
										</p>
									</div>
								</div>
							</Dropzone>
						)}
					</div>

					<div className='imagecrop__controls'>
						{currentImageUrl && (
							<div className='imagecrop__controls__buttons'>
								<button
									className='btn btn-primary imagecrop__controls__button'
									onClick={this.editImage}
									disabled={imageIsLoading}>
									<FormattedMessage id='generic.edit' />
								</button>
								<button
									className='btn btn-default imagecrop__controls__button'
									onClick={this.removeImage}
									disabled={imageIsLoading}>
									<FormattedMessage id='generic.remove' />
								</button>
							</div>
						)}
					</div>
				</div>

				<ImageCropModal />
			</>
		)
	}
}

function mapStateToProps(state) {
	return {}
}

const mapActionsToProps = {
	openModal
}

export default connect(mapStateToProps, mapActionsToProps)(ImageCrop)
