import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { string, array, func, bool, number, oneOfType, object } from 'prop-types'
import TabTemplate from './src/tab.template'

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	${(props) =>
		props.flex &&
		css`
			flex: 1;
		`}
`

const StyledTabsWrapper = styled.ul`
	display: flex;
	flex-direction: row;
	padding: 0;
	margin-top: ${(props) => props.theme.spacing[props.tabsMarginTop]};
	margin-right: ${(props) => props.theme.spacing[props.tabsMarginRight]};
	margin-bottom: ${(props) => props.theme.spacing[props.tabsMarginBottom]};
	margin-left: ${(props) => props.theme.spacing[props.tabsMarginLeft]};

	${(props) =>
		props.investmentsTabs &&
		css`
			flex-wrap: wrap;
			gap: ${(props) => props.theme.spacing[3]};
			margin: 0;
		`}
`

const StyledComponentWrapper = styled.div`
	flex: 1;
`

export default class Tabs extends PureComponent {
	static propTypes = {
		tabs: array,
		match: string,
		activeTab: string,
		onChange: func,
		basePath: string,
		querystr: string,
		onSetRef: oneOfType([func, object]),
		flex: bool,
		tabsMarginTop: number,
		tabsMarginRight: number,
		tabsMarginBottom: number,
		tabsMarginLeft: number,
		hiddenTabs: object,
		investmentsTabs: bool
	}

	static defaultProps = {
		tabs: [],
		flex: true,
		tabsMarginTop: 0,
		tabsMarginRight: 0,
		tabsMarginBottom: 0,
		tabsMarginLeft: 0
	}

	renderComponent = () => {
		const { tabs, activeTab } = this.props
		const activeTabObject =
			tabs &&
			Object.values(tabs)
				.filter((t) => t.path === activeTab)
				.pop()
		return activeTabObject ? activeTabObject.renderComponent() : null
	}

	renderTabs = (obj, key) => {
		const { match, basePath, activeTab, onChange, invertedColors, querystr, hiddenTabs, investmentsTabs } = this.props
		const { path, defaultActive } = obj
		let active = defaultActive || false
		const url = `${basePath}/${path}${querystr ? querystr : ''}`
		const isHidden = hiddenTabs && hiddenTabs[path] ? true : false
		const offset = isHidden && hiddenTabs[path].offset

		if ((activeTab && activeTab === path) || match === path) {
			active = true
		}

		// Investment tabs visibility
		if (obj.notVisible && !investmentsTabs) {
			return null
		}

		return (
			<TabTemplate
				investmentsTabs={investmentsTabs}
				active={active}
				key={key}
				url={url}
				invertedColors={invertedColors}
				activeTab={activeTab}
				onChange={onChange}
				isHidden={isHidden}
				offset={offset ? offset : undefined}
				{...obj}
			/>
		)
	}

	render = () => {
		const { tabs, activeTab, onSetRef, flex, tabsMarginTop, tabsMarginRight, tabsMarginBottom, tabsMarginLeft, investmentsTabs } = this.props
		const tabsMarginProps = { tabsMarginTop, tabsMarginRight, tabsMarginBottom, tabsMarginLeft }

		let ref
		if (onSetRef) {
			ref = typeof onSetRef === 'function' ? (r) => onSetRef(r) : onSetRef
		}

		return (
			<StyledWrapper flex={flex}>

				<StyledTabsWrapper investmentsTabs={investmentsTabs} ref={ref} {...tabsMarginProps} >
					{tabs && Object.values(tabs).map(this.renderTabs)}
				</StyledTabsWrapper>

				{activeTab && <StyledComponentWrapper>{this.renderComponent()}</StyledComponentWrapper>}
			</StyledWrapper>
		)
	}
}
