import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { map } from 'react-immutable-proptypes'

import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'

import { removeDocumentFromCollection } from '../../../actions/collections.actions'

class RemoveDocumentDropdownItemContainer extends Component {
	static propTypes = {
		document: map.isRequired,
		onClick: func
	}

	handleRemoveDocumentFromCollection = () => {
		const { selectedCollectionId, document, onClick, removeDocumentFromCollection } = this.props
		const documentId = document.get('id')
		removeDocumentFromCollection(selectedCollectionId, documentId)
		onClick && onClick(document)
	}

	render = () => {
		return (
			<DropdownIconItem
				tid='collections.modal.documents.dropdown.remove'
				icon='faTrashAlt'
				onClick={this.handleRemoveDocumentFromCollection}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		selectedCollectionId: store.collections.getIn(['selectedCollection', 'id'])
	}
}

const mapActionsToProps = {
	removeDocumentFromCollection
}

export default connect(mapStoreToProps, mapActionsToProps)(RemoveDocumentDropdownItemContainer)
