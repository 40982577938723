import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, number, string } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import { Margin } from 'styled-components-spacing'
import Alert from '../../../dumb-components/shared/alert/alert'
import Text from '../../../dumb-components/shared/text/text'

class AlertContainer extends Component {
	static propTypes = {
		meetingIsLoaded: bool,
		secretary: string,
		numOfAttendees: number
	}

	render() {
		const { meetingIsLoaded, secretary, numOfAttendees } = this.props

		if (!meetingIsLoaded || numOfAttendees === 0 || secretary) {
			return null
		}

		return (
			<Margin top={3}>
				<Alert mode='warning' icon='faExclamationTriangle' iconType='solid'>
					<Text color='white' tid='meetings.attendees.alert.no_secretary_selected' />
				</Alert>
			</Margin>
		)
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(AlertContainer)
