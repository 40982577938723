import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { listDocumentsByCompanies, downloadDocumentPublic } from '../../actions/documents.actions'
import { openModal } from '../../actions/modals.actions'
import DocumentsListByCompanies from '../../dumb-components/documents/documents-list-by-companies/documents-list-by-companies'
import moment from '../../modules/moment.module'
import documentHelper from '../../components/helpers/documents.helper'
import { DOCUMENT_DOWNLOAD_SIGNED_MODAL } from '../../constants/modals'
import { EVENT_TYPE_DOCUMENT_CREATE } from '/shared/constants'

class DocumentsListByCompaniesContainer extends Component {
	state = {
		daysBack: 7
	}

	componentDidMount = () => {
		this.getDocumentsByDate()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { user } = this.props
		const { daysBack } = this.state

		if (prevProps.user !== user) {
			this.getDocumentsByDate()
		}

		if (prevState.daysBack !== daysBack) {
			this.getDocumentsByDate()
		}

		this.checkLiveUpdates()
	}

	checkLiveUpdates = () => {
		const { audit } = this.props
		const documentCreated = audit.get(EVENT_TYPE_DOCUMENT_CREATE, Map())

		if (documentCreated.get('r') === true) {
			this.getDocumentsByDate()
		}
	}

	getDocumentsByDate = () => {
		const { listDocumentsByCompanies } = this.props
		listDocumentsByCompanies({
			createdAt: { $gte: moment().subtract(this.state.daysBack, 'days').startOf('day').toISOString() }
		})
	}

	onFilter = (days) => {
		this.setState({ daysBack: days })
	}

	openDoc = (doc) => {
		this.doDownloadOrOpenDoc(doc, 'VIEWER')
	}

	downloadDoc = (doc) => {
		this.doDownloadOrOpenDoc(doc, 'DOWNLOAD')
	}

	doDownloadOrOpenDoc = (doc, modalMode) => {
		const { downloadDocumentPublic, openModal, user } = this.props
		const isSigned = documentHelper.getDocumentIsSigned(doc)
		const userId = user.get('id')

		if (isSigned) {
			openModal(DOCUMENT_DOWNLOAD_SIGNED_MODAL, { mode: modalMode, document: doc })
			return
		}

		downloadDocumentPublic({
			documentId: doc.get('id'),
			openInViewer: modalMode === 'VIEWER',
			companyId: doc.getIn(['companyInformation', 'id']),
			userId
		})
	}

	render = () => {
		const { daysBack } = this.state
		const { documents } = this.props

		return (
			<div className='col-md-12'>
				<DocumentsListByCompanies
					documents={documents}
					openDoc={this.openDoc}
					onFilter={this.onFilter}
					selectedFilter={daysBack}
					downloadDoc={this.downloadDoc}
				/>
			</div>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		documents: store.documents.get('listByCompanies'),
		user: store.user.get('userObj'),
		audit: store.audit.get('documents')
	}
}

const mapActionsToProps = {
	listDocumentsByCompanies,
	downloadDocumentPublic,
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(DocumentsListByCompaniesContainer)
