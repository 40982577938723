/**
 * index.js
 * Initialization for React and the entire app.
 * @see {@link http://usejsdoc.org} for information about comments standard used.
 */

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router, Route } from './interfaces/router'
import history from './interfaces/history'
import ConnectedIntlProvider from './i18n/ConnectedIntlProvider'
import Cookies from 'universal-cookie'
import { getStore } from './store'
import { ThemeProvider } from 'styled-components'
import theme from '../theme'
import { ModalProvider } from 'styled-react-modal'
import StyledModalBackground from './dumb-components/shared/modal-background/modal-background'
import i18n from './i18n' // initialized i18next instance
import { I18nextProvider } from 'react-i18next'
import { setLanguage } from './actions/i18n.actions'
import ReactGA from 'react-ga4'
import { setAuthenticated } from './actions/credentials.actions'
import App from './components/app'
import { MUItheme } from '../MUItheme'
import { Settings } from 'luxon'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ConfirmProvider } from './containers/shared/confirm/confirm.provider'

const store = getStore()

// Polyfill Intl
if (!window.Intl) {
	window.Intl = require('intl')
}

// See if we have an JSON Web Token (then the user is logged in).
const accessToken = new Cookies().get('accessToken')
if (accessToken) {
	store.dispatch(setAuthenticated(true))
}

const userlanguage = new Cookies().get('userLanguage')
store.dispatch(setLanguage(userlanguage))
Settings.defaultZoneName = 'Europe/Stockholm'

// Google analytics
ReactGA.initialize('UA-99828571-2')
history.listen((location) => {
	ReactGA.send({ hitType: 'pageview', path: location.pathname + location.search })
})

// Start things up...
ReactDOM.render(
	<Provider store={store}>
		<ConnectedIntlProvider>
			<I18nextProvider i18n={i18n}>
				<MUIThemeProvider theme={MUItheme}>
					<ThemeProvider theme={theme}>
						<LocalizationProvider dateAdapter={AdapterLuxon} locale={'sv-SE'}>
							{/*<GlobalStyle>*/}
							<ModalProvider backgroundComponent={StyledModalBackground}>
								<ConfirmProvider>
									<Router history={history}>
										<App />
									</Router>
								</ConfirmProvider>
							</ModalProvider>
							{/*</GlobalStyle>*/}
						</LocalizationProvider>
					</ThemeProvider>
				</MUIThemeProvider>
			</I18nextProvider>
		</ConnectedIntlProvider>
	</Provider>,
	document.getElementById('app')
)

// Prevent droping files anywhere where you shouldn't
document.addEventListener('drop', (e) => {
	e.preventDefault()
})
document.addEventListener('dragover', (e) => {
	e.preventDefault()
})

/**
 * Hide/remove loading screen
 * @private
 */
function _removeLoadingScreen() {
	const loadingScreen = document.getElementById('loading-screen')

	if (loadingScreen.classList) {
		loadingScreen.classList.add('is-fading-out')
	} else {
		loadingScreen.className += ' is-fading-out'
	}
	setTimeout(() => {
		loadingScreen.remove()
	}, 350)
}
_removeLoadingScreen()
