import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import ScrollView from '../../../dumb-components/shared/layout/scroll-view/scroll-view'
import { Link } from 'react-router-dom'

import RepresentativesTable from './reps-table'
import Button from '../../../dumb-components/shared/button/button'
import BankIdModal from '../../../dumb-components/shared/bankid-modal/bankid-modal'

class AuthoriseCompany extends Component {
	goToNextStep() {
		this.props.jumpToStep(2)
	}

	onCancel() {
		this.props.jumpToStep(0)
		this.props.onCancel()
	}

	authorise = (ssn) => {
		this.props.onAuthorise(ssn)
	}

	onSkipAuthorise = () => {
		this.props.onSkipAuthorise((err) => {
			if (err) {
				return
			}
			this.props.jumpToStep(2)
		})
	}

	onVerifyToCustomer = () => {
		this.props.onSkipAuthorise((err) => {
			if (err) {
				return
			}
			this.props.jumpToStep(2)
		}, true)
	}

	render() {
		const {
			bankIdSignedBy,
			bankIdSigned,
			companyUrlAlias,
			selectedCompanyDetails,
			bankIdTransaltionId,
			companyRepresentatives,
			isInvonoUser
		} = this.props
		const { name, orgNumber } = selectedCompanyDetails
		const isOpen = bankIdTransaltionId && !bankIdSigned

		return (
			<div className='account-wizard__content account-wizard--animated account-wizard--fade-in-right'>
				<div className='account-wizard__header' style={{ height: 'auto' }}>
					<h2 className='account-wizard__title'>
						<span className='account-wizard__step'>2</span> <FormattedMessage id='account_wizard.authorise_account' />
					</h2>
					<div style={{ height: '96px' }}>
						<ScrollView autoHide>
							<div>
								<FormattedMessage values={{ name, orgNumber }} id='account_wizard.authorise_account_info' />
							</div>
						</ScrollView>
					</div>
				</div>
				<div className='account-wizard__body account-wizard__body--h275'>
					<div className='i-panel i-panel--white'>
						<div className='i-panel__body'>
							<ScrollView autoHeight alwaysShow={true} autoHeightMin={100} autoHeightMax={250}>
								<RepresentativesTable
									bankIdSigned={bankIdSigned}
									bankIdSignedBy={bankIdSignedBy}
									authorise={this.authorise}
									companyRepresentatives={companyRepresentatives}
								/>
							</ScrollView>

							<BankIdModal
								isOpen={isOpen}
								bankIdTransaltionId={bankIdTransaltionId}
								onCancel={this.onCancel.bind(this)}
							/>
						</div>
					</div>
				</div>
				<div className='account-wizard__footer'>
					{bankIdSigned && bankIdSignedBy && (
						<p className='text--md text--invono'>
							<FormattedMessage id='your_account_has_been_created' />
						</p>
					)}
					{!bankIdSigned && (
						<div>
							<button type='button' className='btn btn-account-wizard' onClick={this.onCancel.bind(this)}>
								<FormattedMessage id='generic.form.cancel' />
							</button>
							{isInvonoUser && (
								<>
									<button type='button' className='btn btn-account-wizard' onClick={this.onSkipAuthorise}>
										<FormattedMessage id='account_wizard.step_2.btn.verify_later' />
									</button>
									<button type='button' className='btn btn-account-wizard' onClick={this.onVerifyToCustomer}>
										<FormattedMessage id='account_wizard.step_2.btn.verify_to_customer' />
									</button>
								</>
							)}
						</div>
					)}
					{bankIdSigned && (
						<div>
							<button type='button' className='btn btn-account-wizard' onClick={this.goToNextStep.bind(this)}>
								<FormattedMessage id='wizard.company.step2.add_users_now' />
							</button>
							<Link to={'/' + companyUrlAlias}>
								<button type='button' className='btn btn-account-wizard'>
									<FormattedMessage id='wizard.company.step2.go_to_account' />
								</button>
							</Link>
						</div>
					)}
				</div>
			</div>
		)
	}
}

export default AuthoriseCompany
