import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func, bool } from 'prop-types'
import { Map, List } from 'immutable'
import { map } from 'react-immutable-proptypes'

import FolderShareControls from '../../dumb-components/documents/folder-share-controls/folder-share-controls'
import SynaSearchContainer from '../syna/search.container'
import { getCompanyIdFromOrgNr } from '../../actions/company.actions'
import FolderShareList from '../../dumb-components/documents/folder-share-list-companies/folder-share-list-companies'

import GoToBilling from '../shared/go-to-billing-component.container'
import Text from '../../dumb-components/shared/text/text'
import permissionsHelper from '../../components/helpers/permissions.helper'
import { SHARING_PERMISSION_READ_ONLY, SHARING_PERMISSION_OWN_DOCUMENTS_ACCESS } from '/shared/constants'

class ShareFolderControlsContainer extends Component {
	state = {
		searchedCompanyData: null,
		isLoading: false,
		sharedWithCompanies: Map()
	}

	static propTypes = {
		onChange: func,
		sharedWithCompanies: map,
		folderId: string,
		parentFolderId: string,
		listOnly: bool
	}

	static defaultProps = {
		sharedWithCompanies: Map()
	}

	synaSearchRef = null

	componentDidMount = () => {
		this.parseSharedWithCompanies()
	}

	componentDidUpdate = (prevProps) => {
		const { folder, folders, folderId } = this.props

		if (folders && folderId !== prevProps.folderId) {
			this.parseSharedWithCompanies()
		}

		if (folder !== prevProps.folder) {
			this.parseSharedWithCompanies()
		}
	}

	parseSharedWithCompanies = () => {
		const { folder, folders, folderId, mapParentToChildren, region, parentFolderId } = this.props
		let { sharedWithCompanies } = this.state

		sharedWithCompanies = folder?.get('sharedWithCompanies') || Map()
		let parent = parentFolderId || mapParentToChildren.findKey((children) => children.includes(folderId))
		while (parent) {
			const folder = folders.find((folder) => folder.get('id') === parent)
			if (folder.has('sharedWithCompanies')) {
				folder.get('sharedWithCompanies').forEach((data, companyId) => {
					data = data.set('parentFolderName', folder.getIn(['name', region.language.main]))
					data = data.set('disabled', true)
					sharedWithCompanies = sharedWithCompanies.set(companyId, data)
				})
			}

			parent = mapParentToChildren.findKey((children) => children.includes(parent))
		}

		this.setState({ sharedWithCompanies })
	}

	onChangeSynaSearch = (data) => {
		const { getCompanyIdFromOrgNr } = this.props
		const companyOrgNumber = data && data.getIn(['company', 'orgNumber'])

		if (!companyOrgNumber) {
			return
		}

		this.setState({ isLoading: true })

		getCompanyIdFromOrgNr(companyOrgNumber, (companyFromDatabase) => {
			const foundCompanyId = companyFromDatabase.get('id')
			// this.synaSearchRef.inputRef.onChange('') // Broken since update to version 5.2.2, works in version 2.3.0

			// No company found
			if (!foundCompanyId) {
				this.setState({ searchedCompanyData: data.get('company'), isLoading: false })
			} else {
				this.shareWithCompany(companyFromDatabase)
				this.setState({ searchedCompanyData: null, isLoading: false })
			}
		})
	}

	shareWithCompany = (company) => {
		const { onChange, sharedWithCompanies } = this.props
		const companyId = company.get('id')

		onChange(
			'sharedWithCompanies',
			sharedWithCompanies.set(
				companyId,
				Map({
					name: company.get('name'),
					sharingPermissions: SHARING_PERMISSION_READ_ONLY
				})
			)
		)
	}

	onChange = (companyId, field, val) => {
		const { onChange } = this.props
		let { sharedWithCompanies } = this.props

		sharedWithCompanies = sharedWithCompanies.setIn([companyId, field], val)
		onChange('sharedWithCompanies', sharedWithCompanies)
	}

	unshareCompany = (companyId) => {
		const { onChange, sharedWithCompanies } = this.props
		onChange('sharedWithCompanies', sharedWithCompanies.delete(companyId))
	}

	renderSynaSearch = () => {
		const { companiesAccessRights, folderSharingEnabled } = this.props
		const { isLoading } = this.state
		const isAdmin = permissionsHelper.getIsAdminForSelectedCompany(companiesAccessRights)

		return (
			<SynaSearchContainer
				onChange={this.onChangeSynaSearch}
				onSetRef={(r) => (this.synaSearchRef = r)}
				isLoading={isLoading}
				disabled={!isAdmin || !folderSharingEnabled}
				withPortal
			/>
		)
	}

	renderSubscriptionLinkComponent = () => {
		return (
			<GoToBilling
				renderComponent={(goToSubscription) => {
					return (
						<>
							<br />
							<Text
								tid='documents.folder.create_manage.sharing.alert.upgrade_link'
								onClick={goToSubscription}
								hasUnderline
							/>
						</>
					)
				}}
			/>
		)
	}

	render = () => {
		const { folderSharingEnabled, companiesAccessRights, listOnly } = this.props
		const { searchedCompanyData, sharedWithCompanies } = this.state
		const isAdmin = permissionsHelper.getIsAdminForSelectedCompany(companiesAccessRights)
		const permissionsOptions = List([
			Map({
				value: SHARING_PERMISSION_READ_ONLY,
				label: 'folder_sharing.permission.read_only'
			}),
			Map({
				value: SHARING_PERMISSION_OWN_DOCUMENTS_ACCESS,
				label: 'folder_sharing.permission.own_documents_access'
			})
		])

		return (
			<>
				{listOnly ? (
					<FolderShareList
						companyData={searchedCompanyData}
						sharedWithCompanies={sharedWithCompanies}
						onUnshareCompany={this.unshareCompany}
						readOnly={!isAdmin}
						permissionsOptions={permissionsOptions}
						onChange={this.onChange}
					/>
				) : (
					<FolderShareControls
						companyData={searchedCompanyData}
						sharedWithCompanies={sharedWithCompanies}
						synaSearchComponent={this.renderSynaSearch()}
						showSubscriptionMessage={!folderSharingEnabled}
						subscriptionLinkComponent={this.renderSubscriptionLinkComponent()}
					/>
				)}
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		sharedWithCompanies: store.folders.getIn(['currentFolder', 'sharedWithCompanies']),
		folderSharingEnabled: store.subscriptions.getIn(['helper', 'folderSharingEnabled']),
		folder: store.folders.get('currentFolder'),
		folders: store.folders.get('folders'),
		mapParentToChildren: store.folders.get('mapParentToChildren'),
		companiesAccessRights: store.company.companiesAccessRights,
		region: store.company.company.region
	}
}

const mapActionsToProps = {
	getCompanyIdFromOrgNr
}

export default connect(mapStoreToProps, mapActionsToProps)(ShareFolderControlsContainer)
