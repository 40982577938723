import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func } from 'prop-types'
import ImmutableProps from 'react-immutable-proptypes'
import { List, Map, fromJS } from 'immutable'
import { Margin } from 'styled-components-spacing'
import LineSeparator from '../../dumb-components/shared/line-separator/line-separator'
import debounce from 'lodash/debounce'
import folderHelper from '../../components/helpers/folder.helper'
import {
	listTasks,
	updateTaskListLocal,
	clearTask,
	createTask,
	saveTask,
	updateTaskLocal,
	reorderTask,
	createTaskLocal,
	fetchLatestOrderIndex,
	onSelectTask
} from '../../actions/tasks.actions'
import { addErrorNotification, addInfoNotification } from '../../actions/notify.actions'
import TaskList from '../../dumb-components/tasks/task-list/task-list'
import TaskCreatorContainer from './task-creator.container'
import { fetchSimpleUsers } from '../../actions/usersCache.actions'
import history from '../../interfaces/history'

import {
	LIVE_TASK_CREATE,
	LIVE_TASK_UPDATE,
	LIVE_TASK_DELETE,
	LIVE_TASK_TRANSFER,
	LIVE_PROJECT_UPDATE,
	LIVE_TASK_DELETE_MULTIPLE,
	LIVE_TASK_EXTERNAL_DELETE_MULTIPLE
} from '../../constants/live-update'

import { OBJ_TYPE_MEETING, OBJ_TYPE_DOCUMENT } from '/shared/constants'
import { withRouter } from '../../interfaces/router'

class TaskListContainer extends Component {
	static propTypes = {
		basePath: string,
		querystr: string,
		tasks: ImmutableProps.list,
		onTaskClick: func
	}

	static defaultProps = {
		tasks: List()
	}

	state = {
		taskInEditMode: null,
		tasksMetadata: Map(),
		tasksInProject: List(),
		tasksInSubProjects: List(),
		debouncers: Map()
	}

	componentDidMount = () => {
		const {
			listTasks,
			projectId,
			match: { params },
			clearTask
		} = this.props
		// Needed for the toolbar to dissapear if you leave the page with selected task
		// by ex. navigating to Tasks. First we need to check if task was "linked"
		// to somebody (href/url link)
		if (!params.id) {
			clearTask()
		}

		listTasks()
	}

	componentDidUpdate = (prevProps) => {
		const {
			listTasks,
			selectedProjectId,
			usersCache,
			match: { params },
			tasks,
			task,
			dateProp
		} = this.props

		this.checkLiveUpdateEvents()

		if (prevProps.match.params.id !== params.id) {
			params.id && this.onTaskClick(params.id)
		}

		if (prevProps.selectedProjectId !== selectedProjectId) {
			listTasks(selectedProjectId)
		}

		if (prevProps.usersCache !== usersCache) {
			this.parseMetadata()
		}

		if (prevProps.tasks !== tasks) {
			this.fetchUsers()
			this.parseTasks()
			this.parseMetadata()
		}

		if (task && prevProps.task !== task) {
			if (
				!prevProps.task ||
				prevProps.task.get('id') !== task.get('id') ||
				(dateProp &&
					dateProp.getIn(['values', 0]) &&
					prevProps.task.get(dateProp.getIn(['values', 0])) !== task.get(dateProp.getIn(['values', 0])))
			) {
				setTimeout(() => {
					const panelNode = document.getElementById(task.get('id'))
					const rect = panelNode && panelNode.getBoundingClientRect()
					//The 150 offset on top might need to be slightly adjusted, if things end up not refocusing sometimes try upping this value.
					const isElementInViewport =
						rect &&
						rect.top >= 150 &&
						rect.left >= 0 &&
						rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
						rect.right <= (window.innerWidth || document.documentElement.clientWidth)
					if (!isElementInViewport) {
						this.props.scrollbarRef.scrollTop(panelNode && panelNode.offsetTop)
					}
				}, 1000)
			}
		}
	}

	checkLiveUpdateEvents = () => {
		const { audit, selectedProjectId, listTasks, projectAudit } = this.props
		const taskCreate = audit.get(LIVE_TASK_CREATE)
		const taskUpdate = audit.get(LIVE_TASK_UPDATE)
		const taskDelete = audit.get(LIVE_TASK_DELETE)
		const taskTransfer = audit.get(LIVE_TASK_TRANSFER)
		const taskDeleteMultiple = audit.get(LIVE_TASK_DELETE_MULTIPLE, Map())
		const taskExternalDeleteMultiple = audit.get(LIVE_TASK_EXTERNAL_DELETE_MULTIPLE, Map())
		const PROJECT_UPDATE = projectAudit.get(LIVE_PROJECT_UPDATE)

		// Folder was changed, checked because of permission updates
		if (PROJECT_UPDATE && PROJECT_UPDATE.get('refresh') === true) {
			listTasks(selectedProjectId)
		}

		// Task was created, edited, transfered or deleted
		if (
			(taskCreate && taskCreate.get('refresh') === true) ||
			(taskUpdate && taskUpdate.get('refresh') === true) ||
			(taskDelete && taskDelete.get('refresh') === true) ||
			(taskTransfer && taskTransfer.get('refresh') === true)
		) {
			listTasks(selectedProjectId)
		}

		if (taskDeleteMultiple.get('refresh') === true) {
			listTasks(selectedProjectId)
		}

		if (taskExternalDeleteMultiple.get('refresh') === true) {
			listTasks(selectedProjectId)
		}
	}

	parseTasks() {
		const { projectId, tasks, projects, mapParentToChildren } = this.props

		const projectDescendents = []
		folderHelper.getDescendentsIds(projectDescendents, projectId, mapParentToChildren)

		if (!tasks) {
			this.setState({ tasksInProject: List(), tasksInSubProjects: List() })
			return null
		}

		let tasksInProject = tasks
		let tasksInSubProjects = List()

		/**
		 * No currentProjectId means that we are in ROOT folder
		 * If a document doesn't have an projectId attached,
		 it belongs to ROOT
		 */
		if (projectId) {
			/**
			 * We are not in ROOT as querystr exists
			 * Compare document projectId found on document with current querystr (currentProjectId)
			 */
			tasksInProject = tasks.filter((task) => {
				return task.get('projectId') === projectId
			})

			/**
			 * If document doesn't equal to querystr (currentProjectId), it belongs to another folder
			 */
			tasksInSubProjects = tasks.filter((obj) => {
				return (
					obj.get('projectId') !== null &&
					obj.get('projectId') !== '' &&
					obj.get('projectId') !== projects &&
					projectDescendents.indexOf(obj.get('projectId')) >= 0
				)
			})
		}

		this.setState({ tasksInProject, tasksInSubProjects })
	}

	parseMetadata() {
		const { tasks, projects, usersCache } = this.props
		let tasksMetadata = Map()
		let mapMeetingIdToTaskId = Map()
		let mapDocumentIdToTaskId = Map()
		if (tasks) {
			tasks.forEach((task) => {
				const metadata = {}
				const assignedTo = task.get('assigne')
				const taskId = task.get('id')
				const projectId = task.get('projectId')
				const createdBy = task.get('createdBy')

				metadata.projectPath = folderHelper.getPath(projectId, projects)

				if (projectId && projectId !== '') {
					metadata.projectName = projects && projects.getIn([projectId, 'name'])
				}

				const assignedToUser = usersCache.get(assignedTo)
				if (assignedTo && assignedToUser) {
					metadata.profileImage = assignedToUser.getIn(['image', 'filename'])
					metadata.assignedToUserId = assignedToUser.get('id')
				}

				const creator = usersCache.get(createdBy)
				if (creator) {
					metadata.creator = creator.get('name')
				}

				tasksMetadata = tasksMetadata.set(taskId, fromJS(metadata))
			})

			this.setState({ tasksMetadata })
		}
	}

	fetchUsers = () => {
		const { tasks, fetchSimpleUsers } = this.props
		let userIds = List()

		tasks &&
			tasks.forEach((task) => {
				const assignedTo = task.get('assigne')
				const createdBy = task.get('createdBy')

				if (assignedTo) {
					userIds = userIds.push(assignedTo)
				}

				if (createdBy) {
					userIds = userIds.push(assignedTo)
				}
			})
		userIds.size > 0 && fetchSimpleUsers(userIds)
	}

	updateTaskLocal = (task) => {
		const { updateTaskLocal } = this.props
		let { debouncers } = this.state
		updateTaskLocal(task)

		const taskId = task.get('id')
		let debouncer = debouncers.get(taskId)

		if (!debouncer) {
			debouncer = debounce((task) => {
				const { saveTask } = this.props
				saveTask(task)
			}, 1000)

			debouncers = debouncers.set(taskId, debouncer)
			this.setState({ debouncers })
		}

		debouncer(task)
	}

	onChange = (field, value) => {
		let { task } = this.props

		if (!field || !task) {
			return
		}

		task = task.set(field, value)

		this.updateTaskLocal(task)
	}

	onBadgeClick = (taskId, projectId) => {
		const { basePath, history } = this.props
		history.push({
			pathname: basePath + `/${taskId}`,
			search: `?project=${projectId}`
		})
	}

	onTaskClick = (taskId) => {
		const { location, basePath, tasks, onTaskClick, onSelectTask, history } = this.props
		const clickedItem = tasks && tasks.find((t) => t.get('id') === taskId)
		const canUpdateOrCreate = clickedItem && (clickedItem.get('ALLOW_UPDATE') || !clickedItem.has('ALLOW_UPDATE'))
		const querystr = location.search && location.search.replace('?focus=comments', '')

		onSelectTask(taskId)
		// clickedItem undefined upon pressing ENTER.
		if (canUpdateOrCreate === true || clickedItem === undefined) {
			this.setState({ taskInEditMode: taskId })
		}

		onTaskClick && onTaskClick()
		history.push({
			pathname: `${basePath}/${taskId}`,
			search: querystr
		})
	}

	onDragEnd = (result) => {
		const { reorderTask, tasks } = this.props
		const { draggableId, destination } = result

		this.onTaskClick(draggableId)
		const sourceTaskId = draggableId

		const list = this.state[destination.droppableId]

		if (!list) {
			return
		}

		const destinationTaskId = list.getIn([destination.index, 'id'])
		reorderTask(tasks, sourceTaskId, destinationTaskId)
	}

	getTasks = () => {
		const { tasks, filterBy } = this.props

		if (!tasks) {
			return null
		}

		// Return filtered tasks list
		const filteredTasks = this.filterTasks(tasks, filterBy)

		return filteredTasks
	}

	createTask = (insertAtIndex, title) => {
		const { createTask, projectId, createTaskLocal, fetchLatestOrderIndex } = this.props
		let task = Map({ title, projectId, orderIndex: insertAtIndex, description: '' })
		this.timeout && clearTimeout(this.timeout)

		if (insertAtIndex === null) {
			fetchLatestOrderIndex((orderIndex) => {
				task = task.set('orderIndex', orderIndex)
				createTaskLocal(orderIndex, task, (newTask) => {
					this.onTaskClick(newTask.get('id'))
					createTask(newTask)
				})
			})
		} else {
			createTaskLocal(insertAtIndex, task, (newTask) => {
				this.onTaskClick(newTask.get('id'))
				createTask(newTask)
			})
		}
	}

	showTaskCreator = (index, parentTaskProjectId) => {
		const { projectId, addErrorNotification, tasks, hasAppliedFilters } = this.props
		if (hasAppliedFilters) {
			addErrorNotification({
				tid: 'tasks.notification.disabled_by_filters'
			})
			return
		}

		if (projectId && projectId !== parentTaskProjectId) {
			return
		}
		const thisOrder = tasks.getIn([index, 'orderIndex'])
		let nextOrder = null
		if (index + 1 >= tasks.size) {
			this.createTask(null)
		} else {
			nextOrder = tasks.getIn([index + 1, 'orderIndex'])
			this.createTask((thisOrder + nextOrder) / 2)
		}
	}

	onNav = (index, direction) => {
		const { tasks } = this.props
		const newIndex = index + direction
		const task = tasks.get(newIndex)

		if (task) {
			this.onTaskClick(task.get('id'))
		}
	}

	resetTaskInEditMode = () => {
		this.timeout = setTimeout(() => {
			this.setState({ taskInEditMode: null })
		}, 500)
	}

	renderTaskCreator = (insertAtIndex) => {
		return <TaskCreatorContainer fieldName='task' onEnter={this.createTask.bind(this, insertAtIndex)} />
	}

	renderTaskEditor = (projectId, title, index, badge, readOnly) => {
		return (
			<TaskCreatorContainer
				fieldName='title'
				value={title}
				onEnter={this.showTaskCreator.bind(this, index, projectId)}
				onArrowUp={this.onNav.bind(this, index)}
				onArrowDown={this.onNav.bind(this, index)}
				onChange={this.onChange}
				onFocus={() => {
					this.timeout && clearTimeout(this.timeout)
				}}
				onBlur={this.resetTaskInEditMode}
				badge={badge}
				readOnly={readOnly}
				inline
				autofocus
			/>
		)
	}

	render = () => {
		const { basePath, projectId, hasAppliedFilters, location, projects, i18n, company } = this.props
		const { taskInEditMode, tasksMetadata, tasksInProject, tasksInSubProjects } = this.state
		const displaySubTasks = tasksInSubProjects.size > 0 && projectId
		const project = projects.get(projectId) && projects.get(projectId)
		const allowCreateTask = project ? project.get('ALLOW_CREATE_TASK') : true
		const showNewTaskButton =
			!hasAppliedFilters &&
			(!tasksInProject ||
				tasksInProject.size === 0 ||
				(taskInEditMode && tasksInProject.last().get('id') === taskInEditMode)) &&
			allowCreateTask

		return (
			<div>
				<TaskList
					tasks={tasksInProject}
					tasksMetadata={tasksMetadata}
					basePath={basePath}
					location={location}
					projectId={projectId}
					tasksName={'tasksInProject'}
					currentUrl={location.pathname + location.search}
					userLang={i18n.language}
					region={company.region}
					onBadgeClick={this.onBadgeClick}
					onTaskClick={this.onTaskClick}
					taskInEditMode={taskInEditMode}
					taskCreatorComponent={this.renderTaskCreator}
					taskEditorComponent={this.renderTaskEditor}
					onClickAddNewTask={this.createTask}
					showNewTaskButton={showNewTaskButton}
					isDragDisabled={hasAppliedFilters}
					onDragEnd={this.onDragEnd}
				/>

				{displaySubTasks && (
					<Margin vertical={5}>
						<LineSeparator tid='tasks.list.sub_category_tasks' bgColor='solitudeLight' />
					</Margin>
				)}

				{displaySubTasks && (
					<TaskList
						tasks={tasksInSubProjects}
						tasksMetadata={tasksMetadata}
						basePath={basePath}
						location={location}
						projectId={projectId}
						tasksName={'tasksInSubProjects'}
						currentUrl={location.pathname + location.search}
						userLang={i18n.language}
						region={company.region}
						onBadgeClick={this.onBadgeClick}
						onTaskClick={this.onTaskClick}
						taskInEditMode={taskInEditMode}
						taskCreatorComponent={this.renderTaskCreator}
						taskEditorComponent={this.renderTaskEditor}
						onClickAddNewTask={this.createTask}
						showNewTaskButton={false}
						isDragDisabled={hasAppliedFilters}
						onDragEnd={this.onDragEnd}
						neverShowNewTaskButton={true}
					/>
				)}
			</div>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		tasks: store.tasks.get('visibleTasks'),
		task: store.tasks.get('task'),
		usersCache: store.usersCache.get('usersCache'),
		projects: store.projects.get('projects'),
		selectedProjectId: store.projects.get('selectedProjectId'),
		mapParentToChildren: store.projects.get('mapParentToChildren'),
		i18n: store.i18n,
		company: store.company.company,
		hasAppliedFilters: store.tasks.get('hasAppliedFilters'),
		audit: store.audit.get('tasks'),
		projectAudit: store.audit.get('projects'),
		dateProp: store.tasks.get('filterBy', List()).find((obj) => obj.get('source') === 'dateProp')
	}
}

const mapActionsToProps = {
	listTasks,
	updateTaskListLocal,
	clearTask,
	createTask,
	saveTask,
	updateTaskLocal,
	reorderTask,
	createTaskLocal,
	addErrorNotification,
	addInfoNotification,
	fetchLatestOrderIndex,
	fetchSimpleUsers,
	onSelectTask
}

export default withRouter(connect(mapStoreToProps, mapActionsToProps)(TaskListContainer))
