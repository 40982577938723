import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { bool, func, string } from 'prop-types'
import backgroundImg from '../../assets/images/bg-bgs.jpg'
import InvonoLogo from '../../assets/images/logo-white.svg'
import ScrollView from '../shared/layout/scroll-view/scroll-view'
import OutdatedBrowserFullscreen from '../dmz/outdated-browser-fullscreen'

const StyledCredentials = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-image: url(${backgroundImg});
`

const StyledRightView = styled.div`
	width: 520px;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	padding: ${(props) => props.theme.spacing[5]};
	display: flex;
	flex-direction: column;

	@media (max-height: 750px) {
		padding-top: ${(props) => props.theme.spacing[2]};
		padding-bottom: ${(props) => props.theme.spacing[2]};
	}
`

const StyledTopComponent = styled.div`
	margin-top: ${(props) => props.theme.spacing[3]};

	@media (max-height: 750px) {
		margin-top: 0;
	}
`

const StyledMiddleComponent = styled.div`
	margin: 0 ${(props) => props.theme.spacing[6]};
	margin-top: ${(props) => props.theme.spacing[5]};
	height: 100%;
`

const StyledBottomComponent = styled.div`
	height: 180px;
	display: flex;
	flex: 1;
	align-items: flex-end;
	margin-top: ${(props) => props.theme.spacing[3]};
`

const StyledLeftView = styled.div`
	display: flex;
	flex: 1;
`

const StyledLeftTextWrapper = styled.div`
	justify-content: center;
	margin: 0 100px;
	display: flex;
	flex-direction: column;
`

const StyledInvonoLogo = styled.img`
	width: 300px;
	margin-bottom: 16px;
`

const StyledMiddleContent = styled.div`
	height: 400px;
	min-height: 100px;
`

const StyledSpacer = styled.div`
	display: flex;
	flex: 1;
`

export default class Credentials extends PureComponent {
	static propTypes = {
		section: string,
		isValidBrowser: bool,
		ignoreBrowserVersion: bool,
		onIgnoreBrowserVersion: func,
		renderTopComponent: func,
		renderMiddleComponent: func,
		renderBottomComponent: func
	}

	render = () => {
		const {
			section,
			isValidBrowser,
			ignoreBrowserVersion,
			onIgnoreBrowserVersion,
			renderTopComponent,
			renderMiddleComponent,
			renderBottomComponent
		} = this.props

		if (!isValidBrowser && !ignoreBrowserVersion) {
			return (
				<StyledCredentials>
					<OutdatedBrowserFullscreen onIgnoreBrowserVersion={onIgnoreBrowserVersion} />
				</StyledCredentials>
			)
		}

		return (
			<StyledCredentials>
				<StyledLeftView>
					<StyledLeftTextWrapper>
						<StyledInvonoLogo src={InvonoLogo} />
					</StyledLeftTextWrapper>
				</StyledLeftView>

				<StyledRightView>
					<StyledTopComponent>{renderTopComponent && renderTopComponent(section)}</StyledTopComponent>
					<StyledMiddleComponent>
						<ScrollView fillContent alwaysShowOnOverflow scrollbarColor='lightGrey'>
							<StyledMiddleContent>
								{renderMiddleComponent && renderMiddleComponent(section)}
								<StyledSpacer />
							</StyledMiddleContent>
						</ScrollView>
					</StyledMiddleComponent>
					<StyledBottomComponent>{renderBottomComponent && renderBottomComponent(section)}</StyledBottomComponent>
				</StyledRightView>
			</StyledCredentials>
		)
	}
}
