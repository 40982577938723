import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'

import DropdownMenuContainer from '../../../containers/shared/dropdown-menu.container'
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'

import { openModal } from '../../../actions/modals.actions'
import { INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL } from '../../../constants/modals'

class UsersRemindDropdownContainer extends Component {
	dropdownRef = null

	openUsersRemindModal = () => {
		this.dropdownRef && this.dropdownRef.onToggleMenu()
		this.props.openModal(INVITE_USERS_BY_EMAIL_TEMPLATE_MODAL)
	}

	getTooltipData = () => {
		const { users } = this.props
		const everyUserIsRegistered = !users.some((u) => u.notMember === true)

		if (everyUserIsRegistered) {
			return {
				isDisabled: true,
				tooltipTid: 'user_management.toolbar.remind.tooltip.no_unregistered_users'
			}
		}

		return {
			isDisabled: false,
			tooltipTid: ''
		}
	}

	render = () => {
		const { isDisabled, tooltipTid } = this.getTooltipData()

		return (
			<DropdownMenuContainer
				btnIcon='faPaperPlane'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				ref={(r) => (this.dropdownRef = r)}
				disabled={isDisabled}
				tooltipTid={tooltipTid}
				tooltipDelayShow='instant'
				noMaxWidth
				inline>
				<DropdownIconItem
					tid='user_management.toolbar.remind.item.remind_users'
					icon='faRepeat'
					onClick={this.openUsersRemindModal}
				/>
			</DropdownMenuContainer>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		users: store.company.userManagement.users
	}
}

const mapActionsToProps = {
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(UsersRemindDropdownContainer)
