import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string } from 'prop-types'
import { toggleMenu } from '../../../../components/helpers/refs.helper'

import Text from '../../../../dumb-components/shared/text/text'
import Button from '../../../../dumb-components/shared/button/button'
import AttendeesPanelContainer from '../general/attendees-panel.container'
import DropdownIconItem from '../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import ManageAttendeeModalContainer from '../../attendees/manage-attendee-modal.container'
import DropdownMenuContainer from '../../../shared/dropdown-menu.container'
import { OutlinedButton } from '../../../../dumb-components/shared/button-v2'
import AttendeesAlertContainer from '../../attendees/alert.container'

import { openModal } from '../../../../actions/modals.actions'
import { MEETINGS_ATTENDEE_SETTINGS_MODAL } from '../../../../constants/modals'
import { MEETING_TYPE_STANDARD } from '/shared/constants'

class AttendeesPanelStandardContainer extends Component {
	static propTypes = {
		scrollableTarget: string
	}

	state = {
		isEditing: false
	}

	dropdownRef = null

	toggleRightHeaderDropdown = () => {
		this.dropdownRef && this.dropdownRef.onToggleMenu()
	}

	setIsEditing = () => {
		this.toggleRightHeaderDropdown()
		this.setState({ isEditing: true })
	}

	finishEditing = () => {
		this.setState({ isEditing: false })
	}

	openAttendeeSettings = ({ userId, dropdownRef }) => {
		toggleMenu(dropdownRef)
		this.props.openModal(MEETINGS_ATTENDEE_SETTINGS_MODAL, { userId })
	}

	renderAttendeeSettingsItem = (userId, dropdownRef) => {
		return (
			<DropdownIconItem
				icon='faCogs'
				tid='meetings.standard.attendee.dropdown.settings'
				onClick={this.openAttendeeSettings.bind(null, { userId, dropdownRef })}
			/>
		)
	}

	renderSummaryRightHeader = () => {
		return (
			<DropdownMenuContainer
				halignMenu='right'
				ref={(ref) => (this.dropdownRef = ref)}
				onClick={this.toggleRightHeaderDropdown}
				renderRaw={<OutlinedButton icon='faEllipsisV' leftMargin />}>
				<DropdownIconItem
					icon='faCog'
					tid='meetings.standard.attendees.panel.dropdown.edit'
					onClick={this.setIsEditing}
				/>
			</DropdownMenuContainer>
		)
	}

	renderCustomInfoText = () => {
		const { isEditing } = this.state

		if (isEditing) {
			return <Text color='white' tid='meetings.standard.attendees.panel.info_text.change_attendees' />
		}

		return null
	}

	renderBottomComponent = () => {
		return (
			<Button mode='primary' tid='meetings.standard.attendees.panel.btn.finish_editing' onClick={this.finishEditing} />
		)
	}

	render = () => {
		const { isEditing } = this.state
		const { protocolSharedAt, isStandardMeeting, hasMeetingPassed, scrollableTarget } = this.props
		const showSummary = Boolean(protocolSharedAt) && !isEditing
		const disableWarning = (isStandardMeeting && hasMeetingPassed) || isEditing

		return (
			<>
				<AttendeesPanelContainer
					renderMoreActionItem={this.renderAttendeeSettingsItem}
					headerRightComponent={showSummary ? this.renderSummaryRightHeader() : undefined}
					bottomComponent={isEditing ? this.renderBottomComponent() : undefined}
					showSummary={showSummary}
					noDefaultInfoText={showSummary || isEditing}
					customInfoTextComponent={this.renderCustomInfoText()}
					forceDisableAttendeeWarning={disableWarning}
					hideInviteAttendee={isEditing}
					clickable={false}
					attendeesWarningComponent={<AttendeesAlertContainer />}
					scrollableTarget={scrollableTarget}
				/>

				<ManageAttendeeModalContainer />
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		protocolSharedAt: store.meetings.getIn(['meeting', 'metadata', 'protocolSharedAt']),
		isStandardMeeting: store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_STANDARD,
		hasMeetingPassed: store.meetings.getIn(['meeting', 'computedValues', 'hasMeetingPassed'])
	}
}

const mapActionsToProps = {
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(AttendeesPanelStandardContainer)
