import { createTheme } from '@mui/material/styles'
import { MUItheme } from '../../../../MUItheme'

export const CustomTheme = createTheme({
	typography: {
		...MUItheme.typography,
		body1: {
			fontSize: '1.3rem'
		},
		body2: {
			fontSize: '1.3rem'
		}
	},
	palette: {
		primary: {
			main: '#369ff4',
			50: '#F0F7FC',
			100: '#D7ECFD',
			200: '#AFD9FB',
			300: '#86C5F8',
			400: '#5EB2F6',
			500: '#369FF4',
			600: '#2B7FC3',
			700: '#205F92',
			800: '#164062',
			900: '#0B2031'
		},
		secondary: {
			main: '#339999',
			50: '#D6EBEB',
			100: '#D6EBEB',
			200: '#ADD6D6',
			300: '#85C2C2',
			400: '#5CADAD',
			500: '#339999',
			600: '#297A7A',
			700: '#1F5C5C',
			800: '#143D3D',
			900: '#143D3D'
		},
		grey: {
			main: '#9E9E9E',
			0: '#FFFFFF',
			50: '#FAFAFA',
			100: '#F5F5F5',
			200: '#EEEEEE',
			300: '#E0E0E0',
			400: '#BDBDBD',
			500: '#9E9E9E',
			600: '#757575',
			700: '#616161',
			800: '#424242',
			900: '#212121'
		},
		success: {
			main: '#00C853',
			light: '#B9F6CA',
			dark: '#1B5E20',
			50: '#B9F6CA',
			100: '#69F0AE',
			200: '#00E676',
			300: '#00C853',
			400: '#2E7D32',
			500: '#1B5E20'
		},
		error: {
			main: '#FF3D00',
			light: '#FFCEBF',
			dark: '#BA2601',
			50: '#FFCEBF',
			100: '#FF9E80',
			200: '#FF6E40',
			300: '#FF3D00',
			400: '#DD2C00',
			500: '#BA2601',
			600: '#9F2203',
			700: '#5F1402'
		},
		blue: {
			300: '#D9E1E7', //Blue - dark in figma
			200: '#E3EBF1', //Blue - default in figma
			100: '#ECF0F5', //Blue - light in figma
			50: '#F5F7FA'
		},
		custom: {
			progress: {
				orange: {
					light: '#FBE7DD',
					dark: '#E98A54'
				},
				purple: {
					light: '#EDDEFB',
					dark: '#A359ED'
				}
			}
		},
		text: {
			primary: '#424242',
			secondary: '#616161',
			disabled: '#BDBDBD'
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					'&.MuiButton-contained': {
						color: '#ffffff',
						paddingTop: '1rem',
						paddingBottom: '1rem'
					}
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					backgroundColor: '#ffffff'
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					paddingTop: '1.75rem',
					paddingBottom: '1.75rem'
				}
			}
		}
	}
})
