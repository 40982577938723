import React, { PureComponent } from 'react'
import { bool, func, string } from 'prop-types'
import styled, { css } from 'styled-components'
import { StyledWrapperCss } from './item.template'

import CompanyLogo from './logo'
import Text from '../../shared/text/text'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import Icon from '../../shared/icon/icon'

const StyledWrapper = styled.div`
	${StyledWrapperCss};
	border-top: 1px solid ${(props) => props.theme.colors.border};
	cursor: ${(props) => (!props.isActive || props.isLoading) && 'default'};
	padding: 0;
`

const StyledInfoWrapper = styled.div`
	align-items: center;
	display: flex;
	flex: 1;
	height: 100%;
	padding-left: ${(props) => props.theme.spacing[4]};

	${(props) =>
		props.isActive &&
		!props.isLoading &&
		css`
		:hover {
			background: ${(props) => props.theme.colors.lightestGrey}};
		}
	`}
`

const StyledInformation = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	margin-left: ${(props) => props.theme.spacing[4]};
`

const StyledControlsWrapper = styled.div`
	border-left: 1px solid ${(props) => props.theme.colors.border};
	display: flex;
	width: 60px;
	height: 60px;
	justify-content: center;
	align-items: center;
	background: ${(props) => props.theme.colors.white};
	cursor: default;
`

export default class CompanyDemoItem extends PureComponent {
	static propTypes = {
		isActive: bool,
		isLoading: bool,
		onToggle: func,
		imageUrl: string
	}

	onSelect = () => {
		const { isActive, isLoading, onSelect } = this.props

		if (!isActive || isLoading) {
			return null
		}

		onSelect && onSelect()
	}

	render = () => {
		const { isActive, isLoading, onToggle, imageUrl } = this.props
		const subTitleTid = isActive ? 'generic.active' : 'generic.inactive'

		return (
			<StyledWrapper isActive={isActive} isLoading={isLoading}>
				<StyledInfoWrapper isActive={isActive} isLoading={isLoading} onClick={this.onSelect}>
					<CompanyLogo imageUrl={imageUrl} isDemo />

					<StyledInformation>
						<Text bold={600} singleLine>
							DEMO
						</Text>
						<Text tid={subTitleTid} />
					</StyledInformation>
				</StyledInfoWrapper>

				<StyledControlsWrapper>
					{isLoading ? (
						<Icon icon='faSpinner' spin />
					) : (
						<ToggleSwitch size='sm' onChange={onToggle} checked={isActive} />
					)}
				</StyledControlsWrapper>
			</StyledWrapper>
		)
	}
}
