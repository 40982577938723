import { fromJS } from 'immutable'
import req from '../modules/request.module'
import {
	FOLDERS_CREATE_REMOTE,
	FOLDERS_FETCH_REMOTE,
	FOLDERS_DELETE_REMOTE,
	FOLDERS_SAVE_REMOTE,
	FOLDERS_LIST,
	FOLDERS_UPDATE_LOCAL,
	FOLDERS_UPDATE_SELECTED_FOLDER_ID,
	FOLDERS_MIRRORED_FETCH_REMOTE,
	FOLDERS_GET_DEFAULT_PERMISSIONS
} from './types'
import {
	EVENT_TYPE_FOLDER_CREATE,
	EVENT_TYPE_FOLDER_UPDATE,
	EVENT_TYPE_FOLDER_DELETE,
	EVENT_TYPE_FOLDER_UNSHARED
} from '/shared/constants'
import { setLiveRequest, resetLiveRequest } from './live-update.actions'
import documentsHelper from '../components/helpers/documents.helper'

export function updateSelectedFolderId(id) {
	return {
		type: FOLDERS_UPDATE_SELECTED_FOLDER_ID,
		payload: id
	}
}
export function fetchDefaultPermissions(parentId, createFolderObject = true) {
	return function (dispatch) {
		return req.get(`/documents/folders/default-permissions/${parentId ? parentId : 'root'}`).then((response) => {
			dispatch({
				type: FOLDERS_GET_DEFAULT_PERMISSIONS,
				payload: fromJS({ permissions: response.data, createFolderObject })
			})
		})
	}
}

export function setupFolderStructure(template, callback) {
	return function (dispatch) {
		return req.post(`/documents/folders/setup`, { template }).then((response) => {
			callback && callback()
		})
	}
}

export function fetchFolder(id) {
	return function (dispatch) {
		return req.get(`/documents/folders/${id}`).then((response) => {
			dispatch({ type: FOLDERS_FETCH_REMOTE, payload: fromJS(response.data) })
		})
	}
}

export function createFolder(folder, callback, errCallback) {
	return function (dispatch) {
		return req
			.post(`/documents/folders`, folder)
			.then((response) => {
				const newFolder = fromJS(response.data)
				dispatch({ type: FOLDERS_CREATE_REMOTE, payload: newFolder })
				callback && callback(newFolder)
			})
			.catch((e) => {
				console.log(e)
				errCallback && errCallback()
			})
	}
}

export function editFolder(folder, callback, errCallback) {
	return function (dispatch) {
		return req
			.put(`/documents/folders/${folder.get('id')}`, folder.toJS())
			.then((response) => {
				dispatch({ type: FOLDERS_SAVE_REMOTE, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch(() => {
				errCallback && errCallback()
			})
	}
}

export function deleteFolder(id, callback, errCallback) {
	return function (dispatch) {
		return req
			.delete(`/documents/folders/${id}`)
			.then((response) => {
				dispatch({ type: FOLDERS_DELETE_REMOTE, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch(() => {
				errCallback && errCallback()
			})
	}
}

export function listFolders(callback) {
	return function (dispatch) {
		return req.get(`/documents/folders`).then((response) => {
			dispatch({ type: FOLDERS_LIST, payload: fromJS(response.data) })
			callback && callback()
		})
	}
}

export function updateFolderLocal(folder) {
	return function (dispatch) {
		dispatch({ type: FOLDERS_UPDATE_LOCAL, payload: folder })
	}
}

export function listMirroredFolders(companyId, investmentId, isCompany) {
	return function (dispatch) {
		return req
			.get(`/documents/mirrored/${companyId}/folders/${investmentId}?isCompany=${isCompany}`)
			.then((response) => {
				dispatch({ type: FOLDERS_MIRRORED_FETCH_REMOTE, payload: fromJS(response.data) })
			})
	}
}

export function listShareholderPreviewMirroredFolders(companyId, investmentId, isCompany) {
	return function (dispatch) {
		return req
			.get(`/documents/mirrored/shareholder-preview/${companyId}/folders/${investmentId}?isCompany=${isCompany}`)
			.then((response) => {
				dispatch({ type: FOLDERS_MIRRORED_FETCH_REMOTE, payload: fromJS(response.data) })
			})
	}
}

export function transferFolder(folderId, toFolderId, callback) {
	return function () {
		return req.put(`/documents/folders/${folderId}/transfer`, { toFolderId }).then(() => {
			callback && callback()
		})
	}
}

export function downloadFolderMatrix(openInViewer) {
	let w = null
	if (openInViewer) {
		w = window.open(`https://${window.location.hostname}/assets/build/misc/redirecting.html`, '_blank')
	}

	return function () {
		return req.post(`/documents/folders/matrix/create`).then((response) => {
			let { downloadUrl, fileSize } = response.data
			if (openInViewer) {
				const { webViewerSupportedFormat, webViewerSupportedSize } = documentsHelper.getWebViewerIsSupported(
					undefined,
					'xlsx',
					fileSize
				)
				if (webViewerSupportedFormat && webViewerSupportedSize) {
					downloadUrl = encodeURIComponent(downloadUrl)
					const webViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${downloadUrl}`
					w.location = webViewerUrl
				} else {
					w.location = downloadUrl
				}
			} else {
				window.open(downloadUrl, '_self')
			}
		})
	}
}

export function socketEventFolders(eventObj) {
	const { eventName, objId, metadata } = eventObj

	return function (dispatch) {
		switch (eventName) {
			case EVENT_TYPE_FOLDER_CREATE:
			case EVENT_TYPE_FOLDER_UPDATE:
			case EVENT_TYPE_FOLDER_DELETE:
			case EVENT_TYPE_FOLDER_UNSHARED: {
				dispatch(setLiveRequest(['folders', eventName], { r: true, objId, metadata }))
				dispatch(resetLiveRequest(['folders', eventName]))
				break
			}
		}
	}
}
