import React, { Component } from 'react'
import { connect } from 'react-redux'
import SplitView from '../../../dumb-components/shared/layout/split-view/split-view'
import ScrollView from '../../../dumb-components/shared/layout/scroll-view/scroll-view'
import MeetingToolbarContainer from '../meeting/smart-meeting/meeting-toolbar.container'
import MeetingTabsContainer from '../meeting/meeting-tabs.container'
import MeetingDocumentsListContainer from '../documents/meeting-documents-list.container'

import MeetingDocumentDetailsContainer from './meeting-document-details.container'

import { calculateMaxWidthOfSplitView, toggleVisibilityOfTabs } from '../../../components/helpers/tabs.helper'

class MeetingDocumetsContainer extends Component {
	// References to the scrollviews
	scrollViewRefs = {}

	// Reference to the tabs
	tabsRef

	// Timer
	resizeEnd

	state = {
		hiddenTabs: []
	}

	componentDidMount = () => {
		window.addEventListener('resize', this.onWindowResize, false)
		this.onWindowResize()
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.onWindowResize, false)
	}

	onWindowResize = () => {
		let { hiddenTabs } = this.state
		const maxWidth = calculateMaxWidthOfSplitView(this.scrollViewRefs)
		hiddenTabs = toggleVisibilityOfTabs(this.tabsRef, hiddenTabs, { maxWidth })

		this.setState({ hiddenTabs })
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('documents')
		return pathArray[0] + 'documents'
	}

	renderLeftHeader = () => {
		const { location } = this.props
		const { hiddenTabs } = this.state

		return (
			<MeetingTabsContainer
				location={location}
				match='documents'
				onSetRef={(ref) => (this.tabsRef = ref)}
				hiddenTabs={hiddenTabs}
			/>
		)
	}

	renderLeftSplitView = () => {
		const {
			location,
			match: { params }
		} = this.props
		const basePath = this.getComponentBasePath()

		return (
			<ScrollView
				header={this.renderLeftHeader}
				headerBottomMargin={3}
				showOnHover={true}
				onSetRef={(ref) => (this.scrollViewRefs['left'] = ref)}>
				<MeetingDocumentsListContainer basePath={basePath} location={location} params={params} />
			</ScrollView>
		)
	}

	renderRightHeader = () => {
		const {
			location,
			match: { params }
		} = this.props
		const basePath = this.getComponentBasePath()

		return <MeetingToolbarContainer params={params} basePath={basePath.split('/documents')[0]} location={location} />
	}

	renderRightSplitView = () => {
		const { match } = this.props

		return (
			<ScrollView
				header={this.renderRightHeader}
				headerBottomMargin={3}
				showOnHover={true}
				onSetRef={(ref) => (this.scrollViewRefs['right'] = ref)}>
				{match.params.documentId && (
					<MeetingDocumentDetailsContainer match={match} location={this.props.location} history={this.props.history} />
				)}
			</ScrollView>
		)
	}

	render = () => {
		return <SplitView leftComponent={this.renderLeftSplitView} rightComponent={this.renderRightSplitView} />
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingDocumetsContainer)
