import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

import Icon from '../../shared/icon/icon'
import Text from '../../shared/text/text'

const StyledMenuLabel = styled.div`
	align-items: center;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	display: flex;
	height: 40px;
	transition: background 0.4s ease;
	padding: 0 ${(props) => props.theme.spacing[4]};
	max-width: 220px;
	cursor: default;

	:hover {
		background: #f2f2f2;
	}
`

const StyledTextWrapper = styled.div`
	margin-left: 12px; /* to match other toolbar margins */
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	@media screen and (max-width: 682px) {
		display: none;
	}
`

export default class CompanyMenuLabel extends PureComponent {
	static propTypes = {
		companyName: string
	}

	getBodyText = () => {
		const { companyName } = this.props

		if (!companyName) {
			return <Text tid='navbar.company.label.choose_company' />
		}

		return <Text singleLine>{companyName}</Text>
	}

	render = () => {
		return (
			<StyledMenuLabel>
				<Icon icon='faBars' />

				<StyledTextWrapper>{this.getBodyText()}</StyledTextWrapper>
			</StyledMenuLabel>
		)
	}
}
