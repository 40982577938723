import { List, Map } from 'immutable'
import { getUserName } from './users'
import { getAttendeeIsInvestor, isAttendeeProxy, isAttendingByProxy } from './meeting.helper'
import { INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE } from '/shared/constants'

export const getAtLeastOneAttendeeCanVote = (attendees, investors) => {
	if (attendees.size === 0) {
		return false
	}

	const atLeastOneAttendeeIsVerifiedInvestor = attendees.some((attendee) => {
		const isInvestor = attendee.get('isInvestor')
		const isAlsoInvestor = attendee.get('isAlsoInvestor')
		const verifiedAttendance = attendee.get('verifiedAttendance', false)

		return (isInvestor || isAlsoInvestor) && verifiedAttendance
	})

	return atLeastOneAttendeeIsVerifiedInvestor
}

//User id can be a List
export const investorParticipatedInVoting = (votingList, userId, meetingId) => {
	return votingList.some((voting) => {
		const isCurrentMeeting = voting.get('meetingId') === meetingId
		const userIdIsList = List.isList(userId)

		if (userIdIsList) {
			const hasVoted = userId.some((id) => voting.getIn(['votes', id]))
			const userHasVotedInCurrentMeeting = isCurrentMeeting && hasVoted

			return userHasVotedInCurrentMeeting
		}

		const userHasVoted = voting.getIn(['votes', userId])
		const userHasVotedInCurrentMeeting = isCurrentMeeting && userHasVoted

		return userHasVotedInCurrentMeeting
	})
}

export const getUsersUserCanVoteFor = (
	attendees = Map(),
	proxies = Map(),
	userId,
	investors,
	usersCache,
	sharesInitialized
) => {
	const attendee = attendees.get(userId, Map())
	const attendeesIamProxyFor = proxies.get(userId, List())
	let usersToVote = List()

	const userIsInvestor = getAttendeeIsInvestor(attendee, investors)
	const userIsVerified = attendee.get('verifiedAttendance', false)
	const userHasNoProxy = !proxies.some((proxyForIds) => {
		return proxyForIds.some((proxyForId) => proxyForId === userId)
	})

	if (userIsInvestor && userIsVerified && userHasNoProxy) {
		const attendeeInvestor = investors && investors.find((inv) => inv.get('id') === attendee.get('investmentId'))
		const hasShares = attendeeInvestor && attendeeInvestor.getIn(['details', 'hasShares'])
		const isCapitalInsurance =
			attendeeInvestor && attendeeInvestor.get('investorTypeOfOwner') === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE
		const canVote = sharesInitialized ? !isCapitalInsurance && hasShares : true
		if (canVote) {
			usersToVote = usersToVote.push(
				Map({
					id: userId,
					name: getUserName(attendee, investors, usersCache)
				})
			)
		}
	}

	attendeesIamProxyFor.forEach((attendeeId) => {
		const attendee = attendees.get(attendeeId)
		const attendeeInvestor = investors && investors.find((inv) => inv.get('id') === attendee.get('investmentId'))
		const hasShares = attendeeInvestor && attendeeInvestor.getIn(['details', 'hasShares'])
		const isCapitalInsurance =
			attendeeInvestor && attendeeInvestor.get('investorTypeOfOwner') === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE
		const canVote = sharesInitialized ? !isCapitalInsurance && hasShares : true
		if (canVote) {
			usersToVote = usersToVote.push(
				Map({
					id: attendeeId,
					name: getUserName(attendee, investors, usersCache)
				})
			)
		}
	})

	return usersToVote
}

export const getAttendeeCanBeRemovedData = (props) => {
	const { votingList, userId, userIds, meetingId, proxies, attendee, isStandardMeeting } = props
	const userIdOrIds = userId || userIds

	const TOOLTIP_STATES = {
		hasVoted: {
			tid: 'meetings.attendees.list.remove_attendee.tooltip.cant_delete_has_voted',
			delayShow: 'instant'
		},
		isProxy: {
			tid: 'meetings.attendees.list.remove_attendee.tooltip.cant_delete_is_proxy',
			delayShow: 'instant'
		},
		attendingByProxy: {
			tid: 'meetings.attendees.list.remove_attendee.tooltip.cant_delete_has_proxy',
			delayShow: 'instant'
		}
	}

	let activeState = ''
	let attendingByProxy
	const isProxy = isAttendeeProxy(attendee, proxies)
	const hasVoted = investorParticipatedInVoting(votingList, userIdOrIds, meetingId)

	if (typeof userIdOrIds === 'string') {
		attendingByProxy = isAttendingByProxy(userIdOrIds, proxies)
	} else {
		attendingByProxy = userIdOrIds ? userIdOrIds.some((id) => isAttendingByProxy(id, proxies)) : false
	}

	if (isProxy) {
		activeState = 'isProxy'
	} else if (hasVoted) {
		activeState = 'hasVoted'
	} else if (attendingByProxy) {
		activeState = 'attendingByProxy'
	}

	return { TOOLTIP_STATES, activeState, isDisabled: Boolean(activeState) }
}

export const isUserModerator = ({ userId, moderators }) => {
	return moderators ? moderators.includes(userId) : false
}
