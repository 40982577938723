import { ALIAS_CREATE, ALIAS_UPDATE, ALIAS_DELETE } from './types'
import { fromJS } from 'immutable'
import req from '../modules/request.module'

export function updateAlias(alias, id, callback) {
	return function (dispatch) {
		return req.put(`shares/personal/investment/alias/${id}`, { alias }).then((response) => {
			dispatch({ type: ALIAS_UPDATE, payload: alias })
			callback && callback()
		})
	}
}

export function deleteAlias(id, alias, callback) {
	return function (dispatch) {
		return req
			.delete(`/shares/personal/investment/alias/${id}`)
			.then(() => {
				dispatch({ type: ALIAS_UPDATE, payload: alias })
				callback && callback()
			})
			.catch((err) => {
				console.log(err)
			})
	}
}
