import React, { Component } from 'react'
import { connect } from 'react-redux'
import { downloadDocument } from '../../../actions/documents.actions'
import MeetingDocumentsListContainer from '../documents/meeting-documents-list.container'

class DocumentsViewContainer extends Component {
	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('documents')
		return pathArray[0] + 'documents'
	}

	onOpenFileInViewer = (file) => {
		const { downloadDocument } = this.props
		downloadDocument({ documentId: file.get('id'), openInViewer: true })
	}

	onDownloadFile = (file) => {
		const { downloadDocument } = this.props
		downloadDocument({ documentId: file.get('id') })
	}

	render = () => {
		const { location } = this.props
		const basePath = this.getComponentBasePath()

		return (
			<MeetingDocumentsListContainer
				viewMode='protocol'
				basePath={basePath}
				location={location}
				onOpenFileInViewer={this.onOpenFileInViewer}
				onDownloadFile={this.onDownloadFile}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		company: store.company.company
	}
}

const mapActionsToProps = {
	downloadDocument
}

export default connect(mapStoreToProps, mapActionsToProps)(DocumentsViewContainer)
