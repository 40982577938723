import React, { PureComponent } from 'react'
import { node, bool, string, func } from 'prop-types'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

const StyledItem = styled.div`
	display: flex;
	background-color: ${(props) =>
		props.active ? props.theme.colors[props.activeColor] : props.plainMode ? 'transparent' : props.theme.colors.white};
	height: 50px;
	margin-bottom: 4px;
	transition: background-color 0.3s;
	width: 100%;

	${(props) =>
		props.striped &&
		css`
			:nth-child(odd) {
				background-color: ${(props) => props.theme.colors[props.stripedBackgroundColor]};
			}
		`}

	${(props) =>
		props.hoverColor &&
		css`
			:hover {
				background-color: ${(props) => props.theme.colors[props.hoverColor]};
				transition: background-color 0.3s;
			}
		`}
`

export default class ListItem extends PureComponent {
	static propTypes = {
		children: node,
		active: bool,
		striped: bool,
		className: string,
		hoverColor: string,
		onClick: func,
		stripedBackgroundColor: string,
		id: string,
		activeColor: string
	}

	static defaultProps = {
		active: false,
		striped: false,
		stripedBackgroundColor: 'tableAltRowColor',
		activeColor: 'solitudeMid'
	}

	render = () => {
		const { children, onClick, ...props } = this.props

		if (onClick) {
			props.onClick = onClick
		}

		return <StyledItem {...props}>{children}</StyledItem>
	}
}
