import React, { PureComponent } from 'react'
import { oneOf, func, bool } from 'prop-types'

import ConfirmModal from '../../containers/shared/confirm.container'

export default class AddExistingDocumentToCollectionWarningModal extends PureComponent {
	static propTypes = {
		existingSharedVersion: oneOf(['NORMAL', 'SIGNED']).isRequired,
		onReplace: func.isRequired,
		onSkip: func.isRequired,
		isLoading: bool
	}

	onReplace = () => {
		const { onReplace, existingSharedVersion } = this.props

		if (existingSharedVersion === 'SIGNED') {
			onReplace('NORMAL')
		} else {
			onReplace('SIGNED')
		}
	}

	getTids = () => {
		const { existingSharedVersion } = this.props

		if (existingSharedVersion === 'SIGNED') {
			return {
				infoTid_1: 'collections.replace_existing_document.modal.info.replace_with_normal_1',
				infoTid_2: 'collections.replace_existing_document.modal.info.replace_with_normal_2'
			}
		}

		return {
			infoTid_1: 'collections.replace_existing_document.modal.info.replace_with_signed_1',
			infoTid_2: 'collections.replace_existing_document.modal.info.replace_with_signed_2'
		}
	}

	render = () => {
		const { onSkip, isLoading } = this.props
		const { infoTid_1, infoTid_2 } = this.getTids()

		return (
			<ConfirmModal
				isOpen={true}
				title='collections.replace_existing_document.modal.title'
				question={infoTid_1}
				infoTid={infoTid_2}
				confirmBtnTid='collections.replace_existing_document.modal.footer.btn.confirm'
				declineBtnTid='collections.replace_existing_document.modal.footer.btn.decline'
				onConfirm={this.onReplace}
				onDecline={onSkip}
				isLoading={isLoading}
			/>
		)
	}
}
