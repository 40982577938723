import { List } from 'immutable'

const _getFlattenedMenuCompanyResources = (children) => {
	if (!children) {
		return []
	}

	return children
		.map((child) => {
			if (child.children) {
				return _getFlattenedMenuCompanyResources(child.children)
			}
			return child.id
		})
		.flat()
}

export const getHasAccessToSection = (menuCompanyResources, sectionId) => {
	const flatSectionArray = _getFlattenedMenuCompanyResources(menuCompanyResources)
	return flatSectionArray.includes(sectionId)
}
