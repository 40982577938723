import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { summarize } from '../../utils/calculations'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import { NUMBER_FORMAT_INTEGER } from '/shared/constants.json'

const AllocationContextMenu = (props) => {
	const totals = summarize(useSelector((state) => state.emissions.submissions))
	const [anchorEl, setAnchorEl] = useState(null)
	const open = !!anchorEl
	const { t } = useTranslation()
	const theme = useTheme()
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const sharesAllocated = `${localeFormatNumber(totals.assignedToList, NUMBER_FORMAT_INTEGER)} ${t(
		'generic.created_of'
	)} ${localeFormatNumber(totals.registeredInterest, NUMBER_FORMAT_INTEGER)} ${t(
		'emissions.allocation.shares-allocated'
	)}`

	return (
		<>
			<Typography
				component={'span'}
				variant={'body2'}
				fontStyle={'italic'}
				sx={{ display: 'flex', alignItems: 'center', color: theme.palette.grey['800'], mr: 4 }}>
				{sharesAllocated}
			</Typography>
			<Tooltip title={t('emissions.invitations.more-options')}>
				<IconButton onClick={handleClick}>
					<MoreVertIcon sx={{ color: 'grey.700' }} />
				</IconButton>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				id='submission-menu'
				open={open}
				onClose={handleClose}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
				<MenuItem
					onClick={() => {
						props.handleDownloadAsExcel(), handleClose()
					}}>
					<GetAppOutlinedIcon sx={{ mr: 2 }}>
						<VisibilityOutlinedIcon fontSize='small' />
					</GetAppOutlinedIcon>
					{t('emissions.allocation.download-open-excel')}
				</MenuItem>
			</Menu>
		</>
	)
}

export default AllocationContextMenu
