export function toInt(value) {
	// Added this to prevent NaN
	if (!isFinite(value.charAt(0))) {
		return
	}

	return value !== '' ? parseInt(value) : null
}

/*
	Since float doesn't work I used React Number Format. Check in add-transaction-modal.container how it is implemented.
	I used it for both int and float.
*/
export function toFloat(value) {
	return value !== '' ? parseFloat(value) : null
}
