import React, { PureComponent } from 'react'
import immutablePropTypes from 'react-immutable-proptypes'
import { string, bool, func } from 'prop-types'
import styled, { css } from 'styled-components'
import { List } from 'immutable'
import StyledList from '../../shared/list/list'
import ListItem from '../../shared/list-item/list-item'
import LineSeparator from '../../shared/line-separator/line-separator'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const StyledListItem = styled(ListItem)`
	margin-top: ${(props) => props.theme.spacing[2]};
	margin-bottom: 0px;
	margin-left: ${(props) => `${50 * (props.level - 1)}px`};
	width: calc(100% - ${(props) => `${50 * (props.level - 1)}px`});
`

const StyledTransparentListItem = styled(StyledListItem)`
	background-color: transparent;
`

export default class AgendaList extends PureComponent {
	static propTypes = {
		listName: string.isRequired,
		ordinaryItems: immutablePropTypes.list,
		postItems: immutablePropTypes.list,
		suggestedItems: immutablePropTypes.list,
		childProps: string,
		renderItemComponent: func,
		renderNewItemButton: func,
		shouldDisplayNewItemButton: func,
		isDragDisabled: bool,
		onDragEnd: func,
		agendaItemInEditMode: string,
		currentUrl: string,
		basePath: string,
		seperatorBgColor: string,
		selectedItemId: string,
		isSecretary: bool
	}

	static defaultProps = {
		items: List(),
		childProps: 'agendaItems'
	}

	renderListItem = (level, parentIndex, listName, startIndex, item, index) => {
		const {
			childProps,
			isDragDisabled,
			renderItemComponent,
			basePath,
			currentUrl,
			selectedItemId,
			isSecretary
		} = this.props
		const dragIsDisabled = isDragDisabled || item.get('locked')
		const listType = `${level + 1}$${item.get('id')}$${index}`
		const active = currentUrl ? currentUrl === `${basePath}/${item.get('id')}` : false
		const itemIsSuggestion = item.get('isSuggested')
		const itemIsSelected = selectedItemId === item.get('id')
		const itemType = item.get('itemType')
		let num = startIndex + index + 1

		if (level > 1) {
			num = startIndex + parentIndex + 1 + String.fromCharCode(96 + num)
		}

		return (
			<Draggable
				draggableId={item.get('id')}
				index={index}
				isDragDisabled={dragIsDisabled || listName === 'archivedItems'}
				key={index}>
				{(provided, snapshot) => (
					<div ref={provided.innerRef} {...provided.draggableProps}>
						<StyledListItem
							active={active || itemIsSelected}
							level={level}
							hoverColor={'lightestGrey'}
							plainMode={listName === 'archivedItems'}>
							{renderItemComponent(listName, {
								id: item.get('id'),
								outcome: item.get('outcome'),
								presenter: !itemIsSuggestion ? item.get('presenter') : item.get('suggestedBy'),
								duration: item.get('duration'),
								name: item.get('proposal'),
								itemType,
								internalType: item.get('internalType'),
								active: item.get('active'),
								locked: item.get('locked'),
								type: item.get('type'),
								progress: item.get('progress'),
								hasUnreadFeedback: item.get('hasUnreadFeedback'),
								isSuggestion: itemIsSuggestion,
								dragHandleProps: provided.dragHandleProps,
								parentIndex: level === 1 ? -1 : parentIndex,
								itemIndex: index,
								isDragDisabled: dragIsDisabled,
								num,
								level
							})}
						</StyledListItem>
						{item.has(childProps) &&
							this.renderList(`${listName}$${item.get('id')}`, listType, startIndex, item.get(childProps, List()))}
					</div>
				)}
			</Draggable>
		)
	}

	renderList = (listName, listType, startIndex, items) => {
		const { renderNewItemButton, shouldDisplayNewItemButton } = this.props
		let [level, id, parentIndex] = listType.split('$')
		parentIndex = parseInt(parentIndex)
		level = parseInt(level)
		const dispayNewItemButton = shouldDisplayNewItemButton(this.props.listName, level, parentIndex)

		return (
			<Droppable droppableId={listName} type={listType}>
				{(provided) => (
					<div ref={provided.innerRef}>
						<StyledList>
							{items && items.map(this.renderListItem.bind(this, level, parentIndex, listName, startIndex))}
							{dispayNewItemButton && renderNewItemButton && (
								<StyledTransparentListItem level={level}>
									{renderNewItemButton(listName, items ? items.size : 0, level === 1 ? -1 : parentIndex)}
								</StyledTransparentListItem>
							)}
							{provided.placeholder}
						</StyledList>
					</div>
				)}
			</Droppable>
		)
	}

	render = () => {
		const {
			ordinaryItems,
			postItems,
			suggestedItems,
			archivedItems,
			renderNewItemButton,
			shouldDisplayNewItemButton,
			listName,
			onDragEnd,
			seperatorBgColor,
			userId
		} = this.props
		const level = 1
		const id = 'root'
		const listId = `${level}$${id}`
		const parentIndex = 0
		const dispayNewItemButton = shouldDisplayNewItemButton(this.props.listName, level, parentIndex)
		const showAdjornmentList = postItems && postItems.size > 0
		const showSuggestedItems = suggestedItems && suggestedItems.size > 0
		const showArchivedItems = archivedItems && archivedItems.size > 0
		const postItemsStartIndex = ordinaryItems ? ordinaryItems.size : 0
		const suggestedItemsStartIndex = postItemsStartIndex + postItems ? postItems.size : 0

		return (
			<React.Fragment>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId={'ordinaryItems'} type={listId}>
						{(provided) => (
							<div ref={provided.innerRef}>
								<StyledList>
									{ordinaryItems &&
										ordinaryItems.map(this.renderListItem.bind(this, level, parentIndex, 'ordinaryItems', 0))}
									{provided.placeholder}
								</StyledList>
							</div>
						)}
					</Droppable>
					{dispayNewItemButton && renderNewItemButton && (
						<StyledTransparentListItem level={level}>
							{renderNewItemButton(listName, ordinaryItems ? ordinaryItems.size : 0, level === 1 ? -1 : parentIndex)}
						</StyledTransparentListItem>
					)}
					{showAdjornmentList && (
						<LineSeparator tid='meetings.agenda.list.ajournment' bgColor={seperatorBgColor} margin />
					)}
					<Droppable droppableId={'postItems'} type={listId}>
						{(provided) => (
							<div ref={provided.innerRef}>
								<StyledList>
									{showAdjornmentList &&
										postItems.map(this.renderListItem.bind(this, level, parentIndex, 'postItems', postItemsStartIndex))}
									{provided.placeholder}
								</StyledList>
							</div>
						)}
					</Droppable>
					{showSuggestedItems && (
						<LineSeparator tid='meetings.agenda.list.suggestions' bgColor={seperatorBgColor} margin />
					)}
					<Droppable droppableId={'suggestedItems'} type={listId}>
						{(provided) => (
							<div ref={provided.innerRef}>
								<StyledList>
									{showSuggestedItems &&
										suggestedItems.map(
											this.renderListItem.bind(this, level, parentIndex, 'suggestedItems', suggestedItemsStartIndex)
										)}
									{provided.placeholder}
								</StyledList>
							</div>
						)}
					</Droppable>
				</DragDropContext>
				{showArchivedItems && (
					<React.Fragment>
						<LineSeparator
							tid='meetings.agenda.list.archived.notes'
							textColor={'muted'}
							bgColor={seperatorBgColor}
							margin
						/>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId={'archivedItems'} type={listId}>
								{(provided) => (
									<div ref={provided.innerRef}>
										<StyledList>
											{archivedItems &&
												archivedItems.map(this.renderListItem.bind(this, level, parentIndex, 'archivedItems', 0))}
											{provided.placeholder}
										</StyledList>
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</React.Fragment>
				)}
			</React.Fragment>
		)
	}
}
