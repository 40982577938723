import axios from 'axios';
import Cookies from 'universal-cookie';
import apiError from '../errors/api.error';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { emissionConsts } from '../constants/emissions';

export default {
	async generatePdf(emissionPdfInfo, lang) {
		try {
			const { emission } = emissionPdfInfo;
			const res = await axios.post(
				`/api/gateway/emissions/companies/${emission.companyId}/emissions/${emission.id}?lang=${lang}`,
				emissionPdfInfo,
				{
					headers: {
						accept: 'application/pdf',
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					},
					responseType: 'blob'
				}
			);

			const fileName = res.headers['content-disposition'].match(/filename\*=UTF-8''(.+)/)[1];
			saveAs(new Blob([res.data], { type: res.headers['content-type'] }), decodeURIComponent(fileName));
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async generateBoardSuggestionPdf(emissionPdfInfo, lang) {
		try {
			const { emission } = emissionPdfInfo;
			const res = await axios.post(
				`/api/gateway/emissions/companies/${emission.companyId}/emissions/${emission.id}/boardSuggestion?lang=${lang}`,
				emissionPdfInfo,
				{
					headers: {
						accept: 'application/pdf',
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					},
					responseType: 'blob'
				}
			);

			const fileName = res.headers['content-disposition'].match(/filename\*=UTF-8''(.+)/)[1];
			saveAs(new Blob([res.data], { type: res.headers['content-type'] }), decodeURIComponent(fileName));
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async getAllEmissions(companyId) {
		try {
			const res = await axios.get(`/api/gateway/emissions/companies/${companyId}/emissions`, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			});

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async save(emission) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${emission.companyId}/emissions/${emission.id}`,
				emission,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async renameEmission(companyId, emissionId, nameObj) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/name`,
				nameObj,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async get(companyId, emissionId) {
		try {
			const res = await axios.get(`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}`, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			});

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async createEmission(companyId, emission) {
		try {
			const res = await axios.post(`/api/gateway/emissions/companies/${companyId}/emissions`, emission, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			});

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async cancelEmission(companyId, emissionId) {
		try {
			const res = await axios.delete(`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}`, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			});

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async sendEmissionCancellationEmail(companyId, emissionId, subject, template, lang) {
		try {
			await axios.post(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/inform-on-cancel?lang=${lang}`,
				{ templateBody: template, subject: subject },
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async shareTypes(companyId) {
		try {
			const res = await axios.get(`/api/gateway/emissions/companies/${companyId}/share-types`, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			});

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async basicShareData(companyId) {
		try {
			const res = await axios.get(`/api/gateway/emissions/companies/${companyId}/basic-share-data`, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			});

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async documentMap(companyId, emissionId) {
		try {
			const res = await axios.get(`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/files`, {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			});

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async uploadDocument(companyId, emissionId, fileKey, file) {
		const fd = new FormData();

		fd.set('file', file);

		try {
			const res = await axios.post(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/files/${fileKey}`,
				fd,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async deleteDocument(companyId, emissionId, fileKey) {
		try {
			await axios.delete(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/files/${fileKey}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async getMarketingFiles(companyId, emissionId) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/marketing/files`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async setMarketingFilePermissions(companyId, emissionId, fileId, permissions) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/marketing/files/${fileId}/permissions`,
				{ permissions },
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async deleteMarketingFile(companyId, emissionId, fileId) {
		try {
			const res = await axios.delete(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/marketing/files/${fileId}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async uploadMarketingFile(companyId, emissionId, file) {
		const fd = new FormData();

		fd.set('file', file);

		try {
			const res = await axios.post(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/marketing/files`,
				fd,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async sendShareRegisterUpdated(companyId, emissionId, subject, template, lang) {
		try {
			await axios.post(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/payments/shareregisterupdated?lang=${lang}`,
				{ templateBody: template, subject: subject },
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async sendSettlementNotes(companyId, emissionId, closureId, subject, template, lang) {
		try {
			await axios.post(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/payments/closures/${closureId}/sendsettlementnotes?lang=${lang}`,
				{ templateBody: template, subject: subject },
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async sendReceipts(companyId, emissionId, closureId, subject, template, investmentIdDocIdList, lang) {
		try {
			await axios.post(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/payments/closures/${closureId}/sendreceipts?lang=${lang}`,
				{ templateBody: template, subject: subject, investmentIdDocIdList: investmentIdDocIdList },
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},
	async getInviteTemplate(companyId, emissionId, type, lang) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invite-template/${type}?lang=${lang}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async getInformTemplate(companyId, emissionId, type, lang) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/inform-template/${type}?lang=${lang}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async getInvitees(companyId, emissionId) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invitees`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async getSingleInvitee(companyId, emissionId, inviteeId) {
		try {
			const res = await axios.get(
				`/api/gateway/subscription-slip/companies/${companyId}/emission/${emissionId}/invitees/${inviteeId}`,
				{}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async deleteInvitee(companyId, emissionId, inviteeId) {
		try {
			await axios.delete(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invitees/${inviteeId}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async getNumOfDealflows(companyId, emissionId) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invitees/dealflows`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async getNotes(companyId, emissionId, inviteeId) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invitees/${inviteeId}/notes`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async updateNotes(companyId, emissionId, invitationId, notes) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invitees/${invitationId}/notes`,
				{ notes },
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async updateInterested(companyId, emissionId, invitationId, interested) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invitees/${invitationId}/interested`,
				{ interested },
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async sendInvestorInvite(companyId, emissionId, invitees, template, lang) {
		try {
			await axios.post(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invitees?lang=${lang}`,
				{
					users: invitees,
					template
				},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async sendDealflowInvite(companyId, orgNumber, emissionId, typeOfInvitees, template, lang) {
		try {
			await axios.post(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invitees/dealflow/${orgNumber}?lang=${lang}`,
				{
					typeOfInvitees,
					template
				},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async sendInvitationReminder(companyId, emissionId, inviteeId, lang) {
		try {
			await axios.post(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invitees/${inviteeId}/invite-reminder?lang=${lang}`,
				undefined,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async getSubscriptionSlip(companyId, emissionId) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/subscription-slip`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async updateSubscriptionSlip(companyId, emissionId, subscriptionSlip) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/subscription-slip`,
				subscriptionSlip,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},
	async updateSubscriptionSlipPaymentInfo(companyId, emissionId, updatedPaymentInfo) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/subscription-slip/payment-info`,
				updatedPaymentInfo,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async getSubmissions(companyId, emissionId) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/submissions`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async getInsuranceOptions(companyId, emissionId) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/subscription-slip/insurance-options`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async getDepositoryOptions(companyId, emissionId) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/subscription-slip/depository-options`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async getSubmission(companyId, emissionId, inviteeId) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/submissions/${inviteeId}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async updateSubmission(companyId, emissionId, inviteeId, submission, lang) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/submissions/${inviteeId}?lang=${lang}`,
				submission,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async releaseSubmission(companyId, emissionId, inviteeId) {
		try {
			await axios.delete(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/submissions/${inviteeId}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async getEmissionStatistics(companyId, emissionId) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/statistics`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw new Error(e.response.data.message);
		}
	},

	async updateAllocationForSubmission(companyId, emissionId, inviteeId, allocation) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invitees/${inviteeId}/allocation`,
				{
					allocation: allocation
				},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async createInvestmentForInvitee(companyId, emissionId, inviteeId) {
		try {
			const res = await axios.post(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/shareholders/${inviteeId}/investment`,
				{},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async createEmissionClosure(companyId, emissionId, closureIndex, closeType, submissions) {
		try {
			const res = await axios.post(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/closure`,
				{
					index: closeType === emissionConsts.closure.type.partial ? closureIndex : 0,
					date: DateTime.now().toUTC().toISO(),
					type: closeType,
					allocations: submissions
						.filter((s) => !s.allocation.fulfilled && s.submission.investmentId)
						.map((s) => {
							return {
								inviteeId: s.inviteeId,
								investmentId: s.submission.investmentId,
								totalAllocation: 0,
								subscribedShares: s.submission.shareCount,
								proRata: s.proRata,
								previouslyAllocated: !s.allocation.previouslyAllocated
									? 0
									: s.allocation.previouslyAllocated,
								allocatedShares: s.allocation.allocation ?? 0
							};
						})
				},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async updateShareRegisterForLatestClosure(companyId, emissionId) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/shareholders/shareregister`,
				{},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async deleteAllSettlementNotesForClosureClosure(companyId, emissionId, closureId) {
		try {
			const res = await axios.delete(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/payments/settlementnotes/${closureId}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},
	async generateReceipt(emissionId, companyId, inviteeId, closureId) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/payments/invitees/${inviteeId}/closure/${closureId}/complete`,
				{},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async deleteReceipt(emissionId, companyId, inviteeId, closureId) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/payments/invitees/${inviteeId}/closure/${closureId}/revert`,
				{},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async updateClosureStatus(companyId, emissionId, status) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/closure/status/${status}`,
				{},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async getBasicDocumentInformation(companyId, emissionId, documentId) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/attachments/${documentId}/info`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async closeEmission(companyId, emissionId) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/status/complete`,
				{},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async getInvestorEmissions(investedInCompanyId, investmentId, companyId) {
		try {
			const headers = { authorization: `Bearer ${new Cookies().get('accessToken')}` };

			if (typeof companyId !== 'undefined') {
				headers['x-company-id'] = companyId;
			}

			const res = await axios.get(
				`/api/gateway/investor-emissions/companies/${investedInCompanyId}/emissions/${investmentId}`,
				{
					headers
				}
			);
			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async getDocumentsForInvitee(companyId, emissionId, inviteeId, lang) {
		try {
			const res = await axios.get(
				`/api/gateway/investor-emissions/companies/${companyId}/emissions/${emissionId}/documents/${inviteeId}?lang=${lang}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async registerPartialClosure(companyId, emissionId, closureId) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/payments/registerClosure/${closureId}`,
				{},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async removeClosure(companyId, emissionId, closureId) {
		try {
			const res = await axios.delete(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/closure/${closureId}`,
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async getSettlementNote(companyId, emissionId, closureId, inviteeId, lang) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/public-payments/settlementnote/${closureId}/${inviteeId}?lang=${lang}`,
				{}
			);
			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},

	async getReceipt(companyId, emissionId, closureId, inviteeId, lang) {
		try {
			const res = await axios.get(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/public-payments/receipt/${closureId}/${inviteeId}?lang=${lang}`,
				{}
			);
			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},
	async updateContactPerson(companyId, emissionId, inviteeId, contactPersonId) {
		try {
			await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/invitees/${inviteeId}/contact/${contactPersonId}/`,
				{},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	},
	async updateEmissionStatus(companyId, emissionId, status) {
		try {
			const res = await axios.put(
				`/api/gateway/emissions/companies/${companyId}/emissions/${emissionId}/update/status/${status}/`,
				{},
				{
					headers: {
						authorization: `Bearer ${new Cookies().get('accessToken')}`
					}
				}
			);

			return res.data;
		} catch (e) {
			throw apiError.create(e.response.data.message);
		}
	}
};
