import React, { PureComponent } from 'react'
import { bool, func } from 'prop-types'
import { list } from 'react-immutable-proptypes'
import styled from 'styled-components'

import Modal from '../../shared/modal/modal'
import Text from '../../shared/text/text'
import FooterRightControls from '../../shared/modal/footer-right-controls'
import { TransparentButton } from '../../shared/button-v2'
import Alert from '../../shared/alert/alert'

const StyledContainer = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;

	> div:first-child {
		margin-bottom: ${(props) => props.theme.spacing[4]};
	}

	> div:last-child {
		margin-bottom: 0;
	}
`

export default class ConfirmInviteAttendees extends PureComponent {
	static propTypes = {
		onConfirm: func,
		onDecline: func,
		isLoading: bool,
		missingFields: list
	}

	getRequiredInfoIsFilled = () => {
		const { missingFields } = this.props
		const hasStartDateTime = !missingFields.some((f) => f === 'startDate')

		return hasStartDateTime
	}

	getHasLocation = () => {
		return !this.props.missingFields.some((f) => f === 'location')
	}

	getHasAgenda = () => {
		return !this.props.missingFields.some((f) => f === 'agenda')
	}

	renderFooter = (requiredInfoIsFilled) => {
		const { onConfirm, onDecline, isLoading } = this.props

		if (!requiredInfoIsFilled) {
			return (
				<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={onDecline} isLoading={isLoading} />
			)
		}

		return (
			<FooterRightControls>
				<TransparentButton
					tid='meetings.standard_meeting.attendees_panel.confirm.send_without_changes'
					onClick={onConfirm}
					isLoading={isLoading}
				/>
				<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={onDecline} isLoading={isLoading} />
			</FooterRightControls>
		)
	}

	renderBody = (requiredInfoIsFilled) => {
		const hasAgenda = this.getHasAgenda()
		const hasLocation = this.getHasLocation()
		let subTid = null

		if (!hasAgenda & !hasLocation) {
			subTid = 'meetings.standard_meeting.attendees_panel.no_location_agenda'
		} else if (!hasAgenda) {
			subTid = 'meetings.standard_meeting.attendees_panel.no_agenda'
		} else if (!hasLocation) {
			subTid = 'meetings.standard_meeting.attendees_panel.no_location'
		}

		return (
			<StyledContainer>
				{!requiredInfoIsFilled && (
					<Alert mode='warning' icon>
						<Text tid='meetings.standard_meeting.attendees_panel.confirm.title' color='white' />
					</Alert>
				)}

				{subTid && (
					<Alert mode='info' icon>
						<Text tid={subTid} color='white' />
					</Alert>
				)}
			</StyledContainer>
		)
	}

	render = () => {
		const { isOpen } = this.props
		const requiredInfoIsFilled = this.getRequiredInfoIsFilled()

		return (
			<Modal
				footerComponent={this.renderFooter(requiredInfoIsFilled)}
				isOpen={isOpen}
				title='meetings.attendees.confirm_invite.modal.title'
				hSize='md'
				vSize={50}
				scrollableContent={false}>
				{this.renderBody(requiredInfoIsFilled)}
			</Modal>
		)
	}
}
