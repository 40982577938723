/**
 * Profile
 * @module components/user/services/account-wizard
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import StepZilla from 'react-stepzilla'
import CompanySearch from '../account-wizard/company-search'
import AuthoriseCompany from '../account-wizard/authorise-company'
import CompanyMembers from '../account-wizard/company-members'
import { signAndCreateCompany, createUnverifiedCompany, companyChanged } from '../../../actions/company.actions'

const INITIAL_STATE = {
	selectedCompany: null,
	selectedCompanyDetails: null,
	selectedCompanyRepresentatives: null,
	selectedCompanySignatories: null,
	bankIdSigned: false,
	bankIdSignedBy: null,
	addMembers: false,
	bankIdTransaltionId: null,
	isForeignCompany: false
}

class AccountWizard extends Component {
	constructor(props) {
		super(props)
		this.state = INITIAL_STATE
	}

	onCancel() {
		this.clearWizard()
		this.props.onCancel()
	}

	clearWizard() {
		this.setState(INITIAL_STATE)
		this.props.companyChanged(null)
	}

	selectCompany(company, isForeignCompany) {
		if (!company) {
			this.setState(INITIAL_STATE)
			return false
		}

		this.setState({
			selectedCompany: company.company.orgNumber,
			selectedCompanyDetails: company.company,
			selectedCompanyRepresentatives: company.allowedRepresentativesToSign,
			bankIdSigned: false,
			bankIdSignedBy: null,
			addMembers: false,
			isForeignCompany
		})
	}

	onSkipAuthorise = (callback, verifyToCustomer) => {
		const {
			isForeignCompany,
			selectedCompanyDetails: { orgNumber, name, country }
		} = this.state
		this.props.createUnverifiedCompany(
			orgNumber,
			this.props.user.email,
			this.props.user.name,
			name,
			callback,
			verifyToCustomer,
			isForeignCompany,
			country
		)
	}

	bankIdCallback = (bankIdData) => {
		this.setState({ bankIdTransaltionId: bankIdData.bankIdMessage })
	}

	successCallback = (responseData) => {
		this.setState({ bankIdSigned: true })
	}

	errorCallback = () => {
		this.setState({ bankIdSignedBy: null })
		this.setState({ bankIdTransaltionId: null })
	}

	authorise(ssn) {
		const { getContinueBankIdCollect, user } = this.props
		const { orgNumber, name } = this.state.selectedCompanyDetails

		this.setState({ bankIdSignedBy: ssn })

		this.props.signAndCreateCompany(
			orgNumber,
			ssn,
			this.props.user.email,
			this.props.user.name,
			name,
			user.phone,
			this.bankIdCallback,
			this.successCallback,
			this.errorCallback,
			getContinueBankIdCollect
		)
	}

	render() {
		const { companyUrlAlias, onRef, isInvonoUser } = this.props

		const steps = [
			{
				name: 'CompanySearch',
				component: (
					<CompanySearch
						selectedCompany={this.state.selectedCompany}
						selectedCompanyDetails={this.state.selectedCompanyDetails}
						onCancel={this.onCancel.bind(this)}
						onChange={this.selectCompany.bind(this)}
					/>
				)
			},
			{
				name: 'AuthoriseCompany',
				component: (
					<AuthoriseCompany
						bankIdTransaltionId={this.state.bankIdTransaltionId}
						selectedCompanyDetails={this.state.selectedCompanyDetails}
						companyRepresentatives={this.state.selectedCompanyRepresentatives}
						onSkipAuthorise={this.onSkipAuthorise}
						bankIdSigned={this.state.bankIdSigned}
						bankIdSignedBy={this.state.bankIdSignedBy}
						onCancel={this.onCancel.bind(this)}
						onAuthorise={this.authorise.bind(this)}
						companyUrlAlias={companyUrlAlias}
						isInvonoUser={isInvonoUser}
					/>
				)
			},
			{ name: 'CompanyMembers', component: <CompanyMembers /> }
		]

		return (
			<div className='account-wizard' ref={onRef && onRef}>
				<StepZilla steps={steps} showNavigation={false} showSteps={false} dontValidate={true} />
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		user: state.user.toJS().userObj,
		isInvonoUser: state.user.getIn(['userObj', 'invonoAdmin']) === true,
		companyUrlAlias: state.company.company && state.company.company.urlAlias
	}
}

const mapDispatchToProps = {
	signAndCreateCompany,
	createUnverifiedCompany,
	companyChanged
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountWizard)
