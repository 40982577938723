import React from 'react'
import { string, bool, array, func, oneOf, oneOfType, node } from 'prop-types'
import styled, { css } from 'styled-components'
import Dropzone from 'react-dropzone'
import Text from '../text/text'
import Icon from '../icon/icon'

const StyledDropzone = styled(Dropzone)`
	display: flex;
	flex: 1;
	min-height: 150px;
	background: ${(props) => (props.transparent === 'true' ? 'transparent' : props.theme.colors.solitudeLight)};
	padding: ${(props) => props.theme.spacing[5]};
	color: ${(props) => props.theme.colors.lightGrey};
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

const StyledSmallDropzone = styled(StyledDropzone)`
	min-height: 36px;
	padding: ${(props) => props.theme.spacing[3]};
	border: 2px dashed ${(props) => props.theme.colors.solitudeDark};

	${(props) =>
		props.hidden === true &&
		css`
			display: none;
		`}
`

const StyledMiniDropzone = styled(StyledDropzone)`
	min-height: 60px;
	height: 60px;
	width: 60px;
	padding: ${(props) => props.theme.spacing[3]};
	border: 2px dashed ${(props) => props.theme.colors.solitudeDark};
	border-radius: 20px;
`

const StyledContent = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px dashed ${(props) => props.theme.colors.solitudeDark};
	padding: ${(props) => props.theme.spacing[3]};
`

const StyledSmallContent = styled(StyledContent)`
	flex-direction: row;
	border: none;
	padding: 0;

	${(props) =>
		props.iconRightMargin &&
		css`
			> :first-child {
				margin-right: ${(props) => props.theme.spacing[props.iconRightMargin]};
			}
		`}
`

const StyledSimpleDropzone = styled(StyledDropzone)`
	min-height: 0;
	background: transparent;
	padding: 0;
	flex-direction: row;

	> :first-child {
		margin-right: ${(props) => props.theme.spacing[4]};
	}
`

const StyledInfo = styled.div`
	display: block;

	p {
		margin-bottom: 0;
	}
`

const StyledFullScreenDropzone = styled(Dropzone)`
	${(props) =>
		props.hidden === true &&
		css`
			display: none;
		`}
`

const renderMiniContent = (isUploading, dropzoneIcon) => {
	const icon = isUploading ? 'faSpinner' : dropzoneIcon
	const iconSpin = isUploading

	return (
		<StyledSmallContent>
			<Icon icon={icon} size='lg' type='light' spin={iconSpin} />
		</StyledSmallContent>
	)
}

const renderSmallContent = (isUploading, dropzoneIcon, dropzoneTid1, dropzoneTid2) => {
	const icon = isUploading ? 'faSpinner' : dropzoneIcon
	const iconSpin = isUploading

	return (
		<StyledSmallContent iconRightMargin={4}>
			<Icon icon={icon} size='xl' type='light' spin={iconSpin} />
			<StyledInfo>
				<Text bold={700} textTransform='uppercase' tid={dropzoneTid1} />
				<Text tag='p' color='muted' fontSize='11px' lineHeight={1.25} tid={dropzoneTid2} />
			</StyledInfo>
		</StyledSmallContent>
	)
}

const ReactDropzone = (props) => {
	const {
		size,
		name,
		multiple,
		accept,
		readOnly,
		onDrop,
		transparent,
		children,
		dropzoneRef,
		hidden,
		isUploading,
		dropzoneIcon,
		dropzoneTid1,
		dropzoneTid2
	} = props

	if (size === 'mini') {
		return (
			<StyledMiniDropzone
				name={name}
				multiple={multiple}
				transparent={transparent}
				accept={accept}
				disabled={readOnly}
				onDrop={onDrop}>
				{renderMiniContent(isUploading, dropzoneIcon)}
			</StyledMiniDropzone>
		)
	}

	if (size === 'small') {
		return (
			<StyledSmallDropzone
				hidden={hidden}
				name={name}
				multiple={multiple}
				transparent={transparent}
				accept={accept}
				disabled={readOnly}
				onDrop={onDrop}>
				{renderSmallContent(isUploading, dropzoneIcon, dropzoneTid1, dropzoneTid2)}
			</StyledSmallDropzone>
		)
	}

	if (size === 'full-screen') {
		return (
			<StyledFullScreenDropzone
				hidden={hidden}
				name={name}
				multiple={multiple}
				accept={accept}
				transparent={transparent}
				disabled={readOnly}
				onDrop={onDrop}
				ref={dropzoneRef}>
				{children}
			</StyledFullScreenDropzone>
		)
	}

	if (size === 'modern') {
		return (
			<StyledSimpleDropzone name={name} multiple={multiple} accept={accept} disabled={readOnly} onDrop={onDrop}>
				<Icon icon={dropzoneIcon} size='xl' type='light' color='white' />
				<Text textTransform='uppercase' tid={dropzoneTid1} color='white' />
			</StyledSimpleDropzone>
		)
	}
}

ReactDropzone.defaultProps = {
	size: 'small',
	name: 'files',
	multiple: false,
	accept: '',
	readOnly: false,
	hidden: false,
	isUploading: false,
	dropzoneIcon: 'faCloudUploadAlt',
	dropzoneTid1: 'generic.form.dnd_upload_text1',
	dropzoneTid2: 'generic.form.dnd_upload_text2'
}

ReactDropzone.propTypes = {
	children: node,
	size: oneOf(['small', 'full-screen', 'mini', 'modern']),
	name: string.isRequired,
	multiple: bool,
	accept: oneOfType([string, array]),
	readOnly: bool,
	onDrop: func.isRequired,
	dropzoneRef: func,
	hidden: bool,
	transparent: oneOf(['true', 'false']),
	isUploading: bool,
	dropzoneIcon: string,
	dropzoneTid1: string,
	dropzoneTid2: string
}

export default ReactDropzone
