import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import CompanySearch from '../../dumb-components/dashboard/company-search/company-search'

class CompanySearchContainer extends Component {
	state = {
		form: {
			country: 'SE',
			name: '',
			orgNumber: ''
		}
	}

	static propTypes = {
		onSelectCompany: func,
		onChange: func
	}

	onChange = (fieldName, val) => {
		this.setState((prevState) => {
			if (fieldName === 'orgNumber') {
				val = val.replace(/[^\w]/gi, '').toUpperCase()
			}

			return { form: { ...prevState.form, [fieldName]: val } }
		})
	}

	onBlur = () => {
		const { onChange } = this.props

		onChange?.({ company: this.state.form })
	}

	render = () => {
		const { invonoAdmin, onSelectCompany } = this.props
		const { form } = this.state

		return (
			<CompanySearch
				{...form}
				hideCountrySelect={invonoAdmin}
				onSelectCompany={onSelectCompany}
				onChange={this.onChange}
				onBlur={this.onBlur}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		invonoAdmin: store.user.getIn(['userObj', 'invonoAdmin'])
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(CompanySearchContainer)
