import React, { Component } from 'react'
import SharesSetupStartpage from './shares-setup--startpage'
import ScrollView from '../../../dumb-components/shared/layout/scroll-view/scroll-view'
import SharesSetup from './shares-setup'

class Shares extends Component {
	render() {
		const { match } = this.props

		return (
			<div className='i-content i-content--pad-right-half'>
				<div className='i-content__body'>
					<div className='i-content__container'>
						<ScrollView showOnHover={true} autoHide={true} noLeftMargin={true}>
							{match.params.setupType ? (
								<SharesSetup match={match} location={this.props.location} history={this.props.history} />
							) : (
								<SharesSetupStartpage match={match} location={this.props.location} history={this.props.history} />
							)}
						</ScrollView>
					</div>
				</div>
			</div>
		)
	}
}

export default Shares
