import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl'
import { fromJS, Map, List } from 'immutable'
import debounce from 'lodash/debounce'
import ScrollView from '../../../../dumb-components/shared/layout/scroll-view/scroll-view'
import {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	deleteLastTransaction,
	cancelTemporaryTransaction
} from '../../../../actions/transaction.actions'
import { addErrorNotification, addInfoNotification } from '../../../../actions/notify.actions'
import DatePicker from '../../../../dumb-components/shared/datepicker/datepicker'
import Panel from '../../../../dumb-components/panel'
import Field from '../../../../dumb-components/fields/field'
import { validateTransactionDate, isRequired } from '../../../../modules/validation.module'
import immutableForm from '../../../framework/immutable-form'
import ShareholderOptionRenderer from '../shares-setup/shareholder-options-renderer'
import ShareholderValueRenderer from '../shares-setup/shareholder-value-renderer'
import { formatIdPretty } from '../../../helpers/users'
import Select from '../../../../dumb-components/fields/select'
import Input from '../../../../dumb-components/shared/input/input'
import { getFullShareTypeLabel } from '../../../helpers/shares'
import Moment from '../../../../modules/moment.module'
import Checkbox from '../../../../dumb-components/shared/checkbox/checkbox'
import AttachmentsContainer from '../../../../containers/shared/attachments.container'
import CommentsListContainer from '../../../../containers/comments/comments-list.container'
import TransactionToolbarControlsContainer from '../../../../containers/shares/transaction-toolbar-controls.container'
import { OBJ_TYPE_SHARE } from '/shared/constants'

const TRANSACTIONTYPE = 'CHANGE-PLEDGE'

const initialTransaction = fromJS({
	type: TRANSACTIONTYPE,
	handlerData: {}
})

const de = debounce((func, type, transaction) => {
	func(type, transaction.toJS())
}, 1000)

class SharesPledgeForm extends Component {
	static propTypes = {
		intl: intlShape.isRequired
	}

	componentDidMount = () => {
		const { tmpTransaction } = this.props

		this.props.fetchTemporaryTransaction(TRANSACTIONTYPE)
		this.setValidators(tmpTransaction)
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.tmpTransaction !== prevProps.tmpTransaction) {
			this.setValidators(this.props.tmpTransaction)
		}
	}

	disableSelectedShareholder = (shareholdersOptions, valueToDisable) => {
		return shareholdersOptions.map((obj) => {
			if (obj.get('value') === valueToDisable) {
				obj = obj.set('disabled', true)
			}

			return obj
		})
	}

	getShareholdersOptions = () => {
		const { investors, transaction } = this.props
		const balances = transaction.get('balances')

		if (!investors) {
			return []
		}

		const investorsArr = []
		investors.forEach((obj) => {
			if (balances.get(obj.get('id'))) {
				const investorTypeOfOwner = obj.get('investorTypeOfOwner')
				const name = obj.getIn(['investorInformation', 'name'])
				const id = obj.getIn(['investorInformation', 'id'])
				let label = ''

				if (investorTypeOfOwner === 'private') {
					label = `${name}[split-here]${formatIdPretty(id)}`
				} else {
					label = name
				}

				investorsArr.push({
					value: obj.get('id'),
					label
				})
			}
		})

		return investorsArr
	}

	findSelectedInvestor = () => {
		const { investors, tmpTransaction } = this.props
		let currentInvestor = List()

		if (investors && tmpTransaction) {
			currentInvestor = investors.find((inv) => {
				return inv.get('id') === tmpTransaction.getIn(['handlerData', 'investorId'])
			})
		}

		return currentInvestor || List()
	}

	setValidators = (tmpTransaction) => { }

	onChange = (field, val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction } = this.props
		let newTransaction = tmpTransaction.setIn(['handlerData', field], val)

		const action = tmpTransaction.getIn(['handlerData', 'action'])

		if (field === 'action') {
			newTransaction = newTransaction.removeIn(['handlerData', 'sequences'])
			newTransaction = newTransaction.removeIn(['handlerData', 'investorId'])
			newTransaction = newTransaction.removeIn(['handlerData', 'reference'])
		}

		if (field === 'investorId') {
			newTransaction = newTransaction.removeIn(['handlerData', 'sequences'])
			newTransaction = newTransaction.removeIn(['handlerData', 'reference'])
		}

		if (field === 'reference' && (action === 'manage' || action === 'remove')) {
			newTransaction = newTransaction.removeIn(['handlerData', 'sequences'])
		}

		this.props.resetErrors('handlerData.' + field)
		updateTransaction(TRANSACTIONTYPE, newTransaction)
		de(saveTemporaryTransaction, TRANSACTIONTYPE, newTransaction)
	}

	onChangeDate = (val) => {
		const { tmpTransaction, updateTransaction, saveTemporaryTransaction } = this.props
		const newTransaction = tmpTransaction.set('date', val)
		this.props.resetErrors('date')
		updateTransaction(TRANSACTIONTYPE, newTransaction)
		de(saveTemporaryTransaction, TRANSACTIONTYPE, newTransaction)
	}

	onChangeAttachments = (field, val) => {
		const { tmpTransaction } = this.props
		let attachedDocuments = tmpTransaction.getIn(['handlerData', field], List())

		attachedDocuments = attachedDocuments.push(val)
		this.onChange(field, attachedDocuments)
	}

	onReorderAttachments = (field, docIds) => {
		this.onChange(field, docIds)
	}

	onSubmit = () => {
		const { tmpTransaction, createTransaction } = this.props

		if (this.props.validate(tmpTransaction)) {
			createTransaction(tmpTransaction.toJS(), () => {
				//resetAdjustmentTransaction();
			})
		}
	}

	cancelTransaction = () => {
		this.props.cancelTemporaryTransaction(TRANSACTIONTYPE)
		//this.props.resetAdjustmentTransaction();
	}

	onSequenceChecked = (sequenceIndex, checked) => {
		const { tmpTransaction } = this.props
		let handlerDataSequences = tmpTransaction.getIn(['handlerData', 'sequences'], List())

		const index = handlerDataSequences.findIndex((sequence) => {
			return sequence.get('index') === sequenceIndex
		})
		if (index > -1) {
			handlerDataSequences = handlerDataSequences.setIn([index, 'pledge'], checked)
		} else {
			handlerDataSequences = handlerDataSequences.push(
				Map({
					index: sequenceIndex,
					pledge: checked
				})
			)
		}

		this.onChange('sequences', handlerDataSequences)
	}

	onSequenceSelectAllChecked = (checked) => {
		const { tmpTransaction, transaction, updateTransaction, saveTemporaryTransaction } = this.props
		const investmentId = tmpTransaction.getIn(['handlerData', 'investorId'])
		let sequences = transaction.get('sequences', List())
		let handlerDataSequences = tmpTransaction.getIn(['handlerData', 'sequences'], List())
		const sequencesChanged = []

		sequences = sequences.map((sequence, index) => {
			sequence = sequence.set('index', index)
			return sequence
		})

		sequences = sequences.filter((sequence) => {
			return sequence.get('investmentId') === investmentId
		})

		sequences.map((sequence) => {
			const index = handlerDataSequences.findIndex((obj) => {
				return obj.get('index') === sequence.get('index')
			})

			if (index >= 0) {
				handlerDataSequences = handlerDataSequences.setIn([index, 'pledge'], checked)
			} else {
				handlerDataSequences = handlerDataSequences.push(
					Map({
						index: sequence.get('index'),
						pledge: checked
					})
				)
			}

			sequencesChanged.push(sequence.get('index'))
		})

		this.setState({ sequencesChanged })
		let newTransaction = tmpTransaction.setIn(['handlerData', 'sequences'], handlerDataSequences)
		newTransaction = newTransaction.setIn(['handlerData', 'allChecked'], checked)
		this.props.resetErrors('handlerData.sequences')
		updateTransaction(TRANSACTIONTYPE, newTransaction)
		de(saveTemporaryTransaction, TRANSACTIONTYPE, newTransaction)
	}

	renderButtons = () => {
		const { tmpTransaction } = this.props

		if (!tmpTransaction) {
			return null
		}

		/** TODO */
		// const investments = tmpTransaction.getIn(['handlerData', 'investments']);

		return (
			<div className='i-content__tabs i-content__tabs--fix-padding'>
				<TransactionToolbarControlsContainer onSubmit={this.onSubmit} onCancel={this.cancelTransaction} />
				{/*!investments && (<button className="btn btn-primary" onClick={this.onSubmit}><span className="fa fa-check fa-lg"></span> <FormattedMessage id="register_transaction"/></button>)}
					<DropdownButton dropdownPosition="right" dropdownAddClass="fa-ul" iconTitle="fa fa-ellipsis-h" cssClass="btn-more-action btn-default">
						<a href="#" onClick={this.cancelTransaction.bind(this)}><span className="fa fa-li fa-times fa-lg"></span> <FormattedMessage id="service.shares.share_pledge_cancel" /></a>
					</DropdownButton>
					*/}
			</div>
		)
	}

	renderAddPledgeTableData = (sequence, index) => {
		const { tmpTransaction, transaction } = this.props
		const seqFrom = sequence.get('sequenceFrom')
		const seqTo = sequence.get('sequenceTo')
		const sequenceIndex = sequence.get('index')
		const handlerData = tmpTransaction.getIn(['handlerData', 'sequences'], List())
		const pledgeIds = transaction.getIn(['sequences', sequenceIndex, 'pledgeIds'], List())

		const handlerDataSequence = handlerData.find((obj) => {
			return obj.get('index') === sequenceIndex
		})
		return (
			<div key={index}>
				<div className='list__item'>
					<div className='list__item__text-area list__item__text-area--w120'>
						<span className='list__item__input list__item__input--pad-lft list__item__input--pad-right'>
							{seqFrom} - {seqTo}
						</span>
					</div>
					<div className='list__item__body'>
						<span>{getFullShareTypeLabel(sequence.get('type'))}</span>
					</div>
					<div className='list__item__body text--align-right'>
						{pledgeIds && pledgeIds.size > 0 && (
							<div className='icon-container text-lg'>
								<i className='fa fa-exclamation'></i>
							</div>
						)}
					</div>
					<div className='pad-hor'>
						<div className='list__item__input list__item__input--pad-right'>
							<Checkbox
								mode='modern-big'
								checked={handlerDataSequence && handlerDataSequence.get('pledge')}
								onChange={this.onSequenceChecked.bind(this, sequenceIndex)}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderManagePledgeTableData = (sequence, index) => {
		const { tmpTransaction } = this.props
		const pledgeId = tmpTransaction.getIn(['handlerData', 'reference'])
		const action = tmpTransaction.getIn(['handlerData', 'action'])
		const handlerDataSequences = tmpTransaction.getIn(['handlerData', 'sequences'], List())

		const seqFrom = sequence.get('sequenceFrom')
		const seqTo = sequence.get('sequenceTo')
		const sequenceIndex = sequence.get('index')

		const pledgeIds = sequence.get('pledgeIds', List())
		let pledgeExists = pledgeIds.includes(pledgeId)

		const i = handlerDataSequences.findIndex((sequence) => {
			return sequence.get('index') === sequenceIndex
		})

		if (i > -1) {
			pledgeExists = handlerDataSequences.getIn([i, 'pledge'])
		}

		return (
			<div key={index}>
				<div className='list__item'>
					<div className='list__item__text-area list__item__text-area--w120'>
						<span className='list__item__input list__item__input--pad-lft list__item__input--pad-right'>
							{seqFrom} - {seqTo}
						</span>
					</div>
					<div className='list__item__body'>
						<span>{getFullShareTypeLabel(sequence.get('type'))}</span>
					</div>
					<div className='pad-hor'>
						<div className='list__item__input list__item__input--pad-right'>
							<Checkbox
								mode='modern-big'
								checked={pledgeExists}
								disabled={action === 'remove'}
								onChange={this.onSequenceChecked.bind(this, sequenceIndex)}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderPledgeTable = (mode) => {
		const { tmpTransaction, investors, transaction } = this.props
		const investmentId = tmpTransaction.getIn(['handlerData', 'investorId'])
		let sequences = transaction.get('sequences', List())

		sequences = sequences.map((sequence, index) => {
			sequence = sequence.set('index', index)
			return sequence
		})

		sequences = sequences.filter((sequence) => {
			return sequence.get('investmentId') === investmentId
		})

		let warn = false
		for (const sequence of sequences) {
			if (sequence.get('pledgeIds').size > 0) {
				warn = true
				break
			}
		}

		if (!investors) {
			return null
		}

		const investor = investors.find((inv) => {
			return inv.get('id') === investmentId
		})

		if (!investor) {
			return null
		}

		return (
			<div>
				<div className='list__list-header'>
					<div className='list__list-header__body list__list-header__text-area--pad-lft list__list-header__text-area--pad-right'>
						<FormattedMessage id='sequence' />
					</div>
					<div className='list__list-header__body list__list-header__text-area--pad-lft list__list-header__text-area--pad-right'>
						<FormattedMessage id='shares.transactions.split.class_of_shares' />
					</div>
					<div className='list__list-header__text-area list__list-header__text-area--pad-right list__list-header__text-area--right'></div>
					<div className='list__list-header__text-area list__list-header__text-area--pad-right list__list-header__text-area--right mar-rgt'>
						{mode !== 'remove' && (
							<Checkbox
								mode='modern-big'
								onChange={this.onSequenceSelectAllChecked}
								checked={tmpTransaction.getIn(['handlerData', 'allChecked'])}
							/>
						)}
					</div>
				</div>
				<div className='list list--table list--striped'>
					{mode === 'add' && sequences.map(this.renderAddPledgeTableData)}
					{(mode === 'manage' || mode === 'remove') && sequences.map(this.renderManagePledgeTableData)}
				</div>
				{warn && mode === 'add' && (
					<div className='alert alert-invono alert--icon mar-btm-0'>
						<div className='icon-container pad-lft'>
							<i className='fa fa-exclamation'></i>
						</div>
						<div className='alert-text'>
							<FormattedHTMLMessage id='shares.transactions.share_pledge.warning' />
						</div>
					</div>
				)}
			</div>
		)
	}

	renderAddPledgePanel = () => {
		const investor = this.findSelectedInvestor()
		const investorId = investor.getIn(['investorInformation', 'id'])

		return (
			<Panel
				renderHeader={() => {
					return (
						<span>
							{investor.getIn(['investorInformation', 'name'])}{' '}
							<span className='text--muted'>{formatIdPretty(investorId)}</span>
						</span>
					)
				}}
				padHor>
				{this.renderPledgeTable('add')}
			</Panel>
		)
	}

	renderManagePledgePanel = () => {
		const { tmpTransaction } = this.props
		const investor = this.findSelectedInvestor()
		const investorId = investor.getIn(['investorInformation', 'id'])

		return (
			<Panel
				renderHeader={() => {
					return (
						<span>
							{investor.getIn(['investorInformation', 'name'])}{' '}
							<span className='text--muted'>{formatIdPretty(investorId)}</span>
						</span>
					)
				}}
				padHor>
				<Field>{this.renderPledgeTable(tmpTransaction.getIn(['handlerData', 'action']))}</Field>
			</Panel>
		)
	}

	renderNotesPanel = () => {
		const { tmpTransaction } = this.props
		const objId = `${TRANSACTIONTYPE}-${tmpTransaction.getIn(['handlerData', 'investorId'])}`

		return (
			<CommentsListContainer
				objType={OBJ_TYPE_SHARE}
				objId={objId}
				objTitle={window.location.href}
				objUrl={window.location.href}
			/>
		)
	}

	renderAttachments = () => {
		const { tmpTransaction, transaction } = this.props
		const investorId = tmpTransaction.getIn(['handlerData', 'investorId'])
		const reference = tmpTransaction.getIn(['handlerData', 'reference'])
		let files = transaction.getIn(['shareData', 'pledges', investorId, reference, 'attachedDocuments'])
		const newFiles = tmpTransaction && tmpTransaction.getIn(['handlerData', 'attachedDocuments'])

		if (newFiles && files) {
			files = files.concat(newFiles)
		} else if (newFiles && !files) {
			files = newFiles
		}

		return (
			<AttachmentsContainer
				fieldName='attachedDocuments'
				onUpload={this.onChangeAttachments}
				onReorderFiles={this.onReorderAttachments}
				files={files}
				objType={OBJ_TYPE_SHARE}
				objId={`${investorId}$${reference?.replace(/[^\w]/gi, '')}`}
				marginBottom
				multiple
			/>
		)
	}

	render = () => {
		const { tmpTransaction, transaction, errors, i18n } = this.props

		const pledgeTypeOptions = [
			{ label: i18n.messages['shares.transaction.change-pledge.add'], value: 'add' },
			{ label: i18n.messages['shares.transaction.change-pledge.manage'], value: 'manage' },
			{ label: i18n.messages['shares.transaction.change-pledge.remove'], value: 'remove' }
		]

		const investorId = tmpTransaction.getIn(['handlerData', 'investorId'])
		const selectedType = tmpTransaction.getIn(['handlerData', 'action'])
		const reference = tmpTransaction.getIn(['handlerData', 'reference'])
		const investorPledges = transaction.getIn(['shareData', 'pledges', investorId], List())

		const pledgeOptions = []

		investorPledges.forEach((obj) => {
			pledgeOptions.push({
				value: `${obj.get('id')}`,
				label: `${obj.get('reference')}[split-here]${Moment(obj.get('date')).format('L')}`
			})
		})

		return (
			<div className='i-content__container'>
				{this.renderButtons()}
				<ScrollView autoHide={true} showOnHover={true}>
					<Panel tid='shares.transactions.shares_pledge.secondary_name'>
						<Field name='date' errors={errors} tid='generic.date' className='col-md-5 col-md-offset-7'>
							<DatePicker
								hasError={errors ? errors.get('date', List()).size > 0 : false}
								calendarPlacement='bottom-end'
								value={tmpTransaction.get('date')}
								onChange={this.onChangeDate}
								language={i18n.language}
							/>
						</Field>

						<Field name='handlerData.action' errors={errors} tid='generic.type'>
							<Select
								value={selectedType}
								options={pledgeTypeOptions}
								simpleValue
								placeholder={i18n.messages['select_placeholder']}
								onSelect={(val) => {
									this.onChange('action', val)
								}}
							/>
						</Field>

						<Field
							name='handlerData.investorId'
							errors={errors}
							tid='shareholder'
							className='form-group--mar-btm-10 col-md-12'
						>
							<Select
								optionComponent={ShareholderOptionRenderer}
								valueComponent={ShareholderValueRenderer}
								options={this.getShareholdersOptions()}
								simpleValue
								value={investorId}
								placeholder={i18n.messages['select_placeholder']}
								onSelect={(iid) => {
									this.onChange('investorId', iid)
								}}
							/>
						</Field>

						{selectedType === 'add' && (
							<Field
								name='handlerData.reference'
								errors={errors}
								tid='reference'
								className='form-group--mar-btm-10 col-md-12'
							>
								<Input fieldName='reference' placeholderTid='create_ref' onChange={this.onChange} value={reference} />
							</Field>
						)}

						{(selectedType === 'manage' || selectedType === 'remove') && (
							<Field
								name='handlerData.reference'
								errors={errors}
								tid='reference'
								className='form-group--mar-btm-10 col-md-12'
							>
								<Select
									optionComponent={ShareholderOptionRenderer}
									valueComponent={ShareholderValueRenderer}
									simpleValue
									options={pledgeOptions}
									placeholder={i18n.messages['select_placeholder']}
									onSelect={(val) => {
										this.onChange('reference', val)
									}}
									value={reference}
								/>
							</Field>
						)}
					</Panel>
					{selectedType === 'add' && investorId && this.renderAddPledgePanel()}
					{(selectedType === 'manage' || selectedType === 'remove') && reference && this.renderManagePledgePanel()}
					{((selectedType === 'add' && investorId) || (selectedType === 'manage' && investorId && reference)) &&
						this.renderAttachments()}
					{((selectedType === 'add' && investorId) || (selectedType === 'manage' && investorId && reference)) &&
						this.renderNotesPanel()}
				</ScrollView>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get('transaction'),
		tmpTransaction: state.transaction.getIn(['tmpTransaction', TRANSACTIONTYPE], initialTransaction),
		investors: state.investors.get('list'),
		i18n: state.i18n
	}
}

const mapActionsToProps = {
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	updateTransaction,
	addErrorNotification,
	addInfoNotification,
	deleteLastTransaction,
	cancelTemporaryTransaction
}

const validators = fromJS({
	date: {
		tid: 'generic.date',
		rules: [
			{ func: isRequired, message: 'validation.is_required' },
			{
				func: validateTransactionDate,
				message: 'validation.current_transaction_date_must_be_later_then_last_transaction'
			}
		]
	},
	'handlerData.investorId': {
		tid: 'generic.transactions.issue_share_certificates.form.shareholder',
		rules: [{ func: isRequired, message: 'validation.is_required' }]
	},
	'handlerData.action': {
		tid: 'generic.type',
		rules: [{ func: isRequired, message: 'validation.is_required' }]
	},
	'handlerData.reference': {
		tid: 'reference',
		rules: [{ func: isRequired, message: 'validation.is_required' }]
	}
})

SharesPledgeForm = immutableForm(SharesPledgeForm, 'sharesPledge', validators)
SharesPledgeForm = injectIntl(SharesPledgeForm)

export default connect(mapStateToProps, mapActionsToProps)(SharesPledgeForm)
