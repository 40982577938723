import React, { Component } from 'react'
import { connect } from 'react-redux'
import immutablePropTypes from 'react-immutable-proptypes'
import { string, array, func, bool, oneOfType } from 'prop-types'
import { Map, List } from 'immutable'
import { fetchCompanyUsers } from '../../actions/company.actions'
import { Select } from '../../dumb-components/shared/select'
import PinableSelectOption from '../../dumb-components/shared/object-filter/helper-components/pinable-select-option'

import { formatCompanyUsersAndRolesAsOptions } from '../../components/helpers/company.helper'

import {
	EVENT_TYPE_COMPANY_MEMBER_ADDED,
	EVENT_TYPE_COMPANY_MEMBER_UPDATE,
	EVENT_TYPE_COMPANY_MEMBER_REMOVE
} from '/shared/constants'

class CompanyMembersSelectContainer extends Component {
	state = {
		options: List()
	}

	static propTypes = {
		fieldName: string,
		onChange: func,
		value: string,
		readOnly: bool,
		placeholderTid: string,
		isClearable: bool,
		includeNoneOption: bool,
		noneOptionValue: string,
		noneOptionLabelTid: string,
		prependOptions: immutablePropTypes.list,
		defaultValue: string,
		labelIsTid: bool
	}

	static defaultProps = {
		labelIsTid: true
	}

	componentDidMount = () => {
		const { fetchCompanyUsers, company, companyMembers } = this.props

		if ((!companyMembers || companyMembers.size === 0) && company && company.id) {
			fetchCompanyUsers(company.id)
		}

		if (companyMembers && companyMembers.size) {
			this.parseCompanyUsersAndRoles()
		}
	}

	componentDidUpdate = (prevProps) => {
		const { fetchCompanyUsers, company, companyMembers } = this.props

		this.checkLiveUpdateEvents(prevProps)

		if (prevProps.company !== company) {
			fetchCompanyUsers(company.id)
		}

		if (prevProps.companyMembers !== companyMembers) {
			this.parseCompanyUsersAndRoles()
		}
	}

	checkLiveUpdateEvents = () => {
		const { audit, fetchCompanyUsers, company } = this.props
		const companyMemberAdded = audit.get(EVENT_TYPE_COMPANY_MEMBER_ADDED)
		const companyMemberUpdate = audit.get(EVENT_TYPE_COMPANY_MEMBER_UPDATE)
		const companyMemberRemove = audit.get(EVENT_TYPE_COMPANY_MEMBER_REMOVE)

		if (
			(companyMemberAdded && companyMemberAdded.get('refresh') === true) ||
			(companyMemberUpdate && companyMemberUpdate.get('refresh') === true) ||
			(companyMemberRemove && companyMemberRemove.get('refresh') === true)
		) {
			fetchCompanyUsers(company.id)
		}
	}

	parseCompanyUsersAndRoles = () => {
		const { companyMembers } = this.props
		const options = formatCompanyUsersAndRolesAsOptions({ companyMembers })

		this.setState({ options })
	}

	onChange = (val) => {
		const { fieldName, onChange } = this.props

		if (fieldName) {
			return onChange(fieldName, val)
		}

		return onChange(val)
	}

	renderFormatOptionLabel = (option) => {
		const { defaultValue, labelIsTid } = this.props

		return <PinableSelectOption defaultValue={defaultValue} option={option} labelIsTid={labelIsTid} />
	}

	render = () => {
		const {
			placeholderTid,
			companyMembers,
			value,
			isClearable,
			includeNoneOption,
			noneOptionValue,
			noneOptionLabelTid,
			i18n,
			prependOptions,
			defaultValue
		} = this.props
		let { options } = this.state

		if (prependOptions) {
			options = prependOptions.concat(options)
		}

		if (includeNoneOption) {
			options = options.unshift(
				Map({
					value: noneOptionValue,
					label: defaultValue ? noneOptionLabelTid : i18n.messages[noneOptionLabelTid]
				})
			)
		}

		const props = {
			placeholderTid,
			onChange: this.onChange,
			options,
			value,
			isClearable
		}

		if (defaultValue) {
			props.formatOptionLabel = this.renderFormatOptionLabel
		}

		return <Select {...props} />
	}
}

const mapStoreToProps = (store) => {
	return {
		companyMembers: store.company.members,
		company: store.company.company,
		audit: store.audit.get('company'),
		i18n: store.i18n
	}
}

const mapActionsToProps = {
	fetchCompanyUsers
}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyMembersSelectContainer)
