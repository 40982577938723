import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { SPACING } from '../../../../theme'

const StyledList = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
`

const StyledHeader = styled.div`
	display: flex;

	${(props) =>
		props.headerOffsetScrollbars &&
		css`
			padding-right: ${SPACING['SCROLL_WIDTH']}px;
		`}
`

const StyledBody = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`

export default class List extends PureComponent {
	static defaultProps = {
		headerOffsetScrollbars: false
	}

	renderHeader = (header) => {
		const { headerOffsetScrollbars } = this.props

		return <StyledHeader headerOffsetScrollbars={headerOffsetScrollbars}>{header && header()}</StyledHeader>
	}

	render = () => {
		const { children, header, className } = this.props

		return (
			<StyledList className={className}>
				{this.renderHeader(header)}
				<StyledBody>{children}</StyledBody>
			</StyledList>
		)
	}
}
