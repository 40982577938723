import React from 'react'
import styled from 'styled-components'
import Icon from '../../shared/icon/icon'
import ColoredContentWrapper from '../colored-content-wrapper/colored-content-wrapper'

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
`

const StyledIconWrapper = styled.div`
	padding-bottom: ${(props) => props.theme.spacing[3]};
`

export default function YellowWarningWrapper({ children }) {
	return (
		<StyledColoredContentWrapper type='secondary'>
			<StyledIconWrapper>
				<Icon icon='faExclamationTriangle' size='normal' color='yellowMid' type='solid' />
			</StyledIconWrapper>
			{children}
		</StyledColoredContentWrapper>
	)
}
