import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func, bool } from 'prop-types'
import { Map, List } from 'immutable'
import i18nHelper from '../../../components/helpers/i18n.helper'
import { listPreviousMeetings } from '../../../actions/meetings.actions'
import { listMeetingTemplates } from '../../../actions/meeting-templates.actions'
import { fetchSimpleUsers } from '../../../actions/usersCache.actions'
import { listGroups } from '../../../actions/groups.actions'
import { filter } from '../../../components/helpers/filter-and-sort.helper'
import Moment from '../../../modules/moment.module'
import UniversalModal from '../../../dumb-components/shared/modal/universal-modal'
import PreviousMeetingList from '../../../dumb-components/meetings/previous-meeting/previous-meeting-list/previous-meeting-list'
import PreviousMeetingFilter from '../../../dumb-components/meetings/previous-meeting/previous-meeting-filter/previous-meeting-filter'
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../dumb-components/shared/button-v2'

class PreviousMeetingModalContainer extends Component {
	static propTypes = {
		currentMeetingId: string,
		selectedPreviousMeeting: string,
		currentMeetingGroupId: string,
		isOpen: bool,
		onSave: func,
		onCancel: func
	}

	state = {
		selectedMeeting: null,
		previousMeetingsMetadata: Map(),
		filteredMeetings: List(),
		filterBy: List()
	}

	componentDidMount = () => {
		const {
			listPreviousMeetings,
			listMeetingTemplates,
			listGroups,
			groups,
			currentMeetingGroupId,
			selectedPreviousMeeting
		} = this.props

		listPreviousMeetings(currentMeetingGroupId)
		listMeetingTemplates(currentMeetingGroupId)

		if (!groups || groups.size === 0) {
			listGroups()
		}

		this.setState({ selectedMeeting: selectedPreviousMeeting })
	}

	componentDidUpdate = (prevProps, prevState) => {
		const {
			listPreviousMeetings,
			currentMeetingGroupId,
			previousMeetings,
			meetingTemplates,
			usersCache,
			selectedPreviousMeeting
		} = this.props
		const { filterBy } = this.state

		if (prevProps.currentMeetingGroupId !== currentMeetingGroupId) {
			listPreviousMeetings(currentMeetingGroupId || '')
		}

		if (prevProps.previousMeetings !== previousMeetings) {
			this.filterMeetings()
			this.loadUsers()
			this.parseMetadata()
		}

		if (prevProps.meetingTemplates !== meetingTemplates || prevProps.usersCache !== usersCache) {
			this.parseMetadata()
		}

		if (prevProps.selectedPreviousMeeting !== selectedPreviousMeeting) {
			this.setState({ selectedMeeting: selectedPreviousMeeting })
		}

		if (prevState.filterBy !== filterBy) {
			this.filterMeetings()
		}
	}

	filterMeetings = () => {
		const { currentMeetingId } = this.props
		let { previousMeetings } = this.props
		const { filterBy } = this.state

		previousMeetings = previousMeetings.filter(
			(obj) => obj.get('id') !== currentMeetingId && obj.getIn(['computedValues', 'meetingIsFinished']) === true
		)

		// Sort by date, latest first
		previousMeetings = previousMeetings.sortBy((meeting) => {
			const meetingDate = meeting.get('startDate', false)
			return meetingDate && -Moment(meetingDate)
		})

		this.setState({ filteredMeetings: filter(previousMeetings, filterBy) })
	}

	loadUsers = () => {
		const { previousMeetings, fetchSimpleUsers } = this.props
		let userIds = List()

		previousMeetings &&
			previousMeetings.forEach((meeting) => {
				if (meeting.get('chairman')) {
					userIds = userIds.push(meeting.get('chairman'))
				}

				if (meeting.get('secretary')) {
					userIds = userIds.push(meeting.get('secretary'))
				}
			})

		fetchSimpleUsers(userIds)
	}

	parseMetadata = () => {
		const { previousMeetings, meetingTemplates, usersCache, i18n } = this.props
		let { previousMeetingsMetadata } = this.state

		previousMeetings.forEach((meeting) => {
			let metadata = Map()
			const id = meeting.get('id')
			const chairman = meeting.get('chairman')
			const secretary = meeting.get('secretary')
			const templateId = meeting.get('templateId')

			if (chairman && usersCache && usersCache.has(chairman)) {
				metadata = metadata.set('chairman', usersCache.getIn([chairman, 'name']))
			}

			if (secretary && usersCache && usersCache.has(secretary)) {
				metadata = metadata.set('secretary', usersCache.getIn([secretary, 'name']))
			}

			if (templateId && meetingTemplates) {
				const meetingTemplate = meetingTemplates.find((obj) => obj.get('id') === templateId)

				if (meetingTemplate) {
					metadata = metadata.set('template', meetingTemplate.get('name'))
				} else if (templateId === 'LEGACY') {
					metadata = metadata.set('template', i18n.messages['meeting.general.template_option.legacy'])
				}
			}

			previousMeetingsMetadata = previousMeetingsMetadata.set(id, metadata)
		})

		this.setState({ previousMeetingsMetadata })
	}

	onSave = () => {
		const { onSave } = this.props
		const { selectedMeeting } = this.state

		onSave(selectedMeeting)
		this.setState({ selectedMeeting: null })
	}

	onCancel = () => {
		const { onCancel } = this.props

		this.setState({ selectedMeeting: null })
		onCancel()
	}

	onSelectMeeting = (id) => {
		this.setState({ selectedMeeting: id })
	}

	onFilterChange = (val) => {
		let { filterBy } = this.state
		let filterMeetingsByDays = filterBy.find((obj) => obj.get('source') === 'filterMeetingsByDays')

		if (filterMeetingsByDays) {
			filterMeetingsByDays = filterMeetingsByDays.set('values', List([val]))
			filterBy = filterBy.map((filter) => {
				if (filter.get('source') === 'filterMeetingsByDays') {
					return filterMeetingsByDays
				}

				return filter
			})
		} else {
			filterBy = filterBy.push(Map({ source: 'filterMeetingsByDays', values: List([val]) }))
		}

		this.setState({ filterBy })
	}

	renderMeetingsList = () => {
		const { selectedMeeting, previousMeetingsMetadata, filteredMeetings } = this.state

		return (
			<PreviousMeetingList
				meetings={filteredMeetings}
				metadata={previousMeetingsMetadata}
				onSelectMeeting={this.onSelectMeeting}
				selectedMeetingId={selectedMeeting}
			/>
		)
	}

	renderModalButtons = () => {
		return (
			<FooterRightControls>
				<TransparentButton tid='generic.form.save' onClick={this.onSave} />
				<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={this.onCancel} />
			</FooterRightControls>
		)
	}

	renderFiltering = () => {
		const { filterBy } = this.state

		return <PreviousMeetingFilter onChange={this.onFilterChange} value={filterBy.getIn([0, 'values', 0])} />
	}

	render = () => {
		const { isOpen, groups, currentMeetingGroupId, i18n, company } = this.props
		const groupName =
			groups && currentMeetingGroupId
				? i18nHelper.getTranslatedValue(groups.getIn([currentMeetingGroupId, 'name']), i18n.language, company.region)
				: i18n.messages['meetings.groups.root']

		return (
			<UniversalModal
				isOpen={isOpen}
				title={groupName}
				modalFooterComponent={this.renderModalButtons()}
				modalHeaderComponent={this.renderFiltering}
				renderContent={this.renderMeetingsList}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		previousMeetings: store.meetings.get('previousMeetings'),
		groups: store.groups.get('groups'),
		meetingTemplates: store.meetingTemplates.get('list'),
		usersCache: store.usersCache.get('usersCache'),
		i18n: store.i18n,
		company: store.company.company
	}
}

const mapActionsToProps = {
	listPreviousMeetings,
	listMeetingTemplates,
	fetchSimpleUsers,
	listGroups
}

export default connect(mapStoreToProps, mapActionsToProps)(PreviousMeetingModalContainer)
