import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, bool } from 'prop-types';

import UpgradePanel from '../../../dumb-components/company/comapny-dashboard/upgrade-panel';
import GoToBillingButton from '../../notifications/onboarding/modals/shared/btn-go-to-billing.container';
import Text from '../../../dumb-components/shared/text/text';

class DashboardUpgradePanelContainer extends Component {
	getPanelData = () => {
		const { litePlan, starterPlan, premiumPlan, enterprisePlan, enterprisePlusPlan } = this.props;

		if (litePlan) {
			return {
				panelTitle: 'company.dashboard.upgrade_panel.title.lite',
				text: 'company.dashboard.upgrade_panel.lite.description'
			};
		}

		if (starterPlan) {
			return {
				panelTitle: 'company.dashboard.upgrade_panel.title.standard',
				text: 'company.dashboard.upgrade_panel.standard.description',
				tidLink: (
					<Text
						tid='company.dashboard.upgrade_panel.standard.description.link.text'
						onClick={this.onClick}
						hasUnderline
					/>
				)
			};
		}

		if (premiumPlan) {
			return {
				panelTitle: 'company.dashboard.upgrade_panel.title.premium',
				text: 'company.dashboard.upgrade_panel.premium.description'
			};
		}

		if (enterprisePlan) {
			return {
				panelTitle: 'company.dashboard.upgrade_panel.title.enterprise',
				text: 'company.dashboard.upgrade_panel.enterprise.description'
			};
		}

		if (enterprisePlusPlan) {
			return {
				panelTitle: 'company.dashboard.upgrade_panel.title.enterprise_plus',
				text: 'company.dashboard.upgrade_panel.enterprise_plus.description'
			};
		}

		// Free
		return {
			panelTitle: 'company.dashboard.upgrade_panel.title.free',
			text: 'company.dashboard.upgrade_panel.free.description'
		};
	};

	onClick = () => {
		const { i18n } = this.props;
		const url = i18n.language.startsWith('sv') ? 'https://invono.se/moten/' : 'https://invono.se/moten/';
		window.open(url, '_blank');
	};

	render = () => {
		const { enterprisePlan } = this.props;

		return (
			<UpgradePanel
				{...this.getPanelData()}
				upgradeButton={
					!enterprisePlan && <GoToBillingButton tid='company.dashboard.upgrade_panel.btn.upgrade_now' />
				}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		freePlan: store.subscriptions.getIn(['helper', 'free']),
		litePlan: store.subscriptions.getIn(['helper', 'lite']),
		starterPlan: store.subscriptions.getIn(['helper', 'starter']),
		premiumPlan: store.subscriptions.getIn(['helper', 'premium']),
		enterprisePlan: store.subscriptions.getIn(['helper', 'enterprise']),
		enterprisePlusPlan: store.subscriptions.getIn(['helper', 'enterprise_plus']),

		i18n: store.i18n
	};
};

const mapActionsToProps = {};

export default connect(mapStoreToProps, mapActionsToProps)(DashboardUpgradePanelContainer);
