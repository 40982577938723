import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { CompanyAdverts } from '../advertising/company-adverts/CompanyAdverts'
import Link from '@mui/material/Link'
import ScrollView from '../../dumb-components/shared/layout/scroll-view/scroll-view'
import { ReadMoreModal } from '../investments/modals/read-more-modal'
import IconButton from '../../mui-components/button/icon-button'
import SearchCompanyModal from './modals/SearchCompanyModal'
import AdvertCard from '../advertising/company-adverts/AdvertCard'
import CriteriaModal from './modals/criteriaModal'
import CompaniesFollowedGrid from './CompaniesFollowedGrid'
import GridPanelRightButtons from '../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons'
import Text from '../../dumb-components/shared/text/text'
import { ADVERT_SECTIONS } from '/shared/constants'
import { useDispatch, useSelector } from 'react-redux'
import { listDealflow } from '../../actions/dealflow.actions'
import { openModal as openModalViaRedux } from '../../actions/modals.actions'
import {
	CAP_TABLE_READ_MORE_MODAL,
	DEALFLOW_USER_CRITERIA_LISTS_MODAL,
	DEALFLOW_USER_CRITERIA_MODAL,
	DEALFLOW_EDIT_USER_CRITERIA_MODAL
} from '../../constants/modals'
import { resetCompanyReducer, companyChanged } from '../../actions/company.actions'
import CriteriaListsModal from './modals/criteriaListsModal'

function DealFlowContainer() {
	const { t } = useTranslation()
	const [handleModals, setHandleModals] = useState('')
	const dealflowCompanies = useSelector((state) => state.dealflow.companies)
	const isReadMoreModalOpen = useSelector(
		(state) => state.modals.getIn(['activeModal', 'name']) === CAP_TABLE_READ_MORE_MODAL
	)
	const isUserCriteriaListsModalOpen = useSelector(
		(state) => state.modals.getIn(['activeModal', 'name']) === DEALFLOW_USER_CRITERIA_LISTS_MODAL
	)
	const isUserCriteriaModalOpen = useSelector(
		(state) =>
			state.modals.getIn(['activeModal', 'name']) === DEALFLOW_USER_CRITERIA_MODAL ||
			state.modals.getIn(['activeModal', 'name']) === DEALFLOW_EDIT_USER_CRITERIA_MODAL
	)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(listDealflow())
		dispatch(resetCompanyReducer())
		dispatch(companyChanged())
	}, [])

	const openModal = (modal) => {
		// read_more, search, criteria, grid
		setHandleModals(modal)
	}

	const closeModals = () => {
		setHandleModals('')
	}

	return (
		<>
			<ScrollView noLeftMargin autoHide showOnHover>
				<Grid container spacing={2} mb={2}>
					<Grid item lg={6} md={12}>
						<Card borderradius={'true'}>
							<CardContent>
								<Typography variant='body1'>{t('dealflow.information_panel.title')}</Typography>
								<Typography variant='body2'>
									<FormattedMessage
										id={'dealflow.information_panel.paragraph'}
										values={{
											link: (
												<Link
													sx={{ pb: 0.3 }}
													onClick={() =>
														dispatch(openModalViaRedux(CAP_TABLE_READ_MORE_MODAL))
													}
													component={'button'}
													color={'positive.main'}>
													<FormattedMessage
														id={'dealflow.information_panel.link.read_more'}
													/>
												</Link>
											),
											anonymous: (
												<FormattedHTMLMessage
													id={'dealflow.information_panel.paragraph.anonymous'}
												/>
											)
										}}
									/>
								</Typography>
							</CardContent>
						</Card>
					</Grid>

					<Grid item lg={3} md={12}>
						<Card variant='dark'>
							<CardContent variant='flex'>
								<Box>
									<Typography variant='body1'>{t('dealflow.search_panel.title')}</Typography>
									<Typography variant='body2'>{t('dealflow.search_panel.paragraph')}</Typography>
								</Box>
								<IconButton icon='faPlus' color='white' onClick={() => openModal('search')} />
							</CardContent>
						</Card>
					</Grid>

					<Grid item lg={3} md={12}>
						<Card variant='dark'>
							<CardContent variant='flex'>
								<Box>
									<Typography variant='body1'>{t('dealflow.criteria_panel.title')}</Typography>
									<Typography variant='body2'>{t('dealflow.criteria_panel.paragraph')}</Typography>
								</Box>
								<IconButton
									icon='faFilter'
									color='white'
									onClick={() => dispatch(openModalViaRedux(DEALFLOW_USER_CRITERIA_LISTS_MODAL))}
								/>
							</CardContent>
						</Card>
					</Grid>

					{dealflowCompanies && dealflowCompanies.length > 0 && (
						<Grid item xs={12}>
							<Card>
								<CardHeader
									title={<Text tid={'dealflow.companies_followed_grid.title'} />}
									action={
										<GridPanelRightButtons
											openGridModal={() => openModal('grid')}
											noFilter={true}
										/>
									}
									//noFilter={true}
								/>
								<CardContent>
									<CompaniesFollowedGrid
										modalLeftHeader={{ tid: 'dealflow.companies_followed_grid.title' }}
										localCloseModal={closeModals}
										isGridModalOpen={handleModals === 'grid'}
									/>
								</CardContent>
							</Card>
						</Grid>
					)}

					<CompanyAdverts forView={ADVERT_SECTIONS.DEALFLOW}>
						{(adverts, openAdvertModal) => {
							if (!adverts || adverts.length === 0) {
								return null
							}

							return (
								<Grid item xs={12}>
									<Grid container spacing={2}>
										{adverts.map((advert, index) => (
											<Grid item xs={4} key={index}>
												<AdvertCard advert={advert} openAdvertModal={openAdvertModal} />
											</Grid>
										))}
									</Grid>
								</Grid>
							)
						}}
					</CompanyAdverts>
				</Grid>
			</ScrollView>
			{isReadMoreModalOpen && (
				<ReadMoreModal title={'dealflow.information_panel.read_more.modal.title'} contentFile='dealflow.txt' />
			)}

			{handleModals === 'search' && (
				<SearchCompanyModal dealflowCompanies={dealflowCompanies} closeModal={closeModals} />
			)}

			{isUserCriteriaListsModalOpen && <CriteriaListsModal />}

			{isUserCriteriaModalOpen && <CriteriaModal />}
		</>
	)
}

export { DealFlowContainer }
