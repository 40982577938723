import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, object, func, string } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'
import { OutlinedButton } from '../../../dumb-components/shared/button-v2'
import LinkDocumentsModalContainer from './link-documents-modal.container'

class LinkDocumentsContainer extends Component {
	static propTypes = {
		tooltipProps: object,
		controlsDisabled: bool,
		linkDocumentsDisabled: bool,
		linkDocumentsDisabledTooltipTid: string,
		onCloseModal: func,
		objId: string,
		objType: string,
		multiple: bool,
		onLinkDocument: func,
		onUnlinkDocument: func
	}

	state = {
		isModalOpen: false
	}

	openModal = () => {
		this.setState({ isModalOpen: true })
	}

	closeModal = (fetchDocuments) => {
		const { onCloseModal } = this.props

		onCloseModal && onCloseModal(fetchDocuments)
		this.setState({ isModalOpen: false })
	}

	getControlsData = () => {
		const { hasWriteAccess, controlsDisabled, linkDocumentsDisabled, linkDocumentsDisabledTooltipTid } = this.props
		let { tooltipProps } = this.props
		let disabled = false

		if (controlsDisabled) {
			disabled = true
		} else if (linkDocumentsDisabled) {
			tooltipProps = {
				active: true,
				tid: linkDocumentsDisabledTooltipTid,
				delayShow: 'instant'
			}
			disabled = true
		} else if (!hasWriteAccess) {
			tooltipProps = {
				active: true,
				activeState: 'hasNoPermissions',
				delayShow: 'instant'
			}
			disabled = true
		}

		return { tooltipProps, disabled }
	}

	render = () => {
		const { objType, objId, multiple, onLinkDocument, onUnlinkDocument } = this.props
		const { isModalOpen } = this.state
		const { tooltipProps, disabled } = this.getControlsData()

		return (
			<>
				<Tooltip tid='link_file' {...tooltipProps}>
					<OutlinedButton icon='faLink' disabled={disabled} onClick={this.openModal} leftMargin />
				</Tooltip>

				<LinkDocumentsModalContainer
					isOpen={isModalOpen}
					onClose={this.closeModal}
					objId={objId}
					objType={objType}
					multiple={multiple}
					onLinkDocument={onLinkDocument}
					onUnlinkDocument={onUnlinkDocument}
				/>
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		hasWriteAccess: store.documents.getIn(['helper', 'hasWriteAccess'])
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(LinkDocumentsContainer)
