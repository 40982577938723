import React, { useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import { useTranslation } from 'react-i18next'
import MuiTextField from '@mui/material/TextField'
import FlagIcon from '@mui/icons-material/Flag'
import { DateTime } from 'luxon'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Divider } from '@mui/material'
import NotesContextMenu from './notes-context-menu'
import { useDispatch, useSelector } from 'react-redux'
import { createNote, updateNote } from '../../../../actions/emissions.actions'
import InputAdornment from '@mui/material/InputAdornment'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const NotesModal = (props) => {
	const { t } = useTranslation()
	const notes = useSelector((state) => state.emissions.notes)

	if (!notes) {
		return null
	}

	const { processed, inviteeId } = notes

	return (
		<Dialog open={props.open} onClose={props.close}>
			<DialogContent sx={{ px: 6, py: 4, minWidth: '60rem' }}>
				<IconButton
					aria-label='close'
					onClick={props.close}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[600]
					}}>
					<CloseIcon />
				</IconButton>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Typography sx={{ textAlign: 'center', pb: 2 }} variant='h4'>
						{t('emissions.invitations.header.notes')}
					</Typography>
					<Box sx={{ textAlign: 'center' }}>{props.name}</Box>
					<NoteTextBox inviteeId={inviteeId} />
					<Box
						sx={{
							width: '100%',
							mt: 3,
							overflowX: 'hidden',
							height: '500px',
							pr: 2
						}}>
						{processed.flagged.length !== 0 && (
							<>
								<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
									<Typography variant={'h4'} color={'error'}>
										{t('emissions.invitations.flagged')}
									</Typography>
									{processed.flagged.map((n) => (
										<Note key={n.id} note={n} inviteeId={inviteeId} />
									))}
								</Box>
								<Divider sx={{ my: 3 }} />
							</>
						)}
						<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
							{processed.unflagged.map((n) => (
								<Note key={n.id} note={n} inviteeId={inviteeId} />
							))}
						</Box>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	)
}

const NoteTextBox = (props) => {
	const { t } = useTranslation()
	const [value, setValue] = useState('')
	const dispatch = useDispatch()
	const emission = useSelector((state) => state.emissions.current)

	const submit = async () => {
		await dispatch(createNote(emission.companyId, emission.id, props.inviteeId, value))
		setValue('')
	}

	return (
		<MuiTextField
			sx={{ mt: 3 }}
			fullWidth
			value={value}
			onChange={(e) => setValue(e.target.value)}
			label={t('emissions.invitations.write-a-note')}
			InputProps={{
				endAdornment: value ? (
					<InputAdornment position='end'>
						<IconButton onClick={() => setValue('')}>
							<CancelOutlinedIcon />
						</IconButton>
						<IconButton onClick={() => submit()}>
							<CheckCircleIcon color={'primary'} />
						</IconButton>
					</InputAdornment>
				) : null
			}}
		/>
	)
}

const Note = (props) => {
	const { completed, important, text, date } = props.note
	const dispatch = useDispatch()
	const emission = useSelector((state) => state.emissions.current)

	return (
		<Box sx={{ bgcolor: 'blue.50', px: 2, pb: 1.5 }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: 1, borderColor: 'blue.300', py: 0.5 }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					{important && <FlagIcon sx={{ mr: 1 }} color={'error'} />}
					{DateTime.fromISO(date).toFormat('dd MMMM hh:mm')}
				</Box>
				<Box sx={{}}>
					<IconButton
						sx={{ mr: 1 }}
						onClick={() =>
							dispatch(
								updateNote(emission.companyId, emission.id, props.inviteeId, {
									...props.note,
									completed: !props.note.completed
								})
							)
						}>
						<CheckCircleOutlineIcon
							sx={{
								color: completed ? 'success.main' : 'grey.700'
							}}
						/>
					</IconButton>
					<NotesContextMenu
						companyId={emission.companyId}
						emissionId={emission.id}
						inviteeId={props.inviteeId}
						note={props.note}
					/>
				</Box>
			</Box>
			<Box sx={{ py: 1 }}>{text}</Box>
		</Box>
	)
}

export default NotesModal
