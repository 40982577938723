import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'

import ManageDeposModal from '../../../dumb-components/shares/manage-depo-modal/manage-depos.modal'

import { MANAGE_DEPO_MODAL } from '../../../constants/modals'
import { closeModal } from '../../../actions/modals.actions'

import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import { showErrorTooltip, hideErrorTooltip } from '../../../dumb-components/shared/tooltip/tooltip.helpers'

import ManageObjectModal from '../../../dumb-components/shared/manage-object-modal'
import { Map, List } from 'immutable'
import { createDepo, editDepo, deleteDepo, listDepos } from '../../../actions/depos.actions'

class ManageAttendeeFunctionsModalContainer extends Component {
	static propTypes = {
		name: string
	}

	state = {
		depoData: Map(),
		hasError: Map(),
		isLoading: false
	}

	dropdownRefs = {}
	inputRef = null

	componentDidMount = () => {
		const { depos, listDepos } = this.props

		if (!depos || depos.size === 0) {
			listDepos()
		}
	}

	onChange = (fieldName, value) => {
		const path = fieldName.split('.')
		let { depoData } = this.state
		this.hideTooltip(fieldName)
		depoData = depoData.setIn(path, value)
		this.setState({ depoData })
	}

	hideTooltip = (ref) => {
		let { hasError } = this.state
		const fieldName = ref.target ? ref.target.getAttribute('name') : ref
		hasError = hasError.remove(fieldName)
		this.setState({ hasError })
		hideErrorTooltip(this, fieldName)
	}

	validate = () => {
		const { depoData } = this.state
		let hasError = Map()
		let isValid = true

		const name = depoData.getIn(['name'])

		if (!name || !name.trim()) {
			hasError = hasError.set('name', List(['meetings.manage_depo_name']))
			showErrorTooltip(this, 'name')
			isValid = false
		}

		this.setState({ hasError })
		return isValid
	}

	setDropdownRef = (id, ref) => {
		this.dropdownRefs[id] = ref
	}

	onSetInputRef = (ref) => {
		this.inputRef = ref
	}

	onSetEditMode = (id) => {
		const { depos } = this.props
		this.dropdownRefs && this.dropdownRefs[id].onToggleMenu()
		const functionToEdit = depos.find((depo) => id === depo.get('id'))
		this.inputRef.focus()
		this.setState({ isEditMode: true, depoData: functionToEdit, hasError: Map() })
	}

	onEditFunction = () => {
		const { editDepo } = this.props
		const { depoData } = this.state

		this.setState({ isLoading: true })

		editDepo(depoData.get('id'), depoData, (editedFunction) => {
			this.setState({ isLoading: false, depoData: Map(), isEditMode: false })

			setTimeout(() => {
				this.inputRef.focus()
			}, 0)
		})
	}

	onDeleteFunction = (id) => {
		const { deleteDepo } = this.props
		this.dropdownRefs && this.dropdownRefs[id].onToggleMenu()
		deleteDepo(id)
	}

	onCancelEdit = () => {
		this.setState({ isEditMode: false, depoData: Map(), hasError: Map() })
	}

	closeModal = () => {
		this.props.closeModal()
	}

	onAdd = () => {
		const { depoData } = this.state
		const { createDepo, listDepos } = this.props

		if (this.validate()) {
			this.setState({ isLoading: true })

			createDepo(depoData, () => {
				this.setState({ isLoading: false, depoData: Map() })
				listDepos()

				setTimeout(() => {
					this.inputRef.focus()
				}, 0)
			})
		}
	}

	renderDropdownComponent = (id) => {
		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisV'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				ref={this.setDropdownRef.bind(null, id)}
				withPortal
				inline>
				<DropdownIconItem tid='generic.edit' icon='faPenAlt' onClick={this.onSetEditMode.bind(null, id)} />
				<DropdownIconItem tid='remove' icon='faTrashAlt' onClick={this.onDeleteFunction.bind(null, id)} />
			</DropdownMenuContainer>
		)
	}

	render = () => {
		let { isOpen, depos } = this.props

		let deposToShow = depos.filter((depo) => !depo.get('isDeleted'))

		if (!isOpen) {
			return null
		}

		return (
			<ManageDeposModal onClose={this.closeModal} hasError title={'shares.depos.manage.depos.modal_title'}>
				<ManageObjectModal
					hasError={this.state.hasError}
					onEdit={this.onEditFunction}
					onCancelEdit={this.onCancelEdit}
					onDeleteFunction={this.onDeleteFunction}
					isEditMode={this.state.isEditMode}
					onSetInputRef={this.onSetInputRef}
					onSetTooltipRefs={() => {}}
					depoData={this.state.depoData}
					tid={'depo.manage_list.empty'}
					placeholderTid={'depo.manage_list.placeholder_text'}
					onChange={this.onChange}
					onHideTooltip={this.hideTooltip}
					onAdd={this.onAdd}
					data={deposToShow}
					renderDropdownComponent={this.renderDropdownComponent}
				/>
			</ManageDeposModal>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		depos: store.depos.get('depos'),
		isOpen: store.modals.getIn(['activeModal', 'name']) === MANAGE_DEPO_MODAL
	}
}

const mapActionsToProps = {
	closeModal,
	createDepo,
	editDepo,
	deleteDepo,
	listDepos
}

export default connect(mapStoreToProps, mapActionsToProps)(ManageAttendeeFunctionsModalContainer)
