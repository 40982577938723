/**
 * Use this HoC in conjunction with for instance MUI's Autocomplete to perform a backend search.
 * This HoC will handle all the logic regarding making a search backend.
 * It holds the states for the inputted search terms, all the results matching that term after 
 * a successful backend search and also the loading state.
 * The component that utilizing this HoC provides a function for the HoC that should be run to
 * make the search. The function often dispatch a redux action that makes a backend request to
 * fetch the search result matching the search term that the user has typed in.
 * The HoC wraps a debouncer around the provided search function so the search is debounced
 * instead of making a search after every key press.
 */
import {useState, useEffect, useMemo, useRef} from 'react'
import { debounce } from 'lodash'
import { func, object, oneOfType, string } from 'prop-types'

const SearchHoc = ({onChange, onClear, initialValue, searchFunc, children}) => {
	const [value, setValue] = useState(initialValue)
	const [inputValue, setInputValue] = useState(initialValue?.label || '')
	const [options, setOptions] = useState([])
	const [loading, setLoading] = useState(false)
	const initiated = useRef(false)

	const search = useMemo(() => {
		return debounce((query, callback) => {
			setLoading(true)
			searchFunc(query, callback)
		}, 200)
	}, [])

	useEffect(() => {
		initiated.current = true
	})

	useEffect(() => {
		let active = true

		if (inputValue === '') {
			initiated.current === true && onClear && onClear()
			setOptions(value ? [value] : [])
			return undefined
		}

		search(inputValue, (err, results) => {
			setLoading(false)

			if (active) {
				let newOptions = []

				if (value) {
					newOptions = [value]
				}

				if (results) {
					newOptions = [...newOptions, ...results]
				}

				setOptions(newOptions)
				onChange && onChange(value)
			}
		})

		return () => {
			active = false
		}
	}, [value, inputValue, search])

	return children({
		value,
		inputValue,
		options,
		loading,
		setValue,
		setInputValue,
		setOptions
	})
}

SearchHoc.propTypes = {
	onChange: func,
	onClear: func,
	children: func.isRequired,
	initialValue: oneOfType([string, object]),
	searchFunc: func
}

export default SearchHoc
