import { getStore } from '../../store'
import { fromJS, List } from 'immutable'
import moment from '../../modules/moment.module'
import meetingHelpers from '/shared/meetings/helpers'
import {getStore as getPublicStore} from '../../public/store'
import {
	MEETING_TYPE_STANDARD,
	MEETING_STATUS_PROTOCOL_NEEDS_SIGNING,
	PROTOCOL_PUBLISHING_TYPE_ESIGNING,
	AGENDA_TOPIC_NOTES_VISIBILITY_HIDE
} from '/shared/constants'

const store = getStore()
const publicStore = getPublicStore()

export const createProtocolRecipientObject = (recipient) => {
	const recipientObject = meetingHelpers.createProtocolRecipientObject(recipient.toJS())
	return fromJS(recipientObject)
}

// Create a signatory object
export const createSignatoryObject = (id, isGuest, company) => {
	const signatory = meetingHelpers.createSignatoryObject(id, isGuest, company)

	return fromJS(signatory)
}

// Check is meeting's start date is todays date
export const isMeetingToday = (meeting) => {
	if (!meeting || !meeting.get('startDate')) {
		return false
	}

	return moment(meeting.get('startDate')).isSame(moment(), 'day')
}

// Check if meeting has started
export const hasMeetingStarted = (meeting) => {
	if (!meeting) {
		return false
	}

	return meeting.getIn(['computedValues', 'meetingIsStarted'])
}

// Toggles archive meeting
export const toggleArchiveMeetingObject = (meeting) => {
	const archived = !meeting.get('archived', false)

	meeting = meeting.set('archived', archived)

	if (archived) {
		meeting = meeting.setIn(['metadata', 'archivedAt'], moment().toISOString())
	} else {
		meeting = meeting.removeIn(['metadata', 'archivedAt'])
	}

	return meeting
}

// Make an flat array (or Map) of agenda items tree structure
export const flattenAgendaItems = (meeting) => {
	const meetingId = meeting && meeting.get('id')
	let agendaItemsArray = List()
	let counter = 0

	meeting &&
		meeting.get('agendaItems') &&
		meeting.get('agendaItems').forEach((item) => {
			if (item.get('archived')) {
				return
			}

			const itemId = item.get('id')
			const num = counter + 1
			item = item.set('num', num)
			item = item.set('objId', `${meetingId}$${itemId}`)

			agendaItemsArray = agendaItemsArray.push(item)

			if (item.get('agendaItems')) {
				item.get('agendaItems').forEach((subitem, subindex) => {
					const itemId = subitem.get('id')
					const num = counter + 1 + String.fromCharCode(96 + subindex + 1)
					subitem = subitem.set('num', num)
					subitem = subitem.set('objId', `${meetingId}$${itemId}`)
					agendaItemsArray = agendaItemsArray.push(subitem)
				})
			}

			counter++
		})

	return agendaItemsArray
}

// Checks if Agenda tab in Agenda should be opened.
// This would happen when user is secretary and meeting is about to start
// Returns agendaItem id with type OPEN if true, else FALSE
export const shouldOpenAgendaTab = (meeting, userId) => {
	const agendaIsPublished = meeting.getIn(['computedValues', 'agendaPublished'])
	const meetingIsFinished = meeting.getIn(['computedValues', 'meetingIsFinished'])
	const isSecretary = meeting.get('secretary') === userId
	const meetingIsToday = isMeetingToday(meeting)
	const openMeetingAgendaItem = meeting.get('agendaItems', List()).find((obj) => obj.get('internalType') === 'open')
	const startTimeHasPassed = moment(meeting.get('startDate')).isBefore(moment(), 'minutes')

	return !!(
		startTimeHasPassed ||
		(isSecretary && agendaIsPublished && meetingIsToday && !meetingIsFinished && openMeetingAgendaItem)
	)
}

export const getMeetingStatusColor = (props) => {
	const { colors } = props.theme

	if (props.meetingType === MEETING_TYPE_STANDARD) {
		const { endDate } = props

		if (props.showWarning) {
			return colors.orange
		}

		// If meetings enddate has passed. moment()
		// returns current date
		if (endDate && moment() > moment(endDate)) {
			return colors.solitudeMid
		}
	}

	if (props.showWarning) {
		return colors.orange
	} else if (props.protocolPublished) {
		return colors.solitudeMid
	} else if (props.agendaPublished && !props.meetingIsFinished) {
		return colors.java
	} else {
		return colors.solitudeMid
	}
}

export const getIsSigningRequested = (meeting) => {
	return meeting && meeting.get('status') === MEETING_STATUS_PROTOCOL_NEEDS_SIGNING
}

// Meeting is started
export const getMeetingStartDatePassed = (startDate) => {
	return startDate ? moment().isAfter(startDate) : false
}

export const getIsStartDateInFuture = (startDate) => {
	return moment(startDate).isAfter(moment())
}

export const getAttendeeIsInvestor = (attendee, investors) => {
	const isInvestor = attendee.get('isInvestor', false)
	const isInvestorAndMember = attendee.get('isAlsoInvestor', false)
	const isInvestorFromInvestorsList =
		investors && investors.some((inv) => inv.get('id') === attendee.get('investmentId'))

	return isInvestor || isInvestorAndMember || isInvestorFromInvestorsList
}

export const findProxyByAttendeeId = (proxies, attendeeId) => {
	let proxy

	if (!proxies) {
		return
	}

	proxies.forEach((attendeeIds, proxyId) => {
		if (attendeeIds.includes(attendeeId)) {
			if (IN_PUBLIC) {
				proxy = publicStore.getState().meetings.getIn(['meeting', 'attendees', proxyId])
			} else {
				proxy = store.getState().meetings.getIn(['meeting', 'attendees', proxyId])
			}
		}
	})

	return proxy
}

export const isAttendeeProxy = (attendee, proxies) => {
	return meetingHelpers.isAttendeeProxy(attendee && attendee.toJS(), proxies && proxies.toJS())
}

export const isAttendingByProxy = (attendeeId, proxies) => {
	return meetingHelpers.isAttendingByProxy(attendeeId, proxies && proxies.toJS())
}

export const isProxyConfirmedForAttendee = (proxy, attendeeId) => {
	return meetingHelpers.isProxyConfirmedForAttendee(proxy && proxy.toJS(), attendeeId)
}

export const isAttendeeModerator = (attendeeId, moderators) => {
	return meetingHelpers.isAttendeeModerator(attendeeId && attendeeId, moderators && moderators.toJS())
}

export const getHasMeetingRole = ({ attendeeId, roles, secretary, chairman }) => {
	if (chairman && chairman === attendeeId) {
		return true
	}

	if (secretary && secretary === attendeeId) {
		return true
	}

	return !!(roles && roles.size)
}

export const getPublishWithEsignTooltipData = ({
	eSigningEnabled,
	protocolMetadata,
	publishingType,
	protocolPublished,
	isStandardMeeting,
	isModerator
}) => {
	const TOOLTIP_STATES = {
		needAtLeastLite: {
			tid: 'meeting.protocol.publish.esign.tooltip.no_esign_enabled',
			delayShow: 'instant'
		},
		isNotPdf: {
			tid: 'meeting.protocol.publish.esign.tooltip.protocol_must_be_pdf',
			delayShow: 'instant'
		},
		processPending: {
			tid: 'meetings.general.toolbar.dropdown.publish_without_esign.tooltip.pending',
			delayShow: 'instant'
		},
		noProtocolUploaded: {
			tid: 'meeting.protocol.publish.both.no_protocol_uploaded',
			delayShow: 'instant'
		},
		notAvailable: {
			tid: 'meeting.protocol.publish.both.not_available',
			delayShow: 'instant'
		},
		protocolPublished: {
			tid: 'meeting.protocol.publish.both.protocol_published',
			delayShow: 'instant'
		},
		notModerator: {
			tid: 'meetings.standard.toolbar.tooltip.only_mod_can_publish_protocol',
			delayShow: 'instant'
		}
	}

	let activeState
	const processPending = Boolean(publishingType) && !protocolPublished
	const notAnPdf = !protocolMetadata || protocolMetadata.get('ext') !== 'pdf'
	const noProtocol = !protocolMetadata || !protocolMetadata.get('documentId')
	const notTheChosenPublishingType = publishingType !== PROTOCOL_PUBLISHING_TYPE_ESIGNING

	if (!eSigningEnabled) {
		activeState = 'needAtLeastLite'
	} else if (protocolPublished) {
		activeState = 'protocolPublished'
	} else if (processPending && notTheChosenPublishingType) {
		activeState = 'notAvailable'
	} else if (processPending) {
		activeState = 'processPending'
	} else if (noProtocol) {
		activeState = 'noProtocolUploaded'
	} else if (notAnPdf) {
		activeState = 'isNotPdf'
	} else if (isStandardMeeting && !isModerator) {
		activeState = 'notModerator'
	}

	return {
		TOOLTIP_STATES,
		activeState,
		isDisabled: Boolean(activeState)
	}
}

export function getFeedbackeeTooltips({ meetingStatus, protocolPublished }) {
	const TOOLTIP_STATES = {
		signingInProgress: {
			tid: 'meetings.protocol.feedback.toolbar.tooltip.signing_in_progress',
			delayShow: 'instant'
		},
		protocolPublished: {
			tid: 'meetings.protocol.feedback.toolbar.tooltip.protocol_already_published',
			delayShow: 'instant'
		}
	}

	let activeState
	const signingInProgress = meetingStatus === MEETING_STATUS_PROTOCOL_NEEDS_SIGNING

	if (signingInProgress) {
		activeState = 'signingInProgress'
	} else if (protocolPublished) {
		activeState = 'protocolPublished'
	}

	return {
		TOOLTIP_STATES,
		activeState,
		isDisabled: Boolean(activeState)
	}
}

export function getProxyDocumentsDisplayData({ proxy, userId, isAGM, hideBadgeIfDefault }) {
	const attendee = store.getState().meetings.getIn(['meeting', 'attendees', userId])
	if (!attendee || !isAGM) {
		return {
			showDropdownItem: false
		}
	}

	const isInvestor = attendee.get('isInvestor')
	const isAlsoInvestor = attendee.get('isAlsoInvestor')

	if (!isInvestor && !isAlsoInvestor) {
		return {
			showDropdownItem: false
		}
	}

	const TOOLTIP_STATES = {
		default: {
			tid: 'meetings.agm.dropdown_item.proxy_documents.tooltip.default',
			delayShow: 'short'
		},
		noDocuments: {
			tid: 'meetings.agm.dropdown_item.proxy_documents.tooltip.no_documents',
			delayShow: 'short'
		},
		accepted: {
			tid: 'meetings.agm.dropdown_item.proxy_documents.tooltip.accepted',
			delayShow: 'short'
		},
		underReview: {
			tid: 'meetings.agm.dropdown_item.proxy_documents.tooltip.underReview',
			delayShow: 'short'
		},
		declined: {
			tid: 'meetings.agm.dropdown_item.proxy_documents.tooltip.declined',
			delayShow: 'short'
		},
		noProxy: {
			tid: 'meetings.agm.dropdown_item.proxy_documents.tooltip.no_proxy',
			delayShow: 'instant'
		}
	}

	const badgeVisible = !!(isAGM && proxy)
	const ignoreProxyDocuments = attendee.get('ignoreProxyDocuments', false)
	const documentStatuses = attendee.get('proxyDocuments')
	const hasProxy = Boolean(proxy)

	const commonProps = {
		showDropdownItem: isAGM,
		badgeVisible,
		TOOLTIP_STATES
	}

	if (!hasProxy) {
		return { activeState: 'noProxy', dropdownItemDisabled: true, ...commonProps }
	}

	if (documentStatuses?.size < 1) {
		if (hideBadgeIfDefault) {
			return { badgeVisible: false }
		}
		return { badgeColor: 'lightGrey', activeState: 'noDocuments', ...commonProps }
	}

	if (ignoreProxyDocuments) {
		return { badgeColor: 'green', activeState: 'accepted', ...commonProps }
	}

	let isAccepted = true
	let isDeclined = false
	let underReview = false
	let noDocuments = false

	if (!documentStatuses) {
		isAccepted = false
		noDocuments = true
	}

	documentStatuses?.forEach((doc) => {
		const status = doc.get('status')

		if (status === 'DECLINED') {
			isDeclined = true
			return false
		}

		if (status !== 'ACCEPTED') {
			isAccepted = false
		}

		if (status === 'REVIEW') {
			underReview = true
		}
	})

	if (isDeclined) {
		return { badgeColor: 'persianRed', activeState: 'declined', ...commonProps }
	}

	if (underReview) {
		return { badgeColor: 'yellowMid', activeState: 'underReview', ...commonProps }
	}

	if (isAccepted) {
		return { badgeColor: 'green', activeState: 'accepted', ...commonProps }
	}

	if (hideBadgeIfDefault) {
		return { ...commonProps, badgeVisible: false }
	}

	if (noDocuments) {
		return { badgeColor: 'lightGrey', activeState: 'noDocuments', ...commonProps }
	}

	return { badgeColor: 'lightGrey', activeState: 'default', ...commonProps }
}

export function countAgendaSubTopics(mainAgendaItemId, agendaItems) {
	if (!agendaItems) {
		return 0
	}
	const mainAgendaItem = agendaItems.find((item) => item.get('id') === mainAgendaItemId)

	if (mainAgendaItem) {
		return mainAgendaItem.get('agendaItems')?.size || 0
	}

	return 0
}

export function canViewTopicNotes(agendaItem) {
	if (!agendaItem) {
		return false
	}

	const topicNotes = agendaItem.get('topicNotes')
	const topicNotesVisibility = agendaItem.get('topicNotesVisibility')

	return topicNotes && topicNotesVisibility !== AGENDA_TOPIC_NOTES_VISIBILITY_HIDE
}

export function getChunkOfAttendees(start, end) {
	const meetingId = store.getState().meetings.getIn(['meeting', 'id'])
	const attendeesMapIndexToId = store.getState().meetings.getIn(['attendeesMapIndexToId', meetingId])

	if (!attendeesMapIndexToId) {
		return
	}

	const attendeeIds = attendeesMapIndexToId.slice(start, end)
	const attendees = attendeeIds.map((attendeeId) => {
		return store.getState().meetings.getIn(['meeting', 'attendees', attendeeId])
	})

	return attendees
}

export function filterExternalPeople(externalPeople) {
	if (!externalPeople || externalPeople.length === 0) {
		return externalPeople
	}

	const attendees = store.getState().meetings.getIn(['meeting', 'attendees'])

	if (!attendees || attendees.size === 0) {
		return externalPeople.filter((person) => !person.isDeleted)
	}

	const hasExternalAttendees = attendees.some((attendee) => attendee.get('isGuest'))

	if (!hasExternalAttendees) {
		return externalPeople.filter((person) => !person.isDeleted)
	}

	return externalPeople.filter((person) => {
		if (!person.isDeleted) {
			return true
		}

		return attendees.has(person.id)
	})
}
