import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Button from '../../shared/button/button'
import { bool, func, oneOf, string } from 'prop-types'
import Input from '../../shared/input/input'
import Label from '../../shared/label/label'
import Text from '../../shared/text/text'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import Tooltip from '../../shared/tooltip/tooltip'
import { map } from 'react-immutable-proptypes'
import { Map } from 'immutable'
import ErrorMessage from '../error-message'

import { SSN_FIELD, EMAIL_FIELD, TOS_FIELD, CODE_FIELD, PHONE_FIELD } from '../../../constants/credentials'

const StyledSignInBankId = styled.div`
	display: flex;
	flex-direction: column;
	> * {
		margin: ${(props) => props.theme.spacing[3]} 0;
	}
	width: 100%;
	height: 100%;
`

const StyledToggleButtonDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	> * {
		margin: 0 ${(props) => props.theme.spacing[2]};
	}
`

const StyledButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: flex-end;
`

const StyledTwoButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: flex-end;

	> * {
		margin: 0 ${(props) => props.theme.spacing[4]};
	}
`

export default class SignInEmail extends PureComponent {
	static propTypes = {
		onClickVerify: func,
		onClickCancel: func,
		onClickSignUp: func,
		bankIdState: oneOf(['pending', 'failed']),
		mode: oneOf(['signup', 'verify']),
		ssn: string,
		email: string,
		verifyCode: string,
		onChange: func,
		onHideTooltip: func,
		onSetTooltipRefs: func,
		tos: bool,
		hasError: map,
		isVerifyMode: bool,
		actionBtnLoading: bool,
		onOpenTos: func,
		ssnFieldDisabled: bool,
		referredBySignin: bool
	}

	static defaultProps = {
		tos: false,
		mode: 'signup',
		hasError: Map()
	}

	onChangeTos = (ref, value) => {
		const { onHideTooltip, onChange } = this.props
		onHideTooltip && onHideTooltip(ref)
		onChange && onChange(ref, value)
	}

	renderRefInfoText = () => {
		const { referredBySignin } = this.props

		if (!referredBySignin) {
			return null
		}

		return <Text color='white' tid='credentials.signup.refered.info' />
	}

	renderSsnInput = () => {
		const {
			hasError,
			onClickSignUp,
			actionBtnLoading,
			isVerifyMode,
			onSetTooltipRefs,
			onChange,
			ssn,
			onHideTooltip,
			ssnFieldDisabled
		} = this.props

		return (
			<Label tid='credentials_pnr' textColor='white'>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([SSN_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, SSN_FIELD)}>
					<Input
						inputStyle='v2'
						fieldName={SSN_FIELD}
						onChange={onChange}
						value={ssn}
						onBlur={onHideTooltip}
						onEnter={onClickSignUp}
						disabled={isVerifyMode || actionBtnLoading || ssnFieldDisabled}
						hasError={hasError.get(SSN_FIELD)}
					/>
				</Tooltip>
			</Label>
		)
	}

	renderEmailInput = () => {
		const {
			hasError,
			onClickSignUp,
			actionBtnLoading,
			isVerifyMode,
			onSetTooltipRefs,
			onChange,
			email,
			onHideTooltip
		} = this.props

		return (
			<Label tid='credentials.signup.label.email' textColor='white'>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([EMAIL_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, EMAIL_FIELD)}>
					<Input
						inputStyle='v2'
						fieldName={EMAIL_FIELD}
						onChange={onChange}
						value={email}
						onBlur={onHideTooltip}
						onEnter={onClickSignUp}
						disabled={isVerifyMode || actionBtnLoading}
						hasError={hasError.get(EMAIL_FIELD)}
					/>
				</Tooltip>
			</Label>
		)
	}

	renderPhoneInput = () => {
		const { onClickSignUp, onChange, phone, isVerifyMode, actionBtnLoading } = this.props

		if (isVerifyMode) {
			return null
		}

		return (
			<Label tid='credentials.verify.phone.label' textColor='white'>
				<Input
					inputStyle='v2'
					fieldName={PHONE_FIELD}
					onChange={onChange}
					value={phone}
					// onBlur={onHideTooltip}
					onEnter={onClickSignUp}
					disabled={isVerifyMode || actionBtnLoading}
					// hasError={hasError.get(PHONE_FIELD)}
				/>
			</Label>
		)
	}

	renderTosSwitch = () => {
		const { hasError, isVerifyMode, onSetTooltipRefs, tos, actionBtnLoading, onOpenTos } = this.props

		if (isVerifyMode) {
			return null
		}

		return (
			<Tooltip
				activeState='formValidator'
				tid={hasError.getIn([TOS_FIELD, 0])}
				ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, TOS_FIELD)}>
				<StyledToggleButtonDiv>
					<ToggleSwitch
						fieldName={TOS_FIELD}
						onChange={this.onChangeTos}
						checked={tos}
						disabled={isVerifyMode || actionBtnLoading}
					/>
					<Text>
						<Text tid='credentials_terms.of.reference_1' color='muted' />
						&nbsp;
						<Button mode='link' tid='credentials_terms.of.reference_2' onClick={onOpenTos} />
					</Text>
				</StyledToggleButtonDiv>
			</Tooltip>
		)
	}

	renderVerifyCodeInput = () => {
		const {
			hasError,
			onClickVerify,
			actionBtnLoading,
			isVerifyMode,
			onSetTooltipRefs,
			onChange,
			code,
			onHideTooltip,
			email
		} = this.props

		if (!isVerifyMode) {
			return null
		}

		return (
			<>
				<Label tid='credentials_verify.by.email' textColor='white'>
					<Tooltip
						activeState='formValidator'
						tid={hasError.getIn([CODE_FIELD, 0])}
						ref={onSetTooltipRefs && onSetTooltipRefs.bind(this, CODE_FIELD)}>
						<Input
							inputStyle='v2'
							fieldName={CODE_FIELD}
							onChange={onChange}
							value={code}
							disabled={actionBtnLoading}
							onBlur={onHideTooltip}
							autoFocus={true}
							onEnter={onClickVerify}
							hasError={hasError.get(CODE_FIELD)}
						/>
					</Tooltip>
				</Label>

				<Text color='white' tid='credentials.verify.code.code_sent_by_email' values={{ email }} />
			</>
		)
	}

	render = () => {
		const { onClickSignUp, isVerifyMode, onClickVerify, onClickCancel, actionBtnLoading, translationId } = this.props

		return (
			<StyledSignInBankId>
				{this.renderRefInfoText()}
				{this.renderSsnInput()}
				{this.renderEmailInput()}
				{this.renderPhoneInput()}
				{this.renderTosSwitch()}
				{this.renderVerifyCodeInput()}

				<ErrorMessage tid={translationId} />

				{isVerifyMode ? (
					<StyledTwoButtonWrapper>
						<Button
							mode='rounded'
							tid='credentials_verify'
							minWidth='150px'
							maxWidth='150px'
							height='50px'
							onClick={onClickVerify}
							isLoading={actionBtnLoading}
						/>
						<Button
							mode='rounded'
							tid='credentials_cancel'
							inverted={true}
							minWidth='150px'
							maxWidth='150px'
							height='50px'
							onClick={onClickCancel}
						/>
					</StyledTwoButtonWrapper>
				) : (
					<StyledButtonWrapper>
						<Button
							mode='rounded'
							tid='credentials_register'
							minWidth='150px'
							maxWidth='150px'
							height='50px'
							onClick={onClickSignUp}
							isLoading={actionBtnLoading}
						/>
					</StyledButtonWrapper>
				)}
			</StyledSignInBankId>
		)
	}
}
