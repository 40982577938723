import React, { Component } from 'react'
import { bool, func, object } from 'prop-types'
import ScrollView from '../../dumb-components/shared/layout/scroll-view/scroll-view'
import ScrollViewHeader from '../../dumb-components/shared/scroll-view-header/scroll-view-header'
import ToolbarContainer from './toolbar.container'
import StatusButtonContainer from './status-button.container'
import TaskAdvancedFilterContainer from './task-advanced-filter.container'
import { getQuery } from '../../interfaces/history'
import { withRouter } from 'react-router'

class RightViewContainer extends Component {
	static propTypes = {
		filterIsOpen: bool,
		onFilterClose: func,
		location: object
	}

	state = {
		scrollbarRef: null
		// activeBlock: null
	}

	_componentRefs = {}

	/*componentDidUpdate = (prevProps) => {
		const {scrollbarRef, activeBlock} = this.state;

		if (prevProps.params.id !== this.props.params.id) {
			// Reset selected section upon task change
			if (activeBlock) {
				this.setState({activeBlock: null});
				scrollbarRef.scrollTop();
			}
		}
	}*/

	setScrollbarRef = (ref) => {
		this.setState({ scrollbarRef: ref })
	}

	setChildRef = (component, ref) => {
		this._componentRefs[component] = ref
		const query = getQuery()
		const { focus } = query

		if (ref && focus === 'comments' && component === 'comments') {
			setTimeout(() => {
				this.state.scrollbarRef.scrollTop(document.getElementById('comments-panel').offsetTop)
			}, 200)
		}
	}

	onToolbarItemClick = (block) => {
		this.setState({ activeBlock: block })
	}

	renderRightSplitViewHeader = () => {
		const {
			location,
			basePath,
			match: { params }
		} = this.props
		const querystr = location.search

		return (
			<ScrollViewHeader>
				<ToolbarContainer
					scrollbarRef={this.state.scrollbarRef}
					blockRefs={this._componentRefs}
					params={params}
					basePath={basePath}
					querystr={querystr}
					onItemClick={this.onToolbarItemClick}
				/>
			</ScrollViewHeader>
		)
	}

	renderRightSplitViewFooter = () => {
		return <StatusButtonContainer />
	}

	renderChildren = () => {
		const { children } = this.props
		const { scrollbarRef, activeBlock } = this.state

		return React.Children.map(children, (child) => {
			return React.cloneElement(child, {
				childrenRef: this.setChildRef,
				scrollbarRef,
				activeBlock
			})
		})
	}

	render = () => {
		const { filterIsOpen, onFilterClose } = this.props

		return (
			<ScrollView
				scrollbarRef={this.setScrollbarRef}
				header={this.renderRightSplitViewHeader}
				headerBottomMargin={3}
				showOnHover={true}
				contentTopMargin={filterIsOpen ? 1 : undefined}>
				{!filterIsOpen && this.renderChildren()}
				{filterIsOpen && <TaskAdvancedFilterContainer isOpen={filterIsOpen} onClose={onFilterClose} />}
			</ScrollView>
		)
	}
}

export default withRouter(RightViewContainer)
