import React from 'react'
import { string, bool } from 'prop-types'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import StyledIcon from '../../../dumb-components/shared/icon/icon'
import MyRecordsDarkInfoPanel from '../../../dumb-components/investment/my-records/my-records-dark-info-panel'

export default function InvestmentReportsContainer() {
	const onCloseMessage = () => {}

	return (
		<Grid container spacing={2}>
			{/* {alreadyDismissed && ( */}
			<Grid item xs={12}>
				<Card variant='dark'>
					<CardContent variant='flex'>
						<Box>
							<Typography variant='body1' gutterBottom>
								<FormattedMessage id={'portfolio.investments.my_records.dark_panel.text1'} />
							</Typography>
							<Typography variant='body2'>
								<FormattedMessage id={'portfolio.investments.my_records.dark_panel.text2'} />
							</Typography>
						</Box>
						<Button variant='simple' onClick={onCloseMessage}>
							<StyledIcon icon='faTimes' type='light' color='white' size='sm' />
						</Button>
					</CardContent>
				</Card>
			</Grid>
			{/* )} */}
			<Grid item xs={12}>
				<span>Something</span>
			</Grid>
		</Grid>
	)
}
