import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func } from 'prop-types'
import { map } from 'react-immutable-proptypes'

import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'

import { deleteCollection } from '../../../actions/collections.actions'

const TOOLTIP_STATES = {
	isRoot: {
		tid: 'collections.modal.collection.item.ROOT.disabled',
		delayShow: 'instant'
	}
}

class RemoveCollectionDropdownItemContainer extends Component {
	static propTypes = {
		collection: map.isRequired,
		onClick: func
	}

	handleRemove = () => {
		const { collection, deleteCollection, onClick } = this.props
		const collectionId = collection.get('id')
		deleteCollection(collectionId)
		onClick && onClick(collection)
	}

	getTooltipData = () => {
		const { collection } = this.props
		const isRoot = collection.get('isRoot')
		let activeState

		if (isRoot) {
			activeState = 'isRoot'
		}

		return { activeState, disabled: Boolean(activeState) }
	}

	render = () => {
		const { activeState, disabled } = this.getTooltipData()

		return (
			<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
				<DropdownIconItem
					icon='faTrashAlt'
					tid='collections.modal.collection.dropdown.remove'
					onClick={this.handleRemove}
					disabled={disabled}
				/>
			</Tooltip>
		)
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {
	deleteCollection
}

export default connect(mapStoreToProps, mapActionsToProps)(RemoveCollectionDropdownItemContainer)
