import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, Map, fromJS } from 'immutable';
import { bool, func } from 'prop-types';
import uuid from 'uuid';
import { FILTER_FIELDS } from '../../constants/documents';
import moment from '../../modules/moment.module';
import { fetchSimpleUsers } from '../../actions/usersCache.actions';
import { fetchRoles, fetchUsersInRolesCache } from '../../actions/user-management.actions';
import {
	setFilterBy,
	unsetFilterBy,
	updateDefaultFilter,
	resetDefaultFiltervalues
} from '../../actions/documents.actions';
import { fetchCompanyUsers } from '../../actions/company.actions';
import { updateUser, saveUser } from '../../actions/user.actions';
import ObjectFilterContainer from '../shared/object-filter.container';
import Text from '../../dumb-components/shared/text/text';
import CompanyMembersAndTeamsSelectContainer from '../company/company-member-and-teams-select.container';
import CompanyMembersSelectContainer from '../company/company-members-select.container';
import { FACTORY_DEFAULT_FILTER_VALUES } from '../../constants/documents';

class DocumentAdvancedFilterContainer extends Component {
	static propTypes = {
		isOpen: bool,
		onClose: func
	};

	state = {
		fields: null
	};

	componentDidMount = () => {
		const { documents } = this.props;
		this.parseFields(FILTER_FIELDS, documents);
	};

	componentDidUpdate = (prevProps) => {
		if (this.props.documents !== prevProps.documents) {
			this.parseFields(this.state.fields, this.props.documents);
		}

		if (this.state.fields && this.props.companyMembers !== prevProps.companyMembers) {
			this.parseCompanyMembers(this.props.companyMembers);
		}

		if (this.state.fields && this.props.roles !== prevProps.roles) {
			this.parseRoles(this.props.roles);
		}

		if (prevProps.filterBy !== this.props.filterBy) {
			this.parseFields(this.state.fields, this.props.documents);
		}
	};

	getRowIndex = (fields, fieldName) => {
		return fields.findIndex((row) => {
			let found = false;

			row.forEach((obj) => {
				if (obj.get('fieldName') === fieldName) {
					found = true;
				}
			});

			return found;
		});
	};

	getFieldIndex = (fields, rowIndex, fieldName) => {
		return fields.get(rowIndex).findIndex((obj) => obj.get('fieldName') === fieldName);
	};

	parseFields = (fields, documentsList) => {
		const permissionsRowIndex = this.getRowIndex(fields, 'permissions');
		const permissionsFieldIndex = this.getFieldIndex(fields, permissionsRowIndex, 'permissions');
		const createdByRowIndex = this.getRowIndex(fields, 'createdBy');
		const createdByFieldIndex = this.getFieldIndex(fields, createdByRowIndex, 'createdBy');
		fields = fields.setIn(
			[permissionsRowIndex, permissionsFieldIndex, 'formatOptionLabel'],
			this.formatOptionLabel
		);
		fields = fields.setIn(
			[permissionsRowIndex, permissionsFieldIndex, 'renderFn'],
			this.renderCompanyMembersAndTeamsSelect
		);
		fields = fields.setIn([createdByRowIndex, createdByFieldIndex, 'renderFn'], this.renderCompanyMembersSelect);

		if (documentsList && documentsList.size > 0) {
			const dateRowIndex = this.getRowIndex(fields, 'date');
			const dateFieldIndex = this.getFieldIndex(fields, dateRowIndex, 'date');
			const extRowIndex = this.getRowIndex(fields, 'extension');
			const extFieldIndex = this.getFieldIndex(fields, extRowIndex, 'extension');
			let minDate;
			let maxDate;
			let extensions = Map();

			documentsList.forEach((doc) => {
				const validFrom = doc.get('validFrom');
				let ext = doc.getIn(['file', 'ext']);
				if (ext && ext === 'bin') {
					const originalName = doc.getIn(['file', 'originalname']);
					if (originalName) {
						const nameSplit = originalName.split('.');
						ext = nameSplit[nameSplit.length - 1];
					}
				}

				if ((!minDate && validFrom) || (minDate && validFrom && moment(validFrom).isBefore(minDate))) {
					minDate = validFrom;
				}

				if ((!maxDate && validFrom) || (maxDate && validFrom && moment(validFrom).isAfter(maxDate))) {
					maxDate = validFrom;
				}

				if (ext && ext !== '' && !extensions.has(ext)) {
					extensions = extensions.set(
						ext,
						Map({
							value: ext,
							label: ext
						})
					);
				}
			});

			fields = fields.setIn([dateRowIndex, dateFieldIndex, 'minDate'], minDate);
			fields = fields.setIn([dateRowIndex, dateFieldIndex, 'maxDate'], maxDate);
			fields = fields.setIn(
				[extRowIndex, extFieldIndex, 'options'],
				extensions.toList().sort((a, b) => a.get('label', '').localeCompare(b.get('label')))
			);
		}

		this.setState({ fields });
	};

	parseCompanyMembers = (companyMembers) => {
		const userIds = companyMembers.map((obj) => obj.get('id'));

		// Need to call this action to update usersCache that is used in the documents filter function to
		// check if user has access to certain documents
		this.props.fetchSimpleUsers(userIds);
	};

	parseRoles = (roles) => {
		const roleIds = fromJS(roles)
			.filter((role) => role.get('id') !== 'aed45ce5-291f-438f-a47a-f547fdecc5ee')
			.map((obj) => obj.get('id'));

		// Need to call this action to update rolesCache that is used in the documents filter function to
		// check if user has access to certain documents
		this.props.fetchUsersInRolesCache(roleIds);
	};

	onChange = (fieldName, val) => {
		const { setFilterBy } = this.props;
		let valueIsEmpty = !val && fieldName !== 'showOnlyTrashed';

		if (fieldName === 'date') {
			val = val.filter((value) => {
				return value !== null;
			});

			valueIsEmpty = val.size === 0;
		}

		setFilterBy(
			Map({
				source: fieldName,
				values: !valueIsEmpty ? List([val]) : null
			})
		);
	};

	onClose = () => {
		const { onClose } = this.props;

		onClose && onClose();
	};

	onClearAll = () => {
		const { unsetFilterBy } = this.props;
		const { fields } = this.state;
		let sources = List();

		fields.forEach((row) => {
			row.forEach((field) => {
				sources = sources.push(field.get('fieldName'));
			});
		});

		unsetFilterBy(sources);
	};

	onSetDefaultValue = (fieldName, val) => {
		const { updateUser, saveUser, updateDefaultFilter, company } = this.props;
		let { userObj } = this.props;
		const id = `${company.id}-documents$${fieldName}`;

		userObj = userObj.setIn(['siteSettings', 'pinedFilters', id], val);
		updateUser(userObj);
		saveUser(userObj.get('id'), userObj.toJS());
		updateDefaultFilter(fieldName, List([val]));
	};

	resetDefaultValues = () => {
		const { updateUser, saveUser, resetDefaultFiltervalues, company } = this.props;
		let { userObj } = this.props;

		FACTORY_DEFAULT_FILTER_VALUES.forEach((val, key) => {
			const id = `${company.id}-documents$${key}`;

			if (userObj.hasIn(['siteSettings', 'pinedFilters', id])) {
				userObj = userObj.setIn(['siteSettings', 'pinedFilters', id], val.get(0));
			}
		});

		updateUser(userObj);
		saveUser(userObj.get('id'), userObj.toJS());
		resetDefaultFiltervalues();
	};

	formatOptionLabel = (option) => {
		return option.label;
	};

	renderCompanyMembersAndTeamsSelect = (fieldName, pinable, placeholder, value, defaultValue, onChange, field) => {
		const prependOptions = List([
			Map({
				value: 'show_all',
				label: 'documents.filter.permissions.show_all'
			})
		]);

		return (
			<CompanyMembersAndTeamsSelectContainer
				fieldName={fieldName}
				onChange={onChange}
				value={value}
				placeholderTid={placeholder}
				isClearable={field.get('isClearable')}
				prependOptions={prependOptions}
				defaultValue={defaultValue}
				formatOptionLabel={field.get('formatOptionLabel')}
			/>
		);
	};

	renderCompanyMembersSelect = (fieldName, pinable, placeholder, value, defaultValue, onChange, field) => {
		return (
			<CompanyMembersSelectContainer
				fieldName={fieldName}
				onChange={onChange}
				value={value}
				placeholderTid={placeholder}
				isClearable={field.get('isClearable')}
				includeNoneOption={field.get('includeNoneOption')}
				noneOptionValue={field.get('noneOptionValue')}
				noneOptionLabelTid={field.get('noneOptionLabelTid')}
			/>
		);
	};

	render = () => {
		const { isOpen, company, filterBy, defaultFilters } = this.props;
		const { fields } = this.state;
		const id = `${company.id}-documents`;

		return (
			<ObjectFilterContainer
				key={uuid()}
				id={id}
				fields={fields}
				values={filterBy}
				defaultValues={defaultFilters}
				title='documents.documents_filter'
				isOpen={isOpen}
				onChange={this.onChange}
				onClose={this.onClose}
				onClearAll={this.onClearAll}
				onSetDefaultValue={this.onSetDefaultValue}
				onResetDefaultValues={this.resetDefaultValues}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		documents: store.documents.get('allDocuments'),
		document: store.documents.get('document'),
		filterBy: store.documents.get('filterBy'),
		defaultFilters: store.documents.get('defaultFilters'),
		roles: store.company.userManagement.roles,
		company: store.company.company,
		companyMembers: store.company.members,
		userObj: store.user.get('userObj')
	};
};

const mapActionsToProps = {
	setFilterBy,
	unsetFilterBy,
	fetchRoles,
	fetchCompanyUsers,
	fetchSimpleUsers,
	fetchUsersInRolesCache,
	updateUser,
	saveUser,
	updateDefaultFilter,
	resetDefaultFiltervalues
};

export default connect(mapStoreToProps, mapActionsToProps)(DocumentAdvancedFilterContainer);
