import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'
import { Map } from 'immutable'
import { fetchNote, saveNote } from '../../actions/notes.actions'
import EditorContainer from './editor.container'

class NotesContainer extends Component {
	static propTypes = {
		objId: string,
		objType: string,
		borderless: bool,
		height: string,
		inline: bool
	}

	componentDidMount = () => {
		const { fetchNote, objId, objType } = this.props
		objId && fetchNote(objType, objId)
	}

	componentDidUpdate = (prevProps) => {
		const { objId, objType, fetchNote } = this.props

		if (objId && prevProps.objId !== objId) {
			fetchNote(objType, objId)
		}
	}

	onChange = (note) => {
		const { objId, objType, saveNote, notes, meetingId } = this.props
		let metadata = notes.getIn([objId, 'metadata'], Map())

		if (meetingId) {
			metadata = metadata.set('meetingId', meetingId)
		}

		saveNote(objId, objType, note, metadata)
	}

	render = () => {
		const { objId, notes, borderless, height, inline } = this.props
		const note = notes ? notes.getIn([objId, 'note'], '') : ''

		return (
			<EditorContainer
				mode='notes'
				objId={objId}
				contentState={note}
				onChange={this.onChange}
				placeholderTid='personal_notes.placeholder'
				borderless={borderless}
				editorHeight={height}
				inline={inline}
				includePadding={true}
			/>
		)
	}
}

function mapStoreToProps(store) {
	return {
		notes: store.notes.get('notes')
	}
}

const mapActionsToProps = {
	fetchNote,
	saveNote
}

export default connect(mapStoreToProps, mapActionsToProps)(NotesContainer)
