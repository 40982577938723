import React, { PureComponent } from 'react'
import { string, bool, func, oneOf } from 'prop-types'
import { Margin, Padding } from 'styled-components-spacing'
import styled, { css } from 'styled-components'
import { v1 } from 'uuid'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { list } from 'react-immutable-proptypes'

import DocumentsHelper from '../../../components/helpers/documents.helper'
import Panel from '../panel/panel'
import Text from '../text/text'
import Dropzone from '../dropzone/dropzone'
import DarkInfoPanel from '../dark-info-panel/dark-info-panel'
import SimpleDarkInfoPanel from '../dark-info-panel/simple-dark-info-panel'
import ModernFilePreview from '../modern-file-preview/modern-file-preview'
import { OutlinedButton } from '../button-v2'
import Icon from '../icon/icon'
import Tooltip from '../tooltip/tooltip'

const StyledListWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;

	> div {
		margin-bottom: ${(props) => props.theme.spacing[3]};
	}

	> div:last-child {
		margin-bottom: 0;
	}
`

const PanelInnerWrapper = styled.div`
	${({ panelType }) =>
		panelType !== 'inline' &&
		css`
			padding: ${(props) => props.theme.spacing[4]};
		`}
`

const StyledDarkInfoPanel = styled(DarkInfoPanel)`
	display: flex;
	justify-content: center;
	min-height: 68px;
	padding: ${(props) => (props.fileEnteredWindow ? 0 : props.theme.spacing[4])};

	${({ fileEnteredWindow }) =>
		fileEnteredWindow &&
		css`
			> [class*='dropzone'] {
				padding: ${(props) => props.theme.spacing[4]};
			}
		`}

	${({ alignItemsCenter }) =>
		alignItemsCenter &&
		css`
			align-items: center;
		`}
`

const StyledModernFilePreviewWrapper = styled.div`
	> div:first-child {
		margin-top: ${(props) => props.theme.spacing[3]};
	}

	> div {
		margin-bottom: ${(props) => props.theme.spacing[3]};
	}

	> div:last-child {
		margin-bottom: 0;
	}
`

const StyledModernFilePreview = styled(ModernFilePreview)`
	background: ${(props) => props.theme.colors.java};
	padding: ${(props) => props.theme.spacing[4]};
	border-radius: ${(props) => props.theme.spacing[3]};
	margin-bottom: ${(props) => props.theme.spacing[3]};
`

export default class Attachments extends PureComponent {
	static propTypes = {
		documents: list,
		nakedStyle: bool,
		fileEnteredWindow: bool,
		onLinkFile: func,
		onDrop: func,
		isDragDisabled: bool,
		onDragEnd: func,
		onOpenFile: func,
		onDownloadFile: func,
		isUploading: bool,
		documentDropdownRenderer: func,
		readOnly: bool,
		multiple: bool,
		getFileIconDotData: func,
		descriptionTid: string,
		oneFileLimitControlsTooltipTid: string,
		panelType: oneOf(['inline']),
		renderLinkDocumentsContainer: func,
		hideLinkDocument: bool,
		objId: string
	}

	dropzoneRef = null

	setDropzoneRef = (ref) => {
		this.dropzoneRef = ref
	}

	openDropzoneFileBrowser = () => {
		this.dropzoneRef.open()
	}

	getFileIconDotData = (document) => {
		const { getFileIconDotData, objId } = this.props
		const fileIconDotData = getFileIconDotData && getFileIconDotData(document, objId)
		return fileIconDotData ? fileIconDotData : {}
	}

	getDescriptionAndControlsData = () => {
		const {
			descriptionTid: providedDescriptionTid,
			oneFileLimitControlsTooltipTid,
			documents,
			multiple,
			readOnly
		} = this.props
		let descriptionTid = providedDescriptionTid
		let tooltipTid
		let tooltipActiveState
		let controlsDisabled

		if (readOnly) {
			controlsDisabled = true
			descriptionTid = 'attachments.generic.readonly.description'
			tooltipActiveState = 'hasNoPermissions'
		} else if (documents && documents.size && !multiple) {
			controlsDisabled = true
			tooltipTid = oneFileLimitControlsTooltipTid
		}

		const tooltipProps = {
			activeState: tooltipActiveState,
			delayShow: controlsDisabled ? 'instant' : undefined
		}

		if (tooltipTid) {
			tooltipProps.tid = tooltipTid
		}

		return {
			descriptionTid,
			controlsDisabled,
			tooltipProps
		}
	}

	renderFileItem = (document, index) => {
		const { isDragDisabled, onOpenFile, onDownloadFile, readOnly, documentDropdownRenderer, getFileIconDotData } =
			this.props
		const documentId = document && document.get('id')
		const { fileIconTooltipTid, fileIconDot, fileIconDotColor } = this.getFileIconDotData(document)

		const commonProps = {
			document,
			onOpenFile,
			onDownloadFile,
			fileIconTooltipTid,
			fileIconDot,
			fileIconDotColor,
			dropdownRenderer: documentDropdownRenderer
		}

		if (readOnly || isDragDisabled) {
			return (
				<StyledModernFilePreviewWrapper key={index}>
					<StyledModernFilePreview {...commonProps} isDragDisabled={true} key={index} />
				</StyledModernFilePreviewWrapper>
			)
		}

		return (
			<Draggable draggableId={documentId} index={index} key={index}>
				{(provided, snapshot) => (
					<div ref={provided.innerRef} {...provided.draggableProps}>
						<StyledModernFilePreviewWrapper key={index}>
							<StyledModernFilePreview
								{...commonProps}
								isDragDisabled={isDragDisabled}
								dragHandleProps={provided.dragHandleProps}
							/>
						</StyledModernFilePreviewWrapper>
					</div>
				)}
			</Draggable>
		)
	}

	renderFilesList = () => {
		const { documents, isDragDisabled, onDragEnd } = this.props

		if (!documents || !documents.size) {
			return null
		}

		if (isDragDisabled) {
			return <StyledListWrapper>{documents.map(this.renderFileItem)}</StyledListWrapper>
		}

		return (
			<StyledListWrapper>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId='attachments'>
						{(provided) => (
							<div ref={provided.innerRef}>
								{documents.map(this.renderFileItem)}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</StyledListWrapper>
		)
	}

	renderAttachments = () => {
		const {
			fileEnteredWindow,
			onLinkFile,
			isUploading,
			readOnly,
			onDrop,
			multiple,
			renderLinkDocumentsContainer,
			hideLinkDocument
		} = this.props

		if (readOnly) {
			return this.renderFilesList()
		}

		if (isUploading) {
			return (
				<>
					<StyledDarkInfoPanel alignItemsCenter={true}>
						<Icon icon='faSpinner' size='lg' color='white' spin />
					</StyledDarkInfoPanel>

					{this.renderFilesList()}
				</>
			)
		}

		const dropzoneSharedProps = {
			onDrop,
			multiple
		}

		const { descriptionTid, controlsDisabled, tooltipProps } = this.getDescriptionAndControlsData()

		if (fileEnteredWindow && !controlsDisabled) {
			return (
				<>
					<StyledDarkInfoPanel fileEnteredWindow={true}>
						<Dropzone {...dropzoneSharedProps} size='modern' />
					</StyledDarkInfoPanel>

					{this.renderFilesList()}
				</>
			)
		}

		return (
			<>
				<StyledDarkInfoPanel
					headerLeftComponent={<Text color='white' tid={descriptionTid} />}
					headerRightComponent={
						<>
							<Tooltip tid='upload_file' {...tooltipProps}>
								<OutlinedButton icon='faPlus' disabled={controlsDisabled} onClick={this.openDropzoneFileBrowser} />
							</Tooltip>

							{!hideLinkDocument &&
								renderLinkDocumentsContainer &&
								renderLinkDocumentsContainer({ tooltipProps, controlsDisabled })}
						</>
					}>
					{!controlsDisabled && (
						<Dropzone {...dropzoneSharedProps} size='full-screen' dropzoneRef={this.setDropzoneRef} />
					)}
				</StyledDarkInfoPanel>

				{this.renderFilesList()}
			</>
		)
	}

	render = () => {
		const { nakedStyle, panelType } = this.props
		const panelMode = panelType === 'inline' ? 'transparent' : undefined

		if (nakedStyle) {
			return this.renderAttachments()
		}

		return (
			<Panel title='tasks.attachments' type={panelType} mode={panelMode} marginBottom={true}>
				<PanelInnerWrapper panelType={panelType}>{this.renderAttachments()}</PanelInnerWrapper>
			</Panel>
		)
	}
}
