import React, { Component } from 'react'
import { connect } from 'react-redux'

import CollectionsNavItem from '../../dumb-components/navbar/collections/collections'
import CollectionsModalContainer from '../collections/collections-modal.container'
import CollectionsShareModalContainer from '../collections/share/share-modal.container'
import CollectionsMergePdfModalContainer from '../collections/merge-pdf/merge-pdf-modal.container'
import CollectionsManageSharedModalContainer from '../collections/manage-shared/manage-shared-modal.container'

import { openModal } from '../../actions/modals.actions'
import { fetchCollections } from '../../actions/collections.actions'
import {
	COLLECTIONS_MANAGE_MODAL,
	COLLECTIONS_MANAGE_SHARED_MODAL,
	COLLECTIONS_SHARE_MODAL,
	COLLECTIONS_MERGE_PDF_MODAL
} from '../../constants/modals'

import DropdownMenuContainer from '../shared/dropdown-menu.container'
import DropdownIconItem from '../../dumb-components/shared/dropdown-item/dropdown-icon-item'

class CollectionsNavItemContainer extends Component {
	dropdownRef = null

	componentDidMount = () => {
		this.fetchCollections()
	}

	componentDidUpdate = (prevProps) => {
		const { companyId, collectionsModalIsOpen } = this.props

		if (prevProps.companyId !== companyId) {
			this.fetchCollections()
		}

		if (collectionsModalIsOpen && !prevProps.collectionsModalIsOpen) {
			this.fetchCollections()
		}
	}

	setDropdownRef = (ref) => {
		this.dropdownRef = ref
	}

	closeDropdown = () => {
		this.dropdownRef && this.dropdownRef.onToggleMenu()
	}

	fetchCollections = () => {
		const { fetchCollections, companySelected } = this.props

		if (companySelected) {
			fetchCollections()
		}
	}

	openCollectionsModal = () => {
		const { openModal } = this.props
		this.closeDropdown()
		openModal(COLLECTIONS_MANAGE_MODAL)
	}

	openManagePastCollectionsModal = () => {
		const { openModal } = this.props
		openModal(COLLECTIONS_MANAGE_SHARED_MODAL)
		this.closeDropdown()
	}

	render = () => {
		const {
			companySelected,
			collectionsModalIsOpen,
			shareModalIsOpen,
			mergePdfModalIsOpen,
			managePostModalIsOpen,
			rootCollection,
			collectionsIsEnabled
		} = this.props

		if (!companySelected) {
			return null
		}

		if (!collectionsIsEnabled) {
			return null
		}

		const hasDocumentsInRoot = rootCollection && rootCollection.get('documents').size > 0

		return (
			<>
				<DropdownMenuContainer
					ref={this.setDropdownRef}
					renderRaw={<CollectionsNavItem hasDocumentsInRoot={hasDocumentsInRoot} />}>
					<DropdownIconItem
						icon='faBooksMedical'
						tid='collections.nav.dropdown_item.open_collections'
						onClick={this.openCollectionsModal}
					/>
					<DropdownIconItem
						icon='faBooks'
						tid='collections.nav.dropdown_item.manage_completed_actions'
						onClick={this.openManagePastCollectionsModal}
					/>
				</DropdownMenuContainer>

				{collectionsModalIsOpen && <CollectionsModalContainer />}
				{shareModalIsOpen && <CollectionsShareModalContainer />}
				{mergePdfModalIsOpen && <CollectionsMergePdfModalContainer />}
				{managePostModalIsOpen && <CollectionsManageSharedModalContainer />}
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	const companyId = store.company?.company?.id
	const activeModalName = store.modals.getIn(['activeModal', 'name'])

	return {
		rootCollection: store.collections.get('collections').find((c) => c.get('isRoot')),
		collectionsModalIsOpen: activeModalName === COLLECTIONS_MANAGE_MODAL,
		shareModalIsOpen: activeModalName === COLLECTIONS_SHARE_MODAL,
		mergePdfModalIsOpen: activeModalName === COLLECTIONS_MERGE_PDF_MODAL,
		managePostModalIsOpen: activeModalName === COLLECTIONS_MANAGE_SHARED_MODAL,
		collectionsIsEnabled: store.subscriptions.getIn(['helper', 'collectionsEnabled']),
		companySelected: Boolean(companyId),
		companyId
	}
}

const mapActionsToProps = {
	openModal,
	fetchCollections
}

export default connect(mapStoreToProps, mapActionsToProps)(CollectionsNavItemContainer)
