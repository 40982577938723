import React, { PureComponent } from 'react'
import { func, bool } from 'prop-types'
import styled from 'styled-components'
import { list } from 'react-immutable-proptypes'
import Moment from '../../../modules/moment.module'

import ModalInnerWrapper from '../../shared/modal/modal-inner-wrapper'
import List from '../../shared/list/list'
import ListItem from '../../shared/list-item/list-item'
import ListTableColumn from '../../shared/list-table/list-table-column'
import Text from '../../shared/text/text'
import Dot from '../../shared/dot/dot'
import Tooltip from '../../shared/tooltip/tooltip'
import CenteredLoading from '../../shared/modal/centered-loading'
import SimpleDarkInfoPanel from '../../shared/dark-info-panel/simple-dark-info-panel'

import { COLLECTION_TYPE_SHARED_DOCUMENTS, COLLECTION_TYPE_MERGED_DOCUMENTS } from '/shared/constants'

const CreatedDate = ({ children }) => <ListTableColumn minWidth={80}>{children}</ListTableColumn>

const Name = ({ children }) => (
	<ListTableColumn minWidth={30} flex={1}>
		{children}
	</ListTableColumn>
)

const Details = ({ children }) => (
	<ListTableColumn minWidth={160} hideBelow={800}>
		{children}
	</ListTableColumn>
)

const Status = styled(ListTableColumn).attrs({
	minWidth: 16,
	center: true
})`
	margin-left: 0;
	margin-right: 0;
`

const Action = ({ children }) => <ListTableColumn minWidth={140}>{children}</ListTableColumn>

const Dropdown = styled(ListTableColumn).attrs({
	minWidth: 16,
	center: true
})`
	margin-top: 0;
	margin-bottom: 0;
`

const StyledListItem = styled(ListItem)`
	border-top: 1px solid ${({ theme }) => theme.colors.border};
	margin-bottom: 0;
	display: flex;
	align-items: center;

	:last-child {
		border-bottom: 1px solid ${({ theme }) => theme.colors.border};
	}
`

export default class CollectionsSharedList extends PureComponent {
	static propTypes = {
		sharedCollections: list.isRequired,
		renderItemDropdown: func.isRequired,
		isLoading: bool
	}

	static defaultProps = {}

	renderHeader = () => {
		return (
			<>
				<CreatedDate>
					<Text tid='collections.manage_shared.modal.collections.header.date' />
				</CreatedDate>
				<Name>
					<Text tid='collections.manage_shared.modal.collections.header.name' />
				</Name>
				<Details>
					<Text tid='collections.manage_shared.modal.collections.header.details' />
				</Details>
				<Status />
				<Action>
					<Text tid='collections.manage_shared.modal.collections.header.action' />
				</Action>
				<Dropdown />
			</>
		)
	}

	renderDetailsContent = (collection) => {
		const type = collection.get('collectionType')

		if (type === COLLECTION_TYPE_SHARED_DOCUMENTS) {
			const expiresAt = collection.get('expiresAt')
			const isActive = expiresAt && Moment(expiresAt).isAfter()

			if (isActive) {
				return (
					<Text
						tid='collections.manage_shared.modal.collections.details.expires_at'
						values={{ expiresAt: Moment(expiresAt).format('L') }}
					/>
				)
			}

			return <Text tid='collections.manage_shared.modal.collections.details.expired' />
		}

		if (type === COLLECTION_TYPE_MERGED_DOCUMENTS) {
			const title = collection.get('mergedDocumentTitle')

			return <Text singleLine>{`${title}.pdf`}</Text>
		}

		return null
	}

	renderActiveIndicator = (collection) => {
		const type = collection.get('collectionType')
		const expiresAt = collection.get('expiresAt')

		const isSharedType = type === COLLECTION_TYPE_SHARED_DOCUMENTS

		if (!isSharedType) {
			return null
		}

		const isActive = expiresAt && Moment(expiresAt).isAfter()
		const dotColor = isActive ? 'green' : 'persianRed'
		const tid = isActive
			? 'collections.manage_shared.modal.collections.tooltip.active'
			: 'collections.manage_shared.modal.collections.tooltip.inactive'

		return (
			<Tooltip tid={tid} delayShow='instant'>
				<Dot bgColor={dotColor} />
			</Tooltip>
		)
	}

	renderListItem = (collection, index) => {
		const { renderItemDropdown } = this.props
		const createdAt = collection.get('createdAt')
		const formattedDate = createdAt ? Moment(createdAt).format('L') : ''
		const type = collection.get('collectionType')

		return (
			<StyledListItem key={index} hoverColor='lightestGrey'>
				<CreatedDate>
					<Text>{formattedDate}</Text>
				</CreatedDate>

				<Name>
					<Text singleLine>{collection.get('name')}</Text>
				</Name>

				<Details>{this.renderDetailsContent(collection)}</Details>

				<Status>{this.renderActiveIndicator(collection)}</Status>

				<Action>
					<Text tid={`collections.manage_shared.modal.collections.action.${type}`} />
				</Action>
				<Dropdown>{renderItemDropdown(collection)}</Dropdown>
			</StyledListItem>
		)
	}

	render = () => {
		const { sharedCollections, isLoading } = this.props

		if (isLoading) {
			return <CenteredLoading />
		}

		if (sharedCollections.size === 0) {
			return (
				<ModalInnerWrapper fullHeight>
					<SimpleDarkInfoPanel>
						<Text tag='em' tid='collections.manage_shared.no_collections' color='white' />
					</SimpleDarkInfoPanel>
				</ModalInnerWrapper>
			)
		}

		return (
			<ModalInnerWrapper fullHeight>
				<List header={this.renderHeader}>{sharedCollections.map(this.renderListItem)}</List>
			</ModalInnerWrapper>
		)
	}
}
