import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import NotifyAboutSignedDocument from '../../../dumb-components/documents/request-esign/notify-about-signed-doc'

import { fetchAndCacheInvestors } from '../../../actions/investors.actions'
import { patchDocument } from '../../../actions/documents.actions'
import { __DELETE__ } from '/shared/constants'

class NotifyAboutSignedDocumentContainer extends Component {
	deleteUserFromBeingNotified = (user) => {
		const { patchDocument, documentId } = this.props
		const userId = user.get('userId')

		const documentObject = Map({
			shareWith: Map({
				[userId]: __DELETE__
			})
		})

		patchDocument(documentId, documentObject)
	}

	render = () => {
		const { shareWith, investors, usersCache } = this.props

		return (
			<NotifyAboutSignedDocument
				shareWith={shareWith}
				investors={investors}
				usersCache={usersCache}
				onDeleteUser={this.deleteUserFromBeingNotified}
			/>
		)
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		documentId: store.documents.getIn(['document', 'id']),
		investors: store.investors.get('investorsCache'),
		usersCache: store.usersCache.get('usersCache'),
		shareWith: store.documents.getIn(['document', 'shareWith'], Map())
	}
}

const mapActionsToProps = {
	fetchAndCacheInvestors,
	patchDocument
}

export default connect(mapStoreToProps, mapActionsToProps)(NotifyAboutSignedDocumentContainer)
