import React, { PureComponent } from 'react'
import { bool, func, number, string, object } from 'prop-types'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import ListItem from '../../shared/list-item/list-item'
import Text from '../../shared/text/text'
import Icon from '../../shared/icon/icon'
import Tooltip from '../../shared/tooltip/tooltip'

import DndDragHandlers, { hoverStyling } from '../../shared/dnd-drag-handlers/dnd-drag-handlers'
import { VOTING_STATUS_TO_TOOLTIP_MAP } from '../../../constants/meetings'
import { MEETING_VOTING_STARTED, MEETING_VOTING_COMPLETED } from '/shared/constants'

const StyledItem = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	height: 50px;
	width: 100%;
`

const StyledDndDragHandlers = styled(DndDragHandlers)`
	background: white;

	${StyledItem}:hover & {
		${hoverStyling}
	}
`

const StyledStatus = styled.div`
	width: 50px;
	height: 100%;
	background-color: ${(props) => {
		if (props.status === MEETING_VOTING_COMPLETED && props.sealed) {
			return props.theme.colors.green
		} else if (props.status === MEETING_VOTING_STARTED) {
			return props.theme.colors.yellowMid
		} else {
			return props.theme.colors.solitudeDark
		}
	}};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

const StyledContent = styled.div`
	flex: 1;
	padding: ${(props) => props.theme.spacing[3]} ${(props) => props.theme.spacing[4]};
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	min-width: 0;
	justify-content: center;
	overflow: hidden;
`

const StyledTitle = styled.div`
	display: flex;
	flex-direction: column;
`

const StyledListItem = styled(ListItem)`
	margin-bottom: ${(props) => props.theme.spacing[2]};
`

const StyledDraggableWrapper = styled.div``

const StyledButtonContainer = styled.div`
	min-width: 44px;
	max-width: 44px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0;
	min-width: 0;
	margin-right: ${(props) => props.theme.spacing[3]};

	button {
		margin-right: 0;
		margin-left: 0;
	}
`

const StyledWarning = styled.div`
	min-width: 50px;
	max-width: 50px;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

const StyledTooltip = styled.div`
	height: 100%;
`

export default class TaskList extends PureComponent {
	static propTypes = {
		isDragDisabled: bool,
		question: string,
		id: string,
		index: number,
		result: string,
		renderMoreActionsMenu: object,
		status: string,
		atLeastOneAttendeeCanVote: bool,
		sealed: bool,
		rollcallDone: bool
	}

	getStatusIcon = () => {
		const { status, sealed } = this.props

		if (status === MEETING_VOTING_COMPLETED && sealed) {
			return 'faCheck'
		}
		return 'faTimes'
	}

	getFinalResultTid = () => {
		const { result } = this.props

		if (!result) {
			return false
		}

		if (result === 'YES') {
			return 'meetings.voting_result.yes'
		}
		if (result === 'NO') {
			return 'meetings.voting_result.no'
		}
		return 'meetings.voting_result.even'
	}

	renderItem = (provided) => {
		const {
			question,
			id,
			topicRef,
			isDragDisabled,
			result,
			renderMoreActionsMenu,
			status,
			atLeastOneAttendeeCanVote,
			sealed,
			rollcallDone
		} = this.props
		const statusIcon = this.getStatusIcon()
		const finalResultTid = this.getFinalResultTid()
		const tooltipTid = VOTING_STATUS_TO_TOOLTIP_MAP[status]
		const showWarningIcon = !atLeastOneAttendeeCanVote || !rollcallDone
		const warningTooltipTid = !atLeastOneAttendeeCanVote
			? 'meetings.voting.list.tooltip.warning.icon.no_attendees_can_vote'
			: 'meetings.agm.voting.list.tooltip.warning.icon.rollcall_not_done'

		return (
			<StyledListItem hoverColor='lightestGrey' id={id}>
				<StyledItem>
					<StyledDndDragHandlers
						isDragDisabled={isDragDisabled}
						dragHandleProps={provided && provided.dragHandleProps}
					/>

					<StyledTooltip>
						<Tooltip tid={tooltipTid} delayShow='instant'>
							<StyledStatus status={status} sealed={sealed}>
								<Icon icon={statusIcon} color='white' type='solid' />
							</StyledStatus>
						</Tooltip>
					</StyledTooltip>

					<StyledContent>
						<StyledTitle>
							<Text singleLine={true}>{question}</Text>
							{finalResultTid ? (
								<Text
									singeLine={true}
									hasItalic
									tid='meetings.voting.final_result'
									values={{ finalResultText: <Text tid={finalResultTid} /> }}
								/>
							) : (
								<Text singleLine={true} hasItalic>
									{topicRef}
								</Text>
							)}
						</StyledTitle>
					</StyledContent>
					{showWarningIcon && (
						<StyledWarning>
							<Tooltip tid={warningTooltipTid} delayShow='instant'>
								<Icon icon='faExclamationTriangle' size='sml' type='solid' color='orange' />
							</Tooltip>
						</StyledWarning>
					)}

					{renderMoreActionsMenu && <StyledButtonContainer>{renderMoreActionsMenu}</StyledButtonContainer>}
				</StyledItem>
			</StyledListItem>
		)
	}

	render = () => {
		const { index, id, isDragDisabled } = this.props

		return (
			<Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
				{(provided) => (
					<StyledDraggableWrapper ref={provided.innerRef} {...provided.draggableProps}>
						{this.renderItem(provided)}
					</StyledDraggableWrapper>
				)}
			</Draggable>
		)
	}
}
