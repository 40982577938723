import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func } from 'prop-types'
import { List } from 'immutable'
import { fetchSimpleUsers } from '../../../actions/usersCache.actions'
import { saveMeeting } from '../../../actions/meetings.actions'
import { fetchAndCacheInvestors } from '../../../actions/investors.actions'
import UniversalModal from '../../../dumb-components/shared/modal/universal-modal'
import AttendeesModalList from '../../../dumb-components/meetings/attendees-modal-list/attendees-modal-list'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import DropdownMenuItem from '../../../dumb-components/shared/dropdown-item/dropdown-item'
import DropdownStatusButtonContainer from './dropdown-status-button.container'
import Text from '../../../dumb-components/shared/text/text'
import Button from '../../../dumb-components/shared/button/button'
import Moment from '../../../modules/moment.module'
import { ATTENDEE_STATUS_PRESENT, ATTENDEE_STATUS_ABSENT } from '/shared/constants'

class AttendeesModalListContainer extends Component {
	static propTypes = {
		isOpen: bool,
		onClose: func
	}

	state = {
		allSelected: false
	}

	moreActionButtonRefs = {}

	componentDidMount() {
		const { attendees, fetchSimpleUsers, fetchAndCacheInvestors } = this.props

		if (attendees) {
			const userIds = attendees.filter((a) => !a.get('isInvestor') && !a.get('isGuest')).map((a) => a.get('userId'))
			const investmentIds = attendees.filter((a) => !a.get('isGuest')).map((a) => a.get('userId'))
			fetchSimpleUsers(userIds)
			fetchAndCacheInvestors(investmentIds)
		}
	}

	componentDidUpdate = (prevProps) => {
		const { attendees, fetchSimpleUsers } = this.props

		if (attendees && prevProps.attendees !== attendees) {
			fetchSimpleUsers(attendees.keySeq().toList())
		}
	}

	onChange = (attendeeId, fieldName, val) => {
		const { saveMeeting } = this.props
		let { meeting, attendees } = this.props

		if (fieldName === 'statusSwitch') {
			attendees = attendees.setIn(
				[attendeeId, 'status'],
				val === true ? ATTENDEE_STATUS_PRESENT : ATTENDEE_STATUS_ABSENT
			)
			attendees = attendees.setIn([attendeeId, 'statusSetFromApp'], true)
			attendees = attendees.setIn([attendeeId, 'haveResponded'], true)
			attendees = attendees.setIn([attendeeId, 'metadata', 'respondedAt', 'respondedAt'], Moment().toISOString())
		} else {
			attendees = attendees.setIn([attendeeId, fieldName], val)
		}
		meeting = meeting.set('attendees', attendees)
		saveMeeting(meeting)
	}

	onSelectAll = (allSelected) => {
		const { saveMeeting } = this.props
		let { meeting, attendees } = this.props

		attendees = attendees.map((attendee) => {
			const attendeeStatus = attendee.get('status')

			if (allSelected) {
				// Don't change for the first toggle all run if attendee already answered the email
				if (attendeeStatus !== ATTENDEE_STATUS_PRESENT) {
					attendee = attendee.setIn(['metadata', 'respondedAt', 'respondedAt'], Moment().toISOString())
				}

				attendee = attendee.set('status', ATTENDEE_STATUS_PRESENT)
			} else {
				// Don't change for the first toggle all run if attendee already answered the email
				if (attendeeStatus !== ATTENDEE_STATUS_ABSENT) {
					attendee = attendee.setIn(['metadata', 'respondedAt', 'respondedAt'], Moment().toISOString())
				}

				attendee = attendee.set('status', ATTENDEE_STATUS_ABSENT)
			}

			attendee = attendee.set('haveResponded', true)
			attendee = attendee.set('statusSetFromApp', true)

			return attendee
		})

		meeting = meeting.set('attendees', attendees)
		saveMeeting(meeting)
		this.setState({ allSelected })
	}

	removeAttendee = (attendeeId) => {
		const { saveMeeting } = this.props
		let { meeting, attendees } = this.props

		attendees = attendees.remove(attendeeId)
		meeting = meeting.set('attendees', attendees)
		saveMeeting(meeting)
	}

	onClose = () => {
		const { onClose } = this.props
		onClose && onClose()
	}

	renderStatus = (id) => {
		const attendee = this.props.attendees.get(id)

		return (
			<DropdownStatusButtonContainer
				fieldName={id}
				haveResponded={attendee && attendee.get('haveResponded')}
				status={attendee && attendee.get('status')}
				readOnly
			/>
		)
	}

	renderMoreActionButton = (id) => {
		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisV'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				tooltipActiveState='btnMoreActions'
				ref={(ref) => (this.moreActionButtonRefs[id] = ref)}>
				<DropdownMenuItem onClick={() => this.removeAttendee(id)}>
					<Text tid='generic.remove' />
				</DropdownMenuItem>
			</DropdownMenuContainer>
		)
	}

	renderAttendeesList = () => {
		const { usersCache, investors, chairman, secretary } = this.props
		const { allSelected } = this.state
		const attendees =
			this.props.attendees &&
			this.props.attendees.sortBy((item) => {
				if (item.get('userId') === chairman) {
					return -3
				} else if (item.get('userId') === secretary) {
					return -2
				}
				return 0
			})

		return (
			<AttendeesModalList
				attendees={attendees}
				usersCache={usersCache}
				investors={investors}
				onChange={this.onChange}
				renderStatus={this.renderStatus}
				renderMoreAction={this.renderMoreActionButton}
				onSelectAll={this.onSelectAll}
				allSelected={allSelected}
			/>
		)
	}

	renderModalButtons = () => {
		return (
			<div>
				<Button tid='meetings.agenda.attendees_modal.close' onClick={this.onClose} />
			</div>
		)
	}

	render() {
		const { isOpen } = this.props

		return (
			<UniversalModal
				isOpen={isOpen}
				titleTid='meetings.agenda.attendees_modal.title'
				modalFooterComponent={this.renderModalButtons()}
				renderContent={this.renderAttendeesList}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get('meeting'),
		attendees: store.meetings.getIn(['meeting', 'attendees']),
		chairman: store.meetings.getIn(['meeting', 'chairman']),
		secretary: store.meetings.getIn(['meeting', 'secretary']),
		usersCache: store.usersCache.get('usersCache'),
		investors: store.investors.get('investorsCache')
	}
}

const mapActionsToProps = {
	fetchSimpleUsers,
	saveMeeting,
	fetchAndCacheInvestors
}

export default connect(mapStoreToProps, mapActionsToProps)(AttendeesModalListContainer)
