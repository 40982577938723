import React, { Component } from 'react'
import { connect } from 'react-redux'
import { oneOf, bool } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import { DOCUMENT_DOWNLOAD_SIGNED_MODAL } from '../../constants/modals'

import { closeModal } from '../../actions/modals.actions'
import { downloadDocumentPublic } from '../../actions/documents.actions'

import DocumentDownloadSignedModal from '../../dumb-components/documents/document-download-signed-modal/document-download-signed-modal'

class DocumentDownloadSignedModalContainer extends Component {
	// Supplied by activeModal options object
	static propTypes = {
		isOpen: bool,
		document: map,
		mode: oneOf(['DOWNLOAD', 'VIEWER'])
	}

	getCompanyId = () => {
		const { document, companyId } = this.props

		const isSharedFromCompanyId = document.get('isSharedFromCompanyId')
		if (isSharedFromCompanyId) {
			return isSharedFromCompanyId
		}

		const companyIdFromDoc = document.getIn(['companyInformation', 'id'])
		if (companyIdFromDoc) {
			return companyIdFromDoc
		}

		return companyId
	}

	doOpenOrDownload = ({ openInViewer, getSignedVersion }) => {
		const { downloadDocumentPublic, document, userId } = this.props
		const documentId = document.get('id')
		const companyId = this.getCompanyId()

		downloadDocumentPublic({ documentId, openInViewer, getSignedVersion, companyId, userId })
	}

	openOriginal = () => {
		this.doOpenOrDownload({ openInViewer: true, getSignedVersion: false })
		this.closeModal()
	}

	openSigned = () => {
		this.doOpenOrDownload({ openInViewer: true, getSignedVersion: true })
		this.closeModal()
	}

	onDownloadOriginal = () => {
		this.doOpenOrDownload({ openInViewer: false, getSignedVersion: false })
		this.closeModal()
	}

	onDownloadSigned = () => {
		this.doOpenOrDownload({ openInViewer: false, getSignedVersion: true })
		this.closeModal()
	}

	closeModal = () => {
		this.props.closeModal()
	}

	render = () => {
		const { isOpen, document, mode } = this.props

		if (!isOpen || !document || !mode) {
			return null
		}

		return (
			<DocumentDownloadSignedModal
				isOpen={true}
				mode={mode}
				onOpenOriginal={this.openOriginal}
				onOpenSigned={this.openSigned}
				onDownloadOriginal={this.onDownloadOriginal}
				onDownloadSigned={this.onDownloadSigned}
				onCancel={this.closeModal}
			/>
		)
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		isOpen: store.modals.getIn(['activeModal', 'name']) === DOCUMENT_DOWNLOAD_SIGNED_MODAL,
		document: store.modals.getIn(['activeModal', 'options', 'document']),
		mode: store.modals.getIn(['activeModal', 'options', 'mode']), // DOWNLOAD or VIEWER
		userId: store.user.getIn(['userObj', 'id']),
		companyId: store.company?.company?.id
	}
}

const mapActionsToProps = {
	closeModal,
	downloadDocumentPublic
}

export default connect(mapStoreToProps, mapActionsToProps)(DocumentDownloadSignedModalContainer)
