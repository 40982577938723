import React from 'react';
import styled, { css } from 'styled-components';
import { oneOf, bool, string, oneOfType, number } from 'prop-types';
import '../../../assets/libs/fontawesome/css/fontawesome.min.css';
import '../../../assets/libs/fontawesome/css/brands.min.css';
import '../../../assets/libs/fontawesome/css/light.min.css';
import '../../../assets/libs/fontawesome/css/solid.min.css';
import theme from '../../../../theme';

export const mapIconToClassName = {
	faAddressBook: 'fa-address-book',
	faAngleDown: 'fa-angle-down',
	faAngleLeft: 'fa-angle-left',
	faAngleRight: 'fa-angle-right',
	faArchive: 'fa-archive',
	faArrowAltFromBottom: 'fa-arrow-alt-from-bottom',
	faArrowAltToBottom: 'fa-arrow-alt-to-bottom',
	faArrowCircleLeft: 'fa-arrow-circle-left',
	faArrowFromBottom: 'fa-arrow-from-bottom',
	faArrowLeft: 'fa-arrow-left',
	faArrowRight: 'fa-arrow-right',
	faArrowsAlt: 'fa-arrows-alt',
	faAsterisk: 'fa-asterisk',
	faBan: 'fa-ban',
	faBars: 'fa-bars',
	faBell: 'fa-bell',
	faBells: 'fa-bell',
	faBookmark: 'fa-bookmark',
	faBooks: 'fa-books',
	faBooksMedical: 'fa-books-medical',
	faBookUser: 'fa-book-user',
	faCalendar: 'fa-calendar',
	faCalendarAlt: 'fa-calendar-alt',
	faCalendarDay: 'fa-calendar-day',
	faCalendarPlus: 'fa-calendar-plus',
	faCaretDown: 'fa-caret-down',
	faCaretLeft: 'fa-caret-left',
	faCaretRight: 'fa-caret-right',
	faCaretUp: 'fa-caret-up',
	faCcAmex: 'fa-cc-amex',
	faCcDinersClub: 'fa-cc-diners-club',
	faCcDiscover: 'fa-cc-discover',
	faCcJcb: 'fa-cc-jcb',
	faCcMastercard: 'fa-cc-mastercard',
	faCcVisa: 'fa-cc-visa',
	faCheck: 'fa-check',
	faCheckCircle: 'fa-check-circle',
	faChevronDoubleRight: 'fa-chevron-double-right',
	faChevronDown: 'fa-chevron-down',
	faChevronLeft: 'fa-chevron-left',
	faChevronRight: 'fa-chevron-right',
	faChevronUp: 'fa-chevron-up',
	faChrome: 'fa-chrome',
	faCity: 'fa-city',
	faClipboardCheck: 'fa-clipboard-check',
	faClipboardCheckList: 'fa-clipboard-list-check',
	faClipboardList: 'fa-clipboard-list',
	faClock: 'fa-clock',
	faClone: 'fa-clone',
	faCloudUpload: 'fa-cloud-upload',
	faCloudUploadAlt: 'fa-cloud-upload-alt',
	faCodeBranch: 'fa-code-branch',
	faCodeMerge: 'fa-code-merge',
	faCog: 'fa-cog',
	faCogs: 'fa-cogs',
	faComment: 'fa-comment',
	faCommentAlt: 'fa-comment-alt',
	faCommentAltExclamation: 'fa-comment-alt-exclamation',
	faCommentAltSmile: 'fa-comment-alt-smile',
	faCommentLines: 'fa-comment-lines',
	faCommentPlus: 'fa-comment-plus',
	faCompressArrowsAlt: 'fa-compress-arrows-alt',
	faCopy: 'fa-copy',
	faCreditCardBlank: 'fa-credit-card-blank',
	faDownload: 'fa-download',
	faEdge: 'fa-edge',
	faEdit: 'fa-edit',
	faEllipsisH: 'fa-ellipsis-h',
	faEllipsisV: 'fa-ellipsis-v',
	faEnvelope: 'fa-envelope',
	faExclamation: 'fa-exclamation',
	faExclamationSquare: 'fa-exclamation-square',
	faExclamationTriangle: 'fa-exclamation-triangle',
	faExclamationCircle: 'fa-exclamation-circle',
	faExpandAlt: 'fa-expand-alt',
	faExpandArrowsAlt: 'fa-expand-arrows-alt',
	faExternalLinkAlt: 'fa-external-link-alt',
	faExternalLinkSquare: 'fa-external-link-square',
	faEye: 'fa-eye',
	faEyeSlash: 'fa-eye-slash',
	faFile: 'fa-file',
	faFileAlt: 'fa-file-alt',
	faFileCertificate: 'fa-file-certificate',
	faFileChartLine: 'fa-file-chart-line',
	faFileCheck: 'fa-file-check',
	faFileContract: 'fa-file-contract',
	faFileEdit: 'fa-file-edit',
	faFileInvoice: 'fa-file-invoice',
	faFilePdf: 'fa-file-pdf',
	faFilePlus: 'fa-file-plus',
	faFileSearch: 'fa-file-search',
	faFileSignature: 'fa-file-signature',
	faFileSpreadsheet: 'fa-file-spreadsheet',
	faFilter: 'fa-filter',
	faFingerprint: 'fa-fingerprint',
	faFirefox: 'fa-firefox',
	faFolder: 'fa-folder',
	faHistory: 'fa-history',
	faHome: 'fa-home',
	faHouseFlood: 'fa-house-flood',
	faInfo: 'fa-info',
	faInfoCircle: 'fa-info-circle',
	faLevelDownAlt: 'fa-level-down-alt',
	faLink: 'fa-link',
	faList: 'fa-list',
	faListAlt: 'fa-list-alt',
	faListUl: 'fa-list-ul',
	faLock: 'fa-lock',
	faLongArrowLeft: 'fa-long-arrow-left',
	faLongArrowRight: 'fa-long-arrow-right',
	faMailBulk: 'fa-mail-bulk',
	faMehBlank: 'fa-meh-blank',
	faMinus: 'fa-minus',
	faPaperclip: 'fa-paperclip',
	faPaperPlane: 'fa-paper-plane',
	faPaste: 'fa-paste',
	faPenAlt: 'fa-pen-alt',
	faPlayCircle: 'fa-play-circle',
	faPlus: 'fa-plus',
	faPlusCircle: 'fa-plus-circle',
	faPlusSquare: 'fa-plus-square',
	faPowerOff: 'fa-power-off',
	faPrint: 'fa-print',
	faQuestion: 'fa-question',
	faQuestionCircle: 'fa-question-circle',
	faRandom: 'fa-random',
	faReceipt: 'fa-receipt',
	faRepeat: 'fa-repeat',
	faReply: 'fa-reply',
	faRetweet: 'fa-retweet',
	faRocket: 'fa-rocket',
	faRss: 'fa-rss',
	faSafari: 'fa-safari',
	faSave: 'fa-save',
	faSearch: 'fa-search',
	faShare: 'fa-share',
	faShareAll: 'fa-share-all',
	faShareAlt: 'fa-share-alt',
	faSignature: 'fa-signature',
	faSlidersH: 'fa-sliders-h',
	faSmile: 'fa-smile',
	faSpinner: 'fa-spinner',
	faSquareFull: 'fa-square-full',
	faStar: 'fa-star',
	faStickyNote: 'fa-sticky-note',
	faStopCircle: 'fa-stop-circle',
	faSyncAlt: 'fa-sync-alt',
	faTasks: 'fa-tasks',
	faTh: 'fa-th',
	faThLarge: 'fa-th-large',
	faThumbtack: 'fa-thumbtack',
	faTimes: 'fa-times',
	faTimesCircle: 'fa-times-circle',
	faTrash: 'fa-trash',
	faTrashAlt: 'fa-trash-alt',
	faTrashUndo: 'fa-trash-undo',
	faTrashUndoAlt: 'fa-trash-undo-alt',
	faUnlink: 'fa-unlink',
	faUnlock: 'fa-unlock',
	faUpload: 'fa-upload',
	faUser: 'fa-user',
	faUserCheck: 'fa-user-check',
	faUserCircle: 'fa-user-circle',
	faUserCog: 'fa-user-cog',
	faUserEdit: 'fa-user-edit',
	faUserFriends: 'fa-user-friends',
	faUserMinus: 'fa-user-minus',
	faUserPlus: 'fa-user-plus',
	faUsers: 'fa-users',
	faUsersMedical: 'fa-users-medical',
	faUserTag: 'fa-user-tag',
	faInfoCirle: 'fa-info-circle'
};

const mapTypeToClassName = {
	brand: 'fab',
	light: 'fal',
	solid: 'fas'
};

const mapRotateToClassName = {
	90: 'fa-rotate-90',
	180: 'fa-rotate-180',
	270: 'fa-rotate-270'
};

const StyledFontAwesomeIcon = styled.span`
	color: ${(props) => props.theme.colors[props.color] || props.color};
	font-size: ${(props) =>
		typeof props.fontSize === 'number' ? `${props.fontSize}px` : props.theme.iconSizes[props.fontSize]};

	${(props) =>
		props.faded &&
		css`
			opacity: 0.3;
		`}
`;

const StyledIcon = (props) => {
	const { type, icon, spin, rotate } = props;

	const typeClassName = mapTypeToClassName[type];
	const iconClassName = mapIconToClassName[icon];
	let className = `${typeClassName} ${iconClassName}`;

	if (spin) {
		className += ' fa-spin';
	}

	if (rotate) {
		const rotateClassName = mapRotateToClassName[rotate];
		className += ' ' + rotateClassName;
	}

	return (
		<StyledFontAwesomeIcon
			theme={theme}
			className={className}
			color={props.color}
			fontSize={props.size}
			faded={props.faded}
		/>
	);
};

StyledIcon.defaultProps = {
	type: 'light',
	size: 'normal',
	spin: false,
	color: 'midGrey'
};

StyledIcon.propTypes = {
	color: string,
	icon: oneOf(Object.keys(mapIconToClassName)).isRequired,
	type: oneOf(['light', 'solid', 'brand']),
	size: oneOfType([oneOf(['xxs', 'xs', 'sm', 'sml', 'normal', 'lg', 'xl', 'xxl', 'xxxl', '10']), number]),
	spin: bool,
	faded: bool,
	rotate: oneOf(['90', '180', '270'])
};

export default StyledIcon;
