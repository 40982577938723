import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func } from 'prop-types'
import { List, Map } from 'immutable'
import uuid from 'uuid'
import { FILTER_FIELDS } from '../../constants/shares'
import { setFilterBy, unsetFilterBy } from '../../actions/investors.actions'
import ObjectFilterContainer from '../shared/object-filter.container'

class ShareholdersAdvancedFilterContainer extends Component {
	static propTypes = {
		isOpen: bool,
		onClose: func
	}

	state = {
		fields: FILTER_FIELDS
	}

	onChange = (fieldName, val) => {
		const { setFilterBy } = this.props
		let valueIsEmpty = !val

		if (fieldName === 'date') {
			val = val.filter((value) => {
				return value !== null
			})

			valueIsEmpty = val.size === 0
		}

		setFilterBy(
			Map({
				source: fieldName,
				values: !valueIsEmpty ? List([val]) : null
			})
		)
	}

	onClose = () => {
		const { onClose } = this.props

		onClose && onClose()
	}

	onClearAll = () => {
		const { unsetFilterBy } = this.props
		const { fields } = this.state
		let sources = List()

		fields.forEach((row) => {
			row.forEach((field) => {
				sources = sources.push(field.get('fieldName'))
			})
		})

		unsetFilterBy(sources)
	}

	render = () => {
		const { isOpen, company, filterBy, defaultFilters } = this.props
		const { fields } = this.state
		const id = `${company.id}-shareholders`

		return (
			<ObjectFilterContainer
				key={uuid()}
				id={id}
				fields={fields}
				values={filterBy}
				defaultValues={defaultFilters}
				title='invstors.invstors_filter'
				isOpen={isOpen}
				onChange={this.onChange}
				onClose={this.onClose}
				onClearAll={this.onClearAll}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		filterBy: store.investors.get('filterBy'),
		company: store.company.company,
		defaultFilters: store.investors.get('defaultFilters')
	}
}

const mapActionsToProps = {
	setFilterBy,
	unsetFilterBy
}

export default connect(mapStoreToProps, mapActionsToProps)(ShareholdersAdvancedFilterContainer)
