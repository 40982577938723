import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Divider } from '@mui/material'
import Button from '@mui/material/Button'
import SendReceiptToAllModal from './send-receipt-to-all-modal'
import { useDispatch, useSelector } from 'react-redux'
import { sendReceipts } from '../../../../actions/emissions.actions'

const SendReceiptPopUpModal = (props) => {
	const { t } = useTranslation()
	const [sendReceiptsToAllModal, setSendReceiptsToAllModal] = useState(false)
	const dispatch = useDispatch()
	const emission = useSelector((state) => state.emissions.current)
	const company = useSelector((state) => state.company.company.name)
	const tEmailSubject = t('emission.payment.receipt.send.email.subject', { company: company })
	const [emailSubject, setEmailSubject] = useState(tEmailSubject)
	const tEmailMessage = t('emission.payment.receipt.send.email.message')
	const [emailMessage, setEmailMessage] = useState(tEmailMessage)

	const sendEmail = async (emailSubject, emailMessage) => {
		const investmentIdDocIdList = []
		if (props.toAll) {
			emission.closures
				.find((c) => c.id === props.closureId)
				.allocations.filter((allocation) => {
					return !allocation.receiptSent
				})
				.forEach((allocation) =>
					investmentIdDocIdList.push({
						investmentId: allocation.investmentId
					})
				)
		} else {
			setEmailSubject(emailSubject)
			setEmailMessage(emailMessage)
			investmentIdDocIdList.push({ investmentId: props.investmentId })
		}
		if (investmentIdDocIdList.length !== 0) {
			await dispatch(
				sendReceipts(
					emission.companyId,
					emission.id,
					props.closureId,
					emailSubject,
					emailMessage,
					investmentIdDocIdList
				)
			)
		}
	}
	const sendEmailClicked = async () => {
		await sendEmail(emailSubject, emailMessage)
		props.close()
	}

	function amountOfReceiptsNotSent() {
		return props.closure?.allocations.filter(
			(a) => (a['receiptDocId_sv'] || a['receiptDocId_en']) && !a.receiptSent
		).length
	}

	return (
		<Dialog open={props.open} onClose={props.close}>
			<SendReceiptToAllModal
				closeAll={props.close}
				closure={props.closure}
				open={sendReceiptsToAllModal}
				close={() => setSendReceiptsToAllModal(false)}
				sendEmail={sendEmail}
				emailSubject={emailSubject}
				emailMessage={emailMessage}
			/>
			<DialogContent sx={{ px: 6, py: 4, minWidth: '60rem', minHeight: '30rem' }}>
				<IconButton
					aria-label='close'
					onClick={props.close}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[600]
					}}>
					<CloseIcon />
				</IconButton>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					{props.toAll ? (
						<>
							<Typography sx={{ textAlign: 'center', pb: 2, pt: 3 }} variant='h4'>
								{t('emissions.payment.send-receipt-toAll', { amount: amountOfReceiptsNotSent() })}
							</Typography>
							<Typography sx={{ textAlign: 'flex-start', pb: 4 }} variant='body2'>
								{t('emissions.payment.send-receipt-toAll-pop-up.desc')}
							</Typography>
						</>
					) : (
						<>
							<Typography sx={{ textAlign: 'center', pb: 2, pt: 3 }} variant='h4'>
								{t('emissions.payment.send-receipt')}
							</Typography>
							<Typography sx={{ textAlign: 'flex-start', pb: 4 }} variant='body2'>
								{t('emissions.payment.send-receipt-pop-up.desc')}
							</Typography>
						</>
					)}
					<Divider sx={{ mb: '16px' }} />
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography variant={'subtitle1'}>{t('subject')}</Typography>
						<Typography
							onClick={() => setSendReceiptsToAllModal(true)}
							variant={'subtitle1'}
							sx={{ color: '#369FF4', cursor: 'pointer' }}>
							{t('minutes.edit')}
						</Typography>
					</Box>
					<Typography variant={'body2'} sx={{ mb: '24px' }}>
						{emailSubject}
					</Typography>
					<Typography variant={'subtitle1'}>{t('emissions.invitations.message')}</Typography>
					<Typography variant={'body2'}>{emailMessage}</Typography>
					<Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
						<Button
							variant={'text'}
							sx={{ color: '#757575', fontWeight: 'bold', alignSelf: 'center', mr: '16px' }}
							onClick={props.close}>
							<Typography variant={'h5'}>{t('dialog.generic.cancel')}</Typography>
						</Button>
						{props.toAll ? (
							<Button
								variant={'contained'}
								sx={{ fontWeight: 'bold', alignSelf: 'center', ml: '16px' }}
								onClick={sendEmailClicked}>
								<Typography variant={'h5'}>
									{t('emissions.payment.send-receipt-toAll-button')}
								</Typography>
							</Button>
						) : (
							<Button
								variant={'contained'}
								sx={{ fontWeight: 'bold', alignSelf: 'center', ml: '16px' }}
								onClick={sendEmailClicked}>
								<Typography variant={'h5'}>{t('emissions.payment.send-receipt')}</Typography>
							</Button>
						)}
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	)
}

export default SendReceiptPopUpModal
