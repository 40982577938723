import req from '../modules/request.module'
import { fromJS } from 'immutable'
import {
	USER_CACHE_ADD_USERS_TO_CACHE,
	USER_CACHE_REMOVE_USER,
	USER_CACHE_UPDATE_REQUEST_CACHE,
	USER_CACHE_RESET_REDUCER
} from './types'

/**
 * Action for removing a user from the cache
 */
export function removeUserFromCache(userId) {
	return function (dispatch) {
		dispatch({
			type: USER_CACHE_REMOVE_USER,
			payload: userId
		})
	}
}

export function resetUserCache() {
	return function (dispatch) {
		dispatch({ type: USER_CACHE_RESET_REDUCER })
	}
}
/**
 * Action for fetching users
 */
export function fetchSimpleUsers(userIds) {
	return function (dispatch, getState) {
		const cache = getState().usersCache.get('usersCache')
		const requestCache = getState().usersCache.get('requestCache')
		userIds = userIds.filter((id) => {
			return id && !cache.has(id) && !requestCache.has(id)
		})
		if (userIds.size > 0) {
			dispatch({ type: USER_CACHE_UPDATE_REQUEST_CACHE, payload: userIds })
			req.post(`/users/public/users`, userIds.toJS()).then((response) => {
				dispatch({ type: USER_CACHE_ADD_USERS_TO_CACHE, payload: fromJS(response.data) })
			})
		}
	}
}
