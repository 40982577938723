import React, { Component } from 'react'
import { string, object } from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import svg from '../../assets/images/processing-bar.svg'

class ProcessingBar extends Component {
	state = {
		open: true
	}

	componentDidMount = () => { }

	render = () => {
		if (!this.props.fakeLoading) {
			return null
		}

		return (
			<div className='processing-bar'>
				<div className='processing-bar__body'>
					<div className='processing-bar__left-color'></div>
					<div className='processing-bar__spinner'>
						<img src={svg} alt='Invono Logo' className='' />
						<span className='text text--invono processing-bar__text'>
							<FormattedMessage id={this.props.fakeLoadingMessage} />
						</span>
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		fakeLoading: state.notify.fakeLoading,
		fakeLoadingMessage: state.notify.fakeLoadingMessage
	}
}

const mapActionsToProps = {}

ProcessingBar = connect(mapStateToProps, mapActionsToProps)(ProcessingBar)
export default ProcessingBar
