import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'

import SharedCollectionOverview from '../../../dumb-components/collections/shared-modal/shared-collection-overview'

import { fetchAndCacheInvestors } from '../../../actions/investors.actions'
import { fetchSimpleUsers } from '../../../actions/usersCache.actions'
import { unshareSharedCollection } from '../../../actions/shared-collections.actions'

class SharedCollectionOverviewContainer extends Component {
	static propTypes = {
		collection: map.isRequired
	}

	componentDidMount = () => {
		const { collection, fetchAndCacheInvestors, fetchSimpleUsers } = this.props
		const shareWith = collection.get('shareWith')

		const investmentIds = shareWith.filter((u) => u.get('isInvestor')).map((u) => u.get('userId'))
		fetchAndCacheInvestors(investmentIds)

		const userIds = shareWith.filter((u) => !u.get('isInvestor') && !u.get('isGuest')).map((u) => u.get('userId'))
		fetchSimpleUsers(userIds.toList())
	}

	turnOffShare = () => {
		const { collection, unshareSharedCollection } = this.props

		unshareSharedCollection(collection)
	}

	render = () => {
		const { collection, usersCache, investors } = this.props

		return (
			<SharedCollectionOverview
				collection={collection}
				onTurnOffShare={this.turnOffShare}
				getUserName={this.handleGetUserName}
				usersCache={usersCache}
				investors={investors}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		collection: store.sharedCollections.get('selectedCollection'),
		usersCache: store.usersCache.get('usersCache'),
		investors: store.investors.get('investorsCache')
	}
}

const mapActionsToProps = {
	fetchAndCacheInvestors,
	fetchSimpleUsers,
	unshareSharedCollection
}

export default connect(mapStoreToProps, mapActionsToProps)(SharedCollectionOverviewContainer)
