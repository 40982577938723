import { Map } from 'immutable'
import { func } from 'prop-types'
import React, { useState } from 'react'
import { map } from 'react-immutable-proptypes'
import { useDispatch } from 'react-redux'
import Panel from '../../../../dumb-components/panel'
import EmailField from './email-field'
import { setDefaultEmail } from '../../../../actions/user.actions'

const EmailPanel = ({ user }) => {
	const dispatch = useDispatch()
	const [userState, setUser] = useState(user)
	const userId = user.get('id')
	const defaultEmail = userState.get('defaultEmail')
	const anyUnverifiedEmails = user.get('emails').some((email) => email.get('verified') === false)
	const onChangeDefaultEmail = (val) => {
		setUser(userState.set('defaultEmail', val))
		dispatch(setDefaultEmail(userId, val))
	}

	return (
		<Panel tid='generic.email'>
			{user.get('emails').map((email, index) => (
				<EmailField
					key={email.get('id')}
					index={index}
					userId={userId}
					emailEntry={email}
					defaultEmail={defaultEmail}
					mode='edit'
					onChangeDefaultEmail={onChangeDefaultEmail}
				/>
			))}
			{!anyUnverifiedEmails && (
				<EmailField
					userId={userId}
					emailEntry={Map({ email: '' })}
					defaultEmail={defaultEmail}
					mode='create'
					onChangeDefaultEmail={onChangeDefaultEmail}
				/>
			)}
		</Panel>
	)
}

EmailPanel.propTypes = {
	user: map,
	changeUserEmail: func,
	addErrorNotification: func,
	addInfoNotification: func,
	clearNotifications: func,
	verifyEmailCode: func
}

export default EmailPanel
