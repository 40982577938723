import { List, Map } from 'immutable';
import { element, func, node, oneOfType, bool, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { map } from 'react-immutable-proptypes';
import { TAB_TEAMS_AND_SHAREHOLDES } from '../select-user-modal/select-user-modal.constants';
import { PeopleModalContext } from './people-modal.context';
import { COPY_CONTACT_TO_COMPANY_MODAL } from '../../../constants/modals';
import { openModal } from '../../../actions/modals.actions';
import { useDispatch, useSelector } from 'react-redux';
import { copyContactsToCompanies } from '../../../actions/company.actions';

function PeopleModalProvider({
	isOpen: defaultIsOpen,
	values,
	singleMode: defaultSingleMode,
	isLoading: defaultIsLoading,
	children,
	onChange,
	initialActiveTab,
	canCopyToMultipleCompanyAddressbooks
}) {
	const [singleMode, setSingleMode] = useState(defaultSingleMode);
	const [selectedPeople, setSelectedPeople] = useState(values);
	const [activeTab, setActiveTab] = useState(initialActiveTab);
	const [isLoading, setIsLoading] = useState(defaultIsLoading);
	const [isOpen, setIsOpen] = useState();
	const [floatingFilter, setFloatingFilter] = useState(false);
	const [filters, setFilters] = useState({});
	const [showGridInExtendedView, setShowGridInExtendedView] = useState(false);
	const [selectedPersonalContacts, setSelectedPersonalContacts] = useState([]);
	const [personalContactsHasBeenCopied, setPersonalContactsHasBeenCopied] = useState(false);
	const [showColumnToggle, setShowColumnToggle] = useState(true);
	const [isFloatingFilterActive, setIsFloatingFilterActive] = useState(false);
	const companyId = useSelector((state) => state.company.company?.id);
	const dispatch = useDispatch();
	const prevActiveTab = useRef(activeTab);

	const processSelectedPeople = (people) => {
		// Trim email for guest people
		people = people.map((person) => {
			const email = person.get('email', '');

			if (email) {
				return person.set('email', email.trim());
			}

			return person;
		});

		return people;
	};

	const genericOnSave = (onSave) => {
		const people = processSelectedPeople(selectedPeople);
		onSave(people, {
			resetSelectedPeople
		});
	};

	const genericOnCancel = (onCancel) => {
		resetSelectedPeople();
		onCancel();
	};

	const updatePersonalContactsHasBeenCopied = () => {
		setSelectedPersonalContacts([]);
		setPersonalContactsHasBeenCopied(true);
	};

	const copyPeopleToCompanyContacts = (people) => {
		if (canCopyToMultipleCompanyAddressbooks) {
			dispatch(
				openModal(COPY_CONTACT_TO_COMPANY_MODAL, {
					contactsToCopy: people,
					callback: updatePersonalContactsHasBeenCopied
				})
			);
			return;
		}

		dispatch(copyContactsToCompanies(people, [companyId], updatePersonalContactsHasBeenCopied));
	};

	const resetSelectedPeople = () => {
		setSelectedPeople(values);
	};

	useEffect(() => {
		setIsOpen(defaultIsOpen);
	}, [defaultIsOpen]);

	useEffect(() => {
		setSelectedPeople(values);
	}, [values]);

	useEffect(() => {
		if (onChange) {
			const people = processSelectedPeople(selectedPeople);
			onChange(people);
		}

		return () => {};
	}, [selectedPeople]);

	useEffect(() => {
		if (activeTab !== prevActiveTab.current) {
			setShowColumnToggle(true);
			setIsFloatingFilterActive(false);
			prevActiveTab.current = activeTab;
		}
	}, [activeTab]);

	const value = {
		singleMode,
		setSingleMode,
		selectedPeople,
		setSelectedPeople,
		activeTab,
		setActiveTab,
		isLoading,
		setIsLoading,
		isOpen,
		setIsOpen,
		floatingFilter,
		setFloatingFilter,
		processSelectedPeople,
		genericOnSave,
		filters,
		setFilters,
		showGridInExtendedView,
		setShowGridInExtendedView,
		selectedPersonalContacts,
		setSelectedPersonalContacts,
		canCopyToMultipleCompanyAddressbooks,
		copyPeopleToCompanyContacts,
		personalContactsHasBeenCopied,
		setPersonalContactsHasBeenCopied,
		resetSelectedPeople,
		showColumnToggle,
		setShowColumnToggle,
		genericOnCancel,
		isFloatingFilterActive,
		setIsFloatingFilterActive
	};

	return <PeopleModalContext.Provider value={value}>{children}</PeopleModalContext.Provider>;
}

PeopleModalProvider.propTypes = {
	isOpen: bool.isRequired,
	values: map,
	singleMode: bool,
	isLoading: bool,
	children: node,
	onChange: func,
	initialActiveTab: string
};

PeopleModalProvider.defaultProps = {
	values: Map(),
	singleMode: false,
	isLoading: false,
	initialActiveTab: TAB_TEAMS_AND_SHAREHOLDES
};

export { PeopleModalProvider };
