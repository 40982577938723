import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import Button from '@mui/material/Button'
import { Select } from '@mui/material/Select'
import { createEmissionClosure } from '../../../../actions/emissions.actions'
import { useDispatch } from 'react-redux'
import { emissionConsts } from '../../../../constants/emissions'
import FullPageSpinner from '../../common/full-page-spinner'

const PartialClosureModal = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)

	const handleClick = async (closeType) => {
		setLoading(true)
		await dispatch(createEmissionClosure(props.companyId, props.emissionId, closeType))
		props.close()
	}

	return (
		<Dialog maxWidth={'md'} fullWidth={true} open={props.open} onClose={props.close}>
			<DialogContent sx={{ px: 6, py: 4, minWidth: '60rem' }}>
				{loading ? (
					<Box sx={{ py: 7 }}>
						<FullPageSpinner text={props.spinnerText} />
					</Box>
				) : (
					<>
						<IconButton
							aria-label='close'
							onClick={props.close}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[600]
							}}>
							<CloseIcon />
						</IconButton>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							{props.closeDesc && (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Typography sx={{ textAlign: 'center', pb: 3, mt: '16px' }} variant='h4'>
										{t('emissions.allocation.close-download')}
									</Typography>
									<Box sx={{ textAlign: 'flex-start', pb: 3 }}>
										{t('emissions.allocation.close-new-emission.desc')}
									</Box>
									<Box sx={{ textAlign: 'flex-start', pb: 2 }}>
										{t('emissions.allocation.partial-close-desc2')}
									</Box>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											width: '100%',
											mt: 4,
											mb: '16px',
											columnGap: 2
										}}>
										<Button
											variant={'text'}
											sx={{ fontWeight: 'bold', alignSelf: 'center' }}
											onClick={props.close}>
											<Typography variant={'h5'}>{t('generic.no')}</Typography>
										</Button>
										<Button
											variant={'contained'}
											sx={{ fontWeight: 'bold', alignSelf: 'center' }}
											onClick={() => handleClick(emissionConsts.closure.type.definite)}>
											<Typography variant={'h5'}>{t('Yes')}</Typography>
										</Button>
									</Box>
								</Box>
							)}
							{props.partialCloseDesc && (
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									<Typography sx={{ textAlign: 'center', pb: 3, mt: '16px' }} variant='h4'>
										{t('emissions.allocation.partial-closing')}
									</Typography>
									<Box sx={{ textAlign: 'flex-start', pb: 3 }}>
										{t('emissions.allocation.partial-close-desc')}
									</Box>
									<Box sx={{ textAlign: 'flex-start', pb: 2 }}>
										{t('emissions.allocation.partial-close-desc2')}
									</Box>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											width: '100%',
											mt: 4,
											mb: '16px',
											columnGap: 2
										}}>
										<Button
											variant={'text'}
											sx={{ fontWeight: 'bold', alignSelf: 'center' }}
											onClick={props.close}>
											<Typography variant={'h5'}>{t('generic.no')}</Typography>
										</Button>
										<Button
											variant={'contained'}
											sx={{ fontWeight: 'bold', alignSelf: 'center' }}
											onClick={() => handleClick(emissionConsts.closure.type.partial)}>
											<Typography variant={'h5'}>{t('Yes')}</Typography>
										</Button>
									</Box>
								</Box>
							)}
						</Box>
					</>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default PartialClosureModal
