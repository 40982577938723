import React, { PureComponent } from 'react'
import { string, func } from 'prop-types'
import { list } from 'react-immutable-proptypes'
import styled from 'styled-components'
import { List } from 'immutable'
import ProtocolTitle from '../protocol-title/protocol-title'

const StyledNotesWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.notesYellow};
	padding-top: ${(props) => props.theme.spacing[3]};
	padding-bottom: ${(props) => props.theme.spacing[3]};
`

const StyledSection = styled.div`
	padding-bottom: ${(props) => props.theme.spacing[4]};
`

const StyledEditorWrapper = styled.div`
	padding: 0 ${(props) => props.theme.spacing[4]};
`

export default class ProtocolTasksList extends PureComponent {
	static propTypes = {
		meetingId: string,
		flatAgendaItems: list,
		renderNotesContainer: func
	}

	static defaultProps = {
		flatAgendaItems: List()
	}

	renderSection = (agendaItem, index) => {
		const { meetingId, renderNotesContainer } = this.props

		const agendaItemId = agendaItem.get('id')
		const proposal = agendaItem.get('proposal')
		const num = agendaItem.get('num')
		const notesObjId = `${meetingId}$${agendaItemId}`

		return (
			<StyledSection key={index}>
				<ProtocolTitle noBorderBottom textLeft={`${num}. ${proposal}`} />

				<StyledEditorWrapper>{renderNotesContainer && renderNotesContainer(notesObjId)}</StyledEditorWrapper>
			</StyledSection>
		)
	}

	render = () => {
		const { flatAgendaItems } = this.props

		return (
			<StyledNotesWrapper>{flatAgendaItems.size > 0 && flatAgendaItems.map(this.renderSection)}</StyledNotesWrapper>
		)
	}
}
