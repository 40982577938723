import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool, func } from 'prop-types'
import { closeModal } from '../../actions/modals.actions'
import { INSUFFICIENT_FUNDS_DOCUMENT_ESIGN_MODAL } from '../../constants/modals'

import history from '../../interfaces/history'
import NoFundsEsignModal from '../../dumb-components/documents/no-funds-esign-modal/no-funds-esign-modal'

class NoFundsEsignModalContainer extends Component {
	static propTypes = {
		onSignWithEmail: func
	}

	onGoToSubscription = () => {
		const { closeModal, companyUrlAlias, history } = this.props

		closeModal()

		history.push(`/${companyUrlAlias}/subscriptions/`)
	}

	onSignWithEmail = () => {
		const { onSignWithEmail, closeModal } = this.props

		closeModal()

		onSignWithEmail && onSignWithEmail()
	}

	onCancel = () => {
		this.props.closeModal()
	}

	render = () => {
		const { isOpen, onSignWithEmail, isSubscriptionAdmin } = this.props

		// Don't render if not needed
		if (!isOpen) {
			return null
		}

		return (
			<NoFundsEsignModal
				isOpen={true}
				onSignWithEmail={this.onSignWithEmail}
				onGoToSubscription={this.onGoToSubscription}
				onCancel={this.onCancel}
				isSubscriptionAdmin={isSubscriptionAdmin}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		isOpen: store.modals.getIn(['activeModal', 'name']) === INSUFFICIENT_FUNDS_DOCUMENT_ESIGN_MODAL,
		isSubscriptionAdmin: store.subscriptions.getIn(['helper', 'isSubscriptionAdmin'], false),
		companyUrlAlias: store.company.company && store.company.company.urlAlias
	}
}

const mapActionsToProps = {
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(NoFundsEsignModalContainer)
