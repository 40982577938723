import React, { Component } from 'react'
import { connect } from 'react-redux'
import InvestorsList from './investors/investors-list'

import { Margin } from 'styled-components-spacing'
import ScrollView from '../../../dumb-components/shared/layout/scroll-view/scroll-view'

import Text from '../../../dumb-components/shared/text/text'
import Breadcrumbs from '../../../dumb-components/shared/breadcrumbs/breadcrumbs'
import BreadcrumbsItem from '../../../dumb-components/shared/breadcrumbs/breadcrumbs-item'

import ShareholdersToolbarControlsContainer from '../../../containers/shares/shareholders-toolbar-controls.container'
import ShareholdersAdvancedFilterContainer from '../../../containers/shares/shareholders-advanced-filter.container'
import ShareholdersAdvancedFilterBtnContainer from '../../../containers/shares/shareholders-advanced-filter-btn.container'
import ShareholdersFilterAndSortContainer from '../../../containers/shares/shareholders-filter-and-sort.container'
import ShareholderAddShareholderBtnContainer from '../../../containers/shares/shareholders-add-shareholder-btn.container'
import InvestorsDetails from './investors/investors-details'
import Wiki from '../../wiki/wiki'
import InformationBlock from '../../framework/information-block'

/** Class representing the Shares component */
class Investors extends Component {
	state = {
		filterIsOpen: false,
		prevInvestor: null
	}

	getComponentBasePath() {
		const pathNameArray = this.props.location.pathname.split('/')

		if (pathNameArray[pathNameArray.length - 1] !== 'register-of-shareholders') {
			pathNameArray.pop()
		}

		return pathNameArray.join('/')
	}

	toggleAdvancedFilter = () => {
		const { selectedInvestor, visibleInvestors, history } = this.props
		const { filterIsOpen, prevInvestor } = this.state
		const basePath = this.getComponentBasePath()

		// if there are no selected investor in the list and there was before filter was open,
		// we select that investor again
		if (prevInvestor) {
			const id = prevInvestor.get('id')

			const prevVisible =
				visibleInvestors &&
				visibleInvestors.find((investor) => {
					return investor.get('id') === id
				})

			if (prevVisible) {
				history.push(`${basePath}/${prevInvestor.get('id')}`)
			} else {
				history.push(basePath)
			}

			this.setState({ prevInvestor: null })
		} else {
			history.push(basePath)
			this.setState({ prevInvestor: selectedInvestor })
		}

		this.setState({ filterIsOpen: !filterIsOpen })
	}

	closeAdvancedFilter = () => {
		this.setState({ filterIsOpen: false, prevInvestor: null })
	}

	renderButtons = () => {
		return (
			<div className='i-content__tabs i-content__tabs--fix-padding'>
				<ShareholdersToolbarControlsContainer />
			</div>
		)
	}

	renderLeftButtons = () => {
		const { location } = this.props
		const basePath = this.getComponentBasePath()

		return (
			<div className='i-content__tabs i-content__tabs--fix-padding'>
				<div className='h-box'>
					<ShareholderAddShareholderBtnContainer
						basePath={basePath}
						location={location}
						onClick={this.closeAdvancedFilter}
					/>
					<ShareholdersAdvancedFilterBtnContainer onClick={this.toggleAdvancedFilter} />
				</div>
			</div>
		)
	}

	renderWiki() {
		return (
			<div className='i-content__container' id='shareholder-register-edit-form'>
				{this.renderButtons()}
				<ScrollView autoHide={true} showOnHover={true}>
					<InformationBlock>
						<Wiki id='3e7c6e11-2e0b-4bcf-93af-c14647ace204' />
					</InformationBlock>
				</ScrollView>
			</div>
		)
	}

	render() {
		const { match } = this.props
		const { filterIsOpen } = this.state
		const basePath = this.getComponentBasePath()

		return (
			<div className='i-content i-content--pad-right-half'>
				<Margin bottom={4}>
					<Breadcrumbs>
						<BreadcrumbsItem itemName={<Text tid='shares.shareholders.breadcrumbs.title' />} isStatic={true} />
					</Breadcrumbs>
				</Margin>

				<div className='i-content__body'>
					<div className='i-content__container'>
						{this.renderLeftButtons()}
						<ScrollView noLeftMargin={true} showOnHover={true} autoHide={true}>
							<ShareholdersFilterAndSortContainer>
								<InvestorsList params={match.params} basePath={basePath} onInvestorClick={this.closeAdvancedFilter} />
							</ShareholdersFilterAndSortContainer>
						</ScrollView>
					</div>
					{!filterIsOpen && (match.params.id ? <InvestorsDetails /> : this.renderWiki())}
					{filterIsOpen && (
						<ScrollView showOnHover={true} autoHide={true}>
							{this.renderButtons()}
							<ShareholdersAdvancedFilterContainer isOpen={filterIsOpen} onClose={this.toggleAdvancedFilter} />
						</ScrollView>
					)}
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		user: state.user.get('userObj'),
		company: state.company.company,
		visibleInvestors: state.investors.get('visibleInvestors'),
		selectedInvestor: state.investors.get('selectedInvestor'),
		hasAppliedFilters: state.investors.get('hasAppliedFilters')
	}
}

const mapActionsToProps = {}

/** Export */
export default connect(mapStateToProps, mapActionsToProps)(Investors)
