import React, { createContext, useContext } from 'react'

/*
Context definition:

value = {
	feature: object,
	startEditingFeature: Function,
	stopEditingFeature: Function,
	modalOpen: boolean
}
*/

export const FeatureContext = createContext({})

export function useFeatureContext() {
	return useContext(FeatureContext)
}
