import React, { useContext, useEffect, useRef, useState } from 'react';
import localeFormatNumber from '/shared/helpers/number-formatter.helper';
import {
	NUMBER_FORMAT_PERCENTAGE,
	NUMBER_FORMAT_PIECE,
	INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE,
	INVESTOR_TYPE_OF_OWNER_PRIVATE,
	INVESTOR_TYPE_OF_OWNER_COMPANY
} from '/shared/constants';
import { closeModal, openModal } from '../../../actions/modals.actions';
import { useDispatch, useSelector } from 'react-redux';
import AgGridChart from '../../../dumb-components/shared/ag-grid/chart/ag-grid-chart';
import {
	byWhatNumberShouldNavigatorShow,
	exportAsPNG,
	showHowManyHundreds,
	limitNameLength
} from '../../../components/helpers/ag-chart.helper';
import { CHART_MODAL } from '../../../constants/modals';
import { MUItheme } from '../../../../MUItheme';
import { InvestmentsContext } from '../investment.container';
import { useTranslation } from 'react-i18next';
import ChartModal from '../shared/chartmodal/chartmodal';
import GridPanelRightButtons from '../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons';
import Text from '../../../dumb-components/shared/text/text';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';

export default function ShareholderDistributionByOwnerChart(props) {
	const chartRefPanel = useRef(null);
	const chartRefModal = useRef(null);
	const { selectedInvestment } = useContext(InvestmentsContext);
	const [chartData, setChartData] = useState();

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const investments = selectedInvestment?.get('investments');
	const chartOption = 'shareholderDistributionByOwnerChart';

	const isChartModalOpen = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'options']) === chartOption;
	});

	useEffect(() => {
		if (investments) {
			setChartData(generateDataForOwnershipDistributionChart());
		}
	}, [investments]);

	const series = [
		{
			type: 'column',
			xKey: 'index',
			yKey: 'numOfShares',
			yName: undefined,
			strokeWidth: 0,
			tooltip: { renderer: formatValue },
			shadow: {
				xOffset: 3
			},
			highlightStyle: {
				item: {
					fill: MUItheme.palette.chart.complementary,
					strokeWidth: 0
				},
				series: {
					...(isChartModalOpen ? { dimOpacity: 1 } : { dimOpacity: 0.2 })
				}
			}
		},
		{
			type: 'line',
			xKey: 'index',
			yKey: 'sharesOfTotal',
			yName: undefined,
			tooltip: { renderer: formatValue },
			marker: {
				size: 0
			},
			highlightStyle: {
				item: {
					fill: MUItheme.palette.chart.complementary,
					strokeWidth: 0
				},
				series: {
					...(isChartModalOpen ? { dimOpacity: 1 } : { dimOpacity: 0.2 })
				}
			}
		}
	];

	const axes = [
		{
			type: 'category',
			position: 'bottom',
			label: {
				formatter: (params) => {
					// console.log('params', params)
					const name = params.value.split('$')[1];
					if (!isChartModalOpen) {
						return limitNameLength(name, 11);
					} else {
						return name;
					}
				}
			}
		},
		{
			min: 0,
			max: 100,
			type: 'number',
			position: 'right',
			keys: ['sharesOfTotal']
		},
		{
			min: 0,
			nice: true,
			tick: {
				count: 4
			},
			type: 'number',
			position: 'left',
			keys: ['numOfShares']
		}
	];

	const generateDataForOwnershipDistributionChart = () => {
		if (!investments) {
			return [];
		}
		let sharesOfTotal = 0,
			chartData = [];
		investments.forEach((investment, i) => {
			const investorTypeOfOwner = investment.get('investorTypeOfOwner');
			let idType;
			switch (investorTypeOfOwner) {
				case INVESTOR_TYPE_OF_OWNER_PRIVATE:
					idType = t('cap_table.ownershi_distrubution_chart.id.private');
					break;
				case INVESTOR_TYPE_OF_OWNER_COMPANY:
					idType = t('cap_table.ownershi_distrubution_chart.id.company');
					break;
				case INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE:
					{
						idType = t('cap_table.ownershi_distrubution_chart.id.capital_insurance');
					}
					break;
				default:
			}
			chartData.push({
				index: `${i}$${investment.get('name')}`,
				id: investment.get('id'),
				idType,
				investorTypeOfOwner: investment.get('investorTypeOfOwner'),
				name: investment.get('name'),
				numOfShares: investment.getIn(['balance', 'numOfTotalShares']),
				ownershipPercentage: investment.getIn(['balance', 'ownershipPercentage'])
			});
		});
		const sortedChartData = chartData.sort((a, b) => b.numOfShares - a.numOfShares);

		//Group capital insurances by name and add #1 #2 etc infront if there's duplicates
		const insurances = sortedChartData.filter((investment) => {
			return investment.investorTypeOfOwner === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE;
		});
		const grouping = insurances.reduce((accumulator, investment) => {
			const { name } = investment;
			if (!accumulator[name]) {
				accumulator[name] = [];
			}
			accumulator[name].push(investment);
			return accumulator;
		}, {});

		for (const group of Object.values(grouping)) {
			if (group.length > 1) {
				group.forEach((investment, i) => {
					const { index } = investment;
					const parts = index.split('$');
					parts[1] = `#${i + 1} ${parts[1]}`;
					investment.index = parts.join('$');
				});
			}
		}

		chartData = sortedChartData.map((chartItem) => {
			sharesOfTotal += chartItem.ownershipPercentage;
			chartItem.sharesOfTotal = sharesOfTotal;
			if (chartItem.sharesOfTotal > 100) {
				chartItem.sharesOfTotal = 100;
			}
			delete chartItem.ownershipPercentage;
			return chartItem;
		});

		return chartData;
	};

	function formatValue(params) {
		//	tooltip
		if (params.xValue && params.yValue) {
			if (params.color === props.colorTwo) {
				const dataObject = chartData.find((dataObject) => {
					return dataObject.index === params.xValue;
				});
				const identificationString = dataObject.id ? `${dataObject.idType}: ${dataObject.id}` : '';

				return {
					title: `${dataObject.name} ${identificationString}`,
					content: localeFormatNumber(params.yValue, NUMBER_FORMAT_PERCENTAGE)
				};
			} else {
				const dataObject = chartData.find((dataObject) => {
					return dataObject.index === params.xValue;
				});
				const identificationString = dataObject.id ? `${dataObject.idType}: ${dataObject.id}` : '';

				return {
					title: `${dataObject.name} ${identificationString}`,
					content: localeFormatNumber(params.yValue, NUMBER_FORMAT_PIECE)
				};
			}
		}
		//	legend
		if (params.axis.boundSeries[0]._yKeys) {
			// numOfShares
			return params.value.toLocaleString().replace(/,(?=[^\s])/g, ' ');
		}
		if (params.axis.boundSeries[0]._yKey) {
			// sharesOfTotal
			return `${params.value} %`;
		}
	}

	const openChartModal = () => {
		dispatch(openModal(CHART_MODAL, chartOption));
	};

	const onCloseChartModal = () => {
		dispatch(closeModal());
	};

	const renderAgChart = () => {
		return (
			<AgGridChart
				chartRef={isChartModalOpen ? chartRefModal : chartRefPanel}
				series={series}
				axes={axes}
				data={chartData}
				showNavigator={byWhatNumberShouldNavigatorShow(chartData, 100)}
				howManyToShow={showHowManyHundreds(chartData, 1)}
				labelFormatter={formatValue}
			/>
		);
	};

	if (!chartData) {
		return null;
	}

	return (
		<>
			{isChartModalOpen && (
				<ChartModal
					leftHeader={'investments.sharebook.shareholder.distribution_by_owner_chart.title'}
					onCancel={onCloseChartModal}
					onExport={() => {
						exportAsPNG(
							chartRefModal,
							t('investments.sharebook.shareholder.distribution_by_owner_chart.title')
						);
					}}
					isOpen={isChartModalOpen}>
					{renderAgChart()}
				</ChartModal>
			)}
			<Card>
				<CardHeader
					title={<Text tid={'investments.sharebook.shareholder.distribution_by_owner_chart.title'} />}
					action={
						<GridPanelRightButtons
							chart
							noFilter={true}
							exportAsExcel={() => {
								exportAsPNG(
									chartRefPanel,
									t('investments.sharebook.shareholder.distribution_by_owner_chart.title')
								);
							}}
							downloadExcelTid={
								'investments.shareholders.shareholder_distribution_by_owner_chart.dropdown_item.download_chart'
							}
							openGridModal={openChartModal}
						/>
					}
				/>
				<CardContent variant='panel'>{renderAgChart()}</CardContent>
			</Card>
		</>
	);
}
