import React, { PureComponent } from 'react'
import { string, array, func, bool, object } from 'prop-types'
import styled from 'styled-components'

import Text from '../text/text'
import ModalTab from './modal-tab'
import { HeaderMenu } from '../../../containers/shared/people-modal/header-menu/HeaderMenu'
import NotificationBadge from '../notification-badge/notification-badge'
import { usePeopleModalContext } from '../../../containers/shared/people-modal/people-modal.context'
import { TAB_TEAMS_AND_SHAREHOLDES } from '../../../containers/shared/select-user-modal/select-user-modal.constants'

const StyledWrapper = styled.div`
	display: flex;
	flex: 1;
	min-height: 69px;
	border-bottom: 1px solid ${(props) => props.theme.colors.border};
`

const StyledTabsWrapper = styled.div`
	align-items: flex-end;
	margin-left: auto;
	flex-direction: row;
	display: flex;
	position: relative;
	bottom: -1px;
	padding-right: ${(props) => (props.showFilterButton ? props.theme.spacing[4] : props.theme.spacing[6])};
`

const StyledTitle = styled.div`
	padding: 14px ${(props) => props.theme.spacing[6]};
`

const StyledDropDownWrapper = styled.div`
	display: flex;
	align-items: center;
`

export default class extends PureComponent {
	static propTypes = {
		tabs: array,
		activeTab: string,
		onChange: func,
		title: string,
		showFilterButton: bool,
		filters: object,
		isFloatingFilterActive: bool
	}

	static defaultProps = {
		showFilterButton: true
	}

	renderTab = (tab, index) => {
		const { onChange, activeTab } = this.props

		return (
			<ModalTab
				onChange={onChange}
				tabName={tab.tabName}
				isActive={activeTab === tab.tabName}
				key={index}
				textTid={tab.title}
				turnedOff={tab.disabled}
				tooltipTid={tab.tooltip}
			/>
		)
	}

	getNotificationBadgeProps = () => {
		const { activeTab, filters, isFloatingFilterActive } = this.props

		const filterActive = typeof filters !== 'undefined' && Object.keys(filters).length > 0

		if (activeTab === TAB_TEAMS_AND_SHAREHOLDES && filterActive) {
			return {
				active: true,
				animated: false,
				color: 'green'
			}
		} else if (isFloatingFilterActive) {
			return {
				active: true,
				animated: false,
				color: 'green'
			}
		} else {
			return {
				active: false
			}
		}
	}

	render = () => {
		const { title, tabs, showFilterButton, activeTab } = this.props

		return (
			<StyledWrapper>
				<StyledTitle>
					<Text tid={title} tag='h4' color='catalinaBlue' />
				</StyledTitle>
				<StyledTabsWrapper showFilterButton={showFilterButton}>
					{tabs && tabs.length > 0 ? tabs.map(this.renderTab) : null}
				</StyledTabsWrapper>
				{showFilterButton && (
					<StyledDropDownWrapper>
						<NotificationBadge {...this.getNotificationBadgeProps()} x={18} y={6}>
							<HeaderMenu />
						</NotificationBadge>
					</StyledDropDownWrapper>
				)}
			</StyledWrapper>
		)
	}
}
