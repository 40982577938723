/**
 * See React DnD documentation for useDrag: https://react-dnd.github.io/react-dnd/docs/api/use-drag
 */
import React from 'react'
import { useDrag } from 'react-dnd'
import { string, symbol, func, object, oneOfType } from 'prop-types'

const Draggable = ({ type, item, previewOptions, options, onEnd, canDrag, isDragging, children }) => {
	const [collected, drag] = useDrag(() => ({
		type,
		item,
		previewOptions,
		options,
		end: onEnd,
		canDrag,
		isDragging,
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	}))

	return children(collected, drag)
}

Draggable.propTypes = {
	type: oneOfType([string, symbol]).isRequired,
	item: oneOfType([object, func]),
	previewOptions: object,
	options: object,
	onEnd: func,
	canDrag: func,
	isDragging: func,
	children: func.isRequired
}

export default Draggable
