import React, { PureComponent } from 'react';
import { bool, func, node, object, number } from 'prop-types';
import styled, { css } from 'styled-components';
import { list } from 'react-immutable-proptypes';
import { List } from 'immutable';

import { getUserName } from '../../../components/helpers/users';

import Panel from '../../shared/panel/panel';
import Text from '../../shared/text/text';
import Tooltip from '../../shared/tooltip/tooltip';
import IotaIcon from '../../shared/iota-icon/iota-icon';
import DarkInfoPanel from '../../shared/dark-info-panel/dark-info-panel';

import { ListPanelHeader } from '../../shared/list-panel/list-panel';
import ListPanelItem from '../../shared/list-panel/list-panel-item';
import SigneeListItem from '../signee-list-item/signee-list-item';

const Wrapper = styled.div`
	margin-left: ${(props) => props.theme.spacing[props.marginLeft]};
	margin-top: ${(props) => props.theme.spacing[props.marginTop]};
	margin-right: ${(props) => props.theme.spacing[props.marginRight]};
	margin-bottom: ${(props) => props.theme.spacing[props.marginBottom]};
`;

const StyledListWrapper = styled.div`
	display: flex;
	flex-direction: column;

	${(props) =>
		!props.noTopMargin &&
		css`
			margin-top: ${(props) => props.theme.spacing[4]};
		`}
`;

const StyledTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const WhiteText = (props) => <Text color='white' {...props} />;

export default class DocumentEsignPanel extends PureComponent {
	static propTypes = {
		eSignees: list,
		investors: list,
		renderDropdown: func,
		bottomComponent: object,
		readOnly: bool,
		userIsSignee: bool,
		eSigning: bool,
		finialisedAt: object,
		mainControlsComponent: object,
		userHasSigned: bool,
		documentAllHaveSigned: bool,
		documentIsSigned: bool,
		finalizationFalied: bool,
		documentSealingFailed: bool,
		recreateDocumentComponent: object,
		signedFilePreviewComponent: object,
		marginLeft: number,
		marginTop: number,
		marginRight: number,
		marginBottom: number
	};

	static defaultProps = {
		eSignees: List(),
		marginLeft: 4,
		marginTop: 4,
		marginRight: 4,
		marginBottom: 4
	};

	getUserName = (signee) => {
		const { investors, usersCache } = this.props;
		return getUserName(signee, investors, usersCache) || '';
	};

	getDarkInfoPanelProps = () => {
		const { mainControlsComponent, bottomComponent } = this.props;

		return {
			headerRightComponent: mainControlsComponent,
			footerComponent: bottomComponent,
			headerLeftComponent: this.renderDarkInfoPanelTextComponent()
		};
	};

	renderRightComponent = () => {
		const { documentAllHaveSigned, documentIsSigned, onExploreOnTangle, documentSealingFailed } = this.props;

		if (!documentAllHaveSigned || !documentIsSigned || documentSealingFailed) {
			return null;
		}

		return (
			<Tooltip tid='documents.esign.signee_panel.iota.show_transaction'>
				<IotaIcon marginLeft={3} onClick={onExploreOnTangle} />
			</Tooltip>
		);
	};

	renderSigneeItem = (signee, index) => {
		const { usersCache, investors, renderDropdown } = this.props;

		return (
			<SigneeListItem
				usersCache={usersCache}
				investors={investors}
				renderDropdown={renderDropdown}
				item={signee}
				key={index}
			/>
		);
	};

	renderSigneeSignedDocument = (signee, index) => {
		return (
			<ListPanelItem key={index} name={this.getUserName(signee)} date={signee.get('signedAt')} status={true} />
		);
	};

	renderDarkInfoPanelTextComponent = () => {
		const {
			readOnly,
			eSigning,
			eSignees,
			finialisedAt,
			userIsSignee,
			userHasSigned,
			documentAllHaveSigned,
			documentIsSigned,
			finalizationFalied,
			documentSealingFailed,
			recreateDocumentComponent
		} = this.props;
		const noSignees = !eSignees.size;

		if (noSignees) {
			return <WhiteText tid='documents.document.esign.panel.information_before' />;
		}

		if (documentAllHaveSigned && !documentIsSigned && !finalizationFalied) {
			return <WhiteText tid='documents.signatories.panel.waiting' />;
		}

		if (documentSealingFailed) {
			return recreateDocumentComponent;
		}

		if (!readOnly && !eSigning) {
			return <WhiteText tid='documents.document.esign.panel.information_after_add_signees' />;
		}

		if (!readOnly && eSigning && !finialisedAt) {
			const showPressPenToSignText = userIsSignee && !userHasSigned;
			const marginBottom = showPressPenToSignText ? 4 : 0;

			return (
				<StyledTextWrapper>
					<WhiteText tid='documents.document.esign.panel.information_during' marginBottom={marginBottom} />

					{showPressPenToSignText && <WhiteText tid='documents.esign.panel.text.during.press_pen_to_sign' />}
				</StyledTextWrapper>
			);
		}

		if (!finialisedAt && userIsSignee && eSigning) {
			return <WhiteText tid='documents.document.esign.panel.information_signee' />;
		}

		return null;
	};

	renderSignedDocumentESignees = () => {
		const { eSignees } = this.props;

		return (
			<>
				<ListPanelHeader columnTid='documents.esign.signatories.signed.list.header.date' />
				{eSignees.map(this.renderSigneeSignedDocument)}
			</>
		);
	};

	renderDocumentESignees = () => {
		const { eSignees } = this.props;
		return eSignees.map(this.renderSigneeItem);
	};

	renderSignedFilePreview = () => {
		const { documentIsSigned, signedFilePreviewComponent } = this.props;

		if (!documentIsSigned) {
			return null;
		}

		return signedFilePreviewComponent;
	};

	renderContent = () => {
		const { eSignees, readOnly, documentIsSigned, marginTop, marginRight, marginBottom, marginLeft } = this.props;
		const listToRender = documentIsSigned ? this.renderSignedDocumentESignees() : this.renderDocumentESignees();
		const darkInfopanelProps = this.getDarkInfoPanelProps();
		const wrapperProps = { marginTop, marginRight, marginBottom, marginLeft };

		return (
			<Wrapper {...wrapperProps}>
				<DarkInfoPanel {...darkInfopanelProps}>{this.renderSignedFilePreview()}</DarkInfoPanel>

				<StyledListWrapper noTopMargin={eSignees.size === 0}>{listToRender}</StyledListWrapper>
			</Wrapper>
		);
	};

	render = () => {
		const { noPanel, documentIsSigned } = this.props;

		if (noPanel) {
			return this.renderContent();
		}

		// Remove comments from the following code when IOTA is solved
		// return (
		// 	<Panel title='document.esign.signatories.panel.title' rightComponent={this.renderRightComponent} marginBottom>
		// 		{this.renderContent()}
		// 	</Panel>
		// )

		// Remove this when IOTA is solved
		return (
			<Panel title='document.esign.signatories.panel.title' marginBottom>
				{this.renderContent()}
			</Panel>
		);
	};
}
