import styled, { css } from 'styled-components'
import { StyledInputTemplate } from '../../input/input.template'

/**
 * ----- TUTORIAL 101: REMOVING LEGACY V1 LOOK ----
 *
 * - In this file:
 *    1. Remove declaration '.react-select__indicators > div.react-select__indicator.react-select__dropdown-indicator'
 *    2. Remove declaration '.react-select__indicators > div.react-select__indicator.react-select__clear-indicator'
 * - In select.helpers
 *    1. Remove 'componentsObject.ClearIndicator = ClearIndicatorComponentLegacy'
 *    2. Remove 'componentsObject.DropdownIndicator = DropdownIndicatorLegacy';
 *    3. Remove declaration of function 'ClearIndicatorComponentLegacy'
 *    4. Remove declaration of function 'DropdownIndicator'
 */

/**
 * DOM Structure of the React Select component for reference
 *

	<div class="react-select-container">
		<div class="react-select__control">
			<div class="react-select__value-container">...</div>
			<div class="react-select__indicators">
				<div class="react-select__clear-indicator"></div>
				<div class="react-select__dropdown-indicator"></div>
			</div>
		</div>
		<div class="react-select__menu">
			<div class="react-select__menu-list">
			<div class="react-select__option">...</div>
			</div>
		</div>
	</div>

*/

// -------------------------------------------------------------------------------------
// ----------------------------- Custom Components Styling -----------------------------
// -------------------------------------------------------------------------------------
export const StyledBackgroundReplacer = styled.div`
	${(props) =>
		props.color &&
		css`
			display: flex;
			align-items: center;
			background: ${props.theme.colors[props.color]};
		`};
`

export const StyledMultiValueDummyLabelContainerWrapper = styled.div`
	> div:first-child {
		background-color: white;
		border: none;
	}
`

// -------------------------------------------------------------------------------------
// -------------------------------- Custom CSS Styling ---------------------------------
// -------------------------------------------------------------------------------------

const StyledSelectTemplate = css`
	color: ${(props) => props.theme.colors.midGrey};
	width: 100%;

	.react-select__control {
		${StyledInputTemplate};
		border-radius: 0px;
		height: ${(props) => !props.isMulti && '36px'};
		min-height: 36px;
		padding: 0;
	}

	.react-select__control--is-disabled {
		background-color: ${(props) => (props.legacyDisabledColor ? '#eee' : props.theme.colors.disabled)};
	}

	/* Normally this is inherited from StyledInputTemplate but it doens't in this case as this is not a normal input */
	.react-select__placeholder {
		color: ${(props) => props.theme.colors.placeholder};
	}

	.react-select__control .react-select__indicator {
		padding: 7px !important;
	}

	.react-select__value-container {
		padding: 2px 10px;
	}

	/*
	 * Fix for extra row in rare occasions in the select field
	 * due to the invisible input taking up space
	 */
	.react-select__value-container--is-multi {
		min-height: 34px; /* Prevents placeholder from dissapearing */

		> div:last-child {
			position: absolute;
		}
	}

	.react-select__control--is-focused {
		> div:first-child > div:last-child {
			position: relative;
		}
	}
	/*****************************************************/

	/* More CSS in select.scss */

	/* As we are using 16px margin bottom around every site, when the select field
	 * is Open, the site is not scrolled far enough and a few pixels of the menu were hidden.
	 * If menuPlacement is top, we need to remove all bottom margin or else the menu will be "floating"
	 */
	.react-select__menu {
		${(props) =>
			props.menuPlacement === 'top'
				? css`
						margin-bottom: 0px !important;
				  `
				: css`
						margin-bottom: 16px !important;
				  `}
	}

	.react-select__multi-value {
		flex-direction: row-reverse;
	}

	.react-select__multi-value__label {
		display: flex;
		align-items: center;
		font-size: 100%;
		padding-left: 2px;
		padding-right: 4px;
		color: ${(props) => props.theme.colors.midGrey};
	}

	.react-select__multi-value__remove {
		border-radius: 0px;
		height: 24px;
		padding-right: 2px;
		padding-left: 2px;
		color: ${(props) => props.theme.colors.midGrey};

		:hover,
		:active,
		:focus {
			background-color: inherit;
			color: #0077b3;
		}

		> svg {
			fill: ${(props) => props.theme.colors.midGrey};
		}
	}

	.react-select__menu-notice,
	.react-select__option {
		font-size: ${(props) => props.theme.fontSizes.default};
		line-height: ${(props) => props.theme.lineHeights.default};
	}

	.react-select__multi-value {
		background-color: ${(props) => props.theme.colors.solitudeMid};
		border: 1px solid #c9e6f2;
		border-radius: 2px;
	}

	/* ||| THIS CAN BE REMOVED AFTER REPLACING ALL SELECT FIELDS WITH NEW ONE. LEGACY V1 LOOK ||| */
	.react-select__indicators > div.react-select__indicator.react-select__dropdown-indicator {
		height: 100%;
		padding: 0 !important;
		display: flex;
		align-items: center;
		width: 28px;
		justify-content: center;

		> span[class*='fa-'] {
			color: rgb(154, 154, 154);
		}

		:hover {
			> span[class*='fa-'] {
				color: rgb(120, 102, 120);
			}
		}
	}

	.react-select__indicators > div.react-select__indicator.react-select__clear-indicator {
		padding: 8px 6px !important;

		> span[class*='fa-'] {
			color: rgb(154, 154, 154);
		}

		:hover {
			> svg,
			span[class*='fa-'] {
				color: rgb(209, 0, 20);
			}
		}
	}
	/* ^^^ THIS CAN BE REMOVED AFTER REPLACING ALL SELECT FIELDS WITH NEW ONE. LEGACY V1 LOOK ^^ */
`

export default StyledSelectTemplate
