import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import StepsModal from '../../../dumb-components/shared/modal/steps-modal'
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import ToggleSwitch from '../../../dumb-components/shared/toggle-switch/toggle-switch'
import { TransparentButton } from '../../../dumb-components/shared/button-v2'
import SimpleDarkInfoPanel from '../../../dumb-components/shared/dark-info-panel/simple-dark-info-panel'
import Text from '../../../dumb-components/shared/text/text'
import FooterPrevious from '../../../dumb-components/shared/modal/previous-left-button'
import ModalInnerWrapper from '../../../dumb-components/shared/modal/modal-inner-wrapper'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'

import DocumentsDndSortContainer from '../documents-dnd-sort.container'
import ShareOverviewAndSaveViewContainer from './shrare-overview-and-save.container'

import { PeopleModalProvider } from '../../shared/people-modal/people-modal.provider'
import { PeopleModalHeader, getDefaultTabs } from '../../shared/people-modal/people-modal-header'
import { TeamsAndInvestorsListContainer } from '../../shared/people-modal/teams-and-investors-list/teams-and-investors-list.container'
import { CompanyContactsGrid } from '../../shared/people-modal/company-contacts/CompanyContactsGrid'
import { PersonalContactsGrid } from '../../shared/people-modal/personal-contacts/PersonalConatctsGrid'

import { closeModal, openModal } from '../../../actions/modals.actions'
import { selectCollection, shareCollection } from '../../../actions/collections.actions'

import shareWithHelper from '../../../components/helpers/share-with.helper'

import { TAB_TEAMS } from '../../shared/select-user-modal/select-user-modal.constants'

import { COLLECTIONS_MANAGE_MODAL } from '../../../constants/modals'

const HeaderComponent = () => (
	<SimpleDarkInfoPanel>
		<Text tid='collections.share_document.modal.share_with.info' color='white' />
	</SimpleDarkInfoPanel>
)

class ShareModalContainer extends Component {
	state = {
		step: 0,
		shareWithActiveTab: TAB_TEAMS,
		usersToShareWith: Map(),
		expiresAt: '',
		hasError: Map(),
		isLoadingSubmit: false
	}

	static propTypes = {
		collection: map.isRequired // Required on mount
	}

	handleGetControlsData = ({ document, documentId, toBeMerged, handleToggle }) => {
		return {
			component: <ToggleSwitch fieldName={documentId} onChange={handleToggle} checked={toBeMerged} />
		}
	}

	handleChangePeopleTab = (tab) => {
		this.setState({ shareWithActiveTab: tab })
	}

	handleChangeUsersToShareWith = (usersToShareWith) => {
		this.handleGenericChange('usersToShareWith', usersToShareWith)
	}

	handleGenericChange = (fieldName, value) => {
		let { hasError } = this.state
		hasError = hasError.remove(fieldName)
		this.setState({ [fieldName]: value, hasError })
	}

	submit = () => {
		const { shareCollection, collection, collectionDocuments } = this.props
		const { usersToShareWith, expiresAt } = this.state

		if (!this.validateSubmit()) {
			return
		}

		this.setState({ isLoadingSubmit: true })
		const includedDocuments = collectionDocuments
			.filter((d) => d.get('includedInAction') !== false)
			.map((d) => d.get('id'))

		shareCollection(
			collection.get('id'),
			{
				documentIds: includedDocuments,
				shareWith: usersToShareWith,
				expiresAt
			},
			(result, err) => {
				this.setState({ isLoadingSubmit: false })
				if (!err) {
					this.doCancel()
				}
			}
		)
	}

	validateSubmit = () => {
		const { usersToShareWith, expiresAt } = this.state
		let hasError = Map()

		// No users selected
		if (!usersToShareWith.size) {
			hasError = hasError.set(
				'usersToShareWith',
				List(['collections.share_documents.modal.overview.right.error.select_users'])
			)
		}

		if (!expiresAt) {
			hasError = hasError.set(
				'expiresAt',
				List(['collections.share_documents.modal.overview.right.error.no_date'])
			)
		}

		this.setState({ hasError })

		return hasError.size === 0
	}

	doCancel = () => {
		const { closeModal, selectCollection } = this.props
		selectCollection()
		closeModal()
	}

	doGoBackToManageCollection = () => {
		const { openModal } = this.props
		openModal(COLLECTIONS_MANAGE_MODAL)
	}

	goToDocumentsStep = () => {
		this.setState({ step: 0 })
	}

	goToShareOverviewStep = () => {
		this.setState({ step: 1 })
	}

	goToSelectPeopleStep = () => {
		this.setState({ step: 2, shareWithActiveTab: TAB_TEAMS })
	}

	getModalTitle = () => {
		const { step } = this.state

		switch (step) {
			case 0:
				return 'collections.share_documents.modal.documents.title'
			default:
				return 'collections.share_documents.modal.share_with.title'
		}
	}

	getSteps = () => {
		const { collection, collectionDocuments } = this.props
		const { step, shareWithActiveTab, usersToShareWith, hasError, expiresAt, isLoadingSubmit } = this.state
		const collectionId = collection.get('id')
		const filteredShareWith = shareWithHelper.removeEmptyExternalPerson(usersToShareWith)
		const numberOfPeopleSharedWith = filteredShareWith.size
		const hasIncludedDocuments = collectionDocuments.some((d) => d.get('includedInAction') !== false)

		return [
			/* Documents sort view */
			{
				body: (
					<DocumentsDndSortContainer
						collectionId={collectionId}
						onChangeIncludedDocuments={this.handleChangeIncludedDocuments}
						onGetControlsData={this.handleGetControlsData}
						headerComponent={<HeaderComponent />}
					/>
				),
				footer: {
					component: (
						<FooterRightControls>
							<Tooltip
								tid='collections.share_document.modal.documents_list.tooltip.needs_document'
								delayShow='instant'
								active={!hasIncludedDocuments}>
								<TransparentButton
									tid='next'
									onClick={this.goToShareOverviewStep}
									disabled={!hasIncludedDocuments}
								/>
							</Tooltip>
							<TransparentButton
								tid='generic.form.cancel'
								textColor='midGrey'
								onClick={this.doGoBackToManageCollection}
							/>
						</FooterRightControls>
					)
				}
			},
			/* Overview view */
			{
				body: (
					<ShareOverviewAndSaveViewContainer
						onGoToSelectPeople={this.goToSelectPeopleStep}
						numberOfPeopleSharedWith={numberOfPeopleSharedWith}
						hasError={hasError}
						expiresAt={expiresAt}
						onChange={this.handleGenericChange}
					/>
				),
				footer: {
					leftComponent: <FooterPrevious onClick={this.goToDocumentsStep} disabled={isLoadingSubmit} />,
					component: (
						<FooterRightControls>
							<TransparentButton
								tid='collections.share_documents.modal.share_with.footer.send'
								onClick={this.submit}
								isLoading={isLoadingSubmit}
							/>
							<TransparentButton
								tid='generic.form.cancel'
								textColor='midGrey'
								onClick={this.doCancel}
								disabled={isLoadingSubmit}
							/>
						</FooterRightControls>
					)
				}
			},
			/* Share with people modal view */
			{
				header: {
					component: <PeopleModalHeader title={this.getModalTitle()} tabs={getDefaultTabs()} />
				},
				body: (
					<ModalInnerWrapper noScrollView fullHeight>
						{step === 2 && (
							<>
								<TeamsAndInvestorsListContainer></TeamsAndInvestorsListContainer>
								<CompanyContactsGrid />
								<PersonalContactsGrid />
							</>
						)}
					</ModalInnerWrapper>
				),
				footer: {
					leftComponent: <FooterPrevious onClick={this.goToShareOverviewStep} />
				}
			}
		]
	}

	render = () => {
		const { step, usersToShareWith } = this.state
		const steps = this.getSteps()

		return (
			<PeopleModalProvider values={usersToShareWith} onChange={this.handleChangeUsersToShareWith} isOpen={true}>
				<StepsModal
					isOpen={true}
					steps={steps}
					step={step}
					transformless={step === 0}
					hSize='xl'
					title={this.getModalTitle()}
				/>
			</PeopleModalProvider>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		collection: store.modals.getIn(['activeModal', 'options', 'collection']),
		collectionDocuments: store.collections.get('selectedCollectionDocuments')
	}
}

const mapActionsToProps = {
	closeModal,
	selectCollection,
	shareCollection,
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(ShareModalContainer)
