import { fromJS, List, Map } from 'immutable'
import {
	SHARED_COLLECTIONS_LIST,
	SHARED_COLLECTION_SELECT,
	SHARED_COLLECTION_UPDATE_LOCAL,
	SHARED_COLLECTION_DELETE
} from '../actions/types'

const INITIAL_STATE = fromJS({
	sharedCollections: [],
	selectedCollection: {}
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case SHARED_COLLECTIONS_LIST: {
			return state.set('sharedCollections', payload)
		}

		case SHARED_COLLECTION_SELECT: {
			return state.set('selectedCollection', payload)
		}

		case SHARED_COLLECTION_UPDATE_LOCAL: {
			if (!payload) {
				return state.set('selectedCollection', Map())
			}

			const sharedCollectionId = payload.get('id')

			state = state.update('sharedCollections', (sharedCollections) => {
				return sharedCollections.map((sharedCollection) => {
					if (sharedCollection.get('id') === sharedCollectionId) {
						sharedCollection = payload
					}

					return sharedCollection
				})
			})

			const selectedCollection = state.get('selectedCollection')
			if (selectedCollection && selectedCollection.get('id') === sharedCollectionId) {
				state = state.set('selectedCollection', payload)
			}

			return state
		}

		case SHARED_COLLECTION_DELETE: {
			state = state.update('sharedCollections', (sharedCollections) => {
				return sharedCollections.filter((sharedCollection) => sharedCollection.get('id') !== payload)
			})

			const selectedCollection = state.get('selectedCollection')
			if (selectedCollection && selectedCollection.get('id') === payload) {
				state = state.set('selectedCollection', Map({}))
			}

			return state
		}

		default:
			return state
	}
}
