import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { string, object, bool, node } from 'prop-types'

export default class Panel extends Component {
	static propTypes = {
		children: node,
		tid: string,
		tidValues: object,
		padHor: bool,
		padVer: bool,
		bodyClassName: string,
		headerClassName: string,
		className: string
	}

	static defaultProps = {
		padHor: false,
		padVer: false,
		bodyClassName: '',
		headerClassName: '',
		tidValues: {}
	}

	render = () => {
		let {
			children,
			tid,
			padHor,
			padVer,
			bodyClassName,
			headerClassName,
			className,
			tidValues,
			renderHeader
		} = this.props

		if (padHor) {
			bodyClassName += ' i-panel__body--pad-hor'
		}
		if (padVer) {
			bodyClassName += ' i-panel__body--pad-ver'
		}

		return (
			<div className={`i-panel ${className}`}>
				{(tid || renderHeader) && (
					<div className={`i-panel__heading ${headerClassName}`}>
						<h2 className='i-panel__title'>
							{tid && <FormattedMessage id={tid} values={tidValues} />}
							{renderHeader && renderHeader()}
						</h2>
					</div>
				)}
				<div className={`i-panel__body ${bodyClassName}`}>{children}</div>
			</div>
		)
	}
}
