import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Button from '../../shared/button/button'
import { bool, func, string } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import Input from '../../shared/input/input'
import Label from '../../shared/label/label'
import Text from '../../shared/text/text'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import Tooltip from '../../shared/tooltip/tooltip'
import { Map } from 'immutable'
import ErrorMessage from '../error-message'
import Alert from '../../shared/alert/alert'
import { SSN_FIELD, REMEMBER_DETAILS_FIELD } from '../../../constants/credentials'

const StyledSignInBankId = styled.div`
	display: flex;
	flex-direction: column;
	> * {
		margin: ${(props) => props.theme.spacing[3]} 0;
	}
	width: 100%;
	height: 100%;
`

const StyledToggleButtonDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	> * {
		margin: 0 ${(props) => props.theme.spacing[2]};
	}
`

const StyledButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: flex-end;
`

export default class SignInEmail extends PureComponent {
	static propTypes = {
		onClickSignIn: func,
		onSetTooltipRefs: func,
		bankIdTransaltionId: string,
		onChange: func,
		ssn: string,
		onHideTooltip: func,
		rememberDetails: bool,
		actionBtnLoading: bool,
		hasError: map,
		translationId: string
	}

	static defaultProps = {
		rememberDetails: false,
		hasError: Map(),
		ssn: ''
	}

	renderSsnInput = () => {
		const { hasError, onSetTooltipRefs, onChange, ssn, onHideTooltip, onClickSignIn, actionBtnLoading } = this.props

		return (
			<Label tid='credentials_pnr' textColor='white'>
				<Tooltip
					activeState='formValidator'
					tid={hasError.getIn([SSN_FIELD, 0])}
					ref={onSetTooltipRefs && onSetTooltipRefs.bind(null, SSN_FIELD)}>
					<Input
						inputStyle='v2'
						fieldName={SSN_FIELD}
						onChange={onChange}
						value={ssn}
						onBlur={onHideTooltip}
						hasError={hasError.get(SSN_FIELD)}
						disabled={actionBtnLoading}
						onEnter={onClickSignIn}
					/>
				</Tooltip>
			</Label>
		)
	}

	render = () => {
		const { rememberDetails, onClickSignIn, actionBtnLoading, onChange, translationId } = this.props

		return (
			<StyledSignInBankId>
				{this.renderSsnInput()}
				<StyledToggleButtonDiv>
					<ToggleSwitch fieldName={REMEMBER_DETAILS_FIELD} onChange={onChange} checked={rememberDetails} />
					<Text tid='credentials_remember.credentials' color='muted' />
				</StyledToggleButtonDiv>
				{/*<Alert mode="error">JUST NU: Vi upplever problem med BankID integrationen och du måste därför logga in med användarnamn och lösenord. Tryck på fliken oven "Byt till Användarnamn". Har du inget lösenord tryck på "Glömt lösenord" för att byta eller skapa ett.</Alert>*/}
				<ErrorMessage tid={translationId} />
				<StyledButtonWrapper>
					<Button
						mode='rounded'
						isLoading={actionBtnLoading}
						tid='credentials_signin'
						minWidth='150px'
						maxWidth='150px'
						height='50px'
						onClick={onClickSignIn}
					/>
				</StyledButtonWrapper>
			</StyledSignInBankId>
		)
	}
}
