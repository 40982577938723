/**
 * Routes
 * @module routes
 */

import React from 'react'
import { Route, Redirect, Switch } from './interfaces/router'
import Signout from './containers/credentials/signout.container'
import Dashboard from './containers/dashboard/dashboard.container'
import PersonalDocuments from './containers/personal-documents/personal-documents.container'
import InvestmentPortfolio from './components/user/investment-portfolio'
// import Profile from './components/user/profile'
import Profile from './components/user/services/profile/profile'
import CredentialsContainer from './containers/credentials/credentials.container'

// Company
import CompanyRoutesContainer from './containers/company/company-routes.container'
import DealflowRoute from './containers/dealflow/DealflowRoute'

const Routes = () => {
	return (
		<Switch>
			<Route exact path='/signin' component={CredentialsContainer} />
			<Route exact path='/signup' component={CredentialsContainer} />
			<Route exact path='/forgot-password' component={CredentialsContainer} />
			<Route exact path='/signout' component={Signout} />

			<Redirect exact from='/' to='/dashboard' />
			<Route exact path='/dashboard' component={Dashboard} />
			<Route exact path='/personal-documents' component={PersonalDocuments} />

			<Route exact path='/profile' component={Profile} />
			<Route path='/portfolio' component={InvestmentPortfolio} />
			<Route path='/dealflow' component={DealflowRoute} />

			<Route path='/:company' component={CompanyRoutesContainer} />
		</Switch>
	)
}

export default Routes
