import Box from '@mui/material/Box'
import React, { useContext } from 'react'
import SubscriptionSlip from '../../../public/containers/emissions/subscription-slip.container'
import { InvestmentsContext } from '../investment.container'
import PublicSubscriptionSlipErrorBoundary from '../../../public/containers/emissions/subscription-slip.container.error-boundary'

const EmissionDetailsContainer = () => {
	const { investedInCompanyInformation, setEmissionTabIndex, currentEmissionIds } = useContext(InvestmentsContext)
	const { id: companyId } = investedInCompanyInformation.toJS()

	function displayEmissionList() {
		setEmissionTabIndex(0)
	}

	return (
		<Box
			sx={{
				width: '100%',
				'> .subscription-slip': {
					height: 'auto',
					padding: 5,
					pt: 3
				},
				'> div:last-child': {
					marginTop: 2
				},
				'> .subscription-slip-form': {
					marginTop: 0
				},
				'.final-submission-submit-button': {
					right: 0
				},
				'> div > form': {
					backgroundColor: 'white',
					padding: 2
				}
			}}>
			<PublicSubscriptionSlipErrorBoundary>
				<SubscriptionSlip
					companyId={companyId}
					displayEmissionList={displayEmissionList}
					isInvestorPage
					{...currentEmissionIds}
				/>
			</PublicSubscriptionSlipErrorBoundary>
		</Box>
	)
}

export default EmissionDetailsContainer
