import { func, bool, oneOf, object } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { getOnlyTabs, getDefaultTabs, PeopleModalHeader } from '../../../../shared/people-modal/people-modal-header'
import {
	TAB_TEAMS_AND_SHAREHOLDES,
	TAB_SHAREHOLDERS
} from '../../../../shared/select-user-modal/select-user-modal.constants'
import { ThemeProvider } from '@mui/material/styles'
import { MUItheme } from '../../../../../../MUItheme'
import { PeopleModalProvider } from '../../../../shared/people-modal/people-modal.provider'
import StepsModal from '../../../../../mui-components/steps-modal/steps-modal'
import { InvestorsGrid } from '../../../../shared/people-modal/investors/InvestorsGrid'
import { TeamsAndInvestorsListContainer } from '../../../../shared/people-modal/teams-and-investors-list/teams-and-investors-list.container'
import { CompanyContactsGrid } from '../../../../shared/people-modal/company-contacts/CompanyContactsGrid'
import { PeopleModalFooterButtons } from '../../../../shared/people-modal/people-modal-footer'
import { emissionConsts } from '../../../../../constants/emissions'
import { useTranslation } from 'react-i18next'
import ComposeInvitationModal from '../compose-invitation-modal'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../../../../mui-components/button/button'
import { sendDealflowInvite, sendInvestorInvite } from '../../../../../actions/emissions.actions'
import { usePrepaid } from '../../../../subscriptions/prepaid-modal/usePrepaid'
import Box from '@mui/material/Box'
import { PrepaidForm } from '../../../../subscriptions/prepaid-modal/PrepaidForm'
import { PrepaidInfo } from '../../../../subscriptions/prepaid-modal/PrepaidInfo'
import Tooltip from '../../../../../mui-components/tooltip/tooltip'
import { useConfirmContext } from '../../../../shared/confirm/confirm.context'
import { getNumberOfDealflowFollowers } from '../../../../../actions/dealflow.actions'
import { PersonalContactsGrid } from '../../../../shared/people-modal/personal-contacts/PersonalConatctsGrid'
import Typography from '@mui/material/Typography'
import Icon from '../../../../../dumb-components/shared/icon/icon'

const InvitationModal = ({ typeOfInvitees, onComplete, onCancel, isOpen = false }) => {
	const prepaidApi = usePrepaid()
	const [pickedUserIds, setPickedUserIds] = useState([])
	const [invite, setInvite] = useState({ template: '', files: [] })
	const [dealflowUsersAmount, setDealflowUsersAmount] = useState()
	const [isSendingInvites, setIsSendingInvites] = useState(false)
	const emission = useSelector((state) => state.emissions.current)
	const invitees = useSelector((state) => state.emissions.invitees)
	const prepaidAmount = useSelector((state) => state.subscriptions.getIn(['helper', 'prepaidAmount']))
	const isFreeOfCharge = useSelector((state) => state.subscriptions.getIn(['helper', 'isFreeOfCharge']))
	const orgNumber = useSelector((state) => state.company.company.orgNumber)

	const dispatch = useDispatch()
	const { t } = useTranslation()
	const confirm = useConfirmContext()

	const numOfPeopleToInvite = pickedUserIds.length || dealflowUsersAmount

	const sufficientAmount = isFreeOfCharge || numOfPeopleToInvite * 3 < prepaidAmount
	const amountToPay = numOfPeopleToInvite * 3 - prepaidAmount

	useEffect(() => {
		if (
			(typeOfInvitees === 'dealflow-via-direct-follow' || typeOfInvitees === 'dealflow-via-criteria') &&
			orgNumber &&
			emission.companyId
		) {
			dispatch(
				getNumberOfDealflowFollowers(orgNumber, typeOfInvitees, emission.companyId, (numDealflowFollowers) =>
					setDealflowUsersAmount(numDealflowFollowers)
				)
			)
		}
	}, [typeOfInvitees, orgNumber, emission.companyId])

	const {
		invitation: { inviteeStatus }
	} = emissionConsts

	const disableInvestors = (investor) => {
		if (!invitees) {
			return
		}

		const investorAlreadySelected = invitees.some((invitee) => invitee.investmentId === investor.get('id'))

		if (investorAlreadySelected) {
			return {
				icon: 'faExclamationSquare',
				isDisabled: true,
				tooltipTid: 'people_modal.grid.selected.tooltip.not_selectable_due_to_prior_selected'
			}
		}
	}

	const disableMemberFunction = (member) => {
		if (member.get('isInvestor')) {
			return {
				icon: 'faExclamationCircle',
				isDisabled: true,
				tooltipTid: 'people_modal.grid.selected.tooltip.not_selectable_due_to_shareholder'
			}
		}

		if (invitees && invitees.length > 0) {
			const memberIsAlreadySelected = invitees.some((invitee) => invitee.userId === member.get('id'))

			if (memberIsAlreadySelected) {
				return {
					icon: 'faExclamationSquare',
					isDisabled: true,
					tooltipTid: 'people_modal.grid.selected.tooltip.not_selectable_due_to_prior_selected'
				}
			}
		}
	}

	const disableCompanyContacts = (companyContact) => {
		if (!invitees) {
			return
		}

		const companyContacrIsAlreadySelected = invitees.some((invitee) => invitee.userId === companyContact.get('id'))

		if (companyContacrIsAlreadySelected) {
			return {
				icon: 'faExclamationSquare',
				isDisabled: true,
				tooltipTid: 'people_modal.grid.selected.tooltip.not_selectable_due_to_prior_selected'
			}
		}
	}

	const sendInvite = async () => {
		if (pickedUserIds.length > 0) {
			await dispatch(sendInvestorInvite(emission.companyId, emission.id, pickedUserIds, invite.template))
		} else {
			await dispatch(
				sendDealflowInvite(emission.companyId, orgNumber, emission.id, typeOfInvitees, invite.template)
			)
		}
		onComplete()
	}

	const onConfirmPayment = async () => {
		prepaidApi.setIsSubmitting(true)

		const result = await confirm.showConfirmation('subscriptions.confirm_purchase.title', [
			{ tid: 'subscriptions.confirm_purchase.question' }
		])

		if (!result) {
			onCancel()
			return
		}

		// dispatch(
		// 	refillPrepaid(
		// 		async () => await sendInvite(),
		// 		() => prepaidApi.setIsSubmitting(false)
		// 	)
		// )
		await prepaidApi.savePrepaidBalance(
			async () => await sendInvite(),
			() => prepaidApi.setIsSubmitting(false)
		)
	}

	const getSecondSlideTitle = () => {
		let tid = ''
		switch (typeOfInvitees) {
			case 'shareholders':
				tid = 'emissions.invitations.current-shareholders'
				break
			case 'potential-investors':
				tid = 'emissions.invitations.potential-investors'
				break
			case 'dealflow-via-direct-follow':
				tid = 'emissions.invitations.modal.title.send-invitation-via-direct-follow'
				break
			case 'dealflow-via-criteria':
				tid = 'emissions.invitations.modal.title.send-invitation-via-criteria'
				break
			default:
				break
		}
		return tid
	}

	const steps = [
		// People list
		{
			slots: {
				header() {
					let peopleModalTabs =
						typeOfInvitees === 'shareholders' ? getOnlyTabs([TAB_SHAREHOLDERS]) : getDefaultTabs()
					if (typeOfInvitees === 'potential-investors') {
						peopleModalTabs = peopleModalTabs.map((tab) => {
							if (tab.tabName !== TAB_TEAMS_AND_SHAREHOLDES) {
								return tab
							}

							tab.title = 'people_modal.tab.members'
							return tab
						})
					}

					return <PeopleModalHeader title='emissions.invitations.invitees' tabs={peopleModalTabs} />
				},
				body() {
					if (typeOfInvitees === 'shareholders') {
						return (
							<InvestorsGrid
								disabledFunction={disableInvestors}
								onSetSelectedRowsOnMount={(person) => person.investorStatus === 'CURRENT'}
							/>
						)
					} else {
						return (
							<>
								<TeamsAndInvestorsListContainer
									disableMemberFunction={disableMemberFunction}
									showOnlyMembers
									includeUnregisteredUsers
								/>
								<CompanyContactsGrid disableFunction={disableCompanyContacts} />
								<PersonalContactsGrid />
							</>
						)
					}
				},
				footerRightActions({ changeStep }) {
					return (
						<PeopleModalFooterButtons
							onSave={(pickedUsers) => {
								const pickedUsersJs = pickedUsers.toJS()
								const final = Object.keys(pickedUsersJs).map((id) => {
									const current = pickedUsersJs[id]
									return {
										userId: current.userId,
										invitedAs:
											typeOfInvitees === 'shareholders'
												? inviteeStatus.currentShareholder
												: inviteeStatus.potentialShareholder,
										investmentId: current.investmentId ? current.investmentId : null,
										isInvestor: current.isInvestor ?? false,
										isCompanyMember: !current.isInvestor && !current.isGuest,
										isCompanyContact: current.isGuest,
										investorTypeOfOwner: current.investorTypeOfOwner
									}
								})

								setPickedUserIds(final)
								changeStep(1)
							}}
							onCancel={onCancel}
							confirmButtonTid='next'
						/>
					)
				}
			},
			styles: {
				body: { pb: 0, pt: 0 }
			}
		},

		// Email invitation
		{
			slots: {
				title() {
					return t(getSecondSlideTitle())
				},
				body() {
					return (
						<ComposeInvitationModal
							numOfPeopleToInvite={numOfPeopleToInvite}
							typeOfInvitees={typeOfInvitees}
							onEmailtemplateChanged={setInvite}
							invite={invite}
						/>
					)
				},
				footerLeftActions({ changeStep }) {
					return (
						<Button onClick={() => changeStep(0)} disabled={isSendingInvites} color={'default'}>
							{t('generic.back')}
						</Button>
					)
				},
				footerRightActions({ changeStep }) {
					return (
						<>
							{isSendingInvites && (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center'
									}}>
									<Icon icon='faSpinner' spin />{' '}
									<Typography component='span' variant='body2' sx={{ ml: 1 }}>
										{t('emissions_module.invitation.sending_invites')}
									</Typography>
								</Box>
							)}
							<Tooltip
								title={'emissions.invitation.modal.send.tooltip.none_to_invite'}
								hideTooltip={numOfPeopleToInvite}>
								<Button
									color='positive'
									disabled={!numOfPeopleToInvite || numOfPeopleToInvite < 1 || isSendingInvites}
									onClick={async () => {
										if (!sufficientAmount) {
											changeStep(2)
										} else {
											setIsSendingInvites(true)
											await sendInvite()
										}
									}}>
									{t(
										sufficientAmount
											? 'emissions.invitations.send'
											: 'emissions.invitations.refill_esigning_balance'
									)}
								</Button>
							</Tooltip>
							<Button onClick={onCancel} disabled={isSendingInvites} color={'default'}>
								{t('generic.form.cancel')}
							</Button>
						</>
					)
				}
			}
		},

		// Esigning Balance
		{
			slots: {
				title: 'subscriptions.prepaid_modal.title',
				body() {
					return (
						<Box sx={{ display: 'flex', gap: '32px' }}>
							<Box sx={{ flex: 1, mt: '-12px' }}>
								<PrepaidForm
									onAmountChanged={prepaidApi.setAmount}
									onAutoRefillChange={prepaidApi.setAutoRefill}
									onTermsChanged={prepaidApi.setTermsAccepted}
									amount={prepaidApi.amount}
									autoRefill={prepaidApi.autoRefill}
									termsAccepted={prepaidApi.termsAccepted}
									amountToPay={amountToPay}
								/>
							</Box>
							<PrepaidInfo />
						</Box>
					)
				},
				footerLeftActions({ changeStep }) {
					return <Button onClick={() => changeStep(1)}>{t('generic.back')}</Button>
				},
				footerRightActions() {
					return (
						<>
							<Tooltip
								states={prepaidApi.tooltipStates}
								activeState={prepaidApi.activeTooltipState}
								hideTooltip={!prepaidApi.saveButtonDisabled}>
								<Button
									onClick={onConfirmPayment}
									disabled={prepaidApi.saveButtonDisabled}
									color='positive'>
									{t('subscriptions.prepaid_modal.buttons.confirm')}
								</Button>
							</Tooltip>
							<Button onClick={onCancel} disabled={prepaidApi.isSubmitting}>
								{t('subscriptions.prepaid_modal.buttons.reject')}
							</Button>
						</>
					)
				}
			}
		}
	]

	return (
		<ThemeProvider theme={MUItheme}>
			<PeopleModalProvider isOpen={false}>
				<StepsModal
					isOpen={isOpen}
					steps={steps}
					modalSize='xl'
					overrideIndex={
						typeOfInvitees === 'dealflow-via-direct-follow' || typeOfInvitees === 'dealflow-via-criteria'
							? 1
							: null
					}
					loading={isSendingInvites}
				/>
			</PeopleModalProvider>
		</ThemeProvider>
	)
}

InvitationModal.propTypes = {
	emission: object,
	typeOfInvitees: oneOf([
		'potential-investors',
		'shareholders',
		'dealflow-via-direct-follow',
		'dealflow-via-criteria'
	]),
	isOpen: bool,
	onComplete: func.isRequired,
	onCancel: func.isRequired
}

export { InvitationModal }
