import React, { PureComponent } from 'react'
import { func, string, bool } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import styled from 'styled-components'
import Square from '../../shared/square/square'
import Text from '../../shared/text/text'
import { ATTENDEE_STATUS_TO_COLOR_MAP } from '../../../constants/meetings'
import { ATTENDEE_STATUS_PRESENT, ATTENDEE_STATUS_ABSENT, ATTENDEE_STATUS_UNSURE } from '/shared/constants'

const StyledContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: center;
`

const StyledStatusWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: ${(props) => props.theme.spacing[2]} ${(props) => props.theme.spacing[4]} 0;

	:first-child {
		margin-left: 0;
	}

	> div {
		margin-right: ${(props) => props.theme.spacing[2]};
	}
`

export default class AttendeeResponseStatusLegend extends PureComponent {
	render = () => {
		const statuses = [ATTENDEE_STATUS_PRESENT, ATTENDEE_STATUS_ABSENT, ATTENDEE_STATUS_UNSURE, 'default']
		const statusToTidMap = {
			[ATTENDEE_STATUS_PRESENT]: 'meetings.attendees.response_status.legend.accepted_invite',
			[ATTENDEE_STATUS_ABSENT]: 'meetings.attendees.response_status.legend.declined',
			[ATTENDEE_STATUS_UNSURE]: 'meetings.attendees.response_status.legend.undecided',
			default: 'meetings.attendees.response_status.legend.no_response'
		}

		return (
			<StyledContainer>
				{statuses.map((status, index) => {
					return (
						<StyledStatusWrapper key={index}>
							<Square bgColor={ATTENDEE_STATUS_TO_COLOR_MAP[status]} /> <Text tid={statusToTidMap[status]} />
						</StyledStatusWrapper>
					)
				})}
			</StyledContainer>
		)
	}
}
