import React from 'react'
import { func, node } from 'prop-types'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'

const StyledFullScreenStealthDropzone = styled(Dropzone)`
	display: flex;
	flex-direction: column;
	flex: 1;
`

const ReactDropzone = ({ onDrop, onDragEnter, onDragLeave, children }) => {
	return (
		<StyledFullScreenStealthDropzone
			disableClick
			disablePreview
			onDrop={onDrop}
			onDragEnter={onDragEnter}
			onDragLeave={onDragLeave}>
			{children}
		</StyledFullScreenStealthDropzone>
	)
}

ReactDropzone.propTypes = {
	children: node,
	onDrop: func.isRequired,
	onDragEnter: func,
	onDragLeave: func
}

export default ReactDropzone
