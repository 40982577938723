import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Text from '../shared/text/text'
import Icon from '../shared/icon/icon'
import Button from '../shared/button/button'

import logoImg from '../../assets/images/logo-white.svg'

const StyledWrapper = styled.div`
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.75);
`

const StyledInnerWrapper = styled.div`
	align-items: center;
	flex-direction: column;
	justify-content: center;
	display: flex;
	max-width: 900px;
	width: 100%;
	padding: ${(props) => props.theme.spacing[5]} ${(props) => props.theme.spacing[5]};
`

const StyledLogo = styled.img`
	height: 80px;
	margin-bottom: ${(props) => props.theme.spacing[5]};
`

const StyledBrowsers = styled.div`
	display: flex;
	width: 100%;
	margin-top: ${(props) => props.theme.spacing[6]};
	margin-bottom: ${(props) => props.theme.spacing[6]};
`

const BrowserCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;

	> span[class*='fa-'] {
		margin-bottom: ${(props) => props.theme.spacing[5]};
	}
`

const StyledButtonsWrapper = styled.div`
	margin-top: ${(props) => props.theme.spacing[5]};

	button {
		margin-left: ${(props) => props.theme.spacing[4]};
		margin-right: ${(props) => props.theme.spacing[4]};
	}
`

export default class OutdatedBrowserFullscreen extends PureComponent {
	onOpenBrowserLink = (updateUrl) => {
		window.open(updateUrl, '_blank')
	}

	onOpenSupportGuide = () => {
		window.open('https://invono.se/support/', '_blank')
	}

	goToSignUp = () => {
		this.props.onIgnoreBrowserVersion('/signup')
	}

	goToSign = () => {
		this.props.onIgnoreBrowserVersion('/signin')
	}

	renderBrowserCard = (iconName, btnText, updateUrl) => {
		return (
			<BrowserCard>
				<Icon type='brand' icon={iconName} size='xxl' color='lightestGrey' />
				<Button
					mode='rounded'
					minWidth='180px'
					maxWidth='180px'
					onClick={this.onOpenBrowserLink.bind(null, updateUrl)}
					inverted
					transparent>
					<Text color='lightestGrey'>{btnText}</Text>
				</Button>
			</BrowserCard>
		)
	}

	render = () => {
		return (
			<StyledWrapper>
				<StyledInnerWrapper>
					<StyledLogo src={logoImg} alt='Invono Logo' />
					<Text tid='outdated_browser_1' marginBottom={5} marginTop={5} fontSize='24px' color='lightestGrey' />
					<Text tid='outdated_browser_2' fontSize='15px' color='lightestGrey' />
					<Text tid='outdated_browser_3' marginBottom={4} fontSize='15px' color='lightestGrey' />
					<Text tid='outdated_browser_4' fontSize='15px' color='lightestGrey' />

					<StyledBrowsers>
						{this.renderBrowserCard('faChrome', 'Chrome, Version 71+', 'https://google.com/chrome')}
						{this.renderBrowserCard('faSafari', 'Safari, Version 12+', 'https://support.apple.com/HT204416')}
						{this.renderBrowserCard('faFirefox', 'Firefox, Version 64+', 'https://mozilla.org/firefox/new')}
						{this.renderBrowserCard('faEdge', 'Edge, Version 16+', 'https://microsoft.com/windows/microsoft-edge')}
					</StyledBrowsers>

					<div>
						<Text tid='outdated_browser_5' fontSize='15px' color='lightestGrey' />
						&nbsp;
						<Button mode='link' onClick={this.onOpenSupportGuide}>
							<Text fontSize='15px' tid='outdated_browser_6' color='lightestGrey' hasUnderline />
						</Button>
					</div>

					<StyledButtonsWrapper>
						<Button
							mode='rounded'
							minWidth='200px'
							maxWidth='200px'
							onClick={this.goToSignUp}
							tid='outdated_browser.btn.signup'
						/>
						<Button
							mode='rounded'
							minWidth='200px'
							maxWidth='200px'
							onClick={this.goToSign}
							tid='outdated_browser.btn.signin'
						/>
					</StyledButtonsWrapper>
				</StyledInnerWrapper>
			</StyledWrapper>
		)
	}
}
