import React, { PureComponent } from 'react'
import { func, bool } from 'prop-types'
import styled from 'styled-components'
import { list } from 'react-immutable-proptypes'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Panel from '../../shared/panel/panel'
import DarkInfoPanel from '../../shared/dark-info-panel/dark-info-panel'
import { OutlinedButton } from '../../shared/button-v2'
import ListItem from './list-item'
import Text from '../../shared/text/text'
import Alert from '../../shared/alert/alert'

const Wrapper = styled.div`
	margin: ${(props) => props.theme.spacing[4]};
	padding-top: 4px; /* Compensate for listitem not having top margin on first item. For consistency */
`

const StyledDarkInfoPanel = styled(DarkInfoPanel)`
	margin: ${(props) => props.theme.spacing[4]};
	margin-bottom: 0;
`

const StyledWarningsWrapper = styled.div`
	margin: 0 ${(props) => props.theme.spacing[4]};

	> div {
		margin-top: ${(props) => props.theme.spacing[3]};
	}
`

const NoVerifiedInvestorsAlert = () => (
	<Alert tid='meetings.voting.panel.warning.no_attendees_can_vote' mode='warning' iconType='solid' icon />
)

const RollcallNotCompletedAlert = ({ onOpenRollcall }) => (
	<Alert mode='warning' iconType='solid' icon>
		<Text tid='meetings.voting.panel.warning.rollcall_not_completed' color='white' />
		&nbsp;
		<Text
			tid='meetings.voting.panel.warning.rollcall_not_completed.open_rollcall'
			color='white'
			onClick={onOpenRollcall}
			hasUnderline
		/>
	</Alert>
)

export default class VotingPanel extends PureComponent {
	static propTypes = {
		questions: list,
		onCreateQuestion: func,
		onDragEnd: func,
		onManageQuestion: func,
		renderMoreActionsMenu: func,
		atLeastOneAttendeeCanVote: bool,
		rollcallDone: bool,
		hasAttendees: bool,
		onOpenRollcallModal: func.isRequired
	}

	static defaultProps = {}

	renderDarkInfoPanelText = () => {
		const { questions } = this.props
		const hasNoQuestions = !questions || !questions.size
		let textTid = 'meetings.voting.panel.dark_info.add_another_question'

		if (hasNoQuestions) {
			textTid = 'meetings.voting.panel.dark_info.add_first_question'
		}

		return <Text tid={textTid} color='white' />
	}

	renderQuestionItem = (questionObject, index) => {
		const { renderMoreActionsMenu, atLeastOneAttendeeCanVote, rollcallDone } = this.props
		const id = questionObject.get('id')

		return (
			<ListItem
				question={questionObject.get('question')}
				topicRef={questionObject.get('topicRef')}
				id={id}
				index={questionObject.get('index', index)} // TEMP INCORRECT, REMOVE default index
				result={questionObject.get('finalResult')}
				status={questionObject.get('status')}
				sealed={questionObject.get('sealed')}
				key={id}
				renderMoreActionsMenu={renderMoreActionsMenu(id)}
				atLeastOneAttendeeCanVote={atLeastOneAttendeeCanVote}
				rollcallDone={rollcallDone}
			/>
		)
	}

	render = () => {
		const {
			questions,
			rollcallDone,
			onDragEnd,
			onCreateQuestion,
			atLeastOneAttendeeCanVote,
			onOpenRollcallModal,
			hasAttendees
		} = this.props
		const hasQuestions = questions.size > 0

		return (
			<Panel title='meetings.voting.panel.title' marginBottom>
				<StyledDarkInfoPanel
					headerLeftComponent={this.renderDarkInfoPanelText()}
					headerRightComponent={<OutlinedButton icon='faPlus' onClick={onCreateQuestion} />}
				/>

				<StyledWarningsWrapper>
					{hasQuestions && !atLeastOneAttendeeCanVote && <NoVerifiedInvestorsAlert />}
					{!rollcallDone && hasAttendees && <RollcallNotCompletedAlert onOpenRollcall={onOpenRollcallModal} />}
				</StyledWarningsWrapper>

				{hasQuestions && (
					<Wrapper>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId='votingQuestionList' type='test'>
								{(provided) => <div ref={provided.innerRef}>{questions.map(this.renderQuestionItem)}</div>}
							</Droppable>
						</DragDropContext>
					</Wrapper>
				)}
			</Panel>
		)
	}
}
