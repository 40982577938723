import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func } from 'prop-types'
import { Map, List, merge } from 'immutable'
import immutablePropTypes from 'react-immutable-proptypes'

import { setRef, getRef, toggleMenu } from '../../../../components/helpers/refs.helper'
import { getUserName } from '../../../../components/helpers/users'
import { fetchAndCacheInvestors } from '../../../../actions/investors.actions'
import { findProxyByAttendeeId, getProxyDocumentsDisplayData } from '../../../../components/helpers/meeting.helper'
import moment from '../../../../modules/moment.module'

import Rollcall from '../../../../dumb-components/meetings/rollcall/rollcall'
import Text from '../../../../dumb-components/shared/text/text'
import DropdownMenuContainer from '../../../shared/dropdown-menu.container'
import DropdownIconItem from '../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import MeetingRoleDisplay from '../../attendees/meeting-role-display.container'
import SendConfirmationToAttendeeContainer from '../general/dropdown-items/send-confirmation-to-attendee.container'
import DocumentsContainer from '../general/dropdown-items/documents.container'
import AddProxyContainer from '../general/dropdown-items/add-proxy.container'
import { ButtonTransparentIcon } from '../../../../dumb-components/shared/button'
import RollcallFilterContainer from './rollcall-filter.container'
import { getChunkOfAttendees } from '../../../../components/helpers/meeting.helper'
import filterAttendees from '../../../../components/helpers/filter-attendees'

import {
	MEETING_TYPE_STANDARD,
	MEETING_SUBTYPE_AGM,
	ATTENDEE_STATUS_PRESENT,
	ATTENDEE_ATTENDANCE_PHYSICAL,
	ATTENDEE_ATTENDANCE_VIDEO,
	ATTENDEE_ATTENDANCE_PHONE
} from '/shared/constants'

const ATTENDEES_CHUNK_SIZE = 20

class RollcallContainer extends Component {
	state = {
		hasMoreAttendees: false,
		numOfLoads: 0,
		attendees: List()
	}

	static propTypes = {
		hideInfo: bool,
		hideMoreActions: bool,
		onGoToProxyDocuments: func,
		formData: immutablePropTypes.map,
		onGoToAddProxy: func
	}

	moreActionBtnRefs = {}

	componentDidMount = () => {
		this.loadAttendees()
	}

	componentDidUpdate = (prevProps) => {
		const { numOfAttendees } = this.props

		if (prevProps.numOfAttendees !== numOfAttendees) {
			this.reloadAttendees()
		}
	}

	goToProxyDocuments = (attendeeId) => {
		const { onGoToProxyDocuments, proxies } = this.props
		this.toggleDropdownMenu(attendeeId)
		const proxy = findProxyByAttendeeId(proxies, attendeeId)
		onGoToProxyDocuments(attendeeId, proxy)
	}

	onChange = (attendeeId, fieldName, value) => {
		const { onChange } = this.props
		let { formData } = this.props
		const { attendees } = this.state

		if (fieldName === 'attendanceType') {
			if (!value) {
				formData = formData.setIn(['attendees', attendeeId, 'attendanceType'], null)
			} else {
				formData = formData.setIn(['attendees', attendeeId, 'attendanceType'], value)
			}

			formData = formData.setIn(['attendees', attendeeId, 'attendanceTypeSetFromApp'], true)
		}

		if (fieldName === 'status') {
			// Value
			if (value) {
				formData = formData.setIn(['attendees', attendeeId, 'status'], value)
				formData = formData.setIn(['attendees', attendeeId, 'statusSetFromApp'], true)
				formData = formData.setIn(['attendees', attendeeId, 'haveResponded'], true)
				formData = this.setVerifiedAttendanceOnSelfIfAGM(formData, attendeeId, value)
				formData = this.setAttendanceTypeOnAttendee(formData, attendeeId)
			} else {
				// Cleared. Change to the status attendee responded
				formData = formData.setIn(
					['attendees', attendeeId, 'status'],
					attendees.getIn([attendeeId, 'responseStatus'], null)
				)
			}
		}

		onChange && onChange(formData)
	}

	setVerifiedAttendanceOnSelfIfAGM = (formData, attendeeId, status) => {
		const { userId, isAGM } = this.props

		if (!isAGM) {
			return formData
		}

		if (status !== ATTENDEE_STATUS_PRESENT) {
			return formData
		}

		formData = formData.setIn(['attendees', attendeeId, 'verifiedAttendance'], true)
		formData = formData.setIn(['attendees', attendeeId, 'verifiedAttendanceAt'], moment().toISOString())

		return formData
	}

	setAttendanceTypeOnAttendee = (formData, attendeeId) => {
		const { hasPhysicalLocation, hasWebLocation, hasPhoneLocation } = this.props
		const numberOfAvailableAttendanceTypes = List([hasPhysicalLocation, hasWebLocation, hasPhoneLocation]).filter(
			(type) => type === true
		).size

		if (formData.getIn(['attendees', attendeeId], 'attendanceTypeSetFromApp')) {
			return formData
		}

		// No attendance types or more than one specified, do not set anything
		if (numberOfAvailableAttendanceTypes !== 1) {
			return formData
		}

		if (hasPhysicalLocation) {
			return formData.setIn(['attendees', attendeeId, 'attendanceType'], ATTENDEE_ATTENDANCE_PHYSICAL)
		}

		if (hasWebLocation) {
			return formData.setIn(['attendees', attendeeId, 'attendanceType'], ATTENDEE_ATTENDANCE_VIDEO)
		}

		if (hasPhoneLocation) {
			return formData.setIn(['attendees', attendeeId, 'attendanceType'], ATTENDEE_ATTENDANCE_PHONE)
		}

		return formData
	}

	toggleDropdownMenu = (userId) => {
		toggleMenu(getRef(this.moreActionBtnRefs, userId))
	}

	formatOptionLabel = (option) => {
		return <Text tid={option.label} />
	}

	getProxyDocumentsDisplayData = (userId) => {
		const { proxies, isAGM } = this.props
		const proxy = findProxyByAttendeeId(proxies, userId)
		const proxyDocumentsDisplayData = getProxyDocumentsDisplayData({
			proxy,
			userId,
			isAGM,
			hideBadgeIfDefault: true
		})
		return { hasProxy: Boolean(proxy), ...proxyDocumentsDisplayData }
	}

	loadAttendees = () => {
		const { numOfAttendees } = this.props
		let { numOfLoads, attendees } = this.state

		setTimeout(() => {
			const start = numOfLoads++ * ATTENDEES_CHUNK_SIZE
			const end = start + ATTENDEES_CHUNK_SIZE
			const newAttendees = getChunkOfAttendees(start, end)

			if (!newAttendees) {
				return
			}

			attendees = attendees.concat(newAttendees)
			const hasMoreAttendees = attendees.size < numOfAttendees
			this.setState({ attendees, numOfLoads, hasMoreAttendees })
		}, 500)
	}

	reloadAttendees = () => {
		const { numOfAttendees } = this.props

		if (numOfAttendees === 0) {
			return this.setState({ attendees: List(), numOfLoads: 0 })
		}

		setTimeout(() => {
			const start = 0
			const end = start + ATTENDEES_CHUNK_SIZE
			const attendees = getChunkOfAttendees(start, end)

			if (!attendees) {
				return this.setState({ attendees: List(), numOfLoads: 0 })
			}

			const hasMoreAttendees = attendees.size < numOfAttendees
			this.setState({ attendees, numOfLoads: 1, hasMoreAttendees })
		}, 500)
	}

	onFilter = (filterCriteria) => {
		const { formData } = this.props

		if (formData.size === 0) {
			return
		}

		const attendeesFilteredInClient = filterAttendees(formData.get('attendees'), filterCriteria)
		return attendeesFilteredInClient
	}

	renderMoreActionMenu = (attendeeId) => {
		const { isAGM, onGoToAddProxy } = this.props
		const { badgeColor, badgeVisible } = this.getProxyDocumentsDisplayData(attendeeId)

		return (
			<DropdownMenuContainer
				ref={setRef.bind(this, this.moreActionBtnRefs, attendeeId)}
				halignMenu='right'
				withPortal
				noMaxWidth
				inline
				buttonNoHorizontalPadding
				renderRaw={
					<ButtonTransparentIcon
						icon='faEllipsisV'
						size='sml'
						notificationBadge={badgeVisible}
						notificationBadgeColor={badgeColor}
						notificationBadgeAnimate={false}
						x={1}
					/>
				}>
				<SendConfirmationToAttendeeContainer
					userId={attendeeId}
					onClick={this.toggleDropdownMenu.bind(null, attendeeId)}
				/>

				{isAGM && (
					<AddProxyContainer
						userId={attendeeId}
						dropdownRef={getRef(this.moreActionBtnRefs, attendeeId)}
						onClickOverride={onGoToAddProxy}
					/>
				)}

				<DocumentsContainer
					userId={attendeeId}
					onClickOverride={this.goToProxyDocuments.bind(null, attendeeId)}
				/>
			</DropdownMenuContainer>
		)
	}

	render = () => {
		const { hideInfo, hideMoreActions, proxies, secretary, chairman, isAGM, formData } = this.props

		const { hasMoreAttendees, attendees } = this.state
		const hideCheckin = !isAGM
		const hideVotingInfo = !isAGM

		return (
			<Rollcall
				attendees={attendees}
				formData={formData}
				onChange={this.onChange}
				hideInfo={hideInfo}
				hideCheckin={hideCheckin}
				hideVotingInfo={hideVotingInfo}
				isAGM={isAGM}
				hideMoreActions={hideMoreActions}
				renderMoreActionMenu={this.renderMoreActionMenu}
				proxies={proxies}
				secretary={secretary}
				chairman={chairman}
				hasMoreAttendees={hasMoreAttendees}
				loadAttendees={this.loadAttendees}
				renderRoleDisplay={({ attendeeId, roles }) => {
					return (
						<MeetingRoleDisplay
							attendeeId={attendeeId}
							roles={roles}
							secretary={secretary}
							chairman={chairman}
						/>
					)
				}}
				filterComponent={
					<RollcallFilterContainer
						hideCheckin={hideCheckin}
						hideMoreActions={hideMoreActions}
						hideVotingInfo={hideVotingInfo}
						onFilter={this.onFilter}
					/>
				}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get('meeting'),
		meetingId: store.meetings.getIn(['meeting', 'id']),
		proxies: store.meetings.getIn(['meeting', 'proxies']),
		secretary: store.meetings.getIn(['meeting', 'secretary']),
		chairman: store.meetings.getIn(['meeting', 'chairman']),
		isAGM: store.meetings.getIn(['meeting', 'meetingSubType']) === MEETING_SUBTYPE_AGM,
		userId: store.user.getIn(['userObj', 'id']),
		hasPhysicalLocation: store.meetings.getIn(['meeting', 'computedValues', 'hasPhysicalLocation']),
		hasPhoneLocation: store.meetings.getIn(['meeting', 'computedValues', 'hasPhoneLocation']),
		hasWebLocation: store.meetings.getIn(['meeting', 'computedValues', 'hasWebLocation']),
		numOfAttendees: store.meetings.getIn(['meeting', 'attendees'])?.size || 0
	}
}

const mapActionsToProps = {
	fetchAndCacheInvestors
}

export default connect(mapStoreToProps, mapActionsToProps)(RollcallContainer)
