import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Margin } from 'styled-components-spacing'
import { map } from 'react-immutable-proptypes'
import Text from '../../shared/text/text'
import Button from '../../shared/button/button'
import { func, bool } from 'prop-types'
import { lighten } from 'polished'
import Icon from '../../shared/icon/icon'
import Tooltip from '../../shared/tooltip/tooltip'

const StyledLinkProtocolBody = styled.div`
	display: flex;
	background: ${(props) => (props.transparent === true ? 'transparent' : props.theme.colors.solitudeLight)};
	align-items: center;
	width: 100%;
	height: 50px;
	border: 1px solid ${(props) => lighten(0.2, props.theme.colors.lightGrey)};
`

const StyledIconDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	background: ${(props) => (props.transparent === true ? 'transparent' : props.theme.colors.solitudeLight)};
	border-right: 1px solid ${(props) => lighten(0.2, props.theme.colors.lightGrey)};
	height: 100%;
`

const StyledInfo = styled.div`
	flex: 1;
	padding: 0 ${(props) => props.theme.spacing[3]};
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

const StyledDropdownWrapper = styled.div`
	display: flex;
	margin-left: ${(props) => props.theme.spacing[3]};
	margin-right: ${(props) => props.theme.spacing[3]};
`

export default class ProtocolPreview extends PureComponent {
	static propTypes = {
		transparent: bool,
		renderDropdownContainer: func,
		meeting: map,
		onOpenMeeting: func,
		onOpenProtocolReview: func,
		onOpenProtocol: func,
		meetingIsDeleted: bool
	}

	render = () => {
		const {
			transparent,
			renderDropdownContainer,
			meeting,
			userId,
			onOpenMeeting,
			onOpenProtocolReview,
			onOpenProtocol,
			meetingIsDeleted
		} = this.props

		if (!meeting || !userId) {
			return null
		}

		const showWarning = meeting.getIn(['computedValues', 'showWarning'])
		const goToSection = meeting.getIn(['computedValues', 'goToSection'])

		let iconComponent = <Icon icon='faCheck' color='green' type='solid' size='lg' />
		if (showWarning) {
			iconComponent = <Icon icon='faExclamationTriangle' color='orange' type='solid' size='lg' />
		}

		let openBtnAction
		let openBtnTid
		let titleTid = 'meeting.inline_preview.status.draft'
		let tooltipTid

		if (goToSection === 'meeting') {
			openBtnAction = onOpenMeeting
			openBtnTid = 'meetings.protocol_inline_preview.agenda_open'

			if (meetingIsDeleted) {
				tooltipTid = 'meetings.tooltip.recover_meeting_to_open_agenda'
			}

			if (meeting.getIn(['computedValues', 'agendaPublished'])) {
				titleTid = 'meeting.inline_preview.status.public'
			}
		} else if (goToSection === 'protocol-review') {
			openBtnAction = onOpenProtocolReview
			openBtnTid = 'meetings.protocol_inline_preview.protocol_open'
			titleTid = 'meeting.inline_preview.status.unpublished'

			if (meetingIsDeleted) {
				tooltipTid = 'meetings.tooltip.recover_meeting_to_open_protocol'
			}

			if (meeting.getIn(['computedValues', 'protocolPublished'])) {
				titleTid = 'meeting.inline_preview.status.published'
			}
		} else if (goToSection === 'protocol') {
			openBtnAction = onOpenProtocol
			openBtnTid = 'meetings.protocol_inline_preview.protocol_open'
			titleTid = 'meeting.inline_preview.status.unpublished'

			if (meetingIsDeleted) {
				tooltipTid = 'meetings.tooltip.recover_meeting_to_open_protocol'
			}

			if (meeting.getIn(['computedValues', 'protocolPublished'])) {
				titleTid = 'meeting.inline_preview.status.published'
			}
		}

		return (
			<StyledLinkProtocolBody transparent={transparent}>
				<StyledIconDiv transparent={transparent}>{iconComponent}</StyledIconDiv>

				<StyledInfo>
					<Text tid={titleTid} singleLine />
				</StyledInfo>

				<StyledDropdownWrapper>
					<Tooltip tid={tooltipTid} active={meetingIsDeleted} delayShow='instant'>
						<Button onClick={openBtnAction} disabled={meetingIsDeleted}>
							<Margin right={3}>
								<Icon icon='faExpandArrowsAlt' size='xs' />
							</Margin>
							<Text tid={openBtnTid} />
						</Button>
					</Tooltip>

					{renderDropdownContainer && renderDropdownContainer()}
				</StyledDropdownWrapper>
			</StyledLinkProtocolBody>
		)
	}
}
