import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func, bool, oneOf, oneOfType } from 'prop-types'
import { Map } from 'immutable'
import Text from '../../../dumb-components/shared/text/text'
import { ATTENDEES_STATUS_OPTIONS } from '../../../constants/meetings'
import DropdownStatusButton from '../../../dumb-components/shared/dropdown-status-button/dropdown-status-button'
import { ATTENDEE_STATUS_PRESENT, ATTENDEE_STATUS_UNSURE, ATTENDEE_STATUS_ABSENT } from '/shared/constants'

class DropdownStatusButtonContainer extends Component {
	static propTypes = {
		fieldName: string,
		haveResponded: bool,
		status: oneOfType([string, bool]),
		onChange: func,
		readOnly: bool,
		context: oneOf(['attendees', 'feedback', 'signing']),
		statusSetFromApp: bool,
		openModal: func,
		verifiedAttendance: bool
	}

	state = {
		isOpen: false
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleMouseDown, false)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleMouseDown, false)
	}

	handleMouseDown = (e) => {
		if (!this.node.contains(e.target)) {
			this.setState({ isOpen: false })
		}
	}

	setRef = (ref) => {
		this.node = ref
	}

	onChange = (val) => {
		const { onChange, fieldName } = this.props

		onChange && onChange(fieldName, val)
		this.setState({ isOpen: false })
	}

	onToggleStatusMenu = () => {
		const { isStatic, readOnly } = this.props

		!isStatic &&
			!readOnly &&
			this.setState((prevState) => {
				return { isOpen: !prevState.isOpen }
			})
	}

	formatOption = (option) => {
		if (option.get('value') === null) {
			return (
				<span>
					<Text bold={700} tid={option.get('label')} />
				</span>
			)
		}

		return (
			<span>
				<Text tid='meetings.attendees.status_option.attendee_is' />
				&nbsp;
				<Text bold={700} tid={option.get('label')} />
			</span>
		)
	}

	render() {
		const { status, verifiedAttendance, isStatic, readOnly, context, statusSetFromApp, openModal } = this.props
		const { isOpen } = this.state
		let options = ATTENDEES_STATUS_OPTIONS
		let statusIcon = 'faTimes'
		let statusColor

		if (
			status === true ||
			status === 'SIGNED' ||
			verifiedAttendance ||
			(status === ATTENDEE_STATUS_PRESENT && statusSetFromApp)
		) {
			statusColor = 'green'
			statusIcon = 'faCheck'
			options = options.filter((obj) => obj.get('value') === ATTENDEE_STATUS_ABSENT)
		} else if (status === ATTENDEE_STATUS_ABSENT && statusSetFromApp) {
			statusColor = 'red'
			options = options.filter((obj) => obj.get('value') === ATTENDEE_STATUS_PRESENT)
		} else if (status === ATTENDEE_STATUS_UNSURE && statusSetFromApp) {
			statusColor = 'yellowMid'
		} else {
			statusColor = 'solitudeDark'
		}

		if (context) {
			options = options.push(
				Map({
					value: null,
					label: 'meetings.attendees.status_option.not_responded'
				})
			)
		}

		return (
			<DropdownStatusButton
				onToggleMenu={this.onToggleStatusMenu}
				statusColor={statusColor}
				statusIcon={statusIcon}
				options={options}
				onChange={this.onChange}
				isOpen={isOpen}
				onSetRef={this.setRef}
				formatOption={this.formatOption}
				readOnly={isStatic || readOnly}
				openModal={openModal}
			/>
		)
	}
}

const mapStoreToProps = () => {
	return {}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(DropdownStatusButtonContainer)
