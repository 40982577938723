import React, { PureComponent } from 'react'
import { string, bool, func } from 'prop-types'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

import ButtonBase from '../button-v2/src/base/button-base'
import Text from '../text/text'
import Tooltip from '../tooltip/tooltip'

const StyledWrapper = styled.div`
	height: 100%;
	> span {
		height: 100%;
	}
`

const StyledModalTab = styled(ButtonBase)`
	display: flex;
	flex: 1;
	border: 1px solid ${(props) => props.theme.colors.border};
	border-top: none;
	height: 100%;
	align-items: center;
	user-select: none;
	justify-content: center;
	background-color: ${(props) => (props.isActive ? props.theme.colors.white : props.theme.colors.tableAltRowColor)};
	list-style-type: none;
	padding: 0 ${(props) => props.theme.spacing[4]};
	margin-left: -1px;

	${(props) =>
		props.isActive &&
		css`
			border-bottom: 2px solid ${(props) => props.theme.colors.white};
		`}

	${(props) =>
		props.turnedOff &&
		css`
			background-color: ${props.theme.colors.disabled};
			cursor: default;
		`}
		${(props) =>
		!props.turnedOff &&
		css`
			transition: 300ms color, 10ms border;

			* {
				transition: 300ms color;
				color: ${(props) => props.theme.colors.midGrey};
			}

			:disabled {
				* {
					color: ${(props) => props.theme.colors.catalinaBlue};
				}
			}

			:hover:not(:disabled) {
				background-color: ${(props) => props.theme.colors.lightestGrey};

				* {
					color: ${(props) => darken(0.15, props.theme.colors.midGrey)};
				}
			}

			:active:focus {
				transition: 100ms color;

				* {
					color: ${(props) => darken(0.25, props.theme.colors.midGrey)};
				}
			}
		`}
`

const ModalTabComponent = ({ props, onClick, isActive, textTid }) => (
	<StyledModalTab onClick={onClick} {...props} disabled={isActive}>
		<Text tid={textTid} singleLine bold={isActive ? 600 : undefined} />
	</StyledModalTab>
)

export default class ModalTab extends PureComponent {
	static propTypes = {
		textTid: string,
		onChange: func,
		tabName: string,
		isActive: bool,
		turnedOff: bool,
		tooltipTid: string
	}

	static defaultProps = {
		isActive: false
	}

	onChange = () => {
		const { tabName, onChange, turnedOff } = this.props

		!turnedOff && onChange(tabName)
	}

	render = () => {
		const { textTid, isActive, tooltipTid, turnedOff } = this.props

		if (turnedOff) {
			return (
				<StyledWrapper>
					<Tooltip tid={tooltipTid} delayShow='instant' place='bottom'>
						<ModalTabComponent props={this.props} onClick={this.onChange} isActive={isActive} textTid={textTid} />
					</Tooltip>
				</StyledWrapper>
			)
		}

		return <ModalTabComponent props={this.props} onClick={this.onChange} isActive={isActive} textTid={textTid} />
	}
}
