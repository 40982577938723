import { fromJS, List } from 'immutable'

import {
	INVESTORS_LIST,
	INVESTORS_FETCH,
	INVESTORS_EDIT,
	INVESTORS_CREATE,
	INVESTORS_FETCH_MY_INVESTMENTS,
	INVESTORS_FETCH_MY_INVESTMENT,
	INVESTOR_LIST_CONCAT,
	INVESTORS_DIRTY,
	INVESTORS_SAVE_START,
	INVESTORS_EDIT_LOCAL,
	INVESTORS_SET_FILTERS,
	INVESTORS_UNSET_FILTERS,
	INVESTORS_SET_VISIBLE,
	COMPANY_RESET_REDUCER,
	INVESTORS_SET_SELECTEDINVESTOR,
	INVESTORS_BEING_FETCHED,
	INVESTORS_FETCHED_AT,
	INVESTORS_UPDATE_INVESTORS_CACHE,
	INVESTORS_UPDATE_INVESTORS_REQUEST_CACHE,
	INVESTORS_SET_NUM_OF_COMMUNICATIVE_ACTIVE_INVESTMENTS,
	INVESTOR_CREATE_LOCAL,
	INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS,
	INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS_MAP,
	INVESTORS_CAPITAL_INSURANCE_UPDATE_MY_INVESTMENTS_MAP,
	INVESTORS_COMPANY_UPDATE_MY_INVESTMENTS_MAP,
	INVESTORS_FETCH_MY_INVESTMENTS_DASHBOARD,
	INVESTORS_CLEAR_MY_INVESTMENTS_DASHBOARD
} from '../actions/types'
import { DEFAULT_SHOWBY_VALUE, DEFAULT_ORDER_VALUE } from '../constants/shares'

const INITIAL_STATE = fromJS({
	list: null,
	visibleInvestors: null,
	selectedInvestor: null,
	myInvestments: [],
	myinvestmentsMap: {},
	selectedInvestment: null,
	investorDirty: false,
	hasAppliedFilters: false,
	filterBy: [
		{ source: 'showBy', values: [DEFAULT_SHOWBY_VALUE] },
		{ source: 'order', values: [DEFAULT_ORDER_VALUE] }
	],
	defaultFilters: {},
	isFetchingInvestors: false,
	investorsFetchedAt: null,
	investorsCache: {},
	investorsRequestCache: {},
	numOfActiveCommunicativeInvestments: null
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case INVESTORS_BEING_FETCHED:
			return state.set('isFetchingInvestors', true)
		case INVESTORS_FETCHED_AT:
			return state.set('investorsFetchedAt', payload)
		case INVESTORS_LIST: {
			if (state.get('isFetchingInvestors') === true) {
				state = state.set('isFetchingInvestors', false)
			}

			return state.set('list', payload)
		}
		case INVESTORS_UPDATE_INVESTORS_CACHE: {
			if (payload?.size > 0) {
				payload.forEach((investor) => {
					state = state.setIn(['investorsCache', investor.get('id')], investor)
					state = state.removeIn(['investorsRequestCache', investor.get('id')])
				})
			}

			return state
		}
		case INVESTORS_UPDATE_INVESTORS_REQUEST_CACHE: {
			payload?.forEach((id) => {
				state = state.setIn(['investorsRequestCache', id], id)
			})

			return state
		}
		case INVESTORS_FETCH:
			return state.set('selectedInvestor', payload)
		case INVESTOR_CREATE_LOCAL: {
			state = state.updateIn(['list'], (investors) => {
				return investors ? investors.push(payload) : List([payload])
			})

			state = state.set('selectedInvestor', payload)
			return state
		}
		case INVESTORS_CREATE:
			return state.set('selectedInvestor', payload)
		case INVESTORS_EDIT: {
			let investor = state.get('selectedInvestor')

			if (!state.get('investorDirty')) {
				investor = payload
			}

			return state.set('selectedInvestor', investor)
		}
		case INVESTORS_EDIT_LOCAL: {
			if (!payload) {
				return state.set('selectedInvestor', payload)
			}

			// Update list
			const listIndex = (state.get('list', List([])) || List([])).findIndex((i) => i.get('id') === payload.get('id'))

			if (listIndex >= 0) {
				state = state.setIn(['list', listIndex], payload)
			}

			// Update selected investor
			state = state.set('selectedInvestor', payload)
			return state
		}
		case INVESTORS_FETCH_MY_INVESTMENTS: {
			state = state.set('myInvestments', payload.get('investments'))
			return state.set('myInvestmentsMap', payload.get('myInvestmentsMap'))
		}
		case INVESTORS_FETCH_MY_INVESTMENT: {
			return state.set('selectedInvestment', payload)
		}
		case INVESTORS_FETCH_MY_INVESTMENTS_DASHBOARD: {
			state = state.setIn(['dashboard', 'myInvestments'], payload.get('investments'));
			return state.setIn(['dashboard','myInvestmentsMap'], payload.get('myInvestmentsMap'));
		}
		case INVESTORS_CLEAR_MY_INVESTMENTS_DASHBOARD: {
			return state.set('dashboard', {});
		}
		case INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS: {
			const myInvestments = state.get('myInvestments')
			const newMyInvestments = myInvestments.concat(payload)

			state = state.set('myInvestments', newMyInvestments)

			return state
		}
		case INVESTORS_PERSONAL_INVESTMENT_UPDATE_MY_INVESTMENTS_MAP: {
			const myInvestmentsMap = state.getIn(['myInvestmentsMap', 'privateInvesments'])
			const newMyInvestmentsMap = myInvestmentsMap.push(fromJS({ personalInvestment: payload.first().get('id') }))

			state = state.setIn(['myInvestmentsMap', 'privateInvesments'], newMyInvestmentsMap)
			return state
		}
		case INVESTORS_CAPITAL_INSURANCE_UPDATE_MY_INVESTMENTS_MAP: {
			const myInvestmentsMap = state.getIn(['myInvestmentsMap', 'capitalInsuranceInvestments'])
			const newMyInvestmentsMap = myInvestmentsMap.push(fromJS({ personalInvestment: payload.first().get('id') }))

			state = state.setIn(['myInvestmentsMap', 'capitalInsuranceInvestments'], newMyInvestmentsMap)
			return state
		}
		case INVESTORS_COMPANY_UPDATE_MY_INVESTMENTS_MAP: {
			const myInvestmentsMap = state.getIn(['myInvestmentsMap', 'viaCompanyInvestments'])
			const newMyInvestmentsMap = myInvestmentsMap.push(fromJS({ personalInvestment: payload.first().get('id') }))

			state = state.setIn(['myInvestmentsMap', 'viaCompanyInvestments'], newMyInvestmentsMap)
			return state
		}
		case INVESTORS_SAVE_START:
			return state.set('investorDirty', false)
		case INVESTORS_DIRTY:
			return state.set('investorDirty', true)
		case INVESTORS_SET_FILTERS: {
			const source = payload.get('source')
			const values = payload.get('values')
			let filterBy = state.get('filterBy')
			let sourceRemoved = false

			if (!values) {
				filterBy = filterBy.filter((obj) => obj.get('source') !== source)
				sourceRemoved = true
			}

			const index = filterBy.findIndex((obj) => obj.get('source') === source)

			if (index >= 0) {
				filterBy = filterBy.set(index, payload)
			} else if (!sourceRemoved) {
				filterBy = filterBy.push(payload)
			}

			return state.set('filterBy', filterBy)
		}
		case INVESTORS_UNSET_FILTERS: {
			const sources = payload
			let filterBy = state.get('filterBy')

			filterBy = filterBy.filter((filter) => {
				const source = filter.get('source')
				let found = false

				sources.forEach((obj) => {
					if (obj === source && source !== 'order' && source !== 'showBy') {
						found = true
					}
				})

				return !found
			})

			filterBy = filterBy.map((filter) => {
				if (filter.get('source') === 'showBy') {
					filter = filter.set('values', List([DEFAULT_SHOWBY_VALUE]))
				} else if (filter.get('source') === 'order') {
					filter = filter.set('values', List([DEFAULT_ORDER_VALUE]))
				}

				return filter
			})

			return state.set('filterBy', filterBy)
		}
		case INVESTORS_SET_VISIBLE:
			state = state.set('hasAppliedFilters', payload.hasAppliedFilters)
			return state.set('visibleInvestors', payload.investors)
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}
		case INVESTORS_SET_SELECTEDINVESTOR: {
			return state.set('selectedInvestor', payload)
		}

		case INVESTORS_SET_NUM_OF_COMMUNICATIVE_ACTIVE_INVESTMENTS:
			return state.set('numOfActiveCommunicativeInvestments', payload)
		case INVESTOR_LIST_CONCAT: {
			if (state.get('list') === null) {
				return state.set('list', payload)
			} else {
				return state.set('list', state.get('list').concat(payload))
			}
		}

		default:
			return state
	}
}
