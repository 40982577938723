import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import {
	fetchUsers,
	fetchUser,
	resetRolesAndUsersCache,
	removeUserFromCompany,
	transferOwnership
} from '../../../actions/user-management.actions'
import { updateCompanyLocal, fetchCompany } from '../../../actions/company.actions'
import { openModal } from '../../../actions/modals.actions'
import UserManagementContainer from './user-management-container'

import { ButtonTransparentIcon } from '../../../dumb-components/shared/button'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'
import DropdownMenuContainer from '../../../containers/shared/dropdown-menu.container'
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'

import {
	EVENT_TYPE_COMPANY_MEMBER_UPDATE,
	EVENT_TYPE_COMPANY_MEMBER_DELETE,
	EVENT_TYPE_COMPANY_OWNER_CHANGED,
	EVENT_TYPE_USER_REGISTERED
} from '/shared/constants'
import {
	INVITE_USER_BY_EMAIL_TEMPLATE_STEPS_MODAL,
	REMIND_USER_BY_EMAIL_TEMPLATE_MODAL
} from '../../../constants/modals'

import UsersRemindDropdownContainer from './users-remind-dropdown.container'
import InviteUserStepsModalContainer from '../../../containers/notify-by-templates/modals/users/invite-user-steps-modal.container'
import RemindUserModalContainer from '../../../containers/notify-by-templates/modals/users/remind-user-modal.container'
import RemindUsersModalContainer from '../../../containers/notify-by-templates/modals/users/remind-users-modal.container'
import UserEditModalContainer from '../../../containers/user-management/user-edit-modal.container'
import ConfirmContainer from '../../../containers/shared/confirm.container'

import { routeHoc } from '../../../interfaces/router'

const DEFAULT_STATE = {
	isOpenUserEditModal: false,
	isOpenConfirmUserDeleteModal: false,
	isOpenConfirmTransferOwnershipModal: false,
	isLoading: false,
	managedUserId: null,
	userIsActive: null
}

class UserManagementUsers extends Component {
	state = DEFAULT_STATE

	userMoreActionRef = []

	componentDidMount() {
		const { fetchUsers, fetchCompany, company } = this.props
		fetchUsers(company.id)
		fetchCompany(company.id)
	}

	componentDidUpdate = (prevProps) => {
		this.checkLiveUpdateEvents()
	}

	checkLiveUpdateEvents = () => {
		const { audit, company, fetchUsers, fetchCompany } = this.props
		const companyMemberUpdate = audit.get(EVENT_TYPE_COMPANY_MEMBER_UPDATE, Map())
		const companyMemberDelete = audit.get(EVENT_TYPE_COMPANY_MEMBER_DELETE, Map())
		const companyOwnerChanged = audit.get(EVENT_TYPE_COMPANY_OWNER_CHANGED, Map())
		const companyMemberRegistered = audit.get(EVENT_TYPE_USER_REGISTERED, Map())

		if (
			companyMemberUpdate.get('refresh') === true ||
			companyMemberDelete.get('refresh') === true ||
			companyOwnerChanged.get('refresh') === true
		) {
			fetchUsers(company.id)
		}

		if (companyOwnerChanged.get('refresh') === true) {
			fetchCompany(company.id)
		}

		if (companyMemberRegistered.get('refresh') === true) {
			fetchUsers(company.id)
			fetchCompany(company.id)
		}
	}

	resetState = () => {
		this.setState(DEFAULT_STATE)
	}

	onAddUser = () => {
		this.props.openModal(INVITE_USER_BY_EMAIL_TEMPLATE_STEPS_MODAL)
	}

	onRemindUser = (user) => {
		this.props.openModal(REMIND_USER_BY_EMAIL_TEMPLATE_MODAL, { user })
	}

	onEditUser = (userId, userIsActive) => {
		this.closeMoreActionDropdown(userId)

		this.props.fetchUser(userId, this.props.company.id)

		this.setState({ isOpenUserEditModal: true, userIsActive })
	}

	onCancelUserEditModal = () => {
		this.setState({ isOpenUserEditModal: false, userIsActive: false })
	}

	onDeleteUser = (userId) => {
		this.closeMoreActionDropdown(userId)

		if (userId === null) {
			return false
		}

		this.setState({ isOpenConfirmUserDeleteModal: true, managedUserId: userId })
	}

	onCancelDeleteUser = () => {
		this.resetState()
	}

	doDeleteUser = () => {
		const { company, resetRolesAndUsersCache, removeUserFromCompany, updateCompanyLocal } = this.props
		const { managedUserId } = this.state

		this.setState({ isLoading: true })

		const updatedUsers = company.users.filter((id) => {
			return id !== managedUserId
		})

		removeUserFromCompany(managedUserId, company.id, (e) => {
			if (e) {
				this.setState({ isLoading: false })
				return
			}

			resetRolesAndUsersCache()
			updateCompanyLocal({ ...company, users: updatedUsers })

			this.resetState()
		})
	}

	onTransferCompanyOwnership = (userId) => {
		this.closeMoreActionDropdown(userId)

		if (userId === null) {
			return false
		}

		this.setState({ isOpenConfirmTransferOwnershipModal: true, managedUserId: userId })
	}

	onCancelTransferCompanyOwnership = () => {
		this.resetState()
	}

	doTransferCompanyOwnership = () => {
		const { company, transferOwnership } = this.props
		const { managedUserId } = this.state

		this.setState({ isLoading: true })

		transferOwnership(managedUserId, company.id, (e) => {
			if (e) {
				this.setState({ isLoading: false })
				return
			}

			this.resetState()
		})
	}

	closeMoreActionDropdown = (userId) => {
		this.userMoreActionRef[userId] && this.userMoreActionRef[userId].onToggleMenu()
	}

	renderUserRoles(user) {
		const { userRoles } = user
		const { company } = this.props
		const isOwner = company.superUser === user.id
		let output = ''
		let count = 0

		userRoles.forEach((role) => {
			if (isOwner && role === 'Super Administratör') {
				role = 'Kontoägare'
			}
			count++
			if (count < userRoles.length) {
				output += role + ', '
			} else {
				output += role
			}
		})

		return output
	}

	renderUser = (user) => {
		const { i18n, company, userObj } = this.props

		const { isUsingDefaultEmail } = user
		const isSuperUser = company.superUser === user.id
		const loggedInUserIsAdmin = userObj ? company.superUser === userObj.get('id') : false
		const userIsActive = user.id && !user.isUnregistered ? true : false
		const key = user.id || user.email

		return (
			<tr key={key}>
				<td>{user.name}</td>
				<td>
					<Tooltip tid='user_management.users.default_email_in_use' active={isUsingDefaultEmail}>
						<span>{user.email}</span>
					</Tooltip>
				</td>
				<td>{i18n.messages[`users.status.${userIsActive ? 'ACTIVE' : 'NOT_REGISTERED'}`]}</td>
				<td>{this.renderUserRoles(user)}</td>
				<td className='text-right'>
					<DropdownMenuContainer
						btnIcon='faEllipsisV'
						halignMenu='right'
						btnMode='transparent-icon'
						transparentIconButtonSize='sml'
						ref={(r) => (this.userMoreActionRef[user.id] = r)}
						noMaxWidth
						inline>
						<DropdownIconItem
							icon='faUserEdit'
							tid='generic.form.edit'
							onClick={this.onEditUser.bind(null, user.id || user.email, user.id ? true : false)}
						/>

						{!userIsActive && (
							<DropdownIconItem
								icon='faRepeat'
								tid='user_management.users.user.dropdown.remind_user'
								onClick={this.onRemindUser.bind(this, user)}
							/>
						)}

						<Tooltip active={isSuperUser} tid='user_management.users.tooltip.owner_cant_be_removed' delayShow='instant'>
							<DropdownIconItem
								icon='faTrashAlt'
								tid='generic.delete'
								onClick={this.onDeleteUser.bind(null, user.id || user.email)}
								disabled={isSuperUser}
							/>
						</Tooltip>

						{!isSuperUser && loggedInUserIsAdmin && !company.isUserDemo && (
							<Tooltip
								active={!userIsActive}
								tid='user_management.users.transfer_ownership.not_active.tooltip'
								delayShow='instant'>
								<DropdownIconItem
									icon='faRandom'
									disabled={!userIsActive}
									tid='user_management.users.btn.transfer_ownership'
									onClick={this.onTransferCompanyOwnership.bind(null, user.id)}
								/>
							</Tooltip>
						)}
					</DropdownMenuContainer>
				</td>
			</tr>
		)
	}

	renderToolbar = () => {
		return (
			<>
				<UsersRemindDropdownContainer />

				<Tooltip tid='tooltip.company.management.action.add_user'>
					<ButtonTransparentIcon icon='faUserPlus' size='sml' onClick={this.onAddUser} />
				</Tooltip>
			</>
		)
	}

	render = () => {
		const {
			isOpenUserEditModal,
			isLoading,
			isOpenConfirmUserDeleteModal,
			isOpenConfirmTransferOwnershipModal,
			userIsActive
		} = this.state
		const { location } = this.props
		const users = this.props.users || []

		return (
			<UserManagementContainer location={location} roleSelectorComponent={this.renderToolbar}>
				<div className='panel'>
					<div className='panel-body'>
						<div className='table-responsive' style={{ overflowX: 'unset' }}>
							<table className='table table-striped table-hover'>
								<thead>
									<tr>
										<th>
											<FormattedMessage id='generic.name' />
										</th>
										<th>
											<FormattedMessage id='generic.email' />
										</th>
										<th>
											<FormattedMessage id='generic.status' />
										</th>
										<th>
											<FormattedMessage id='generic.user_role' />
										</th>
									</tr>
								</thead>
								<tbody>{users.map(this.renderUser)}</tbody>
							</table>
						</div>
					</div>
				</div>

				<InviteUserStepsModalContainer />
				<RemindUserModalContainer />
				<RemindUsersModalContainer />

				<UserEditModalContainer
					isOpen={isOpenUserEditModal}
					onCancel={this.onCancelUserEditModal}
					userIsActive={userIsActive}
				/>

				<ConfirmContainer
					isOpen={isOpenConfirmUserDeleteModal}
					isLoading={isLoading}
					onConfirm={this.doDeleteUser}
					onDecline={this.onCancelDeleteUser}
					title='generic.delete'
					question='company.management.remove_user_confirm'
				/>

				<ConfirmContainer
					isOpen={isOpenConfirmTransferOwnershipModal}
					isLoading={isLoading}
					onConfirm={this.doTransferCompanyOwnership}
					onDecline={this.onCancelTransferCompanyOwnership}
					title='user_management.users.btn.transfer_ownership'
					question='user_management.users.transfer_ownership_confirm'
				/>
			</UserManagementContainer>
		)
	}
}

function mapStoreToProps(store) {
	return {
		users: store.company.userManagement.users,
		userObj: store.user.get('userObj'),
		company: store.company.company,
		i18n: store.i18n,
		audit: store.audit.get('company')
	}
}

const mapActionsToProps = {
	fetchUsers,
	fetchUser,
	resetRolesAndUsersCache,
	removeUserFromCompany,
	updateCompanyLocal,
	transferOwnership,
	fetchCompany,
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(UserManagementUsers)
