import React, { PureComponent } from 'react'
import { bool, func } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import styled from 'styled-components'

import Modal from '../../shared/modal/modal'
import Label from '../../shared/label/label'
import Input from '../../shared/input/input'
import TextArea from '../../shared/input/textarea'
import ColoredContentWrapper from '../../shared/colored-content-wrapper/colored-content-wrapper'
import FooterRightControls from '../../shared/modal/footer-right-controls'
import { TransparentButton } from '../../shared/button-v2'

const StyledTeamCreateWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`

const StyledInputWrapper = styled.div`
	margin-bottom: 16px;
`

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`

export default class TeamCreateModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		onSave: func,
		onCancel: func,
		onChange: func,
		team: map,
		hasError: map,
		isLoading: bool
	}

	renderFooter = () => {
		const { onSave, onCancel, isLoading } = this.props

		return (
			<FooterRightControls>
				<TransparentButton tid='generic.form.create' onClick={onSave} isLoading={isLoading} />
				<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={onCancel} disabled={isLoading} />
			</FooterRightControls>
		)
	}

	renderContent = () => {
		const { onChange, team, hasError, isLoading } = this.props

		return (
			<StyledTeamCreateWrapper>
				<StyledColoredContentWrapper type='secondary'>
					<Label tid='generic.name'>
						<StyledInputWrapper>
							<Input
								fieldName='name'
								placeholderTid='user_management.team.add_team.name.placeholder'
								onChange={onChange}
								hasError={hasError.get('name')}
								value={team.get('name', '')}
								disabled={isLoading}
							/>
						</StyledInputWrapper>
					</Label>
					<Label tid='generic.description'>
						<TextArea
							fieldName='description'
							placeholderTid='generic.description'
							onChange={onChange}
							value={team.get('description', '')}
							minRows={7}
							disabled={isLoading}
						/>
					</Label>
				</StyledColoredContentWrapper>
			</StyledTeamCreateWrapper>
		)
	}

	render = () => {
		const { isOpen, title } = this.props

		return (
			<Modal
				isOpen={isOpen}
				title='company.management.add_role'
				footerComponent={this.renderFooter()}
				hSize='md'
				vSize={75}>
				{this.renderContent()}
			</Modal>
		)
	}
}
