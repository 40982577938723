/**
 * Messages reducer
 * @module reducers/messages.reducer
 */

import { MESSAGES_LIST, MESSAGES_FETCH, COMPANY_RESET_REDUCER } from '../actions/types'

/** The initial state object for messages. */
const INITIAL_STATE = {
	messagesList: [],
	message: null
}

/** Export the reducer */
export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		// Messages list
		case MESSAGES_LIST:
			return { ...state, messagesList: action.payload }

		// Single message
		case MESSAGES_FETCH:
			const messageListUpdated = state.messagesList.map((item) => {
				if (item.id === action.payload.id) {
					item.status = 'read'
				}
				return item
			})
			return { ...state, message: action.payload, messagesList: messageListUpdated }
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}
