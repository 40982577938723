import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { string, bool, func } from 'prop-types'
import { Padding } from 'styled-components-spacing'
import Panel from '../../panel/panel'
import Button from '../../button/button'

const StyledWrapper = styled.div`
	flex: 1;
`

const StyledButtonWrapper = styled.div`
	display: flex;
	flex: 1;
	padding: 0 ${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[4]};
`

export default class ObjectFilterPanel extends PureComponent {
	static propTypes = {
		title: string,
		renderObjectFilterFormComponent: func,
		isOpen: bool,
		onClose: func,
		onClearAll: func,
		onResetDefaultValues: func
	}

	renderButtons = () => {
		const { onClose, onClearAll, onResetDefaultValues } = this.props

		return (
			<StyledButtonWrapper>
				<Button onClick={onClose} tid='object_filter.close' />
				<Button onClick={onClearAll} tid='object_filter.clear_all' mode='button-link' />
				{onResetDefaultValues && (
					<Button onClick={onResetDefaultValues} tid='object_filter.reset_default_values' mode='button-link' />
				)}
			</StyledButtonWrapper>
		)
	}

	render = () => {
		const { title, renderObjectFilterFormComponent, isOpen } = this.props

		return (
			<StyledWrapper isOpen={isOpen}>
				<Panel mode='light' title={title} shadowed>
					{renderObjectFilterFormComponent()}
					{this.renderButtons()}
				</Panel>
			</StyledWrapper>
		)
	}
}
