import React, { PureComponent } from 'react'
import { object, string, number } from 'prop-types'
import styled, { css } from 'styled-components'
import { v1 } from 'uuid'

import SimpleDarkInfoPanel from './simple-dark-info-panel'
import Button from '../button/button'

const StyledSimpleDarkInfopanel = styled(SimpleDarkInfoPanel)`
	flex-direction: column;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
`

const HeaderLeft = styled.div`
	display: flex;
	min-width: 0;
	flex: 1;
	margin-right: ${(props) => props.theme.spacing[4]};
	align-items: center;
`

const HeaderRight = styled.div`
	display: flex;
`

const Footer = styled.div`
	display: flex;

	${(props) =>
		props.isExpanded &&
		css`
			border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
			padding-top: ${(props) => props.theme.spacing[4]};
			margin-top: ${(props) => props.theme.spacing[4]};
		`}
`

export default class DarkInfoPanel extends PureComponent {
	state = {
		isExpanded: false,
		domId: v1()
	}

	static propTypes = {
		headerLeftComponent: object,
		headerRightComponent: object,
		footerComponent: object,
		className: string
	}

	componentDidMount = () => {
		const { domId } = this.state
		const targetNode = document.getElementById(`${domId}-footer`)

		if (targetNode) {
			const observer = new MutationObserver(this.setIsExpanded)

			observer.observe(targetNode, {
				attributes: false,
				childList: true,
				subtree: true
			})

			this.setIsExpanded()
		}
	}

	setIsExpanded = () => {
		const { domId } = this.state
		const targetNode = document.getElementById(`${domId}-footer`)

		if (targetNode.hasChildNodes()) {
			this.setState({ isExpanded: true })
		} else {
			this.setState({ isExpanded: false })
		}
	}

	renderHeader = () => {
		const { headerLeftComponent, headerRightComponent } = this.props

		return (
			<Header>
				<HeaderLeft>{headerLeftComponent}</HeaderLeft>
				<HeaderRight>{headerRightComponent}</HeaderRight>
			</Header>
		)
	}

	renderFooter = () => {
		const { footerComponent } = this.props
		const { isExpanded, domId } = this.state

		return (
			<Footer id={`${domId}-footer`} isExpanded={isExpanded}>
				{footerComponent && footerComponent}
			</Footer>
		)
	}

	render = () => {
		const { children, className } = this.props

		return (
			<StyledSimpleDarkInfopanel className={className}>
				{this.renderHeader()}

				{children}

				{this.renderFooter()}
			</StyledSimpleDarkInfopanel>
		)
	}
}
