import React, { createContext, useContext } from 'react'

/**
 * Values:
 * - outerContainerRef: Ref
 * - scrollbarRef: Ref
 */

export const ScrollViewContext = createContext({ outerContainerRef: null, scrollbarRef: null })

export const useScrollViewContext = () => {
	return useContext(ScrollViewContext)
}
