import { fromJS, Map, List } from 'immutable';
import uuid from 'uuid';
import req from '../modules/request.module';
import { generateQuery } from '../components/helpers/query-helper';

import {
	DOCUMENTS_LIST,
	DOCUMENTS_LIST_PAGINATED,
	DOCUMENTS_UPDATE_LOCAL,
	DOCUMENTS_FETCH_REMOTE,
	DOCUMENTS_SAVE_REMOTE,
	DOCUMENTS_SAVE_REMOTE_START,
	DOCUMENTS_DELETE_REMOTE,
	DOCUMENTS_DOCUMENT_DIRTY,
	DOCUMENTS_MIRRORED_FETCH_REMOTE,
	DOCUMENTS_SET_FILTERS,
	DOCUMENTS_UNSET_FILTERS,
	DOCUMENTS_CREATE_LOCAL,
	DOCUMENTS_SELECT,
	DOCUMENTS_SET_VISIBLE,
	DOCUMENTS_SET_DEFAULT_FILTERS,
	DOCUMENTS_UPDATE_DEFAULT_FILTER,
	DOCUMENTS_RESET_DEFAULT_VALUES,
	DOCUMENTS_LIST_BY_COMPANIES,
	DOCUMENTS_FETCH_HELPER,
	DOCUMENTS_HARD_DELETE,
	DOCUMENTS_HARD_DELETE_LOCAL,
	FOLDERS_LIST,
	DOCUMENTS_FETCH_DOCUMENTS_TO_MERGE,
	DOCUMENTS_PREPEND_DOCUMENT_TO_MERGE,
	DOCUMENTS_TO_SAVE_SAVE_REMOTE,
	DOCUMENTS_CLEAR_DOCUMENTS_TO_MERGE,
	DOCUMENTS_UPDATE_NUMBER_OF_SHARE_WITH,
	COLLECTION_REMOVE_DELETED_DOCUMENT,
	DOCUMENTS_ADD_DOCUMENT_TO_LIST,
	DOCUMENTS_CLEAR_PAGINATION,
	DOCUMENTS_UPDATE_PAGINATED_DOCUMENTS,
	DOCUMENTS_UPDATE_DOCUMENT_IN_LIST
} from './types';

import { addErrorNotification, addInfoNotification } from './notify.actions';
import moment from '../modules/moment.module';
import {
	EVENT_TYPE_DOCUMENT_CREATE,
	EVENT_TYPE_DOCUMENT_UPDATE,
	EVENT_TYPE_DOCUMENT_DELETE,
	EVENT_TYPE_DOCUMENT_TRANSFER,
	EVENT_TYPE_DOCUMENT_SIGN,
	EVENT_TYPE_DOCUMENT_SIGNING_FINALIZED,
	EVENT_TYPE_DOCUMENT_SHOW_ONB_DOCUMENT_UPLOADED_MODAL,
	__DELETE__
} from '/shared/constants';
import { setLiveRequest, resetLiveRequest } from './live-update.actions';
import documentsHelper from '../components/helpers/documents.helper';
import { saveAs } from 'file-saver';

export function setFilteredDocuments(documents, hasAppliedFilters) {
	return {
		type: DOCUMENTS_SET_VISIBLE,
		payload: { documents, hasAppliedFilters }
	};
}

/**
 * Action for updating the document list locally
 */
export function updateDocumentListLocal(documents) {
	return {
		type: DOCUMENTS_LIST,
		payload: documents
	};
}

export function transferDocument(documentIds, folderId, callback) {
	const parts = { documentIds, folderId };

	return function () {
		return req.post(`/documents/documents/transfer`, parts).then(() => {
			callback && callback();
		});
	};
}

/**
 * Action for fetching a document
 */
export function fetchDocument(id, silent) {
	return function (dispatch) {
		return req
			.get(`/documents/documents/${id}?silent=${silent}`)
			.then((response) => {
				if (response.data && !response.data.silentError) {
					dispatch({ type: DOCUMENTS_FETCH_REMOTE, payload: fromJS(response.data) });
				}
			})
			.catch((err) => {
				console.log(err);
				dispatch(documentsError('documents.error.load_document'));
			});
	};
}

/**
 * Action for fetching all documents that corresponds to the given IDs
 */
export function fetchDocuments(documentIds, callback) {
	return function () {
		return req.post('/documents/documents/multiple', documentIds).then((response) => {
			callback && callback(fromJS(response.data));
		});
	};
}

export function fetchDocumentsCount(callback) {
	return function () {
		return req
			.get(`/documents/documents/count`)
			.then((response) => {
				callback && callback(fromJS(response.data));
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

// ------------------- External Documents Start ------------------- //

export function fetchExternalDocument(id) {
	return function (dispatch) {
		return req
			.get(`/documents/documents/external/${id}`)
			.then((response) => {
				dispatch({ type: DOCUMENTS_FETCH_REMOTE, payload: fromJS(response.data) });
			})
			.catch((err) => {
				console.log(err);
				dispatch(documentsError('documents.error.load_document'));
			});
	};
}

export function saveExternalDocument(objType, objId, doc, callback) {
	const id = doc.get('id');

	return function (dispatch) {
		dispatch({ type: DOCUMENTS_SAVE_REMOTE_START, payload: doc });
		return req
			.put(`/documents/documents/external/${objType}/${objId}/${id}`, doc.toJS(), { onlyLatest: true })
			.then((response) => {
				const document = fromJS(response.data);
				dispatch({ type: DOCUMENTS_SAVE_REMOTE, payload: document });
				callback && callback(document);
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes('onlyLatest:true')) {
					console.error(e);
					dispatch(documentsError('documents.error.save_document'));
				}
			});
	};
}

export function linkDocument(document, objType, objId, callback) {
	return function (dispatch) {
		const documentId = document.get('id');

		if (!document.has('links')) {
			document = document.set('links', List());
		}

		document = document.update('links', (links) => links.push(Map({ objId, objType })));

		dispatch({ type: DOCUMENTS_UPDATE_PAGINATED_DOCUMENTS, payload: document });

		return req.put(`/documents/documents/attachment/${objType}/${objId}/${documentId}/link`).then((response) => {
			callback && callback(fromJS(response.data));
		});
	};
}

export function unlinkDocument(document, objType, objId, callback) {
	return function (dispatch) {
		const documentId = document.get('id');

		document = document.update('links', (links) => {
			return links.filter((link) => link.get('objId') !== objId);
		});

		dispatch({ type: DOCUMENTS_UPDATE_PAGINATED_DOCUMENTS, payload: document });

		return req.delete(`/documents/documents/external/${objType}/${objId}/${documentId}`).then(() => {
			callback && callback(document);
		});
	};
}

// ------------------- External Documents End ------------------- //

export function downloadDocument({ documentId, openInViewer, getSignedVersion, getMergedVersion }) {
	const params = [];
	if (openInViewer) {
		params.push('open=true');
	}
	if (getSignedVersion) {
		params.push('signed=true');
	}
	if (getMergedVersion) {
		params.push('merged=true');
	}

	let w = null;
	if (openInViewer) {
		w = window.open(`https://${window.location.hostname}/assets/build/misc/redirecting.html`, '_blank');
	}

	return function () {
		return req
			.get(`/documents/download/${documentId}${params.length > 0 ? `?${params.join('&')}` : ''}`)
			.then((response) => {
				const { fileSize, downloadUrl, ext } = response.data;

				if (openInViewer) {
					const { webViewerSupportedFormat, webViewerSupportedSize } =
						documentsHelper.getWebViewerIsSupported(undefined, ext, fileSize);
					if (webViewerSupportedFormat && webViewerSupportedSize) {
						const encodedUrl = encodeURIComponent(downloadUrl);
						w.location = `https://view.officeapps.live.com/op/view.aspx?src=${encodedUrl}`;
					} else {
						w.location = downloadUrl;
					}
				} else {
					const link = document.createElement('a');
   					link.href = downloadUrl;
    				link.download = downloadUrl.substr(downloadUrl.lastIndexOf('/') + 1);
    				link.click();
				}
			});
	};
}

export function downloadDocumentPublic({
	documentId,
	openInViewer,
	companyId,
	getSignedVersion,
	getMergedVersion,
	userId
}) {
	const params = [];
	if (companyId) {
		params.push(`companyId=${companyId}`);
	}
	if (openInViewer) {
		params.push('open=true');
	}
	if (getSignedVersion) {
		params.push('signed=true');
	}
	if (getMergedVersion) {
		params.push('merged=true');
	}
	if (userId) {
		params.push(`userId=${userId}`);
	}

	let w = null;
	if (openInViewer) {
		w = window.open(`https://${window.location.hostname}/assets/build/misc/redirecting.html`, '_blank');
	}

	return function () {
		return req
			.get(`/documents/public/download/${documentId}${params.length > 0 ? `?${params.join('&')}` : ''}`)
			.then((response) => {
				const { fileSize, downloadUrl, ext } = response.data;

				if (openInViewer) {
					const { webViewerSupportedFormat, webViewerSupportedSize } =
						documentsHelper.getWebViewerIsSupported(undefined, ext, fileSize);
					if (webViewerSupportedFormat && webViewerSupportedSize) {
						const encodedUrl = encodeURIComponent(downloadUrl);
						w.location = `https://view.officeapps.live.com/op/view.aspx?src=${encodedUrl}`;
					} else {
						w.location = downloadUrl;
					}
				} else {
					window.open(downloadUrl);
				}
			});
	};
}

export function downloadMultipleDocumentsPublic({ documentsData, companyId, userId }) {
	return function () {
		req.post(`/documents/public/multi-download/?companyId=${companyId}&sub=${userId}`, { documentsData }).then(
			(response) => {
				const { downloadData } = response.data;
				downloadData &&
					downloadData.forEach((data) => {
						const { downloadUrl, originalname } = data;
						fetch(downloadUrl)
							.then((res) => res.blob())
							.then((blob) => saveAs(blob, originalname))
							.catch((err) => console.error(err));
					});
			}
		);
	};
}

export function updateDocumentLocal(doc) {
	return function (dispatch) {
		dispatch({ type: DOCUMENTS_DOCUMENT_DIRTY });
		dispatch({ type: DOCUMENTS_UPDATE_LOCAL, payload: doc });
	};
}

export function addDocumentToList(doc) {
	return function (dispatch) {
		dispatch({ type: DOCUMENTS_ADD_DOCUMENT_TO_LIST, payload: doc });
	};
}

/**
 * Action for saving a document
 */
export function saveDocument(doc, callback) {
	return function (dispatch) {
		dispatch({ type: DOCUMENTS_SAVE_REMOTE_START, payload: doc });
		return req
			.put(`/documents/documents/${doc.get('id')}`, doc.toJS(), { onlyLatest: true })
			.then((response) => {
				const document = fromJS(response.data);
				dispatch({ type: DOCUMENTS_SAVE_REMOTE, payload: fromJS(response.data) });
				callback && callback(undefined, document);
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes('onlyLatest:true')) {
					dispatch(documentsError('documents.error.save_document'));
					callback && callback(e);
				}
			});
	};
}

export function patchDocument(documentId, documentData, callback) {
	return function (dispatch) {
		if (documentData.size === 0) {
			return;
		}

		dispatch(patchDocumentLocal(documentData));
		const documentDataJS = documentData?.toJS();

		return req
			.patch(`/documents/documents/${documentId}`, { documentData: documentDataJS }, { onlyLatest: true })
			.then((response) => {
				const document = fromJS(response.data);
				dispatch({ type: DOCUMENTS_SAVE_REMOTE, payload: document });
				callback && callback({ document, documentData });
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes('onlyLatest:true')) {
					dispatch(documentsError('documents.error.save_document'));
					callback && callback({ e, documentData });
				}
			});
	};
}

export function patchDocumentLocal(documentData) {
	return function (dispatch, getState) {
		let document = getState().documents.get('document');

		// Build keyPath for use later when we update the document object
		// A keyPath is a path to a property seperated by dot, ie attendees.2A9EA0C2-9334-415D-9785-13B90F51E5A3.status or agendaItems.0.agendaItems.1.presenter
		const keyPathBuilder = (obj) => {
			const keyPath = {};
			const recurse = (obj, current) => {
				for (const key in obj) {
					const value = obj[key];
					const newKey = current ? `${current}.${key}` : key;

					if (value && typeof value === 'object' && Object.keys(value).length > 0) {
						recurse(value, newKey);
					} else {
						keyPath[newKey] = value;
					}
				}
			};

			recurse(obj);
			return fromJS(keyPath);
		};

		// 1. Build keyPath
		const keyPath = keyPathBuilder(documentData.toJS());

		// 2. Update document object
		keyPath.forEach((val, key) => {
			const path = key.split('.');

			if (val === __DELETE__ || val === undefined) {
				document = document.removeIn(path);
			} else {
				document = document.setIn(path, val);
			}
		});

		// 3. Update store
		dispatch({ type: DOCUMENTS_UPDATE_LOCAL, payload: document });
	};
}

export function saveDocumentToMerge(doc) {
	return function (dispatch) {
		dispatch({ type: DOCUMENTS_TO_SAVE_SAVE_REMOTE, payload: doc });
		return req
			.put(`/documents/documents/${doc.get('id')}`, doc.toJS(), { onlyLatest: true })
			.then((response) => {
				dispatch({ type: DOCUMENTS_TO_SAVE_SAVE_REMOTE, payload: fromJS(response.data) });
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes('onlyLatest:true')) {
					dispatch(documentsError('documents.error.save_document'));
				}
			});
	};
}

/**
 * Action for creating a document
 */
export function createDocument(doc, folderId, callbacks) {
	const config = {
		onUploadProgress: (progressEvent) =>
			callbacks && callbacks.onUploadProgress ? callbacks.onUploadProgress(progressEvent) : null
	};

	const docId = uuid();
	doc.append('id', docId);

	const localDoc = {
		id: docId,
		createdAt: moment().toISOString(),
		isUploading: true,
		folderId
	};
	for (const prop in doc) {
		if (prop[0] !== 'file') {
			localDoc[prop[0]] = prop[1];
		}
	}

	return function (dispatch) {
		dispatch(createDocumentLocal(fromJS(localDoc)));

		return req
			.post(`/documents/documents/${folderId ? `?folderId=${folderId}` : ''}`, doc, config)
			.then((response) => {
				let doc = fromJS(response.data);
				doc = doc.set('isUploading', false);
				dispatch({ type: DOCUMENTS_UPDATE_LOCAL, payload: doc });
				callbacks && callbacks.onComplete ? callbacks.onComplete(doc) : callbacks(doc);
			})
			.catch((e) => {
				console.log(e);
				dispatch(documentsError('documents.error.create_document'));

				if (e.response.data.translationId === 'documents.error.no_storage_space_left') {
					dispatch(hardDeleteDocumentLocal(docId));
				}
			});
	};
}

export function deleteDocumentsPublic(docId, objType, objId, companyId, callback) {
	return function (dispatch) {
		return req
			.delete(`/documents/public/documents/${objType}/${objId}/${docId}?companyId=${companyId}`)
			.then(() => {
				dispatch({ type: DOCUMENTS_DELETE_REMOTE, payload: docId });
				callback && callback();
			})
			.catch(() => {
				dispatch(documentsError('documents.error.delete_document'));
			});
	};
}

export function createDocumentLocal(doc) {
	return {
		type: DOCUMENTS_CREATE_LOCAL,
		payload: doc
	};
}

export function hardDeleteDocumentLocal(docId) {
	return {
		type: DOCUMENTS_HARD_DELETE_LOCAL,
		payload: docId
	};
}

// Lists documents with pagination
export function listDocumentsWithPagination({ folderId, numOfItems, page }, callback) {
	const query = generateQuery({
		folder: folderId,
		onlyUndeleted: true,
		numOfItems,
		page
	});

	return function (dispatch) {
		return req
			.get(`/documents/documents/${query}`)
			.then((response) => {
				const documents = fromJS(response.data.documents);
				const totalNumOfDocuments = response.headers['x-pagination-total-items'];
				dispatch({ type: DOCUMENTS_LIST_PAGINATED, payload: { documents, totalNumOfDocuments } });
				delete response.data.documents;
				dispatch({ type: FOLDERS_LIST, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch((err) => {
				console.log(err);
				callback && callback({ errror: err });
				dispatch(documentsError('documents.error.load_documents'));
			});
	};
}

export function clearPagination() {
	return function (dispatch) {
		dispatch({ type: DOCUMENTS_CLEAR_PAGINATION });
	};
}

/**
 * Action for fetching a list of documents
 */
export function listDocuments(selectedFolderId, callback) {
	return function (dispatch) {
		return req
			.get(`/documents/documents/${selectedFolderId ? `?folder=${selectedFolderId}` : ''}`)
			.then((response) => {
				dispatch({ type: DOCUMENTS_LIST, payload: fromJS(response.data.documents) });
				delete response.data.documents;
				dispatch({ type: FOLDERS_LIST, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch((err) => {
				console.log(err);
				dispatch(documentsError('documents.error.load_documents'));
			});
	};
}

/**
 * Action for clearing documents list
 */
export function clearDocumentsList() {
	return function (dispatch) {
		dispatch({ type: DOCUMENTS_LIST, payload: null });
	};
}

/**
 * Action for clearing documents list
 */
export function clearDocument() {
	return function (dispatch) {
		dispatch({ type: DOCUMENTS_FETCH_REMOTE, payload: null });
	};
}

/**
 * Action for deleting a document
 * @param {String} id — document id
 */
export function deleteDocuments(id, callback) {
	return function (dispatch) {
		return req
			.delete(`/documents/documents/${id}`)
			.then((response) => {
				const document = fromJS(response.data);
				dispatch({ type: DOCUMENTS_DELETE_REMOTE, payload: document });
				dispatch({
					type: COLLECTION_REMOVE_DELETED_DOCUMENT,
					payload: { documentId: id }
				});
				dispatch(addInfoNotification({ tid: 'documents.notifications.info.document_deleted' }));
				callback && callback();
			})
			.catch((error) => {
				console.error(error);
				dispatch(documentsError('documents.error.delete_document'));
			});
	};
}

export function deleteDocumentsExternal(id, objType, objId, callback, softError) {
	return function (dispatch) {
		return req
			.delete(`/documents/documents/external/${objType}/${objId}/${id}`)
			.then(() => {
				callback && callback();
			})
			.catch((err) => {
				console.log(err);
				!softError && dispatch(documentsError('documents.error.delete_document'));
			});
	};
}

export function listMirroredDocuments(companyId, investmentId, folderId, isCompany) {
	const query = generateQuery({ folder: folderId, isCompany });

	return function (dispatch) {
		return req.get(`/documents/mirrored/${companyId}/documents/${investmentId}${query}`).then((response) => {
			dispatch({ type: DOCUMENTS_MIRRORED_FETCH_REMOTE, payload: fromJS(response.data) });
		});
	};
}

export function listShareholderPreviewMirroredDocuments(companyId, investmentId, folderId, isCompany) {
	const query = generateQuery({ folder: folderId, isCompany });

	return function (dispatch) {
		return req
			.get(`/documents/mirrored/shareholder-preview/${companyId}/documents/${investmentId}${query}`)
			.then((response) => {
				dispatch({ type: DOCUMENTS_MIRRORED_FETCH_REMOTE, payload: fromJS(response.data) });
			});
	};
}

/*
 * --- Document Merge ---
 */

export function generateDocumentFile(docId, fileType, objOwnerId, onInit, onCompleted) {
	return function () {
		onInit && onInit(docId);
		req.post(`/documents/documents/${docId}/generate-file`, { fileType, objOwnerId })
			.then((response) => {
				onCompleted && onCompleted(null, fromJS(response.data));
			})
			.catch((e) => {
				onCompleted && onCompleted(e);
			});
	};
}

export function storeDocumentsToMerge(documents) {
	return function (dispatch) {
		dispatch({
			type: DOCUMENTS_FETCH_DOCUMENTS_TO_MERGE,
			payload: documents
		});
	};
}

export function clearDocumentsToMerge() {
	return function (dispatch) {
		dispatch({
			type: DOCUMENTS_CLEAR_DOCUMENTS_TO_MERGE
		});
	};
}

export function prependDocumentToMerge(document) {
	return function (dispatch) {
		dispatch({
			type: DOCUMENTS_PREPEND_DOCUMENT_TO_MERGE,
			payload: document
		});
	};
}

export function mergeDocuments(docIds, primaryDocumentId, callback, errCallback) {
	return function (dispatch) {
		return req
			.post('/documents/merge', { docIds: docIds.toJS(), primaryDocumentId })
			.then((response) => {
				const document = fromJS(response.data);
				const mergedDocumentData = document.get('mergedDocumentData');
				const mergeData = Map({
					mergedDocumentData
				});

				dispatch(patchDocumentLocal(mergeData));
				callback && callback(document);
			})
			.catch((e) => {
				errCallback && errCallback(e);
				dispatch(documentsError('documents.error.failed_to_merge_documents'));
			});
	};
}

// Mock
export function removeMergedDocument(docId, callback, errCallback) {
	return function (dispatch) {
		return req
			.delete(`/documents/merge/${docId}`)
			.then((response) => {
				const document = fromJS(response.data);
				dispatch({
					type: DOCUMENTS_UPDATE_LOCAL,
					payload: document
				});
				callback && callback(document);
			})
			.catch((e) => {
				errCallback && errCallback(e);
				dispatch(documentsError('documents.error.failed_to_remove_merged_document'));
			});
	};
}

/*
 * --- eSign ---
 */

export function startDocumentSigning(documentId, eSignees, callback) {
	eSignees = eSignees.toJS();
	return function (dispatch) {
		return req
			.post(`/documents/documents/esign/${documentId}/start`, { eSignees })
			.then((response) => {
				const document = fromJS(response.data);
				dispatch({ type: DOCUMENTS_SAVE_REMOTE, payload: document });
				callback && callback(undefined, document);
			})
			.catch((e) => {
				callback && callback(e);
				console.log(e);
			});
	};
}

export function cancelDocumentSigning(documentId, callback) {
	return function (dispatch) {
		return req
			.post(`/documents/documents/esign/${documentId}/cancel`)
			.then((response) => {
				const document = fromJS(response.data);
				dispatch({ type: DOCUMENTS_SAVE_REMOTE, payload: document });
				dispatch({
					type: COLLECTION_REMOVE_DELETED_DOCUMENT,
					payload: { documentId: document.get('id'), removeSignedVersion: true }
				});
				callback && callback(undefined, document);
			})
			.catch((e) => {
				console.log(e);
				callback && callback(e);
			});
	};
}

export function remindAboutESign(documentId, userId) {
	return function (dispatch) {
		return req.post(`/documents/documents/esign/${documentId}/remind`, { userId }).then(() => {
			dispatch(addInfoNotification({ tid: 'documents.esigning.reming_about_esigning.message' }));
		});
	};
}

export function recreateDocument(documentId, cb) {
	return function (dispatch) {
		return req
			.post(`/documents/documents/esign/recreate/${documentId}`)
			.then((response) => {
				dispatch({ type: DOCUMENTS_SAVE_REMOTE, payload: fromJS(response.data) });
				cb && cb();
			})
			.catch(() => {
				dispatch(documentsError('documents.error.save_document'));
				cb && cb();
			});
	};
}

/**
 * Action for fetching all documents in all the companies the user is a member of
 */
export function listDocumentsByCompanies(where) {
	return function (dispatch) {
		where = where && encodeURIComponent(JSON.stringify(where));
		return req
			.get(`/documents/documents/companies${where ? `?q=${where}` : ''}`)
			.then((response) => {
				dispatch({ type: DOCUMENTS_LIST_BY_COMPANIES, payload: fromJS(response.data) });
			})
			.catch((err) => {
				console.log(err);
				dispatch(documentsError('documents.error.load_documents'));
			});
	};
}

/*
 * Action for fetching the latest order index
 */
export function fetchLatestOrderIndex(objType, objId, callback) {
	return function () {
		return req.get(`/documents/documents/attachment/${objType}/${objId}/orderindex`).then((response) => {
			callback && callback(response.data);
		});
	};
}

/**
 * Action for recover trashed document
 */
export function recoverDocument(documentId, callback) {
	return function (dispatch) {
		return req
			.put(`/documents/documents/${documentId}/recover`)
			.then((response) => {
				dispatch({ type: DOCUMENTS_SAVE_REMOTE, payload: fromJS(response.data) });
				dispatch(addInfoNotification({ tid: 'documents.notifications.info.document_recovered' }));
				callback && callback();
			})
			.catch(() => {
				dispatch(documentsError('documents.error.save_document'));
			});
	};
}

/**
 * Action for delete pending documents perminantly
 */
export function deleteDocumentsPerminantly(currentSelectedDocumentId) {
	return function (dispatch) {
		return req
			.delete(`/documents/documents/hard-delete`)
			.then((response) => {
				dispatch({
					type: DOCUMENTS_HARD_DELETE,
					payload: Map({ currentSelectedDocumentId, documentsToDelete: fromJS(response.data) })
				});
				dispatch(addInfoNotification({ tid: 'documents.notifications.info.document_deleted_perminantly' }));
			})
			.catch((err) => {
				console.error(err);
				dispatch(documentsError('documents.error.perminantly_delete_documents'));
			});
	};
}

export function reorderDocumentsToMerge(documents, sourceIndex, destinationIndex, callback) {
	return function (dispatch) {
		let document = documents.get(sourceIndex);
		const documentOrder = documents.getIn([destinationIndex, 'orderIndexMergeDocuments'], 0.01) || 0.01;
		let prevDocOrder;

		// down
		if (sourceIndex < destinationIndex) {
			if (destinationIndex + 1 < documents.size) {
				const prevDoc = documents.getIn([destinationIndex + 1]);
				prevDocOrder = (destinationIndex > 0 && prevDoc.get('orderIndexMergeDocuments', 0)) || 0;
			} else {
				prevDocOrder = documentOrder + 100;
			}
		} else {
			// up
			const prevDoc = documents.getIn([destinationIndex - 1]);
			prevDocOrder = (destinationIndex > 0 && prevDoc.get('orderIndexMergeDocuments', 0)) || 0;
		}

		const orderIndex = (documentOrder + prevDocOrder) / 2;
		document = document.set('orderIndexMergeDocuments', orderIndex);

		// Add the updated document to the documents stack
		documents = documents.map((obj) => {
			if (obj.get('id') === document.get('id')) {
				return document;
			}

			return obj;
		});

		// Sort documents by order index
		documents = documents.sort((a, b) => {
			if (a.get('orderIndexMergeDocuments') === b.get('orderIndexMergeDocuments')) {
				return 0;
			} else {
				return a.get('orderIndexMergeDocuments') - b.get('orderIndexMergeDocuments');
			}
		});

		callback && callback(documents);

		const documentIdToOrderIndexMap = {};
		documents.forEach((doc) => {
			documentIdToOrderIndexMap[doc.get('id')] = doc.get('orderIndexMergeDocuments');
		});

		dispatch({ type: DOCUMENTS_FETCH_DOCUMENTS_TO_MERGE, payload: documents });

		req.post(`/documents/merge/reorder`, { documentsToReorder: documentIdToOrderIndexMap });
	};
}

/**
 * Add individuals to share the document with.
 * @param string the ID to share
 * @params object the individuals to share with
 */
export function updateIndividualsToShareWith({ documentId, meetingId, individuals }) {
	return function (dispatch) {
		const query = generateQuery({
			meetingId
		});

		return req
			.post(`/documents/documents/${documentId}/individuals/share${query}`, { individuals })
			.then((response) => {
				const patchData = fromJS(response.data.patchData);
				const { numOfShareholdersToShareWith, numOfAttendeesToShareWith, numOfOtherPersonsToShareWith } =
					response.data;

				dispatch(patchDocumentLocal(patchData));
				dispatch({
					type: DOCUMENTS_UPDATE_NUMBER_OF_SHARE_WITH,
					payload: { numOfShareholdersToShareWith, numOfAttendeesToShareWith, numOfOtherPersonsToShareWith }
				});
			})
			.catch(() => {
				documentsError('documents.unshare_document.error');
			});
	};
}

export function setFilterBy(val) {
	return {
		type: DOCUMENTS_SET_FILTERS,
		payload: val
	};
}

export function unsetFilterBy(sources) {
	return {
		type: DOCUMENTS_UNSET_FILTERS,
		payload: sources
	};
}

export function updateDefaultFilter(source, values) {
	return {
		type: DOCUMENTS_UPDATE_DEFAULT_FILTER,
		payload: Map({ source, values })
	};
}

export function resetDefaultFiltervalues() {
	return {
		type: DOCUMENTS_RESET_DEFAULT_VALUES
	};
}

export function documentsSetDefaultFilterBy(defaultFilters) {
	return function (dispatch) {
		let filters = Map();
		defaultFilters &&
			defaultFilters.forEach((val, key) => {
				const source = key.split('$')[1];
				dispatch(setFilterBy(fromJS({ source, values: [val] })));
				filters = filters.set(source, List([val]));
			});
		dispatch({
			type: DOCUMENTS_SET_DEFAULT_FILTERS,
			payload: filters
		});
	};
}

export function onSelectDocument(documentId) {
	return {
		type: DOCUMENTS_SELECT,
		payload: documentId
	};
}

export function updateDocumentInList(document) {
	return {
		type: DOCUMENTS_UPDATE_DOCUMENT_IN_LIST,
		payload: document
	};
}

/**
 * Action for dispatching an document error
 * @param {String} error — error message
 */
export function documentsError(error) {
	return addErrorNotification({
		tid: error
	});
}

export function socketEventDocuments(eventObj) {
	const { eventName, objId, metadata } = eventObj;

	return function (dispatch) {
		switch (eventName) {
			case EVENT_TYPE_DOCUMENT_CREATE:
			case EVENT_TYPE_DOCUMENT_UPDATE:
			case EVENT_TYPE_DOCUMENT_DELETE:
			case EVENT_TYPE_DOCUMENT_TRANSFER:
			case EVENT_TYPE_DOCUMENT_SIGN:
			case EVENT_TYPE_DOCUMENT_SIGNING_FINALIZED:
			case EVENT_TYPE_DOCUMENT_SHOW_ONB_DOCUMENT_UPLOADED_MODAL: {
				dispatch(setLiveRequest(['documents', eventName], { r: true, objId, metadata }));
				dispatch(resetLiveRequest(['documents', eventName]));
				break;
			}
		}
	};
}

export function fetchDocumentHelper(callback) {
	return function (dispatch) {
		return req.get('/documents/documents/helper').then((response) => {
			dispatch({
				type: DOCUMENTS_FETCH_HELPER,
				payload: fromJS(response.data)
			});
			callback && callback();
		});
	};
}
