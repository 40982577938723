import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
	PERSONAL_TRANSACTION_TYPE_LEVELING,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
	PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
	PERSONAL_TRANSACTION_TYPE_SPLIT,
	PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
	PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
	PERSONAL_TRANSACTION_TYPE_SNAPSHOT,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
	NUMBER_FORMAT_INTEGER,
	PERSONAL_TRANSACTION_TYPE_GENESIS
} from '/shared/constants'

const mapFieldAndTransactionTypeToTid = {
	transactionType: {
		default: 'own_ownership_registration.form_field.transaction_type'
	},
	date: {
		default: 'own_ownership_registration.form_field.date'
	},
	transferFrom: {
		default: 'own_ownership_registration.form_field.transfer_from'
	},
	transferTo: {
		default: 'own_ownership_registration.form_field.transfer_to'
	},
	conversionOfSharesNameFrom: {
		default: 'own_ownership_registration.form_field.conversion_of_shares_name_from'
	},
	conversionOfSharesClassFrom: {
		default: 'own_ownership_registration.form_field.conversion_of_shares_class_from'
	},
	conversionOfSharesNameTo: {
		default: 'own_ownership_registration.form_field.conversion_of_shares_name_to'
	},
	conversionOfSharesClassTo: {
		default: 'own_ownership_registration.form_field.conversion_of_shares_class_to'
	},
	splitQuotaFrom: {
		default: 'own_ownership_registration.form_field.split_quota_from'
	},
	splitQuotaTo: {
		default: 'own_ownership_registration.form_field.split_quota_to'
	},
	capitalInvested: {
		default: 'own_ownership_registration.form_field.capital_invested'
	},
	numOfTotalShares: {
		default: 'own_ownership_registration.form_field.number_of_total_shares'
	},
	'shareTypes.shareTypeName': {
		default: 'own_ownership_registration.form_field.share_type_name'
	},
	'shareTypes.shareTypeClass': {
		default: 'own_ownership_registration.form_field.share_type_class'
	},
	'shareTypes.totalSharesEmitted': {
		default: 'own_ownership_registration.form_field.total_shares_emitted',
		[PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE]: 'own_ownership_registration.form_field.total_shares_emitted.emission',
		[PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE]:
			'own_ownership_registration.form_field.total_shares_emitted.emission',
		[PERSONAL_TRANSACTION_TYPE_LEVELING]: 'own_ownership_registration.form_field.total_shares_emitted.emission',
		[PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES]:
			'own_ownership_registration.form_field.total_shares_emitted.emission',
		[PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS]:
			'own_ownership_registration.form_field.total_shares_emitted.emission',
		[PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE]: 'own_ownership_registration.form_field.total_shares_emitted.emission',
		[PERSONAL_TRANSACTION_TYPE_GENESIS]: 'own_ownership_registration.form_field.total_shares_emitted.emission'
	},
	'shareTypes.latestPrice': {
		default: 'own_ownership_registration.form_field.latest_price',
		[PERSONAL_TRANSACTION_TYPE_SPLIT]: 'own_ownership_registration.form_field.latest_price.split'
	},
	'shareTypes.votesPerShare': {
		default: 'own_ownership_registration.form_field.votes_per_share'
	},
	'shareTypes.myShares': {
		default: 'own_ownership_registration.form_field.my_shares',
		[PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES]:
			'own_ownership_registration.form_field.my_shares.conversion_of_shares',
		[PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL]:
			'own_ownership_registration.form_field.my_shares.reduction_of_shares',
		[PERSONAL_TRANSACTION_TYPE_GENESIS]: 'own_ownership_registration.form_field.my_shares.genesis',
		[PERSONAL_TRANSACTION_TYPE_SPLIT]: 'own_ownership_registration.form_field.my_shares.split',
		[PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY]: 'own_ownership_registration.form_field.my_shares.transfer_buy',
		[PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL]: 'own_ownership_registration.form_field.my_shares.transfer_sell'
	},
	'shareTypes.purchasePrice': {
		default: 'own_ownership_registration.form_field.total_purchase_price'
	},
	numOfTotalCompanyShares: {
		default: 'own_ownership_registration.form_field.num_of_total_company_shares'
	},
	totalVotes: {
		default: 'own_ownership_registration.form_field.company_total_votes'
	},
	myTotalVotes: {
		default: 'own_ownership_registration.form_field.my_total_votes'
	},
	companyTotalValue: {
		default: 'own_ownership_registration.form_field.company_total_value'
	},
	myTotalValue: {
		default: 'own_ownership_registration.form_field.my_total_value'
	}
}

export const useFieldTranslation = () => {
	const { t } = useTranslation()

	/**
	 *
	 * @param {string} fieldName
	 * @param {string} transactionType
	 * @param {string} fallback
	 * @returns {string}
	 */
	const translateFieldLabel = (fieldName, transactionType = '', fallback = '') => {
		if (!mapFieldAndTransactionTypeToTid[fieldName]) {
			return t(fallback)
		}

		return t(
			mapFieldAndTransactionTypeToTid[fieldName][transactionType] ??
				mapFieldAndTransactionTypeToTid[fieldName]['default']
		)
	}

	return { translateFieldLabel }
}
