import styled, { css } from 'styled-components'
import { BaseModalBackground } from 'styled-react-modal'
import { rgba } from 'polished'

const StyledModalBackground = styled(BaseModalBackground)`
	background-color: ${(props) => rgba(props.theme.colors.black, 0.5)};

	padding: ${(props) => {
		if (props.fullSize) {
			return `0`
		}
		return props.theme.spacing[5]
	}}};
	z-index: 10000;

	${(props) =>
		props.align === 'left' &&
		css`
			justify-content: flex-start;
		`}

	${(props) =>
		props.align === 'right' &&
		css`
			justify-content: flex-end;
		`}
`

export default StyledModalBackground
