import { fromJS } from 'immutable'
import req from '../modules/request.module'
import {
	PROJECTS_CREATE_REMOTE,
	PROJECTS_FETCH_REMOTE,
	PROJECTS_DELETE_REMOTE,
	PROJECTS_SAVE_REMOTE,
	PROJECTS_LIST,
	PROJECTS_UPDATE_LOCAL,
	PROJECTS_UPDATE_SELECTED_PROJECT_ID,
	PROJECTS_GET_DEFAULT_PERMISSIONS,
	PROJECTS_LIST_USER_TASKS
} from './types'
import { LIVE_PROJECT_CREATE, LIVE_PROJECT_UPDATE, LIVE_PROJECT_DELETE } from '../constants/live-update'
import { setLiveRequest, resetLiveRequest } from './live-update.actions'
import documentsHelper from '../components/helpers/documents.helper'

export function updateSelectedProjectId(id) {
	return {
		type: PROJECTS_UPDATE_SELECTED_PROJECT_ID,
		payload: id
	}
}
export function fetchDefaultPermissions(parentId, createProjectObject = true) {
	return function (dispatch) {
		return req.get(`/tasks/projects/default-permissions/${parentId ? parentId : 'root'}`).then((response) => {
			dispatch({
				type: PROJECTS_GET_DEFAULT_PERMISSIONS,
				payload: fromJS({ permissions: response.data, createProjectObject })
			})
		})
	}
}

export function fetchProject(id) {
	return function (dispatch) {
		return req.get(`/tasks/projects/${id}`).then((response) => {
			dispatch({ type: PROJECTS_FETCH_REMOTE, payload: fromJS(response.data) })
		})
	}
}

export function createProject(project, callback, errCallback) {
	return function (dispatch) {
		return req
			.post(`/tasks/projects`, project)
			.then((response) => {
				const newProject = fromJS(response.data)
				dispatch({ type: PROJECTS_CREATE_REMOTE, payload: newProject })
				callback && callback(newProject)
			})
			.catch(() => {
				errCallback && errCallback()
			})
	}
}

export function editProject(project, callback, errCallback) {
	return function (dispatch) {
		return req
			.put(`/tasks/projects/${project.get('id')}`, project.toJS())
			.then((response) => {
				dispatch({ type: PROJECTS_SAVE_REMOTE, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch(() => {
				errCallback && errCallback()
			})
	}
}

export function deleteProject(id, callback, errCallback) {
	return function (dispatch) {
		return req
			.delete(`/tasks/projects/${id}`)
			.then((response) => {
				dispatch({ type: PROJECTS_DELETE_REMOTE, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch(() => {
				errCallback && errCallback()
			})
	}
}

export function listProjects() {
	return function (dispatch) {
		return req.get(`/tasks/projects`).then((response) => {
			dispatch({ type: PROJECTS_LIST, payload: fromJS(response.data) })
		})
	}
}

export function listProjectNamesForUserTasks(data) {
	return function (dispatch) {
		return req.post('/tasks/projects/company-tasks-combo', data.toJS()).then((response) => {
			dispatch({ type: PROJECTS_LIST_USER_TASKS, payload: fromJS(response.data) })
		})
	}
}

export function updateProjectLocal(project) {
	return function (dispatch) {
		dispatch({ type: PROJECTS_UPDATE_LOCAL, payload: project })
	}
}

export function downloadProjectMatrix(openInViewer) {
	let w = null
	if (openInViewer) {
		w = window.open(`https://${window.location.hostname}/assets/build/misc/redirecting.html`, '_blank')
	}

	return function () {
		return req.get(`/tasks/projects/matrix`).then((response) => {
			let { downloadUrl, fileSize } = response.data
			if (openInViewer) {
				const { webViewerSupportedFormat, webViewerSupportedSize } = documentsHelper.getWebViewerIsSupported(
					undefined,
					'xlsx',
					fileSize
				)
				if (webViewerSupportedFormat && webViewerSupportedSize) {
					downloadUrl = encodeURIComponent(downloadUrl)
					const webViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${downloadUrl}`
					w.location = webViewerUrl
				} else {
					w.location = downloadUrl
				}
			} else {
				window.open(downloadUrl, '_self')
			}
		})
	}
}

export function socketEventProjects(eventObj) {
	const { eventName, objId, metadata } = eventObj

	return function (dispatch) {
		switch (eventName) {
			case LIVE_PROJECT_CREATE:
			case LIVE_PROJECT_UPDATE:
			case LIVE_PROJECT_DELETE: {
				dispatch(setLiveRequest(['projects', eventName], { refresh: true, objId, metadata }))
				dispatch(resetLiveRequest(['projects', eventName]))
				break
			}
			default:
				dispatch({ type: 'PROJECTS_NULL', payload: null })
		}
	}
}
