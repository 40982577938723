import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import styled from '@emotion/styled'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { InvestmentsContext } from '../../../containers/investments/investment.container'
import CompanyBoardGridContainer from '../../../containers/investments/general/company-board-grid.container'
import CasesGridContainer from '../../../containers/investments/general/cases-grid.container'
import CompanyPanel from '../../../dumb-components/investment/general/company-panel'
import RegisteredPanel from '../../../dumb-components/investment/general/RegisteredPanel'
import { useDispatch, useSelector } from 'react-redux'
import { updateAlias, deleteAlias } from '../../../actions/alias.actions'
import { FinancialStatementGrid } from './financial-statement/FinancialStatementGrid'
import { openModal } from '../../../actions/modals.actions'
import { CAP_TABLE_READ_MORE_MODAL, MODELS_RECOMMEND_INVONO } from '../../../constants/modals'
import { ReadMoreModal } from '../modals/read-more-modal'
import { RecommendModal } from '../../recommend/RecommendModal'

const StyledBox = styled(Box)(({ theme }) => ({
	marginBottom: theme.spacing(2)
}))

export default function InvestmentGeneralContainer() {
	const { t } = useTranslation()
	const {
		investedInCompanyInformation,
		permissions,
		selectedPersonalInvestment,
		selectedInvestment,
		isCompany,
		inDealflow,
		isShareholderPreview
	} = useContext(InvestmentsContext)
	const isCompanyInvestor = selectedInvestment ? true : false
	const isRegisteredInvonoCompany = selectedPersonalInvestment?.get('isRegisteredInvonoCompany')

	// selectedPersonalInvestment && console.log(selectedPersonalInvestment.toJS())

	const isGuest = permissions.get('isGuest')

	const {
		orgNumber,
		dateOfReg,
		companyOrigin,
		dateOfBoardReg,
		financialYear,
		historicalName,
		branchOfIndustry,
		companyType,
		shares,
		addresses
	} = investedInCompanyInformation.toJS()
	const { city, street, postalCode } = addresses[0]

	const dispatch = useDispatch()
	const name = useSelector(
		(state) =>
			state.capTableDetails.get('aliasOfTheInvestedCompany') ||
			state.capTableDetails.getIn([
				'combinedSingleInvestmentAndAllTransactions',
				'investedInCompanyInformation',
				'name'
			])
	)

	const onCancelEditingCompanyName = (value, { reason }) => {
		if (reason !== 'cancel') {
			return
		}

		dispatch(updateAlias(value, selectedPersonalInvestment.get('id')))
	}

	const onResetCompanyName = (setCompanyName) => {
		setCompanyName(investedInCompanyInformation.get('name'))
		dispatch(deleteAlias(selectedPersonalInvestment.get('id'), investedInCompanyInformation.get('name')))
	}

	const isReadMoreModalOpen = useSelector(
		(state) => state.modals.getIn(['activeModal', 'name']) === CAP_TABLE_READ_MORE_MODAL
	)

	const openReadMoreModal = () => {
		dispatch(openModal(CAP_TABLE_READ_MORE_MODAL))
	}

	const openRecommendationModal = () => {
		dispatch(openModal(MODELS_RECOMMEND_INVONO))
	}

	return (
		<Grid container spacing={2} pb={2}>
			<Grid item lg={isCompany || isShareholderPreview ? 12 : 9} md={12}>
				<CompanyPanel
					companyName={name}
					orgNumber={orgNumber}
					street={street}
					city={city}
					postalCode={postalCode}
					onCancelEditingCompanyName={onCancelEditingCompanyName}
					onResetCompanyName={onResetCompanyName}
				/>
			</Grid>
			{!isCompany && !isShareholderPreview && (
				<Grid item lg={3} md={12}>
					{/* <LinkedToShareRegisterPanel */}
					<RegisteredPanel
						investment={selectedPersonalInvestment}
						isCompanyInvestor={isCompanyInvestor}
						isRegistered={isRegisteredInvonoCompany}
					/>
				</Grid>
			)}

			<Grid item lg={3} md={6}>
				<Card variant='naked'>
					<CardContent>
						<StyledBox>
							<Typography variant='body2Italic'>{t('investments.general.panel.company_form')}</Typography>
							<Typography variant='body2'>
								{companyType || t('investments.general.panel.no_info')}
							</Typography>
						</StyledBox>
						<StyledBox>
							<Typography variant='body2Italic'>
								{t('investments.general.panel.registration_date')}
							</Typography>
							<Typography variant='body2'>
								{dateOfReg || t('investments.general.panel.no_info')}
							</Typography>
						</StyledBox>
						<StyledBox>
							<Typography variant='body2Italic'>{t('investments.general.panel.fiscal_year')}</Typography>
							<Typography variant='body2'>
								{financialYear || t('investments.general.panel.no_info')}
							</Typography>
						</StyledBox>
					</CardContent>
				</Card>
			</Grid>

			<Grid item lg={3} md={6}>
				<Card variant='naked'>
					<CardContent>
						<StyledBox>
							<Typography variant='body2Italic'>
								{t('investments.general.panel.historical_name.title')}
							</Typography>
							<Typography variant='body2'>
								{historicalName || t('investments.general.panel.no_info')}
							</Typography>
						</StyledBox>
						<StyledBox>
							<Typography variant='body2Italic'>
								{t('investments.general.panel.company_origin.title')}
							</Typography>
							<Typography variant='body2'>
								{companyOrigin || t('investments.general.panel.no_info')}?
							</Typography>
						</StyledBox>
						<StyledBox>
							<Typography variant='body2Italic'>
								{t('investments.general.panel.board_reg_date.title')}
							</Typography>
							<Typography variant='body2'>
								{dateOfBoardReg || t('investments.general.panel.no_info')}
							</Typography>
						</StyledBox>
					</CardContent>
				</Card>
			</Grid>

			<Grid item lg={3} md={6}>
				<Card variant='naked'>
					<CardContent>
						<StyledBox>
							<Typography variant='body2Italic'>
								{t('investments.general.panel.branch_of_industry.title')}
							</Typography>
							<Typography variant='body2'>
								{branchOfIndustry || t('investments.general.panel.no_info')}
							</Typography>
						</StyledBox>
					</CardContent>
				</Card>
			</Grid>

			{!isCompany && !isShareholderPreview && (
				<Grid item lg={3} md={6}>
					<Card variant='solitude' borderradius={'true'}>
						<CardContent>
							<StyledBox>
								{isRegisteredInvonoCompany ? (
									<>
										{!isCompanyInvestor ? (
											<>
												<Typography variant='body2' gutterBottom>
													<FormattedHTMLMessage id='investments.general.panel.solitude_panel.registered_but_not_linked' />
												</Typography>

												<Typography variant='body1'>
													<Link
														sx={{ pb: 0.3 }}
														onClick={openReadMoreModal}
														component={'button'}
														color={'positive.main'}>
														<FormattedMessage
															id={'investments.information_panel.link.read_more'}
														/>
													</Link>
												</Typography>
											</>
										) : (
											<>
												<Typography variant='body2Italic'>
													{t('investments.general.panel.share_capital')}
												</Typography>

												<Typography variant='h4'>
													{shares || t('investments.general.panel.no_info')}
												</Typography>
											</>
										)}
									</>
								) : (
									<>
										<Typography variant='body2' gutterBottom>
											<FormattedHTMLMessage
												id={
													inDealflow
														? 'investments.general.dealflow_preview.panel.solitude_panel.not_registered'
														: 'investments.general.panel.solitude_panel.not_registered'
												}
											/>
										</Typography>
										<Typography variant='body1'>
											<Link
												sx={{ pb: 0.3 }}
												onClick={openRecommendationModal}
												component={'button'}
												color={'positive.main'}>
												<FormattedMessage
													id={'investments.general.panel.solitude_panel.recommend'}
												/>
											</Link>
										</Typography>
									</>
								)}
							</StyledBox>
						</CardContent>
					</Card>
				</Grid>
			)}

			<FinancialStatementGrid orgNumber={orgNumber} />

			<Grid item xs={12}>
				<CompanyBoardGridContainer companyName={name} />
			</Grid>

			<Grid item xs={12}>
				<CasesGridContainer companyName={name} />
			</Grid>
			<RecommendModal />

			{isReadMoreModalOpen && (
				<ReadMoreModal
					title={'investments.general.share_register_link_information.title'}
					contentFile='linked-to-share-register.txt'
				/>
			)}
		</Grid>
	)
}
