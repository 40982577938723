import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../../../actions/modals.actions'
import { MY_RECORDS_CONFIGURE_PORTFOLIO_TABLE_DATA_MODAL } from '../../../../constants/modals'
import MUIDialog from '../../../../mui-components/dialog/mui-dialog'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import { TextField } from 'mui-rff'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import { Condition } from '../../../../dumb-components/shared/final-form/Condition'
import { InvestmentsContext } from '../../investment.container'
import {
	INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE,
	INVESTOR_TYPE_OF_OWNER_PRIVATE,
	INVESTOR_TYPE_OF_OWNER_COMPANY
} from '/shared/constants'
import { FormattedHTMLMessage } from 'react-intl'
import { Form } from 'react-final-form'
import { FormGroup, FormGroupContainer } from '../../../../mui-components/form-block/FromGroup'
import {
	updateInvestmentConfiguration,
	getIndirectPersonalInvestmentsWithOrgNrsLimited
} from '../../../../actions/my-records.actions'
import { Map } from 'immutable'
import NumberFormatPercentage from '../../../../dumb-components/shared/format-number-input/number-format-percentage'
import { FormattedMessage } from 'react-intl'

const StyledBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	/* width: '100%',
	height: '200px',
	background: theme.palette.highlight.yellow.main,
	padding: theme.spacing(4), */
	gap: theme.spacing(2)
}))

export default function ConfigurePortfolioTableDataModal() {
	const [existingPersonalInvestTypesIndirect, setExistingPersonalInvestTypesIndirect] = useState([])
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { selectedPersonalInvestment } = useContext(InvestmentsContext)
	const kindOfInvestment = selectedPersonalInvestment.getIn(['personalInvestmentType', 'type'])
	const initialValues = {
		showInPortfolio: selectedPersonalInvestment.get('showInPortfolio'),
		personalInvestmentType: selectedPersonalInvestment.get('personalInvestmentType').toJS()
	}

	const isOpen = useSelector(
		(state) => state.modals.getIn(['activeModal', 'name']) === MY_RECORDS_CONFIGURE_PORTFOLIO_TABLE_DATA_MODAL
	)

	const onClose = () => dispatch(closeModal())

	//  FINAL FORM
	const onSubmit = (values) => {
		dispatch(
			updateInvestmentConfiguration(selectedPersonalInvestment.get('id'), values, (error) => {
				if (error) {
					return
				}

				onClose()
			})
		)
	}

	useEffect(() => {
		const orgNumber = selectedPersonalInvestment.get('orgNumber')
		const viaOrgNumber = selectedPersonalInvestment.getIn(['personalInvestmentType', 'viaCompanyOrgNumber'])
		//viaOrgNumber checks for inidirect ownership. State is only relevant in this case.
		if (orgNumber && viaOrgNumber) {
			const selectedInvestmentId = selectedPersonalInvestment.get('id')
			dispatch(
				getIndirectPersonalInvestmentsWithOrgNrsLimited(
					orgNumber,
					viaOrgNumber,
					selectedInvestmentId,
					(response) => {
						setExistingPersonalInvestTypesIndirect(response)
					}
				)
			)
		}
	}, [])

	const validate = (values) => {
		let errors = Map()
		if (
			values.personalInvestmentType.ownershipPercentage &&
			parseInt(values.personalInvestmentType.ownershipPercentage) > 100
		) {
			errors = errors.setIn(
				['personalInvestmentType', 'ownershipPercentage'],
				t('investments.my_records.configure_my_portfolio_table_data.errors.ownship_percentage_gt_100')
			)
		}

		if (values.personalInvestmentType.viaInvestmentType === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE) {
			const insuranceNr = values.personalInvestmentType.insuranceNr
			const duplicate = existingPersonalInvestTypesIndirect.some((type) => {
				return type.insuranceNr === insuranceNr
			})
			if (duplicate) {
				errors = errors.setIn(
					['personalInvestmentType', 'insuranceNr'],
					t('investments.my_records.configure_my_portfolio_table_data.modal.error_insurance_nr')
				)
			}
		}

		return errors.toJS()
	}

	const companyName = selectedPersonalInvestment.getIn(['investedInCompanyInformation', 'viaCompanyName'])
	const companyName2 = selectedPersonalInvestment.getIn(['investedInCompanyInformation', 'name'])

	const privateDisabled = existingPersonalInvestTypesIndirect.find((personalInvestmentType) => {
		return personalInvestmentType.viaInvestmentType === INVESTOR_TYPE_OF_OWNER_PRIVATE
	})
		? true
		: false

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={initialValues}
			validate={validate}
			keepDirtyOnReinitialize
			render={(formProps) => {
				const { handleSubmit, submitting, pristine, errors, invalid, values, form } = formProps
				const submittingDisabled = submitting || pristine || invalid
				return (
					<MUIDialog
						isOpen={isOpen}
						onClose={onClose}
						title={t('investments.my_records.configure_my_portfolio_table_data.modal.title')}
						onConfirm={handleSubmit}
						confirmButtonTid={'dialog.generic.save'}
						closeButtonTid='generic.form.close'
						maxWidth='lg'
						fullWidth>
						<FormGroupContainer>
							<Fade in timeout={650}>
								<FormGroup>
									<TextField
										select
										name='showInPortfolio'
										label={t(
											'investments.my_records.configure_my_portfolio_table_data.modal.inputfield.my_portfolio_visibility'
										)}>
										<MenuItem value={false}>
											<FormattedMessage
												id={
													'investments.my_records.configure_my_portfolio_table_data.modal.menu_item.dont_use_my_records_data'
												}
												values={{
													doNot: (
														<Typography
															sx={{ display: 'contents' }}
															component={'span'}
															color={'error.main'}>
															{t('generic.do_not')}
														</Typography>
													),
													ownRecords: (
														<Typography
															variant={'body1'}
															sx={{ display: 'contents' }}
															component={'span'}
															fontWeight={800}>
															{t('investments.app_section.generic.my_records')}
														</Typography>
													),
													myPortfolio: (
														<Typography
															variant={'body1'}
															sx={{ display: 'contents' }}
															component={'span'}
															fontWeight={800}>
															{t('investments.app_section.generic.my_portfolio')}
														</Typography>
													)
												}}
											/>
										</MenuItem>
										<MenuItem value={true}>
											<FormattedMessage
												id={
													'investments.my_records.configure_my_portfolio_table_data.modal.menu_item.use_my_records_data'
												}
												values={{
													ownRecords: (
														<Typography
															variant={'body1'}
															sx={{ display: 'contents' }}
															component={'span'}
															fontWeight={800}>
															{t('investments.app_section.generic.my_records')}
														</Typography>
													),
													myPortfolio: (
														<Typography
															variant={'body1'}
															sx={{ display: 'contents' }}
															component={'span'}
															fontWeight={800}>
															{t('investments.app_section.generic.my_portfolio')}
														</Typography>
													)
												}}
											/>
										</MenuItem>
									</TextField>
									<Typography variant='body2'>
										<FormattedHTMLMessage id='investments.my_records.configure_my_portfolio_table_data.modal.paragraph1.1' />
									</Typography>
									<Typography variant='body2'>
										{t(
											'investments.my_records.configure_my_portfolio_table_data.modal.paragraph1.2'
										)}
									</Typography>
									<Typography variant='body2'>
										<FormattedHTMLMessage id='investments.my_records.configure_my_portfolio_table_data.modal.paragraph1.3' />
									</Typography>
									<Typography variant='body2'>
										{t(
											'investments.my_records.configure_my_portfolio_table_data.modal.paragraph1.4'
										)}
									</Typography>
								</FormGroup>
							</Fade>

							{kindOfInvestment === INVESTOR_TYPE_OF_OWNER_COMPANY && (
								<Fade in timeout={650}>
									<FormGroup>
										<TextField
											select
											name='personalInvestmentType.viaInvestmentType'
											label={t(
												'investments.my_records.configure_my_portfolio_table_data.modal.inputfield.type_of_ownership'
											)}>
											<MenuItem value={INVESTOR_TYPE_OF_OWNER_PRIVATE} disabled={privateDisabled}>
												{t(
													'investments.my_records.configure_my_portfolio_table_data.modal.menu_item.private'
												)}
											</MenuItem>
											<MenuItem value={INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE}>
												{t(
													'investments.my_records.configure_my_portfolio_table_data.modal.menu_item.insurance'
												)}
											</MenuItem>
										</TextField>

										{!values.personalInvestmentType.viaInvestmentType && (
											<Typography variant='body2'>
												{t(
													'investments.my_records.configure_my_portfolio_table_data.modal.paragraph2',
													{
														companyName,
														companyName2
													}
												)}
											</Typography>
										)}

										<Condition
											when='personalInvestmentType.viaInvestmentType'
											is={INVESTOR_TYPE_OF_OWNER_PRIVATE}>
											<Fade in timeout={650}>
												<StyledBox>
													<TextField
														name='personalInvestmentType.ownershipPercentage'
														label={t(
															'investments.my_records.configure_my_portfolio_table_data.modal.inputfield.your_ownership_percentage'
														)}
														InputProps={{ inputComponent: NumberFormatPercentage }}
													/>
													<Typography variant='body2'>
														{t(
															'investments.my_records.configure_my_portfolio_table_data.modal.paragraph3',
															{
																companyName,
																companyName2
															}
														)}
													</Typography>
												</StyledBox>
											</Fade>
										</Condition>

										<Condition
											when='personalInvestmentType.viaInvestmentType'
											is={INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE}>
											<Fade in timeout={650}>
												<StyledBox>
													<TextField
														name='personalInvestmentType.insuranceNr'
														label={t(
															'investments.my_records.configure_my_portfolio_table_data.modal.inputfield.certificate_reference'
														)}
													/>
													<Typography variant='body2'>
														{t(
															'investments.my_records.configure_my_portfolio_table_data.modal.paragraph4'
														)}
													</Typography>
													<TextField
														name='personalInvestmentType.ownershipPercentage'
														label={t(
															'investments.my_records.configure_my_portfolio_table_data.modal.inputfield.your_ownership_percentage'
														)}
														InputProps={{ inputComponent: NumberFormatPercentage }}
													/>
													<Typography variant='body2'>
														{t(
															'investments.my_records.configure_my_portfolio_table_data.modal.paragraph5',
															{
																companyName,
																companyName2
															}
														)}
													</Typography>
												</StyledBox>
											</Fade>
										</Condition>
									</FormGroup>
								</Fade>
							)}
						</FormGroupContainer>
					</MUIDialog>
				)
			}}
		/>
	)
}
