import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, string, array, oneOfType, bool, oneOf, object } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import { Margin } from 'styled-components-spacing'
import moment from '../../modules/moment.module'
import { createDocument } from '../../actions/documents.actions'
import { createAttachedDocument } from '../../actions/attachments.actions'
import Dropzone from '../../dumb-components/shared/dropzone/dropzone'

class DropzoneContainer extends Component {
	static propTypes = {
		fieldName: string.isRequired,
		multiple: bool,
		accept: oneOfType([string, array]),
		readOnly: bool,
		onUpload: func.isRequired,
		transparent: bool,
		objId: string,
		objType: string,
		mode: oneOf(['attachment', 'normal']),
		uploadFormDataProps: object
	}

	static defaultProps = {
		mode: 'normal'
	}

	state = {
		isUploading: false
	}

	upload = (filesToUpload) => {
		const {
			createDocument,
			createAttachedDocument,
			onUpload,
			fieldName,
			objId,
			objType,
			mode,
			uploadFormDataProps
		} = this.props

		this.setState({ isUploading: true })

		filesToUpload.forEach((file) => {
			const document = new FormData()
			const props = {
				file,
				lastModifiedDate: moment(file.lastModified).toISOString(),
				title: file.name,
				hideFromDocumentsSection: true,
				...uploadFormDataProps
			}

			if (objId && objType) {
				props.link = JSON.stringify([{ objId, objType }])
			}

			Object.keys(props).forEach((key) => {
				if (Array.isArray(props[key])) {
					for (let i = 0; i < props[key].length; i++) {
						document.append(key, props[key][i])
					}
				} else {
					document.append(key, props[key])
				}
			})

			if (mode === 'attachment') {
				createAttachedDocument(objType, objId, document, (doc) => {
					if (onUpload && fieldName) {
						onUpload(fieldName, doc.get('id'))
					} else {
						onUpload(doc.get('id'))
					}

					this.setState({ isUploading: false })
				})
			} else {
				createDocument(document, null, (doc) => {
					if (onUpload && fieldName) {
						onUpload(fieldName, doc.get('id'))
					} else {
						onUpload(doc.get('id'))
					}

					this.setState({ isUploading: false })
				})
			}
		})
	}

	render = () => {
		const { fieldName, multiple, transparent, accept, readOnly } = this.props
		const { isUploading } = this.state

		return (
			<Margin bottom={3}>
				<Dropzone
					size='small'
					name={fieldName}
					multiple={multiple}
					transparent={transparent ? 'true' : 'false'}
					accept={accept}
					readOnly={readOnly}
					onDrop={this.upload}
					isUploading={isUploading}
				/>
			</Margin>
		)
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {
	createDocument,
	createAttachedDocument
}

export default connect(mapStoreToProps, mapActionsToProps)(DropzoneContainer)
