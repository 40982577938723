import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionSummary'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const FormAccordionEntry = (props) => {
	const [expanded, setExpanded] = useState(true)
	const { disableClose } = props

	return (
		<Accordion
			expanded={expanded}
			disableGutters
			elevation={0}
			sx={{
				pt: disableClose ? 0 : 4,
				'&::before': {
					bgcolor: 'transparent'
				}
			}}
			onChange={(_, exp) => !disableClose && setExpanded(exp)}>
			<AccordionSummary
				expandIcon={
					disableClose ? (
						<></>
					) : (
						<KeyboardArrowDownIcon
							sx={{
								color: 'secondary.main'
							}}
						/>
					)
				}
				sx={{
					alignItems: 'center',
					backgroundColor: 'secondary.50',
					minHeight: '32px',
					maxHeight: '32px',
					borderRadius: '4px',
					'&Mui-expanded': {
						minHeight: '32px',
						maxHeight: '32px'
					},
					cursor: disableClose ? 'default !important' : 'pointer'
				}}>
				<Typography sx={{ textTransform: 'uppercase' }} variant={'overline'}>
					{props.header}
				</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					backgroundColor: '#fff !important',
					pt: 0.5,
					'&.MuiButtonBase-root:hover': {
						cursor: 'default'
					}
				}}>
				{props.children}
			</AccordionDetails>
		</Accordion>
	)
}

export default FormAccordionEntry
