import {
	NOTIFY_MENU_COLLAPSED,
	NOTIFY_REMOVE_MESSAGE,
	NOTIFY_CLEAR_MESSAGES,
	NOTIFY_ASIDE_EXPANDED,
	NOTIFY_ADD_MESSAGE,
	NOTIFY_FILE_ENTERED_GLOBAL,
	NOTIFY_BANKID,
	NOTIFY_SOCKET_ID,
	NOTIFY_HIDE_APP_MENUS,
	NOTIFY_SHOW_APP_MENUS,
	NOTIFY_DEMO_LOADING,
	NOTIFY_ATTACHMENT_UPLOADING,
	NOTIFY_FETCHING_ATTACHMENTS,
	NOTIFY_SET_ACTIVE_TAB,
	NOTIFY_COMPONENT_LOADING,
	NOTIFY_IS_UPLOADING_EXCEL,
	NOTIFY_RETURN_URL
} from '../actions/types'

/**
 * Action for toggling the main menu.
 */
export function toggleMenu(currentMenuState) {
	return { type: NOTIFY_MENU_COLLAPSED, payload: !currentMenuState }
}

/**
 * Action for toggling the aside panel.
 */
export function toggleAside(currentAsideState) {
	return { type: NOTIFY_ASIDE_EXPANDED, payload: !currentAsideState }
}

export function closeAside() {
	return { type: NOTIFY_ASIDE_EXPANDED, payload: false }
}

/**
 * Action for removing an notification.
 */
export function removeNotification(index) {
	return { type: NOTIFY_REMOVE_MESSAGE, payload: index }
}

/**
 * Action for clearing all notifications.
 */
export function clearNotifications(index) {
	return { type: NOTIFY_CLEAR_MESSAGES }
}

/**
 * Action for adding error notification
 * @param {Object} message - the message to be displayed for the user
 * @param {string} message.tid - translation ID of the message to display
 */
export function addErrorNotification(message) {
	return function (dispatch) {
		message.type = 'ERROR'
		return dispatch({ type: NOTIFY_ADD_MESSAGE, payload: message })
	}
}

/**
 * Action for adding information notification
 * @param {Object} message - the message to be displayed for the user
 * @param {string} message.tid - translation ID of the message to display
 */
export function addInfoNotification(message) {
	return function (dispatch) {
		message.type = 'INFO'
		return dispatch({ type: NOTIFY_ADD_MESSAGE, payload: message })
	}
}

/**
 * Changes state when a file is dragged over the website
 */
export function changeFileEnteredGlobal(bool) {
	return { type: NOTIFY_FILE_ENTERED_GLOBAL, payload: bool }
}

/**
 * Notify about changes in bankID
 */
export function notifyBankId(msg) {
	return { type: NOTIFY_BANKID, payload: msg }
}

/**
 * Notify about socket ID
 */
export function notifySocketId(id) {
	return function (dispatch, getState) {
		const userId = getState().user.getIn(['userObj', 'id'])

		if (userId) {
			dispatch({
				type: 'updateSocketId',
				payload: { userId },
				isSocketAction: true
			})
		}
		dispatch({ type: NOTIFY_SOCKET_ID, payload: id })
		return
	}
}

/**
 * Hide application menus
 */
export function hideAppMenus() {
	return { type: NOTIFY_HIDE_APP_MENUS }
}

/**
 * Show application menus
 */
export function showAppMenus() {
	return { type: NOTIFY_SHOW_APP_MENUS }
}

export function notifyReturnUrl(payload) {
	return { type: NOTIFY_RETURN_URL, payload }
}

export function notifyDemoIsLoading(bool) {
	return function (dispatch) {
		dispatch({ type: NOTIFY_DEMO_LOADING, payload: bool })
	}
}

export function notifyAttachmentIsUploading(uploading) {
	return function (dispatch) {
		dispatch({ type: NOTIFY_ATTACHMENT_UPLOADING, payload: uploading })
	}
}

export function notifyFetchingAttachments(bool) {
	return function (dispatch) {
		dispatch({ type: NOTIFY_FETCHING_ATTACHMENTS, payload: bool })
	}
}

export function setComponentLoading(componentName, isLoading) {
	return function (dispatch) {
		dispatch({ type: NOTIFY_COMPONENT_LOADING, payload: { componentName, isLoading } })
	}
}

export function setActiveTab(tabName) {
	return function (dispatch) {
		// Tricky shit! First dispatch forces the container to which store.notify.activeTab is bound to update.
		// The second dispatch resets the store to its original value. We need some delay here so the component has the time to rerender.
		dispatch({ type: NOTIFY_SET_ACTIVE_TAB, payload: tabName })
		setTimeout(() => {
			dispatch({ type: NOTIFY_SET_ACTIVE_TAB, payload: null })
		}, 100)
	}
}

export function notifyIsUploadingExcel(isUploadingExcel) {
	return {
		type: NOTIFY_IS_UPLOADING_EXCEL,
		payload: isUploadingExcel
	}
}
