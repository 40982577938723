import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { bool, string, oneOf, func, object } from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import ScrollView from '../layout/scroll-view/scroll-view'

const StyledMenu = styled.ul`
	position: ${(props) => props.position};
	${(props) => (props.direction === 'up' ? 'bottom' : 'top')} : 100%;
	${(props) => props.halignMenu}: 0;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	min-width: 180px;
	width: max-content;
	max-width: ${(props) => (props.noMaxWidth ? 'unset' : '250px')};
	list-style: none;
	background-color: ${(props) => props.theme.colors.white};
	background-clip: padding-box;
	margin: 0;
	box-shadow: 0 5px 12px 2px ${(props) => rgba(props.theme.colors.black, 0.25)};
	border: none;
	border-radius: 0;
	padding: ${(props) => props.theme.spacing[3]} 0;

	/* Visibility instead of display: none so that width/height
	 * can be calculated upon first setRef call
	 */
	${(props) =>
		(props.withPortal || props.alwaysRenderDropdownItems) &&
		css`
			visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
		`}

	${(props) =>
		props.noDropdownPaddingTop &&
		css`
			padding-top: 0;
		`}

	${(props) =>
		props.minWidth &&
		css`
			min-width: ${props.minWidth};
		`}

	${(props) =>
		props.menuNoTop &&
		css`
			top: unset;
		`}

	${(props) =>
		props.clientRects &&
		props.parentRects &&
		props.halignMenu === 'right' &&
		css`
			top: ${props.parentRects.top + props.parentRects.height}px;
			left: ${props.parentRects.left - props.clientRects.width + props.parentRects.width}px;
		`}

	${(props) =>
		props.parentRects &&
		props.halignMenu === 'left' &&
		css`
			top: ${props.parentRects.top + props.parentRects.height}px;
			left: ${props.parentRects.left}px;
		`}
`

export default class DropdownMenuMenu extends PureComponent {
	static propTypes = {
		scrollbars: bool,
		isOpen: bool,
		halignMenu: oneOf(['left', 'right']),
		direction: oneOf(['down', 'up']),
		minWidth: string,
		menuNoTop: bool,
		noMaxWidth: bool,
		scrollbarAlwaysVisible: bool,
		onSetRef: func,
		noDropdownPaddingTop: bool,
		clientRects: object, // rects of THIS component
		parentRects: object, // rects of parent component eg. dropdown icon button
		position: string,
		alwaysRenderDropdownItems: bool,
		withPortal: bool
	}

	static defaultProps = {
		isOpen: false,
		halignMenu: 'left',
		scrollbars: false,
		direction: 'down',
		menuNoTop: false,
		noMaxWidth: false,
		position: 'absolute'
	}

	renderComponent = () => {
		const {
			children,
			scrollbars,
			isOpen,
			halignMenu,
			direction,
			minWidth,
			menuNoTop,
			noMaxWidth,
			scrollbarAlwaysVisible,
			noDropdownPaddingTop,
			onSetRef,
			clientRects,
			parentRects,
			position,
			alwaysRenderDropdownItems,
			withPortal
		} = this.props

		return (
			<StyledMenu
				isOpen={isOpen}
				halignMenu={halignMenu}
				direction={direction}
				minWidth={minWidth}
				menuNoTop={menuNoTop}
				noMaxWidth={noMaxWidth}
				noDropdownPaddingTop={noDropdownPaddingTop}
				ref={onSetRef && onSetRef}
				clientRects={clientRects}
				parentRects={parentRects}
				position={position}
				alwaysRenderDropdownItems={alwaysRenderDropdownItems}
				withPortal={withPortal}>
				{scrollbars ? (
					<ScrollView maxHeight={500} noRightMargin noLeftMargin alwaysShowOnOverflow={scrollbarAlwaysVisible}>
						{children}
					</ScrollView>
				) : (
					children
				)}
			</StyledMenu>
		)
	}

	render = () => {
		const { clientRects, parentRects, halignMenu, isOpen, withPortal, alwaysRenderDropdownItems } = this.props

		if (!isOpen && !withPortal && !alwaysRenderDropdownItems) {
			return null
		}

		// parentRects are not needed when aligning left
		if (!clientRects || (!parentRects && halignMenu !== 'left')) {
			return this.renderComponent()
		} else {
			return ReactDOM.createPortal(this.renderComponent(), document.body)
		}
	}
}
