import React, { Component } from 'react'
import { connect } from 'react-redux'
import { number } from 'prop-types'
import { Map } from 'immutable'

import Tooltip from '../../../../dumb-components/shared/tooltip/tooltip'
import DropdownIconItem from '../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import DropdownMenuContainer from '../../../shared/dropdown-menu.container'
import { OutlinedButton } from '../../../../dumb-components/shared/button-v2'
import AddAttendeeContainer from '../../meeting/general/dropdown-items/add-attendee.container'
import ManageModeratorsContainer from '../../meeting/general/dropdown-items/manage-moderators.container'

import { MEETING_TYPE_STANDARD } from '/shared/constants'

class AttendeePanelControlsContainer extends Component {
	state = {
		dropdownTooltipData: Map()
	}

	static propTypes = {
		marginBottom: number
	}

	dropdownRef = null

	toggleDropdown = () => {
		this.dropdownRef.onToggleMenu()
	}

	dropdownTooltipDataChanged = (dropdownTooltipData) => {
		this.setState({ dropdownTooltipData })
	}

	render = () => {
		const { noAttendees, marginBottom, isStandardMeeting } = this.props
		const { dropdownTooltipData } = this.state

		const activeState = dropdownTooltipData.get('activeState')
		const disabled = dropdownTooltipData.get('disabled')
		const dropdownTooltipTid = noAttendees ? 'meetings.attendees.panel.controls.tooltip.no_attendees' : undefined

		return (
			<>
				<AddAttendeeContainer
					renderComponent={({ openAttendeesModal, TOOLTIP_STATES, activeState, disabled }) => (
						<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
							<OutlinedButton disabled={disabled} icon='faPlus' onClick={openAttendeesModal} />
						</Tooltip>
					)}
				/>

				<DropdownMenuContainer
					halignMenu='right'
					ref={(ref) => (this.dropdownRef = ref)}
					onClick={this.toggleDropdown}
					disabled={noAttendees || disabled}
					withPortal
					renderRaw={
						<Tooltip
							active={noAttendees || disabled}
							activeState={activeState}
							tid={dropdownTooltipTid}
							delayShow='instant'>
							<OutlinedButton icon='faEllipsisV' disabled={noAttendees || disabled} leftMargin />
						</Tooltip>
					}>
					<AddAttendeeContainer
						onTooltipDataChanged={this.dropdownTooltipDataChanged}
						renderComponent={({ openAttendeesModal }) => (
							<DropdownIconItem
								icon='faCog'
								tid='meetings.attendees.panel.controls.dropdown.edit_attendees'
								onClick={() => {
									openAttendeesModal()
									this.toggleDropdown()
								}}
							/>
						)}
					/>

					{isStandardMeeting && <ManageModeratorsContainer toggleDropdown={this.toggleDropdown} />}
				</DropdownMenuContainer>
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		noAttendees: !store.meetings.getIn(['meeting', 'attendees'], Map()).size,
		isStandardMeeting: store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_STANDARD
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(AttendeePanelControlsContainer)
