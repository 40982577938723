import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'

import RightColumnOnlyLayout, { Entry } from '../sub-modules/right-column-only-layout'
import Button from '../../../shared/button/button'
import Text from '../../../shared/text/text'
import Tooltip from '../../../shared/tooltip/tooltip'

import {
	RESOURCE_OWNERSHIP,
	RESOURCE_SHARES_SETUP,
	RESOURCE_MEETINGS,
	RESOURCE_DOCUMENTS,
	RESOURCE_TASKS
} from '/shared/constants'

const MeetingsContent = styled.div`
	display: flex;
	flex-direction: column;
`

export default class CompanyDashboardIntro extends PureComponent {
	static propTypes = {
		onGoToSection: func,
		onGetResource: func
	}

	renderGoToButton = (section) => {
		const { onGoToSection, onGetResource } = this.props
		let resource = onGetResource(section)

		// Special code for ownership/sharesetup.
		// Might have access to shares but they might
		// not be set up as of yet
		if (!resource && section === RESOURCE_SHARES_SETUP) {
			resource = onGetResource(RESOURCE_OWNERSHIP)
		}

		return (
			<Tooltip activeState='hasNoPermissions' active={!resource}>
				<Button tid='generic.open' onClick={onGoToSection.bind(null, resource.url)} disabled={!resource} />
			</Tooltip>
		)
	}

	render = () => {
		return (
			<RightColumnOnlyLayout>
				<Entry
					titleTid='onboarding.modal.company.dashboard.intro_e1_title'
					subTextTid='onboarding.modal.company.dashboard.intro_e1_subtext'
					bottomComponent={this.renderGoToButton(RESOURCE_SHARES_SETUP)}
				/>

				<Entry
					titleTid='onboarding.modal.company.dashboard.intro_e2_title'
					bottomComponent={this.renderGoToButton(RESOURCE_MEETINGS)}>
					<MeetingsContent>
						<Text tid='onboarding.modal.company.dashboard.standard_meeting' bold={600} />
						<Text tid='onboarding.modal.company.dashboard.content_1' marginBottom={3} />

						<Text tid='onboarding.modal.company.dashboard.smart_meeting' bold={600} />
						<Text tid='onboarding.modal.company.dashboard.content_2' marginBottom={3} />

						<Text tid='onboarding.modal.company.dashboard.content_3' />
					</MeetingsContent>
				</Entry>

				<Entry
					titleTid='onboarding.modal.company.dashboard.title_2'
					bottomComponent={this.renderGoToButton(RESOURCE_DOCUMENTS)}>
					<Text tid='onboarding.modal.company.dashboard.content_5' />
					<Text tid='onboarding.modal.company.dashboard.content_5' />
				</Entry>

				<Entry
					titleTid='onboarding.modal.company.dashboard.title_3'
					subTextTid='onboarding.modal.company.dashboard.content_6'
					bottomComponent={this.renderGoToButton(RESOURCE_TASKS)}
				/>
			</RightColumnOnlyLayout>
		)
	}
}
