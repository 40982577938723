import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { fromJS, Map, List } from 'immutable'
import { v1 } from 'uuid'
import sharesValidator from '../../../helpers/shares.validator'
import { addErrorNotification, addInfoNotification } from '../../../../actions/notify.actions'
import { listInvestors } from '../../../../actions/investors.actions'
import SelectCreatable from '../../../../dumb-components/fields/select-creatable'
import req from '../../../../modules/request.module'
import { Scrollbars } from 'react-custom-scrollbars'
import { formatSsn, formatOrg } from '../../../helpers/users'
import { validateSsn, validateOrg, validateCompanyId, validateUserId } from '../../../../modules/field-validators'
import ShareholderOptionRenderer from './shareholder-options-renderer'

class AddShareholders extends Component {
	step = 2
	state = {
		investors: Map({}),
		investorsOptions: [],
		idInputAutofocus: false
	}

	onChange(transaction) {
		this.props.onChange(transaction)
	}

	componentDidMount() {
		this.props.listInvestors(true)
	}

	componentDidUpdate(prevProps) {
		if (this.props.investors !== prevProps.investors) {
			this.parseInvestors(this.props.investors)
		}
		if (this.props.transaction !== prevProps.transaction) {
			this.parseInvestors(this.props.investors)
		}
	}

	parseInvestors(newInvestors) {
		let { investors } = this.state
		const { transaction } = this.props
		const investorsOptions = []

		newInvestors &&
			newInvestors.forEach((investor) => {
				const id = investor.get('id')
				investors = investors.set(id, investor)
			})

		investors.forEach((investor) => {
			const id = investor.get('id')
			const isCapitalInsurance = investor.get('investorTypeOfOwner') === 'capitalInsurance'
			let investorIdOrInsuranceNr = investor.getIn(['investorInformation', 'id'])
			if (isCapitalInsurance) {
				investorIdOrInsuranceNr = investor.getIn(['investorInformation', 'insuranceNr'], '')
			}
			if (!transaction.hasIn(['investors', id])) {
				investorsOptions.push({
					value: id,
					label: `${investor.getIn(['investorInformation', 'name'])}[split-here]${investorIdOrInsuranceNr}`
				})
			}
		})

		this.setState({ investors, investorsOptions })
	}

	goToNext() {
		const investors = this.props.transaction.get('investors')

		try {
			sharesValidator.validateInvestors(investors)
			this.props.onNext && this.props.onNext(this.step + 1)
			this.props.jumpToStep(this.step + 1)
		} catch (e) {
			this.props.addErrorNotification({
				text: e.message
			})
		}
	}

	goToPrevious() {
		this.props.onPrevious(this.step - 1)
		this.props.jumpToStep(this.step - 1)
	}

	getEmptyInvestor() {
		return fromJS({
			investorTypeOfOwner: null,
			investorInformation: {
				name: '',
				id: ''
			},
			id: null
		})
	}

	getOwnerTypes() {
		const { i18n } = this.props
		return [
			{ value: 'private', label: i18n.messages['shares.type_of_owner.private'] },
			{ value: 'company', label: i18n.messages['shares.type_of_owner.company'] },
			{ value: 'foreign', label: i18n.messages['shares.type_of_owner.foreign'] }
		]
	}

	deleteRow(id) {
		const { onChange } = this.props
		let { transaction } = this.props
		const investor = transaction.getIn(['investors', id])
		const investorHasId = investor.get('id') ? true : false
		transaction = transaction.removeIn(['investors', id])

		if (investorHasId) {
			let hiddenInvestors = transaction.get('hiddenInvestors')

			if (!hiddenInvestors) {
				hiddenInvestors = Map()
			}

			hiddenInvestors = hiddenInvestors.set(id, true)
			transaction = transaction.set('hiddenInvestors', hiddenInvestors)
		}

		onChange(transaction)
	}

	onSelectInvestor = (val) => {
		let { transaction } = this.props
		const { investors } = this.state
		this.setState({ idInputAutofocus: false })

		if (!investors.has(val.value)) {
			this.setState({ idInputAutofocus: true })
			this.onNew(val)
		} else {
			transaction = transaction.setIn(['investors', val.value], investors.get(val.value))

			if (transaction.getIn(['hiddenInvestors', val.value])) {
				transaction = transaction.removeIn(['hiddenInvestors', val.value])
			}

			this.onChange(transaction)
		}
	}

	onNew = (val) => {
		let { transaction } = this.props
		let newInvestor = this.getEmptyInvestor()
		newInvestor = newInvestor.setIn(['investorInformation', 'name'], val.label)
		newInvestor = newInvestor.setIn(['metadata', 'index'], transaction.get('investors', Map()).size + 1)
		transaction = transaction.setIn(['investors', v1()], newInvestor)
		this.onChange(transaction)
		this.refs.scrollbarone && this.refs.scrollbarone.scrollToBottom && this.refs.scrollbarone.scrollToBottom()
	}

	fetchSsn = (ssn, id) => {
		req
			.get(`/syna-integration/persons/${ssn}`)
			.then((response) => {
				const userInfo = response.data
				if (userInfo) {
					let investorInformation = this.props.transaction.getIn(['investors', id, 'investorInformation'], Map())

					investorInformation = investorInformation.set('id', formatSsn(ssn))
					investorInformation = investorInformation.set('name', userInfo.name)
					investorInformation = investorInformation.set(
						'address',
						userInfo.addresses[0] && userInfo.addresses[0].street
					)
					investorInformation = investorInformation.set(
						'zip',
						userInfo.addresses[0] && userInfo.addresses[0].postalCode
					)
					investorInformation = investorInformation.set('city', userInfo.addresses[0] && userInfo.addresses[0].city)

					let transaction = this.props.transaction.setIn(['investors', id, 'investorInformation'], investorInformation)
					transaction = transaction.setIn(['investors', id, 'investorTypeOfOwner'], 'private')
					this.onChange(transaction)
				}
			})
			.catch((e) => {
				console.log(e)
			})
	}

	fetchOrg = (org, id) => {
		req
			.get(`/syna-integration/companies/${org}`)
			.then((response) => {
				const company = response.data.company
				if (company) {
					if (this.props.transaction.getIn(['investors', id, 'investorTypeOfOwner'])) {
						return
					}
					let investorInformation = this.props.transaction.getIn(['investors', id, 'investorInformation'], Map())

					investorInformation = investorInformation.set('id', formatOrg(org))
					investorInformation = investorInformation.set('name', company.name)
					investorInformation = investorInformation.set('address', company.addresses[0] && company.addresses[0].street)
					investorInformation = investorInformation.set('zip', company.addresses[0] && company.addresses[0].postalCode)
					investorInformation = investorInformation.set('city', company.addresses[0] && company.addresses[0].city)

					let transaction = this.props.transaction.setIn(['investors', id, 'investorInformation'], investorInformation)
					transaction = transaction.setIn(['investors', id, 'investorTypeOfOwner'], 'company')
					this.onChange(transaction)
				}
			})
			.catch((e) => {
				console.log(e)
			})
	}

	onChangeId = (id, val) => {
		const { investors } = this.props
		const validSSn = validateSsn(val) ? false : true
		const validOrg = validateOrg(val) ? false : true
		const validCompanyId = validateCompanyId(val) ? false : true
		const validUserId = validateUserId(val) ? false : true
		const existingInvestor = investors.find((obj) => {
			const personOrOrgr = obj.getIn(['investorInformation', 'id'])
			return (
				validUserId ||
				formatSsn(personOrOrgr) === formatSsn(val) ||
				validCompanyId ||
				formatOrg(personOrOrgr) === formatOrg(val) ||
				personOrOrgr === val
			)
		})

		let transaction = this.props.transaction

		if (existingInvestor) {
			transaction = transaction.removeIn(['investors', id])
			transaction = transaction.setIn(['investors', existingInvestor.get('id')], existingInvestor)
			this.props.onChange(transaction)
			return
		}

		if (validSSn) {
			transaction = transaction.setIn(['investors', id, 'locked'], true)
			this.fetchSsn(val, id)
		}

		if (validOrg) {
			transaction = transaction.setIn(['investors', id, 'locked'], true)
			this.fetchOrg(val, id)
		}

		if (validCompanyId) {
			transaction = transaction.setIn(['investors', id, 'locked'], true)
			transaction = transaction.setIn(['investors', id, 'investorTypeOfOwner'], 'company')
		}

		if (validUserId) {
			transaction = transaction.setIn(['investors', id, 'locked'], true)
			transaction = transaction.setIn(['investors', id, 'investorTypeOfOwner'], 'private')
		}
		transaction = transaction.setIn(['investors', id, 'investorInformation', 'id'], val)

		this.props.onChange(transaction)
	}

	renderInvestor = (investor, id, index) => {
		const { idInputAutofocus } = this.state

		const address = investor.getIn(['investorInformation', 'address'])
		const zip = investor.getIn(['investorInformation', 'zip'])
		const city = investor.getIn(['investorInformation', 'city'])

		const isCapitalInsurance = investor.get('investorTypeOfOwner') === 'capitalInsurance'
		let idFieldValue = investor.getIn(['investorInformation', 'id'], '')

		let fullAdress = []
		if (address) {
			fullAdress.push(address)
		}
		if (zip) {
			fullAdress.push(zip)
		}
		if (city) {
			fullAdress.push(city)
		}
		fullAdress = fullAdress.join(', ')
		let idFieldDisabeld = address || zip || city

		//Special captial insurance case
		if (isCapitalInsurance) {
			idFieldValue = investor.getIn(['investorInformation', 'insuranceNr'], '')
			fullAdress = investor.getIn(['captialIncuranceOwnerInformation', 'name'], '')
			idFieldDisabeld = true
		}

		return (
			<div className='list__item' key={id}>
				<span className='share-shareholders__number'>#{index}</span>
				<span className='share-shareholders__name'>
					<input value={investor.getIn(['investorInformation', 'name'], '')} disabled={true} className='form-control' />
				</span>
				<span className='share-shareholders__id'>
					<input
						value={idFieldValue}
						onChange={(event) => {
							this.onChangeId(id, event.target.value)
						}}
						disabled={idFieldDisabeld}
						className='form-control'
						autoFocus={idInputAutofocus}
					/>
				</span>
				<span className=''>{fullAdress}</span>
				<span className='share-shareholders__delete'>
					<button type='button' className='btn btn-default' onClick={this.deleteRow.bind(this, id)}>
						<FormattedMessage id='remove' />
					</button>
				</span>
			</div>
		)
	}

	renderCreate = () => {
		const { investorsOptions } = this.state
		const { i18n } = this.props
		const { transaction } = this.props
		const numOfInvestors = transaction ? transaction.get('investors', List()).size : 0

		return (
			<div className='list__item'>
				<span className='share-shareholders__number' />
				<span className='share-shareholders__name'>
					<SelectCreatable
						className={numOfInvestors > 2 ? 'Select--up' : ''}
						options={investorsOptions}
						onChange={this.onSelectInvestor}
						placeholder={i18n.messages['shareholder_select']}
						promptText={i18n.messages['add']}
						optionComponent={ShareholderOptionRenderer}
						noResultsText={i18n.messages['shareholder_select_empty']}
						promptTextCreator={(text) => {
							return i18n.messages['add'] + ' ' + text
						}}
					/>
				</span>
			</div>
		)
	}

	render() {
		const { transaction } = this.props
		let selectedInvestors = transaction.get('investors')
		let index = 1

		selectedInvestors = selectedInvestors.sort((a, b) => {
			const aName = a.getIn(['investorInformation', 'name'])
			const bName = b.getIn(['investorInformation', 'name'])
			if (aName === bName) {
				return 0
			} else {
				return aName > bName ? 1 : -1
			}
		})

		return (
			<div className={`account-wizard__content account-wizard--animated ${this.props.className}`}>
				<div className='account-wizard__header'>
					<h2 className='account-wizard__title'>
						<span className='account-wizard__step'>3</span> <FormattedMessage id='add_shareholders' />
					</h2>
					<p>
						<FormattedMessage id='add_shareholders_information' />
					</p>
				</div>
				<div className='account-wizard__body'>
					<div className='i-panel i-panel--white'>
						<div className='i-panel__body'>
							<Scrollbars ref='scrollbarone' autoHeight autoHeightMin={270}>
								<div className='list list--striped list--table'>
									<div className='list__list-header'>
										<div className='share-shareholders__number'>
											<FormattedMessage id='#' />
										</div>
										<div className='share-shareholders__name'>
											<FormattedMessage id='name' />
										</div>
										<div className='share-shareholders__id'>
											<FormattedMessage id='sharebook_ID' />
										</div>
									</div>
									<div className='list__body'>
										{selectedInvestors &&
											selectedInvestors
												.map((investor, id) => {
													return this.renderInvestor(investor, id, index++)
												})
												.toList()}
										{this.renderCreate()}
									</div>
								</div>
							</Scrollbars>
						</div>
					</div>
				</div>
				<div className='account-wizard__footer'>
					<button type='button' className='btn btn-account-wizard' onClick={this.goToPrevious.bind(this)}>
						<FormattedMessage id='previous' />
					</button>
					<button type='button' className='btn btn-account-wizard' onClick={this.goToNext.bind(this)}>
						<FormattedMessage id='next' />
					</button>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		investors: state.investors.get('list', fromJS([])),
		i18n: state.i18n
	}
}

const mapActionsToProps = {
	listInvestors,
	addErrorNotification,
	addInfoNotification
}

export default connect(mapStateToProps, mapActionsToProps)(AddShareholders)
