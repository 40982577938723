import { fromJS, List, Map } from 'immutable'

import {
	FOLDERS_CREATE_REMOTE,
	FOLDERS_FETCH_REMOTE,
	FOLDERS_UPDATE_LOCAL,
	FOLDERS_DELETE_REMOTE,
	FOLDERS_SAVE_REMOTE,
	FOLDERS_LIST,
	FOLDERS_UPDATE_SELECTED_FOLDER_ID,
	FOLDERS_MIRRORED_FETCH_REMOTE,
	FOLDERS_GET_DEFAULT_PERMISSIONS,
	COMPANY_RESET_REDUCER,
	SHARING_FOLDERS_LIST
} from '../actions/types'

const INITIAL_STATE = fromJS({
	mapParentToChildren: {},
	folders: Map(),
	currentFolder: null,
	selectedFolderId: '',
	mirroredFolders: Map(),
	defaultPermissions: Map()
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action
	let parentId, parentList, folder, parent, index

	switch (type) {
		case FOLDERS_LIST:
			state = state.remove('mapParentToChildren')
			state = state.remove('folders')
			state = state.set('mapParentToChildren', payload.get('mapParentToChildren'))
			state = state.set('folders', payload.get('folders'))
			return state

		case SHARING_FOLDERS_LIST:
			state = state.setIn(['sharedFolders', 'folders'], payload.get('folders'))
			state = state.setIn(['sharedFolders', 'mapParentToChildren'], payload.get('mapParentToChildren'))
			return state

		case FOLDERS_UPDATE_LOCAL:
			return state.set('currentFolder', payload)

		case FOLDERS_CREATE_REMOTE:
			parentId = payload.get('parent') || 'root'
			parentList = state.getIn(['mapParentToChildren', parentId]) || List()
			parentList = parentList.push(payload.get('id'))
			state = state.setIn(['mapParentToChildren', parentId], parentList)
			state = state.setIn(['folders', payload.get('id')], payload)
			return state.set('currentFolder', null)

		case FOLDERS_FETCH_REMOTE:
			return state.set('currentFolder', payload)

		case FOLDERS_DELETE_REMOTE:
			folder = state.get('currentFolder')
			parent = folder.get('parent') || 'root'
			index = state.getIn(['mapParentToChildren', parent], List()).indexOf(folder.get('id'))

			state = state.removeIn(['folders', folder.get('id')])
			state = state.removeIn(['mapParentToChildren', folder.get('id')])
			state = state.removeIn(['mapParentToChildren', parent, index])
			return state.set('currentFolder', null)

		case FOLDERS_SAVE_REMOTE:
			state = state.setIn(['folders', payload.get('id')], payload)
			return state.set('currentFolder', null)

		case FOLDERS_UPDATE_SELECTED_FOLDER_ID:
			return state.set('selectedFolderId', payload)

		case FOLDERS_MIRRORED_FETCH_REMOTE:
			return state.set('mirroredFolders', payload)

		case FOLDERS_GET_DEFAULT_PERMISSIONS: {
			const permissions = payload.get('permissions')
			const createFolderObject = payload.get('createFolderObject')

			state = state.set('defaultPermissions', permissions)

			if (!createFolderObject) {
				return state
			}

			let currentFolder = state.get('currentFolder')
			if (!currentFolder) {
				currentFolder = Map({ permissions })
				return state.set('currentFolder', currentFolder)
			}

			return state.setIn(['currentFolder', 'permissions'], permissions)
		}
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}
