import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { Map, List } from 'immutable'

import CompanyWizard from '../../../dumb-components/company/company-wizard/company-wizard'
import SynaSearch from '../../syna/search.container'
import RepresentativesSelect from './representatives-select.container'
import SkipAuthorise from '../../../dumb-components/company/company-wizard/skip-authorise'

/**
 * Step - Starts on 1
 * 1. Search | 2. Representatives | 3. ToS | 4. Verify
 */

const INITIAL_STATE = {
	step: 1,
	company: Map(),
	toBeAuthorizedBy: null,
	allowedRepresentativesOptions: List(),
	ToS: false
}

class CompanyAccountWizardContainer extends Component {
	state = INITIAL_STATE

	static propTypes = {
		onRef: func,
		onAuthorize: func,
		onSkipAuthorise: func,
		onVerifyToCustomer: func
	}

	stepRefs = {}
	scrollbarRef = null
	synaSearchRef = null

	componentDidMount = () => {
		this.props.onRef(this)
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { step, company } = this.state

		// We check company so that scrollIntoViewIfNeeded
		// is not triggered when reseting the state
		if (company.size > 0 && step !== prevState.step) {
			this.stepRefs[step] && this.stepRefs[step].scrollIntoViewIfNeeded && this.stepRefs[step].scrollIntoViewIfNeeded()
		}
	}

	componentWillUnmount = () => {
		this.props.onRef(undefined)
	}

	onChangeCompany = (company) => {
		// Start from the beginning if company get's deleted
		if (!company) {
			this.resetState()
			return
		}

		this.setState({ company, step: 2, toBeAuthorizedBy: null, ToS: false })
	}

	onChange = (fieldName, value) => {
		if (fieldName === 'toBeAuthorizedBy') {
			if (this.state.step >= 3) {
				this.setState({ [fieldName]: value })
			} else {
				this.setState({ [fieldName]: value, step: 3 })
			}
			return
		}

		if (fieldName === 'ToS') {
			if (value === false) {
				this.setState({ [fieldName]: value, step: 3 })
			} else {
				this.setState({ [fieldName]: value, step: 4 })
			}
			return
		}
	}

	onSetStepRef = (index, ref) => {
		this.stepRefs[index] = ref
	}

	onSetScrollbarRef = (ref) => {
		this.scrollbarRef = ref
	}

	onSetSearchRef = (ref) => {
		this.synaSearchRef = ref
	}

	onAuthorize = () => {
		const { company, toBeAuthorizedBy } = this.state
		const { onAuthorize } = this.props

		onAuthorize(
			Map({
				companyOrgNumber: company.getIn(['company', 'orgNumber']),
				companyName: company.getIn(['company', 'name']),
				toBeAuthorizedBy
			})
		)
	}

	onSkipAuthorise = () => {
		const { company } = this.state
		const { onSkipAuthorise } = this.props

		onSkipAuthorise(
			Map({
				companyOrgNumber: company.getIn(['company', 'orgNumber']),
				companyName: company.getIn(['company', 'name'])
			})
		)
	}

	onVerifyToCustomer = () => {
		const { company } = this.state
		const { onVerifyToCustomer } = this.props

		onVerifyToCustomer(
			Map({
				companyOrgNumber: company.getIn(['company', 'orgNumber']),
				companyName: company.getIn(['company', 'name'])
			})
		)
	}

	searchComponentRenderer = () => {
		return <SynaSearch onChange={this.onChangeCompany} onSetRef={this.onSetSearchRef} />
	}

	renderRepresentativesComponent = () => {
		const { isInvonoUser } = this.props
		const { company, toBeAuthorizedBy } = this.state
		const allowedRepresentativesToSign = company.get('allowedRepresentativesToSign', List())

		return (
			<>
				<RepresentativesSelect
					allowedRepresentativesToSign={allowedRepresentativesToSign}
					onChange={this.onChange}
					value={toBeAuthorizedBy}
				/>
				{isInvonoUser && (
					<SkipAuthorise
						onSkipAuthorise={this.onSkipAuthorise}
						onVerifyToCustomer={this.onVerifyToCustomer}
						marginTop={true}
					/>
				)}
			</>
		)
	}

	resetState = () => {
		this.setState(INITIAL_STATE)
	}

	// Used by parent for reseting state
	// The delay is there so any CSS swipe transitions
	// can finish before state gets reset
	_resetState = () => {
		setTimeout(() => {
			// Reset Syna search box
			this.synaSearchRef && this.synaSearchRef.select.onChange(null)
			// Scroll view to top
			this.scrollbarRef && this.scrollbarRef.scrollToTop()

			// If Syna Search field doesn't have an ref,
			// the component was already unmounted. Don't reset
			// the state or we will get console warnings about memmory
			// leak

			this.synaSearchRef && this.resetState()
		}, 500)
	}

	render = () => {
		const { step, ToS } = this.state

		return (
			<CompanyWizard
				step={step}
				ToS={ToS}
				onSetScrollbarRef={this.onSetScrollbarRef}
				onSetStepRef={this.onSetStepRef}
				onChange={this.onChange}
				onAuthorize={this.onAuthorize}
				renderSearch={this.searchComponentRenderer}
				representativesComponent={this.renderRepresentativesComponent()}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		isInvonoUser: store.user.getIn(['userObj', 'invonoAdmin']) === true
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyAccountWizardContainer)
