import uuid from 'uuid'
import { fromJS } from 'immutable'
import arrayMove from 'array-move'
import debounce from 'lodash/debounce'
import req from '../modules/request.module'
import {
	VOTING_LIST,
	VOTING_UPDATE_LOCAL,
	VOTING_CREATE,
	VOTING_CREATE_LOCAL,
	VOTING_FETCH,
	VOTING_SAVE,
	VOTING_DELETE,
	VOTING_SELECT,
	VOTING_REMOTLY_CREATED,
	VOTING_REMONTLY_UPDATED,
	VOTING_REMOTLY_DELETED
} from './types'
import {
	EVENT_TYPE_VOTING_CREATED,
	EVENT_TYPE_VOTING_UPDATED,
	EVENT_TYPE_VOTING_DELETED,
	EVENT_TYPE_VOTING_ATTENDEE_VOTED,
	EVENT_TYPE_VOTING_STOPPED
} from '/shared/constants'

export function listVotings(meetingId) {
	return function (dispatch) {
		return req.get(`/meetings/voting/${meetingId}/list`).then((response) => {
			dispatch({ type: VOTING_LIST, payload: fromJS(response.data) })
		})
	}
}

export function updateVotingLocal(voting) {
	return function (dispatch) {
		dispatch({ type: VOTING_UPDATE_LOCAL, payload: voting })
	}
}

export function createVoting(voting, meetingId) {
	return function (dispatch) {
		if (!voting.has('id')) {
			voting = voting.set('id', uuid())
		}

		dispatch(createVotingLocal(voting))

		return req.post(`/meetings/voting/${meetingId}`, { voting }).then((response) => {
			dispatch({ type: VOTING_CREATE, payload: fromJS(response.data) })
		})
	}
}

export function createVotingLocal(voting) {
	return function (dispatch) {
		dispatch({ type: VOTING_CREATE_LOCAL, payload: voting })
	}
}

export function fetchVoting(votingId) {
	return function (dispatch) {
		return req.get(`/meetings/voting/${votingId}`).then((response) => {
			dispatch({ type: VOTING_FETCH, payload: fromJS(response.data) })
		})
	}
}

const _saveRemotely = debounce((voting, callback) => {
	return req
		.put(`/meetings/voting/${voting.get('meetingId')}/${voting.get('id')}`, { voting: voting.toJS() })
		.then(() => {
			callback && callback()
		})
		.catch(() => {
			callback && callback()
		})
}, 1000)

export function saveVoting(voting, callback) {
	return function (dispatch) {
		dispatch(updateVotingLocal(voting))
		_saveRemotely(voting, callback)
	}
}

export function changeVotingResult(meetingId, votingId, voterId, votingResult, callback) {
	const data = {
		meetingId,
		votingId,
		votingResult,
		voterId
	}

	return function (dispatch) {
		return req
			.put(`/meetings/voting/${meetingId}/${votingId}/result`, data)
			.then((response) => {
				callback && callback()
				dispatch({ type: VOTING_SAVE, payload: fromJS(response.data) })
			})
			.catch(() => {
				callback && callback()
			})
	}
}

export function deleteVoting(meetingId, votingId, callback) {
	return function (dispatch) {
		return req.delete(`/meetings/voting/${meetingId}/${votingId}`).then(() => {
			callback && callback()
			dispatch({ type: VOTING_DELETE, payload: votingId })
		})
	}
}

export function selectVoting(votingId) {
	return function (dispatch) {
		dispatch({ type: VOTING_SELECT, payload: votingId })
	}
}

export function startVoting(meetingId, votingId, callback) {
	return function (dispatch) {
		return req
			.put(`/meetings/voting/${meetingId}/${votingId}/start`)
			.then((response) => {
				callback && callback()
				dispatch({ type: VOTING_SAVE, payload: fromJS(response.data) })
			})
			.catch(() => {
				callback && callback()
			})
	}
}

export function sealVoting(meetingId, votingId, callback) {
	return function (dispatch) {
		return req
			.put(`/meetings/voting/${meetingId}/${votingId}/seal`)
			.then((response) => {
				dispatch({ type: VOTING_SAVE, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch(() => {
				callback && callback()
			})
	}
}

export function endVoting(meetingId, votingId, callback) {
	return function (dispatch) {
		return req
			.put(`/meetings/voting/${meetingId}/${votingId}/end`)
			.then((response) => {
				callback && callback()
				dispatch({ type: VOTING_SAVE, payload: fromJS(response.data) })
			})
			.catch(() => {
				callback && callback()
			})
	}
}

export function reorderVotings(meetingId, fromIndex, toIndex) {
	return function (dispatch, getState) {
		const votings = getState().votings.get('list')
		const votingsToUpdate = []
		let newVotings = arrayMove(votings.toJS(), fromIndex, toIndex)
		let index = 0

		newVotings = newVotings.map((voting) => {
			const oldVoting = votings.find((v) => v.get('id') === voting.id)

			voting.index = index++

			// Uppdatera endast omröstningar vars index har ändrats
			if (oldVoting.get('index') !== voting.index) {
				votingsToUpdate.push({
					id: voting.id,
					index: voting.index
				})
			}

			return voting
		})

		votingsToUpdate.length > 0 && req.put(`/meetings/voting/${meetingId}/reorder`, { indeces: votingsToUpdate })

		dispatch({ type: VOTING_LIST, payload: fromJS(newVotings) })
	}
}

export function socketEventVotings(eventObject) {
	const { eventName, data, metadata } = eventObject

	return function (dispatch) {
		switch (eventName) {
			case EVENT_TYPE_VOTING_CREATED:
				dispatch({ type: VOTING_REMOTLY_CREATED, payload: fromJS(data) })
				break
			case EVENT_TYPE_VOTING_UPDATED:
			case EVENT_TYPE_VOTING_ATTENDEE_VOTED:
				dispatch({ type: VOTING_REMONTLY_UPDATED, payload: fromJS(data) })
				break
			case EVENT_TYPE_VOTING_DELETED:
				dispatch({ type: VOTING_REMOTLY_DELETED, payload: metadata.votingId })
				break
			case EVENT_TYPE_VOTING_STOPPED:
				dispatch({ type: VOTING_SAVE, payload: fromJS(data) })
				break
		}
	}
}
