import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Moment from '../../../modules/moment.module'
import StepZilla from 'react-stepzilla'
import { fromJS } from 'immutable'
import Cookies from 'universal-cookie'
import Wiki from '../../wiki/wiki'
import debounce from 'lodash/debounce'
import {
	updateTransaction,
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction
} from '../../../actions/transaction.actions'
import { fetchMenu } from '../../../actions/menu.actions'

import ShareCapital from './shares-setup/share-capital'
import ShareTypes from './shares-setup/share-types'
import AddShareholders from './shares-setup/add-shareholders'
import ShareDistributionA from './shares-setup/share-distribution-a'
import ShareDistributionB from './shares-setup/share-distribution-b'
import ShareOverview from './shares-setup/share-overview'
import history from '../../../interfaces/history'

const initialTransaction = fromJS({
	type: 'GENESIS',
	handlerData: {},
	investors: {}
})

const de = debounce((func, transaction) => {
	func('GENESIS', transaction.toJS())
}, 1000)
class SharesSetup extends Component {
	constructor(props) {
		super(props)
		this.state = {
			currentStep:
				new Cookies().get('sharesSetupCurrentStep' + ('/' + this.props.location.pathname.split('/')[1] + '/')) || 0,
			moveDirection: 'forward'
		}
	}

	componentDidMount() {
		this.fetchTemporaryTransaction()
	}

	fetchTemporaryTransaction() {
		this.props.fetchTemporaryTransaction('GENESIS')
	}

	onChange(transaction) {
		this.props.updateTransaction('GENESIS', transaction)
		de(this.props.saveTemporaryTransaction, transaction)
	}

	onNext(nextStep) {
		const companyPath = '/' + this.props.location.pathname.split('/')[1] + '/'
		new Cookies().set('sharesSetupCurrentStep' + companyPath, nextStep, {
			path: '/',
			expires: Moment().add(1, 'year').toDate()
		})
		this.setState({ currentStep: nextStep, moveDirection: 'forward' })
	}

	onPrevious(nextStep) {
		const companyPath = '/' + this.props.location.pathname.split('/')[1] + '/'
		new Cookies().set('sharesSetupCurrentStep' + companyPath, nextStep, {
			path: '/',
			expires: Moment().add(1, 'year').toDate()
		})
		this.setState({ currentStep: nextStep, moveDirection: 'backward' })
	}

	onCreateTransaction(transaction) {
		const { createTransaction, location, fetchMenu, company, history } = this.props
		createTransaction(transaction, () => {
			this.onChange(initialTransaction)
			this.setState({ currentStep: 0 })
			const companyPath = '/' + location.pathname.split('/')[1] + '/'
			new Cookies().set('sharesSetupCurrentStep' + companyPath, 0, {
				path: '/',
				expires: Moment().add(1, 'year').toDate()
			})
			new Cookies().set('sharesSetupCurrentOption' + companyPath, 0, {
				path: '/',
				expires: Moment().add(1, 'year').toDate()
			})
			fetchMenu('main')
			history.push(location.pathname.split('shares')[0] + 'shares/share-register')
		})
	}

	cancelWizard() {
		this.onChange(initialTransaction)
		this.setState({ currentStep: 0 })
		const companyPath = '/' + this.props.location.pathname.split('/')[1] + '/'
		new Cookies().set('sharesSetupCurrentStep' + companyPath, 0, {
			path: '/',
			expires: Moment().add(1, 'year').toDate()
		})
		new Cookies().set('sharesSetupCurrentOption' + companyPath, 0, {
			path: '/',
			expires: Moment().add(1, 'year').toDate()
		})
		this.props.history.push(this.props.location.pathname.split('shares')[0] + 'shares/setup')
	}

	renderWiki() {
		const { setupType } = this.props.match.params

		let wiki = ''
		switch (parseInt(this.state.currentStep)) {
			case 0:
				wiki = <Wiki id='f93fb3b1-ab32-4b25-9c4e-08aa98e42458' className='text--wiki' />
				break
			case 1:
				wiki = <Wiki id='fd64075e-c106-4904-bf57-e80b5a7fe3a6' className='text--wiki' />
				break
			case 2:
				wiki = <Wiki id='f8e6382f-bff8-4d78-af05-22a55145303a' className='text--wiki' />
				break
			case 3:
				wiki = (
					<Wiki
						id={setupType === 'a' ? 'd4c0bf1f-0921-4f8a-ac0c-d1daecc5f137' : '0baad4cf-17a4-496c-b002-cededdfa2bfe'}
						className='text--wiki'
					/>
				)
				break
			case 4:
				wiki = <Wiki id='fd19740b-b553-4a6d-8f9e-7e34ff22c34a' className='text--wiki' />
				break
		}

		return (
			<div className='i-panel'>
				<div className='i-panel__body'>{wiki}</div>
			</div>
		)
	}

	render() {
		const {
			match: { params },
			transaction
		} = this.props
		const { setupType } = params
		const { currentStep, moveDirection } = this.state

		const steps = [
			{
				name: 'shareCapital',
				component: (
					<ShareCapital
						setupType={setupType}
						transaction={transaction}
						onChange={this.onChange.bind(this)}
						onNext={this.onNext.bind(this)}
						onPrevious={this.onPrevious.bind(this)}
						className={
							currentStep === 0 && moveDirection === 'backward'
								? 'account-wizard--fade-in-left'
								: 'account-wizard--fade-in-right'
						}
					/>
				)
			},
			{
				name: 'shareTypes',
				component: (
					<ShareTypes
						setupType={setupType}
						transaction={transaction}
						onChange={this.onChange.bind(this)}
						onNext={this.onNext.bind(this)}
						onPrevious={this.onPrevious.bind(this)}
						className={
							currentStep === 1 && moveDirection === 'backward'
								? 'account-wizard--fade-in-left'
								: 'account-wizard--fade-in-right'
						}
					/>
				)
			},
			{
				name: 'shareholders',
				component: (
					<AddShareholders
						setupType={setupType}
						transaction={transaction}
						onChange={this.onChange.bind(this)}
						onNext={this.onNext.bind(this)}
						onPrevious={this.onPrevious.bind(this)}
						className={
							currentStep === 2 && moveDirection === 'backward'
								? 'account-wizard--fade-in-left'
								: 'account-wizard--fade-in-right'
						}
					/>
				)
			}
		]

		if (setupType === 'a') {
			// if "a"
			steps.push({
				name: 'shareDistributionA',
				component: (
					<ShareDistributionA
						setupType={setupType}
						transaction={transaction}
						onChange={this.onChange.bind(this)}
						onNext={this.onNext.bind(this)}
						onPrevious={this.onPrevious.bind(this)}
						className={
							currentStep === 3 && moveDirection === 'backward'
								? 'account-wizard--fade-in-left'
								: 'account-wizard--fade-in-right'
						}
					/>
				)
			})
		} else if (setupType === 'b') {
			//if "b"
			steps.push({
				name: 'shareDistributionB',
				component: (
					<ShareDistributionB
						setupType={setupType}
						transaction={transaction}
						onChange={this.onChange.bind(this)}
						onNext={this.onNext.bind(this)}
						onPrevious={this.onPrevious.bind(this)}
						className={
							currentStep === 3 && moveDirection === 'backward'
								? 'account-wizard--fade-in-left'
								: 'account-wizard--fade-in-right'
						}
					/>
				)
			})
		}

		steps.push({
			name: 'shareOverview',
			component: (
				<ShareOverview
					setupType={setupType}
					transaction={transaction}
					onChange={this.onChange.bind(this)}
					onCreateTransaction={this.onCreateTransaction.bind(this)}
					onPrevious={this.onPrevious.bind(this)}
					className={
						currentStep === 4 && moveDirection === 'backward'
							? 'account-wizard--fade-in-left'
							: 'account-wizard--fade-in-right'
					}
				/>
			)
		})

		const title = setupType === 'a' ? 'option_a' : 'option_b'
		const subtitle = setupType === 'a' ? 'option_a_info' : 'option_b_info'

		return (
			<div>
				<div className='i-panel i-panel--white'>
					<div className='i-panel__heading i-panel__heading--big'>
						<div className='i-panel__title-wrapper'>
							<h2 className='i-panel__title'>
								<FormattedMessage id={title} />{' '}
								<span className='i-panel__title__text-muted'>
									<FormattedMessage id='setup' />
								</span>
							</h2>
							<div className='i-panel__subtitle'>
								<FormattedMessage id={subtitle} />
							</div>
						</div>
						<div className='i-panel__button-wrapper'>
							<button type='button' className='btn btn-block btn-panel-header' onClick={this.cancelWizard.bind(this)}>
								<FormattedMessage id='generic.form.cancel' />
							</button>
						</div>
					</div>
					<div
						className='i-panel__body i-panel__body--pad-hor i-panel__body--expandable i-panel__body--expandable-open'
						data-height='650px'>
						<div className='account-wizard'>
							<StepZilla
								steps={steps}
								showNavigation={false}
								showSteps={false}
								dontValidate={true}
								startAtStep={parseInt(currentStep)}
								preventEnterSubmission={true}
							/>
						</div>
					</div>
				</div>
				{this.renderWiki()}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		history: history,
		transaction: state.transaction.getIn(['tmpTransaction', 'GENESIS'], initialTransaction),
		company: state.company.company
	}
}

export default connect(mapStateToProps, {
	updateTransaction,
	fetchTemporaryTransaction,
	saveTemporaryTransaction,
	createTransaction,
	fetchMenu
})(SharesSetup)
