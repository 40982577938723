import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, bool, string } from 'prop-types'
import { List, Set, fromJS } from 'immutable'
import styled from 'styled-components'
import req from '../../modules/request.module'
import ShareholdersToolbarControls from '../../dumb-components/shares/shareholders-toolbar-controls/shareholders-toolbar-controls'
import DropdownMenuContainer from '../../containers/shared/dropdown-menu.container'
import Tooltip from '../../dumb-components/shared/tooltip/tooltip'
import DropdownIconItem from '../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import InvestorRelinkModalBtnController from '../../containers/shares/investor-relink-modal-btn-controller.container'
import { validateEmail } from '../../modules/validation.module'
import Copy from 'copy-to-clipboard'
import {
	unlinkInvestor,
	listInvestors,
	fetchInvestor,
	importShareholdersFromExcel
} from '../../actions/investors.actions'
import { generateExcelFromTemplate } from '../../actions/shares.actions'
import { dismissBlock } from '../../actions/user.actions'
import { openModal } from '../../actions/modals.actions'
import { createCapitalInsurance, listCapitalInsurances } from '../../actions/capital-insurance.actions'
import { getShareholderStatus } from '../../components/helpers/shares'
import documentsHelper from '../../components/helpers/documents.helper'
import { fetchOwnerReportFromApp } from '../../actions/report-pdfs.actions'
import Text from '../../dumb-components/shared/text/text'
import SendEmailConfirm from '../../dumb-components/shared/email-confirm-modal/send-email-confirm'
import CopyEmailConfirm from '../../dumb-components/shared/email-confirm-modal/copy-email-confirm'
import { SHARES_POSSIBLE_INVESTOR_STATUSES } from '../../constants/shares'
import { MODALS_SHARES_ERROR_MODAL, MANAGE_DEPO_MODAL } from '../../constants/modals'

import RemindShareholderAboutRegistrationDropdownItem from './remind-shareholder-about-registration-item.container'
import Dropzone from '../../dumb-components/shared/dropzone/dropzone'
import Divider from '../../dumb-components/shared/divider/divider'
import ShareholderTemplateErrorContainer from './shareholder-template-error.container'
import CapitalInsuranceModalContainer from '../shares/capital-insurance-modal.container'
import ManageDepoModalContainer from './modals/manage-depo-modal.container'
import investmentApi from '/shared/helpers/investment.helper'
import history, { getLocation } from '../../interfaces/history'

const MORE_ACTION_TOOLTIP_STATES = {
	default: {
		activeState: 'btnMoreActions'
	},
	noShareholderSelected: {
		tid: 'shares.shareholders.details.toolbar.no_shareholder_selected',
		delayShow: 'instant'
	}
}

const DOWNLOAD_TOOLTIP_STATES = {
	noShareholders: {
		tid: 'shares.shareholders.details.toolbar.no_visible_shareholders',
		delayShow: 'instant'
	}
}

const PRINT_OWNER_REPORT_TOOLTIP_STATES = {
	default: {
		tid: 'shares.shareholders.print_owner_report.tooltip.default'
	},
	sharebookNotInitialized: {
		tid: 'shares.shareholders.details.toolbar.no_sharebook_initialized',
		delayShow: 'instant'
	}
}

const TEXT_COMPONENT_FILTERED_BOLD = {
	filtered: <Text tid='shareholders.toolbar.generic.helper.filtered' bold={600} />
}

const INITIAL_STATE = {
	isSendEmailModalOpen: false,
	isCopyEmailModalOpen: false,
	insuranceCompanyModalIsOpen: false,
	depoModalIsOpen: false,
	capitalInsuranceModalContainerisLoading: false
}

class TransactionToolbarControlsContainer extends Component {
	static propTypes = {
		onDeleteShareholder: func,
		deleteShareholderDisabled: bool,
		isLinked: bool,
		selectedInvestmentId: string
	}

	static defaultProps = {
		deleteShareholderDisabled: false,
		isLinked: false
	}

	state = INITIAL_STATE

	dropzoneRef = null

	componentDidMount = () => {
		const { listCapitalInsurances } = this.props

		listCapitalInsurances()
	}

	createExcel = () => {
		const { visibleInvestors } = this.props

		const ids = visibleInvestors.map((i) => {
			return i.get('id')
		})

		req
			.post(`/shares/excel/investments/`, { ids: ids && ids.toJS() })
			.then(() => {
				window.open(`/api/pdf/excel/${this.props.company.id}`, '_self')
			})
			.catch((e) => {
				console.log(e)
			})
	}

	openAddInsuranceCompanyModal = () => {
		this.setState({ insuranceCompanyModalIsOpen: true })
	}

	openManageDeposModal = () => {
		this.props.openModal(MANAGE_DEPO_MODAL)
	}

	onEmailShareholders = () => {
		this.setState({
			isSendEmailModalOpen: true
		})
	}

	doEmailShareholders = () => {
		const mailToVisibleShareholdersData = this.getMailToVisibleInvestorsData()
		location.href = mailToVisibleShareholdersData.mailto
		this.sendEmailBtnRef && this.sendEmailBtnRef.onToggleMenu()
		this.closeConfirm()
	}

	onCopyEmailShareholders = () => {
		this.setState({
			isCopyEmailModalOpen: true
		})
	}

	doCopyEmailShareholders = () => {
		const mailToVisibleShareholdersData = this.getMailToVisibleInvestorsData()
		const { emails } = mailToVisibleShareholdersData

		Copy(emails)

		this.sendEmailBtnRef && this.sendEmailBtnRef.onToggleMenu()
		this.closeConfirm()
	}

	onShareholderListDownload = () => {
		this.createExcel()
		this.downloadBtnRef && this.downloadBtnRef.onToggleMenu()
	}

	openShareholderPreview = () => {
		const { selectedInvestmentId, company } = this.props
		const location = getLocation()

		if (!location) {
			throw new Error('No location object')
		}

		const splitted = location.pathname.split('/')
		delete splitted[splitted.length - 1]
		const url = `${splitted.join('/')}preview/?investmentId=${selectedInvestmentId}`

		history.push(url)
	}

	generateExcelFromTemplate = () => {
		const { generateExcelFromTemplate } = this.props
		this.downloadBtnRef && this.downloadBtnRef.onToggleMenu()
		generateExcelFromTemplate('addShareholders')
	}

	generateExcelFromTemplate2 = () => {
		const { generateExcelFromTemplate } = this.props
		this.downloadBtnRef && this.downloadBtnRef.onToggleMenu()
		generateExcelFromTemplate('emission')
	}

	uploadExcel = ({ preventOpenDropdown }) => {
		this.downloadBtnRef && !preventOpenDropdown && this.downloadBtnRef.onToggleMenu()
		this.dropzoneRef.open()
	}

	onHandleUpploadExcel = (files) => {
		const { importShareholdersFromExcel } = this.props
		importShareholdersFromExcel(files[0], this.handleErrorData)
	}

	handleErrorData = (errorData) => {
		const { openModal } = this.props

		openModal(MODALS_SHARES_ERROR_MODAL, { errorData })
	}

	setDropzoneRef = (ref) => {
		this.dropzoneRef = ref
	}

	getMailToVisibleInvestorsData = () => {
		const { visibleInvestors, user } = this.props

		if (!visibleInvestors || !user) {
			return null
		}

		// Get email of visible shareholders
		const investors = visibleInvestors.map((investor) => {
			const inv = investor.toJS()
			return inv && investmentApi.getInvestorEmail(inv)
		})

		// Get only valid emails
		let validInvestorEmails = List()
		investors.forEach((email) => {
			if (validateEmail(email)) {
				validInvestorEmails = validInvestorEmails.push(email)
			}
		})

		// Create list with addresses
		const commaSeparatedEmails = validInvestorEmails.join(',')

		// Disable send email to visible button if there are no investors with emails
		const disabled = validInvestorEmails && validInvestorEmails.size > 0 ? false : true

		// Get current user email
		const selfEmail = user.get('email')

		const mailToVisibleData = {
			mailto: `mailto:${selfEmail}?bcc=${commaSeparatedEmails}`,
			emails: validInvestorEmails.join(';'),
			disabled
		}

		return mailToVisibleData
	}

	getInvestorsStatus = () => {
		const { visibleInvestors } = this.props
		let statuses = Set()

		for (const investor of visibleInvestors) {
			const shareholderStatus = getShareholderStatus(investor)
			statuses = statuses.add(shareholderStatus)
			if (statuses.size === SHARES_POSSIBLE_INVESTOR_STATUSES.size) {
				return statuses
			}
		}

		return statuses
	}

	unlinkInvestor = () => {
		const { selectedInvestmentId, unlinkInvestor } = this.props

		this.unlinkBtnRef && this.unlinkBtnRef.onToggleMenu()

		if (!selectedInvestmentId) {
			return null
		}

		unlinkInvestor(selectedInvestmentId)
	}

	printOwnerReport = () => {
		const { fetchOwnerReportFromApp } = this.props
		fetchOwnerReportFromApp()
		this.printOwnerReportBtnRef && this.printOwnerReportBtnRef.onToggleMenu()
	}

	closeConfirm = () => {
		this.setState(INITIAL_STATE)
	}

	renderPrintOwnerReportDropdown = () => {
		const { sharesInitialized } = this.props
		let activeState

		if (!sharesInitialized) {
			activeState = 'sharebookNotInitialized'
		}

		return (
			<DropdownMenuContainer
				btnIcon='faPrint'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				tooltipStates={PRINT_OWNER_REPORT_TOOLTIP_STATES}
				tooltipActiveState={activeState}
				disabled={activeState ? true : false}
				noMaxWidth={true}
				ref={(ref) => (this.printOwnerReportBtnRef = ref)}>
				<DropdownIconItem tid='investments.print_shareholders' icon='faFileContract' onClick={this.printOwnerReport} />
			</DropdownMenuContainer>
		)
	}

	renderUnlinkDropdown = () => {
		const { onDeleteShareholder, isLinked } = this.props
		const dropdownDisabled = !onDeleteShareholder ? true : false
		const tooltipDelayShow = dropdownDisabled ? 'instant' : undefined
		const tooltipTid = dropdownDisabled
			? 'shares.shareholders.details.toolbar.no_shareholder_selected'
			: 'shares.shareholders.details.toolbar.settings.unlink.tooltip'

		return (
			<DropdownMenuContainer
				btnIcon='faUserCog'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				tooltipTid={tooltipTid}
				tooltipDelayShow={tooltipDelayShow}
				noMaxWidth={true}
				withPortal
				disabled={dropdownDisabled}
				ref={(ref) => (this.unlinkBtnRef = ref)}>
				<InvestorRelinkModalBtnController moreActionBtnRef={this.unlinkBtnRef} />

				<DropdownIconItem
					tid='investor.unlink.dropdown.item.title'
					icon='faUnlink'
					disabled={!isLinked}
					onClick={this.unlinkInvestor}
				/>
			</DropdownMenuContainer>
		)
	}

	renderDownloadDropdown = () => {
		const { visibleInvestors } = this.props
		const noShareholders = !visibleInvestors || visibleInvestors.size === 0
		let activeState

		if (noShareholders) {
			activeState = 'noShareholders'
		}

		return (
			<DropdownMenuContainer
				btnIcon='faFileSpreadsheet'
				halignMenu='right'
				tooltipStates={DOWNLOAD_TOOLTIP_STATES}
				tooltipActiveState={activeState}
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				noMaxWidth={true}
				ref={(ref) => (this.downloadBtnRef = ref)}>
				<Tooltip states={DOWNLOAD_TOOLTIP_STATES} activeState={activeState}>
					<DropdownIconItem
						tid='investors.download_excel'
						icon='faFileSpreadsheet'
						onClick={this.onShareholderListDownload}
						disabled={noShareholders}
					/>
				</Tooltip>
				<Divider />
				<DropdownIconItem
					tid='investors.toolbar.excel.create_template'
					icon='faArrowAltToBottom'
					onClick={this.generateExcelFromTemplate}
				/>
				<DropdownIconItem
					tid='investors.toolbar.excel.upload_template'
					icon='faArrowAltFromBottom'
					onClick={this.uploadExcel}
				/>
			</DropdownMenuContainer>
		)
	}

	renderEmailDropdown = () => {
		const mailToVisibleShareholdersData = this.getMailToVisibleInvestorsData()
		const emailFilteredShareholdersDisabled = mailToVisibleShareholdersData
			? mailToVisibleShareholdersData.disabled
			: true

		return (
			<DropdownMenuContainer
				btnIcon='faPaperPlane'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				withPortal
				ref={(ref) => (this.sendEmailBtnRef = ref)}
				noMaxWidth={true}>
				<Tooltip
					tid='tooltip.shares.shareholders.action.email_filtered.no_valid_email'
					delayShow='instant'
					active={emailFilteredShareholdersDisabled}>
					<DropdownIconItem
						icon='faEnvelope'
						tid='shareholders.email.email_selected_shareholders'
						tidValues={TEXT_COMPONENT_FILTERED_BOLD}
						onClick={this.onEmailShareholders}
						disabled={emailFilteredShareholdersDisabled}
					/>
				</Tooltip>

				<Tooltip
					tid='tooltip.shares.shareholders.action.email_filtered.no_valid_email'
					delayShow='instant'
					active={emailFilteredShareholdersDisabled}>
					<DropdownIconItem
						icon='faFilePlus'
						tid='shareholders.email.copy_email_selected_shareholders'
						tidValues={TEXT_COMPONENT_FILTERED_BOLD}
						onClick={this.onCopyEmailShareholders}
						disabled={emailFilteredShareholdersDisabled}
					/>

					<RemindShareholderAboutRegistrationDropdownItem />
				</Tooltip>
			</DropdownMenuContainer>
		)
	}

	renderMoreActionDropdown = () => {
		const { onDeleteShareholder, deleteShareholderDisabled, onCancel } = this.props
		// onDeleteShareholder can be used to determine if an shareholder is selected
		const dropdownDisabled = !onCancel && !onDeleteShareholder
		let activeState

		if (dropdownDisabled) {
			activeState = 'noShareholderSelected'
		}

		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisH'
				halignMenu='right'
				btnMode='transparent-icon'
				ref={(ref) => (this.moreActionBtnRef = ref)}
				tooltipStates={MORE_ACTION_TOOLTIP_STATES}
				noMaxWidth={true}
				disabled={dropdownDisabled}
				tooltipActiveState={activeState}>
				{onCancel && (
					<DropdownIconItem tid='shares.share_register.new_investor.cancel' icon='faTimes' onClick={onCancel} />
				)}

				{onDeleteShareholder && (
					<Tooltip
						tid='tooltip.shares.shareholders.action.delete.has_had_shares'
						delayShow='instant'
						active={deleteShareholderDisabled}>
						<DropdownIconItem
							tid='delete_shareholder'
							icon='faTrashAlt'
							disabled={deleteShareholderDisabled}
							onClick={onDeleteShareholder}
						/>
					</Tooltip>
				)}
			</DropdownMenuContainer>
		)
	}

	renderEmailConfirm = () => {
		const { isSendEmailModalOpen, isCopyEmailModalOpen } = this.state
		const statuses = this.getInvestorsStatus()

		return (
			<>
				{isSendEmailModalOpen && (
					<SendEmailConfirm
						isOpen={true}
						onConfirm={this.doEmailShareholders}
						onDecline={this.closeConfirm}
						investorsStatus={statuses}
					/>
				)}
				{isCopyEmailModalOpen && (
					<CopyEmailConfirm
						isOpen={true}
						onConfirm={this.doCopyEmailShareholders}
						onDecline={this.closeConfirm}
						investorsStatus={statuses}
					/>
				)}
			</>
		)
	}

	renderErrorModal = () => {
		return <ShareholderTemplateErrorContainer onAccept={this.uploadExcel} />
	}

	checkIfInsuranceCompanyExists = (selectedInsuranceCompany) => {
		const { capitalInsurances } = this.props
		const orgNumber = selectedInsuranceCompany.getIn(['company', 'orgNumber'])
		const companyExists = capitalInsurances.some((company) => {
			return company.get('organisationNumber').includes(orgNumber)
		})

		return companyExists
	}

	closeAddInsuranceCompanyModal = (companyId, selectedInsuranceCompany) => {
		const { createCapitalInsurance } = this.props
		const companyExists = selectedInsuranceCompany && this.checkIfInsuranceCompanyExists(selectedInsuranceCompany)

		if (companyId && selectedInsuranceCompany && !companyExists) {
			this.setState({ capitalInsuranceModalContainerisLoading: true })
			createCapitalInsurance(selectedInsuranceCompany, () => {
				this.setState({ capitalInsuranceModalContainerisLoading: false })
			})
		}

		this.setState({ insuranceCompanyModalIsOpen: false })
	}

	addCapitalInsuranceDropdown = () => {
		return (
			<DropdownMenuContainer
				btnIcon='faBooks'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				disabled={false}
				noMaxWidth={true}>
				<DropdownIconItem
					icon='faHouseFlood'
					tid='shares.shareholders.add_capital_insurance.btn.tooltip'
					onClick={this.openAddInsuranceCompanyModal}
				/>
				<DropdownIconItem
					icon='faFileInvoice'
					tid='shareholders.toolbar.dropdown.manage_depo.btn'
					onClick={this.openManageDeposModal}
				/>
			</DropdownMenuContainer>
		)
	}

	renderShareholderPeekDropdown = () => {
		const { selectedInvestor } = this.props
		const totalShares = selectedInvestor ? selectedInvestor.getIn(['details', 'numOfTotalShares']) : 0
		const hasShares = totalShares && totalShares > 0
		return (
			<DropdownMenuContainer
				btnIcon='faBookUser'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				disabled={false}
				noMaxWidth={true}>
				<Tooltip tid='shareholders_preview_disabled_tid' delayShow='instant' active={!hasShares}>
					<DropdownIconItem
						disabled={!hasShares}
						icon='faExpandArrowsAlt'
						tid='shares.shareholders.toolbar.peek.menu_item.preview_cap_table'
						onClick={this.openShareholderPreview}
					/>
				</Tooltip>
			</DropdownMenuContainer>
		)
	}

	render = () => {
		const { insuranceCompanyModalIsOpen, capitalInsuranceModalContainerisLoading } = this.state

		return (
			<div>
				<ManageDepoModalContainer />
				<CapitalInsuranceModalContainer
					isModalOpen={insuranceCompanyModalIsOpen}
					closeModal={this.closeAddInsuranceCompanyModal}
					isLoading={capitalInsuranceModalContainerisLoading}
				/>
				<ShareholdersToolbarControls
					addCapitalInsuranceRenderer={this.addCapitalInsuranceDropdown}
					moreActionsDropdownRenderer={this.renderMoreActionDropdown}
					emailDropdownRenderer={this.renderEmailDropdown}
					downloadDropdownRenderer={this.renderDownloadDropdown}
					unlinkDropdownRenderer={this.renderUnlinkDropdown}
					printOwnerReportDropdown={this.renderPrintOwnerReportDropdown}
					shareholderPeekDropdownRenderer={this.renderShareholderPeekDropdown}
				/>
				{this.renderEmailConfirm()}
				{this.renderErrorModal()}
				<Dropzone
					hidden={true}
					onDrop={this.onHandleUpploadExcel}
					dropzoneRef={this.setDropzoneRef}
					size='full-screen'
				/>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		company: state.company.company,
		user: state.user.get('userObj'),
		visibleInvestors: state.investors.get('visibleInvestors', List()) || List(),
		selectedInvestor: state.investors.get('selectedInvestor'),
		sharesInitialized: state.company.company?.metadata?.sharesInitialized,
		capitalInsurances: state.capitalInsurance.get('capitalInsurances')
	}
}
const mapActionsToProps = {
	unlinkInvestor,
	listInvestors,
	fetchInvestor,
	dismissBlock,
	generateExcelFromTemplate,
	importShareholdersFromExcel,
	fetchOwnerReportFromApp,
	openModal,
	createCapitalInsurance,
	listCapitalInsurances
}
export default connect(mapStateToProps, mapActionsToProps)(TransactionToolbarControlsContainer)
