import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { string, object, array, bool, node } from 'prop-types'
import { fromJS, Map, List } from 'immutable'
import { Select } from '../../../../dumb-components/shared/select'
import NumericInput from '../../../../dumb-components/fields/numeric-input'
import { getFullShareTypeLabel } from '../../../helpers/shares'
import { openModal } from '../../../../actions/modals.actions'
import { SHARES_SHARE_TYPE_MODAL } from '../../../../constants/modals'
import { TRANSACTION_TYPE_EMISSION } from '/shared/constants'

class ShareTypes extends Component {
	static propTypes = {
		latestTransaction: object, // Immutable Map
		shareTypes: object, // Immutable List
		value: object, // Immutable List
		showPriceField: bool,
		intl: intlShape.isRequired,
		allowOnlyPositiveNumbers: bool,
		appendIsNewOnSubmit: bool
	}

	static defaultProps = {
		shareTypes: List(),
		value: List(),
		showPriceField: true,
		appendIsNewOnSubmit: false,
		allowOnlyPositiveNumbers: false
	}

	state = {
		selectableShareTypes: List()
	}

	componentDidMount = () => {
		this.setSelectableShareTypes(this.props.shareTypes, this.props.value)
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.shareTypes !== prevProps.shareTypes) {
			this.setSelectableShareTypes(this.props.shareTypes, this.props.value)
		}

		if (this.props.value !== prevProps.value) {
			this.setSelectableShareTypes(this.props.shareTypes, this.props.value)
		}
	}

	setSelectableShareTypes = (shareTypes, values) => {
		let selectableShareTypes = List()

		if (shareTypes) {
			shareTypes.forEach((shareType) => {
				const label = getFullShareTypeLabel(shareType.get('type'))

				let isDisabled = false

				if (values) {
					values.forEach((value) => {
						if (value.get('type') === shareType.get('type')) {
							isDisabled = true
						}
					})
				}

				selectableShareTypes = selectableShareTypes.push(
					fromJS({
						value: shareType.get('type'),
						label,
						isDisabled
					})
				)
			})

			// Itirera över this.props.value och lägg till sådana som skapats nya.
			// Kontrollera så att aktieslag i this.props.value inte redan finns i selectableShareTypes
			if (values) {
				values.forEach((value) => {
					const added = selectableShareTypes.find((selectableShareType) => {
						return selectableShareType.get('value') === value.get('type')
					})

					if (!added) {
						selectableShareTypes = selectableShareTypes.push(
							fromJS({
								value: value.get('type'),
								label: getFullShareTypeLabel(value.get('type')),
								isDisabled: true
							})
						)
					}
				})
			}
		}

		this.setState({ selectableShareTypes })
	}

	isShareTypeRemovable(shareType) {
		let removable = false

		this.props.shareTypes.forEach((registeredShareType) => {
			if (registeredShareType.get('type') === shareType.get('type')) {
				removable = true
			}
		})

		return removable
	}

	getEmptyShareType() {
		return Map()
	}

	onChange = (index, field, val) => {
		const { value, shareTypes } = this.props
		let values = value
		let valueObj = values.get(index)

		if (!valueObj) {
			valueObj = Map()
			valueObj = valueObj.set(field, val)
			values = values.push(valueObj)
		} else {
			if (field === 'type') {
				const existsingShareType = shareTypes && shareTypes.find((obj) => obj.get('type') === val)
				if (existsingShareType && valueObj.get('isNew')) {
					valueObj = Map() // reset share type that has been created during the session
				}
			}

			valueObj = valueObj.set(field, val)
			values = values.set(index, valueObj)
		}
		this.props.onChange(values)
	}

	onChangeShareType = (index, val) => {
		if (val === '__createNew') {
			this.createShareType(index)
			return
		}

		this.onChange(index, 'type', val)
	}

	createShareType = (index) => {
		const { latestTransaction, showPriceField } = this.props
		const quotaValue = latestTransaction.getIn(['shareData', 'quotaValue'])
		let shareType = this.getEmptyShareType()

		if (!showPriceField) {
			shareType = shareType.set('pricePerShare', quotaValue)
		}

		this.showModal(shareType, index, { pricePerShareReadonly: !showPriceField, disableDeleteButton: true })
	}

	showModal = (shareType, index, modalProps = {}) => {
		this.props.openModal(SHARES_SHARE_TYPE_MODAL, {
			...modalProps,
			selectableShareTypes: this.state.selectableShareTypes.toJS(),
			shareType: shareType.toJS(),
			onSubmit: this.onSubmitModalForm.bind(this, index),
			onDelete: this.removeShareType.bind(this, index)
		})
	}

	onSubmitModalForm = (index, shareType) => {
		let selectableShareTypes = this.state.selectableShareTypes
		let values = this.props.value
		const { appendIsNewOnSubmit } = this.props

		shareType = fromJS(shareType) // Remove when modal is using immutable

		if (appendIsNewOnSubmit) {
			shareType = shareType.set('isNew', true)
		}
		values = values.push(shareType)

		selectableShareTypes = selectableShareTypes.push(
			fromJS({
				value: shareType.get('type'),
				label: getFullShareTypeLabel(shareType.get('type'))
			})
		)

		this.setState({ selectableShareTypes })
		this.props.onChange(values)
	}

	removeShareType = (index) => {
		const values = this.props.value.delete(index)
		this.props.onChange(values)
	}

	editShareType = (index) => {
		const { value, showPriceField } = this.props
		const shareType = value.get(index)
		this.showModal(shareType, index, { pricePerShareReadonly: !showPriceField, editState: true })
	}

	formatNumerInput = (num) => {
		const { formatNumber } = this.props.intl

		if (isNaN(num)) {
			return num
		}
		//return num;
		return formatNumber(num)
	}

	renderShareType = (selectedShareType, index) => {
		const { i18n, showPriceField, readonly, allowOnlyPositiveNumbers, currentTransactionType } = this.props
		let { selectableShareTypes } = this.state
		const removable = this.isShareTypeRemovable(selectedShareType)
		const editable = !removable && selectedShareType.get('type')

		if (index === 1 && currentTransactionType === TRANSACTION_TYPE_EMISSION) {
			return null
		}

		selectableShareTypes = selectableShareTypes.push(
			Map({
				value: '__createNew',
				label: i18n.messages['create_new_class_of_shares']
			})
		)

		return (
			<div className='list__item' key={index}>
				<div className={`list__item__text-area list__item__text-area--${showPriceField ? 'x3' : 'x5'}`}>
					<span className='list__item__input list__item__input--pad-right list__item__input--pad-lft'>
						<Select
							value={selectedShareType.get('type')}
							options={selectableShareTypes}
							onChange={(val) => {
								this.onChangeShareType(index, val)
							}}
							placeholderTid={'select_placeholder'}
							simpleValue={true}
						/>
					</span>
				</div>
				<span className='list__item__text-area list__item__text-area--x2'>
					<span className='list__item__input list__item__input--pad-right'>
						<NumericInput
							type='text'
							className='form-control text--align-right'
							value={selectedShareType.get('numOfShares', null)}
							onChange={(val) => {
								this.onChange(index, 'numOfShares', val)
							}}
							disabled={readonly}
						/>
					</span>
				</span>
				{showPriceField && (
					<span className='list__item__text-area list__item__text-area--x2'>
						<span className='list__item__input list__item__input--pad-right'>
							<NumericInput
								type='text'
								className='form-control text--align-right'
								allowDecimals
								value={selectedShareType.get('pricePerShare')}
								onChange={(val) => {
									this.onChange(index, 'pricePerShare', val)
								}}
								disabled={readonly}
							/>
						</span>
					</span>
				)}
				<span className='list__item__text-area list__item__text-area--sm'>
					<span className='list__item__input list__item__input--pad-right text--align-right'>
						{removable && (
							<button type='button' className='btn btn-default' onClick={this.removeShareType.bind(this, index)}>
								<span className='fa fa-times'></span>
							</button>
						)}
						{editable && (
							<button type='button' className='btn btn-default' onClick={this.editShareType.bind(this, index)}>
								<span className='fa fa-pencil'></span>
							</button>
						)}
					</span>
				</span>
			</div>
		)
	}

	render = () => {
		let value = this.props.value
		value = value.push(this.getEmptyShareType())

		const showPriceField = this.props.showPriceField

		return (
			<div>
				<div className='list__list-header'>
					<div
						className={`list__list-header__text-area list__list-header__text-area--${
							showPriceField ? 'x3' : 'x5'
						} list__list-header__text-area--pad-lft list__list-header__text-area--pad-right`}>
						<FormattedMessage id='shares.transactions.bonus_issue.form.class_of_shares' />
					</div>
					<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right'>
						<FormattedMessage id='shares.transactions.bonus_issue.form.add_shares_amount' />
					</div>
					{showPriceField && (
						<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right'>
							<FormattedMessage id='price_per_share' />
						</div>
					)}
				</div>
				<div className='list list--striped list--table'>{value.map(this.renderShareType).toList()}</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		i18n: state.i18n,
		currentTransactionType: state.transaction.get('currentTransactionType')
	}
}

const mapActionsToProps = {
	openModal
}

ShareTypes = injectIntl(ShareTypes)
export default connect(mapStateToProps, mapActionsToProps)(ShareTypes)
