import React, { PureComponent } from 'react'
import { string, object, bool, number, oneOf } from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

const pulseAnimKeyframes = keyframes`
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
	}
	70% {
			-webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
	}
	100% {
			-webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
`

const StyledWrapper = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
`

const StyledOffsetBackground = styled.span`
	position: absolute;
	left: ${(props) => props.x}px;
	top: ${(props) => props.y}px;
	border-radius: 50%;
	background: ${(props) => props.theme.colors[props.bgColor]};
	width: 18px !important;
	height: 18px !important;
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledBadge = styled.span`
	position: ${(props) => (props.posRelative ? 'relative' : 'absolute')};
	left: ${(props) => props.x}px;
	top: ${(props) => props.y}px;
	border-radius: 50%;
	background: ${(props) => props.theme.colors[props.color] || props.color};
	width: ${(props) => {
		switch (props.size) {
			case 'sm':
				return '10px'
			case 'md':
				return '17px'
		}
	}} !important;
	height: ${(props) => {
		switch (props.size) {
			case 'sm':
				return '10px'
			case 'md':
				return '17px'
		}
	}} !important;
	display: flex;
	align-items: center;
	justify-content: center;

	${(props) =>
		props.animated &&
		css`
			box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
			animation: ${pulseAnimKeyframes} 2s infinite;
		`}

	${(props) =>
		props.zIndex &&
		css`
			z-index: 1;
		`}
`

export default class NotificationBadge extends PureComponent {
	static propTypes = {
		active: bool,
		children: object,
		color: string,
		animated: bool,
		x: number,
		y: number,
		size: oneOf(['sm', 'md']),
		zIndex: bool
	}

	static defaultProps = {
		color: 'persianRed',
		animated: false,
		x: 0,
		y: -3,
		size: 'sm'
	}

	render = () => {
		const { active, animated, offsetBgColor, children, color, x, y, size, zIndex } = this.props

		if (!active) {
			return children
		}

		if (offsetBgColor) {
			return (
				<StyledWrapper>
					<StyledOffsetBackground bgColor={offsetBgColor} x={x} y={y}>
						<StyledBadge size={size} color={color} animated={animated} posRelative={true} zIndex={zIndex} />
					</StyledOffsetBackground>
					{children && children}
				</StyledWrapper>
			)
		}

		return (
			<StyledWrapper>
				<StyledBadge size={size} color={color} animated={animated} x={x} y={y} zIndex={zIndex} />
				{children && children}
			</StyledWrapper>
		)
	}
}
