import { fromJS, Map } from 'immutable'
import req from '../modules/request.module'
import stripeClient from 'stripe-client'
import {
	SUBSCRIPTIONS_FETCH_API_KEY,
	SUBSCRIPTIONS_FETCH,
	SUBSCRIPTIONS_SAVE,
	SUBSCRIPTIONS_FETCH_PLANS,
	SUBSCRIPTIONS_UPDATE_FORM_OBJECT,
	SUBSCRIPTIONS_VERIFY_COUPON,
	SUBSCRIPTIONS_CREATE_TOKEN,
	SUBSCRIPTIONS_SAVE_PREPAID,
	SUBSCRIPTIONS_UPDATE_LOCAL_PREPAID,
	SUBSCRIPTIONS_FETCH_HELPER,
	SUBSCRIPTION_ADD_STRIPE_ERROR
} from './types'
import { fetchMenu } from './menu.actions'
import { addErrorNotification } from './notify.actions'
import { fetchUser } from './user.actions'
import {
	// EVENTS
	EVENT_TYPE_SUBSCRIPTIONS_UPDATED
} from '/shared/constants'

export function updateFormObject(formObject) {
	return {
		type: SUBSCRIPTIONS_UPDATE_FORM_OBJECT,
		payload: formObject
	}
}

export function resetFormObject() {
	return {
		type: SUBSCRIPTIONS_UPDATE_FORM_OBJECT,
		payload: Map()
	}
}

/**
 * Action for fetching subscription for a company
 */
export function fetchSubscription() {
	return function (dispatch, getState) {
		const company = getState().company?.company

		return req.get('/subscriptions/').then((response) => {
			const subscription = fromJS(response.data)

			dispatch({
				type: SUBSCRIPTIONS_FETCH,
				payload: subscription
			})

			const stripeCustomer = subscription.getIn(['stripeData', 'sources', 'data', 0])
			const taxIds = subscription.getIn(['stripeData', 'tax_ids', 'data', 0])
			let paymentDetails
			if (stripeCustomer) {
				const expMonth = stripeCustomer.get('exp_month')
				paymentDetails = Map({
					brand: stripeCustomer.get('brand'),
					number: stripeCustomer.get('last4'),
					exp_month: expMonth < 10 ? `0${expMonth}` : expMonth,
					exp_year: stripeCustomer.get('exp_year'),
					name: stripeCustomer.get('name'),
					address_line1: stripeCustomer.get('address_line1'),
					address_line2: stripeCustomer.get('address_line2'),
					address_zip: stripeCustomer.get('address_zip'),
					address_city: stripeCustomer.get('address_city'),
					address_state: stripeCustomer.get('address_state'),
					address_country: stripeCustomer.get('address_country')
				})

				if (taxIds) {
					paymentDetails = paymentDetails.set('taxIdType', taxIds.get('type'))
					paymentDetails = paymentDetails.set('vatNumber', taxIds.get('value'))
				}
			} else {
				paymentDetails = Map({ address_country: company.country })
			}

			dispatch(updateFormObject(Map({ paymentDetails })))
		})
	}
}

export function saveSubscriptions(companyId, subscription) {
	return function (dispatch) {
		return req.put(`/subscriptions/${companyId}`, subscription).then((response) => {
			dispatch({
				type: SUBSCRIPTIONS_FETCH,
				payload: fromJS(response.data)
			})
		})
	}
}

export function refillPrepaid(cb, errorCallback) {
	return function (dispatch) {
		return req
			.post(`/subscriptions/refill-prepaid`)
			.then((response) => {
				dispatch({
					type: SUBSCRIPTIONS_FETCH,
					payload: fromJS(response.data)
				})
				cb && cb()
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
				if (err?.response?.data?.data?.stripeError === true) {
					dispatch(addStripeError(err?.response?.data?.data))
				}
			})
	}
}

export function changePlan(planIds, couponCode, callback, errorCallback) {
	return function (dispatch) {
		return req
			.post(`/subscriptions/change-plan/`, { planIds, couponCode })
			.then((response) => {
				const subscription = fromJS(response.data)
				dispatch({
					type: SUBSCRIPTIONS_SAVE,
					payload: subscription
				})
				dispatch(fetchUser('me'))
				dispatch(fetchMenu('main'))
				dispatch(fetchSubscriptionHelper())

				callback && callback(subscription)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
				if (err?.response?.data?.data?.stripeError === true) {
					dispatch(addStripeError(err?.response?.data?.data))
				}
			})
	}
}

export function resetPlan(callback) {
	return function (dispatch) {
		return req
			.post(`/subscriptions/change-plan-reset`)
			.then((response) => {
				dispatch({
					type: SUBSCRIPTIONS_SAVE,
					payload: fromJS(response.data)
				})

				callback && callback()
			})
			.catch((err) => {
				callback && callback(err)
				if (err?.response?.data?.data?.stripeError === true) {
					dispatch(addStripeError(err?.response?.data?.data))
				}
			})
	}
}

export function fetchPlans() {
	return function (dispatch) {
		return req.get('/subscriptions/plans').then((response) => {
			dispatch({
				type: SUBSCRIPTIONS_FETCH_PLANS,
				payload: fromJS(response.data)
			})
		})
	}
}

export function fetchApiKey() {
	return function (dispatch) {
		return req.get('/subscriptions/public_api_key').then((response) => {
			dispatch({
				type: SUBSCRIPTIONS_FETCH_API_KEY,
				payload: response.data
			})
		})
	}
}

export function createCardToken(apiKey, card, callback, errorCallback) {
	return async function (dispatch) {
		const stripe = stripeClient(apiKey)
		for (const prop in card) {
			if (card[prop] === null) {
				delete card[prop]
			}
		}

		try {
			const response = await stripe.createToken({ card })
			const token = await response.json()

			if (token.error) {
				dispatch(
					addErrorNotification({
						text: token.error?.message
					})
				)

				errorCallback && errorCallback()
			} else {
				dispatch({
					type: SUBSCRIPTIONS_CREATE_TOKEN,
					payload: fromJS(token)
				})
				callback?.(token)
			}

			return token
		} catch (err) {
			errorCallback && errorCallback()
			throw err
		}
	}
}

export function createPaymentDetails({
	sourceId,
	companyName,
	companyCountry,
	address,
	vatNumber,
	taxIdType,
	callback,
	errorCallback
}) {
	return async function (dispatch) {
		try {
			const response = await req.post('/subscriptions/customers', {
				sourceId,
				companyName,
				companyCountry,
				address,
				vatNumber,
				taxIdType
			})
			dispatch({
				type: SUBSCRIPTIONS_SAVE,
				payload: fromJS(response.data)
			})

			callback?.()
			return fromJS(response.data)
		} catch (err) {
			errorCallback && errorCallback(err)
			if (err?.response?.data?.data?.stripeError === true) {
				dispatch(addStripeError(err?.response?.data?.data))
			}
			throw err
		}
	}
}

export function savePaymentDetails({ customerId, sourceId, customer, vatNumber, taxIdType, callback, errorCallback }) {
	return async function (dispatch) {
		try {
			const response = await req.put(`/subscriptions/customers`, {
				customerId,
				sourceId,
				customer,
				vatNumber,
				taxIdType
			})
			dispatch({
				type: SUBSCRIPTIONS_SAVE,
				payload: fromJS(response.data)
			})

			callback && callback()
		} catch (err) {
			errorCallback && errorCallback(err)
			if (err?.response?.data?.data?.stripeError === true) {
				dispatch(addStripeError(err?.response?.data?.data))
			}
			throw err
		}
	}
}

export function updateCard(customerId, sourceId, callback, errCallback) {
	return async function (dispatch) {
		try {
			const response = await req.put(`/subscriptions/customers/source`, { customerId, sourceId })
			dispatch({
				type: SUBSCRIPTIONS_SAVE,
				payload: fromJS(response.data)
			})

			callback && callback()
			return fromJS(response.data)
		} catch (err) {
			errCallback && errCallback(err)
			if (err?.response?.data?.data?.stripeError === true) {
				dispatch(addStripeError(err?.response?.data?.data))
			}
			throw err
		}
	}
}

export function setCompanyAddressUsed(addressType) {
	return async function () {
		return await req.put('/subscriptions/address-type', { addressType })
	}
}

export function unsetCompanyAddressUsed() {
	return async function () {
		return await req.delete('/subscriptions/address-type')
	}
}

export function resetSubscripion() {
	return {
		type: SUBSCRIPTIONS_FETCH,
		payload: null
	}
}

export function verifyCoupon(couponCode, callback) {
	return function (dispatch) {
		return req
			.get(`/subscriptions/coupon/${couponCode}`)
			.then((response) => {
				dispatch({
					type: SUBSCRIPTIONS_VERIFY_COUPON,
					payload: fromJS(response.data)
				})

				callback && callback()
			})
			.catch((err) => {
				callback && callback(err)
				if (err?.response?.data?.data?.stripeError === true) {
					dispatch(addStripeError(err?.response?.data?.data))
				}
			})
	}
}

export function redeemCoupon(couponCode, callback, errorCallback) {
	return function (dispatch) {
		return req
			.put('/subscriptions/coupon', { couponCode })
			.then((response) => {
				dispatch({
					type: SUBSCRIPTIONS_FETCH,
					payload: fromJS(response.data)
				})

				callback && callback()
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
				if (err?.response?.data?.data?.stripeError === true) {
					dispatch(addStripeError(err?.response?.data?.data))
				}
			})
	}
}

export function clearCoupon() {
	return function (dispatch) {
		dispatch({
			type: SUBSCRIPTIONS_VERIFY_COUPON,
			payload: null
		})
	}
}

export function postPartnerCode(partnerCode, callback, errorCallback) {
	return function (dispatch) {
		return req
			.put('/subscriptions/partner-code', { partnerCode })
			.then((response) => {
				dispatch({
					type: SUBSCRIPTIONS_FETCH,
					payload: fromJS(response.data)
				})

				callback && callback()
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
				if (err?.response?.data?.data?.stripeError === true) {
					dispatch(addStripeError(err?.response?.data?.data))
				}
			})
	}
}

export function resetPrepaid(callback) {
	return function (dispatch) {
		return req
			.put(`/subscriptions/prepaid/reset-balance`)
			.then((response) => {
				dispatch({
					type: SUBSCRIPTIONS_SAVE_PREPAID,
					payload: fromJS(response.data)
				})
				callback && callback()
			})
			.catch((err) => {
				callback && callback(err)
				if (err?.response?.data?.data?.stripeError === true) {
					dispatch(addStripeError(err?.response?.data?.data))
				}
			})
	}
}

export function updatePrepaidLocal(prepaid) {
	return function (dispatch) {
		dispatch({
			type: SUBSCRIPTIONS_UPDATE_LOCAL_PREPAID,
			payload: prepaid
		})
	}
}

export function setInvoiceReciever(invoiceReciever, callback, errorCallback) {
	return function (dispatch) {
		return req
			.put(`/subscriptions/invoice-reciever`, { invoiceReciever })
			.then((response) => {
				dispatch({
					type: SUBSCRIPTIONS_SAVE,
					payload: fromJS(response.data)
				})
				callback && callback()
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	}
}

export function fetchSubscriptionHelper(callback) {
	return function (dispatch) {
		return req
			.get('/subscriptions/helper')
			.then((response) => {
				dispatch({ type: SUBSCRIPTIONS_FETCH_HELPER, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch((e) => {
				callback && callback(e)
			})
	}
}

export function updateSubscriptionHelperLocal(helper) {
	return function (dispatch) {
		dispatch({ type: SUBSCRIPTIONS_FETCH_HELPER, payload: fromJS(helper) })
	}
}

export function addStripeError(error) {
	return function (dispatch) {
		dispatch({ type: SUBSCRIPTION_ADD_STRIPE_ERROR, payload: fromJS(error) })
	}
}

export function resetStripeError() {
	return function (dispatch) {
		dispatch({ type: SUBSCRIPTION_ADD_STRIPE_ERROR })
	}
}

export function socketEventSubscriptions(eventObj) {
	const { eventName, objId, metadata } = eventObj

	return function (dispatch) {
		switch (eventName) {
			case EVENT_TYPE_SUBSCRIPTIONS_UPDATED: {
				dispatch(updateSubscriptionHelperLocal(metadata))
				break
			}
		}
	}
}
