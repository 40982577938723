import moment from '../../modules/moment.module'
import { validateOrgNumber, validatePersonNumber } from '../../modules/validation.module'
import { INVESTOR_TYPE_OF_OWNER_COMPANY } from '/shared/constants'
import investmentApi from '/shared/helpers/investment.helper'

export const formatSsn = (ssn) => {
	ssn = ssn && ssn.trim().replace('-', '')

	if (!ssn) {
		return ''
	}

	if (ssn.length === 10) {
		const d = moment()
		const year = d.year() - 2000
		const twoFirst = ssn.substring(0, 2)

		if (year < twoFirst) {
			ssn = '19' + ssn
		} else {
			ssn = '20' + ssn
		}
	}
	return ssn
}

export const formatOrg = (org) => {
	org = org && org.trim().replace('-', '')
	return org
}

export const formatSsnPretty = (ssn) => {
	ssn = ssn && ssn.trim().replace('-', '')

	if (!ssn) {
		return ''
	}

	if (ssn.length === 12) {
		return ssn.replace(/([0-9]{8})([X0-9]{4})/, (match, p1, p2) => {
			return [p1, p2].join('-')
		})
	} else if (ssn.length === 10) {
		return ssn.replace(/([0-9]{6})([X0-9]{4})/, (match, p1, p2) => {
			return [p1, p2].join('-')
		})
	} else {
		return ssn
	}
}

export const maskSsn = (ssn) => {
	if (ssn.length === 12) {
		return ssn.replace(/([0-9]{8})([X0-9]{4})/, (match, p1) => {
			return `${p1}-XXXX`
		})
	} else if (ssn.length === 10) {
		return ssn.replace(/([0-9]{6})([X0-9]{4})/, (match, p1) => {
			return `${p1}-XXXX`
		})
	} else {
		return ssn
	}
}

export const formatOrgPretty = (org) => {
	return formatSsnPretty(org)
}

export const formatIdPretty = (id) => {
	if (validatePersonNumber(id)) {
		id = formatSsnPretty(id)
	} else if (validateOrgNumber(id)) {
		id = formatOrgPretty(id)
	}
	return id
}

export const isSsnEqual = (ssn1, ssn2) => {
	const prettySsn1 = formatSsnPretty(formatSsn(ssn1))
	const prettySsn2 = formatSsnPretty(formatSsn(ssn2))

	return prettySsn1 === prettySsn2
}

export const userIsAccountHolder = (userId, companies) => {
	let accountHolder = false

	companies &&
		companies.forEach((company) => {
			if (!company.get('isUserDemo') && company.get('superUser') === userId) {
				accountHolder = true
			}
		})

	return accountHolder
}

export const getUserName = (object, investors, usersCache, withEmail) => {
	if (!object) {
		return ''
	}

	const userId = object.get('userId')

	// Guest
	let name = object.get('name')

	// Investor
	if (object.get('isInvestor') === true && investors) {
		const investor = investors.has(object.get('investmentId'))
			? investors.get(object.get('investmentId'))
			: investors.find((i) => i.get('id') === object.get('userId'))

		// Without email
		if (investor && !withEmail) {
			name = investmentApi.getInvestorName(investor.toJS())
		}

		// With email
		if (investor && withEmail) {
			const email = investmentApi.getInvestorEmail(investor.toJS())
			if (investor.get('investorTypeOfOwner') === INVESTOR_TYPE_OF_OWNER_COMPANY) {
				name = `${investmentApi.getInvestorName(investor.toJS())} - ${email}`
			} else {
				name = investmentApi.getInvestorName(investor.toJS())
			}
		}
	}

	// User (from cache)
	if (!name) {
		name = usersCache.getIn([userId, 'name'])
	}

	return name
}

export const getEmail = (user, companyId) => {
	if (!companyId) {
		return getDefaultEmail(user)
	}

	const companyEmailRef = user.getIn(['companyEmail', 'companyId'])

	if (!companyEmailRef) {
		return getDefaultEmail(user)
	}

	const emailObject = user.get('emails').find((email) => email.get('id') === companyEmailRef) || getDefaultEmail(user)
	return emailObject.get('email')
}

export const getDefaultEmail = (user) => {
	const emailObject = user.get('emails').find((email) => email.get('id') === user.get('defaultEmail'))
	return emailObject.get('email')
}
