import { fromJS } from 'immutable'

export const DOCUMENT_TASKS_CATEGORY_ID = '783838f5-2b0e-47d5-9075-b4eb0fa50433'

export const FILTER_FIELDS = fromJS([
	[
		{
			size: 1 / 2,
			fieldName: 'dateProp',
			labelTid: 'documents.filter.label.dateprop',
			options: [
				{ value: 'lastModified', label: 'documents.dateprop.lastmodified' },
				{ value: 'createdAt', label: 'documents.dateprop.createdat' },
				{ value: 'validFrom', label: 'documents.dateprop.validfrom' },
				{ value: 'validTo', label: 'documents.dateprop.validto' }
			],
			isClearable: false,
			pinable: true,
			renderer: 'select',
			placeholder: 'select_placeholder'
		},
		{
			size: 1 / 2,
			fieldName: 'date',
			minDateLabelTid: 'object_filter.label.from_date',
			maxDateLabelTid: 'object_filter.label.to_date',
			yearLabelTid: 'object_filter.label.by_year',
			showYearSelect: false,
			isClearable: true,
			renderer: 'dateRange'
		}
	],
	[
		{
			size: 1 / 2,
			fieldName: 'docStatus',
			labelTid: 'generic.status',
			options: [
				{ value: 'show_all', label: 'documents.status.show_all' },
				{ value: 'INVALID', label: 'documents.status.overdue' },
				{ value: 'WARNING', label: 'documents.status.warning' },
				{ value: 'none', label: 'documents.status.none' }
			],
			pinable: true,
			renderer: 'select',
			placeholder: 'select_placeholder'
		},
		{
			size: 1 / 2,
			fieldName: 'order',
			labelTid: 'object_filter.label.order',
			options: [
				{ value: 'title', label: 'documents.filter.order.alphabetical' },
				{ value: 'datePropAsc', label: 'documents.filter.order.date_asc' },
				{ value: 'datePropDesc', label: 'documents.filter.order.date_desc' }
			],
			isClearable: false,
			pinable: true,
			renderer: 'select',
			placeholder: 'select_placeholder'
		}
	],
	[
		{
			size: 1,
			fieldName: 'extension',
			labelTid: 'documents.filter.label.extension',
			isClearable: true,
			renderer: 'select',
			placeholder: 'select_placeholder'
		}
	],
	[
		{
			size: 1,
			fieldName: 'signedDoc',
			labelTid: 'documents.filter.label.signed',
			options: [
				{ value: 'unsigned', label: 'documents.filter.signed.not_signed' },
				{ value: 'pending', label: 'documents.filter.signed.pending' },
				{ value: 'ongoing', label: 'documents.filter.signed.ongoing' },
				{ value: 'completed', label: 'documents.filter.signed.completed' }
			],
			isClearable: true,
			renderer: 'select',
			placeholder: 'select_placeholder',
			labelIsTid: true
		}
	],
	[
		{
			size: 1,
			fieldName: 'createdBy',
			labelTid: 'documents.filter.label.created_by',
			isClearable: true,
			placeholder: 'select_placeholder'
		}
	],
	[
		{
			size: 1,
			fieldName: 'permissions',
			labelTid: 'documents.filter.label.permissions',
			placeholder: 'select_placeholder',
			pinable: true
		}
	],
	[
		{
			size: 1,
			fieldName: 'showOnlyTrashed',
			tid: 'filter.label.show_only_trashed',
			renderer: 'checkbox'
		}
	]
])

export const DOCUMENT_STATUSES = fromJS([
	{ value: 'INVALID', label: 'documents.status.overdue', statusColor: 'red' },
	{ value: 'WARNING', label: 'documents.status.warning', statusColor: 'orange' }
])

export const FACTORY_DEFAULT_FILTER_VALUES = fromJS({
	dateProp: ['createdAt'],
	order: ['datePropDesc'],
	docStatus: ['show_all'],
	permissions: ['show_all'],
	showOnlyTrashed: [false]
})
