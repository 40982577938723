import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'

import Modal from '../../../../dumb-components/shared/modal/modal'
import FooterRightControls from '../../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../../dumb-components/shared/button-v2'
import ManageProxyDocumentsContainer from './manage-proxy-documents.container'

import { closeModal } from '../../../../actions/modals.actions'

import { MEETINGS_PROXY_MANAGE_DOCUMENT } from '../../../../constants/modals'
import { findProxyByAttendeeId } from '../../../../components/helpers/meeting.helper'

class ManageProxyDocumentsModalContainer extends Component {
	renderFooterComponent = () => {
		const { closeModal } = this.props

		return (
			<FooterRightControls>
				<TransparentButton tid='generic.form.close' textColor='midGrey' onClick={closeModal} />
			</FooterRightControls>
		)
	}

	render = () => {
		const { isOpen, attendeeId } = this.props

		if (!isOpen) {
			return null
		}

		return (
			<Modal
				isOpen={isOpen}
				hSize='xl'
				title='meetings.agm.proxy.documents_modal.title'
				footerComponent={this.renderFooterComponent()}>
				<ManageProxyDocumentsContainer attendeeId={attendeeId} />
			</Modal>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		isOpen: store.modals.getIn(['activeModal', 'name']) === MEETINGS_PROXY_MANAGE_DOCUMENT,
		attendeeId: store.modals.getIn(['activeModal', 'options', 'attendeeId'])
	}
}

const mapActionsToProps = {
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(ManageProxyDocumentsModalContainer)
