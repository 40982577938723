import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import NumericInput from '../../../dumb-components/fields/numeric-input'

/**
 * Format value as a button
 */
export function buttonLinkFormatter(value, column) {
	const buttonConfig = column.get('button')
	const href = buttonConfig.get('href').replace('{value}', value)
	const buttonClasses = buttonConfig.get('className', 'btn btn-default')
	const buttonText = buttonConfig.get('text')

	return (
		<Link to={href} className={buttonClasses}>
			<FormattedMessage id={buttonText} />
		</Link>
	)
}

/**
 * Format value as an NumberFormat input field
 */
export function numberFormatInputFieldFormatter(value, column) {
	const thousandSeparator = column.get('thousandSeparator', ' ')
	const decimalSeparator = column.get('decimalSeparator', ',')
	const decimalScale = column.get('decimalScale')
	const readOnly = column.get('readOnly', false)
	const inputClassName = column.get('inputClassName')

	return (
		<NumericInput
			type='text'
			thousandSeparator={thousandSeparator}
			decimalSeparator={decimalSeparator}
			decimalScale={decimalScale}
			value={value}
			disabled={readOnly}
			className={`form-control ${inputClassName}`}
		/>
	)
}
