/**
 * DropdownButton
 * @module components/framework/dropdown-button
 */

import React, { Component } from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

/** class representing the dropdown button component. */
class DropdownButton extends Component {
	/**
	 * Constructor
	 */
	constructor(props) {
		super(props)

		this.documentWasClicked = this.documentWasClicked.bind(this)
		this.state = { open: false }
	}

	/**
	 * componentDidMount
	 */
	componentDidMount() {
		document.addEventListener('click', this.documentWasClicked)
	}

	/**
	 * componentWillUnmount
	 */
	componentWillUnmount() {
		document.removeEventListener('click', this.documentWasClicked)
	}

	/**
	 * Toggle the open state on click
	 */
	onToggleClick() {
		this.setState({ open: !this.state.open })
	}

	/**
	 * Callback function for when the document is clicked
	 * @param {object} event - MouseEvent
	 */
	documentWasClicked(event) {
		if (this.state.open === true) {
			var currentTag = event.target

			while (
				currentTag !== null &&
				(currentTag.classList === undefined || currentTag.classList.contains('dropdown-toggle') === false)
			) {
				currentTag = currentTag.parentNode
			}

			if (currentTag === null) {
				this.onToggleClick()
			}
		}
	}

	/**
	 * Renders a single item
	 */
	renderSubmenuItem(item, index) {
		let className = ''
		if (item && item.props && item.props.disabled === true) {
			className += 'disabled'

			if (item.type.displayName === 'Link' || item.type === 'a') {
				return (
					<li key={index} className={`${className}`}>
						<a
							href='#'
							className='text--disabled'
							onClick={(event) => {
								event.preventDefault()
							}}>
							{item.props.children}
						</a>
					</li>
				)
			}
		}

		if (item && item.type === 'span') {
			return <li className='divider' key={index}></li>
		}

		return (
			<li key={index} className={`${className}`}>
				{item}
			</li>
		)
	}

	/**
	 * Render supplied or pre-set default message
	 */
	renderDefaultMessage() {
		const message = this.props.defaultMessage || <FormattedMessage id='generic.default_dropdown_message' />
		return <div className='navbar-dropdown--placeholder'>{message}</div>
	}

	/**
	 * The render function
	 */
	render() {
		const values = this.props.values || {}
		const hideCaret = this.props.hideCaret || false
		const btnCssClass = this.props.cssClass
			? 'btn dropdown-toggle ' + this.props.cssClass
			: 'btn dropdown-toggle btn-default'
		const dropdownAddClass = this.props.dropdownAddClass ? ' ' + this.props.dropdownAddClass : ''
		const dropdownClass =
			this.props.dropdownPosition === 'right' ? 'dropdown-menu dropdown-menu-right' : 'dropdown-menu'
		const groupCssClass = this.state.open ? 'btn-group open' : 'btn-group'
		const groupButtonCssClass = this.props.buttonCss ? groupCssClass + ' ' + this.props.buttonCss : groupCssClass
		const translationId = this.props.translationId ? (
			this.props.html ? (
				<FormattedHTMLMessage id={this.props.translationId} values={values} />
			) : (
				<FormattedMessage id={this.props.translationId} values={values} />
			)
		) : null
		const title = translationId ? translationId : this.props.title || ''
		const iconTitle = this.props.iconTitle
		const defaultMessage = this.renderDefaultMessage()
		let children = this.props.children && this.props.children.length > 0 ? this.props.children : [defaultMessage]

		if (this.props.children && Object.prototype.toString.call(this.props.children) !== '[object Array]') {
			children = [this.props.children]
		}

		return (
			<div className={groupButtonCssClass}>
				<button
					className={btnCssClass}
					onClick={this.onToggleClick.bind(this)}
					type='button'
					aria-expanded={this.state.open}>
					{iconTitle ? <span className={iconTitle}></span> : title}{' '}
					{!iconTitle ? <i className='dropdown-caret'></i> : ''}
				</button>
				<ul className={dropdownClass + dropdownAddClass}>{children.map(this.renderSubmenuItem.bind(this))}</ul>
			</div>
		)
	}
}

/** Export */
export default DropdownButton
