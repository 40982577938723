import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import Panel from '../panel/panel'
import { List } from 'immutable'
import { func, number, string, bool, oneOf } from 'prop-types'
import ImmutableProps from 'react-immutable-proptypes'
import Text from '../text/text'
import Moment from '../../../modules/moment.module'
import Button from '../button/button'
import ProfileImage from '../profile-image/profile-image'
import v1 from 'uuid'
import { stateToHTML } from 'draft-js-export-html'

const StyledWrapper = styled.div`
	${(props) =>
		props.panelType !== 'inline' &&
		css`
			padding: ${props.theme.spacing[4]};
		`}
`

const StyledCommentWrapper = styled.div`
	min-height: 50px;
	display: flex;
	margin-bottom: ${(props) => props.theme.spacing[4]};

	:last-child {
		margin-bottom: 0px;
	}
`

const StyledCommentContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-left: ${(props) => props.theme.spacing[4]};
	position: relative;
`

const StyledCommentHeader = styled.div`
	display: flex;
	flex-direction: row;
	min-height: 19px;
	/*
		This has to do with the ChevronDown icon being
	 	too big and causing height jumping on hover
	*/
	margin-right: 34px;
`

const StyledCommentBody = styled.div`
	flex: 1;
	width: 100%;
`

const ActionControlWrapper = styled.div`
	display: none;
	flex: 1;
	justify-content: flex-end;
	padding-right: ${(props) => props.theme.spacing[3]};
	position absolute;
	top: 0;
	right: 0;

	${StyledCommentWrapper}:hover & {
		display: flex;
	}
`

const ShowMoreLessButtonWrapper = styled.div`
	margin-bottom: ${(props) => props.theme.spacing[4]};
`

class Comments extends PureComponent {
	static propTypes = {
		comments: ImmutableProps.list,
		commentsEditorRenderer: func,
		usersCache: ImmutableProps.map.isRequired,
		btnActionsRenderer: func,
		maxVisibleComments: number,
		isEditing: string,
		bottomCreatorRenderer: func,
		scrollbarRef: func,
		currentUserId: string,
		showAllComments: bool,
		onToggleShowAllComments: func,
		marginBottom: bool,
		panelType: oneOf(['inline']),
		selected: bool,
		withoutPanel: bool
	}

	static defaultProps = {
		comments: List(),
		maxVisibleComments: 4,
		showAllComments: true,
		marginBottom: true
	}

	renderComment = (comment, index) => {
		const {
			comments,
			btnActionsRenderer,
			editingCommentId,
			commentsEditorRenderer,
			usersCache,
			currentUserId,
			maxVisibleComments,
			showAllComments
		} = this.props

		if (!showAllComments && index < comments.size - maxVisibleComments) {
			return null
		}

		const createdBy = comment.get('createdBy')
		const image = usersCache.getIn([createdBy, 'image'])
		const userId = usersCache.getIn([createdBy, 'id'])
		const creatorProfileImageHref = image
			? `/api/users/public/images/${image.get('filename')}-80x80?userId=${userId}`
			: null
		const userFullName = usersCache.getIn([createdBy, 'name'])
		const createdAt = Moment(comment.get('createdAt')).format('lll')
		const commentText = comment.get('text')
		const parsedCommentText = commentText ? stateToHTML(commentText) : ''
		const commentId = comment.get('id')

		return (
			<StyledCommentWrapper key={index}>
				<ProfileImage height='50px' width='50px' image={creatorProfileImageHref} />
				<StyledCommentContentWrapper>
					<StyledCommentHeader>
						<Text bold={600} singleLine>
							{userFullName}
						</Text>
						&nbsp;
						<Text color='lightGrey' nowrap>
							{createdAt}
						</Text>
						{createdBy === currentUserId && (
							<ActionControlWrapper>{btnActionsRenderer && btnActionsRenderer(comment)}</ActionControlWrapper>
						)}
					</StyledCommentHeader>
					<StyledCommentBody>
						{editingCommentId === commentId ? (
							commentsEditorRenderer && commentsEditorRenderer(comment, index)
						) : (
							<Text asHtml>{parsedCommentText}</Text>
						)}
					</StyledCommentBody>
				</StyledCommentContentWrapper>
			</StyledCommentWrapper>
		)
	}

	renderContent = () => {
		const {
			comments,
			bottomCreatorRenderer,
			maxVisibleComments,
			showAllComments,
			onToggleShowAllComments,
			panelType
		} = this.props
		const showMoreLessButtonTid = showAllComments
			? 'comments.comments_list.hide_earlier_comments'
			: 'comments.comments_list.show_all_comments'

		return (
			<StyledWrapper panelType={panelType}>
				{comments.size >= maxVisibleComments && (
					<ShowMoreLessButtonWrapper>
						<Button mode='link' onClick={onToggleShowAllComments} tid={showMoreLessButtonTid} />
					</ShowMoreLessButtonWrapper>
				)}
				{comments.map(this.renderComment)}
				{bottomCreatorRenderer && bottomCreatorRenderer()}
			</StyledWrapper>
		)
	}

	render = () => {
		const {
			comments,
			bottomCreatorRenderer,
			maxVisibleComments,
			showAllComments,
			onToggleShowAllComments,
			marginBottom,
			panelType,
			selected,
			withoutPanel
		} = this.props
		const showMoreLessButtonTid = showAllComments
			? 'comments.comments_list.hide_earlier_comments'
			: 'comments.comments_list.show_all_comments'
		const panelMode = panelType === 'inline' ? 'light' : undefined

		if (withoutPanel) {
			return this.renderContent()
		}

		return (
			<Panel
				title='generic.comments'
				marginBottom={marginBottom}
				type={panelType}
				mode={panelMode}
				id='comments-panel'
				ref={(node) => (this._panel = node)}
				active={selected}>
				<StyledWrapper panelType={panelType}>
					{comments.size >= maxVisibleComments && (
						<ShowMoreLessButtonWrapper>
							<Button mode='link' onClick={onToggleShowAllComments} tid={showMoreLessButtonTid} />
						</ShowMoreLessButtonWrapper>
					)}
					{comments.map(this.renderComment)}
					{bottomCreatorRenderer && bottomCreatorRenderer()}
				</StyledWrapper>
			</Panel>
		)
	}
}

export default Comments
