/**
 * Define steps:
	const steps = [
		{
			label?: string,
			slots: {
				title?: string|function,
				header: ({changeStep: (step) => void, activeStep: number}) => void,
				body: ({changeStep: (step) => void, activeStep: number}) => void,
				footerLeftActions?: ({changeStep: (step) => void, activeStep: number}) => void,
				footerRightActions?: ({changeStep: (step) => void, activeStep: number}) => void,
			},
			styles: {
				body: {} as sx
			}
		}
		...
	]
 */
import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'
import { func, string, bool, arrayOf, oneOf, oneOfType, shape, object, number } from 'prop-types'
import Stepper from '../stepper/stepper'
import FooterRightControls from '../../dumb-components/shared/modal/footer-right-controls'
import { useTranslation } from 'react-i18next'

const StepsModal = ({
	modalSize,
	title,
	isOpen,
	steps,
	useStepper,
	formProps,
	onExited,
	onEntered,
	overrideIndex,
	loading
}) => {
	const [swipedOnce, setSwipedOnce] = useState(false)
	const [index, setIndex] = useState(overrideIndex || 0)
	const { t } = useTranslation()
	const activeStep = index
	const content = steps[index]

	const [slideIn, setSlideIn] = useState(true)
	const [slideDirection, setSlideDirection] = useState('left')

	const changeStep = (step) => {
		const direction = step > index ? 'right' : 'left'
		const newIndex = step

		const oppositeDirection = direction === 'left' ? 'right' : 'left'
		setSlideDirection(direction)
		setSlideIn(false)
		setSwipedOnce(true)

		setTimeout(() => {
			setIndex(newIndex)
			setSlideDirection(oppositeDirection)
			setSlideIn(true)
		}, 500)
	}

	let renderTitle = title
	if (content?.slots && content?.slots?.title) {
		renderTitle = typeof content.slots.title === 'function' ? content.slots.title() : t(content.slots.title)
	}

	const stepsLabels = steps.map((step) => ({ label: step.label }))

	const shouldRenderFooterLeftActions = Boolean(content?.slots?.footerLeftActions && !overrideIndex)

	return (
		<Dialog open={isOpen} maxWidth={modalSize} fullWidth={true}>
			{renderTitle && <DialogTitle>{renderTitle}</DialogTitle>}
			{content?.slots?.header?.(changeStep, activeStep)}
			<DialogContent sx={content?.styles?.body}>
				{useStepper && <Stepper steps={stepsLabels} activeStep={index} />}
				{swipedOnce ? (
					<Slide
						in={slideIn}
						direction={slideDirection}
						onExited={onExited && onExited.bind(null, activeStep)}
						onEntered={onEntered && onEntered.bind(null, activeStep)}>
						<Box sx={{ height: '100%' }}>
							{content?.slots?.body?.({ changeStep, activeStep, formProps })}
						</Box>
					</Slide>
				) : (
					<Box sx={{ height: '100%' }}>{content?.slots?.body?.({ changeStep, activeStep, formProps })}</Box>
				)}
			</DialogContent>
			<DialogActions sx={{ justifyContent: shouldRenderFooterLeftActions ? 'space-between' : 'flex-end' }}>
				{shouldRenderFooterLeftActions &&
					content?.slots?.footerLeftActions?.({ changeStep, activeStep, formProps })}
				<FooterRightControls loading={loading}>
					{content?.slots?.footerRightActions?.({ changeStep, activeStep, formProps })}
				</FooterRightControls>
			</DialogActions>
		</Dialog>
	)
}

StepsModal.propTypes = {
	modalSize: oneOfType([string, bool, oneOf(['xs', 'sm', 'md', 'lg', 'xl'])]),
	title: string,
	isOpen: bool,
	steps: arrayOf(
		shape({
			label: string,
			slots: shape({
				title: oneOfType([string, func]),
				header: func,
				body: func.isRequired,
				footerRightActions: func,
				footerLeftActions: func
			}),
			styles: shape({
				body: object
			})
		})
	),
	useStepper: bool,
	formProps: shape({
		handleSubmit: func,
		values: object
	}),
	onExited: func,
	onEntered: func,
	overrideIndex: number,
	loading: bool
}

StepsModal.defaultProps = {
	modalSize: 'lg'
}

export default StepsModal
