import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, string, func } from 'prop-types'
import { relinkInvestor } from '../../actions/investors.actions'
import InvestorRelinkModal from '../../dumb-components/shares/investor-relink-modal/investor-relink-modal'

class InvestorRelinkModalContainer extends Component {
	state = {
		value: ''
		//isLoading: false
	}

	static propTypes = {
		isOpen: bool,
		onCancel: func
	}

	static defaultProps = {}

	onChange = (fieldName, value) => {
		this.setState({ value })
	}

	onLink = () => {
		const { relinkInvestor, selectedInvestor } = this.props

		const { value } = this.state
		relinkInvestor(selectedInvestor.get('id'), value)
		this.onCancel()

		// Fun Garbage
		// this.setState({isLoading: true});
		// setTimeout(() => {
		// 	this.setState({isLoading: false});
		// 	this.props.onCancel();
		// }, 2000);
	}

	onCancel = () => {
		const { onCancel } = this.props

		// Clear input
		this.setState({ value: '' })

		onCancel && onCancel()
	}

	render = () => {
		const { isOpen } = this.props
		const { isLoading, value } = this.state

		return (
			<InvestorRelinkModal
				isOpen={isOpen}
				isLoading={isLoading}
				controlButtonsRenderer={this.renderControlButtons}
				onChange={this.onChange}
				value={value}
				onLink={this.onLink}
				onCancel={this.onCancel}
			/>
		)
	}
}

function mapStoreToProps(store) {
	return {}
}

const mapActionsToProps = {
	relinkInvestor
}

InvestorRelinkModalContainer = connect(mapStoreToProps, mapActionsToProps)(InvestorRelinkModalContainer)
export default InvestorRelinkModalContainer
