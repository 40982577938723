export const SPACING = {
	0: 0,
	1: 2,
	2: 4,
	3: 8,
	4: 16,
	5: 32,
	6: 64,
	SCROLL_WIDTH: 10
}

const theme = {
	fontSizes: {
		default: '13px',
		h1: '36px',
		h2: '28px',
		h3: '22px',
		h4: '18px'
	},
	iconSizes: {
		xxs: '8px',
		xs: '13px',
		sm: '16px',
		sml: '20px',
		normal: '22px',
		lg: '26px',
		xl: '32px',
		xxl: '64px',
		xxxl: '128px'
	},
	fontFamilies: {
		openSans: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif'
	},
	lineHeights: {
		default: 1.43,
		h1: 1,
		h2: 1.29,
		h3: 'normal',
		h4: 'normal'
	},
	borderRadius: {
		default: '6px'
	},
	margins: {
		default: {
			top: 0,
			bottom: 0
		},
		h1: {
			top: '16px',
			bottom: '8px'
		},
		h2: {
			top: '16px',
			bottom: '8px'
		},
		h3: {
			top: '16px',
			bottom: '8px'
		},
		h4: {
			top: '8px',
			bottom: '8px'
		},
		p: {
			top: 0,
			bottom: '8px'
		}
	},
	bold: {
		default: 'normal',
		h1: 600,
		h2: 600,
		h3: 600,
		h4: 600
	},
	colors: {
		java: '#339999',
		black: '#000000',
		white: '#ffffff',
		lightGrey: '#A5A5A5',
		midGrey: '#6C6C6C', // Used for body text.
		darkGrey: '#3B3C3C',
		solitudeLight: '#ECF0F5',
		solitudeMid: '#E3EBF1',
		solitudeDark: '#D9E1E7',
		purpleLight: '#C39AD6',
		purpleMid: '#9B59B6',
		yellowLight: '#F6D183',
		yellowMid: '#EFB23A',
		catalinaBlue: '#425060',
		persianRed: '#CC3333',
		limeade: '#D6AD32',
		gamboge: '#5B892D',
		red: '#ef5350',
		green: '#00B651',
		orange: '#D7A741',
		active: '#e2ebf1',
		dodgerBlue: '#369ff4',
		lightestGrey: '#f9f9f9',
		buttonBorderGrey: '#c3c3c3',
		muted: '#A5A5A5',
		// disabled: '#E3EBF1',
		disabled: '#ECF0F5',
		placeholder: '#d0d0d0', // Only used for placeholders. OBS. Needs also to be changed manually in "_variables.scss"
		border: 'rgba(0, 0, 0, 0.07)',
		tableAltRowColor: '#fdfdfd',
		limedSpruce: '#41585F',
		notesYellow: '#FFFEC6',
		blueLight: '#eaf0f5',
		silver: '#f8f9fa',
		transparent: 'transparent', // just so we can specify transparent background when color is applied dynamic
		textError: '#f02809',
		borderBlue: '#2684FF',
		contentBlocks: {
			primary: '#D9EDFD',
			secondary: '#FBFBEA',
			disabledLightGrey: '#FAFAFA',
			solitudeMid: '#E3EBF1'
		},
		headerGrey: '#D9D9D9', // Used as grid modal header
		darkHeaderGrey: '#C4C4C4'
	},
	spacing: {
		0: `${SPACING[0]}px`,
		1: `${SPACING[1]}px`,
		2: `${SPACING[2]}px`,
		3: `${SPACING[3]}px`,
		4: `${SPACING[4]}px`,
		5: `${SPACING[5]}px`,
		6: `${SPACING[6]}px`,
		'0-scrollOffset': `${SPACING['SCROLL_WIDTH']}px`,
		'4-scrollOffset': `${SPACING[4] - SPACING['SCROLL_WIDTH']}px`,
		'5-scrollOffset': `${SPACING[5] - SPACING['SCROLL_WIDTH']}px`,
		'6-scrollOffset': `${SPACING[6] - SPACING['SCROLL_WIDTH']}px`
	},
	breakpoints: {
		xxs: 0,
		xs: 320,
		sm: 576,
		md: 768,
		lg: 992,
		xl: 1200
	}
}

export default theme
