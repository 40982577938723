import { fromJS } from 'immutable'
import req from '../../modules/request.module'
import { VOTING_VOTE, VOTING_LIST, VOTING_UPDATE_LOCAL, VOTING_VOTE_USER_UPDATE_LOCAL, VOTING_FETCH } from './types'
import {
	EVENT_TYPE_VOTING_STARTED,
	EVENT_TYPE_VOTING_STOPPED,
	EVENT_TYPE_VOTING_RESULT_CHANGED_BY_ADMIN
} from '/shared/constants'

export function fetchOngoingVoting(meetingId, userId, companyId, callback) {
	return function (dispatch) {
		return req
			.get(`/meetings/public/${meetingId}/votings/ongoing?sub=${userId}&companyId=${companyId}`)
			.then((response) => {
				const voting = fromJS(response.data)
				dispatch({ type: VOTING_FETCH, payload: voting })
				callback?.(voting)
			})
			.catch((err) => {
				callback?.(undefined, err)
			})
	}
}

export function vote(meetingId, votingId, voterId, result, userId, companyId, callback) {
	return function (dispatch) {
		dispatch({ type: VOTING_VOTE_USER_UPDATE_LOCAL, payload: fromJS({ voterId, result }) })

		return req
			.post(`/meetings/public/${meetingId}/vote/${votingId}?sub=${userId}&companyId=${companyId}`, { voterId, result })
			.then((response) => {
				callback && callback()
				dispatch({ type: VOTING_VOTE, payload: fromJS(response.data) })
			})
			.catch((e) => {
				callback && callback()
			})
	}
}

// export function voteBlank(meetingId, votingId, voterIds, userId, companyId) {
// 	return function (dispatch) {
// 		return req.post(`/meetings/public/${meetingId}/vote/${votingId}/blank?sub=${userId}&companyId=${companyId}`, {voterIds}).then((response) => {
// 			dispatch({type: VOTING_VOTE, payload: fromJS(response.data)})
// 		})
// 	}
// }

export function listVotings(meetingId, userId, companyId) {
	return function (dispatch) {
		return req.get(`/meetings/public/${meetingId}/votings?sub=${userId}&companyId=${companyId}`).then((response) => {
			dispatch({ type: VOTING_LIST, payload: fromJS(response.data) })
		})
	}
}

export function clearVoting() {
	return function (dispatch) {
		dispatch({ type: VOTING_UPDATE_LOCAL, payload: null })
	}
}

export function socketEventVotings(eventObject) {
	const { eventName, data } = eventObject

	return function (dispatch) {
		switch (eventName) {
			case EVENT_TYPE_VOTING_STARTED:
			case EVENT_TYPE_VOTING_STOPPED:
				dispatch({ type: VOTING_UPDATE_LOCAL, payload: fromJS(data) })
				break
			case EVENT_TYPE_VOTING_RESULT_CHANGED_BY_ADMIN:
				dispatch({ type: VOTING_LIST, payload: fromJS(data) })
				break
		}
	}
}
