import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func } from 'prop-types'
import { Map, List, fromJS } from 'immutable'
import AgGrid from '../../../dumb-components/shared/ag-grid/ag-grid'
import {
	TRANSACTION_TYPE_EMISSION,
	INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE,
	GRID_MODE_MODAL,
	NUMBER_FORMAT_INTEGER
} from '/shared/constants'
import { SHARES_TRANSACTION_LIMITATIONS } from '../../../constants/shares'
import { listInvestors } from '../../../actions/investors.actions'
import { saveTemporaryTransaction, updateTransaction } from '../../../actions/transaction.actions'
import { withTranslation } from 'react-i18next'
import EmissionExcelDropdownContainer from './header-containers/emission-excel-dropdown.container'

import debounce from 'lodash/debounce'
import { TransparentButton } from '../../../dumb-components/shared/button-v2'
import localeFormatNumber from '../../../modules/format-number'

const de = debounce((func, type, transaction, callback) => {
	func(type, transaction, callback)
}, 1000)

const initialTransaction = fromJS({
	type: TRANSACTION_TYPE_EMISSION,
	investors: {},
	sortedShareholders: true,
	handlerData: {}
})

const gridOptions = {
	suppressCopyRowsToClipboard: true
}

const modalLeftHeader = {
	tid: 'transactions.share_issue.grid.modal.title'
}

class EmissionGridContainer extends Component {
	state = {
		rowData: null,
		gridApi: null,
		cellValueUpdated: false,
		floatingFilter: false,
		sharesLeftHeader: null
	}

	static propTypes = {
		gridModalIsOpen: bool
	}

	componentDidMount = () => {
		const { listInvestors, investors } = this.props
		if (!investors) {
			listInvestors(true)
		}
	}

	componentDidUpdate = (prevProps) => {
		const { investors } = this.props
		const { rowData } = this.state

		if (investors && !rowData) {
			this.setState({ rowData: this.setInitialRowData(investors) })
		}
	}

	setInitialRowData = (investors) => {
		const { tmpTransaction, t } = this.props
		const shareholdersInTmpTransaction = tmpTransaction.getIn(['handlerData', 'shareholders'])
		let rowData = []

		if (!shareholdersInTmpTransaction || shareholdersInTmpTransaction.size === 0) {
			rowData = investors
				.map((investor) => {
					const id = investor.get('id')
					const identifier = this.getInvestorIdentifier(investor)
					const name = investor.getIn(['investorInformation', 'name'])
					const newShares = this.getNumOfShares(investor)
					const shareholder = this.getShareholder(investor)
					const transactionLimitations = this.getTransactionLimitations(shareholder)
					const staticTransactionLimitations = t(
						'shares.transactions.emissions.modal.cell_value.transaction_limitations'
					)
					return { id, identifier, name, newShares, ...transactionLimitations, staticTransactionLimitations }
				})
				.filter((investor) => {
					return investor.identifier
				})
				.toJS()
			return rowData
		}

		const newShareholders = investors.filter((investor) => {
			return !shareholdersInTmpTransaction.some((tmpShareholder) => {
				return investor.get('id') === tmpShareholder.get('investmentId')
			})
		})

		const changedNewShareholders = newShareholders
			.map((shareholder) => {
				const id = shareholder.get('id')
				const identifier = this.getInvestorIdentifier(shareholder)
				const name = shareholder.getIn(['investorInformation', 'name'])
				const newShares = 0
				const transactionLimitations = this.getTransactionLimitations(shareholder)
				const staticTransactionLimitations = t('shares.transactions.emissions.modal.cell_value.transaction_limitations')
				return { id, identifier, name, newShares, ...transactionLimitations, staticTransactionLimitations }
			})
			.filter((investor) => {
				return investor.identifier
			})
			.toJS()

		rowData = shareholdersInTmpTransaction
			.map((shareholder) => {
				const investor = investors.find((inv) => inv.get('id') === shareholder.get('investmentId'))

				if (!investor) {
					return {}
				}

				const id = investor.get('id')
				const identifier = this.getInvestorIdentifier(investor)
				const name = investor.getIn(['investorInformation', 'name'])
				const newShares = shareholder.get('numOfShares') || 0
				const transactionLimitations = this.getTransactionLimitations(shareholder)
				const staticTransactionLimitations = t('shares.transactions.emissions.modal.cell_value.transaction_limitations')
				return { id, identifier, name, newShares, ...transactionLimitations, staticTransactionLimitations }
			})
			.filter((investor) => {
				return investor.identifier
			})
			.toJS()

		rowData = changedNewShareholders ? rowData.concat(changedNewShareholders) : rowData

		return rowData
	}

	getInvestorIdentifier = (investor) => {
		const investorTypeOfOwner = investor.get('investorTypeOfOwner')
		const identifier =
			investorTypeOfOwner === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE
				? investor.getIn(['investorInformation', 'insuranceNr'])
				: investor.getIn(['investorInformation', 'id'])
		return identifier
	}

	getTransactionLimitations = (shareholder) => {
		const { t, transaction, tmpTransaction } = this.props
		let defaultLimitationsOfCurrentSharetype = tmpTransaction.getIn([
			'handlerData',
			'types',
			0,
			'transactionLimitations'
		])
		if (!defaultLimitationsOfCurrentSharetype) {
			const shareType = tmpTransaction.getIn(['handlerData', 'types', 0, 'type'])
			const transactionShareTypes = transaction.getIn(['shareData', 'types'])
			const transactionType = transactionShareTypes.find((type) => {
				return type.get('type') === shareType
			})
			defaultLimitationsOfCurrentSharetype = transactionType ? transactionType.get('transactionLimitations') : List()
		}
		const transactionLimitations = shareholder ? shareholder.get('transactionLimitations') : null
		const parsedTransactionLimitations = {}

		// The already selected transaction limitations for the shareholder
		if (transactionLimitations && transactionLimitations.size > 0) {
			transactionLimitations.forEach((transactionLimitation) => {
				parsedTransactionLimitations[transactionLimitation] = t(
					'shares.transactions.emissions.modal.transaction_limitations.dropdown.yes'
				)
			})
			//apply the default from the sharetype if nothing is specfied for the shareholder
		} else if (defaultLimitationsOfCurrentSharetype && defaultLimitationsOfCurrentSharetype.size > 0) {
			defaultLimitationsOfCurrentSharetype.forEach((transactionLimitation) => {
				parsedTransactionLimitations[transactionLimitation] = t(
					'shares.transactions.emissions.modal.transaction_limitations.dropdown.yes'
				)
			})
		}
		//Finaly add the no answer to remaining drop down items
		const parsedTransactionLimitationsKeys = Object.keys(parsedTransactionLimitations)
		SHARES_TRANSACTION_LIMITATIONS.forEach((transactionLimitation) => {
			const { value } = transactionLimitation
			if (!parsedTransactionLimitationsKeys.includes(value)) {
				parsedTransactionLimitations[value] = t(
					'shares.transactions.emissions.modal.transaction_limitations.dropdown.no'
				)
			}
		})
		return parsedTransactionLimitations
	}

	getNumOfShares = (investor) => {
		const shareholder = this.getShareholder(investor)
		const numOfShares = shareholder ? shareholder.get('numOfShares') : 0

		return numOfShares
	}

	getShareholder = (investor) => {
		const { tmpTransaction } = this.props
		const shareholders = tmpTransaction.getIn(['handlerData', 'shareholders'], List())
		const shareholder =
			shareholders &&
			shareholders.find((shareholder) => {
				return shareholder.get('investmentId') === investor.get('id')
			})

		return shareholder
	}

	convertRowDataToShareholderData = (rowData, shareType) => {
		const { t } = this.props
		const { id, newShares } = rowData
		const parsedNewShares = parseInt(newShares)

		let shareholder = Map({
			investmentId: id,
			type: shareType,
			numOfShares: parsedNewShares || undefined,
			transactionLimitations: List()
		})

		SHARES_TRANSACTION_LIMITATIONS.forEach((transactionLimitation) => {
			const transactionLimitationValue = transactionLimitation.value
			const rowValue = rowData[transactionLimitationValue]

			// Legacy - store all chosen transaction limitations in array
			if (rowValue && rowValue === t('shares.transactions.emissions.modal.transaction_limitations.dropdown.yes')) {
				shareholder = shareholder.update('transactionLimitations', (tl) => tl.push(transactionLimitation.value))
			}

			// shareholder = shareholder.set(transactionLimitationValue, rowValue)
		})

		return shareholder
	}

	onGridReady = (params) => {
		this.setState({
			gridApi: params.api
		})
	}

	onRowDragEnd = ({ api }) => {
		const { updateTransaction, saveTemporaryTransaction } = this.props
		let { tmpTransaction } = this.props
		let shareholders = List()
		const shareType = tmpTransaction.getIn(['handlerData', 'types', 0, 'type'])
		api.forEachNode((rowNode) => {
			const shareholder = this.convertRowDataToShareholderData(rowNode.data, shareType)
			shareholders = shareholders.push(shareholder)
		})

		tmpTransaction = tmpTransaction.setIn(['handlerData', 'shareholders'], shareholders)

		updateTransaction(TRANSACTION_TYPE_EMISSION, tmpTransaction)
		de(saveTemporaryTransaction, TRANSACTION_TYPE_EMISSION, tmpTransaction)
	}

	onCellValueChanged = ({ api }) => {
		const { updateTransaction, saveTemporaryTransaction } = this.props
		let { tmpTransaction } = this.props
		const { gridApi, rowData } = this.state

		let shareholders = List()
		const shareType = tmpTransaction.getIn(['handlerData', 'types', 0, 'type'])

		gridApi.forEachNode((rowNode) => {
			const shareholder = this.convertRowDataToShareholderData(rowNode.data, shareType)
			shareholders = shareholders.push(shareholder)
		})

		tmpTransaction = tmpTransaction.setIn(['handlerData', 'shareholders'], shareholders)

		updateTransaction(TRANSACTION_TYPE_EMISSION, tmpTransaction)
		de(saveTemporaryTransaction, TRANSACTION_TYPE_EMISSION, tmpTransaction, this.setCellValueUpdatedTimer)
	}

	setCellValueUpdatedTimer = () => {
		clearTimeout()
		this.setState({ cellValueUpdated: true })

		setTimeout(() => this.setState({ cellValueUpdated: false }), 2000)
	}

	calculateSharesLeft = (editorsValue, prevValue) => {
		const { tmpTransaction } = this.props
		const { sharesLeftHeader } = this.state
		const shareholders = tmpTransaction.getIn(['handlerData', 'shareholders'], List())
		const shareType = tmpTransaction.getIn(['handlerData', 'types', 0])
		if (!shareType) {
			return 0
		}

		let numOfSharesLeft = shareType.get('numOfShares') || 0

		if (sharesLeftHeader !== 0 || !sharesLeftHeader) {
			shareholders.forEach((shareholder) => {
				if (shareType.get('type') === shareholder.get('type')) {
					numOfSharesLeft -= shareholder.get('numOfShares') || 0
				}
			})
		}

		if (editorsValue || prevValue > 0) {
			let newNumOfSharesLeft = sharesLeftHeader || sharesLeftHeader === 0 ? sharesLeftHeader : numOfSharesLeft

			if (editorsValue > prevValue) {
				newNumOfSharesLeft -= editorsValue - prevValue
			} else {
				newNumOfSharesLeft += prevValue - editorsValue
			}

			return newNumOfSharesLeft
		}

		return numOfSharesLeft
	}

	columnDefs = () => {
		const { t, isUploadingExcel } = this.props
		const { sharesLeftHeader } = this.state
		const sharesLeft = sharesLeftHeader || sharesLeftHeader === 0 ? sharesLeftHeader : this.calculateSharesLeft()
		const newSharesHeaderClass =
			sharesLeft === 0 ? 'ag-right-aligned-header' : ['text--error', 'ag-right-aligned-header']

		return [
			{
				field: 'name',
				headerName: t('shares.transactions.emissions.modal.header.name'),
				rowDrag: true,
				flex: 1,
				minWidth: 300
			},
			{
				field: 'identifier',
				headerName: t('shares.transactions.emissions.modal.header.id_insurance_number'),
				flex: 2,
				minWidth: 200
			},
			{
				field: 'newShares',
				editable: !isUploadingExcel,
				singleClickEdit: true,
				type: 'rightAligned',
				headerName: this.renderHeaderTid(),
				headerClass: newSharesHeaderClass,
				onCellValueChanged: this.onCellValueChanged,
				cellEditor: 'numericEditor',
				suppressPaste: true,
				flex: 1,
				minWidth: 200,
				valueFormatter: (params) => {
					return localeFormatNumber(params.value, NUMBER_FORMAT_INTEGER)
				},
				cellEditorParams: {
					updateSharesLeft: this.updateSharesLeft
				}
			},
			{
				field: 'staticTransactionLimitations',
				headerName: t('shares.transactions.emissions.modal.group_header.transaction_limitations'),
				cellRenderer: 'agGroupCellRenderer',
				flex: 1,
				minWidth: 200,
				filter: false,
				floatingFilter: this.state.floatingFilter
			}
		]
	}

	detailCellRendererParams = () => {
		const { t, isUploadingExcel } = this.props

		return {
			refreshStrategy: 'nothing',
			detailGridOptions: {
				stopEditingWhenCellsLoseFocus: true,
				columnDefs: [
					{
						field: SHARES_TRANSACTION_LIMITATIONS[0].value,
						headerName: t(SHARES_TRANSACTION_LIMITATIONS[0].label),
						singleClickEdit: true,
						editable: !isUploadingExcel,
						cellEditor: 'agRichSelectCellEditor',
						cellEditorParams: {
							values: this.renderTransactionLimitationsEditor(),
							cellHeight: 48
						},
						onCellValueChanged: this.onCellValueChanged,
						cellEditorPopup: true,
						flex: 1
					},
					{
						field: SHARES_TRANSACTION_LIMITATIONS[1].value,
						headerName: t(SHARES_TRANSACTION_LIMITATIONS[1].label),
						singleClickEdit: true,
						editable: !isUploadingExcel,
						cellEditor: 'agRichSelectCellEditor',
						cellEditorParams: {
							values: this.renderTransactionLimitationsEditor(),
							cellHeight: 48
						},
						onCellValueChanged: this.onCellValueChanged,
						cellEditorPopup: true,
						flex: 1
					},
					{
						field: SHARES_TRANSACTION_LIMITATIONS[2].value,
						headerName: t(SHARES_TRANSACTION_LIMITATIONS[2].label),
						singleClickEdit: true,
						editable: !isUploadingExcel,
						cellEditor: 'agRichSelectCellEditor',
						cellEditorParams: {
							values: this.renderTransactionLimitationsEditor(),
							cellHeight: 48
						},
						onCellValueChanged: this.onCellValueChanged,
						cellEditorPopup: true,
						flex: 1
					},
					{
						field: SHARES_TRANSACTION_LIMITATIONS[3].value,
						headerName: t(SHARES_TRANSACTION_LIMITATIONS[3].label),
						singleClickEdit: true,
						editable: !isUploadingExcel,
						cellEditor: 'agRichSelectCellEditor',
						cellEditorParams: {
							values: this.renderTransactionLimitationsEditor(),
							cellHeight: 48
						},
						onCellValueChanged: this.onCellValueChanged,
						cellEditorPopup: true,
						flex: 1
					},
					{
						field: SHARES_TRANSACTION_LIMITATIONS[4].value,
						headerName: t(SHARES_TRANSACTION_LIMITATIONS[4].label),
						singleClickEdit: true,
						editable: !isUploadingExcel,
						cellEditor: 'agRichSelectCellEditor',
						cellEditorParams: {
							values: this.renderTransactionLimitationsEditor(),
							cellHeight: 48
						},
						onCellValueChanged: this.onCellValueChanged,
						cellEditorPopup: true,
						flex: 1
					}
				],
				defaultColDef: {
					resizable: true,
					menuTabs: ['columnsMenuTab'],
					columnsMenuParams: {
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
			},
			getDetailRowData: (params) => {
				params.successCallback([params.data])
			}
		}
	}

	getRowNodeId = (data) => {
		return data.id
	}

	onUploadCallback = () => {
		const { investors } = this.props

		this.setState({ rowData: this.setInitialRowData(investors) })
	}

	renderTransactionLimitationsEditor = () => {
		const { t } = this.props

		return [
			t('shares.transactions.emissions.modal.transaction_limitations.dropdown.yes'),
			t('shares.transactions.emissions.modal.transaction_limitations.dropdown.no')
		]
	}

	renderHeaderTid = () => {
		const { i18n } = this.props
		const { sharesLeftHeader } = this.state
		const sharesLeft = sharesLeftHeader || sharesLeftHeader === 0 ? sharesLeftHeader : this.calculateSharesLeft()

		return (
			i18n.messages['shares.transactions.emissions.modal.header.new_shares'] +
			' ' +
			i18n.messages['N_left'].replace('{num}', localeFormatNumber(sharesLeft, NUMBER_FORMAT_INTEGER) || 0)
		)
	}

	getFilterButtonTid = () => {
		return this.state.floatingFilter
			? 'ag_grid.panel.dropdown_menu.filter.item.hide_filter'
			: 'ag_grid.panel.dropdown_menu.filter.item.show_filter'
	}

	displayFloatingFilter = () => {
		this.setState((prevState) => ({ floatingFilter: !prevState.floatingFilter }))
	}

	updateSharesLeft = (valueWithoutSpace, prevValue) => {
		const sharesLeft = this.calculateSharesLeft(valueWithoutSpace, prevValue)

		this.setState({ sharesLeftHeader: sharesLeft })
	}

	renderRightHeaderComponent = () => {
		return (
			<>
				<TransparentButton
					onClick={this.displayFloatingFilter}
					tid={this.getFilterButtonTid()}
					textColor='white'
					hasUnderline
				/>
				<EmissionExcelDropdownContainer onUploadCallback={this.onUploadCallback} />
			</>
		)
	}

	render = () => {
		const { gridModalIsOpen, t, isUploadingExcel } = this.props
		const { rowData, cellValueUpdated, floatingFilter } = this.state
		const dropdownManageTid = t('shares.transactions.emissions.modal.cell_value.transaction_limitations.manage')

		return (
			<AgGrid
				rowDragEnabled={true}
				floatingFilter={floatingFilter}
				onRowDragEnd={this.onRowDragEnd}
				rowData={rowData}
				editors={['numericEditor']}
				columnDefs={this.columnDefs()}
				detailCellRendererParams={this.detailCellRendererParams()}
				onGridReady={this.onGridReady}
				getRowNodeId={this.getRowNodeId}
				dropdownManageTid={dropdownManageTid}
				gridOptions={gridOptions}
				stopEditingWhenCellsLoseFocus={true}
				isGridModalOpen={gridModalIsOpen}
				cellValueUpdated={cellValueUpdated}
				rightHeaderComponent={this.renderRightHeaderComponent}
				isUploadingExcel={isUploadingExcel}
				masterDetail
				mode={GRID_MODE_MODAL}
				modalLeftHeader={modalLeftHeader}
				notSortable
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		tmpTransaction: store.transaction.getIn(['tmpTransaction', TRANSACTION_TYPE_EMISSION], initialTransaction),
		investors: store.investors.get('list'),
		transaction: store.transaction.get('transaction'),
		i18n: store.i18n,
		isUploadingExcel: store.notify.isUploadingExcel
	}
}

const mapActionsToProps = {
	listInvestors,
	saveTemporaryTransaction,
	updateTransaction
}

export default withTranslation()(connect(mapStoreToProps, mapActionsToProps)(EmissionGridContainer))
