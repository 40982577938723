import React from 'react'
import Box from '@mui/material/Box'
import { DropDown } from '../../../../mui-components/dropdown/DropDown'
import IconButton from '../../../../mui-components/button/icon-button'
import { ColumnViewMenuItem } from './menu-items/ColumnViewMenuItem'
import { FloatingFilterMenuItem } from './menu-items/FloatingFilterMenuItem'
import { TeamsFilterMenuItem } from './menu-items/TeamsFilterMenuItem'
import { usePeopleModalContext } from '../people-modal.context'

export const HeaderMenu = () => {
	const { showColumnToggle } = usePeopleModalContext()

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', marginRight: 6 }}>
			<DropDown
				button={({ params }) => <IconButton size='sml' noBorder icon='faEllipsisV' {...params} />}
				alignMenu='right'>
				<FloatingFilterMenuItem />
				<TeamsFilterMenuItem />
				{showColumnToggle && <ColumnViewMenuItem />}
			</DropDown>
		</Box>
	)
}
