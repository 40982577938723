import React, { PureComponent } from 'react'
import { string, bool, func, object, oneOf, number } from 'prop-types'
import styled, { css } from 'styled-components'
import { Editor } from 'react-draft-wysiwyg'
import { withTranslation } from 'react-i18next'
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Icon from '../icon/icon'
import { rgba } from 'polished'

// Docs: https://jpuri.github.io/react-draft-wysiwyg/#/docs

const StyledFullscreenBody = styled.div`
	position: fixed;
	display: flex;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	height: 100vh;
	width: 100vw;
	background-color: ${(props) => (props.mode === 'notes' ? props.theme.colors.notesYellow : props.theme.colors.white)};
`

const StyledRoot = styled.div`
	background-color: ${(props) => {
		if (props.readOnly) {
			return props.theme.colors.disabled
		}

		return props.mode === 'notes' ? props.theme.colors.notesYellow : props.theme.colors.white
	}};
	width: 100%;

	${(props) =>
		props.includePadding &&
		props.inline &&
		css`
			padding: ${props.theme.spacing[4]};
		`}

	.wrapper-class {
		display: flex;
		flex: 1;
		flex-direction: column;
		width: 100%;

		${(props) =>
			!props.borderless &&
			css`
				border: 1px solid #c3c3c3;
			`};

		${(props) =>
			props.inline &&
			css`
				border: none;
				position: relative;
			`};
	}

	.toolbar-class {
		padding: 0;
		display: flex;
		align-items: center;
		border: none;
		height: ${(props) => props.toolbarHeight}px;
		margin: 0;
		border-bottom: none;

		${(props) =>
			props.inline &&
			css`
				position: absolute;
				z-index: 900;
				visibility: hidden;
				transform: scale(0);
				border: 1px solid ${(props) => props.theme.colors.border};
				box-shadow: 2px 2px 5px 0 ${(props) => rgba(props.theme.colors.darkGrey, 0.5)};
			`}

		${(props) =>
			props.inline &&
			!props.toolbarHidden &&
			css`
				top: ${(props) => props.toolbarPosition.top}px;
				${(props) =>
					props.toolbarPosition.left !== undefined &&
					css`
						left: ${(props) => props.toolbarPosition.left}px;
					`}
				${(props) =>
					props.toolbarPosition.right !== undefined &&
					css`
						right: ${(props) => props.toolbarPosition.right}px;
					`}
			visibility: visible;
				transform: scale(1);
				transition: transform 0.15s cubic-bezier(0.3, 1.2, 0.2, 1);
			`}
	}

	.editor-class {
		flex: 1;
		height: inherit;
		padding: 0 1em;

		${(props) =>
			props.inline &&
			css`
				padding-left: ${(props) => props.theme.spacing[2]};
				padding-right: ${(props) => props.theme.spacing[2]};
			`}
	}

	.group-wrapper {
		margin: 0;
		border-right: none;
		height: 100%;
	}

	.option-wrapper {
		border: none;

		> span[class*='fa-'] {
			font-size: 17px;
		}

		:hover {
			box-shadow: none;
		}

		&.rdw-option-active {
			box-shadow: none;
		}
	}

	.public-DraftEditor-content {
		border: 1px solid white;
	}

	.DraftEditor-root {
		width: 100%;
	  }

	.DraftEditor-editorContainer {
		width: 100%;
	}

	${(props) =>
		props.readOnly &&
		css`
			.rdw-editor-toolbar {
				background-color: ${(props) => props.theme.colors.disabled};
			}

			.rdw-option-wrapper {
				background-color: ${(props) => props.theme.colors.disabled};
			}

			.public-DraftEditor-content {
				border-color: ${(props) => props.theme.colors.disabled};
			}
		`}

	${(props) =>
		props.mode === 'notes' &&
		!props.readOnly &&
		css`
			.rdw-editor-toolbar {
				background-color: ${(props) => props.theme.colors.notesYellow};
			}

			.rdw-option-wrapper {
				background-color: ${(props) => props.theme.colors.notesYellow};
			}

			.public-DraftEditor-content {
				border-color: ${(props) => props.theme.colors.notesYellow};
			}
		`}

	.public-DraftEditorPlaceholder-root {
		color: ${(props) => props.theme.colors.placeholder};
	}

	.rdw-link-modal {
		min-height: 205px;
		height: auto;
	}

	.public-DraftStyleDefault-block {
		${(props) =>
			props.inline &&
			css`
				margin: 0;
			`};
	}
`

const StyledEditor = styled.div`
	display: flex;
	cursor: text;
	min-height: ${(props) => props.height};

	/* Fix for z-index issue for placeholder */
	.public-DraftEditorPlaceholder-root {
		z-index: 0;
	}
`

class EditorRTE extends PureComponent {
	static propTypes = {
		editorState: object,
		onChange: func,
		isFullscreen: bool,
		mode: oneOf(['notes']),
		editorHeight: string,
		readOnly: bool,
		placeholderTid: string,
		borderless: bool,
		stripPastedStyles: bool,
		toolbarHidden: bool,
		inline: bool,
		setEditorRef: func,
		setWrapperRef: func,
		onShowToolbar: func,
		toolbarPosition: object,
		toolbarHeight: number,
		includePadding: bool
	}

	static defaultProps = {
		editorHeight: '200px',
		borderless: false,
		stripPastedStyles: true,
		toolbarHidden: false,
		inline: false,
		toolbarPosition: {},
		includePadding: false
	}

	renderFullscreenOptionButton = () => {
		const { toggleFullscreen, isFullscreen } = this.props
		const icon = isFullscreen ? 'faCompressArrowsAlt' : 'faExpandArrowsAlt'
		return (
			<div className='rdw-link-wrapper group-wrapper' onClick={toggleFullscreen}>
				<div className='rdw-option-wrapper option-wrapper'>
					<Icon icon={icon} type='solid' />
				</div>
			</div>
		)
	}

	renderEditor = () => {
		const {
			editorState,
			onChange,
			onBlur,
			onFocus,
			readOnly,
			placeholderTid,
			t,
			stripPastedStyles,
			setEditorRef
		} = this.props

		const toolbarOptions = {
			options: ['inline', 'list', 'link'],
			inline: {
				className: 'group-wrapper',
				options: ['bold', 'italic', 'underline'],
				bold: {
					className: 'option-wrapper'
				},
				italic: {
					className: 'option-wrapper'
				},
				underline: {
					className: 'option-wrapper'
				}
			},
			list: {
				className: 'group-wrapper',
				options: ['unordered', 'ordered'],
				unordered: {
					className: 'option-wrapper'
				},
				ordered: {
					className: 'option-wrapper'
				}
			},
			link: {
				className: 'group-wrapper',
				defaultTargetOption: '_blank',
				options: ['link'],
				link: {
					className: 'option-wrapper'
				}
			}
		}

		return (
			<Editor
				wrapperClassName='wrapper-class'
				editorClassName='editor-class'
				toolbarClassName='toolbar-class'
				editorState={editorState}
				onEditorStateChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				toolbar={toolbarOptions}
				toolbarCustomButtons={[this.renderFullscreenOptionButton()]}
				readOnly={readOnly}
				placeholder={placeholderTid ? t(placeholderTid) : undefined}
				stripPastedStyles={stripPastedStyles}
				ref={setEditorRef}
				spellCheck={true}
			/>
		)
	}

	render = () => {
		const {
			isFullscreen,
			mode,
			editorHeight,
			readOnly,
			borderless,
			inline,
			setWrapperRef,
			editorRef,
			wrapperRef,
			onShowToolbar,
			toolbarPosition,
			toolbarHidden,
			toolbarHeight,
			includePadding
		} = this.props

		return (
			<StyledRoot
				mode={mode}
				readOnly={readOnly}
				borderless={borderless}
				inline={inline}
				toolbarPosition={toolbarPosition}
				toolbarHidden={toolbarHidden}
				toolbarHeight={toolbarHeight}
				includePadding={includePadding}>
				{isFullscreen && <StyledFullscreenBody mode={mode}>{this.renderEditor()}</StyledFullscreenBody>}
				{!isFullscreen && (
					<StyledEditor height={editorHeight} ref={setWrapperRef} onContextMenu={onShowToolbar}>
						{this.renderEditor()}
					</StyledEditor>
				)}
			</StyledRoot>
		)
	}
}

export default withTranslation()(EditorRTE)
