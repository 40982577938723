import React, { PureComponent } from 'react'
import { List, Map } from 'immutable'
import { object, string, func, bool } from 'prop-types'
import styled, { css } from 'styled-components'
import TreeMenu from '../../shared/tree-menu/tree-menu'
import TreeMenuItem from '../../shared/tree-menu-item/tree-menu-item'
import Text from '../../shared/text/text'
import Icon from '../../shared/icon/icon'
import { Margin } from 'styled-components-spacing'
import ScrollView from '../../shared/layout/scroll-view/scroll-view'
import Badge from '../../shared/badge/badge'
import i18nhelper from '../../../components/helpers/i18n.helper'
import Tooltip from '../../shared/tooltip/tooltip'
import { isEllipsisActive } from '../../shared/tooltip/tooltip.helpers'

const StyledTreeMenu = styled(TreeMenu)`
	height: 100%;
`

const StyledContentWrapper = styled.div`
	flex: 1;
	width: 100%;

	> span {
		flex: 1;
		width: 100%;
	}
`

const StyledMenuWrapper = styled.div`
	flex: 1;
	height: 100%;

	${(props) =>
		props.bordered &&
		css`
			border: 1px solid ${(props) => props.theme.colors.border};
		`}
`

const StyledFolderItem = styled.div`
	background-color: ${(props) => props.isSelected && props.theme.colors.lightestGrey};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	height: 45px;
	padding-left: ${(props) => props.level * 16 + 'px'};
`

const StyledNameBase = styled.div`
	flex: 1;
	padding-left: ${(props) => props.theme.spacing[3]};
	padding-right: ${(props) => props.theme.spacing[3]};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`

const StyledFilesBase = styled.div`
	width: 64px;
	padding-left: ${(props) => props.theme.spacing[2]};
	padding-right: ${(props) => props.theme.spacing[2]};
`

const StyledSelectedBase = styled.div`
	width: ${(props) => (props.renderHoverText ? '200px' : '50px')};
	padding-left: ${(props) => props.theme.spacing[2]};
	padding-right: ${(props) => props.theme.spacing[4]};
`

const StyledNameCell = styled(StyledNameBase)``

const StyledFilesCell = styled(StyledFilesBase)``

const StyledSelectedCell = styled(StyledSelectedBase)`
	text-align: right;
`

const StyledSpanBtn = styled.span`
	cursor: pointer;
	padding-right: ${(props) => props.theme.spacing[2]};
`

const StyledFolderMenuSection = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeDark};
	padding-left: ${(props) => props.theme.spacing[4]};
	padding-top: ${(props) => props.theme.spacing[3]};
	padding-bottom: ${(props) => props.theme.spacing[3]};
`

const StyledBadge = styled(Badge)`
	transition: all 0s;
	visibility: hidden;

	${StyledFolderItem}:hover & {
		visibility: visible;
	}
`

export default class FolderTree extends PureComponent {
	static propTypes = {
		folders: object,
		selected: string,
		isInvestorMode: bool,
		renderHoverText: bool,
		onOpen: func,
		onClose: func,
		onSelect: func,
		userLang: string,
		region: object,
		disabled: bool,
		onItemRef: func,
		permission: string,
		destinationBadgeTid: string
	}

	static defaultProps = {
		folders: List(),
		isInvestorMode: false,
		renderHoverText: false,
		disabled: false
	}

	nameRefs = Map()

	onItemClick = (folder) => {
		const { onSelect, disabled, isInvestorMode, permission } = this.props
		// Before we used to check if ALLOW_CREATE is true, but if ALLOW_CREATE is true that means that the user is also allowed to create new folder/category/group which is not always the case.
		// Therefor we change to check ALLOW_CREATE_TASK, ALLOW_CREATE_MEETING, ALLOW_CREATE_DOCUMENT instead.
		const preventMove = !folder.get(permission) && folder.get('id') && !isInvestorMode
		!preventMove && !disabled && onSelect && onSelect(folder)
	}

	onClose = (folderId, e) => {
		e.stopPropagation()
		const { onClose } = this.props
		onClose && onClose(folderId)
	}

	onOpen = (folderId, e) => {
		e.stopPropagation()
		const { onOpen } = this.props
		onOpen && onOpen(folderId)

		// Needed for ellipsis tooltips to work as they cannot be calculated when item is hidden
		this.forceUpdate()
	}

	onNameRef = (id, ref) => {
		if (!id || !ref) {
			return
		}

		this.nameRefs = this.nameRefs.set(id, ref)
	}

	renderName = (folder) => {
		const { userLang, region, disabled } = this.props
		const name = i18nhelper.getTranslatedValue(folder.get('name'), userLang, region)
		const isOpen = folder.get('isOpen')
		const hasChildren = folder.get('children') ? true : false
		const id = folder.get('id')

		return (
			<StyledNameCell ref={this.onNameRef.bind(null, id)}>
				{hasChildren && isOpen && (
					<StyledSpanBtn onClick={this.onClose.bind(null, id)}>
						<Margin right={2} as='span'>
							<Icon icon='faCaretDown' size='xs' type='solid' />
						</Margin>
					</StyledSpanBtn>
				)}

				{hasChildren && !isOpen && (
					<StyledSpanBtn onClick={this.onOpen.bind(null, id)}>
						<Margin right={2} as='span'>
							<Icon icon='faCaretRight' size='xs' type='solid' />
						</Margin>
					</StyledSpanBtn>
				)}

				<Text singleLine={true} color={disabled ? 'muted' : undefined}>
					{name}
				</Text>
			</StyledNameCell>
		)
	}

	renderContent = (folder, level) => {
		const { isInvestorMode, selected, renderHoverText, userLang, region, permission, destinationBadgeTid } = this.props
		const id = folder.get('id')
		// Before we used to check if ALLOW_CREATE is true, but if ALLOW_CREATE is true that means that the user is also allowed to create new folder/category/group which is not always the case.
		// Therefor we change to check ALLOW_CREATE_TASK, ALLOW_CREATE_MEETING, ALLOW_CREATE_DOCUMENT instead.
		const disabled = (!folder.get(permission) && folder.get('id') && !isInvestorMode) || folder.get('disabled')
		const isSelected = selected === id && !disabled
		const name = i18nhelper.getTranslatedValue(folder.get('name'), userLang, region)
		const nameRef = this.nameRefs.get(id)
		const ellipsisTooltipActive = nameRef ? isEllipsisActive(nameRef) : false

		if (isInvestorMode) {
			return (
				<StyledContentWrapper>
					<Tooltip text={name} active={ellipsisTooltipActive}>
						<StyledFolderItem level={level} isSelected={isSelected}>
							{this.renderName(folder)}
						</StyledFolderItem>
					</Tooltip>
				</StyledContentWrapper>
			)
		}

		const numOfFiles = folder.get('numOfFiles')

		return (
			<StyledFolderItem level={level} disabled={disabled}>
				{this.renderName(folder)}

				<StyledFilesCell>
					<Text>{numOfFiles}</Text>
				</StyledFilesCell>

				{!disabled && (
					<StyledSelectedCell renderHoverText={renderHoverText}>
						{!isSelected && renderHoverText && (
							<StyledBadge
								isStatic={true}
								isSelected={isSelected}
								tid={destinationBadgeTid}
								maxWidth={200}
								bgColor='white'
							/>
						)}
						{isSelected && <Icon icon='faCheck' size='sml' type='solid' color='green' />}
					</StyledSelectedCell>
				)}
			</StyledFolderItem>
		)
	}

	renderFolderItem = (level, folder, index) => {
		const { onItemRef } = this.props
		const children = folder.get('children')
		const isOpen = folder.get('isOpen') ? true : false

		return (
			<TreeMenuItem
				onClick={this.onItemClick.bind(null, folder)}
				onRef={onItemRef && onItemRef.bind(null, folder.get('id'))}
				contentComponent={this.renderContent.bind(null, folder, level)}
				isOpen={isOpen}
				key={index}>
				{children && children.map(this.renderFolderItem.bind(null, level + 1))}
			</TreeMenuItem>
		)
	}

	render = () => {
		const { isInvestorMode, folders } = this.props
		if (isInvestorMode) {
			return (
				<StyledMenuWrapper>
					<StyledTreeMenu>
						<StyledFolderMenuSection>
							<Text tid='generic.folder_name' color='muted' />
						</StyledFolderMenuSection>
						<ScrollView noLeftMargin={true} noRightMargin={true}>
							{folders.map(this.renderFolderItem.bind(null, 0))}
						</ScrollView>
					</StyledTreeMenu>
				</StyledMenuWrapper>
			)
		}

		return (
			<StyledMenuWrapper bordered>
				<TreeMenu>{folders.map(this.renderFolderItem.bind(null, 0))}</TreeMenu>
			</StyledMenuWrapper>
		)
	}
}
