// Node modules
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { array } from 'prop-types'
import { withRouter } from '../../interfaces/router'
import history from '../../interfaces/history'

// Components
import Breadcrumbs from '../../dumb-components/shared/breadcrumbs/breadcrumbs'
import BreadcrumbsItem from '../../dumb-components/shared/breadcrumbs/breadcrumbs-item'
import Text from '../../dumb-components/shared/text/text'
import { Margin } from 'styled-components-spacing'

class BreadcrumbsContainer extends Component {
	static propTypes = {
		sections: array
	}

	getComponentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('transactions')
		return pathArray[0] + 'transactions'
	}

	onRootClick = () => {
		const basePath = this.getComponentBasePath()

		history.push(basePath)
	}

	renderBreadcrumbsItems = () => {
		const { sections, selectedSection } = this.props

		// Nothing selected
		if (selectedSection === null || selectedSection === -1) {
			return <BreadcrumbsItem itemName={<Text tid='shares.transactions.breadcrumbs.title' />} isStatic={true} />
		}

		return (
			<div>
				<BreadcrumbsItem
					itemName={<Text tid='shares.transactions.breadcrumbs.title' />}
					onBtnClick={this.onRootClick}
				/>
				<BreadcrumbsItem
					itemName={<Text tid={sections[selectedSection].title} />}
					isStatic={true}
					breadcrumbsMaxWidth='unset'
				/>
			</div>
		)
	}

	render = () => {
		return (
			<Margin bottom={4}>
				<Breadcrumbs>{this.renderBreadcrumbsItems()}</Breadcrumbs>
			</Margin>
		)
	}
}

function mapStoreToProps(store) {
	return {}
}

const mapActionsToProps = {}

export default withRouter(connect(mapStoreToProps, mapActionsToProps)(BreadcrumbsContainer))
