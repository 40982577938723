import React, { PureComponent } from 'react'
import { string, bool, func } from 'prop-types'
import styled, { css } from 'styled-components'
import ListItem from '../../shared/list-item/list-item'
import moment from '../../../modules/moment.module'
import Text from '../../shared/text/text'
import Badge from '../../shared/badge/badge-new'
import { getMeetingStatusColor } from '../../../components/helpers/meeting.helper'

import { MEETING_TYPE_SMART, MEETING_TYPE_STANDARD } from '/shared/constants'

const StyledListItem = styled(ListItem)`
	margin-bottom: ${(props) => props.theme.spacing[2]};

	:last-child {
		margin-bottom: ${(props) => props.theme.spacing[4]};
	}
`

const StyledMeetingItem = styled.div`
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	height: 50px;
	border-left: 16px solid transparent;
	border-left-color: ${(props) => getMeetingStatusColor(props)};

	${(props) =>
		props.onClick &&
		css`
			cursor: pointer;
		`}
`

const StyledContent = styled.div`
	flex: 1;
	padding: ${(props) => props.theme.spacing[3]} ${(props) => (props.hasBadge ? '144px' : props.theme.spacing[4])}
		${(props) => props.theme.spacing[3]} ${(props) => props.theme.spacing[4]};
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	min-width: 0;
`

const StyledTitle = styled.div`
	display: flex;
`

const StyledSubtitle = styled.div`
	display: flex;
`

const StyledSubDate = styled.div`
	min-width: 93px;
`

const StyledDate = styled.div`
	text-align: center;
	padding-left: ${(props) => props.theme.spacing[4]};
	padding-right: ${(props) => props.theme.spacing[4]};
	border-right: 1px solid ${(props) => props.theme.colors.active};
	width: 105px;
`

export default class MeetingItem extends PureComponent {
	static propTypes = {
		id: string,
		name: string,
		startDate: string,
		endDate: string,
		active: bool,
		showWarning: bool,
		groupPath: string,
		groupName: string,
		onBadgeClick: func,
		onMeetingClick: func,
		renderEditor: func,
		inEditMode: bool,
		agendaPublished: bool,
		protocolPublished: bool,
		meetingIsFinished: bool,
		isSecretary: bool,
		archived: bool,
		protocolUploaded: bool
	}

	formatDate = (dateStr, dateType) => {
		switch (dateType) {
			case 'date':
				return moment(dateStr).format('L')
			case 'time':
				return moment(dateStr).format('LT')
		}
	}

	getScheduledAt = () => {
		const { startDate, endDate } = this.props
		let scheduledAt = ''

		if (startDate || endDate) {
			scheduledAt = '@ '
			if (startDate) {
				scheduledAt += this.formatDate(startDate, 'time')
			}

			scheduledAt += ' - '

			if (endDate) {
				scheduledAt += this.formatDate(endDate, 'time')
			}
		}

		return scheduledAt
	}

	renderMeetingWithDate = () => {
		const scheduledAt = this.getScheduledAt()

		return (
			<>
				<Text color='muted' tid='meeting' singleLine />
				&nbsp;
				{scheduledAt && (
					<StyledSubDate>
						<Text color='muted' singleLine>
							{scheduledAt}
						</Text>
					</StyledSubDate>
				)}
			</>
		)
	}

	renderSubtitleStandard = () => {
		const { protocolUploaded } = this.props

		if (protocolUploaded) {
			return <Text color='muted' tid='meetings.list.protocol' singleLine />
		}

		return this.renderMeetingWithDate()
	}

	renderSubtitleSmart = () => {
		const { archived, goToSection, isSecretary, protocolPublished, meetingIsFinished } = this.props

		if (!archived && !goToSection && !isSecretary && !protocolPublished && !meetingIsFinished) {
			return null
		}

		if (archived) {
			return (
				<>
					<Text color='muted' tid='meetings.list.meeting_archived' />
					&nbsp;-&nbsp;
				</>
			)
		}

		if (goToSection === 'meeting') {
			if (meetingIsFinished) {
				return <Text color='muted' tid='meetings.meeting.list.status.agenda' singleLine />
			}

			return this.renderMeetingWithDate()
		}

		const subTitleTid =
			isSecretary && !protocolPublished ? 'meetings.list.unpublished_protocol' : 'meetings.list.protocol'
		return <Text color='muted' tid={subTitleTid} signleLine />
	}

	render = () => {
		const {
			id,
			name,
			startDate,
			endDate,
			active,
			showWarning,
			groupId,
			groupPath,
			groupName,
			onBadgeClick,
			onMeetingClick,
			renderEditor,
			inEditMode,
			agendaPublished,
			protocolPublished,
			meetingIsFinished,
			meetingType
		} = this.props
		const hasBadge = groupName ? true : false

		const meetingItemProps = {
			showWarning,
			agendaPublished,
			protocolPublished,
			meetingIsFinished,
			meetingType,
			endDate,
			onClick: () => onMeetingClick && onMeetingClick(id)
		}

		return (
			<StyledListItem active={active} id={id} hoverColor='lightestGrey'>
				<StyledMeetingItem {...meetingItemProps}>
					<StyledDate>{startDate ? this.formatDate(startDate, 'date') : '-'}</StyledDate>

					<StyledContent hasBadge={hasBadge}>
						<StyledTitle>
							{!inEditMode && (
								<React.Fragment>
									{name && <Text singleLine>{name}</Text>}
									{!name && <Text signleLine tid='meetings.list.write_a_name' color='muted' />}
								</React.Fragment>
							)}
							{groupName && (
								<Badge expandedText={groupPath} onClick={() => onBadgeClick && onBadgeClick(id, groupId)}>
									{groupName}
								</Badge>
							)}
							{inEditMode && renderEditor()}
						</StyledTitle>
						<StyledSubtitle>
							{meetingType === MEETING_TYPE_STANDARD && this.renderSubtitleStandard()}
							{meetingType === MEETING_TYPE_SMART && this.renderSubtitleSmart()}
						</StyledSubtitle>
					</StyledContent>
				</StyledMeetingItem>
			</StyledListItem>
		)
	}
}
