import React, { forwardRef } from 'react'
import NumberFormat from 'react-number-format'

const NumberFormatCustom = (props, ref) => {
	const { onChange, floatFormat, ...other } = props

	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			thousandSeparator={' '}
			isNumericString
			decimalSeparator={floatFormat ? ',' : undefined}
			decimalScale={floatFormat ? undefined : 0}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				})
			}}
		/>
	)
}

export default forwardRef(NumberFormatCustom)
