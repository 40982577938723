import React, { PureComponent } from 'react'
import { string, oneOf, node, bool, oneOfType, object } from 'prop-types'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import Text from '../text/text'
import { v1 } from 'uuid'
import { getTextColor, getShowDelay } from './tooltip.helpers'
import TOOLTIP_DEFAULT_STATES from './tooltip.default-states'

const StyledRawWrapper = styled.div`
	> * {
		color: ${(props) => props.theme.colors[props.color]};
	}
`

const StyledMaxWidthWrapper = styled.div`
	max-width: ${(props) => props.maxWidth};
`

export default class Tooltip extends PureComponent {
	static propTypes = {
		children: node,
		raw: node,
		place: oneOf(['top', 'right', 'bottom', 'left']),
		delayShow: oneOf(['instant', 'short', 'long']),
		type: oneOf(['success', 'warning', 'error', 'info', 'light', 'dark']),
		tid: oneOfType([string, bool]),
		text: oneOfType([string, node]),
		activeState: oneOfType([string, bool]),
		states: object,
		active: bool,
		offset: object,
		event: string,
		maxWidth: string,
		values: object
	}

	static defaultProps = {
		activeState: 'default',
		type: 'dark',
		active: true
	}

	hide = () => {
		ReactTooltip.hide(this.nodeRef)
	}

	show = () => {
		ReactTooltip.show(this.nodeRef)
	}

	render = () => {
		let { activeState, place, tid, text, delayShow, type, offset, event, maxWidth } = this.props
		const { states, children, active, raw, values } = this.props
		const id = v1()
		let defaultObject = null

		if (active === false) {
			return children
		}

		// If empty string or null was sent, set default value.
		if (!activeState) {
			activeState = 'default'
		}

		// Get object from states (if sent)
		if (states) {
			defaultObject = states[activeState]
		}

		// If no default object was found in states or state wasn't sent,
		// check default states defined in 'tooltip.default-states' instead if 'activeState' was provided
		if (!defaultObject) {
			defaultObject = TOOLTIP_DEFAULT_STATES[activeState]
		}

		// If defaultObject has value at this point, the activeState was found in the constants or sent states
		if (defaultObject) {
			if (defaultObject.activeState) {
				defaultObject = TOOLTIP_DEFAULT_STATES[defaultObject.activeState]
			}

			if (defaultObject.tid) {
				tid = defaultObject.tid
			}

			if (defaultObject.text) {
				text = defaultObject.text
			}

			if (defaultObject.place && !place) {
				place = defaultObject.place
			}

			if (defaultObject.delayShow && !delayShow) {
				delayShow = defaultObject.delayShow
			}

			if (defaultObject.type) {
				type = defaultObject.type
			}

			if (defaultObject.event) {
				event = defaultObject.event
			}

			if (defaultObject.offset) {
				offset = defaultObject.offset
			}

			if (defaultObject.maxWidth) {
				maxWidth = defaultObject.maxWidth
			}
		}

		// Prepare values
		const tooltipProps = {
			id,
			type,
			offset,
			event,
			place: place ? place : 'top',
			delayShow: delayShow ? getShowDelay(delayShow) : getShowDelay('long'),
			visible: true
		}

		// If there is no TID or TEXT specified and there's no active, return children
		if ((!tid && !text && !defaultObject && !raw) || active === false) {
			return children
		}

		const getTooltipChildren = () => {
			const textColor = getTextColor(type)

			if (tid) {
				if (maxWidth) {
					return (
						<StyledMaxWidthWrapper maxWidth={maxWidth}>
							<Text tid={tid} color={textColor} values={values} />
						</StyledMaxWidthWrapper>
					)
				}

				return <Text tid={tid} color={textColor} values={values} />
			} else if (text) {
				if (maxWidth) {
					return (
						<StyledMaxWidthWrapper maxWidth={maxWidth}>
							<Text color={textColor}>{text}</Text>;
						</StyledMaxWidthWrapper>
					)
				}

				return <Text color={textColor}>{text}</Text>
			}

			return <StyledRawWrapper color={textColor}>{raw}</StyledRawWrapper>
		}

		return (
			<span data-tip data-for={id} ref={(ref) => (this.nodeRef = ref)}>
				{children}
				<ReactTooltip {...tooltipProps} effect='solid'>
					{getTooltipChildren()}
				</ReactTooltip>
			</span>
		)
	}
}
