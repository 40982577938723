import { fromJS, Map } from 'immutable'
import req from '../modules/request.module'
import moment from '../modules/moment.module'

import {
	SHARED_COLLECTIONS_LIST,
	SHARED_COLLECTION_SELECT,
	SHARED_COLLECTION_UPDATE_LOCAL,
	SHARED_COLLECTION_DELETE
} from './types'

export function listSharedCollections(callback) {
	return function (dispatch) {
		req.get('/documents/shared-collections').then((response) => {
			const sharedCollections = fromJS(response.data)
			dispatch({ type: SHARED_COLLECTIONS_LIST, payload: sharedCollections })
			callback && callback()
		})
	}
}

export function selectSharedCollection(sharedCollection = Map()) {
	return function (dispatch) {
		dispatch({ type: SHARED_COLLECTION_SELECT, payload: sharedCollection })
	}
}

export function updateSharedCollectionLocal(sharedCollection) {
	return function (dispatch) {
		dispatch({ type: SHARED_COLLECTION_UPDATE_LOCAL, payload: sharedCollection })
	}
}

export function saveSharedCollection(sharedCollection, callback) {
	return function (dispatch) {
		const sharedCollectionId = sharedCollection.get('id')
		req
			.put(`/documents/shared-collections/${sharedCollectionId}`, { sharedCollection })
			.then((response) => {
				dispatch({ type: SHARED_COLLECTION_UPDATE_LOCAL, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch((err) => {
				callback && callback(undefined, err)
			})
	}
}

export function unshareSharedCollection(sharedCollection) {
	return function (dispatch) {
		const sharedCollectionId = sharedCollection.get('id')
		sharedCollection = sharedCollection.set('expiresAt', moment().toISOString())

		dispatch(updateSharedCollectionLocal(sharedCollection))

		req.put(`/documents/shared-collections/${sharedCollectionId}/unshare`).then((response) => {
			dispatch({ type: SHARED_COLLECTION_UPDATE_LOCAL, payload: fromJS(response.data) })
		})
	}
}

export function deleteSharedCollection(sharedCollectionId, callback) {
	return function (dispatch) {
		req
			.delete(`/documents/shared-collections/${sharedCollectionId}`)
			.then(() => {
				dispatch({ type: SHARED_COLLECTION_DELETE, payload: sharedCollectionId })
				callback && callback()
			})
			.catch((err) => {
				callback && callback(undefined, err)
			})
	}
}
