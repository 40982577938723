import { fromJS } from 'immutable'

import { INVESTORS_LIST } from '../actions/types'

const INITIAL_STATE = fromJS({
	list: null
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action
	switch (type) {
		case INVESTORS_LIST:
			return state.set('list', payload)
		default:
			return state
	}
}
