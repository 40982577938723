import React, { createContext, useContext } from 'react'

/*
Context definition:

value = {
	isSubmitting: Boolean,
	setIsSubmitting: (isSubmitting: Boolean) => void,

}
*/

export const SubscriptionModalAdminContext = createContext({})

export function useSubscriptionModalAdminContext() {
	return useContext(SubscriptionModalAdminContext)
}
