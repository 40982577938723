import featureService from '../services/feature'

/**
 * 
 * @param {object} feature 
 * @param {function} callback 
 * @returns 
 */
export function createFeature(feature, callback) {
	return async function () {
		try {
			const newFeature = await featureService.createFeature(feature)
			callback(undefined, newFeature)
		} catch (error) {
			callback(error)
		}
	}
}

/**
 * 
 * @param {uuid} id 
 * @param {function} callback 
 * @returns 
 */
export function getFeature(id, callback) {
	return async function () {
		try {
			const feature = await featureService.getFeature(id)
			callback(undefined, feature)
		} catch (error) {
			callback(error)
		}
	}
}

/**
 * 
 * @param {object} feature 
 * @param {function} callback 
 * @returns 
 */
export function editFeature(feature, callback) {
	return async function () {
		try {
			const updatedFeature = await featureService.editFeature(feature)
			callback(undefined, updatedFeature)
		} catch (error) {
			callback(error)
		}
	}
}

/**
 * 
 * @param {uuid} id 
 * @param {function} callback 
 * @returns 
 */
export function deleteFeature(id, callback) {
	return async function () {
		try {
			await featureService.deleteFeature(id)
			callback()
		} catch (error) {
			callback(error)
		}
	}
}

/**
 * 
 * @param {uuid} position 
 * @param {function} callback 
 * @returns 
 */
export function listFeaturesByPosition(position, callback) {
	return async function () {
		try {
			const feature = await featureService.listFeaturesByPosition(position)
			callback(undefined, feature)
		} catch (error) {
			callback(error)
		}
	}
}

/**
 * 
 * @param {function} callback 
 * @returns 
 */
export function listFeatures(callback) {
	return async function () {
		try {
			const features = await featureService.listFeatures()
			callback(undefined, features)
		} catch (error) {
			callback(error)
		}
	}
}

/**
 * 
 * @param {uuid} id 
 * @param {uuid} position 
 * @param {function} callback 
 * @returns 
 */
export function setFeaturePosition(id, position, callback) {
	return async function () {
		try {
			const feature = await featureService.setFeaturePosition(id, position)
			callback(undefined, feature)
		} catch (error) {
			callback(error)
		}
	}
}

export function unsetFeaturePosition(id, position, callback) {
	return async function () {
		try {
			const feature = await featureService.unsetFeaturePosition(id, position)
			callback(undefined, feature)
		} catch (error) {
			callback(error)
		}
	}
}
