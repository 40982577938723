import React from 'react'
import { List, isImmutable } from 'immutable'
import { components } from 'react-select-next'
import Text from '../../text/text'
import Icon from '../../icon/icon'

// Custom Styled blocks
import { StyledBackgroundReplacer, StyledMultiValueDummyLabelContainerWrapper } from './select.template'
// Custom filters
import { filterPartialMatch, filterExactWordMatch } from './select.filters'

// -------------------------------------------------------------------------------------
// --------------------------------- Helper Functions ----------------------------------
// -------------------------------------------------------------------------------------

// Sets up filters based on filterType
export const setupFilters = (filterType, option, input) => {
	switch (filterType) {
		case 'word-match':
			return filterExactWordMatch(option, input)
		default:
			return filterPartialMatch(option, input)
	}
}

// Parse value and return the result based on if single string was passed (simplevalue or object)
export const parseValue = (value, options, optionsValue) => {
	if (List.isList(value)) {
		// Whole option object was sent (probably, as we only check first value)
		if (value.getIn([0, optionsValue])) {
			return value.toJS()
		}

		// Else just values were passed. Need to create object
		const result = value.map((val) => {
			return options.find((option) => {
				return option.get(optionsValue) === val
			})
		})

		return result.toJS()
	}

	// String, value (mostly always ID)
	if (typeof value === 'string' || typeof value === 'number') {
		let result = options.find((option) => {
			if (option.has('options')) {
				return option.get('options').find((subOption) => {
					return subOption.get(optionsValue) === value
				})
			}
			return option.get(optionsValue) === value
		})

		if (result && result.has('options')) {
			result = result.get('options').find((option) => {
				return option.get(optionsValue) === value
			})
		}

		// Empty if user haven't specified any value
		return result ? result.toJS() : ''
	}

	// Handle empty result, for example on clear
	if (!value) {
		return null
	}

	// Whole option-object
	if (typeof value === 'object') {
		return isImmutable(value) ? value.toJS() : value
	}
}

// Setup all neccecary custom components based on values passed in via configObject
export const setupCustomComponents = (configObject) => {
	const {
		labelIsTid,
		placeholderTid,
		isClearable,
		clearAlternativeRenderer,
		hideDropdownIndicator,
		placeholderRenderer,
		optionRenderer,
		dropdownIndicatorAlternativeRenderer
	} = configObject
	const componentsObject = {}

	// Always replace LoadingIndicator with our own
	componentsObject.LoadingIndicator = LoadingIndicator

	// Always replace (remove) the Separator
	componentsObject.IndicatorSeparator = IndicatorSeparatorComponent

	// Translate group heading
	componentsObject.GroupHeading = GroupHeading

	// Custom Option Rendered sent in
	if (optionRenderer) {
		componentsObject.Option = optionRenderer.bind(null, components.Option)
		// Translate labels and current value label
	} else if (labelIsTid) {
		componentsObject.Option = OptionComponentTranslated
		componentsObject.SingleValue = SingleValueComponentTranslated
	} else {
		componentsObject.Option = OptionComponent
		componentsObject.SingleValue = SingleValueComponent
	}

	// Set custom placeholder that translates TIDs automatically if placeholder is set
	if (placeholderTid || placeholderRenderer) {
		componentsObject.Placeholder = PlaceholderComponent
	}

	if (clearAlternativeRenderer) {
		// Replace remove icon with counter
		componentsObject.ClearIndicator = clearAlternativeRenderer
	} else if (!isClearable) {
		// Remove clear icon
		componentsObject.ClearIndicator = ClearIndicatorComponent
	} else {
		// PART OF LEGACY UI
		componentsObject.ClearIndicator = ClearIndicatorComponentLegacy
	}

	componentsObject.MultiValueLabel = MultiValueLabel
	componentsObject.MultiValueRemove = MultiValueRemove
	componentsObject.MultiValueContainer = MultiValueContainer

	if (dropdownIndicatorAlternativeRenderer) {
		componentsObject.DropdownIndicator = dropdownIndicatorAlternativeRenderer
	} else if (hideDropdownIndicator) {
		componentsObject.DropdownIndicator = DropdownIndicatorEmpty
	} else {
		// PART OF LEGACY UI
		componentsObject.DropdownIndicator = DropdownIndicatorLegacy
	}

	return componentsObject
}

// Replaces multi value background color
const multiValueReplaceBackgroundColor = (multiValueLabelColor, component) => {
	return <StyledBackgroundReplacer color={multiValueLabelColor}>{component}</StyledBackgroundReplacer>
}

// -------------------------------------------------------------------------------------
// --------------------------------- Custom Components ---------------------------------
// -------------------------------------------------------------------------------------

// Remove indicator separator (vertical line between label and dropdown btn)
const IndicatorSeparatorComponent = () => {
	return null
}

// Don't render any cross/clear button left of the dropdown button
const ClearIndicatorComponent = () => {
	return null
}

// Custom (translated) group heading
const GroupHeading = (props) => {
	return (
		<components.GroupHeading {...props}>
			<Text tid={props.children} fontSize='11px' color='placeholder' />
		</components.GroupHeading>
	)
}

const OptionComponent = (props) => {
	if (props.data.isDivider === true) {
		return (
			<components.Option {...props}>
				<Text color='muted'>
					<hr style={{ marginTop: 0, marginBottom: 0 }} />
				</Text>
			</components.Option>
		)
	}

	if (props.isDisabled === true && !props.children) {
		return (
			<components.Option {...props}>
				<Text color='muted'>{props.label}</Text>
			</components.Option>
		)
	}

	return <components.Option {...props} />
}

const SingleValueComponent = ({ children, ...props }) => {
	return (
		<components.SingleValue {...props}>
			<Text singleLine>{children}</Text>
		</components.SingleValue>
	)
}

// Options will be translated (TIDs should be sent)
const OptionComponentTranslated = (props) => {
	if (props.isDisabled === true) {
		return (
			<components.Option {...props}>
				<Text tid={props.label} color='muted' />
			</components.Option>
		)
	}

	return (
		<components.Option {...props}>
			<Text tid={props.label} color={props.data.color ? props.data.color : null} />
		</components.Option>
	)
}

// Selected value will be translated (TIDs should be sent as options)
const SingleValueComponentTranslated = ({ children, ...props }) => {
	return (
		<components.SingleValue {...props}>
			<Text tid={children} singleLine color={props.data.color ? props.data.color : null} />
		</components.SingleValue>
	)
}

// Placeholder Component (translates TIDs automatically). Has functionality to handle custom placeholderrenderer
const PlaceholderComponent = (props) => {
	const { placeholderRenderer } = props.selectProps

	if (placeholderRenderer) {
		return <components.Placeholder {...props}>{placeholderRenderer()}</components.Placeholder>
	}

	return (
		<components.Placeholder {...props}>
			<Text tid={props.children} color='placeholder' />
		</components.Placeholder>
	)
}

// Use Invonos loading indicator
const LoadingIndicator = () => {
	return <Icon icon='faSpinner' size='sm' type='solid' spin />
}

const MultiValueLabel = (props) => {
	const { multiValueLabelColor, multiValueDummyLabel } = props.data

	if (multiValueLabelColor) {
		return multiValueReplaceBackgroundColor(multiValueLabelColor, <components.MultiValueLabel {...props} />)
	}

	if (multiValueDummyLabel) {
		const { children } = props

		return (
			<components.MultiValueLabel {...props}>
				<Text tid={children} color='placeholder' />
			</components.MultiValueLabel>
		)
	}

	return <components.MultiValueLabel {...props} />
}

const MultiValueRemove = (props) => {
	const { multiValueLabelColor, multiValueDummyLabel } = props.data

	if (multiValueLabelColor) {
		return multiValueReplaceBackgroundColor(multiValueLabelColor, <components.MultiValueRemove {...props} />)
	}

	if (multiValueDummyLabel) {
		return null
	}

	return <components.MultiValueRemove {...props} />
}

const MultiValueContainer = (props) => {
	const { multiValueDummyLabel } = props.data

	if (multiValueDummyLabel) {
		return (
			<StyledMultiValueDummyLabelContainerWrapper>
				<components.MultiValueContainer {...props} />
			</StyledMultiValueDummyLabelContainerWrapper>
		)
	}

	return <components.MultiValueContainer {...props} />
}

const DropdownIndicatorEmpty = () => {
	return null
}

// --- PART OF LEGACY LEGACY UI --- \\\
const ClearIndicatorComponentLegacy = ({ innerProps }) => {
	return (
		<div className='react-select__indicator react-select__clear-indicator' {...innerProps}>
			<Icon icon='faTimes' size='xs' />
		</div>
	)
}

const DropdownIndicatorLegacy = ({ innerProps }) => {
	return (
		<div className='react-select__indicator react-select__dropdown-indicator' {...innerProps}>
			<Icon icon='faCaretDown' size='sm' type='solid' />
		</div>
	)
}
