import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import { Map } from 'immutable'

import { SelectCreatable } from '../../../dumb-components/shared/select'
import Text from '../../../dumb-components/shared/text/text'

import { MEETINGS_DURATION_OPTIONS } from '../../../constants/meetings'

class DurationSelectContainer extends Component {
	state = {
		durationOptions: MEETINGS_DURATION_OPTIONS
	}

	static propTypes = {
		fieldName: string,
		onChange: func,
		agendaItem: map
	}

	static defaultProps = {
		fieldName: 'duration'
	}

	componentDidMount() {
		this.appendCurrentDurationToOptions()
	}

	componentDidUpdate(prevProps) {
		const { agendaItem } = this.props

		if ((agendaItem && agendaItem.get('duration')) !== (prevProps.agendaItem && prevProps.agendaItem.get('duration'))) {
			this.appendCurrentDurationToOptions()
		}
	}

	onChange = (fieldName, value, action) => {
		const { onChange } = this.props

		if (value && action === 'create-option') {
			value = parseInt(value.value)

			// Don't do anything if non-numeric characters get entered
			if (!Number.isInteger(value)) {
				return
			}

			// Reject negative numbers
			if (value < 0) {
				return
			}
		}

		onChange && onChange(fieldName, value)
	}

	appendCurrentDurationToOptions = () => {
		const { agendaItem } = this.props
		let { durationOptions } = this.state

		// No agenda item selected
		if (!agendaItem) {
			return
		}

		const duration = agendaItem.get('duration')

		// Agenda item has no duration
		if (!duration) {
			return
		}

		const alreadyInOptions = durationOptions.find((d) => d.get('value') === agendaItem.get('duration')) ? true : false

		// Duration is already in the durationsOptions list
		if (alreadyInOptions) {
			return
		}

		// Append new duration object to list and sort
		durationOptions = durationOptions.push(Map({ value: duration, label: duration }))
		durationOptions = durationOptions.sortBy((duration) => duration.get('value'))

		this.setState({ durationOptions })
	}

	formatOptionLabel = (option) => {
		return (
			<span>
				<Text>{option.label}</Text> <Text tid='generic.mins' color='muted' />
			</span>
		)
	}

	formatCreateLabel = (text) => {
		return text
	}

	render() {
		const { fieldName, onChange, agendaItem } = this.props
		const { durationOptions } = this.state

		return (
			<SelectCreatable
				fieldName={fieldName}
				onChange={this.onChange}
				value={agendaItem && agendaItem.get('duration')}
				options={durationOptions}
				isClearable={true}
				createOptionPosition='first'
				formatOptionLabel={this.formatOptionLabel}
				formatCreateLabel={this.formatCreateLabel}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(DurationSelectContainer)
