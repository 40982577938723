import { fromJS } from 'immutable'
import {
	IMMUTABLE_FORM_ADD_ERROR,
	IMMUTABLE_FORM_RESET_ERROR,
	IMMUTABLE_FORM_RESET_ERRORS,
	IMMUTABLE_FORM_INIT,
	IMMUTABLE_FORM_RESET
} from './types'

export function initForm(name) {
	return {
		type: IMMUTABLE_FORM_INIT,
		payload: name
	}
}

export function resetForm() {
	return {
		type: IMMUTABLE_FORM_RESET,
		payload: null
	}
}

export function addError(name, error) {
	return {
		type: IMMUTABLE_FORM_ADD_ERROR,
		payload: fromJS({
			name,
			error
		})
	}
}

export function resetError(name) {
	return {
		type: IMMUTABLE_FORM_RESET_ERROR,
		payload: name
	}
}

export function resetErrors() {
	return {
		type: IMMUTABLE_FORM_RESET_ERRORS,
		payload: null
	}
}
