import React, {forwardRef} from 'react'
import {Button as MuiButton} from '@mui/material'
import styled from '@mui/styled-engine'
import PropTypes from 'prop-types';

const StyledButton = styled(MuiButton)(() => ({
	'&.Mui-disabled': {
		pointerEvents: 'auto'
	}
}))

const Button = forwardRef((props, ref) => {
	const { disabled, onClick, variant, color, ...other } = props
	const adjustedButtonProps = {
		disabled: disabled,
		component: disabled ? 'div' : undefined,
		onClick: disabled ? undefined : onClick,
		variant: variant,
		color: color
	}

	return <StyledButton ref={ref} {...other} {...adjustedButtonProps} />
})

Button.propTypes = {
	color: PropTypes.string,
	...MuiButton.propTypes
}

Button.defaultProps = {
	color: 'primary',
	...MuiButton.defaultProps
}


export default Button
