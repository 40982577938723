import { fromJS } from 'immutable'

import { SHARE_REGISTER_FETCH_CERTIFICATES, COMPANY_RESET_REDUCER } from '../actions/types'

const INITIAL_STATE = fromJS({
	certificates: null
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case SHARE_REGISTER_FETCH_CERTIFICATES:
			return state.set('certificates', payload)
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}
