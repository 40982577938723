import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import permissionsHelper from '../../components/helpers/permissions.helper'
import { downloadAccessMatrix } from '../../actions/company.actions'
import IconDrawer from '../../dumb-components/shared/icon-drawer/icon-drawer'

const DOWNLOAD_ACCESS_MATRIX_TOOLTIP_STATES = {
	default: {
		tid: 'user_management.settings.download_access_matrix'
	}
}

class UserManagementSettingsContainer extends Component {
	getDrawerObject = () => {
		const { companiesAccessRights, downloadAccessMatrix } = this.props
		const downloadMatrixDisabled = !permissionsHelper.getIsAdminForSelectedCompany(companiesAccessRights)

		return List([
			Map({
				icon: 'faCog'
			}),
			Map({
				icon: 'faFileSpreadsheet',
				disabled: downloadMatrixDisabled,
				tooltipStates: DOWNLOAD_ACCESS_MATRIX_TOOLTIP_STATES,
				tooltipActiveState: downloadMatrixDisabled ? 'hasNoPermissions' : 'default',
				onClick: () => downloadAccessMatrix(true)
			})
		])
	}

	render = () => {
		const drawer = this.getDrawerObject()

		return <IconDrawer drawer={drawer} />
	}
}

const mapStoreToProps = (store) => {
	return {
		companiesAccessRights: store.company.companiesAccessRights
	}
}

const mapActionsToProps = {
	downloadAccessMatrix
}

export default connect(mapStoreToProps, mapActionsToProps)(UserManagementSettingsContainer)
