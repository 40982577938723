import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { fromJS, isImmutable } from 'immutable'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS } from '../../constants/modals'
import TargetFolderTreeView from '../../dumb-components/personal-documents/choose-target-folder-tree-view'
import personalDocumentsService from '../../services/personal-documents'
import { closeModal } from '../../actions/modals.actions'
import { copyDocumentToPersonalDocuments } from '../../actions/personal-documents.actions'
import MUIDialog from '../../mui-components/dialog/mui-dialog'

export default function CopyDocumentToPersonalDocumentsModal() {
	const [folders, setFolders] = useState(fromJS({ name: 'personal-documents.my-documents', children: [] }))
	const [targetFolder, setTargetFolder] = useState(null)
	const modals = useSelector((state) => state.modals)
	const isOpen = modals.getIn(['activeModal', 'name']) === COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS
	const modalOptions = modals.getIn(['activeModal', 'options'])
	const document =
		isOpen && typeof modalOptions !== 'undefined' && isImmutable(modalOptions) && modalOptions?.get('document')
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleCopy = () => {
		const fileName = document.get('title')
		const file = document.get('file')
		const documentId = document.get('id')
		const sourceBucket = file.get('bucket')
		const sourceFilePath = file.get('path')

		dispatch(
			copyDocumentToPersonalDocuments(
				documentId,
				sourceBucket,
				fileName,
				sourceFilePath,
				targetFolder === 'root' ? '' : targetFolder
			)
		)
		dispatch(closeModal(COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS))
	}
	useEffect(() => {
		const fetchFolders = async () => {
			const folders = await personalDocumentsService.getPersonalFolders()

			setFolders(fromJS(folders))
		}

		isOpen && fetchFolders()
	}, [isOpen])

	return (
		<>
			<MUIDialog
				maxWidth={'md'}
				fullWidth={true}
				isOpen={isOpen}
				title={t('personal-documents.copy-file-to-personal-documents')}
				confirmButtonTid='personal-documents.copy-document'
				onConfirm={handleCopy}
				onClose={() => dispatch(closeModal(null, 'DISMISSED'))}
				closeButtonTid='dialog.generic.cancel'
				disabledConfirm={!targetFolder}>
				<Typography variant={'body2'} sx={{ flex: 'auto', my: 4 }}>
					{t('personal-documents.choose-target-folder-description')}
				</Typography>
				<TargetFolderTreeView folders={folders} onSelectFolder={(nodeId) => setTargetFolder(nodeId)} />
			</MUIDialog>
		</>
	)
}
