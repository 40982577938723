// Node modules
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, string, object } from 'prop-types'
import { withRouter } from '../../interfaces/router'
import { List, Map } from 'immutable'
import { Margin } from 'styled-components-spacing'

// Helpers
import i18nhelper from '../../components/helpers/i18n.helper'

// Actions
import {
	listProjects,
	updateProjectLocal,
	updateSelectedProjectId,
	fetchDefaultPermissions
} from '../../actions/projects.actions'
import { clearTasksList, clearTask } from '../../actions/tasks.actions'

// Components
import Breadcrumbs from '../../dumb-components/shared/breadcrumbs/breadcrumbs'
import BreadcrumbsItem from '../../dumb-components/shared/breadcrumbs/breadcrumbs-item'
import Text from '../../dumb-components/shared/text/text'
import Badge from '../../dumb-components/shared/badge/badge'
import Tooltip from '../../dumb-components/shared/tooltip/tooltip'
import history, { getQuery } from '../../interfaces/history'

import { LIVE_PROJECT_CREATE, LIVE_PROJECT_UPDATE, LIVE_PROJECT_DELETE } from '../../constants/live-update'

class BreadcrumbsContainer extends Component {
	static propTypes = {
		projectId: string,
		basePath: string,
		onCreateProject: func,
		onManageProject: func,
		location: object.isRequired
	}

	state = {
		projectStack: List(),
		dropdownOpen: Map()
	}

	componentDidMount = () => {
		const { listProjects } = this.props
		listProjects()
		this.storeSelectedProject()
		this.stackProjects()
		document.addEventListener('mousedown', this.handleMouseDown, false)
	}

	componentWillUnmount = () => {
		document.removeEventListener('mousedown', this.handleMouseDown, false)
	}

	componentDidUpdate = (prevProps) => {
		this.checkLiveUpdateEvents(prevProps)

		if (prevProps.location !== this.props.location) {
			this.storeSelectedProject()
		}

		if (prevProps.projectId !== this.props.projectId) {
			this.stackProjects()
		}

		if (prevProps.projects !== this.props.projects) {
			this.stackProjects()
		}
	}

	checkLiveUpdateEvents = (prevProps) => {
		const { audit, projectId, projects, listProjects, basePath, history } = this.props
		const projectCreate = audit.get(LIVE_PROJECT_CREATE)
		const projectUpdate = audit.get(LIVE_PROJECT_UPDATE)
		const projectDelete = audit.get(LIVE_PROJECT_DELETE)

		// Project was created, edited or deleted so update list
		if (
			(projectCreate && projectCreate.get('refresh') === true) ||
			(projectUpdate && projectUpdate.get('refresh') === true) ||
			(projectDelete && projectDelete.get('refresh') === true)
		) {
			listProjects()
		}

		// Project was deleted and it is the project the user is within so lets redirect user to root folder
		if (projectDelete && projectDelete.get('refresh') === true) {
			const objId = projectDelete.get('objId')

			if (projectId === objId) {
				const currentProjectParentId = projects.has(projectId) ? projects.getIn([projectId, 'parent']) : null

				if (currentProjectParentId) {
					history.push({ pathname: basePath, search: `?project=${currentProjectParentId}` })
				} else {
					history.push(basePath)
				}
			}
		}
	}

	storeSelectedProject = () => {
		const { updateSelectedProjectId } = this.props
		const query = getQuery()
		const { project } = query

		updateSelectedProjectId(project || null)
	}

	stackProjects = () => {
		const { projectId, projects } = this.props
		let stack = List()
		let currentProjectId = projectId

		while (projects.get(currentProjectId)) {
			const parentId = projects.getIn([currentProjectId, 'parent'])
			stack = stack.unshift(currentProjectId)
			currentProjectId = parentId
		}

		this.setState({ projectStack: stack })
	}

	handleMouseDown = (e) => {
		if (!this.node.contains(e.target)) {
			this.closeAllDropdowns()
		}
	}

	toggleDropdown = (projectId) => {
		this.setState((prevState) => {
			let { dropdownOpen } = prevState
			dropdownOpen = dropdownOpen.map((obj, objId) => {
				if (projectId !== objId) {
					obj = false
				}

				return obj
			})
			dropdownOpen = dropdownOpen.set(projectId, !dropdownOpen.get(projectId))

			return { dropdownOpen }
		})
	}

	onMenuItemClick = (parentProjectId, project) => {
		const { clearTasksList, clearTask, basePath, history } = this.props
		const projectId = project.get('id')
		this.toggleDropdown(parentProjectId)
		clearTasksList()
		clearTask()

		history.push({
			pathname: `${basePath}/`,
			search: `?project=${projectId}`
		})
	}

	closeAllDropdowns = () => {
		this.setState({ dropdownOpen: Map() })
	}

	onCreateProject = (projectId) => {
		const { onCreateProject, fetchDefaultPermissions } = this.props

		fetchDefaultPermissions(projectId)

		this.toggleDropdown(projectId)
		onCreateProject && onCreateProject(projectId === 'root' ? null : projectId)
	}

	onManageProject = (managedProjectId) => {
		const { onManageProject, history, basePath, projectId, task } = this.props
		const selectedTaskid = task ? task.get('id') : ''

		this.toggleDropdown(managedProjectId)

		// If managing project that is not currently selected, redirect to fetch its details.
		if (managedProjectId !== projectId) {
			history.push({
				pathname: `${basePath}/${selectedTaskid}`,
				search: `?project=${managedProjectId}`
			})
		}

		onManageProject && onManageProject(managedProjectId)
	}

	onClickSelectedProject = (projectId, taskId) => {
		const { basePath, history, clearTask } = this.props

		clearTask()

		if (projectId === 'root') {
			history.push(basePath)
			return
		}

		history.push({
			pathname: basePath,
			search: `?project=${projectId}`
		})
	}

	renderBreadcrumbs = (projectId, index) => {
		const { mapParentToChildren, basePath, i18n, company, params, projects } = this.props
		const { dropdownOpen, projectStack } = this.state
		let children = List()
		const childrenIds = mapParentToChildren.get(projectId, List())
		let currentProject

		const canManageProject = projects && projects.getIn([projectId, 'ALLOW_UPDATE'])
		const canCreateProject = projects && projects.getIn([projectId, 'ALLOW_CREATE'])

		if (projectId === 'root') {
			currentProject = Map({
				name: <Text tid='caterogies.root' />,
				id: projectId
			})
		} else {
			currentProject = projects.get(projectId)
		}

		childrenIds.forEach((childId) => {
			let child = projects.get(childId)
			if (child) {
				child = child.set('selected', projectStack.get(index + 1) === childId)
				children = children.push(child)
			}
		})

		children = children.map((obj) => {
			obj = obj.set('name', i18nhelper.getTranslatedValue(obj.get('name'), i18n.language, company.region))

			if (obj.get('description')) {
				obj = obj.set(
					'description',
					i18nhelper.getTranslatedValue(obj.get('description'), i18n.language, company.region)
				)
			}

			return obj
		})
		children = children.sort((a, b) => a.get('name').localeCompare(b.get('name')))

		return (
			<BreadcrumbsItem
				key={currentProject && currentProject.get('id')}
				basePath={basePath}
				params={params}
				isOpen={dropdownOpen.get(projectId)}
				itemName={
					projectId !== 'root'
						? i18nhelper.getTranslatedValue(currentProject && currentProject.get('name'), i18n.language, company.region)
						: currentProject && currentProject.get('name')
				}
				isRoot={projectId === 'root'}
				items={children}
				tooltipTidRight='tooltip.tasks.breadcrumbs.dropdown'
				onBtnClick={this.onClickSelectedProject.bind(this, currentProject && currentProject.get('id'), params.id)}
				onToggleMenu={this.toggleDropdown.bind(this, currentProject && currentProject.get('id'))}
				onMenuItemClick={this.onMenuItemClick.bind(this, currentProject && currentProject.get('id'))}
				onCreateItem={this.onCreateProject.bind(this, currentProject && currentProject.get('id'))}
				onManageItem={this.onManageProject.bind(this, currentProject && currentProject.get('id'))}
				menuItemLeftComponent={(project) => {
					return <Text singleLine={true}>{project.get('name')}</Text>
				}}
				menuItemRightComponent={(project) => {
					if (projectId !== 'root' || (!project.get('isMeetingGroup') && !project.get('isFolder'))) {
						return null
					}
					const tid = project.get('isMeetingGroup')
						? 'tasks.breadcrumbs.badge.system.tooltip'
						: 'tasks.breadcrumbs.badge.system.tooltip_folder'

					return (
						<Tooltip tid={tid} delayShow='short'>
							<Badge bgColor='dodgerBlue' textColor='white' height={18} noTransition isStatic>
								<Text tid='tasks.breadcrumbs.badge.system_folder' color='white' fontSize='11px' lineHeight={2} />
							</Badge>
						</Tooltip>
					)
				}}
				menuItemSelected={(project) => {
					return project.get('selected')
				}}
				createItemTid='category.create_category'
				manageItemTid='category.manage_category'
				canManageItem={canManageProject}
				canCreateItem={canCreateProject}
				scrollbarAlwaysVisible={true}
				createTooltipTid={currentProject && currentProject.get('createTooltipTid')}
				manageTooltipTid={currentProject && currentProject.get('manageTooltipTid')}
				onMouseEnterDrop={this.toggleDropdown.bind(null, currentProject && currentProject.get('id'))}
				onMouseLeaveDrop={this.closeAllDropdowns}
			/>
		)
	}

	render = () => {
		const { projectStack } = this.state
		return (
			<div ref={(node) => (this.node = node)}>
				<Margin all={3}>
					<Breadcrumbs>
						{this.renderBreadcrumbs('root', -1)}
						{projectStack && projectStack.map(this.renderBreadcrumbs)}
					</Breadcrumbs>
				</Margin>
			</div>
		)
	}
}

function mapStoreToProps(store, ownProps = {}) {
	return {
		history: history,
		task: store.tasks.get('task'),
		projects: store.projects.get('projects'),
		mapParentToChildren: store.projects.get('mapParentToChildren'),
		currentProject: store.projects.get('currentProject'),
		projectId: store.projects.get('selectedProjectId'),
		company: store.company.company,
		i18n: store.i18n,
		audit: store.audit.get('projects')
	}
}

const mapActionsToProps = {
	listProjects,
	updateProjectLocal,
	updateSelectedProjectId,
	fetchDefaultPermissions,
	clearTasksList,
	clearTask
}

export default withRouter(connect(mapStoreToProps, mapActionsToProps)(BreadcrumbsContainer))
