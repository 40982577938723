import React, { useState } from 'react'
import MUITreeItem from '@mui/lab/TreeItem'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
	deletePersonalDocument,
	downloadPersonalDocument,
	getSignedUrl,
	uploadPersonalDocumentToCompanyFolder
} from '../../actions/personal-documents.actions'
import { useDispatch } from 'react-redux'
import CustomFileIcon from '../../dumb-components/shared/file-icon/file-icon'
import TreeItemContextMenu from './tree-item-context.menu'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'
import { MODAL_TYPES } from '../../constants/personal-documents'
import ChooseTargetCompanyModal from '../../dumb-components/personal-documents/choose-target-company-modal'
import ChooseTargetFolderModal from '../../dumb-components/personal-documents/choose-target-folder-modal'
import { useConfirmContext } from '../../containers/shared/confirm/confirm.context'
import { useTranslation } from 'react-i18next'

const TreeDocumentItem = ({ name, absolutePath, nodeId, onDragStart }) => {
	const dispatch = useDispatch()
	const confirm = useConfirmContext()
	const { t } = useTranslation()
	const [activeModalType, setActiveModalType] = useState(null)
	const [targetCompany, setTargetCompany] = useState(null)

	const deleteDocument = async () => {
		const result = await confirm.showConfirmation(
			t('personal-documents.confirm.remove-title', { x: name }),
			t('personal-documents.confirm.delete-document-description')
		)

		if (!result) {
			return
		}

		dispatch(deletePersonalDocument(absolutePath))
	}

	const contextMenuOptions = [
		...(name.endsWith('.pdf')
			? [
					{
						icon: <ZoomOutMapIcon />,
						label: 'personal-documents.preview-pdf',
						onClick: () => dispatch(getSignedUrl(absolutePath, (url) => window.open(url, '_blank')))
					}
			  ]
			: []),
		{
			icon: <DownloadOutlinedIcon />,
			label: 'personal-documents.download-file',
			onClick: () => downloadPersonalDocument(absolutePath)
		},
		{
			icon: <FileCopyOutlinedIcon />,
			label: 'personal-documents.copy-to-company',
			onClick: () => setActiveModalType(MODAL_TYPES.CHOOSE_TARGET_COMPANY)
		},
		{
			icon: <DeleteOutlineIcon />,
			label: 'personal-documents.delete-file',
			onClick: deleteDocument
		}
	]

	const documentParts = name.split('.')
	const ext = documentParts[documentParts.length - 1]

	return (
		<>
			<MUITreeItem
				onFocusCapture={(e) => e.stopPropagation()}
				draggable
				onDragStart={onDragStart}
				label={
					<Box sx={{ display: 'flex', alignItems: 'center', py: 1, pl: 0.5, pr: 0 }}>
						<Box sx={{ display: 'flex', alignItems: 'center', width: '36px', mr: 2, ml: 0.5 }}>
							<CustomFileIcon ext={ext} />
						</Box>
						<Typography variant={'body2'} sx={{ flex: 'auto' }}>
							{name}
						</Typography>
						<TreeItemContextMenu options={contextMenuOptions} />
					</Box>
				}
				nodeId={nodeId}
			/>
			{activeModalType === MODAL_TYPES.CHOOSE_TARGET_COMPANY && (
				<ChooseTargetCompanyModal
					open
					onAccept={(companyId) => {
						setTargetCompany(companyId)
						setActiveModalType(MODAL_TYPES.CHOOSE_TARGET_FOLDER)
					}}
					onCancel={() => setActiveModalType(null)}
				/>
			)}
			{activeModalType === MODAL_TYPES.CHOOSE_TARGET_FOLDER && (
				<ChooseTargetFolderModal
					open
					companyId={targetCompany}
					onAccept={async (companyId, targetFolder) => {
						await dispatch(uploadPersonalDocumentToCompanyFolder(companyId, targetFolder, absolutePath))
						setActiveModalType(null)
					}}
					onCancel={() => setActiveModalType(null)}
				/>
			)}
		</>
	)
}

export default TreeDocumentItem
