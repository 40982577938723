import req from '../modules/request.module';
import { fetchMenu } from './menu.actions';
import { fromJS } from 'immutable';
import {
	COMPANY_CHANGED,
	COMPANY_ERROR,
	COMPANY_RESET_REDUCER,
	COMPANY_FETCH_USER_TAGS,
	COMPANY_DIRTY,
	COMPANY_EDIT_START,
	COMPANY_UPDATE_LOCAL,
	COMPANY_FETCH_MEMBERS,
	COMPANY_FETCH_MEMBERS_AS_OPTIONS,
	COMPANIES_GET,
	COMPANIES_GET_ACCESS_RIGHTS,
	COMPANIES_COMPANY_UPDATE_REMOTE,
	USER_CACHE_ADD_USERS_TO_CACHE,
	USER_FETCH_COMPANIES_USER_MEMBER_OF
} from './types';
import {
	EVENT_TYPE_COMPANY_MEMBER_ADDED,
	EVENT_TYPE_COMPANY_MEMBER_UPDATE,
	EVENT_TYPE_COMPANY_MEMBER_REMOVE,
	EVENT_TYPE_COMPANY_MEMBER_DELETE,
	EVENT_TYPE_COMPANY_UPDATE,
	EVENT_TYPE_COMPANY_OWNER_CHANGED,
	EVENT_TYPE_USER_REGISTERED
} from '/shared/constants';
import { setLiveRequest, resetLiveRequest } from './live-update.actions';
import { resetUserCache } from './usersCache.actions';
import { meetingsSetDefaultFilterBy } from './meetings.actions';
import { documentsSetDefaultFilterBy, fetchDocumentHelper } from './documents.actions';
import { tasksSetDefaultFilterBy } from './tasks.actions';
import { cancelBankId } from './credentials.actions';
import { addErrorNotification, addInfoNotification } from './notify.actions';
import { fetchSubscriptionHelper } from './subscriptions.actions';
import documentsHelper from '../components/helpers/documents.helper';
import history from '../interfaces/history';
import companyService from '../services/company';

export const fetchUserCompanies = (userId) => async (dispatch) => {
	dispatch({
		type: COMPANIES_GET,
		payload: await companyService.fetchUserCompanies(userId)
	});
};

export function companyChanged(payload) {
	return {
		type: COMPANY_CHANGED,
		payload
	};
}

export function resetCompanyReducer() {
	return {
		type: COMPANY_RESET_REDUCER,
		payload: null
	};
}

export function updateCompanyLocal(company) {
	return function (dispatch) {
		dispatch({ type: COMPANY_UPDATE_LOCAL, payload: company });
	};
}

export function updateCompanyMetaData(companyId, metaDataKey, metaDataValue, callback) {
	return function (dispatch) {
		return req.put(`/companies/${companyId}/metadata`, { metaDataKey, metaDataValue }).then((response) => {
			dispatch(companyChanged(response.data));
			callback && callback();
		});
	};
}

export function createUnverifiedCompany(
	orgNumber,
	email,
	userName,
	name,
	callback,
	verifiedToCustomer,
	isForeignCompany,
	country
) {
	return function (dispatch) {
		const data = { orgNumber, email, userName, name, country, isForeignCompany };
		return req
			.post(`/companies/active/unverified${verifiedToCustomer ? '?verifiedToCustomer=true' : ''}`, data)
			.then((response) => {
				const { company } = response.data;

				dispatch(companyChanged(company));
				dispatch(fetchUserCompanies('me'));
				dispatch(fetchMenu('main'));
				callback && callback(company);
			})
			.catch((e) => {
				callback && callback(undefined, e);
				throw e;
			});
	};
}

export function getCompanyIdFromOrgNr(orgNumber, callback) {
	req.get(`/companies/${orgNumber}/simple?orgnumber=true&simple=true&silent=true`)
		.then((response) => {
			callback && callback(fromJS(response.data));
		})
		.catch((e) => {
			callback && callback(fromJS({}));
		});
}

/**
 * Action for signing and creating a company
 */
export function signAndCreateCompany(
	orgNumber,
	ssn,
	email,
	userName,
	name,
	phone,
	bankidCallback,
	callback,
	errorCallback,
	getContinueBankIdCollect
) {
	return function (dispatch) {
		const verify = (orderRef) => {
			return function (dispatch) {
				req.post('/companies/active/complete', { orderRef, orgNumber, email, userName, phone })
					.then((response) => {
						const { company } = response.data;

						dispatch(companyChanged(company));
						dispatch(fetchUserCompanies('me'));
						dispatch(fetchMenu('main'));
						callback && callback(fromJS(response.data));
					})
					.catch((e) => {
						errorCallback && errorCallback(e);
						console.log(e);
					});
			};
		};

		const collect = (orderRef, getContinueBankIdCollect) => {
			return function (dispatch) {
				req.post('/users/public/bankid/collect', { orderRef })
					.then((response) => {
						const { status } = response.data;

						if (getContinueBankIdCollect() === false) {
							dispatch(cancelBankId(orderRef));
							throw new Error();
						}

						if (status === 'pending') {
							setTimeout(() => {
								bankidCallback(response.data);
								dispatch(collect(orderRef, getContinueBankIdCollect));
							}, 500);
						} else if (status === 'complete') {
							dispatch(verify(orderRef));
						} else {
							errorCallback && errorCallback(new Error('Failed to collect bankid'));
						}
					})
					.catch((e) => {
						errorCallback && errorCallback(e);
						console.log(e);
					});
			};
		};

		const data = { orgNumber, ssn, email, userName, name };
		return req
			.post(`/companies/active/init`, data)
			.then((response) => {
				const { orderRef } = response.data;

				if (orderRef !== 'bypass') {
					dispatch(collect(orderRef, getContinueBankIdCollect));
				} else {
					dispatch(verify(orderRef));
				}
			})
			.catch((e) => {
				callback && callback(e);
				throw e;
			});
	};
}

export function signAndActivateCompany(orgNumber, ssn, name, bankidCallback, callback, getContinueBankIdCollect) {
	return function (dispatch) {
		const verify = (orderRef) => {
			return function (dispatch) {
				req.post('/companies/verify/complete', { orderRef, orgNumber })
					.then((response) => {
						const { company } = response.data;

						dispatch(companyChanged(company));

						dispatch(
							addInfoNotification({
								tid: 'company.univerified.verify.notify.success'
							})
						);

						dispatch(fetchUserCompanies('me'));
						dispatch(fetchMenu('main'));
						callback && callback();
					})
					.catch((e) => {
						callback && callback(e);
						console.log(e);
					});
			};
		};

		const collect = (orderRef, getContinueBankIdCollect) => {
			return function (dispatch) {
				req.post('/users/public/bankid/collect', { orderRef })
					.then((response) => {
						const { status } = response.data;

						if (getContinueBankIdCollect() === false) {
							dispatch(cancelBankId(orderRef));
							throw new Error();
						}

						if (status === 'pending') {
							setTimeout(() => {
								bankidCallback(response.data);
								dispatch(collect(orderRef, getContinueBankIdCollect));
							}, 500);
						} else if (status === 'complete') {
							dispatch(verify(orderRef));
						} else {
							callback && callback(new Error('Failed to collect bankid'));
						}
					})
					.catch((e) => {
						callback && callback(e);
						console.log(e);
					});
			};
		};

		const data = { orgNumber, ssn, name };
		return req
			.post(`/companies/verify/init`, data)
			.then((response) => {
				const { orderRef } = response.data;

				if (orderRef !== 'bypass') {
					dispatch(collect(orderRef, getContinueBankIdCollect));
				} else {
					dispatch(verify(orderRef));
				}
			})
			.catch((e) => {
				callback && callback(e);
				throw e;
			});
	};
}

/**
 * Action for fetching a company by url-alias a company
 */
export function fetchCompanyByUrlAlias(urlAlias, callback) {
	return function (dispatch, getState) {
		const state = getState();
		const storedFilters = state.user.getIn(['userObj', 'siteSettings', 'pinedFilters']);

		req.get(`/companies/${urlAlias}?alias=true`)
			.then((response) => {
				dispatch(companyChanged(response.data));
				dispatch(fetchSubscriptionHelper());
				dispatch(resetUserCache());
				dispatch(fetchMenu('main'));
				dispatch(socketUpdateCompanyId(response.data.id));
				dispatch(fetchCompaniesAccessRights(response.data.id));
				dispatch(fetchDocumentHelper());

				// Set default filters
				let filteredStoredFilters =
					storedFilters && storedFilters.filter((value, key) => key.startsWith(response.data.id));
				if (filteredStoredFilters) {
					filteredStoredFilters = filteredStoredFilters
						.mapKeys((key) => key.replace(`${response.data.id}-`, ''))
						.groupBy((value, key) => key.split('$')[0]);
					filteredStoredFilters.has('meetings') &&
						dispatch(meetingsSetDefaultFilterBy(filteredStoredFilters.get('meetings')));
					filteredStoredFilters.has('documents') &&
						dispatch(documentsSetDefaultFilterBy(filteredStoredFilters.get('documents')));
					filteredStoredFilters.has('tasks') &&
						dispatch(tasksSetDefaultFilterBy(filteredStoredFilters.get('tasks')));
				}

				if (callback !== undefined && typeof callback === 'function') {
					callback();
				}
			})
			.catch((e) => {
				if (history.location.pathname.includes(urlAlias)) {
					history.push('/dashboard');
				}
				dispatch(companyError('Could\'t fetch company.'));
			});
	};
}

/**
 * Action for fetching an company by id
 */
export function fetchCompany(id, setDefaultFilters = false) {
	return function (dispatch, getState) {
		req.get(`/companies/${id}`)
			.then((response) => {
				dispatch(companyChanged(response.data));
				dispatch(fetchMenu('main'));
				dispatch(fetchSubscriptionHelper());
				dispatch(fetchCompaniesAccessRights(response.data.id));
				dispatch(fetchDocumentHelper());

				// Set default filters
				if (setDefaultFilters) {
					const state = getState();
					const storedFilters = state.user.getIn(['userObj', 'siteSettings', 'pinedFilters']);
					let filteredStoredFilters =
						storedFilters && storedFilters.filter((value, key) => key.startsWith(response.data.id));
					if (filteredStoredFilters) {
						filteredStoredFilters = filteredStoredFilters
							.mapKeys((key) => key.replace(`${response.data.id}-`, ''))
							.groupBy((value, key) => key.split('$')[0]);
						filteredStoredFilters.has('meetings') &&
							dispatch(meetingsSetDefaultFilterBy(filteredStoredFilters.get('meetings')));
						filteredStoredFilters.has('documents') &&
							dispatch(documentsSetDefaultFilterBy(filteredStoredFilters.get('documents')));
						filteredStoredFilters.has('tasks') &&
							dispatch(tasksSetDefaultFilterBy(filteredStoredFilters.get('tasks')));
					}
				}
			})
			.catch(() => {
				dispatch(companyError('Couldn\'t fetch company.'));
			});
	};
}

/**
 * Update company profile
 * @param {String} companyId — company id
 * @param {Object} profileData — object with profile data to store/update
 */
export function updateCompanyProfile(companyId, profileData) {
	return function (dispatch) {
		dispatch({ type: COMPANY_EDIT_START });
		req.put(`/companies/${companyId}`, profileData, { onlyLatest: true })
			.then((response) => {
				dispatch({ type: COMPANIES_COMPANY_UPDATE_REMOTE, payload: fromJS(response.data) });
				dispatch(companyChanged(response.data));
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes('onlyLatest:true')) {
					dispatch(companyError('Couldn\'t update company profile.'));
				}
			});
	};
}

export function companyProfileIsDirty() {
	return {
		type: COMPANY_DIRTY,
		payload: null
	};
}

export function fetchCustomTags(companyId, type) {
	return function (dispatch) {
		return req
			.get(`/companies/${companyId}/tags?type=${type}`)
			.then((response) => {
				dispatch({
					type: COMPANY_FETCH_USER_TAGS,
					payload: response.data
				});
			})
			.catch((err) => {
				dispatch(companyError('Couldn\'t fetch custom tags.'));
			});
	};
}

/**
 * Action for saving users profile image
 */
export function saveCompanyLogo(companyId, obj, type) {
	return function (dispatch) {
		req.put(`/companies/${companyId}/image/${type}`, obj)
			.then((response) => {
				dispatch(companyChanged(response.data));
				dispatch({ type: COMPANIES_COMPANY_UPDATE_REMOTE, payload: fromJS(response.data) });
			})
			.catch((err) => {
				dispatch(companyError('Couldn\'t update company profile.'));
			});
	};
}

/**
 * Delete user profile image
 */
export function deleteCompanyLogo(companyId, type) {
	return function (dispatch) {
		req.delete(`/companies/${companyId}/image/${type}`)
			.then((response) => {
				dispatch({ type: COMPANIES_COMPANY_UPDATE_REMOTE, payload: fromJS(response.data) });
				dispatch(companyChanged(response.data));
			})
			.catch((err) => {
				dispatch(companyError('Couldn\'t update company profile.'));
			});
	};
}

/**
 * Cancel a company account
 */
export function cancelAccount(companyId, callback) {
	return function (dispatch) {
		return req.put(`/companies/${companyId}/cancel`).then((response) => {
			dispatch(companyChanged(response.data));
			dispatch(fetchUserCompanies('me'));

			callback && callback(response.data);
		});
	};
}

export function fetchCompaniesAccessRights(id) {
	return function (dispatch) {
		return req.get(`/permissions/acl/my-roles/${id}`).then((response) => {
			dispatch({
				type: COMPANIES_GET_ACCESS_RIGHTS,
				payload: response.data
			});
		});
	};
}

export function activateUserDemo(cb) {
	return function (dispatch) {
		return req
			.post(`/companies/activate-user-demo`)
			.then(() => {
				dispatch(fetchUserCompanies('me'));
				cb && cb();
			})
			.catch((e) => {
				cb && cb(e);
			});
	};
}

export function deactivateUserDemo(cb) {
	return function (dispatch) {
		return req
			.post(`/companies/deactivate-user-demo`)
			.then(() => {
				dispatch(fetchUserCompanies('me'));
				cb && cb();
			})
			.catch((e) => {
				cb && cb(e);
			});
	};
}

export function reactivateCompany(id, callback) {
	return function (dispatch) {
		return req
			.post(`/companies/reactivate-company/${id}`)
			.then((response) => {
				dispatch(companyChanged(response.data));
				dispatch(fetchUserCompanies('me'));
				dispatch(fetchMenu('main'));
				callback && callback();
			})
			.catch((err) => {
				callback && callback();
				dispatch(companyError('Couldn\'t reactivate company '));
			});
	};
}

export function fetchCompanyUsersAsSelectOptions(companyId, callback) {
	return function (dispatch) {
		return req
			.get(`/companies/${companyId}/users?options=true`, { preventRepeatedRequests: true })
			.then((response) => {
				const members = fromJS(response.data.options);
				dispatch({ type: COMPANY_FETCH_MEMBERS_AS_OPTIONS, payload: members });
				callback && callback(members);
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function fetchCompanyUsers(companyId, callback) {
	return function (dispatch) {
		return req
			.get(`/companies/${companyId}/users`, { preventRepeatedRequests: true })
			.then((response) => {
				const members = fromJS(response.data);
				dispatch({ type: COMPANY_FETCH_MEMBERS, payload: members });
				dispatch({ type: USER_CACHE_ADD_USERS_TO_CACHE, payload: members });
				callback && callback(members);
			})
			.catch((e) => {
				console.log(e);
			});
	};
}

export function fetchCompaniesUserIsMemberOf(contactsToCopyIds) {
	return async function (dispatch) {
		try {
			const response = await req.post(`/companies/user/member/of`, { contactsToCopyIds });
			dispatch({ type: USER_FETCH_COMPANIES_USER_MEMBER_OF, payload: fromJS(response.data) });
		} catch (error) {
			console.log(error);
		}
	};
}

export function copyContactsToCompanies(contactsToCopy, selectedCompanies, callback) {
	return async function (dispatch) {
		try {
			await req.put(`/companies/add/contact/`, { contactsToCopy, selectedCompanies });

			const notificationTid = () => {
				const manyContacts = contactsToCopy.size > 1;
				const manyCompanies = selectedCompanies.size > 1;

				if (manyContacts && manyCompanies) {
					return 'many_many';
				}
				if (!manyContacts && !manyCompanies) {
					return 'one_one';
				}
				if (manyContacts && !manyCompanies) {
					return 'many_one';
				}
				if (!manyContacts && manyCompanies) {
					return 'one_many';
				}
			};

			dispatch(
				addInfoNotification({
					tid: `copy_contact_to_company_modal.notify.success.${notificationTid()}`
				})
			);
			callback && callback();
		} catch (error) {
			console.log(error);
		}
	};
}

export function fetchCompanyUsersCount(callback) {
	return function (dispatch) {
		return req.get(`/permissions/company/users/count`).then((response) => {
			callback && callback(fromJS(response.data));
		});
	};
}

export function updateAppSettings(setting) {
	return function (dispatch) {
		return req.put('/companies/settings', { setting }).then((response) => {
			dispatch(updateCompanyLocal(response.data));
		});
	};
}

export function resetCompany() {
	return companyChanged(null);
}

export function socketUpdateCompanyId(companyId) {
	return function (dispatch) {
		dispatch({
			type: 'updateCompanyId',
			payload: { newCompanyId: companyId },
			isSocketAction: true
		});
	};
}

/**
 * Action for dispatching an company error
 */
export function companyError(error) {
	return {
		type: COMPANY_ERROR,
		payload: error
	};
}

export function downloadAccessMatrix(openInViewer) {
	let w = null;
	if (openInViewer) {
		w = window.open(`https://${window.location.hostname}/assets/build/misc/redirecting.html`, '_blank');
	}

	return function () {
		return req.get(`/permissions/matrix/access`).then((response) => {
			let { downloadUrl, fileSize } = response.data;
			if (openInViewer) {
				const { webViewerSupportedFormat, webViewerSupportedSize } = documentsHelper.getWebViewerIsSupported(
					undefined,
					'xlsx',
					fileSize
				);
				if (webViewerSupportedFormat && webViewerSupportedSize) {
					downloadUrl = encodeURIComponent(downloadUrl);
					const webViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${downloadUrl}`;
					w.location = webViewerUrl;
				} else {
					w.location = downloadUrl;
				}
			} else {
				window.open(downloadUrl, '_self');
			}
		});
	};
}

export function checkDealflowAvailability(orgNumber, callback) {
	return async function () {
		try {
			const response = await req.get(`/companies/company/dealflow/${orgNumber}`);
			callback(response.data);
		} catch (error) {
			callback(false);
		}
	};
}

export function updateUsersMetaData(userId, values) {
	return async function (dispatch) {
		try {
			await req.patch(`/companies/members/${userId}`, values);
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'companies.errors.update_users_meta_data' }));
		}
	};
}

export function socketEventCompany(eventObj) {
	const { eventName, objId, metadata, data } = eventObj;

	return function (dispatch, getState) {
		switch (eventName) {
			case EVENT_TYPE_COMPANY_MEMBER_ADDED:
			case EVENT_TYPE_COMPANY_MEMBER_UPDATE:
			case EVENT_TYPE_COMPANY_MEMBER_REMOVE:
			case EVENT_TYPE_COMPANY_MEMBER_DELETE:
			case EVENT_TYPE_COMPANY_UPDATE:
			case EVENT_TYPE_COMPANY_OWNER_CHANGED:
			case EVENT_TYPE_USER_REGISTERED: {
				dispatch(setLiveRequest(['company', eventName], { refresh: true, objId, metadata }));
				dispatch(resetLiveRequest(['company', eventName]));

				if (eventName === EVENT_TYPE_COMPANY_UPDATE && data) {
					const companyId = getState().company?.company?.id;

					if (companyId === objId) {
						dispatch(updateCompanyLocal(data));
					}
				}
				break;
			}
		}
	};
}
