import React, { Component } from 'react'
import { array, object, string, bool, func, number } from 'prop-types'
import { fromJS } from 'immutable'
import immutablePropTypes from 'react-immutable-proptypes'
import { FormattedMessage } from 'react-intl'
import Tooltip from '../../shared/tooltip/tooltip'
import { validateEmail } from '../../../modules/validation.module'
import DropdownButton from '../../../components/framework/dropdown-button'
import clsx from 'clsx'

export default class InvestorEmailsField extends Component {
	static propTypes = {
		fieldName: string,
		email: string,
		onChange: func,
		id: string,
		labelTid: string,
		notified: immutablePropTypes.map,
		icon: string,
		iconClass: string,
		readonly: bool,
		placeholder: string,
		className: string,
		wrapperClassName: string,
		meta: object,
		i18n: object,
		objId: string,
		isActiveUser: bool,
		isActiveCompany: bool,
		isUnregistered: bool,
		onNotify: func,
		investorTypeOfOwner: string,
		onDelete: func,
		hideInformButton: bool
	}

	onChange = (e) => {
		const { onChange } = this.props
		const val = e.target.value

		onChange(val)
	}

	onNotify = () => {
		const { onNotify } = this.props

		onNotify(0)
	}

	render = () => {
		const {
			fieldName,
			email,
			id,
			labelTid,
			notified,
			readonly,
			placeholder,
			className,
			wrapperClassName,
			i18n,
			objId,
			isActiveUser,
			isActiveCompany,
			isUnregistered,
			hasInvestorId,
			investorTypeOfOwner,
			hideInformButton,
			meta: { touched, error }
		} = this.props
		const reallyIsNotified = notified
		const wrapperClass = wrapperClassName || 'col-md-12'
		const showInputTooltip =
			(reallyIsNotified && isActiveUser) || isUnregistered
		const reallyIsActiveUser =
			investorTypeOfOwner !== 'company' && isActiveUser
		const reallyIsUnregistered = isUnregistered

		let inputTooltipTid
		if (isActiveUser) {
			inputTooltipTid = 'shares.shareholders.registered.tooltip'
		} else if (isUnregistered) {
			inputTooltipTid = 'shares.shareholders.unregistered.tooltip'
		}

		// Special occasion (to show showRemindText) when company type of owner gets linked and then link is removed.
		const hasFeedback = ((reallyIsNotified && reallyIsActiveUser) || reallyIsUnregistered)
		const hasError = touched && error

		return (
			<div className={clsx('form-group', wrapperClass, {'has-feedback': hasFeedback, 'has-error': hasError})}>
				{labelTid && (
					<label className='control-label' htmlFor={id}>
						<FormattedMessage id={labelTid} />
					</label>
				)}

				<div>
					<Tooltip active={showInputTooltip} tid={inputTooltipTid}>
						<input
							key={objId}
							name={fieldName}
							onChange={this.onChange}
							value={email || ''}
							id={id}
							placeholder={i18n[placeholder]}
							disabled={readonly}
							className={className}
							type='email'
						/>
					</Tooltip>
					{hasInvestorId && reallyIsActiveUser ? (
						<span className='form-control-feedback text--success' style={{ top: '24px', width: '120px' }}>
							<FormattedMessage id='shares.shareholders.registered' /> <i className='fa fa-check'></i>
						</span>
					) : null}
					{reallyIsUnregistered ? (
						<span className='form-control-feedback text--error'>
							<FormattedMessage id='shares.shareholders.unregistered' /> <i className='fa fa-exclamation'></i>
						</span>
					) : null}
				</div>

				{touched && error && (
					<small className='help-block'>
						<FormattedMessage id={error} />
					</small>
				)}
			</div>
		)
	}
}
