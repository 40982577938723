import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import AutoSave from '../../forms/AutoSave'
import CustomSelect from '../../forms/CustomSelect'
import { TextField } from 'mui-rff'
import { deleteDocument, saveEmission, uploadDocument } from '../../../../actions/emissions.actions'
import { emissionConsts } from '../../../../constants/emissions'
import { useDispatch } from 'react-redux'
import { documentTypeEntries, FileToUpload } from '../preparations/preparations'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'

export const DecisionTypeAndPaymentMethodComponent = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { decisionTypes, paymentTypes } = emissionConsts.common
	const emission = props.emission

	const save = async (values) => {
		await dispatch(saveEmission(values))
	}

	const paymentTypeValue = () => {
		let value = ''
		if (emission.paymentType === paymentTypes.cash) {
			value = t('emissions.form-field.payment-type.options.cash')
		}
		if (emission.paymentType === paymentTypes.nonMonetary) {
			value = t('emissions.form-field.payment-type.options.cash')
		}
		if (emission.paymentType === paymentTypes.setOff) {
			value = t('emissions.form-field.payment-type.options.setOff')
		}
		return value
	}

	return (
		<>
			<Form onSubmit={save} keepDirtyOnReinitialize={true} initialValues={{ ...emission }}>
				{({ valid }) => {
					return (
						<>
							<AutoSave
								save={(values) => {
									if (valid) {
										save(values)
									}
								}}
							/>
							<Box sx={{ display: 'flex', width: '100%', maxWidth: '288px' }}>
								<CustomSelect
									readOnly={props.preventChanges}
									id='decisionType'
									label={t('emissions.registration.decision-was-made.dropdown')}
									options={[
										{
											id: decisionTypes.annual,
											text: t('emissions.form-field.decision-type.options.annual')
										},
										{
											id: decisionTypes.extraOrdinary,
											text: t('emissions.form-field.decision-type.options.extraordinary')
										},
										{
											id: decisionTypes.authorizing,
											text: t('emissions.form-field.decision-type.options.authorizing')
										},
										{
											id: decisionTypes.retrospective,
											text: t('emissions.form-field.decision-type.options.retrospective')
										}
									]}
								/>
							</Box>
							<TextField
								disabled={props.preventChanges}
								inputProps={{ readOnly: true }}
								sx={{ maxWidth: '288px', ml: '24px' }}
								name={'paymentType'}
								label={t('emissions.form-field.payment-type')}
								value={paymentTypeValue()}
							/>
						</>
					)
				}}
			</Form>
		</>
	)
}

export const DocumentsToRegistrationsOffice = (props) => {
	const emission = props.emission
	const { t } = useTranslation()
	const dispatch = useDispatch()

	return (
		<Box
			position={'relative'}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				minHeight: '423px',
				mx: '40px',
				mt: '24px',
				border: '1px solid #E0E0E0',
				borderRadius: '4px'
			}}>
			<Typography position={'absolute'} top={'32px'} left={'40px'} variant={'h6'}>
				{t('emissions.registration.general-documents-to-Bolagsverket.header')}
			</Typography>
			{document.getElementsByName('decisionType').item(0)?.value === null ? (
				<Typography
					variant={'body2'}
					sx={{
						display: 'flex',
						alignSelf: 'center',
						maxWidth: '520px',
						color: 'grey.600'
					}}>
					{t('emissions.registration.general-documents-to-Bolagsverket.desc')}
				</Typography>
			) : (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						mb: '16px',
						mt: '56px'
					}}>
					{documentTypeEntries.map((t, i) => {
						const info = props.documentMap[t.fileKey]

						return t.visible(emission.decisionType) ? (
							<Box
								key={t.fileKey}
								sx={{
									display: 'flex',
									alignItems: 'center',
									mx: '40px'
								}}>
								<FileToUpload
									preventChanges={props.preventChanges}
									mailIcon
									key={t.fileKey}
									title={t.title}
									filekey={t.fileKey}
									fileName={info ? info.name : ''}
									fileId={info ? info.id : ''}
									onFileDeleted={() =>
										dispatch(deleteDocument(emission.companyId, emission.id, t.fileKey))
									}
									onFilePicked={(file) =>
										dispatch(uploadDocument(emission.companyId, emission.id, t.fileKey, file))
									}
								/>
							</Box>
						) : null
					})}
				</Box>
			)}
		</Box>
	)
}

export const InfoBox = (props) => {
	const { t } = useTranslation()

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				mx: '40px',
				mt: '8px',
				mb: props.mb,
				background: '#D6EBEB',
				borderRadius: '4px'
			}}>
			<MailOutlineIcon sx={{ color: '#1F5C5C' }} />
			<Typography variant={'body2'} sx={{ my: '14px', mx: '4px' }}>
				{t('emissions.registration.send-it-in-physically.text')}
			</Typography>
			<Typography variant={'body2Italic'} sx={{ my: '14px' }}>
				{t('emissions.registration.send-it-in-physically.text2')}
			</Typography>
		</Box>
	)
}

export const Confirmations = (props) => {
	const { t } = useTranslation()
	const emission = props.emission
	const dispatch = useDispatch()
	const { documentTypes } = emissionConsts.common

	const confirmationEntries = [
		{
			title: 'emissions.document-types.case-received',
			fileKey: documentTypes.caseReceived,
			visible: () => true
		},
		{
			title: 'emissions.document-types.payment-to-registration-office',
			fileKey: documentTypes.paymentToRegistrationOffice,
			visible: () => true
		},
		{
			title: 'emissions.document-types.case-registered',
			fileKey: documentTypes.caseRegistered,
			visible: () => true
		},
		{
			title: 'emissions.document-types.additions',
			fileKey: documentTypes.additions,
			visible: () => true
		}
	]

	return (
		<>
			<Box
				position={'relative'}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					minHeight: '252px',
					mx: '40px',
					mt: '32px',
					border: '1px solid #E0E0E0',
					borderRadius: '4px'
				}}>
				<Typography position={'absolute'} top={'32px'} left={'40px'} variant={'h6'}>
					{t('emissions.registration.confirmations.header')}
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						mb: '16px',
						mt: '56px'
					}}>
					{confirmationEntries.map((t, i) => {
						const key = `${props.closure?.index}_${t.fileKey}`
						const info = props.documentMap[key]

						return t.visible() ? (
							<Box
								key={t.fileKey}
								sx={{
									display: 'flex',
									alignItems: 'center',
									mx: '40px'
								}}>
								<FileToUpload
									preventChanges={props.preventChanges}
									key={t.fileKey}
									title={t.title}
									filekey={key}
									fileName={info ? info.name : ''}
									fileId={info ? info.id : ''}
									onFileDeleted={() => dispatch(deleteDocument(emission.companyId, emission.id, key))}
									onFilePicked={(file) =>
										dispatch(uploadDocument(emission.companyId, emission.id, key, file))
									}
								/>
							</Box>
						) : null
					})}
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					mx: '40px',
					mt: '16px',
					color: '#369FF4',
					justifyContent: 'flex-end',
					alignItems: 'center'
				}}></Box>
		</>
	)
}
