import React, { useContext, useState, useEffect } from 'react'

import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import Grid from '@mui/material/Grid'
import { emissionConsts } from '../../../constants/emissions'
import { NUMBER_FORMAT_CURRENCY } from '/shared/constants.json'
import AutoSave from '../../../containers/emissions/forms/AutoSave'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import CheckIcon from '@mui/icons-material/Check'
import {
	CompanyForm,
	InsuranceForm,
	InvestorTypeRadioButtons,
	PrivateForm,
	SharesFields,
	validateSubscriptionSlipForm
} from '../../../containers/emissions/admin/subscription-list/subscription-slip-for-admin-form'
import FormAccordionEntry from '../../../containers/emissions/common/form-accordion-entry'
import { TextField } from 'mui-rff'
import EmissionDisplayValueList from '../../../containers/emissions/common/emission-display-value-list'
import EmissionDisplayValue from '../../../containers/emissions/common/emission-display-value'
import {
	displayCurrency,
	displayDate,
	displayEmissionType,
	displayInteger,
	displayShareCount
} from '../../../containers/emissions/utils/display-helpers'
import RichTextEditor from '../../../dumb-components/shared/editorv2/rich-text-editor'
import { summarize } from '../../../containers/emissions/utils/calculations'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import { DatePicker } from 'mui-rff'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import { SnackbarContext } from '../../providers/snackbar-provider'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import FullPageSpinner from '../../../containers/emissions/common/full-page-spinner'

import StickyContact from './sticky-contact-information'
import * as parsers from '../../../modules/field-parsers'

const {
	invitation: { investorType },
	common: { emissionTypes }
} = emissionConsts

const SubscriptionSlipForm = (props) => {
	const [open, setOpen] = useState(false)
	const snackbar = useContext(SnackbarContext)
	const { common: allocationTypes } = emissionConsts
	const [loading, setLoading] = useState(false)
	const { t } = useTranslation()
	const { displayData, submission, insuranceOptions, depositoryOptions } = props.subscriptionSlip
	const { texts, emission } = displayData
	const calculations = summarize(emission)
	const allocationType = displayData.emission.allocationType

	const [submissionValues, setSubmissionValues] = useState(submission)

	const [stickyFields, setStickyFields] = useState([])
	const invitee = props.invitee

	const updateStickyFields = (type) => {
		type = type || invitee.type
		if (type === investorType.private || invitee.type === investorType.dealflow) {
			setStickyFields(
				Object.entries({
					fullName: invitee.name,
					email: invitee.email,
					phone: invitee.phone,
					ssn: invitee.reference,
					street:
						invitee.beneficiary ||
						invitee.type === investorType.private ||
						invitee.type === investorType.dealflow
							? invitee.address?.street
							: false,
					zipCode:
						invitee.beneficiary ||
						invitee.type === investorType.private ||
						invitee.type === investorType.dealflow
							? invitee.address?.zipCode
							: false,
					city:
						invitee.beneficiary ||
						invitee.type === investorType.private ||
						invitee.type === investorType.dealflow
							? invitee.address?.city
							: false,
					country:
						invitee.beneficiary ||
						invitee.type === investorType.private ||
						invitee.type === investorType.dealflow
							? invitee.address?.country
							: false
				})
					.filter(([k, v]) => (invitee.type === investorType.company ? false : !!v))
					.map(([k, v]) => k)
			)
		} else if (type === investorType.company) {
			setStickyFields(
				Object.entries({
					name: invitee.type !== investorType.company ? false : invitee.company,
					organizationNumber: invitee.type !== investorType.company ? false : invitee.reference,
					street: invitee.address?.street,
					zipCode: invitee.address?.zipCode,
					city: invitee.address?.city,
					country: invitee.address?.country
				})
					.filter(([k, v]) => !!v)
					.map(([k, v]) => k)
			)
		} else if (type === investorType.insurance || type === 'capitalInsurance') {
			setStickyFields(
				Object.entries({
					fullName: invitee.name,
					email: invitee.email,
					phone: invitee.phone,
					ssn: invitee.type === investorType.private ? invitee.reference : invitee.beneficiarySsn,
					street:
						invitee.beneficiary || invitee.type === investorType.private ? invitee.address.street : false,
					zipCode:
						invitee.beneficiary || invitee.type === investorType.private ? invitee.address.zipCode : false,
					city: invitee.beneficiary || invitee.type === investorType.private ? invitee.address.city : false,
					country:
						invitee.beneficiary || invitee.type === investorType.private ? invitee.address.country : false
				})
					.filter(([k, v]) => (invitee.type === investorType.company ? false : !!v))
					.map(([k, v]) => k)
			)
		} else {
			setStickyFields(
				Object.entries({
					fullName: false,
					email: false,
					phone: false,
					ssn: false,
					street: false,
					zipCode: false,
					city: false,
					country: false
				})
					.filter(([k, v]) => !!v)
					.map(([k, v]) => k)
			)
		}
	}

	useEffect(() => {
		async function initSubmissionValues() {
			if (invitee.type === investorType.private || invitee.type === investorType.dealflow) {
				setSubmissionValues({
					...submission,
					private: {
						...submission.private,
						fullName: invitee.name,
						email: invitee.email,
						phone: invitee.phone,
						ssn: invitee.reference,
						street: invitee.address?.street,
						zipCode: invitee.address?.zipCode,
						city: invitee.address?.city,
						country: invitee.address?.country
					},
					insurance: {
						...submission.insurance,
						beneficiary: {
							...submission.insurance.beneficiary,
							fullName: invitee.name,
							email: invitee.email,
							phone: invitee.phone,
							ssn: invitee.reference,
							street: invitee.address?.street,
							zipCode: invitee.address?.zipCode,
							city: invitee.address?.city,
							country: invitee.address?.country
						}
					}
				})
			} else if (invitee.type === investorType.company) {
				setSubmissionValues({
					...submission,
					company: {
						...submission.company,
						name: invitee.company,
						organizationNumber: invitee.reference,
						street: invitee.address.street,
						zipCode: invitee.address.zipCode,
						city: invitee.address.city,
						country: invitee.address.country,
						contactPerson: {
							email: invitee.contacts?.[0]?.email ?? '',
							fullName: invitee.contacts?.[0]?.name ?? '',
							phone: invitee.contacts?.[0]?.phone ?? ''
						}
					},
					type: investorType.company
				})
			} else if (invitee.type === investorType.insurance || invitee.type === 'capitalInsurance') {
				setSubmissionValues({
					...submission,
					private: {
						...submission.private,
						fullName: invitee.name,
						ssn: invitee.beneficiarySsn,
						email: invitee.email,
						phone: invitee.phone
					},
					insurance: {
						...submission.insurance,
						beneficiary: {
							...submission.insurance.beneficiary,
							fullName: invitee.name,
							ssn: invitee.beneficiarySsn,
							email: invitee.email,
							phone: invitee.phone,
							street: invitee.capitalInsuranceOwnerInformation.street,
							zipCode: invitee.capitalInsuranceOwnerInformation.zipCode,
							city: invitee.capitalInsuranceOwnerInformation.city,
							country: invitee.capitalInsuranceOwnerInformation.country
						},
						depositoryInstitution: invitee.depositoryInstitute,
						insuranceCompany: insuranceOptions.find((option) => option.name === invitee.company).id,
						insuranceNumber: invitee.reference
					},
					type: investorType.insurance
				})
			}
		}
		updateStickyFields()
		initSubmissionValues()
	}, [])

	useEffect(() => {
		if (submission?.finalSubmission) {
			setTimeout(() => {
				setSubmissionValues({
					...submissionValues,
					type: submission.type
				})
			}, 500)
		}
	}, [submission.type])

	const onSubmit = async (values) => {
		setLoading(true)
		try {
			await props.onSubmit({ ...values, finalSubmission: true })
			snackbar.display('emissions.subscription-form.submitted-thank-you', 'success')
			setLoading(false)
			setOpen(true)
		} catch (e) {
			setLoading(false)
			snackbar.display(e.message, 'error')
		}
	}

	const closeModal = () => {
		setOpen(false)
		props.displayOverviewPage()
	}

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={{ ...submissionValues, sharesBeforeEmission: null }}
			validate={(values) => validateSubscriptionSlipForm(values, t, props.statistics.maxAmountOfNewShares)}>
			{({ form, handleSubmit, valid }) => {
				const currentValues = form.getState().values
				const readOnly = currentValues.finalSubmission

				useEffect(() => {
					updateStickyFields(currentValues.type)
				}, [currentValues.type])
				return (
					<form onSubmit={handleSubmit} noValidate>
						<AutoSave
							save={(values) => {
								if (valid && !values.signSubmissionNote) {
									props.onSubmit(values)
								}
							}}
						/>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
									<div>
										{readOnly ? (
											<Chip
												icon={<CheckIcon />}
												label={t(
													`public.subscription-slip.shares-submitted.${
														submission.signed ? 'signed' : 'not-signed'
													}`
												)}
												sx={{
													color: submission.signed ? 'success.dark' : 'black',
													bgcolor: submission.signed ? 'success.100' : '#fdd835',
													'& .MuiChip-icon': {
														color: submission.signed ? 'success.dark' : 'black'
													}
												}}
											/>
										) : (
											<Chip
												label={t('public.subscription-slip.no-shares-submitted')}
												sx={{
													color: 'error.700',
													bgcolor: 'error.100'
												}}
											/>
										)}
									</div>
									<Typography
										variant={'subtitle2'}
										sx={{
											display: 'flex',
											alignItems: 'center',
											cursor: 'pointer',
											'&:hover': {
												textDecoration: 'underline'
											}
										}}
										onClick={() => props.displayOverviewPage()}>
										<ChevronLeftIcon sx={{ mr: 1 }} />{' '}
										{t('emissions.subscription-form.to-emission-details')}
									</Typography>
								</Box>
								{displayData.company.logo && (
									<Typography
										variant={'h5'}
										sx={{ py: 2, px: 2, color: 'grey.600', bgcolor: 'grey.300' }}>
										{'Placeholder Logga'}
									</Typography>
								)}
							</Box>
							<Typography sx={{ pt: 3 }} variant='h4'>
								{t('public.subscription-slip.digital-subscription-form.header')}
							</Typography>
						</Box>
						<Typography variant='subtitle2' sx={{ pt: 2 }}>
							{t('public.subscription-slip.digital-subscription-form.desc', {
								company: displayData.company.name
							})}
						</Typography>
						<FormAccordionEntry header={t('emissions.header.basic-information')}>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
									{t('emissions.pdf.subscription-date')}:
								</Typography>
								<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
									{t('public.subscription-slip.subscription-rate')}:
								</Typography>
								<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
									{t('emissions.tabs.allocation')}:
								</Typography>
								<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
									{t('public.subscription-slip.settlement-day')}:
								</Typography>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
								<Typography sx={{ my: 1 }} variant={'body2'}>
									{`${displayData.emission.subscriptionStartDate.slice(
										0,
										10
									)} - ${displayData.emission.subscriptionEndDate.slice(0, 10)}`}
								</Typography>
								<Typography sx={{ my: 1, textTransform: 'lowerCase' }} variant={'body2'}>
									{`${localeFormatNumber(
										displayData.emission.pricePerShare,
										NUMBER_FORMAT_CURRENCY
									)} ${t('shares.per_share')}`}
								</Typography>
								{allocationType === allocationTypes.payment ? (
									<Typography sx={{ my: 1 }} variant={'body2'}>
										{t('public.subscription-slip.allocation-by-payment')}
									</Typography>
								) : (
									<Typography sx={{ my: 1 }} variant={'body2'}>
										{t('public.subscription-slip.allocation-by-decision')}
									</Typography>
								)}
								<Typography sx={{ my: 1 }} variant={'body2'}>
									{displayData.emission.paymentDueDate.slice(0, 10)}
								</Typography>
							</Box>
						</FormAccordionEntry>
						<FormAccordionEntry header={t('public.subscription-slip.subscriber-info')}>
							<Grid container rowSpacing={3} columnSpacing={3}>
								<StickyContact invitee={invitee} />
								<Box sx={{ display: 'flex', mt: 4, ml: 3, mb: -1 }}>
									<Typography variant={'body2'} sx={{ color: 'grey.600' }}>
										{t('public.subscription-slip.invest-via')}:
									</Typography>
								</Box>
								<InvestorTypeRadioButtons currentValues={currentValues} readOnly={readOnly} />
								{currentValues.type === investorType.private && (
									<PrivateForm readOnly={readOnly} stickyFields={stickyFields} />
								)}
								{currentValues.type === investorType.company && (
									<CompanyForm stickyFields={stickyFields} readOnly={readOnly} type={invitee.type} />
								)}
								{currentValues.type === investorType.insurance && (
									<InsuranceForm
										readOnly={readOnly}
										stickyFields={stickyFields}
										insuranceOptions={insuranceOptions}
										depositoryOptions={depositoryOptions}
										insuranceMissingText={t(
											'emissions.subscription-form.investor.insurance-missing',
											{
												company: 'Google Inc'
											}
										)}
									/>
								)}
							</Grid>
						</FormAccordionEntry>

						<FormAccordionEntry header={t('emissions.subscription-form.binding-commitments')}>
							<Grid container rowSpacing={3} columnSpacing={3}>
								<Box sx={{ display: 'flex', mt: 4, ml: 3, mb: 1 }}>
									<Typography variant={'body2'} sx={{ color: 'grey.600' }}>
										{t('emissions.subscription-form.investor.subscription-reporting')}
									</Typography>
								</Box>
								<Grid sx={{ display: 'flex' }} item xs={12}>
									<Grid container rowSpacing={3} columnSpacing={3}>
										<SharesFields
											readOnly={readOnly}
											currentValues={currentValues}
											emission={emission}
										/>
									</Grid>
								</Grid>
								<Box sx={{ display: 'flex', mt: 3, ml: 3, mb: -1 }}>
									<Typography variant={'body1'} sx={{ fontWeight: '600' }}>
										{t('emissions.subscription-form.investor.calc-help')}
									</Typography>
								</Box>
								<Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={12}>
									<TextField
										sx={{ mb: 1.5 }}
										InputProps={{ readOnly, inputProps: { maxLength: 80 } }}
										name={'sharesBeforeEmission'}
										type={'text'}
										label={t('emissions.subscription-form.investor.shares-before-emission')}
										autoComplete='off'
										fieldProps={{
											parse: parsers.toInt
										}}
										onKeyDown={(event) => {
											const allowedKeys = [
												'Backspace',
												'Delete',
												'ArrowDown',
												'ArrowUp',
												'ArrowLeft',
												'Tab',
												'Shift'
											]
											if (
												!((event.key >= 0 && event.key <= 9) || allowedKeys.includes(event.key))
											) {
												event.preventDefault()
											}
										}}
									/>
									<TextField
										sx={{ my: 1.5 }}
										onClick={(e) => e.target.blur()}
										InputProps={{ readOnly: true, inputProps: { maxLength: 80 } }}
										name={'sharesAfterEmission'}
										label={t('emissions.subscription-form.investor.shares-after-emission')}
										value={
											isNaN(currentValues.shareCount + currentValues.sharesBeforeEmission)
												? '0'
												: currentValues.shareCount + currentValues.sharesBeforeEmission
										}
									/>
								</Grid>
								{allocationType === allocationTypes.decision && (
									<Box sx={{ display: 'flex', ml: 3, my: 1.5 }}>
										<Typography variant={'body2'}>
											{t('emissions.subscription-form.investor.allocation-notice')}
										</Typography>
									</Box>
								)}
							</Grid>
						</FormAccordionEntry>
						{allocationType === allocationTypes.payment && (
							<FormAccordionEntry header={t('emissions.subscription-form.payment-information')}>
								<Grid container rowSpacing={3} columnSpacing={3}>
									<Box sx={{ display: 'flex', mt: 4, ml: 3, mb: 1 }}>
										<Typography variant={'body2'} sx={{ color: 'grey.600' }}>
											{t('public.subscription-slip.paymentInfo.desc')}
										</Typography>
									</Box>
									<Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={12}>
										<DatePicker
											readOnly
											value={displayData.emission.paymentDueDate}
											name={'paymentInfo.paymentDate'}
											label={t('public.subscription-slip.payment-date')}
										/>
									</Grid>
									<Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={6}>
										<TextField
											readOnly
											value={displayData.paymentInfo.bank}
											name={'paymentInfo.bank'}
											label={t('emissions.subscription-form.bank')}
										/>
									</Grid>
									<Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={6}>
										<TextField
											readOnly
											value={displayData.paymentInfo.accountNumber}
											name={'paymentInfo.accountNumber'}
											label={t('emissions.subscription-form.bank-account-number')}
										/>
									</Grid>
									<Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={6}>
										<TextField
											readOnly
											value={displayData.paymentInfo.iban}
											name={'paymentInfo.iban'}
											label={t('emissions.subscription-form.iban')}
										/>
									</Grid>
									<Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={6}>
										<TextField
											readOnly
											value={displayData.paymentInfo.bic}
											name={'paymentInfo.bic'}
											label={t('emissions.subscription-form.bic')}
										/>
									</Grid>
									<Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={12}>
										<TextField
											readOnly
											value={displayData.paymentInfo.paymentReference}
											name={'paymentInfo.paymentReference'}
											label={t('emissions.subscription-form.payment-reference')}
										/>
									</Grid>
								</Grid>
							</FormAccordionEntry>
						)}
						<FormAccordionEntry header={t('emissions.subscription-form.decided-emission')}>
							<Grid container rowSpacing={3} columnSpacing={3}>
								<Grid item xs={12}>
									<EmissionDisplayValueList>
										<EmissionDisplayValue
											label={'emissions.subscription-form.shares-before-emission'}
											value={`${displayInteger(emission.currentShareCount)} ${t(
												'public.subscription-slip.container.shares'
											)}`}
										/>
										<EmissionDisplayValue
											label={'emissions.form-field.emission-type'}
											value={t(displayEmissionType(emission))}
										/>
										{emission.emissionType === emissionTypes.preferential && (
											<EmissionDisplayValue
												label={'emissions.form-field.pro-rata-due-date'}
												value={displayDate(emission.proRataDueDate)}
											/>
										)}
										<EmissionDisplayValue
											label={'emissions.pdf.new-share-count'}
											value={`${displayShareCount(emission)} ${t(
												'public.subscription-slip.container.shares'
											)}`}
										/>
										<EmissionDisplayValue
											label={'public.subscription-slip.subscription-rate'}
											value={`${displayCurrency(emission.pricePerShare)} ${t(
												'public.subscription-slip.container.per-share'
											)}`}
										/>
										<EmissionDisplayValue
											label={'emissions.form-field.quote-value'}
											value={`${displayCurrency(emission.quoteValue)} SEK`}
										/>
										<EmissionDisplayValue
											label={'emissions.form-field.emission-amount'}
											value={`${calculations.emissionAmount} SEK`}
										/>
										<EmissionDisplayValue
											label={'emissions.subscription-form.payment-due-date'}
											value={displayDate(emission.paymentDueDate)}
										/>
										<EmissionDisplayValue
											label={'public.subscription-slip.container.last-apply-date'}
											value={displayDate(emission.subscriptionEndDate)}
										/>
									</EmissionDisplayValueList>
								</Grid>
								<Grid sx={{ mt: 2 }} item xs={12}>
									<Typography variant={'subtitle1'}>
										{t('emissions.subscription-form.precedency')}
									</Typography>
									<Typography sx={{ mt: 1 }} variant={'body2'}>
										{texts.precedency}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant={'subtitle1'}>
										{t('emissions.subscription-form.notice-of-allocation')}
									</Typography>
									<Typography sx={{ mt: 1 }} variant={'body2'}>
										{texts.noticeOfAllocation}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant={'subtitle1'}>
										{t('emissions.subscription-form.dividend-rights')}
									</Typography>
									<Typography sx={{ mt: 1 }} variant={'body2'}>
										{texts.dividendRights}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant={'subtitle1'}>
										{t('emissions.subscription-form.company-disclaimers')}
									</Typography>
									<Typography sx={{ mt: 1 }} variant={'body2'}>
										{t('public.subscription-slip.container.no-reservation')}
									</Typography>
								</Grid>
							</Grid>
						</FormAccordionEntry>
						<FormAccordionEntry header={t('emissions.subscription-form.important-information')}>
							<Grid item xs={12}>
								<RichTextEditor
									readOnly
									label={t('emissions.subscription-form.important-information')}
									value={texts.importantInformation}
								/>
							</Grid>
						</FormAccordionEntry>
						<FormAccordionEntry header={t('emissions.subscription-form.packaging-and-drag-along')}>
							<Grid item xs={12}>
								<RichTextEditor
									readOnly
									label={t('emissions.subscription-form.packaging-and-drag-along')}
									value={texts.packagingAndDragAlong}
								/>
							</Grid>
						</FormAccordionEntry>
						<Box
							className='final-submission-submit-button'
							position={'fixed'}
							bottom={0}
							left={0}
							zIndex={2}
							sx={{
								height: '72px',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								pr: 4,
								right: { xs: '0', md: '10%' }
							}}>
							<>
								{currentValues.finalSubmission ? (
									<Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
										<Button variant={'contained'} disabled>
											<Typography variant={'h5'}>
												{t('public.subscription-slip.container.submit.button')}
											</Typography>
										</Button>
									</Box>
								) : (
									<Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
										<Button variant={'contained'} type='submit'>
											<Typography variant={'h5'}>
												{t('public.subscription-slip.container.submit.button')}
											</Typography>
										</Button>
									</Box>
								)}

								<Dialog open={loading}>
									<DialogContent sx={{ px: 6, py: 4, minWidth: '60rem' }}>
										<FullPageSpinner />
									</DialogContent>
								</Dialog>

								<Dialog open={open} disableEscapeKeyDown>
									<DialogContent sx={{ px: 6, py: 4, minWidth: '60rem' }}>
										<Box sx={{ display: 'flex', flexDirection: 'column' }}>
											<Typography sx={{ textAlign: 'center', pb: 3 }} variant='h4'>
												{t('public.final-submission.e-sign-settlement-note.header')}
											</Typography>
											<Typography variant={'body2'}>
												{t('public.final-submission.e-sign-settlement-note.decision.desc')}
											</Typography>
											{props.allocationType === allocationTypes.allocationTypes.payment && (
												<Typography sx={{ mt: 5 }} variant={'body2'}>
													{t('public.final-submission.e-sign-settlement-note.payment.desc')}
												</Typography>
											)}
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'flex-end',
													mt: 7,
													columnGap: 2
												}}>
												<Button
													onClick={() => {
														closeModal()
													}}
													variant={'contained'}
													sx={{ fontWeight: 'bold' }}>
													{t('emissions.subscription-list.ok')}
												</Button>
											</Box>
										</Box>
									</DialogContent>
								</Dialog>
							</>
						</Box>
					</form>
				)
			}}
		</Form>
	)
}

export default SubscriptionSlipForm
