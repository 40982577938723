import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, object, string } from 'prop-types'
import { EditorState, SelectionState } from 'draft-js'
import CommentCreator from '../../dumb-components/shared/comments/comment-creator'
import { editComment } from '../../actions/comments.actions'

class CommentsCreatorContainer extends Component {
	state = {
		isFocused: false
	}

	static propTypes = {
		comment: object,
		onCommentCancel: func,
		onCommentCreated: func,
		placeholderTid: string,
		btnTid: string,
		objTitle: string,
		objUrl: string
	}

	static defaultProps = {
		placeholderTid: 'comments.comment_creator.placeholder',
		btnTid: 'comments.creator.save_comment'
	}

	componentDidMount = () => {
		const { comment } = this.props

		if (!comment.get('editorState')) {
			this.createEditorState()
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { comment } = this.props

		if (prevProps.comment !== comment && !comment.get('editorState')) {
			this.createEditorState()
		}
	}

	createEditorState = () => {
		const { comment } = this.props
		const editorState = EditorState.createWithContent(comment.get('text'))

		this.onChange(editorState)
	}

	onChange = (editorState) => {
		const { index } = this.props
		this.props.onChange(['comments', index, 'editorState'], editorState)
	}

	onShouldFocus = () => {
		const { comment } = this.props
		const { isFocused } = this.state

		if (isFocused) {
			this.rteRef.focusEditor()
			return
		}

		// Setstate on rander error
		this.setState({ isFocused: true })

		this.rteRef.focusEditor()

		let editorState = comment.get('editorState')
		editorState = this.moveSelectionToEnd(editorState)
		this.onChange(editorState)
	}

	onCreatorFocus = () => {
		const { comment } = this.props

		// Setstate on rander error
		this.setState({ isFocused: true })

		this.rteRef.focusEditor()

		let editorState = comment.get('editorState')
		editorState = this.moveSelectionToEnd(editorState)
		this.onChange(editorState)
	}

	onCreatorBlur = () => {
		this.setState((prevState) => {
			return { ...prevState, isFocused: false }
		})
	}

	onCommentSave = () => {
		const { onCommentSave, editComment, objId, objTitle, objUrl } = this.props
		let { comment } = this.props
		comment = comment.set('text', comment.get('editorState').getCurrentContent())
		editComment(objId, comment, objTitle, objUrl)
		onCommentSave && onCommentSave()
	}

	onCommentCancel = () => {
		const { onCommentCancel, comment } = this.props
		const editorState = EditorState.createWithContent(comment.get('text'))
		this.onChange(editorState)
		onCommentCancel && onCommentCancel()
	}

	setRef = (ref) => {
		const { comment } = this.props
		this.rteRef = ref

		this.onShouldFocus()

		let editorState = comment.get('editorState')
		editorState = this.moveSelectionToEnd(editorState)
		this.onChange(editorState)
	}

	moveSelectionToEnd = (editorState) => {
		const content = editorState.getCurrentContent()
		const blockMap = content.getBlockMap()

		const key = blockMap.last().getKey()
		const length = blockMap.last().getLength()

		const selection = new SelectionState({
			anchorKey: key,
			anchorOffset: length,
			focusKey: key,
			focusOffset: length
		})
		return EditorState.forceSelection(editorState, selection)
	}

	render = () => {
		const { comment, placeholderTid, btnTid } = this.props
		const { isFocused } = this.state
		const commentEditorState = comment.get('editorState')

		if (!commentEditorState) {
			return null
		}

		return (
			<CommentCreator
				editorState={commentEditorState}
				onChange={this.onChange}
				onShouldFocus={this.onShouldFocus}
				onRef={this.setRef}
				onFocus={this.onCreatorFocus}
				onBlur={this.onCreatorBlur}
				onCommentSave={this.onCommentSave}
				isFocused={isFocused}
				onCommentCancel={this.onCommentCancel}
				mode='inline-edit'
				placeholderTid={placeholderTid}
				btnTid={btnTid}
			/>
		)
	}
}
function mapStoreToProps(store) {
	return {}
}

const mapActionsToProps = {
	editComment
}

export default connect(mapStoreToProps, mapActionsToProps)(CommentsCreatorContainer)
