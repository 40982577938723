import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, object } from 'prop-types'

import Tooltip from '../../../../../dumb-components/shared/tooltip/tooltip'
import DropdownIconItem from '../../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import history from '../../../../../interfaces/history'

import { recoverMeeting } from '../../../../../actions/meetings.actions'

class RecoverMeetingContainer extends Component {
	static propTypes = {
		dropdownRef: object,
		basePath: string,
		location: object
	}

	recoverMeeting = () => {
		const {
			meetingId,
			recoverMeeting,
			basePath,
			location: { search },
			dropdownRef,
			history
		} = this.props

		dropdownRef && dropdownRef.onToggleMenu()

		recoverMeeting(meetingId, () => {
			history.push(`${basePath}${search && search}`)
		})
	}

	render = () => {
		const { hasExtendedRights } = this.props

		return (
			<Tooltip activeState='hasNoPermissions' active={!hasExtendedRights}>
				<DropdownIconItem
					onClick={this.recoverMeeting}
					disabled={!hasExtendedRights}
					icon='faTrashUndoAlt'
					tid='meetings.meeting.toolbar.item.recover_meeting'
				/>
			</Tooltip>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meetingId: store.meetings.getIn(['meeting', 'id']),
		hasExtendedRights: store.meetings.getIn(['meeting', 'computedValues', 'hasExtendedRights'])
	}
}

const mapActionsToProps = {
	recoverMeeting
}

export default connect(mapStoreToProps, mapActionsToProps)(RecoverMeetingContainer)
