import React, { Component } from 'react';
import { connect } from 'react-redux';
import { string, bool, func, number, oneOf } from 'prop-types';
import { map, list } from 'react-immutable-proptypes';
import { Map } from 'immutable';

import { closeModal } from '../../actions/modals.actions';
import { startDocumentSigning } from '../../actions/documents.actions';
import { DOCUMENT_REQUEST_ESIGN_MODAL_ADVANCED } from '../../constants/modals';
import RequestEsignAdvancedModalContainer from './request-esign/request-esign-advanced-modal.container';
import { OBJ_TYPE_MEETING, OBJ_TYPE_DOCUMENT } from '/shared/constants';

const INITIAL_STATE = {
	data: Map({
		usersToNotify: Map(),
		isLoading: false
	})
};

class DocumentRequestEsignModalContainer extends Component {
	state = INITIAL_STATE;

	static propTypes = {
		renderShareWithComponent: func,
		onCancel: func,
		onCloseModal: func,
		skipFilesView: bool,
		onSendSignRequest: func,
		objType: oneOf([OBJ_TYPE_DOCUMENT, OBJ_TYPE_MEETING])
	};

	static defaultProps = {
		objType: OBJ_TYPE_DOCUMENT
	};

	componentDidMount = () => {
		this.setUsersToNotify();
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { isOpenAdvancedModal } = this.props;

		// When advnaced modal opens
		if (isOpenAdvancedModal && isOpenAdvancedModal !== prevProps.isOpenAdvancedModal) {
			this.setUsersToNotify();
		}
	};

	setUsersToNotify = () => {
		const { docUsersToNotify } = this.props;
		this.setState({ data: this.state.data.set('usersToNotify', docUsersToNotify) });
	};

	onChange = (data) => {
		this.setState({ data });
	};

	resetState = () => {
		this.setState(INITIAL_STATE);
	};

	sendSignRequest = () => {
		const { documentId, eSignees, startDocumentSigning, onSendSignRequest } = this.props;
		const { data } = this.state;

		this.setState({ data: this.state.data.set('isLoading', true) });

		startDocumentSigning(documentId, eSignees, (err, document) => {
			onSendSignRequest && onSendSignRequest(err, document);
			this.closeModal();
			this.resetState();
		});
	};

	cancelProcess = () => {
		const { closeModal, onCancel } = this.props;

		this.resetState();

		closeModal();
		onCancel && onCancel();
	};

	closeModal = () => {
		const { closeModal, onCloseModal } = this.props;

		this.resetState();

		closeModal();
		onCloseModal && onCloseModal();
	};

	render = () => {
		const { isOpenAdvancedModal, renderShareWithComponent, skipFilesView, objType } = this.props;
		const { data } = this.state;
		const commonModalProps = {
			onChange: this.onChange,
			onSave: this.sendSignRequest,
			onCancel: this.cancelProcess,
			renderShareWithComponent,
			data
		};

		if (isOpenAdvancedModal) {
			return (
				<RequestEsignAdvancedModalContainer
					{...commonModalProps}
					skipFilesView={skipFilesView}
					objType={objType}
				/>
			);
		}

		return null;
	};
}

const mapStoreToProps = (store) => {
	return {
		documentId: store.documents.getIn(['document', 'id']),
		eSignees: store.documents.getIn(['document', 'eSignees']),
		docUsersToNotify: store.documents.getIn(['document', 'shareWith'], Map()),
		isOpenAdvancedModal: store.modals.getIn(['activeModal', 'name']) === DOCUMENT_REQUEST_ESIGN_MODAL_ADVANCED
	};
};

const mapActionsToProps = {
	closeModal,
	startDocumentSigning
};

export default connect(mapStoreToProps, mapActionsToProps)(DocumentRequestEsignModalContainer);
