import React, { useState } from 'react'
import { addDocumentToCollection } from '../../actions/collections.actions'
import AddExistingDocumentToCollectionWarningModal from '../../dumb-components/collections/add-existing-document-warning-modal'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../actions/modals.actions'

function AddExistingDocumentToCollectionWarningModalContainer() {
	const options = useSelector((store) => {
		return store.modals.getIn(['activeModal', 'options'])
	})
	const documentId = options.get('documentId')
	const sharedVersion = options.get('sharedVersion')

	const dispatch = useDispatch()

	const [isLoading, setIsLoading] = useState(false)

	const addToCollection = (version) => {
		setIsLoading(true)

		dispatch(
			addDocumentToCollection(documentId, version, () => {
				setIsLoading(false)
				dispatch(closeModal())
			})
		)
	}

	const handleCancel = () => {
		dispatch(closeModal())
	}

	return (
		<AddExistingDocumentToCollectionWarningModal
			isLoading={isLoading}
			existingSharedVersion={sharedVersion}
			onReplace={addToCollection}
			onSkip={handleCancel}
		/>
	)
}

export default AddExistingDocumentToCollectionWarningModalContainer
