import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	addCompanyToDealflow,
	checkIfIHaveInvestmentInCompany,
	checkIfIHavePersonalInvestmentsInCompany
} from '../../../actions/dealflow.actions'
import { AdvertCardView } from './views/AdvertCardView'

const FOLLOW_BTN_TOOLTIP_STATES = {
	selectedCompanyIsAlreadyInDealFlow: 'dealflow.add_company_modal.search.paragraph.already_in_dealflow',
	investmentExists: 'dealflow.add_company_modal.search.paragraph.already_added_company.shareholder_exists_tooltip',
	personalInvestmentExists:
		'dealflow.add_company_modal.search.paragraph.already_added_company.personal_exists_tooltip'
}

export default function AdvertCard({ advert, openAdvertModal }) {
	const [investmentExists, setInvestmentExists] = useState(null)
	const [personalInvestmentExists, setPersonalInvestmentExists] = useState(null)
	const [selectedCompanyIsAlreadyInDealFlow, setSelectedCompanyIsAlreadyInDealFlow] = useState(null)
	const dealflowCompanies = useSelector((state) => state.dealflow.companies)
	const investments = useSelector((state) => state.investors.get('myInvestments'))

	const dispatch = useDispatch()

	useEffect(() => {
		if (advert.company.orgNumber) {
			dispatch(checkIfIHaveInvestmentInCompany(advert.company.orgNumber, setInvestmentExists))
			dispatch(checkIfIHavePersonalInvestmentsInCompany(advert.company.orgNumber, setPersonalInvestmentExists))
			setSelectedCompanyIsAlreadyInDealFlow(
				dealflowCompanies.findIndex((dealflowCompany) => {
					return dealflowCompany.orgNumber === advert.company.orgNumber
				}) !== -1
			)
		}
	}, [advert.company.orgNumber, dealflowCompanies, investments])

	const onFollow = () => {
		dispatch(addCompanyToDealflow(advert.company.orgNumber))
	}

	const followBtnDisabled = selectedCompanyIsAlreadyInDealFlow || investmentExists || personalInvestmentExists

	const getFollowBtnTooltipState = () => {
		let tooltipState
		if (selectedCompanyIsAlreadyInDealFlow) {
			tooltipState = 'selectedCompanyIsAlreadyInDealFlow'
		} else if (investmentExists) {
			tooltipState = 'investmentExists'
		} else if (personalInvestmentExists) {
			tooltipState = 'personalInvestmentExists'
		}

		return FOLLOW_BTN_TOOLTIP_STATES[tooltipState]
	}

	if (investmentExists === null || personalInvestmentExists === null || selectedCompanyIsAlreadyInDealFlow === null) {
		return null
	}

	return (
		<AdvertCardView
			advert={advert}
			openAdvertModal={openAdvertModal}
			followBtnDisabled={followBtnDisabled}
			followBtnTooltip={getFollowBtnTooltipState()}
			onFollow={onFollow}
		/>
	)
}
