import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../../actions/modals.actions'
import { Form, Field } from 'react-final-form'
import { TextField } from 'mui-rff'
import { useTranslation } from 'react-i18next'
import { FormattedHTMLMessage } from 'react-intl'
//  MUI
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
//  COMPONENTS
import SynaSearchContainer from '../../syna/mui/search.cotainer'
import { fetchCompanyData } from '../../../actions/syna.actions'
import { INVESTMENTS_OVERVIEW_PRIVATE_MODE_ADD_INVESTMENT_MODAL } from '../../../constants/modals'
import Button from '../../../mui-components/button/button'
import Tooltip from '../../../mui-components/tooltip/tooltip'
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import { Condition } from '../../../dumb-components/shared/final-form/Condition'
import {
	INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE,
	INVESTOR_TYPE_OF_OWNER_PRIVATE,
	INVESTOR_TYPE_OF_OWNER_COMPANY
} from '/shared/constants'
import { isRequired } from '../../../modules/validation.module'
import { FormGroup, FormGroupContainer } from '../../../mui-components/form-block/FromGroup'
import {
	addPersonalInvestment,
	addPersonalInvestmentCompany,
	addPersonalInvestmentCapitalInsurance,
	convertDealflowToPrivatePersonalInvestment,
	convertDealflowToCapitalInsurancePersonalInvestment
} from '../../../actions/investments.actions'
import { CompanyAddress } from '../company-address/CompanyAddress'
import { listDealflow } from '../../../actions/dealflow.actions'
import { checkIfCompanyExistsInDealflow, getCompanyInDealflowByOrgNumber } from '../../../components/helpers/dealflow'
import Checkbox from '../../../mui-components/checkbox/checkbox'

const SAVE_BUTTON_TOOLTIP_STATES = {
	noCompany: {
		title: 'investments_overview.add_company.modal.tooltip.no_selected_company',
		enterDelay: 'instant'
	},
	notConfirmedStatement: {
		title: 'investments_overview.add_company.modal.tooltip.not_confirmed_statement',
		enterDelay: 'instant'
	},
	noInvestmentType: {
		title: 'investments_overview.add_company.modal.tooltip.no_investment_type',
		enterDelay: 'instant'
	},
	noInsuranceNr: {
		title: 'investments_overview.add_company.modal.tooltip.no_insurance_number',
		enterDelay: 'instant'
	},
	noCompanyOwningShares: {
		title: 'investments_overview.add_company.modal.tooltip.no_company_owning_shares',
		enterDelay: 'instant'
	},
	companyInvestmentExists: {
		title: 'investments_overview.add_company.modal.tooltip.company_investment_exists',
		enterDelay: 'instant'
	},
	companyAlreadyExists: {
		title: 'investments_overview.add_company.modal.tooltip.company_already_exists',
		enterDelay: 'instant'
	},
	acceptStatementToProceed: {
		title: 'investments_overview.add_company.modal.tooltip.accept_statement_to_proceed',
		enterDelay: 'instant'
	}
}

export default function AddCompanyModalContainer() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const investedInComponyInputRef = useRef()
	const typeOfownerInputRef = useRef()
	const viaCompanyInputRef = useRef()
	const insuranceNumberInputRef = useRef()

	const isOpen = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'name']) === INVESTMENTS_OVERVIEW_PRIVATE_MODE_ADD_INVESTMENT_MODAL
	})

	const myInvestments = useSelector((state) => {
		return state.investors.get('myInvestments')
	})

	const companiesInDealflow = useSelector((state) => state.dealflow.companies)

	const onClose = () => {
		dispatch(closeModal())
	}

	const checkIfPrivateInvestmentExists = (investedInCompany) => {
		const privateInvestmentExists = myInvestments.find((investment) => {
			const orgNumberOfInvestedCompany = investedInCompany?.company?.orgNumber
			return (
				(orgNumberOfInvestedCompany === investment.getIn(['investedInCompanyInformation', 'orgNumber']) ||
					orgNumberOfInvestedCompany === investment.get('orgNumber')) &&
				(investment.get('investorTypeOfOwner') === INVESTOR_TYPE_OF_OWNER_PRIVATE ||
					investment.getIn(['personalInvestmentType', 'type']) === INVESTOR_TYPE_OF_OWNER_PRIVATE)
			)
		})

		return privateInvestmentExists ? true : false
	}

	const checkIfInsuranceInvestmentExists = (investedInCompany, insuranceNr) => {
		const orgNumberOfInvestedCompany = investedInCompany?.company?.orgNumber
		const capitalInsuranceInvestments = myInvestments.filter((myInvestment) => {
			const investorTypeOfOwner = myInvestment.getIn(['personalInvestmentType', 'type'])

			return investorTypeOfOwner === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE
		})

		const capitalInsuranceExists = capitalInsuranceInvestments.find((investment) => {
			const investmentOrgNumber = investment.get('orgNumber')
			const investmentInsuranceNumber = investment.getIn(['personalInvestmentType', 'insuranceNr'])

			if (investmentOrgNumber === orgNumberOfInvestedCompany && investmentInsuranceNumber === insuranceNr) {
				return investment
			}
		})

		return capitalInsuranceExists ? true : false
	}

	const getTooltipActiveState = (errors, values) => {
		if (errors?.investedInCompany) {
			if (
				values.ownershipOptions === INVESTOR_TYPE_OF_OWNER_PRIVATE &&
				typeof values.investedInCompany === 'object' &&
				checkIfPrivateInvestmentExists(values.investedInCompany)
			) {
				return 'companyAlreadyExists'
			}

			if (
				values.ownershipOptions === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE &&
				values.insuranceNumber &&
				typeof values.investedInCompany === 'object' &&
				checkIfInsuranceInvestmentExists(values.investedInCompany, values.insuranceNumber)
			) {
				return 'companyInvestmentExists'
			}

			return 'noCompany'
		}

		if (errors?.statementReadConfirmation) {
			return 'notConfirmedStatement'
		}

		if (errors?.ownershipOptions) {
			return 'noInvestmentType'
		}

		if (errors.insuranceNumber) {
			return 'noInsuranceNr'
		}

		if (errors.selectCompanyOwningShares) {
			return 'noCompanyOwningShares'
		}

		const orgNumber = values.investedInCompany?.company?.orgNumber
		const dealflowExists = checkIfCompanyExistsInDealflow(orgNumber, companiesInDealflow)
		if (dealflowExists && !values.statementReadConfirmation) {
			return 'acceptStatementToProceed'
		}
	}

	//  FINAL FORM
	const validate = (values) => {
		const errors = {}

		if (!isRequired(values.statementReadConfirmation) && checkIfCompanyExistsInDealflow()) {
			errors.statementReadConfirmation = t('validation.is_required')
		}

		if (!isRequired(values.investedInCompany)) {
			errors.investedInCompany = t('validation.is_required')
		}

		if (!isRequired(values.ownershipOptions)) {
			errors.ownershipOptions = t('validation.is_required')
		}

		if (
			values?.ownershipOptions === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE &&
			!isRequired(values.insuranceNumber)
		) {
			errors.insuranceNumber = t('validation.is_required')
		}

		if (
			values?.ownershipOptions === INVESTOR_TYPE_OF_OWNER_COMPANY &&
			!isRequired(values.selectCompanyOwningShares)
		) {
			errors.selectCompanyOwningShares = t('validation.is_required')
		}

		if (
			values.ownershipOptions === INVESTOR_TYPE_OF_OWNER_PRIVATE &&
			typeof values.investedInCompany === 'object' &&
			checkIfPrivateInvestmentExists(values.investedInCompany)
		) {
			errors.investedInCompany = t('investment.add_investments.validation.investment_already_exists')
			investedInComponyInputRef?.current?.focus()
		}

		if (
			values.ownershipOptions === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE &&
			values.insuranceNumber &&
			typeof values.investedInCompany === 'object' &&
			checkIfInsuranceInvestmentExists(values.investedInCompany, values.insuranceNumber)
		) {
			errors.investedInCompany = t('investment.add_investments.validation.investment_already_exists')
			investedInComponyInputRef?.current?.focus()
		}

		if (
			values.ownershipOptions === INVESTOR_TYPE_OF_OWNER_COMPANY &&
			values.investedInCompany?.company?.orgNumber === values.selectCompanyOwningShares?.company?.orgNumber
		) {
			errors.selectCompanyOwningShares = t(
				'investment.add_investments.validation.company_cannot_own_shares_in_itself'
			)
		}

		return errors
	}

	const onSubmit = async (values) => {
		const { ownershipOptions } = values
		const orgNumber = values.investedInCompany?.company?.orgNumber
		const dealflowExists = checkIfCompanyExistsInDealflow(orgNumber, companiesInDealflow)
		let dealflow

		if (dealflowExists) {
			dealflow = companiesInDealflow.find((company) => company.orgNumber === orgNumber)
		}

		switch (ownershipOptions) {
			case INVESTOR_TYPE_OF_OWNER_PRIVATE: {
				if (dealflowExists) {
					dispatch(convertDealflowToPrivatePersonalInvestment(dealflow.id, orgNumber))
				} else {
					dispatch(addPersonalInvestment(orgNumber))
				}
				break
			}
			case INVESTOR_TYPE_OF_OWNER_COMPANY: {
				const secondaryOrgNumber = values.selectCompanyOwningShares?.company?.orgNumber
				dispatch(addPersonalInvestmentCompany(orgNumber, secondaryOrgNumber))
				break
			}
			case INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE: {
				const { insuranceNumber } = values
				if (dealflowExists) {
					dispatch(
						convertDealflowToCapitalInsurancePersonalInvestment(dealflow.id, orgNumber, insuranceNumber)
					)
				} else {
					dispatch(addPersonalInvestmentCapitalInsurance(orgNumber, insuranceNumber))
					break
				}
			}
		}
		onClose()
	}

	const changeOwnership = (value, previousValues, change) => {
		if (
			previousValues.ownershipOptions === INVESTOR_TYPE_OF_OWNER_COMPANY &&
			typeof previousValues.selectCompanyOwningShares === 'object'
		) {
			change('selectCompanyOwningShares', null)
		}

		if (
			previousValues.ownershipOptions === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE &&
			typeof previousValues.insuranceNumber === 'string'
		) {
			change('insuranceNumber', null)
		}

		change('ownershipOptions', value)

		if (value === INVESTOR_TYPE_OF_OWNER_COMPANY) {
			setTimeout(() => viaCompanyInputRef?.current?.focus(), 200)
		} else if (value === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE) {
			setTimeout(() => insuranceNumberInputRef?.current?.focus(), 200)
		}
	}

	//  SYNA
	const handleSynaSearch = (selectedOption, onChangeField) => {
		if (!selectedOption) {
			return
		}

		dispatch(
			fetchCompanyData(selectedOption.value, (data) => {
				if (data && data.company && data.representatives) {
					onChangeField(data)
					setTimeout(() => typeOfownerInputRef?.curent?.focus(), 1000)
				} else {
					onChangeField(null)
				}
			})
		)
	}

	useEffect(() => {
		dispatch(listDealflow(true))
	}, [])

	return (
		<Dialog open={isOpen} maxWidth='sm' fullWidth>
			<DialogTitle>{t('investments.overview.add_company_modal.title')} </DialogTitle>
			<Form
				validate={validate}
				onSubmit={onSubmit}
				render={(formProps) => {
					const { handleSubmit, submitting, pristine, errors, invalid, values, form } = formProps
					const orgNumber = values.investedInCompany?.company?.orgNumber
					const dealflowExists = checkIfCompanyExistsInDealflow(orgNumber, companiesInDealflow)
					const companyInDealflow = getCompanyInDealflowByOrgNumber(orgNumber, companiesInDealflow)
					const dealflowExistsAndCompanyIsOptedIn =
						dealflowExists && companyInDealflow.investedInCompanyInformation.optIn
					const submittingDisabled =
						submitting ||
						pristine ||
						invalid ||
						(dealflowExistsAndCompanyIsOptedIn && !values.statementReadConfirmation)

					return (
						<form onSubmit={handleSubmit}>
							<DialogContent>
								<FormGroupContainer>
									<FormGroup>
										<Field
											name='investedInCompany'
											render={({ input, meta }) => {
												return (
													<>
														<SynaSearchContainer
															label={t('investments.add_company_modal.search.label')}
															noOptionsText={t(
																'investments.add_company_modal.search.noOptionText'
															)}
															initialValue={input.value}
															onChange={(value) =>
																handleSynaSearch(value, input.onChange)
															}
															onClear={() => {
																form.reset()
															}}
															onBlur={input.onBlur}
															inputRef={investedInComponyInputRef}
															meta={meta}
														/>
														{input.value?.company && (
															<CompanyAddress company={input.value.company} />
														)}
													</>
												)
											}}
										/>
									</FormGroup>

									<Condition
										when={'investedInCompany'}
										is={(values) => Object.keys(values).length === 0}>
										<Fade in timeout={650}>
											<FormGroup nogap={'true'}>
												<Typography variant='body2' gutterBottom>
													{t('investments.add_company_modal.initial_info.paragraph1')}
												</Typography>
												<Typography variant='body2' gutterBottom>
													{t('investments.add_company_modal.initial_info.paragraph2')}
												</Typography>
												<Typography variant='body2' gutterBottom>
													{t('investments.add_company_modal.initial_info.paragraph3')}
												</Typography>
												<Typography variant='body2'>
													{t('investments.add_company_modal.initial_info.paragraph4')}
												</Typography>
											</FormGroup>
										</Fade>
									</Condition>

									<Condition when='investedInCompany' is={(value) => typeof value === 'object'}>
										<Fade in timeout={650}>
											<FormGroup>
												<TextField
													select
													name='ownershipOptions'
													label={t('investments.add_company_modal.select_ownership.label')}
													onChange={(event) => {
														const value = event.target.value
														changeOwnership(value, values, form.change)
													}}
													inputRef={typeOfownerInputRef}>
													<MenuItem value={INVESTOR_TYPE_OF_OWNER_PRIVATE}>
														{t(
															'investments.add_company_modal.select_ownership.menu_item.one'
														)}
													</MenuItem>
													<MenuItem value={INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE}>
														{t(
															'investments.add_company_modal.select_ownership.menu_item.two'
														)}
													</MenuItem>
													<MenuItem value={INVESTOR_TYPE_OF_OWNER_COMPANY}>
														{t(
															'investments.add_company_modal.select_ownership.menu_item.three'
														)}
													</MenuItem>
												</TextField>

												<Typography variant='body2'>
													{t('investments.add_company_modal.select_ownership.paragraph')}
												</Typography>
											</FormGroup>
										</Fade>
									</Condition>

									<Condition when='ownershipOptions' is={INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE}>
										<Fade in timeout={650}>
											<FormGroup>
												<TextField
													name='insuranceNumber'
													label={t('investments.add_company_modal.insurance_number.label')}
													inputRef={insuranceNumberInputRef}
												/>

												<Typography variant='body2'>
													{t('investments.add_company_modal.insurance_number.paragraph')}
												</Typography>
											</FormGroup>
										</Fade>
									</Condition>

									<Condition when='ownershipOptions' is={INVESTOR_TYPE_OF_OWNER_COMPANY}>
										<Fade in timeout={650}>
											<FormGroup>
												<Field
													name='selectCompanyOwningShares'
													render={({ input, meta }) => {
														return (
															<>
																<SynaSearchContainer
																	label={t(
																		'investments.add_company_modal.select_company_owning_shares.label'
																	)}
																	noOptionsText={t(
																		'investments.add_company_modal.search.noOptionText'
																	)}
																	initialValue={input.value}
																	onChange={(value) =>
																		handleSynaSearch(value, input.onChange)
																	}
																	onClear={() => {
																		input.onChange(null)
																	}}
																	onBlur={input.onBlur}
																	inputRef={viaCompanyInputRef}
																	meta={meta}
																/>
																{input.value?.company && (
																	<CompanyAddress company={input.value.company} />
																)}
															</>
														)
													}}
												/>
											</FormGroup>
										</Fade>
									</Condition>

									<Condition
										when='investedInCompany'
										is={(value) => {
											return (
												typeof value === 'object' &&
												dealflowExistsAndCompanyIsOptedIn &&
												values &&
												(values.ownershipOptions === INVESTOR_TYPE_OF_OWNER_PRIVATE ||
													values.ownershipOptions ===
														INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE)
											)
										}}>
										<Fade in timeout={650}>
											<FormGroup ref={(ref) => ref?.focus()}>
												<Box sx={{ display: 'flex', alignItems: 'center', height: '16px' }}>
													<Checkbox
														name={'statementReadConfirmation'}
														data={{}}
														sx={{ padding: 0, top: 2.5, left: '9px' }}
													/>
													<Typography variant='body2'>
														{t(
															'investments.add_company_modal.already_added_company.paragraph1'
														)}
													</Typography>
												</Box>
												<Typography variant='body2'>
													<FormattedHTMLMessage id='investments.add_company_modal.already_added_company.paragraph2' />
												</Typography>
												<Typography variant='body2'>
													{t(
														'investments.add_company_modal.already_added_company.paragraph3'
													)}
												</Typography>
											</FormGroup>
										</Fade>
									</Condition>
								</FormGroupContainer>
							</DialogContent>

							<DialogActions>
								<FooterRightControls>
									<Tooltip
										states={SAVE_BUTTON_TOOLTIP_STATES}
										activeState={submittingDisabled ? getTooltipActiveState(errors, values) : ''}>
										<Button variant='positive' disabled={submittingDisabled} type='submit'>
											{dealflowExistsAndCompanyIsOptedIn &&
											values &&
											(values.ownershipOptions === INVESTOR_TYPE_OF_OWNER_PRIVATE ||
												values.ownershipOptions === INVESTOR_TYPE_OF_OWNER_CAPITAL_INSURANCE)
												? t('investments.add_company.modal.move_to_portfolio.button')
												: t('dialog.generic.save')}
										</Button>
									</Tooltip>

									<Divider orientation='vertical' flexItem light />

									<Button type='button' onClick={onClose}>
										{t('investments.add_company.modal.cancel.button')}
									</Button>
								</FooterRightControls>
							</DialogActions>
						</form>
					)
				}}
			/>
		</Dialog>
	)
}
