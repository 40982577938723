import React from 'react'
import { bool, func, string } from 'prop-types'
import Modal from '../../shared/modal/modal'
import Button from '../../shared/button/button'
import { TransparentButton } from '../button-v2'
import FooterRightControls from '../modal/footer-right-controls'

const MoveItemModal = ({ onMove, isOpen, onClose, titleTid, itemTreeContainerRenderer }) => {
	const renderFooterComponent = () => {
		return (
			<FooterRightControls>
				<TransparentButton tid='generic.move' onClick={onMove && onMove} />
				<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={onClose && onClose} />
			</FooterRightControls>
		)
	}

	return (
		<Modal
			footerComponent={renderFooterComponent()}
			isOpen={isOpen}
			onBackgroundClick={onClose && onClose}
			title={titleTid}
			hSize='lg'>
			{itemTreeContainerRenderer && itemTreeContainerRenderer()}
		</Modal>
	)
}

MoveItemModal.defaultProps = {}

MoveItemModal.propTypes = {
	isOpen: bool,
	onClose: func,
	titleTid: string,
	onMove: func,
	itemTreeContainerRenderer: func,
	renderFooterComponent: func
}

export default MoveItemModal
