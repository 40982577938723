import { fromJS } from 'immutable'
import { CORE_PRODUCTS_FETCH, COMPANY_RESET_REDUCER } from '../actions/types'

const INITIAL_STATE = fromJS({
	coreProducts: []
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case CORE_PRODUCTS_FETCH:
			return state.set('coreProducts', payload)
		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}
		default:
			return state
	}
}
