import { AUTH_USER } from '../actions/types'

const INITIAL_STATE = {
	authenticated: false
}

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case AUTH_USER:
			return { ...state, authenticated: true }

		default:
			return state
	}
}
