import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, number } from 'prop-types'
import { Margin } from 'styled-components-spacing'
import { Map } from 'immutable'
import Alert from '../../../dumb-components/shared/alert/alert'
import Text from '../../../dumb-components/shared/text/text'

class AlertContainer extends Component {
	render() {
		const { meetingIsLoaded, attendees, attendeesNeedNotification, rollcallDone } = this.props
		const numOfAttendees = attendees.size
		const meetingHasChanged = attendees.some((a) => a.get('notifiedAboutChanges') === false)
		const hasNewAttendees = attendees.some((a) => a.get('notified') === false)

		const rollcallDoneAndMeetingHasNotChanged = rollcallDone && !meetingHasChanged

		if (!meetingIsLoaded || numOfAttendees === 0 || (rollcallDone && !hasNewAttendees)) {
			return null
		}

		if (!meetingHasChanged && !attendeesNeedNotification) {
			return (
				<Margin top={3}>
					<Alert mode='info' icon='faCheck' iconType='solid'>
						<Text color='white' tid='meetings.attendees.alert.all_invited' />
					</Alert>
				</Margin>
			)
		}

		const tid = meetingHasChanged
			? 'meetings.attendees.alert.meeting_has_changed'
			: 'meetings.attendees.alert.attendees_not_notified'

		return (
			<Margin top={3}>
				<Alert mode='warning' icon='faExclamationTriangle' iconType='solid'>
					<Text color='white' tid={tid} />
				</Alert>
			</Margin>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meetingIsLoaded: store.meetings.getIn(['meeting', 'id']) ? true : false,
		attendees: store.meetings.getIn(['meeting', 'attendees'], Map()) || Map(),
		attendeesNeedNotification: store.meetings.getIn(['meeting', 'computedValues', 'attendeesWarning']),
		meetingIsStarted: store.meetings.getIn(['meeting', 'computedValues', 'meetingIsStarted']),
		rollcallDone: store.meetings.getIn(['meeting', 'metadata', 'rollcallDone'])
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(AlertContainer)
