// Node modules
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from '../../../interfaces/router'
import { string, bool, func, oneOf } from 'prop-types'
import ImmutableProps from 'react-immutable-proptypes'
import { Map, fromJS } from 'immutable'

// Actions
import { fetchGroup, createGroup, editGroup, deleteGroup, updateGroupLocal } from '../../../actions/groups.actions'
import { clearMeeting, clearMeetingsList } from '../../../actions/meetings.actions'

// Validations
import { isRequired } from '../../../modules/validation.module'

// HOC:s
import immutableForm from '../../../components/framework/immutable-form'

// Components
import Text from '../../../dumb-components/shared/text/text'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import DropdownItem from '../../../dumb-components/shared/dropdown-item/dropdown-item'
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import ObjectPermissionsContainer from '../../shared/object-permissions.contrainer'
import Tooltip from '../../../dumb-components/shared/tooltip/tooltip'
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../dumb-components/shared/button-v2'
import ManageFolderModal, {
	StyledColoredContentWrapper
} from '../../../dumb-components/documents/manage-folder/manage-folder-modal'
import ModalItemForm from '../../../dumb-components/shared/modal/modal-form/modal-item-form'

import history from '../../../interfaces/history'

import { LIVE_GROUP_UPDATE, LIVE_GROUP_DELETE } from '../../../constants/live-update'

const TAB_INFO = 'info'
const TAB_PERMISSIONS = 'permissions'

const TABS = [
	{
		tabName: TAB_INFO,
		title: 'documents.folder.create_manage.tab.info.title'
	},
	{
		tabName: TAB_PERMISSIONS,
		title: 'documents.folder.create_manage.tab.info.permissions'
	}
]

class GroupsFormContainer extends Component {
	state = {
		activeTab: TAB_INFO
	}
	static propTypes = {
		groupId: string,
		parentGroupId: string,
		mode: oneOf(['create', 'manage']),
		isOpen: bool,
		onClose: func,
		basePath: string,
		selectedGroupId: string,
		group: ImmutableProps.map
	}

	static defaultProps = {
		mode: 'create',
		isOpen: false,
		group: Map()
	}

	componentDidMount = () => {
		const { fetchGroup, groupId } = this.props

		if (groupId) {
			fetchGroup(groupId)
		}
	}

	componentDidUpdate = (prevProps) => {
		const { fetchGroup, groupId } = this.props

		if (this.props.groupId && prevProps.groupId !== this.props.groupId) {
			fetchGroup(groupId)
		}

		this.checkLiveUpdateEvents()
	}

	checkLiveUpdateEvents = () => {
		const { audit, groupId, fetchGroup } = this.props
		const groupUpdate = audit.get(LIVE_GROUP_UPDATE, Map())
		const groupDelete = audit.get(LIVE_GROUP_DELETE, Map())

		// Group was edited
		if (groupUpdate.get('refresh') === true && groupUpdate.get('objId') === groupId) {
			fetchGroup(groupId)
		}

		// Group was deleted
		if (groupDelete.get('refresh') === true && groupDelete.get('objId') === groupId) {
			this.closeModal()
		}
	}

	onChange = (field, value) => {
		const { updateGroupLocal, resetErrors, company } = this.props
		let { group } = this.props

		group = group || Map()

		if (field === 'name' || field === 'description') {
			let entry = group.get(field, Map())
			entry = entry.set(company.region.language.main, value)
			group = group.set(field, entry)
		} else {
			group = group.set(field, value)
		}

		resetErrors(field)
		updateGroupLocal(group)
	}

	onSubmit = () => {
		const { mode } = this.props

		if (mode === 'create') {
			return this.createGroup()
		}

		return this.updateGroup()
	}

	createGroup = () => {
		const { createGroup, parentGroupId, basePath, clearMeeting, clearMeetingsList } = this.props
		let group = this.props.group || Map()

		group = group.set('parent', parentGroupId)

		if (this.validate(group)) {
			createGroup(
				group,
				(newGroup) => {
					this.closeModal()
					clearMeeting()
					clearMeetingsList()

					history.push({
						pathname: basePath,
						search: `?group=${newGroup.get('id')}`
					})
				},
				this.closeModal
			)
		}
	}

	updateGroup = () => {
		const { editGroup, group } = this.props

		if (this.validate(group)) {
			editGroup(
				group,
				() => {
					this.closeModal()
				},
				this.closeModal
			)
		}
	}

	validate = (group) => {
		const { validate, validateField, company } = this.props

		if (validate(group) && validateField(group.getIn(['name', company.region.language.main]), 'name')) {
			return true
		}

		return false
	}

	deleteGroup = () => {
		const { group, deleteGroup, selectedGroupId, router, basePath } = this.props

		if (group.get('id') === selectedGroupId) {
			const parentGroupId = group.get('parent')
			const goto = { pathname: basePath }

			if (parentGroupId) {
				goto.search = `?group=${parentGroupId}`
			}

			history.push(goto)
		}

		deleteGroup(group.get('id'), this.closeModal, this.closeModal)
	}

	closeModal = (clearGroup = true) => {
		const { onClose, updateGroupLocal, resetErrors } = this.props

		// Reset any errors so they don't appear upon next modal opening
		resetErrors('name')

		if (clearGroup) {
			updateGroupLocal(null)
		}

		this.setState({ activeTab: TAB_INFO })
		onClose && onClose()
	}

	onTabChange = (tab) => {
		this.setState({ activeTab: tab })
	}

	renderFooterComponent = () => {
		const { mode, group, mapParentToChildren, meetings } = this.props
		const meetingsInGroup =
			group &&
			meetings &&
			meetings.filter((obj) => {
				return obj.get('groupId') === group.get('id')
			})
		const deleteBtnDisabled =
			group && mapParentToChildren.get(group.get('id')) && mapParentToChildren.get(group.get('id')).size > 0
				? true
				: meetingsInGroup && meetingsInGroup.size > 0

		if (mode === 'create') {
			return (
				<FooterRightControls>
					<TransparentButton tid='generic.form.create' onClick={this.onSubmit} />
					<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={this.closeModal} />
				</FooterRightControls>
			)
		}

		return (
			<FooterRightControls>
				<TransparentButton tid='generic.form.save' onClick={this.onSubmit} />
				<DropdownMenuContainer
					inline
					halignMenu='right'
					renderRaw={<TransparentButton tid='modal.footer.dropdown_menu.more_options' textColor='midGrey' />}
					openDirection='up'>
					<DropdownIconItem
						icon='faTimes'
						tid='modal.footer.dropdown_menu.cancel_and_close'
						onClick={this.closeModal}
					/>
					<DropdownItem divider />
					<Tooltip tid='meetings.group.modal.tooltip.meetings_exists' delayShow='instant' active={deleteBtnDisabled}>
						<DropdownIconItem
							icon='faTrashAlt'
							tid='meetings.category.delete_group'
							disabled={deleteBtnDisabled}
							onClick={this.deleteGroup}
						/>
					</Tooltip>
				</DropdownMenuContainer>
			</FooterRightControls>
		)
	}

	renderTabTitle = (titleTid) => {
		return (
			<Box alignItems='center' valign='center'>
				<Text tid={titleTid} />
			</Box>
		)
	}

	renderInfoTab = () => {
		const { errors, company } = this.props
		let { group } = this.props

		if (group) {
			group = group.set('name', group.getIn(['name', company.region.language.main]))
			group = group.set('description', group.getIn(['description', company.region.language.main]))
		}

		return (
			<StyledColoredContentWrapper type='secondary'>
				<ModalItemForm item={group} errors={errors} onChange={this.onChange} nameTid='meetings.group.form.name' />
			</StyledColoredContentWrapper>
		)
	}

	renderPermissionsTab = () => {
		const { group } = this.props

		return (
			<StyledColoredContentWrapper type='secondary'>
				<ObjectPermissionsContainer
					permissions={group && group.get('permissions')}
					onChange={this.onChange}
					type='transparent'
					noTitle={true}
					infoTextTid='meetings.group.create_manage.team-access.info'
				/>
			</StyledColoredContentWrapper>
		)
	}

	render = () => {
		const { mode, isOpen } = this.props
		const { activeTab } = this.state
		const titleTid = mode === 'create' ? 'meetings.groups.create_group' : 'meetings.groups.manage_group'

		return (
			<ManageFolderModal
				isOpen={isOpen}
				titleTid={titleTid}
				footerComponent={this.renderFooterComponent()}
				tabs={TABS}
				activeTab={activeTab}
				onTabChange={this.onTabChange}>
				{activeTab === TAB_INFO && this.renderInfoTab()}
				{activeTab === TAB_PERMISSIONS && this.renderPermissionsTab()}
			</ManageFolderModal>
		)
	}
}

function mapStoreToProps(store) {
	return {
		group: store.groups.get('currentGroup', Map()),
		selectedGroupId: store.groups.get('selectedGroupId'),
		mapParentToChildren: store.groups.get('mapParentToChildren', Map()),
		meetings: store.meetings.get('allMeetings'),
		company: store.company.company,
		i18n: store.i18n,
		audit: store.audit.get('groups')
	}
}

const mapActionsToProps = {
	fetchGroup,
	createGroup,
	editGroup,
	deleteGroup,
	updateGroupLocal,
	clearMeeting,
	clearMeetingsList
}

const validators = fromJS({
	name: {
		tid: 'breadcrumbs.item.form.name',
		rules: [{ func: isRequired, message: 'validation.is_required' }]
	}
})

GroupsFormContainer = immutableForm(GroupsFormContainer, 'groupDetails', validators)

export default withRouter(connect(mapStoreToProps, mapActionsToProps)(GroupsFormContainer))
