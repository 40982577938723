import React, { PureComponent } from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'

import ScrollView from '../../../shared/layout/scroll-view/scroll-view'
import Text from '../../../shared/text/text'

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	align-self: center;
	height: 100%;
	width: 75%;
`

const StyledColumn = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 346px;
	height: 422px;

	h3 {
		margin-top: 0;
	}
`

const UserImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: ${(props) => props.theme.spacing[5]};
`

const UserImage = styled.img`
	width: auto;
	height: 160px;
`

const BottomComponentWrapper = styled.div`
	position: absolute;
	bottom: 0;
`

export const Column = ({ image, title, subtitle, text, bottomComponent }) => {
	return (
		<StyledColumn>
			<UserImageContainer>
				<UserImage src={image} />
			</UserImageContainer>

			<Text tag='h2' tid={title} />
			{subtitle && <Text tag='h4' tid={subtitle} align='center' />}
			{typeof text === 'string' && <Text tid={text} align='center' />}
			{Array.isArray(text) &&
				text.map((t) => {
					return (
						<>
							{t.subtitle && <Text tag='h3' tid={t.subtitle} />}
							<Text tag='p' tid={t.text} align='center' />
						</>
					)
				})}

			<BottomComponentWrapper>{bottomComponent && bottomComponent}</BottomComponentWrapper>
		</StyledColumn>
	)
}

export default class CenteredLeftRightLayout extends PureComponent {
	static propTypes = {
		children: node
	}

	render = () => {
		const { children } = this.props

		return (
			<ScrollView noRightMargin noLeftMargin fillContent alwaysShow>
				<Wrapper>{children}</Wrapper>
			</ScrollView>
		)
	}
}
