import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { GRID_MODE_PANEL, GRID_MODE_MODAL } from '/shared/constants'
import { useTranslation } from 'react-i18next'
import AgGrid from '../../../dumb-components/shared/ag-grid/ag-grid'
import ExportExcelButton from '../../../dumb-components/investment/shareholders/export-excel-button'
import { casesColumnDefs, columnTypes } from '../../../dumb-components/shared/ag-grid/column-defs/column-defs'
import { GRID_MODAL } from '../../../constants/modals'
import { openModal } from '../../../actions/modals.actions'
import { useDispatch, useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import GridPanelRightButtons from '../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons'
import Text from '../../../dumb-components/shared/text/text'
import { fetchCases } from '../../../actions/syna.actions'
import { InvestmentsContext } from '../investment.container'

const CasesGridContainer = ({ companyName }) => {
	const { investedInCompanyInformation } = useContext(InvestmentsContext)
	const { orgNumber } = investedInCompanyInformation.toJS()

	const gridRef = useRef()
	const { t } = useTranslation()
	const [colDefs, setColDefs] = useState(casesColumnDefs(t))
	const [rowData, setRowData] = useState()
	const [floatingFilter, setFloatingFilter] = useState(false)
	const [colTypes, setColTypes] = useState(columnTypes())

	const dispatch = useDispatch()
	const gridOption = 'cases'

	const isGridModalOpen = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'options']) === gridOption
	})

	useEffect(() => {
		dispatch(
			fetchCases(orgNumber, (error, cases) => {
				if (error) {
					return
				}

				setRowData(cases)
			})
		)

		return () => { }
	}, [])

	const exportAsExcel = useCallback(() => {
		gridRef.current.api.exportDataAsExcel()
	})

	const openGridModal = useCallback(() => {
		dispatch(openModal(GRID_MODAL, gridOption))
	})

	const mode = () => {
		return isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL
	}

	const modalLeftHeader = {
		tid: 'investments.general.panel.cases.title',
		values: { companyName: companyName }
	}

	const displayFloatingFilter = useCallback(() => {
		if (floatingFilter) {
			setFloatingFilter(false)
		} else {
			setFloatingFilter(true)
		}
	})

	if (!rowData || rowData.length === 0) {
		return null
	}


	return (
		<Card>
			<CardHeader
				title={<Text tid={'investments.general.panel.cases.title'} values={{ companyName: companyName }} />}
				action={
					<GridPanelRightButtons
						exportAsExcel={exportAsExcel}
						openGridModal={openGridModal}
						downloadExcelTid={'investments.cases.dropdown_item.download_excel_list'}
						displayFloatingFilter={displayFloatingFilter}
						floatingFilter={floatingFilter}
					/>
				}
			/>
			<CardContent variant={rowData.length > 15 ? 'noBottomPadding' : ''}>
				<AgGrid
					mode={mode()}
					isGridModalOpen={isGridModalOpen}
					rowData={rowData}
					columnDefs={colDefs}
					rightHeaderComponent={() => {
						return (
							<ExportExcelButton
								exportAsExcel={exportAsExcel}
								floatingFilter={floatingFilter}
								displayFloatingFilter={displayFloatingFilter}
							/>
						)
					}}
					modalLeftHeader={modalLeftHeader}
					gridRef={gridRef}
					floatingFilter={floatingFilter}
					columnTypes={colTypes}
				/>
			</CardContent>
		</Card>
	)
}

export default CasesGridContainer
