import React, { PureComponent } from 'react'
import { string, number, func } from 'prop-types'
import styled, { css } from 'styled-components'
import { map } from 'react-immutable-proptypes'
import { Draggable } from 'react-beautiful-dnd'
import { StyledListHeaderTemplate } from '../../shared/list/list-header.template'
import ListItem from '../../shared/list-item/list-item'
import Tooltip from '../../shared/tooltip/tooltip'
import Text from '../../shared/text/text'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import Icon from '../../shared/icon/icon'
import documentsHelper from '../../../components/helpers/documents.helper'
import FileIcon from '../../shared/file-icon/file-icon'
import moment from '../../../modules/moment.module'
import ListTableColumn from '../../shared/list-table/list-table-column'

const DragIcon = styled(ListTableColumn).attrs({
	minWidth: 0,
	center: true
})`
	margin: 0 ${(props) => props.theme.spacing[2]};
	opacity: 0;
	transition: opacity 0.1s ease-in;
`

const FileIconWrapper = styled(ListTableColumn).attrs({
	minWidth: 36,
	center: true
})`
	margin: 0;

	@media only screen and (max-width: 800px) {
		width: 28px;
	}
`

const Filename = styled(ListTableColumn).attrs({
	flex: 3
})`
	display: flex;

	> span:nth-child(2) {
		margin-left: ${({ theme }) => theme.spacing[3]};
	}
`

const Filesize = ({ children }) => (
	<ListTableColumn minWidth={80} hideBelow={700} flex={1}>
		{children}
	</ListTableColumn>
)

const Filedate = ({ children }) => (
	<ListTableColumn minWidth={100} hideBelow={500} flex={1}>
		{children}
	</ListTableColumn>
)

const Controls = styled(ListTableColumn).attrs({
	minWidth: 45,
	center: true
})``

const StyledListItem = styled(ListItem)`
	border: 1px solid ${(props) => props.theme.colors.border};
	padding: 0;
	border-left: none;
	border-right: none;
	margin: ${(props) => props.theme.spacing[2]} 0;
	align-items: center;
	cursor: ${({ isDragDisabled }) => (isDragDisabled ? 'not-allowed' : 'move')};
	user-select: none;
	position: static;

	:first-child {
		margin-top: 0;
	}

	${({ isDragDisabled }) =>
		!isDragDisabled &&
		css`
			:hover ${DragIcon} {
				opacity: 1;
			}
		`}
`

const ListHeaderWrapper = styled.div`
	${StyledListHeaderTemplate}
	border: none;
`

export const ListHeader = () => (
	<ListHeaderWrapper>
		<DragIcon />
		<FileIconWrapper />
		<Filename>
			<Text tid='esign.advanced_modal.attachents.table.header.name' hasItalic />
		</Filename>
		<Filesize>
			<Text tid='esign.advanced_modal.attachents.table.header.size' hasItalic />
		</Filesize>
		<Filedate>
			<Text tid='esign.advanced_modal.attachents.table.header.date' hasItalic />
		</Filedate>
		<Controls />
	</ListHeaderWrapper>
)

export default class DocumentsDndSortListItem extends PureComponent {
	static propTypes = {
		doc: map,
		draggableIdProp: string,
		index: number,
		onChange: func,
		getControlsData: func,
		renderNameAddon: func
	}

	static defaultProps = {
		getControlsData: () => {}
	}

	onChange = (value) => {
		const { doc, onChange } = this.props
		onChange(doc, value)
	}

	getControlsComponent = (index) => {
		const { doc, getControlsData } = this.props
		const toBeMerged = doc.getIn(['metadata', 'toBeMerged'])
		const { component, tooltipTid, isDisabled, isDragDisabled } = getControlsData(doc, index)

		if (component) {
			return { component, isDragDisabled }
		}

		return {
			component: (
				<Tooltip tid={tooltipTid} delayShow='instant' active={isDisabled}>
					<ToggleSwitch onChange={this.onChange} checked={toBeMerged} disabled={isDisabled} />
				</Tooltip>
			)
		}
	}

	render = () => {
		const { doc, draggableIdProp, index, renderNameAddon } = this.props
		const file = doc.get('file')
		const ext = file.get('ext')
		const createdAt = file.get('createdAt')
		const draggableId = doc.get(draggableIdProp)
		const { value: fileSizeValue, unit: fileSizeUnit } = documentsHelper.getFileSize(file)
		const fileSize = `${Math.ceil(fileSizeValue)} ${fileSizeUnit}`
		const isBeingGenerated = doc.getIn(['metadata', 'isBeingGenerated']) === true
		const { component: controlsComponent, isDragDisabled } = this.getControlsComponent(index)

		return (
			<Draggable draggableId={draggableId} index={index} key={index} isDragDisabled={isDragDisabled}>
				{(provided, snapshot) => (
					<div ref={provided.innerRef} {...provided.draggableProps}>
						<StyledListItem {...provided.dragHandleProps} isDragDisabled={isDragDisabled} hoverColor='lightestGrey'>
							<DragIcon>
								<Icon icon='faArrowsAlt' type='solid' size='10' color='midGrey' />
							</DragIcon>
							<FileIconWrapper>
								{!isBeingGenerated && <FileIcon ext={ext} />}
								{isBeingGenerated && (
									<Tooltip tid='documents.merge.file_is_being_generated.tooltip' delayShow='instant'>
										<Icon icon='faSpinner' spin={true} size='normal' />
									</Tooltip>
								)}
							</FileIconWrapper>

							<Filename>
								<Text singleLine>{doc.get('title')}</Text>
								{renderNameAddon && renderNameAddon(doc)}
							</Filename>

							<Filesize>
								<Text singleLine>{fileSize}</Text>
							</Filesize>

							<Filedate>
								<Text singleLine>{moment(createdAt).format('L')}</Text>
							</Filedate>

							<Controls>{controlsComponent}</Controls>
						</StyledListItem>
					</div>
				)}
			</Draggable>
		)
	}
}
