import React, { useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormAccordionEntry from '../../common/form-accordion-entry'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import WhiteSection from '../../common/white-section'
import { emissionConsts } from '../../../../constants/emissions'
import { Divider } from '@mui/material'
import { DatePicker, TextField } from 'mui-rff'
import { Field, Form } from 'react-final-form'
import AutoSave from '../../forms/AutoSave'
import { updateSubscriptionSlip } from '../../../../actions/emissions.actions'
import { summarize } from '../../utils/calculations'
import EmissionDisplayValue from '../../common/emission-display-value'
import EmissionDisplayValueList from '../../common/emission-display-value-list'
import { displayDate, displayEmissionType, displayInteger, displayShareCount } from '../../utils/display-helpers'
import RichTextEditor from '../../../../dumb-components/shared/editorv2/rich-text-editor'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import { NUMBER_FORMAT_CURRENCY } from '/shared/constants.json'
import Box from '@mui/material/Box'
import { debounce } from 'lodash'
import { emissionIsReadOnly } from '../../utils/emission-status-helper'
import IconButton from '@mui/material/IconButton'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useScrollViewContext } from '../../../../dumb-components/shared/layout/scroll-view/scroll-view.context'

const {
	common: { allocationTypes, emissionTypes }
} = emissionConsts

const SubscriptionForm = (props) => {
	const { scrollbarRef } = useScrollViewContext()
	const { t } = useTranslation()
	const lang = useSelector((state) => state.i18n.language)
	const company = useSelector((state) => state.company.company)
	const subscriptionSlip = useSelector((state) => state.emissions.subscriptionSlip)
	const emission = props.emission
	const dispatch = useDispatch()
	const currentLang = lang.substring(0, 2)
	const [overrideLanguage, setOverrideLanguage] = useState(currentLang)
	const preventChanges = emissionIsReadOnly(emission, SubscriptionForm.name)
	const [updatedImportantInformation, setUpdatedImportantInformation] = useState()
	const [updatedPackagingAndDragAlong, setUpdatedPackagingAndDragAlong] = useState()

	const initialValues = {
		emissionId: subscriptionSlip.emissionId,
		bank: subscriptionSlip.paymentInfo?.bank,
		accountNumber: subscriptionSlip.paymentInfo?.accountNumber,
		iban: subscriptionSlip.paymentInfo?.iban,
		bic: subscriptionSlip.paymentInfo?.bic,
		texts_en: subscriptionSlip.texts_en,
		texts_sv: subscriptionSlip.texts_sv
	}

	const save = debounce(async (values) => {
		const payload = {
			emissionId: values.emissionId,
			texts_en: values.texts_en,
			texts_sv: values.texts_sv,
			paymentInfo: {
				bank: values.bank,
				accountNumber: values.accountNumber,
				iban: values.iban,
				bic: values.bic
			}
		}
		await dispatch(updateSubscriptionSlip(emission.companyId, emission.id, payload))
	}, 1200)

	const validate = (values) => {
		const errors = {}

		// If allocationType is decision we don't validate payment information
		if (emission.allocationType === allocationTypes.decision) {
			return errors
		}

		if (!values.bank) {
			errors.bank = t('emissions.payment.bank-error', { lng: overrideLanguage })
		}
		if (!values.accountNumber) {
			errors.accountNumber = t('emissions.payment.account-number-error', { lng: overrideLanguage })
		}
		if (!values.iban) {
			errors.iban = t('emissions.payment.iban-error', { lng: overrideLanguage })
		}
		if (!values.bic) {
			errors.bic = t('emissions.payment.bic-error', { lng: overrideLanguage })
		}

		return errors
	}

	useEffect(() => {
		scrollbarRef?.current?.scrollToTop()
	}, [])

	return (
		<Form initialValues={initialValues} onSubmit={save} validate={validate}>
			{({ form, valid, handleSubmit }) => {
				const calculations = summarize(emission)
				return (
					<Grid container>
						<AutoSave
							save={(values) => {
								if (valid) {
									save(values)
								}
							}}
						/>
						<Grid item xs={8}>
							<WhiteSection sx={{ display: 'flex', flexDirection: 'column' }}>
								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<Typography variant='h5'>
										{t('emissions.tabs.subscription-form', { lng: overrideLanguage })}
									</Typography>

									<Box
										sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
										onClick={async () => {
											setOverrideLanguage(overrideLanguage === 'en' ? 'sv' : 'en')

											setUpdatedImportantInformation(
												overrideLanguage === 'sv'
													? subscriptionSlip.texts_en.importantInformation
													: subscriptionSlip.texts_sv.importantInformation
											)

											setUpdatedPackagingAndDragAlong(
												overrideLanguage === 'sv'
													? subscriptionSlip.texts_en.packagingAndDragAlong
													: subscriptionSlip.texts_sv.packagingAndDragAlong
											)
										}}>
										<IconButton size={'small'} color='primary' sx={{ top: -5 }}>
											<EditOutlinedIcon />
										</IconButton>
										<Typography color='primary' variant='h5'>
											{t('emissions.subscription-form.switch-language', {
												lng: overrideLanguage
											})}
										</Typography>
									</Box>
								</Box>
								<Typography variant='body2' sx={{ pt: 2 }}>
									{t('emissions.subscription-form.desc', { lng: overrideLanguage })}
								</Typography>
								<Divider flexItem sx={{ my: 3, border: '1px solid #E3EBF1' }} />{' '}
								<Typography variant='subtitle2'>
									{t('emissions.subscription-form.desc2', {
										lng: overrideLanguage,
										company: company.name
									})}
								</Typography>
								<FormAccordionEntry
									header={t('emissions.header.basic-information', { lng: overrideLanguage })}>
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
											{t('emissions.pdf.subscription-date', { lng: overrideLanguage })}:
										</Typography>
										<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
											{t('public.subscription-slip.subscription-rate', { lng: overrideLanguage })}
											:
										</Typography>
										<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
											{t('emissions.tabs.allocation', { lng: overrideLanguage })}:
										</Typography>
										<Typography sx={{ my: 1, fontWeight: '600' }} variant={'body1'}>
											{t('emissions.subscription-form.payment-due-date', {
												lng: overrideLanguage
											})}
											:
										</Typography>
									</Box>
									<Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
										<Typography sx={{ my: 1 }} variant={'body2'}>
											{displayDate(emission.subscriptionStartDate)} -
											{displayDate(emission.subscriptionEndDate)}
										</Typography>
										<Typography sx={{ my: 1 }} variant={'body2'}>
											{emission.pricePerShare &&
												`${localeFormatNumber(
													emission.pricePerShare,
													NUMBER_FORMAT_CURRENCY
												)} ${t('shares.per_share', { lng: overrideLanguage })}`}
										</Typography>
										{emission.allocationType === allocationTypes.payment ? (
											<Typography sx={{ my: 1 }} variant={'body2'}>
												{t('public.subscription-slip.allocation-by-payment', {
													lng: overrideLanguage
												})}
											</Typography>
										) : (
											<Typography sx={{ my: 1 }} variant={'body2'}>
												{t('public.subscription-slip.allocation-by-decision', {
													lng: overrideLanguage
												})}
											</Typography>
										)}
										<Typography sx={{ my: 1 }} variant={'body2'}>
											{emission.paymentDueDate && displayDate(emission.paymentDueDate)}
										</Typography>
									</Box>
								</FormAccordionEntry>
								{emission.allocationType === allocationTypes.payment && (
									<FormAccordionEntry
										header={t('emissions.subscription-form.payment-information', {
											lng: overrideLanguage
										})}>
										<Grid container rowSpacing={3} columnSpacing={3}>
											<Grid item xs={12}>
												<Typography variant='body2' sx={{ my: 1 }}>
													{t('public.subscription-slip.paymentInfo.desc', {
														lng: overrideLanguage,
														paymentDueDate: displayDate(emission.paymentDueDate)
													})}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<TextField
													disabled={preventChanges}
													name={'bank'}
													label={t('emissions.subscription-form.bank', {
														lng: overrideLanguage
													})}
												/>
											</Grid>
											<Grid item xs={6}>
												<TextField
													disabled={preventChanges}
													name={'accountNumber'}
													label={t('emissions.subscription-form.bank-account-number', {
														lng: overrideLanguage
													})}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													disabled={preventChanges}
													name={'iban'}
													label={t('emissions.subscription-form.iban', {
														lng: overrideLanguage
													})}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													disabled={preventChanges}
													name={'bic'}
													label={t('emissions.subscription-form.bic', {
														lng: overrideLanguage
													})}
												/>
											</Grid>
											<Grid item xs={12}>
												<Typography variant='body2' sx={{ my: 1 }}>
													{t('emissions.subscription-form.payment-reference', {
														lng: overrideLanguage
													})}
												</Typography>
											</Grid>
										</Grid>
									</FormAccordionEntry>
								)}
								<FormAccordionEntry
									header={t('emissions.subscription-form.decided-emission', {
										lng: overrideLanguage
									})}>
									<Grid container rowSpacing={3} columnSpacing={3}>
										<Grid item xs={12}>
											<EmissionDisplayValueList>
												<EmissionDisplayValue
													lang={overrideLanguage}
													label={'emissions.subscription-form.shares-before-emission'}
													value={
														emission.currentShareCount &&
														`${displayInteger(emission.currentShareCount)}  ${t(
															'public.subscription-slip.container.shares',
															{ lng: overrideLanguage }
														)}`
													}
												/>
												<EmissionDisplayValue
													lang={overrideLanguage}
													label={'emissions.form-field.emission-type'}
													value={t(displayEmissionType(emission), { lng: overrideLanguage })}
												/>
												{emission.emissionType === emissionTypes.preferential && (
													<EmissionDisplayValue
														lang={overrideLanguage}
														label={'emissions.subscription-form.pro-rata-due-date'}
														value={
															emission.proRataDueDate &&
															displayDate(emission.proRataDueDate)
														}
													/>
												)}
												<EmissionDisplayValue
													lang={overrideLanguage}
													label={'emissions.pdf.new-share-count'}
													value={
														(emission.newShareCountMin || emission.newShareCountMax) &&
														`${displayShareCount(emission)}  ${t(
															'public.subscription-slip.container.shares',
															{ lng: overrideLanguage }
														)}`
													}
												/>
												<EmissionDisplayValue
													lang={overrideLanguage}
													label={'emissions.form-field.price-per-share'}
													value={
														emission.pricePerShare &&
														localeFormatNumber(
															emission.pricePerShare,
															NUMBER_FORMAT_CURRENCY
														)
													}
												/>
												<EmissionDisplayValue
													lang={overrideLanguage}
													label={'emissions.form-field.quote-value.current'}
													value={
														emission.quoteValue &&
														localeFormatNumber(emission.quoteValue, NUMBER_FORMAT_CURRENCY)
													}
												/>
												<EmissionDisplayValue
													lang={overrideLanguage}
													label={'emissions.form-field.emission-amount'}
													value={
														calculations.emissionAmount !== '-' &&
														`${calculations.emissionAmount} SEK`
													}
												/>
												<EmissionDisplayValue
													lang={overrideLanguage}
													label={'emissions.subscription-form.last-apply-date'}
													value={
														emission.subscriptionEndDate &&
														displayDate(emission.subscriptionEndDate)
													}
												/>
												<EmissionDisplayValue
													lang={overrideLanguage}
													label={'emissions.subscription-form.payment-due-date'}
													value={
														emission.paymentDueDate && displayDate(emission.paymentDueDate)
													}
												/>
											</EmissionDisplayValueList>
										</Grid>
										<Grid item xs={12}>
											<TextField
												disabled={preventChanges}
												multiline
												name={`texts_${overrideLanguage}.noDepot`}
												label={t('emissions.subscription-form.transaction', {
													lng: overrideLanguage
												})}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												disabled={preventChanges}
												multiline
												name={`texts_${overrideLanguage}.noticeOfAllocation`}
												label={t('emissions.subscription-form.notice-of-allocation', {
													lng: overrideLanguage
												})}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												disabled={preventChanges}
												multiline
												name={`texts_${overrideLanguage}.dividendRights`}
												label={t('emissions.subscription-form.dividend-rights', {
													lng: overrideLanguage
												})}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												disabled={preventChanges}
												multiline
												name={`texts_${overrideLanguage}.companyDisclaimers`}
												label={t('emissions.subscription-form.preferential-right', {
													lng: overrideLanguage
												})}
											/>
										</Grid>
										<Grid item xs={12}>
											<FormAccordionEntry
												header={t('emissions.subscription-form.important-information', {
													lng: overrideLanguage
												})}>
												<Grid item xs={12}>
													<Field name={`texts_${overrideLanguage}.importantInformation`}>
														{(props) => {
															return (
																<div>
																	<RichTextEditor
																		readOnly={preventChanges}
																		onChange={props.input.onChange}
																		onBlur={() => {
																			props.input.onBlur()
																			setTimeout(() => handleSubmit(), 500)
																		}}
																		label={t(
																			'emissions.subscription-form.important-information',
																			{ lng: overrideLanguage }
																		)}
																		value={props.input.value}
																	/>
																</div>
															)
														}}
													</Field>
												</Grid>
											</FormAccordionEntry>
											<FormAccordionEntry
												header={t('emissions.subscription-form.packaging-and-drag-along', {
													lng: overrideLanguage
												})}>
												<Grid item xs={12}>
													<Field name={`texts_${overrideLanguage}.packagingAndDragAlong`}>
														{(props) => {
															return (
																<div>
																	<RichTextEditor
																		readOnly={preventChanges}
																		onChange={props.input.onChange}
																		onBlur={() => {
																			props.input.onBlur()
																			setTimeout(() => handleSubmit(), 500)
																		}}
																		label={t(
																			'emissions.subscription-form.packaging-and-drag-along',
																			{ lng: overrideLanguage }
																		)}
																		value={props.input.value}
																	/>
																</div>
															)
														}}
													</Field>
												</Grid>
											</FormAccordionEntry>
										</Grid>
									</Grid>
								</FormAccordionEntry>
							</WhiteSection>
						</Grid>
					</Grid>
				)
			}}
		</Form>
	)
}

export default SubscriptionForm
