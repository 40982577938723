import React, { PureComponent } from 'react'
import { string, object } from 'prop-types'
import { Padding } from 'styled-components-spacing'
import Grid from 'styled-components-grid'

import Panel from '../shared/panel/panel'
import Label from '../shared/label/label'
import Input from '../shared/input/input'

export default class CompanyDetailsRight extends PureComponent {
	static propTypes = {
		branchOfIndustry: string,
		businessOverviewComponent: object
	}

	static defaultProps = {
		branchOfIndustry: ''
	}

	render = () => {
		const { branchOfIndustry, businessOverviewComponent } = this.props

		return (
			<Panel title='company.profile.details.business_overview' marginBottom>
				<Padding all={3}>
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<Label tid='company.profile.details.branch_of_industry'>
									<Input value={branchOfIndustry} disabled />
								</Label>
							</Padding>
						</Grid.Unit>
					</Grid>

					{businessOverviewComponent && (
						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Label tid='company.profile.details.business_overview'>{businessOverviewComponent}</Label>
								</Padding>
							</Grid.Unit>
						</Grid>
					)}
				</Padding>
			</Panel>
		)
	}
}
