import React from 'react'
import { getCompanyLogoUrl } from '../../../../components/helpers/company.helper'
import MUIDialog from '../../../../mui-components/dialog/mui-dialog'
import styles from './companyadvertsmodal.module.css'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

function CompanyAdvertsModal({ advert, closeModal }) {
	const logoUrl = getCompanyLogoUrl(advert.company.id, advert.logotype, 100)

	return (
		<MUIDialog
			isOpen={true}
			title={advert.company.name}
			onClose={closeModal}
			confirmButton={false}
			maxWidth='lg'
			closeButtonTid='generic.form.close'>
			<Box sx={{ display: 'flex', gap: '24px' }}>
				{advert.logotype && logoUrl && <img src={logoUrl} alt={advert.company.name} className={styles.img} />}
				<div dangerouslySetInnerHTML={{ __html: advert.text }} />
			</Box>
		</MUIDialog>
	)
}

export { CompanyAdvertsModal }
