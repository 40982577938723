import Box from '@mui/material/Box'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import RichTextEditor from '../../../../dumb-components/shared/editorv2/rich-text-editor'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import CustomFileIcon from '../../../../dumb-components/shared/file-icon/file-icon'
import ListItemText from '@mui/material/ListItemText'

const LeftSide = (props) => {
	const { t } = useTranslation()

	return (
		<Box sx={props.sx}>
			<Typography sx={{ pt: 2 }} variant='h4'>
				{t('emissions.invitations.send-invite')}
			</Typography>
			<Typography sx={{ pt: 2 }} variant='body2'>
				{t('emissions.invitations.invite-text')}
			</Typography>
			<Typography sx={{ pt: 2 }} variant='body2'>
				{t('emissions.invitations.invite-text.link-info')}
			</Typography>
			<Typography sx={{ pt: 4 }} variant='subtitle1'>
				{t('emissions.invitations.message')}
			</Typography>
			<Box sx={{ pt: 1 }}>
				<RichTextEditor
					editorStyle={{ maxHeight: '44rem' }}
					value={props.template}
					updateValue={props.template}
					onChange={props.onEmailTemplateChanged}
				/>
			</Box>
		</Box>
	)
}

const RightSide = (props) => {
	const { t } = useTranslation()

	const tidToInviteeType = {
		shareholders: 'emissions.invitations.num_of_shareholders_to_invite',
		'potential-investors': 'emissions.invitations.num_of_potential_investors_to_invite'
	}

	return (
		<Box sx={props.sx}>
			{typeof props.numOfPeopleToInvite !== 'undefined' && tidToInviteeType[props.typeOfInvitees] && (
				<Typography sx={{ pt: 4 }} variant='subtitle1'>
					<div
						dangerouslySetInnerHTML={{
							__html: t(tidToInviteeType[props.typeOfInvitees], {
								numOfPeopleToInvite: props.numOfPeopleToInvite
							})
						}}
					/>
				</Typography>
			)}
			<Typography sx={{ pt: 2 }} variant='subtitle1'>
				{t('emissions.invitations.shared-files', { count: props.files.length })}
			</Typography>
			<Box>
				<List sx={{ maxHeight: 640, overflow: 'auto', pr: 1, whiteSpace: 'nowrap' }}>
					{props.files.map((file, i) => {
						const parts = file.name.split('.')
						const ext = parts[parts.length - 1]

						return (
							<ListItem key={i} sx={{ bgcolor: 'background.paper', mt: 1 }}>
								<ListItemIcon sx={{ height: 48 }}>
									<CustomFileIcon ext={ext} />
								</ListItemIcon>
								<ListItemText
									sx={{ ml: 1 }}
									primary={
										<Typography
											variant='body2'
											color='text.primary'
											sx={{ pb: 1.5, textOverflow: 'ellipsis', overflow: 'hidden' }}>
											{file.name}
										</Typography>
									}
									secondary={`${file.sizeMb} MB`}
								/>
							</ListItem>
						)
					})}
				</List>
			</Box>
		</Box>
	)
}

const BaseInvitationModal = (props) => {
	const [px, py] = [6, 4]

	return (
		<Box sx={{ display: 'flex', height: '100%' }}>
			<LeftSide
				sx={{ flex: 2, paddingRight: '32px' }}
				template={props.invite.template}
				onEmailTemplateChanged={props.onEmailTemplateChanged}
			/>
			<RightSide
				sx={{ flex: 1, bgcolor: 'blue.200', px, py, minWidth: '52%', overflow: 'auto' }}
				files={props.invite.files}
				typeOfInvitees={props.typeOfInvitees}
				numOfPeopleToInvite={props.numOfPeopleToInvite}
			/>
		</Box>
	)
}

export default BaseInvitationModal
