import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import { number } from 'prop-types'
import filterAndSort from '../../components/helpers/filter-and-sort.helper'

import { setFilteredDocuments } from '../../actions/documents.actions'

class DocumentsFilterAndSortContainer extends Component {
	applyFiltersAndSort = () => {
		const {
			allDocuments,
			setFilteredDocuments,
			filters,
			usersCache,
			usersInRoleCache,
			filterDocumentsChanged,
			documentsExcludedFromFiltering
		} = this.props
		let visibleDocuments = allDocuments
		const hasAppliedFilters = this.hasAppliedFilters(filters)
		if (visibleDocuments) {
			const filtering = new filterAndSort(
				visibleDocuments,
				documentsExcludedFromFiltering,
				filters,
				usersCache,
				usersInRoleCache
			)
			visibleDocuments = filtering.filter()
			visibleDocuments = filtering.sort()
			setFilteredDocuments(visibleDocuments, hasAppliedFilters)
			filterDocumentsChanged && filterDocumentsChanged(visibleDocuments.size, allDocuments.size)
		}
	}

	hasAppliedFilters = (filters) => {
		const { defaultFilters } = this.props
		let hasAppliedFilters = false

		filters.forEach((filter) => {
			const source = filter.get('source')
			const values = filter.get('values')
			const val = values && values.get(0)

			// Covers basic filter
			if ((source === 'document' || source === 'tag' || source === 'folder') && values.size !== 0) {
				hasAppliedFilters = true
				return
			}

			// Check default filters
			if (
				source !== 'document' &&
				source !== 'tag' &&
				source !== 'folder' &&
				((defaultFilters.has(source) && defaultFilters.getIn([source, 0]) !== val) || !defaultFilters.has(source))
			) {
				hasAppliedFilters = true
				return
			}
		})

		return hasAppliedFilters
	}

	componentDidMount = () => {
		this.applyFiltersAndSort()
	}

	componentDidUpdate = (prevProps) => {
		const { allDocuments, filters, defaultFilters } = this.props
		if (prevProps.allDocuments !== allDocuments) {
			this.applyFiltersAndSort()
		}
		if (prevProps.filters !== filters) {
			this.applyFiltersAndSort()
		}
		if (prevProps.defaultFilters !== defaultFilters) {
			this.applyFiltersAndSort()
		}
	}

	render = () => {
		return this.props.children
	}
}

const mapStoreToProps = (store, ownProps) => {
	return {
		allDocuments: ownProps.isSharedFromCompanyId
			? store.documents.get('sharedDocuments')
			: store.documents.get('allDocuments'),
		documentsExcludedFromFiltering: store.documents.get('documentsExcludedFromFiltering'),
		filters: store.documents.get('filterBy'),
		defaultFilters: store.documents.get('defaultFilters'),
		usersCache: store.usersCache.get('usersCache', Map()),
		usersInRoleCache: store.company.userManagement.usersInRoleCache
	}
}

const mapActionsToProps = {
	setFilteredDocuments
}

export default connect(mapStoreToProps, mapActionsToProps)(DocumentsFilterAndSortContainer)
