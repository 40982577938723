import React, { Component } from 'react'
import { connect } from 'react-redux'

import AlertBetaAgmInfo from '../../notifications/onboarding/alerts/dashboard/beta-agm-info'
import AlertNewEsigningInfo from '../../notifications/onboarding/alerts/dashboard/new-esigning-info'
import AlertPremiumSmartMeetingsInfo from '../../notifications/onboarding/alerts/dashboard/premium-smart-meetings-info'

class CompanyDashboardAlerts extends Component {
	render = () => {
		return (
			<>
				<AlertBetaAgmInfo />
				<AlertNewEsigningInfo />
				{/*<AlertPremiumSmartMeetingsInfo />*/}
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyDashboardAlerts)
