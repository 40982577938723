import { List } from "immutable";

const obj = {

	itemsToApply: (templateItems) => {
		let items = List()
		templateItems.forEach((item) => {
			item = item.set('generatedByTemplate', true);

			const children = item.get('items') || List();

			if (item.get('active') && children.size === 0) {
				items = items.push(item);

			} else if (children.size > 0) {
				const subitems = item.get('items').filter((i) => {
					return i.get('active');
				});

				const agendaItems = subitems.map((i) => {
					i.set('generatedByTemplate', true);
					return i;
				});

				item = item.set('agendaItems', agendaItems)


				if (item.get('agendaItems').size > 0) {
					items = items.push(item);
				}
			}
		});
		return items;

	}

};

export default obj;
