import React, { Component } from 'react'
import { connect } from 'react-redux'

import OnboardingModal from '../../../../../dumb-components/onboarding/modal'
import { FirstStepBody, SecondStepBody } from '../../../../../dumb-components/onboarding/modals/documents/intro'
import FakeFooterSpacer from '../../../../../dumb-components/shared/modal/fake-footer-spacer'
import { ButtonTransparentIcon } from '../../../../../dumb-components/shared/button/'
import HeaderControls from '../../header-controls.container'
import ButtonGoToBilling from '../shared/btn-go-to-billing.container'

import { getGlobalRef } from '../../../../../components/helpers/global-refs.helper'
import { REF_DOCUMENTS_UPLOAD_DROPZONE } from '../../../../../constants/global-refs'

import { markOnboardAsRead } from '../../../../../actions/notifications.actions'
import { closeModal } from '../../../../../actions/modals.actions'

class DocumentsIntroContainer extends Component {
	state = {
		step: 0
	}

	openUploadView = () => {
		getGlobalRef(REF_DOCUMENTS_UPLOAD_DROPZONE).open()
		this.markModalAsRead()
	}

	goToPageTwo = () => {
		this.setState({ step: 1 })
	}

	onGoToBeginning = () => {
		this.setState({ step: 0 })
	}

	markModalAsRead = () => {
		const { markOnboardAsRead, closeModal, notificationId } = this.props

		markOnboardAsRead(notificationId)
		closeModal()
	}

	getSteps = () => {
		const { eSigningEnabled } = this.props

		return [
			{
				body: <FirstStepBody openUpload={this.openUploadView} showEsignInfo={this.goToPageTwo} />,
				footer: {
					component: <FakeFooterSpacer height={36} />
				}
			},
			{
				body: (
					<SecondStepBody
						billingButton={
							!eSigningEnabled ? (
								<ButtonGoToBilling
									onDone={this.markModalAsRead}
									tid='onboarding.modals.documents.intro.esign.more.btn.upgrade'
								/>
							)
								: null
						}
					/>
				),
				footer: {
					leftComponent: <ButtonTransparentIcon onClick={this.onGoToBeginning} icon='faLongArrowLeft' size='xl' />
				}
			}
		]
	}

	render = () => {
		return (
			<OnboardingModal
				title='TODO: Title'
				mode='steps'
				step={this.state.step}
				steps={this.getSteps()}
				dropdownComponent={<HeaderControls type='DROPDOWN' />}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		notificationId: store.modals.getIn(['activeModal', 'options', 'notificationId']),
		eSigningEnabled: store.subscriptions.getIn(['helper', 'eSigningEnabled'])
	}
}

const mapActionsToProps = {
	markOnboardAsRead,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(DocumentsIntroContainer)
