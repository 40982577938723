import React, { PureComponent } from 'react'
import immutablePropTypes from 'react-immutable-proptypes'
import { func, bool, string, object, oneOf } from 'prop-types'
import { List, Map } from 'immutable'
import { Padding, Margin } from 'styled-components-spacing'
import Grid from 'styled-components-grid'
import styled, { css } from 'styled-components'
import Panel from '../../shared/panel/panel'
import CreditCardInput from '../../shared/credit-card-input/credit-card-input'
import Button from '../../shared/button/button'
import AddressInput from '../../shared/address-input/address-input'
import Checkbox from '../../shared/checkbox/checkbox'
import Text from '../../shared/text/text'
import countries from 'localized-countries'
import swedishCountryNames from 'localized-countries/data/sv.json'
import englishCountryNames from 'localized-countries/data/en.json'
import Icon from '../../shared/icon/icon'
import Tooltip from '../../shared/tooltip/tooltip'
import Input from '../../shared/input/input'
import Label from '../../shared/label/label'
import { Select } from '../../shared/select'
import { getTaxIdType } from '/shared/helpers/subscriptions.helper'

const StyledWrapper = styled.div`
	${(props) =>
		props.mode === 'modal' &&
		css`
			margin: -${(props) => props.theme.spacing[3]};
		`}
`

const StyledPaymentDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const StyledPaymentDetailsRow = styled.div`
	display: flex;
	flex-direction: row;
	height: 30px;
	align-items: center;
`

const StyledPaymentDetailsLabel = styled.div`
	min-width: 180px;
	max-width: 240px;
	margin-right: ${(props) => props.theme.spacing[4]};
`

const StyledPaymentDetailsValue = styled.div`
	white-space: nowrap;
`

export default class PaymentDetailsPanel extends PureComponent {
	static propTypes = {
		paymentDetails: immutablePropTypes.map,
		onChange: func,
		isLoading: bool,
		onSave: func,
		companyAddressUsed: bool,
		onChangeCompanyAddressUsed: func,
		errors: immutablePropTypes.map,
		disabled: bool,
		language: string,
		i18n: object,
		mode: oneOf(['panel', 'modal']),
		viewMode: oneOf(['read', 'edit']),
		changeViewMode: func,
		companyHasAddresses: bool,
		stripeError: immutablePropTypes.map
	}

	static defaultProps = {
		mode: 'panel',
		companyCountry: 'SE'
	}

	getCreditcardIcon = () => {
		const { paymentDetails } = this.props
		let icon
		let iconStyle = 'brand'

		switch (paymentDetails.get('brand')) {
			case 'Visa':
				icon = 'faCcVisa'
				break
			case 'MasterCard':
				icon = 'faCcMastercard'
				break
			case 'JCB':
				icon = 'faCcJcb'
				break
			case 'Discover':
				icon = 'faCcDiscover'
				break
			case 'Diners Club':
				icon = 'faCcDinersClub'
				break
			case 'American Express':
				icon = 'faCcAmex'
				break
			default:
				icon = 'faCreditCardBlank'
				iconStyle = 'solid'
		}

		return { icon, iconStyle }
	}

	getCreditCard = () => {
		const { paymentDetails } = this.props

		return Map({
			number: paymentDetails.get('number'),
			exp_month: paymentDetails.get('exp_month'),
			exp_year: paymentDetails.get('exp_year'),
			cvc: paymentDetails.get('cvc'),
			name: paymentDetails.get('name')
		})
	}

	getAddress = () => {
		const { paymentDetails, companyCountry } = this.props

		return Map({
			address_line1: paymentDetails.get('address_line1'),
			address_line2: paymentDetails.get('address_line2'),
			address_zip: paymentDetails.get('address_zip'),
			address_city: paymentDetails.get('address_city'),
			address_state: paymentDetails.get('address_state'),
			address_country: paymentDetails.get('address_country')
		})
	}

	getCountryName = (countryCode) => {
		const { language } = this.props

		return countries(
			language && language.substr(0, 2).toLowerCase() === 'sv' ? swedishCountryNames : englishCountryNames
		).get(countryCode)
	}

	getTaxIdTypeOptions = () => {
		const { paymentDetails } = this.props
		const taxIdTypes = getTaxIdType(paymentDetails.get('address_country'))

		if (!taxIdTypes) {
			return
		}

		let options = List()

		taxIdTypes.forEach((taxIdType) => {
			options = options.push(
				Map({
					value: taxIdType,
					label: `tax_id.${taxIdType}`
				})
			)
		})

		return options
	}

	renderEditView = () => {
		const {
			paymentDetails,
			onChange,
			isLoading,
			onSave,
			companyAddressUsed,
			onChangeCompanyAddressUsed,
			language,
			errors,
			disabled,
			i18n,
			mode,
			changeViewMode,
			companyHasAddresses
		} = this.props
		const creditCard = this.getCreditCard()
		const address = this.getAddress()

		const isModalMode = mode === 'modal'
		const isPanelMode = mode === 'panel'
		let outerPadding = isPanelMode && 3

		return (
			<Panel title='subscriptions.payment_details' nakedStyle={isModalMode} marginBottom>
				<StyledWrapper mode={mode}>
					<Padding all={outerPadding}>
						<CreditCardInput
							creditCard={creditCard}
							onChange={onChange}
							disabled={disabled}
							errors={errors}
							i18n={i18n}
						/>

						{paymentDetails.get('address_country') !== 'SE' && (
							<Grid>
								<Grid.Unit size={1 / 3}>
									<Padding all={3}>
										<Label tid='subscriptions.tax_id_type.label'>
											<Select
												fieldName='taxIdType'
												labelIsTid={true}
												isClearable={false}
												onChange={onChange}
												options={this.getTaxIdTypeOptions()}
												value={paymentDetails.get('taxIdType')}
												isSearchable={false}
												hasError={errors && errors.get('taxIdType')}
											/>
										</Label>
									</Padding>
								</Grid.Unit>
								<Grid.Unit size={2 / 3}>
									<Padding all={3}>
										<Label tid='subscriptions.vat_number.label'>
											<Input
												fieldName='vatNumber'
												onChange={onChange}
												value={paymentDetails.get('vatNumber')}
												hasError={errors && errors.get('vatNumber')}
											/>
										</Label>
									</Padding>
								</Grid.Unit>
							</Grid>
						)}

						<Grid>
							<Grid.Unit size={1}>
								<Padding all={3}>
									<Tooltip tid='subscriptions.use_company_address.tooltip.no_addresses' active={!companyHasAddresses}>
										<Checkbox
											mode='modern-big'
											fieldName='companyAddressUsed'
											onChange={onChangeCompanyAddressUsed}
											checked={companyAddressUsed ? true : false}
											disabled={disabled || !companyHasAddresses}>
											<Text tid='subscriptions.use_company_address' />
										</Checkbox>
									</Tooltip>
								</Padding>
							</Grid.Unit>
						</Grid>

						<AddressInput
							address={address}
							onChange={onChange}
							language={language}
							showSecondAddressLine={false}
							errors={errors}
							disabled={disabled}
							withCountryPortal={isModalMode}
						/>

						{isPanelMode && (
							<Grid>
								<Padding all={3}>
									<Button disabled={isLoading} tid='generic.form.cancel' onClick={() => changeViewMode('read')} />
									<Button
										mode='primary'
										isLoading={isLoading}
										tid='subscriptions.payment_details.button.save'
										onClick={onSave}
									/>
								</Padding>
							</Grid>
						)}
					</Padding>
				</StyledWrapper>
			</Panel>
		)
	}

	renderReadOnlyView = () => {
		const { paymentDetails, changeViewMode } = this.props
		const creditCard = this.getCreditCard()
		const address = this.getAddress()
		const { icon, iconStyle } = this.getCreditcardIcon()
		const creditCardNumber = creditCard.get('number', '') || ''
		const shortenedCreditCardNumber = creditCardNumber.toString().slice(-4)
		const taxIdType = paymentDetails.get('taxIdType')
		const vatNumber = paymentDetails.get('vatNumber')
		const hasTaxId = taxIdType && vatNumber

		return (
			<Panel title='subscriptions.payment_details' marginBottom>
				<Padding all={3}>
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<StyledPaymentDetailsContainer>
									<StyledPaymentDetailsRow>
										<StyledPaymentDetailsLabel>
											<Text tid='subscriptions.card_number' />
										</StyledPaymentDetailsLabel>
										<StyledPaymentDetailsValue>
											<Icon icon={icon} type={iconStyle} />
											&nbsp;<Text bold={600}>{`**** ${shortenedCreditCardNumber}`}</Text>
										</StyledPaymentDetailsValue>
									</StyledPaymentDetailsRow>

									<StyledPaymentDetailsRow>
										<StyledPaymentDetailsLabel>
											<Text tid='subscriptions.expires' />
										</StyledPaymentDetailsLabel>
										<StyledPaymentDetailsValue>
											<Text bold={600}>{`${creditCard.get('exp_month')} / ${creditCard.get('exp_year')}`}</Text>
										</StyledPaymentDetailsValue>
									</StyledPaymentDetailsRow>

									<StyledPaymentDetailsRow>
										<StyledPaymentDetailsLabel>
											<Text tid='subscriptions.name' />
										</StyledPaymentDetailsLabel>
										<StyledPaymentDetailsValue>
											<Text bold={600}>{creditCard.get('name')}</Text>
										</StyledPaymentDetailsValue>
									</StyledPaymentDetailsRow>

									<StyledPaymentDetailsRow>
										<StyledPaymentDetailsLabel>
											<Text tid='subscriptions.customer_address.address_line' />
										</StyledPaymentDetailsLabel>
										<StyledPaymentDetailsValue>
											<Text bold={600}>{address.get('address_line1')}</Text>
										</StyledPaymentDetailsValue>
									</StyledPaymentDetailsRow>

									<StyledPaymentDetailsRow>
										<StyledPaymentDetailsLabel>
											<Text tid='subscriptions.customer_address.postal_code' />
										</StyledPaymentDetailsLabel>
										<StyledPaymentDetailsValue>
											<Text bold={600}>{address.get('address_zip')}</Text>
										</StyledPaymentDetailsValue>
									</StyledPaymentDetailsRow>

									<StyledPaymentDetailsRow>
										<StyledPaymentDetailsLabel>
											<Text tid='subscriptions.customer_address.city' />
										</StyledPaymentDetailsLabel>
										<StyledPaymentDetailsValue>
											<Text bold={600}>{address.get('address_city')}</Text>
										</StyledPaymentDetailsValue>
									</StyledPaymentDetailsRow>

									<StyledPaymentDetailsRow>
										<StyledPaymentDetailsLabel>
											<Text tid='subscriptions.customer_address.country' />
										</StyledPaymentDetailsLabel>
										<StyledPaymentDetailsValue>
											<Text bold={600}>{this.getCountryName(address.get('address_country'))}</Text>
										</StyledPaymentDetailsValue>
									</StyledPaymentDetailsRow>

									{hasTaxId && (
										<StyledPaymentDetailsRow>
											<StyledPaymentDetailsLabel>
												<Text tid={`tax_id.${taxIdType}`} />
											</StyledPaymentDetailsLabel>
											<StyledPaymentDetailsValue>
												<Text bold={600}>{vatNumber}</Text>
											</StyledPaymentDetailsValue>
										</StyledPaymentDetailsRow>
									)}
								</StyledPaymentDetailsContainer>
							</Padding>
						</Grid.Unit>
					</Grid>

					<Grid>
						<Padding all={3}>
							<Button
								mode='primary'
								tid={'subscriptions.payment_details.button.update_payment_details'}
								onClick={() => changeViewMode('edit')}
							/>
						</Padding>
					</Grid>
				</Padding>
			</Panel>
		)
	}

	render = () => {
		const { viewMode } = this.props

		if (viewMode === 'read') {
			return this.renderReadOnlyView()
		}

		return this.renderEditView()
	}
}
