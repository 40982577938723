import React, { PureComponent } from 'react'
import { string, bool, func } from 'prop-types'
import styled from 'styled-components'
import { map, list } from 'react-immutable-proptypes'
import { getUserName } from '../../../components/helpers/users'

import { StyledListItem, StyledName } from './notify-about-signed-doc.template'
import List from '../../shared/list/list'
import ListItem from '../../shared/list-item/list-item'
import Text from '../../shared/text/text'

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`

const StyledLinkWrapper = styled.div`
	display: flex;
	margin-left: ${(props) => props.theme.spacing[3]};
`

export default class NotifyAboutSignedDoc extends PureComponent {
	static propTypes = {
		shareWith: map,
		investors: list,
		usersCache: map,
		onDeleteUser: func
	}

	renderUserItem = (userToNotify, index) => {
		const { usersCache, investors, onDeleteUser } = this.props
		const name = getUserName(userToNotify, investors, usersCache)

		return (
			<StyledListItem key={index} hoverColor='lightestGrey' striped>
				<StyledName singleLine>{name}</StyledName>
				<StyledLinkWrapper>
					<Text tid='generic.remove' onClick={onDeleteUser.bind(null, userToNotify)} hasUnderline />
				</StyledLinkWrapper>
			</StyledListItem>
		)
	}

	render = () => {
		const { shareWith } = this.props

		return (
			<StyledWrapper>
				<List>{shareWith.toList().map(this.renderUserItem)}</List>
			</StyledWrapper>
		)
	}
}
