import { MEETINGS_FETCH_MEETING, MEETINGS_SAVE_ATTENDEE, MEETINGS_SAVE_ATTENDEES } from '../actions/types'
import { fromJS } from 'immutable'

const INITIAL_STATE = fromJS({
	meeting: {}
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case MEETINGS_FETCH_MEETING:
			return state.set('meeting', payload)

		case MEETINGS_SAVE_ATTENDEE: {
			return state.setIn(['meeting', 'attendees', payload.get('userId')], payload)
		}

		case MEETINGS_SAVE_ATTENDEES: {
			return state.setIn(['meeting', 'attendees'], payload)
		}

		default:
			return state
	}
}
