import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, oneOf, string, bool } from 'prop-types'
import ImmutableProps from 'react-immutable-proptypes'
import { List, Map, Set } from 'immutable'
import ObjectPermissions from '../../dumb-components/shared/object-permissions/object-permissions'
import { fetchSimpleUsers } from '../../actions/usersCache.actions'
import { fetchRolesCache, fetchUsersInRolesCache } from '../../actions/user-management.actions'
import permissionsHelper from '../../components/helpers/permissions.helper'

const SUPER_ADMIN = 'aed45ce5-291f-438f-a47a-f547fdecc5ee'

class ObjectPermissionsPanel extends Component {
	static propTypes = {
		closeTrigger: string,
		permissions: ImmutableProps.map,
		onChange: func.isRequired,
		type: oneOf(['inline', 'transparent']),
		selected: bool,
		infoTextTid: string,
		noTitle: bool,
		marginBottom: bool
	}

	state = {
		rolesName: List()
	}

	componentDidMount = () => {
		const { permissions } = this.props
		this.fetchRoleNames(permissions)
		this.fetchUserNames(permissions)
		this.fetchUsersInRolesCache(permissions)
	}

	componentDidUpdate = (prevProps) => {
		const { permissions } = this.props

		if (prevProps.permissions !== permissions) {
			this.fetchRoleNames(permissions)
			this.fetchUserNames(permissions)
			this.fetchUsersInRolesCache(permissions)
		}
	}

	fetchRoleNames = (permissions) => {
		permissions && this.props.fetchRolesCache(permissions.getIn(['roles', 'rolesWithAccess'], Map()).keySeq().toList())
	}

	fetchUserNames = (permissions) => {
		permissions && this.props.fetchSimpleUsers(permissions.getIn(['users', 'usersWithAccess'], Map()).keySeq().toList())
	}

	fetchUsersInRolesCache = (permissions) => {
		permissions &&
			this.props.fetchUsersInRolesCache(permissions.getIn(['roles', 'rolesWithAccess'], Map()).keySeq().toList())
	}

	onToggleRestricted = (isChecked) => {
		let { permissions, usersInRoleCache, usersCache } = this.props
		const { onChange } = this.props

		if (isChecked) {
			// move the "selected roles to whitelist and remove blacklist"
			if (!permissions.getIn(['roles', 'whitelist'])) {
				permissions = permissions.setIn(['roles', 'whitelist'], Map())
			}

			if (!permissions.getIn(['users', 'whitelist'])) {
				permissions = permissions.setIn(['users', 'whitelist'], Map())
			}

			permissions = permissions.setIn(['roles', 'originalWhitelist'], permissions.getIn(['roles', 'whitelist']))
			permissions = permissions.setIn(['users', 'originalWhitelist'], permissions.getIn(['users', 'whitelist']))

			const selectedValues = permissionsHelper.parseRoleValues(permissions)
			selectedValues.forEach((value) => {
				const obj = permissions.getIn(['roles', 'rolesWithAccess', value])
				permissions = permissions.setIn(['roles', 'whitelist', value], obj)
			})

			const userValues = permissionsHelper.parseUsersValues(permissions, usersInRoleCache, usersCache)
			userValues.forEach((value) => {
				const obj = permissions.getIn(['users', 'usersWithAccess', value])
				permissions = permissions.setIn(['users', 'whitelist', value], obj)
			})
		} else {
			//Make sure we don't inherit things when we unlock.
			if (!permissions.getIn(['roles', 'whitelist'])) {
				permissions = permissions.setIn(['roles', 'whitelist'], Map())
			}

			if (!permissions.getIn(['users', 'whitelist'])) {
				permissions = permissions.setIn(['users', 'whitelist'], Map())
			}

			if (!permissions.getIn(['roles', 'blacklist'])) {
				permissions = permissions.setIn(['roles', 'blacklist'], Map())
			}

			if (!permissions.getIn(['users', 'blacklist'])) {
				permissions = permissions.setIn(['users', 'blacklist'], Map())
			}

			//Blacklist all the users that doesn't have access. This is why it be like it do.
			const setInheritedWhitelistRoles = Set.fromKeys(permissions.getIn(['roles', 'inheritedWhitelist']))
			const setWhitelistRoles = Set.fromKeys(permissions.getIn(['roles', 'whitelist']))
			const rolesToBlacklist = setInheritedWhitelistRoles.subtract(setWhitelistRoles)
			rolesToBlacklist.forEach((roleId) => {
				permissions = permissions.setIn(
					['roles', 'blacklist', roleId],
					permissions.getIn(['roles', 'rolesWithAccess', roleId])
				)
			})

			const setInheritedWhitelistUsers = Set.fromKeys(permissions.getIn(['users', 'inheritedWhitelist']))
			const setWhitelistUsers = Set.fromKeys(permissions.getIn(['users', 'whitelist']))
			const usersToBlacklist = setInheritedWhitelistUsers.subtract(setWhitelistUsers)
			usersToBlacklist.forEach((userId) => {
				permissions = permissions.setIn(
					['users', 'blacklist', userId],
					permissions.getIn(['users', 'usersWithAccess', userId])
				)
			})

			permissions = permissions.setIn(['roles', 'whitelist'], permissions.getIn(['roles', 'originalWhitelist'], Map()))
			permissions = permissions.deleteIn(['roles', 'originalWhitelist'])

			permissions = permissions.setIn(['users', 'whitelist'], permissions.getIn(['users', 'originalWhitelist'], Map()))
			permissions = permissions.deleteIn(['users', 'originalWhitelist'])
		}
		permissions = permissions.set('isRestricted', isChecked)
		onChange('permissions', permissions)
	}

	onResetDefaultsRoles = () => {
		const { onChange } = this.props
		let { permissions } = this.props

		if (permissions.get('isRestricted')) {
			return
		}

		permissions = permissions.setIn(['roles', 'whitelist'], Map())
		permissions = permissions.setIn(['roles', 'blacklist'], Map())
		onChange('permissions', permissions)
	}

	onRemoveRole = (roleId) => {
		const { onChange } = this.props
		let { permissions } = this.props

		if (!permissions.getIn(['roles', 'blacklist'])) {
			permissions = permissions.setIn(['roles', 'blacklist'], Map())
		}

		if (permissions.getIn(['roles', 'whitelist', roleId])) {
			permissions = permissions.deleteIn(['roles', 'whitelist', roleId])
		} else {
			permissions = permissions.setIn(
				['roles', 'blacklist', roleId],
				permissions.getIn(['roles', 'rolesWithAccess', roleId])
			)
		}
		onChange('permissions', permissions)
	}

	onSelectRole = (roleId) => {
		const { onChange } = this.props
		let { permissions } = this.props

		if (!permissions.getIn(['roles', 'whitelist'])) {
			permissions = permissions.setIn(['roles', 'whitelist'], Map())
		}

		if (permissions.getIn(['roles', 'blacklist', roleId])) {
			permissions = permissions.deleteIn(['roles', 'blacklist', roleId])
		} else {
			permissions = permissions.setIn(
				['roles', 'whitelist', roleId],
				permissions.getIn(['roles', 'rolesWithAccess', roleId])
			)
		}

		onChange('permissions', permissions)
	}

	onRemoveUser = (userId) => {
		const { onChange } = this.props
		let { permissions } = this.props

		if (!permissions.getIn(['users', 'blacklist'])) {
			permissions = permissions.setIn(['users', 'blacklist'], Map())
		}

		if (permissions.getIn(['users', 'whitelist', userId])) {
			permissions = permissions.deleteIn(['users', 'whitelist', userId])
		} else {
			permissions = permissions.setIn(
				['users', 'blacklist', userId],
				permissions.getIn(['users', 'usersWithAccess', userId])
			)
		}
		onChange('permissions', permissions)
	}

	onSelectUser = (userId) => {
		const { onChange } = this.props
		let { permissions } = this.props
		if (!permissions.getIn(['users', 'whitelist'])) {
			permissions = permissions.setIn(['users', 'whitelist'], Map())
		}

		if (permissions.getIn(['users', 'blacklist', userId])) {
			permissions = permissions.deleteIn(['users', 'blacklist', userId])
		} else {
			permissions = permissions.setIn(
				['users', 'whitelist', userId],
				permissions.getIn(['users', 'usersWithAccess', userId])
			)
		}
		onChange('permissions', permissions)
	}

	onRolesChange = (val, event) => {
		const action = event.get('action')
		switch (action) {
			case 'remove-value': {
				const removedValue = event.getIn(['removedValue', 'value'])
				this.onRemoveRole(removedValue)
				break
			}
			case 'select-option': {
				const selectedValue = event.getIn(['option', 'value'])
				this.onSelectRole(selectedValue)
				break
			}
		}
	}

	onUsersChange = (val, event) => {
		const action = event.get('action')
		switch (action) {
			case 'remove-value': {
				const removedValue = event.getIn(['removedValue', 'value'])
				this.onRemoveUser(removedValue)
				break
			}
			case 'select-option': {
				const selectedValue = event.getIn(['option', 'value'])
				this.onSelectUser(selectedValue)
				break
			}
		}
	}

	onClear = () => {
		const { onChange } = this.props
		let { permissions } = this.props

		// Reset the whitelist + blacklist
		permissions = permissions.setIn(['users', 'whitelist'], Map())
		permissions = permissions.setIn(['users', 'blacklist'], Map())
		onChange('permissions', permissions)
	}

	onResetDefaultsUsers = () => {
		const { onChange } = this.props
		let { permissions } = this.props

		permissions = permissions.setIn(['users', 'blacklist'], Map())
		onChange('permissions', permissions)
	}

	render = () => {
		const {
			permissions,
			type,
			onSetRef,
			selected,
			usersInRoleCache,
			usersCache,
			rolesCache,
			infoTextTid,
			noTitle,
			marginBottom
		} = this.props

		if (!permissions) {
			return null
		}

		const roleValues = permissionsHelper.parseRoleValues(permissions)
		const usersValues = permissionsHelper.parseUsersValues(permissions, usersInRoleCache, usersCache)

		// Done
		const isRestricted = permissions.get('isRestricted')

		// Options
		const roleOptions = permissionsHelper.parseRoleOptions(permissions, rolesCache)
		const usersOptions = permissionsHelper.parseUsersOptions(permissions, usersInRoleCache, usersCache)

		return (
			<ObjectPermissions
				ref={onSetRef ? onSetRef : null}
				onToggleRestricted={this.onToggleRestricted}
				isRestricted={isRestricted}
				roleValues={roleValues}
				roleOptions={roleOptions}
				usersOptions={usersOptions}
				usersValues={usersValues}
				onClear={this.onClear}
				onRolesChange={this.onRolesChange}
				onUsersChange={this.onUsersChange}
				onResetDefaultsRoles={this.onResetDefaultsRoles}
				panelType={type}
				selected={selected}
				infoTextTid={infoTextTid}
				noTitle={noTitle}
				marginBottom={marginBottom}
			/>
		)
	}
}
function mapStoreToProps(store) {
	return {
		usersCache: store.usersCache.get('usersCache', Map()),
		rolesCache: store.company.userManagement.rolesCache,
		usersInRoleCache: store.company.userManagement.usersInRoleCache
	}
}

const mapActionsToProps = {
	fetchSimpleUsers,
	fetchRolesCache,
	fetchUsersInRolesCache
}

export default connect(mapStoreToProps, mapActionsToProps)(ObjectPermissionsPanel)
