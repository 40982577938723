import { fromJS, List } from 'immutable'

import { NOTES_FETCH_REMOTE, NOTES_SAVE_REMOTE, COMPANY_RESET_REDUCER } from '../actions/types'

const INITIAL_STATE = fromJS({
	notes: {},
	isDirty: false
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case NOTES_FETCH_REMOTE: {
			const objId = payload.get('objId')
			const note = payload.get('note')

			if (!note) {
				return state
			}

			return state.setIn(['notes', objId], note)
		}

		case NOTES_SAVE_REMOTE: {
			const objId = payload.get('objId')
			return state.setIn(['notes', objId], payload)
		}

		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}

		default:
			return state
	}
}
