import React, { PureComponent } from 'react'
import { string, bool, func, oneOf, node, object } from 'prop-types'
import styled, { css } from 'styled-components'
import ReactModal from 'styled-react-modal'
import Text from '../text/text'
import ScrollView from '../layout/scroll-view/scroll-view'
import { SM, MD, LM, LG, XL } from '../../../constants/modals'

const StyledModal = ReactModal.styled`
  display: flex;
	width: ${(props) => {
		if (props.hSize === 'sm') {
			return `${SM}px`
		} else if (props.hSize === 'md') {
			return `${MD}px`
		} else if (props.hSize === 'lm') {
			return `${LM}px`
		} else if (props.hSize === 'lg') {
			return `${LG}px`
		} else if (props.hSize === 'xl') {
			return `${XL}px`
		} else if (props.fullSize) {
			return `100%`
		}
	}};
  height: ${(props) => {
		if (props.fullSize) {
			return `100%`
		}
  }};
  border-radius: ${(props) => {
		if (props.fullSize) {
			return `0`
		}
		return props.theme.spacing[4]
  }};
	flex-direction: column;
	background-color: ${(props) => props.theme.colors.white};
`

const StyledModalMainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`

const StyledModalHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 14px ${(props) => props.theme.spacing[6]};
	border-bottom: 1px solid ${(props) => props.theme.colors.border};
	min-height: 50px;

	${(props) =>
		props.noHeaderRightPadding &&
		css`
			padding-right: 0;
		`}

	${(props) =>
		props.headerBackground &&
		css`
			background: ${(props) => props.theme.colors[props.headerBackground]};
		`}
`

const StyledTitle = styled.span`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1;
`

const StyledModalBody = styled.div`
	height: ${(props) => {
		if (props.height) {
			return `${props.height}px`
		} else if (props.fullSize) {
			return `100%`
		}
	}};
`

const StyledModalBodyInner = styled.div`
	margin-top: ${(props) => (props.fullHeight || props.noBodyMargin ? 0 : props.theme.spacing[5])};
	margin-bottom: ${(props) => (props.noBodyMargin ? 0 : props.theme.spacing[5])};
	margin-left: ${(props) => (props.noBodyMargin ? 0 : props.theme.spacing[6])};
	margin-right: ${(props) => (props.noBodyMargin ? 0 : props.theme.spacing[6])};

	${(props) =>
		props.fullHeight &&
		css`
			height: 100%;
		`}
`

const StyledFooterLeft = styled.div`
	display: flex;
`

const StyledFooterWrapper = styled.div`
	display: flex;
	align-items: center;
	border-top: 1px solid ${(props) => props.theme.colors.border};
	padding: ${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[6]};
	justify-content: ${(props) => (props.footerLeftComponent ? 'space-between' : 'flex-end')};

	${(props) =>
		props.marginTopInFooter &&
		css`
			margin-top: 8px;
		`}
`

class Modal extends PureComponent {
	state = {
		maxModalBodyHeight: 0
	}

	resizeTimer = null
	headerRef = null
	modalRef = null
	footerRef = null

	componentDidMount = () => {
		window.addEventListener('resize', this.handleResize, false)
		this.setModalBodyMaxHeight()
	}

	componentDidUpdate = (prevProps) => {
		const { isOpen, footerComponent, footerLeftComponent } = this.props

		if (isOpen && isOpen !== prevProps.isOpen) {
			this.setModalBodyMaxHeight()
		} else if (isOpen && footerComponent !== prevProps.footerComponent) {
			this.setModalBodyMaxHeight()
		} else if (isOpen && footerLeftComponent !== prevProps.footerLeftComponent) {
			this.setModalBodyMaxHeight()
		}
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.handleResize, false)
	}

	handleResize = () => {
		clearTimeout(this.resizeTimer)

		this.resizeTimer = setTimeout(() => {
			this.setModalBodyMaxHeight()
		}, 200)
	}

	setHeaderRef = (ref) => {
		this.headerRef = ref
	}

	setModalRef = (ref) => {
		this.modalRef = ref
	}

	setFooterRef = (ref) => {
		this.footerRef = ref
	}

	setModalBodyMaxHeight = () => {
		setTimeout(() => {
			this.doSetModalBodyMaxHeight()
		}, 1)
	}

	doSetModalBodyMaxHeight = () => {
		if (!this.modalRef) {
			return
		}

		// Available space in window
		const availableHeight = window.innerHeight

		// Modal Components Height
		const headerHeight = this.headerRef ? this.headerRef.clientHeight : 0
		const footerHeight = this.footerRef ? this.footerRef.clientHeight : 0
		const modalTotalHeight = this.modalRef.clientHeight

		const maxModalBodyHeight = availableHeight - headerHeight - footerHeight
		const baseModalBodyHeight = this.getModalBaseBodyHeight()
		const modalBodyHeight = this.getModalBodyHeight()

		// Modal is too big to fit in the window
		if (modalTotalHeight > availableHeight) {
			this.setState({ maxModalBodyHeight })
		} else if (modalBodyHeight < baseModalBodyHeight) {
			// Modal's current body height is less than it could be (base height)
			this.setState({ maxModalBodyHeight })
		}
	}

	getModalBaseBodyHeight = () => {
		const { vSize, title, align } = this.props

		if (align === 'right' || align === 'left') {
			return window.innerHeight
		}

		const isHeaderless = title ? false : true
		const baseHeight = 500

		let height = baseHeight * (vSize / 100)

		if (isHeaderless) {
			height += 50
		}

		return height
	}

	getModalBodyHeight = () => {
		const { maxModalBodyHeight } = this.state
		const baseHeight = this.getModalBaseBodyHeight()

		if (maxModalBodyHeight && baseHeight > maxModalBodyHeight) {
			return maxModalBodyHeight - 64
		}

		return baseHeight
	}

	renderTitle = () => {
		const {
			title,
			rawTitle,
			headerRightRenderer,
			titleValues,
			isOpen,
			noHeaderRightPadding,
			headerComponent,
			headerBackground,
			headerTitleColor
		} = this.props
		const titleColor = headerTitleColor ? 'white' : 'catalinaBlue'

		if (headerComponent) {
			return headerComponent
		}

		if (!title && !rawTitle && !headerRightRenderer) {
			return null
		}

		return (
			<StyledModalHeader
				headerRightRenderer={headerRightRenderer}
				ref={isOpen && this.setHeaderRef}
				noHeaderRightPadding={noHeaderRightPadding}
				headerBackground={headerBackground}>
				<StyledTitle>
					{title ? <Text tid={title} values={titleValues} tag='h4' color={titleColor} /> : rawTitle}
				</StyledTitle>
				{headerRightRenderer && headerRightRenderer()}
			</StyledModalHeader>
		)
	}

	renderBody = () => {
		const { scrollableContent, children, noBodyMargin, scrollbarRef, fullSize } = this.props
		const height = this.getModalBodyHeight()

		const modalBodyProps = fullSize ? { fullSize } : { height }

		return (
			<StyledModalBody {...modalBodyProps}>
				{scrollableContent && (
					<ScrollView scrollbarRef={scrollbarRef} noRightMargin noLeftMargin fillContent alwaysShow>
						<StyledModalBodyInner>{children}</StyledModalBodyInner>
					</ScrollView>
				)}
				{!scrollableContent && (
					<StyledModalBodyInner fullHeight noBodyMargin={noBodyMargin}>
						{children}
					</StyledModalBodyInner>
				)}
			</StyledModalBody>
		)
	}

	renderFooter = () => {
		const { footerComponent, footerLeftComponent, isOpen, marginTopInFooter } = this.props

		// No Footer at all
		if (!footerComponent && !footerLeftComponent) {
			return null
		}

		return (
			<StyledFooterWrapper
				footerLeftComponent={footerLeftComponent}
				ref={isOpen && this.setFooterRef}
				marginTopInFooter={marginTopInFooter}>
				{footerLeftComponent && <StyledFooterLeft>{footerLeftComponent}</StyledFooterLeft>}
				{footerComponent}
			</StyledFooterWrapper>
		)
	}

	render = () => {
		const {
			hSize,
			isOpen,
			allowScroll,
			onBackgroundClick,
			onEscapeKeydown,
			beforeOpen,
			afterOpen,
			beforeClose,
			afterClose,
			align,
			fullSize
		} = this.props
		const modalProps = { hSize, isOpen, allowScroll, fullSize }
		const backgroundProps = { align, fullSize }

		if (onBackgroundClick) {
			modalProps.onBackgroundClick = onBackgroundClick
		}

		if (onEscapeKeydown) {
			modalProps.onEscapeKeydown = onEscapeKeydown
		}

		if (beforeOpen) {
			modalProps.beforeOpen = beforeOpen
		}

		if (afterOpen) {
			modalProps.afterOpen = afterOpen
		}

		if (beforeClose) {
			modalProps.beforeClose = beforeClose
		}

		if (afterClose) {
			modalProps.afterClose = afterClose
		}

		return (
			<StyledModal {...modalProps} backgroundProps={backgroundProps}>
				<StyledModalMainWrapper ref={isOpen && this.setModalRef}>
					{this.renderTitle()}
					{this.renderBody()}
					{this.renderFooter()}
				</StyledModalMainWrapper>
			</StyledModal>
		)
	}
}

Modal.defaultProps = {
	isOpen: false,
	allowScroll: false,
	vSize: 100,
	scrollableContent: true,
	noBodyMargin: false
}

Modal.propTypes = {
	hSize: oneOf(['sm', 'md', 'lm', 'lg', 'xl']),
	vSize: oneOf([25, 50, 75, 100]),
	title: string,
	isOpen: bool,
	allowScroll: bool,
	rawTitle: node,
	onBackgroundClick: func,
	onEscapeKeydown: func,
	beforeOpen: func,
	afterOpen: func,
	beforeClose: func,
	afterClose: func,
	headerRightRenderer: func,
	scrollableContent: bool,
	noBodyMargin: bool,
	titleValues: object,
	noHeaderRightPadding: bool,
	headerComponent: object,
	scrollbarRef: func,
	fullSize: bool,
	headerBackground: string,
	headerTitleColor: string,
	marginTopInFooter: bool
}

export default Modal
