import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { useTranslation } from 'react-i18next'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import ScrollView from '../../../dumb-components/shared/layout/scroll-view/scroll-view'
import IconButton from '../../../mui-components/button/icon-button'
import CreateAdvertModal from './modals/CreateAdvertModal'
import AdvertsGrid from './AdvertsGrid'
import CompaniesAdvertsGrid from './CompaniesAdvertsGrid'
import { ReadMoreModal } from '../../investments/modals/read-more-modal'
import { CAP_TABLE_READ_MORE_MODAL } from '../../../constants/modals'
import { openModal as openModalViaRedux } from '../../../actions/modals.actions'

export default function AdvertisingAdministrationOverviewContainer() {
	const [handleModals, setHandleModals] = useState('')
	const [selectedAdvertId, setSelectedAdvertId] = useState()
	const [previewAdvert, setPreviewAdvert] = useState(false)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isInvonoAdmin = useSelector((state) => state.user.getIn(['userObj', 'invonoAdmin']))
	const companyIsInvono = useSelector((state) => state.company.company.orgNumber === '5569872335')

	const openModal = (modal, id, preview = false) => {
		//create_advert, grid, companies_grid
		if (id) {
			setSelectedAdvertId(id)
			preview && setPreviewAdvert(true)
			setHandleModals(modal)
		} else {
			setHandleModals(modal)
		}
	}

	const closeModals = () => {
		setSelectedAdvertId('')
		setPreviewAdvert(false)
		setHandleModals('')
	}

	const isReadMoreModalOpen = useSelector(
		(state) => state.modals.getIn(['activeModal', 'name']) === CAP_TABLE_READ_MORE_MODAL
	)

	const openReadMoreModal = () => {
		dispatch(openModalViaRedux(CAP_TABLE_READ_MORE_MODAL))
	}

	return (
		<div className='i-content i-content--pad-right-half'>
			<div className='i-content__container'>
				<ScrollView noLeftMargin autoHide showOnHover>
					<Grid container spacing={2} mb={2}>
						<Grid item lg={9} md={12}>
							<Card borderradius={'true'}>
								<CardContent>
									<Typography variant='body1'>
										{t('advertising.administration.overview.information_card.title')}
									</Typography>
									<Typography variant='body2'>
										<FormattedMessage
											id={'advertising.administration.overview.information_card.paragraph'}
											values={{
												link: (
													<Link
														sx={{ pb: 0.3 }}
														onClick={openReadMoreModal}
														component={'button'}
														color={'positive.main'}>
														<FormattedMessage
															id={'investments.information_panel.link.read_more'}
														/>
													</Link>
												),
												mailTo: (
													<a
														href='mailto:jacob.frick@invono.se'
														style={{ color: '#369FF4', textDecoration: 'underline' }}>
														jacob.frick@invono.se
													</a>
												)
											}}
										/>
									</Typography>
								</CardContent>
							</Card>
						</Grid>
						<Grid item lg={3} md={12}>
							<Card variant='dark' borderradius={'true'}>
								<CardContent variant='flex'>
									<Box>
										<Typography variant='body1'>
											{t('advertising.administration.overview.create_advert_card.title')}
										</Typography>
										<Typography variant='body2'>
											{t('advertising.administration.overview.create_advert_card.paragraph')}
										</Typography>
									</Box>
									<IconButton
										icon='faPlus'
										color='white'
										onClick={() => openModal('create_advert')}
									/>
								</CardContent>
							</Card>
						</Grid>
						<AdvertsGrid localCloseModal={closeModals} openModal={openModal} />

						{isInvonoAdmin && companyIsInvono && (
							<CompaniesAdvertsGrid
								openModal={openModal}
								localCloseModal={closeModals}
								isGridModalOpen={handleModals === 'companies_grid'}
							/>
						)}
					</Grid>
				</ScrollView>
			</div>
			{handleModals === 'create_advert' && (
				<CreateAdvertModal
					isOpen={true}
					closeModal={closeModals}
					advertId={selectedAdvertId}
					preview={previewAdvert}
				/>
			)}

			{isReadMoreModalOpen && (
				<ReadMoreModal
					title={'advert.administration.overview.read_more.modal.title'}
					contentFile='administration-adverts.txt'
				/>
			)}
		</div>
	)
}
