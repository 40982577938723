import { saveAs } from 'file-saver'

/**
 * HTML helper
 * @module components/helpers/html-helper
 */

export const removeStyleFromHtmlElement = (str) => {
	if (str === null || str === undefined) {
		return null
	} else if (str === '') {
		return str
	}

	return str.replace(/\sstyle=".*?"/gi, '')
}

//prints the content of a html element containing a canvas.
//expected to only have one canvas element as child property.
export const printCanvas = ({ ref, filename }) => {
	const element = ref.current
	const canvas = element.querySelector('canvas')
	const image = canvas.toDataURL('image/png')
	saveAs(image, filename)
}
