import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

const SimpleSnackbar = (props) => {
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		props.toggleSnackbar(false)
	}

	const style = `
	html {
		font-size: 10px;
	}
	body {
		font-size: 13px;
		line-height: 1.42857143;
	}`

	const vertical = props.vertical || 'top'
	const horizontal = props.horizontal || 'right'

	return (
		<>
			<style>{style}</style>
			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				open={props.open}
				autoHideDuration={6000}
				onClose={handleClose}
				sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'pre-wrap' }}>
				<Alert
					variant='filled'
					onClose={handleClose}
					severity={props.severity}
					sx={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						'& .MuiAlert-action': {
							pt: 0
						}
					}}>
					<Typography variant={'h5'}>{props.message}</Typography>
				</Alert>
			</Snackbar>
		</>
	)
}

export default SimpleSnackbar
