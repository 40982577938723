import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func, string, object, oneOf } from 'prop-types'
import TagsSelect from '../../dumb-components/shared/tags-select/tags-select'
import { createTag, listTags } from '../../actions/tags.actions'
import v1 from 'uuid/v1'

// Audit
import { LIVE_TAG_CREATE } from '../../constants/live-update'

class TagsSelectContainer extends Component {
	static propTypes = {
		onTagsChange: func,
		readOnly: bool,
		fieldName: string,
		menuPortalTarget: object,
		menuPlacement: oneOf(['top', 'bottom', 'auto'])
	}

	componentDidMount = () => {
		const { listTags, tags } = this.props

		if (!tags || tags.size === 0) {
			listTags()
		}
	}

	componentDidUpdate = () => {
		const { audit, listTags } = this.props
		const TAG_CREATE = audit.get(LIVE_TAG_CREATE)

		if (TAG_CREATE && TAG_CREATE.get('r') === true) {
			listTags()
		}
	}

	onChange = (tags, action) => {
		const { onTagsChange, fieldName } = this.props

		// New tag created
		if (action && action.get && action.get('action') === 'create-option') {
			const newTag = tags.get(-1)
			this.onCreate(newTag, tags)

			// Stop before running on tags change as they are ran automatically in onCreate function
			return
		}

		if (fieldName) {
			return onTagsChange(fieldName, tags)
		}

		return onTagsChange(tags)
	}

	onCreate = (newTag, tags) => {
		const { createTag } = this.props

		newTag = {
			id: v1(),
			name: newTag
		}

		// Create new tag in the datbase
		createTag(newTag)

		// Append the new tag id into document and update it
		tags = tags.push(newTag.id)
		this.onChange(tags)
	}

	render = () => {
		const { tags, value, readOnly, menuPortalTarget, menuPlacement } = this.props

		return (
			<TagsSelect
				onChange={this.onChange}
				onCreate={this.onCreate}
				options={tags}
				readOnly={readOnly}
				value={value}
				menuPortalTarget={menuPortalTarget}
				menuPlacement={menuPlacement}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		tags: store.tags.get('tags'),
		audit: store.audit.get('tags')
	}
}

const mapActionsToProps = {
	createTag,
	listTags
}

export default connect(mapStoreToProps, mapActionsToProps)(TagsSelectContainer)
