import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'
import { set } from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import debounce from 'lodash/debounce'

import MergeOverviewAndSave from '../../../dumb-components/collections/merge-modal/merge-overview-and-save'

import folderHelper from '../../../components/helpers/folder.helper'
import documentsHelper from '../../../components/helpers/documents.helper'
import { downloadDocument } from '../../../actions/documents.actions'
import { updateMergedDocumentLocal } from '../../../actions/collections.actions'

class MergeOverviewAndSaveContainer extends Component {
	state = {
		hasError: Map()
	}

	static propTypes = {
		onGoToMoveFile: func.isRequired,
		setValidateFunction: func.isRequired,
		folderPath: string
	}

	componentDidMount = () => {
		this.props.setValidateFunction(this.validate)
	}

	validate = () => {
		const { mergedDocument } = this.props
		const title = mergedDocument ? mergedDocument.get('title') : ''
		let hasError = Map()

		if (title.trim().length === 0) {
			hasError = hasError.set('title', List(['collections.merge_pdf.modal.overview.validate.name']))
		}

		this.setState({ hasError })

		return !hasError.size
	}

	handleChangeDocumentName = (documentName) => {
		const { mergedDocument, updateMergedDocumentLocal } = this.props
		updateMergedDocumentLocal(mergedDocument.set('title', documentName))
	}

	handleOpenDocument = ({ documentId }) => {
		const { downloadDocument } = this.props
		downloadDocument({ documentId, openInViewer: true })
	}

	handleDownloadDocument = ({ documentId }) => {
		const { downloadDocument } = this.props
		downloadDocument({ documentId })
	}

	getDocumentIsPdf = (document) => {
		return documentsHelper.getDocumentIsPdf(document.getIn(['file', 'ext']))
	}

	getDocumentsToBeMerged = () => {
		const { selectedCollectionDocuments } = this.props

		return selectedCollectionDocuments.filter((d) => this.getDocumentIsPdf(d) && d.get('includedInAction') !== false)
	}

	render = () => {
		const { mergedDocument, onGoToMoveFile, folderPath } = this.props
		const { hasError } = this.state
		const mergedDocuments = this.getDocumentsToBeMerged()

		return (
			<MergeOverviewAndSave
				mergedDocument={mergedDocument}
				mergedDocuments={mergedDocuments}
				onGoToMoveFile={onGoToMoveFile}
				onOpenMerged={this.handleOpenDocument}
				onDownloadMerged={this.handleDownloadDocument}
				onChangeDocumentName={this.handleChangeDocumentName}
				hasError={hasError}
				folderPath={folderPath}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		selectedCollectionDocuments: store.collections.get('selectedCollectionDocuments'),
		mergedDocument: store.collections.get('mergedDocument')
	}
}

const mapActionsToProps = {
	downloadDocument,
	updateMergedDocumentLocal
}

export default connect(mapStoreToProps, mapActionsToProps)(MergeOverviewAndSaveContainer)
