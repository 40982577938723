import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool, func } from 'prop-types'
import { map } from 'react-immutable-proptypes'

import { PeopleModalProvider } from '../../shared/people-modal/people-modal.provider.js'
import { PeopleModalContainer } from '../../shared/people-modal/people-modal.container'
import { PeopleModalHeader, getDefaultTabs } from '../../shared/people-modal/people-modal-header'
import { PeopleModalFooter } from '../../shared/people-modal/people-modal-footer'
import { TeamsAndInvestorsListContainer } from '../../shared/people-modal/teams-and-investors-list/teams-and-investors-list.container'
import { CompanyContactsGrid } from '../../shared/people-modal/company-contacts/CompanyContactsGrid'
import { PersonalContactsGrid } from '../../shared/people-modal/personal-contacts/PersonalConatctsGrid'
import { filterExternalPeople } from '../../../components/helpers/meeting.helper'

import { MEETING_SUBTYPE_AGM } from '/shared/constants'
import CopyContactToCompanyModal from '../../shared/copy-contact/copy-contact-to-company-modal'
import { CopyContactModalProvider } from '../../shared/copy-contact/copy-contact-modal.provider.js'
class AddAttendeesModalContainer extends Component {
	state = {}

	static propTypes = {
		isOpen: bool,
		excludeInvestors: bool,
		excludeExternals: bool,
		investorsTooltip: string,
		externalsTooltip: string,
		disableShareholderFunction: func,
		disableMemberFunction: func,
		users: map,
		onClose: func,
		onSelectMultipleAttendees: func
	}

	static defaultProps = {}

	/**
	 * This function checks if any external attendees have modified email compared
	 * to the original users. If that's the case, notified is set for the user.
	 * Returns all attendees
	 */
	prepareExternalAttendees = (allAttendees) => {
		const originalUsers = this.props.users

		return allAttendees.map((attendee) => {
			// Skip if user is not an Guest
			if (!attendee.get('isGuest')) {
				return attendee
			}

			// Skip if user has no name or no email
			if (!attendee.get('name') || !attendee.get('email')) {
				return attendee
			}

			const userId = attendee.get('userId')
			const prevEmail = originalUsers && originalUsers.getIn([userId, 'email'])
			const newEmail = attendee.get('email')

			if (prevEmail !== newEmail) {
				attendee = attendee.set('notified', false)
				attendee = attendee.set('notifiedAboutChanges', null)
			}

			return attendee
		})
	}

	addAttendeesFromModal = (attendees) => {
		const { onSelectMultipleAttendees, companyUsers, onClose } = this.props

		attendees = this.prepareExternalAttendees(attendees)

		onSelectMultipleAttendees(attendees)
		onClose()
	}

	getHideProxies = (guestAttendee) => {
		const isProxy = guestAttendee.get('isProxy', false)

		if (isProxy) {
			return true
		}
	}

	render() {
		const {
			isAGM,
			isOpen,
			onClose,
			users,
			excludeInvestors,
			excludeExternals,
			investorsTooltip,
			externalsTooltip,
			disableShareholderFunction,
			disableMemberFunction,
			disableAttendeeToggleFunction
		} = this.props

		return (
			<>
				<PeopleModalProvider isOpen={isOpen} values={users}>
					<PeopleModalContainer
						headerComponent={() => (
							<PeopleModalHeader
								title='meetings.attendees.select_user_modal.title'
								tabs={getDefaultTabs()}
							/>
						)}
						footerComponent={() => (
							<PeopleModalFooter onSave={this.addAttendeesFromModal} onCancel={onClose} />
						)}>
						<TeamsAndInvestorsListContainer
							disableInvestorFunction={disableShareholderFunction}
							disableMemberFunction={disableMemberFunction}
						/>
						<CompanyContactsGrid filterFunction={filterExternalPeople} />
						<PersonalContactsGrid />
					</PeopleModalContainer>
				</PeopleModalProvider>
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		companyUsers: store.company.members,
		isAGM: store.meetings.getIn(['meeting', 'meetingSubType']) === MEETING_SUBTYPE_AGM
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(AddAttendeesModalContainer)
