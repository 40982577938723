import { fromJS } from 'immutable'

export const PREPAID_OPTIONS = fromJS([
	{
		value: 100,
		label: 100
	},
	{
		value: 250,
		label: 250
	},
	{
		value: 500,
		label: 500
	},
	{
		value: 1000,
		label: 1000
	},
	{
		value: 1500,
		label: 1500
	},
	{
		value: 2000,
		label: 2000
	}
])
