import React, { Component } from 'react'
import { connect } from 'react-redux'
import MoveItemModal from '../../dumb-components/shared/move-item-modal/move-item-modal'
import ItemsTreeContainer from '../shared/item-tree.container'
import { transferDocument, updateDocumentLocal, listDocuments } from '../../actions/documents.actions'
import { addErrorNotification, addInfoNotification } from '../../actions/notify.actions'
import { closeModal } from '../../actions/modals.actions'
import { DOCUMENT_MOVE_DOCUMENT_MODAL } from '../../constants/modals'

class DocumentsMoveFileModal extends Component {
	state = {
		selectedFolder: null
	}

	onFolderMove = () => {
		const {
			transferDocument,
			selectedFolderId,
			updateDocumentLocal,
			addErrorNotification,
			doc,
			listDocuments
		} = this.props
		const { selectedFolder } = this.state

		if (!selectedFolder) {
			addErrorNotification({
				tid: 'documents.move_document.error.no_folder_selected.message'
			})
			return
		}

		const docsToTransfer = [doc.get('id')]
		const moveToFolderId = selectedFolder.get('id') || null

		// IPS-4888 - Update folderId in the document so that the
		// Folder badge shows up in the document list immediately
		updateDocumentLocal(doc.set('folderId', moveToFolderId))

		transferDocument(docsToTransfer, moveToFolderId, () => {
			// IPS-5605 - When an document is moved, we have no clue about the filter
			// amount status (x / y filtered/shown). That's why we fetch all documents
			// again to reaalculate these values. This is far from an ideal soultion
			// as we fetch all documents everytime a document is moved. This is however
			// consistant with what we do for Tasks and Meetings to get the counter working.
			listDocuments(selectedFolderId)

			// Commended by IPS-4888
			// doc = doc.set('folderId', moveToFolderId);
			// fetchDocument(docsToTransfer);
			// updateDocumentLocal(doc);
			// this.setState({selectedFolder: null});
		})

		this.onClose()
	}

	onClose = () => {
		const { closeModal } = this.props

		closeModal()

		this.setState({ selectedFolder: null })
	}

	onFolderSelected = (folder) => {
		this.setState({ selectedFolder: folder })
	}

	render = () => {
		const { isOpen, doc, folders } = this.props
		const { selectedFolder } = this.state

		if (!isOpen) {
			return null
		}

		const folder =
			selectedFolder || (doc && folders && folders.find((folder) => folder.get('id') === doc.get('folderId')))

		return (
			<MoveItemModal
				titleTid='documents.generic.folders'
				isOpen={true}
				onMove={this.onFolderMove}
				onClose={this.onClose}
				itemTreeContainerRenderer={() => {
					return (
						<ItemsTreeContainer
							items={folders}
							onItemSelected={this.onFolderSelected}
							selectedItem={folder}
							renderHoverText={true}
							permission='ALLOW_CREATE_DOCUMENT'
						/>
					)
				}}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		doc: store.documents.get('document'),
		folders: store.folders.get('folders'),
		selectedFolderId: store.folders.get('selectedFolderId'),
		isOpen: store.modals.getIn(['activeModal', 'name']) === DOCUMENT_MOVE_DOCUMENT_MODAL
	}
}

const mapActionsToProps = {
	transferDocument,
	updateDocumentLocal,
	closeModal,
	addErrorNotification,
	addInfoNotification,
	listDocuments
}

export default connect(mapStoreToProps, mapActionsToProps)(DocumentsMoveFileModal)
