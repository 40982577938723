import React from 'react'
import { useTranslation } from 'react-i18next'

export function EmailCellRenderer({ value }) {
	const { t } = useTranslation()
	
	if (value) {
		return value
	}

	return <em>{t('select_user_model.no_email_address')}</em>
}
