import React, { PureComponent } from 'react'
import { string, bool, func, object } from 'prop-types'
import { Map } from 'immutable'
import { map } from 'react-immutable-proptypes'
import styled, { css } from 'styled-components'
import { StyledListHeaderTemplate } from '../../shared/list/list-header.template'

import ScrollView from '../../shared/layout/scroll-view/scroll-view'
import Tooltip from '../../shared/tooltip/tooltip'
import Input from '../../shared/input/input'
import Button from '../../shared/button/button'
import List from '../../shared/list/list'
import ListItem from '../../shared/list-item/list-item'
import Text from '../../shared/text/text'
import ColoredContentWrapper from '../../shared/colored-content-wrapper/colored-content-wrapper'
import { OutlinedButton } from '../../shared/button-v2'

const EllipsisTemplate = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	display: flex;
	flex-direction: row;
	padding: ${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[5]};
	margin: ${(props) => props.theme.spacing[4]} ${(props) => props.theme.spacing[6]};

	> span {
		flex: 1;
		margin-right: ${(props) => props.theme.spacing[3]};
	}
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: ${(props) => props.theme.spacing[3]};
	height: calc(100% - ${(props) => props.theme.spacing[5]});
`

const StyledCreatorWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin: ${(props) => props.theme.spacing[5]} ${(props) => props.theme.spacing[6]};

	> span {
		flex: 1;
		margin-right: ${(props) => props.theme.spacing[4]};
	}
`

const StyledHeader = styled.div`
	${StyledListHeaderTemplate}
	${EllipsisTemplate}
	margin: 0 ${(props) => props.theme.spacing[6]};
`

const StyledListItem = styled(ListItem)`
	border-bottom: 1px solid ${(props) => props.theme.colors.solitudeDark};
	margin-bottom: 0px;
	padding: 0;

	:first-child {
		border-top: 1px solid ${(props) => props.theme.colors.solitudeDark};
	}
`

const StyledListItemContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	height: 100%;
`

const StyledName = styled.div`
	${EllipsisTemplate}
	display: flex;
	flex: 1;
	min-width: 100px;
	width: 100px;
	padding: 0 ${(props) => props.theme.spacing[3]};
`

const StyledDropdown = styled.div``

const InnerWrapper = styled.div`
	padding: 0 ${(props) => props.theme.spacing[6]};
	flex: 1;
`

const StyledOutlinedButton = styled.div``

export default class AttendeeManageFunctions extends PureComponent {
	static propTypes = {
		hasError: map,
		isLoading: bool,
		onChange: func,
		onAdd: func,
		onSetTooltipRefs: func,
		onHideTooltip: func,
		functionData: map,
		allFunctions: map,
		renderDropdownComponent: func,
		isEditMode: bool,
		onCancelEdit: func,
		onEdit: func,
		onSetInputRef: func
	}

	static defaultProps = {
		allFunctions: Map()
	}

	renderControls = () => {
		const { isEditMode, onAdd, onCancelEdit, onEdit, isLoading } = this.props

		if (isEditMode) {
			return (
				<>
					<Button onClick={onCancelEdit} isLoading={isLoading} tid='generic.form.cancel' />
					<Button onClick={onEdit} isLoading={isLoading} tid='generic.edit' mode='primary' />
				</>
			)
		}

		return (
			<StyledOutlinedButton>
				<OutlinedButton
					icon='faPlus'
					onClick={onAdd}
					isLoading={isLoading}
					color='midGrey'
					borderColor='buttonBorderGrey'
					background='lightestGrey'
				/>
			</StyledOutlinedButton>
		)
	}

	renderNewCustomFunctionForm = () => {
		const {
			hasError,
			onSetTooltipRefs,
			onHideTooltip,
			onChange,
			isLoading,
			onAdd,
			functionData,
			onSetInputRef,
			onEdit,
			isEditMode
		} = this.props

		return (
			<StyledColoredContentWrapper type='solitudeMid'>
				<Tooltip
					activeState='formValidator'
					place='bottom'
					tid={hasError.getIn(['name.sv', 0])}
					ref={onSetTooltipRefs.bind(null, 'name.sv')}>
					<Input
						fieldName='name.sv'
						placeholderTid='meetings.attendee.function.manage_function.input.placeholder'
						onChange={onChange}
						onBlur={onHideTooltip}
						disabled={isLoading}
						onEnter={isEditMode ? onEdit : onAdd}
						value={functionData.getIn(['name', 'sv'], '')}
						hasError={hasError.get('name.sv')}
						onSetRef={onSetInputRef}
					/>
				</Tooltip>

				{this.renderControls()}
			</StyledColoredContentWrapper>
		)
	}

	renderListItem = (data, id) => {
		const { renderDropdownComponent } = this.props

		return (
			<StyledListItem key={id} striped>
				<StyledListItemContent>
					<StyledName>
						<Text singleLine>{data.getIn(['name', 'sv'])}</Text>
					</StyledName>
					<StyledDropdown>{renderDropdownComponent(id)}</StyledDropdown>
				</StyledListItemContent>
			</StyledListItem>
		)
	}

	renderCustomFunctionsList = () => {
		let { allFunctions } = this.props

		if (!allFunctions.size) {
			return (
				<InnerWrapper>
					<Text align='center' tid='meetings.attendees.functions.manage_function.empty' hasItalic />
				</InnerWrapper>
			)
		}

		return (
			<List>
				<ScrollView noLeftMargin noRightMargin alwaysShow>
					<InnerWrapper>{allFunctions.map(this.renderListItem).toList()}</InnerWrapper>
				</ScrollView>
			</List>
		)
	}

	render = () => {
		return (
			<Wrapper>
				{this.renderNewCustomFunctionForm()}
				{this.renderCustomFunctionsList()}
			</Wrapper>
		)
	}
}
