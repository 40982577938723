import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { createUser } from '../../../../actions/user-management.actions'

import NotifySingleUserModalContainer from '../../generic-modals/notify-single-user-modal.container'

import { INVITE_USER_BY_EMAIL_TEMPLATE_MODAL } from '../../../../constants/modals'
import { EMAIL_TEMPLATES_INVITE_USER_TO_COMPANY } from '/shared/constants'

class InviteUserModalContainer extends Component {
	static propTypes = {
		onDone: func
	}

	inviteUser = (workingTemplate, closeModalCallback, errorCallback) => {
		const { companyId, createUser, user, index, onDone } = this.props

		const userToCreate = {
			name: user.get('name'),
			email: user.get('email'),
			// Add "User [Default]" role to user if it does not have any roles attached
			userRoles:
				!user.get('roles') || user.get('roles').size === 0
					? ['dede9e47-dd3c-4510-97d6-973a6ad7ddac']
					: user.get('roles').toArray()
		}

		createUser(userToCreate, companyId, workingTemplate.toJS(), (error) => {
			if (error) {
				errorCallback()
				return
			}

			closeModalCallback()
			onDone?.(index)
		})
	}

	render = () => {
		return (
			<NotifySingleUserModalContainer
				templateName={EMAIL_TEMPLATES_INVITE_USER_TO_COMPANY}
				modalName={INVITE_USER_BY_EMAIL_TEMPLATE_MODAL}
				onSubmit={this.inviteUser}
				successNotificatonTid='user_management.unregistered_user_notified_to_register'
				errorNotificationTid='user_management.error.notify_unregistered_user'
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		user: store.modals.getIn(['activeModal', 'options', 'user']),
		index: store.modals.getIn(['activeModal', 'options', 'index']),
		companyId: store.company.company.id
	}
}

const mapActionsToProps = {
	createUser
}

export default connect(mapStoreToProps, mapActionsToProps)(InviteUserModalContainer)
