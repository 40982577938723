import React, { PureComponent } from 'react'
import { func, object } from 'prop-types'
import styled from 'styled-components'

import Text from '../shared/text/text'
import Button from '../shared/button/button'
import ColoredContentWrapper from '../shared/colored-content-wrapper/colored-content-wrapper'
import Icon from '../shared/icon/icon'

const StyledWrapper = styled.div`
	position: relative;
`

const EditorWrapper = styled.div`
	border: 1px solid ${(props) => props.theme.colors.border};
	border-radius: ${(props) => props.theme.spacing[4]};
	padding: ${(props) => props.theme.spacing[4]};
`

const StyledToolbar = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.theme.spacing[2]};
	justify-content: flex-end;
	align-items: center;
`

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
	align-items: center;
`

const StyledIconWrapper = styled.div`
	padding-right: ${(props) => props.theme.spacing[3]};
`

export default class HandleEmailTemplate extends PureComponent {
	static propTypes = {
		languageSwitcherComponent: object,
		dropdownComponent: object.isRequired,
		editorComponent: object.isRequired
	}

	static defaultProps = {}

	render = () => {
		const { languageSwitcherComponent, dropdownComponent, editorComponent } = this.props

		return (
			<StyledWrapper>
				<StyledColoredContentWrapper type='secondary'>
					<StyledIconWrapper>
						<Icon icon='faExclamationTriangle' size='normal' color='yellowMid' type='solid' />
					</StyledIconWrapper>
					<Text tid='email_templat.replacement_tags.information' />
				</StyledColoredContentWrapper>
				<StyledToolbar>
					{languageSwitcherComponent}
					{dropdownComponent}
				</StyledToolbar>
				<EditorWrapper>{editorComponent}</EditorWrapper>
			</StyledWrapper>
		)
	}
}
