import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { map } from 'react-immutable-proptypes'

import { openModal } from '../../../actions/modals.actions'
import { updateDocumentLocal } from '../../../actions/documents.actions'
import { DOCUMENT_MOVE_DOCUMENT_MODAL } from '../../../constants/modals'

const TOOLTIP_STATES = {}

class MoveFileComponentContainer extends Component {
	static propTypes = {
		document: map.isRequired,
		renderComponent: func.isRequired
	}

	static defaultProps = {}

	openMoveModal = () => {
		const { updateDocumentLocal, openModal, document } = this.props

		updateDocumentLocal(document)
		openModal(DOCUMENT_MOVE_DOCUMENT_MODAL)
	}

	getTooltipData = () => {
		const { document, isSharedFromCompany, isSharedFromCompanyName } = this.props
		const canMoveDocument = document.get('ALLOW_UPDATE') && !isSharedFromCompany
		const tooltipValues = {}
		let activeState

		if (!canMoveDocument && isSharedFromCompany) {
			activeState = 'hasNoPermissionsSharedCompany'
			tooltipValues.companyName = isSharedFromCompanyName
		} else if (!canMoveDocument) {
			activeState = 'hasNoPermissions'
		}

		return {
			TOOLTIP_STATES,
			activeState,
			tooltipValues,
			isDisabled: Boolean(activeState)
		}
	}

	render = () => {
		const { renderComponent } = this.props
		const { TOOLTIP_STATES, activeState, tooltipValues, isDisabled } = this.getTooltipData()

		return renderComponent({
			openMoveModal: this.openMoveModal,
			TOOLTIP_STATES,
			activeState,
			tooltipValues,
			isDisabled
		})
	}
}

const mapStoreToProps = (store, ownProps) => {
	const isSharedFromCompanyId = ownProps.document.get('isSharedFromCompanyId')

	return {
		isSharedFromCompany: Boolean(isSharedFromCompanyId),
		isSharedFromCompanyName: store.folders.getIn(['folders', isSharedFromCompanyId, 'translatedName'])
	}
}

const mapActionsToProps = {
	openModal,
	updateDocumentLocal
}

export default connect(mapStoreToProps, mapActionsToProps)(MoveFileComponentContainer)
