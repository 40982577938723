import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
	GRID_MODE_PANEL,
	GRID_MODE_MODAL,
	INVESTMENTS_CAPITAL_INSURANCE,
	INVESTMENTS_PRIVATE_PORTFOLIO,
	INVESTMENTS_COMPANY_INVESTMENTS_PORTFOLIO,
	INVESTMENTS_VIA_COMPANY
} from '/shared/constants'
import AgGrid from '../../../dumb-components/shared/ag-grid/ag-grid'
import { columnTypes, useInvestmentsColumnDefs } from '../../../dumb-components/shared/ag-grid/column-defs/column-defs'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '../../../actions/modals.actions'
import { GRID_MODAL } from '../../../constants/modals'
import ExportExcelButton from '../../../dumb-components/investment/shareholders/export-excel-button'
import { useInvestmentsPortfolioRowData } from '../../../dumb-components/shared/ag-grid/transposed-grid/transposed-grid-row-data'
import { List, Map } from 'immutable'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import GridPanelRightButtons from '../../../dumb-components/shared/ag-grid/panel/grid-panel-right-buttons'
import Text from '../../../dumb-components/shared/text/text'

const InvestmentsGridContainer = ({ rowDataMemo, investmentType, id }) => {
	const gridRef = useRef(id)
	const [colDefs, setColDefs] = useInvestmentsColumnDefs()
	const colTypes = columnTypes()
	const [myPortfolioItems, setMyPortfolioItems] = useState(null)
	const [investmentsPortfolioRowData, setInvestmentsPortfolioRowData] = useInvestmentsPortfolioRowData()
	const [hamburgerMenuItems, setHamburgerMenuItems] = useState(null)

	const investmentViaCompany = investmentType === INVESTMENTS_VIA_COMPANY

	const gridOption = 'investments&' + id

	const isGridModalOpen = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'options']) === gridOption
	})

	const dispatch = useDispatch()
	useEffect(() => {
		if (!rowDataMemo && !investmentViaCompany) {
			return null
		}
		let myNewPortfolioItems = List()

		rowDataMemo.forEach((investment) => {
			const investmentDetails = investment.get('details')
			const rootOwners = investment.get('rootOwners')
			if (rootOwners) {
				rootOwners.forEach((rootOwner) => {
					let updatedInvestment = investment
					const showInPortfolio = rootOwner.getIn(['personalInvestment', 'showInPortfolio'])
					if (!showInPortfolio) {
						let adjustedRootDetails = Map()

						const hasDetailsAndIsNotEmpty = rootOwner.get('details') && !rootOwner.get('details').isEmpty()
						if (!investmentDetails.isEmpty() && hasDetailsAndIsNotEmpty) {
							const ownershipPercentage = rootOwner.getIn(['details', 'ownershipPercentage'])

							const myPostMoney = (investmentDetails.get('myPostMoney') * ownershipPercentage) / 100
							const newOwnershipPercentage =
								(investmentDetails.get('ownershipPercentage') * ownershipPercentage) / 100
							const myTotalVotes = (investmentDetails.get('myTotalVotes') * ownershipPercentage) / 100
							const votingRightPercentage =
								(investmentDetails.get('votingRightPercentage') * ownershipPercentage) / 100
							const capitalInvested =
								(investmentDetails.get('capitalInvested') * ownershipPercentage) / 100
							const numOfTotalShares =
								(investmentDetails.get('numOfTotalShares') * ownershipPercentage) / 100
							const averagePaidPricePerShare =
								(investmentDetails.get('averagePaidPricePerShare') * ownershipPercentage) / 100
							const valuePerformance =
								(investmentDetails.get('valuePerformance') * ownershipPercentage) / 100
							const valuePerformancePercentage = investmentDetails.get('valuePerformancePercentage')

							adjustedRootDetails = investmentDetails

							adjustedRootDetails = adjustedRootDetails.set('myPostMoney', myPostMoney)
							adjustedRootDetails = adjustedRootDetails.set('ownershipPercentage', newOwnershipPercentage)
							adjustedRootDetails = adjustedRootDetails.set('myTotalVotes', myTotalVotes)
							adjustedRootDetails = adjustedRootDetails.set(
								'votingRightPercentage',
								votingRightPercentage
							)
							adjustedRootDetails = adjustedRootDetails.set('capitalInvested', capitalInvested)
							adjustedRootDetails = adjustedRootDetails.set('numOfTotalShares', numOfTotalShares)
							adjustedRootDetails = adjustedRootDetails.set(
								'averagePaidPricePerShare',
								averagePaidPricePerShare
							)
							adjustedRootDetails = adjustedRootDetails.set('valuePerformance', valuePerformance)
							adjustedRootDetails = adjustedRootDetails.set(
								'valuePerformancePercentage',
								valuePerformancePercentage
							)
						}

						updatedInvestment = investment.set('details', adjustedRootDetails)
					}

					const getRootOwnerName = rootOwner.getIn(['personalInvestment', 'alias']) || investment.get('name')

					const investorTypeOfOwner =
						rootOwner.get('investorTypeOfOwner') ||
						rootOwner.getIn(['personalInvestment', 'personalInvestmentType', 'viaInvestmentType'])
					const insuranceNumber =
						rootOwner.get('insuranceNr') ||
						rootOwner.getIn(['personalInvestment', 'personalInvestmentType', 'insuranceNr'])
					let name
					if (investorTypeOfOwner === 'capitalInsurance') {
						name = `${getRootOwnerName} - ${investorTypeOfOwner} - ${insuranceNumber}`
					} else {
						name = `${getRootOwnerName} - ${investorTypeOfOwner}`
					}

					updatedInvestment = updatedInvestment.set('name', name)
					updatedInvestment = updatedInvestment.set('link', rootOwner.get('link'))

					updatedInvestment = updatedInvestment.set(
						'rootOwnerPersonalInvestmentId',
						rootOwner.get('personalInvestmentId')
					)

					myNewPortfolioItems = myNewPortfolioItems.push(updatedInvestment)
				})
			}
		})

		myNewPortfolioItems =
			myNewPortfolioItems &&
			myNewPortfolioItems.map((portfolioItem) => {
				let newPortfolioItem = portfolioItem

				const matchedRootOwner = newPortfolioItem
					.get('rootOwners')
					.find(
						(rootOwner) =>
							rootOwner.get('personalInvestmentId') ===
							newPortfolioItem.get('rootOwnerPersonalInvestmentId')
					)
				const showInPortfolio = matchedRootOwner.getIn(['personalInvestment', 'showInPortfolio'])

				if (showInPortfolio) {
					const personalInvestmentDetails = matchedRootOwner.getIn(['personalInvestment', 'details'])

					if (!personalInvestmentDetails.isEmpty()) {
						const ownershipPercentage = matchedRootOwner.getIn([
							'personalInvestment',
							'personalInvestmentType',
							'ownershipPercentage'
						])

						const myPostMoney = (personalInvestmentDetails.get('myPostMoney') * ownershipPercentage) / 100
						const newOwnershipPercentage =
							(personalInvestmentDetails.get('ownershipPercentage') * ownershipPercentage) / 100
						const myTotalVotes = (personalInvestmentDetails.get('myTotalVotes') * ownershipPercentage) / 100
						const votingRightPercentage =
							(personalInvestmentDetails.get('votingRightPercentage') * ownershipPercentage) / 100
						const capitalInvested =
							(personalInvestmentDetails.get('capitalInvested') * ownershipPercentage) / 100
						const numOfTotalShares =
							(personalInvestmentDetails.get('numOfTotalShares') * ownershipPercentage) / 100
						const averagePaidPricePerShare =
							(personalInvestmentDetails.get('averagePaidPricePerShare') * ownershipPercentage) / 100
						const valuePerformance =
							(personalInvestmentDetails.get('valuePerformance') * ownershipPercentage) / 100
						const valuePerformancePercentage = personalInvestmentDetails.get('valuePerformancePercentage')

						let adjustedRootDetails = personalInvestmentDetails

						adjustedRootDetails = adjustedRootDetails.set('myPostMoney', myPostMoney)
						adjustedRootDetails = adjustedRootDetails.set('ownershipPercentage', newOwnershipPercentage)
						adjustedRootDetails = adjustedRootDetails.set('myTotalVotes', myTotalVotes)
						adjustedRootDetails = adjustedRootDetails.set('votingRightPercentage', votingRightPercentage)
						adjustedRootDetails = adjustedRootDetails.set('capitalInvested', capitalInvested)
						adjustedRootDetails = adjustedRootDetails.set('numOfTotalShares', numOfTotalShares)
						adjustedRootDetails = adjustedRootDetails.set(
							'averagePaidPricePerShare',
							averagePaidPricePerShare
						)
						adjustedRootDetails = adjustedRootDetails.set('valuePerformance', valuePerformance)
						adjustedRootDetails = adjustedRootDetails.set(
							'valuePerformancePercentage',
							valuePerformancePercentage
						)

						newPortfolioItem = newPortfolioItem.set('details', adjustedRootDetails)
					}
				}

				return newPortfolioItem
			})

		setMyPortfolioItems(myNewPortfolioItems)
	}, [rowDataMemo, investmentViaCompany])

	useEffect(() => {
		if (!rowDataMemo || rowDataMemo.size === 0) {
			return null
		}
		if (myPortfolioItems && investmentViaCompany) {
			setColDefs(myPortfolioItems, isGridModalOpen, id)
			setInvestmentsPortfolioRowData(myPortfolioItems, isGridModalOpen)
		} else {
			setColDefs(rowDataMemo, isGridModalOpen, id)
			setInvestmentsPortfolioRowData(rowDataMemo, isGridModalOpen)
		}
	}, [myPortfolioItems, isGridModalOpen, rowDataMemo, investmentViaCompany])

	useEffect(() => {
		if (investmentsPortfolioRowData && investmentsPortfolioRowData.length > 0) {
			const newArray = []
			const newObject = investmentsPortfolioRowData[0]

			Object.entries(newObject).forEach(([key, value]) => {
				if (typeof value === 'object') {
					newArray.push({ [key]: value.data.link })
				}
			})

			setHamburgerMenuItems(newArray)
		}
	}, [investmentsPortfolioRowData])

	const viaCompanyInvestment = investmentViaCompany && rowDataMemo.get(0)
	let name
	if (viaCompanyInvestment) {
		name = viaCompanyInvestment.get('viaCompanyAlias') || viaCompanyInvestment.get('viaCompanyName')
	}

	const TRANSLATION_STATES = {
		[INVESTMENTS_PRIVATE_PORTFOLIO]: {
			modalLeftHeader: 'privately_owned_not_via_any_company',
			title: 'privately_owned_not_via_any_company',
			downloadExcelTid: 'investments.privately_owned.dropdown_item.download_excel_list'
		},
		[INVESTMENTS_CAPITAL_INSURANCE]: {
			modalLeftHeader: 'investments.capital_insurance.owned_via',
			title: 'investments.capital_insurance.owned_via',
			downloadExcelTid: 'investments.via_capital_insurance.dropdown_item.download_excel_list'
		},
		[INVESTMENTS_COMPANY_INVESTMENTS_PORTFOLIO]: {
			modalLeftHeader: 'company_owned_direcy',
			title: 'company_owned_direcy',
			downloadExcelTid: 'investments.company_direct_owned.dropdown_item.download_excel_list'
		}
	}

	const openGridModal = useCallback(() => {
		dispatch(openModal(GRID_MODAL, gridOption))
	})

	const modalLeftHeader = () => {
		if (investmentViaCompany) {
			return {
				tid: 'investments.company_owned_shares',
				values: { company: name },
				...(window.location.href.includes('/portfolio') && {
					secondTid: 'investments.company_owned_shares.second_part'
				})
			}
		} else {
			return {
				tid: TRANSLATION_STATES[investmentType].title
			}
		}
	}

	const rowClassRules = {
		// row style function
		'first-column-accumulated': (params) => {
			return params.data?.rowId ? true : false
		},
		'first-column-sharetype': (params) => {
			return params.data?.shareTypeRow ? true : false
		}
	}

	if (!investmentsPortfolioRowData || investmentsPortfolioRowData.length === 0) {
		return null
	}

	return (
		<Card>
			<CardHeader
				title={
					investmentViaCompany ? (
						<>
							<Text tid={'investments.company_owned_shares'} values={{ company: name }} />
							{window.location.href.includes('/portfolio') && (
								<>
									&nbsp;
									<Text tid={'investments.company_owned_shares.second_part'} color={'lightGrey'} />
								</>
							)}
						</>
					) : (
						<Text tid={TRANSLATION_STATES[investmentType].title} />
					)
				}
				action={
					<GridPanelRightButtons
						openGridModal={openGridModal}
						noFilter={true}
						hamburgerMenuItems={hamburgerMenuItems}
					/>
				}
			/>
			<CardContent variant='panel'>
				<AgGrid
					id={id}
					mode={isGridModalOpen ? GRID_MODE_MODAL : GRID_MODE_PANEL}
					isGridModalOpen={isGridModalOpen}
					rowData={investmentsPortfolioRowData}
					columnDefs={colDefs}
					gridRef={gridRef}
					doNotIncludeExcelExport={true}
					columnTypes={colTypes}
					modalLeftHeader={modalLeftHeader()}
					rightHeaderComponent={() => {
						return <ExportExcelButton noFilter={true} />
					}}
					rowClassRules={isGridModalOpen && rowClassRules}
					groupDisplayType='groupRows'
					groupRowRenderer='agGroupCellRenderer'
					isGroupOpenByDefault={() => true}
					groupRowRendererParams={{ suppressCount: true }}
					rowsPerPage={investmentsPortfolioRowData?.length}
					groupedGrid
				/>
			</CardContent>
		</Card>
	)
}

export default InvestmentsGridContainer
