import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../../../../../dumb-components/shared/button/button';
import HeaderControls from '../../header-controls.container';
import { ButtonTransparentIcon } from '../../../../../dumb-components/shared/button';
import FakeFooterSpacer from '../../../../../dumb-components/shared/modal/fake-footer-spacer';

import OnboardingModal from '../../../../../dumb-components/onboarding/modal';
import WelcomeUser from '../../../../../dumb-components/onboarding/modals/dashboard/welcome-user';
import CompanyAccountWizard from '../../../../company/account-wizard/account-wizard.container';
import InitAuthorizeCompany from '../../../../company/account-wizard/init-authorize-company.container';

import { closeModal } from '../../../../../actions/modals.actions';
import { markOnboardAsDismiss } from '../../../../../actions/notifications.actions';
import history from '../../../../../interfaces/history';

const INITIAL_STATE = {
	step: 0,
	authData: null
};

class WelcomeUserOnboardModal extends Component {
	state = INITIAL_STATE;

	componentDidMount = () => {
		hj && hj('trigger', 'heatmap-onboarding-new-user');
	};

	goToBeginning = () => {
		this.setState(INITIAL_STATE);
		this.companyAccountWizardRef._resetState();
	};

	goToAddCompany = () => {
		this.setState({ step: 1 });
	};

	goToAutorize = (authData) => {
		this.setState({ step: 2, authData });
	};

	cancelAuthorize = () => {
		this.goToBeginning();
	};

	dismissPermanently = () => {
		const { notificationId, markOnboardAsDismiss, closeModal } = this.props;
		markOnboardAsDismiss(notificationId);
		closeModal();
	};

	onCompanyAuthorised = (responseData) => {
		const urlAlias = responseData.getIn(['company', 'urlAlias']);

		this.dismissPermanently();

		this.props.history.push(`/${urlAlias}`);
	};

	getSteps = () => {
		const { authData } = this.state;

		return [
			{
				body: <WelcomeUser onGoToAddCompany={this.goToAddCompany} />,
				footer: {
					component: <FakeFooterSpacer height={36} />
				}
			},
			{
				body: <CompanyAccountWizard onRef={this.setCompanyAccountWizardRef} onAuthorize={this.goToAutorize} />,
				footer: {
					leftComponent: (
						<ButtonTransparentIcon onClick={this.goToBeginning} icon='faLongArrowLeft' size='xl' />
					)
				}
			},
			{
				body: <InitAuthorizeCompany authData={authData} onCompanyAuthorised={this.onCompanyAuthorised} />,
				footer: {
					component: <Button tid='generic.form.cancel' onClick={this.goToBeginning} />
				}
			}
		];
	};

	setCompanyAccountWizardRef = (ref) => {
		this.companyAccountWizardRef = ref;
	};

	controlsComponent = () => {
		return <HeaderControls />;
	};

	render = () => {
		const { step } = this.state;

		return (
			<OnboardingModal
				dropdownComponent={this.controlsComponent()}
				mode='steps'
				step={step}
				steps={this.getSteps()}
			/>
		);
	};
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		notificationId: store.modals.getIn(['activeModal', 'options', 'notificationId'])
	};
};

const mapActionsToProps = {
	markOnboardAsDismiss,
	closeModal
};

export default connect(mapStoreToProps, mapActionsToProps)(WelcomeUserOnboardModal);
