import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map, fromJS } from 'immutable'
import Cookies from 'universal-cookie'

import { fetchOnboardNotifications } from '../../../actions/notifications.actions'
import { openModal, closeModal } from '../../../actions/modals.actions'

// Main Modal Constants
import {
	ONB_DASHBOARD_WELCOME_USER,
	ONB_DASHBOARD_WELCOME_MEMBER,
	ONB_DASHBOARD_WELCOME_SHAREHOLDER_USER,
	ONB_DASHBOARD_WELCOME_SHAREHOLDER_COMPANY,
	ONB_COMPANY_DASHBOARD_INTRO,
	ONB_COMPANY_DOCUMENTS_INTRO,
	ONB_COMPANY_MEETINGS_INTRO,
	ONB_COMPANY_DOCUMENTS_UPLOAD,
	ALLOW_CREATE_DOCUMENT,
	ALLOW_CREATE_STANDARD_MEETING,
	EVENT_TYPE_DOCUMENT_SHOW_ONB_DOCUMENT_UPLOADED_MODAL,
	ONB_REQUIREMENT_DOCUMENTS_INITIALIZED
} from '/shared/constants'

import CompanyDashboardIntro from './modals/company-dashboard/intro.container'
import DashboardWelcomeUser from './modals/dashboard/welcome-user.container'
import DashboardWelcomeMember from './modals/dashboard/welcome-member.container'
import DashboardWelcomeShareholderUser from './modals/dashboard/welcome-shareholder-user.container'
import DashboardWelcomeShareholderCompany from './modals/dashboard/welcome-shareholder-company.container'
import DocumentsIntro from './modals/documents/intro.container'
import MeetingsIntro from './modals/meetings/intro.container'
import DocumentsUploadedDocInfo from './modals/documents/uploaded-document-info.container'

class OnboardingControllerContainer extends Component {
	state = {
		allowedPermissions: List()
	}

	componentDidMount = () => {
		this.fetchNotifications()
		this.setAllowedPermissions()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { onboardNotifications, userLocation, activeModal, socketId } = this.props
		const { allowedPermissions } = this.state

		this.setAllowedPermissions()

		if (!activeModal && userLocation !== prevProps.userLocation) {
			this.checkForNotifications()
		}

		if (!activeModal && onboardNotifications !== prevProps.onboardNotifications) {
			this.checkForNotifications()
		}

		if (!activeModal && activeModal !== prevProps.activeModal) {
			this.checkForNotifications()
		}

		if (socketId !== prevProps.socketId) {
			this.fetchNotifications()
		}

		if (!activeModal && allowedPermissions !== prevState.allowedPermissions) {
			this.checkForNotifications()
		}

		this.checkLiveUpdates()
	}

	checkLiveUpdates = () => {
		const { documentUploadedEvent, onboardNotifications, openModal, userLocation, activeModal } = this.props

		if (!onboardNotifications) {
			return
		}

		const documentUploadedOnboardNotification = onboardNotifications.find(
			(n) => n.get('notificationTemplate') === ONB_COMPANY_DOCUMENTS_UPLOAD
		)
		const shouldShow =
			documentUploadedOnboardNotification &&
			!documentUploadedOnboardNotification.get('isRead') &&
			documentUploadedOnboardNotification.get('siteContext') === userLocation

		if (documentUploadedEvent.get('r') === true && shouldShow) {
			const notificationTemplate = documentUploadedOnboardNotification.get('notificationTemplate')
			const optionsData = {
				notificationId: documentUploadedOnboardNotification.get('id'),
				notificationData: documentUploadedOnboardNotification.get('notificationData')
			}

			if (!activeModal) {
				openModal(notificationTemplate, optionsData)
			}
		}
	}

	fetchNotifications = () => {
		const { socketId, fetchOnboardNotifications } = this.props

		if (socketId) {
			setTimeout(() => {
				fetchOnboardNotifications()
			}, 500)
		}
	}

	setAllowedPermissions = () => {
		const { allowedPermissions } = this.state
		let newAllowedPermissions = List()

		newAllowedPermissions = this.getHasPermissionToUploadDocument(newAllowedPermissions)
		newAllowedPermissions = this.getHasPermissionsToCreateStandardMeeting(newAllowedPermissions)
		newAllowedPermissions = this.getRequirementDocumentsInitialized(newAllowedPermissions)

		const permissionsWereChanged = !allowedPermissions.equals(newAllowedPermissions)

		if (permissionsWereChanged) {
			this.setState({ allowedPermissions: newAllowedPermissions })
		}
	}

	getHasPermissionToUploadDocument = (allowedPermissions) => {
		const { folders, folderId } = this.props
		const hasPermissions = folders.getIn([folderId, ALLOW_CREATE_DOCUMENT])

		if (hasPermissions) {
			return allowedPermissions.push(ALLOW_CREATE_DOCUMENT)
		}

		return allowedPermissions
	}

	getHasPermissionsToCreateStandardMeeting = (allowedPermissions) => {
		const { selectedGroupId, groups } = this.props

		const hasPermissions =
			groups.getIn([selectedGroupId, ALLOW_CREATE_STANDARD_MEETING]) && groups.getIn([selectedGroupId, 'ALLOW_CREATE'])

		if (hasPermissions) {
			return allowedPermissions.push(ALLOW_CREATE_STANDARD_MEETING)
		}

		return allowedPermissions
	}

	getRequirementDocumentsInitialized = (allowedPermissions) => {
		const { documentsAreInitialized } = this.props

		if (documentsAreInitialized) {
			return allowedPermissions.push(ONB_REQUIREMENT_DOCUMENTS_INITIALIZED)
		}

		return allowedPermissions
	}

	filterNotification = (notification) => {
		const { allowedPermissions } = this.state

		// Notification should not load automatically
		if (notification.getIn(['notificationData', 'preventAutoLoad']) === true) {
			return false
		}

		const neededPermissions = notification.getIn(['notificationData', 'neededPermissions'], List()) || List()

		if (neededPermissions.size === 0) {
			return true
		}

		const hasEveryNeededPermission = neededPermissions.every((np) => allowedPermissions.includes(np))

		return hasEveryNeededPermission
	}

	checkForNotifications = () => {
		const { onboardNotifications, userLocation, sessionReadNotifications, openModal } = this.props

		if (onboardNotifications.size === 0) {
			return
		}

		// Get notifications relevant for users current location
		let relevantNotifications = onboardNotifications.filter((n) => n.get('siteContext') === userLocation)

		// Filter out notifications that have been dismissed this session
		relevantNotifications = relevantNotifications.filter((n) => !sessionReadNotifications.has(n.get('id')))

		// Filter out notifications that user has no permissions to view
		relevantNotifications = relevantNotifications.filter(this.filterNotification)

		// Check if there are any unread notifications
		const hasUnreadNotification = relevantNotifications.findIndex((n) => !n.get('isRead')) > -1

		// Show first notification and break
		for (let i = 0; i < relevantNotifications.size; i++) {
			const notification = relevantNotifications.get(i)

			// If an unread notification was found and current notfication
			// is already read, skip to next notification
			if (hasUnreadNotification && notification.get('isRead')) {
				continue
			}

			const notificationTemplate = notification.get('notificationTemplate')

			const optionsData = {
				notificationId: notification.get('id'),
				notificationData: notification.get('notificationData')
			}

			setTimeout(() => {
				openModal(notificationTemplate, { ...optionsData })
			}, 300)

			break
		}
	}

	render = () => {
		const { activeModal } = this.props

		switch (activeModal) {
			case ONB_DASHBOARD_WELCOME_USER:
				return <DashboardWelcomeUser />
			case ONB_DASHBOARD_WELCOME_MEMBER:
				return <DashboardWelcomeMember />
			case ONB_DASHBOARD_WELCOME_SHAREHOLDER_USER:
				return <DashboardWelcomeShareholderUser />
			case ONB_DASHBOARD_WELCOME_SHAREHOLDER_COMPANY:
				return <DashboardWelcomeShareholderCompany />
			case ONB_COMPANY_DASHBOARD_INTRO:
				return <CompanyDashboardIntro />
			case ONB_COMPANY_DOCUMENTS_INTRO:
				return <DocumentsIntro />
			case ONB_COMPANY_MEETINGS_INTRO:
				return <MeetingsIntro />
			case ONB_COMPANY_DOCUMENTS_UPLOAD:
				return <DocumentsUploadedDocInfo />
			default:
				return null
		}
	}
}

const mapStoreToProps = (store) => {
	const ONB_PERMISSIONS_DATA = {
		folderId: store.folders.get('selectedFolderId', 'root') || 'root',
		folders: store.folders.get('folders', Map()),
		selectedGroupId: store.groups.get('selectedGroupId', 'root') || 'root',
		groups: store.groups.get('groups', Map()),
		documentsAreInitialized: store.company.company?.metadata?.documentsInitialized
	}

	return {
		onboardNotifications: store.notifications.get('onboardNotifications'),
		sessionReadNotifications: store.notifications.get('sessionReadNotifications'),
		userLocation: store.notifications.get('userLocation'),
		activeModal: store.modals.getIn(['activeModal', 'name']),
		socketId: store.notify.socketId,
		documentUploadedEvent:
			store.audit.getIn(['documents', EVENT_TYPE_DOCUMENT_SHOW_ONB_DOCUMENT_UPLOADED_MODAL]) || Map(),
		...ONB_PERMISSIONS_DATA
	}
}

const mapActionsToProps = {
	fetchOnboardNotifications,
	openModal,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(OnboardingControllerContainer)
