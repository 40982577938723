import React from 'react'
import { Box, Table, TableHead, TableBody, TableRow, TableCell, TableContainer } from '@mui/material'
import { getShareTypeLabel } from '../../../../../components/helpers/shares'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import { NUMBER_FORMAT_CURRENCY, NUMBER_FORMAT_INTEGER } from '/shared/constants'
import { useTranslation } from 'react-i18next'

function NumberAndPrice(props) {
	const {
		data: { types }
	} = props
	const { t } = useTranslation()
	return (
		<Box className='custom-tooltip' sx={{ pading: 2, minWidth: '400px' }}>
			<TableContainer>
				<Table sx={{ width: 'calc(100% - 32px)' }} size='small'>
					<TableHead>
						<TableRow>
							<TableCell component='th'></TableCell>
							<TableCell component='th' align='right'>
								{t('investments.sharebook.transaction_history_grid.new_shares.tooltip.amount')}
							</TableCell>
							<TableCell component='th' align='right'>
								{t('investments.sharebook.transaction_history_grid.new_shares.tooltip.price')}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{types.map((shareType, index) => (
							<TableRow key={index}>
								<TableCell>{getShareTypeLabel(shareType.type)}</TableCell>
								<TableCell align='right'>
									{typeof shareType.newShares === 'number'
										? localeFormatNumber(shareType.newShares, NUMBER_FORMAT_INTEGER)
										: '-'}
								</TableCell>
								<TableCell align='right'>
									{typeof shareType.latestPrice === 'number'
										? localeFormatNumber(shareType.latestPrice, NUMBER_FORMAT_CURRENCY)
										: '-'}
								</TableCell>
							</TableRow>
						))}
						{(!types || types.length === 0) && (
							<TableRow>
								<TableCell colSpan={3}>
									<em>{t('grid.tooltips.new_shares_and_price.no_share_types')}</em>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export { NumberAndPrice }
