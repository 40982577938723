import req from '../modules/request.module';
import { fromJS, List } from 'immutable';
import { setLanguage } from '../actions/i18n.actions';
import {
	USER_UPDATE,
	USER_ERROR,
	USER_SAVE,
	USER_REMOVE_COMPANY,
	USER_DIRTY,
	USER_EDIT_START,
	USER_FETCH
} from './types';
import { signout } from './credentials.actions';
import { meetingsSetDefaultFilterBy } from './meetings.actions';
import { documentsSetDefaultFilterBy } from './documents.actions';
import { tasksSetDefaultFilterBy } from './tasks.actions';
import { fetchPrivateCustomer } from './private-subscriptions.actions';
import ReactGA from 'react-ga4';
import { addErrorNotification, addInfoNotification } from './notify.actions';
import { fetchUserCompanies } from './company.actions';

export function updateUser(user) {
	return {
		type: USER_UPDATE,
		payload: user
	};
}

/**
 * Action for fetching a user
 */
export function fetchUser(userId, callback) {
	return async function (dispatch, getState) {
		try {
			const response = await req.get(`/users/${userId}`);

			callback && callback();
			const userObj = fromJS(response.data);
			dispatch({ type: USER_FETCH, payload: userObj });

			if (userId === 'me') {
				ReactGA.set({ userId: response.data.id });
				ReactGA.event('authentication', { userId: response.data.id });
				dispatch(setLanguage(response.data.siteSettings ? response.data.siteSettings.language : null));
				dispatch(fetchPrivateCustomer());
				dispatch({
					type: 'updateSocketId',
					payload: { userId: response.data.id },
					isSocketAction: true
				});

				// Set default filters
				const company = getState().company.company;

				if (company) {
					const storedFilters = userObj.getIn(['siteSettings', 'pinedFilters']);
					let filteredStoredFilters =
						storedFilters && storedFilters.filter((value, key) => key.startsWith(company.id));
					if (filteredStoredFilters) {
						filteredStoredFilters = filteredStoredFilters
							.mapKeys((key) => key.replace(`${company.id}-`, ''))
							.groupBy((value, key) => key.split('$')[0]);
						filteredStoredFilters.has('meetings') &&
							dispatch(meetingsSetDefaultFilterBy(filteredStoredFilters.get('meetings')));
						filteredStoredFilters.has('documents') &&
							dispatch(documentsSetDefaultFilterBy(filteredStoredFilters.get('documents')));
						filteredStoredFilters.has('tasks') &&
							dispatch(tasksSetDefaultFilterBy(filteredStoredFilters.get('tasks')));
					}
				}
			}
		} catch (e) {
			callback && callback();
			console.error(e);
			dispatch(userError('Could\'t fetch user.'));
		}
	};
}

/**
 * Action for saving a user
 */
export function saveUser(id, props, ignoreSetLanguage = false, callback) {
	return function (dispatch) {
		dispatch({ type: USER_EDIT_START });
		delete props.companies;
		req.put(`/users/${id}`, props, { onlyLatest: true })
			.then((response) => {
				dispatch({ type: USER_SAVE, payload: fromJS(response.data) });

				if (id === 'me' && !ignoreSetLanguage) {
					dispatch(setLanguage(response.data.siteSettings ? response.data.siteSettings.language : null));
				}

				callback && callback();
			})
			.catch((e) => {
				if (!e || !e.message || !e.message.includes('onlyLatest:true')) {
					callback && callback(e);
					dispatch(userError('Could\'t save user.'));
				}
			});
	};
}

/**
 * Action for saving users profile image
 */
export function saveProfileImage(id, obj) {
	return function (dispatch) {
		req.put(`/users/${id}/image`, obj)
			.then((response) => {
				dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
			})
			.catch(() => {
				dispatch(userError('Could\'t save user.'));
			});
	};
}

/**
 * Delete user profile image
 */
export function deleteProfileImage(id) {
	return function (dispatch) {
		req.delete(`/users/${id}/image`)
			.then((response) => {
				dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
			})
			.catch(() => {
				dispatch(userError('Could\'t save user.'));
			});
	};
}

/**
 * Add new e-mail
 */
export function addEmail(id, { email, isDefault }, callback) {
	return function (dispatch) {
		return req.post(`/users/${id}/email`, { email, isDefault }).then((response) => {
			dispatch(addInfoNotification({ tid: 'users.profile.feedbacks.verification_code_sent' }));
			callback?.(fromJS(response.data));
		});
	};
}

export function verifyNewEmail(code, emailId, callback) {
	return function (dispatch) {
		return req.get(`/users/email/verify-new?code=${code}&emailId=${emailId}`).then((response) => {
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
			dispatch(fetchUserCompanies('me'));
			dispatch(addInfoNotification({ tid: 'users.profile.feedbacks.new_email_added' }));
			callback?.();
		});
	};
}

/**
 * Change user e-mail
 */
export function changeUserEmail(id, email, emailId, callback) {
	return function (dispatch) {
		return req.put(`/users/${id}/email/${emailId}`, { email }).then((response) => {
			dispatch(addInfoNotification({ tid: 'users.profile.feedbacks.verification_code_sent' }));
			dispatch(fetchUserCompanies('me'));
			callback?.();
		});
	};
}

export function verifyEmailCode(code, emailId, callback) {
	return function (dispatch) {
		return req.get(`/users/email/${emailId}/verify-change?code=${code}`).then((response) => {
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
			dispatch(addInfoNotification({ tid: 'users.profile.feedbacks.changes_made' }));
			callback?.();
		});
	};
}

/**
 * Remove email
 */
export function deleteEmail(id, emailId) {
	return function (dispatch) {
		return req.delete(`/users/${id}/email/${emailId}`).then((response) => {
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
			dispatch(addInfoNotification({ tid: 'users.profile.feedbacks.email_deleted' }));
		});
	};
}

export function setDefaultEmail(id, emailId) {
	return function (dispatch) {
		req.put(`/users/${id}/email/${emailId}/default`).then((response) => {
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
			dispatch(addInfoNotification({ tid: 'users.profile.feedbacks.default_email_changed' }));
		});
	};
}

export function connectEmailToCompany(id, emailId, companyId) {
	return function (dispatch) {
		req.put(`/users/${id}/email/${emailId}/company/${companyId}`).then((response) => {
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
			dispatch(addInfoNotification({ tid: 'users.profile.feedbacks.email_connected_to_company' }));
		});
	};
}

export function disconnectEmailFromCompany(id, companyId) {
	return function (dispatch) {
		req.delete(`/users/${id}/email/company/${companyId}`).then((response) => {
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
			dispatch(addInfoNotification({ tid: 'users.profile.feedbacks.email_connected_to_company' }));
		});
	};
}
/**
 * Cancel user account
 */
export function cancelAccount(id, callback) {
	return function (dispatch) {
		return req.delete(`/users/${id}`).then(() => {
			dispatch(signout());
			callback && callback();
		});
	};
}

/**
 * Change user password
 */
export function changeUserPassword(id, props, callback) {
	return function (dispatch) {
		return req
			.put(`/users/${id}/change-password`, props.toJS())
			.then((response) => {
				dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
				callback && callback();
			})
			.catch(() => {
				dispatch(userError('Could\'t save user.'));
			});
	};
}

export function dismissBlock(blockId) {
	return function (dispatch, getState) {
		const userId = getState().user.getIn(['userObj', 'id']);
		return req.post(`/users/dismiss-block/${blockId}/${userId}`).then((response) => {
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
		});
	};
}

export function dismissOnboardingAlert(notificationId) {
	return function (dispatch, getState) {
		const userId = getState().user.getIn(['userObj', 'id']);
		return req.post(`/users/dismiss-onboarding-alert/${notificationId}/${userId}`).then((response) => {
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
		});
	};
}

export function resetOnboardingAlerts() {
	return function (dispatch, getState) {
		const userId = getState().user.getIn(['userObj', 'id']);
		return req.post(`/users/reset-onboarding-alerts/${userId}`).then((response) => {
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
		});
	};
}

export function allowSupportAccess() {
	return function (dispatch, getState) {
		const userId = getState().user.getIn(['userObj', 'id']);
		return req.put(`/users/${userId}/allow-support-access`).then((response) => {
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
		});
	};
}

export function disallowSupportAccess() {
	return function (dispatch, getState) {
		const userId = getState().user.getIn(['userObj', 'id']);
		return req.put(`/users/${userId}/disallow-support-access`).then((response) => {
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
		});
	};
}

/**
 * Add contact to users personal address book
 */
export function addContactToAddressBook(newAddress, callback) {
	return function (dispatch, getState) {
		let addressBook = getState().user.getIn(['userObj', 'addressBook'], List()) || List();
		addressBook = addressBook.insert(0, newAddress);

		dispatch(saveUser('me', { addressBook: addressBook.toJS() }, true, callback));
	};
}

/**
 * Remove contact from users personal address book
 */
export function removeContactFromAddressBook(id, callback) {
	return function (dispatch, getState) {
		let addressBook = getState().user.getIn(['userObj', 'addressBook'], List()) || List();
		addressBook = addressBook.filter((address) => address.get('id') !== id);

		dispatch(saveUser('me', { addressBook: addressBook.toJS() }, true, callback));
	};
}

/**
 * Update contact in user address book
 */
export function updateContactInAddressBook(id, newAddress, callback) {
	return function (dispatch, getState) {
		delete newAddress.selectable;
		delete newAddress.selected;
		let addressBook = getState().user.getIn(['userObj', 'addressBook'], List()) || List();
		addressBook = addressBook.update((addresses) =>
			addresses.map((address) => {
				if (address.get('id') === id) {
					return newAddress;
				}

				return address;
			})
		);

		dispatch(saveUser('me', { addressBook: addressBook.toJS() }, true, callback));
	};
}

export function recommendInvonoToCompany(emails) {
	return async function (dispatch) {
		try {
			const response = await req.post(`/users/recommend`, { emails });
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
			dispatch(addInfoNotification({ tid: 'recommend_invono.modal.recommendations_successfull' }));
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'dealflow.recommend.error' }));
		}
	};
}

export function recommendInvonoToCompanyByDealflow(emails) {
	return async function (dispatch) {
		try {
			const response = await req.post(`/users/recommend/dealflow`, { emails });
			dispatch({ type: USER_SAVE, payload: fromJS(response.data) });
			dispatch(addInfoNotification({ tid: 'recommend_invono.modal.recommendation_successfull' }));
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'dealflow.recommend.error' }));
		}
	};
}

/**
 * Remove a company from the companies array
 */
export function removeCompanyFromUser(companyId) {
	return {
		type: USER_REMOVE_COMPANY,
		payload: companyId
	};
}

export function userProfileIsDirty() {
	return {
		type: USER_DIRTY,
		payload: null
	};
}

/**
 * Action for dispatching an user error
 */
export function userError(error) {
	return {
		type: USER_ERROR,
		payload: fromJS(error)
	};
}
