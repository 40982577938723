import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { List, Map } from 'immutable'
import { getFullShareTypeLabel } from '../../../helpers/shares'
import Panel from '../../../../dumb-components/panel'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'

import { NUMBER_FORMAT_INTEGER } from '/shared/constants'

class ConversionOfSharesView extends Component {
	state = {
		shareTypes: List()
	}

	componentDidMount = () => {
		this.setShareTypes(this.props.transaction, this.props.tmpTransaction)
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.transaction !== prevProps.transaction || this.props.tmpTransaction !== prevProps.tmpTransaction) {
			this.setShareTypes(this.props.transaction, this.props.tmpTransaction)
		}
	}

	setShareTypes = (transaction, tmpTransaction) => {
		let newShareType

		if (tmpTransaction) {
			newShareType = tmpTransaction.getIn(['handlerData', 'newShareType'])
		}

		let shareTypes = transaction.getIn(['shareData', 'types'], List())

		if (newShareType) {
			const newShareTypeType = newShareType.get('type')
			const newShareTypeInList = shareTypes.find((obj) => {
				return obj.get('type') === newShareTypeType
			})

			if (!newShareTypeInList) {
				shareTypes = shareTypes.push(newShareType)
			}
		}

		this.setState({ shareTypes })
	}

	calculateNumOfShares = (shareType) => {
		const { tmpTransaction } = this.props
		let numOfShares = shareType.get('numOfShares')

		if (!tmpTransaction) {
			return numOfShares
		}

		const shareTypeFrom = tmpTransaction.getIn(['handlerData', 'shareTypeFrom'])
		const shareTypeTo = tmpTransaction.getIn(['handlerData', 'shareTypeTo'])

		if (shareType.get('type') !== shareTypeFrom && shareType.get('type') !== shareTypeTo) {
			return numOfShares
		}

		tmpTransaction.getIn(['handlerData', 'investors'], Map()).map((investor) => {
			investor.forEach((obj) => {
				if (shareType.get('type') === shareTypeFrom) {
					numOfShares -= obj.get('amount') || 0
				} else {
					numOfShares += obj.get('amount') || 0
				}
			})
		})

		return numOfShares
	}

	renderShareType = (shareType, index) => {
		const name = getFullShareTypeLabel(shareType.get('type'))
		const numOfSharesBefore = shareType.get('numOfShares')
		const numOfSharesAfter = this.calculateNumOfShares(shareType)

		return (
			<div className='list__item' key={index}>
				<span className='list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left'>
					{name}
				</span>
				<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
					{localeFormatNumber(numOfSharesBefore, NUMBER_FORMAT_INTEGER)}
				</span>
				<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
					{numOfSharesAfter !== NaN ? localeFormatNumber(numOfSharesAfter, NUMBER_FORMAT_INTEGER) : ''}
				</span>
			</div>
		)
	}

	render = () => {
		const { transaction } = this.props
		const { shareTypes } = this.state

		if (Object.keys(transaction).length === 0) {
			return null
		}

		return (
			<Panel padHor className='i-panel--white'>
				<div className='list__list-header'>
					<div className='list__list-header__text-area list__list-header__text-area--x3 list__list-header__text-area--pad-lft list__list-header__text-area--pad-right'>
						<FormattedMessage id='shares.class_of_shares' />
					</div>
					<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
						<FormattedMessage id='shares.transactions.conversion_of_shares.total_before' />
					</div>
					<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
						<FormattedMessage id='shares.transactions.conversion_of_shares.total_after' />
					</div>
				</div>
				<div className='list list--table list--striped'>{shareTypes && shareTypes.map(this.renderShareType)}</div>
				<FormattedHTMLMessage id='shares.transactions.conversion_of_shares.information1' />
			</Panel>
		)
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get('transaction'),
		tmpTransaction: state.transaction.getIn(['tmpTransaction', 'CONVERSION-OF-SHARES']),
		i18n: state.i18n
	}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ConversionOfSharesView)
