import React from 'react'
import Box from '@mui/material/Box'
import { DropDown } from '../../../../../mui-components/dropdown/DropDown'
import MenuItemIcon from '../../../../../mui-components/dropdown/menu/menu-item-icon'
import IconButton from '../../../../../mui-components/button/icon-button'
import { usePeopleModalContext } from '../../people-modal.context'
import { TAB_MY_PERSONAL_CONTACTS } from '../../../select-user-modal/select-user-modal.constants'
import Tooltip from '../../../../../mui-components/tooltip/tooltip'

export const ColumnViewMenuItem = () => {
	const { setShowGridInExtendedView, showGridInExtendedView, activeTab } = usePeopleModalContext()
	const isViewingMyPersonalContacts = activeTab === TAB_MY_PERSONAL_CONTACTS

	if (!showGridInExtendedView) {
		return (
			<Tooltip
				title='people_modal.view_menu.view_all_columns.tooltip.disabled_my_personal_contacts'
				hideTooltip={!isViewingMyPersonalContacts}>
				<MenuItemIcon
					icon='faTh'
					listItemTid='people_modal.view_menu.view_all_columns'
					onClick={() => setShowGridInExtendedView(true)}
					disabled={isViewingMyPersonalContacts || showGridInExtendedView}
				/>
			</Tooltip>
		)
	}

	return (
		<Tooltip
			title='people_modal.view_menu.column_default_view.tooltip.disabled_my_personal_contacts'
			hideTooltip={!isViewingMyPersonalContacts}>
			<MenuItemIcon
				icon='faThLarge'
				listItemTid='people_modal.view_menu.column_default_view'
				onClick={() => setShowGridInExtendedView(false)}
				disabled={isViewingMyPersonalContacts || !showGridInExtendedView}
			/>
		</Tooltip>
	)

	/*return (
		<Box sx={{ display: 'flex', alignItems: 'center', marginRight: 6 }}>
			<DropDown
				alignMenu='right'
				button={({ params }) => <IconButton size='sml' noBorder icon='faEllipsisV' {...params} />}>
				<Tooltip
					title='people_modal.view_menu.view_all_columns.tooltip.disabled_my_personal_contacts'
					hideTooltip={!isViewingMyPersonalContacts}>
					<MenuItemIcon
						icon='faTh'
						listItemTid='people_modal.view_menu.view_all_columns'
						onClick={() => setShowGridInExtendedView(true)}
						disabled={isViewingMyPersonalContacts || showGridInExtendedView}
					/>
				</Tooltip>
				<Tooltip
					title='people_modal.view_menu.column_default_view.tooltip.disabled_my_personal_contacts'
					hideTooltip={!isViewingMyPersonalContacts}>
					<MenuItemIcon
						icon='faThLarge'
						listItemTid='people_modal.view_menu.column_default_view'
						onClick={() => setShowGridInExtendedView(false)}
						disabled={isViewingMyPersonalContacts || !showGridInExtendedView}
					/>
				</Tooltip>
			</DropDown>
		</Box>
	)*/
}
