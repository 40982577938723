import React, { PureComponent } from 'react'
import { fromJS } from 'immutable'

import ImmutablePropTypes from 'react-immutable-proptypes'
import { func, bool, string, object, oneOfType, number } from 'prop-types'
import styled from 'styled-components'
import { Padding, Margin } from 'styled-components-spacing'
import Grid from 'styled-components-grid'
import { lighten } from 'polished'
import Panel from '../../shared/panel/panel'
import Label from '../../shared/label/label'
import Button from '../../shared/button/button'
import ToggleSwitch from '../../shared/toggle-switch/toggle-switch'
import Text from '../../shared/text/text'
import Alert from '../../shared/alert/alert'
import Select from '../../shared/select/src/select'
import Tooltip from '../../shared/tooltip/tooltip'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'

import { NUMBER_FORMAT_CURRENCY } from '/shared/constants'

import { PREPAID_OPTIONS } from '../../../containers/subscriptions/constants'

const StyledAutorefillContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	*[class*='text__StyledElement'] {
		margin-left: ${(props) => props.theme.spacing[2]};
		margin-right: ${(props) => props.theme.spacing[2]};
	}
`

const StyledCurrentAmountContainer = styled.div`
	border-bottom: 1px solid ${(props) => lighten(0.2, props.theme.colors.lightGrey)};
	display: flex;
	flex: 1;
	align-items: flex-end;
`

const StyledCurrentAmount = styled.div`
	flex: 1;
`

export default class PrepaiedPanel extends PureComponent {
	static propTypes = {
		prepaidAmount: number,
		prepaidSelectedAmount: number,
		onChangeSubscription: func,
		onClickPurchase: func,
		isLoading: bool,
		canPurchase: bool,
		warning: oneOfType([string, object]),
		info: oneOfType([string, object]),
		infoIcon: bool,
		willChangeAmountTo: number,
		onChangeAutorefill: func,
		eSigningEnabled: bool,
		disabled: bool,
		pricePerBankIdSigning: number
	}

	static defaultProps = {
		canPurchase: false,
		prepaidSelectedAmount: 0
	}

	getOptionLabel = (option) => {
		return <>{localeFormatNumber(option.label, NUMBER_FORMAT_CURRENCY)}</>
	}

	render = () => {
		const {
			prepaidAmount,
			prepaidSelectedAmount,
			prepaidAutoRefill,
			onChangeSubscription,
			onClickPurchase,
			isLoading,
			warning,
			info,
			infoIcon,
			canPurchase,
			isFreeOfCharge,
			eSigningEnabled,
			disabled,
			pricePerBankIdSigning
		} = this.props

		return (
			<Panel mode='light' title='subscriptions.prepaid.title' marginBottom>
				<Padding all={3}>
					<Grid>
						<Grid.Unit size={1}>
							<Padding all={3}>
								<StyledCurrentAmountContainer>
									<StyledCurrentAmount>
										<Text tid='subscriptions.prepaid.label.current_amount' color='muted' />
										<br />
										<Text fontSize='13px'>
											{localeFormatNumber(prepaidAmount, NUMBER_FORMAT_CURRENCY)}
										</Text>
									</StyledCurrentAmount>
								</StyledCurrentAmountContainer>
							</Padding>
						</Grid.Unit>
						<Grid.Unit size={1 / 2}>
							<Padding all={3}>
								<Label tid='subscriptions.prepaid.label.refill_amount'>
									<Select
										fieldName='prepaidSelectedAmount'
										placeholderTid='subscriptions.prepaid.placeholder.select_amount'
										onChange={onChangeSubscription}
										value={prepaidSelectedAmount}
										options={PREPAID_OPTIONS}
										isSearchable={false}
										getOptionLabel={this.getOptionLabel}
										isClearable={true}
										isDisabled={!eSigningEnabled || disabled}
									/>
								</Label>
							</Padding>
							<Padding all={3}>
								<StyledAutorefillContainer>
									<ToggleSwitch
										fieldName='prepaidAutoRefill'
										disabled={!eSigningEnabled || disabled}
										checked={prepaidAutoRefill}
										onChange={onChangeSubscription}
									/>
									<Text tid='subscriptions.prepaid.autorefill' />
								</StyledAutorefillContainer>
							</Padding>
							<Padding all={3}>
								<StyledCurrentAmountContainer>
									<StyledCurrentAmount>
										<Text
											tid='subscriptions.prepaid.label.price_per_bankid_signing'
											color='muted'
										/>
										<br />
										<Text fontSize='13px'>
											{localeFormatNumber(pricePerBankIdSigning, NUMBER_FORMAT_CURRENCY)}{' '}
											<Text color='muted' tid='generic.excl_vat.parentheses' />
										</Text>
									</StyledCurrentAmount>
								</StyledCurrentAmountContainer>
							</Padding>
						</Grid.Unit>
						<Grid.Unit size={1 / 2}>
							<Padding all={3}>
								<Text tag='p' tid='subscriptions.prepaid.description' />
								<Text tag='p' tid='subscriptions.prepaid.description_pt2' />
							</Padding>
						</Grid.Unit>
						<Grid.Unit size={1}>
							<Padding all={3}>
								{!isFreeOfCharge && warning && (
									<Margin bottom={4}>
										<Alert mode='warning' icon>
											{typeof warning === 'string' && <Text tid={warning} color='white' />}
											{typeof warning !== 'string' && <Text {...warning} color='white' />}
										</Alert>
									</Margin>
								)}

								{!isFreeOfCharge && info && (
									<Margin bottom={4}>
										<Alert mode='info' icon={infoIcon}>
											{typeof info === 'string' && <Text tid={info} color='white' />}
											{typeof info !== 'string' && <Text {...info} color='white' />}
										</Alert>
									</Margin>
								)}

								{!isFreeOfCharge && (
									<Tooltip
										tid='subscription.prepaid.btn.buy.disabled_free'
										active={!eSigningEnabled}
										delayShow='instant'>
										<Button
											isLoading={isLoading}
											mode='primary'
											onClick={onClickPurchase}
											tid='subscriptions.purchase'
											minWidth='160px'
											disabled={!canPurchase || disabled}
										/>
									</Tooltip>
								)}
							</Padding>
						</Grid.Unit>
					</Grid>
				</Padding>
			</Panel>
		)
	}
}
