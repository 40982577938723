import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import Moment from '../../../modules/moment.module'
import { formatOrgPretty } from '../../../components/helpers/users.js'

import CompanyDetailsLeft from '../../../dumb-components/company-profile/company-details-left'

class CompanyDetailsLeftContainer extends Component {
	static propTypes = {
		onChange: func.isRequired
	}

	formatOrgNumber = (orgNumber) => {
		return formatOrgPretty(orgNumber)
	}

	getOrgNumber = () => {
		const { orgNumber } = this.props

		if (orgNumber.indexOf('demo=') === 0 || orgNumber.indexOf('userdemo=') === 0) {
			return '5569872335'
		}

		return orgNumber
	}

	render = () => {
		const { onChange, language, companyName, regDate, addresses } = this.props

		if (!addresses || addresses.length === 0) {
			addresses.push({ type: 'padr' })
		}

		return (
			<CompanyDetailsLeft
				companyName={companyName}
				orgNumber={this.formatOrgNumber(this.getOrgNumber())}
				regDate={regDate}
				addresses={addresses}
				language={language}
				onChange={onChange}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	const { company } = store.company

	return {
		language: store.i18n.language,
		companyName: company && company.name,
		orgNumber: company && company.orgNumber,
		regDate: company && Moment(company.createdAt).format('l'),
		addresses: company && company.addresses
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyDetailsLeftContainer)
