import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FlagIcon from '@mui/icons-material/Flag'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { deleteNote, updateNote } from '../../../../actions/emissions.actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const NotesContextMenu = (props) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = !!anchorEl
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<Tooltip title={t('emissions.invitations.more-options')}>
				<IconButton onClick={handleClick}>
					<MoreVertIcon sx={{ color: 'grey.700' }} />
				</IconButton>
			</Tooltip>

			<Menu
				anchorEl={anchorEl}
				id='notes-menu'
				open={open}
				onClose={handleClose}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
				<MenuItem
					onClick={() => {
						dispatch(
							updateNote(props.companyId, props.emissionId, props.inviteeId, {
								...props.note,
								important: !props.note.important
							})
						)
					}}>
					<ListItemIcon>
						<FlagIcon fontSize='small' />
					</ListItemIcon>
					{t(
						props.note.important
							? 'emissions.invitations.remove-important-flag'
							: 'emissions.invitations.flag-as-important'
					)}
				</MenuItem>
				<MenuItem
					onClick={() => {
						dispatch(deleteNote(props.companyId, props.emissionId, props.inviteeId, props.note.id))
					}}>
					<ListItemIcon>
						<DeleteOutlineIcon fontSize='small' />
					</ListItemIcon>
					{t('emissions.invitations.remove-note')}
				</MenuItem>
			</Menu>
		</>
	)
}

export default NotesContextMenu
