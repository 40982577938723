import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'

import ListItem from '../../shared/list-item/list-item'
import Text from '../../shared/text/text'

const StyledDocumentItemTemplate = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
`

const StyledDocumentItem = styled.div`
	${StyledDocumentItemTemplate};
	border: 1px solid ${(props) => props.theme.colors.solitudeDark};
	width: 100%;
`

const StyledListItem = styled(ListItem)`
	margin-bottom: ${(props) => props.theme.spacing[2]};
`

const StyledTransparentListItem = styled(StyledListItem)`
	background-color: transparent;
`

const StyledEmptyMessageItem = styled(StyledDocumentItem)`
	${StyledDocumentItemTemplate};
	border: none;
`

const StyledEmptyMessage = styled.div`
	flex: 1;
	padding-left: ${(props) => props.theme.spacing[4]};
	padding-right: ${(props) => props.theme.spacing[4]};
	text-align: center;
`

export default class EmptyListMessage extends PureComponent {
	render = () => {
		return (
			<StyledTransparentListItem>
				<StyledEmptyMessageItem>
					<StyledEmptyMessage>
						<Text color='muted' tid='documents.list.no_documents_in_root_folder' />
					</StyledEmptyMessage>
				</StyledEmptyMessageItem>
			</StyledTransparentListItem>
		)
	}
}
