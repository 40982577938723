import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, object, func, array } from 'prop-types'
import { List } from 'immutable'
import Text from '../../../dumb-components/shared/text/text'
import Box from '../../../dumb-components/shared/layout/box/box'
import { Margin } from 'styled-components-spacing'
import Icon from '../../../dumb-components/shared/icon/icon'
import Tabs from '../../../dumb-components/shared/tabs/tabs'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import DropdownMenuItem from '../../../dumb-components/shared/dropdown-item/dropdown-item'
import history from '../../../interfaces/history'

class MeetingTabsContainer extends Component {
	static propTypes = {
		location: object,
		match: string,
		onSetRef: func,
		hiddenTabs: object
	}

	onDropdownItemClick = (id) => {
		const {
			location: { search },
			history
		} = this.props
		const basePath = this.getParentBasePath()

		history.push({
			pathname: `${basePath}/${id}`,
			search
		})
	}

	getParentBasePath = () => {
		const {
			location: { pathname }
		} = this.props
		const path = pathname
		const pathArray = path.split('meeting/')
		return pathArray[0] + 'meeting'
	}

	calculateAgendaItemsCount = () => {
		const { meeting, userId } = this.props
		const isSimpleMode = meeting && meeting.getIn(['computedValues', 'isSimpleMode'])

		if (!meeting) {
			return 0
		}

		const agendaItems = meeting.get('agendaItems', List()).filter((item) => {
			if (item.get('archived') && !item.get('usersWithNotes', List()).includes(userId)) {
				return false
			}

			return true
		})

		let items = 0
		agendaItems.forEach((item) => {
			if (item.get('isSuggested') && isSimpleMode) {
				return
			}

			if (item.get('agendaItems') && item.get('agendaItems').size > 0) {
				items += item.get('agendaItems').size
			}
			items++
		})

		return items
	}

	calculateAttendesCount = () => {
		const { meeting } = this.props
		return meeting && meeting.get('attendees') ? meeting.get('attendees').size : 0
	}

	getTabs = () => {
		const { meeting, numOfDocuments, numOfTasks } = this.props
		const agendaItemsCount = this.calculateAgendaItemsCount()
		const attendeesCount = this.calculateAttendesCount()

		return [
			{
				path: 'info',
				highlightColor: 'solitudeDark',
				tid: 'meetings.agenda.tabs.info',
				tabContentRenderer() {
					return (
						<Box alignItems='center' valign='center' flexGrow={false}>
							<Text tid='meetings.agenda.tabs.info' singleLine />
						</Box>
					)
				}
			},
			{
				path: 'attendees',
				highlightColor:
					meeting &&
					meeting.getIn(['computedValues', 'attendeesWarning']) === true &&
					!meeting.getIn(['computedValues', 'isSimpleMode'])
						? 'orange'
						: 'solitudeDark',
				tid: 'meetings.agenda.tab.attendees',
				tabContentRenderer() {
					return (
						<Box alignItems='space-around' valign='center' flexGrow={false}>
							<Text tid='meetings.agenda.tab.attendees' singleLine />
							<Margin left={4}>
								<Text bold={700}>{attendeesCount ? attendeesCount : '0'}</Text>
							</Margin>
						</Box>
					)
				}
			},
			{
				path: 'agenda',
				highlightColor: 'solitudeDark',
				tid: 'meetings.agenda.tabs.agenda',
				tabContentRenderer() {
					return (
						<Box alignItems='center' valign='center' flexGrow={false}>
							<Text tid='meetings.agenda.tabs.agenda' singleLine />
							<Margin left={4}>
								<Text bold={700}>{agendaItemsCount ? agendaItemsCount : '0'}</Text>
							</Margin>
						</Box>
					)
				}
			},
			{
				path: 'documents',
				highlightColor: 'solitudeDark',
				tid: 'meetings.agenda.tab.files',
				tabContentRenderer() {
					return (
						<Box alignItems='space-around' valign='center' flexGrow={false}>
							<Text tid='meetings.agenda.tab.files' singleLine />
							<Margin left={4}>
								<Text bold={700}>{numOfDocuments ? numOfDocuments : '0'}</Text>
							</Margin>
						</Box>
					)
				}
			},
			{
				path: 'tasks',
				highlightColor: 'solitudeDark',
				tid: 'meetings.agenda.tab.tasks',
				tabContentRenderer() {
					return (
						<Box alignItems='space-around' valign='center' flexGrow={false}>
							<Text tid='meetings.agenda.tab.tasks' singleLine />
							<Margin left={4}>
								<Text bold={700}>{numOfTasks ? numOfTasks : '0'}</Text>
							</Margin>
						</Box>
					)
				}
			}
		]
	}

	renderDropdownMenuItem = (id, index) => {
		const tabs = this.getTabs()
		const tab = tabs.find((obj) => obj.path === id)

		return (
			<DropdownMenuItem onClick={() => this.onDropdownItemClick(id)} key={index}>
				<Text color={tab.highlightColor !== 'solitudeDark' ? tab.highlightColor : undefined} tid={tab.tid} />
			</DropdownMenuItem>
		)
	}

	renderDropdownMenu = () => {
		const { hiddenTabs } = this.props

		if (!hiddenTabs) {
			return null
		}

		const paths = Object.keys(hiddenTabs)

		if (paths.length === 0) {
			return null
		}

		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisV'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'>
				{paths && paths.map(this.renderDropdownMenuItem)}
			</DropdownMenuContainer>
		)
	}

	render = () => {
		const { match, onSetRef, hiddenTabs } = this.props
		const basePath = this.getParentBasePath()
		const tabs = this.getTabs()

		return (
			<React.Fragment>
				<Tabs
					invertedColors={true}
					basePath={basePath}
					querystr={location.search}
					match={match}
					tabs={tabs}
					onSetRef={onSetRef}
					flex={false}
					hiddenTabs={hiddenTabs}
				/>
				{this.renderDropdownMenu()}
			</React.Fragment>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meeting: store.meetings.get('meeting'),
		userId: store.user.getIn(['userObj', 'id']),
		numOfDocuments: store.meetings.getIn(['meeting', 'computedValues', 'numOfDocuments']),
		numOfTasks: store.meetings.getIn(['meeting', 'computedValues', 'numOfTasks'])
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingTabsContainer)
