import React, { PureComponent } from 'react'
import { string, bool, array, func } from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { SYNA_COMPANY_ROLES } from '../../../constants/syna-company-roles'

export default class RepresentativesTable extends PureComponent {
	static propTypes = {
		bankIdSigned: bool,
		bankIdSignedBy: string,
		companyRepresentatives: array,
		authorise: func.isRequired
	}

	static defaultProps = {
		companyRepresentatives: []
	}

	renderTableHeader = () => {
		return (
			<thead>
				<tr>
					<th className='account-company'>
						<FormattedMessage id='account.name' />
					</th>
					<th className='account-type'>
						<FormattedMessage id='account.ssn' />
					</th>
					<th className='account-access'>
						<FormattedMessage id='account.position' />
					</th>
					<th>&nbsp;</th>
				</tr>
			</thead>
		)
	}

	renderTableBody = () => {
		const { bankIdSigned, companyRepresentatives, bankIdSignedBy } = this.props

		if (!bankIdSigned) {
			return <tbody>{companyRepresentatives.map(this.renderTableRow)}</tbody>
		}

		let signedBy = {}
		companyRepresentatives.forEach((rep) => {
			if (rep.ssn === bankIdSignedBy) {
				signedBy = rep
			}
		})

		return <tbody>{this.renderTableRow(signedBy)}</tbody>
	}

	renderTableRow = (rep, index) => {
		const { ssn, roles, name } = rep
		const ssnTrimmed = ssn && ssn.slice(0, -4) + '-xxxx'

		const rolesNamed = roles
			.map((role) => {
				if (SYNA_COMPANY_ROLES[role]) {
					return SYNA_COMPANY_ROLES[role]
				}
			})
			.join(', ')

		return (
			<tr key={index}>
				<td>{name}</td>
				<td>{ssnTrimmed}</td>
				<td>{rolesNamed}</td>
				<td className='text--align-right'>{this.renderAuthoriseButton(ssn, name)}</td>
			</tr>
		)
	}

	renderAuthoriseButton = (ssn, name) => {
		const { bankIdSignedBy, bankIdSigned, authorise } = this.props

		const btnDisabled = bankIdSignedBy && bankIdSignedBy !== ssn
		const btnActive = bankIdSignedBy && bankIdSignedBy === ssn && !bankIdSigned
		const btnSuccess = bankIdSignedBy && bankIdSignedBy === ssn && bankIdSigned

		if (btnDisabled) {
			return (
				<button className='btn btn-default' disabled='disabled'>
					<FormattedMessage id='sign_width_bankid' />
				</button>
			)
		}

		if (btnSuccess) {
			return <span className='fa fa-check text--invono'></span>
		}

		if (btnActive) {
			return (
				<button className='btn btn-primary' disabled='disabled'>
					<FormattedMessage id='waiting...' />
				</button>
			)
		}

		return (
			<button className='btn btn-default' onClick={authorise.bind(null, ssn, name)}>
				<FormattedMessage id='sign_width_bankid' />
			</button>
		)
	}

	render = () => {
		return (
			<table className='table table-striped'>
				{this.renderTableHeader()}
				{this.renderTableBody()}
			</table>
		)
	}
}
