import React, { Component } from 'react'
import { connect } from 'react-redux'
import { object, string, array, bool, func, number, oneOf } from 'prop-types'
import BreadcrumbsContainer from './breadcrumbs.container'
import GroupsFormContainer from './groups-form.container'
import Box from '../../../dumb-components/shared/layout/box/box'
import { withRouter } from '../../../interfaces/router'

class GroupsContainer extends Component {
	state = {
		showGroupForm: false,
		projectFormMode: 'create',
		groupId: null,
		parentGroupId: null
	}

	onCreateGroup = (groupId) => {
		this.setState({
			showGroupForm: true,
			projectFormMode: 'create',
			parentGroupId: groupId
		})
	}

	onManageGroup = (groupId) => {
		this.setState({
			showGroupForm: true,
			projectFormMode: 'manage',
			groupId: groupId
		})
	}

	onModalClose = () => {
		this.setState({
			showGroupForm: false,
			groupId: null
		})
	}

	render = () => {
		const {
			basePath,
			match: { params }
		} = this.props
		const { showGroupForm, projectFormMode, groupId, parentGroupId } = this.state

		return (
			<Box>
				<BreadcrumbsContainer
					basePath={basePath}
					params={params}
					onCreateGroup={this.onCreateGroup}
					onManageGroup={this.onManageGroup}
				/>
				<GroupsFormContainer
					basePath={basePath}
					groupId={groupId}
					parentGroupId={parentGroupId}
					isOpen={showGroupForm}
					mode={projectFormMode}
					onClose={this.onModalClose}
				/>
			</Box>
		)
	}
}

function mapStoreToProps(store) {
	return {}
}

const mapActionsToProps = {}

export default withRouter(connect(mapStoreToProps, mapActionsToProps)(GroupsContainer))
