import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import { number } from 'prop-types'
import FilterBy from '../../dumb-components/shared/filter-by/filter-by'
import DropdownMenuContainer from '../shared/dropdown-menu.container'
import DropdownItem from '../../dumb-components/shared/dropdown-item/dropdown-item'
import Text from '../../dumb-components/shared/text/text'

import folderHelper from '../../components/helpers/folder.helper'

import { listTags } from '../../actions/tags.actions'
import { setFilterBy } from '../../actions/tasks.actions'

const FILTER_BY_ITEMS = ['tag', 'project', 'task']

class TasksFilterByContainer extends Component {
	static propTypes = {
		totalTasks: number,
		totalTasksShown: number
	}

	static defaultProps = {}

	state = {
		selectedFilter: 'tag',
		savedValues: Map(),
		selectedValues: List(),
		options: List()
	}

	componentDidMount = () => {
		this.props.listTags()
	}

	componentWillUnmount = () => {
		const { selectedFilter } = this.state
		this.props.setFilterBy(
			Map({
				source: selectedFilter,
				values: List(),
				isSimpleFilter: true
			})
		)
	}

	componentDidUpdate = (prevProps) => {
		const { selectedFilter } = this.state
		const { tags, projects, projectId, tasks } = this.props

		if (selectedFilter === 'tag' && prevProps.tags !== tags) {
			this.setOptions(selectedFilter)
		}

		if (selectedFilter === 'folder' && prevProps.projects !== projects) {
			this.setOptions(selectedFilter)
		}

		if (prevProps.projectId !== projectId) {
			this.setOptions(selectedFilter)
		}

		if (prevProps.tasks !== tasks) {
			this.setOptions(selectedFilter)
		}
	}

	parseTags = (tags) => {
		const { tasks } = this.props
		const tagsMap = {}

		if (tasks) {
			tasks.forEach((task) => {
				const tags = task.get('tags', List())
				tags.forEach((tag) => {
					tagsMap[tag] = true
				})
			})
		}

		return tags
			.map((tag) => {
				return Map({
					value: tag.get('id'),
					label: tag.get('name')
				})
			})
			.filter((tag) => {
				return tagsMap[tag.get('value')]
			})
			.sortBy((f) => f.get('label').toLowerCase())
	}

	handleMultipleFolderNames = (options, projects) => {
		let haveMultipleMap = Map()
		options.forEach((option, index) => {
			const label = option.get('label')
			if (haveMultipleMap.get(label)) {
				haveMultipleMap = haveMultipleMap.update(label, (arr) => {
					return arr.push(index)
				})
			} else {
				haveMultipleMap = haveMultipleMap.set(label, List([index]))
			}
		})

		haveMultipleMap.forEach((arr) => {
			if (arr.size > 1) {
				arr.forEach((i) => {
					const option = options.get(i)
					const project = projects.get(option.get('value'))
					const parentId = project.get('parent')
					const parent = projects.get(parentId)
					if (parent) {
						options = options.updateIn([i, 'label'], (label) => {
							return `${label} | ${parent.get('translatedName')}`
						})
					}
				})
			}
		})
		return options
	}

	parseProjects = (projects) => {
		const { mapParentToChildren, projectId } = this.props
		// if selected folder
		const ids = []

		folderHelper.getDescendentsIds(ids, projectId, mapParentToChildren)
		let options = projects
			.toList()
			.map((project) => {
				return Map({
					value: project.get('id'),
					label: project.getIn(['name', 'sv'])
				})
			})
			.filter((project) => {
				return project.get('label') && ids.includes(project.get('value'))
			})
			.sortBy((f) => f.get('label').toLowerCase())
		options = this.handleMultipleFolderNames(options, projects)
		return options
	}

	parseTasks = (tasks) => {
		let tasksSet = Map()
		if (!tasks) {
			return List()
		}

		tasks.forEach((task) => {
			if (task.get('title')) {
				tasksSet = tasksSet.set(task.get('title').trim(), task)
			}
		})

		return tasksSet
			.toList()
			.map((task) => {
				let label = task.get('title')
				if (label === '' || label === ' ') {
					label = 'n/a'
				}
				return Map({
					value: task.get('id'),
					label
				})
			})
			.filter((task) => {
				return task.get('label')
			})
			.sortBy((f) => f.get('label').toLowerCase())
	}

	setOptions = (selectedFilter) => {
		const { tags, projects, tasks } = this.props

		switch (selectedFilter) {
			case 'tag':
				this.setState({ options: this.parseTags(tags) })
				break
			case 'project':
				this.setState({ options: this.parseProjects(projects) })
				break
			case 'task':
				this.setState({ options: this.parseTasks(tasks) })
				break
			default:
		}
	}

	// Triggers when filter type is changed
	onChangeSelectedFilter = (selectedFilter) => {
		// Previously selected filter
		const prevSelectedFilter = this.state.selectedFilter
		let { savedValues, selectedValues } = this.state

		// Save current selection to backup
		savedValues = savedValues.setIn([prevSelectedFilter], selectedValues)

		// Read values from backup if there are any
		selectedValues = savedValues.get(selectedFilter, List())

		// Close menu
		this.moreActionBtnRef && this.moreActionBtnRef.onToggleMenu()

		// Get the new options
		this.setOptions(selectedFilter)

		this.setFilterBy(selectedFilter, selectedValues)

		// Update states
		this.setState({ selectedFilter, savedValues, selectedValues })
	}

	setFilterBy = (selectedFilter, selectedValues) => {
		const { tasks } = this.props

		if (selectedFilter === 'task') {
			let taskMap = Map()
			tasks.forEach((doc) => {
				taskMap = taskMap.set(doc.get('id'), doc)
			})

			selectedValues = selectedValues.map((selectedValue) => {
				return taskMap.getIn([selectedValue, 'title'])
			})
		}

		this.props.setFilterBy(
			Map({
				source: selectedFilter,
				values: selectedValues,
				isSimpleFilter: true
			})
		)
	}

	// Triggers when a new value is added or removed
	onChange = (selectedValues) => {
		const { selectedFilter } = this.state
		this.setState({ selectedValues })

		this.setFilterBy(selectedFilter, selectedValues)
	}

	renderDropdown = () => {
		const { selectedFilter } = this.state

		return (
			<DropdownMenuContainer
				menuIcon='faChevronDown'
				menuNoTop={true}
				buttonMinWidth='24px'
				buttonNoHorizontalPadding={true}
				tooltipTid='tooltip.tasks.filter_by.dropdown'
				ref={(ref) => (this.moreActionBtnRef = ref)}>
				{FILTER_BY_ITEMS.map((item, index) => {
					// Don't render item if it is currently selected
					if (selectedFilter === item) {
						return null
					}

					return (
						<DropdownItem onClick={this.onChangeSelectedFilter.bind(null, item)} key={index}>
							<Text tid='component.filterby.menu.filter_by' />
							&nbsp;
							<Text tid={`component.filterby.item.${item}`} />
						</DropdownItem>
					)
				})}
			</DropdownMenuContainer>
		)
	}

	render = () => {
		const { selectedFilter, selectedValues, options } = this.state
		const { totalTasks, totalTasksShown } = this.props
		return (
			<FilterBy
				dropdownRenderer={this.renderDropdown}
				options={options}
				onChange={this.onChange}
				selectedFilterByItem={selectedFilter}
				value={selectedValues}
				closeMenuOnSelect={true}
				counterLeftValue={totalTasksShown}
				counterRightValue={totalTasks}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		tags: store.tags.getIn(['tags']),
		projects: store.projects.get('projects'),
		tasks: store.tasks.get('allTasks'),
		mapParentToChildren: store.projects.get('mapParentToChildren'),
		projectId: store.projects.get('selectedProjectId')
	}
}

const mapActionsToProps = {
	listTags,
	setFilterBy
}

export default connect(mapStoreToProps, mapActionsToProps)(TasksFilterByContainer)
