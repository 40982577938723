import React, { PureComponent } from 'react'
import { string, func } from 'prop-types'
import styled from 'styled-components'
import Text from '../text/text'

import DropdownItem from '../dropdown-item/dropdown-item'
import StyledStatusRectangle from './styled-status-rectangle'

const StyledDropdownItem = styled(DropdownItem)`
	min-width: 200px;
`

const StyledLeftComponent = styled.div``

const StyledRightComponent = styled.div`
	white-space: nowrap;
	flex: 1;
	text-align: left;
`

export default class StatusDropdownItem extends PureComponent {
	static propTypes = {
		statusLabelTid: string,
		statusColor: string,
		onClick: func
	}

	render = () => {
		const { statusLabelTid, statusColor, onClick } = this.props

		return (
			<StyledDropdownItem onClick={onClick}>
				<StyledLeftComponent>
					<StyledStatusRectangle color={statusColor} />
				</StyledLeftComponent>
				<StyledRightComponent>
					<Text tid={statusLabelTid}></Text>
				</StyledRightComponent>
			</StyledDropdownItem>
		)
	}
}
