import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool } from 'prop-types'
import { Map } from 'immutable'
import { saveMeeting } from '../../../actions/meetings.actions'
import WebLocationPanel from '../../../dumb-components/meetings/location-panels/web'
import EditorContainer from '../../shared/editor.container'

const ADDITIONAL_INFO_OBJ_ID = '$locationWebAdditionalInfo'

class PhysicalContainer extends Component {
	static propTypes = {
		marginBottom: bool,
		withoutPanel: bool,
		readOnly: bool // Overrides old container logic for readOnly IF SPECIFIED
	}

	static defaultProps = {
		marginBottom: false
	}

	onChange = (fieldName, value) => {
		const { saveMeeting } = this.props
		let { meeting } = this.props

		meeting = meeting.setIn(['onlineLocationData', fieldName], value)

		saveMeeting(meeting)
	}

	getIsExpanded = () => {
		const { onlineLocationData } = this.props
		return (onlineLocationData.get('appName', '') || '').trim().length > 0 ? true : false
	}

	renderEditor = () => {
		const { meetingId, onlineLocationData } = this.props

		return (
			<EditorContainer
				fieldName='additionalInfo'
				objId={meetingId + ADDITIONAL_INFO_OBJ_ID}
				contentState={onlineLocationData.get('additionalInfo')}
				onChange={this.onChange}
			/>
		)
	}

	render = () => {
		const { meeting, marginBottom, withoutPanel, onlineLocationData } = this.props
		let { readOnly } = this.props

		// If not readOnly prop was sent in (that's why undefined check),
		// container then uses the legacy logic. This was needed
		// as there might be different logic in standard/smart meeting
		if (readOnly === undefined) {
			readOnly = meeting.getIn(['computedValues', 'isSimpleMode']) || meeting.get('isDeleted')
		}

		return (
			<WebLocationPanel
				appName={onlineLocationData.get('appName')}
				appUrl={onlineLocationData.get('appUrl')}
				appPassword={onlineLocationData.get('appPassword')}
				isExpanded={this.getIsExpanded()}
				onChange={this.onChange}
				renderEditor={this.renderEditor}
				readOnly={readOnly}
				marginBottom={marginBottom}
				withoutPanel={withoutPanel}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get('meeting', Map()) || Map(),
		meetingId: store.meetings.getIn(['meeting', 'id']),
		onlineLocationData: store.meetings.getIn(['meeting', 'onlineLocationData'], Map()) || Map()
	}
}

const mapActionsToProps = {
	saveMeeting
}

export default connect(mapStoreToProps, mapActionsToProps)(PhysicalContainer)
