import React, { Component } from 'react'
import { connect } from 'react-redux'
import immutablePropTypes from 'react-immutable-proptypes'
import { string, bool, object, func, number } from 'prop-types'
import { List } from 'immutable'
import { setRef, getRef, toggleMenu } from '../../../components/helpers/refs.helper'
import { addAttendee, addMultipleAttendees, saveAttendee } from '../../../actions/meetings.actions'

import AttendeesList from '../../../dumb-components/meetings/attendees-list/attendees-list'

import AttendeeItemContainer from './attendee-item.container'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import InviteAttendees from '../meeting/general/dropdown-items/invite-attendees.container'
import SendConfirmation from '../meeting/general/dropdown-items/send-confirmation-to-attendee.container'
import DeleteAttendee from '../meeting/general/dropdown-items/delete-attendee.container'

import AddAttendeesFromPreviousContainer from '../meeting/general/add-attendees-from-previous.container'
import ButtonTransparentIcon from '../../../dumb-components/shared/button/src/button-transparent-icon'
import AddProxyContainer from '../meeting/general/dropdown-items/add-proxy.container'
import DocumentsContainer from '../meeting/general/dropdown-items/documents.container'
import history from '../../../interfaces/history'

import {
	findProxyByAttendeeId,
	getProxyDocumentsDisplayData,
	isAttendeeModerator,
	getChunkOfAttendees
} from '../../../components/helpers/meeting.helper'
import { MEETING_TYPE_STANDARD, MEETING_SUBTYPE_AGM } from '/shared/constants'

const ATTENDEES_RENDER_LIMIT = 5
const ATTENDEES_CHUNK_SIZE = 20

class AttendeesListContainer extends Component {
	state = {
		proxyDocuments: null,
		attendeesRenderLimit: ATTENDEES_RENDER_LIMIT,
		attendees: List(),
		numOfLoads: 0,
		hasMoreAttendees: false
	}

	static propTypes = {
		basePath: string,
		querystr: string,
		params: object,
		attendees: immutablePropTypes.map,
		readOnly: bool,
		permissions: immutablePropTypes.map,
		chairman: string,
		secretary: string,
		clickable: bool,
		showMoreAction: bool,
		renderMoreActionItem: func,
		forceReadOnly: bool,
		forceCanSetStatus: bool,
		forceDisableAttendeeWarning: bool,
		hideInviteAttendee: bool,
		onClickShowMoreAttendees: func,
		usePortalForMoreAction: bool,
		useReactWindow: bool,
		reactWindowHeight: number,
		onSetListRef: func,
		isStatic: bool,
		moderators: immutablePropTypes.list,
		scrollableTarget: string
	}

	static defaultProps = {
		readOnly: false,
		clickable: true,
		showMoreAction: false
	}

	moreActionBtnRefs = {}

	componentDidMount() {
		this.loadAttendees()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { params, numOfAttendees, attendeesHasBeenChanged } = this.props
		const { attendees } = this.state

		if ((!prevState.attendees || prevState.attendees.size === 0) && attendees && params && !params.attendeeId) {
			this.goToFirstAttendee()
		}

		if (prevProps.numOfAttendees !== numOfAttendees || attendeesHasBeenChanged) {
			this.reloadAttendees()
		}
	}

	goToFirstAttendee = () => {
		const { attendees } = this.state

		if (attendees && attendees.size > 0) {
			this.onClick(attendees.first().get('userId'))
		}
	}

	getProxyDocument = (attendeeId) => {
		const { meetingId } = this.props
		const { proxyDocuments } = this.state
		const objId = `${meetingId}$${attendeeId}`

		if (!proxyDocuments) {
			return
		}

		const docId = proxyDocuments.getIn(['mapObjIdToDocId', objId, 0])
		const doc = proxyDocuments.get('documents').find((obj) => obj.get('id') === docId)

		return doc
	}

	loadAttendees = () => {
		const { numOfAttendees } = this.props
		let { numOfLoads, attendees } = this.state

		if (numOfAttendees === 0) {
			return
		}

		setTimeout(() => {
			// Only increment numOfLoads if the number of attendees is equal to ATTENDEES_CHUNK_SIZE
			const start = numOfLoads++ * ATTENDEES_CHUNK_SIZE
			const end = start + ATTENDEES_CHUNK_SIZE
			const newAttendees = getChunkOfAttendees(start, end)

			if (!newAttendees) {
				return
			}

			attendees = attendees.concat(newAttendees)
			const hasMoreAttendees = attendees.size < numOfAttendees
			this.setState({ attendees, numOfLoads, hasMoreAttendees })
		}, 500)
	}

	reloadAttendees = () => {
		const { numOfAttendees } = this.props
		let { numOfLoads, attendees: currentAttendees } = this.state

		if (numOfAttendees === 0 && currentAttendees.size > 0) {
			return this.setState({ attendees: List() })
		}

		setTimeout(() => {
			const start = 0
			const end = start + (numOfLoads > 0 ? numOfLoads : numOfLoads + 1) * ATTENDEES_CHUNK_SIZE
			const attendees = getChunkOfAttendees(0, end)

			if (!attendees) {
				return
			}

			const hasMoreAttendees = attendees.size < numOfAttendees
			this.setState({ attendees, hasMoreAttendees })
		}, 500)
	}

	onClick = (id) => {
		const { basePath, querystr, history } = this.props

		history.push({
			pathname: `${basePath}/${id}`,
			search: querystr
		})
	}

	closeMoreActionsDropdown = (userId) => {
		toggleMenu(this.moreActionBtnRefs[userId])
	}

	onAttendeeChange = (userId, attendee) => {
		const { saveAttendee } = this.props

		saveAttendee(userId, attendee)
	}

	getAttendeeProxy = (userId) => {
		const { proxies } = this.props

		if (!proxies) {
			return
		}

		const proxy = findProxyByAttendeeId(proxies, userId)

		return proxy
	}

	isAttendeeModerator = (userId) => {
		const { moderators } = this.props

		return isAttendeeModerator(userId, moderators)
	}

	toggleShowAllAttendees = () => {
		const { numOfAttendees } = this.props
		const { attendeesRenderLimit } = this.state
		let { attendees } = this.state

		if (attendeesRenderLimit !== ATTENDEES_RENDER_LIMIT) {
			attendees = attendees.slice(0, ATTENDEES_CHUNK_SIZE)
			const hasMoreAttendees = attendees.size < numOfAttendees

			this.setState({
				attendeesRenderLimit: ATTENDEES_RENDER_LIMIT,
				numOfLoads: 1,
				attendees,
				hasMoreAttendees
			})
		} else {
			this.setState({ attendeesRenderLimit: 0 })
		}
	}

	renderMoreActionButton = (userId) => {
		const {
			readOnly,
			renderMoreActionItem,
			forceReadOnly,
			hideInviteAttendee,
			usePortalForMoreAction,
			isAGM
		} = this.props
		const { attendees } = this.state
		const proxy = this.getAttendeeProxy(userId)
		const isAttendeeModerator = this.isAttendeeModerator(userId)

		const { badgeColor, badgeVisible } = getProxyDocumentsDisplayData({
			proxy,
			userId,
			attendees,
			isAGM,
			hideBadgeIfDefault: true
		})

		return (
			<DropdownMenuContainer
				ref={setRef.bind(this, this.moreActionBtnRefs, userId)}
				halignMenu='right'
				withPortal={usePortalForMoreAction}
				noMaxWidth
				buttonNoHorizontalPadding
				inline
				renderRaw={
					<ButtonTransparentIcon
						icon='faEllipsisV'
						size='sml'
						notificationBadge={badgeVisible}
						notificationBadgeColor={badgeColor}
						notificationBadgeAnimate={false}
						disabled={forceReadOnly || readOnly}
						x={1}
					/>
				}>
				{!hideInviteAttendee && (
					<InviteAttendees
						type='dropdown'
						dropdownRef={getRef(this.moreActionBtnRefs, userId)}
						mode='SINGLE'
						userId={userId}
					/>
				)}

				<SendConfirmation userId={userId} onClick={this.closeMoreActionsDropdown} />

				{renderMoreActionItem && renderMoreActionItem(userId, getRef(this.moreActionBtnRefs, userId))}

				{isAGM && (
					<AddProxyContainer
						dropdownRef={getRef(this.moreActionBtnRefs, userId)}
						userId={userId}
						attendees={attendees}
						disabled={isAttendeeModerator}
					/>
				)}
				<DocumentsContainer dropdownRef={getRef(this.moreActionBtnRefs, userId)} userId={userId} />

				<DeleteAttendee userId={userId} onCloseMoreActionDropdown={this.closeMoreActionsDropdown} />
			</DropdownMenuContainer>
		)
	}

	renderAttendeeItem = (attendeeProps) => {
		const {
			readOnly,
			chairman,
			secretary,
			attendeesWarning,
			userId,
			hasExtendedRights,
			clickable,
			showMoreAction,
			forceReadOnly,
			forceCanSetStatus,
			isStandardMeeting,
			forceDisableAttendeeWarning,
			isStatic,
			moderators
		} = this.props
		const { attendees } = this.state
		const proxyDocument = this.getProxyDocument(attendeeProps.userId)
		const canSetStatus =
			forceCanSetStatus || attendeeProps.userId === userId || (!isStandardMeeting && hasExtendedRights)
		const warning = !forceDisableAttendeeWarning && (attendeesWarning || !attendeeProps.notified)
		const hasResponded = attendees && attendees.getIn([attendeeProps.index, 'responseStatus']) ? true : false


		return (
			<AttendeeItemContainer
				{...attendeeProps}
				chairman={chairman}
				secretary={secretary}
				canSetStatus={canSetStatus}
				readOnly={forceReadOnly || readOnly}
				forceReadOnly={forceReadOnly}
				onClick={clickable ? this.onClick : undefined}
				onChange={this.onAttendeeChange}
				attendee={attendees.get(attendeeProps.index)}
				assistantForAttendee={attendees.get(attendeeProps.assistantForAttendee)}
				attendeesWarning={warning}
				hasExtendedRights={hasExtendedRights}
				renderMoreActionButton={showMoreAction && this.renderMoreActionButton}
				context='attendees'
				proxyDocument={proxyDocument}
				isStandardMeeting={isStandardMeeting}
				isStatic={attendeeProps.isStatic || isStatic}
				moderators={moderators}
				hasResponded={hasResponded}
			/>
		)
	}

	renderAddPreviousAttendeesComponent = () => {
		return <AddAttendeesFromPreviousContainer />
	}

	render() {
		const {
			basePath,
			params,
			permissions,
			proxies,
			clickable,
			useReactWindow,
			reactWindowHeight,
			onSetListRef,
			scrollableTarget
		} = this.props
		const { attendeesRenderLimit, hasMoreAttendees, attendees } = this.state

		return (
			<AttendeesList
				attendees={attendees}
				permissions={permissions}
				renderItemComponent={this.renderAttendeeItem}
				basePath={basePath}
				clickable={clickable}
				currentUrl={basePath && params ? `${basePath}/${params.attendeeId}` : undefined}
				addPreviousAttendeesComponent={this.renderAddPreviousAttendeesComponent()}
				proxies={proxies}
				attendeesRenderLimit={attendeesRenderLimit}
				onToggleShowAllAttendees={this.toggleShowAllAttendees}
				useReactWindow={useReactWindow}
				reactWindowHeight={reactWindowHeight}
				onSetListRef={onSetListRef}
				loadAttendees={this.loadAttendees}
				hasMoreAttendees={hasMoreAttendees}
				scrollableTarget={scrollableTarget}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	const meetingId = store.meetings.getIn(['meeting', 'id'])

	return {
		history: history,
		userId: store.user.getIn(['userObj', 'id']),
		hasExtendedRights: store.meetings.getIn(['meeting', 'computedValues', 'hasExtendedRights']),
		isStandardMeeting: store.meetings.getIn(['meeting', 'meetingType']) === MEETING_TYPE_STANDARD,
		meetingId,
		companyId: store.company.company.id,
		attendeesWarning: store.meetings.getIn(['meeting', 'computedValues', 'attendeesWarning']),
		votingList: store.votings.get('list'),
		proxies: store.meetings.getIn(['meeting', 'proxies']),
		isAGM: store.meetings.getIn(['meeting', 'meetingSubType']) === MEETING_SUBTYPE_AGM,
		numOfAttendees: store.meetings.getIn(['meeting', 'attendees'])?.size || 0,
		attendeesHasBeenChanged: meetingId ? store.meetings.getIn(['attendeesHasBeenChanged', meetingId]) : false
	}
}

const mapActionsToProps = {
	addAttendee,
	addMultipleAttendees,
	saveAttendee
}

export default connect(mapStoreToProps, mapActionsToProps)(AttendeesListContainer)
