/**
 * Information Block Class
 * @module components/framework/information-block
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { saveUser } from '../../actions/user.actions'

/** Class representing the information block component */
class InformationBlock extends Component {
	/**
	 * Constructor
	 */
	constructor(props) {
		super(props)

		this.state = { visible: true, dismissable: false }
	}

	componentDidMount() {
		if (
			this.props.user &&
			this.props.user.userObj &&
			this.props.user.userObj.siteSettings &&
			this.props.user.userObj.siteSettings.dismissedBlocks &&
			this.props.id
		) {
			if (this.props.user.userObj.siteSettings.dismissedBlocks.indexOf(this.props.id) !== -1) {
				this.setState({ visible: false })
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.user &&
			prevProps.user.userObj &&
			prevProps.user.userObj.siteSettings &&
			prevProps.user.userObj.siteSettings.dismissedBlocks &&
			this.props.id &&
			prevProps.id !== this.props.id
		) {
			if (prevProps.user.userObj.siteSettings.dismissedBlocks.indexOf(this.props.id) === -1) {
				this.setState({ visible: true })
			} else {
				this.setState({ visible: false })
			}
		}

		if (
			this.props.user &&
			this.props.user.userObj &&
			this.props.user.userObj.siteSettings &&
			this.props.user.userObj.siteSettings.dismissedBlocks &&
			prevProps.id &&
			this.props.user.userObj !== prevProps.user.userObj
		) {
			if (this.props.user.userObj.siteSettings.dismissedBlocks.indexOf(prevProps.id) !== -1) {
				this.setState({ visible: false })
			}
		}
	}

	/**
	 * Remove info block on click
	 */
	close() {
		this.setState({ visible: false })
		if (this.props.id) {
			let siteSettings = { ...this.props.user.userObj.siteSettings }
			if (!siteSettings.dismissedBlocks) {
				siteSettings.dismissedBlocks = []
			}
			siteSettings.dismissedBlocks.push(this.props.id)
			this.props.saveUser(this.props.user.userObj.id, { siteSettings })
		}
	}

	/**
	 * Render
	 */
	render() {
		if (!this.props.user.userObj) {
			return null
		}

		if (this.state.visible === true) {
			const dismissable = this.props.dismissable || this.state.dismissable
			const attachedCss = this.props.cssClass ? ` ${this.props.cssClass}` : ''
			const extendedCss = dismissable ? ` border ${attachedCss}` : attachedCss
			let blockCssClass = ''
			let bodyCss = ''
			if (this.props.flex) {
				blockCssClass = `i-panel  ${extendedCss}`
				bodyCss = 'i-panel__body'

				return (
					<div className={blockCssClass}>
						<div className={bodyCss}>
							<div>
								{dismissable ? (
									<button className='close close-icon-with-text' onClick={this.close.bind(this)}>
										<span>
											<FormattedMessage id='remove' />
										</span>
										<i className='pci-cross pci-circle'></i>
									</button>
								) : null}
							</div>
							{this.props.children || null}
						</div>
					</div>
				)
			} else {
				blockCssClass = `panel panel-colorful panel-blue${extendedCss}`
				bodyCss = 'panel-body'

				return (
					<div className={blockCssClass}>
						<div className={bodyCss}>
							<div>
								{dismissable ? (
									<button className='close close-icon-with-text' onClick={this.close.bind(this)}>
										<span>
											<FormattedMessage id='remove' />
										</span>
										<i className='pci-cross pci-circle'></i>
									</button>
								) : null}
							</div>
							{this.props.children || null}
						</div>
					</div>
				)
			}
		} else {
			return null
		}
	}
}

/** Map state to props. */
function mapStateToProps(state) {
	return {
		user: state.user.toJS()
	}
}

const mapActionsToProps = {
	saveUser
}

/** Export */
InformationBlock = connect(mapStateToProps, mapActionsToProps)(InformationBlock)
export default InformationBlock
