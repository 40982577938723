import React from 'react'
import MUITreeView from '@mui/lab/TreeView'
import MUITreeItem from '@mui/lab/TreeItem'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FolderIcon from '@mui/icons-material/Folder'
import { useTranslation } from 'react-i18next'

const TargetFolderTreeView = ({ folders, onSelectFolder }) => {
	const { t } = useTranslation()
	const renderNode = (root) => {
		if (!root) return null
		const children = root.get('children')
		const name = root.get('name')
		const id = root.get('id') || root.get('absolutePath') || 'root'
		const isRoot = id === 'root' || root.get('isRoot')

		return (
			<MUITreeItem
				key={id}
				nodeId={id}
				label={
					<Box sx={{ display: 'flex', alignItems: 'center', py: 1, pl: 0.5, pr: 0 }}>
						<Box sx={{ display: 'flex', alignItems: 'center', fontSize: '48px' }}>
							<FolderIcon fontSize={'inherit'} sx={{ mr: 1 }} />
						</Box>
						<Typography variant={'body2'} sx={{ flex: 'auto' }}>
							{isRoot ? t(name) : name}
						</Typography>
					</Box>
				}>
				{!!children.size && children.map(renderNode)}
			</MUITreeItem>
		)
	}

	return (
		<MUITreeView
			aria-label='target folder selector'
			defaultCollapseIcon={<ExpandMoreIcon />}
			defaultExpandIcon={<ChevronRightIcon />}
			defaultExpanded={['root']}
			sx={{ overflowY: 'auto', minHeight: 300 }}
			onNodeSelect={(_, nodeIds) => onSelectFolder(nodeIds)}>
			{renderNode(folders)}
		</MUITreeView>
	)
}
export default TargetFolderTreeView
