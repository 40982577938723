import React, { Component } from 'react'
import { connect } from 'react-redux'
import immutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { string, bool, func, number } from 'prop-types'
import { Padding } from 'styled-components-spacing'
import { createAttendeeObject, normalizeAttendeeObject } from '../../../components/helpers/meeting.helper.pure'
import { fetchAndCacheInvestors } from '../../../actions/investors.actions'
import Panel from '../../../dumb-components/shared/panel/panel'
import AttendeesList from '../../../dumb-components/meetings/attendees-list/attendees-list'
import AttendeeItemContainer from '../attendees/attendee-item.container'
import AttendeeSelectContainer from '../attendees/attendee-select.container'
import DropdownMenuContainer from '../../shared/dropdown-menu.container'
import DropdownMenuItem from '../../../dumb-components/shared/dropdown-item/dropdown-item'
import Text from '../../../dumb-components/shared/text/text'

class AttendeePanelContainer extends Component {
	static propTypes = {
		attendees: immutablePropTypes.map,
		readOnly: bool,
		groupId: string,
		permissions: immutablePropTypes.map,
		chairman: string,
		secretary: string,
		onChange: func,
		showStatusIndicator: bool,
		showToggleSwitch: bool,
		panelTitle: string,
		attendeesRenderLimit: number
	}

	static defaultProps = {
		showStatusIndicator: true,
		showToggleSwitch: false,
		attendees: Map()
	}

	moreActionButtonRefs = {}

	componentDidMount() {
		const { attendees } = this.props

		if (attendees) {
			this.fetchInvestors()
		}
	}

	fetchInvestors = () => {
		const { fetchAndCacheInvestors, attendees } = this.props
		const investmentIds = attendees?.filter((a) => !a.get('isGuest'))?.map((a) => a.get('userId'))

		if (investmentIds) {
			fetchAndCacheInvestors(investmentIds)
		}
	}

	onAddAttendee = (attendeeId) => {
		const { onChange } = this.props
		let { attendees } = this.props

		attendees = attendees.set(attendeeId, createAttendeeObject(attendeeId))

		onChange('attendees', attendees)
	}

	onAddMultipleAttendees = (attendees) => {
		const { company, onChange } = this.props

		// Put all guests in its own list
		let guests = attendees.filter((attendee) => attendee.get('isGuest') === true)

		// Put all users in its own list
		const users = attendees.filter(
			(attendee) => !attendee.has('isGuest') || !attendee.get('isGuest') || attendee.get('isGuest') === false
		)

		// Remove the empty external object. It is only there because it is needed in the modal to create an empty row in the external invites of the select user modal.
		guests = guests.filter((guest) => guest.get('name') && guest.get('email'))

		attendees = users.concat(guests)

		attendees = attendees.map((attendee, userId) => {
			return normalizeAttendeeObject(userId, attendee, company)
		})

		onChange('attendees', attendees)
	}

	onAttendeeChange = (attendeeId, attendee) => {
		const { onChange } = this.props
		let { attendees } = this.props

		attendees = attendees.map((obj, id) => {
			if (id === attendeeId) {
				return attendee
			}

			return obj
		})

		onChange('attendees', attendees)
	}

	onRemoveAttendee = (attendeeId) => {
		const { onChange } = this.props
		let { attendees } = this.props

		attendees = attendees.filter((obj, id) => id !== attendeeId)

		onChange('attendees', attendees)
	}

	renderAttendeeItem = (attendeeItemProps) => {
		const { readOnly, chairman, secretary, attendees, showStatusIndicator, showToggleSwitch } = this.props

		return (
			<AttendeeItemContainer
				{...attendeeItemProps}
				chairman={chairman}
				secretary={secretary}
				readOnly={readOnly}
				onClick={this.onClick}
				onChange={this.onAttendeeChange}
				attendee={attendees.get(attendeeItemProps.userId)}
				assistantForAttendee={attendees.get(attendeeItemProps.assistantForAttendee)}
				renderMoreActionButton={this.renderMoreActionButton}
				showWarning={false}
				showStatusIndicator={showStatusIndicator}
				showToggleSwitch={showToggleSwitch}
				context='attendees'
			/>
		)
	}

	renderAddAttendeeComponent = () => {
		const { groupId, permissions, attendees, showStatusIndicator } = this.props

		return (
			<AttendeeSelectContainer
				groupId={groupId}
				permissions={permissions}
				values={attendees}
				onSelectAttendee={this.onAddAttendee}
				onSelectMultipleAttendees={this.onAddMultipleAttendees}
				showStatusIndicator={showStatusIndicator}
			/>
		)
	}

	renderMoreActionButton = (attendeeId) => {
		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisV'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				tooltipActiveState='btnMoreActions'
				ref={(ref) => (this.moreActionButtonRefs[attendeeId] = ref)}>
				<DropdownMenuItem onClick={() => this.onRemoveAttendee(attendeeId)}>
					<Text tid='meeting.schedule_next_meeting.attendees.remove' />
				</DropdownMenuItem>
			</DropdownMenuContainer>
		)
	}

	render() {
		const { chairman, secretary, investors, panelTitle, readOnly, attendeesRenderLimit } = this.props
		const attendees =
			this.props.attendees &&
			this.props.attendees.sortBy((item) => {
				if (item.get('userId') === chairman) {
					return -3
				} else if (item.get('userId') === secretary) {
					return -2
				}
				return 0
			})

		return (
			<Panel title={panelTitle} marginBottom>
				<Padding vertical={4} horizontal={4}>
					<AttendeesList
						attendees={attendees?.toList()}
						investors={investors}
						renderItemComponent={this.renderAttendeeItem}
						renderAddAttendeeComponent={!readOnly && this.renderAddAttendeeComponent}
						attendeesRenderLimit={attendeesRenderLimit}
					/>
				</Padding>
			</Panel>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		investors: store.investors.get('investorsCache'),
		company: store.company.company
	}
}

const mapActionsToProps = {
	fetchAndCacheInvestors
}

export default connect(mapStoreToProps, mapActionsToProps)(AttendeePanelContainer)
