import req from '../modules/request.module'
import { MENU_FETCH, MENU_ERROR } from './types'

/**
 * Action for fetching a menu
 */
export function fetchMenu(menu) {
	return function (dispatch) {
		req
			.get(`/resources/menu/${menu}`)
			.then((response) => {
				dispatch({ type: MENU_FETCH, payload: response.data })
			})
			.catch((err) => dispatch(menuError(err.response)))
	}
}

/**
 * Action for dispatching an menu error
 */
export function menuError(error) {
	return {
		type: MENU_ERROR,
		payload: error
	}
}
