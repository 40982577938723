import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getCompanyLogoUrl } from '../../../../../components/helpers/company.helper'
import { Box } from '@mui/material'
import Button from '../../../../../mui-components/button/button'
import { func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FormattedHTMLMessage } from 'react-intl'
import { LogoModal } from './LogoModal'

const LogoSelector = ({ value, onChange }) => {
	const [modalOpen, setModalOpen] = useState(false)
	const { t } = useTranslation()
	const company = useSelector((state) => state.company.company)
	const currentLogoUrl = value && getCompanyLogoUrl(company.id, value, 100)

	if (!company.image) {
		return <FormattedHTMLMessage id='advertising.administration.no_logo_text' />
	}

	return (
		<Box>
			{currentLogoUrl ? (
				<img
					src={currentLogoUrl}
					alt={company.name}
					style={{
						maxHeight: '75px',
						width: 'auto',
						height: 'unset',
						maxWidth: 'unset',
						display: 'inline-block',
						marginRight: '16px'
					}}
				/>
			) : null}
			{Object.keys(company.image).length > 1 && (
				<Button variant='default' onClick={() => setModalOpen(true)}>
					{t(value ? 'logo_selector.change_logo' : 'logo_selector.select_logo')}
				</Button>
			)}
			{modalOpen && (
				<LogoModal
					open={modalOpen}
					value={value}
					onChange={(selectedLogotype) => {
						onChange(selectedLogotype)
						setModalOpen(false)
					}}
					onClose={() => setModalOpen(false)}
				/>
			)}
		</Box>
	)
}

LogoSelector.propTypes = {
	value: string,
	onChange: func.isRequired
}

export { LogoSelector }
