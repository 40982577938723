import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { string } from 'prop-types'
import moment from '../../../../modules/moment.module'

import Box from '../../../../dumb-components/shared/layout/box/box'
import MeetingStandardGeneral from '../../../../dumb-components/meetings/meeting-standard/meeting-standard-general'
import AttendeesPanelStandardContainer from '../standard/attendees-panel-standard.container'
import EditorContainer from '../../../shared/editor.container'
import AttachmentsContainer from '../standard/attachements.container'
import LocationsContainer from '../../location-panels/locations.container'
import VotingPanelContainer from '../../voting/voting-panel.container'
import OnbAlertCreateAgmHowTo from '../../../notifications/onboarding/alerts/meetings/create-agm-how-to'
import TagsSelectContainer from '../../../shared/tags-select.container'
import TaskPluginContainer from '../../../tasks/task-plugin.container'
import SimpleDarkInfoPanel from '../../../../dumb-components/shared/dark-info-panel/simple-dark-info-panel'
import { Padding } from 'styled-components-spacing'
import MeetingStandardPanel from '../../../../dumb-components/meetings/meeting-standard-panel/meeting-standard-panel'
import StartStandardConfirmModalContainer from './start-standard-confirm-modal.container'
import ManageProxyDocumentsModalContainer from '../agm/manage-proxy-documents-modal.container'
import SelectProxyModalContainer from '../agm/select-proxy-modal.container'
import ConfirmModal from '../../../shared/confirm.container'
import { patchMeetingLocal } from '../../../../actions/meetings.actions'
import { closeModal } from '../../../../actions/modals.actions'
import AttendeesConfirmInviteModal from '../../../../dumb-components/meetings/attendees-confirm-invite/attendees-confirm-invite'
import { ATTENDEES_CONFIRM_INVITE_MODAL } from '../../../../constants/modals'

import {
	MEETING_SUBTYPE_AGM,
	OBJ_TYPE_MEETING,
	MEETING_SUBTYPE_DEFAULT,
	__DELETE__,
	OBJ_TYPE_MEETING_PROTOCOL
} from '/shared/constants'
import { MEETING_GROUPS_ID } from '../../../../constants/meetings'

class MeetingStandardContainer extends Component {
	static propTypes = {
		scrollableTarget: string
	}

	state = {
		activeTab: 0, // 0 = general information, 1 = location
		meetingTypeConfirmModalIsOpen: false
	}

	onTabChange = (path) => {
		this.setState(() => {
			return { activeTab: path }
		})
	}

	componentDidMount = () => {
		hj && hj('trigger', 'poll-standard-meeting')
		hj && hj('trigger', 'heatmap-standard-meeting')
	}

	onChange = (field, value) => {
		const { updateMeetingLocal } = this.props
		let { meeting } = this.props

		// Start Date was changed, check so that the endDate is not earlier or same than startDate
		if (field === 'startDate' && value) {
			const startDate = moment(value)
			let endDate = meeting.get('endDate') && moment(meeting.get('endDate'))

			if (endDate) {
				endDate = endDate.set({
					year: startDate.year(),
					month: startDate.month(),
					date: startDate.date()
				})

				meeting = meeting.set('endDate', endDate.toISOString())
			}

			// endDate comes before or at startDate. Set it to meetingStartDate + 1h
			if (startDate >= endDate || !endDate) {
				endDate = startDate.add(1, 'hours')
				meeting = meeting.set('endDate', endDate.toISOString())
			}
		}

		// End Date was changed, replace only the time values
		if (field === 'endDate' && value) {
			const valueDate = moment(value)
			let endDate = moment(meeting.get('endDate') || meeting.get('startDate'))

			endDate = endDate.set({
				hour: valueDate.hour(),
				minute: valueDate.minute()
			})

			value = endDate.toISOString()
		}

		if (field === 'meetingSubType' && meeting.get('meetingSubType') === MEETING_SUBTYPE_AGM) {
			this.setState({ meetingTypeConfirmModalIsOpen: true })
			return
		}

		meeting = meeting.set(field, value)
		updateMeetingLocal(meeting)
	}

	onUpload = (objType, doc) => {
		const { patchMeetingLocal, meeting } = this.props
		let patchData = Map()

		if (objType === OBJ_TYPE_MEETING_PROTOCOL) {
			patchData = patchData.set(
				'protocolData',
				Map({
					documentId: doc.get('id'),
					ext: doc.getIn(['file', 'ext'])
				})
			)
		}

		const counter = meeting.getIn(['metadata', 'uploadedDocumentTypes', objType])
		patchData = patchData.setIn(['metadata', 'uploadedDocumentTypes', objType], counter !== undefined ? counter + 1 : 1)
		patchMeetingLocal(patchData)
	}

	onDelete = (objType) => {
		const { patchMeetingLocal } = this.props
		let { meeting } = this.props
		let patchData = Map()
		const counter = meeting.getIn(['metadata', 'uploadedDocumentTypes', objType])
		patchData = patchData.setIn(
			['metadata', 'uploadedDocumentTypes', objType],
			counter !== undefined && counter > 0 ? counter - 1 : 0
		)

		if (objType === OBJ_TYPE_MEETING_PROTOCOL) {
			patchData = patchData.setIn(['metadata', 'protocolSharedAt'], __DELETE__)

			patchData = patchData.set('protocolData', __DELETE__)

			if (meeting.has('attendees')) {
				meeting = meeting.get('attendees').forEach((attendees, attendeeId) => {
					patchData = patchData.setIn(['attendees', attendeeId, 'protocolSharedAt'], __DELETE__)
				})
			}
		}
		patchMeetingLocal(patchData)
	}

	changeMeetingType = () => {
		const { updateMeetingLocal } = this.props
		let { meeting } = this.props

		meeting = meeting.set('meetingSubType', MEETING_SUBTYPE_DEFAULT)
		updateMeetingLocal(meeting)

		this.setState({ meetingTypeConfirmModalIsOpen: false })
	}

	closeModal = () => {
		this.setState({ meetingTypeConfirmModalIsOpen: false })
	}

	confirmInviteModal = () => {
		const { modalOptions, closeModal } = this.props
		const func = modalOptions?.getIn(['action', 'func'])
		const arg = modalOptions?.getIn(['action', 'arg'])
		func(...arg.toJS())
		closeModal()
	}

	renderEditorContainer = (fieldName) => {
		const { meeting } = this.props

		return (
			<EditorContainer
				fieldName={fieldName}
				objId={meeting.get('id')}
				contentState={meeting.get(fieldName)}
				onChange={this.onChange}
				readOnly={false}
				placeholderTid='meetings.general_information.notes.placeholder'
			/>
		)
	}

	renderFirstTabContent = () => {
		const { protocolSharedAt, isAGM, meeting, scrollableTarget } = this.props
		const groupId = meeting.get('groupId')

		return (
			<>
				<AttachmentsContainer onUpload={this.onUpload} onDelete={this.onDelete} protocolSharedAt={protocolSharedAt} />

				<AttendeesPanelStandardContainer key={isAGM} scrollableTarget={scrollableTarget} />

				{isAGM && <VotingPanelContainer key={meeting.get('moderators')} />}

				<TaskPluginContainer
					objType={OBJ_TYPE_MEETING}
					projectId={groupId ? groupId : MEETING_GROUPS_ID}
					objId={meeting.get('id')}
					marginBottom={true}
				/>
			</>
		)
	}

	renderSecondTabContent = () => {
		const { meetingIsDeleted } = this.props
		return <LocationsContainer readOnly={meetingIsDeleted} marginBottom={true} />
	}

	renderTagsContainer = () => {
		const { meeting } = this.props

		return (
			<TagsSelectContainer
				onTagsChange={(tags) => this.onChange('tags', tags)}
				value={meeting && meeting.get('tags')}
			/>
		)
	}

	render = () => {
		const {
			meeting,
			userId,
			i18n,
			groups,
			company,
			renderMode,
			meetingIsDeleted,
			readOnly,
			activeModal,
			modalOptions,
			closeModal
		} = this.props
		const { activeTab, meetingTypeConfirmModalIsOpen } = this.state
		const group = meeting && groups && groups.has(meeting.get('groupId')) ? groups.get(meeting.get('groupId')) : null
		const missingFields = modalOptions?.get('missingFields') || List()

		if (readOnly) {
			return <MeetingStandardPanel />
		}
		return (
			<>
				<Box direction='column'>
					<MeetingStandardGeneral
						meeting={meeting}
						userId={userId}
						onChange={this.onChange}
						readOnly={meetingIsDeleted}
						renderEditorContainer={this.renderEditorContainer}
						group={group}
						userLang={i18n.language}
						region={company.region}
						renderMode={renderMode}
						meetingIsDeleted={meetingIsDeleted}
						onClickTab={this.onTabChange}
						generalInfoAlert={<OnbAlertCreateAgmHowTo />}
						renderTagsContainer={this.renderTagsContainer}
					/>

					{activeTab === 0 && this.renderFirstTabContent()}
					{activeTab === 1 && this.renderSecondTabContent()}
				</Box>
				{meetingTypeConfirmModalIsOpen && (
					<ConfirmModal
						isOpen={true}
						title='meetings.standard.change_meeting_type.confirm.title'
						question='meetings.standard.change_meeting_type.confirm.question'
						onConfirm={this.changeMeetingType}
						onDecline={this.closeModal}
					/>
				)}

				<StartStandardConfirmModalContainer />
				<ManageProxyDocumentsModalContainer />
				<SelectProxyModalContainer />
				<AttendeesConfirmInviteModal
					onConfirm={this.confirmInviteModal}
					onDecline={closeModal}
					missingFields={missingFields}
					isOpen={activeModal === ATTENDEES_CONFIRM_INVITE_MODAL}
				/>
			</>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meeting: store.meetings.get('meeting'),
		userId: store.user.getIn(['userObj', 'id']),
		groups: store.groups.get('groups'),
		i18n: store.i18n,
		company: store.company.company,
		meetingIsDeleted: store.meetings.getIn(['meeting', 'isDeleted']),
		protocolSharedAt: store.meetings.getIn(['meeting', 'metadata', 'protocolSharedAt']),
		isAGM: store.meetings.getIn(['meeting', 'meetingSubType']) === MEETING_SUBTYPE_AGM,
		readOnly: store.meetings.getIn(['meeting', 'computedValues', 'isSimpleMode'], false),
		activeModal: store.modals.getIn(['activeModal', 'name']),
		modalOptions: store.modals.getIn(['activeModal', 'options'])
	}
}

const mapActionsToProps = {
	patchMeetingLocal,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingStandardContainer)
