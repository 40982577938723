import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Steg0Svg from '../../../../assets/images/emissions/Steg0.svg'
import Steg1Svg from '../../../../assets/images/emissions/Steg1.svg'
import Steg2Svg from '../../../../assets/images/emissions/Steg2.svg'
import Steg3Svg from '../../../../assets/images/emissions/steg3.svg'
import DelstängSvg from '../../../../assets/images/emissions/Delstng_stng.svg'
import Typography from '@mui/material/Typography'
import RectangleRoundedIcon from '@mui/icons-material/RectangleRounded'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import WhiteSection from '../../common/white-section'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import { Link, useLocation } from 'react-router-dom'
import { addErrorNotification, addInfoNotification } from '../../../../actions/notify.actions'
import {
	deleteSettlementNotesForClosure,
	sendSettlementNotes,
	updateShareRegister
} from '../../../../actions/emissions.actions'
import { PaymentFormComponent } from './payment-components'
import Chip from '@mui/material/Chip'
import AutoSave from '../../forms/AutoSave'
import emissions from '../../../../services/emissions'
import { emissionConsts } from '../../../../constants/emissions'
import RichTextEditor from '../../../../dumb-components/shared/editorv2/rich-text-editor'

const Pages = (props) => {
	const emission = useSelector((state) => state.emissions.current)
	const shareRegisterUpdated =
		emission.closures && emission.closures.length > 0
			? emission.closures[emission.closures.length - 1].shareRegisterUpdated
			: false
	const settlementNotesGenerated =
		emission.closures && emission.closures.length > 0
			? emission.closures[emission.closures.length - 1].settlementNotesGenerated
			: false
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useLocation()
	const [validForm, setValidForm] = useState(false)
	const [validSubject, setValidSubject] = useState(false)
	const language = useSelector((state) => state.i18n.language)
	const [message, setMessage] = useState('')
	const [template, setTemplate] = useState('')
	const [subject, setSubject] = useState('')

	useEffect(() => {
		const debounce = setTimeout(() => {
			const f = async () => {
				const response = await emissions.getInformTemplate(
					emission.companyId,
					emission.id,
					emissionConsts.templates.settlementNote,
					language
				)
				setTemplate(response.template)
			}
			f()
		}, 0)
		return () => clearTimeout(debounce)
	}, [])

	async function validate() {
		const error = {}
		if (subject.length === 0) {
			error.subject = t('emissions.payment.subject-error')
			setValidSubject(false)
		} else {
			setValidSubject(true)
		}
		return error
	}

	const handleClick_updateShareRegister = async () => {
		dispatch(updateShareRegister(emission.companyId, emission.id))
		await dispatch(addInfoNotification({ text: t('emissions.payment.updated-share-register-header') }))
		props.page()
	}

	const handleClick_ResetSettlementNotes = async () => {
		if (validForm) {
			dispatch(
				deleteSettlementNotesForClosure(
					emission.companyId,
					emission.id,
					emission.closures[emission.closures.length - 1].id
				)
			)
			await dispatch(addInfoNotification({ text: t('emissions.payment.settlement-notes-created') }))
			props.page()
		} else {
			await dispatch(
				addErrorNotification({
					text: t('emissions.payment.payment-create-settlement-notes-error.notification')
				})
			)
		}
	}

	const handleMessageChange = (event) => {
		setMessage(event)
	}

	const handleSubjectChange = (event) => {
		setSubject(event.target.value)
	}

	const handleClick_sendSettlementNotes = async () => {
		if (validSubject) {
			await dispatch(
				sendSettlementNotes(
					emission.companyId,
					emission.id,
					emission.closures[emission.closures.length - 1].id,
					subject,
					message
				)
			)
			await dispatch(addInfoNotification({ text: t('emissions.payment.settlement-notes-sent') }))
			props.page()
		} else {
			await dispatch(
				addErrorNotification({
					text: t('emissions.payment.payment-send-settlement-notes-error.notification')
				})
			)
		}
	}

	return (
		<WhiteSection sx={{ display: 'flex', height: '70rem', overflowY: props.scroll }}>
			{props.back && <ArrowBackIosRoundedIcon sx={{ display: 'flex', cursor: 'pointer' }} onClick={props.back} />}
			<Grid container sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
				<Box sx={{ display: 'flex' }}>
					{!props.startPage ? (
						<Grid item xs={4}>
							<Box sx={{ mt: '78px', display: 'flex', pl: 3, mr: 3 }}>
								{props.firstDecisionPage && <img src={Steg1Svg} style={{ height: '300px' }} />}
								{props.secondDecisionPage && <img src={Steg2Svg} style={{ height: '300px' }} />}
								{props.thirdDecisionPage && <img src={Steg3Svg} style={{ height: '300px' }} />}
							</Box>
						</Grid>
					) : (
						<Grid item xs={5}>
							<Box sx={{ display: 'flex', pl: 10 }}>
								{props.payment && <img src={Steg1Svg} style={{ height: '300px' }} />}
								{(props.decision || props.noShareReg) && (
									<img src={Steg0Svg} style={{ height: '300px' }} />
								)}
								{(props.noClosure || props.completed) && (
									<img src={DelstängSvg} style={{ height: '300px' }} />
								)}
							</Box>
						</Grid>
					)}
					{props.startPage ? (
						<Grid
							item
							xs={6}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center'
							}}>
							{props.decision ||
							props.payment ||
							props.noClosure ||
							props.noShareReg ||
							props.completed ? (
								<>
									<Typography variant={'h4'}>{props.label}</Typography>
									<Typography variant={'body2'} sx={{ my: 3, color: 'grey.800' }}>
										{props.desc}
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'grey.800' }}>
										{props.desc2}
									</Typography>
								</>
							) : (
								<Typography variant={'body2'} sx={{ my: 3, color: 'grey.800' }}>
									{props.desc}
								</Typography>
							)}
						</Grid>
					) : (
						<Grid sx={{ display: 'flex' }} item xs={7}>
							{props.firstDecisionPage && (
								<Box
									sx={{
										flexDirection: 'column',
										display: 'flex',
										alignItems: 'flex-start',
										justifyContent: 'center',
										height: '100%'
									}}>
									{props.firstDecisionPage && shareRegisterUpdated && (
										<Chip
											icon={<CheckIcon />}
											label={t('meetings.details.attendees.panel.responded')}
											sx={{
												mb: '30px',
												color: 'success.dark',
												bgcolor: 'success.100',
												'& .MuiChip-icon': {
													color: 'success.dark'
												}
											}}
										/>
									)}
									<Typography
										component={'span'}
										variant={'body2'}
										sx={{ display: 'flex', alignItems: 'center', color: 'grey.600', mb: 2 }}>
										{props.step}
									</Typography>
									<Typography variant={'h4'}>{props.label}</Typography>
									<Typography variant={'body2'} sx={{ display: 'flex', my: 3, color: 'grey.800' }}>
										{props.desc}
									</Typography>
								</Box>
							)}
							{(props.secondDecisionPage || props.thirdDecisionPage) && (
								<Box
									sx={{
										mt: '78px',
										flexDirection: 'column',
										display: 'flex',
										alignItems: 'flex-start',
										justifyContent: 'center',
										height: '100%'
									}}>
									{props.secondDecisionPage && settlementNotesGenerated && (
										<Chip
											icon={<CheckIcon />}
											label={t('generic.created')}
											sx={{
												mb: '30px',
												color: 'success.dark',
												bgcolor: 'success.100',
												'& .MuiChip-icon': {
													color: 'success.dark'
												}
											}}
										/>
									)}
									<Typography
										component={'span'}
										variant={'body2'}
										sx={{ display: 'flex', alignItems: 'center', color: 'grey.600', mb: 2 }}>
										{props.step}
									</Typography>
									<Typography variant={'h4'}>{props.label}</Typography>
									<Typography variant={'body2'} sx={{ display: 'flex', my: 3, color: 'grey.800' }}>
										{props.desc}
									</Typography>
									<Box sx={{ width: '100%' }}>
										<Typography variant={'body2'} sx={{ color: 'grey.800' }}>
											{props.desc2}
										</Typography>
										<Typography variant={'subtitle1'} sx={{ my: 3 }}>
											{props.sub}
										</Typography>
										{props.secondDecisionPage && (
											<Box sx={{ mb: '168px' }}>
												<PaymentFormComponent setValidForm={setValidForm} />
											</Box>
										)}
										{props.thirdDecisionPage && (
											<Form onSubmit={() => {}} validate={validate}>
												{({ valid }) => {
													return (
														<Grid container sx={{ display: 'flex' }}>
															<AutoSave
																save={() => {
																	setValidSubject(valid)
																}}
															/>
															<Grid item xs={12} sx={{ display: 'flex', mb: '168px' }}>
																<Grid container rowSpacing={3} columnSpacing={3}>
																	<Grid item xs={12}>
																		<TextField
																			required
																			name={'subject'}
																			label={t('subject')}
																			sx={{ mb: '20px' }}
																			value={subject}
																			onChange={handleSubjectChange}
																		/>
																	</Grid>
																	<Grid item xs={12}>
																		<RichTextEditor
																			label={t('message')}
																			value={message !== '' ? message : template}
																			onChange={handleMessageChange}
																		/>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													)
												}}
											</Form>
										)}
									</Box>
								</Box>
							)}
						</Grid>
					)}
				</Box>
				{!props.startPage ? (
					<Grid item xs={12} sx={{ display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-start' }}>
						{props.firstDecisionPage && (
							<Box
								position={'absolute'}
								zIndex={2}
								bottom={'0px'}
								sx={{
									mr: '64px',
									width: '-webkit-fill-available',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center'
								}}>
								<Box sx={{ display: 'flex', mb: '20px' }}>
									<BlueIcon />
									<GreyIcon />
									<GreyIcon />
								</Box>
								<Grid
									item
									xs={6}
									sx={{ display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-end' }}>
									{!shareRegisterUpdated ? (
										<Button
											disabled={props.preventChanges}
											variant={'contained'}
											sx={{ display: 'flex', fontWeight: 'bold', mb: '20px' }}
											onClick={handleClick_updateShareRegister}>
											<Typography variant={'h5'}>
												{t('emissions.payment.update-share-register-header')}
											</Typography>
										</Button>
									) : (
										<Button
											disabled={props.preventChanges}
											variant={'contained'}
											sx={{
												display: 'flex',
												fontWeight: 'bold',
												mb: '20px'
											}}
											onClick={props.page}>
											<Typography variant={'h5'} sx={{ mx: '32px' }}>
												{t('advertising.administration.create_advert_modal.button.next')}
											</Typography>
										</Button>
									)}
								</Grid>
							</Box>
						)}
						{props.secondDecisionPage && (
							<Box
								position={'absolute'}
								zIndex={2}
								bottom={'0px'}
								sx={{
									mr: '64px',
									width: '-webkit-fill-available',
									backgroundColor: 'grey.0',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									height: '98px'
								}}>
								<Box sx={{ display: 'flex' }}>
									<GreyIcon />
									<BlueIcon />
									<GreyIcon />
								</Box>
								<Box sx={{ display: 'flex' }}>
									{!settlementNotesGenerated ? (
										<Button
											disabled={props.preventChanges}
											variant={'contained'}
											sx={{
												display: 'flex',
												fontWeight: 'bold'
											}}
											onClick={() => {
												handleClick_ResetSettlementNotes()
											}}>
											<Typography variant={'h5'}>
												{t('emissions.payment.create-settlement-notes')}
											</Typography>
										</Button>
									) : (
										<Button
											disabled={props.preventChanges}
											variant={'contained'}
											sx={{
												display: 'flex',
												fontWeight: 'bold'
											}}
											onClick={props.page}>
											<Typography variant={'h5'} sx={{ mx: '32px' }}>
												{t('advertising.administration.create_advert_modal.button.next')}
											</Typography>
										</Button>
									)}
								</Box>
							</Box>
						)}
						{props.thirdDecisionPage && (
							<Box
								position={'absolute'}
								zIndex={2}
								bottom={'0px'}
								sx={{
									mr: '64px',
									width: '-webkit-fill-available',
									backgroundColor: 'grey.0',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									height: '98px'
								}}>
								<Box sx={{ display: 'flex' }}>
									<GreyIcon />
									<GreyIcon />
									<BlueIcon />
								</Box>
								<Box sx={{ display: 'flex' }}>
									<Button
										disabled={props.preventChanges}
										variant={'contained'}
										onClick={handleClick_sendSettlementNotes}
										sx={{
											display: 'flex',
											fontWeight: 'bold'
										}}>
										<Typography variant={'h5'}>
											{t('emissions.payment.send-settlement-notes')}
										</Typography>
									</Button>
								</Box>
							</Box>
						)}
					</Grid>
				) : (
					<>
						{(props.decision || props.noShareReg) && (
							<Grid
								item
								xs={12}
								sx={{ display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-end' }}>
								<Button
									disabled={props.preventChanges}
									onClick={() => {
										props.page()
									}}
									variant={'contained'}
									sx={{ display: 'flex', fontWeight: 'bold' }}>
									<Typography variant={'h5'}>{props.buttonText}</Typography>
								</Button>
							</Grid>
						)}
						{props.payment && (
							<Grid
								item
								xs={12}
								sx={{ display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-end' }}>
								<Button
									disabled={props.preventChanges}
									onClick={handleClick_updateShareRegister}
									variant={'contained'}
									sx={{ display: 'flex', fontWeight: 'bold' }}>
									<Typography variant={'h5'}>{props.buttonText}</Typography>
								</Button>
							</Grid>
						)}
						{props.noClosure && (
							<Grid
								item
								xs={12}
								sx={{ display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-end' }}>
								<Link to={location.pathname.replace(/\/[^\/]*$/, '/allocation')}>
									<Button
										disabled={props.preventChanges}
										variant={'contained'}
										sx={{ display: 'flex', fontWeight: 'bold' }}>
										<Typography variant={'h5'}>{props.buttonText}</Typography>
									</Button>
								</Link>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</WhiteSection>
	)
}

const GreyIcon = () => {
	return (
		<RectangleRoundedIcon
			variant={'contained'}
			sx={{
				mx: 0.5,
				borderRadius: '10px',
				height: '4px',
				width: '16px',
				background: '#E0E0E0',
				color: '#E0E0E0'
			}}
		/>
	)
}
const BlueIcon = () => {
	return (
		<RectangleRoundedIcon
			variant={'contained'}
			sx={{
				mx: 0.5,
				borderRadius: '10px',
				height: '4px',
				width: '32px',
				background: '#2B7FC3',
				color: '#2B7FC3'
			}}
		/>
	)
}

export default Pages
