import React, { createContext } from 'react'
import { usePopupState, bindHover, bindMenu, bindToggle } from 'material-ui-popup-state/hooks'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import { uniqueId } from 'lodash'
import { func, node, oneOf } from 'prop-types'

export const DropDownContext = createContext()
function DropDown({ button, children, alignMenu }) {
	const popupState = usePopupState({
		variant: 'popover',
		popupId: uniqueId()
	})

	return (
		<>
			{button({ params: bindHover(popupState) })}
			<HoverMenu
				transitionDuration={0}
				{...bindMenu(popupState)}
				anchorOrigin={{ vertical: 'bottom', horizontal: alignMenu }}
				transformOrigin={{ vertical: 'top', horizontal: alignMenu }}>
				<DropDownContext.Provider value={{ popupState }}>{children}</DropDownContext.Provider>
			</HoverMenu>
		</>
	)
}

DropDown.propTypes = {
	button: func.isRequired,
	children: node,
	alignMenu: oneOf(['left', 'right'])
}

DropDown.defaultProps = {
	alignMenu: 'left'
}

export { DropDown }
