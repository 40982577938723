import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import { downloadDocument } from '../../../../actions/documents.actions'
import { useSelector } from 'react-redux'
import { emissionConsts } from '../../../../constants/emissions'
import StyledIcon from '../../../../dumb-components/shared/icon/icon'
import emissions from '../../../../services/emissions'
import SendReceiptPopUpModal from './send-receipt-pop-up-modal'

export const SendReceiptContextMenu = (props) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [receiptModal, setReceiptModal] = useState(false)
	const open = !!anchorEl
	const { t } = useTranslation()

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const menuItem = props.receiptSent ? (
		<MenuItem
			onClick={() => {
				setReceiptModal(true), handleClose()
			}}>
			<ListItemIcon>
				<StyledIcon icon='faPaperPlane' type='light' color='#6C6C6C' size='sml' />
			</ListItemIcon>

			{t('emissions.payment.resend-receipt')}
		</MenuItem>
	) : (
		<MenuItem
			disabled={!props.paymentCompleted}
			onClick={() => {
				setReceiptModal(true), handleClose()
			}}>
			<ListItemIcon>
				<StyledIcon icon='faPaperPlane' type='light' color='#6C6C6C' size='sml' />
			</ListItemIcon>
			{t('emissions.payment.send-receipt')}
		</MenuItem>
	)

	return (
		<>
			<SendReceiptPopUpModal
				sendReceiptToOne
				open={receiptModal}
				close={() => setReceiptModal(false)}
				investmentId={props.investmentId}
				closureId={props.closureId}
			/>
			<Tooltip title={t('emissions.invitations.more-options')}>
				<IconButton onClick={handleClick}>
					<MoreVertIcon sx={{ color: 'grey.700' }} />
				</IconButton>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				id='payment-menu'
				open={open}
				onClose={handleClose}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
				{menuItem}
			</Menu>
		</>
	)
}

export const OpenClosureDocuments = (props) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = !!anchorEl
	const { t } = useTranslation()
	const emission = useSelector((state) => state.emissions.current)
	const allocationType = emission.allocationType
	const { allocationTypes } = emissionConsts.common
	const language = useSelector((state) => state.i18n.language)
	const currentLang = language.substring(0, 2)
	const closure = emission.closures.find((c) => c.id === props.closureId)
	const allocation = closure.allocations.find((a) => a.inviteeId === props.inviteeId)
	const existingSettlementNoteId =
		currentLang === 'sv' ? allocation['settlementDocId_sv'] : allocation['settlementDocId_en']
	const [settlementNoteDocId, setSettlementNoteDocId] = useState(existingSettlementNoteId ?? null)

	async function generateSettlementNote() {
		const settlementNoteDocId = await emissions.getSettlementNote(
			emission.companyId,
			emission.id,
			props.closureId,
			props.inviteeId,
			currentLang
		)
		setSettlementNoteDocId(settlementNoteDocId)
		return settlementNoteDocId
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<Tooltip title={t('emissions.invitations.more-options')}>
				<IconButton onClick={handleClick}>
					<StyledIcon icon='faExpandArrowsAlt' type='light' color='#6C6C6C' size='sml' />
				</IconButton>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				id='payment-menu'
				open={open}
				onClose={handleClose}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
				<MenuItem
					onClick={() => {
						OpenDocuments(props.subscriptionSlipDocId), handleClose()
					}}>
					<ListItemIcon>
						<StyledIcon icon='faStickyNote' type='light' color='#6C6C6C' size='sml' />
					</ListItemIcon>
					{t('emission.submission.pdf-note-filename')}
				</MenuItem>
				{allocationType === allocationTypes.decision && (
					<MenuItem
						onClick={async () => {
							if (settlementNoteDocId) {
								OpenDocuments(settlementNoteDocId), handleClose()
							} else {
								const docId = await generateSettlementNote()
								OpenDocuments(docId), handleClose()
							}
						}}>
						<ListItemIcon>
							<StyledIcon icon='faFileInvoice' type='light' color='#6C6C6C' size='sml' />
						</ListItemIcon>
						{t('emission.settlement.pdf-note-filename')}
					</MenuItem>
				)}
				<MenuItem
					disabled={!props.paymentCompleted}
					onClick={() => {
						OpenDocuments(props.receiptDocId), handleClose()
					}}>
					<ListItemIcon>
						<StyledIcon icon='faReceipt' type='light' color='#6C6C6C' size='sml' />
					</ListItemIcon>
					{t('emission.receipt.pdf-filename')}
				</MenuItem>
			</Menu>
		</>
	)
}

const OpenDocuments = (docId) => {
	downloadDocument({
		documentId: docId,
		openInViewer: true,
		getMergedVersion: false,
		getSignedVersion: false
	})()
}
