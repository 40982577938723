import React, { PureComponent } from 'react'
import { string, bool } from 'prop-types'
import styled from 'styled-components'
import ColoredContentWrapper from '../../shared/colored-content-wrapper/colored-content-wrapper'
import Text from '../../shared/text/text'

const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	word-break: break-all;
`

export default class TopicAndQuestionPanel extends PureComponent {
	static propTypes = {
		topicRef: string,
		question: string
	}

	render = () => {
		const { topicRef, question } = this.props

		return (
			<>
				<StyledColoredContentWrapper type='primary' marginBottom={3}>
					<Text fontSize='13px' bold={600} tid='meetings.public.voting_modal.topic_ref' />
					<Text>{topicRef}</Text>
				</StyledColoredContentWrapper>

				<StyledColoredContentWrapper type='secondary' marginBottom={4}>
					<Text fontSize='13px' bold={600} tid='meetings.voting_modal.vote.text.question' />
					<Text>{question}</Text>
				</StyledColoredContentWrapper>
			</>
		)
	}
}
