import Moment from 'moment-timezone'
import 'moment/locale/sv'

let locale = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage

if (locale.toLowerCase() === 'sv-se') {
	locale = 'sv'
}

Moment.locale(locale)
Moment.tz.setDefault('Europe/Stockholm')

export default Moment
