import React, { Component } from 'react'
import { connect } from 'react-redux'

import Layout from '../../dumb-components/company/comapny-dashboard/layout'
import Alerts from '../../containers/company/company-dashboard/company-dashboard-alerts.container'

// Grid panels
import UpgradePanel from '../../containers/company/company-dashboard/upgrade-panel.container'
import TeamMembersPanel from '../../containers/company/company-dashboard/team-members-panel.container'
import CompanyInfoPanel from '../../containers/company/company-dashboard/company-info-panel.container'
import ShareholdersPanel from '../../containers/company/company-dashboard/shareholders-panel.container'
import DocumentsPanel from '../../containers/company/company-dashboard/documents-panel.container'
import MeetingsPanel from '../../containers/company/company-dashboard/meetings-panel.container'
import TasksPanel from '../../containers/company/company-dashboard/tasks-panel.container'
import { routeHoc } from '../../interfaces/router'
import { LOCATION_COMPANY_DASHBOARD } from '/shared/constants'

class CompanyLandingPage extends Component {
	state = {
		hasSharesProduct: false
	}

	componentDidMount = () => {
		this.setHasSharesProduct()
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.activeProducts !== this.props.activeProducts) {
			this.setHasSharesProduct()
		}
	}

	setHasSharesProduct = () => {
		const { activeProducts } = this.props
		this.setState({ hasSharesProduct: activeProducts.some((p) => p === 'SHARES') })
	}

	render = () => {
		const { hasSharesProduct } = this.state

		return (
			<Layout
				alertsComponent={<Alerts />}
				companyInfoComponent={<CompanyInfoPanel />}
				upgradePanelComponent={<UpgradePanel />}
				teamMembersComponent={<TeamMembersPanel />}
				shareholdersComponent={hasSharesProduct && <ShareholdersPanel />}
				documentsComponent={<DocumentsPanel />}
				meetingsComponent={<MeetingsPanel />}
				tasksComponent={<TasksPanel />}
			/>
		)
	}
}

function mapStateToProps(store) {
	return {
		activeProducts: store.company?.company?.products
	}
}

const mapActionsToProps = {}

CompanyLandingPage = connect(
	mapStateToProps,
	mapActionsToProps
)(routeHoc(CompanyLandingPage, { routeName: LOCATION_COMPANY_DASHBOARD }))

export default CompanyLandingPage
