import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	fetchClosureReportFileBasicInfo,
	getSubmissions,
	removeClosure,
	updateClosureStatus
} from '../../../../actions/emissions.actions'
import WhiteSection from '../../common/white-section'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import undrawPieChartSvg from '../../../../assets/images/emissions/undraw_pie_chart.svg'
import paketeringSvg from '../../../../assets/images/emissions/Paketering.svg'
import { Divider, useTheme } from '@mui/material'
import { emissionConsts } from '../../../../constants/emissions'
import PartialClosureModal from './allocation-partial-closure-modal'
import Button from '@mui/material/Button'
import SplitButton from './split-button-partial-closure'
import AllocationGrid from './allocation-grid'
import { summarize } from '../../utils/calculations'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import { NUMBER_FORMAT_CURRENCY, NUMBER_FORMAT_INTEGER } from '/shared/constants.json'
import CustomFileIcon from '../../../../dumb-components/shared/file-icon/file-icon'
import { displayDate } from '../../utils/display-helpers'
import StatCard from '../subscription-list/statcards'
import { downloadDocument } from '../../../../actions/documents.actions'
import {
	allocationCloseErrorNotification,
	allocationCloseInfoNotification
} from '../../../../actions/allocation.actions'
import documentsHelper from '../../../../components/helpers/documents.helper'
import { FormattedNumber } from 'react-intl'
import { Link } from 'react-router-dom'
import { emissionIsReadOnly } from '../../utils/emission-status-helper'

const Allocation = ({ emission }) => {
	const price = useSelector((state) => state.emissions.current.pricePerShare)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const theme = useTheme()
	const totals = summarize(useSelector((state) => state.emissions.submissions))
	const {
		common: { newShareCountTypes, allocationTypes },
		closure: { type, closureStatus }
	} = emissionConsts
	const shareCountType = emission.newShareCountType
	const [partialClosureModal, setPartialClosureModal] = useState(false)
	const [closureModal, setClosureModal] = useState(false)
	const submissions = useSelector((state) => state.emissions.submissions)
	const closureStat = useSelector((state) => state.emissions.current.closureStatus)
	const closures = useSelector((state) => state.emissions.current.closures ?? [])
	const closure = closures[closures.length - 1]
	const langCode = useSelector((s) => s.i18n.language).substring(0, 2)
	const [infoNotificationShown, setInfoNotificationShown] = useState(true)
	const [fileSizeDisplayString, setFileSizeDisplayString] = useState(null)
	const newShareCountMax = emission.newShareCountMax
	const preventChanges = emissionIsReadOnly(emission, Allocation.name)

	useEffect(() => {
		async function fetchSubmissions() {
			await dispatch(getSubmissions(emission.companyId, emission.id))
		}
		fetchSubmissions()
	}, [closures])

	useEffect(() => {
		async function openAllocation() {
			if (emission.allocationType === allocationTypes.decision && closure?.type === type.partial) {
				if (
					closure?.shareRegisterUpdated &&
					closure?.settlementNotesGenerated &&
					closure?.settlementNotesSent &&
					closureStat === closureStatus.closureOngoing
				) {
					await dispatch(updateClosureStatus(emission.companyId, emission.id, closureStatus.closurePending))
				}
			} else if (
				closure?.shareRegisterUpdated &&
				closureStat === closureStatus.closureOngoing &&
				closure?.type === type.partial
			) {
				await dispatch(updateClosureStatus(emission.companyId, emission.id, closureStatus.closurePending))
			}
		}

		openAllocation()
	}, [])

	useEffect(() => {
		if (
			emission.newShareCountMax !== 0 &&
			totals.assignedToList !== 0 &&
			emission.newShareCountMax - totals.assignedToList === 0
		) {
			if (!infoNotificationShown) {
				showAllSharesAllocatedNotification()
				setInfoNotificationShown(true)
			}
		} else {
			setInfoNotificationShown(false)
		}
	}, [totals])

	useEffect(() => {
		async function fetchFileSize() {
			if (closureStat === closureStatus.closureOngoing) {
				await dispatch(
					fetchClosureReportFileBasicInfo(
						emission.companyId,
						emission.id,
						closure.docIdList.find((doc) => doc.language === langCode).docId
					)
				)

				const fileSize = documentsHelper.getFileSize({
					get: () => closure.docIdList.find((doc) => doc.language === langCode).size
				})

				setFileSizeDisplayString(
					<>
						<FormattedNumber value={fileSize.value} format={'filesize'} /> {fileSize.unit}
					</>
				)
			}
		}

		fetchFileSize()
	}, [closure])

	const handleClickSomeSharesMustBeAllocated = () => {
		if (totals.currentAllocation <= 0) {
			dispatch(
				allocationCloseInfoNotification(t('emissions.allocation.info.close.some-shares-has-to-be-allocated'))
			)
			return false
		}
		return true
	}

	const handleClickAllSharesNotAllocated = () => {
		if (newShareCountMax !== 0 && totals.assignedToList !== 0 && newShareCountMax - totals.assignedToList !== 0) {
			dispatch(
				allocationCloseInfoNotification(t('emissions.allocation.info.close.all-shares-has-to-be-allocated'))
			)
			return false
		}
		return true
	}

	const isOverAllocated = () => {
		if (newShareCountMax < totals.assignedToList) {
			dispatch(allocationCloseInfoNotification(t('emissions.allocation.info.close.shares_over_allocation')))
			return true
		}
		return false
	}

	// If a shareholder has assigned for pro rata equivalent, or more, amount of shares then this shareholder has to be allocated a minimum of shares equivalent to pro rata.
	const proRataNotFullfilled = () => {
		if (
			submissions.find(
				(submission) =>
					submission.submission.shareCount >= submission.proRata &&
					submission.proRata > submission.allocation.allocation
			)
		) {
			dispatch(
				allocationCloseInfoNotification(t('emissions.allocation.info.close.pro_rata_allocation_not_reached'))
			)
			return true
		}
		return false
	}

	const showAllSharesAllocatedNotification = async () => {
		await dispatch(
			allocationCloseInfoNotification(t('emissions.allocation.info.close.all-shares-has-been-allocated'))
		)
	}

	const isEmissionValidCallback = () => {
		if (emission.newShareCountMin > totals.assignedToList) {
			return false
		} else {
			return true
		}
	}

	const undoClosure = async () => {
		if (!closure.shareRegisterUpdated) {
			await dispatch(removeClosure(emission.companyId, emission.id, closure.id))
		} else {
			await dispatch(allocationCloseErrorNotification(t('emissions.allocation.undo-closure.error')))
		}
	}

	return (
		<Box position={'relative'}>
			{closureStat !== closureStatus.closureOngoing && (
				<>
					{!(
						shareCountType === newShareCountTypes.range &&
						emission.newShareCountMin > totals.registeredInterest
					) && (
						<Box>
							<Grid container columnSpacing={2}>
								<Grid item xs={6}>
									<Grid container rowSpacing={1}>
										{(shareCountType === newShareCountTypes.highest ||
											shareCountType === newShareCountTypes.range) && (
											<Grid item xs={12}>
												{shareCountType === newShareCountTypes.highest && (
													<StatCard
														label={t('emissions.allocation.total-emission-volume')}
														mainValue={localeFormatNumber(
															!emission.newShareCountMax ||
																isNaN(emission.newShareCountMax)
																? 0
																: emission.newShareCountMax,
															NUMBER_FORMAT_INTEGER
														)}
														secondaryValue={localeFormatNumber(
															emission.newShareCountMax * price,
															NUMBER_FORMAT_CURRENCY
														)}
														prefix='Max'
														suffix={t('emissions.allocation.shares')}
													/>
												)}
												{shareCountType === newShareCountTypes.range && (
													<StatCard
														label={t('emissions.allocation.total-emission-volume')}
														minValue={localeFormatNumber(
															!emission.newShareCountMin ||
																isNaN(emission.newShareCountMin)
																? 0
																: emission.newShareCountMin,
															NUMBER_FORMAT_INTEGER
														)}
														mainValue={localeFormatNumber(
															!emission.newShareCountMax ||
																isNaN(emission.newShareCountMax)
																? 0
																: emission.newShareCountMax,
															NUMBER_FORMAT_INTEGER
														)}
														secondaryRangeValue={localeFormatNumber(
															emission.newShareCountMin * price,
															NUMBER_FORMAT_CURRENCY
														)}
														secondaryValue={localeFormatNumber(
															emission.newShareCountMax * price,
															NUMBER_FORMAT_CURRENCY
														)}
														prefix={t('emissions.allocation.between')}
														suffix={t('emissions.allocation.shares')}
													/>
												)}
											</Grid>
										)}
										{shareCountType === newShareCountTypes.specific && (
											<Grid item xs={12} sx={{ display: 'flex' }}>
												<Grid item xs={6}>
													<StatCard
														label={t('emissions.allocation.total-emission-volume')}
														mainValue={localeFormatNumber(
															!emission.newShareCountMax ||
																isNaN(emission.newShareCountMax)
																? 0
																: emission.newShareCountMax,
															NUMBER_FORMAT_INTEGER
														)}
														secondaryValue={localeFormatNumber(
															emission.newShareCountMax * price,
															NUMBER_FORMAT_CURRENCY
														)}
														prefix={t('emissions.allocation.specific')}
														suffix={t('emissions.allocation.shares')}
													/>
												</Grid>
												<Divider flexItem orientation={'vertical'} variant={'middle'} />
												<Grid item xs={6}>
													<StatCard
														label={t('emissions.allocation.registered-interest')}
														mainValue={localeFormatNumber(
															totals.registeredInterest,
															NUMBER_FORMAT_INTEGER
														)}
														secondaryValue={localeFormatNumber(
															totals.registeredInterest * price,
															NUMBER_FORMAT_CURRENCY
														)}
														suffix={t('emissions.allocation.shares')}
													/>
												</Grid>
											</Grid>
										)}
										{(shareCountType === newShareCountTypes.highest ||
											shareCountType === newShareCountTypes.range) && (
											<Grid item xs={12}>
												<StatCard
													companyId={emission.companyId}
													emissionId={emission.id}
													sx={{ pb: 7.5 }}
													label={t('emissions.allocation.partialClosures')}
													showPartialClosureList
													mainValue={localeFormatNumber(
														emission.closures?.length ?? 0,
														NUMBER_FORMAT_INTEGER
													)}
													desc={localeFormatNumber(
														totals.amountOfPartialClosures,
														NUMBER_FORMAT_INTEGER
													)}
													suffix={t('emissions.allocation.quantity')}
												/>
											</Grid>
										)}
									</Grid>
								</Grid>
								<Grid item xs={6}>
									<Grid container rowSpacing={1}>
										{(shareCountType === newShareCountTypes.highest ||
											shareCountType === newShareCountTypes.range) && (
											<Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
												<Grid item xs={6}>
													<StatCard
														label={t('emissions.allocation.registered-interest')}
														mainValue={localeFormatNumber(
															totals.registeredInterest,
															NUMBER_FORMAT_INTEGER
														)}
														secondaryValue={localeFormatNumber(
															totals.registeredInterest * price,
															NUMBER_FORMAT_CURRENCY
														)}
														suffix={t('emissions.allocation.shares')}
													/>
												</Grid>
												<Divider flexItem orientation={'vertical'} variant={'middle'} />
												<Grid item xs={6}>
													<StatCard
														label={t('emissions.allocation.volume-left-until-full')}
														mainValue={localeFormatNumber(
															emission.newShareCountMax - totals.registeredInterest,
															NUMBER_FORMAT_INTEGER
														)}
														secondaryValue={localeFormatNumber(
															(emission.newShareCountMax - totals.registeredInterest) *
																price,
															NUMBER_FORMAT_CURRENCY
														)}
														info
														suffix={t('emissions.allocation.shares')}
													/>
												</Grid>
											</Grid>
										)}
										<Grid item xs={12} display='flex'>
											<Grid item xs={6}>
												<StatCard
													label={t('emissions.allocation.assigned-to-list')}
													mainValue={localeFormatNumber(
														totals.assignedToList,
														NUMBER_FORMAT_INTEGER
													)}
													secondaryValue={localeFormatNumber(
														totals.assignedToList * price,
														NUMBER_FORMAT_CURRENCY
													)}
													suffix={t('emissions.allocation.shares')}
												/>
											</Grid>
											<Divider flexItem orientation={'vertical'} variant={'middle'} />
											<Grid item xs={6}>
												<StatCard
													label={t('emissions.allocation.left-to-allocate')}
													mainValue={localeFormatNumber(
														emission.newShareCountMax - totals.assignedToList,
														NUMBER_FORMAT_INTEGER
													)}
													secondaryValue={localeFormatNumber(
														(emission.newShareCountMax - totals.assignedToList) * price,
														NUMBER_FORMAT_CURRENCY
													)}
													suffix={t('emissions.allocation.shares')}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					)}

					{!(
						shareCountType === newShareCountTypes.range &&
						emission.newShareCountMin > totals.registeredInterest
					) && (
						<WhiteSection sx={{ mt: 4 }}>
							<PartialClosureModal
								companyId={emission.companyId}
								emissionId={emission.id}
								closeDesc
								open={closureModal}
								close={() => setClosureModal(false)}
							/>
							<PartialClosureModal
								companyId={emission.companyId}
								emissionId={emission.id}
								partialCloseDesc
								open={partialClosureModal}
								close={() => setPartialClosureModal(false)}
							/>
							<Grid container>
								<Grid item xs={12} sx={{ height: '80rem' }}>
									<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
										<Typography variant={'h4'} sx={{ mb: 6 }}>
											{t('emissions.header.allocations-list')}
										</Typography>
										{closures.length === 0 ? (
											<>
												{shareCountType === newShareCountTypes.specific ||
												totals.assignedToList === newShareCountMax ? (
													<Button
														disabled={preventChanges}
														variant={'contained'}
														sx={{
															fontWeight: 'bold',
															alignSelf: 'center',
															py: '1.5rem !important'
														}}
														onClick={() => {
															if (isOverAllocated() || proRataNotFullfilled()) {
																return false
															}
															if (
																handleClickAllSharesNotAllocated() &&
																handleClickSomeSharesMustBeAllocated()
															) {
																setClosureModal(true)
															}
														}}>
														<Typography variant={'h5'}>
															{t('emissions.allocation.close-emission')}
														</Typography>
													</Button>
												) : (
													<SplitButton
														preventChanges={preventChanges}
														companyId={emission.companyId}
														emissionId={emission.id}
														isEmissionValidCallback={isEmissionValidCallback}
														sharesAreAllocated={handleClickSomeSharesMustBeAllocated}
														isOverAllocated={isOverAllocated}
														proRataNotFullfilled={proRataNotFullfilled}
													/>
												)}
											</>
										) : (
											<>
												{shareCountType === newShareCountTypes.specific ||
												totals.assignedToList === newShareCountMax ? (
													<Button
														disabled={preventChanges}
														variant={'contained'}
														sx={{
															fontWeight: 'bold',
															alignSelf: 'center',
															py: '1.5rem !important'
														}}
														onClick={() => {
															if (isOverAllocated()) {
																return false
															}
															if (
																handleClickAllSharesNotAllocated() &&
																handleClickSomeSharesMustBeAllocated()
															) {
																setClosureModal(true)
															}
														}}>
														<Typography variant={'h5'}>
															{t('emissions.allocation.close-emission')}
														</Typography>
													</Button>
												) : (
													<Button
														disabled={preventChanges}
														variant={'contained'}
														sx={{
															fontWeight: 'bold',
															alignSelf: 'center',
															py: '1.5rem !important'
														}}
														onClick={() => {
															if (handleClickSomeSharesMustBeAllocated()) {
																setPartialClosureModal(true)
															}
														}}>
														<Typography variant={'h5'}>
															{t('emissions.allocation.partial-closure')}
														</Typography>
													</Button>
												)}
											</>
										)}
									</Box>
									<AllocationGrid
										submissions={submissions.filter((s) => !s.allocation.fulfilled)}
										companyId={emission.companyId}
										emissionId={emission.id}
										preventChanges={preventChanges}
									/>
								</Grid>
							</Grid>
						</WhiteSection>
					)}

					{shareCountType === newShareCountTypes.range &&
						emission.newShareCountMin > totals.registeredInterest && (
							<WhiteSection sx={{ height: '70rem', width: '100%' }}>
								<Typography variant={'h4'}>{t('emissions.header.allocations-list')}</Typography>
								<Grid
									container
									sx={{ display: 'flex', height: '100%', alignItems: 'center', mb: '10px' }}>
									<Grid item xs={5}>
										<Box sx={{ display: 'flex', pl: 3 }}>
											<img src={undrawPieChartSvg} style={{ height: '300px' }} />
										</Box>
									</Grid>
									<Grid item xs={7} sx={{ display: 'flex' }}>
										<Box
											sx={{
												display: 'flex',
												alignSelf: 'flex-start',
												height: '100%'
											}}>
											<Typography
												variant={'body2'}
												sx={{ alignSelf: 'center', color: 'grey.600' }}>
												{t('emissions.allocation.lowest-emission-volume')}
											</Typography>
										</Box>
									</Grid>
								</Grid>
							</WhiteSection>
						)}
				</>
			)}

			{closureStat === closureStatus.closureOngoing && (
				<WhiteSection sx={{ height: '70rem', width: '100%' }}>
					<Box key={closure.id} sx={{ display: 'flex', mt: '101px' }}>
						<Grid container sx={{ display: 'flex', height: '100%', alignItems: 'flex-end', mb: '10px' }}>
							<Grid item xs={5} sx={{ display: 'flex' }}>
								<Box sx={{ display: 'flex', pl: 3 }}>
									<img src={paketeringSvg} style={{ height: '300px' }} />
								</Box>
							</Grid>
							<Grid item xs={7} sx={{ display: 'flex' }}>
								<Box
									sx={{
										flexDirection: 'column',
										display: 'flex',
										alignItems: 'flex-start',
										justifyContent: 'center',
										height: '100%',
										mr: '110px'
									}}>
									{closure.type === type.definite && (
										<Box sx={{ display: 'flex', flexDirection: 'column' }}>
											<Typography variant={'h4'}>
												{t('emissions.allocation.ongoing-closure')}
											</Typography>
											<Typography
												variant={'body2'}
												sx={{ my: 3, color: theme.palette.grey['800'] }}>
												{t('emissions.allocation.closing-in-progress.desc')}
											</Typography>
											<Typography
												variant={'body2'}
												sx={{ mb: 3, color: theme.palette.grey['800'] }}>
												{t('emissions.allocation.ongoing-closure.desc')}
											</Typography>
										</Box>
									)}
									{closure.type === type.partial && (
										<Box sx={{ display: 'flex', flexDirection: 'column' }}>
											<Typography variant={'h4'}>
												{t('emissions.allocation.ongoing-partial-closure')}
											</Typography>
											<Typography
												variant={'body2'}
												sx={{ my: 3, color: theme.palette.grey['800'] }}>
												{t('emissions.allocation.ongoing-partial-closure.desc')}
											</Typography>
											<Typography
												variant={'body2'}
												sx={{ mb: 3, color: theme.palette.grey['800'] }}>
												{t('emissions.allocation.ongoing-partial-closure.desc2')}
											</Typography>
										</Box>
									)}

									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											py: '8px',
											border: 1,
											width: '-webkit-fill-available',
											borderColor: '#E0E0E0',
											borderRadius: '4px'
										}}>
										<Box sx={{ display: 'flex' }}>
											<Box sx={{ display: 'flex', width: '34px', height: '42px', ml: '16px' }}>
												<CustomFileIcon ext={'pdf'} />
											</Box>
											<Box sx={{ display: 'flex', flexDirection: 'column', mx: '21px' }}>
												{closure.type === type.definite && (
													<Typography
														variant={'body2'}
														sx={{ color: theme.palette.grey['800'] }}>
														{`${t('emissions.allocation.definiteClosure.title')}`}
													</Typography>
												)}
												{closure.type === type.partial && (
													<Typography
														variant={'body2'}
														sx={{ color: theme.palette.grey['800'] }}>
														{`${t('emissions.allocation.partialClosure.title')} ${
															closure.index
														}`}
													</Typography>
												)}
												<Box sx={{ display: 'flex' }}>
													<Typography
														variant={'overline'}
														sx={{
															textTransform: 'uppercase',
															mr: '8px',
															color: theme.palette.grey['500']
														}}>
														{t('generic.created')}
													</Typography>
													<Typography
														variant={'overline'}
														sx={{ mr: '8px', color: theme.palette.grey['500'] }}>
														{displayDate(closure.date)}
													</Typography>
													<Divider flexItem orientation={'vertical'} variant={'middle'} />
													<Typography
														variant={'overline'}
														sx={{ ml: '8px', color: theme.palette.grey['500'] }}>
														{fileSizeDisplayString}
													</Typography>
												</Box>
											</Box>
										</Box>
										<Typography
											onClick={() => {
												OpenPartialClosurePdf(
													closure.docIdList.find((doc) => doc.language === langCode).docId
												)
											}}
											sx={{
												display: 'flex',
												color: 'primary.main',
												fontWeight: 'bold',
												cursor: 'pointer',
												mr: '16px'
											}}>
											{t('emissions.allocation.open-document')}
										</Typography>
									</Box>
									<Button
										disabled={preventChanges}
										variant={'contained'}
										onClick={undoClosure}
										sx={{ display: 'flex', mt: '8px' }}>
										<Typography variant={'h5'}>
											{t('emissions.allocation.undo-closure.button')}
										</Typography>
									</Button>
								</Box>
							</Grid>
						</Grid>
						<Box position={'absolute'} bottom={'30px'} right={'40px'} sx={{ display: 'flex' }}>
							<Link to={location.pathname.replace(/\/[^\/]*$/, '/payment')}>
								<Button
									disabled={preventChanges}
									variant={'contained'}
									sx={{ display: 'flex', fontWeight: 'bold' }}>
									<Typography variant={'h5'}>{t('emissions.tabs.payment')}</Typography>
								</Button>
							</Link>
						</Box>
					</Box>
				</WhiteSection>
			)}
		</Box>
	)
}

const OpenPartialClosurePdf = (docId) => {
	downloadDocument({
		documentId: docId,
		openInViewer: true,
		getMergedVersion: false,
		getSignedVersion: false
	})()
}

export default Allocation
