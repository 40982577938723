import React, { PureComponent } from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import { lighten } from 'polished'

import { StyledWrapperCss } from './item.template'
import Tooltip from '../../shared/tooltip/tooltip'
import Text from '../../shared/text/text'
import Icon from '../../shared/icon/icon'

const StyledWrapper = styled.div`
	${StyledWrapperCss}
	justify-content: center;
	align-items: center;
	border-top: 1px solid ${(props) => props.theme.colors.border};
	background: #273e45;

	span:first-child {
		margin-right: ${(props) => props.theme.spacing[3]};
	}

	:hover {
		background: ${lighten(0.05, '#273E45')};
	}
`

export default class AddCompanyItem extends PureComponent {
	static propTypes = {
		onClick: func
	}

	render = () => {
		const { onClick } = this.props

		return (
			<Tooltip tid='navbar.company_dropdown.add_company.tooltip' place='right' delayShow='instant'>
				<StyledWrapper onClick={onClick}>
					<Icon icon='faPlusCircle' size='sml' color='white' />
					<Text tid='navbar.company_dropdown.add_company.title' fontSize='12px' color='white' />
				</StyledWrapper>
			</Tooltip>
		)
	}
}
