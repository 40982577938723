import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { deleteLastTransaction, cancelTemporaryTransactions } from '../../../../actions/transaction.actions'
import { listInvestors } from '../../../../actions/investors.actions'

import Moment from '../../../../modules/moment.module'
import { fetchMenu } from '../../../../actions/menu.actions'
import req from '../../../../modules/request.module'
import ConfirmContainer from '../../../../containers/shared/confirm.container'
import history from '../../../../interfaces/history'

class RevertToLastTransaction extends Component {
	state = {
		expanded: false,
		createdBy: '',
		confirmIsOpen: false
	}

	handleExpand = (e) => {
		e.preventDefault()
		this.setState((prevState) => ({ expanded: !prevState.expanded }))
	}

	handleConfirm = (e) => {
		if (this.props.transaction.get('type') === 'GENESIS') {
			this.setState({ confirmIsOpen: true })
		} else {
			this.doRevert()
		}
	}

	doRevert = () => {
		const { listInvestors, deleteLastTransaction, cancelTemporaryTransactions, transaction } = this.props

		this.closeConfirmModal()

		cancelTemporaryTransactions()
		deleteLastTransaction()
		listInvestors()
	}

	closeConfirmModal = () => {
		this.setState({ confirmIsOpen: false })
	}

	componentDidMount = () => {
		if (this.props.transaction && this.props.transaction.get('createdBy')) {
			this.getCreatedBy(this.props.transaction.get('createdBy'))
		}
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.transaction !== this.props.transaction) {
			if (!this.props.transaction) {
				this.props.fetchMenu('main')
				this.props.history.push('/' + this.props.company.urlAlias + '/shares/setup')
				return
			}
			if (prevProps.transaction.get('id') !== this.props.transaction.get('id')) {
				this.setState({ expanded: false })
			}

			this.getCreatedBy(this.props.transaction.get('createdBy'))
		}
	}

	getCreatedBy = (createBy) => {
		if (!createBy) {
			return
		}

		req
			.get(`/users/public/users/${createBy}`)
			.then((response) => {
				this.setState({ createdBy: response.data.name })
			})
			.catch((err) => {
				console.log(err)
			})
	}

	render = () => {
		const { transaction } = this.props
		const { expanded, confirmIsOpen } = this.state

		if (!transaction || !transaction.get('id')) {
			return null
		}

		let date = transaction.get('date')
		let type = transaction.get('type')
		date = Moment(date)

		return (
			<div className='i-panel i-panel--transparent i-panel--mar-btm-0'>
				<div className='i-panel__body i-panel__body--pad-vert-no i-panel__body--mar-btm-0 h-box h-box--v-center'>
					<div className='form-group form-group--mar-btm-0 col-md-3'>
						<span className='text'>{date.format('L')}</span>
					</div>
					<div className='form-group form-group--mar-btm-0 col-md-5 form-group-no-padding text-overflow'>
						<span className='text'>
							<FormattedMessage id={type} />
							<span> </span>
						</span>
					</div>
					<div className='form-group form-group--mar-btm-0 col-md-4 text--align-right'>
						<span
							className={`text--link text--muted ${expanded ? 'visibility-hidden' : ''}`}
							onClick={this.handleExpand}>
							<FormattedMessage id='shares.transactions.revert.last.transaction' />
						</span>
					</div>
				</div>
				{expanded ? (
					<div className='i-panel__body i-panel__body--pad-btm-0 i-panel__body--pad-top-half v-box'>
						<div className='form-group col-md-12 text'>
							<span className='text--bold'>
								<FormattedMessage id='user.profile.information' />
							</span>
							<p>
								<FormattedMessage id='revert_transaction_info1' />
							</p>
							<p>
								<FormattedMessage id='revert_transaction_info2' />
							</p>
						</div>

						<div className='form-group col-md-12'>
							<div className='h-box h-box--v-center'>
								<div className='form-group '>
									<button className={`btn btn-primary button--w50 button--mar-right`} onClick={this.handleConfirm}>
										<FormattedMessage id='generic.yes' />
									</button>
								</div>
								<div className='form-group '>
									<button className={`btn btn-default button--w50`} onClick={this.handleExpand}>
										<FormattedMessage id='generic.form.cancel' />
									</button>
								</div>
							</div>
						</div>
					</div>
				) : null}

				<ConfirmContainer
					title='reset_shares_to_genesis_title'
					question='reset_shares_to_genesis_question'
					message='reset_shares_to_genesis_message'
					onConfirm={this.doRevert}
					onDecline={this.closeConfirmModal}
					isOpen={confirmIsOpen}
				/>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		history: history,
		transaction: state.transaction.get('transaction'),
		company: state.company.company,
		i18n: state.i18n
	}
}

const mapActionsToProps = {
	deleteLastTransaction,
	cancelTemporaryTransactions,
	listInvestors,
	fetchMenu
}

RevertToLastTransaction = connect(mapStateToProps, mapActionsToProps)(RevertToLastTransaction)
export default RevertToLastTransaction
