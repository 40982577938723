import React from 'react'
import {string, func} from 'prop-types'
import {map} from 'react-immutable-proptypes'
import DropdownIconItem from '../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import Tooltip from '../../../../dumb-components/shared/tooltip/tooltip'
import RecoverFileComponentContainer from '../recover-file-component.container'

const RecoverFileDropDownItem = ({onClick, ...props}) => {
	return (
		<RecoverFileComponentContainer {...props}>
			{({ recover, TOOLTIP_STATES, activeState, isDisabled }) => (
				<Tooltip states={TOOLTIP_STATES} activeState={activeState}>
					<DropdownIconItem
						tid='documents.toolbar.recover_document'
						icon='faTrashUndoAlt'
						disabled={isDisabled}
						onClick={() => {
							onClick && onClick()
							recover()
						}}
					/>
				</Tooltip>
			)}
		</RecoverFileComponentContainer>
	)
}

RecoverFileDropDownItem.propTypes = {
	document: map.isRequired,
	basePath: string.isRequired,
	querystr: string,
	onClick: func
}

export default RecoverFileDropDownItem
