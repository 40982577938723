import React, { Component } from 'react'
import { connect } from 'react-redux'
import { resetStripeError } from '../../actions/subscriptions.actions'
import { Margin } from 'styled-components-spacing'
import Alert from '../../dumb-components/shared/alert/alert'
import Panel from '../../dumb-components/shared/panel/panel'
import Text from '../../dumb-components/shared/text/text'

class StripeErrorContainer extends Component {
	duration = 10000

	componentDidUpdate = (prevProps) => {
		const { stripeError } = this.props

		if (!prevProps.stripeError && stripeError) {
			this.timer = setTimeout(this.clearError, this.duration)
		}

		if (prevProps.stripeError && stripeError && prevProps.stripeError !== stripeError) {
			this.timer = setTimeout(this.clearError, this.duration)
		}

		if (prevProps.stripeError && !stripeError && this.timer) {
			clearTimeout(this.timer)
		}
	}

	clearError = () => {
		const { resetStripeError } = this.props

		resetStripeError()
	}

	render = () => {
		const { stripeError } = this.props

		if (!stripeError) {
			return null
		}

		return (
			<Panel nakedStyle={true} marginBottom={true}>
				<Alert mode='warning' icon='faExclamationTriangle' iconType='solid'>
					<Text color='white'>{stripeError.get('message')}</Text>
				</Alert>
			</Panel>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		stripeError: store.subscriptions.get('stripeError')
	}
}

const mapActionsToProps = {
	resetStripeError
}

export default connect(mapStoreToProps, mapActionsToProps)(StripeErrorContainer)
