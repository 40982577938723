import React, { Component } from 'react'
import { connect } from 'react-redux'
import RedirectImageAdmin from '../../../../../assets/images/onboarding/bounce.png'
import RedirectImageUser from '../../../../../assets/images/onboarding/config.png'

import FakeFooterSpacer from '../../../../../dumb-components/shared/modal/fake-footer-spacer'
import { ButtonTransparentIcon } from '../../../../../dumb-components/shared/button'
import Button from '../../../../../dumb-components/shared/button/button'
import { TransparentButton } from '../../../../../dumb-components/shared/button-v2'

import {
	AdminFirstStepBody,
	AdminReadMoreScratchBody,
	AdminReadMoreTemplateBody,
	RedirectToDashboardBody,
	UserTemplateMoreInfo
} from '../../../../../dumb-components/onboarding/modals/documents/init-document-structure'
import OnboardingModal from '../../../../../dumb-components/onboarding/modal'

import { ONB_COMPANY_DOCUMENTS_INITIALIZE } from '/shared/constants'
import { openModal, closeModal } from '../../../../../actions/modals.actions'
import permissionsHelper from '../../../../../components/helpers/permissions.helper'

import { setupFolderStructure, listFolders } from '../../../../../actions/folders.actions'
import { updateCompanyMetaData } from '../../../../../actions/company.actions'
import { fetchMenu } from '../../../../../actions/menu.actions'
import history from '../../../../../interfaces/history'

const ControlButton = ({ action, arrowDirection }) => (
	<ButtonTransparentIcon
		onClick={action}
		icon={arrowDirection === 'RIGHT' ? 'faLongArrowRight' : 'faLongArrowLeft'}
		size='xl'
	/>
)

const FakeFooter = () => <FakeFooterSpacer height={36} />

/**
 * 1. Onboarding controller closes modal automatically when there's no notification in onboardingNotifications
 * 2. Upload Document Intro onboarding modal never showing?
 */
class UploadedDocumentInfoContainer extends Component {
	state = {
		adminStep: 2, // Start in middle
		userStep: 0,
		isLoading: false,
		isAdmin: undefined
	}

	componentDidMount = () => {
		this.setIsAdmin()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { companiesAccessRights } = this.props
		const { isAdmin } = this.state

		if (prevProps.companiesAccessRights !== companiesAccessRights) {
			this.setIsAdmin()
		}

		if (prevState.isAdmin !== isAdmin) {
			this.openModalIfNeeded()
		}
	}

	openModalIfNeeded = () => {
		const { openModal, isOpen, documentsInitialized } = this.props
		const { isAdmin } = this.state

		if (isAdmin === undefined) return

		if (isOpen) return

		if (documentsInitialized === true) return

		openModal(ONB_COMPANY_DOCUMENTS_INITIALIZE)
	}

	setIsAdmin = () => {
		const { companiesAccessRights } = this.props
		const isAdmin = permissionsHelper.getIsAdminForSelectedCompany(companiesAccessRights)

		if (typeof isAdmin === 'boolean') {
			this.setState({ isAdmin })
		}
	}

	nextStep = (steps = 1) => {
		const { isAdmin } = this.state

		if (typeof steps !== 'number') {
			steps = 1
		}

		if (isAdmin) {
			this.setState({ adminStep: this.state.adminStep + steps })
		} else {
			this.setState({ userStep: this.state.userStep + steps })
		}
	}

	prevStep = () => {
		const { isAdmin } = this.state

		if (isAdmin) {
			this.setState({ adminStep: this.state.adminStep - 1 })
		} else {
			this.setState({ userStep: this.state.userStep - 1 })
		}
	}

	cancelStartFromScratch = () => {
		this.prevStep()
		this.redirectToCompanyDashboard()
	}

	cancelStartFromTemplate = () => {
		this.nextStep()
		this.redirectToCompanyDashboard()
	}

	cancelMain = () => {
		this.nextStep(2)
		this.redirectToCompanyDashboard()
	}

	redirectToCompanyDashboard = () => {
		setTimeout(() => {
			this.doRedirectToDashboard()
		}, 3000)
	}

	redirectUserToCompanyDashboard = () => {
		this.nextStep()
		this.redirectToCompanyDashboard()
	}

	doRedirectToDashboard = () => {
		const { closeModal, history } = this.props
		closeModal()
		history.push(`/${this.props.companyUrlAlias}`)
	}

	doStartFromScratch = () => {
		const { closeModal } = this.props

		this.setState({ isLoading: true })

		this.setupFolderStructure('none', () => {
			this.setState({ isLoading: false })
			closeModal()
		})
	}

	doStartFromTemplate = () => {
		const { closeModal } = this.props
		this.setState({ isLoading: true })

		this.setupFolderStructure('invono1', () => {
			this.setState({ isLoading: false })
			closeModal()
		})
	}

	// window.open('https://invono.se/dokument/dokumentstruktur/', '_blank');
	setupFolderStructure = (template, callback) => {
		const { setupFolderStructure, updateCompanyMetaData, fetchMenu, companyId, listFolders } = this.props

		setupFolderStructure(template, () => {
			updateCompanyMetaData(companyId, 'documentsInitialized', true, () => {
				fetchMenu('main')
				listFolders(() => {
					callback && callback()
				})
			})
		})
	}

	getAdminSteps = () => {
		const { isLoading } = this.state

		return [
			{
				body: <RedirectToDashboardBody image={RedirectImageAdmin} />,
				footer: {
					component: <FakeFooter />
				}
			},
			{
				// Start from scratch details
				body: (
					<AdminReadMoreScratchBody
						onStartFromScratch={this.doStartFromScratch}
						onCancel={this.cancelStartFromScratch}
						isLoading={isLoading}
					/>
				),
				footer: {
					component: <ControlButton action={this.nextStep} arrowDirection='RIGHT' />
				}
			},
			{
				// Entry slide
				body: <AdminFirstStepBody onReadMoreScratch={this.prevStep} onReadMoreTemplate={this.nextStep} />,
				footer: {
					component: (
						<TransparentButton
							tid='onboarding.modals.documents.doc_setup.template_details.btn.cancel'
							textColor='midGrey'
							onClick={this.cancelMain}
						/>
					)
				}
			},
			{
				// Start from template details
				body: (
					<AdminReadMoreTemplateBody
						onStartFromTemplate={this.doStartFromTemplate}
						onCancel={this.cancelStartFromTemplate}
						isLoading={isLoading}
					/>
				),
				footer: {
					leftComponent: <ControlButton action={this.prevStep} />
				}
			},
			{
				body: <RedirectToDashboardBody image={RedirectImageAdmin} />,
				footer: {
					component: <FakeFooter />
				}
			}
		]
	}

	getUserSteps = () => {
		return [
			{
				body: <UserTemplateMoreInfo />,
				footer: {
					component: (
						<Button
							tid='onboarding.modals.documents.doc_setup.user.info.button.close'
							onClick={this.redirectUserToCompanyDashboard}
						/>
					)
				}
			},
			{
				body: <RedirectToDashboardBody image={RedirectImageUser} />,
				footer: {
					component: <FakeFooter />
				}
			}
		]
	}

	render = () => {
		const { isOpen } = this.props
		const { isAdmin } = this.state

		if (isAdmin === undefined) {
			return null
		}

		if (!isOpen) {
			return null
		}

		return (
			<OnboardingModal
				mode='steps'
				step={isAdmin ? this.state.adminStep : this.state.userStep}
				steps={isAdmin ? this.getAdminSteps() : this.getUserSteps()}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		companyId: store.company.company.id,
		companyUrlAlias: store.company.company.urlAlias,
		documentsInitialized: store.company.company.metadata?.documentsInitialized,
		companiesAccessRights: store.company.companiesAccessRights,
		isOpen: store.modals.getIn(['activeModal', 'name']) === ONB_COMPANY_DOCUMENTS_INITIALIZE
	}
}

const mapActionsToProps = {
	openModal,
	closeModal,
	setupFolderStructure,
	updateCompanyMetaData,
	fetchMenu,
	listFolders
}

export default connect(mapStoreToProps, mapActionsToProps)(UploadedDocumentInfoContainer)
