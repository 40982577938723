import React, { PureComponent } from 'react'
import { string, bool, func } from 'prop-types'
import styled from 'styled-components'

import Modal from '../../shared/modal/modal'
import Button from '../../shared/button/button'

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]} 0;
	height: 100%;
	justify-content: center;

	> button {
		margin-left: 0;

		:first-child {
			margin-bottom: ${(props) => props.theme.spacing[4]};
		}
	}
`

export default class NoFundsEsignModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		onSignWithEmail: func,
		onCancel: func,
		onGoToSubscription: func,
		isSubscriptionAdmin: bool
	}

	renderFooter = () => {
		const { onCancel } = this.props

		return <Button tid='generic.form.cancel' onClick={onCancel && onCancel} />
	}

	renderRefillBtn = () => {
		const { isSubscriptionAdmin, onGoToSubscription } = this.props

		if (!isSubscriptionAdmin) {
			return null
		}

		return (
			<Button
				mode='primary'
				tid='documents.esign.no_funds_modal.btn.refill'
				onClick={onGoToSubscription && onGoToSubscription}
			/>
		)
	}

	renderProceedWithEmailSignBtn = () => {
		const { isSubscriptionAdmin, onSignWithEmail } = this.props

		return (
			<Button
				mode={!isSubscriptionAdmin ? 'primary' : 'default'}
				tid='documents.esign.no_funds_modal.btn.proceed'
				onClick={onSignWithEmail && onSignWithEmail}
			/>
		)
	}

	render = () => {
		const { isOpen } = this.props

		return (
			<Modal
				title='documents.esign.no_funds_modal.title'
				isOpen={isOpen}
				hSize='md'
				vSize={50}
				scrollableContent={false}
				footerComponent={this.renderFooter()}>
				<StyledWrapper>
					{this.renderRefillBtn()}
					{this.renderProceedWithEmailSignBtn()}
				</StyledWrapper>
			</Modal>
		)
	}
}
