import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func, string } from 'prop-types'
import { Map, List } from 'immutable'
import {
	downloadDocument,
	listDocumentsWithPagination,
	linkDocument,
	unlinkDocument,
	clearDocumentsList,
	clearPagination
} from '../../../actions/documents.actions'
import DocumentsInvestorsView from '../../../dumb-components/documents/documents-investors-view/documents-investors-view'
import ItemTreeContainer from '../../shared/item-tree.container'
import Modal from '../../../dumb-components/shared/modal/modal'
import DropdownMenu from '../../shared/dropdown-menu.container'
import DropdownIconItem from '../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import FooterRightControls from '../../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../../dumb-components/shared/button-v2'
import ConfirmModal from '../../shared/confirm.container'
import documentsHelper from '../../../components/helpers/documents.helper'
import folderHelper from '../../../components/helpers/folder.helper'

const NUM_OF_ITEMS_IN_BATCH = 25

const INITIAL_STATE = {
	selectedFolder: Map(),
	changesMade: false,
	isConfirmSwapOpen: false,
	swapToDocumentId: null,
	isLoading: false,
	page: 1
}

class LinkDocumentsModalContainer extends Component {
	static propTypes = {
		isOpen: bool,
		onClose: func,
		objId: string,
		objType: string,
		multiple: bool,
		onLinkDocument: func,
		onUnlinkDocument: func
	}

	static defaultProps = {
		multiple: false,
		isOpen: false
	}

	state = INITIAL_STATE

	treeItemRefs = Map()

	dropdownRefs = Map()

	componentDidMount = () => {
		const { clearPagination } = this.props

		clearPagination()
	}

	componentDidUpdate = (prevProps) => {
		const { isOpen } = this.props

		if (!prevProps.isOpen && isOpen) {
			this.fetchData()
		}
	}

	fetchData = () => {
		const { listDocumentsWithPagination } = this.props
		let { page } = this.state

		listDocumentsWithPagination({ folderId: '', numOfItems: NUM_OF_ITEMS_IN_BATCH, page: page++ })
		this.setState({ page })
	}

	onDownloadFile = (documentId) => {
		const { downloadDocument, companyId } = this.props
		downloadDocument({ documentId, companyId })
		this.dropdownRefs.get(documentId)?.onToggleMenu()
	}

	onOpenFile = (documentId) => {
		const { downloadDocument, companyId } = this.props
		downloadDocument({ documentId, companyId, openInViewer: true })
		this.dropdownRefs.get(documentId)?.onToggleMenu()
	}

	onFolderSelected = (folder) => {
		const { listDocumentsWithPagination, clearPagination } = this.props
		const folderId = folder.get('id')

		clearPagination()
		listDocumentsWithPagination({ folderId, numOfItems: NUM_OF_ITEMS_IN_BATCH, page: 1 })

		this.setState({ selectedFolder: folder, page: 1 })
	}

	onTreeItemRef = (folderId, ref) => {
		if (!folderId || !ref) {
			return
		}

		this.treeItemRefs = this.treeItemRefs.set(folderId, ref)
	}

	onBadgeClick = (documentId, folderId) => {
		const { folders } = this.props
		const folderToSelect = folders.get(folderId)

		this.onFolderSelected(folderToSelect)
		this.itemTreeContainerRef && this.itemTreeContainerRef.onBadgeClick(folderId, true)

		// Scroll the folder into view when selected
		const selectedFolderItemRef = this.treeItemRefs.get(folderId)
		if (selectedFolderItemRef && selectedFolderItemRef.scrollIntoViewIfNeeded) {
			setTimeout(() => {
				selectedFolderItemRef.scrollIntoViewIfNeeded()
			}, 0)
		}
	}

	onChangeLink = (documentId, checked) => {
		const { multiple, objType, objId, documents } = this.props

		if (checked && !multiple) {
			const documentAlreadySelected = documents.some(
				(document) =>
					document
						.get('links')
						?.some((link) => link.get('objType') === objType && link.get('objId').startsWith(objId)) || false
			)

			if (documentAlreadySelected) {
				this.setState({ isConfirmSwapOpen: true, swapToDocumentId: documentId })
				return
			}
		}

		this.doChangeLink(documentId, checked)
	}

	doChangeLink = (documentId, checked) => {
		const { linkDocument, unlinkDocument, onLinkDocument, onUnlinkDocument, objId, objType, documents } = this.props

		this.setState({ isLoading: true })

		const document = documents.find((doc) => doc.get('id') === documentId)

		if (checked) {
			linkDocument(document, objType, objId, (document) => {
				this.setState({ isLoading: false })
				onLinkDocument && onLinkDocument(objType, document)
			})
		} else {
			unlinkDocument(document, objType, objId, (document) => {
				this.setState({ isLoading: false })
				onUnlinkDocument && onUnlinkDocument(objType, document)
			})
		}

		this.setState({ changesMade: true, isConfirmSwapOpen: false, swapToDocumentId: null })
	}

	swapDocument = () => {
		const { objType, objId, unlinkDocument, linkDocument, onLinkDocument, onUnlinkDocument, documents } = this.props
		const { swapToDocumentId } = this.state

		this.setState({ isLoading: true })

		documents.forEach((document) => {
			const isLinked = this.isDocumentLinked({ links: document.get('links') })

			if (isLinked) {
				unlinkDocument(document, objType, objId, (document) => {
					this.setState({ isLoading: false })
					onUnlinkDocument(objType, document)
				})
			}

			if (document.get('id') === swapToDocumentId) {
				linkDocument(document, objType, objId, (document) => {
					this.setState({ isLoading: false })
					onLinkDocument && onLinkDocument(objType, document)
				})
			}
		})

		this.setState({ isConfirmSwapOpen: false, swapToDocumentId: null })
	}

	close = () => {
		const { onClose, clearPagination } = this.props
		const { changesMade } = this.state

		clearPagination()
		this.setState(INITIAL_STATE)
		onClose && onClose(changesMade)
	}

	closeConfirmSwapModal = () => {
		this.setState({ isConfirmSwapOpen: false, swapToDocumentId: null })
	}

	setDropdownRef = (id, ref) => {
		this.dropdownRefs = this.dropdownRefs.set(id, ref)
	}

	isDocumentLinked = ({ links }) => {
		const { objId } = this.props
		const isChecked = links?.some((link) => link.get('objId') === objId) || false
		return isChecked
	}

	renderModalFooter = () => {
		const { changesMade, isLoading } = this.state

		return (
			<FooterRightControls>
				<TransparentButton
					tid={changesMade ? 'save_and_close' : 'generic.form.close'}
					textColor='midGrey'
					onClick={this.close}
					isLoading={isLoading}
				/>
			</FooterRightControls>
		)
	}

	renderMoreActionMenu = (documentId) => {
		const { objId, objType, documents } = this.props
		const document = documents.find((doc) => doc.get('id') === documentId)
		const isLinked = this.isDocumentLinked({ links: document.get('links') })
		const isViewableInBrowser = documentsHelper.isViewableInBrowser(
			document.get('file'),
			document.getIn(['file', 'ext'])
		)

		return (
			<DropdownMenu
				btnIcon='faEllipsisV'
				halignMenu='right'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				tooltipActiveState='btnMoreActions'
				ref={this.setDropdownRef.bind(null, documentId)}
				noHorizontalMargin
				withPortal
				inline>
				{isViewableInBrowser && (
					<DropdownIconItem icon='faExpandArrowsAlt' tid='open_file' onClick={this.onOpenFile.bind(null, documentId)} />
				)}

				<DropdownIconItem
					icon='faArrowAltToBottom'
					tid='download_file'
					onClick={this.onDownloadFile.bind(null, documentId)}
				/>
				<DropdownIconItem
					icon={isLinked ? 'faUnlink' : 'faLink'}
					tid={isLinked ? 'unlink_file' : 'link_file'}
					onClick={this.onChangeLink.bind(this, documentId, !isLinked)}
				/>
			</DropdownMenu>
		)
	}

	render = () => {
		const { folders, language, region, isOpen, objId, objType, documents } = this.props
		const { selectedFolder, isConfirmSwapOpen, swapToDocumentId, isLoading } = this.state

		const selectedFolderId = selectedFolder?.get('id')
		let docs = List()

		documents.forEach((doc) => {
			const docFolderId = doc.get('folderId')
			const folderPath = folderHelper.getPath(docFolderId, folders)

			if (folderPath) {
				doc = doc.set('folderPath', folderPath)
				doc = doc.set('folderName', folders.getIn([docFolderId, 'name']))
			}

			if (!selectedFolderId || docFolderId === selectedFolderId) {
				docs = docs.push(doc)
			}
		})

		return (
			<Modal
				title='documents.link_documents_modal.title'
				isOpen={isOpen}
				footerComponent={this.renderModalFooter()}
				scrollableContent={false}
				hSize='xl'>
				<DocumentsInvestorsView
					folders={folders}
					documents={docs}
					selectedFolder={selectedFolder}
					userLang={language}
					region={region}
					onBadgeClick={this.onBadgeClick}
					withoutPanel={true}
					renderDropdown={this.renderMoreActionMenu}
					showToggleSwitch={true}
					noMinHeight={true}
					isToggleSwitchChecked={this.isDocumentLinked}
					onToggleSwitchChange={this.onChangeLink}
					isToggleSwitchDisabled={() => isLoading}
					onLoadDocuments={this.fetchData}
					folderTreeContainer={() => {
						return (
							<ItemTreeContainer
								onRef={(ref) => (this.itemTreeContainerRef = ref)}
								items={folders}
								isInvestorMode={true}
								onItemSelected={this.onFolderSelected}
								selectedItem={selectedFolder}
								onItemRef={this.onTreeItemRef}
							/>
						)
					}}
				/>

				<ConfirmModal
					title='documents.link_document_modal.confirm_document_swap.title'
					message='documents.link_document_modal.confirm_document_swap.message'
					onConfirm={this.swapDocument}
					onDecline={this.closeConfirmSwapModal}
					isOpen={isConfirmSwapOpen}
				/>
			</Modal>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		folders: store.folders?.get('folders'),
		documents: store.documents.getIn(['paginatedDocuments', 'documents']),
		totalNumOfDocuments: store.documents.getIn(['paginatedDocuments', 'totalNumOfDocuments']),
		companyId: store.company.company.id,
		region: store.company.company.region,
		language: store.i18n.language
	}
}

const mapActionsToProps = {
	downloadDocument,
	listDocumentsWithPagination,
	linkDocument,
	unlinkDocument,
	clearDocumentsList,
	clearPagination
}

export default connect(mapStoreToProps, mapActionsToProps)(LinkDocumentsModalContainer)
