import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import IconButton from '../../../app/mui-components/button/icon-button'
import AgGrid from '../../../app/dumb-components/shared/ag-grid/ag-grid'
import { GRID_MODE_PANEL } from '/shared/constants'
import { DropDown } from '../../../app/mui-components/dropdown/DropDown'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUserCriteriaDealflow, changeStatus } from '../../../app/actions/dealflow.actions'
import Moment from '../../../app/modules/moment.module'
import MenuItemIcon from '../../../app/mui-components/dropdown/menu/menu-item-icon'
import { openModal } from '../../actions/modals.actions'
import { DEALFLOW_EDIT_USER_CRITERIA_MODAL } from '../../constants/modals'

export default function CriteriaListsGrid() {
	const { t } = useTranslation()
	const [rowData, setRowData] = useState(null)
	const dealflowUserCriteriaLists = useSelector((state) => state.dealflow.userCriteriaLists)
	const dispatch = useDispatch()

	useEffect(() => {
		let _rowData
		if (dealflowUserCriteriaLists.size > 0) {
			_rowData = dealflowUserCriteriaLists
				.map((item) => {
					return {
						created: Moment(item.get('createdAt')).format('YYYY-MM-DD'),
						description: item.get('name'),
						status: item.get('active'),
						id: item.get('id')
					}
				})
				.toJS()
		}
		setRowData(_rowData)
	}, [dealflowUserCriteriaLists])

	const onDeleteDealflowCriteria = (id, changeStepCallback) => {
		dispatch(deleteUserCriteriaDealflow(id, changeStepCallback))
	}

	const onManageDealflowCriteria = (id) => {
		dispatch(openModal(DEALFLOW_EDIT_USER_CRITERIA_MODAL, { id: id }, true))
	}
	const shared = {
		sortable: false,
		suppressMenu: true,
		maxWidth: 103
	}

	return (
		<>
			{rowData && (
				<Grid item lg={12} md={12}>
					<AgGrid
						noEvenRowBackground
						ordinaryTextColor
						mode={GRID_MODE_PANEL}
						rowData={rowData}
						components={{
							statusCell: (params) => {
								const [status, setStatus] = useState(params.data.status)

								const toggleStatus = () => {
									let _status
									if (params.data.status === true) {
										_status = false
									} else {
										_status = true
									}

									dispatch(
										changeStatus(_status, params.data.id, () => {
											setStatus(_status)
										})
									)
								}

								return (
									<Link
										underline='none'
										component='button'
										variant='body2'
										color={'text.positive'}
										onClick={toggleStatus}>
										{status === true
											? t('dealflow.criteria_modal.status_button_on')
											: t('dealflow.criteria_modal.status_button_off')}
									</Link>
								)
							},
							optionCell: (params) => {
								return (
									<DropDown
										alignMenu='right'
										button={({ params }) => <IconButton noBorder icon='faEllipsisV' {...params} />}>
										<MenuItemIcon
											onClick={() => {
												onManageDealflowCriteria(params.data.id)
											}}
											icon={'faCog'}
											listItemTid={
												'dealflow.criteria_modal.options_dropdown.manage_criteria_list'
											}
										/>

										<MenuItemIcon
											onClick={() => {
												onDeleteDealflowCriteria(params.data.id)
											}}
											icon={'faTrashAlt'}
											listItemTid={
												'dealflow.criteria_modal.options_dropdown.delete_criteria_list'
											}
										/>
									</DropDown>
								)
							}
						}}
						columnDefs={[
							{
								field: 'created',
								headerName: t('dealflow.criteria_modal.list.header1'),
								maxWidth: 120,
								sortable: shared.sortable,
								suppressMenu: shared.suppressMenu
							},
							{
								field: 'description',
								headerName: t('dealflow.criteria_modal.list.header2'),
								flex: 1,
								sortable: shared.sortable,
								suppressMenu: shared.suppressMenu
							},
							{
								field: 'status',
								headerName: t('dealflow.criteria_modal.list.header3'),
								maxWidth: shared.maxWidth,
								cellRenderer: 'statusCell',
								sortable: shared.sortable,
								suppressMenu: shared.suppressMenu
							},
							{
								field: 'options',
								headerName: '',
								cellRenderer: 'optionCell',
								maxWidth: 85,
								sortable: shared.sortable,
								suppressMenu: shared.suppressMenu
							}
						]}
					/>
				</Grid>
			)}
		</>
	)
}
