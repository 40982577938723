import React, { Component } from 'react'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'
import { injectIntl, intlShape } from 'react-intl'
import { saveSubscriptions, refillPrepaid } from '../../actions/subscriptions.actions'
import PrepaiedPanel from '../../dumb-components/subscriptions/prepaid-panel/prepaid-panel'
import ConfirmModalContainer from '../shared/confirm.container'

import { PRODUCT_ACCOUNT_PLAN_FREE_ID, SUBSCRIPTION_ESIGN_BANKID_COST } from '/shared/constants'

class PrepaidContainer extends Component {
	state = {
		isLoading: false,
		isConfirmModalOpen: false
	}

	onChangeSubscription = (field, value) => {
		let { subscription } = this.props
		const { saveSubscriptions } = this.props
		subscription = subscription.set(field, value)
		saveSubscriptions(subscription.get('companyId'), subscription)
	}

	onPurchase = () => {
		this.setState({ isLoading: true, isConfirmModalOpen: true })
	}

	doPurchase = () => {
		const { refillPrepaid } = this.props
		this.setState({ isConfirmModalOpen: false })
		refillPrepaid(
			() => {
				this.setState({ isLoading: false })
			},
			() => {
				// TODO: error callback logic
			}
		)
	}

	cancelPurchase = () => {
		this.setState({ isConfirmModalOpen: false, isLoading: false })
	}

	render = () => {
		const { subscription, onChangeAutorefill, eSigningEnabled, companyDueForCancellation } = this.props
		const { isLoading, isConfirmModalOpen } = this.state
		const subscribingToFreeAccount =
			subscription.get('accountPlanId', PRODUCT_ACCOUNT_PLAN_FREE_ID) === PRODUCT_ACCOUNT_PLAN_FREE_ID
		const prepaidAmount = subscription.get('prepaidAmount', 0)
		const prepaidSelectedAmount = subscription.get('prepaidSelectedAmount')
		const prepaidAutoRefill = subscription.get('prepaidAutoRefill')
		const stripeData = subscription.get('stripeData')
		const canPurchase = stripeData && stripeData.getIn(['sources', 'total_count']) > 0 && eSigningEnabled
		let warning, info, infoIcon

		if (stripeData && stripeData.getIn(['sources', 'total_count']) === 0 && !companyDueForCancellation) {
			warning = 'subscriptions.warning.complete_card_and_address_details'
		}
		const isFreeOfCharge = subscription && subscription.get('isFreeOfCharge')

		if (subscribingToFreeAccount) {
			return null
		}

		return (
			<React.Fragment>
				<PrepaiedPanel
					prepaidAmount={prepaidAmount}
					prepaidSelectedAmount={prepaidSelectedAmount}
					prepaidAutoRefill={prepaidAutoRefill}
					onChangeSubscription={this.onChangeSubscription}
					isLoading={isLoading}
					onClickPurchase={this.onPurchase}
					isFreeOfCharge={isFreeOfCharge}
					warning={warning}
					info={info}
					infoIcon={infoIcon}
					canPurchase={canPurchase}
					disabled={isFreeOfCharge || companyDueForCancellation}
					eSigningEnabled={eSigningEnabled}
					onChangeAutorefill={onChangeAutorefill}
					pricePerBankIdSigning={SUBSCRIPTION_ESIGN_BANKID_COST}
				/>

				<ConfirmModalContainer
					title='subscriptions.confirm_purchase.title'
					message='subscriptions.confirm_purchase.question'
					onConfirm={this.doPurchase}
					onDecline={this.cancelPurchase}
					isOpen={isConfirmModalOpen}
				/>
			</React.Fragment>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		subscription: store.subscriptions.get('subscription'),
		eSigningEnabled: store.subscriptions.getIn(['helper', 'eSigningEnabled']),
		companyDueForCancellation: store.company.company && store.company.company.toBeCanceled
	}
}

const mapActionsToProps = {
	saveSubscriptions,
	refillPrepaid
}

export default injectIntl(connect(mapStoreToProps, mapActionsToProps)(PrepaidContainer))
