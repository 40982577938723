import { fromJS, List, Map } from 'immutable'
import {
	TASKS_LIST,
	TASKS_SET_VISIBLE,
	TASKS_UPDATE_LOCAL,
	TASKS_CREATE,
	TASKS_CREATE_LOCAL,
	TASKS_FETCH,
	TASKS_SAVE,
	TASKS_SAVE_START,
	TASKS_DELETE,
	TASKS_TASK_DIRTY,
	TASKS_UPDATE_PROJECT,
	TASKS_LIST_BY_COMPANIES,
	TASKS_SET_FILTERS,
	TASKS_UNSET_FILTERS,
	TASKS_SELECT,
	TASKS_SET_DEFAULT_FILTERS,
	TASKS_UPDATE_DEFAULT_FILTER,
	TASKS_RESET_DEFAULT_VALUES,
	TASKS_LIST_BY_OBJECTS,
	TASKS_CREATE_EXTERNAL_TASK_LOCAL,
	TASKS_CREATE_EXTERNAL,
	TASKS_UPDATE_EXTERNAL_TASKS_LOCAL,
	TASKS_HARD_DELETE,
	COMPANY_RESET_REDUCER,
	TASKS_DELETE_EXTERNAL
} from '../actions/types'

import { FACTORY_DEFAULT_FILTER_VALUES } from '../constants/tasks'

const INITIAL_STATE = fromJS({
	allTasks: null,
	listByCompanies: null,
	listByObjects: {
		mapObjIdToTasks: {},
		tasksMap: {}
	},
	task: null,
	visibleTasks: null,
	tasksExcludedFromFiltering: [],
	hasAppliedFilters: false,
	filterBy: [
		{ source: 'dateProp', values: FACTORY_DEFAULT_FILTER_VALUES.get('dateProp') },
		{ source: 'order', values: FACTORY_DEFAULT_FILTER_VALUES.get('order') },
		{ source: 'status', values: FACTORY_DEFAULT_FILTER_VALUES.get('status') },
		{ source: 'assigne', values: FACTORY_DEFAULT_FILTER_VALUES.get('assigne') },
		{ source: 'tag', values: [], isSimpleFilter: true },
		{ source: 'showOnlyTrashed', values: FACTORY_DEFAULT_FILTER_VALUES.get('showOnlyTrashed') }
	],
	defaultFilters: FACTORY_DEFAULT_FILTER_VALUES,
	isDirty: Map()
})

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action

	switch (type) {
		case TASKS_LIST:
			return state.set('allTasks', payload)
		case TASKS_SET_VISIBLE:
			state = state.set('hasAppliedFilters', payload.hasAppliedFilters)
			return state.set('visibleTasks', payload.tasks)
		case TASKS_UPDATE_LOCAL: {
			if (payload) {
				const taskId = payload.get('id')
				const allTasksIndex = (state.get('allTasks') || List()).findIndex((task) => task.get('id') === taskId)
				if (allTasksIndex >= 0) {
					state = state.setIn(['allTasks', allTasksIndex], payload)
					state = state.update('allTasks', (allTasks) => {
						return allTasks.sortBy((l) => {
							return l.get('orderIndex')
						})
					})
				}

				if (state.hasIn(['listByObjects', 'tasksMap', taskId])) {
					state = state.setIn(['listByObjects', 'tasksMap', taskId], payload)
				}

				return state.set('task', payload)
			}
			return state
		}
		case TASKS_SELECT: {
			const task = state.get('allTasks', List()).find((task) => {
				return task.get('id') === payload
			})
			return state.set('task', task)
		}
		case TASKS_CREATE: {
			const task = payload
			let allTasks = state.get('allTasks')

			allTasks = allTasks.map((existingTask) => {
				if (existingTask.get('id') === task.get('id')) {
					existingTask = task
				}
				return existingTask
			})

			const updatedState = state.set('allTasks', allTasks)
			return updatedState.set('task', task)
		}
		case TASKS_CREATE_LOCAL: {
			const task = payload.get('task')
			const insertAtIndex = payload.get('insertAtIndex')
			let allTasks = state.get('allTasks')
			if (insertAtIndex === -1) {
				allTasks = allTasks.insert(allTasks.getIn([allTasks.size, 'orderIndex']) + 100, task)
			} else if (insertAtIndex || insertAtIndex === 0) {
				allTasks = allTasks.insert(insertAtIndex, task)
			}
			state = state.update('tasksExcludedFromFiltering', (tasks) => tasks.push(task.get('id')))
			const updatedState = state.set('allTasks', allTasks)
			return updatedState.set('task', task)
		}
		case TASKS_FETCH: {
			if (payload && payload.has('id')) {
				state = state.set('task', payload)
			} else if (payload === null) {
				state = state.set('task', null)
			}
			return state
		}
		case TASKS_SAVE_START:
			return state.setIn(['isDirty', payload], false)
		case TASKS_SAVE: {
			if (state.getIn(['isDirty', payload.get('id')])) {
				return state
			}

			const allTasksIndex = (state.get('allTasks', List()) || List()).findIndex(
				(task) => task.get('id') === payload.get('id')
			)
			if (allTasksIndex >= 0) {
				state = state.setIn(['allTasks', allTasksIndex], payload)
			}
			if (state.hasIn(['listByObjects', 'tasksMap', payload.get('id')])) {
				state = state.setIn(['listByObjects', 'tasksMap', payload.get('id')], payload)
			}
			if (state.getIn(['task', 'id']) === payload.get('id')) {
				state = state.set('task', payload)
			}
			return state
		}

		case TASKS_DELETE: {
			const task = state.get('task')
			const allTasks = state.get('allTasks').map((obj) => {
				if (obj.get('id') === task?.get('id')) {
					obj = obj.set('isDeleted', true)
				}
				return obj
			})

			state = state.set('allTasks', allTasks)
			state = state.update('tasksExcludedFromFiltering', (tasksExcludedFromFiltering) =>
				tasksExcludedFromFiltering.filter((taskId) => taskId !== payload)
			)
			return state.set('task', null)
		}

		case TASKS_DELETE_EXTERNAL: {
			return state.update('allTasks', (allTasks) => {
				return allTasks.filter((task) => {
					return task.get('id') !== payload
				})
			})
		}

		case TASKS_HARD_DELETE: {
			let found = false
			const tasksToDelete = payload.get('tasksToDelete')
			const currentSelectedtaskId = payload.get('currentSelectedtaskId')

			state = state.update('allTasks', (alltasks) => {
				return alltasks.filter((task) => {
					if (task.get('id') === currentSelectedtaskId) {
						found = true
					}

					return !tasksToDelete.includes(task.get('id'))
				})
			})

			if (found) {
				return state.set('task', null)
			}

			return state
		}

		case TASKS_TASK_DIRTY:
			return state.setIn(['isDirty', payload], true)
		case TASKS_UPDATE_PROJECT: {
			payload.get('taskIds').forEach((taskId) => {
				const taskIndex = state.get('allTasks').findIndex((doc) => doc.get('id') === taskId)
				state = state.setIn(['allTasks', taskIndex, 'projectId'], payload.get('projectId'))
			})

			state = state.setIn(['task', 'projectId'], payload.get('projectId'))
			return state
		}
		case TASKS_LIST_BY_COMPANIES:
			return state.set('listByCompanies', payload)

		case TASKS_SET_FILTERS: {
			const source = payload.get('source')
			const values = payload.get('values')
			const isSimpleFilter = payload.get('isSimpleFilter')
			let filterBy = state.get('filterBy')
			let sourceRemoved = false

			// Remove previous filter criteria when filitering in simple filter
			if (isSimpleFilter) {
				filterBy = filterBy.filter((obj) => !obj.get('isSimpleFilter'))
				// Remove filter criteria if value is null (null == value have been cleared in advanced filter)
			} else if (!values) {
				filterBy = filterBy.filter((obj) => obj.get('source') !== source)
				sourceRemoved = true
			}

			const index = filterBy.findIndex((obj) => obj.get('source') === source)

			if (index >= 0) {
				filterBy = filterBy.set(index, payload)
			} else if (!sourceRemoved) {
				filterBy = filterBy.push(payload)
			}

			state = state.set('tasksExcludedFromFiltering', List())
			return state.set('filterBy', filterBy)
		}

		case TASKS_UNSET_FILTERS: {
			const sources = payload
			const defaultFilters = state.get('defaultFilters')
			let filterBy = state.get('filterBy')

			filterBy = filterBy.filter((filter) => {
				let keep = false

				sources.forEach((source) => {
					if (source === filter.get('source') || filter.get('isSimpleFilter')) {
						if (defaultFilters.has(source)) {
							keep = true
						}
					}
				})

				return keep
			})

			filterBy = filterBy.map((filter) => {
				sources.forEach((source) => {
					if (source === filter.get('source')) {
						const defaultFilter = defaultFilters.get(source)
						if (defaultFilter) {
							filter = filter.set('values', defaultFilter)
						}
					}
				})

				return filter
			})

			return state.set('filterBy', filterBy)
		}

		case TASKS_SET_DEFAULT_FILTERS: {
			const filters = payload
			let defaultFilters = state.get('defaultFilters')

			filters.forEach((values, key) => {
				defaultFilters = defaultFilters.set(key, values)
			})

			return state.set('defaultFilters', defaultFilters)
		}

		case TASKS_UPDATE_DEFAULT_FILTER: {
			const source = payload.get('source')
			const values = payload.get('values')
			return state.setIn(['defaultFilters', source], values)
		}

		case TASKS_RESET_DEFAULT_VALUES: {
			let filterBy = state.get('filterBy')

			filterBy = filterBy.map((filter) => {
				const source = filter.get('source')

				if (FACTORY_DEFAULT_FILTER_VALUES.has(source)) {
					filter = filter.set('values', FACTORY_DEFAULT_FILTER_VALUES.get(source))
				}

				return filter
			})

			state = state.set('filterBy', filterBy)
			return state.set('defaultFilters', FACTORY_DEFAULT_FILTER_VALUES)
		}

		case TASKS_LIST_BY_OBJECTS: {
			return state.set('listByObjects', payload)
		}

		case TASKS_CREATE_EXTERNAL_TASK_LOCAL: {
			let task = payload.get('task')
			const taskId = task.get('id')
			const insertAtIndex = payload.get('insertAtIndex')
			const objId = payload.get('objId')

			task = task.set('createdLocalOnly', true)

			if (!state.hasIn(['listByObjects', 'mapObjIdToTasks', objId])) {
				state = state.setIn(['listByObjects', 'mapObjIdToTasks', objId], List())
			}

			state = state.updateIn(['listByObjects', 'mapObjIdToTasks', objId], (taskIds) => taskIds.push(taskId))

			state = state.setIn(['listByObjects', 'tasksMap', taskId], task)
			return state.set('task', task)
		}

		case TASKS_CREATE_EXTERNAL: {
			const task = payload
			let allTasks = state.get('allTasks', List())

			allTasks =
				allTasks &&
				allTasks.map((existingTask) => {
					if (existingTask.get('id') === task.get('id')) {
						existingTask = task
					}
					return existingTask
				})

			state = state.set('allTasks', allTasks)

			if (state.hasIn(['listByObjects', 'tasksMap', task.get('id')])) {
				state = state.setIn(['listByObjects', 'tasksMap', task.get('id')], task)
				state = state.removeIn(['listByObjects', 'tasksMap', task.get('id'), 'createdLocalOnly'])
			}

			return state.set('task', task)
		}

		case TASKS_UPDATE_EXTERNAL_TASKS_LOCAL: {
			return state.set('listByObjects', payload)
		}

		case COMPANY_RESET_REDUCER: {
			return INITIAL_STATE
		}

		default:
			return state
	}
}
