import React, { PureComponent } from 'react'
import { func, bool } from 'prop-types'
import styled, { css } from 'styled-components'
import { lighten, darken } from 'polished'

import ButtonBase from '../button-v2/src/base/button-base'
import Icon from '../icon/icon'
import Text from '../text/text'

const Button = styled(ButtonBase)`
	display: flex;
	cursor: pointer;
	align-items: center;
	min-height: 36px;
	padding: 0 ${(props) => props.theme.spacing[4]};

	> :first-child {
		margin-right: ${(props) => props.theme.spacing[3]};
	}

	:hover:not(:disabled) {
		> * {
			transition: 300ms color;
			color: ${(props) => darken(0.15, props.theme.colors.midGrey)};
		}
	}

	${({ disabled, theme }) =>
		disabled &&
		css`
			> * {
				color: ${(props) => lighten(0.15, props.theme.colors.midGrey)};
			}
		`}
`

export default class PreviousLeftButton extends PureComponent {
	static propTypes = {
		onClick: func,
		disabled: bool
	}

	render = () => {
		const { onClick, disabled } = this.props

		return (
			<Button onClick={onClick} disabled={disabled}>
				<Icon icon='faLongArrowLeft' size='xl' />
				<Text tid='steps_modal.footer.generic.previous' fontSize='16px' bold={600} />
			</Button>
		)
	}
}
