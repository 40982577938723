import axios from 'axios'
import Cookies from 'universal-cookie'
import apiError from '../errors/api.error'

export default {
	async fetchPrivateCustomer() {
		try {
			const res = await axios.get('/api/subscriptions/private-customers', {
				headers: {
					authorization: `Bearer ${new Cookies().get('accessToken')}`
				}
			})

			return res.data
		} catch (e) {
			throw apiError.create(e.response.data.message)
		}
	}
}
