import React, { PureComponent } from 'react'
import { func, number, object, bool } from 'prop-types'
import styled from 'styled-components'

import ScrollView from '../../shared/layout/scroll-view/scroll-view'
import Text from '../../shared/text/text'
import Checkbox from '../../shared/checkbox/checkbox'
import Box from '../../shared/layout/box/box'
import Button from '../../shared/button/button'

const StyledWrapper = styled.div`
	height: 100%;
`

const StyledStep = styled.div`
	opacity: ${(props) => (props.visible ? 1 : 0)};
	transition: opacity 0.3s ease-in;
	padding-bottom: ${(props) => props.theme.spacing[5]};
`

const NumberingWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${(props) => props.theme.spacing[4]};

	h3 {
		margin-top: 0;
		margin-bottom: 0;
	}
`

const NumberingNumber = styled.div`
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background: ${(props) => props.theme.colors.java};
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: ${(props) => props.theme.spacing[4]};
`

const SectionWrapper = styled.div`
	padding-left: 56px;
`

const StyledWrapperInner = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]} ${(props) => props.theme.spacing[6]};
`

export default class CompanyWizard extends PureComponent {
	static propTypes = {
		step: number,
		renderSearch: func,
		onChange: func,
		representativesComponent: object,
		ToS: bool,
		onAuthorize: func,
		onSetScrollbarRef: func
	}

	static defaultProps = {
		step: 1
	}

	renderStepNumber = (number, titleTid) => {
		return (
			<NumberingWrapper>
				<NumberingNumber>
					<Text tag='h3' color='lightestGrey'>
						{number}
					</Text>
				</NumberingNumber>
				<Text tag='h3' tid={titleTid} />
			</NumberingWrapper>
		)
	}

	renderToS = () => {
		const { onChange, ToS } = this.props
		const agreementUrl = 'https://invono.se/om-oss/anvandarvillkor-och-kundavtal/kundavtal-bolagskonto/'

		return (
			<Box valign='center'>
				<Checkbox mode='modern-big' onChange={onChange} fieldName='ToS' checked={ToS} />
				&nbsp;&nbsp;
				<Text tid='account.wizard.accept.agreement.part.1' />
				&nbsp;
				<a href={agreementUrl} target='_blank' rel='noopener noreferrer'>
					<Text color='muted' tid='account.wizard.accept.agreement.part.2' hasUnderline />
				</a>
			</Box>
		)
	}

	renderStep = (stepNumber, titleTid, component) => {
		const { onSetStepRef, step } = this.props

		return (
			<StyledStep visible={step >= stepNumber} ref={onSetStepRef.bind(null, stepNumber)}>
				<NumberingWrapper>
					<NumberingNumber>
						<Text tag='h3' color='lightestGrey'>
							{stepNumber}
						</Text>
					</NumberingNumber>
					<Text tag='h3' tid={titleTid} />
				</NumberingWrapper>

				<SectionWrapper>{component}</SectionWrapper>
			</StyledStep>
		)
	}

	render = () => {
		const { onSetScrollbarRef, renderSearch, representativesComponent, onAuthorize } = this.props

		return (
			<StyledWrapper>
				<ScrollView scrollbarRef={onSetScrollbarRef} noRightMargin noLeftMargin fillContent alwaysShow>
					<StyledWrapperInner>
						{this.renderStep(1, 'company_wizard.step.1.title', renderSearch())}

						{this.renderStep(2, 'company_wizard.step.2.title', representativesComponent)}

						{this.renderStep(3, 'company_wizard.step.3.title', this.renderToS())}

						{this.renderStep(
							4,
							'company_wizard.step.4.title',
							<Button mode='primary' tid='company_wizard.step.4.btn.verify_with_bankid' onClick={onAuthorize} />
						)}
					</StyledWrapperInner>
				</ScrollView>
			</StyledWrapper>
		)
	}
}
