import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { func } from 'prop-types'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import { useTranslation } from 'react-i18next'
import styled from '@mui/styled-engine'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { InvestmentsContext } from '../investment.container'
import AgGrid from '../../../dumb-components/shared/ag-grid/ag-grid'
import { linkTransactionColumnDefs } from '../../../dumb-components/shared/ag-grid/column-defs/column-defs'
import Moment from '../../../modules/moment.module'
import {
	GRID_MODE_PANEL,
	PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
	PERSONAL_TRANSACTION_TYPE_LEVELING,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
	PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
	PERSONAL_TRANSACTION_TYPE_SPLIT,
	PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
	PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES,
	PERSONAL_TRANSACTION_TYPE_SNAPSHOT,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
	PERSONAL_TRANSACTION_TYPE_GENESIS
} from '/shared/constants'
import { filterTransactions } from '/shared/helpers/linkTransactionsHelper.v2'
import { MY_RECORDS_EDIT_TRANSACTION_MODAL } from '../../../constants/modals'

const StyledYellowBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.highlight.yellow.main,
	padding: theme.spacing(4),
	borderRadius: '8px'
}))

const LinkTransactionGridContainer = ({ previewTransactionCallback }) => {
	const { selectedInvestment, selectedPersonalInvestment } = useContext(InvestmentsContext)
	const transactions = selectedInvestment && selectedInvestment.get('transactions')

	if (!transactions) {
		return null
	}

	const form = useForm()
	const formValues = form.getState().values

	const isEditTransactionModal = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'name']) === MY_RECORDS_EDIT_TRANSACTION_MODAL
	})
	const editModeTransactionId = useSelector((state) => {
		return state.modals.getIn(['activeModal', 'options', 'link'])
	})

	const linkedTransactionId = formValues?.link?.transactionId ? formValues.link.transactionId : null

	const gridRef = useRef()

	const { t } = useTranslation()
	const [colDefs, setColDefs] = useState(linkTransactionColumnDefs(t, previewTransactionCallback))
	const [rowData, setRowData] = useState([])
	// const [rowHeight, setRowHeight]= useState(68)
	const userId = selectedInvestment.get('id')

	useEffect(() => {
		if (transactions) {
			const formTransactionType = form.getFieldState('transactionType').value
			const translatedTransactionType = translateTransactionType(formTransactionType)
			let filteredTransactions = transactions

			//filter all existing linked transactions so that you can't double link a transaction.
			let myPersonalTransactions = selectedPersonalInvestment && selectedPersonalInvestment.get('transactions')

			if (myPersonalTransactions) {
				myPersonalTransactions = myPersonalTransactions.filter((transaction) => {
					return transaction.getIn(['link', 'transactionId'])
				})
				let linkIds = myPersonalTransactions.map((transaction) => {
					return transaction.getIn(['link', 'transactionId'])
				})

				// This doesn't filter out the linked transaction in edit mode.
				if (isEditTransactionModal) {
					linkIds = linkIds.filter((linkId) => linkId !== editModeTransactionId)
				}

				filteredTransactions = filteredTransactions.filter((transaction) => {
					return !linkIds.includes(transaction.get('id'))
				})
			}

			//A SHOW A SELECTION OF ALL TRANSACTIONS DEPENDING ON THE SELECTED TRANSACTION TYPE IN THE MODAL
			filteredTransactions = filteredTransactions.filter((transaction) => {
				if (translatedTransactionType === 'TRANSFER-2') {
					return transaction.get('type') === 'TRANSFER-2' || transaction.get('type') === 'TRANSFER'
				}
				return (
					transaction.get('type') === translatedTransactionType ||
					transaction.getIn(['handlerData', 'emissionType']) === translatedTransactionType ||
					PERSONAL_TRANSACTION_TYPE_SNAPSHOT === translatedTransactionType
				)
			})

			if (formTransactionType === PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY) {
				filteredTransactions = filteredTransactions.filter(
					(transaction) => transaction.getIn(['handlerData', 'investmentIdTo']) === userId
				)
			}

			if (formTransactionType === PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL) {
				filteredTransactions = filteredTransactions.filter(
					(transaction) => transaction.getIn(['handlerData', 'investmentIdFrom']) === userId
				)
			}

			if (formTransactionType === PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER) {
				filteredTransactions = filteredTransactions.filter(
					(transaction) =>
						transaction.getIn(['handlerData', 'investmentIdFrom']) !== userId &&
						transaction.getIn(['handlerData', 'investmentIdTo']) !== userId
				)
			}

			//Filter away transactions that are not applicable to my records
			const tomato = filterTransactions(filteredTransactions.toJS(), userId)
			filteredTransactions = fromJS(tomato)

			const newTransactions =
				filteredTransactions &&
				filteredTransactions.map((transaction) => {
					const isLinked = linkedTransactionId === transaction.get('id') ? true : false

					if (
						isLinked &&
						form.getState().dirty &&
						Object.keys(form.getState().dirtyFields).find((field) => field !== 'link') &&
						!form.getState().values.link.isDirty
					) {
						form.mutators.setIsDirty()
					}

					return {
						linkButton: {
							id: transaction.get('id'),
							link: {
								transactionId: isLinked && transaction.get('id'),
								isDirty: isLinked && form.getState().values.link.isDirty
							}
						},
						date: Moment(transaction.get('date')).format('L'),
						description:
							t(transaction.getIn(['descriptionData', 'title'])) +
							': ' +
							transaction.getIn(['descriptionData', 'summary'])
					}
				})

			if (newTransactions) {
				setRowData(newTransactions.toJS())
			}
		}
	}, [selectedInvestment, linkedTransactionId, formValues])

	useEffect(() => {
		const formState = form.getState()
		if (
			formState.dirty &&
			formState.values?.link?.transactionId &&
			!formState.values.link.isDirty &&
			!form.getFieldState('link')
		) {
			form.registerField('link', (fieldState) => {
				const { change } = fieldState

				change({
					transactionId: formState.values.link.transactionId,
					isDirty: formState.values.link.isDirty
				})
			})

			form.mutators.setIsDirty()
		}
	}, [form.getState().dirty])

	const translateTransactionType = (transactionType) => {
		switch (transactionType) {
			case PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE:
				return 'BONUS-ISSUE'
			case PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY:
			case PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL:
			case PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER:
				return 'TRANSFER-2'
			case PERSONAL_TRANSACTION_TYPE_SPLIT:
				return 'SPLIT'
			case PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES:
				return 'CHANGE-VOTES'
			case PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL:
				return 'REDUCTION-OF-SHARE-CAPITAL'
			case PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES:
				return 'CONVERSION-OF-SHARES'
			case PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE:
				return 'RIGHTS_ISSUE'
			case PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE:
				return 'DIRECTED_NEW_ISSUE'
			case PERSONAL_TRANSACTION_TYPE_LEVELING:
				return 'EMISSION-LEVELING'
			case PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES:
				return 'UNKNOWN_EMISSION_TYPE_1'
			case PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS:
				return 'UNKNOWN_EMISSION_TYPE_2'
			case PERSONAL_TRANSACTION_TYPE_SNAPSHOT:
				return PERSONAL_TRANSACTION_TYPE_SNAPSHOT
			case PERSONAL_TRANSACTION_TYPE_GENESIS:
				return 'GENESIS'
		}
	}

	/*
	const getRowHeight = useCallback((params) => {
			if (params.node.rowHeight > 48) {
				console.log('params', params.node.rowHeight)
				setRowHeight(params.node.rowHeight)
			}
		})
	*/

	const rowClassRules = useMemo(() => {
		return {
			'linkButton-linked': (params) => {
				const { link } = params.data.linkButton
				const { transactionId, isDirty } = link

				return transactionId && !isDirty
			},
			'linkButton-link-broken': (params) => {
				const { link } = params.data.linkButton
				const { transactionId, isDirty } = link

				return transactionId && isDirty
			}
		}
	})

	const gridOptions = {
		rowClass: 'link-transaction-row'
	}

	return (
		<>
			{rowData.length === 0 ? (
				<StyledYellowBox>
					<Typography variant='body2'>
						{t('investments.my_records.modal.add_transaction.no_company_transactions.paragraph1')}
					</Typography>
				</StyledYellowBox>
			) : (
				<Card sx={{ height: '100%' }}>
					<CardContent variant='panel' sx={{ p: '0 !important', height: '100%' }}>
						<AgGrid
							mode={GRID_MODE_PANEL}
							rowData={rowData}
							columnDefs={colDefs}
							gridRef={gridRef}
							rowHeight={68}
							// rowHeight={rowHeight}
							// getRowHeight={getRowHeight}
							noPagination
							suppressContextMenu
							rowClassRules={rowClassRules}
							noEvenRowBackground
							overflowAuto
							gridOptions={gridOptions}
						/>
					</CardContent>
				</Card>
			)}
		</>
	)
}

LinkTransactionGridContainer.propTypes = {
	previewTransactionCallback: func
}

export default LinkTransactionGridContainer
