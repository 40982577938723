import req from '../modules/request.module'
import {
	ADVERTISING_CREATE_ADVERT,
	ADVERTISING_UPDATE_ADVERT,
	ADVERTISING_LIST_ADVERTS,
	ADVERTISING_DELETE_ADVERT
} from './types'
import { addErrorNotification, addInfoNotification } from './notify.actions'

/**
 *
 * @param {string} forView
 * @param {function} callback
 */
export function fetchAdverts(forView, callback) {
	return async function () {
		try {
			const response = await req.get(`/gateway/adverts/section/${forView}`)
			callback(undefined, response.data)
		} catch (error) {
			callback(error)
		}
	}
}

export function fetchCompanyAdverts(callback) {
	return async function (dispatch) {
		try {
			const response = await req.get(`/gateway/adverts`)
			dispatch({ type: ADVERTISING_LIST_ADVERTS, payload: response.data })
			callback?.(undefined, response.data)
		} catch (error) {
			callback?.(error)
		}
	}
}

export function fetchAdvert(advertId, callback) {
	return async function () {
		try {
			const response = await req.get(`/gateway/adverts/${advertId}`)
			callback(undefined, response.data)
		} catch (error) {
			callback(error)
		}
	}
}

export function createAdvert(advert, callback) {
	return async function (dispatch) {
		try {
			const response = await req.post('/gateway/adverts/', advert)
			dispatch({ type: ADVERTISING_CREATE_ADVERT, payload: response.data })
			dispatch(addInfoNotification({ tid: 'advertising.notifications.advert_created' }))
			callback(undefined, response.data)
		} catch (error) {
			console.log('error', error)
			dispatch(addErrorNotification({ tid: 'advertising.notifications.advert_creation_failed' }))
			callback(error)
		}
	}
}

export function updateAdvert(advertId, advert, callback) {
	return async function (dispatch) {
		try {
			const response = await req.put(`/gateway/adverts/${advertId}`, advert)
			dispatch({ type: ADVERTISING_UPDATE_ADVERT, payload: response.data })
			dispatch(addInfoNotification({ tid: 'advertising.notifications.advert_updated' }))
			callback(undefined, response.data)
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'advertising.notifications.advert_updating_failed' }))
			callback(error)
		}
	}
}

export function deleteAdvert(advertId, callback) {
	return async function (dispatch) {
		try {
			await req.delete(`/gateway/adverts/${advertId}`)
			dispatch({ type: ADVERTISING_DELETE_ADVERT, payload: advertId })
			dispatch(addInfoNotification({ tid: 'advertising.notifications.advert_deleted' }))
			callback?.()
		} catch (error) {
			dispatch(addErrorNotification({ tid: 'advertising.notifications.advert_deletion_failed' }))
		}
	}
}
