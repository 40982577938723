import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func, oneOf } from 'prop-types'
import { listProjects } from '../../actions/projects.actions'
import MoveItemModal from '../../dumb-components/shared/move-item-modal/move-item-modal'
import ItemTreeContainer from '../shared/item-tree.container'
import { updateTaskLocal, transferTask, fetchTask, listExternalTasks, listTasks } from '../../actions/tasks.actions'
import { closeModal } from '../../actions/modals.actions'
import { TASK_MOVE_TASK_MODAL } from '../../constants/modals'

class TasksMoveTaskContainer extends Component {
	state = {
		selectedProject: null
	}

	static propTypes = {
		taskMode: oneOf(['new', 'edit'])
	}

	static defaultProps = {
		taskMode: 'edit'
	}

	componentDidMount = () => {
		const { companyId } = this.props

		if (companyId) {
			this.fetchProjects()
		}
	}

	componentDidUpdate = (prevProps) => {
		const { companyId } = this.props

		if (companyId && prevProps.comapnyId !== companyId) {
			this.fetchProjects()
		}
	}

	fetchProjects = () => {
		const { projects, listProjects } = this.props

		if (!projects || projects.size === 0) {
			//listProjects();
		}
	}

	onProjectMove = () => {
		const {
			onClose,
			selectedProjectId,
			updateTaskLocal,
			transferTask,
			fetchTask,
			taskMode,
			mode,
			listExternalTasks,
			listTasks,
			objId
		} = this.props
		const { selectedProject } = this.state
		let { task } = this.props

		const tasksToTransfer = [task.get('id')]
		const moveToProjectId = selectedProject.get('id') || null

		if (taskMode === 'edit') {
			// IPS-4888 - Update projectId in the task so that the
			// Project badge shows up in the tasks list immediately
			updateTaskLocal(task.set('projectId', moveToProjectId))

			transferTask(tasksToTransfer, moveToProjectId, selectedProjectId, () => {
				if (mode === 'external') {
					listExternalTasks(objId, () => {
						task = task.set('projectId', moveToProjectId)
						fetchTask(task.get('id'))
					})
				} else {
					listTasks(selectedProjectId, () => {
						task = task.set('projectId', moveToProjectId)
						fetchTask(task.get('id'))
					})
				}
			})
		} else {
			task = task.set('projectId', moveToProjectId)
			updateTaskLocal(task)
		}

		this.onClose()
	}

	onProjectSelected = (project) => {
		this.setState({ selectedProject: project })
	}

	onClose = () => {
		const { closeModal } = this.props

		this.setState({ selectedProject: null })

		closeModal()
	}

	render = () => {
		const { isOpen, task, projects } = this.props
		const { selectedProject } = this.state
		const project =
			selectedProject || (task && projects && projects.find((project) => project.get('id') === task.get('projectId')))

		if (!isOpen) {
			return null
		}

		return (
			<MoveItemModal
				titleTid='tasks.move.task.modal.title'
				isOpen={isOpen}
				onMove={this.onProjectMove}
				onClose={this.onClose}
				itemTreeContainerRenderer={() => {
					return (
						<ItemTreeContainer
							items={projects}
							onItemSelected={this.onProjectSelected}
							selectedItem={project}
							rootItemTid='caterogies.root'
							renderHoverText={true}
							permission='ALLOW_CREATE_TASK'
						/>
					)
				}}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		companyId: store.company && store.company.company && store.company.company.id,
		task: store.tasks.get('task'),
		projects: store.projects.get('projects'),
		selectedProjectId: store.projects.get('selectedProjectId'),
		isOpen: store.modals.getIn(['activeModal', 'name']) === TASK_MOVE_TASK_MODAL
	}
}

const mapActionsToProps = {
	updateTaskLocal,
	transferTask,
	fetchTask,
	listProjects,
	listExternalTasks,
	listTasks,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(TasksMoveTaskContainer)
