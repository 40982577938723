import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, oneOf, func } from 'prop-types'
import { Map, List } from 'immutable'
import { hideErrorTooltip, showErrorTooltip } from '../../dumb-components/shared/tooltip/tooltip.helpers'
import ForgotPassword from '../../dumb-components/credentials/middle-components/forgot-password'
import { validateEmail, validatePassword, validateAuthCode } from '../../modules/validation.module'
import { forgotPassword } from '../../actions/credentials.actions'

import { EMAIL_FIELD, CODE_FIELD, PASSWORD_FIELD, PASSWORD_VERIFY_FIELD } from '../../constants/credentials'

const STEPS = {
	EMAIL: 0,
	CODE: 1,
	PASS: 2
}

class ForgotPasswordContainer extends Component {
	state = {
		formData: Map(),
		hasError: Map(),
		actionBtnTid: 'credentials.forgotpw.btn.send',
		actionBtnLoading: false
	}

	static propTypes = {
		onCancel: func
	}

	onChange = (fieldName, value) => {
		const { onChange } = this.props
		let { formData } = this.state

		if (fieldName === CODE_FIELD) {
			value = value.trim()
		}

		formData = formData.set(fieldName, value)
		this.onHideTooltip(fieldName)
		this.setState({ formData })

		onChange && onChange(fieldName, value)
	}

	onClickActionBtn = () => {
		const { formData } = this.state
		const { step, sessionId, forgotPassword } = this.props

		let valid = false

		if (step === STEPS.EMAIL) {
			valid = this.validateEmail()
		} else if (step === STEPS.CODE) {
			valid = this.validateCode()
		} else if (step === STEPS.PASS) {
			valid = this.validatePassword()
		}

		if (valid) {
			this.setState({ actionBtnLoading: true })
			forgotPassword(sessionId, 'FORGOT-PASSWORD', formData, () => {
				this.setState({
					actionBtnTid: 'credentials.forgotpw.btn.verify',
					actionBtnLoading: false
				})
			})
		}
	}

	onHideTooltip = (ref) => {
		let { hasError } = this.state
		// If there's no target, fieldname was sent directly
		const fieldName = ref.target ? ref.target.getAttribute('name') : ref
		hasError = hasError.remove(fieldName)
		this.setState({ hasError })
		hideErrorTooltip(this, fieldName)
	}

	onSetTooltipRefs = (refName, ref) => {
		if (ref && refName) {
			this[refName] = ref
		}
	}

	validateEmail = () => {
		const { formData } = this.state
		let hasError = Map()
		let isValid = true

		// Validate Email
		if (!validateEmail(formData.get(EMAIL_FIELD))) {
			hasError = hasError.set(EMAIL_FIELD, List(['credentials.validation.email']))
			showErrorTooltip(this, EMAIL_FIELD)
			isValid = false
		}

		this.setState({ hasError })

		return isValid
	}

	validateCode = () => {
		const { formData } = this.state
		let hasError = Map()
		let isValid = true
		const codeValue = formData.get(CODE_FIELD)

		// Validate code
		if (!validateAuthCode(codeValue)) {
			hasError = hasError.set(CODE_FIELD, List(['credentials.validation.code']))
			showErrorTooltip(this, CODE_FIELD)
			isValid = false
		}

		this.setState({ hasError })

		return isValid
	}

	validatePassword = () => {
		const { formData } = this.state
		let hasError = Map()
		let isValid = true

		// Validate password Regex
		if (!validatePassword(formData.get(PASSWORD_FIELD))) {
			hasError = hasError.set(PASSWORD_FIELD, List(['credentials.validation.password']))
			showErrorTooltip(this, PASSWORD_FIELD)
			isValid = false
			// Validate Password Match
		} else if (formData.get(PASSWORD_FIELD) !== formData.get(PASSWORD_VERIFY_FIELD)) {
			hasError = hasError.set(PASSWORD_VERIFY_FIELD, List(['credentials.validation.password_not_equal']))
			showErrorTooltip(this, PASSWORD_VERIFY_FIELD)
			isValid = false
		}

		this.setState({ hasError })

		return isValid
	}

	render = () => {
		const { formData, hasError, actionBtnTid, actionBtnLoading } = this.state
		const { onCancel, step, status, translationId } = this.props

		return (
			<ForgotPassword
				onChange={this.onChange}
				onClickActionBtn={this.onClickActionBtn}
				onSetTooltipRefs={this.onSetTooltipRefs}
				onHideTooltip={this.onHideTooltip}
				hasError={hasError}
				onCancel={onCancel}
				step={step}
				status={status}
				actionBtnTid={actionBtnTid}
				actionBtnLoading={actionBtnLoading}
				translationId={translationId}
				email={formData && formData.get(EMAIL_FIELD)}
				code={formData && formData.get(CODE_FIELD)}
				password={formData && formData.get(PASSWORD_FIELD)}
				passwordVerify={formData && formData.get(PASSWORD_VERIFY_FIELD)}
			/>
		)
	}
}

function mapStoreToProps(store) {
	return {
		step: store.credentials.get('step'),
		status: store.credentials.get('status'),
		translationId: store.credentials.get('translationId'),
		sessionId: store.credentials.get('sessionId')
	}
}

const mapActionsToProps = {
	forgotPassword
}

export default connect(mapStoreToProps, mapActionsToProps)(ForgotPasswordContainer)
