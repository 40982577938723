import React, { Component } from 'react'
import { bool, func } from 'prop-types'
import ScrollView from '../../dumb-components/shared/layout/scroll-view/scroll-view'
import ScrollViewHeader from '../../dumb-components/shared/scroll-view-header/scroll-view-header'
import ToolbarContainer from './toolbar.container'
import DocumentAdvancedFilterContainer from './document-advanced-filter.container'

export default class RightViewContainer extends Component {
	static propTypes = {
		filterIsOpen: bool,
		onFilterClose: func
	}

	renderRightSplitViewHeader = () => {
		const { location, basePath, params } = this.props
		const querystr = location.search

		return (
			<ScrollViewHeader>
				{params.id && <ToolbarContainer basePath={basePath} params={params} querystr={querystr} />}
			</ScrollViewHeader>
		)
	}

	render = () => {
		const { children, filterIsOpen, onFilterClose } = this.props

		return (
			<ScrollView
				header={this.renderRightSplitViewHeader}
				headerBottomMargin={3}
				showOnHover={true}
				contentTopMargin={filterIsOpen ? 1 : undefined}>
				{!filterIsOpen && children}
				{filterIsOpen && <DocumentAdvancedFilterContainer isOpen={filterIsOpen} onClose={onFilterClose} />}
			</ScrollView>
		)
	}
}
