import React, { PureComponent } from 'react'
import immutablePropTypes from 'react-immutable-proptypes'
import { string, object, oneOfType, bool, func, number, oneOf } from 'prop-types'
import styled from 'styled-components'
import StyledList from '../../shared/list/list'

export default class MeetingList extends PureComponent {
	static propTypes = {
		meetings: immutablePropTypes.list,
		renderMeetingComponent: func
	}

	renderMeeting = (meeting, index) => {
		return this.props.renderMeetingComponent({
			key: index,
			id: meeting.get('id'),
			name: meeting.get('name'),
			startDate: meeting.get('startDate'),
			endDate: meeting.get('endDate'),
			showWarning: meeting.getIn(['computedValues', 'showWarning']),
			templateId: meeting.get('templateId'),
			agendaPublished: meeting.getIn(['computedValues', 'agendaPublished']),
			protocolPublished: meeting.getIn(['computedValues', 'protocolPublished']),
			protocolUploaded: meeting.getIn(['computedValues', 'protocolUploaded']),
			meetingIsFinished: meeting.getIn(['computedValues', 'meetingIsFinished']),
			goToSection: meeting.getIn(['computedValues', 'goToSection']),
			archived: meeting.get('archived'),
			listIndex: index
		})
	}

	render = () => {
		const { meetings } = this.props

		return <StyledList>{meetings && meetings.map(this.renderMeeting)}</StyledList>
	}
}
