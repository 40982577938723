import React, { Component } from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import Select from 'react-select'
import NumericInput from '../../dumb-components/fields/numeric-input'
import { fromJS } from 'immutable'
import { SHARES_DEFAULT_CLASSES, SHARES_DEFAULT_TYPES } from '../../constants/shares'
import { localeConfig } from '../../modules/format-number'
import { getOnlyUppercaseAndLetters } from '../../components/helpers/shares'
import SelectCreatable from '../../dumb-components/fields/select-creatable'

class ShareTypes extends Component {
	static propTypes = {
		intl: intlShape.isRequired
	}

	state = {
		totalNumOfSharesLeft: 0,
		transferLimitationOptions: [],
		marginBtm: 0,
		dropDown: true
	}

	componentDidMount() {
		const { transaction, i18n } = this.props
		this.setTotalSharesLeft(transaction)
		this.setState({
			transferLimitationOptions: [
				{ label: i18n.messages['transfer-limitation.pre-emption'], value: 'pre-emption' },
				{ label: i18n.messages['transfer-limitation.redemption-reservation'], value: 'redemption-reservation' },
				{ label: i18n.messages['transfer-limitation.redemption-disclaimers'], value: 'redemption-disclaimers' },
				{ label: i18n.messages['transfer-limitation.consent-disclaimers'], value: 'consent-disclaimers' },
				{ label: i18n.messages['transfer-limitation.conversion-disclaimer'], value: 'conversion-disclaimer' }
			]
		})
	}

	componentDidUpdate(prevProps) {
		if (this.props.transaction !== prevProps.transaction) {
			this.setTotalSharesLeft(this.props.transaction)
		}
	}

	setTotalSharesLeft(transaction, types) {
		const sharesLeft = this.calculateSharesLeft(transaction, types)
		this.setState({ totalNumOfSharesLeft: sharesLeft })
	}

	getEmptyShareType() {
		return {
			shareClass: null,
			shareType: null,
			type: null,
			numOfShares: null,
			votesPerShare: null,
			transactionLimitations: []
		}
	}

	calculateSharesLeft(transaction, types) {
		if (!transaction) {
			return 0
		}

		if (!types) {
			types = transaction.getIn(['handlerData', 'initialShareData', 'types'])
		}

		let sharesLeft = transaction.getIn(['handlerData', 'initialShareData', 'numOfTotalShares'], 0)

		if (types && types.size > 0) {
			types.forEach((type) => {
				const numOfShares = type.get('numOfShares')
				if (numOfShares) {
					sharesLeft -= parseInt(numOfShares)
				}
			})
		}

		return sharesLeft
	}

	onChangeClassOrType(propName, index, val) {
		let types = this.props.transaction.getIn(['handlerData', 'initialShareData', 'types'])

		if (!types) {
			types = []
		} else {
			types = types.toJS()
		}

		let type = types[index]

		if (!type) {
			types.push(this.getEmptyShareType())
			type = types[types.length - 1]
		}

		const modifiedType = { ...type, [propName]: val }
		modifiedType.type = this.getTypeProp(modifiedType)
		types[index] = modifiedType
		const transaction = this.props.transaction.setIn(['handlerData', 'initialShareData', 'types'], fromJS(types))
		this.props.onChange(transaction)
	}

	getTypeProp(type) {
		const shareClass = type.shareClass
		const shareType = type.shareType

		if (shareClass && shareType) {
			return shareClass + '$' + shareType
		} else if (shareClass) {
			return shareClass + '$'
		} else if (shareType) {
			return '$' + shareType
		} else {
			return '-'
		}
	}

	handleInputChange(index, propName, val) {
		let types = this.props.transaction.getIn(['handlerData', 'initialShareData', 'types'])

		if (!types) {
			types = []
		} else {
			types = types.toJS()
		}

		let type = types[index]

		if (!type) {
			types.push(this.getEmptyShareType())
			type = types[types.length - 1]
		}

		const modifiedType = { ...type, [propName]: val }
		modifiedType.type = this.getTypeProp(modifiedType)
		types[index] = modifiedType
		const transaction = this.props.transaction.setIn(['handlerData', 'initialShareData', 'types'], fromJS(types))
		this.props.onChange(transaction)
	}

	deleteRow(index) {
		const transaction = this.props.transaction.removeIn(['handlerData', 'initialShareData', 'types', index])
		this.props.onChange(transaction)
	}

	formatNumerInput(num) {
		const { formatNumber } = this.props.intl

		if (isNaN(num)) {
			return num
		}
		//return num;
		return formatNumber(num)
	}

	getShareClasses() {
		const { i18n } = this.props
		const types = this.props.transaction.getIn(['handlerData', 'initialShareData', 'types'], fromJS([]))
		const shareClasses = JSON.parse(JSON.stringify(SHARES_DEFAULT_CLASSES)).map((defaultClass) => {
			defaultClass.label = i18n.messages[defaultClass.label] || decodeURIComponent(defaultClass.value)
			return defaultClass
		})

		types.forEach((type) => {
			const sc = type.get('shareClass')
			if (sc) {
				let found = false
				shareClasses.forEach((shareClass) => {
					if (shareClass.value === sc) {
						found = true
					}
				})

				if (!found) {
					shareClasses.push({
						value: sc,
						label: sc
					})
				}
			}
		})

		return shareClasses
	}

	getShareTypes() {
		const { i18n } = this.props
		const types = this.props.transaction.getIn(['handlerData', 'initialShareData', 'types'], fromJS([]))
		const shareTypes = JSON.parse(JSON.stringify(SHARES_DEFAULT_TYPES)).map((defaultType) => {
			defaultType.label = i18n.messages[defaultType.label] || decodeURIComponent(defaultType.value)
			return defaultType
		})

		types.forEach((type) => {
			const st = type.get('shareType')
			if (st) {
				let found = false
				shareTypes.forEach((shareType) => {
					if (shareType.value === st) {
						found = true
					}
				})

				if (!found) {
					shareTypes.push({
						value: st,
						label: st
					})
				}
			}
		})

		return shareTypes
	}

	renderType(type, index, isLast) {
		const { i18n } = this.props
		const totalNumOfSharesLeft = this.state.totalNumOfSharesLeft
		const shareClasses = this.getShareClasses()
		const shareTypes = this.getShareTypes()
		const deletable = !isLast
		const placeholder = !isLast ? '0' : i18n.messages['N_left'].replace('{num}', totalNumOfSharesLeft)
		const classType = type.type ? type.type.split('$') : []

		return (
			<div className='list__item list__item--no-height list__item--pad-top list__item--align-start' key={index}>
				<span className='share-types__share-class'>
					<Select.Creatable
						value={classType.length > 1 ? classType[0] : null}
						options={shareClasses}
						onChange={this.onChangeClassOrType.bind(this, 'shareClass', index)}
						simpleValue={true}
						clearable={false}
						className={index > 2 ? 'Select--up' : ''}
						placeholder={i18n.messages['shares.type_or_select']}
						promptTextCreator={(val) => {
							return `${i18n.messages['generic.form.create']}: ${val}`
						}}
					/>
				</span>

				<span className='share-types__share-type'>
					<SelectCreatable
						value={classType.length > 1 ? classType[1] : classType[0]}
						options={shareTypes}
						simpleValue={true}
						onChange={this.onChangeClassOrType.bind(this, 'shareType', index)}
						placeholder={i18n.messages['shares.type_or_select']}
						onInputChange={getOnlyUppercaseAndLetters}
						className={index > 2 ? 'Select--up' : ''}
						promptTextCreator={(val) => {
							return `${i18n.messages['generic.form.create']}: ${val}`
						}}
					/>
				</span>

				<span className='share-types__num-of-shares'>
					<NumericInput
						type='text'
						className='form-control text--align-right'
						placeholder={placeholder}
						value={type.numOfShares}
						onChange={(val) => {
							this.handleInputChange(index, 'numOfShares', val)
						}}
					/>
				</span>
				<span className='share-types__votes-per-share'>
					<NumericInput
						type='text'
						className='form-control text--align-right'
						placeholder={i18n.messages['shares.per_share']}
						value={type.votesPerShare}
						allowDecimals
						onChange={(val) => {
							this.handleInputChange(index, 'votesPerShare', val)
						}}
					/>
				</span>
				{this.props.setupType === 'b' && (
					<span className='share-types__latest-price'>
						<NumericInput
							type='text'
							className='form-control text--align-right'
							allowDecimals
							value={type.latestPrice}
							onChange={(val) => {
								this.handleInputChange(index, 'latestPrice', val)
							}}
						/>
					</span>
				)}
				<span className='share-types__transaction-limitations'>
					<Select
						value={type.transactionLimitations}
						multi
						clearable={false}
						options={this.state.transferLimitationOptions}
						placeholder={i18n.messages['select_placeholder']}
						onChange={(val) => {
							this.handleInputChange(index, 'transactionLimitations', val.split(','))
						}}
						className={index > 2 ? 'Select--up' : ''}
						simpleValue={true}
					/>
				</span>
				<span className='share-types__delete'>
					{deletable && (
						<button type='button' className='btn btn-default' onClick={this.deleteRow.bind(this, index)}>
							<i className='fa fa-times'></i>
						</button>
					)}
				</span>
			</div>
		)
	}

	render() {
		const { marginBtm } = this.state
		const types = this.props.transaction.getIn(['handlerData', 'initialShareData', 'types'], fromJS([]))
		const rows = types.push(fromJS(this.getEmptyShareType()))

		return (
			<div style={{ marginBottom: marginBtm }}>
				<div className='share-types__list-header'>
					<div className='share-types__share-class'>&nbsp;</div>
					<div className='share-types__share-type'>
						<FormattedMessage id='series' />
					</div>
					<div className='share-types__num-of-shares'>
						<FormattedMessage id='total_number_of_shares' />
					</div>
					<div className='share-types__votes-per-share'>
						<FormattedMessage id='votes' />
					</div>
					{this.props.setupType === 'b' && (
						<div className='share-types__latest-price'>
							<FormattedMessage id='latest_price' />
						</div>
					)}
					<div className='share-types__transaction-limitations'>
						<FormattedMessage id='shares.transaction_restrictions' />
					</div>
				</div>
				<div className='list list--striped list--table'>
					{rows.map((type, index) => {
						return this.renderType(type.toJS(), index, index + 1 === rows.size)
					})}
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return { i18n: state.i18n }
}

ShareTypes = connect(mapStateToProps)(ShareTypes)
ShareTypes = injectIntl(ShareTypes)
export default ShareTypes
