import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'

import { endVoting } from '../../../actions/votings.actions'

class EndVotingComponentContainer extends Component {
	state = {
		isLoading: false
	}

	static propTypes = {
		renderComponent: func
	}

	endVoting = () => {
		const { endVoting, votingId, meetingId } = this.props

		this.setState({ isLoading: true })

		endVoting(meetingId, votingId, () => {
			this.setState({ isLoading: false })
		})
	}

	render = () => {
		const { renderComponent } = this.props
		const { isLoading } = this.state

		return renderComponent({
			endVoting: this.endVoting,
			isLoading
		})
	}
}

const mapStoreToProps = (store) => {
	return {
		votingId: store.votings.getIn(['voting', 'id']),
		meetingId: store.meetings.getIn(['meeting', 'id'])
	}
}

const mapActionsToProps = {
	endVoting
}

export default connect(mapStoreToProps, mapActionsToProps)(EndVotingComponentContainer)
