import React from 'react'
import MenuItemIcon from '../../../../../mui-components/dropdown/menu/menu-item-icon'
import { usePeopleModalContext } from '../../people-modal.context'

export const FloatingFilterMenuItem = () => {
	const { floatingFilter, setFloatingFilter, isFloatingFilterActive } = usePeopleModalContext()
	const tid = floatingFilter
		? 'ag_grid.panel.dropdown_menu.filter.item.hide_filter'
		: 'ag_grid.panel.dropdown_menu.filter.item.show_filter'

	return (
		<MenuItemIcon
			icon='faFilter'
			onClick={() => setFloatingFilter(!floatingFilter)}
			listItemTid={tid}
			notificationBadge={isFloatingFilterActive}
			notificationBadgeAnimate={false}
			notificationBadgeColor={'green'}
		/>
	)
}
