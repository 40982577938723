import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { fromJS, List, Map } from 'immutable'
import { getFullShareTypeLabel } from '../../../helpers/shares'
import Moment from '../../../../modules/moment.module'
import Panel from '../../../../dumb-components/panel'
import TextBox from '../../../framework/textbox'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import { NUMBER_FORMAT_CURRENCY, NUMBER_FORMAT_INTEGER } from '/shared/constants'

class BonusIssueView extends Component {
	state = {
		shareTypes: List()
	}

	componentDidMount = () => { }

	componentDidUpdate = (prevProps) => {
		if (this.props.transaction !== prevProps.transaction || this.props.tmpTransaction !== prevProps.tmpTransaction) {
			this.setShareTypes(this.props.transaction, this.props.tmpTransaction)
		}
	}

	setShareTypes = (transaction, tmpTransaction) => {
		let newShareTypes = List()

		if (tmpTransaction) {
			newShareTypes = tmpTransaction.getIn(['handlerData', 'shareTypes'], List()).filter((obj) => {
				return obj.get('isNew')
			})
		}

		let shareTypes = transaction && transaction.getIn(['shareData', 'types'], List()).concat(newShareTypes)
		this.setState({ shareTypes })
	}

	calcRemainingSharesToDistribute = () => {
		const { tmpTransaction } = this.props

		if (!tmpTransaction) {
			return
		}

		const bonusIssueType = tmpTransaction.getIn(['handlerData', 'type'])
		const shareTypes = tmpTransaction.getIn(['handlerData', 'shareTypes'], List())
		const investments = tmpTransaction.getIn(['handlerData', 'investments'], Map())

		if (bonusIssueType !== 'shares') {
			return
		}

		let numOfSharesToDistribute = 0
		shareTypes.forEach((obj) => {
			if (obj.get('numOfShares') > 0) {
				numOfSharesToDistribute += obj.get('numOfShares')
			}
		})

		let remainingSharesToDistribute = numOfSharesToDistribute
		investments.map((shareTypes) => {
			shareTypes.forEach((obj) => {
				if (obj.get('diff') > 0) {
					remainingSharesToDistribute -= obj.get('diff')
				}
			})
		})

		return remainingSharesToDistribute
	}

	calculateShareCapital = () => {
		const { transaction, tmpTransaction } = this.props

		if (!tmpTransaction) {
			return
		}

		const type = tmpTransaction.getIn(['handlerData', 'type'])

		if (type === 'quota') {
			return (
				transaction.getIn(['shareData', 'shareCapital']) +
				tmpTransaction.getIn(['handlerData', 'increaseShareCapitalBy']) || 0
			)
		}

		const shareTypes = tmpTransaction.getIn(['handlerData', 'shareTypes'])
		const quotaValue = transaction.getIn(['shareData', 'quotaValue'])
		let numOfShares = transaction.getIn(['shareData', 'numOfTotalShares'])

		if (!shareTypes) {
			return
		}

		shareTypes.forEach((obj) => {
			numOfShares += obj.get('numOfShares') || 0
		})

		return numOfShares * quotaValue
	}

	calculateQuotaValue = () => {
		const { transaction, tmpTransaction } = this.props

		if (!tmpTransaction) {
			return
		}

		const type = tmpTransaction.getIn(['handlerData', 'type'])
		const shareTypes = tmpTransaction.getIn(['handlerData', 'shareTypes'], List())

		if (type === 'shares' && shareTypes.size > 0) {
			return transaction.getIn(['shareData', 'quotaValue'])
		} else if (tmpTransaction.getIn(['handlerData', 'increaseShareCapitalBy']) > 0) {
			const shareCapital =
				transaction.getIn(['shareData', 'shareCapital']) +
				tmpTransaction.getIn(['handlerData', 'increaseShareCapitalBy']) || 0
			let numOfShares = transaction.getIn(['shareData', 'numOfTotalShares'])

			shareTypes.forEach((obj) => {
				numOfShares += obj.get('numOfShares') || 0
			})

			return shareCapital / numOfShares
		}
	}

	renderShareType = (shareType, index) => {
		const { tmpTransaction, transaction } = this.props
		const name = getFullShareTypeLabel(shareType.get('type'))
		const existingShareType =
			transaction.getIn(['shareData', 'types'], List()).find((obj) => {
				return obj.get('type') === shareType.get('type')
			}) || Map()
		const currentNumOfShares = existingShareType.get('numOfShares', 0)

		let newNumOfShares = currentNumOfShares
		let numOfSharesMissingOrAdded = 0
		let statusClass = ''

		if (tmpTransaction) {
			const newShareType = tmpTransaction.getIn(['handlerData', 'shareTypes'], List()).find((obj) => {
				return obj.get('type') === shareType.get('type')
			})

			if (newShareType) {
				const numOfIssuedShares = newShareType.get('numOfShares', 0)

				if (numOfIssuedShares > 0) {
					newNumOfShares = currentNumOfShares + numOfIssuedShares
				}
			}
		}

		return (
			<div className='list__item' key={index}>
				<span className='list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left'>
					{name}
				</span>
				<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
					{localeFormatNumber(currentNumOfShares, NUMBER_FORMAT_INTEGER)}
				</span>
				<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
					{tmpTransaction && localeFormatNumber(newNumOfShares, NUMBER_FORMAT_INTEGER)}
				</span>
			</div>
		)
	}

	render = () => {
		const { transaction, tmpTransaction } = this.props
		if (!transaction) {
			return null
		}

		const { shareTypes } = this.state
		const currentDate = transaction.get('date')
		const currentShareCapital = transaction.getIn(['shareData', 'shareCapital'])
		const currentQuota = transaction.getIn(['shareData', 'quotaValue'])
		const currentShareTypes = transaction.getIn(['shareData', 'types'])

		let newDate
		let newShareCapital
		let newQuota
		let transactionBegan = false
		let remainingSharesToDistribute

		if (tmpTransaction) {
			newDate = tmpTransaction.get('date')
			newShareCapital = this.calculateShareCapital()
			newQuota = this.calculateQuotaValue()
			remainingSharesToDistribute = this.calcRemainingSharesToDistribute()
		}

		return (
			<Panel padHor className='i-panel--white'>
				<div>
					<div className='list__list-header'>
						<div className='list__list-header__text-area list__list-header__text-area--x3 list__list-header__text-area--pad-lft list__list-header__text-area--pad-right'>
							&nbsp;
						</div>
						<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
							<span>{Moment(currentDate).format('L')}</span>
						</div>
						<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
							{newDate && Moment(newDate).format('L')}
						</div>
					</div>
					<div className='list list--table list--striped'>
						<div className='list__item'>
							<span className='list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left'>
								<FormattedMessage id='share_capital' />
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{localeFormatNumber(currentShareCapital, NUMBER_FORMAT_CURRENCY)}
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{tmpTransaction && newShareCapital && localeFormatNumber(newShareCapital, NUMBER_FORMAT_CURRENCY)}
							</span>
						</div>
						{shareTypes && shareTypes.map(this.renderShareType)}
						<div className='list__item'>
							<span className='list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left'>
								<FormattedMessage id='quota_value' />
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{localeFormatNumber(currentQuota, NUMBER_FORMAT_CURRENCY)}
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{tmpTransaction && newQuota && localeFormatNumber(newQuota, NUMBER_FORMAT_CURRENCY)}
							</span>
						</div>
						{tmpTransaction && tmpTransaction.getIn(['handlerData', 'type']) === 'shares' && (
							<div className='list__item'>
								<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-lft list__item__text-area--pad-right'></span>
								<span className='list__item__text-area list__item__text-area--x5 list__item__text-area--pad-right list__item__text-area--right'>
									<span className='h-box'>
										<strong>
											<FormattedMessage id='shares.transactions.bonus_issue.remaining_shares_to_distribute' />
											:&nbsp;
										</strong>
										{localeFormatNumber(Math.ceil(remainingSharesToDistribute) || 0, NUMBER_FORMAT_INTEGER)}
									</span>
								</span>
							</div>
						)}
					</div>
				</div>
				<FormattedHTMLMessage id='shares.transactions.bonus_issue.information' />
			</Panel>
		)
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get('transaction'),
		tmpTransaction: state.transaction.getIn(['tmpTransaction', 'BONUS-ISSUE']),
		i18n: state.i18n
	}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(BonusIssueView)
