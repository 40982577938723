/**
 * Syna search
 * @module components/helpers/syna-search
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import req from '../../modules/request.module'

/** Class representing Syna search. */
class SynaSearch extends Component {
	/**
	 * Constructor
	 */
	constructor(props) {
		super(props)

		this.state = {
			selectedCompany: null
		}

		this.autocompleteTimeout = null
		this.autocompleteDelay = 300
		this.minAutocompleteCharacters = 3
		this.onGoingRequest = false
		this.reqCancelToken = req.CancelToken.source()
	}

	/**
	 * Loads options for react select
	 * @param {String} query — The search query.
	 * @param {Function} callback — The react-select callback function.
	 */
	getOptions(query, callback) {
		if (this.autocompleteTimeout) {
			clearTimeout(this.autocompleteTimeout)
		}

		this.autocompleteTimeout = setTimeout(() => {
			if (query.trim().length < this.minAutocompleteCharacters) {
				callback(null, { options: [] })
			} else {
				this.search(query, callback)
			}
		}, this.autocompleteDelay)
	}

	/**
	 * Search the external API for a company.
	 * @param {String} query — The search query.
	 * @param {Function} callback — The react-select callback function.
	 */
	search(query, callback) {
		const data = { options: [] }

		if (this.onGoingRequest) {
			this.reqCancelToken.cancel('Operation canceled by the user.')
			this.reqCancelToken = req.CancelToken.source()
		}

		this.onGoingRequest = true

		req
			.get(`/syna-integration/companies?q=${query}`, { cancelToken: this.reqCancelToken.token })
			.then((response) => {
				this.onGoingRequest = false

				if (response.data && response.data.length > 0) {
					response.data.map((company) => {
						data.options.push({ value: company.orgNumber, label: company.name })
					})
				}

				callback(null, data)
			})
			.catch((e) => {
				this.onGoingRequest = false
				callback(null, data)
			})
	}

	/**
	 * Select a company in react select.
	 * @param {Object} company — The selected company value from react select.
	 */
	selectCompany(company) {
		if (!company) {
			this.setState({ selectedCompany: null })
			return false
		}

		const orgNumber = company.value

		req
			.get(`/syna-integration/companies/${orgNumber}`)
			.then((response) => {
				if (response.data && response.data.company && response.data.representatives) {
					this.setState({ selectedCompany: orgNumber })
					this.props.onChange(response.data)
				} else {
					this.setState({ selectedCompany: null })
					this.props.onChange(null)
				}
			})
			.catch((e) => {
				this.setState({ selectedCompany: null })
				this.props.onChange(null)
			})
	}

	/**
	 * Filters the select options
	 * @param {Object} options — An array of options.
	 */
	filterOptions(options) {
		return options
	}

	/**
	 * Render
	 */
	render() {
		// Access translated messages from redux state without using FormattedMessage
		// NOTE: Only use this when FormattedMessage doesn't work, such as in React Select etc.
		const { i18n } = this.props
		const placeholder = i18n.messages['services.syna_search.placeholder']
		const selectProps = {
			searchPromptText: null,
			loadingPlaceholder: i18n.messages.loadingPlaceholder,
			noResultsText: i18n.messages.noResultsText
		}

		return (
			<Select.Async
				name='q'
				value={this.state.selectedCompany}
				className='form-control'
				placeholder={placeholder}
				autoload={false}
				onBlurResetsInput={false}
				loadOptions={this.getOptions.bind(this)}
				onChange={this.selectCompany.bind(this)}
				filterOptions={this.filterOptions.bind(this)}
				ignoreAccents={false}
				ignoreCase={false}
				{...selectProps}
			/>
		)
	}
}

/** Map state to props. */
function mapStateToProps(state) {
	return {
		i18n: state.i18n
	}
}

/** Export */
SynaSearch = connect(mapStateToProps, {})(SynaSearch)
export default SynaSearch
