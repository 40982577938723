import React, { PureComponent } from 'react'
import { string, bool, object, array, func } from 'prop-types'
import styled from 'styled-components'

import Modal from '../../shared/modal/modal'
import ModalTabsHeader from '../../shared/modal/modal-tabs-header'
import ColoredContentWrapper from '../../shared/colored-content-wrapper/colored-content-wrapper'

export const StyledColoredContentWrapper = styled(ColoredContentWrapper)`
	flex-direction: column;
	padding: ${(props) => props.theme.spacing[5]};
	margin-bottom: ${(props) => props.theme.spacing[4]};
`

export default class ManageFolderModal extends PureComponent {
	static propTypes = {
		isOpen: bool,
		titleTid: string,
		footerComponent: object,
		tabs: array,
		activeTab: string,
		onTabChange: func
	}

	renderHeaderComponent = () => {
		const { tabs, activeTab, onTabChange, titleTid } = this.props

		return (
			<ModalTabsHeader
				tabs={tabs}
				activeTab={activeTab}
				onChange={onTabChange}
				title={titleTid}
				showFilterButton={false}
			/>
		)
	}

	render = () => {
		const { isOpen, footerComponent, children } = this.props

		return (
			<Modal
				isOpen={isOpen}
				footerComponent={footerComponent}
				hSize='md'
				headerComponent={this.renderHeaderComponent()}>
				{children}
			</Modal>
		)
	}
}
