import React, { PureComponent } from 'react'
import { string, bool, number, func, oneOf } from 'prop-types'
import styled, { css } from 'styled-components'

import IotaIconBlack from '../../../assets/images/iota-logo-black.svg'

const StyledIotaIcon = styled.img`
	height: ${(props) => props.height}px;
	width: auto;

	${(props) =>
		props.marginLeft &&
		css`
			margin-left: ${(props) => props.theme.spacing[props.marginLeft]};
		`}

	${(props) =>
		props.marginRight &&
		css`
			margin-right: ${(props) => props.theme.spacing[props.marginRight]};
		`}

	${(props) =>
		props.onClick &&
		css`
			:hover {
				cursor: pointer;
			}
		`}
`

export default class IotaIcon extends PureComponent {
	static propTypes = {
		mode: oneOf(['black']),
		height: number,
		onClick: func,
		marginLeft: number,
		marginRight: number
	}

	static defaultProps = {
		mode: 'black',
		height: 22
	}

	render = () => {
		const { onClick, height, marginLeft, marginRight } = this.props

		return (
			<StyledIotaIcon
				onClick={onClick && onClick}
				src={IotaIconBlack}
				height={height}
				marginLeft={marginLeft}
				marginRight={marginRight}
			/>
		)
	}
}
