import React, { Component } from 'react'
import { connect } from 'react-redux'
import { object, string } from 'prop-types'

import Toolbar from '../../../../dumb-components/shared/toolbar/toolbar'
import { ButtonTransparentIcon } from '../../../../dumb-components/shared/button'
import DropdownIconItem from '../../../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import DropdownMenuContainer from '../../../shared/dropdown-menu.container'
import Tooltip from '../../../../dumb-components/shared/tooltip/tooltip'
import DropdownItem from '../../../../dumb-components/shared/dropdown-item/dropdown-item'

// Handle attendee components
import RollcallStatusMessage from '../../../../dumb-components/meetings/toolbar/rollcall-status-message'
import AddAttendees from '../general/dropdown-items/add-attendee.container'
import InviteAttendees from '../general/dropdown-items/invite-attendees.container'
import Rollcall from '../general/dropdown-items/rollcall-button.container'

// Handle meeting state components
import StartMeetingContainer from '../../agenda/start-meeting.container'
import EndMeetingContainer from '../../agenda/end-meeting.container'

// More actions components
import MoveMeeting from '../general/dropdown-items/move-meeting.container'
import Archive from '../general/dropdown-items/archive.container'
import Delete from '../general/dropdown-items/delete-meeting.container'
import Recover from '../general/dropdown-items/recover.container'

class MeetingToolbarContainer extends Component {
	static propTypes = {
		params: object,
		location: object,
		basePath: string
	}

	toggleAttendeeDropdown = () => {
		this.attendeeDropdownRef && this.attendeeDropdownRef.onToggleMenu()
	}

	toggleStateDropdown = () => {
		this.stateDropdownRef && this.stateDropdownRef.onToggleMenu()
	}

	toggleMoreActionDropdown = () => {
		this.moreActionDropdownRef && this.moreActionDropdownRef.onToggleMenu()
	}

	renderRollcallStatusMessage = () => {
		const { rollcallDone } = this.props
		return <RollcallStatusMessage rollcallDone={rollcallDone} />
	}

	renderAttendeeDropdown = () => {
		const {
			rollcallDone,
			basePath,
			location: { pathname },
			hasExtendedRights
		} = this.props
		const redirectToAttendees = !pathname.includes('/attendees') && `${basePath}/attendees`

		return (
			<DropdownMenuContainer
				ref={(ref) => (this.attendeeDropdownRef = ref)}
				halignMenu='right'
				disabled={!hasExtendedRights}
				noMaxWidth
				withPortal
				renderRaw={
					<Tooltip activeState={!hasExtendedRights ? 'hasNoPermissions' : undefined}>
						<ButtonTransparentIcon
							icon='faUserCircle'
							disabled={!hasExtendedRights}
							notificationBadge={hasExtendedRights}
							notificationBadgeAnimate={rollcallDone ? false : true}
							notificationBadgeColor={rollcallDone ? 'green' : 'persianRed'}
							onClick={this.toggleAttendeeDropdown}
						/>
					</Tooltip>
				}>
				<AddAttendees
					redirectTo={redirectToAttendees}
					renderComponent={({ openAttendeesModal }) => (
						<DropdownIconItem
							icon='faUserPlus'
							tid='meetings.general.toolbar.dropdown.add_attendee'
							onClick={() => {
								this.toggleAttendeeDropdown()
								openAttendeesModal()
							}}
						/>
					)}
				/>

				<InviteAttendees type='dropdown' dropdownRef={this.attendeeDropdownRef} />

				<Rollcall dropdownRef={this.attendeeDropdownRef} />
			</DropdownMenuContainer>
		)
	}

	renderStateDropdown = () => {
		const { basePath, hasExtendedRights } = this.props

		return (
			<DropdownMenuContainer
				btnMode='transparent-icon'
				btnIcon='faRocket'
				disabled={!hasExtendedRights}
				halignMenu='right'
				withPortal
				tooltipActiveState={!hasExtendedRights ? 'hasNoPermissions' : undefined}
				ref={(ref) => (this.stateDropdownRef = ref)}>
				<StartMeetingContainer mode='dropdown' />

				<EndMeetingContainer mode='dropdown' basePath={basePath} />
			</DropdownMenuContainer>
		)
	}

	renderMoreActionsDropdown = () => {
		const { basePath, location, meetingIsDeleted, hasExtendedRights } = this.props
		const commonProps = {
			dropdownRef: this.moreActionDropdownRef
		}

		return (
			<DropdownMenuContainer
				btnIcon='faEllipsisH'
				halignMenu='right'
				btnMode='transparent-icon'
				disabled={!hasExtendedRights}
				withPortal
				tooltipActiveState={!hasExtendedRights ? 'hasNoPermissions' : undefined}
				ref={(ref) => (this.moreActionDropdownRef = ref)}>
				<MoveMeeting {...commonProps} />

				<Archive {...commonProps} />

				<DropdownItem divider />

				{meetingIsDeleted ? (
					<Recover {...commonProps} basePath={basePath} location={location} />
				) : (
					<Delete {...commonProps} basePath={basePath} location={location} />
				)}
			</DropdownMenuContainer>
		)
	}

	render = () => {
		return (
			<Toolbar>
				{this.renderRollcallStatusMessage()}
				{this.renderAttendeeDropdown()}
				{this.renderStateDropdown()}
				{this.renderMoreActionsDropdown()}
			</Toolbar>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		rollcallDone: store.meetings.getIn(['meeting', 'metadata', 'rollcallDone'], false),
		meetingIsDeleted: store.meetings.getIn(['meeting', 'isDeleted']),
		hasExtendedRights: store.meetings.getIn(['meeting', 'computedValues', 'hasExtendedRights'])
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingToolbarContainer)
