import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func } from 'prop-types'
import Tooltip from '../../dumb-components/shared/tooltip/tooltip'
import { createMeetingLocal, createMeeting } from '../../actions/meetings.actions'
import { Map } from 'immutable'
import DropdownMenuContainer from '../shared/dropdown-menu.container'

import DropdownIconItem from '../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import SubscriptionBadge from '../../dumb-components/shared/badge/subscription-badge'
import history from '../../interfaces/history'

const TRIAL_MEETING_LIMIT = 2

import {
	MEETING_TYPE_SMART,
	MEETING_TYPE_STANDARD,
	MEETING_SUBTYPE_DEFAULT,
	ALLOW_CREATE_STANDARD_MEETING
} from '/shared/constants'

const TOOLTIP_SIMPLE_STATES = {
	upgradeSubscription: {
		tid: 'meetings.create.simple.subscription.upgrade.tooltip',
		delayShow: 'instant'
	}
}

const TOOLTIP_SMART_STATES = {
	upgradeSubscription: {
		tid: 'meetings.create.smart.subscription.upgrade.tooltip',
		delayShow: 'instant'
	}
}

class MeetingCreateButtonContainer extends Component {
	static propTypes = {
		basePath: string,
		renderCustomComponent: func
	}

	state = {
		createSmartMeetingBtnClicked: false,
		createSimpleMeetingBtnClicked: false,
		trialMeetingsLeft: Map({
			[MEETING_TYPE_SMART]: 0,
			[MEETING_TYPE_STANDARD]: 0
		})
	}

	createMeetingBtnRef = null

	componentDidMount() {
		this.calculateTrialMeetingsLeft()
	}

	componentDidUpdate(prevProps) {
		const { meetings } = this.props

		if (prevProps.meetings && prevProps.meetings.size !== meetings.size) {
			this.calculateTrialMeetingsLeft()
		}
	}

	calculateTrialMeetingsLeft = () => {
		const { meetings } = this.props
		let { trialMeetingsLeft } = this.state

		let trialStandardLeft = TRIAL_MEETING_LIMIT
		let trialSmartLeft = TRIAL_MEETING_LIMIT

		for (const m of meetings) {
			if (m.get('meetingType') === MEETING_TYPE_STANDARD && trialStandardLeft > 0) {
				trialStandardLeft--
			} else if (m.get('meetingType') === MEETING_TYPE_SMART && trialSmartLeft > 0) {
				trialSmartLeft--
			}

			if (trialStandardLeft === 0 && trialSmartLeft === 0) {
				break
			}
		}

		trialMeetingsLeft = trialMeetingsLeft.set(MEETING_TYPE_STANDARD, trialStandardLeft)
		trialMeetingsLeft = trialMeetingsLeft.set(MEETING_TYPE_SMART, trialSmartLeft)

		this.setState({ trialMeetingsLeft })
	}

	setLoadingState = (meetingType, boolean) => {
		const { renderCustomComponent } = this.props

		if (renderCustomComponent) {
			return
		}

		switch (meetingType) {
			case MEETING_TYPE_SMART:
				this.setState({ createSmartMeetingBtnClicked: boolean })
				break
			case MEETING_TYPE_STANDARD:
				this.setState({ createSimpleMeetingBtnClicked: boolean })
				break
		}
	}

	createMeetingRemote = (meeting) => {
		const { createMeetingLocal, createMeeting, selectedGroupId, basePath, history } = this.props

		this.createMeetingBtnRef && this.createMeetingBtnRef.onToggleMenu()

		meeting = meeting.set('groupId', selectedGroupId)

		createMeetingLocal(meeting, (newMeeting) => {
			createMeeting(newMeeting, () => {
				if (selectedGroupId) {
					history.push({
						pathname: `${basePath}/${newMeeting.get('id')}`,
						search: `?group=${selectedGroupId}`
					})
				} else {
					history.push(`${basePath}/${newMeeting.get('id')}`)
				}

				const newMeetingType = newMeeting.get('meetingType')
				this.setLoadingState(newMeetingType, false)
			})
		})
	}

	onCreateMeetingStandard = () => {
		this.setLoadingState(MEETING_TYPE_STANDARD, true)
		this.createMeetingRemote(
			Map({
				meetingType: MEETING_TYPE_STANDARD,
				meetingSubType: MEETING_SUBTYPE_DEFAULT
			})
		)
	}

	onCreateMeetingSmart = () => {
		this.setLoadingState(MEETING_TYPE_SMART, true)
		this.createMeetingRemote(
			Map({
				meetingType: MEETING_TYPE_SMART
			})
		)
	}

	getTrialMeetingsLeft = (meetingType) => {
		const { trialMeetingsLeft } = this.state
		const meetingsLeft = trialMeetingsLeft.get(meetingType)
		return meetingsLeft ? meetingsLeft : false
	}

	renderBadgeComponent = (meetingType, meetingsLeft) => {
		const { subHasSimpleMeetings, subHasSmartMeetings } = this.props

		// Meeting - Already subscribing
		if (meetingType === MEETING_TYPE_STANDARD && subHasSimpleMeetings) {
			return null
		}

		// Meeting Smart - Already subscribing
		if (meetingType === MEETING_TYPE_SMART && subHasSmartMeetings) {
			return null
		}

		// Smart or simple, one meeting left
		if (meetingsLeft === 1) {
			return <SubscriptionBadge tid='meetings.create.trial.meetings_left_singular' />
		}

		// Smart or simple, at least one meeting left
		if (meetingsLeft > 1) {
			return <SubscriptionBadge tid='meetings.create.trial.meetings_left_plurar' tidValues={{ meetingsLeft }} />
		}

		// Meeting - Not Paying
		if (meetingType === MEETING_TYPE_STANDARD && !subHasSimpleMeetings) {
			return <SubscriptionBadge tid='badge.subscription.upgrade.starter_plus' />
		}

		// Meeting Smart - Not Paying
		if (meetingType === MEETING_TYPE_SMART && !subHasSmartMeetings) {
			return <SubscriptionBadge tid='badge.subscription.upgrade.premium_plus' />
		}

		return null
	}

	renderCreateStandardBtn = () => {
		const { subHasSimpleMeetings, groups } = this.props
		const { createSimpleMeetingBtnClicked } = this.state
		const selectedGroupId = this.props.selectedGroupId ? this.props.selectedGroupId : 'root'
		const trialMeetingsLeft = this.getTrialMeetingsLeft(MEETING_TYPE_STANDARD) // false if no trial left

		const subscriptionActive = trialMeetingsLeft || subHasSimpleMeetings
		const allowCreate = groups.getIn([selectedGroupId, 'ALLOW_CREATE_MEETING'])
		const hasPermissions =
			(groups.getIn([selectedGroupId, ALLOW_CREATE_STANDARD_MEETING]) && allowCreate) ||
			(trialMeetingsLeft && allowCreate)
		let activeState = null

		if (!subscriptionActive) {
			activeState = 'upgradeSubscription'
		} else if (!hasPermissions) {
			activeState = 'hasNoPermissions'
		}

		return (
			<Tooltip states={TOOLTIP_SIMPLE_STATES} activeState={activeState}>
				<DropdownIconItem
					icon='faCalendar'
					tid='meetings.create_standard_meeting'
					disabled={!subscriptionActive || !hasPermissions || createSimpleMeetingBtnClicked}
					onClick={this.onCreateMeetingStandard}
					rightComponent={this.renderBadgeComponent(MEETING_TYPE_STANDARD, trialMeetingsLeft)}
				/>
			</Tooltip>
		)
	}

	renderCreateSmartBtn = () => {
		const { subHasSmartMeetings, groups } = this.props
		const { createSmartMeetingBtnClicked } = this.state
		const selectedGroupId = this.props.selectedGroupId ? this.props.selectedGroupId : 'root'

		const trialMeetingsLeft = this.getTrialMeetingsLeft(MEETING_TYPE_SMART) // false if no trial left
		const subscriptionActive = trialMeetingsLeft || subHasSmartMeetings
		const allowCreate = groups.getIn([selectedGroupId, 'ALLOW_CREATE_MEETING'])
		const hasPermissions =
			(groups.getIn([selectedGroupId, 'ALLOW_CREATE_SMART_MEETING']) && allowCreate) ||
			(allowCreate && trialMeetingsLeft)
		let activeState = null

		if (!subscriptionActive) {
			activeState = 'upgradeSubscription'
		} else if (!hasPermissions) {
			activeState = 'hasNoPermissions'
		}

		return (
			<Tooltip states={TOOLTIP_SMART_STATES} activeState={activeState}>
				<DropdownIconItem
					icon='faCalendarAlt'
					tid='meetings.create_smart_meeting'
					disabled={!subscriptionActive || !hasPermissions || createSmartMeetingBtnClicked}
					onClick={this.onCreateMeetingSmart}
					rightComponent={this.renderBadgeComponent(MEETING_TYPE_SMART, trialMeetingsLeft)}
				/>
			</Tooltip>
		)
	}

	render() {
		const { renderCustomComponent } = this.props

		if (renderCustomComponent) {
			return renderCustomComponent({
				createStandardMeeting: this.onCreateMeetingStandard,
				createSmartMeeting: this.onCreateMeetingSmart
			})
		}

		return (
			<DropdownMenuContainer
				ref={(ref) => (this.createMeetingBtnRef = ref)}
				halignMenu='right'
				btnIcon='faPlusSquare'
				btnMode='transparent-icon'
				transparentIconButtonSize='sml'
				noMaxWidth={true}>
				{this.renderCreateSmartBtn()}
				{this.renderCreateStandardBtn()}
			</DropdownMenuContainer>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		history: history,
		meetings: store.meetings.get('allMeetings', Map()) || Map(),
		selectedGroupId: store.groups.get('selectedGroupId'),
		groups: store.groups.get('groups'),
		subHasSimpleMeetings: store.subscriptions.getIn(['helper', 'simpleMeetingsEnabled']),
		subHasSmartMeetings: store.subscriptions.getIn(['helper', 'smartMeetingsEnabled']),
		documentsSpace: store.subscriptions.getIn(['helper', 'documentsSpace'])
	}
}

const mapActionsToProps = {
	createMeetingLocal,
	createMeeting
}

export default connect(mapStoreToProps, mapActionsToProps)(MeetingCreateButtonContainer)
