import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { fromJS, List } from 'immutable'
import Moment from '../../../../modules/moment.module'
import TextBox from '../../../framework/textbox'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import { NUMBER_FORMAT_CURRENCY, NUMBER_FORMAT_INTEGER } from '/shared/constants'

class EmissionView extends Component {
	componentDidMount() { }

	calculateShareCapital(shareData, quota) {
		const types = shareData.get('types')
		let shareCapital = 0
		if (!types) {
			return shareCapital
		}

		types.forEach((type) => {
			const numOfShares = parseInt(type.get('numOfShares'))
			if (!isNaN(numOfShares)) {
				shareCapital += quota * numOfShares
			}
		})
		return shareCapital
	}

	calculateNumOfTotalShares(shareData) {
		const types = shareData.get('types')
		let totalNumOfShares = 0

		if (!types) {
			return totalNumOfShares
		}

		types.forEach((type) => {
			const numOfShares = parseInt(type.get('numOfShares'))
			if (!isNaN(numOfShares)) {
				totalNumOfShares += numOfShares
			}
		})

		return totalNumOfShares
	}

	calculateSharesLeft(tmpTransaction) {
		const shareholders = tmpTransaction.getIn(['handlerData', 'shareholders'], List())
		const shareType = tmpTransaction.getIn(['handlerData', 'types', 0])
		if (!shareType) {
			return 0
		}

		let numOfSharesLeft = shareType.get('numOfShares') || 0

		shareholders.forEach((shareholder) => {
			if (shareType.get('type') === shareholder.get('type')) {
				numOfSharesLeft -= shareholder.get('numOfShares') || 0
			}
		})

		return numOfSharesLeft
	}

	render() {
		const { transaction, tmpTransaction } = this.props
		if (!transaction) {
			return null
		}

		const currentShareData = transaction.get('shareData')

		if (!currentShareData) {
			return null
		}

		const currentShareCapital = currentShareData.get('shareCapital')
		const currentNumOfTotalShares = currentShareData.get('numOfTotalShares')
		const quota = currentShareData.get('quotaValue')
		const currentFromDate = Moment(transaction.get('date'))

		let newShareCapital = 0
		let newNumOfTotalShares = 0
		let newFromDate
		let numOfSharesLeft = 0
		let sharesLeftClasName =
			'list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'
		if (tmpTransaction) {
			const handlerData = tmpTransaction.get('handlerData')
			const emissionType = handlerData.get('emissionType')
			const shareDiff = handlerData.get('shareDiff')

			if (emissionType === 'EMISSION-LEVELING' && shareDiff) {
				shareDiff.forEach((types) => {
					types.forEach((type) => {
						newNumOfTotalShares += type.get('diff', 0)
					})
				})
				newNumOfTotalShares += currentNumOfTotalShares
				newShareCapital = newNumOfTotalShares * quota
			} else {
				newShareCapital = this.calculateShareCapital(handlerData, quota)
				newNumOfTotalShares = this.calculateNumOfTotalShares(handlerData)
				newFromDate = tmpTransaction.get('date')
				newShareCapital = newShareCapital > 0 ? currentShareCapital + newShareCapital : null
				newNumOfTotalShares = newNumOfTotalShares > 0 ? currentNumOfTotalShares + newNumOfTotalShares : null
				numOfSharesLeft = this.calculateSharesLeft(tmpTransaction)
				if (numOfSharesLeft !== 0) {
					sharesLeftClasName = sharesLeftClasName + ' text--error'
				}
			}
		}

		return (
			<div className='i-panel i-panel--white'>
				<div className='i-panel__body i-panel__body i-panel__body--pad-hor'>
					<div className='list__list-header'>
						<div className='list__list-header__text-area list__list-header__text-area--x3 list__list-header__text-area--pad-lft list__list-header__text-area--pad-right'>
							&nbsp;
						</div>
						<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
							<span>{currentFromDate.format('L')}</span>
						</div>
						<div className='list__list-header__text-area list__list-header__text-area--x2 list__list-header__text-area--pad-right list__list-header__text-area--right'>
							<span>{newFromDate ? Moment(newFromDate).format('L') : ''}</span>
						</div>
					</div>
					<div className='list list--striped list--table'>
						<div className='list__item'>
							<span className='list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left'>
								<FormattedMessage id='share_capital' />
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{localeFormatNumber(currentShareCapital, NUMBER_FORMAT_CURRENCY)}
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{tmpTransaction && newShareCapital && localeFormatNumber(newShareCapital, NUMBER_FORMAT_CURRENCY)}
							</span>
						</div>

						<div className='list__item'>
							<span className='list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left'>
								<FormattedMessage id='shares_issued' />
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{localeFormatNumber(currentNumOfTotalShares, NUMBER_FORMAT_INTEGER)}
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{tmpTransaction &&
									newNumOfTotalShares &&
									localeFormatNumber(newNumOfTotalShares, NUMBER_FORMAT_INTEGER)}
							</span>
						</div>

						<div className='list__item'>
							<span className='list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left'>
								<FormattedMessage id='quota_value' />
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{localeFormatNumber(quota, NUMBER_FORMAT_CURRENCY)}
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{tmpTransaction && quota && localeFormatNumber(quota, NUMBER_FORMAT_CURRENCY)}
							</span>
						</div>
						<div className='list__item'>
							<span className='list__item__text-area list__item__text-area--x3 list__item__text-area--pad-lft list__item__text-area--pad-right list__item__text-area--left'>
								<FormattedMessage id='shares.transaction.emission.remaining_shares' />
							</span>
							<span className='list__item__text-area list__item__text-area--x2 list__item__text-area--pad-right list__item__text-area--right'>
								{'-'}
							</span>
							<span className={sharesLeftClasName}>
								{tmpTransaction && localeFormatNumber(numOfSharesLeft, NUMBER_FORMAT_INTEGER)}
							</span>
						</div>
					</div>
					<FormattedHTMLMessage id='shares.transactions.emission.emission_information' />

				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		transaction: state.transaction.get('transaction'),
		tmpTransaction: state.transaction.getIn(['tmpTransaction', 'EMISSION']),
		i18n: state.i18n
	}
}
const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EmissionView)
