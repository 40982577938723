// Source: https://stripe.com/docs/invoicing/customer/tax-ids
const countryToTaxIdTypeMap = {
	AU: ['au_abn'],
	AT: ['eu_vat'],
	BE: ['eu_vat'],
	BR: ['br_cnpj', 'br_cpf'],
	BG: ['eu_vat'],
	CA: ['ca_bn', 'ca_qst'],
	CL: ['cl_tin'],
	HR: ['eu_vat'],
	CY: ['eu_vat'],
	CS: ['eu_vat'],
	DK: ['eu_vat'],
	EE: ['eu_vat'],
	FI: ['eu_vat'],
	FR: ['eu_vat'],
	DE: ['eu_vat'],
	GR: ['eu_vat'],
	HK: ['hk_br'],
	HU: ['eu_vat'],
	IN: ['in_gst'],
	ID: ['id_npwp'],
	IE: ['eu_vat'],
	IT: ['eu_vat'],
	JP: ['jp_cn', 'jp_rn'],
	KR: ['kr_brn'],
	LV: ['eu_vat'],
	LI: ['li_uid'],
	LT: ['eu_vat'],
	LU: ['eu_vat'],
	MY: ['my_frp', 'my_itn', 'my_sst'],
	MT: ['eu_vat'],
	MX: ['mx_rfc'],
	NL: ['eu_vat'],
	NZ: ['nz_gst'],
	NO: ['no_vat'],
	PL: ['eu_vat'],
	PT: ['eu_vat'],
	RO: ['eu_vat'],
	RU: ['ru_inn', 'ru_kpp'],
	SA: ['sa_vat'],
	SG: ['sg_gst', 'sg_uen'],
	SK: ['eu_vat'],
	SI: ['eu_vat'],
	ZA: ['za_vat'],
	ES: ['es_cif', 'eu_vat'],
	SE: ['eu_vat'],
	CH: ['ch_vat'],
	TW: ['tw_vat'],
	TH: ['th_vat'],
	AE: ['ae_trn'],
	GB: ['gb_vat', 'eu_vat'],
	US: ['us_ein']
};

const getTaxIdType = (countryCode) => {
	if (!countryCode) {
		return null;
	}

	const taxIdTypeObj = countryToTaxIdTypeMap[countryCode];

	if (!taxIdTypeObj) {
		return null;
	}

	return taxIdTypeObj;
};

module.exports = {
	getTaxIdType
};
