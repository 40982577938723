import React, { PureComponent } from 'react'
import styled from 'styled-components'
import InvonoLogoWhite from '../../../assets/images/logo-white.svg'
import Text from '../../shared/text/text'
import { Margin } from 'styled-components-spacing'
import Button from '../../shared/button/button'

import backgroundImg from '../../../assets/images/bg-bgs.jpg'

const StyledWrapper = styled.div`
	width: 100vw;
	height: 100vh;
	padding: ${(props) => props.theme.spacing[5]};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-size: cover;
	background-image: url(${backgroundImg});
`

const StyledTextWrapper = styled.div``

const StyledLink = styled.a`
	text-decoration: none;
`

const StyledLogo = styled.img`
	max-width: 300px;
`

const StyledDismissedBtnWrapper = styled.div`
	display: flex;
	justify-content: center;
`

export default class MobileComingSoon extends PureComponent {
	componentWillUnmount = () => {
		const ivp = document.getElementById('inv-view-port')
		ivp.setAttribute('content', 'width=device-width, initial-scale=0.3')
	}

	render = () => {
		const { onDismiss } = this.props

		return (
			<StyledWrapper>
				<StyledLogo src={InvonoLogoWhite} />
				<StyledTextWrapper>
					<Text fontSize='18px' color='white' tid='app.mobile-landing-page.text1' />

					<Margin vertical={5}>
						<StyledLink href='https://invono.se'>
							<Text fontSize='18px' color='white' tid='app.mobile-landing-page.text2' />
						</StyledLink>
					</Margin>

					<StyledDismissedBtnWrapper>
						<Button mode='rounded' onClick={onDismiss} tid='app.mobile-landing-page.btn.dismiss' />
					</StyledDismissedBtnWrapper>
				</StyledTextWrapper>
			</StyledWrapper>
		)
	}
}
