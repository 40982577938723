import React, { PureComponent } from 'react'
import immutableProps from 'react-immutable-proptypes'
import { string, bool, func, oneOfType } from 'prop-types'
import { List } from 'immutable'
import styled from 'styled-components'
import Grid from 'styled-components-grid'
import { Padding } from 'styled-components-spacing'
import moment from '../../../../modules/moment.module'
import Label from '../../label/label'
import Input from '../../input/input'
import { Select, MultiSelect } from '../../select'
import DateRange from '../../datepicker/date-range'
import Button from '../../button/button'
import Text from '../../text/text'
import Checkbox from '../../checkbox/checkbox'
import PinableSelectOption from '../helper-components/pinable-select-option'

const StyledLabelInput = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;

	> :not(:first-child):not(:last-child) {
		margin-left: ${(props) => props.theme.spacing[4]};
	}

	> :last-child {
		margin-left: ${(props) => props.theme.spacing[2]};
	}
`

const StyledLabelButtonWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
`

export default class ObjectFilterForm extends PureComponent {
	static propTypes = {
		fields: immutableProps.list,
		values: immutableProps.list,
		defaultValues: immutableProps.map,
		renderPinComponent: func,
		onChange: func,
		onClear: func,
		onSetDefaultValue: func,
		language: string
	}

	static defaultProps = {
		values: List()
	}

	onClearAll = (fieldName) => {
		const { onClear } = this.props

		onClear && onClear(fieldName)
	}

	getValue = (fieldName) => {
		const { values } = this.props
		let value

		values.forEach((val) => {
			if (val.get('source') === fieldName) {
				value = val.getIn(['values', 0])
			}
		})

		return value
	}

	getDefaultValue = (fieldName) => {
		const { defaultValues } = this.props
		return defaultValues.getIn([fieldName, 0])
	}

	renderPinableSelectOption = (defaultValue, labelIsTid, option) => {
		return <PinableSelectOption defaultValue={defaultValue} labelIsTid={labelIsTid} option={option} />
	}

	renderClearAllButton = (fieldName) => {
		return (
			<StyledLabelInput>
				<Button onClick={this.onClearAll.bind(this, fieldName)} mode='link' tid='object_filter.clear_all' />
			</StyledLabelInput>
		)
	}

	renderInputField = (fieldName, pinable, placeholder, value, defaultValue) => {
		const { onChange } = this.props

		return <Input fieldName={fieldName} placeholder={placeholder} onChange={onChange} value={value} />
	}

	renderCheckboxField = (fieldName, pinable, placeholder, value, defaultValue, tid) => {
		const { onChange } = this.props

		return <Checkbox fieldName={fieldName} onChange={onChange} checked={value} tid={tid} mode='modern-big' />
	}

	renderSelectField = (
		fieldName,
		pinable,
		placeholder,
		value,
		defaultValue,
		options,
		labelIsTid,
		isClearable,
		formatOptionLabel,
		isDisabled
	) => {
		const { onChange } = this.props

		return (
			<Select
				fieldName={fieldName}
				placeholderTid={placeholder}
				onChange={onChange}
				options={options}
				value={value}
				labelIsTid={labelIsTid}
				isClearable={isClearable}
				formatOptionLabel={
					pinable ? this.renderPinableSelectOption.bind(this, defaultValue, labelIsTid) : formatOptionLabel
				}
			/>
		)
	}

	renderMultiSelectField = (fieldName, pinable, placeholder, value, defaultValue, options, labelIsTid, isClearable) => {
		const { onChange } = this.props

		return (
			<MultiSelect
				fieldName={fieldName}
				placeholderTid={placeholder}
				onChange={onChange}
				options={options}
				value={value}
				labelIsTid={labelIsTid}
				isClearable={isClearable}
			/>
		)
	}

	renderDateRange = (
		fieldName,
		pinable,
		placeholder,
		value,
		defaultValue,
		minDateLabelTid,
		maxDateLabelTid,
		yearLabelTid,
		minDate,
		maxDate,
		showYearSelect,
		isClearable,
		isDisabled
	) => {
		const { onChange, language } = this.props
		const startYear = minDate ? moment(minDate).year() : moment().year() - 5

		return (
			<DateRange
				fieldName={fieldName}
				minDate={minDate}
				maxDate={maxDate}
				minDateLabelTid={minDateLabelTid}
				maxDateLabelTid={maxDateLabelTid}
				yearLabelTid={yearLabelTid}
				onChange={onChange}
				value={value}
				startYear={startYear}
				showYearSelect={showYearSelect}
				isClearable={isClearable}
				isDisabled={isDisabled}
				language={language}
			/>
		)
	}

	renderComponent = (FieldComponent, componentProps, fieldName, pinable, placeholder, value, defaultValue) => {
		const { onChange } = this.props

		return (
			<FieldComponent
				{...componentProps}
				fieldName={fieldName}
				placeholder={placeholder}
				onChange={onChange}
				value={value}
			/>
		)
	}

	renderInput = (field) => {
		const { values, onChange } = this.props
		const value = this.getValue(field.get('fieldName'))
		const defaultValue = this.getDefaultValue(field.get('fieldName'))

		switch (field.get('renderer')) {
			case 'input':
				return this.renderInputField(
					field.get('fieldName'),
					field.get('pinable'),
					field.get('placeholder'),
					value,
					defaultValue
				)
			case 'select':
				return this.renderSelectField(
					field.get('fieldName'),
					field.get('pinable'),
					field.get('placeholder'),
					value,
					defaultValue,
					field.get('options'),
					field.get('labelIsTid'),
					field.get('isClearable'),
					field.get('formatOptionLabel')
				)
			case 'multiSelect':
				return this.renderMultiSelectField(
					field.get('fieldName'),
					field.get('pinable'),
					field.get('placeholder'),
					value,
					defaultValue,
					field.get('options'),
					field.get('labelIsTid'),
					field.get('isClearable')
				)
			case 'dateRange':
				return this.renderDateRange(
					field.get('fieldName'),
					field.get('pinable'),
					field.get('placeholder'),
					value,
					defaultValue,
					field.get('minDateLabelTid'),
					field.get('maxDateLabelTid'),
					field.get('yearLabelTid'),
					field.get('minDate'),
					field.get('maxDate'),
					field.get('showYearSelect'),
					field.get('isClearable'),
					field.get('isDisabled')
				)
			case 'checkbox':
				return this.renderCheckboxField(
					field.get('fieldName'),
					field.get('pinable'),
					field.get('placeholder'),
					value,
					defaultValue,
					field.get('tid')
				)
			default:
				if (field.get('renderFn')) {
					const renderFn = field.get('renderFn')
					return renderFn(
						field.get('fieldName'),
						field.get('pinable'),
						field.get('placeholder'),
						value,
						defaultValue,
						onChange,
						field
					)
				}
		}
	}

	renderSetDefaultButton = (fieldName) => {
		const { onSetDefaultValue } = this.props
		const value = this.getValue(fieldName)
		const defaultValue = this.getDefaultValue(fieldName)

		if (value === defaultValue) {
			return null
		}

		return (
			<StyledLabelButtonWrapper>
				<Button
					mode='link'
					onClick={() => onSetDefaultValue(fieldName, value)}
					tid='object_filter.form.set_default_value'
				/>
			</StyledLabelButtonWrapper>
		)
	}

	renderField = (field, index) => {
		const { renderPinComponent } = this.props
		const pinable = field.get('pinable')
		const labelTid = field.get('labelTid')
		let renderedInputField

		if (pinable && renderPinComponent) {
			renderedInputField = renderPinComponent(field.get('fieldName'), this.renderInput(field))
		} else {
			renderedInputField = this.renderInput(field)
		}

		return (
			<Grid.Unit size={field.get('size')} key={index}>
				<Padding all={3}>
					{labelTid && (
						<Label
							tid={field.get('labelTid')}
							rightComponent={pinable ? this.renderSetDefaultButton.bind(this, field.get('fieldName')) : undefined}>
							{renderedInputField}
						</Label>
					)}
					{!labelTid && renderedInputField}
				</Padding>
			</Grid.Unit>
		)
	}

	renderRow = (fields, index) => {
		return <Grid key={index}>{fields.map(this.renderField)}</Grid>
	}

	render = () => {
		const { fields } = this.props

		return <Padding all={3}>{fields && fields.map(this.renderRow)}</Padding>
	}
}
