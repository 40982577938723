import React, { useEffect, useState } from "react";
import MUIDialog from "../../../mui-components/dialog/mui-dialog";
import { closeModal, openModal } from "../../../actions/modals.actions";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveMeeting } from "../../../actions/meetings.actions";
import { Form } from 'react-final-form'
import { FormGroupContainer, FormGroup } from "../../../mui-components/form-block/FromGroup";
import { Select } from 'mui-rff'
import MenuItem from '@mui/material/MenuItem'
import history from "../../../interfaces/history";
import Typography from '@mui/material/Typography'
import { listMeetingTemplates } from "../../../actions/meeting-templates.actions";
import { MEETING_SELECT_TEMPLATE_MODAL } from "../../../constants/modals";


export const FOOTER_SAVE_BUTTON_TOOLTIP_STATES = {
	noTemplateSelected: {
		title: 'meetings.smart.select_template_modal.footer.confirm_btn.tooltip.template_required',
		enterDelay: 'instant'
	}
}

const SelectTemplateModal = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [templateOptions, setTemplateOptions] = useState(null);
	const [initialValues, setInitialValues] = useState(null);

	const meeting = useSelector(state => state.meetings.get('meeting'));
	const meetingTemplates = useSelector(state => state.meetingTemplates.get('list'));
	const isModalOpen = useSelector(state => state.modals.getIn(['activeModal', 'name']) === MEETING_SELECT_TEMPLATE_MODAL);

	useEffect(() => {
		dispatch(listMeetingTemplates(undefined, () => dispatch(openModal(MEETING_SELECT_TEMPLATE_MODAL))
		));
	}, []);

	useEffect(() => {
		if (meetingTemplates.size > 0) {
			setTemplateOptions(meetingTemplates.toJS())
		}	
	}, [meetingTemplates]);

	useEffect(() => {
		if (meeting && meeting.get('templateId')) {
			setInitialValues({ template: meeting.get('templateId')});
		}

	}, [meeting]);

	const onSubmit = (values) => {
		let _meeting = meeting
		_meeting = _meeting.set('templateLocked', true);
		_meeting = _meeting.set('templateId', values.template);		

		dispatch(saveMeeting(_meeting, () => dispatch(closeModal())));

	};

	const onClose = () => {
		dispatch(closeModal());
		const hrefFirstPart = history.location.pathname.split('/agenda')[0];

		history.push(hrefFirstPart + '/info')
		
	};	

	if (!templateOptions) {
		return null;
	}

	const validate = (values) => {	
		const errors = {};
		if (!values.template) {
			errors.template = t('meetings.smart.select_template_modal.validation.template_required');

		}
		return errors;
	};	

return (
	<Form
		onSubmit={onSubmit}
		initialValues={initialValues}
		validate={validate}
		>
			{(formProps) => {
				const {
					handleSubmit,
					errors,
					values
				} = formProps;
				
				return (
					<MUIDialog 
						isOpen={isModalOpen}
						onConfirm={handleSubmit}
						onClose={onClose}
						disabledConfirm={errors.template || !values}
						title={t('meetings.smart.template_information_modal.title')}
						confirmButtonTid='meetings.smart.template_information_modal.footer_btn.confirm'
						tooltipStates={FOOTER_SAVE_BUTTON_TOOLTIP_STATES}
						tooltipActiveState={errors.template ? 'noTemplateSelected' : null}
						>
							<FormGroupContainer>
								<FormGroup>
									<Select name='template'
											label={t('meetings.smart.select_template_modal.template_select_field.label')}>
												{templateOptions.map((option) => {													
													return (
														<MenuItem
															key={option.id}
															value={option.id}>
															{option.name}
														</MenuItem>
													)
													
												})}
									</Select></FormGroup>
								<FormGroup>
									<Typography variant='body2'>
										{t('meetings.smart.agenda_tab.select_template_modal.yellow_panel.body1')}
									</Typography>
									<Typography variant='body2'>
										{t('meetings.smart.agenda_tab.select_template_modal.yellow_panel.body2')}
									</Typography>
									
								</FormGroup>
							</FormGroupContainer>

					</MUIDialog>
				);
			}}
	</Form>
);
};

export default SelectTemplateModal;
