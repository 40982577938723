import React, { PureComponent } from 'react'
import { string, node, oneOf, func, oneOfType, bool, object } from 'prop-types'
import styled, { css } from 'styled-components'
import v1 from 'uuid'
import Text from '../text/text'

const StyledPanel = styled.div`
	background-color: ${(props) => {
		if (props.mode === 'light' || props.mode === 'branded') {
			return props.theme.colors.white
		} else if (props.mode === 'transparent' || props.mode === 'naked') {
			return 'transparent'
		} else if (props.mode === 'notes') {
			return props.theme.colors.notesYellow
		} else {
			return props.theme.colors.solitudeMid
		}
	}};
	width: 100%;
	margin-bottom: ${(props) => (props.marginBottom ? props.theme.spacing[4] : '0px')};

	/* 16 extra padding on EVERY panel to make it more spacey.
	 * As instructed by PO.
	 */
	padding-bottom: ${(props) => {
		if (props.noBottomPadding) {
			return 0
		} else if (props.mode === 'naked' || props.mode === 'transparent') {
			return 0
		} else {
			return props.theme.spacing[4]
		}
	}};

	${(props) =>
		props.bordered &&
		css`
			border: 1px solid ${(props) => props.theme.colors.border};
		`}

	${(props) =>
		props.shadowed &&
		css`
			box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
		`} /*${(props) =>
		props.active &&
		css`
			box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
		`}*/
	
	display: flex;
	flex-direction: column;

	${(props) => props.captableMode && css`
		height: 100%;
	`}
`

const StyledPanelHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: ${(props) => (props.mode === 'branded' ? props.theme.colors.java : 'none')};
	border-bottom: ${(props) => (props.mode !== 'notes' ? '1px solid rgba(0, 0, 0, 0.07)' : 'none')};
	height: 50px;

	${(props) =>
		!props.noHeaderPadding &&
		css`
			padding: 0 ${(props) => props.theme.spacing[4]};
		`}

	/* Legacy Mode, matches old panel left-padding*/
	${(props) =>
		props.legacyMode &&
		css`
			padding-left: 15px;
		`}

	/* captable Mode, extra padding for the title*/
	${(props) =>
		props.captableMode &&
		css`
			padding-left: 24px;
		`}
`

const StyledTitle = styled.span`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1;
`

const StyledLeftComponent = styled.div`
	flex: 1;
	min-width: 0;
`

const StyledRightComponent = styled.div``

const StyledPanelBody = styled.div`
	height: ${(props) => (props.height ? props.height : 'auto')};
	flex: 1;
`

const StyledInlinePanelHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: ${(props) => (props.mode === 'branded' ? props.theme.colors.java : 'none')};
	height: 50px;
`

const StyledInlineTitleBackground = styled.div`
	display: flex;
	align-items: center;
	padding-right: ${(props) => props.theme.spacing[4]};
	height: 100%;
`

const StyledInlineBorderProvider = styled.div`
	align-self: flex-end;
	flex: 1;
	border-top: 1px solid rgba(0, 0, 0, 0.07);
	height: 50%;
	width: 100%;
`

const StyledPanelFooter = styled.div`
	display: flex;
	flex-direction: row;
	min-height: 60px;
`

class Panel extends PureComponent {
	static propTypes = {
		marginBottom: bool,
		children: node,
		title: string,
		mode: oneOf(['light', 'branded', 'transparent', 'notes']),
		leftComponent: func,
		rightComponent: oneOfType([node, func]),
		type: oneOf(['inline', 'transparent']),
		id: string,
		active: bool,
		bodyHeight: string,
		footerComponent: func,
		bordered: bool,
		legacyMode: bool,
		shadowed: bool,
		nakedStyle: bool,
		noHeaderPadding: bool,
		titleValues: object,
		noBottomPadding: bool,
		captableMode:bool
	}

	static defaultProps = {
		title: null,
		marginBottom: false,
		id: v1(),
		active: false,
		legacyMode: false,
		captableMode: false,
		noHeaderPadding: false
	}

	render = () => {
		const {
			children,
			title,
			mode,
			rightComponent,
			marginBottom,
			type,
			id,
			active,
			bodyHeight,
			footerComponent,
			bordered,
			legacyMode,
			captableMode,
			shadowed,
			leftComponent,
			nakedStyle,
			noHeaderPadding,
			titleValues,
			noBottomPadding
		} = this.props

		const renderRightComponent = typeof rightComponent === 'function' ? rightComponent() : rightComponent

		if (nakedStyle) {
			return (
				<StyledPanel mode='naked' marginBottom={marginBottom}>
					<StyledPanelBody>{children}</StyledPanelBody>
				</StyledPanel>
			)
		}

		return (
			<StyledPanel
				mode={mode}
				marginBottom={marginBottom}
				id={id}
				active={active}
				bordered={bordered}
				shadowed={shadowed}
				noBottomPadding={noBottomPadding}
				captableMode={captableMode}
			>
				{type === 'inline' && title && (
					<StyledInlinePanelHeader mode={mode}>
						<StyledInlineTitleBackground>
							<StyledTitle>
								<Text tid={title} tag='h4' color='catalinaBlue' values={titleValues && titleValues} />
							</StyledTitle>
						</StyledInlineTitleBackground>
						<StyledInlineBorderProvider />
					</StyledInlinePanelHeader>
				)}
				{type !== 'inline' && (title || leftComponent || rightComponent) && (
					<StyledPanelHeader mode={mode} legacyMode={legacyMode} noHeaderPadding={noHeaderPadding} captableMode={captableMode}>
						{title && (
							<StyledTitle>
								<Text
									tid={title}
									tag={'h4'}
									color={'catalinaBlue'}
									fontSize={legacyMode ? '1.2em' : undefined}
									values={titleValues && titleValues}
								/>
							</StyledTitle>
						)}
						{leftComponent && <StyledLeftComponent>{leftComponent()}</StyledLeftComponent>}
						<StyledRightComponent>{renderRightComponent}</StyledRightComponent>
					</StyledPanelHeader>
				)}
				<StyledPanelBody height={bodyHeight}>{children}</StyledPanelBody>
				{footerComponent && <StyledPanelFooter>{footerComponent()}</StyledPanelFooter>}
			</StyledPanel>
		)
	}
}

export default Panel
