import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fromJS, Map, List } from 'immutable'
import Panel from '../../../../dumb-components/panel'
import Field from '../../../../dumb-components/fields/field'
import Select from '../../../../dumb-components/fields/select'

class ChangeofReservationSequences extends Component {
	componentDidMount = () => {}

	onChange = (sequenceIndex, val) => {
		const { tmpTransaction, onChange } = this.props

		let sequences = tmpTransaction.getIn(['handlerData', 'sequences'], List())
		const index = sequences.findIndex((obj) => {
			return obj.get('index') === sequenceIndex
		})

		if (index >= 0) {
			sequences = sequences.setIn([index, 'transactionLimitations'], val.split(','))
		} else {
			sequences = sequences.push(
				Map({
					index: sequenceIndex,
					transactionLimitations: val.split(',')
				})
			)
		}

		onChange('sequences', sequences)
	}

	getSelectedTransactionLimitations = (transactionSequence, sequenceIndex) => {
		const { tmpTransaction } = this.props
		const sequences = tmpTransaction.getIn(['handlerData', 'sequences'], List())
		const sequence = sequences.find((obj) => {
			return obj.get('index') === sequenceIndex
		})

		return sequence
			? sequence.get('transactionLimitations', List())
			: transactionSequence.get('transactionLimitations', List())
	}

	renderSequence = (sequence, index) => {
		const { investors, transactionLimitationsOptions, selectedShareType, i18n } = this.props

		if (!investors || sequence.get('type') !== selectedShareType) {
			return null
		}

		const sequenceIndex = index
		const investor = investors.find((inv) => {
			return inv.get('id') === sequence.get('investmentId')
		})

		if (!investor) {
			return null
		}

		const investmentId = investor.get('id')
		const transactionLimitations = this.getSelectedTransactionLimitations(sequence, sequenceIndex)

		return (
			<div className='i-panel' key={sequenceIndex}>
				<div className='i-panel__heading'>
					<h2 className='i-panel__title'>
						{sequence.get('sequenceFrom')} - {sequence.get('sequenceTo')}
					</h2>
					<div className='box--inline'>
						{investor.getIn(['investorInformation', 'name'])}{' '}
						<span className='text--muted'>{investor.getIn(['investorInformation', 'id'])}</span>
					</div>
				</div>
				<div className='i-panel__body'>
					<Field tid='shares.transaction_restrictions'>
						<Select
							value={
								transactionLimitations
									? transactionLimitations.join
										? transactionLimitations.join()
										: transactionLimitations
									: null
							}
							options={transactionLimitationsOptions}
							simpleValue
							multi
							placeholder={i18n.messages['select_placeholder']}
							onSelect={(val) => {
								this.onChange(sequenceIndex, val)
							}}
						/>
					</Field>
				</div>
			</div>
		)
	}

	render = () => {
		const { transaction } = this.props

		if (!transaction) {
			return
		}

		const sequences = transaction.get('sequences')

		return <div>{sequences && sequences.map(this.renderSequence)}</div>
	}
}

function mapStateToProps(state) {
	return {
		investors: state.investors.get('list'),
		i18n: state.i18n
	}
}

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ChangeofReservationSequences)
