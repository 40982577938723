// ConnectedIntlProvider.js

import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { numberFormats } from '../constants/i18n'

// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
function mapStateToProps(state) {
	const { language, messages } = state.i18n
	const loadedLocales = ['sv-SE', 'en-GB']
	const defaultLocale = 'en-GB'
	const locale = loadedLocales.indexOf(language) > -1 ? language : defaultLocale
	return {
		locale,
		messages,
		defaultLocale,
		key: locale,
		formats: {
			number: numberFormats
		}
	}
}

export default connect(mapStateToProps)(IntlProvider)
