import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'
import { closeModal, openModal } from '../../actions/modals.actions'

import PaymentRequiresActionModal from '../../dumb-components/subscriptions/payment-requires-action-modal/payment-requires-action-modal'

import { SUBSCRIPTION_PAYMENT_REQUIRES_ACTION_MODAL, SUBSCRIPTION_AFTER_SUB_POT_INFO } from '../../constants/modals'

class PaymentRequiresActionModalContainer extends Component {
	static propTypes = {
		onClose: func
	}

	onClose = () => {
		const { onClose, showPotInfo, openModal, closeModal } = this.props

		onClose && onClose()
		closeModal(SUBSCRIPTION_PAYMENT_REQUIRES_ACTION_MODAL)

		showPotInfo && openModal(SUBSCRIPTION_AFTER_SUB_POT_INFO)
	}

	render = () => {
		const { isOpen } = this.props

		return <PaymentRequiresActionModal isOpen={isOpen} onClose={this.onClose} />
	}
}

const mapStoreToProps = (store) => {
	return {
		isOpen: store.modals.getIn(['activeModal', 'name']) === SUBSCRIPTION_PAYMENT_REQUIRES_ACTION_MODAL,
		showPotInfo: store.modals.getIn(['activeModal', 'options', 'showPotInfo'])
	}
}

const mapActionsToProps = {
	closeModal,
	openModal
}

export default connect(mapStoreToProps, mapActionsToProps)(PaymentRequiresActionModalContainer)
