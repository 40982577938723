import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { listExternalTasks, saveExternalTask } from '../../../actions/tasks.actions'
import { fetchSimpleUsers } from '../../../actions/usersCache.actions'
import TasksList from '../../../dumb-components/meetings/protocol-view/tasks-list/tasks-list'

class TasksListContainer extends Component {
	static propTypes = {
		objId: string,
		readOnly: bool
	}

	state = {
		tasks: null
	}

	componentDidMount = () => {
		this.fetchTasks()
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { tasks } = this.state

		if (prevState.tasks !== tasks) {
			this.parseTasks()
		}
	}

	fetchTasks = () => {
		const { listExternalTasks, objId } = this.props

		if (objId) {
			listExternalTasks(objId, (tasks) => {
				this.setState({ tasks })
			})
		}
	}

	parseTasks = () => {
		const { fetchSimpleUsers } = this.props
		const { tasks } = this.state
		let userIds = List()

		tasks &&
			tasks.forEach((task) => {
				const assigne = task.get('assigne')

				if (assigne) {
					userIds = userIds.push(assigne)
				}
			})

		fetchSimpleUsers(userIds)
	}

	onChange = (taskId, fieldName, val) => {
		const { saveExternalTask, objId } = this.props
		let { tasks } = this.state
		const index = tasks.findIndex((obj) => obj.get('id') === taskId)
		let task = tasks.get(index)
		task = task.set(fieldName, val)
		tasks = tasks.set(index, task)

		this.setState({ tasks })
		saveExternalTask(objId, task)
	}

	render = () => {
		const { readOnly, usersCache } = this.props
		const { tasks } = this.state

		if (!tasks || tasks.size === 0) {
			return null
		}

		return <TasksList tasks={tasks} usersCache={usersCache} readOnly={readOnly} onChange={this.onChange} />
	}
}

const mapStoreToProps = (store) => {
	return {
		usersCache: store.usersCache.get('usersCache')
	}
}

const mapActionsToProps = {
	listExternalTasks,
	saveExternalTask,
	fetchSimpleUsers
}

export default connect(mapStoreToProps, mapActionsToProps)(TasksListContainer)
