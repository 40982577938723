import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func } from 'prop-types'

import EditorContainer from '../../shared/editor.container'
import CompanyDetailsRight from '../../../dumb-components/company-profile/company-details-right'

class CompanyDetailsRightContainer extends Component {
	static propTypes = {
		onChange: func.isRequired
	}

	getBranchOfIndustry = () => {
		const { branchOfIndustry } = this.props
		let tempValue = ''
		let value = ''

		if (branchOfIndustry) {
			if (typeof branchOfIndustry[0] === 'object') {
				for (let i = 0; i < branchOfIndustry.length; i++) {
					tempValue += branchOfIndustry[i].code + ' ' + branchOfIndustry[i].name
					if (branchOfIndustry.length !== i + 1) {
						tempValue += ', '
					}
				}
				value = tempValue
			} else {
				value = branchOfIndustry[0]
			}
		}

		return value
	}

	renderBusinessOverview = () => {
		const { businessOverview, onChange } = this.props

		return (
			<EditorContainer
				fieldName='businessOverview'
				placeholderTid='company.profile.details.business_overview.placeholder'
				contentState={businessOverview}
				onChange={onChange}
			/>
		)
	}

	render = () => {
		return (
			<CompanyDetailsRight
				branchOfIndustry={this.getBranchOfIndustry()}
				businessOverviewComponent={this.renderBusinessOverview()}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	const { company } = store.company

	return {
		branchOfIndustry: company && company.branchOfIndustry,
		businessOverview: company && company.businessOverview
	}
}

const mapActionsToProps = {}

export default connect(mapStoreToProps, mapActionsToProps)(CompanyDetailsRightContainer)
