import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from '../../../../interfaces/router'
import { isImmutable, Map } from 'immutable'
import { listInvestors } from '../../../../actions/investors.actions'
import { formatIdPretty } from '../../../helpers/users'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'
import history from '../../../../interfaces/history'

import {
	EVENT_TYPE_SHARES_INVESTMENT_CREATE,
	EVENT_TYPE_SHARES_INVESTMENT_UPDATE,
	EVENT_TYPE_SHARES_INVESTMENT_DELETE,
	NUMBER_FORMAT_PERCENTAGE,
	NUMBER_FORMAT_INTEGER
} from '/shared/constants'

class InvestorsList extends Component {
	investorRefs = {}

	componentDidMount = () => {
		const { listInvestors, params, basePath } = this.props
		listInvestors(false, (investors) => {
			//	Renders the first investor on the right panel
			if (!params.id && investors?.size > 0) {
				investors && basePath && history.push(`${basePath}/${investors.first().get('id')}`)
			}
		})
	}

	componentDidUpdate = () => {
		this.scrollToSelectedInvestor()
		this.checkLiveUpdateEvents()
	}

	checkLiveUpdateEvents = () => {
		const { audit, listInvestors } = this.props
		const investorCreate = audit.get(EVENT_TYPE_SHARES_INVESTMENT_CREATE)
		const investorUpdate = audit.get(EVENT_TYPE_SHARES_INVESTMENT_UPDATE)
		const investorDelete = audit.get(EVENT_TYPE_SHARES_INVESTMENT_DELETE)

		if (
			(investorCreate && investorCreate.get('refresh') === true) ||
			(investorUpdate && investorUpdate.get('refresh') === true) ||
			(investorDelete && investorDelete.get('refresh') === true)
		) {
			listInvestors()
		}
	}

	scrollToSelectedInvestor = () => {
		const { selectedInvestorId } = this.props

		if (!selectedInvestorId) {
			return
		}

		const htmlElement = this.investorRefs[selectedInvestorId]

		if (htmlElement && htmlElement.scrollIntoViewIfNeeded) {
			htmlElement.scrollIntoViewIfNeeded()
		}
	}

	setInvestorRef = (ref) => {
		if (!ref) {
			return
		}

		this.investorRefs[ref.getAttribute('refid')] = ref
	}

	renderInvestor = (investor) => {
		const { filterBy, onInvestorClick, i18n } = this.props
		const showBy = (filterBy.find((obj) => obj.get('source') === 'showBy') || Map()).getIn(['values', 0])
		const id = investor.get('id')

		let itemIdentifier = investor.getIn(['investorInformation', 'id'], '')
		const ownershipProp = showBy === 'numOfShares' ? 'numOfTotalShares' : 'ownershipPercentage'
		const ownership = investor.getIn(['details', ownershipProp])
		const name = investor.getIn(['investorInformation', 'name'])
		const toLink = this.props.basePath + '/' + id

		itemIdentifier = formatIdPretty(itemIdentifier)

		if (investor.getIn(['investorTypeOfOwner']) === 'capitalInsurance') {
			itemIdentifier =
				i18n.messages['investments.capital_insurance.insurance_owner'] +
				': ' +
				investor.getIn(['captialIncuranceOwnerInformation', 'name'], 'n/a')
		}

		const noShares = !ownership || ownership === 0

		return (
			<NavLink
				to={toLink}
				onClick={onInvestorClick}
				activeClassName='list__item--active'
				className='list__item'
				key={id}>
				<div className='list__item__drag-area'></div>
				<div className={`list__item__total-shares ${noShares ? 'list__item__total-shares--grey' : null}`}>
					{!noShares && ownershipProp === 'numOfTotalShares' && localeFormatNumber(ownership, NUMBER_FORMAT_INTEGER)}
					{!noShares &&
						ownershipProp === 'ownershipPercentage' &&
						localeFormatNumber(ownership, NUMBER_FORMAT_PERCENTAGE)}
				</div>
				<div className='list__item__body' refid={id} ref={this.setInvestorRef}>
					<span className='list__item__text list__item__text--strong'>{name}</span>
					<span className='list__item__text'>{itemIdentifier}</span>
				</div>
			</NavLink>
		)
	}

	splitInvestors = (investors) => {
		const active = []
		const old = []

		investors.forEach((investor) => {
			const numOfTotalShares = investor.getIn(['details', 'numOfTotalShares'])
			!numOfTotalShares || numOfTotalShares === 0 ? old.push(investor) : active.push(investor)
		})
		return [active, old]
	}

	/**
	 * The render function
	 */
	render() {
		const { investors } = this.props
		if (!isImmutable(investors)) {
			return null
		}

		return <div className='list'>{investors.map(this.renderInvestor)}</div>
	}
}

function mapStateToProps(state) {
	return {
		investors: state.investors.get('visibleInvestors'),
		selectedInvestorId: state.investors.getIn(['selectedInvestor', 'id']),
		filterBy: state.investors.get('filterBy'),
		i18n: state.i18n,
		audit: state.audit.get('investors')
	}
}

const mapActionsToProps = {
	listInvestors
}

/** Export */
export default connect(mapStateToProps, mapActionsToProps)(InvestorsList)
