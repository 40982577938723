import React, { useContext } from 'react'
import { string, bool } from 'prop-types'
import Grid from '@mui/material/Grid'
import { FormattedMessage } from 'react-intl'
import StyledIcon from '../../../dumb-components/shared/icon/icon'
import { InvestmentsContext } from '../investment.container'

export default function InvestmentBuySellContainer() {
	const { permissions } = useContext(InvestmentsContext)
	const isGuest = permissions.get('isGuest')

	const onCloseMessage = () => {}

	return (
		<Grid container spacing={2}>
			{/* {alreadyDismissed && ( */}
			<Grid item xs={12}></Grid>
		</Grid>
	)
}
