import React, { PureComponent } from 'react'
import immutableProps from 'react-immutable-proptypes'
import { string, func, bool, number } from 'prop-types'
import { Map } from 'immutable'
import styled from 'styled-components'
import moment from '../../../modules/moment.module'
import Label from '../label/label'
import DatePicker from './datepicker'
import YearSelect from '../year-select/year-select'
import Text from '../text/text'

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
`

const StyledFieldWrapper = styled.div`
	width: ${(props) => (props.showYearSelect ? '33%' : '50%')};
	padding: 0 ${(props) => props.theme.spacing[3]};

	:first-child {
		padding-left: 0;
	}

	:last-child {
		padding-right: 0;
	}
`

export default class DateRange extends PureComponent {
	static propTypes = {
		fieldName: string,
		minDate: string,
		maxDate: string,
		minDateLabelTid: string,
		maxDateLabelTid: string,
		yearLabelTid: string,
		minDatePlaceholder: string,
		maxDatePlaceholder: string,
		yearSelectPlaceholder: string,
		onChange: func,
		value: immutableProps.map,
		showYearSelect: bool,
		startYear: number,
		isClearable: bool,
		isDisabled: bool,
		language: string
	}

	static defaultProps = {
		value: Map()
	}

	onChange = (field, val) => {
		const { onChange, fieldName } = this.props
		let { value } = this.props

		value = value.set(field, val)

		if (fieldName) {
			onChange && onChange(fieldName, value)
		} else {
			onChange && onChange(value)
		}
	}

	render = () => {
		const {
			minDate,
			maxDate,
			minDateLabelTid,
			maxDateLabelTid,
			yearLabelTid,
			minDatePlaceholder,
			maxDatePlaceholder,
			yearSelectPlaceholder,
			value,
			showYearSelect,
			isClearable,
			isDisabled,
			language
		} = this.props
		let startYear = minDate ? moment(minDate).year() : null

		if (this.props.startYear) {
			startYear = this.props.startYear
		}

		return (
			<StyledWrapper>
				<StyledFieldWrapper showYearSelect={showYearSelect}>
					<Label tid={minDateLabelTid}>
						<DatePicker
							fieldName='minDate'
							placeholder={minDatePlaceholder}
							onChange={this.onChange}
							value={value ? value.get('minDate') : null}
							isClearable={isClearable}
							disabled={isDisabled}
							language={language}
						/>
					</Label>
				</StyledFieldWrapper>
				<StyledFieldWrapper showYearSelect={showYearSelect}>
					<Label tid={maxDateLabelTid}>
						<DatePicker
							fieldName='maxDate'
							placeholder={maxDatePlaceholder}
							onChange={this.onChange}
							value={value ? value.get('maxDate') : null}
							isClearable={isClearable}
							calendarPlacement='bottom-end'
							disabled={isDisabled}
							language={language}
						/>
					</Label>
				</StyledFieldWrapper>
				{showYearSelect && (
					<StyledFieldWrapper>
						<Label tid={yearLabelTid}>
							<YearSelect
								fieldName='year'
								startYear={startYear}
								placeholder={yearSelectPlaceholder}
								onChange={this.onChange}
								value={value ? value.get('year') : null}
								isClearable={isClearable}
							/>
						</Label>
					</StyledFieldWrapper>
				)}
			</StyledWrapper>
		)
	}
}
