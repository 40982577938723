import React, { Component } from 'react'
import { connect } from 'react-redux'
import immutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { oneOf, bool, func } from 'prop-types'
import PaymentDetailsPanel from '../../dumb-components/subscriptions/payment-details-panel/payment-details-panel'

import {
	isRequired,
	validateCreditCardNumber,
	validateExpDate,
	validateCVCNumber
} from '../../modules/validation.module'
import { addErrorNotification, addInfoNotification } from '../../actions/notify.actions'
import {
	updateFormObject,
	unsetCompanyAddressUsed,
	setCompanyAddressUsed,
	savePaymentDetails,
	createPaymentDetails,
	updateCard,
	resetFormObject,
	createCardToken,
	resetStripeError
} from '../../actions/subscriptions.actions'
import UniversalModal from '../../dumb-components/shared/modal/universal-modal'
import FooterRightControls from '../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../dumb-components/shared/button-v2'

class PaymentDetailsContainer extends Component {
	static propTypes = {
		mode: oneOf(['panel', 'modal']),
		isOpen: bool,
		onClose: func,
		onBeforeSave: func,
		onAfterSave: func,
		onError: func
	}

	static defaultProps = {
		mode: 'panel'
	}

	state = {
		isPaymentDetailsLoading: false,
		errors: Map(),
		viewMode: 'read' // 'read' or 'edit'
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { stripeData, formObject, updateFormObject } = this.props

		// if (
		// 	stripeData &&
		// 	(!formObject.has('paymentDetails') || (prevState.viewMode === 'edit' && this.state.viewMode === 'read'))
		// ) {
		// 	const stripeCustomer = stripeData.getIn(['sources', 'data', 0])
		//
		// 	if (stripeCustomer) {
		// 		const expMonth = stripeCustomer.get('exp_month')
		// 		let paymentDetails = Map({
		// 			brand: stripeCustomer.get('brand'),
		// 			number: stripeCustomer.get('last4'),
		// 			exp_month: expMonth < 10 ? `0${expMonth}` : expMonth,
		// 			exp_year: stripeCustomer.get('exp_year'),
		// 			name: stripeCustomer.get('name'),
		// 			address_line1: stripeCustomer.get('address_line1'),
		// 			address_line2: stripeCustomer.get('address_line2'),
		// 			address_zip: stripeCustomer.get('address_zip'),
		// 			address_city: stripeCustomer.get('address_city'),
		// 			address_state: stripeCustomer.get('address_state'),
		// 			address_country: stripeCustomer.get('address_country')
		// 		})
		//
		// 		updateFormObject(formObject.set('paymentDetails', paymentDetails))
		// 	}
		// }
	}

	onChange = (field, val) => {
		const { updateFormObject } = this.props
		let { formObject } = this.props
		formObject = formObject.setIn(['paymentDetails', field], val)
		updateFormObject(formObject)
	}

	onChangeCompanyAddressUsed = (field, checked) => {
		const {
			updateFormObject,
			company: { addresses },
			unsetCompanyAddressUsed
		} = this.props

		if (!checked) {
			this.unsetCompanyAddress()
			return
		}

		let address = addresses.find((obj) => {
			return obj.type === 'padr'
		})

		if (!address) {
			address = addresses[0]
		}

		this.setCompanyAddress(address)
	}

	setCompanyAddress = (selectedAddress) => {
		const { updateFormObject, setCompanyAddressUsed } = this.props
		let { formObject, paymentDetails } = this.props

		paymentDetails = paymentDetails.set('address_line1', selectedAddress.street)
		paymentDetails = paymentDetails.set('address_zip', selectedAddress.postalCode)
		paymentDetails = paymentDetails.set('address_city', selectedAddress.city)

		formObject = formObject.set('paymentDetails', paymentDetails)
		formObject = formObject.set('companyAddressUsed', true)

		updateFormObject(formObject)
		setCompanyAddressUsed(selectedAddress.type)
	}

	unsetCompanyAddress = () => {
		const { updateFormObject, unsetCompanyAddressUsed } = this.props
		let { formObject, paymentDetails } = this.props

		paymentDetails = paymentDetails.remove('address_line1')
		paymentDetails = paymentDetails.remove('address_zip')
		paymentDetails = paymentDetails.remove('address_city')

		formObject = formObject.set('paymentDetails', paymentDetails)
		formObject = formObject.set('companyAddressUsed', false)

		updateFormObject(formObject)
		unsetCompanyAddressUsed()
	}

	isCardDetailsChanged = (paymentDetails) => {
		const { stripeData } = this.props
		const stripeCustomer = stripeData.getIn(['sources', 'data', 0])

		if (!stripeCustomer) {
			return true
		}

		const expMonth = stripeCustomer.get('exp_month')
		const exp_month = expMonth < 10 ? `0${expMonth}` : expMonth

		if (
			paymentDetails.get('number').toString().substr(-4) !== stripeCustomer.get('last4') ||
			paymentDetails.get('exp_month') !== exp_month ||
			paymentDetails.get('exp_year') !== stripeCustomer.get('exp_year') ||
			paymentDetails.has('cvc') ||
			paymentDetails.get('name') !== stripeCustomer.get('name')
		) {
			return true
		}

		return false
	}

	isAddressChanged = (paymentDetails) => {
		const { stripeData } = this.props
		const address = stripeData.get('address')

		if (!address) {
			return false
		}

		if (
			paymentDetails.get('address_line1') !== address.get('line1') ||
			paymentDetails.get('address_line2') !== address.get('line2') ||
			paymentDetails.get('address_zip') !== address.get('postal_code') ||
			paymentDetails.get('address_city') !== address.get('city') ||
			paymentDetails.get('address_state') !== address.get('state') ||
			paymentDetails.get('address_country') !== address.get('country')
		) {
			return true
		}

		return false
	}

	preRequest = (loading) => {
		this.setState({ [loading]: true })
	}

	postRequest = (loading) => {
		this.setState({ [loading]: false })
	}

	validateCreditCard = (creditCard) => {
		const { addErrorNotification } = this.props
		let { errors } = this.state

		// Reset Errors
		errors = Map()

		if (!isRequired(creditCard.get('number'))) {
			errors = errors.update('number', (err) =>
				!err ? List(['validation.is_required']) : err.push('validation.is_required')
			)
			addErrorNotification({
				tid: 'validation.is_required'
			})
		}

		if (!validateCreditCardNumber(creditCard.get('number'))) {
			errors = errors.update('number', (err) =>
				!err ? List(['validation.credit_card_number']) : err.push('validation.credit_card_number')
			)
			addErrorNotification({
				tid: 'validation.credit_card_number'
			})
		}

		if (!isRequired(creditCard.get('exp_month'))) {
			errors = errors.update('exp_month', (err) =>
				!err ? List(['validation.is_required']) : err.push('validation.is_required')
			)
			addErrorNotification({
				tid: 'validation.is_required'
			})
		}

		if (!validateExpDate(creditCard.get('exp_month'), creditCard)) {
			errors = errors.update('exp_month', (err) =>
				!err ? List(['validation.credit_card_exp_date']) : err.push('validation.credit_card_exp_date')
			)
			addErrorNotification({
				tid: 'validation.credit_card_exp_date'
			})
		}

		if (!isRequired(creditCard.get('exp_year'))) {
			errors = errors.update('exp_year', (err) =>
				!err ? List(['validation.is_required']) : err.push('validation.is_required')
			)
			addErrorNotification({
				tid: 'validation.is_required'
			})
		}

		if (!validateExpDate(creditCard.get('exp_year'), creditCard)) {
			errors = errors.update('exp_year', (err) =>
				!err ? List(['validation.credit_card_exp_date']) : err.push('validation.credit_card_exp_date')
			)
			addErrorNotification({
				tid: 'validation.credit_card_exp_date'
			})
		}

		if (!isRequired(creditCard.get('cvc'))) {
			errors = errors.update('cvc', (err) =>
				!err ? List(['validation.is_required']) : err.push('validation.is_required')
			)
			addErrorNotification({
				tid: 'validation.is_required'
			})
		}

		if (!validateCVCNumber(creditCard.get('cvc'))) {
			errors = errors.update('cvc', (err) =>
				!err ? List(['validation.cvc_number']) : err.push('validation.cvc_number')
			)
			addErrorNotification({
				tid: 'validation.cvc_number'
			})
		}

		if (!isRequired(creditCard.get('name'))) {
			errors = errors.update('name', (err) =>
				!err ? List(['validation.is_required']) : err.push('validation.is_required')
			)
			addErrorNotification({
				tid: 'validation.cardholder_name'
			})
		}

		if (!isRequired(creditCard.get('address_country'))) {
			errors = errors.update('address_country', (err) =>
				!err ? List(['validation.is_required']) : err.push('validation.is_required')
			)
			addErrorNotification({ tid: 'validation.address_country' })
		}

		if (creditCard.get('address_country') !== 'SE' && !isRequired(creditCard.get('taxIdType'))) {
			errors = errors.update('taxIdType', (err) =>
				!err ? List(['validation.is_required']) : err.push('validation.is_required')
			)
			addErrorNotification({ tid: 'validation.tax_id_type' })
		}

		if (creditCard.get('address_country') !== 'SE' && !isRequired(creditCard.get('vatNumber'))) {
			errors = errors.update('vatNumber', (err) =>
				!err ? List(['validation.is_required']) : err.push('validation.is_required')
			)
			addErrorNotification({ tid: 'validation.vat_number' })
		}

		this.setState({ errors })
		return errors.size === 0
	}

	createCardObject = (paymentDetails) => {
		const card = {
			exp_month: paymentDetails.get('exp_month'),
			exp_year: paymentDetails.get('exp_year'),
			number: paymentDetails.get('number'),
			cvc: paymentDetails.get('cvc'),
			name: paymentDetails.get('name'),
			address_line1: paymentDetails.get('address_line1'),
			address_line2: paymentDetails.get('address_line2'),
			address_city: paymentDetails.get('address_city'),
			address_state: paymentDetails.get('address_state'),
			address_zip: paymentDetails.get('address_zip'),
			address_country: paymentDetails.get('address_country')
		}

		return card
	}

	createAddressObject(paymentDetails) {
		const address = {
			line1: paymentDetails.get('address_line1'),
			line2: paymentDetails.get('address_line2'),
			postal_code: paymentDetails.get('address_zip'),
			city: paymentDetails.get('address_city'),
			state: paymentDetails.get('address_state'),
			country: paymentDetails.get('address_country')
		}

		return address
	}

	updatePaymentDetails = async () => {
		const {
			stripeData,
			company,
			createPaymentDetails,
			updateCard,
			apiKey,
			createCardToken,
			resetStripeError,
			paymentDetails
		} = this.props

		this.scrollbarRef && this.scrollbarRef.scrollToTop()

		resetStripeError()

		const vatNumber = paymentDetails.get('vatNumber')
		const taxIdType = paymentDetails.get('taxIdType')

		const customerId = stripeData.get('id')

		const cardDetailsChanged = this.isCardDetailsChanged(paymentDetails)
		const paymentDetailsValid = this.validateCreditCard(paymentDetails)

		if (!paymentDetailsValid) {
			return
		}

		const cardObject = this.createCardObject(paymentDetails)
		const address = this.createAddressObject(paymentDetails)

		// * Här skapas betalkortet och kunden
		if (!customerId) {
			this.preRequest('isPaymentDetailsLoading')
			this.triggerOnBeforeSaveIfInModal()

			try {
				const token = await createCardToken(apiKey, cardObject)
				await createPaymentDetails({
					sourceId: token.id,
					companyName: company.name,
					companyCountry: paymentDetails.get('address_country'),
					address,
					vatNumber,
					taxIdType
				})
				this.postRequest('isPaymentDetailsLoading')
				this.infoNotify('subscriptions.notification.subscriptions_was_successfully_created')
				this.triggerOnAfterSaveIfInModal()
			} catch (error) {
				this.postRequest('isPaymentDetailsLoading')
				this.triggerOnErrorIfInModal()
			}
		} else if (customerId && cardDetailsChanged) {
			// * Här skapas ett nytt betalkort samt uppdateras kundens betalkälla
			this.preRequest('isPaymentDetailsLoading')
			this.triggerOnBeforeSaveIfInModal()

			try {
				const token = await createCardToken(apiKey, cardObject)
				const updatedSubscription = await updateCard(customerId, token.id)
				await this.changeCustomerAddressIfNeeded(updatedSubscription)
				this.postRequest('isPaymentDetailsLoading')
				this.infoNotify('subscriptions.notification.creadit_card_successfully_created')
				this.changeToViewModeIfInPanel()
				this.triggerOnAfterSaveIfInModal()
			} catch (error) {
				this.postRequest('isPaymentDetailsLoading')
				this.triggerOnErrorIfInModal()
			}
		}
	}

	async changeCustomerAddressIfNeeded(updatedSubscription) {
		const { savePaymentDetails, paymentDetails } = this.props

		const addressChanged = this.isAddressChanged(paymentDetails)

		if (!addressChanged) {
			return
		}

		const vatNumber = paymentDetails.get('vatNumber')
		const taxIdType = paymentDetails.get('taxIdType')

		const customerId = updatedSubscription.getIn(['stripeData', 'id'])

		const address = this.createAddressObject(paymentDetails)

		await savePaymentDetails({
			customerId,
			customer: { address },
			vatNumber,
			taxIdType
		})
	}

	infoNotify(tid) {
		this.props.addInfoNotification({ tid })
	}

	changeToViewModeIfInPanel() {
		if (this.props.mode === 'panel') {
			this.setState({ viewMode: 'read' })
		}
	}

	triggerOnBeforeSaveIfInModal() {
		if (this.props.mode === 'modal' && typeof this.props.onBeforeSave === 'function') {
			this.props.onBeforeSave()
		}
	}

	triggerOnAfterSaveIfInModal() {
		if (this.props.mode === 'modal' && typeof this.props.onAfterSave === 'function') {
			this.props.onAfterSave()
		}
	}

	triggerOnErrorIfInModal() {
		if (this.props.mode === 'modal' && typeof this.props.onError === 'function') {
			this.props.onError()
		}
	}

	onClose = () => {
		const { onClose } = this.props

		onClose && onClose()
	}

	changeViewMode = (newViewMode) => {
		const { resetStripeError } = this.props

		if (newViewMode === 'read') {
			resetStripeError()
		}

		this.setState({ viewMode: newViewMode })
	}

	setScrollbarRef = (ref) => {
		this.scrollbarRef = ref
	}

	renderModalButtons = () => {
		const { isLoading } = this.props

		return (
			<FooterRightControls>
				<TransparentButton
					tid='subscriptions.payment_details.button.continue_with_payment'
					onClick={this.updatePaymentDetails}
					isLoading={isLoading}
				/>
				<TransparentButton
					tid='generic.form.close'
					textColor='midGrey'
					onClick={this.onClose}
					disabled={isLoading}
				/>
			</FooterRightControls>
		)
	}

	renderPanel = () => {
		const {
			i18n,
			companyAddressUsed,
			stripeData,
			mode,
			isLoading,
			company: { addresses, country }
		} = this.props
		let { paymentDetails } = this.props
		const { errors } = this.state
		let { viewMode } = this.state
		const hasSources = stripeData && stripeData.getIn(['sources', 'total_count']) > 0
		const hasAddresses = addresses?.length > 0

		if (mode === 'panel' && !hasSources) {
			return null
		}

		if (mode === 'modal') {
			viewMode = 'edit'
		}

		if (!paymentDetails.has('address_country')) {
			paymentDetails = paymentDetails.set('address_country', country)
		}

		return (
			<PaymentDetailsPanel
				paymentDetails={paymentDetails}
				onChange={this.onChange}
				isLoading={isLoading}
				onSave={this.updatePaymentDetails}
				companyHasAddresses={hasAddresses}
				companyAddressUsed={companyAddressUsed}
				onChangeCompanyAddressUsed={this.onChangeCompanyAddressUsed}
				language={i18n.language.substr(0, 2)}
				i18n={i18n}
				errors={errors}
				mode={mode}
				viewMode={viewMode}
				changeViewMode={this.changeViewMode}
			/>
		)
	}

	render = () => {
		const { mode, isOpen } = this.props

		if (mode === 'panel') {
			return this.renderPanel()
		}

		return (
			<UniversalModal
				isOpen={isOpen}
				titleTid='subscriptions.payment_details'
				modalFooterComponent={this.renderModalButtons()}
				renderContent={this.renderPanel}
				scrollableContent={true}
				hSize='lg'
				scrollbarRef={this.setScrollbarRef}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		company: store.company.company,
		formObject: store.subscriptions.get('formObject', Map()),
		stripeData: store.subscriptions.getIn(['subscription', 'stripeData'], Map()) || Map(),
		paymentDetails: store.subscriptions.getIn(['formObject', 'paymentDetails'], Map()),
		companyAddressUsed: store.subscriptions.getIn(['formObject', 'companyAddressUsed'], false),
		apiKey: store.subscriptions.get('apiKey'),
		i18n: store.i18n
	}
}

const mapActionsToProps = {
	updateFormObject,
	unsetCompanyAddressUsed,
	setCompanyAddressUsed,
	savePaymentDetails,
	createPaymentDetails,
	updateCard,
	resetFormObject,
	addErrorNotification,
	addInfoNotification,
	createCardToken,
	resetStripeError
}

export default connect(mapStoreToProps, mapActionsToProps)(PaymentDetailsContainer)
