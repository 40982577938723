import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool } from 'prop-types'

import FakeFooterSpacer from '../../../../../dumb-components/shared/modal/fake-footer-spacer'
import { ButtonTransparentIcon } from '../../../../../dumb-components/shared/button'
import {
	FirstStepBody,
	SecondStepBody
} from '../../../../../dumb-components/onboarding/modals/documents/uploaded-document-info'
import OnboardingModal from '../../../../../dumb-components/onboarding/modal'

import { markOnboardAsDismiss } from '../../../../../actions/notifications.actions'
import { closeModal } from '../../../../../actions/modals.actions'

class UploadedDocumentInfoContainer extends Component {
	state = {
		step: 0
	}

	nextStep = () => {
		this.setState({ step: this.state.step + 1 })
	}

	prevStep = () => {
		this.setState({ step: this.state.step - 1 })
	}

	dismissPermanently = () => {
		const { notificationId, markOnboardAsDismiss, closeModal } = this.props
		markOnboardAsDismiss(notificationId)
		closeModal()
	}

	getSteps = () => {
		const PreviousButton = () => <ButtonTransparentIcon onClick={this.prevStep} icon='faLongArrowLeft' size='xl' />

		return [
			{
				body: <FirstStepBody onNext={this.nextStep} />,
				footer: {
					component: <FakeFooterSpacer height={36} />
				}
			},
			{
				body: <SecondStepBody onDismiss={this.dismissPermanently} />,
				footer: {
					leftComponent: <PreviousButton />
				}
			}
		]
	}

	render = () => {
		return <OnboardingModal mode='steps' step={this.state.step} steps={this.getSteps()} />
	}
}

const mapStoreToProps = (store) => {
	return {
		notificationId: store.modals.getIn(['activeModal', 'options', 'notificationId'])
	}
}

const mapActionsToProps = {
	markOnboardAsDismiss,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(UploadedDocumentInfoContainer)
