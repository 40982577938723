import req from '../modules/request.module'
import { fromJS } from 'immutable'
import { CAPITAL_INSURANCE_LIST, CAPITAL_INSURANCE_CREATE, SHARES_ERROR } from './types'

export function listCapitalInsurances() {
	return function (dispatch) {
		return req
			.get(`/shares/capital-insurance/`)
			.then((response) => {
				dispatch({
					type: CAPITAL_INSURANCE_LIST,
					payload: fromJS(response.data)
				})
			})
			.catch(() => {
				dispatch(sharesError("Could't list capital insurances."))
			})
	}
}

export function createCapitalInsurance(synaCompanyData, callback) {
	const capitalInsurance = {
		name: synaCompanyData && synaCompanyData.getIn(['company', 'name']),
		zip: synaCompanyData && synaCompanyData.getIn(['company', 'addresses', 0, 'postalCode']),
		city: synaCompanyData && synaCompanyData.getIn(['company', 'addresses', 0, 'city']),
		address: synaCompanyData && synaCompanyData.getIn(['company', 'addresses', 0, 'street']),
		organisationNumber: synaCompanyData && synaCompanyData.getIn(['company', 'orgNumber'])
	}

	return function (dispatch) {
		return req
			.post(`/shares/capital-insurance/`, { capitalInsurance })
			.then((response) => {
				dispatch({ type: CAPITAL_INSURANCE_CREATE, payload: fromJS(response.data) })
				callback && callback()
			})
			.catch(() => {
				dispatch(sharesError("Could't save capital insurance."))
			})
	}
}

/**
 * Error handling
 */
export function sharesError(error) {
	return {
		type: SHARES_ERROR,
		payload: error
	}
}
