import React from 'react'
import ReactSelect from 'react-select-next'
import styled from 'styled-components'
import { bool, func, array, string } from 'prop-types'

import Grid from 'styled-components-grid'
import { Padding } from 'styled-components-spacing'
import Panel from '../../../panel/panel'

import {
	StyledHeaderLarge,
	StyledInputShortLabel,
	StyledInputMediumLabel,
	StyledInputLarge
} from './placeholder-components'

const PlaceholderEngine = ({ recipe, marginBottom }) => {
	const getComponent = (instruction) => {
		switch (instruction) {
			case 'i-sl':
				return <StyledInputShortLabel />
			case 'i-ml':
				return null
			case 'i-lg':
				return <StyledInputLarge />
			default:
				return null
		}
	}

	const renderComponent = (gridSize, component, index) => {
		const componentToRender = getComponent(component)

		return (
			<Grid.Unit key={index} size={1 / gridSize}>
				<Padding all={3}>{componentToRender}</Padding>
			</Grid.Unit>
		)
	}

	const renderRow = (rowInstruction, index) => {
		const amountOfComponents = rowInstruction.length

		return <Grid key={index}>{rowInstruction.map(renderComponent.bind(null, amountOfComponents))}</Grid>
	}

	return (
		<Panel leftComponent={() => <StyledHeaderLarge />} marginBottom={marginBottom} mode='transparent'>
			<Padding all={3}>{recipe.map(renderRow)}</Padding>
		</Panel>
	)
}

PlaceholderEngine.defaultProps = {
	recipe: array,
	marginBottom: bool
}

PlaceholderEngine.defaultProps = {
	marginBottom: false
}

export default PlaceholderEngine
