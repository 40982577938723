import React, { PureComponent } from 'react'
import { string, bool } from 'prop-types'
import styled, { css } from 'styled-components'
import Icon from '../../../icon/icon'

const StyledSpinnerWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const ContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;

	${(props) =>
		props.isLoading &&
		css`
			visibility: hidden;
		`}
`

export default class ButtonLoadingInsert extends PureComponent {
	static propTypes = {
		isLoading: bool,
		className: string
	}

	render = () => {
		const { isLoading, children, className } = this.props

		return (
			<>
				<ContentWrapper className={className} isLoading={isLoading}>
					{children}
				</ContentWrapper>
				{isLoading && (
					<StyledSpinnerWrapper>
						<Icon icon='faSpinner' type='solid' spin />
					</StyledSpinnerWrapper>
				)}
			</>
		)
	}
}
