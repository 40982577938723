import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import { useSelector } from 'react-redux'
import Text from '../../../dumb-components/shared/text/text'
import { FeatureAdmin } from './feature-admin'
import { usePopupState, bindHover } from 'material-ui-popup-state/hooks'
import { FeatureToolbar } from './feature-toolbar'
import { useFeatureGroupContext } from './feature-group.context'
import { string } from 'prop-types'
import { FeatureContext } from './feature.context'
import { useTranslation } from 'react-i18next'

function FeatureCard({ id }) {
	const [modalOpen, setModalOpen] = useState(false)
	const [link, setLink] = useState(null)
	const { getFeature, isInvonoAdmin, isFetchingFeatures } = useFeatureGroupContext()
	const [index, feature] = getFeature(id)
	const noFeatureAndUserIsAdmin = !isFetchingFeatures && !feature && isInvonoAdmin
	const lang = useSelector((state) => state.i18n.language.substr(0, 2))
	const { t } = useTranslation()
	const popupState = usePopupState({
		variant: 'popover',
		popupId: `${id}-feature`
	})

	useEffect(() => {
		if (feature?.link?.url) {
			const scheme = 'https://'
			const domain = feature?.link?.url

			if (!domain.includes(scheme) && !domain.includes('http://')) {
				setLink(scheme.concat(domain))
			} else {
				setLink(domain)
			}
		}
	}, [feature?.link?.url])

	const startEditingFeature = () => {
		setModalOpen(true)
	}

	const stopEditingFeature = () => {
		setModalOpen(false)
	}

	const value = {
		feature,
		index,
		startEditingFeature,
		stopEditingFeature,
		modalOpen
	}

	if (isFetchingFeatures) {
		return null
	}

	if (noFeatureAndUserIsAdmin) {
		return (
			<FeatureContext.Provider value={value}>
				<FeatureAdmin />
			</FeatureContext.Provider>
		)
	}

	if (!feature) {
		return null
	}

	const adminProps = isInvonoAdmin ? bindHover(popupState) : {}

	return (
		<FeatureContext.Provider value={value}>
			<Card {...adminProps}>
				<CardHeader variant='noBorder' title={feature?.title?.[lang]} />
				<CardContent>
					<Text asHtml>{feature?.body?.[lang]}</Text>

					{feature?.link && (
						<div style={{ marginTop: '24px' }}>
							<Link href={link} target='_blank' variant='body1' color='positive.main'>
								{t('feature_card.link.title')}
							</Link>
						</div>
					)}
				</CardContent>
			</Card>
			{isInvonoAdmin && <FeatureToolbar popupState={popupState} />}
		</FeatureContext.Provider>
	)
}

FeatureCard.propTypes = {
	id: string
}

export { FeatureCard }
