import React, { Component } from 'react'

class ShareholderOptionRenderer extends Component {
	render = () => {
		const { option, onSelect, className } = this.props

		const arr = option.label.split('[split-here]')
		const name = arr[0]
		const id = arr[1]
		const props = {}

		if (!option.disabled) {
			props.onClick = onSelect.bind(this, option)
		}

		return (
			<span {...props} className={className}>
				{name} <span className='text--muted'>{id}</span>
			</span>
		)
	}
}

export default ShareholderOptionRenderer
