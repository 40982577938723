import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import SelectModeratorsModal from '../../../../dumb-components/meetings/standard-meeting/select-moderators-modal'

import { setMeetingModerator, unsetMeetingModerator } from '../../../../actions/meetings.actions'
import { closeModal } from '../../../../actions/modals.actions'

import { MEETINGS_MANAGE_MODERATORS } from '../../../../constants/modals'

class SelectModeratorsModalContainer extends Component {
	state = {
		moderatorsLoading: Map()
	}

	setModeratorIsLoading = (moderatorId, isLoading = false) => {
		let { moderatorsLoading } = this.state

		if (isLoading) {
			moderatorsLoading = moderatorsLoading.set(moderatorId, isLoading)
		} else {
			moderatorsLoading = moderatorsLoading.delete(moderatorId)
		}

		this.setState({ moderatorsLoading })
	}

	onChange = (attendeeId, shouldBeModerator) => {
		const { meetingId, setMeetingModerator, unsetMeetingModerator } = this.props

		this.setModeratorIsLoading(attendeeId, true)

		if (shouldBeModerator) {
			setMeetingModerator(meetingId, attendeeId, this.setModeratorIsLoading)
		} else {
			unsetMeetingModerator(meetingId, attendeeId, this.setModeratorIsLoading)
		}
	}

	closeModal = () => {
		this.props.closeModal()
	}

	getTeamMembersMap = () => {
		const { attendees } = this.props

		return attendees.filter((attendee) => {
			if (attendee.get('isGuest')) {
				return false
			}

			if (attendee.get('isInvestor')) {
				return false
			}

			return true
		})
	}

	render = () => {
		const { isOpen, moderatorsIds, usersCache, proxies } = this.props
		const { moderatorsLoading } = this.state

		if (!isOpen) {
			return null
		}

		const teamMembers = this.getTeamMembersMap()

		return (
			<SelectModeratorsModal
				teamMembers={teamMembers}
				moderatorsIds={moderatorsIds}
				usersCache={usersCache}
				proxies={proxies}
				moderatorsLoading={moderatorsLoading}
				onChange={this.onChange}
				onClose={this.closeModal}
			/>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		meetingId: store.meetings.getIn(['meeting', 'id']),
		attendees: store.meetings.getIn(['meeting', 'attendees']),
		usersCache: store.usersCache.get('usersCache'),
		moderatorsIds: store.meetings.getIn(['meeting', 'moderators']),
		proxies: store.meetings.getIn(['meeting', 'proxies']),
		isOpen: store.modals.getIn(['activeModal', 'name']) === MEETINGS_MANAGE_MODERATORS
	}
}

const mapActionsToProps = {
	setMeetingModerator,
	unsetMeetingModerator,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(SelectModeratorsModalContainer)
