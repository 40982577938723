import React from 'react'
import { string, bool, func, oneOf } from 'prop-types'
import styled from 'styled-components'
import Text from '../text/text'

const StyledLabel = styled.label`
	display: flex;
	align-items: center;
`

const StyledCheckbox = styled.input`
	margin: 0 !important;
	margin-right: ${(props) => props.tid && props.theme.spacing[2]} !important;

	:focus {
		outline: none;
	}
`

const StyledModernCheckbox = styled.div`
	> input {
		display: none;
	}

	> label {
		margin-bottom: 0px;
	}

	> input + label {
		display: flex;
		align-items: center;
		position: relative;
		cursor: pointer;

		> span {
			padding-left: ${(props) => props.theme.spacing[3]};
		}

		&:before {
			content: '';
			width: ${(props) => props.theme.iconSizes['normal']};
			height: ${(props) => props.theme.iconSizes['normal']};
			border: 1px solid ${(props) => props.theme.colors.lightGrey};
			background: ${(props) => props.theme.colors.white};
		}

		&:after {
			content: '✔';
			position: absolute;
			left: 5px;
			font-size: 16px;
			color: ${(props) => props.theme.colors.java};
		}
		@-moz-document url-prefix(){
			&:after {
				font-size: 24px;
			}
		}
		@supports (-ms-ime-align: auto) {
			&:after {
				left: 0px;
			}
  	}
}

	}

	> input:not(:checked) + label {
		&:after {
			opacity: 0;
			transform: scale(0);
		}
	}

	> input:disabled + label {
		cursor: default;

		&:before {
			border-color: ${(props) => props.theme.colors.lightGrey};
			background-color: #eee;
		}
	}

	> input:checked + label {
		&:before {
			opacity: 1;
			transform: scale(1);
		}
	}
`

/**
 * There's an issue with controlled/uncontrolled checkboxes in react. See this:
 * https://github.com/facebook/react/issues/6779
 */
const Checkbox = ({ children, checked, disabled, tid, onChange, mode, fieldName }) => {
	if (!onChange) {
		onChange = () => {} // Fulhack för att slippa massa bekymmer
	}

	const onChangeLocal = (event) => {
		if (fieldName) {
			onChange(fieldName, event.target.value)
		} else {
			onChange(event.target.value)
		}
	}

	const props = {
		checked,
		onChange: onChangeLocal
	}

	let text = null

	if (tid) {
		text = <Text tid={tid} />
	} else if (children) {
		text = children
	}

	if (mode === 'modern-big') {
		return (
			<StyledModernCheckbox
				onClick={() => {
					// Don't trigger on change if checkbox has disabled state
					if (fieldName && !disabled) {
						onChange && onChange(fieldName, !checked)
					} else if (!disabled) {
						onChange && onChange(!checked)
					}
				}}>
				<input type='checkbox' checked={checked} disabled={disabled} onChange={() => {}} />
				<label>{text}</label>
			</StyledModernCheckbox>
		)
	}

	return (
		<StyledLabel>
			<StyledCheckbox type='checkbox' {...props} tid={tid} disabled={disabled} />
			{text}
		</StyledLabel>
	)
}

Checkbox.defaultProps = {
	checked: false,
	disabled: false
}

Checkbox.propTypes = {
	checked: bool,
	disabled: bool,
	tid: string,
	onChange: func,
	mode: oneOf(['modern-big']),
	fieldName: string
}

export default Checkbox
