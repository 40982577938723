import React, { useRef } from 'react'
import { string } from 'prop-types'
import { map } from 'react-immutable-proptypes'
import DropdownMenuContainer from '../shared/dropdown-menu.container'
import DropdownIconItem from '../../dumb-components/shared/dropdown-item/dropdown-icon-item'
import Tooltip from '../../dumb-components/shared/tooltip/tooltip'
import OpenOrDownloadComponent from './generic/open-or-download-file-component.container'
import AddToCollectionComponent from './generic/add-document-to-collection-component.container'
import MoveFileItemContainer from './generic/dropdown-items/move-file-item.container'
import DeleteFileItem from './generic/dropdown-items/delete-file-item.container'
import RecoverFileDropDownItem from './generic/dropdown-items/recover-file.container'
import { openModal } from '../../actions/modals.actions'
import { COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS } from '../../constants/modals'
import { useDispatch } from 'react-redux'

const DocumentListItemDropdownContainer = ({ document, basePath, querystr }) => {
	const dropdownRef = useRef()
	const dispatch = useDispatch()
	const toggleMenu = () => dropdownRef.current?.onToggleMenu()

	return (
		<DropdownMenuContainer
			ref={dropdownRef}
			btnIcon='faEllipsisV'
			halignMenu='right'
			btnMode='transparent-icon'
			transparentIconButtonSize='sml'
			noMaxWidth={true}>
			<OpenOrDownloadComponent document={document}>
				{({ TOOLTIP_STATES, activeState, isDisabled, openOrDownload }) => (
					<Tooltip states={TOOLTIP_STATES} activeState={activeState} active={isDisabled}>
						<DropdownIconItem
							icon='faExpandArrowsAlt'
							tid='open_file'
							disabled={isDisabled}
							onClick={() => {
								toggleMenu()
								openOrDownload()
							}}
						/>
					</Tooltip>
				)}
			</OpenOrDownloadComponent>

			<OpenOrDownloadComponent document={document} mode='DOWNLOAD'>
				{({ openOrDownload }) => (
					<DropdownIconItem
						icon='faArrowAltToBottom'
						tid='download_file'
						onClick={() => {
							toggleMenu()
							openOrDownload()
						}}
					/>
				)}
			</OpenOrDownloadComponent>

			<AddToCollectionComponent document={document}>
				{({ TOOLTIP_STATES, activeState, isDisabled, addToCollection }) => (
					<Tooltip states={TOOLTIP_STATES} activeState={activeState} active={isDisabled}>
						<DropdownIconItem
							tid='documents.list.dropdown.item.add_to_collection'
							icon='faReceipt'
							disabled={isDisabled}
							onClick={() => {
								toggleMenu()
								addToCollection()
							}}
						/>
					</Tooltip>
				)}
			</AddToCollectionComponent>

			<DropdownIconItem
				icon='faCopy'
				tid='personal-documents.copy-file-to-personal-documents'
				onClick={() => {
					toggleMenu()
					dispatch(openModal(COPY_DOCUMENT_TO_PERSONAL_DOCUMENTS, { document }))
				}}
			/>

			<MoveFileItemContainer
				document={document}
				onClick={() => {
					toggleMenu()
				}}
			/>

			<DeleteFileItem document={document} basePath={basePath} querystr={querystr} onClick={toggleMenu} />

			<RecoverFileDropDownItem document={document} basePath={basePath} querystr={querystr} onClick={toggleMenu} />
		</DropdownMenuContainer>
	)
}

DocumentListItemDropdownContainer.propTypes = {
	document: map,
	basePath: string.isRequired,
	querystr: string.isRequired
}

export default DocumentListItemDropdownContainer
