import React from 'react'
import Box from '@mui/material/Box'
import Icon from '../../../dumb-components/shared/icon/icon'

const FullPageSpinner = (props) => {
	return (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center'
				}}>
				<Icon icon='faSpinner' size='xl' spin />
				{props.text && <Box sx={{ pl: 2 }}>{props.text}</Box>}
			</Box>
		</Box>
	)
}

export default FullPageSpinner
