import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, bool, func } from 'prop-types'
import { Map, List, fromJS } from 'immutable'

import {
	fetchUser,
	fetchRoles,
	editUser,
	removeUserFromCompany,
	resetRolesAndUsersCache
} from '../../actions/user-management.actions'
import { updateCompanyLocal } from '../../actions/company.actions'
import { removeUserFromCache } from '../../actions/usersCache.actions'
import { addErrorNotification, addInfoNotification, clearNotifications } from '../../actions/notify.actions'
import { validatePersonNumber, validateEmail } from '../../modules/validation.module'

import UserEditModal from '../../dumb-components/user-management/user-edit-modal/user-edit-modal'
import FooterRightControls from '../../dumb-components/shared/modal/footer-right-controls'
import { TransparentButton } from '../../dumb-components/shared/button-v2'

class UserEditModalContainer extends Component {
	state = {
		user: Map(),
		hasError: Map(),
		rolesOptions: List(),
		isLoading: false
	}

	static propTypes = {
		isOpen: bool,
		userIsActive: bool
	}

	componentDidUpdate = (prevProps) => {
		const { isOpen, roles, fetchRoles, selectedUser, company } = this.props

		if (isOpen && prevProps.isOpen !== isOpen) {
			this.setState({ isLoading: true })
			fetchRoles()
		}

		if (
			isOpen &&
			(prevProps.roles !== roles || prevProps.selectedUser !== selectedUser || prevProps.company !== company)
		) {
			const isOwner =
				selectedUser && selectedUser.id && company && company.superUser && selectedUser.id === company.superUser
			let rolesOptions = List()

			roles.forEach((role) => {
				if (role.id === 'aed45ce5-291f-438f-a47a-f547fdecc5ee' && isOwner) {
					return
				}

				rolesOptions = rolesOptions.push(
					Map({
						label: role.name,
						value: role.id
					})
				)
			})

			this.setState({ rolesOptions })
		}

		if (isOpen && selectedUser && prevProps.selectedUser !== selectedUser) {
			this.setState({ isLoading: false, user: fromJS(selectedUser) })
		}
	}

	onChange = (fieldName, value) => {
		let { user, hasError } = this.state

		if (fieldName === 'email' && !user.has('oldEmail')) {
			user = user.set('oldEmail', user.get('email'))
		}

		user = user.set(fieldName, value)

		this.setState({ user })

		if (hasError.get(fieldName)) {
			hasError = hasError.delete(fieldName)
			this.setState({ hasError })
		}
	}

	onSave = () => {
		const { editUser, company, onCancel, resetRolesAndUsersCache, removeUserFromCache, userIsActive } = this.props
		let { user } = this.state

		this.setState({ isLoading: true })

		if (this.validate()) {
			// Legacy code did convert userRoles to comma separated values
			// so here we go...
			let userRolesJoined = user.get('userRoles').join(',')

			user = user.set('userRoles', userRolesJoined)

			resetRolesAndUsersCache()
			removeUserFromCache(user.get('id'))

			editUser(
				userIsActive ? user.get('id') : user.get('oldEmail', user.get('email')),
				user.toJS(),
				company.id,
				userIsActive,
				(err) => {
					err && this.setState({ isLoading: false })
					!err && this.onCancel()
				}
			)
		} else {
			// Reset loading if validation did not pass
			this.setState({ isLoading: false })
		}
	}

	onCancel = () => {
		const { onCancel, clearNotifications } = this.props
		this.resetState()
		clearNotifications()
		onCancel()
	}

	validate = () => {
		const { user } = this.state
		let { hasError } = this.state
		let isValid = true

		if (user.get('name', '').trim().length === 0) {
			hasError = hasError.set('name', List([true]))
			this.addNotification('name')
			isValid = false
		}

		if (!validateEmail(user.get('email'))) {
			hasError = hasError.set('email', List([true]))
			this.addNotification('email')
			isValid = false
		}

		if (!isValid) {
			this.setState({ hasError })
		}

		return isValid
	}

	addNotification = (fieldName) => {
		const { addErrorNotification } = this.props

		addErrorNotification({
			tid: `user_management.member.add.validation.${fieldName}`
		})
	}

	resetState = () => {
		this.setState({
			user: Map(),
			hasError: Map(),
			rolesOptions: List(),
			isLoading: false
		})
	}

	renderFooter = () => {
		const { isLoading } = this.state

		return (
			<FooterRightControls>
				<TransparentButton tid='update' onClick={this.onSave} isLoading={isLoading} disabled={isLoading} />
				<TransparentButton tid='generic.form.cancel' textColor='midGrey' onClick={this.onCancel} disabled={isLoading} />
			</FooterRightControls>
		)
	}

	render = () => {
		const { user, rolesOptions, hasError, isLoading } = this.state
		const { isOpen } = this.props

		return (
			<React.Fragment>
				<UserEditModal
					isOpen={isOpen}
					user={user}
					rolesOptions={rolesOptions}
					hasError={hasError}
					isLoading={isLoading}
					onChange={this.onChange}
					renderFooter={this.renderFooter()}
				/>
			</React.Fragment>
		)
	}
}

const mapStoreToProps = (store) => {
	return {
		selectedUser: store.company.userManagement.selectedUser,
		company: store.company.company,
		roles: store.company.userManagement.roles
	}
}

const mapActionsToProps = {
	addErrorNotification,
	addInfoNotification,
	clearNotifications,
	fetchRoles,
	fetchUser,
	editUser,
	removeUserFromCompany,
	resetRolesAndUsersCache,
	removeUserFromCache,
	updateCompanyLocal
}

export default connect(mapStoreToProps, mapActionsToProps)(UserEditModalContainer)
