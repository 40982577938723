import React, {useState} from 'react'
import { addDocumentToCollection } from '../../actions/collections.actions'
import AddSignedOrUnsignedDocumentModal from '../../dumb-components/collections/add-signed-or-unsigned-document-modal'
import { useDispatch, useSelector } from 'react-redux'
import {ADD_DOCUMENT_TO_COLLECTION_MODAL} from '../../constants/modals'
import { closeModal } from '../../actions/modals.actions'

function AddDocumentToCollectionModalContainer() {
	const options = useSelector((store) => {
		return store.modals.getIn(['activeModal', 'options'])
	})
	const documentId = options.get('documentId')

	const dispatch = useDispatch()

	const [isLoading, setIsLoading] = useState(false)

	const addToCollection = (version) => {
		setIsLoading(true)

		dispatch(addDocumentToCollection(documentId, version, () => {
			setIsLoading(false)
			dispatch(closeModal())
		}))
	}

	const handleCancel = () => {
		dispatch(closeModal())
	}

	return (
		<AddSignedOrUnsignedDocumentModal
			onAddDocument={addToCollection}
			onCancel={handleCancel}
			isLoadingButton={isLoading}
		/>
	)
}

export default AddDocumentToCollectionModalContainer
