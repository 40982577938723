import React, { PureComponent } from 'react'
import immutablePropTypes from 'react-immutable-proptypes'
import { string, object, oneOfType, bool, func, number, oneOf } from 'prop-types'
import styled from 'styled-components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import ListItem from '../../shared/list-item/list-item'
import StyledList from '../../shared/list/list'
import NewListItemButton from '../../shared/new-list-item-button/new-list-item-button'
import Task from '../task/task'
import Badge from '../../shared/badge/badge-new'
import i18nhelper from '../../../components/helpers/i18n.helper'

const StyledListItem = styled(ListItem)`
	margin-top: ${(props) => props.theme.spacing[2]};
	margin-bottom: 0px;
`

const StyledTransparentListItem = styled(StyledListItem)`
	background-color: transparent;
`

const renderProjectPath = (projectPath, userLang, region) => {
	if (!projectPath) {
		return
	}

	return projectPath
		.map((obj) => {
			return i18nhelper.getTranslatedValue(obj, userLang, region)
		})
		.join(' / ')
}

export default class TaskList extends PureComponent {
	static propTypes = {
		tasks: oneOfType([string, immutablePropTypes.list]),
		currentUrl: string,
		userLang: string,
		region: object,
		onBadgeClick: func,
		onTaskClick: func,
		taskCreatorComponent: func,
		taskInEditMode: string,
		taskEditorComponent: func,
		showTaskCreatorAfterIndex: number,
		onClickAddNewTask: func,
		showNewTaskButton: bool,
		showTaskEditButton: bool,
		onTaskEditBtnClick: func,
		readOnly: bool,
		mode: oneOf(['normal', 'darker']),
		onDragEnd: func,
		isDragDisabled: bool,
		showMoreAction: bool,
		onDeleteTask: func
	}

	renderBadge = (taskId, taskProjectId) => {
		const { projectId, tasksName, tasksMetadata, userLang, region, onBadgeClick, tasks } = this.props
		const showBadge = !projectId || tasksName !== 'tasksInProject'
		const projectName = tasksMetadata.getIn([taskId, 'projectName'])
		const projectPath = tasksMetadata.getIn([taskId, 'projectPath'])
		const prettyProjectPath = projectPath && renderProjectPath(projectPath, userLang, region)

		if (!showBadge || !projectName) {
			return null
		}

		return (
			<Badge expandedText={prettyProjectPath} onClick={() => onBadgeClick && onBadgeClick(taskId, taskProjectId)}>
				{i18nhelper.getTranslatedValue(projectName, userLang, region)}
			</Badge>
		)
	}

	renderTask = (task, index) => {
		const {
			basePath,
			pluginMode,
			mode,
			location,
			projectId,
			tasksName,
			isDragDisabled,
			tasksMetadata,
			currentUrl,
			userLang,
			region,
			onBadgeClick,
			onTaskClick,
			taskInEditMode,
			taskEditorComponent,
			showTaskEditButton,
			onTaskEditBtnClick,
			showMoreAction,
			onDeleteTask
		} = this.props
		let active = false
		if (!pluginMode) {
			const to = `${basePath}/${task.get('id')}${location.search}`
			active = currentUrl === to && !isEditing
		}
		const isEditing = taskInEditMode === task.get('id')
		const taskId = task.get('id')

		const showBadge = !projectId || tasksName !== 'tasksInProject'
		const canUpdate = task.get('ALLOW_UPDATE') === true

		return (
			<Task
				key={index}
				isDragDisabled={!canUpdate || isDragDisabled}
				taskId={taskId}
				title={task.get('title')}
				status={task.get('status')}
				dueAt={task.get('dueAt')}
				orderIndex={task.get('orderIndex')}
				profileImage={tasksMetadata.getIn([taskId, 'profileImage'])}
				assignedToUserId={tasksMetadata.getIn([taskId, 'assignedToUserId'])}
				projectPath={tasksMetadata.getIn([taskId, 'projectPath'])}
				projectName={showBadge && tasksMetadata.getIn([taskId, 'projectName'])}
				projectId={task.get('projectId')}
				index={index}
				mode={mode}
				active={active}
				isEditing={isEditing}
				userLang={userLang}
				region={region}
				onBadgeClick={onBadgeClick}
				onTaskClick={onTaskClick}
				onEditClick={onTaskEditBtnClick}
				taskEditorComponent={isEditing && taskEditorComponent ? taskEditorComponent : null}
				showEditButton={showTaskEditButton}
				renderBadge={this.renderBadge}
				creator={tasksMetadata.getIn([taskId, 'creator'])}
				createdAt={task.get('createdAt')}
				isDeleted={task.get('isDeleted')}
				showMoreAction={showMoreAction}
				onDeleteTask={onDeleteTask}
			/>
		)
	}

	render() {
		const { tasks, onClickAddNewTask, mode, onDragEnd, tasksName, neverShowNewTaskButton } = this.props
		let { showNewTaskButton } = this.props
		const newTaskButtonTid = tasks && tasks.size > 0 ? 'tasks.list.add_another_task' : 'tasks.list.add_new_task'

		if (neverShowNewTaskButton) {
			showNewTaskButton = false
		}

		return (
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={tasksName}>
					{(provided) => (
						<div ref={provided.innerRef}>
							<StyledList>
								{tasks && tasks.map(this.renderTask)}
								{showNewTaskButton && (
									<StyledTransparentListItem>
										<NewListItemButton
											onClick={() => {
												onClickAddNewTask && onClickAddNewTask(null, null)
											}}
											tid={newTaskButtonTid}
											mode={mode}
										/>
									</StyledTransparentListItem>
								)}
							</StyledList>
						</div>
					)}
				</Droppable>
			</DragDropContext>
		)
	}
}
