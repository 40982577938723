import React, { useEffect, useRef } from 'react'
import { bool, func, object } from 'prop-types'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import CRITERIA from '/shared/criteria'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../../mui-components/checkbox/checkbox'
import FormHelperText from '@mui/material/FormHelperText'
import { useForm } from 'react-final-form'
import Box from '@mui/material/Box'
import { Select } from 'mui-rff'
import MenuItem from '@mui/material/MenuItem'

const DealflowCriteria = ({ errors, hideSelectAll, data }) => {
	const { t } = useTranslation()
	const categoryRefs = useRef({})
	const form = useForm()

	useEffect(() => {
		if (!errors) {
			return
		}

		const errorKeys = Object.keys(errors)
		if (errorKeys.length > 0) {
			const firstKey = errorKeys[0]
			categoryRefs.current[firstKey]?.focus?.()
		}
	}, [errors])

	return (
		<>
			{Object.keys(CRITERIA).map((section, i) => {
				return (
					<Grid item md={12} key={i}>
						<Card borderradius={'true'} ref={(ref) => (categoryRefs.current[section] = ref)} tabIndex={-1}>
							<CardContent>
								<Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<Grid item>
										<Typography variant={'h5'} gutterBottom>
											{t(`dealflow.criteria.section.${section}`)}
										</Typography>
									</Grid>

									{!hideSelectAll && (
										<Grid item sx={{ mb: 2, width: '200px' }}>
											<Select
												name={`sectionSelectionType-${section}`}
												label={t('dealflow.use_criteria_modal.dropdown.and_or_selection')}>
												<MenuItem value={'and'}>
													{t('dealflow.use_criteria_modal.dropdown.item.and')}
												</MenuItem>
												<MenuItem value={'or'}>
													{t('dealflow.use_criteria_modal.dropdown.item.or')}
												</MenuItem>
											</Select>
										</Grid>
									)}
								</Grid>

								{errors?.[section] && (
									<FormHelperText error sx={{ pb: 1 }}>
										{errors[section]}
									</FormHelperText>
								)}

								<Grid container spacing={2} rowSpacing={'0px'}>
									{CRITERIA[section].map((criterion, i) => {
										if (hideSelectAll && criterion.includes('select_all')) {
											return null
										}
										const properties = {
											name: criterion,
											data: data,
											...(!hideSelectAll && {
												onClick: () => {
													form.mutators.selectAll(criterion)
												}
											})
										}

										return (
											<Grid key={i} item md={3}>
												<FormControlLabel
													sx={{ height: '30px' }}
													nomargin={data && 'true'}
													control={<Checkbox {...properties} />}
													label={
														<Typography variant={'body2'}>
															{t(`dealflow.criteria.${criterion}`)}
														</Typography>
													}
												/>
											</Grid>
										)
									})}
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				)
			})}
		</>
	)
}

DealflowCriteria.propTypes = {
	handleCriteriaChange: func,
	criteria: object,
	errors: object,
	hideSelectAll: bool,
	data: object
}

export default DealflowCriteria
