import React from 'react'
import { bool, func, string } from 'prop-types'
import styled from 'styled-components'
import { ButtonTransparentIcon } from '../../shared/button'
import Dropzone from '../../shared/dropzone/dropzone'
import Text from '../../shared/text/text'
import Tooltip from '../../shared/tooltip/tooltip'

const DocumentUploadWrapper = styled.div``

const FullScreenWrapper = styled.div`
	top: 0;
	left: 0;
	z-index: 100;
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: ${(props) => props.theme.colors.java};
	opacity: 0.8;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
`

const DocumentUpload = ({
	isOpen,
	onClickBtnOpen,
	name,
	multiple,
	onDrop,
	dropzoneRef,
	hasPermissionsToUpload,
	hasAppliedFilters,
	isSharedFolder,
	sharedByCompanyName,
	isRootSharedFolder
}) => {
	const dropzoneProps = {
		name,
		multiple,
		onDrop,
		dropzoneRef
	}

	const fullscreenMessageTid = hasAppliedFilters
		? 'documents.fullscreen-upload.info.disabled-by-filters'
		: 'documents.fullscreen-upload.info'
	const uploadBtnDisabled = !hasPermissionsToUpload || hasAppliedFilters

	let tooltipActiveState = null
	const tooltipValues = {}
	const tooltipStates = {
		default: {
			tid: 'tooltip.documents.action.upload_document'
		},
		hasAppliedFilters: {
			tid: 'tooltip.documents.cant_upload_with_filters',
			delayShow: 'instant'
		},
		isRootSharedFolder: {
			tid: 'tooltip.documents.action.upload_document.folder_is_root_shared_folder',
			delayShow: 'instant'
		}
	}

	if (isRootSharedFolder) {
		tooltipActiveState = 'isRootSharedFolder'
	} else if (!hasPermissionsToUpload && isSharedFolder) {
		tooltipActiveState = 'hasNoPermissionsSharedCompany'
		tooltipValues.companyName = sharedByCompanyName
	} else if (!hasPermissionsToUpload) {
		tooltipActiveState = 'hasNoPermissions'
	} else if (hasAppliedFilters) {
		tooltipActiveState = 'hasAppliedFilters'
	}

	return (
		<DocumentUploadWrapper id='intro-step2'>
			<Tooltip states={tooltipStates} activeState={tooltipActiveState} values={tooltipValues}>
				<ButtonTransparentIcon
					onClick={onClickBtnOpen}
					icon='faArrowAltFromBottom'
					disabled={uploadBtnDisabled}
					size='sml'
				/>
			</Tooltip>

			<Dropzone {...dropzoneProps} size='full-screen' hidden={true} />

			{isOpen && hasPermissionsToUpload && (
				<Dropzone {...dropzoneProps} size='full-screen' readOnly={hasAppliedFilters}>
					<FullScreenWrapper>
						<Text tag='h1' tid={fullscreenMessageTid} color='white' />
					</FullScreenWrapper>
				</Dropzone>
			)}
		</DocumentUploadWrapper>
	)
}

DocumentUpload.defaultProps = {
	isOpen: false,
	hasPermissionsToUpload: false,
	hasAppliedFilters: false
}

DocumentUpload.propTypes = {
	isOpen: bool,
	onClickBtnOpen: func,
	dropzoneRef: func,
	hasPermissionsToUpload: bool,
	hasAppliedFilters: bool,
	isSharedFolder: bool,
	sharedByCompanyName: string,
	isRootSharedFolder: bool
}

export default DocumentUpload
