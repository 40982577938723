import React, { useContext } from 'react'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import styled from '@mui/styled-engine'
import { InvestmentsContext } from '../../../containers/investments/investment.container'
import investmentHelper from '/shared/helpers/investment.helper'
import { formatIdPretty } from '../../../components/helpers/users'
import {useTranslation} from 'react-i18next'

const StyledBadge = styled(Paper)(({ theme }) => {
	return {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.secondary,
		padding: '0.772rem 1.6rem',
		fontWeight: 600
	}
})

export function Badge() {
	const {t} = useTranslation()
	const {selectedInvestment} = useContext(InvestmentsContext)
	const name = selectedInvestment && investmentHelper.getInvestorName(selectedInvestment.toJS())
	let ssn = selectedInvestment && investmentHelper.getInvestorSsn(selectedInvestment.toJS())

	if (ssn) {
		ssn = formatIdPretty(ssn)
	}

	return (
		<StyledBadge>
			<Stack
				spacing={1}
				direction='row'
				divider={<Divider sx={{ width: '1px', backgroundColor: 'white' }} flexItem variant='middle' />}>
				<span>{t('investments.preview_shareholder.preview')}</span>
				<span>{name} {ssn}</span>
			</Stack>
		</StyledBadge>
	)
}
