const {
	PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE,
	PERSONAL_TRANSACTION_TYPE_LEVELING,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES,
	PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS,
	PERSONAL_TRANSACTION_TYPE_GENESIS,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL,
	PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER,
	PERSONAL_TRANSACTION_TYPE_SPLIT,
	PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE,
	PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES,
	PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL,
	PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES
} = require('../constants.json');

const linkTransactionsHelper = {
	//For any given transaction, for any given investment returns what the personal transaction type will translate into
	getTranslatedTransactionType: (transaction, investmentId) => {
		const { type, handlerData } = transaction;
		switch (type) {
			case 'GENESIS':
				return PERSONAL_TRANSACTION_TYPE_GENESIS;
			case 'TRANSFER':
			case 'TRANSFER-2': {
				const { investmentIdFrom, investmentIdTo } = handlerData;
				if (investmentIdFrom === investmentId) {
					return PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL;
				} else if (investmentIdTo === investmentId) {
					return PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY;
				} else {
					return PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER;
				}
			}
			case 'EMISSION': {
				const { emissionType } = handlerData;
				switch (emissionType) {
					case 'RIGHTS_ISSUE':
						return PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE;
					case 'DIRECTED_NEW_ISSUE':
						return PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE;
					case 'EMISSION-LEVELING':
						return PERSONAL_TRANSACTION_TYPE_LEVELING;
					case 'UNKNOWN_EMISSION_TYPE_1':
						return PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES;
					case 'UNKNOWN_EMISSION_TYPE_2':
						return PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS;
					default:
				}
				return;
			}
			case 'SPLIT':
				return PERSONAL_TRANSACTION_TYPE_SPLIT;
			case 'CONVERSION-OF-SHARES':
				return PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES;
			case 'CHANGE-VOTES':
				return PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES;
			case 'REDUCTION-OF-SHARE-CAPITAL':
				return PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL;
			case 'BONUS-ISSUE':
				return PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE;
			// case 'CHANGE-OF-RESERVATION':
			// 	return //Not handled by my records
			// case 'CHANGE-PLEDGE':
			// 	return //Not handled by my records
			// case 'CHANGE-SHARE-LETTER':
			// 	return //Not handled by my records
			default:
				return;
		}
	},
	createLinkedPersonalTransaction: (shareRegiterTransaction, investmentId) => {
		const { date, balance, id, handlerData, sequences, prevtransactionBalance } = shareRegiterTransaction;
		const { totalVotes, postMoney, myPostMoney, numOfTotalCompanyShares, myTotalVotes, types } = balance;
		const transactionType = linkTransactionsHelper.getTranslatedTransactionType(
			shareRegiterTransaction,
			investmentId
		);
		//Create skeleton with data that is uniform for every transaction type
		const personalTransaction = {
			transactionType,
			date,
			link: {
				transactionId: id
			},
			handlerData: {},
			shareData: {
				postMoney,
				myPostMoney,
				numOfTotalCompanyShares,
				totalVotes,
				myTotalVotes
			}
		};

		//Fill out the data specific for each transacation type
		switch (transactionType) {
			case PERSONAL_TRANSACTION_TYPE_RIGHTS_ISSUE:
			case PERSONAL_TRANSACTION_TYPE_DIRECTED_NEW_ISSUE:
			case PERSONAL_TRANSACTION_TYPE_CONVERSION_CONVERTIBLES:
			case PERSONAL_TRANSACTION_TYPE_EXCERCISE_OPTIONS_RIGHTS: {
				const emittedType = handlerData.types[0];
				const shareTypeFromBalance = types.find((type) => {
					return type.type === emittedType.type;
				});
				const votesPerShare = shareTypeFromBalance.votesPerShare || 0;

				const shareholderFromBalance = handlerData.shareholders.find((shareholder) => {
					return shareholder.investmentId === investmentId && shareholder.type === emittedType.type;
				});
				const myShares = shareholderFromBalance ? shareholderFromBalance.numOfShares : 0;

				personalTransaction.handlerData.types = [
					{
						shareType: emittedType.type,
						totalSharesEmitted: emittedType.numOfShares,
						latestPrice: emittedType.pricePerShare,
						votesPerShare,
						myShares
					}
				];
				break;
			}
			case PERSONAL_TRANSACTION_TYPE_LEVELING: {
				const { shareDiff } = handlerData;
				const myShareDiffs = shareDiff[investmentId];
				if (!myShareDiffs) {
					break;
				}
				const totalSharesPerType = {};
				Object.values(shareDiff).forEach((diff) => {
					diff.forEach((shareType) => {
						const { type, diff } = shareType;
						if (totalSharesPerType[type]) {
							totalSharesPerType[type] += diff;
						} else {
							totalSharesPerType[type] = diff;
						}
					});
				});
				const shareTypes = myShareDiffs.map((diff) => {
					const sequence = sequences[diff.index];
					const latestPrice = sequence.price || 0;
					const balanceType = types.find((balanceType) => {
						return balanceType.type === diff.type;
					});
					const votesPerShare = balanceType.votesPerShare || 0;
					return {
						shareType: diff.type,
						totalSharesEmitted: totalSharesPerType[diff.type],
						latestPrice,
						votesPerShare,
						myShares: diff.diff
					};
				});
				personalTransaction.handlerData.types = shareTypes;
				break;
			}
			case PERSONAL_TRANSACTION_TYPE_GENESIS: {
				/**
					For the Genesis transaction we work directly with the balance object instead
					of the transactions handlerData, We can do this because the genesis transaction
					is guaranteed to not have any previous transactions.
					*/
				if (!types) {
					break;
				}
				let shareTypes = types.map((type) => {
					return {
						shareType: type.type,
						totalSharesEmitted: type.totalShares || 0,
						latestPrice: type.latestPrice || 0,
						votesPerShare: type.votesPerShare || 0,
						myShares: type.shares || 0
					};
				});
				shareTypes = shareTypes.filter((type) => {
					return type.myShares > 0;
				});
				personalTransaction.handlerData.types = shareTypes;
				break;
			}
			case PERSONAL_TRANSACTION_TYPE_TRANSFER_BUY:
			case PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL:
			case PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER: {
				const { investmentNameFrom, investmentNameTo } = handlerData;
				personalTransaction.handlerData = {
					transferFrom: investmentNameFrom,
					transferTo: investmentNameTo
				};
				const originalTransactionType = shareRegiterTransaction.type;
				let handlerDataSequences = handlerData.sequences;

				if (originalTransactionType === 'TRANSFER') {
					const transactionSequences = shareRegiterTransaction.sequences;
					handlerDataSequences = handlerDataSequences.map((handlerDataSequence) => {
						const sequence = transactionSequences[handlerDataSequence.index];
						handlerDataSequence.shareType = sequence.type;
						handlerDataSequence.used = handlerDataSequence.amount;
						return handlerDataSequence;
					});
				} else {
					handlerDataSequences = handlerDataSequences.map((sequence) => {
						let { data } = sequence;
						if (!data) {
							return sequence;
						}
						data = data.trim();
						const [sequenceFrom, sequenceTo] = data.split('-');
						sequence.used = sequenceTo - sequenceFrom + 1;
						return sequence;
					});
				}
				handlerDataSequences = handlerDataSequences.filter((sequence) => {
					return sequence.used > 0;
				});
				const handlerDataShareTypes = handlerDataSequences.map((sequence) => {
					return sequence.shareType;
				});
				const uniqueShareTypeNames = [...new Set(handlerDataShareTypes)];

				const shareTypes = uniqueShareTypeNames.map((shareTypeName) => {
					const shareTypeFromBalance =
						types &&
						types.find((type) => {
							return type.type === shareTypeName;
						});
					//When you sell all your shares there won't be any balance object from the current transaction, share type information however exists from the previous one.
					const shareTypeFromPrevBalance =
						prevtransactionBalance &&
						prevtransactionBalance.types &&
						prevtransactionBalance.types.find((type) => {
							return type.type === shareTypeName;
						});

					const shareType = {
						shareType: shareTypeName
					};
					if (transactionType === PERSONAL_TRANSACTION_TYPE_TRANSFER_OTHER) {
						return shareType;
					}
					shareType.votesPerShare =
						(shareTypeFromBalance && shareTypeFromBalance.votesPerShare) ||
						(shareTypeFromPrevBalance && shareTypeFromPrevBalance.votesPerShare) ||
						0;
					let myShares = 0;
					let latestPrice = 0;
					handlerDataSequences.forEach((sequence) => {
						if (sequence.shareType === shareTypeName) {
							myShares += sequence.used;
							latestPrice = sequence.price || latestPrice;
						}
					});
					if (transactionType === PERSONAL_TRANSACTION_TYPE_TRANSFER_SELL) {
						const capitalInvestedForPrevType =
							(shareTypeFromPrevBalance && shareTypeFromPrevBalance.capitalInvestedForType) || 0;
						const capitalInvestedForType =
							(shareTypeFromBalance && shareTypeFromBalance.capitalInvestedForType) || 0;
						const capitalInvestedForTypeDiff = capitalInvestedForType - capitalInvestedForPrevType;
						shareType.purchasePrice = -capitalInvestedForTypeDiff;
					}
					shareType.myShares = myShares;
					shareType.latestPrice = latestPrice;
					return shareType;
				});
				personalTransaction.handlerData.types = shareTypes;
				break;
			}
			case PERSONAL_TRANSACTION_TYPE_SPLIT: {
				const { before, after } = handlerData;

				personalTransaction.handlerData.splitQuotaFrom = before;
				personalTransaction.handlerData.splitQuotaTo = after;

				const shareTypes = types.map((type) => {
					return {
						shareType: type.type,
						latestPrice: type.latestPrice || 0,
						purchasePrice: type.capitalInvestedForType || 0,
						myShares: type.shares || 0
					};
				});
				personalTransaction.handlerData.types = shareTypes;
				break;
			}
			case PERSONAL_TRANSACTION_TYPE_BONUS_ISSUE: {
				const { shareTypes } = handlerData;

				//find the sharetype that is changed by comparing the current balance to the last transactions balance
				const prevBalanceTypesObject = prevtransactionBalance && prevtransactionBalance.types;
				const prevBalanceTypes =
					prevBalanceTypesObject &&
					prevBalanceTypesObject.map((type) => {
						return type.type;
					});
				const updatedShareTypesFromBalance = types.filter((type) => {
					//If the share type wasn't part of prev transaction its the new one for this bonus issue
					if (!prevBalanceTypesObject) {
						return true;
					}
					if (!prevBalanceTypes.includes(type.type)) {
						return true;
					}
					//if the sharetype existed before check if the number of shares changed.
					const prevBalanceTypeObj = prevBalanceTypesObject.find((prevType) => {
						return prevType.type === type.type;
					});
					return type.shares !== prevBalanceTypeObj.shares;
				});
				const mappedTypes = updatedShareTypesFromBalance.map((type) => {
					const prevBalanceTypeObj =
						prevBalanceTypesObject &&
						prevBalanceTypesObject.find((prevType) => {
							return prevType.type === type.type;
						});

					const myShares = prevBalanceTypeObj ? type.shares - prevBalanceTypeObj.shares : type.shares;

					const handlerDataShareType = shareTypes.find((handerType) => {
						return handerType.type === type.type;
					});
					return {
						shareType: type.type,
						totalSharesEmitted: (handlerDataShareType && handlerDataShareType.numOfShares) || 0,
						latestPrice: type.latestPrice || 0,
						votesPerShare: type.votesPerShare || 0,
						myShares
					};
				});
				personalTransaction.handlerData.types = mappedTypes;
				break;
			}
			case PERSONAL_TRANSACTION_TYPE_ADJUSTMENT_OF_VOTES: {
				let { types } = handlerData;
				types = types.map((type) => {
					return {
						shareType: type.type,
						votesPerShare: type.votes
					};
				});
				personalTransaction.handlerData.types = types;
				break;
			}
			case PERSONAL_TRANSACTION_TYPE_REDUCTION_OF_SHARE_CAPITAL: {
				const { investments } = handlerData;
				let shareTypes = investments[investmentId];
				shareTypes = shareTypes.filter((type) => {
					return type.diff > 0;
				});
				const prevBalanceTypes = prevtransactionBalance.types;

				shareTypes = shareTypes.map((shareType) => {
					const typeFromBalance =
						types &&
						types.find((type) => {
							return type.type === shareType.type;
						});
					const typeFromPrevBalance = prevBalanceTypes.find((type) => {
						return type.type === shareType.type;
					});
					const prevCapitalInvested = typeFromPrevBalance.capitalInvestedForType;
					const capitalInvested = typeFromBalance ? typeFromBalance.capitalInvestedForType : 0;
					const capitalInvestedForTypeDiff = capitalInvested - prevCapitalInvested;
					return {
						shareType: shareType.type,
						myShares: shareType.diff,
						purchasePrice: Math.abs(capitalInvestedForTypeDiff)
					};
				});

				if (shareTypes.length > 0) {
					personalTransaction.handlerData.types = shareTypes;
				}
				break;
			}
			case PERSONAL_TRANSACTION_TYPE_CONVERSION_OF_SHARES:
				{
					const { shareTypeFrom, shareTypeTo, investors } = handlerData;
					const shareCertificates = investors[investmentId];
					let shares = 0;
					shareCertificates.forEach((certificate) => {
						const { amount } = certificate;
						if (amount > 0) {
							shares += amount;
						}
					});
					personalTransaction.handlerData.conversionOfSharesFrom = shareTypeFrom;
					personalTransaction.handlerData.conversionOfSharesTo = shareTypeTo;
					personalTransaction.handlerData.types = [
						{
							shareType: shareTypeTo,
							myShares: shares
						}
					];
				}

				break;
		}
		personalTransaction.isComplete = true;

		return personalTransaction;
	},
	filterTransactions: (transactions, investmentId) => {
		return transactions.filter((transaction) => {
			const { type, handlerData } = transaction;
			switch (type) {
				case 'REDUCTION-OF-SHARE-CAPITAL':
				case 'BONUS-ISSUE':
					return handlerData.type === 'shares';
				case 'CONVERSION-OF-SHARES':
					return handlerData.investors[investmentId];
				case 'CHANGE-OF-RESERVATION':
				case 'CHANGE-SHARE-LETTER':
				case 'CHANGE-PLEDGE':
					return false;
				default:
					return true;
			}
		});
	}
};

module.exports = linkTransactionsHelper;
