import { NUMBER_FORMAT_CURRENCY, NUMBER_FORMAT_PERCENTAGE, NUMBER_FORMAT_INTEGER } from '/shared/constants'
import localeFormatNumber from '/shared/helpers/number-formatter.helper'

export function calculateStatistics(emissionStatistics) {
	return {
		achievedAmount: achievedAmount(emissionStatistics),
		amountToAchieveSpan: amountToAchieveSpan(emissionStatistics),
		percentageOfLowerSpanAsNumber: percentageOfLowerSpanAsNumber(emissionStatistics),
		percentageOfLowerSpanAsPercentage: percentageOfLowerSpanAsPercentage(emissionStatistics),
		percentageOfHigherSpanAsNumber: percentageOfHigherSpanAsNumber(emissionStatistics),
		percentageOfHigherSpanAsPercentage: percentageOfHigherSpanAsPercentage(emissionStatistics),
		availableSharesSpan: availableSharesSpan(emissionStatistics),
		subscribedNewShares: formatAsInteger(emissionStatistics.subscribedNewShares),
		inviteesInvited: formatAsInteger(emissionStatistics.inviteesInvited),
		inviteesSubscribed: formatAsInteger(emissionStatistics.inviteesSubscribed)
	}
}

function achievedAmount(emissionStatistics) {
	return formatAsCurrency(emissionStatistics.achievedAmount)
}

function amountToAchieveSpan(emissionStatistics) {
	const minAmount = emissionStatistics.minAmountToAchieve
	const maxAmount = emissionStatistics.maxAmountToAchieve

	if (minAmount === maxAmount || minAmount === 0) {
		return formatAsCurrency(maxAmount)
	}

	return `${formatAsCurrency(minAmount)} - ${formatAsCurrency(maxAmount)}`
}

function percentageOfLowerSpanAsNumber(emissionStatistics) {
	const percentage = (emissionStatistics.achievedAmount / emissionStatistics.minAmountToAchieve) * 100
	return percentage > 100 ? 100 : Number(percentage.toFixed(2))
}

function percentageOfLowerSpanAsPercentage(emissionStatistics) {
	return formatAsPercentage(percentageOfLowerSpanAsNumber(emissionStatistics))
}

function percentageOfHigherSpanAsNumber(emissionStatistics) {
	const precentageAsNumber = Number(
		((emissionStatistics.achievedAmount / emissionStatistics.maxAmountToAchieve) * 100).toFixed(2)
	)

	return isNaN(precentageAsNumber) ? 0 : precentageAsNumber
}

function percentageOfHigherSpanAsPercentage(emissionStatistics) {
	return formatAsPercentage(percentageOfHigherSpanAsNumber(emissionStatistics))
}

function availableSharesSpan(emissionStatistics) {
	const min = emissionStatistics.minAmountOfNewShares
	const max = emissionStatistics.maxAmountOfNewShares

	if (min === max || min === 0) {
		return formatAsCurrency(max)
	}

	return `${formatAsCurrency(min)} - ${formatAsCurrency(max)}`
}

function formatAsCurrency(num) {
	return localeFormatNumber(num, NUMBER_FORMAT_CURRENCY, { suffix: '', round: 0 })
}

function formatAsInteger(num) {
	return localeFormatNumber(num, NUMBER_FORMAT_INTEGER, { suffix: '', round: 0 })
}

function formatAsPercentage(num) {
	return localeFormatNumber(num, NUMBER_FORMAT_PERCENTAGE, { suffix: ' %', round: 2 })
}
