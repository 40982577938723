import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, func } from 'prop-types'
import { map } from 'react-immutable-proptypes'

import history from '../../../interfaces/history'

import { deleteDocuments } from '../../../actions/documents.actions'
import { deleteSharedDocument } from '../../../actions/documents-sharing.actions'
import { openModal, closeModal } from '../../../actions/modals.actions'
import { CONFIRM_DELETE_DOCUMENT } from '../../../constants/modals'

const DELETE_TOOLTIP_STATES = {
	default: {
		tid: 'documents.toolbar.tooltip.trash_document'
	}
}

class DeleteFileComponentContainer extends Component {
	static propTypes = {
		document: map.isRequired,
		basePath: string.isRequired,
		querystr: string.isRequired
	}

	reqCallback = () => {
		const { basePath, querystr } = this.props

		history.push({
			pathname: basePath,
			search: querystr
		})
	}

	deleteDocument = () => {
		const { document, openModal } = this.props
		const func = this.doDeleteDocument
		if (document.has('links') && document.get('links').size > 0) {
			openModal(CONFIRM_DELETE_DOCUMENT, func)
			return
		}

		this.doDeleteDocument()
	}

	doDeleteDocument = () => {
		const {
			deleteDocuments,
			document,
			closeModal,
			deleteSharedDocument,
			isSharedFromCompany,
			isSharedFromCompanyId
		} = this.props
		const docId = document.get('id')
		if (isSharedFromCompany) {
			deleteSharedDocument(docId, isSharedFromCompanyId, this.reqCallback)
		} else {
			deleteDocuments(docId, this.reqCallback)
		}
		closeModal()
	}

	getDeleteTooltipData = () => {
		const { document, isSharedFromCompany, isSharedFromCompanyName } = this.props
		const canDeleteDocument = document.get('ALLOW_DELETE')

		let activeState = 'default'
		let isDisabled = false
		const tooltipValues = {}

		if (!canDeleteDocument && isSharedFromCompany) {
			activeState = 'hasNoPermissionsSharedCompany'
			tooltipValues.companyName = isSharedFromCompanyName
			isDisabled = true
		} else if (!canDeleteDocument) {
			activeState = 'hasNoPermissions'
			isDisabled = true
		}

		return {
			TOOLTIP_STATES: DELETE_TOOLTIP_STATES,
			activeState,
			tooltipValues,
			isDisabled
		}
	}

	getDocumentIsDeleted = () => {
		const { document } = this.props
		return document.get('isDeleted')
	}

	render = () => {
		const { document, children } = this.props
		const isDeleted = document.get('isDeleted')
		const { TOOLTIP_STATES, activeState, tooltipValues, isDisabled } = this.getDeleteTooltipData()

		if (isDeleted) {
			return null
		}

		return children({
			softDelete: this.deleteDocument,
			TOOLTIP_STATES,
			activeState,
			tooltipValues,
			isDisabled,
			isDeleted: false
		})
	}
}

const mapStoreToProps = (store, ownProps) => {
	const isSharedFromCompanyId = ownProps.document.get('isSharedFromCompanyId')

	return {
		isSharedFromCompanyId,
		isSharedFromCompany: Boolean(isSharedFromCompanyId),
		isSharedFromCompanyName: store.folders.getIn(['folders', isSharedFromCompanyId, 'translatedName'])
	}
}

const mapActionsToProps = {
	deleteDocuments,
	deleteSharedDocument,
	openModal,
	closeModal
}

export default connect(mapStoreToProps, mapActionsToProps)(DeleteFileComponentContainer)
